import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Grid, Typography, TextField } from 'rc'
import { Button } from 'components'

const CancelPrimeMC = ({ HideModal, unsubscribePrime, canceled, isAgency }) => {
  const [cancelPrimeReason, setCancelPrimeReason] = useState('')

  return !canceled ? (
    <ModalContainer
      container
      direction="column"
      alignItems="space-between"
      css={`
        ${({ theme }) => theme.media.tabletDown`
          height: ${({ theme }) => theme.remCalc('500')};
          padding: ${({ theme }) => theme.remCalc('20')};
        `}
      `}
    >
      <Typography variant="h2" align="center">
        ¿Por qué quieres cancelar tu suscripción Wiggot prime?
      </Typography>
      <TextField
        multiline
        label="Tus comentarios nos ayudan"
        id="account-primeSection-textarea-cancelPrimeReason"
        onChange={e => setCancelPrimeReason(e.target.value || '')}
        css={`
          textarea {
            height: ${({ theme }) => theme.remCalc('200')};
          }
        `}
      ></TextField>
      <Grid container justify="space-between">
        <Button
          id="account-primeSection-button-cancelPrimeClose"
          type="tertiary"
          onClick={HideModal}
          css={`
            width: ${({ theme }) => theme.remCalc('200')};
            ${({ theme }) => theme.media.tabletDown`
              width: ${({ theme }) => theme.remCalc('105')};
            `}
          `}
        >
          Cerrar
        </Button>
        <Button
          id="account-primeSection-button-cancelPrimeConfirm"
          type="alert"
          onClick={ () => unsubscribePrime(cancelPrimeReason, isAgency) }
          css={`
            width: ${({ theme }) => theme.remCalc('290')};
            ${({ theme }) => theme.media.tabletDown`
              width: ${({ theme }) => theme.remCalc('155')};
            `}
          `}
        >
          Cancelar suscripción
        </Button>
      </Grid>
    </ModalContainer>
  ) : (
    <ModalContainer
      container
      direction="column"
      justify="center"
      alignItems="space-around"
    >
      <Typography variant="h2" align="center">
        Tu plan PRIME ha sido cancelado
      </Typography>
      <Typography variant="subtitle1" align="center">
        Al terminar el periodo actual de prime contratado éste ya no se renovará
        automáticamente, hasta que realices de nuevo el proceso de activación.
      </Typography>
      <Button
        id="account-primeSection-button-cancelPrimeClose"
        type="tertiary"
        onClick={HideModal}
        css={`width: ${({ theme }) => theme.remCalc('200')};`}
      >
        Cerrar
      </Button>
    </ModalContainer>
  )
}

const ModalContainer = styled(Grid)`
  width: ${({ theme }) => theme.remCalc('650')};
  max-width: 100%;
  height: ${({ theme }) => theme.remCalc('400')};
  padding: ${({ theme }) => theme.remCalc('20 50')};
`

CancelPrimeMC.propTypes = {
  canceled: PropTypes.bool,
  isAgency: PropTypes.bool,
  unsubscribePrime: PropTypes.func,
  HideModal: PropTypes.func
}

export default CancelPrimeMC
