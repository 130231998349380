var _jsxFileName = 'src/lib/elements/Checkboxes/RadioCheckBoxItem.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Layout, LayoutItem, Block } from '../Layouts';
import { Text } from '../Texts';

var RadioCheckBoxItem = function RadioCheckBoxItem(_ref) {
  var className = _ref.className,
      label = _ref.label,
      value = _ref.value,
      isSelected = _ref.isSelected,
      _onClick = _ref.onClick,
      noLabel = _ref.noLabel,
      disabled = _ref.disabled,
      center = _ref.center,
      uppercase = _ref.uppercase;

  var checkboxContainerClasses = classNames(className, 'radio-checkbox-item', {
    'radio-checkbox-item--checked': isSelected,
    'radio-checkbox-item--unchecked': !isSelected,
    'radio-checkbox-item--disabled': disabled,
    'radio-checkbox-item--no-label': noLabel
  });

  var checkboxClasses = classNames('radio-checkbox-item__checkbox', {
    'radio-checkbox-item__checkbox--checked': isSelected,
    'radio-checkbox-item__checkbox--unchecked': !isSelected,
    'radio-checkbox-item__checkbox--disabled': disabled
  });

  var labelCheckbox = classNames('radio-checkbox-item__label', {
    'radio-checkbox-item__disabled': disabled
  });
  return React.createElement(
    Layout,
    {
      className: checkboxContainerClasses,
      middle: true,
      center: center,
      onClick: function onClick() {
        _onClick(value);
      },
      __source: {
        fileName: _jsxFileName,
        lineNumber: 40
      },
      __self: _this
    },
    React.createElement(
      Block,
      { noResponsive: true, className: checkboxClasses, __source: {
          fileName: _jsxFileName,
          lineNumber: 48
        },
        __self: _this
      },
      React.createElement(
        Layout,
        { center: true, middle: true, fullHeight: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 49
          },
          __self: _this
        },
        isSelected && React.createElement(LayoutItem, { className: 'radio-checkbox-item__checkbox-selected', __source: {
            fileName: _jsxFileName,
            lineNumber: 51
          },
          __self: _this
        })
      )
    ),
    !noLabel && React.createElement(
      Text,
      { shrink: true, className: labelCheckbox, uppercase: uppercase, bold: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 56
        },
        __self: _this
      },
      label
    )
  );
};

RadioCheckBoxItem.propTypes = {
  /** Value to give on callback. */
  value: PropTypes.any.isRequired,
  /** Render's the element in a centered position */
  center: PropTypes.bool,
  /** Custom className in Layout Wrapper.  */
  className: PropTypes.string,
  /** Gives a disable stylish. */
  disabled: PropTypes.bool,
  /** Image url that should be displayed. */
  imageUrl: PropTypes.string,
  /** Gives a diferent style. */
  isSelected: PropTypes.bool,
  /** Text to render in checkbox */
  label: PropTypes.string,
  /** */
  noLabel: PropTypes.bool,
  /** Handler of click action on Checkbox. */
  onClick: PropTypes.func,
  /** Display's the label prop in a uppercase form */
  uppercase: PropTypes.bool
};

export default RadioCheckBoxItem;