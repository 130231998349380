import React from 'react'
import BenefitLayoutMC from 'modules/freemium/BenefitLayoutMC'
import ListImg from 'static/drawings/avaclick-zero.svg'

const PropertiesLimitMC = (props) => {
  return (<BenefitLayoutMC
    title='Agrega propiedades ilimitadas'
    image={ListImg}
    description='Haz llegado a tu límite de propiedades en una lista. Si deseas agregar más propiedades, contrata Prime y crea listas con propiedades ilimitadas.'
  />)
}

export default PropertiesLimitMC