import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Typography } from 'rc'
import DropDown, { DropdownAction, DropdownMenu } from 'rc/DropDown'
import { useSelector } from 'react-redux'
import { Image } from 'components'
const AccountOptions = ({ menuOptions = [] }) => {
  const { photo, fullname } = useSelector(
    ({ profileReducer: { photo, fullname } }) => ({
      photo,
      fullname
    })
  )

  const renderProfileImage = isPrincipal => {
    return (
      <AccountContainer isPrincipal={isPrincipal} hasBg={!photo}>
        {photo ? (
          <Image id={photo} />
        ) : fullname ? (
          <Typography component="span" variant="h4" color="inherit">
            {fullname
              .split(' ')
              .map((item, index) => {
                if (index >= 2) {
                  return ''
                }
                return item.charAt(0)
              })
              .join('')}
          </Typography>
        ) : (
          ''
        )}
      </AccountContainer>
    )
  }

  const renderOptions = (
    { name, icon: Icon, show, to, onClick, isPrincipal },
    index
  ) => {
    return show ? (
      <MenuOption key={index} onClick={() => (onClick ? onClick() : to())}>
        {isPrincipal && renderProfileImage(true)}
        {Icon && <Icon />}
        <Typography variant="subtitle2" color="palette.primary.main">
          {name}
        </Typography>
      </MenuOption>
    ) : (
      ''
    )
  }

  return (
    <DropDown>
      <DropdownActionStyled>{renderProfileImage()}</DropdownActionStyled>
      <DropdownMenuStyled>
        <MenuContainer>
          {menuOptions && menuOptions.map(renderOptions)}
        </MenuContainer>
      </DropdownMenuStyled>
    </DropDown>
  )
}

AccountOptions.propTypes = {
  menuOptions: PropTypes.array
}

const AccountContainer = styled(Grid).attrs({
  container: true,
  inline: true,
  center: true
})`
  overflow: hidden;
  background-color: #fff;
  ${({ theme }) => theme.bordered('1', theme.palette.base.white)}
  margin: ${({ theme, isPrincipal }) =>
    isPrincipal ? theme.remCalc('3 8 0 -5') : theme.remCalc('0 17 0 -5')};
  cursor: pointer;
  ${({ theme }) => theme.box(42, 42, true)}
  min-width: 40px;
  min-height: 40px;
  ${({ hasBg }) =>
    hasBg &&
    'box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -2px rgba(0,0,0,.1);'}
`
const DropdownActionStyled = styled(DropdownAction)`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
`
const DropdownMenuStyled = styled(DropdownMenu)`
  width: unset;
  right: ${({ theme }) => theme.remCalc('15')};
  margin-top: ${({ theme }) => theme.remCalc('10')};
  border-radius: ${({ theme }) => theme.remCalc('15')};
  overflow: hidden;
`
const MenuContainer = styled.div`
  width: ${({ theme }) => theme.remCalc('250')};
  min-height: ${({ theme }) => theme.remCalc('50')};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`
const MenuOption = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  max-height: 60px;
  box-sizing: border-box;
  cursor: pointer;
  padding: ${({ theme }) => theme.remCalc('10 25')};
  border-radius: 40px;
  &:hover {
    background-color: #e9ebf1;
    transition: 0.5s;
  }
  svg {
    ${({ theme }) => theme.box(30, 30)}
    margin-right: ${({ theme }) => theme.remCalc('10')};
    color: ${({ theme }) => theme.palette.primary.main};
    fill: ${({ theme }) => theme.palette.primary.main};
  }
  h6 {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
`

export default AccountOptions
