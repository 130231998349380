var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _jsxFileName = 'src/lib/generics/FormInputViews/NumberInputView.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';

var NumberInput = (_temp = _class = function (_Component) {
  _inherits(NumberInput, _Component);

  function NumberInput(props) {
    _classCallCheck(this, NumberInput);

    var _this = _possibleConstructorReturn(this, (NumberInput.__proto__ || Object.getPrototypeOf(NumberInput)).call(this, props));

    _this.onChange = function (e) {
      var value = e.target.value;


      var regex = _this.getCorrectRegex();
      if (!regex) {
        _this.setState({
          value: value
        });
        return;
      }

      if (_this.props.max) {
        if (Number(value) > Number(_this.props.max)) {
          return;
        }
      }

      var deleteAlpha = value.replace(/[a-z,A-Z,\s]/g, '');
      var cleanValue = deleteAlpha.replace(/,/g, '');
      var newValue = cleanValue === '.' ? '0.' : cleanValue;

      var stringNewValue = String(newValue);
      var charactersBeforeDot = stringNewValue.substring(0, stringNewValue.indexOf('.') !== -1 ? stringNewValue.indexOf('.') : stringNewValue.length).length;
      if (_this.props.maxLength && charactersBeforeDot > _this.props.maxLength) {
        return;
      }

      if (_this.props.min && _this.props.min > Number(newValue) && newValue !== '') {
        return;
      }

      if (regex.test(newValue)) {
        if (!_this.props.noCommas) {
          newValue = _this.getNumberWithCommas(newValue);
        }

        _this.setState({
          value: newValue
        });

        var onChange = _this.props.onChange;

        if (onChange) {
          var numberWithoutCommas = newValue.replace(/,/g, '');
          var event = void 0;
          if (isNaN(numberWithoutCommas)) {
            event = { target: { value: numberWithoutCommas } };
            onChange(event);
            return;
          }
          event = { target: { value: numberWithoutCommas } };
          onChange(event);
        }
      }
    };

    _this.getNumberWithCommas = function (number) {
      var dotIndex = number.indexOf('.');
      var numberBeforeDot = number;
      var numberAfterDot = '';

      if (dotIndex > -1) {
        numberBeforeDot = number.substring(0, dotIndex);
        numberAfterDot = number.substring(dotIndex, number.length);
      }

      var div = numberBeforeDot.length / 3;
      var pieces = [];
      if (div > 0) {
        var index = 0;

        while (index < parseInt(div)) {
          if (index === div || numberBeforeDot.length / 3 < 1) {
            break;
          }
          var pieceOfNumber = numberBeforeDot.substring(numberBeforeDot.length - 3, numberBeforeDot.length);
          numberBeforeDot = numberBeforeDot.substring(0, numberBeforeDot.length - 3);
          pieces.push(pieceOfNumber);
          index++;
        }
      }

      return '' + (numberBeforeDot ? numberBeforeDot : '') + (numberBeforeDot && pieces.length > 0 ? ',' : '') + (pieces.length > 0 ? pieces.reverse().join(',') : '') + numberAfterDot;
    };

    _this.getCorrectRegex = function () {
      var decimalDigits = _this.props.decimalDigits;

      switch (true) {
        case decimalDigits === 0:
          return (/^(\s*|\d+)$/
          );

        case decimalDigits > 0:
          return new RegExp('^(\\s*|(\\d+\\.?\\d{0,' + decimalDigits + '}))$');

        default:
          break;
      }
    };

    _this.state = {
      value: _this.props.value !== undefined && _this.props.value !== null ? _this.props.noCommas ? _this.props.value : _this.getNumberWithCommas('' + _this.props.value) : ''
    };
    return _this;
  }

  _createClass(NumberInput, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          id = _props.id,
          placeholder = _props.placeholder,
          onFocus = _props.onFocus,
          _onBlur = _props.onBlur,
          disabled = _props.disabled,
          disabledTransparent = _props.disabledTransparent,
          onKeyPress = _props.onKeyPress,
          onKeyDown = _props.onKeyDown,
          onInputMounted = _props.onInputMounted;
      var value = this.state.value;


      return React.createElement('input', {
        className: className,
        type: 'text',
        onChange: this.onChange,
        value: value,
        id: id,
        placeholder: placeholder,
        onFocus: onFocus,
        onBlur: function onBlur() {
          _onBlur(undefined, true);
        },
        disabled: disabled || disabledTransparent,
        onKeyPress: onKeyPress,
        onKeyDown: onKeyDown,
        ref: onInputMounted,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 192
        },
        __self: this
      });
    }
  }]);

  return NumberInput;
}(Component), _class.propTypes = {
  /** */
  className: PropTypes.string,
  /** */
  decimalDigits: PropTypes.number,
  /** Disables the input */
  disabled: PropTypes.bool,
  /** */
  disabledTransparent: PropTypes.bool,
  /** Used for testing purposes */
  id: PropTypes.string,
  /** */
  max: PropTypes.number,
  /** */
  maxLength: PropTypes.number,
  /** */
  min: PropTypes.number,
  /** */
  noCommas: PropTypes.bool,
  /** Callback function to be called on onBlur event. */
  onBlur: PropTypes.func,
  /** Callback function to retrieve the current input value. */
  onChange: PropTypes.func,
  /** Callback function to be called on onFocus event. */
  onFocus: PropTypes.func,
  /** */
  onInputMounted: PropTypes.func,
  /** */
  onKeyDown: PropTypes.func,
  /** Handle key press callback */
  onKeyPress: PropTypes.func,
  /** Placeholder to set when no text entered */
  placeholder: PropTypes.string,
  /** Current value set in Input. */
  value: PropTypes.any
}, _class.defaultProps = {
  decimalDigits: 0
}, _temp);


export default NumberInput;