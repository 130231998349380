var _jsxFileName = 'src/lib/elements/Layouts/LayoutItem.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/**
 * Main functionality to be used inside a layout
 */
var LayoutItem = function LayoutItem(_ref) {
  var _classNames;

  var id = _ref.id,
      className = _ref.className,
      children = _ref.children,
      auto = _ref.auto,
      mediumAuto = _ref.mediumAuto,
      largeAuto = _ref.largeAuto,
      shrink = _ref.shrink,
      mediumShrink = _ref.mediumShrink,
      largeShrink = _ref.largeShrink,
      selfBottom = _ref.selfBottom,
      selfMiddle = _ref.selfMiddle,
      selfTop = _ref.selfTop,
      small = _ref.small,
      medium = _ref.medium,
      large = _ref.large,
      smOffset = _ref.smOffset,
      mdOffset = _ref.mdOffset,
      lgOffset = _ref.lgOffset,
      onClick = _ref.onClick,
      decorated = _ref.decorated,
      alert = _ref.alert,
      warning = _ref.warning,
      success = _ref.success,
      smallAuto = _ref.smallAuto,
      hideSmallOnly = _ref.hideSmallOnly,
      showSmallOnly = _ref.showSmallOnly,
      onMouseOver = _ref.onMouseOver,
      onMouseOut = _ref.onMouseOut;

  var layoutClasses = classNames((_classNames = {
    auto: auto,
    'small-auto': smallAuto,
    'medium-auto': mediumAuto,
    'large-auto': largeAuto,
    shrink: shrink,
    'medium-shrink': mediumShrink,
    'large-shrink': largeShrink,
    'align-self-bottom': selfBottom,
    'align-self-middle': selfMiddle,
    'align-self-top': selfTop
  }, _defineProperty(_classNames, 'small-' + small, small), _defineProperty(_classNames, 'medium-' + medium, medium), _defineProperty(_classNames, 'large-' + large, large), _defineProperty(_classNames, 'small-offset-' + smOffset, smOffset), _defineProperty(_classNames, 'medium-offset-' + mdOffset, mdOffset), _defineProperty(_classNames, 'large-offset-' + lgOffset, lgOffset), _defineProperty(_classNames, 'layout-item--decorated', decorated), _defineProperty(_classNames, 'layout-item--alert', alert), _defineProperty(_classNames, 'layout-item--warning', warning), _defineProperty(_classNames, 'layout-item--success', success), _defineProperty(_classNames, 'hide-for-small-only', hideSmallOnly), _defineProperty(_classNames, 'show-for-small-only', showSmallOnly), _classNames), 'cell', 'layout-item', className);
  return React.createElement(
    'div',
    {
      id: id,
      className: layoutClasses,
      onClick: onClick,
      onMouseOver: onMouseOver,
      onMouseOut: onMouseOut,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 67
      },
      __self: _this
    },
    children
  );
};

LayoutItem.defaultProps = {
  className: '',
  children: '',
  auto: false,
  shrink: false,
  selfBottom: false,
  selfMiddle: false,
  selfTop: false,
  onClick: function onClick() {},
  decorated: false,
  alert: false,
  warning: false,
  success: false
};

LayoutItem.propTypes = {
  /** Specifies if the layout item must be alert */
  alert: PropTypes.bool,
  /** Enlarge the columns automatically. */
  auto: PropTypes.bool,
  /** Elements to render when passed. */
  children: PropTypes.any,
  /** Pre-append classes passed, just in case. */
  className: PropTypes.string,
  /** Specifies if the layout item must be decorated */
  decorated: PropTypes.bool,
  hideSmallOnly: PropTypes.bool,
  /** Sets the ID of a component. */
  id: PropTypes.string,
  /** Colums of large device. */
  large: PropTypes.number,
  /** Enlarge the columns automatically in large device size. */
  largeAuto: PropTypes.bool,
  /** Shrinks the columns in large device automatically. */
  largeShrink: PropTypes.bool,
  /** Offseted columns in a large device. */
  lgOffset: PropTypes.number,
  /** Offseted columns in a medium device. */
  mdOffset: PropTypes.number,
  /** Colums of medium device. */
  medium: PropTypes.number,
  /** Enlarge the columns automatically in medium device size. */
  mediumAuto: PropTypes.bool,
  /** Shrinks the columns in medium device automatically. */
  mediumShrink: PropTypes.bool,
  /** Click event */
  onClick: PropTypes.func,
  /** */
  onMouseOut: PropTypes.func,
  /** */
  onMouseOver: PropTypes.func,
  /** Align this specific Element to Botom. */
  selfBottom: PropTypes.bool,
  /** Align this specific Element to Middle. */
  selfMiddle: PropTypes.bool,
  /** Align this specific Element to Top. */
  selfTop: PropTypes.bool,
  /** */
  showSmallOnly: PropTypes.bool,
  /** Shrinks the columns automatically. */
  shrink: PropTypes.bool,
  /** Colums of small device */
  small: PropTypes.number,
  /** Enlarge the columns automatically in medium device size. */
  smallAuto: PropTypes.bool,
  /** Offseted columns in a small device. */
  smOffset: PropTypes.number,
  /** Specifies if the layout item must be success */
  success: PropTypes.bool,
  /** Specifies if the layout item must be warning */
  warning: PropTypes.bool
};

export default LayoutItem;