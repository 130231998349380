import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Grid, Typography, Button } from 'rc'
import { useMedia } from 'rc/Hooks'
import { CheckmarkSquare } from 'rc/Icons'
import { LinkButton } from 'components'
import ProfileSectionCard from './_ProfileSectionCard'
import NotCompletedFields from './_NotCompletedFields'

// C O N S T A N S
import { PROFILE_SECTIONS } from 'utils/constants'

const ProgressContainerStyled = styled(Grid)`
  width: 95%;
  position: relative;
  background-color: ${({ theme }) => theme.palette.warning.light};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  margin: ${({ theme, profileSection }) =>
    profileSection ? theme.remCalc('5 0 15 0') : theme.remCalc('15 0 15 0')};
  ${({ theme }) => theme.radius(10)}
  ${({ theme, percentage }) => theme.media.tablet`
    width: ${({ profileSection }) => (profileSection ? '100%' : '70%')};
  `}
`

const CompleteProfileStyled = styled.div`
  background: ${({ theme, percentage }) =>
    percentage < 100
      ? theme.palette.text.grayLight
      : theme.palette.primary.dark};
  ${({ theme }) => theme.position('absolute', -18, -16, undefined)}
  ${({ theme }) => theme.box(54, 54, true)}
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  ${({ theme }) => theme.bordered('4', theme.palette.base.white)}
  cursor: ${({ percentage }) => (percentage < 100 ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
`

const WalkthroughContainerStyled = styled(Grid)`
  z-index: 3;
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.2);
  width: 95%;
  height: ${({ theme }) => theme.remCalc('330')};
  max-width: ${({ theme }) => theme.remCalc('350')};
  padding: ${({ theme }) => theme.remCalc('25')};
  background-color: ${({ theme }) => theme.palette.base.white};
  ${({ theme }) => theme.position('absolute', -64, 4)}

  ${({ theme }) => theme.radius(5)}
  ${({ walkthroughFixed }) =>
    walkthroughFixed && `box-shadow: 0 0 0 99999px rgba(0,0, 0, 0.5);`}
  ${({ theme }) => theme.media.desktop`
  ${({ theme }) => theme.position('absolute', -64, 20)}
  `}
`

const ProfileProgressBar = ({
  data,
  profileSection,
  theme: {
    devices: { tabletDown }
  }
}) => {
  const [walkthrough, setWalkthrough] = useState(false)
  const [walkthroughFixed, setWalkthroughFixed] = useState(false)
  const isTabletDown = useMedia(tabletDown)
  const { profile: profileValues = {}, isMyProfile } = data

  const getPercentage = () => {
    let currentPercentage = 0
    Object.keys(PROFILE_SECTIONS).forEach(key => {
      const currentSection = PROFILE_SECTIONS[key]

      const percentagePerField =
        currentSection.sectionValue / currentSection.fields.length

      currentPercentage = currentSection.fields.reduce(
        (previous, currentField) => {
          const currentValue = profileValues[currentField]
          let isFilled = false
          if (
            (Array.isArray(currentValue) && currentValue.length > 0) ||
            (!Array.isArray(currentValue) && currentValue)
          ) {
            isFilled = true
          }
          return isFilled ? percentagePerField + previous : previous
        },
        currentPercentage
      )
    })
    return currentPercentage
  }

  const percentage = getPercentage()

  return (isMyProfile && profileSection) ||
    (!profileSection && percentage < 100 && percentage > 0) ? (
    <Grid container center direction="column">
      <ProfileSectionCard
        css={`
          margin-top: ${({ theme }) => theme.remCalc('25')};
          overflow: inherit;
        `}
        profileSection={profileSection}
        containerBoxProps={{ flat: true }}
      >
        <Typography
          percentage={percentage}
          variant={isTabletDown && !profileSection ? 'body2' : 'subtitle1'}
          css={`
            max-width: 100%;
            margin-bottom: ${({ theme }) => theme.remCalc('10')};
            ${({ theme, percentage }) => theme.media.desktop`
              max-width: calc(100% - 70px);
              font-weight: ${
                Math.round(percentage) === 100 ? 'bold' : 'initial'
              };
            `}
          `}
        >
          {profileSection
            ? Math.round(percentage) === 100
              ? 'Información completa'
              : `Te queda ${
                  100 - Math.round(percentage)
                }% para completar tu perfil.`
            : 'Completa la información de tu perfil para dar una mejor imagen a tus clientes y a otros asesores.'}
        </Typography>
        <Grid
          container
          flat
          profileSection={profileSection}
          justify="space-between"
          css={`
            width: calc(
              100% -
                ${({ theme, profileSection }) =>
                  profileSection ? theme.remCalc('13') : '0'}
            );
          `}
        >
          <ProgressContainerStyled
            container
            flat
            profileSection={profileSection}
          >
            <div
              css={`
                height: ${({ theme }) => theme.remCalc('20')};
                width: ${percentage}%;
                ${({ theme }) => theme.radius(10)}
                ${({ theme }) =>
                  `background: linear-gradient(90deg, ${theme.palette.primary.light} 49.92%, ${theme.palette.primary.dark} 99.92%);`}
              `}
            ></div>
            <NotCompletedFields
              percentage={percentage}
              profile={profileValues}
              profileSection={profileSection}
            ></NotCompletedFields>
            <CompleteProfileStyled
              percentage={percentage}
              onClick={() => {
                if (percentage === 100) {
                  setWalkthrough(true)
                  setWalkthroughFixed(true)
                }
              }}
              onMouseEnter={() => {
                if (percentage === 100 && !walkthroughFixed && !isTabletDown)
                  setWalkthrough(true)
              }}
              onMouseLeave={() => {
                if (percentage === 100 && !walkthroughFixed && !isTabletDown)
                  setWalkthrough(false)
              }}
            >
              <CheckmarkSquare
                css={`
                  width: 60%;
                  fill: ${({ theme }) => theme.palette.base.white};
                `}
              />
            </CompleteProfileStyled>
          </ProgressContainerStyled>
          {!profileSection ? (
            <LinkButton
              id="profile-profileProgressBar-linkButton-goToCompleteProfile"
              color="palette.primary.main"
              href={'/profile'}
              css={`
                width: 100%;
                margin-top: ${({ theme }) => theme.remCalc('20')};
                ${({ theme, percentage }) => theme.media.tablet`
                  width: ${({ theme }) => theme.remCalc('250')};
                  margin-top: 0;
                `}
              `}
            >
              Ir a completar perfil
            </LinkButton>
          ) : null}
        </Grid>
      </ProfileSectionCard>
      {walkthrough && (
        <Fragment>
          {walkthroughFixed ? (
            <div
              css={`
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 2;
              `}
              onClick={() => {
                setWalkthrough(false)
                setWalkthroughFixed(false)
              }}
            />
          ) : null}
          <Grid
            css={`
              width: 95%;
              max-width: ${({ theme }) => theme.remCalc('1080')};
              position: relative;
            `}
          >
            <WalkthroughContainerStyled
              container
              item
              direction="column"
              justify="center"
              alignItems="space-around"
              walkthroughFixed={walkthroughFixed}
              onMouseEnter={() =>
                !walkthroughFixed && !isTabletDown ? setWalkthrough(true) : null
              }
              onMouseLeave={() =>
                !walkthroughFixed && !isTabletDown
                  ? setWalkthrough(false)
                  : null
              }
            >
              <Typography variant="h4">Información completa</Typography>
              <div
                css={`
                  background: ${({ theme }) => theme.palette.primary.main};
                  ${({ theme }) => theme.box(70, 70, true)}
                  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
                  ${({ theme }) =>
                    theme.bordered('7', theme.palette.base.white)}

                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <CheckmarkSquare
                  css={`
                    width: 60%;
                    fill: ${({ theme }) => theme.palette.base.white};
                  `}
                />
              </div>
              <Typography
                variant="body1"
                css={`
                  text-align: center;
                `}
              >
                Cuando tienes tu información completa proyectas unas imagen más
                confiable con asesores y con tus clientes.
              </Typography>
              <Button
                id="profile-profileDetail-certifiedMC-button-close"
                color="primary"
                onClick={() => {
                  setWalkthrough(false)
                  setWalkthroughFixed(false)
                }}
                css={`
                  width: 70%;
                `}
              >
                Entendido
              </Button>
            </WalkthroughContainerStyled>
          </Grid>
        </Fragment>
      )}
    </Grid>
  ) : null
}

ProfileProgressBar.propTypes = {
  data: PropTypes.object,
  profileSection: PropTypes.bool,
  theme: PropTypes.object
}

export default withTheme(ProfileProgressBar)
