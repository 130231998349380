import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 21.0003H9.78602V14.7148H6V21.0003ZM6.81949 15.5507H8.93786V20.1644H6.83588L6.81949 15.5507Z" fill="#A0A3BD"/>
      <path d="M10.5566 21.0002H14.3427V11.8711H10.5566V21.0002ZM11.3761 12.7029H13.4945V20.1643H11.3925L11.3761 12.7029Z" fill="#A0A3BD"/>
      <path d="M15.1133 20.9997H18.8993V9.11719H15.1133V20.9997ZM15.9328 9.93667H18.0511V20.1802H15.9451L15.9328 9.93667Z" fill="#A0A3BD"/>
      <path d="M6.82264 11.158L10.33 7.57273L12.4361 9.70339L17.6726 4.4259V5.39699H18.5044V3H16.0992V3.83178H17.0867L12.4361 8.51924L10.3259 6.38038L6.22852 10.5761L6.82264 11.158Z" fill="#A0A3BD"/>
    </svg>
  </Fragment>,
  'BusinnessChart',
  '0 0 24 24'
)
