var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/Previewers/YouTubePreviewer.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout } from '../Layouts';
import { YouTube, Empty } from '../../generics/Previewers';

var YouTubePreviewer = (_temp2 = _class = function (_Component) {
  _inherits(YouTubePreviewer, _Component);

  function YouTubePreviewer() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, YouTubePreviewer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = YouTubePreviewer.__proto__ || Object.getPrototypeOf(YouTubePreviewer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      valid: true,
      videoId: null,
      url: null
    }, _this.getYoutubeVideoId = function (url) {
      if (url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
          return _this.setState({ videoId: match[2], valid: true });
        } else {
          return _this.setState({ valid: false });
        }
      } else {
        return _this.setState({ valid: false });
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(YouTubePreviewer, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var url = this.props.url;

      if (url) {
        this.setState({ url: url });
        this.getYoutubeVideoId(url);
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      if (this.props.url !== prevProps.url) {
        this.getYoutubeVideoId(this.props.url);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          height = _props.height,
          width = _props.width,
          relatedVideo = _props.relatedVideo,
          controls = _props.controls,
          url = _props.url;
      var _state = this.state,
          valid = _state.valid,
          videoId = _state.videoId;


      return React.createElement(
        Layout,
        { shrink: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 60
          },
          __self: this
        },
        url ? valid ? React.createElement(YouTube, {
          height: height,
          width: width,
          relatedVideo: relatedVideo,
          controls: controls,
          videoId: videoId,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 63
          },
          __self: this
        }) : React.createElement(Empty, {
          height: height,
          width: width,
          urlImage: 'https://s3-us-west-2.amazonaws.com/icons-forms/Previewers/fill-1%403x.png',
          label: 'TU VIDEO NO TIENE EL FORMATO CORRECTO',
          broken: true,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 71
          },
          __self: this
        }) : React.createElement(Empty, {
          height: height,
          width: width,
          urlImage: 'https://s3-us-west-2.amazonaws.com/icons-forms/YouTube-icon.png',
          label: 'NO HAY VIDEO ADJUNTO',
          __source: {
            fileName: _jsxFileName,
            lineNumber: 80
          },
          __self: this
        })
      );
    }
  }]);

  return YouTubePreviewer;
}(Component), _class.propTypes = {
  /** */
  controls: PropTypes.bool,
  /** */
  height: PropTypes.string,
  /** */
  relatedVideo: PropTypes.bool,
  /** */
  url: PropTypes.string,
  /** */
  value: PropTypes.any,
  /** */
  width: PropTypes.string
}, _temp2);


export default YouTubePreviewer;