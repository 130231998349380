var _jsxFileName = 'src/lib/elements/Texts/Label.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
var Label = function Label(_ref) {
  var text = _ref.children,
      htmlFor = _ref.htmlFor,
      className = _ref.className,
      important = _ref.important,
      inline = _ref.inline,
      right = _ref.right,
      center = _ref.center;

  var labelClasses = classNames(className, 'wig-label', {
    'wig-label--important': important,
    'wig-label-inline': inline,
    'wig-label--normal': !important,
    'wig-label--right': right,
    'wig-label--center': center
  });
  return React.createElement(
    'label',
    { className: labelClasses, htmlFor: htmlFor, __source: {
        fileName: _jsxFileName,
        lineNumber: 21
      },
      __self: _this
    },
    text
  );
};

Label.propTypes = {
  /** */
  center: PropTypes.bool,
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  htmlFor: PropTypes.string,
  /** */
  important: PropTypes.bool,
  /** */
  inline: PropTypes.bool,
  /** */
  right: PropTypes.bool
};

export default Label;