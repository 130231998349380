import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography, Button } from 'rc'
import Spinner from 'rc/Spinner'
import ScrollBottomHandler from 'rc/ScrollBottomHandler'
import Grid from 'rc/Grid'
import { Debounce } from 'rc/helpers/globalHelpers'

const SpinnerStyled = styled(Spinner)`
  margin-top: ${({ theme }) => theme.remCalc('30')};
`

const ListContentContainerStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
`

const ListBodyContainerStyled = styled(ScrollBottomHandler)`
  width: 100%;
  display: block;
  flex: 1;
  overflow-y: auto;
  padding: ${({ theme }) => theme.remCalc('20 15')};
  box-shadow: 0px -3px 10px rgba(151, 164, 177, 0.25);
  > div:last-child {
    margin: 0;
  }
`

const EmptyStateTitleStyled = styled(Typography).attrs({
  variant: 'h4'
})`
  padding-top: ${({ theme }) => theme.remCalc('20')};
  text-align: center;
`

const EmptyStateSubtitleStyled = styled(Typography).attrs({
  variant: 'subtitle4'
})`
  text-align: center;
`

const EmptyStateSubtitleButton = styled(Button).attrs({
  variant: 'standard'
})`
  display: inline-block;
  margin-top: 20px;
  text-align: center;
`

const EmptyContainerStyled = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const RowLoadingContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const FetchMoreLabelStyled = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.remCalc('5')};
  width: 100%;
  text-align: center;
  ${({ theme }) =>
    theme.typography.buildTextVariant({
      fontSize: 14,
      color: '#0077E2'
    })}
`

class List extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    elementComponent: PropTypes.func.isRequired,
    fetchFunction: PropTypes.func.isRequired,
    batchComponent: PropTypes.func,
    bodyContainerStyle: PropTypes.any,
    className: PropTypes.string,
    filterComponent: PropTypes.func,
    resolveResponse: PropTypes.func,
    renderExtraContent: PropTypes.func
  }

  static defaultProps = {
    resolveResponse: ({ payload: { data } }) => data,
    config: {
      noResults: null,
      filters: null
    }
  }

  initializeParams = () => {
    const {
      config: { filters }
    } = this.props

    let params = {}

    filters &&
      filters.forEach(item => {
        params[item.name] = item.initialValue ? item.initialValue : null
      })

    return params
  }

  state = {
    checkedList: [],
    dataList: [],
    status: null,
    params: this.initializeParams(),
    fetchAction: null,
    unlock: null,
    page: 0,
    pages: 0
  }

  componentDidMount() {
    this.fetchDataList('INIT')
  }

  debounceRequestSearch = newParams => {
    this.setState(
      prevState => ({
        dataList: [],
        checkedList: [],
        status: 'LOADING',
        fetchAction: 'SEARCH',
        page: 0,
        pages: 0,
        params: { ...prevState.params, ...newParams }
      }),
      () => this.debounceFetchDataList('SEARCH', this.state.unlock)
    )
  }

  requestSearch = newParams => {
    this.setState(
      prevState => ({
        dataList: [],
        checkedList: [],
        status: 'LOADING',
        fetchAction: 'SEARCH',
        page: 0,
        pages: 0,
        params: { ...prevState.params, ...newParams }
      }),
      () => this.fetchDataList('SEARCH', this.state.unlock)
    )
  }

  requestNextPage = unlock => {
    const { page, pages, status } = this.state

    if (page < pages && status !== 'FETCH_ERROR') {
      this.fetchDataList('NEXT_PAGE', unlock)
    } else {
      this.setState({ status: 'FINISH_REACHED', unlock })
    }
  }

  fetchDataList = (fetchAction, unlock) => {
    const { fetchFunction, resolveResponse } = this.props
    const { params, page: actualPage } = this.state

    this.setState(
      {
        fetchAction,
        status: 'LOADING'
      },
      () => {
        fetchFunction({ params, page: actualPage })
          .then(res => {
            const { data, page, pages } = resolveResponse(res)

            const dataList = data.map((item, _index) => ({
              ...item,
              _checked: false,
              _index
            }))

            this.setState(
              {
                dataList: [...this.state.dataList, ...dataList],
                page: parseInt(page) + 1,
                pages: parseInt(pages),
                status: 'FETCH_SUCCESS'
              },
              unlock
            )
          })
          .catch(err => {
            this.setState({ status: 'FETCH_ERROR' }, unlock)
          })
      }
    )
  }

  debounceFetchDataList = Debounce(this.fetchDataList, 300)

  onUpdate = (action, record, index) => {
    switch (action) {
      case 'edit':
        this.setState(prevState => ({
          dataList: prevState.dataList.map((_item, _index) =>
            index === _index ? record : _item
          )
        }))
        break
      case 'update':
        this.setState(prevState => ({
          dataList: prevState.dataList.map((_item, _index) =>
            index === _index ? { ..._item, ...record } : _item
          )
        }))
        break
      case 'delete':
        this.setState(prevState => ({
          dataList: [
            ...prevState.dataList.slice(0, index),
            ...prevState.dataList.slice(index + 1)
          ]
        }))
        break
      default:
        this.setState(
          {
            status: 'LOADING',
            dataList: [],
            checkedList: [],
            page: 0,
            pages: 0
          },
          () => this.fetchDataList('RESET', this.state.unlock)
        )
        break
    }
  }

  renderEmptyList = () => {
    const { status, params, fetchAction } = this.state
    const { noResults } = this.props.config

    const data = noResults({
      filters: params,
      status,
      fetchAction
    }).filter(item => item !== null)

    return data.map((item, key) => {
      switch (item.type) {
        case 'image':
          return <img key={`splash-item-${key}`} alt="img" src={item.src} />
        case 'title':
          return (
            <EmptyStateTitleStyled key={`splash-item-${key}`}>
              {item.text}
            </EmptyStateTitleStyled>
          )
        case 'subtitle':
          return (
            <EmptyStateSubtitleStyled
              variant="body2"
              key={`splash-item-${key}`}
            >
              {item.text}
            </EmptyStateSubtitleStyled>
          )
        case 'button':
          return (
            <EmptyStateSubtitleButton
              id={item.id}
              key={`splash-item-${key}`}
              onClick={item.onClick}
            >
              {item.text}
            </EmptyStateSubtitleButton>
          )
        case 'html':
          return item.src
        default:
          return null
      }
    })
  }

  renderContent = () => {
    const { fetchAction, status } = this.state
    return fetchAction === null ||
      (fetchAction === 'INIT' && status === 'LOADING') ? (
      <Grid container center>
        <SpinnerStyled />
      </Grid>
    ) : (
      <Fragment>
        {this.renderHeader()}
        {this.renderBody()}
      </Fragment>
    )
  }

  renderHeader = () => {
    const {
      batchComponent,
      filterComponent,
      config: { filters }
    } = this.props
    const { checkedList, params } = this.state
    return checkedList.length > 0
      ? batchComponent &&
          batchComponent({
            checkedList
          })
      : filterComponent &&
          filterComponent({
            config: filters,
            params,
            debounceRequestSearch: this.debounceRequestSearch,
            requestSearch: this.requestSearch
          })
  }

  renderBody = () => {
    return (
      <ListBodyContainerStyled
        onNearEnd={this.requestNextPage}
        css={this.props.bodyContainerStyle}
      >
        {this.renderElement()}
      </ListBodyContainerStyled>
    )
  }

  renderContentElements = (params, dataList) => {
    const { elementComponent, renderExtraContent } = this.props
    const items = dataList.map((item, index) =>
      elementComponent({
        data: item,
        filters: params,
        onUpdate: this.onUpdate,
        dataListLength: dataList.length,
        index
      })
    )

    return renderExtraContent ? [renderExtraContent(items)] : items
  }

  renderElement = () => {
    const {
      elementComponent,
      config: { noResults }
    } = this.props
    const { dataList, status, fetchAction, params } = this.state

    return status !== 'FETCH_ERROR' ? (
      status === 'LOADING' &&
      (fetchAction === 'SEARCH' || fetchAction === 'INIT') ? (
        <EmptyContainerStyled>
          <SpinnerStyled />
        </EmptyContainerStyled>
      ) : elementComponent && dataList.length > 0 ? (
        [
          ...this.renderContentElements(params, dataList),
          status === 'LOADING' ? (
            <RowLoadingContainerStyled>
              <SpinnerStyled />
            </RowLoadingContainerStyled>
          ) : status === 'FETCH_SUCCESS' ? (
            <FetchMoreLabelStyled
              key="more"
              onClick={() => this.requestNextPage()}
            >
              Ver más
            </FetchMoreLabelStyled>
          ) : null
        ]
      ) : noResults && status !== 'LOADING' ? (
        <EmptyContainerStyled>{this.renderEmptyList()}</EmptyContainerStyled>
      ) : (
        <EmptyContainerStyled>
          <SpinnerStyled />
        </EmptyContainerStyled>
      )
    ) : null
  }

  render() {
    return (
      <ListContentContainerStyled className={this.props.className}>
        {this.renderContent()}
      </ListContentContainerStyled>
    )
  }
}

export default List
