import {
  getPathPortal,
  formatPrice
  // FormatPriceRange
} from 'utils/helpers'
import { PROPERTY_TYPES } from 'utils/constants'

export const renderTitle = (titleValue, maxLength = 30) => {
  let cutTitle = titleValue

  if (cutTitle && cutTitle.length > maxLength) {
    cutTitle = `${titleValue.slice(0, maxLength)}...`
  }

  if (!cutTitle) cutTitle = '_'

  return cutTitle
}

export const buildTitle = ({
  propertyType,
  saleActive,
  presaleActive,
  leaseActive
}) => {
  const operation = [
    saleActive && 'Venta',
    presaleActive && 'Preventa',
    leaseActive && 'Renta'
  ].filter(op => op)

  return renderTitle(
    `${PROPERTY_TYPES[propertyType].name} en ${operation.join(' y ')}`
  )
}

export const getFormattedMinPrice = prices => {
  return `$${formatPrice(Math.min(...prices))}`
}

export const getFormattedMinCommission = (commission, type) => {
  const prefix = '| Comisión '
  const prefixType = type === 'Renta' ? ' meses' : ' %'
  return `${prefix}${Math.min(...commission)}${prefixType}`
}

export const getFormattedPrices = ({
  presaleActive,
  saleActive,
  isDevelopment,
  presalePrice,
  presaleSharedCommission,
  salePrice,
  saleSharedCommission,
  presaleCurrency,
  saleCurrency,
  leaseActive,
  leasePrice,
  leaseSharedCommission,
  leaseCurrency
}) => {
  const prices = []
  const validateSinglePrice = (label, allPrices, allCommissions, currency) =>
    prices.push({
      label,
      price: getFormattedMinPrice(allPrices),
      commission: getFormattedMinCommission(...allCommissions),
      currency
    })
  if (presaleActive || saleActive) {
    let titlePrices = []
    let allPrices = []
    let allCommissions = []

    const validateOperationPrices = (operationName, price, commission) => {
      const fPrice = isDevelopment ? price : [price]
      titlePrices.push(operationName)
      allPrices = fPrice
      allCommissions = [[commission ? commission : 0]]
    }

    if (presaleActive) {
      validateOperationPrices('Preventa', presalePrice, presaleSharedCommission)
    }

    if (saleActive) {
      validateOperationPrices('Venta', salePrice, saleSharedCommission)
    }
    validateSinglePrice(
      titlePrices.join('/'),
      allPrices,
      allCommissions,
      presaleCurrency ? presaleCurrency : saleCurrency
    )
  }

  if (leaseActive) {
    const leasePrices = isDevelopment ? leasePrice : [leasePrice]
    validateSinglePrice(
      'Renta',
      leasePrices,
      [[leaseSharedCommission ? leaseSharedCommission : 0], 'Renta'],
      leaseCurrency
    )
  }

  return prices
}

export const goToDetail = (developmentId, modelDevelopmentId, propertyId) => {
  const isModelDevelopment = modelDevelopmentId === propertyId
  const isDevelopment = developmentId && !modelDevelopmentId

  let url = `${getPathPortal}${
    isDevelopment
      ? `development/${developmentId}`
      : isModelDevelopment
      ? `development/${developmentId}/model/${modelDevelopmentId}`
      : `property/${propertyId}?view=new`
  }`

  return window.open(url, '_blank')
}
