import React from 'react'
import { any, func } from 'prop-types'
import styled from 'styled-components'
import { Button, SubmitButton } from 'components'
import { Thunderbolt } from 'rc/Icons'

const ThunderboltStyled = styled(Thunderbolt)`
  color: ${({ primary, theme }) =>
    primary ? '#ffffff' : theme.palette.prime.yellow} !important;
  height: ${({ theme }) => theme.remCalc('20')};
  margin-left: ${({ theme }) => theme.remCalc('5')};
`

export function PrimeButton({ children, ...props }) {
  return (
    <Button type="prime" {...props}>
      <ThunderboltStyled />
      {children}
    </Button>
  )
}

PrimeButton.propTypes = {
  children: any
}

export function PrimeSubmitButton({ children, ...props }) {
  return (
    <SubmitButton type="prime" {...props}>
      {children}
      <ThunderboltStyled />
    </SubmitButton>
  )
}

PrimeSubmitButton.propTypes = {
  children: any,
  onClick: func
}
