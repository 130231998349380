/* eslint-disable react/display-name */
import React from 'react'
import styled from 'styled-components'
import { Typography } from 'rc'
import { Image } from 'c3-ui/generics/Images'
import { PLATFORM_TYPES } from 'utils/constants'
import { Grid } from 'rc'

import { Cross, Clock, ThumbsUp, ThumbsDown, PropertyNotFound } from 'rc/Icons'

import {
  PropertyMatch,
  Success,
  Unlinked,
  WiggotPrimeNotificationLogo,
  PropertySent,
  PointLead
} from 'components/Icons'
import { titleGenerator } from 'utils/task_helper'
import Pro from 'static/logos/pro.svg'

const Unapproved = styled(Cross)`
  fill: #ffffff;
  ${({ theme }) => theme.box(40, 40)}
`
const Pending = styled(Clock)`
  fill: #ffffff;
  ${({ theme }) => theme.box(35, 35)}
`

const ThumbsUpStyled = styled(ThumbsUp)`
  fill: white;
  width: ${({ theme }) => theme.remCalc('30')};
`

const ThumbsDownStyled = styled(ThumbsDown)`
  fill: white;
  width: ${({ theme }) => theme.remCalc('30')};
`
const PropertyNotFoundStyled = styled(PropertyNotFound)`
  fill: #ffffff;
  ${({ theme }) => theme.box(40, 40)}
`

const initials = name => {
  return name
    .split(' ')
    .map((item, index) => {
      if (index >= 2) {
        return ''
      }
      return item.charAt(0)
    })
    .join('')
}

const shortName = name => {
  const nameParse = (name && name.split(' ')) || []
  return nameParse.length > 1 ? `${nameParse[0]} ${nameParse[1]}` : nameParse[0]
}

const goodSyntax = (condition, isTrue, isFalse) => {
  return `${condition ? isTrue : isFalse}`
}

export const notificationsTemplateInfo = (type, meta) => {
  switch (type) {
    case 'PENDING_PROPERTY':
      return {
        id: 'general-notifications-link-pendingProperty',
        badge: {
          content: () => (
            <PropertySent
              css={`
                width: ${({ theme }) => theme.remCalc('45')};
              `}
            />
          ),
          status: 'secondary'
        },
        description: `Tu asesor <b>${meta.broker_name}</b> te envió una propiedad para que la publiques.`,
        nav: {
          url: `/properties`,
          state: { 'properties_filter-f_table': 'PENDING_PROPERTIES' }
        }
      }
    case 'SHARED_PROPERTY':
      return {
        id: 'general-notifications-link-sharedProperty',
        badge: {
          status: !meta.photo ? 'secondary' : '',
          content: () =>
            meta.photo ? (
              <Image
                src={meta.photo}
                baseURL={process.env.REACT_APP_IMAGE_BASE_URL}
              />
            ) : (
              <Typography variant="h6" color="white">
                {initials(meta.fullname)}
              </Typography>
            )
        },
        nav: { url: `/property/${meta.property_id}` },
        description: `El asesor <b>${shortName(
          meta.fullname
        )}</b> compartió tu propiedad con un cliente.`
      }
    case 'PROPERTIES_MATCH':
      return {
        id: 'general-notifications-link-propertiesMatch',
        badge: {
          content: () => <PropertyMatch />
        },
        nav: {
          url: `/contacts/${meta.contact_id}/${meta.requirement_id}`,
          params: {
            suggestions: true
          }
        },
        description: `Encontramos <b>${goodSyntax(
          meta.count > 1,
          `${meta.count} propiedades`,
          `${meta.count} propiedad`
        )}</b> ${goodSyntax(
          meta.count > 1,
          `nuevas `,
          `nueva `
        )} para tu cliente ${shortName(meta.contact_name)}.`
      }
    case 'LIKE':
      return {
        id: 'general-notifications-link-likedProperty',
        badge: {
          status: () => (meta.liked ? 'success' : 'danger'),
          content: () =>
            meta.liked ? <ThumbsUpStyled /> : <ThumbsDownStyled />
        },
        nav: {
          url: meta.requirement_id
            ? `/contacts/${meta.contact_id}/${meta.requirement_id}/${
                meta.is_wiggot ? 'wg' : 'se'
              }/${meta.property_id}/shd`
            : `/contacts/${meta.contact_id}/detail/${
                meta.is_wiggot ? 'wg' : 'se'
              }/${meta.property_id}/snt`,
          params: {
            withoutRequirement: meta.requirement_id ? false : true,
            notification: true
          }
        },
        description: `A tu cliente <b>${shortName(meta.contact_name)}</b> ${
          meta.liked ? 'le gustó' : 'no le gustó'
        } <b>la propiedad</b> que le enviaste.`
      }
    case 'PROFILE_GRADED': {
      const { photo, name, agency_name } = meta
      return {
        id: 'general-notifications-link-profileGraded',
        badge: {
          status: !photo ? 'secondary' : '',
          content: () =>
            photo ? (
              <Image
                src={photo}
                baseURL={process.env.REACT_APP_IMAGE_BASE_URL}
              />
            ) : (
              <Typography variant="h6" color="white">
                {initials(name)}
              </Typography>
            )
        },
        nav: {
          url: '/profile'
        },
        description: `<b>${name}</b>${
          agency_name ? ` de <b>${agency_name}</b> ` : ' asesor independiente '
        }te ha calificado en tu perfil.`
      }
    }
    case 'CONNECT_PORTALS':
      return {
        id:
          meta.status === 'ENABLED'
            ? 'general-notifications-link-connectedToExternalPortal'
            : meta.status === 'PENDING'
            ? 'general-notifications-link-requestToConnectToExternalPortal'
            : 'general-notifications-link-failedConnectionToExternalPortal',
        badge: {
          status: () =>
            meta.status === 'ENABLED'
              ? ''
              : meta.status === 'PENDING'
              ? 'warning'
              : 'danger',
          content: () =>
            meta.status === 'ENABLED' ? (
              <Success />
            ) : meta.status === 'PENDING' ? (
              <Pending />
            ) : (
              <Unapproved />
            )
        },
        nav: { url: '/properties' },
        description:
          meta.status === 'ENABLED'
            ? `¡Tu cuenta <b>se conectó con ${
                PLATFORM_TYPES[meta.portal_id]
                  ? PLATFORM_TYPES[meta.portal_id].name
                  : ''
              }</b> exitosamente! Ahora puedes <b>enviar tus propiedades</b> desde Wiggot®`
            : meta.status === 'PENDING'
            ? `<b>Tu solicitud</b> para conectar tu cuenta con ${
                PLATFORM_TYPES[meta.portal_id]
                  ? PLATFORM_TYPES[meta.portal_id].name
                  : ''
              } <b>ya fue enviada.</b> Te avisaremos cuando este proceso finalice.`
            : `<b>No se pudo conectar</b> tu cuenta con ${
                PLATFORM_TYPES[meta.portal_id]
                  ? PLATFORM_TYPES[meta.portal_id].name
                  : ''
              }. Verifica los datos de tu cuenta e <b>inténtalo de nuevo.</b>`
      }
    case 'UNAVAILABLE_PROPERTY':
      return {
        id: 'general-notifications-link-unavailableProperty',
        badge: {
          status: 'danger',
          content: () => <PropertyNotFoundStyled />
        },
        nav: { url: `/property/${meta.property_id}` },
        description: `Una propiedad que compartiste con <b>${
          meta.contacts_count
        } ${goodSyntax(
          meta.count !== 1,
          `clientes `,
          `cliente `
        )}</b> ya no está disponible.`
      }
    case 'PORTAL-UNLINKED':
      return {
        id: 'general-notifications-link-portal-unlinked',
        badge: {
          status: 'danger',
          content: () => <Unlinked />
        },
        nav: { url: `/properties` },
        description: `Tu cuenta de <b>${meta.portal_name}</b> ha tenido una <b>desconexión</b> inesperada. Para volver a conectar tu cuenta ve al paso 5 de subir propiedad y conéctala.`
      }
    case 'WELCOME_TO_PRIME':
      return {
        id: 'general-notifications-notification-welcomeToPrime',
        badge: {
          status: 'black',
          content: () => <WiggotPrimeNotificationLogo />
        },
        description: `Bienvenido a Wiggot Prime. Ya puedes usar todas las herramientas.`
      }
    case 'WELCOME_BACK_TO_PRIME':
      return {
        id: 'general-notifications-notification-welcomeBackToPrime',
        badge: {
          status: 'black',
          content: () => <WiggotPrimeNotificationLogo />
        },
        description: `Bienvenido a Wiggot Prime de nuevo. Ya puedes usar todas las herramientas.`
      }
    case 'EXPIRED_PRIME':
      return {
        id: 'general-notifications-button-expiredPrime',
        badge: {
          status: 'black',
          content: () => <WiggotPrimeNotificationLogo />
        },
        description: `Tu cuenta Prime caducó, ponte en contacto con un asesor Wiggot para renovar.`
      }
    case 'PRIME_ABOUT_TO_EXPIRE':
      return {
        id: 'general-notifications-button-aboutToExpirePrime',
        badge: {
          status: 'black',
          content: () => <WiggotPrimeNotificationLogo />
        },
        description: `Tu cuenta prime está por caducar, ponte en contacto con un asesor Wiggot para renovar.`
      }
    case 'TRIAL_PRIME_ACTIVATED':
      return {
        id: 'general-notifications-notification-trialPrimeActivated',
        badge: {
          status: 'black',
          content: () => <WiggotPrimeNotificationLogo />
        },
        description: `Tu cuenta de prueba ha sido activada por 30 días, disfruta de Wiggot Prime.`
      }
    case 'THERE_ARE_PROPERTIES_TO_UPDATE':
      return {
        id: 'general-notifications-button-there-are-properties-to-update',
        badge: {
          status: 'black',
          content: () => <WiggotPrimeNotificationLogo />
        },
        description: `Tienes propiedades marcadas como "Sin actualizar". Renueva la información para restablecerlas.`
      }
    case 'THERE_ARE_PUBLIC_LISTS_TO_UPDATE':
      return {
        id: 'general-notifications-button-there-are-lists-to-update',
        badge: {
          status: 'black',
          content: () => <WiggotPrimeNotificationLogo />
        },
        description: `Tienes listas publicas marcadas como "Sin actividad". Recupera las listas marcadas si aun deseas conservarlas.`
      }
    case 'PROPERTY_TO_DELETE':
      return {
        id: 'general-notifications-notification-trialPrimeActivated',
        badge: {
          content: () => (
            <PropertySent
              css={`
                width: ${({ theme }) => theme.remCalc('45')};
              `}
            />
          ),
          status: 'secondary'
        },
        description: `Tu asesor <b>${meta.broker_name}</b> te envió una propiedad para que la elimines.`,
        nav: {
          url: `/properties`,
          state: {
            'properties_filter-f_table': 'PENDING_TO_DELETE_PROPERTIES'
          }
        }
      }
    case 'TASK_REMINDER': {
      return {
        id: 'general-notifications-link-taskReminder',
        badge: {
          status: 'success',
          content: () => (
            <Grid container inline center>
              <Clock
                css={`
                  color: ${({ theme }) => theme.palette.base.white};
                  fill: ${({ theme }) => theme.palette.base.white};
                  ${({ theme }) => theme.box(30, 30)}
                `}
              />
            </Grid>
          )
        },
        nav: {
          url: `/crm/contacts/${meta.contact_id}`
        },
        description: `Recordatorio de tarea: <a>${
          meta.task_type !== 'OTHER'
            ? titleGenerator(meta.task_type)
            : 'Tienes una tarea -'
        } ${meta.contact_name}</a>`
      }
    }
    case 'USER_SUBSCRIPTION_PAYMENT_FAILED':
      return {
        id: 'general-notifications-notification-userSubscriptionPaymentFailed',
        badge: {
          status: 'danger',
          content: () => <Unapproved />
        },
        description: `No se ha podido procesar el pago porque se ha rechazado la tarjeta. Revisa tu email para más información.`
      }
    case 'USER_PRIME_SUBSCRIPTION_PAYMENT_SUCCESS':
      return {
        id: 'general-notifications-notification-userSubscriptionPaymentSuccess',
        badge: {
          status: 'success',
          content: () => <Success />
        },
        description: `Tu pago ha sido registrado correctamente, sigue disfrutando de los beneficios de wiggot prime.`
      }
    case 'USER_PASS_SUBSCRIPTION_PAYMENT_SUCCESS':
      return {
        id: 'general-notifications-notification-userSubscriptionPaymentSuccess',
        badge: {
          status: 'success',
          content: () => <Success />
        },
        description: `Tu pago ha sido registrado correctamente, sigue disfrutando de los beneficios de wiggot pass.`
      }
    case 'AGENCY_SUBSCRIPTION_PAYMENT_FAILED':
      return {
        id: 'general-notifications-notification-agencySubscriptionPaymentFailed',
        badge: {
          status: 'danger',
          content: () => <Unapproved />
        },
        description: `No se ha podido procesar el pago prime para tu inmobiliaria porque se ha rechazado la tarjeta. Revisa tu email si tú realizaste el intento de compra para más información.`
      }
    case 'AGENCY_SUBSCRIPTION_PAYMENT_SUCCESS':
      return {
        id: 'general-notifications-notification-agencySubscriptionPaymentSuccess',
        badge: {
          status: 'success',
          content: () => <Success />
        },
        description: `Tu  pago prime para tu inmobiliaria ha sido registrado correctamente, sigue disfrutando de los beneficios de wiggot prime.`
      }
    case 'SINGLE_PAYMENT_SUCCESS':
      return {
        id: 'general-notifications-notification-userSubscriptionPaymentSuccess',
        badge: {
          status: 'success',
          content: () => <Success />
        },
        description: `Tu pago ha sido registrado correctamente, sigue disfrutando de los beneficios de wiggot prime.`,
        nav: {
          url: `/addons/monopolio`
        }
      }
    case 'SINGLE_PAYMENT_PAID':
      return {
        id: 'general-notifications-notification-userSubscriptionPaymentSuccess',
        badge: {
          status: 'success',
          content: () => <Success />
        },
        description: `Tu pago ha sido registrado correctamente, sigue disfrutando de los beneficios de wiggot prime.`,
        nav: {
          url: `/addons/monopolio`
        }
      }
    case 'WELCOME_TO_PRO':
      return {
        id: 'general-notifications-notification-userSubscriptionPaymentPRO',
        badge: {
          status: 'white',
          content: () => (
            <img
              src={Pro}
              css={`
                width: ${({ theme }) => theme.remCalc('45')};
              `}
              alt="Pro"
            />
          )
        },
        description: `Tu pago ha sido registrado correctamente, sigue disfrutando de los beneficios de wiggot prime.`,
        nav: {
          url: `/addons/monopolio`
        }
      }
    case 'ASSIGN_LEAD':
      return {
        id: 'general-notifications-notification-assignLead',
        badge: {
          status: 'success',
          content: () => <PointLead />
        }
      }
    case 'WIGGOT_LEAD':
      return {
        id: 'general-notifications-notification-wiggotLead',
        badge: {
          status: 'success',
          content: () => <PointLead />
        },
        description: meta.title,
        nav: {
          url: `/crm?leadId=${meta.lead_data.lead_id}&position=WIGGOT_LEADS`
        }
      }
    default:
      return {}
  }
}
