import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Typography } from 'rc'
import ChecklistCompletion from 'static/img/ChecklistCompletion.svg'
import { HideModal } from 'reduxActions/services'
import { useHistory } from 'react-router-dom'

const CompleteAgencyInfoMC = props => {
  const { HideModal } = props
  const history = useHistory()
  return (
    <ContainerStyled>
      <img
        src={ChecklistCompletion}
        alt="ChecklistCompletion"
        css={`height: ${({ theme }) => theme.remCalc('130')};`}
      />
      <Typography variant="h2" textTransform="none" align="center" css={`color: #0076FF;`}>
        Completa tus datos
      </Typography>
      <Typography variant="p">
        Para acceder a esta herramienta es necesario que ingreses todos los datos de tu inmobiliaria en el perfil.
        Una vez que hayas llenado el formulario, podrás crear tu sitio web y compartirlo con otros asesores y clientes.
      </Typography>
      <ButtonContainer>
        <ButtonStyled variant="outlined" color="gray" onClick={HideModal}>
          cancelar
        </ButtonStyled>
        <ButtonStyled onClick={() => history.push("/profile?scrollToAgency=true")}>
          Ir al perfil
        </ButtonStyled>
      </ButtonContainer>
    </ContainerStyled>
  )
}

CompleteAgencyInfoMC.propTypes = {
  HideModal: PropTypes.func
}

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.remCalc('30')};
  max-width: ${({ theme }) => theme.remCalc('500')};
  >:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.remCalc('20')};
  }
`

const ButtonContainer = styled.div`
  display:flex;
  width: 100%;
  justify-content: space-between;
`

const ButtonStyled = styled(Button)`
  width: 45%;
  border-radius: ${({ theme }) => theme.remCalc('30')} !important;
  height: ${({ theme }) => theme.remCalc('50')};
`
const mapDispatchToProps = {
  HideModal
}

export default connect(null, mapDispatchToProps)
  (CompleteAgencyInfoMC)
