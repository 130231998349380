import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M4.72488 1.60001C4.72488 1.04001 5.16488 0.600012 5.72488 0.600012H16.8049C17.3649 0.600012 17.8049 1.04001 17.8049 1.60001C17.8049 2.16001 17.3649 2.60001 16.8049 2.60001H5.72488C5.16488 2.60001 4.72488 2.16001 4.72488 1.60001ZM16.8049 7.00001H5.72488C5.16488 7.00001 4.72488 7.44001 4.72488 8.00001C4.72488 8.56001 5.16488 9.00001 5.72488 9.00001H16.8049C17.3649 9.00001 17.8049 8.56001 17.8049 8.00001C17.8049 7.44001 17.3649 7.00001 16.8049 7.00001ZM16.8049 13.4H5.72488C5.16488 13.4 4.72488 13.84 4.72488 14.4C4.72488 14.96 5.16488 15.4 5.72488 15.4H16.8049C17.3649 15.4 17.8049 14.96 17.8049 14.4C17.8049 13.84 17.3649 13.4 16.8049 13.4ZM1.40488 6.68002C0.624883 6.68002 0.00488281 7.32001 0.00488281 8.10001C0.00488281 8.88001 0.624883 9.52001 1.40488 9.52001C2.18488 9.52001 2.80488 8.88001 2.80488 8.10001C2.80488 7.32001 2.16488 6.68002 1.40488 6.68002ZM1.40488 0.200012C0.624883 0.200012 0.00488281 0.840007 0.00488281 1.62001C0.00488281 2.40001 0.624883 3.04 1.40488 3.04C2.18488 3.04 2.80488 2.40001 2.80488 1.62001C2.80488 0.840007 2.16488 0.200012 1.40488 0.200012ZM1.40488 12.96C0.624883 12.96 0.00488281 13.6 0.00488281 14.38C0.00488281 15.16 0.624883 15.8 1.40488 15.8C2.18488 15.8 2.80488 15.16 2.80488 14.38C2.80488 13.6 2.16488 12.96 1.40488 12.96Z"
      fill="currentColor"
    />
  </>,
  'List',
  '0 0 18 16'
)
