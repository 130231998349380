import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import { useHistory } from 'react-router'
import { lazy } from '@loadable/component'
import styled from 'styled-components'

import { RedirectRoute } from '../components'
import { WiggotSideMenu, NavBar } from 'modules/app'
import ErrorBoundary from '../components/ErrorBoundary'

import { useGetToken } from 'src/hooks/useGetToken'

const Profile = lazy(() => import('pages/Profile'))
const PropertyDetail = lazy(() => import('pages/PropertyDetail'))
const Search = lazy(() => import('pages/Searcher'))

function UserRoutes() {
  const { token, roles } = useGetToken()
  const { verified } = useSelector(
    ({ profileReducer: { verified } }) => ({
      verified
    })
  )
  const history = useHistory()

  useEffect(() => {
    if (!token) {
      history.push('/login')
    }
  }, [token, roles])

  const isUnverified = token && !roles.DEVELOPER_USER && verified === false
  const routesOptions = roles.DEVELOPER_USER
    ? DEVELOPER_ACCOUNT_ROUTES
    : roles.DEVELOPER && !roles.DEVELOPER_USER
    ? USER_ROUTES
    : REGULAR_ACCOUNT_ROUTES

  return (
    <ErrorBoundary shouldCheck>
      <WiggotSideMenu />
      <NavBar isUnverified={isUnverified} />
      <Content isUnverified={isUnverified} isActive={!!token}>
        <Switch>
          {routesOptions.map(({ path, component }) => (
            <RedirectRoute
              exact
              fallbackTo={'/login'}
              canAccessIf={!!token}
              key={path}
              path={path}
              component={component}
            />
          ))}
        </Switch>
      </Content>
    </ErrorBoundary>
  )
}

export default UserRoutes

const Content = styled.div`
  height: 100vh;
  width: 100%;
  padding-top: ${({ theme }) =>
    theme.remCalc(theme.navigationBarMobileHeight.toString())};
  ${({ theme }) => theme.media.desktop`
    padding-top: ${theme.remCalc(theme.navigationBarHeight.toString())};
    padding-left: ${({ theme }) => theme.remCalc('70')};
  `}
  ${({ theme }) => theme.media.tabletDown`
    padding-top: 70px;
  `}
`

const COMMON_ROUTES = [
  { component: Search, path: '/search' },
  { component: Search, path: '/search?searchDetail=true' },
  {
    component: lazy(() => import('pages/AuthorizeUserPlatform')),
    path: '/authorizePlatform/:platform'
  },
  {
    component: lazy(() => import('pages/DevelopmentDetail')),
    path: '/development/:developmentId'
  },
  {
    component: lazy(() => import('pages/DevelopmentDetail')),
    path: '/development/:developmentId/model/:modelId'
  },
  {
    component: lazy(() => import('pages/DevelopmentSearcher')),
    path: '/developmentsSearcher'
  }
]

export const REGULAR_ACCOUNT_ROUTES = [
  ...COMMON_ROUTES,
  { component: lazy(() => import('pages/Lists')), path: '/lists' },
  {
    component: lazy(() => import('pages/ManagePlatforms')),
    path: '/managePlatforms'
  },
  { component: Profile, path: '/profile' },
  { component: Profile, path: '/profile/:profileId' },
  { component: PropertyDetail, path: '/property/se/:propertyId' },
  { component: PropertyDetail, path: '/property/:propertyId' },
  {
    component: lazy(() => import('pages/AssociationProperties')),
    path: '/association/properties'
  },
  {
    component: lazy(() => import('pages/AssociationAgencies')),
    path: '/association/agencies'
  },
  {
    component: lazy(() => import('pages/AssociationAgencyBrokers')),
    path: '/association/agencyBrokers'
  },
  { component: lazy(() => import('pages/Account')), path: '/account' },
  { component: lazy(() => import('pages/NewAgency')), path: '/newAgency' },
  { component: lazy(() => import('pages/Reports')), path: '/Reports' },
  {
    component: lazy(() => import('pages/AddonMetricool')),
    path: '/addon/social'
  },
  //Deprecated soon
  { component: lazy(() => import('pages/AllPlans')), path: '/all-plans' },
  { component: lazy(() => import('pages/CRM')), path: '/crm' },
  {
    component: lazy(() => import('pages/CrmContactDetail')),
    path: '/crm/contacts/:contact_id'
  }
]
export const DEVELOPER_ACCOUNT_ROUTES = [
  ...COMMON_ROUTES,
  {
    component: lazy(() => import('pages/ManageDevelopmentsPlatforms')),
    path: '/manageDevelopmentsPlatforms'
  },
  {
    component: lazy(() => import('pages/Developments')),
    path: '/developments'
  },
  {
    component: lazy(() => import('pages/DevelopmentPromotions')),
    path: '/developmentsPromotions'
  },
  {
    component: lazy(() => import('pages/DevelopmentReports')),
    path: '/developments/reports'
  },
  { component: lazy(() => import('pages/LoginRol')), path: '/loginrol' },
  {
    component: lazy(() => import('pages/UploadModel')),
    path: '/development/:developmentId/uploadModel'
  },
  {
    component: lazy(() => import('pages/DevelopmentDashboard')),
    path: '/developmentDashboard'
  },
  {
    component: lazy(() => import('pages/DevelopmentAccount')),
    path: '/developmentAccount'
  },
  {
    component: lazy(() =>
      import('modules/development/forms/development/GeneralDevelopmentForm')
    ),
    path: '/uploadDevelopmentNew'
  },

  {
    component: lazy(() => import('pages/DevelopmentPromotionSite')),
    path: '/developmentsPromotions/site/:development_id'
  },
  {
    component: lazy(() => import('pages/DevelopmentUnitsModifications')),
    path: '/development/:developmentId/inventory_history'
  },
  {
    component: lazy(() => import('pages/CRM')),
    path: '/crm'
  },
  {
    component: lazy(() => import('pages/CrmContactDetail')),
    path: '/crm/contacts/:contact_id'
  }
]

function AllRoutes() {
  let mergedArrays = [...REGULAR_ACCOUNT_ROUTES]
  const developerPaths = DEVELOPER_ACCOUNT_ROUTES.map(item => {
    return item.path
  })
  const regularPaths = REGULAR_ACCOUNT_ROUTES.map(item => {
    return item.path
  })

  developerPaths.forEach((item, index) => {
    if (!regularPaths.includes(item))
      mergedArrays.push(DEVELOPER_ACCOUNT_ROUTES[index])
  })

  return mergedArrays
}

export const USER_ROUTES = AllRoutes()
