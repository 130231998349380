import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'

export default createSvgIcon(
  <Fragment>
    <path fill="#FFA000" d="M89,11.2L98.6,2c0.8-0.7,0.2-2-0.8-2H2.2C1.1,0,0.1,0.9,0.1,2v22h97.6c1,0,1.6-1.3,0.8-2L89,12.8
      C88.5,12.4,88.5,11.6,89,11.2z"/>
    <path fill="#F68500" fillRule="evenodd" clipRule="evenodd" d="M0.1,24l13,11.6V24H0.1z"/>
    <path fill="#FFFFFF" d="M17.3,16.4h2L15.6,8h-1.9l-3.7,8.4h2l0.7-1.8h3.9L17.3,16.4z M13.3,13.1l1.3-3.2l1.3,3.2H13.3z M24.2,9.9
      c-0.8,0-1.5,0.3-2.1,0.8V7.5h-1.9v8.9h1.9v-3.2c0-1.2,0.6-1.7,1.5-1.7c0.8,0,1.3,0.5,1.3,1.5v3.4h1.9v-3.7
      C26.9,10.7,25.8,9.9,24.2,9.9z M32,16.5c2.1,0,3.5-1.4,3.5-3.3S34,9.9,32,9.9s-3.6,1.4-3.6,3.3S29.9,16.5,32,16.5z M32,15
      c-0.9,0-1.7-0.7-1.7-1.8s0.7-1.8,1.7-1.8c0.9,0,1.6,0.7,1.6,1.8S32.9,15,32,15z M38.8,10.8V10H37v6.5h1.9v-3c0-1.2,0.7-1.8,1.7-1.8
      c0.1,0,0.3,0,0.4,0V9.9C40,9.9,39.3,10.2,38.8,10.8z M44.2,10.8V10h-1.8v6.5h1.9v-3c0-1.2,0.7-1.8,1.7-1.8c0.1,0,0.3,0,0.4,0V9.9
      C45.4,9.9,44.6,10.2,44.2,10.8z M50.3,9.9c-1,0-2.1,0.3-2.8,0.8l0.7,1.3c0.5-0.4,1.2-0.6,1.9-0.6c1,0,1.5,0.5,1.5,1.3h-1.5
      c-2,0-2.8,0.8-2.8,1.9c0,1.1,0.9,2,2.4,2c0.9,0,1.6-0.3,2-0.9v0.8h1.8v-3.7C53.4,10.8,52.3,9.9,50.3,9.9z M50.2,15.2
      c-0.7,0-1.1-0.3-1.1-0.8c0-0.4,0.3-0.8,1.2-0.8h1.3v0.7C51.3,15,50.8,15.2,50.2,15.2z M58.2,8v1.6h1.7v6.8h1.9V8H58.2z M67.1,16.6
      c2.1,0,3.6-1.6,3.6-4.3s-1.5-4.3-3.6-4.3c-2.1,0-3.6,1.6-3.6,4.3S65.1,16.6,67.1,16.6z M67.1,14.9c-1,0-1.6-0.8-1.6-2.7
      s0.7-2.7,1.6-2.7c1,0,1.6,0.8,1.6,2.7S68.1,14.9,67.1,14.9z M73.9,12.5c1.2,0,2.1-0.9,2.1-2.3s-0.9-2.3-2.1-2.3
      c-1.2,0-2.1,0.9-2.1,2.3S72.6,12.5,73.9,12.5z M73.1,16.4h1.4L80.3,8h-1.4L73.1,16.4z M73.9,11.6c-0.5,0-0.9-0.4-0.9-1.3
      c0-0.9,0.4-1.3,0.9-1.3c0.5,0,0.9,0.4,0.9,1.3C74.7,11.1,74.4,11.6,73.9,11.6z M79.6,16.5c1.2,0,2.1-0.9,2.1-2.3s-0.9-2.3-2.1-2.3
      c-1.2,0-2.1,0.9-2.1,2.3S78.4,16.5,79.6,16.5z M79.6,15.5c-0.5,0-0.9-0.4-0.9-1.3c0-0.9,0.4-1.3,0.9-1.3c0.5,0,0.9,0.4,0.9,1.3
      C80.4,15.1,80.1,15.5,79.6,15.5z"/>
  </Fragment>, 'DiscountFlagTen, 0 0 101 36'
)