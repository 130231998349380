import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Cropper from 'react-easy-crop'
import { Grid, Typography } from 'rc'
import { Button } from 'components'
import axios from 'axios'

const ZoomButtonStyled = styled(Button)`
  width: 40px;
  height: 40px;
  min-height: 40px;
  font-size: 28px;
  padding: 0;
  &:last-child {
    margin-left: ${({ theme }) => theme.remCalc('5')};
  }
`

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const CropImageMC = ({
  token,
  aspect,
  imageId,
  customFile,
  cropShape,
  onImageSelected,
  type
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const imageSource = `${process.env.REACT_APP_IMAGE_BASE_URL}/${imageId}`
  const fileInput = useRef()
  const [file, setFile] = useState(customFile)
  const [progress, setProgress] = useState(0)

  const [imageConfig, setImageConfig] = useState({
    image: imageSource,
    crop: { x: 0, y: 0 },
    aspect,
    zoom: 1
  })

  const [croppedArea, setCroppedArea] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  })

  const onChange = async e => {
    e.stopPropagation()
    e.preventDefault()
    const {
      target: { files = [] }
    } = e
    if (files.length > 0) {
      let file = files[0]
      let base = await toBase64(file)
      setFile(file)
      setImageConfig({ ...imageConfig, image: base })
    }
  }

  const onCropChange = crop => {
    setImageConfig({
      ...imageConfig,
      crop
    })
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }

  const onZoomChange = zoom => {
    setImageConfig({
      ...imageConfig,
      zoom
    })
  }

  const uploadImage = async () => {
    let url = `${process.env.REACT_APP_IMAGE_UPLOAD_URL}/${type}`

    if (!file) {
      url += `/${imageId}`
    }

    try {
      const response = await axios({
        headers: {
          Authorization: token
        },
        method: 'post',
        url,
        data: file,
        params: {
          ...croppedArea
        },
        onUploadProgress: progressEvent => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader(
                'x-decompressed-content-length'
              )

          if (totalLength !== null) {
            setProgress(Math.round((progressEvent.loaded * 100) / totalLength))
          }
        }
      })
      const {
        data: { id }
      } = response

      if (onImageSelected && id) {
        onImageSelected(id)
      }
    } catch (error) {
      /**
       *
       */
    }
    setIsUploading(false)
  }

  return (
    <div
      css={`
        width: ${({ theme }) => theme.remCalc('600')}
        max-width: 100%;
        padding: ${({ theme }) => theme.remCalc('30')};
      `}
    >
      <Typography
        variant="h2"
        color="theme.palette.primary.main"
        display="block"
        align="center"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        Carga una fotografía
      </Typography>
      <div
        css={`
          position: relative;
          height: ${({ theme }) => theme.remCalc('300')};
        `}
      >
        <Cropper
          cropShape={cropShape}
          restrictPosition={false}
          {...imageConfig}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
        />
      </div>
      <input
        ref={fileInput}
        type="file"
        accept="image/*"
        css={`
          display: none;
        `}
        onChange={onChange}
      />
      {progress ? (
        <div
          css={`
            transition: width linear 500ms;
            width: ${progress}%;
            height: 6px;
            background: ${({ theme }) => theme.palette.primary.main};
          `}
        />
      ) : (
        ''
      )}
      <Grid
        container
        justify="flex-end"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        <Grid inline container>
          <ZoomButtonStyled
            id="profile-cropImage-mc-button-zoomOut"
            disabled={isUploading}
            variant="outlined"
            onClick={() => onZoomChange(imageConfig.zoom - 0.04)}
          >
            -
          </ZoomButtonStyled>

          <ZoomButtonStyled
            id="profile-cropImage-mc-button-zoomIn"
            disabled={isUploading}
            variant="outlined"
            onClick={() => onZoomChange(imageConfig.zoom + 0.04)}
          >
            +
          </ZoomButtonStyled>
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-between"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        <Button
          rounded
          id="profile-cropImage-mc-button-changePhoto"
          width="large"
          variant="outlined"
          onClick={() =>
            fileInput && fileInput.current && fileInput.current.click()
          }
          disabled={isUploading}
          css={`
            min-width: ${({ theme }) => theme.remCalc('120')};
            width: ${({ theme }) => theme.remCalc('120')};
            ${({ theme }) => theme.media.desktop`
              min-width: ${({ theme }) => theme.remCalc('160')};
              width: auto;
              margin-right: ${theme.remCalc('5')};
            `}
          `}
        >
          Cambiar foto
        </Button>
        <Button
          rounded
          id="profile-cropImage-mc-button-saveChanges"
          width="large"
          onClick={() => {
            setIsUploading(true)
            uploadImage()
          }}
          disabled={isUploading}
          css={`
            min-width: ${({ theme }) => theme.remCalc('120')};
            ${({ theme }) => theme.media.desktop`
              min-width: ${({ theme }) => theme.remCalc('160')};
            `}
          `}
        >
          Guardar
        </Button>
      </Grid>
    </div>
  )
}

CropImageMC.defaultProps = {
  aspect: 1,
  type: 'photo'
}

CropImageMC.propTypes = {
  token: PropTypes.string,
  type: PropTypes.oneOf(['photo', 'avatar']),
  aspect: PropTypes.number,
  imageId: PropTypes.string,
  onImageSelected: PropTypes.func,
  cropShape: PropTypes.string,
  customFile: PropTypes.any
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CropImageMC)
