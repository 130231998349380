export const AmplitudeEvents = {
  // LOGIN
  LOGIN_VIEW: 'login_view',
  LOGIN_FORM_ACTION: 'loginForm_action',
  LOGIN_SEARCH_VIEW: 'loginSearch_view',
  LOGIN_ROLE_SELECT_VIEW: 'loginRoleSelect',
  LOGIN_FORGOT_PASSWORD_VIEW: 'loginForgotPassword_view',
  LOGIN_FORGOT_PASSWORD_ACTION: 'loginForgotPassword_action',
  LOGIN_SIGN_UP_VIEW: 'loginSignUp_view',
  LOGIN_SIGN_UP_STEP_2_VIEW: 'loginSignUpStep2_view',
  LOGIN_SIGN_UP_ACTION: 'loginSignUp_action',
  LOGIN_PROFILE_VIEW: 'loginProfile_view',
  // SEARCH
  SEARCH_ID_SEARCH_VIEW: 'idSearch_view',
  SEARCH_RESULTS_VIEW: 'results_view',
  SEARCH_HIDE_MAP: 'hideMap_selected',
  SEARCH_DETAIL_VIEW: 'detail_view',
  SEARCH_SHARE_VIEW: 'share_view',
  SEARCH_SHARE_ACTION: 'share_action',
  SEARCH_MAP_SCROLL: 'map_scroll',
  SEARCH_MAP_PIN: 'mapPin_selected',
  // DATASHEET
  DATASHEET_REPORT_PROPERTY_VIEW: 'reportProperty_view',
  DATASHEET_PDF_MENU_SELECTED: 'pdfMenu_selected',
  DATASHEET_PDF_CUSTOMIZED_VIEW: 'pdfCustomized_view',
  DATASHEET_PDF_PREVIEW: 'pdfPreview_view',
  DATASHEET_CUSTOMIZED_VIEW: 'datasheetCustomized_view',
  DATASHEET_COPY_LINK_SELECTED: 'datasheetCopyLink_selected',
  DATASHEET_SHARE_VIEW: 'datasheetShare_view',
  DATASHEET_START_CHAT_ACTION: 'datasheetStartChat_action',
  DATASHEET_VIEW_PHONE_ACTION: 'datasheetViewPhone_action',
  DATASHEET_VIEW_EMAIL_ACTION: 'datasheetViewEmail_action',
  DATASHEET_OPEN_WHATSAPP: 'datasheetOpenWhatsapp_action',
  // PROPERTIES
  PROPERTIES_HOME_VIEW: 'propertiesHome_view',
  PROPERTIES_NEW_PROPERTY_VIEW: 'propertiesNewProperty_view',
  PROPERTIES_UPLOAD_PROPERTY_VIEW: 'propertiesUploadProperty_view',
  PROPERTIES_MATCH_DETAIL_VIEW: 'propertiesMatchDetailView_view',
  PROPERTIES_AGENCY_ACTION: 'propertiesAgency_action',
  PROPERTIES_NON_UPDATE_ACTION: 'propertiesNonUpdate_action',
  PROPERTIES_PENDING_ACTION: 'propertiesPending_action',
  PROPERTIES_RECENTLY_REMOVED_ACTION: 'propertiesRecentlyRemoved_action',
  PROPERTIES_PROPERTY_DETAIL_ACTION: 'propertiesPropertyDetail_action',
  PROPERTIES_DATASHEET_ACTION: 'propertiesDatasheet_action',
  PROPERTIES_MATCHES_ACTION: 'propertiesMatches_action',
  PROPERTIES_OPORTUNITIES_ACTION: 'propertiesOportunities_action',
  PROPERTIES_PERFORMANCE_ACTION: 'propertiesPerformance_action',
  PROPERTIES_PERFORMANCE_VIEW: 'propertiesPerformance_view',
  PROPERTIES_PERFORMANCE_EDIT_VIEW: 'propertiesPerformanceEdit_view',
  PROPERTIES_PERFORMANCE_DELETE_ACTION: 'propertiesPerformanceDelete_action',
  PROPERTIES_PERFORMANCE_STORE_ACTION: 'propertiesPerformanceStore_action',
  PROPERTIES_PERFORMANCE_PUBLISH_ACTION: 'propertiesPerformancePublish_action',
  PROPERTIES_PERFORMANCE_UNPUBLISH_ACTION:
    'propertiesPerformanceUnpublish_action',
  PROPERTIES_PERFORMANCE_MATCHES_SELECTED:
    'propertiesPerformanceMatches_selected',
  PROPERTIES_PERFORMANCE_OPORTUNITIES_SELECTED:
    'propertiesPerformanceOportunities_selected',
  PROPERTIES_PROPERTY_FILTER_SELECTED: 'propertiesPropertyFilter_selected',
  PROPERTIES_PROPERTY_TYPE_FILTER_SELECTED:
    'propertiesPropertyTypeFilter_selected',
  // DEVELOPMENTS
  DEVELOPMENTS_SEARCH_HOME_VIEW: 'developmentsSearchHome_view',
  DEVELOPMENTS_SEARCH_DEVELOPMENT_DETAIL_VIEW:
    'developmentsSearchDevelopmentDetail_view',
  DEVELOPMENTS_MODEL_DETAIL_VIEW: 'developmentsModelDetail_view',
  DEVELOPMENTS_SEARCH_SHARE_VIEW: 'developmentsSearchShare_view',
  DEVELOPMENTS_DETAIL_SHARE_VIEW: 'developmentsDetailShare_view',
  DEVELOPMENTS_MODELS_DETAIL_SHARE_VIEW: 'developmentsModelsDetailShare_view',
  DEVELOPMENTS_SHARE_UNITS_VIEW: 'developmentsShareUnits_view',
  DEVELOPMENTS_DEVELOPMENT_UNITS_VIEW: 'developmentsDevelopmentUnits_view',
  DEVELOPMENTS_DEVELOPMENT_PUBLIC_DATASHEET_VIEW:
    'developmentsDevelopmentPublicDatasheet_view',
  //DEVELOPMENT INVENTORY
  DEVELOPMENTS_INVENTORY_HOME_VIEW: 'developmentsInventoryHome_view',
  DEVELOPMENTS_INVENTORY_NEW_DEVELOPMENT_VIEW:
    'developmentsInventoryNewDevelopment_view',
  DEVELOPMENTS_DEVELOPMENT_TABS_SELECTED:
    'developmentsDevelopmentTabs_selected',
  DEVELOPMENTS_EDIT_DEVELOPMENT_VIEW: 'developmentsEditDevelopment_view',
  DEVELOPMENTS_NEW_MODEL_VIEW: 'developmentsNewModel_view',
  DEVELOPMENT_EDIT_MODEL_VIEW: 'developmentEditModel_view',
  DEVELOPMENT_UNITS_COPY_LINK_SELECTED: 'developmentUnitsCopyLink_selected',
  DEVELOPMENT_UNITS_SHARE_LINK_ACTION: 'developmentUnitsShareLink_action',
  DEVELOPMENT_DOWNLOAD_TEMPLATE_ACTION: 'developmentDownloadTemplate_action',
  DEVELOPMENT_UNITS_UPLOAD_TEMPLATE_ACTION:
    'developmentUnitsUploadTemplate_action',
  DEVELOPMENT_UNITS_ACTIVATE_SELECTED: 'developmentUnitsActivate_selected',
  DEVELOPMENT_UNITS_UPDATE_PRICES_VIEW: 'developmentUnitsUpdatePrices_view',
  DEVELOPMENT_UNITS_SHOW_LOG_VIEW: 'developmentUnitsShowLog_view',
  DEVELOPMENT_UNITS_OPTION_ACTION: 'developmentUnitsOption_action', //'User clicks on the three dots of a unit card'
  DEVELOPMENT_PLANS_DOWNLOAD_TEMPLATE_ACTION:
    'developmentPlansDownloadTemplate_action',
  DEVELOPMENT_PLANS_UPLOAD_TEMPLATE_ACTION:
    'developmentPlansUploadTemplate_action',
  DEVELOPMENT_PLANS_ACTIVATE: 'developmentPlansActivate',
  DEVELOPMENT_COLLABORATORS_VIEW: 'developmentCollaborators_view',
  DEVELOPMENT_COLLABORATORS_ACTIONS: 'developmentCollaborators_actions',
  DEVELOPMENTS_INVENTORY_HOME_TABS_SELECTED:
    'developmentInventoryHomeTabs_selected',
  DEVELOPMENTS_INVENTORY_BUY_TOKENS_ACTIONS:
    'developmentInventoryBuyTokens_actions',
  DEVELOPMENTS_INVENTORY_TOKEN_INFORMATION_ACTION:
    'developmentInventoryTokenInformation_action',
  DEVELOPMENTS_INVENTORY_TOKEN_INFORMATION_VIEW:
    'developmentInventoryTokenInformation_view',
  // REQUIREMENTS
  REQUIREMENTS_HOME_VIEW: 'requirementsHome_view',
  REQUIREMENTS_NEW_VIEW: 'requirementsNew_view',
  REQUIREMENTS_FILTER_SELECTED: 'requirementsFilter_selected',
  REQUIREMENTS_REQUIREMENT_DETAIL_ACTION:
    'requirementsRequirementDetail_action',
  REQUIREMENTS_REQUIREMENT_ACTIVATE_SELECTED:
    'requirementsRequirementActivate_selected',
  REQUIREMENTS_REQUIREMENT_SEARCH_ACTION:
    'requirementsRequirementSearch_action',
  REQUIREMENTS_AGENCY_ACTION: 'requirementsAgency_action',
  REQUIREMENTS_REQUIREMENT_DETAIL_VIEW: 'requirementsRequirementDetail_view',
  REQUIREMENTS_REQUIREMENT_OPORTUNITIES_VIEW:
    'requirementsRequirementOportunities_view',
  REQUIREMENTS_REQUIREMENT_SHARED_VIEW: 'requirementsRequirementShared_view',
  REQUIREMENTS_REQUIREMENT_MATCHES_VIEW: 'requirementsRequirementMatches_view',
  // PUBLIC LISTS
  PUBLIC_LISTS_HOME_VIEW: 'publicListsHome_view',
  PUBLIC_LISTS_NEW_VIEW: 'publicListsNew_view',
  PUBLIC_LISTS_LIST_DETAIL_VIEW: 'publicListsListDetail_view',
  PUBLIC_LISTS_LIST_DETAIL_SHARE_VIEW: 'publicListsListDetailShare_view',
  PUBLIC_LISTS_LIST_DETAIL_SHARE_ACTION: 'publicListsListDetailShare_action'
}
