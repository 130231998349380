import React, { useContext, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Grid, Typography, Link, Switch } from 'rc'
import { StarRounded, Share, Engine, EyeSlash2 } from 'rc/Icons'
import { Sparkles } from 'components/Icons'
import { ProfileSectionHeader } from 'modules/profile/components'
import { ProfileContext } from 'modules/profile'
import { Image, Button } from 'components'
import { getArrayWithN } from 'rc/helpers/arrayHelpers'
import { getTimeDifference } from 'rc/helpers/miscellaneous'
import {
  ShareProfileMC,
  GradeAgencyBrokerMC,
  ProfileScoresSectionMC,
  GetToKnowProfileScoresMC
} from 'modules/profile/modalContent'
import { ShowModal } from 'reduxActions/services'
import { UpdateProfileConfig } from 'reduxActions/profile'
import { SCORES_SECTION_CONFIG } from 'utils/constants'
import { EventRx, EVENTS } from 'utils/event'
import FreemiumWrapper from 'modules/freemium/FreemiumWrapper'
import { RULES } from 'modules/freemium/utils/constants'

const ImageContainerStyled = styled.div`
  overflow: hidden;
  ${({ theme }) => theme.box(60, 60, true)}
  ${({ theme }) => theme.bordered('7', theme.palette.base.white)}
  display: inline-flex;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(54, 57, 70, 0.24);
  background-color: ${({ theme, photo }) =>
    !photo && theme.palette.primary.main};
  align-items: ${({ photo }) => !photo && 'center'};
  justify-content: ${({ photo }) => !photo && 'center'};
  cursor: ${({ isMyProfile }) => isMyProfile && 'pointer'};
`

const CardStyled = styled(Grid)`
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.2);
  width: 95%;
  max-width: ${({ theme }) => theme.remCalc('1080')};
  height: auto;
  padding: ${({ theme, flat }) => (flat ? 0 : theme.remCalc('30 15'))};
  ${({ theme }) => theme.radius(5)}
  margin-bottom: ${({ theme }) => theme.remCalc('40')};
  overflow: hidden;
  background-color: ${({ outlineHidden }) => (outlineHidden ? '#F2F2F2' : '')};
  ${({ theme }) => theme.media.desktop`
    padding: ${({ theme, flat }) => (flat ? 0 : theme.remCalc('30 50'))};  
  `}
`

const EngineButton = props => {
  return (
    <Button
      radius="100%"
      css={`
        ${({ theme }) => theme.box(30, 30)}
        min-height: 30px;
        padding: 0;
        ${({ theme }) => theme.media.desktop`
          padding: initial;
          min-height: 55px;
          ${({ theme }) => theme.box(55, 55)}
        `}
      `}
      {...props}
    >
      <Engine
        css={`
          ${({ theme }) => theme.box(20, 20)}

          ${({ theme }) => theme.media.desktop`
         ${({ theme }) => theme.box(29, 29)}
    `}
        `}
      />
    </Button>
  )
}

const shareLabel = 'Compartir tarjeta digital'
const ShareProfileButton = props => {
  return (
    <FreemiumWrapper
      section={RULES.PROFILE_SHARE}
      label={shareLabel}
      {...props}
    >
      <Button variant="outlined" {...props}>
        <Share
          css={`
            margin-right: ${({ theme }) => theme.remCalc('10')};
          `}
        ></Share>
        {shareLabel}
      </Button>
    </FreemiumWrapper>
  )
}
const ProfileScores = ({ ShowModal, UpdateProfileConfig, token, ...props }) => {
  const {
    profile: {
      profile_id: profileId,
      user_fullname: profileName,
      user_photo: profilePhoto,
      user_fullname,
      scores = [],
      user_scores_section_config: config
    } = {},
    isMyProfile
  } = useContext(ProfileContext)

  const showScoresConfigModal = params => {
    ShowModal(ProfileScoresSectionMC, {
      initialValues: {
        user_scores_section_config: config || 'HIDDEN'
      }
    })
  }

  const onlyScore =
    isMyProfile && config === SCORES_SECTION_CONFIG.SCORE_WITHOUT_COMMENT

  const activeScoresLabel = 'Activar mis calificaciones'

  return (!isMyProfile && config === SCORES_SECTION_CONFIG.SHOWN) ||
    isMyProfile ? (
    <CardStyled
      outlineHidden={isMyProfile && config === SCORES_SECTION_CONFIG.HIDDEN}
    >
      <Grid
        container
        css={`
          margin-bottom: 10px;
        `}
      >
        <Grid container inline alignItems="center" direction="column">
          <ProfileSectionHeader>
            {isMyProfile
              ? 'Mis calificaciones (asesores)'
              : `Calificaciones de ${user_fullname}`}
            <Typography
              component="span"
              variant="caption"
              fontWeight="bold"
              css={`
                color: #fe9131;
                margin-left: 3px;
              `}
            >
              Nuevo
              <Sparkles
                css={`
                  height: 18px;
                  width: 20px;
                  fill: #fe9131;
                  color: #fe9131;
                  ${({ theme }) => theme.media.tabletDown`
                    display: none;
                  `}
                `}
              ></Sparkles>
            </Typography>{' '}
          </ProfileSectionHeader>
          <Typography
            variant="caption2"
            fontWeight="medium"
            css={`
              display: block;
            `}
          >
            {isMyProfile
              ? 'Activa esta sección para mostrar tu calificación y poder calificar a otros asesores.'
              : ''}
          </Typography>
        </Grid>
        {isMyProfile ? (
          config === SCORES_SECTION_CONFIG.HIDDEN ? (
            <Fragment>
              <FreemiumWrapper
                section={RULES.PROFILE_RATINGS}
                custom
                css={`
                  margin-left: auto;
                  ${({ theme }) => theme.media.desktop`
                    display: none;
                  `}
                `}
              >
                <EngineButton
                  css={`
                    margin-left: auto;
                  `}
                  onClick={() => showScoresConfigModal()}
                />
              </FreemiumWrapper>
              <FreemiumWrapper
                section={RULES.PROFILE_RATINGS}
                label={activeScoresLabel}
                css={`
                  margin-left: auto;
                  ${({ theme }) => theme.media.desktopDown`
                    display: none;
                  `}
                `}
              >
                <Button
                  onClick={() => showScoresConfigModal()}
                  css={`
                    margin-left: auto;
                    ${({ theme }) => theme.media.desktopDown`
                    display: none;
                  `}
                  `}
                >
                  <Engine css="margin-right: 10px;"></Engine>
                  {activeScoresLabel}
                </Button>
              </FreemiumWrapper>
            </Fragment>
          ) : (
            <Grid
              container
              inline
              alignItems="center"
              css={`
                margin-left: auto;
                ${({ theme }) => theme.media.tabletDown`
                  margin-top: ${({ theme }) => theme.remCalc('10')};
                  
                `}
              `}
            >
              {scores.length > 0 && (
                <ShareProfileButton
                  css={`
                    width: ${({ theme }) => theme.remCalc('280')};
                    max-width: 100%;
                    margin-right: 10px;
                    ${({ theme }) => theme.media.desktopDown`
                      width: ${({ theme }) => theme.remCalc('220')};
                      padding: 0;
                      min-height: ${({ theme }) => theme.remCalc('30')};
                      font-size: ${({ theme }) => theme.remCalc('14')};
                    `}
                  `}
                  onClick={() =>
                    ShowModal(ShareProfileMC, {
                      type: 'agencyBroker'
                    })
                  }
                ></ShareProfileButton>
              )}
              <FreemiumWrapper
                section={RULES.PROFILE_RATINGS}
                label={activeScoresLabel}
                {...props}
              >
                <EngineButton
                  onClick={() => showScoresConfigModal()}
                ></EngineButton>
              </FreemiumWrapper>
            </Grid>
          )
        ) : (
          ''
        )}
      </Grid>
      {isMyProfile && config === SCORES_SECTION_CONFIG.HIDDEN ? (
        <Grid container justify="center" direction="column">
          <EyeSlash2
            css={`
              color: ${({ theme }) => theme.palette.base.black};
              ${({ theme }) => theme.box(50, 60)}
            `}
          ></EyeSlash2>
          <Typography
            variant="subtitle1"
            css={`
              margin-bottom: 35px;
            `}
          >
            Sección oculta
          </Typography>
          <Button
            variant="outlined"
            width="large"
            onClick={() => {
              ShowModal(GetToKnowProfileScoresMC)
            }}
          >
            Conocer más
          </Button>
        </Grid>
      ) : scores.length > 0 ? (
        <div>
          {isMyProfile && (
            <Grid
              container
              inline
              alignItems="center"
              css={`
                margin: ${({ theme }) => theme.remCalc('0 10 10 0')};
              `}
            >
              <Switch
                onChange={async (e, checked) => {
                  await UpdateProfileConfig(token, {
                    user_scores_section_config: checked
                      ? SCORES_SECTION_CONFIG.SHOWN
                      : SCORES_SECTION_CONFIG.SCORE_WITHOUT_COMMENT
                  })
                  EventRx.dispatch(EVENTS.RELOAD_PROFILE_VIEW)
                }}
                css={`
                  ${({ theme }) => theme.box(34, 16)}
                `}
                value={config === SCORES_SECTION_CONFIG.SHOWN}
              ></Switch>
              <Typography fontWeight="bold" variant="caption2">
                Comentarios{' '}
                {config === SCORES_SECTION_CONFIG.SHOWN
                  ? 'visibles'
                  : 'ocultos'}{' '}
                en tu perfil
              </Typography>
            </Grid>
          )}

          <div
            onlyScore={onlyScore}
            css={`
              user-select: none;
              width: 100%;
              overflow: auto;
              white-space: nowrap;
              padding: ${({ theme }) => theme.remCalc('20')};
              margin: ${({ theme }) => theme.remCalc('-20 -5 0 -5')};
              ${({ theme }) => theme.media.desktop`
              width: calc(100% + 70px);
              margin: ${({ theme }) => theme.remCalc('-20 0 0 -30')};
            `};
              opacity: ${({ onlyScore }) => (onlyScore ? 0.5 : 1)};
              position: relative;
            `}
          >
            {scores.map(
              ({ photo, score, comment, created_at, fullname, profile_id }) => {
                return (
                  <div
                    key={photo}
                    css={`
                      display: inline-block;
                      padding: ${({ theme }) => theme.remCalc('2 8')};
                    `}
                  >
                    <Grid
                      container
                      direction="column"
                      css={`
                        ${({ theme }) => theme.box(366, 190)}
                        background-color: ${({ theme }) =>
                          theme.palette.base.white};
                        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
                        ${({ theme }) => theme.radius(10)}
                        padding: ${({ theme }) => theme.remCalc('10 18 5 30')};
                      `}
                    >
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        <ImageContainerStyled
                          photo={photo}
                          onClick={() => {
                            if (isMyProfile)
                              window.open(`/profile/${profile_id}`, '_blank')
                          }}
                          isMyProfile={isMyProfile}
                        >
                          {photo ? (
                            <Image id={photo}></Image>
                          ) : (
                            <Typography
                              component="span"
                              variant="h2"
                              color="theme.palette.base.white"
                              css={`
                                text-transform: uppercase;
                              `}
                            >
                              {fullname
                                .split(' ')
                                .map((item, index) => {
                                  if (index >= 2) {
                                    return ''
                                  }
                                  return item.charAt(0)
                                })
                                .join('')}
                            </Typography>
                          )}
                        </ImageContainerStyled>
                        <Grid
                          inline
                          container
                          direction="column"
                          alignItems="center"
                        >
                          <div>
                            {getArrayWithN(5).map(({ value }) => (
                              <StarRounded
                                key={`${photo}${value}`}
                                score={score}
                                value={value}
                                css={`
                                  ${({ theme }) => theme.box(20, 20)}
                                  margin: ${({ theme }) =>
                                    theme.remCalc('0 5')};
                                  color: ${({ theme, score, value }) =>
                                    value <= score
                                      ? theme.palette.warning.main
                                      : theme.palette.base.grayMedium};
                                  fill: ${({ theme, score, value }) =>
                                    value <= score
                                      ? theme.palette.warning.main
                                      : theme.palette.base.grayMedium};
                                `}
                              ></StarRounded>
                            ))}
                          </div>
                          <Typography
                            variant="body3"
                            color="theme.palette.gray.main"
                            fontWeight="bold"
                            align="right"
                          >
                            {getTimeDifference(created_at)}
                          </Typography>
                        </Grid>
                      </Grid>
                      {isMyProfile ? (
                        <Link
                          as={RouterLink}
                          to={`/profile/${profile_id}`}
                          target="_blank"
                          css={`
                            text-decoration: underline;
                            margin-top: ${({ theme }) => theme.remCalc('5')};
                          `}
                        >
                          {fullname}
                        </Link>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          color="theme.palette.gray.dark"
                          css={`
                            margin-top: ${({ theme }) => theme.remCalc('5')};
                          `}
                        >
                          {fullname}
                        </Typography>
                      )}

                      <Typography
                        variant="body2"
                        css={`
                          margin-top: ${({ theme }) => theme.remCalc('10')};
                          white-space: normal;
                        `}
                      >
                        {comment}
                      </Typography>
                    </Grid>
                  </div>
                )
              }
            )}
          </div>
        </div>
      ) : (
        <Grid container direction="column" alignItems="center">
          <Grid container center>
            {getArrayWithN(5).map(({ value }) => {
              return (
                <StarRounded
                  key={value}
                  css={`
                    ${({ theme }) => theme.box(30, 30)}
                    color: #C7CED7;
                    margin: ${({ theme }) => theme.remCalc('0 5')};
                  `}
                ></StarRounded>
              )
            })}
          </Grid>
          <Typography
            variant="body1"
            color="theme.palette.base.black"
            align="center"
            css={`
              margin: 10px auto 0;
              width: ${({ theme }) => theme.remCalc('570')};
              max-width: 100%;
            `}
          >
            {isMyProfile
              ? 'Aún no tienes calificaciones, comparte tu tarjeta digital con otros asesores para que te califiquen.'
              : 'No hay calificaciones aún, si conoces a este asesor o han hecho negocios juntos califícalo.'}
          </Typography>

          {isMyProfile ? (
            <ShareProfileButton
              css={`
                width: ${({ theme }) => theme.remCalc('280')};
                max-width: 100%;
                margin: 30px auto 0;
              `}
              onClick={() =>
                ShowModal(ShareProfileMC, {
                  type: 'agencyBroker'
                })
              }
            ></ShareProfileButton>
          ) : (
            <Button
              css={`
                width: ${({ theme }) => theme.remCalc('280')};
                max-width: 100%;
                margin: 30px auto 0;
              `}
              onClick={() =>
                ShowModal(GradeAgencyBrokerMC, {
                  profileId,
                  profileName,
                  profilePhoto
                })
              }
            >
              Calificar asesor
            </Button>
          )}
        </Grid>
      )}
    </CardStyled>
  ) : null
}

ProfileScores.propTypes = {
  ShowModal: PropTypes.func,
  UpdateProfileConfig: PropTypes.func,
  token: PropTypes.string
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  ShowModal,
  UpdateProfileConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScores)
