/* eslint-disable react/display-name */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Grid } from 'rc'
import { withBreadcrumbs } from 'rc/Hocs/withBreadcrumbs'

import { ChevronRight } from 'rc/Icons'

const GridStyled = styled(Grid)`
  > svg:last-child {
    display: none;
  }
`

const Crumb = ({ crumbString }) => (
  <span
    css={`
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: ${({ theme }) => theme.palette.primary.main};
      :hover {
        color: ${({ theme }) => theme.palette.primary.dark};
      }
    `}
  >
    {crumbString}
  </span>
)

Crumb.propTypes = {
  crumbString: PropTypes.string
}

const routes = [
  {
    path: '/crm/contacts',
    breadcrumb: <Crumb crumbString="Lista de contactos" />
  },
  {
    path: '/crm/contacts/:contactId([A-z0-9]+)',
    breadcrumb: <Crumb crumbString="Contacto" />
  },
  {
    path: '/contacts/:contactId([A-z0-9]+)/:requirementId([A-z0-9]+)',
    breadcrumb: param => {
      return param.match.params.requirementId !== 'detail' ? (
        <Crumb crumbString="Requerimiento" />
      ) : null
    }
  },
  {
    path: '/requirements',
    breadcrumb: <Crumb crumbString="Lista de requerimientos" />
  },
  {
    path: '/requirements/:contactId([A-z0-9]+)/:requirementId([A-z0-9]+)',
    breadcrumb: param => {
      return param.match.params.requirementId !== 'detail' ? (
        <Crumb crumbString="Requerimiento" />
      ) : null
    }
  },
  {
    path: '/:navBarTab(contacts|requirements)/:contactId([a-z0-9]+)/:requirementId([A-z0-9]+)/:detailType(wg|se)/:propertyId([A-z0-9]+)/:origin(shd|sgn|snt)/:datasheetId([A-z0-9])?',
    breadcrumb: <Crumb crumbString="Detalle de propiedad" />
  }
]

const Breadcrumbs = ({ breadcrumbs, className }) => {
  return (
    <GridStyled container alignItems="center" className={className}>
      {breadcrumbs
        .map(({ match, breadcrumb }, index) =>
          // other props are available during render, such as `location`
          // and any props found in your route objects will be passed through too
          breadcrumb ? (
            <span key={index}>
              <NavLink to={match.url}>{breadcrumb}</NavLink>
            </span>
          ) : null
        )
        .filter(item => item != null)
        .reduce((acc, x, index) => {
          const component = (
            <Fragment key={index}>
              {' '}
              {x}
              <ChevronRight
                css={`
                  fill: ${({ theme }) => theme.palette.text.grayLight};
                  width: ${({ theme }) => theme.remCalc('20')};
                  margin: ${({ theme }) => theme.remCalc('0 8')};
                `}
              />
            </Fragment>
          )

          return [...acc, component]
        }, [])}
    </GridStyled>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
  className: PropTypes.string
}

const BreadcrumbsComponent = withBreadcrumbs(routes)(Breadcrumbs)

export default BreadcrumbsComponent
