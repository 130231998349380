var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/Steppers/BallsStepper.js',
    _this3 = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout } from '../Layouts';
import { Text } from '../Texts';

var BASE_CLASS_NAME = 'balls-stepper';

var BallsStepper = (_temp2 = _class = function (_Component) {
  _inherits(BallsStepper, _Component);

  function BallsStepper() {
    var _ref,
        _this2 = this;

    var _temp, _this, _ret;

    _classCallCheck(this, BallsStepper);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = BallsStepper.__proto__ || Object.getPrototypeOf(BallsStepper)).call.apply(_ref, [this].concat(args))), _this), _this.renderElements = function () {
      var _this$props = _this.props,
          steps = _this$props.steps,
          currentStep = _this$props.currentStep,
          titles = _this$props.titles,
          percentage = _this$props.percentage;

      var stepsPositions = Array.from(new Array(steps), function (val, index) {
        return index;
      });
      var stepsSuccess = stepsPositions.reduce(function (val, currVal) {
        if (currVal < currentStep) val.push(true);else val.push(false);
        return val;
      }, []);

      var toRender = stepsPositions.reduce(function (elements, step) {
        var currPercentage = currentStep === step ? percentage : 0;
        elements.push(React.createElement(Ball, {
          shrink: true,
          isSelected: currentStep === step,
          isPending: currentStep < step,
          isSuccess: stepsSuccess[step],
          step: step + 1,
          title: titles[step],
          __source: {
            fileName: _jsxFileName,
            lineNumber: 29
          },
          __self: _this2
        }));
        if (step < steps - 1) {
          elements.push(React.createElement(Bar, {
            auto: true,
            isCompleted: stepsSuccess[step],
            percentage: currPercentage,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 40
            },
            __self: _this2
          }));
        }
        return elements;
      }, []);

      return toRender;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(BallsStepper, [{
    key: 'render',
    value: function render() {
      return React.createElement(
        Layout,
        { className: BASE_CLASS_NAME, middle: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 55
          },
          __self: this
        },
        this.renderElements()
      );
    }
  }]);

  return BallsStepper;
}(Component), _class.propTypes = {
  currentStep: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
  titles: PropTypes.array
}, _temp2);
export { BallsStepper as default };


var Ball = function Ball(_ref2) {
  var _classNames, _classNames2;

  var isSelected = _ref2.isSelected,
      isPending = _ref2.isPending,
      isSuccess = _ref2.isSuccess,
      step = _ref2.step,
      title = _ref2.title;

  var ballClasses = classNames(BASE_CLASS_NAME + '__ball', (_classNames = {}, _defineProperty(_classNames, BASE_CLASS_NAME + '__ball--selected', isSelected), _defineProperty(_classNames, BASE_CLASS_NAME + '__ball--pending', isPending), _defineProperty(_classNames, BASE_CLASS_NAME + '__ball--success', isSuccess), _classNames));

  var titleClasses = classNames(BASE_CLASS_NAME + '__title', (_classNames2 = {}, _defineProperty(_classNames2, BASE_CLASS_NAME + '__title--selected', isSelected), _defineProperty(_classNames2, BASE_CLASS_NAME + '__title--pending', !isSelected), _classNames2));

  return React.createElement(
    Layout,
    { vertical: true, middle: true, center: true, __source: {
        fileName: _jsxFileName,
        lineNumber: 75
      },
      __self: _this3
    },
    React.createElement(
      Layout,
      { className: ballClasses, middle: true, center: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 76
        },
        __self: _this3
      },
      React.createElement(
        Text,
        { noResponsive: true, bold: true, largeSize: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 77
          },
          __self: _this3
        },
        isSuccess ? '' : step
      ),
      React.createElement(
        Text,
        { noResponsive: true, bold: true, className: titleClasses, __source: {
            fileName: _jsxFileName,
            lineNumber: 80
          },
          __self: _this3
        },
        title
      )
    )
  );
};

Ball.propTypes = {
  isPending: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};

var Bar = function Bar(_ref3) {
  var isCompleted = _ref3.isCompleted,
      percentage = _ref3.percentage;

  var barPendingClasses = classNames(BASE_CLASS_NAME + '__bar', BASE_CLASS_NAME + '__bar--pending');
  var barChildClasses = classNames(BASE_CLASS_NAME + '__bar', BASE_CLASS_NAME + '__bar--selected');

  percentage = isCompleted ? 100 : percentage;

  return React.createElement(
    Layout,
    { className: barPendingClasses, __source: {
        fileName: _jsxFileName,
        lineNumber: 109
      },
      __self: _this3
    },
    React.createElement('div', { className: barChildClasses, style: { width: percentage + '%' }, __source: {
        fileName: _jsxFileName,
        lineNumber: 110
      },
      __self: _this3
    })
  );
};

Bar.propTypes = {
  isCompleted: PropTypes.bool,
  percentage: PropTypes.number
};