import React, { useContext, useState } from 'react'
import { object } from 'prop-types'
import styled from 'styled-components'
import { Typography, RadioGroup, Radio, Checkbox, withForm } from 'rc'

import { MaskedInput } from 'rc/FormComponents'

import {
  ModelFormSectionContainer,
  ActiveInputTitleContainer,
  MaskedInputContainerStyled
} from 'modules/development/static-components'
import ModelFormContext from '../ModelFormContext'
function Operation(props) {
  const {
    fc: {
      values: {
        price_range,
        presale_active,
        min_presale_price,
        max_presale_price,
        sale_active,
        min_sale_price,
        max_sale_price,
        lease_active,
        min_lease_price,
        max_lease_price,
        has_presale,
        has_sale,
        has_lease
      },
      customChange
    }
  } = props
  const [operationActive, setOperationActive] = useState({
    presale_active,
    lease_active,
    sale_active
  })

  const { formErrors = [], cleanFormErrors } = useContext(ModelFormContext)

  const handleMaskedInputChange = ({ target }) => {
    const { value, name } = target
    cleanFormErrors(name)
    customChange({ [`${name}`]: value === '' ? null : parseInt(value) })
  }

  const handleActiveOperations = (name, newValue) => {
    setOperationActive({ ...operationActive, [`${name}`]: newValue })
  }

  return (
    <ModelFormSectionContainer>
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
      >
        Operación
      </Typography>
      <div
        css={`
          display: flex;
          margin: ${({ theme }) => theme.remCalc('20 0')};
        `}
      >
        <RadioGroup
          name="price_range"
          isRadioRequired={true}
          css={`
            margin-top: 0 !important;
          `}
        >
          <LabelStyled>
            <Radio
              css={`
                margin-right: ${({ theme }) => theme.remCalc('8')};
              `}
              checked={price_range === 'true'}
              type="checkbox"
              value={true}
            />
            <Typography variant="subtitle4" fontWeight="regular">
              Rango de precio
            </Typography>
          </LabelStyled>
          <LabelStyled>
            <Radio
              css={`
                margin-right: ${({ theme }) => theme.remCalc('8')};
              `}
              checked={price_range === 'false'}
              type="checkbox"
              value={false}
            />
            <Typography variant="subtitle4" fontWeight="regular">
              Precio fijo
            </Typography>
          </LabelStyled>
        </RadioGroup>
      </div>
      <div
        css={`
          display: flex;
          flex-direction: column;
          width: 100%;
          > div:not(:last-child) {
            margin-bottom: ${({ theme }) => theme.remCalc('12')};
          }
        `}
      >
        <OperationTypeInnerContainer>
          <ActiveInputTitleContainer>
            <CheckboxStyled
              name="presale_active"
              disabled={!has_presale}
              onChange={({ target: { name, checked } = {} }) =>
                handleActiveOperations(name, checked)
              }
            />
            <Typography variant="subtitle4" fontWeight="medium">
              Preventa
            </Typography>
          </ActiveInputTitleContainer>
          <MaskedInputContainerStyled fullWidth={price_range === 'true'}>
            <MaskedInput
              name="min_presale_price"
              label="Desde"
              placeholder="Precio mínimo"
              mask="money"
              value={min_presale_price}
              disabled={!presale_active}
              required={presale_active}
              onChange={handleMaskedInputChange}
              error={
                operationActive.presale_active &&
                formErrors['min_presale_price']
              }
            />
            {price_range === 'true' && (
              <MaskedInput
                name="max_presale_price"
                label={price_range === 'true' ? 'Hasta' : 'Precio preventa'}
                placeholder="Precio máximo"
                mask="money"
                value={max_presale_price}
                disabled={!presale_active}
                required={presale_active}
                onChange={handleMaskedInputChange}
              />
            )}
          </MaskedInputContainerStyled>
        </OperationTypeInnerContainer>
        <OperationTypeInnerContainer>
          <ActiveInputTitleContainer>
            <CheckboxStyled
              name="sale_active"
              onChange={({ target: { name, checked } = {} }) =>
                handleActiveOperations(name, checked)
              }
              disabled={!has_sale}
            />
            <Typography variant="subtitle4" fontWeight="medium">
              Venta
            </Typography>
          </ActiveInputTitleContainer>
          <MaskedInputContainerStyled fullWidth={price_range === 'true'}>
            <MaskedInput
              name="min_sale_price"
              label="Desde"
              placeholder="Precio mínimo"
              mask="money"
              value={min_sale_price}
              disabled={!operationActive.sale_active}
              required={operationActive.sale_active}
              onChange={handleMaskedInputChange}
              error={
                operationActive.sale_active && formErrors['min_sale_price']
              }
            />
            {price_range === 'true' && (
              <MaskedInput
                name="max_sale_price"
                label={price_range === 'true' ? 'Hasta' : 'Precio venta'}
                placeholder="Precio máximo"
                mask="money"
                value={max_sale_price}
                disabled={!operationActive.sale_active}
                required={operationActive.sale_active}
                onChange={handleMaskedInputChange}
              />
            )}
          </MaskedInputContainerStyled>
        </OperationTypeInnerContainer>
        <OperationTypeInnerContainer>
          <ActiveInputTitleContainer>
            <CheckboxStyled
              name="lease_active"
              disabled={!has_lease}
              onChange={({ target: { name, checked } = {} }) =>
                handleActiveOperations(name, checked)
              }
            />
            <Typography variant="subtitle2" fontWeight="medium">
              Renta
            </Typography>
          </ActiveInputTitleContainer>
          <MaskedInputContainerStyled fullWidth={price_range === 'true'}>
            <MaskedInput
              name="min_lease_price"
              label="Desde"
              placeholder="Precio mínimo"
              mask="money"
              value={min_lease_price}
              disabled={!lease_active}
              required={lease_active}
              onChange={handleMaskedInputChange}
              error={
                operationActive.lease_active && formErrors['min_lease_price']
              }
            />
            {price_range === 'true' && (
              <MaskedInput
                name="max_lease_price"
                label={price_range === 'true' ? 'Hasta' : 'Precio renta'}
                placeholder="Precio máximo"
                mask="money"
                value={max_lease_price}
                disabled={!lease_active}
                required={lease_active}
                onChange={handleMaskedInputChange}
              />
            )}
          </MaskedInputContainerStyled>
        </OperationTypeInnerContainer>
      </div>
    </ModelFormSectionContainer>
  )
}

Operation.propTypes = {
  fc: object
}

const LabelStyled = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 20%;
`
const CheckboxStyled = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.remCalc('8')};
`
const OperationTypeInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.remCalc('20 10')};
  gap: ${({ theme }) => theme.remCalc('5')};
  background: #f5f7fd;
  border: 1px solid #e8ecf9;
  border-radius: ${({ theme }) => theme.remCalc('10')};
`

export default withForm(Operation)
