import React from 'react'
import PropTypes from 'prop-types'

import { ReduxForm as Form } from 'components'
import { FormGroup, TextInput } from 'c3-ui/elements/FormInputs'

import { LABEL_PROPS, AGENCY_UPGRADE_FORM } from './_constants'

const AgencyUpgradeForm = ({ initialValues }) => {
  return (
    <Form
      form={AGENCY_UPGRADE_FORM}
      destroyOnUnmount={false}
      initialValues={initialValues}
      noResponsive
    >
      <FormGroup>
        <TextInput
          id="profile-agencyUpgradeUserForm-mc-input-contactName"
          name="contactName"
          label="NOMBRE COMPLETO"
          labelProps={LABEL_PROPS}
          validations={[{ validationName: 'emptiness' }]}
          isRequired
        />
        <TextInput
          id="profile-agencyUpgradeUserForm-mc-input-phoneNumber"
          name="phoneNumber"
          label="CELULAR"
          labelProps={LABEL_PROPS}
          type="number"
          noCommas
          validations={[
            { validationName: 'emptiness' },
            {
              validationName: 'checkSize',
              params: {
                type: 'moreThan',
                size: '7'
              }
            }
          ]}
          isRequired
        />
        <TextInput
          id="profile-agencyUpgradeUserForm-mc-input-email"
          name="email"
          label="CORREO ELECTRÓNICO"
          labelProps={LABEL_PROPS}
          validations={[
            { validationName: 'emptiness' },
            {
              validationName: 'validEmail'
            }
          ]}
          disabled
          isRequired
        />
      </FormGroup>
    </Form>
  )
}

AgencyUpgradeForm.propTypes = {
  initialValues: PropTypes.object
}

export default AgencyUpgradeForm
