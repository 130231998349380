export const ActivePrime = Authorization => ({
  type: 'ACTIVE_PRIME',
  payload: {
    request: {
      method: 'post',
      url: '/prime/trial',
      headers: { Authorization }
    }
  }
})

export const RenovatePrime = (Authorization, data) => ({
  type: 'RENOVATE_PRIME',
  payload: {
    request: {
      method: 'post',
      url: '/prime/renovate_prime_form',
      headers: { Authorization },
      data
    }
  }
})

export const GetPrimeInfo = Authorization => ({
  type: 'GET_PRIME_INFO',
  payload: {
    request: {
      method: 'get',
      headers: { Authorization },
      url: `/prime/user`
    }
  }
})

export const PrimeSubscribe = (Authorization, data) => ({
  type: 'PRIME_SUBSCRIBE',
  payload: {
    request: {
      method: 'post',
      url: '/prime/subscribe',
      headers: { Authorization },
      data
    }
  }
})

export const AgencyPrimeSubscribe = (Authorization, data) => ({
  type: 'AGENCY_PRIME_SUBSCRIBE',
  payload: {
    request: {
      method: 'post',
      url: '/agency/prime/subscribe',
      headers: { Authorization },
      data
    }
  }
})

export const GetAgencyAvailableBrokers = (Authorization, value) => ({
  type: 'GET_AGENCY_AVAILABLE_BROKERS',
  payload: {
    request: {
      method: 'get',
      headers: { Authorization },
      url: `/agency/prime/available_brokers?s=${value}`
    }
  }
})

export const AgencyPrimeAssign = (Authorization, data) => ({
  type: 'AGENCY_PRIME_ASSIGN',
  payload: {
    request: {
      method: 'post',
      url: '/agency/prime/assign',
      headers: { Authorization },
      data
    }
  }
})

export const AgencyPrimeUnassign = (Authorization, userId) => ({
  type: 'AGENCY_PRIME_UNASSIGN',
  payload: {
    request: {
      method: 'post',
      url: `/agency/prime/unassign/${userId}`,
      headers: { Authorization },      
    }
  }
})

export const UnsubscribePrime = (Authorization, unsubscribe_reason) => ({
  type: 'PRIME_UNSUBSCRIBE',
  payload: {
    request: {
      method: 'post',
      url: '/prime/unsubscribe',
      headers: { Authorization },
      data: {
        unsubscribe_reason
      }
    }
  }
})

export const UnsubscribeAgencyPrime = (Authorization, unsubscribe_reason) => ({
  type: 'PRIME_AGENCY_UNSUBSCRIBE',
  payload: {
    request: {
      method: 'post',
      url: '/agency/prime/unsubscribe',
      headers: { Authorization },
      data: {
        unsubscribe_reason
      }
    }
  }
})

export const UpdateAgencyPrime = (Authorization, plan_id, card_token) => ({
  type: 'UPDATE_AGENCY_PRIME',
  payload: {
    request: {
      method: 'post',
      url: '/agency/prime/update',
      headers: { Authorization },
      data: {
        plan_id,
        card_token
      }
    }
  }
})

export const GetUpdatePlanAmountCharge = (Authorization, plan_id) => ({
  type: 'GET_UPDATE_PLAN_AMOUNT_CHARGE',
  payload: {
    request: {
      method: 'get',
      headers: { Authorization },
      url: `/agency/prime/update_amount?plan_id=${plan_id}`
    }
  }
})

export const UpgradePrimeToPass = (Authorization, data) => ({
  type: 'UPGRADE_PRIME_TO_PASS',
  payload: {
    request: {
      method: 'post',
      url: '/prime/upgrade_to_pass',
      headers: { Authorization },
      data
    }
  }
})

export const ResumePrimeSubscription = Authorization => ({
  type: 'RESUME_PRIME_SUBSCRIPTION',
  payload: {
    request: {
      method: 'post',
      url: '/prime/resume_subscription',
      headers: { Authorization }
    }
  }
})

export const ResumeAgencyPrimeSubscription = Authorization => ({
  type: 'RESUME_PRIME_SUBSCRIPTION',
  payload: {
    request: {
      method: 'post',
      url: '/agency/prime/resume_subscription',
      headers: { Authorization }
    }
  }
})

export const GetNewCheckoutPage = (Authorization, plan_id) => ({
  type: 'GET_NEW_CHECKOUT_PAGE',
  payload: {
    request: {
      method: 'get',
      url: `/prime/chargebee/new_checkout_hosted_page/${plan_id}`,
      headers: { Authorization }      
    }
  }
})

export const GetCheckoutPage = (Authorization, plan_id) => ({
  type: 'GET_CHECKOUT_PAGE',
  payload: {
    request: {
      method: 'get',
      url: `/prime/chargebee/checkout_hosted_page/${plan_id}`,
      headers: { Authorization }      
    }
  }
})

export const UpgradeToPass = (Authorization) => ({
  type: 'UPGRADE_TO_PASS',
  payload: {
    request: {
      method: 'post',
      url: `/prime/chargebee/upgrade_to_pass`,
      headers: { Authorization }
    }
  }
})

export const CancelSubscription = (Authorization, reason ) => ({
  type: 'CANCEL_SUBSCRIPTION',
  payload: {
    request: {
      method: 'post',
      url: `/prime/chargebee/cancel_subscription`,
      headers: { Authorization },
      data: { unsubscribe_reason: reason }
    }
  }
})

export const ReactivateSubscription = (Authorization) => ({
  type: 'CANCEL_SUBSCRIPTION',
  payload: {
    request: {
      method: 'post',
      url: `/prime/chargebee/reactivate_subscription`,
      headers: { Authorization }
    }
  }
})

export const AssignChargebeeSlots = (Authorization, data) => ({
  type: 'ASSIGN_CHARGEBEE_SLOTS',
  payload: {
    request: {
      method: 'post',
      url: `/prime/chargebee/assign_slots`,
      headers: { Authorization },
      data
    }
  }
})

export const RemoveChargebeeSlot = (Authorization, userId) => ({
  type: 'REMOVE_CHARGEBEE_SLOT',
  payload: {
    request: {
      method: 'post',
      url: `/prime/chargebee/${userId}/remove_slot`,
      headers: { Authorization }
    }
  }
})

export const GetChargebeePortalSession = (Authorization) => ({
  type: 'GET_CHARGEBEE_PORTAL_SESSION',
  payload: {
    request: {
      method: 'get',
      url: `/prime/chargebee/portal_session`,
      headers: { Authorization }      
    }
  }
})

export const CashSubscription = (Authorization, data) => ({
  type: 'CASH_SUBSCRIPTION',
  payload: {
    request: {
      method: 'post',
      url: `/prime/stripe/cash_subscription`,
      headers: { Authorization },
      data
    }
  }
})

export const AssignStripeSlots = (Authorization, data) => ({
  type: 'ASSIGN_STRIPE_SLOTS',
  payload: {
    request: {
      method: 'post',
      url: `/prime/stripe/assign_slots`,
      headers: { Authorization },
      data
    }
  }
})

export const RemoveStripeSlots = (Authorization, userId) => ({
  type: 'REMOVE_STRIPE_SLOTS',
  payload: {
    request: {
      method: 'post',
      url: `/prime/stripe/${userId}/remove_slot`,
      headers: { Authorization }
    }
  }
})

export const GetPassMinimumFields = Authorization => ({
  type: 'PASS_MINIMUM_FIELDS',
  payload: {
    request: {
      method: 'get',
      url: '/user/pass_required_info',
      headers: { Authorization }
    }
  }
})

export const PrimeSubscribeOxxoPay = (Authorization, plan_id, userGenerals) => ({
  type: 'PRIME_SUBSCRIBE_OXXO_PAY',
  payload: {
    request: {
      method: 'post',
      url: '/prime/subscribe/oxxo_pay',
      headers: { Authorization },
      data: {
        plan_id,
        ...userGenerals
      }
    }
  }
})

export const AgencyPrimeSubscribeOxxoPay = (Authorization, plan_id) => ({
  type: 'PRIME_SUBSCRIBE_OXXO_PAY',
  payload: {
    request: {
      method: 'post',
      url: '/agency/prime/subscribe/oxxo_pay',
      headers: { Authorization },
      data: {
        plan_id
      }
    }
  }
})

export const UpgradeCostDetails = (Authorization, plan_id, userGenerals) => ({
  type: 'UPGRADE_COST_DETAILS',
  payload: {
    request: {
      method: 'get',
      url: `/prime/upgrade/${plan_id}/cost_details`,
      params: userGenerals,
      headers: { Authorization } 
    }
  }
})

export const UpgradePrime = (Authorization, data) => ({
  type: 'UPGRADE_PRIME',
  payload: {
    request: {
      method: 'post',
      url: `/prime/upgrade`,
      headers: { Authorization },
      data
    }
  }
})

export const AgencyUpgradeCostDetails = (Authorization, plan_id) => ({
  type: 'AGENCY_UPGRADE_COST_DETAILS',
  payload: {
    request: {
      method: 'get',
      url: `/agency/prime/upgrade/${plan_id}/cost_details`,
      headers: { Authorization }      
    }
  }
} )

export const UpgradeAgencyPrime = (Authorization, data) => ({
  type: 'UPGRADE_AGENCY_PRIME',
  payload: {
    request: {
      method: 'post',
      url: `/agency/prime/upgrade`,
      headers: { Authorization },
      data
    }
  }
})

