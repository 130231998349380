var _jsxFileName = 'src/lib/elements/General/Divider.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
var Divider = function Divider(_ref) {
  var className = _ref.className,
      hideSmallOnly = _ref.hideSmallOnly;

  var dividerClasses = classNames(className, {
    'hide-for-small-only': hideSmallOnly
  });
  return React.createElement('hr', { className: dividerClasses, __source: {
      fileName: _jsxFileName,
      lineNumber: 8
    },
    __self: _this
  });
};

Divider.propTypes = {
  /** */
  className: PropTypes.string,
  /** */
  hideSmallOnly: PropTypes.bool
};

export default Divider;