import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M78.4631 65.5694C77.298 70.8552 71.8883 75.5795 67.313 77.676C60.005 81.0058 52.206 77.0202 44.6849 78.8391C40.4189 79.8719 37.5923 80.7854 32.9383 80.5138C28.4439 80.2561 24.6696 77.6392 21.6424 74.2945C18.6519 70.9625 18.3693 66.409 18.5893 61.9468C18.8213 57.5094 20.5672 55.6273 19.1037 51.1591C18.2656 48.611 16.5495 46.4581 14.8447 44.3912C13.1398 42.3242 11.3743 40.1953 10.4867 37.6713C7.89392 30.3696 13.3296 24.6276 19.2245 21.2823C22.4287 20.0422 26.9267 21.0457 31.9267 19.0422C35.4585 17.627 40.6814 13.2224 44.4267 12.5422C53.0503 10.9806 59.2666 11.92 66.6599 16.8333C72.2991 20.5868 76.6217 26.2883 78.6603 32.7621C79.7804 36.2699 80.1464 40.1129 79.3355 43.7346C78.5639 47.1849 74.7373 50.2585 74.789 53.7056C74.8104 55.7664 76.5091 57.3794 77.5048 59.181C78.6934 61.2546 78.9341 63.4527 78.4631 65.5694Z"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 44.5C35 25.9985 49.9985 11 68.5 11C87.0015 11 102 25.9985 102 44.5C102 63.0015 87.0015 78 68.5 78C49.9985 78 35 63.0015 35 44.5Z"
      fill="#FD6C6C"
    />
    <path
      d="M57.78 35.12L79.89 57.23"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.22 35.12L57.11 57.23"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Fragment>,
  'CrossIcon',
  '0 0 102 96'
)
