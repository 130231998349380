import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { ShowFlashNotification } from 'reduxActions/services'
import { Typography } from 'rc'
import { WhatsappFlat, Link } from 'rc/Icons'
import { isMobile } from 'rc/helpers/miscellaneous'
import { Button } from 'components'
import GenericShareMC from './GenericShareMC'
import { useAmplitude } from 'src/hooks/useAmplitude'

/**
 *
 * @param {object} props
 * @param {() => Promise<string>} props.getShareUrl
 */
function WhatsappShareMC({ getShareUrl, ...props }) {
  const dispatch = useDispatch()

  const textToCopy = useRef(null)
  const [shareUrl, setShareUrl] = useState('')

  const { trackEvent, AmplitudeEvents } = useAmplitude()

  const tracker = (name, description, extra = {}) =>
    trackEvent({
      name,
      eventProperties: {
        description,
        ...extra
      },
      configs: {
        path: true
      }
    })

  useEffect(() => {
    tracker(
      AmplitudeEvents.DEVELOPMENTS_SHARE_UNITS_VIEW,
      'User open share development units modal'
    )
  }, [])

  const copyToClipboard = () => {
    tracker(
      AmplitudeEvents.DEVELOPMENT_UNITS_COPY_LINK_SELECTED,
      'User copies link within share units modal'
    )
    textToCopy.current.select()
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
  }

  const openWhatsapp = shareUrl => {
    tracker(
      AmplitudeEvents.DEVELOPMENT_UNITS_SHARE_LINK_ACTION,
      'User clicks on share units via WhatsApp'
    )
    const encodedUrl = encodeURI(shareUrl)
    if (!isMobile()) {
      const popUp = window.open(
        `https://web.whatsapp.com/send?text=${encodedUrl}`,
        '_blank'
      )
      if (popUp) {
        return popUp.focus()
      }
    }
    window.location = `whatsapp://send?text=${encodedUrl}`
  }

  const urlToShare = async () => {
    if (shareUrl) return shareUrl
    const url = await getShareUrl()
    url && setShareUrl(url)
    return url
  }

  return (
    <GenericShareMC
      title="Compartir inventario"
      okText="Abrir whatsapp"
      {...props}
      icon={
        <WhatsappFlat
          css={`
            ${({ theme }) => theme.box(50, 50)}
            color: ${({ theme }) => theme.palette.success.main};
          `}
        />
      }
      onOk={async () => {
        openWhatsapp(await urlToShare())
        props.HideModal()
      }}
    >
      <CustomContainer>
        <Typography
          variant="body2"
          color="palette.new.text"
          css={`
            font-weight: 400;
          `}
        >
          *Has aplicado algunos filtros al listado. Solo se compartirán las
          unidades mostradas actualmente.
        </Typography>
        <input
          ref={textToCopy}
          type="text"
          readOnly
          value={shareUrl}
          css={`
            position: absolute;
            left: -9999px;
          `}
        />
        <Button
          type="secondary"
          rounded
          onClick={async () => {
            await urlToShare()
            await copyToClipboard()
            dispatch(
              ShowFlashNotification('success', 'Enlace copiado al portapapeles')
            )
          }}
          css={`
            width: 100%;
            min-height: ${({ theme }) => theme.remCalc('40')};
          `}
        >
          <Link
            css={`
              height: ${({ theme }) => theme.remCalc('17')};
              margin-right: ${({ theme }) => theme.remCalc('10')};
            `}
          />
          Copiar enlace
        </Button>
        <DiverContainer>
          <Divider
            css={`
              margin-left: 0;
            `}
          />
          O
          <Divider
            css={`
              margin-right: 0;
            `}
          />
        </DiverContainer>
      </CustomContainer>
    </GenericShareMC>
  )
}

WhatsappShareMC.propTypes = {
  HideModal: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.any,
  copy: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  getShareUrl: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  shareUrl: PropTypes.string
}

const CustomContainer = styled.div`
  margin: ${({ theme }) => theme.remCalc('10 0 0')};
  height: ${({ theme }) => theme.remCalc('180')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
const DiverContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Divider = styled.hr`
  margin: ${({ theme }) => theme.remCalc('20 5')};
  flex: 1;
`

export default WhatsappShareMC
