var _jsxFileName = 'src/lib/elements/Checkboxes/Checkbox.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Layout } from '../Layouts';
import { CheckboxError } from './';

var BASE_CLASSNAME = 'checkbox';

/** Single Checkbox wrapper to enhance any other Checkbox with error and touch handler. */
var Checkbox = function Checkbox(_ref) {
  var checkboxClassName = _ref.checkboxClassName,
      CheckboxType = _ref.checkboxType,
      className = _ref.className,
      disabled = _ref.disabled,
      error = _ref.error,
      label = _ref.label,
      onClick = _ref.onClick,
      touched = _ref.touched,
      value = _ref.value,
      props = _objectWithoutProperties(_ref, ['checkboxClassName', 'checkboxType', 'className', 'disabled', 'error', 'label', 'onClick', 'touched', 'value']);

  var checkboxGroupClasses = classNames(className, BASE_CLASSNAME, _defineProperty({}, BASE_CLASSNAME + '--error', error && touched && !disabled));

  return React.createElement(
    Fragment,
    {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 28
      },
      __self: _this
    },
    React.createElement(
      Layout,
      { className: checkboxGroupClasses, __source: {
          fileName: _jsxFileName,
          lineNumber: 29
        },
        __self: _this
      },
      React.createElement(CheckboxType, Object.assign({
        className: checkboxClassName,
        label: label,
        value: value,
        onClick: onClick,
        isSelected: value ? true : false,
        disabled: disabled
      }, props, {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 30
        },
        __self: _this
      }))
    ),
    React.createElement(CheckboxError, { touched: touched, error: error, disabled: disabled, __source: {
        fileName: _jsxFileName,
        lineNumber: 40
      },
      __self: _this
    })
  );
};

Checkbox.propTypes = {
  /** Checkbox Type to render */
  checkboxType: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  /** Handler of click action on Checkbox. */
  onClick: PropTypes.func.isRequired,
  /** Value to give on callback. */
  value: PropTypes.any.isRequired,
  /** Custom className in CheckboxType passed. */
  checkboxClassName: PropTypes.string,
  /** Custom className in Layout Wrapper.  */
  className: PropTypes.string,
  /** Gives a disable stylish. */
  disabled: PropTypes.bool,
  /** Sets error in case it has one. */
  error: PropTypes.string,
  /** Text to render in checkbox */
  label: PropTypes.string,
  /** Sets touch if passed. */
  touched: PropTypes.bool
};

export default Checkbox;