import styled from 'styled-components'
import { Typography } from 'rc'
import { Button } from 'components'

export const ButtonStyled = styled(Button).attrs(props => ({
  type: props.type
}))`
  border-radius: ${({ theme }) => theme.remCalc('50')} !important;
  min-width: ${({ theme }) => theme.remCalc('120')};
`
export const PrimaryTitle = styled(Typography).attrs(props => ({
  variant: props.variant ? props.variant : 'h2',
  color: 'palette.primary.main',
  textTransform: 'initial'
}))``
