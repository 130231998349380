import React from 'react'
import { bool, any, func } from 'prop-types'
import Typography from 'rc/Typography'
import { ChevronRight, Cross } from 'rc/Icons'
import styled from 'styled-components'

const MenuButton = ({ onClick, isMenuOpened }) => {
  return (
    <div
      css={`
        background: ${({ theme }) => theme.palette.secondary.main};
        width: 100%;
      `}
    >
      <div
        container
        center
        css={`
          height: ${({ theme }) => theme.remCalc('50')};
          width: 100%;
          z-index: 3;
          cursor: pointer;
          ${({ theme }) => theme.media.desktop`
            height: ${({ theme }) => theme.remCalc('70')};
          `}
        `}
        onClick={onClick}
        data-toggle="menu-button"
        id="navigation-side-bar__menu-button"
      >
        <Typography
          variant="subtitle1"
          color="white"
          css={`
            padding-right: 15px;
            padding-left: 5px;
            user-select: none;
          `}
        >
          Menú
        </Typography>
        <MenuButtonContainer container center inline>
          <ChevronRight
            $ismenuopened={isMenuOpened}
            css={`
              margin-right: ${({ theme, $ismenuopened }) =>
                $ismenuopened ? 0 : theme.remCalc('-3')};
              height: ${({ theme }) => theme.remCalc('22')};
              transform: ${({ $ismenuopened }) =>
                $ismenuopened ? 'rotate(180deg)' : 'rotate(0deg)'};
              transition-delay: 0;
              transition-duration: 200ms;
              transition-property: transform;
              transition-timing-function: ease-out;
            `}
          />
        </MenuButtonContainer>
        <Cross
          css={`
            ${({ theme }) => theme.position('absolute', 10, 10)};
            ${({ theme }) => theme.box(35, 35)}
            fill: ${({ theme }) => theme.palette.base.white};
            color: ${({ theme }) => theme.palette.base.white};
            ${({ theme }) => theme.media.desktop`
              display: none;
            `}
          `}
        />
      </div>
    </div>
  )
}

const MenuButtonContainer = styled.div`
  background-color: #0d47a1;
  ${({ theme }) => theme.box(30, 30, true)}
  fill: ${({ theme }) => theme.palette.base.white};
  color: ${({ theme }) => theme.palette.base.white};
  ${({ theme }) => theme.position('absolute', 35 - 15, -15)};
  ${({ theme }) => theme.media.desktopDown`
    display: none;
  `}
`

MenuButton.propTypes = {
  isMenuOpened: bool,
  menuHeaderCustomized: any,
  onClick: func
}

export default MenuButton
