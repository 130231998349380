import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 3.75C8.66421 3.75 9 4.08579 9 4.5V19.5C9 19.9142 8.66421 20.25 8.25 20.25C7.83579 20.25 7.5 19.9142 7.5 19.5V4.5C7.5 4.08579 7.83579 3.75 8.25 3.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H20.25C21.0784 3.75 21.75 4.42157 21.75 5.25V18.75C21.75 19.5784 21.0784 20.25 20.25 20.25H3.75C2.92157 20.25 2.25 19.5784 2.25 18.75V5.25ZM20.25 5.25H3.75V18.75H20.25V5.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 7.5C2.25 7.08579 2.58579 6.75 3 6.75H5.25C5.66421 6.75 6 7.08579 6 7.5C6 7.91421 5.66421 8.25 5.25 8.25H3C2.58579 8.25 2.25 7.91421 2.25 7.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 10.5C2.25 10.0858 2.58579 9.75 3 9.75H5.25C5.66421 9.75 6 10.0858 6 10.5C6 10.9142 5.66421 11.25 5.25 11.25H3C2.58579 11.25 2.25 10.9142 2.25 10.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 13.5C2.25 13.0858 2.58579 12.75 3 12.75H5.25C5.66421 12.75 6 13.0858 6 13.5C6 13.9142 5.66421 14.25 5.25 14.25H3C2.58579 14.25 2.25 13.9142 2.25 13.5Z"
      fill="currentColor"
    />
  </Fragment>,
  'NoteBook',
  '0 0 24 24'
)
