var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _jsxFileName = 'src/lib/elements/Layouts/PageTopLayout.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Container } from './';
import { PageTitle } from '../Texts';
var PageTopLayout = (_temp = _class = function (_Component) {
  _inherits(PageTopLayout, _Component);

  function PageTopLayout() {
    _classCallCheck(this, PageTopLayout);

    return _possibleConstructorReturn(this, (PageTopLayout.__proto__ || Object.getPrototypeOf(PageTopLayout)).apply(this, arguments));
  }

  _createClass(PageTopLayout, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          pageName = _props.pageName,
          className = _props.className;

      return React.createElement(
        Layout,
        { className: 'page-top-layout ' + className, middle: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 18
          },
          __self: this
        },
        React.createElement(
          Container,
          {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 19
            },
            __self: this
          },
          React.createElement(
            Layout,
            { justify: true, middle: true, __source: {
                fileName: _jsxFileName,
                lineNumber: 20
              },
              __self: this
            },
            React.createElement(
              PageTitle,
              { shrink: true, __source: {
                  fileName: _jsxFileName,
                  lineNumber: 21
                },
                __self: this
              },
              pageName
            ),
            children
          )
        )
      );
    }
  }]);

  return PageTopLayout;
}(Component), _class.propTypes = {
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  pageName: PropTypes.string
}, _temp);


export default PageTopLayout;