import React, { Fragment, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { connect } from 'react-redux'

import { Grid, Typography, Link } from 'rc'
import { Pencil } from 'rc/Icons'
import { useMedia } from 'rc/Hooks'

import { Button } from 'components'

import {
  ProfileSectionCard,
  ProfileCover,
  ContainerBoxStyled,
  ProfileSectionHeader,
  SimpleText
} from 'modules/profile/components'
import { StarChecked } from 'rc/Icons'
import { ProfileContext } from 'modules/profile'
import { ShowModal } from 'reduxActions/services'
import { ProfilePersonalInfoMC, VerifyTelephoneMC } from '../modalContent'
import { FreemiumWrapper, RULES } from 'modules/freemium'

const IconContainerStyled = styled.div`
  ${({ theme }) => theme.box(25, 25, true)}
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.remCalc('8')};
`

const ButtonStyled = styled(Button)`
  margin-top: ${({ theme }) => theme.remCalc('10')};
`

const ProfilePersonalInfo = ({
  roles,
  ShowModal,
  theme: {
    devices: { tabletDown }
  },
  ...props
}) => {
  const {
    profile: {
      user_fullname = '',
      user_email = '',
      user_current_position = '',
      user_phone_number = '',
      user_city = '',
      user_state = '',
      phone_number_verified = null,
      verified_email = null
    } = {},
    profile,
    customChange,
    isMyProfile
  } = useContext(ProfileContext)

  const isTabletDown = useMedia(tabletDown)

  const [showPhone, setShowPhone] = useState(isMyProfile ? true : false)
  const [showEmail, setShowEmail] = useState(isMyProfile ? true : false)

  const handleShowPhone = () => {
    setShowPhone(true)
  }

  const handleShowEmail = () => {
    setShowEmail(true)
  }

  return (
    <ProfileSectionCard flat>
      <ProfileCover />
      <ContainerBoxStyled
        container
        direction="column"
        justify="space-between"
        css={`
          height: auto;
          margin-top: ${({ theme }) => theme.remCalc('15')};
          padding: ${({ theme }) => theme.remCalc('0 15 12 15')};
          ${({ theme }) => theme.media.desktop`
            padding: ${({ theme }) => theme.remCalc('0 50 12 50')};
          `}
        `}
      >
        <Grid container alignItems="center">
          <ProfileSectionHeader
            textTransform="initial"
            css={`
              display: flex;
              justify-content: center;
              ${({ theme }) => theme.media.desktop`
                justify-content: flex-start;              
              `}
            `}
          >
            Información del asesor
          </ProfileSectionHeader>
          {isMyProfile && (
            <Grid
              item
              flat
              xsAuto
              container
              justify="flex-end"
              alignItems="center"
            >
              <Button
                id="profile-personalInfo-button-editPersonalInfo"
                radius="100%"
                css={`
                  ${({ theme }) => theme.box(50, 50)}

                  margin-left: ${({ theme }) => theme.remCalc('10')};
                `}
                onClick={() => {
                  ShowModal(
                    ProfilePersonalInfoMC,
                    {
                      profile,
                      onInfoUpdated: newValues =>
                        customChange(newValues, null, true)
                    },
                    { noClosingButton: false, overflow: false }
                  )
                }}
              >
                <Pencil
                  css={`
                    ${({ theme }) => theme.box(30, 30)}
                  `}
                />
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          justify="space-between"
          css={`
            padding: ${({ theme }) => theme.remCalc('10 0')};
            flex-direction: column;
            ${({ theme }) => theme.media.desktop`
              flex-direction: row;
            `}
          `}
        >
          <Grid
            container
            direction="column"
            css={`
              ${({ theme }) => theme.media.desktop`
                width: calc(50% - ${({ theme }) => theme.remCalc('110')});
                text-align: initial;                
              `}
            `}
          >
            <SimpleText variant="subtitle3">Nombre completo</SimpleText>
            <SimpleText flat>{user_fullname}</SimpleText>

            <Fragment>
              <SimpleText variant="subtitle3">Puesto actual</SimpleText>
              <SimpleText flat>
                {user_current_position ? user_current_position : '-'}
              </SimpleText>
            </Fragment>
          </Grid>
          {(user_city || user_state) && (
            <Grid
              container
              css={`
                flex: 1;
                height: fit-content;
                padding-top: ${({ theme }) => theme.remCalc('60')};
                ${({ theme }) => theme.media.desktop`
                  justify-content: center;
                `}
              `}
            >
              <Typography variant="subtitle1" css={``} align="center">
                {user_city ? `${user_city}, ` : ''}
                {user_state ? `${user_state}.` : ''}
              </Typography>
            </Grid>
          )}
          <Grid
            container
            direction="column"
            css={`
              ${({ theme }) => theme.media.desktop`
                text-align: initial;              
                width: calc(50% - ${({ theme }) => theme.remCalc('100')});
                padding-left: ${({ theme }) => theme.remCalc('130')};
              `}
            `}
          >
            {showEmail && (
              <Grid container>
                <Grid item flat xs={3}>
                  {verified_email && (
                    <IconContainerStyled>
                      <StarChecked
                        css={`
                          color: ${({ theme }) => theme.palette.primary.main};
                        `}
                      />
                    </IconContainerStyled>
                  )}
                </Grid>
                <Grid
                  item
                  flat
                  xsAuto
                  container
                  direction="column"
                  justify="flex-start"
                >
                  <SimpleText variant="subtitle3">
                    Correo electrónico
                  </SimpleText>
                  <SimpleText flat>
                    {user_email ? (
                      <Link
                        id="profile-profileDetail-link-openBrokerEmail"
                        underline="always"
                        href={`mailto:${user_email}`}
                      >
                        {user_email}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </SimpleText>
                </Grid>
              </Grid>
            )}
            {!showEmail ? (
              <FreemiumWrapper custom section={RULES.PROFILE_ACCESS}>
                <ButtonStyled
                  id="profile-wiggotModule-button-contactEmailSuccess"
                  full
                  size={isTabletDown ? 'medium' : 'medium'}
                  variant="outlined"
                  onClick={handleShowEmail}
                >
                  Ver email
                </ButtonStyled>
              </FreemiumWrapper>
            ) : null}

            {showPhone && (
              <Grid container>
                <Grid item flat xs={3}>
                  {phone_number_verified && (
                    <IconContainerStyled>
                      <StarChecked
                        css={`
                          color: ${({ theme }) => theme.palette.primary.main};
                        `}
                      />
                    </IconContainerStyled>
                  )}
                </Grid>

                <Grid
                  item
                  flat
                  xsAuto
                  container
                  direction="column"
                  justify="flex-start"
                >
                  <SimpleText variant="subtitle3">Celular</SimpleText>
                  <SimpleText flat>
                    {user_phone_number ? (
                      <Link
                        id="profile-profileDetail-link-openBrokerPhone"
                        underline="always"
                        href={`tel:+${user_phone_number}`}
                      >
                        {user_phone_number}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </SimpleText>{' '}
                  {isMyProfile && !phone_number_verified && (
                    <Button
                      css={`
                        max-width: ${({ theme }) => theme.remCalc('160')};
                        margin-top: ${({ theme }) => theme.remCalc('8')};
                      `}
                      size="small"
                      onClick={() =>
                        ShowModal(VerifyTelephoneMC, {
                          phoneNumber: user_phone_number,
                          idBase: 'profile',
                          onInfoUpdated: newValues =>
                            customChange(newValues, null, false)
                        })
                      }
                    >
                      Verificar celular
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
            {user_phone_number ? (
              !showPhone ? (
                <FreemiumWrapper custom section={RULES.PROFILE_ACCESS}>
                  <ButtonStyled
                    id="profile-wiggotModule-button-contactPhoneSuccess"
                    full
                    size={isTabletDown ? 'medium' : 'medium'}
                    variant="outlined"
                    onClick={handleShowPhone}
                  >
                    Ver teléfono
                  </ButtonStyled>
                </FreemiumWrapper>
              ) : null
            ) : null}
          </Grid>
        </Grid>
      </ContainerBoxStyled>
    </ProfileSectionCard>
  )
}

ProfilePersonalInfo.propTypes = {
  roles: PropTypes.object,
  ShowModal: PropTypes.func
}

const mapStateToProps = ({ authReducer: { roles } }) => ({ roles })

const mapDispatchToProps = {
  ShowModal
}

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePersonalInfo)
)
