import React, { Suspense } from 'react'
import { any, bool, string } from 'prop-types'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { Spinner } from 'rc'

function RedirectRoute(props) {
  const location = useLocation()
  if (props.fallbackTo === '/login' && !props.canAccessIf) { 
    return window.location.replace(
      `${process.env.REACT_APP_NEW_APLICATION_URL}/auth/login`
    )
  }

  if (props.canAccessIf === false) {
    return (
      <Redirect
        to={{
          pathname:
            location.state && location.state.from
              ? `${location.state.from.pathname}${location.state.from.search}`
              : props.fallbackTo,
          state: { from: location }
        }}
      />
    )
  }

  return (
    <Suspense
      fallback={
        <div
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
          `}
        >
          <Spinner />
        </div>
      }
    >
      <Route
        exact={props.exact}
        path={props.path}
        component={props.component}
      />
    </Suspense>
  )
}

RedirectRoute.propTypes = {
  exact: bool,
  path: string,
  fallbackTo: string,
  canAccessIf: bool,
  component: any
}

export default RedirectRoute
