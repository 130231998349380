import styled from 'styled-components'
import WhiteLogo from 'static/logos/wiggot-prime-white.svg'

export const Container = styled.div`
  p {
    text-align: center;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.remCalc('12')};
  margin-bottom: ${({ theme }) => theme.remCalc('10')};
  ${({ theme }) => theme.media.desktop`
    justify-content: space-between
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.remCalc('25')};
    margin-bottom: ${({ theme }) => theme.remCalc('22')};
  `}
  h3 {
    color: white;
    ${({ theme }) => theme.media.desktopDown`
    text-align:center;
  `}
  }
  img {
    min-height: ${({ theme }) => theme.remCalc('180')};
  }
`

export const WiggotPrimeLogo = styled.img.attrs({
  src: WhiteLogo,
  alt: 'Wiggot Prime'
})``
