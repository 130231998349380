/**
 * Gets the association main info.
 * @param {*} Authorization
 */
export const GetAssociationInfo = Authorization => ({
  type: 'GET_ASSOCIATION_INFO',
  payload: {
    request: {
      url: '/association',
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} agencyName
 */
export const FindAgencies = (Authorization, agencyName) => ({
  type: 'FIND_AGENCIES',
  payload: {
    request: {
      url: '/association/find_agencies',
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        name: agencyName
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} agencyIds
 */
export const InviteAgencies = (Authorization, agencyIds) => ({
  type: 'INVITE_AGENCIES',
  payload: {
    request: {
      url: '/association/invite_agencies',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        agency_ids: agencyIds
      }
    }
  }
})
/**
 *
 * @param {*} Authorization
 * @param {*} page
 * @param {*} limit
 * @param {*} order
 * @param {*} orderDirection
 * @param {*} filters
 */
export const GetAssociationAgencies = (
  Authorization,
  page = 0,
  limit = 10,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'GET_ASSOCIATION_AGENCIES',
  payload: {
    request: {
      url: '/association/agencies',
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} agencyIds
 */
export const BlockAssociationAgencies = (Authorization, agencyIds) => ({
  type: 'BLOCK_ASSOCIATION_AGENCIES',
  payload: {
    request: {
      url: '/association/agencies/block',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        agency_ids: agencyIds
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} agencyIds
 */
export const ActivateAssociationAgencies = (Authorization, agencyIds) => ({
  type: 'ACTIVATE_ASSOCIATION_AGENCIES',
  payload: {
    request: {
      url: '/association/agencies/activate',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        agency_ids: agencyIds
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} agencyIds
 */
export const DeleteAssociationAgencies = (Authorization, agencyIds) => ({
  type: 'DELETE_ASSOCIATION_AGENCIES',
  payload: {
    request: {
      url: '/association/agencies',
      method: 'delete',
      headers: {
        Authorization
      },
      data: {
        agency_ids: agencyIds
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} page
 * @param {*} limit
 * @param {*} order
 * @param {*} orderDirection
 * @param {*} filters
 */
export const GetAssociationAgenciesInvitations = (
  Authorization,
  page = 0,
  limit = 10,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'GET_ASSOCIATION_AGENCIES_INVITATIONS',
  payload: {
    request: {
      url: '/association/invitations',
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} agencyIds
 */
export const DeleteAssociationAgenciesInvitations = (
  Authorization,
  agencyIds
) => ({
  type: 'DELETE_ASSOCIATION_AGENCIES_INVITATIONS',
  payload: {
    request: {
      url: '/association/invitations',
      method: 'delete',
      headers: {
        Authorization
      },
      data: {
        agency_ids: agencyIds
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} page
 * @param {*} limit
 * @param {*} order
 * @param {*} orderDirection
 * @param {*} filters
 */
export const GetAssociationProperties = (
  Authorization,
  page = 0,
  limit = 10,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'GET_ASSOCIATION_PROPERTIES',
  payload: {
    request: {
      url: '/association/properties',
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} propertyIds
 */
export const BlockAssociationProperties = (Authorization, propertyIds) => ({
  type: 'BLOCK_ASSOCIATION_PROPERTIES',
  payload: {
    request: {
      url: '/association/properties/block',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        property_ids: propertyIds
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} propertyIds
 */
export const ActivateAssociationProperties = (Authorization, propertyIds) => ({
  type: 'ACTIVATE_ASSOCIATION_PROPERTIES',
  payload: {
    request: {
      url: '/association/properties/activate',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        property_ids: propertyIds
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} page
 * @param {*} limit
 * @param {*} order
 * @param {*} orderDirection
 * @param {*} filters
 */
export const GetAssociationAgencyBrokers = (
  Authorization,
  { page, order, orderDirection, filters }
) => ({
  type: 'GET_ASSOCIATION_AGENCY_BROKERS',
  payload: {
    request: {
      url: '/association/brokers',
      method: 'get',
      headers: {
        Authorization
      },
      params: { l: 20, p: page, o: order, d: orderDirection, ...filters }
    }
  }
})

export const BlockAssociationAgencyBrokers = (Authorization, userIds) => ({
  type: 'BLOCK_ASSOCIATION_AGENCY_BROKERS',
  payload: {
    request: {
      url: '/association/brokers/block',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        user_ids: userIds
      }
    }
  }
})

export const ActivateAssociationAgencyBrokers = (Authorization, userIds) => ({
  type: 'ACTIVATE_ASSOCIATION_AGENCY_BROKERS',
  payload: {
    request: {
      url: '/association/brokers/activate',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        user_ids: userIds
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} brokerName
 */
export const SearchAgencyBrokersInAssociation = (
  Authorization,
  brokerName
) => ({
  type: 'SEARCH_AGENCY_BROKERS_IN_ASSOCIATION',
  payload: {
    request: {
      url: '/association/brokers/find',
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        search: brokerName
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} agencyName
 */
export const SearchAgenciesInAssociation = (Authorization, agencyName) => ({
  type: 'SEARCH_AGENCIES_IN_ASSOCIATION',
  payload: {
    request: {
      url: '/association/agencies/find',
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        search: agencyName
      }
    }
  }
})

/**
 *
 * @param {*} email
 * @param {*} invitationCode
 */
export const AcceptAssociationInvitation = (email, invitationCode) => ({
  type: 'ACCEPT_ASSOCIATION_INVITATION',

  payload: {
    request: {
      url: `/association/invitation/${invitationCode}/accept`,
      method: 'post',
      params: {
        email
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} invitationCode
 */
export const RejectAssociationInvitation = (email, invitationCode) => ({
  type: 'REJECT_ASSOCIATION_INVITATION',
  payload: {
    request: {
      url: `/association/invitation/${invitationCode}/reject`,
      method: 'post',
      params: {
        email
      }
    }
  }
})

/**
 *
 * @param {*} email
 * @param {*} invitationCode
 */
export const GetAssociationInvitation = (email, invitationCode) => ({
  type: 'GET_ASSOCIATION_INVITATION',
  payload: {
    request: {
      url: `/association/invitation/${invitationCode}`,
      method: 'get',
      params: {
        email
      }
    }
  }
})

export const GetAssociationPropertyReports = (Authorization, params) => ({
  type: 'GET_ASSOCIATION_PROPERTY_REPORTS',
  payload: {
    request: {
      headers: {
        Authorization
      },
      url: `/association/reports/properties`,
      method: 'get',
      params
    }
  }
})

export const GetAssociationAssesorsReports = (Authorization, params) => ({
  type: 'GET_ASSOCIATION_ASSESORS_REPORTS',
  payload: {
    request: {
      headers: {
        Authorization
      },
      url: `/association/reports/assesors`,
      method: 'get',
      params
    }
  }
})

export const GetAssociationAgenciesReports = (Authorization, params) => ({
  type: 'GET_ASSOCIATION_AGENCIES_REPORTS',
  payload: {
    request: {
      headers: {
        Authorization
      },
      url: `/association/reports/agencies`,
      method: 'get',
      params
    }
  }
})

export const GetAssociationReports = (Authorization, params) => ({
  type: 'GET_ASSOCIATIONS_REPORTS',
  payload: {
    request: {
      headers: {
        Authorization
      },
      url: `/association/reports/associations`,
      method: 'get',
      params
    }
  }
})
