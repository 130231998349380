var _jsxFileName = 'src/lib/generics/FormInputViews/InputView.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/**
 * Used to display a correct input.
 */

var BASE_CLASSNAME = 'input';
var InputView = function InputView(_ref) {
  var id = _ref.id,
      className = _ref.className,
      value = _ref.value,
      type = _ref.type,
      placeholder = _ref.placeholder,
      autoCompleteOn = _ref.autoCompleteOn,
      onChange = _ref.onChange,
      onFocus = _ref.onFocus,
      onBlur = _ref.onBlur,
      disabled = _ref.disabled,
      onKeyPress = _ref.onKeyPress;

  var inputClasses = classNames(className, BASE_CLASSNAME, _defineProperty({}, BASE_CLASSNAME + '--disabled', disabled));
  return React.createElement('input', {
    id: id,
    className: inputClasses,
    value: value,
    type: type,
    placeholder: placeholder,
    autoComplete: autoCompleteOn ? 'on' : 'off',
    onChange: onChange,
    onFocus: onFocus,
    onBlur: onBlur,
    disabled: disabled,
    onKeyPress: onKeyPress,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 26
    },
    __self: _this
  });
};

InputView.propTypes = {
  /** Enables input to make suggestions according to input */
  autoCompleteOn: PropTypes.bool,
  /** Class to be passed */
  className: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** */
  id: PropTypes.string,
  /** Name of the component */
  name: PropTypes.string,
  /** Callback function to be called on onBlur event. */
  onBlur: PropTypes.func,
  /** Callback function to retrieve the current input value. */
  onChange: PropTypes.func,
  /** Callback function to be called on onFocus event. */
  onFocus: PropTypes.func,
  /** */
  onKeyPress: PropTypes.func,
  /** Placeholder to set when no text entered */
  placeholder: PropTypes.string,
  /** Input type, it could be: password, phone, email, password, etc.*/
  type: PropTypes.string,
  /** Current value set in Input. */
  value: PropTypes.any
};

InputView.defaultProps = {
  className: '',
  name: '',
  value: '',
  type: 'text',
  placeholder: '',
  autoCompleteOn: false,
  onChange: function onChange() {}
};

export default InputView;