import React, { useState } from 'react'
import styled from 'styled-components'
import { FormContainer, TextField, SubmitButton, Button } from 'rc'
import { useDispatch, useSelector } from 'react-redux'
import { Cross } from 'rc/Icons'
import { isEmail } from 'rc/helpers/stringHelpers'
import { ShowFlashNotification } from 'reduxActions/services'
import WiggotDevelopment from 'static/logos/wiggot-development.svg'
import { DevelopmentQuotationRequest } from 'reduxActions/development'


const BuyTokensFormMobile = ({ HideModal }) => {

    const dispatch = useDispatch()
    const { fullname, phoneNumber, email } = useSelector(({ profileReducer: { fullname, phoneNumber, email } }) => ({ fullname, phoneNumber, email }))
    const [isLoading, setIsLoading] = useState(false)
    const validateHandler = field => value => {
        switch (field) {
            case 'fullname':
                return value === '' ? 'Campo requerido.' : null
            case 'email':
                return value === ''
                    ? 'Campo requerido.'
                    : !isEmail(value)
                        ? 'Ingresa un correo válido.'
                        : null
            case 'phone':
                if (value) {
                    const PhoneRegex = /^((\+?[0-9]{2})?[0-9]{10})$/gm
                    const res = PhoneRegex.exec(value) === null
                    return res ? 'Ingresa un teléfono válido.' : null
                }
                return 'Campo requerido.'
            // case 'tokens': 
            //   return isFree ? null : !value ? 'Ingresa un valor' : null
            default:
                return null
        }
    }

    const _initForm = {
        name: fullname ? fullname : '',
        email: email ? email : '',
        phone: phoneNumber ? phoneNumber : '',
        tokens: 0,
        free: true,
        section_id: 4
    }

    const handleSubmit = async data => {
        let errors = {}

        Object.keys(data).forEach(key => {
            let res = validateHandler(key)(data[key])
            if (res) errors[key] = res
        })

        if (Object.keys(errors).length > 0) {
            const errObj = new Error()
            errObj.code = 'local-errors'
            errObj.metadata = errors
            throw errObj
        }

        setIsLoading(true)
        await dispatch(DevelopmentQuotationRequest(data))
            .then(res => handleSubmitSuccess())
        // .catch(err => handleSubmitFail(err))
    }

    const handleSubmitSuccess = () => {
        dispatch(ShowFlashNotification('success', 'El equipo de comercial te contactará en breve.'))
        HideModal()
    }

    const handleSubmitFail = (localErrors, fc, submitErrors) => {
        setIsLoading(false)
        if (Object.keys(localErrors).length === 0 && submitErrors) {
            if (submitErrors.code === 'local-errors') {
                fc.setCustomErrors({
                    ...submitErrors.metadata
                })
            }
        }
        dispatch(ShowFlashNotification('danger', 'Ocurrió un error. Vuelvelo a intentar más tarde.'))

    }


    return (
        <Container
            name="developments_quotation"
            initialValues={_initForm}
            onSubmit={handleSubmit}
            onSubmitSuccess={handleSubmitSuccess}
            onSubmitFail={handleSubmitFail}
        >
            <CustomCloseButton onClick={HideModal}>
                <CrossIconStyled />
            </CustomCloseButton>
            <img
                src={WiggotDevelopment}
                alt="wiggot-developments-logo"
                css={`
              width: 50%;
              margin-bottom: ${({ theme }) => theme.remCalc('20')};
            `}
            />
            <CustomText>
                Completa la información y nos contactaremos contigo para brindarte el
                mejor servicio.
            </CustomText>
            <FieldsContainer>
                <TextField
                    name="tokens"
                    label="Cantidad de tokens"
                    placeholder="Cantidad de tokens"
                    required
                    type='number'
                />
                <TextField
                    name="name"
                    label="Nombre completo"
                    placeholder="Nombre completo"
                    validate={validateHandler('name')}
                    required
                />
                <TextField
                    name="email"
                    label="Correo"
                    placeholder="Correo"
                    validate={validateHandler('email')}
                    required
                />
                <TextField
                    name="phone"
                    label="Teléfono"
                    placeholder="Teléfono"
                    validate={validateHandler('phone')}
                    required
                />
            </FieldsContainer>
            <ActionsContainer>
                <Button
                    size="large"
                    variant="outlined"
                    color="gray"
                    onClick={HideModal}
                    disabled={isLoading}
                >
                    Cancelar
                </Button>
                <SubmitButton disabled={isLoading} size="large">
                    Enviar
                </SubmitButton>
            </ActionsContainer>
        </Container>
    )
}


const Container = styled(FormContainer)`
  width: ${({ theme }) => theme.remCalc('640')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.remCalc('40')};
  border-radius: ${({ theme }) => theme.remCalc('20')};
  position: relative;
  ${({ theme }) => theme.media.tabletDown`
    width:100%;
  `}
`
const CustomCloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.remCalc('12')};
  right: ${({ theme }) => theme.remCalc('12')};
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
`
const CrossIconStyled = styled(Cross)`
  ${({ theme }) => theme.box(24, 24)}
  fill: #A0A3BD;
`
const CustomText = styled.span`
  font-weight: 600;
  font-size: ${({ theme }) => theme.remCalc('18')};
  line-height: 150%;
  text-align: center;
  color: #0076FF;
  width: 80%;
  margin-bottom: ${({ theme }) => theme.remCalc('20')};
`
const FieldsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  > * { 
    margin: ${({ theme }) => theme.remCalc('10 0 20 0')};
    width: 100%;
  }
`
const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.remCalc('10')};
  > button { 
    width: 48%;
    border-radius: 30px !important;
  }
`

BuyTokensFormMobile.propTypes = {

}

export default BuyTokensFormMobile