import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  Button,
  Typography,
  TextField
} from 'rc'
import { WiggotIconWarning } from 'components/Icons'
import { Duplicate,
  Export
} from 'rc/Icons'

import {
  GetTokenPersist,
  GetRegenerateTokenPersist
} from 'reduxActions/tokens'

const OfflineMC = () => {

  const dispatch = useDispatch()
  const { token } = useSelector(({ authReducer: { token } }) => ({
    token
  }))

  const [apiKeyPersist, setApiKeyPersist] = useState(null)
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    dispatch(GetTokenPersist(token))
      .then(res => {
        const { payload: { data } = {} } = res
        setApiKeyPersist(data.token)
      })
      .catch(err => {
        console.log({ err }, 'Get tokens persist err')
      })
  }, [token])

  const handlerRegenerateApiKey = () => {
    dispatch(GetRegenerateTokenPersist(token))
      .then(res => {
        const { payload: { data } = {} } = res
        setApiKeyPersist(data.token)
      })
      .catch(err => {
        console.log({ err }, 'Get regenerate tokens persist err')
      })
  }

  const copyText = () => {
    const r = document.createRange()
    const elementId = `apiKeyPersist`
    r.selectNode(document.getElementById(elementId))
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r)
    document.execCommand('copy')
    setIsCopied(true)    
    window.getSelection().removeAllRanges()
    setTimeout(() => {
      setIsCopied(false)
    }, 1200)
  }

  return (
    <>
    <SectionContainerModal>      
      <SectionSubContainer1>                    
            
            <TextContainer>
              <Typography 
                variant="h2" 
                fontWeight={700}
                color="palette.primary.main" 
                css={`margin-bottom: ${({ theme }) => theme.remCalc('16')};`}
                align={'center'}
              >
                API Key
              </Typography>  
              <Typography variant="p" align={'inherit'}  fontsize={14} fontWeight={400} css={`color: #605D75;`}>
              Comparte el API Key con la asociación a la que perteneces para que tengan acceso a tus propiedades y puedan mostrarlas en su sitio web.
              </Typography>
            </TextContainer>
        </SectionSubContainer1>


        <SectionSubContainer>                    
            <WiggotStoreLogoStyled />
            <TextContainer> 
              <Typography variant="p" align={'inherit'} fontsize={12} fontWeight={600} css={`color: #605D75;`}>
              El API Key concede acceso a información privada de los contactos y propiedades registrados en tu cuenta. Es un dato sensible que debes compartir solo con personas de confianza.
              </Typography>
            </TextContainer>
        </SectionSubContainer>

        

        <SectionSubContainer>                    
            <TextContainer> 
              <TextAreaStyled
                id="apiKeyPersist"
                label=""
                name="contact_description"
                multiline
                fullWidth
                required
                value={apiKeyPersist}
              />
              
            </TextContainer>
            <ButtonsContainerApi>
              <ButtonStyledToken 
                onClick={copyText} 
                variant="outlined" 
                css={`border-radius: ${({ theme }) => theme.remCalc('50')} !important;`}
              >
              <DuplicateIcon />
                Copiar
              </ButtonStyledToken>

              <ButtonStyledTokenR
               onClick={() => handlerRegenerateApiKey() }
               css={`border-radius: ${({ theme }) => theme.remCalc('50')} !important;`}
               >
                <RegenerateateIcon />
                Regenerar
              </ButtonStyledTokenR>
            </ButtonsContainerApi>
        </SectionSubContainer>
        <SuccessNotification isCopied={isCopied}>
            ApiKey copiado
          </SuccessNotification>
    </SectionContainerModal>
    </>
  )
}

const WiggotStoreLogoStyled = styled(WiggotIconWarning)`
  width: 45%;
  height: auto;
  margin-right: ${({ theme }) => theme.remCalc('12')};
`
const SectionContainerModal = styled.div`
  width: ${({ theme }) => theme.remCalc('650')};
  max-width: 100%;
  height: ${({ theme }) => theme.remCalc('450')};
  padding: ${({ theme }) => theme.remCalc('20 50')};
`
const SectionSubContainer = styled.div`
  display: flex;
  flex-direction: 'column';
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.remCalc('10')};
  border-radius: ${({ theme }) => theme.remCalc('20')};
  justify-content: space-between;
`
const SectionSubContainer1 = styled.div`
  display: flex;
  flex-direction: 'column';
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.remCalc('10')};
  border-radius: ${({ theme }) => theme.remCalc('20')};
  justify-content: space-between;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;            
  width: auto;
  justify-content: center;
`
const TextAreaStyled = styled(TextField)`
  margin-top: ${({ theme }) => theme.remCalc('20')};
  textarea {
    height: ${({ theme }) => theme.remCalc('100')};
    width:  ${({ theme }) => theme.remCalc('400')};
  }
`
const ButtonsContainerApi = styled.div`
  /* display: flex;  */
  align-items: center;
  width: ${({ isDesktop }) => isDesktop ? '35%' : '50%'};
  /* margin: ${({ theme, isDesktop }) => isDesktop ? theme.remCalc('0 0 0 12') : theme.remCalc('12 0 0 0')}; */
`
const ButtonStyledToken = styled(Button)`
  height: ${({ theme }) => theme.remCalc('46')};  
  width: 100%;  
  margin: ${({ theme }) => theme.remCalc('10')}; 
`
const ButtonStyledTokenR = styled(Button)`
  height: ${({ theme }) => theme.remCalc('46')};  
  width: 100%;  
  margin: ${({ theme }) => theme.remCalc('10')}; 
  color: #fff;
  background-color: #FD7171;
  border-style: solid;
  border-color: #FD7171;

  &&:hover {
  color: #fff;
  background-color: #FD7171;
  border-style: solid;
  border-color: #FD7171;
  }
`
const DuplicateIcon = styled(Duplicate)`
  margin: ${({ theme }) => theme.remCalc('0 10')};
  height: ${({ theme }) => theme.remCalc('16')} !important;
`

const RegenerateateIcon = styled(Export)`
  margin: ${({ theme }) => theme.remCalc('0 10')};
  height: ${({ theme }) => theme.remCalc('16')} !important;
`
const SuccessNotification = styled.span`
  margin-left: ${({ theme }) => theme.remCalc('8')};
  opacity: ${({ isCopied }) => (isCopied ? 1 : 0)};  
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 5px;
  padding: ${({ theme }) => theme.remCalc('2 15')}; 
  transition: all ease 500ms;
`
export default OfflineMC



