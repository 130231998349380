import React from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import { Grid, Typography, Button } from 'rc'

import ExpiredPrime from 'static/img/expired_prime.png'
import VerifyAccountBackground from 'static/img/verify_account_background.png'

const VerifyAccountMC = ({ HideModal }) => {
  return (
    <GridContainerStyled
      container
      direction="column"
      alignItems="space-between"
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="space-between"
        css={`
          height: ${({ theme }) => theme.remCalc('180')};
          background-image: url(${VerifyAccountBackground});
          background-size: cover;
          background-repeat: no-repeat;
          ${({ theme }) => theme.media.desktop`
          background-size: contain;
          height: ${({ theme }) => theme.remCalc('260')};
        `}
        `}
      />
      <Grid
        container
        justify="center"
        alignItems="space-around"
        direction="column"
        css={`
          position: absolute;
          width: 100%;
          height: 100%;
        `}
      >
        <Grid
          container
          direction="column"
          alignItems="space-between"
          css={`
            width: 100%;
            height: 100%;
          `}
        >
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            css={`
              height: ${({ theme }) => theme.remCalc('260')};
            `}
          >
            <Grid container direction="column" justify="center">
              <Grid
                container
                direction="column"
                justify="center"
                css={`
                  width: 35%;
                  margin-top: ${({ theme }) => theme.remCalc('20')};
                `}
              >
                <Typography
                  variant="h2"
                  color="white"
                  textTransform="initial"
                  css={`
                    font-size: ${({ theme }) => theme.remCalc('25')};
                  `}
                >
                  {'Verifica tu cuenta'}
                </Typography>
              </Grid>
              <ImageGridStyled container />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="space-around"
            direction="column"
            css={`
              height: ${({ theme }) => theme.remCalc('180')};
              margin-bottom: ${({ theme }) => theme.remCalc('10')};
            `}
          >
            <Grid
              container
              center
              css={`
                width: 85%;
              `}
            >
              <Typography
                variant="body1"
                css={`
                  text-align: center;
                `}
              >
                Te enviamos un correo, para poder usar las herramientas wiggot,
                confirma tu cuenta.
              </Typography>
            </Grid>
            <Button
              onClick={() => {
                HideModal()
              }}
              css={`
                height: ${({ theme }) => theme.remCalc('50')};
                width: ${({ theme }) => theme.remCalc('220')};
              `}
              id="components-wiggotPrime-mc-button-verifyAccountAccept"
            >
              Entendido
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </GridContainerStyled>
  )
}

VerifyAccountMC.propTypes = {
  HideModal: func
}

const ImageGridStyled = styled(Grid)`
  width: 30%;
  height: ${({ theme }) => theme.remCalc('290')};
  background-image: url(${ExpiredPrime});
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: ${({ theme }) => theme.remCalc('5')};
`

const GridContainerStyled = styled(Grid)`
  width: ${({ theme }) => theme.remCalc('600')};
  max-width: 100%;
  position: relative;
  height: ${({ theme }) => theme.remCalc('500')};
  ${({ theme }) => theme.media.desktop`
    height: ${({ theme }) => theme.remCalc('450')};
  `}
`

export default VerifyAccountMC
