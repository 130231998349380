export const GetRules = Authorization => ({
  type: 'GET_RULES',
  payload: {
    request: {
      method: 'get',
      headers: { Authorization },
      url: `/rules`
    }
  }
})

export const ActivateTrialPlan = Authorization => ({
  type: 'ACTIVATE_PLAN_TRIAL',
  payload: {
    request: {
      method: 'post',
      headers: { Authorization },
      url: `/advanced/trial`
    }
  }
})
