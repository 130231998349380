import React from 'react'
import { createSvgIcon } from 'rc'

export default createSvgIcon(
  <>
    <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
  </>,
  'MenuIcon',
  '0 -960 960 960'
)
