import React from 'react'

import { ReduxForm as Form } from 'components'
import { FormGroup, TextInput } from 'c3-ui/elements/FormInputs'

import { LABEL_PROPS, AGENCY_UPGRADE_FORM } from './_constants'

const AgencyUpgradeForm = () => {
  return (
    <Form form={AGENCY_UPGRADE_FORM} noResponsive destroyOnUnmount={false}>
      <FormGroup>
        <TextInput
          id="profile-agencyUpgradeDataForm-mc-input-agencyName"
          name="agencyName"
          label="NOMBRE DE LA EMPRESA"
          labelProps={LABEL_PROPS}
          validations={[{ validationName: 'emptiness' }]}
          isRequired
        />
        <TextInput
          id="profile-agencyUpgradeDataForm-mc-input-businessName"
          name="businessName"
          label="RAZÓN SOCIAL"
          labelProps={LABEL_PROPS}
          validations={[{ validationName: 'emptiness' }]}
          isRequired
        />
        <TextInput
          id="profile-agencyUpgradeDataForm-mc-input-contactEmail"
          name="contactEmail"
          label="CORREO DE CONTACTO"
          labelProps={LABEL_PROPS}
          validations={[
            { validationName: 'emptiness' },
            {
              validationName: 'validEmail'
            }
          ]}
          isRequired
        />
        <TextInput
          id="profile-agencyUpgradeDataForm-mc-input-agencyPhone"
          name="agencyPhone"
          label="TELÉFONO"
          labelProps={LABEL_PROPS}
          type="number"
          noCommas
          validations={[
            {
              validationName: 'checkSize',
              params: {
                type: 'moreThan',
                size: '7'
              }
            }
          ]}
        />
      </FormGroup>
    </Form>
  )
}

AgencyUpgradeForm.propTypes = {}

export default AgencyUpgradeForm
