/**
 *
 * @param {*} Authorization
 */
export const CreateEmptyDevelopment = Authorization => {
  return {
    type: 'CREATE_EMPTY_DEVELOPMENT',
    payload: {
      request: {
        url: `/development/generate_empty`,
        method: 'post',
        headers: {
          Authorization
        }
      }
    }
  }
}

/**
 *
 * @param {*} Authorization
 * @param {Object} data - DevelopmentInfo
 * @param {string} data.development_id
 * @param {string} data.development_name
 * @param {number} data.delivery_month
 * @param {number} data.delivery_year
 * @param {string} data.development_description
 * @param {string} data.logo
 * @param {number} data.contact_phone
 * @param {string} data.contact_email
 * @param {number} data.whatsapp
 * @param {string} data.country
 * @param {string} data.state
 * @param {string} data.city
 * @param {string} data.neighborhood
 * @param {string} data.street
 * @param {string} data.address_reference
 * @param {string} data.latitude
 * @param {string} data.longitude
 * @param {string} data.location_description
 * @param {string} data.youtube_url
 * @param {string} data.matterport_url
 * @param {boolean} data.cash_payment
 * @param {boolean} data.credit_payment
 * @param {boolean} data.credit_mortgage
 * @param {string} data.credit_details
 * @param {number} data.commission
 * @param {boolean} data.share_commission
 * @param {number} data.shared_commission
 * @param {string[]} data.images
 * @param {string[]} data.amenities
 */
export const UpdateDevelopment = (Authorization, data) => {
  return {
    type: 'UPDATE_DEVELOPMENT',
    payload: {
      request: {
        url: `/development`,
        method: 'put',
        headers: {
          Authorization
        },
        data
      }
    }
  }
}
/**
 *
 * @param {*} Authorization
 * @param {Object} data - Model info
 * @param {string} data.development_id
 * @param {string} data.model_name
 * @param {string} data.model_description
 * @param {number} data.units_models
 * @param {string} data.property_type
 * @param {string} data.cover_image
 * @param {string} data.ground_shape
 * @param {string} data.ground_relief
 * @param {boolean} data.ground_active":
 * @param {boolean} data.construction_active
 * @param {boolean} data.garden_active
 * @param {boolean} data.terrace_active
 * @param {number} data.ground_size
 * @param {number} data.construction_size
 * @param {number} data.garden_size
 * @param {number} data.terrace_size
 * @param {number} data.floors
 * @param {number} data.bedrooms
 * @param {number} data.bathrooms
 * @param {number} data.half_bathrooms
 * @param {number} data.parking_lots
 * @param {number} data.cellar
 * @param {boolean} data.presale_active
 * @param {boolean} data.sale_active
 * @param {boolean} data.lease_active
 * @param {number} data.presale_since
 * @param {number} data.presale_until
 * @param {number} data.sale_since
 * @param {number} data.sale_until
 * @param {number} data.lease_since
 * @param {number} data.lease_until
 * @param {string} data.youtube_url
 * @param {string} data.matterport_url
 * @param {string[]} data.model_images
 */
export const AddDevelopmentModel = (Authorization, data) => {
  return {
    type: 'ADD_MODEL_TO_DEVELOPMENT',
    payload: {
      request: {
        url: `/development/model`,
        method: 'post',
        headers: {
          Authorization
        },
        data
      }
    }
  }
}

/**
 *
 * @param {string} Authorization
 * @param {string} developmentId
 */
export const GetDevelopmentModelsBasics = (Authorization, developmentId) => {
  return {
    type: 'GET_DEVELOPMENT_MODELS_BASICS',
    payload: {
      request: {
        url: `/developments/${developmentId}/models/basics`,
        method: 'get',
        headers: {
          Authorization
        }
      }
    }
  }
}

/**
 *
 * @param {string} Authorization
 * @param {string} developmentId
 */
export const GetDevelopmentModels = (Authorization, developmentId) => {
  return {
    type: 'GET_DEVELOPMENT_MODELS',
    payload: {
      request: {
        url: `/developments/${developmentId}/models`,
        method: 'get',
        headers: {
          Authorization
        }
      }
    }
  }
}

/**
 *
 * @param {string} Authorization
 * @param {string} developmentId
 * @param {object} model
 */
export const UpdateDevelopmentModel = (Authorization, developmentId, model) => {
  return {
    type: 'UPDATE_DEVELOPMENT_MODEL',
    payload: {
      request: {
        url: `/development/model`,
        method: 'put',
        headers: {
          Authorization
        },
        data: {
          development_id: developmentId,
          ...model
        }
      }
    }
  }
}

/**
 *
 * @param {string} Authorization
 * @param {string} developmentId
 */
export const PublishDevelopment = (Authorization, developmentId) => {
  return {
    type: 'PUBLISH_DEVELOPMENT',
    payload: {
      request: {
        url: `/developments/publish`,
        method: 'post',
        headers: {
          Authorization
        },
        data: {
          development_id: developmentId
        }
      }
    }
  }
}

/**
 *
 * @param {string} Authorization
 * @param {string} modelId
 */
export const DeleteDevelopmentModel = (Authorization, modelId) => {
  return {
    type: 'DELETE_DEVELOPMENT_MODEL',
    payload: {
      request: {
        url: `/development/model`,
        method: 'delete',
        headers: {
          Authorization
        },
        data: {
          model_development_id: modelId
        }
      }
    }
  }
}

/**
 *
 * @param {string} Authorization
 * @param {string} type - list (My developments) or all (All published developments)
 */
export const GetDevelopments = (
  Authorization,
  type,
  name,
  page = 0,
  limit = 50
) => {
  return {
    type: 'GET_DEVELOPMENTS',
    payload: {
      request: {
        url: `/development/${type}?p=${page}&l=${limit}&n=${name || ''}`,
        method: 'get',
        headers: {
          Authorization
        }
      }
    }
  }
}

/**
 *
 * @param {string} Authorization
 * @param {string} developmentId
 */
export const DeleteDevelopment = (Authorization, developmentId) => {
  return {
    type: 'DELETE_DEVELOPMENT',
    payload: {
      request: {
        url: `/developments/${developmentId}`,
        method: 'delete',
        headers: {
          Authorization
        }
      }
    }
  }
}

/**
 *
 * @param {string} Authorization
 * @param {string} developmentId
 */
export const DevelopmentChangeStatusToDraft = (Authorization, developmentId) => {
  return {
    type: 'DEVELOPMENT_CHANGE_STATUS_TO_DRAFT',
    payload: {
      request: {
        url: `/developments/${developmentId}/draft`,
        method: 'post',
        headers: {
          Authorization
        }
      }
    }
  }
}

/**
 * View detail of development with models
 * @param {string} Authorization
 * @param {string} developmentId
 * @param {string} type - Could be view or nothing
 */
export const GetDevelopment = (Authorization, developmentId, type, showGenericNeighborhood = false) => {
  const params = {}

  if (showGenericNeighborhood) params.show_generic_neigborhood = true

  return {
    type: 'GET_DEVELOPMENT',
    payload: {
      request: {
        url: `/developments/${developmentId}${type ? `/${type}` : ''}`,
        method: 'get',
        headers: {
          Authorization
        },
        params
      }
    }
  }
}

/**
 * View detail of development with models
 * @param {string} Authorization
 * @param {string} developmentId
 * @param {string} type - Could be view or nothing
 */
export const GetDevelopmentSearch = (Authorization, development_id, params) => {
  return {
    type: 'GET_DEVELOPMENT_SEARCH',
    payload: {
      request: {
        url: `/properties/search`,
        method: 'get',
        params: {
          ...params,
          development_id,
          models: true
        },
        headers: {
          Authorization
        }
      }
    }
  }
}

/**
 * @param {string} Authorization
 * @param {string} developmentId
 */
export const GenerateDevelopmentDataSheetId = (
  Authorization,
  developmentId,
  requirementId,
  type
) => {
  return {
    type: 'GENERATE_DEVELOPMENT_DATASHEET_ID',
    payload: {
      request: {
        url: `/datasheet/share/development${type ? `?type=${type}` : ''}`,
        method: 'post',
        headers: {
          Authorization
        },
        data: {
          development_id: [developmentId],
          requirement_id: requirementId,
          type
        }
      }
    }
  }
}

export const GetModelInfo = (Authorization, modelId) => {
  return {
    type: 'GET_MODEL_INFO',
    payload: {
      request: {
        url: `/developments/model/${modelId}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const UploadModel = (Authorization, data) => {
  return {
    type: 'UPLOAD_MODEL',
    payload: {
      request: {
        url: `/developments/model`,
        method: 'post',
        headers: { Authorization },
        data
      }
    }
  }
}

export const GetDevelopmentBasicInfo = Authorization => {
  return {
    type: 'GET_DEVELOPMENT_BASIC_INFO',
    payload: {
      request: {
        url: '/developments/basic_information',
        method: 'get',
        headers: {
          Authorization
        }
      }
    }
  }
}

export const UpdateModel = (Authorization, data) => {
  return {
    type: 'UPDATE_MODEL',
    payload: {
      request: {
        url: `/developments/model`,
        method: 'put',
        headers: { Authorization },
        data
      }
    }
  }
}

export const CreateDevelopment = (Authorization, data) => {
  return {
    type: 'CREATE_DEVELOPMENT',
    payload: {
      request: {
        url: `developments/submit`,
        method: 'post',
        headers: {
          Authorization
        },
        data
      }
    }
  }
}

export const GetModelInfoToUpdate = (Authorization, developmentId) => {
  return {
    type: 'GET_MODEL_INFO_TO_UPDATE',
    payload: {
      request: {
        url: `/developments/model/${developmentId}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetModelBasicInfo = (Authorization, developmentId) => {
  return {
    type: 'GET_MODEL_INFO_TO_UPDATE',
    payload: {
      request: {
        url: `/developments/model/basic_information/${developmentId}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetDevelopmentForEdit = (Authorization, developmentId, showGenericNeighborhood = false) => {
  const params = {}

  if (showGenericNeighborhood) params.show_generic_neigborhood = true

  return {
    type: 'GET_DEVELOPMENT_FOR_EDIT',
    payload: {
      request: {
        url: `/developments/${developmentId}`,
        method: 'get',
        headers: {
          Authorization
        },
        params
      }
    }
  }
}

export const GetDevelopmentView = (Authorization, developmentId) => {
  return {
    type: 'GET_DEVELOPMENT_VIEW',
    payload: {
      request: {
        url: `/developments/${developmentId}/view`,
        method: 'get',
        headers: {
          Authorization
        }
      }
    }
  }
}

export const UpdateDevelopmentNew = (Authorization, data, developmentId) => {
  return {
    type: 'UPDATE_DEVELOPMENT_NEW',
    payload: {
      request: {
        url: `/developments/update/${developmentId}`,
        method: 'put',
        headers: {
          Authorization
        },
        data
      }
    }
  }
}

export const PublishNewDevelopment = (Authorization, developmentId) => {
  return {
    type: 'PUBLISH_NEW_DEVELOPMENT',
    payload: {
      request: {
        url: `/developments/${developmentId}/publish`,
        method: 'post',
        headers: {
          Authorization
        }
      }
    }
  }
}

export const ActivateDeveloperRole = Authorization => ({
  type: 'ACTIVATE_DEVELOPER_ROLE',
  payload: {
    request: {
      url: `/user/add_role_developer`,
      method: 'put',
      headers: {
        Authorization
      }
    }
  }
})

export const DevelopmentsReportsTops = Authorization => {
  return {
    type: 'DEVELOPMENTS_REPORTS_TOPS',
    payload: {
      request: {
        url: `/reports/top_summary`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const DevelopmentsReportsTables = (Authorization, { l, p, f_table }) => {
  return {
    type: 'DEVELOPMENTS_REPORTS_TABLES',
    payload: {
      request: {
        url: `/reports?l=${l}&p=${p}&f_table=${f_table}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const DevelopmentQuotationRequest = data => {
  return {
    type: 'DEVELOPMENT_QUOTATION_REQUEST',
    payload: {
      request: {
        url: `/tokens/lead`,
        method: 'POST',
        headers: {},
        data
      }
    }
  }
}

export const AssignDevelopmentAdmin = (Authorization, user_id) => {
  return {
    type: 'ASSIGN_DEVELOPMENT_ADMIN',
    payload: {
      request: {
        url: `/developments/admins/assesor`,
        method: 'POST',
        headers: { Authorization },
        data: { user_id }
      }
    }
  }
}

export const DeleteDevelopmentAdmin = (Authorization, users_id) => {
  return {
    type: 'DELETE_DEVELOPMENT_BROKER',
    payload: {
      request: {
        url: `/developments/admins/assesor`,
        method: 'DELETE',
        headers: { Authorization },
        data: {
          users_id
        }
      }
    }
  }
}

export const GetDevelopmentAdmins = Authorization => {
  return {
    type: 'DEVELOPMENTS_ADMINS',
    payload: {
      request: {
        url: `/developments/admins/assesor?l=10&p=0`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetDevelopmentAdvisorsList = (
  Authorization,
  limit,
  page,
  filters
) => {
  return {
    type: 'DEVELOPMENT_ADVISORS_LIST',
    payload: {
      request: {
        url: `/developers/advisors`,
        method: 'get',
        headers: { Authorization },
        params: {
          l: limit,
          p: page,
          ...filters
        }
      }
    }
  }
}

export const GetDevelopmentBrokersAssigned = (Authorization, developmentId) => {
  return {
    type: 'DEVELOPMENTS_BROKERS_ASSIGNED',
    payload: {
      request: {
        url: `/developments/asessors/${developmentId}?l=10&p=0`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const AssignDevelopmentBroker = (
  Authorization,
  development_id,
  user_id
) => {
  return {
    type: 'ASSIGN_DEVELOPMENT_BROKER',
    payload: {
      request: {
        url: `/developments/admin/assing_development/${development_id}`,
        method: 'POST',
        headers: { Authorization },
        data: {
          user_id
        }
      }
    }
  }
}

export const UnassignDevelopmentBroker = (
  Authorization,
  development_id,
  user_id
) => {
  return {
    type: 'UNASSIGN_DEVELOPMENT_BROKER',
    payload: {
      request: {
        url: `/developments/admin/unassing_development/${development_id}`,
        method: 'DELETE',
        headers: { Authorization },
        data: {
          user_id
        }
      }
    }
  }
}

export const GetDevelopmentList = (Authorization, without_token = false) => {
  const url = '/developments/basics'
  return {
    type: 'GET_DEVELOPMENT_LIST',
    payload: {
      request: {
        url: without_token ? url.concat('?without_token=true') : url,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetDevelopmentSitesList = Authorization => {
  return {
    type: 'GET_DEVELOPMENT_SITES_LIST',
    payload: {
      request: {
        url: `/development_sites`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}
export const GetDevelopmentInventory = (
  Authorization,
  developmentId,
  l = 100,
  p = 0,
  userOwned,
  filters
) => {
  return {
    type: 'GET_DEVELOPMENT_INVENTORY',
    payload: {
      request: {
        url: `/developments/inventory/get_inventory`,
        params: {
          development_id: developmentId,
          l,
          p,
          status: !userOwned ? 'AVAILABLE' : '',
          ...filters
        },
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetDevelopmentsWithoutSite = Authorization => {
  return {
    type: 'GET_DEVELOPMENTS_WITHOUT_SITE',
    payload: {
      request: {
        url: `/development_sites/developments`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const UpdateUnitStatus = (Authorization, data) => ({
  type: 'UPDATE_UNIT_STATUS',
  payload: {
    request: {
      url: `/developments/inventory/change_status_loader_inventory`,
      method: 'put',
      headers: {
        Authorization
      },
      data
    }
  }
})

export const UpdateInventory = (Authorization, data) => ({
  type: 'UPDATE_INVENTORY',
  payload: {
    request: {
      url: `/developments/inventory/update_inventory`,
      method: 'put',
      headers: {
        Authorization
      },
      data
    }
  }
})

export const DeleteUnitInventory = (Authorization, development_units_id) => {
  return {
    type: 'DELETE_UNIT_INVENTORY',
    payload: {
      request: {
        url: `/developments/inventory/unit_delete`,
        method: 'delete',
        headers: {
          Authorization
        },
        data: {
          development_units_id
        }
      }
    }
  }
}

export const UpdatePriceInventory = (Authorization, data) => ({
  type: 'UPDATE_PRICE_INVENTORY',
  payload: {
    request: {
      url: `/developments/inventory/update_price_inventory`,
      method: 'put',
      headers: {
        Authorization
      },
      data
    }
  }
})

export const UploadUnitTemplate = (token, data, onUploadProgress) => {
  const formData = new FormData()
  formData.append('fileExcel', data)
  return {
    type: 'UPLOAD_UNIT_TEMPLATE',
    payload: {
      client: 'inventoryTemplates',
      request: {
        headers: {
          filename: 'fileExcel'
        },
        onUploadProgress,
        method: 'post',
        url: `/upload_units`,
        params: {
          token
        },
        data: formData
      }
    }
  }
}

export const UploadPlansTemplate = (
  token,
  developmentId,
  data,
  onUploadProgress
) => {
  const formData = new FormData()
  formData.append('fileExcel', data)
  return {
    type: 'UPLOAD_PLANS_TEMPLATE',
    payload: {
      client: 'inventoryTemplates',
      request: {
        headers: {
          filename: 'fileExcel'
        },
        onUploadProgress,
        method: 'post',
        url: `/upload_plans/${developmentId}`,
        params: {
          token
        },
        data: formData
      }
    }
  }
}
export const DevelopmentSitePrefill = (Authorization, developmentId) => {
  return {
    type: 'DEVELOPMENT_SITE_PREFILL',
    payload: {
      request: {
        url: `/development_sites/${developmentId}/prefilled`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const DownloadInventoryTemplate = (Authorization, developmentId) => {
  return {
    type: 'DOWNLOAD_INVENTORY_TEMPLATE',
    payload: {
      client: 'inventoryTemplates',
      request: {
        url: `/${developmentId}?token=${Authorization}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}
export const GetDevelopmentSite = (Authorization, developmentSiteId) => {
  return {
    type: 'GET_DEVELOPMENT_SITE',
    payload: {
      request: {
        url: `/development_sites/${developmentSiteId}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetPlans = (Authorization, developmentId) => {
  return {
    type: 'GET_PLANS',
    payload: {
      request: {
        url: `/developments/${developmentId}/plans`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}
export const CreateDevelopmentSite = (Authorization, data) => {
  return {
    type: 'CREATE_DEVELOPMENT_SITE',
    payload: {
      request: {
        url: '/development_sites',
        method: 'post',
        headers: { Authorization },
        data
      }
    }
  }
}

export const DeletePlans = (Authorization, developmentId) => {
  return {
    type: 'DELETE_PLANS',
    payload: {
      request: {
        url: `/developments/${developmentId}/plans`,
        method: 'delete',
        headers: {
          Authorization
        }
      }
    }
  }
}
export const AvailableContactBrokers = (
  Authorization,
  developmentId,
  query
) => {
  return {
    type: 'AVAILABLE_CONTACT_BROKERS',
    payload: {
      request: {
        url: `/development_sites/${developmentId}/available_advisors?name=${query}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}
export const DeleteDevelopmentSite = (Authorization, developmentSiteId) => {
  return {
    type: 'DELETE_DEVELOPMENT_SITE',
    payload: {
      request: {
        url: `/development_sites/${developmentSiteId}`,
        method: 'delete',
        headers: { Authorization }
      }
    }
  }
}
export const UnpublishDevelopmentSite = (Authorization, developmentSiteId) => {
  return {
    type: 'UNPUBLISH_DEVELOPMENT_SITE',
    payload: {
      request: {
        url: `/development_sites/${developmentSiteId}/unpublish`,
        method: 'patch',
        headers: { Authorization }
      }
    }
  }
}
export const UpdateDevelopmentSite = (
  Authorization,
  developmentSiteId,
  data
) => {
  return {
    type: 'UPDATE_DEVELOPMENT_SITE',
    payload: {
      request: {
        url: `/development_sites/${developmentSiteId}`,
        method: 'put',
        headers: { Authorization },
        data
      }
    }
  }
}
export const PublishDevelopmentSite = (
  Authorization,
  developmentSiteId,
  data
) => {
  return {
    type: 'PUBLISH_DEVELOPMENT_SITE',
    payload: {
      request: {
        url: `/development_sites/${developmentSiteId}/publish`,
        method: 'patch',
        headers: { Authorization },
        data
      }
    }
  }
}
export const GetModelUnitQuotationFilters = (
  Authorization,
  development_model_id,
  towF,
  oriF
) => {
  return {
    type: 'GET_MODEL_UNIT_QUOTATION_FILTERS',
    payload: {
      request: {
        url: `developments/plans/filters/${development_model_id}?tower=${towF}&orientation=${oriF}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}
export const GetModelUnitQuotationPrices = (Authorization, unitId) => {
  return {
    type: 'GET_MODEL_UNIT_QUOTATION_Prices',
    payload: {
      request: {
        url: `developments/plans/${unitId}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}
export const UpdateExclusivity = (
  Authorization,
  developmentId,
  exclusivity
) => {
  return {
    type: 'UPDATE_EXCLUSIVITY',
    payload: {
      request: {
        url: `/developments/update/${developmentId}/exclusivity`,
        method: 'put',
        headers: {
          Authorization
        },
        data: {
          exclusivity
        }
      }
    }
  }
}

export const GetDevelopmentPlatforms = Authorization => {
  return {
    type: 'GET_DEVELOPMENT_PLATFORMS',
    payload: {
      request: {
        url: `/developers/portals`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetDevelopmentUnitsFilters = (
  Authorization,
  developmentId,
  status
) => {
  return {
    type: 'GET_DEVELOPMENT_UNITS_FILTERS',
    payload: {
      request: {
        url: `/developments/inventory/filter/${developmentId}/units${
          status ? `?status=${status}` : ''
        }`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetUnitInfo = (Authorization, unitId) => {
  return {
    type: 'GET_UNIT_INFO',
    payload: {
      request: {
        url: `/developments/inventory/unit/${unitId}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const PutUnitsDraftModal = (Authorization, developmentId) => ({
  type: 'PUT_UNITS_DRAFT_MODAL',
  payload: {
    request: {
      url: `/developments/${developmentId}/units_model_draft`,
      method: 'put',
      headers: {
        Authorization
      }
    }
  }
})

export const UpdateShowUnits = (Authorization, developmentId) => ({
  type: 'UPDATE_SHOW_UNITS',
  payload: {
    request: {
      url: `/developments/${developmentId}/show_units`,
      method: 'put',
      headers: {
        Authorization
      },
      data: {}
    }
  }
})

export const GetUnitsInventorySummary = (Authorization, developmentId) => ({
  type: 'GET_UNITS_INVENTORY_SUMMARY',
  payload: {
    request: {
      url: `/developments/inventory/summary/${developmentId}`,
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const UpdateShowPlans = (Authorization, developmentId, show_plans) => ({
  type: 'UPDATE_SHOW_PLANS',
  payload: {
    request: {
      url: `/developments/${developmentId}/show_plans`,
      method: 'put',
      headers: {
        Authorization
      },
      data: {
        show_plans
      }
    }
  }
})

export const GetUnitsInventoryHistory = (
  Authorization,
  developmentId,
  { p = '', l = '', model = '', type = '', broker = '', order = '' } = {
    p: 0,
    l: 10,
    model: '',
    type: '',
    broker: '',
    order: ''
  }
) => ({
  type: 'GET_UNITS_INVENTORY_HISTORY',
  payload: {
    request: {
      url: `/developments/inventory/history/${developmentId}?p=${p}&l=${l}${
        model && `&model=${model}`
      }${type && `&type=${type}`}${broker && `&broker=${broker}`}${
        order && `&order=${order}`
      }`,
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const GetUnitsInventoryHistoryFilters = (
  Authorization,
  developmentId
) => ({
  type: 'GET_UNITS_INVENTORY_HISTORY_FILTERS',
  payload: {
    request: {
      url: `/developments/inventory/history/filters_data/${developmentId}`,
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const JoinToSalesForce = (Authorization, developmentId, questions = {}) => ({
  type: 'JOIN_TO_SALES_FORCE',
  payload: {
    request: {
      url: `/developments/${developmentId}/sales-force/join`,
      method: 'post',
      headers: {
        Authorization
      },
      data: questions
    }
  }
})

export const DisjoinToSalesForce = (Authorization, developmentId, questions = {}) => ({
  type: 'DISJOIN_TO_SALES_FORCE',
  payload: {
    request: {
      url: `/developments/${developmentId}/sales-force/disjoin`,
      method: 'post',
      headers: {
        Authorization
      },
      data: questions
    }
  }
})
