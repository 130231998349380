import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
      fill="#25252B"
    />
    <path
      d="M33.3119 30.2418C33.3119 30.8082 33.144 31.362 32.8294 31.833C32.5148 32.3041 32.0677 32.6714 31.5444 32.8884C31.0212 33.1054 30.4454 33.1625 29.8897 33.0524C29.334 32.9423 28.8235 32.67 28.4225 32.2698C28.0216 31.8696 27.7483 31.3596 27.6371 30.8042C27.5259 30.2487 27.5818 29.6728 27.7978 29.1491C28.0138 28.6255 28.3802 28.1776 28.8507 27.8621C29.3211 27.5466 29.8745 27.3776 30.441 27.3765C31.2009 27.3765 31.9297 27.6783 32.4671 28.2157C33.0044 28.753 33.3063 29.4818 33.3063 30.2418"
      fill="#FFC834"
    />
    <path
      d="M47.2577 31.0874C47.2185 31.0223 47.1753 30.9596 47.1283 30.8999L31.9937 15.7671C31.9634 15.714 31.9302 15.6627 31.8943 15.6133C31.4843 15.2198 30.9381 15 30.3698 15C29.8015 15 29.2552 15.2198 28.8452 15.6133C28.8087 15.6627 28.7749 15.714 28.744 15.7671L13.6093 30.8999C13.4058 31.1054 13.2468 31.3507 13.1423 31.6204C13.0378 31.8901 12.9899 32.1785 13.0018 32.4675C13.0018 32.4675 13.0018 32.4788 13.0018 32.4844V41.4609C13.0067 41.961 13.2084 42.439 13.5633 42.7914C13.9181 43.1437 14.3975 43.3422 14.8976 43.3436H46.0051C46.5071 43.3416 46.988 43.1412 47.3428 42.786C47.6976 42.4309 47.8976 41.9498 47.8991 41.4478V32.4788C47.8971 32.2153 47.8396 31.9553 47.7304 31.7155C47.6212 31.4758 47.4627 31.2618 47.2652 31.0874H47.2577ZM17.551 32.9626L30.3773 20.1363L43.3592 33.1182V39.1188H17.5435L17.551 32.9626Z"
      fill="white"
    />
  </Fragment>,
  'WiggotPrimeNotificationLogo',
  '0 5 60 50'
)
