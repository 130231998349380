var _jsxFileName = 'src/lib/elements/Layouts/PageTitleWrapper.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';

import { PageContainer, PageTopLayout } from './';

var PageTitleWrapper = function PageTitleWrapper(_ref) {
  var pageName = _ref.pageName,
      topContent = _ref.topContent,
      topBarClassName = _ref.topBarClassName,
      contentClassName = _ref.contentClassName,
      children = _ref.children;

  return React.createElement(
    PageContainer,
    {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 14
      },
      __self: _this
    },
    React.createElement(
      PageTopLayout,
      {
        pageName: pageName,
        className: 'page-title-wrapper__top-bar ' + (topBarClassName ? topBarClassName : ''),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 15
        },
        __self: _this
      },
      topContent
    ),
    React.createElement(
      'div',
      {
        className: 'page-title-wrapper__content ' + (contentClassName ? contentClassName : ''),
        __source: {
          fileName: _jsxFileName,
          lineNumber: 23
        },
        __self: _this
      },
      children
    )
  );
};

PageTitleWrapper.propTypes = {
  /** */
  children: PropTypes.any,
  /** */
  contentClassName: PropTypes.string,
  /** */
  pageName: PropTypes.string,
  /** */
  topBarClassName: PropTypes.string,
  /** */
  topContent: PropTypes.any,
  /** */
  topContentIsShown: PropTypes.bool
};

export default PageTitleWrapper;