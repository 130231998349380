import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Grid, Typography } from 'rc'
import { PublishDevelopment } from 'reduxActions/development'
import { Button } from 'components'
import { ShowFlashNotification, HideModal } from 'reduxActions/services'
import { LABELS_BY_KEY } from 'utils/constants'
const PrepublishDevelopmentMC = ({
  uncompletedDevelopmentFields,
  uncompletedModelFields,
  token,
  PublishDevelopment,
  developmentId,
  ShowFlashNotification,
  HideModal
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const hasUncompletedFields =
    uncompletedDevelopmentFields.length > 0 || uncompletedModelFields.length > 0

  if (
    uncompletedModelFields.length > 0 &&
    typeof uncompletedModelFields[0] === 'object'
  ) {
    uncompletedModelFields = uncompletedModelFields[0].empty_fields
  }
  const history = useHistory()

  const ReadyToPublishContent = () => {
    return (
      <Fragment>
        <Typography
          variant="h2"
          textTransform="initial"
          color="palette.primary.main"
          align="center"
          display="block"
        >
          Tu desarrollo ya puede publicarse
        </Typography>
        <Typography
          variant="body1"
          css={`
            margin-top: ${({ theme }) => theme.remCalc('15')};
            width: ${({ theme }) => theme.remCalc('335')};
          `}
          align="center"
          display="block"
        >
          Ya has completado los campos requeridos para publicar tu desarrollo en
          wiggot.
        </Typography>
        <Typography
          variant="h3"
          css={`
            margin-top: ${({ theme }) => theme.remCalc('23')};
          `}
          align="center"
          display="block"
        >
          ¿Quieres publicar tu desarrollo?
        </Typography>
        <Grid
          container
          css={`
            margin-top: ${({ theme }) => theme.remCalc('30')};
            ${({ theme }) => theme.media.desktop`
        justify-content: space-between;
      `}
          `}
        >
          <Grid item flat md={11}>
            <Button
              type="tertiary"
              full
              onClick={() => history.push('/developments')}
            >
              Guardar borrador
            </Button>
          </Grid>
          <Grid item flat md={11}>
            <Button
              full
              onClick={() => {
                setIsLoading(true)
                const publish = async () => {
                  try {
                    await PublishDevelopment(token, developmentId)
                    setIsLoading(false)
                    history.push('/developments')
                    setTimeout(() => {
                      ShowFlashNotification(
                        'success',
                        'Tu desarrollo se publico correctamente.'
                      )
                    }, 0)
                  } catch (error) {
                    setIsLoading(false)

                    const {
                      error: {
                        response: { data: { error: { code } = {} } = {} } = {}
                      } = {}
                    } = error

                    switch (code) {
                      case 'EMPTY_FIELDS':
                        ShowFlashNotification(
                          'danger',
                          'Necesitas completar el registro de tus modelos.'
                        )
                        break
                      case 'EMPTY_MODEL':
                        ShowFlashNotification(
                          'danger',
                          'Necesitas al menos un modelo para publicar.'
                        )
                        break
                      default:
                        return
                    }
                  }
                }
                publish()
              }}
              disabled={isLoading}
            >
              Publicar desarrollo
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
  const DevelopmentNotCompletedContent = () => {
    const customLabelsByKey = {
      ...LABELS_BY_KEY,
      INSUFFICIENT_IMAGES: 'Necesitas agregar por lo menos tres imágenes',
      EMPTY_MODEL: 'Necesitas por lo menos un modelo',
      ALLS_MODELS_INCOMPLETE: 'Todos los modelos están incompletos.'
    }
    return (
      <Fragment>
        <Typography
          variant="h2"
          textTransform="initial"
          color="palette.primary.main"
          align="center"
          display="block"
        >
          Hacen falta algunos campos para publicar
        </Typography>
        <Typography
          variant="body1"
          css={`
            margin-top: ${({ theme }) => theme.remCalc('15')};
            margin-left: auto;
            margin-right: auto;
            width: ${({ theme }) => theme.remCalc('335')};
          `}
          align="center"
          display="block"
        >
          Ve y completa los siguientes campos requeridos para poder publicar tu
          desarrollo.
        </Typography>
        <div
          css={`
            ${({ theme }) => theme.bordered('1', theme.palette.base.grayMedium)}
            padding: ${({ theme }) => theme.remCalc('5 20')};
            margin-top: ${({ theme }) => theme.remCalc('20')};
          `}
        >
          {uncompletedDevelopmentFields
            .map(field => customLabelsByKey[field])
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((field, index) => (
              <Typography key={index} variant="body1" display="block">
                {field}
              </Typography>
            ))}
          {uncompletedModelFields.length > 0 ? (
            <div
              css={`
                margin-top: 5px;
              `}
            >
              <Typography variant="subtitle1" display="block">
                Modelos:
              </Typography>
              <div
                css={`
                  padding-left: 5px;
                `}
              >
                {uncompletedModelFields
                  .map(field => customLabelsByKey[field])
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .map((field, index) => (
                    <Typography key={index} variant="body1" display="block">
                      {field}
                    </Typography>
                  ))}
              </div>
            </div>
          ) : null}
        </div>
        <Grid
          container
          css={`
            margin-top: ${({ theme }) => theme.remCalc('25')};
            ${({ theme }) => theme.media.desktop`
              justify-content: space-between;
            `}
          `}
        >
          <Grid item flat md={11}>
            <Button
              type="tertiary"
              full
              onClick={() => history.push('/developments')}
            >
              Guardar borrador
            </Button>
          </Grid>
          <Grid item flat md={11}>
            <Button full onClick={HideModal}>
              Completar campos
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
  return (
    <Grid
      container
      direction="column"
      css={`
        padding: ${({ theme }) => theme.remCalc('30')};
        width: ${({ theme }) => theme.remCalc('470')};
        max-width: 100%;
      `}
    >
      {hasUncompletedFields ? (
        <DevelopmentNotCompletedContent></DevelopmentNotCompletedContent>
      ) : (
        <ReadyToPublishContent></ReadyToPublishContent>
      )}
    </Grid>
  )
}

PrepublishDevelopmentMC.propTypes = {
  token: PropTypes.string.isRequired,
  PublishDevelopment: PropTypes.func.isRequired,
  uncompletedDevelopmentFields: PropTypes.array,
  uncompletedModelFields: PropTypes.array,
  developmentId: PropTypes.string,
  ShowFlashNotification: PropTypes.func,
  HideModal: PropTypes.func
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  PublishDevelopment,
  ShowFlashNotification,
  HideModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrepublishDevelopmentMC)
