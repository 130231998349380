var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _initialiseProps,
    _jsxFileName = 'src/lib/elements/Checkboxes/CheckboxGroup.js';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { Layout } from '../Layouts';
import { CheckboxError } from './';

var CheckboxGroup = (_temp = _class = function (_Component) {
  _inherits(CheckboxGroup, _Component);

  function CheckboxGroup(props) {
    _classCallCheck(this, CheckboxGroup);

    var _this = _possibleConstructorReturn(this, (CheckboxGroup.__proto__ || Object.getPrototypeOf(CheckboxGroup)).call(this, props));

    _initialiseProps.call(_this);

    var _this$props = _this.props,
        children = _this$props.children,
        values = _this$props.values,
        initialValue = _this$props.initialValue,
        keyValue = _this$props.keyValue,
        onChange = _this$props.onChange;

    var arrVal = Array(React.Children.count(children)).fill(false);

    var baseValues = void 0;
    if (values !== undefined && values !== '') {
      baseValues = Array.isArray(values) ? values.length > 0 ? values : undefined : values;
    } else if (initialValue !== undefined) {
      baseValues = Array.isArray(initialValue) ? initialValue.length > 0 ? initialValue : undefined : initialValue;
    }

    if (baseValues !== undefined) {
      Array.isArray(baseValues) ? baseValues.forEach(function (value) {
        React.Children.forEach(children, function (child, index) {
          var childValue = child.props.value;

          if (keyValue) {
            if (childValue[keyValue] === value[keyValue]) {
              arrVal[index] = true;
            }
          } else if (_.isEqual(childValue, value)) {
            arrVal[index] = true;
          }
        });
      }) : React.Children.forEach(children, function (child, index) {
        var childValue = child.props.value;

        if (keyValue) {
          if (childValue[keyValue] === baseValues[keyValue]) {
            arrVal[index] = true;
          }
        } else if (_.isEqual(childValue, baseValues)) {
          arrVal[index] = true;
        }
      });
    }

    if (initialValue !== undefined && values === '') {
      onChange(initialValue);
    }

    _this.state = {
      arrVal: arrVal || []
    };
    return _this;
  }

  _createClass(CheckboxGroup, [{
    key: 'render',
    value: function render() {
      var _classNames;

      var _props = this.props,
          className = _props.className,
          smColumns = _props.smColumns,
          mdColumns = _props.mdColumns,
          lgColumns = _props.lgColumns,
          disabled = _props.disabled,
          error = _props.error,
          justify = _props.justify,
          touched = _props.touched,
          vertical = _props.vertical;


      var checkboxGroupClasses = classNames(className, this.BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, this.BASE_CLASSNAME + '--vertical', vertical), _defineProperty(_classNames, this.BASE_CLASSNAME + '--error', error && touched && !disabled), _classNames));

      return React.createElement(
        'div',
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 193
          },
          __self: this
        },
        React.createElement(
          Layout,
          {
            className: checkboxGroupClasses,
            justify: justify,
            middle: !vertical,
            smallColumns: smColumns,
            mediumColumns: mdColumns,
            largeColumns: lgColumns,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 194
            },
            __self: this
          },
          this.renderCheckboxes()
        ),
        React.createElement(CheckboxError, { touched: touched, error: error, disabled: disabled, __source: {
            fileName: _jsxFileName,
            lineNumber: 204
          },
          __self: this
        })
      );
    }
  }]);

  return CheckboxGroup;
}(Component), _class.propTypes = {
  /** Checkboxes to render. */
  children: PropTypes.array.isRequired,
  /** Used to retrieve the selected values.*/
  onChange: PropTypes.func.isRequired,
  /** Custom className. */
  className: PropTypes.string,
  /** Disables all children checkboxes. */
  disabled: PropTypes.bool,
  /** Error message. */
  error: PropTypes.string,
  /** Sets initial values to Checkbox Group*/
  initialValue: PropTypes.any,
  /** Justify checkboxes and only works if shrink is enabled. */
  justify: PropTypes.bool,
  /** Used to compare any object value(initialValue) with children values to init them correctly.*/
  keyValue: PropTypes.string,
  /** In case you want large columns divide the checkboxes. */
  lgColumns: PropTypes.number,
  /** In case you want medium columns divide the checkboxes. */
  mdColumns: PropTypes.number,
  /** In case, you want to select multiple checkboxes. */
  multiSelectable: PropTypes.bool,
  /** In case a button is implemented in Checkbox child, it will work.*/
  onBlur: PropTypes.func,
  /** In case a button is implemented on each child Checkbox, it will work.*/
  onFocus: PropTypes.func,
  /** In case you don't want to shrink each child Checkbox. */
  shrinkItems: PropTypes.bool,
  /** In case you want small columns divide the checkboxes. */
  smColumns: PropTypes.number,
  /** Identifies if user already touched checkbox group. */
  touched: PropTypes.bool,
  /** Sets values to Checkbox Group*/
  values: PropTypes.any,
  /** Vertical orientation on checkboxes. */
  vertical: PropTypes.bool
}, _class.defaultProps = {
  shrinkItems: true
}, _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  this.BASE_CLASSNAME = 'checkbox-group';

  this.handleCheckboxSelect = function (value, index) {
    var arrVal = _this2.state.arrVal;
    var _props2 = _this2.props,
        values = _props2.values,
        onChange = _props2.onChange,
        keyValue = _props2.keyValue,
        multiSelectable = _props2.multiSelectable;


    var back = multiSelectable ? [].concat(_toConsumableArray(arrVal.slice(0, index))) : [].concat(_toConsumableArray(arrVal.slice(0, index).fill(false)));

    var front = multiSelectable ? [].concat(_toConsumableArray(arrVal.slice(index + 1))) : [].concat(_toConsumableArray(arrVal.slice(index + 1).fill(false)));

    _this2.setState(function () {
      return {
        arrVal: [].concat(_toConsumableArray(back), [!arrVal[index]], _toConsumableArray(front))
      };
    }, function () {
      if (arrVal[index]) {
        var newValues = void 0;
        if (multiSelectable) {
          var tmp = values ? values.filter(function (item) {
            return keyValue ? value[keyValue] !== item[keyValue] : !_.isEqual(item, value);
          }) : [];
          newValues = tmp;
        } else {
          newValues = null;
        }

        onChange(newValues);
      } else {
        var _newValues = multiSelectable ? Array.isArray(values) ? [].concat(_toConsumableArray(values), [value]) : [value] : value;
        onChange(_newValues);
      }
    });
  };

  this.renderCheckboxes = function () {
    var _props3 = _this2.props,
        onBlur = _props3.onBlur,
        onFocus = _props3.onFocus,
        children = _props3.children,
        shrinkItems = _props3.shrinkItems,
        disabled = _props3.disabled;
    var arrVal = _this2.state.arrVal;


    return React.Children.map(children, function (child, index) {
      return React.createElement(child.type, Object.assign({}, child.props, {
        disabled: disabled ? disabled : child.props.disabled,
        isSelected: arrVal && arrVal.length ? arrVal[index] : false,
        onBlur: onBlur,
        onClick: function onClick(value) {
          return _this2.handleCheckboxSelect(value, index);
        },
        onFocus: onFocus,

        shrink: shrinkItems
      }));
    });
  };
}, _temp);


export default CheckboxGroup;