export const ShowModal = (
  Component = '',
  componentProps = {},
  modalProps = {},
  withHistory = false
) => ({
  type: 'SHOW_MODAL',
  payload: {
    Component,
    componentProps,
    modalProps,
    withHistory
  }
})

export const ShowFlashNotification = (
  type,
  message,
  image,
  flashNotificationContainerProps,
  flashNotificationWrapperProps,
  flashNotificationProps,
  customTime
) => {
  return dispatch => {
    dispatch(
      ShowFlashNotificationSingle(
        type,
        message,
        image,
        flashNotificationContainerProps,
        flashNotificationWrapperProps,
        flashNotificationProps
      )
    )
    setTimeout(() => {
      dispatch(HideFlashNotification())
    }, customTime || 3000)
  }
}

/**
 *
 * @param {string} type - Could be success, danger or warning
 */
export const ShowFlashNotificationSingle = (
  type,
  message,
  image,
  flashNotificationContainerProps,
  flashNotificationWrapperProps,
  flashNotificationProps
) => ({
  type: 'SHOW_FLASH_NOTIFICATION',
  payload: {
    type,
    message,
    image,

    flashNotificationContainerProps,
    flashNotificationWrapperProps,
    flashNotificationProps
  }
})

export const HideFlashNotification = () => ({
  type: 'HIDE_FLASH_NOTIFICATION'
})

export const GoBackModalHistory = () => ({
  type: 'GO_BACK_MODAL_HISTORY'
})

export const HideModalPersist = () => ({
  type: 'HIDE_MODAL_PERMANENT'
})

export const InitializePusher = token => ({
  type: 'INITIALIZE_PUSHER',
  token
})

export const HideModal = () => ({
  type: 'HIDE_MODAL'
})

export const LockModal = () => ({
  type: 'LOCK_MODAL'
})

export const UnlockModal = () => ({
  type: 'UNLOCK_MODAL'
})
