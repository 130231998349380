var _jsxFileName = 'src/lib/elements/Layouts/Container.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
var Container = function Container(_ref) {
  var className = _ref.className,
      children = _ref.children,
      fluid = _ref.fluid,
      widder = _ref.widder,
      withPageTopTitle = _ref.withPageTopTitle,
      layoutVertical = _ref.layoutVertical,
      widderNoPadding = _ref.widderNoPadding;

  var containerClasses = classNames(className, 'container', {
    'container--fluid': fluid,
    'container--wider': widder,
    'container--wider-no-padding': widderNoPadding,
    'container--page-top-layout-title': withPageTopTitle,
    'grid-y': layoutVertical
  });
  return React.createElement(
    'div',
    { className: containerClasses, __source: {
        fileName: _jsxFileName,
        lineNumber: 20
      },
      __self: _this
    },
    children
  );
};

Container.propTypes = {
  /** */
  children: PropTypes.any,
  /** If you need to add a height size or so */
  className: PropTypes.string,
  /** */
  fluid: PropTypes.bool,
  /** */
  layoutVertical: PropTypes.bool,
  /** */
  widder: PropTypes.bool,
  /** */
  widderNoPadding: PropTypes.bool,
  /** */
  withPageTopTitle: PropTypes.bool
};

export default Container;