var _jsxFileName = 'src/lib/elements/Texts/TruncateText.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';

var TruncateText = function TruncateText(_ref) {
  var className = _ref.className,
      line = _ref.line,
      truncateText = _ref.truncateText,
      text = _ref.text,
      TruncateElement = _ref.TruncateElement;

  return React.createElement(TextTruncate, {
    line: line,
    containerClassName: className,
    truncateText: truncateText,
    text: text,
    textTruncateChild: React.createElement(TruncateElement, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 18
      },
      __self: _this
    }),
    __source: {
      fileName: _jsxFileName,
      lineNumber: 13
    },
    __self: _this
  });
};

TruncateText.propTypes = {
  /** */
  className: PropTypes.string,
  /** */
  line: PropTypes.number,
  /** */
  text: PropTypes.string,
  /** */
  TruncateElement: PropTypes.any,
  /** */
  truncateText: PropTypes.string
};

TruncateText.defaultProps = {
  TruncateElement: function TruncateElement() {
    return null;
  },
  line: 1,
  truncateText: '...'
};

export default TruncateText;