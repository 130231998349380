import React, { useEffect, useState } from 'react'
import { object, array } from 'prop-types'
import styled from 'styled-components'
import { Typography, Button, TextField, withForm } from 'rc'
import { ModelFormSectionContainer } from 'modules/development/static-components'
import { Plus, Cross } from 'rc/Icons'

function OtherEquipments(props) {
  const [inputVal, setInputVal] = useState('')
  const [inputErr, setInputErr] = useState(null)
  const [otherEquipments, setOtherEquipments] = useState(props.otherEquipmentList ? props.otherEquipmentList : [])
  const { fc: { customChange } } = props

  const handleInputChange = ({ target: { value } }) => {
    setInputVal(value)
    setInputErr(null)
  }

  const addCustomAmenity = () => {
    let errMsg = null
    if(inputVal === '') errMsg = 'Escribe algo.'
    else if(otherEquipments.includes(inputVal)) errMsg = 'Esta amenidad ya esta agregada.'    
    setInputVal('')
    setInputErr(errMsg)

    if(!errMsg) setOtherEquipments([ ...otherEquipments, inputVal])
  }

  const removeAmenity = index => {
    const newArr = [...otherEquipments.slice(0, index), ...otherEquipments.slice(index + 1)]
    setOtherEquipments(newArr)
  }

  const handleKeyDown = e => {
    const { keyCode } = e
    if (keyCode === 13) {
      e.preventDefault()
      addCustomAmenity()
    }
    return e
  }

  const renderBadges = (label, index) => {
    return (
      <Badge key={label}>
        {label}
        <CrossStyled 
          onClick={() => removeAmenity(index)} 
        />
      </Badge>
    )
  }

  useEffect(() => {    
    customChange({ ["other_equipments"]: otherEquipments })
  },[otherEquipments])

  return (
    <ModelFormSectionContainer>
      <Typography
        variant="h2" 
        textTransform="initial"
        color="palette.primary.main"
      >
        Otros
      </Typography>
      <Typography variant="subtitle4" fontWeight="regular">
        Agrega otro tipo de amenidad, equipamiento o servicio.
      </Typography>
      <div
        css={`
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: ${({ theme }) => theme.remCalc('20 0 12 0')};
        `}
      >
        <Button
          color="warning"
          css={`
            border-radius: 20px !important;
            width: 40px;
            padding: 0 !important;
          `}
          onClick={addCustomAmenity}
        >
          <Plus css={`${({ theme }) => theme.box(20, 20)}`}/>
        </Button>
        <TextField
          placeholder={"Agregar amenidad o equipamiento"}
          css={`
            width: 90%;
            margin: 0;
          `}
          value={inputVal}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          error={inputErr}
        />
      </div>
      <BadgesContainer>
        {otherEquipments.map(renderBadges)}
      </BadgesContainer>
    </ModelFormSectionContainer>
  )
}

OtherEquipments.propTypes = {
  fc: object,
  otherEquipmentList: array
}

const BadgesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
`

const Badge = styled.p`
  margin: ${({ theme }) => theme.remCalc('5')};
  pointer-events: none;
  align-items: center;
  display: inline-flex;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  padding: ${({ theme }) => theme.remCalc('5 10')};
  ${({ theme }) => theme.radius(30)}
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
  span {
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  }
`

const CrossStyled = styled(Cross)`
  fill: white;
  pointer-events: auto;
  cursor: pointer;
  ${({ theme }) => theme.box(20, 20)};
`

export default withForm(OtherEquipments)
