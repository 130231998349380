import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { TextField, FormContainer, Typography, Link, Grid } from 'rc'
import { isEmail } from 'rc/helpers/stringHelpers'
import { SubmitButton, Button } from 'components'
import { WiggotLogo } from 'components/Icons'
import {
  ShowFlashNotification,
  HideFlashNotification,
  HideModal,
  UnlockModal,
  GoBackModalHistory
} from 'reduxActions/services'
import { LogIn } from 'reduxActions/auth'

const LoginContentMC = ({
  className,
  ShowFlashNotification,
  HideFlashNotification,
  GoBackModalHistory,
  HideModal,
  UnlockModal,
  LogIn,
  ...props
}) => {
  const baseId = 'general-loginContent-mc'

  const handleSubmit = (values, errors) => {
    const required_fields = ['email', 'password']
    const fields_with_errors = required_fields.filter(
      field =>
        !values[field] ||
        (values[field] &&
          typeof values[field] === 'string' &&
          values[field].trim().length === 0)
    )
    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }

    const { accept_terms, ...apiValues } = values
    return LogIn(apiValues)
  }

  const handleSubmitSuccess = res => {
    UnlockModal()
    HideModal()
    setTimeout(() => {
      ShowFlashNotification('success', 'Has iniciado sesión.')
    }, 200)
  }

  const handleSubmitFail = (fieldErrors, fc, submitErrors) => {
    if (submitErrors) {
      let { code, metadata } = submitErrors
      const {
        error: {
          response: { data: { error: { code: responseCode } = {} } = {} } = {}
        } = {}
      } = submitErrors
      code = code || responseCode
      switch (code) {
        case 'REQUIRED':
          {
            const fieldErrors = metadata.reduce((previous, current) => {
              return {
                ...previous,
                [current]: 'Campo requerido'
              }
            }, {})

            fc.setCustomErrors({
              ...fieldErrors
            })
          }
          break

        default:
          ShowFlashNotification(
            'danger',
            `Ha ocurrido un error, intenta mas tarde.`
          )
          break
      }
    }
  }

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  const emailChecker = newValue => {
    return !isEmail(newValue) && 'Ingresa un email válido.'
  }

  return (
    <div
      className={className}
      css={`
        padding: ${({ theme }) => theme.remCalc('30')};
      `}
    >
      <div
        css={`
          width: ${({ theme }) => theme.remCalc('300')};
          max-width: 100%;
          margin: 0 auto;
        `}
      >
        <WiggotLogo
          css={`
            width: ${({ theme }) => theme.remCalc('140')};
            display: block;
            margin: 0 auto;
          `}
        ></WiggotLogo>
        <Typography
          variant="h2"
          textTransform="initial"
          color="palette.primary.main"
          align="center"
          display="block"
          css={`
            margin-top: ${({ theme }) => theme.remCalc('20')};
            margin-top: ${({ theme }) => theme.remCalc('15')};
          `}
        >
          Inicia sesión
        </Typography>
        <FormContainer
          initialValues={{
            name: '',
            email: '',
            password: ''
          }}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFail}
          css={`
            > *:not(:last-child) {
              margin-bottom: ${({ theme }) => theme.remCalc('14')};
            }
          `}
        >
          <TextField
            id={`${baseId}-input-email`}
            name="email"
            fullWidth
            placeholder="Escribe tu email"
            label="Email"
            autoComplete="new-password"
            validate={newValue => {
              let message = isRequired(newValue)
              if (newValue) message = emailChecker(newValue)
              return message
            }}
          ></TextField>

          <TextField
            id={`${baseId}-input-password`}
            name="password"
            type="password"
            fullWidth
            placeholder="Escribe tu contraseña"
            label="Contraseña"
            autoComplete="new-password"
            validate={isRequired}
          ></TextField>
          <Typography
            variant="body2"
            display="block"
            css={`
              margin-bottom: ${({ theme }) => theme.remCalc('50')};
            `}
          >
            ¡Olvide mi contraseña!{' '}
            <Link
              id={`${baseId}-link-terms`}
              underline="always"
              as={RouterLink}
              target="_blank"
              to="/forgotPassword"
            >
              Recuperar contraseña
            </Link>{' '}
          </Typography>
          <Grid container justify="space-between">
            <Grid item flat md={9}>
              <Button
                type="tertiary"
                id={`${baseId}-button-cancel`}
                full
                onClick={GoBackModalHistory}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item flat md={14}>
              <SubmitButton
                id={`${baseId}-button-submit`}
                full
                css={`
                  padding: 0;
                `}
              >
                Inicia sesión
              </SubmitButton>
            </Grid>
          </Grid>
        </FormContainer>
      </div>
    </div>
  )
}

LoginContentMC.propTypes = {
  LogIn: PropTypes.func.isRequired,
  HideModal: PropTypes.func.isRequired,
  UnlockModal: PropTypes.func.isRequired,
  ShowFlashNotification: PropTypes.func.isRequired,
  HideFlashNotification: PropTypes.func.isRequired,
  GoBackModalHistory: PropTypes.func.isRequired,
  className: PropTypes.string
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  ShowFlashNotification,
  HideFlashNotification,
  HideModal,
  UnlockModal,
  LogIn,
  GoBackModalHistory
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContentMC)
