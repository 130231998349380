import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Typography } from 'rc'
import { CheckmarkSquare } from 'rc/Icons'
import { Button } from 'components'

const PaymentModalSuccess = ({ HideModal, UnlockModal, message }) => {
  return (
    <Container
      container
      item
      direction="column"
      alignItems="space-between"
      justify="center"
    >
      <CheckmarkSquareStyled />
      <Typography variant="subtitle1" align="center">
        {message}
      </Typography>
      <Button
        onClick={() => {
          UnlockModal()
          HideModal()
        }}
        css={`
          width: 100%;
        `}
      >
        Cerrar
      </Button>
    </Container>
  )
}

PaymentModalSuccess.propTypes = {
  HideModal: PropTypes.func,
  message: PropTypes.string,
  UnlockModal: PropTypes.func
}

const Container = styled(Grid)`
  ${({ theme }) => theme.box(400, 250)}
`
const CheckmarkSquareStyled = styled(CheckmarkSquare)`
  ${({ theme }) => theme.box(50, 50)}
  fill: ${({ theme }) => theme.palette.primary.main};
`

export default PaymentModalSuccess
