/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import {
  UserRoutes,
  USER_ROUTES,
  ProtectedRoutes,
  PROTECTED_ROUTES,
  PublicRoutes,
  PUBLIC_ROUTES
} from 'modules/app/routes'
import { PDFListener, UpdateBanner } from 'modules/app'
import { useSelector, useDispatch } from 'react-redux'
import { UserSectionLog } from 'reduxActions/user'
import { pathnameToSnake } from 'utils/helpers'
import { SECTIONS_TO_SAVE_VISIT } from 'utils/constants'

function Routes() {
  const { roles, token } = useSelector(({ authReducer: { roles, token } }) => ({
    roles,
    token
  }))
  const { serverVersion } = useSelector(
    ({ generalReducer: { serverVersion } }) => ({
      serverVersion
    })
  )

  let location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const { pathname, search } = location
    const keyName = pathnameToSnake(pathname)

    if (SECTIONS_TO_SAVE_VISIT.includes(keyName)) {
      let valid = true

      if (keyName === 'SEARCH' && search) valid = false

      if (valid && token) {
        dispatch(UserSectionLog(token, keyName))
      }
    }
  }, [location])

  const Home = () => {
    return roles.DEVELOPER_USER ? (
      <Redirect to="/developments" />
    ) : roles.ASSOCIATION ? (
      <Redirect to="/association/agencies" />
    ) : (
      <Redirect to="/login" />
    )
  }
  return (
    <>
      {process.env.REACT_APP_ENV === 'PROD' &&
        process.env.REACT_APP_VERSION !== serverVersion && (
          <UpdateBanner isLogged hasEmailVerified />
        )}
      <Switch>
        <Route path={userPaths} component={UserRoutes} />
        <Route path={protectedPaths} component={ProtectedRoutes} />
        <Route path={publicPaths} component={PublicRoutes} />
        <Route exact path="/">
          <Home />
        </Route>
      </Switch>
      <PDFListener />
    </>
  )
}

Routes.propTypes = {}

const userPaths = USER_ROUTES.map(({ path }) => path)

const publicPaths = PUBLIC_ROUTES.map(({ path }) => path)

const protectedPaths = PROTECTED_ROUTES.map(({ path }) => path)

export default Routes
