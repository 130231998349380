import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'rc'
import { AddToList } from 'components/Icons'
import { useAddLists } from 'src/hooks/useAddLists'

const PublicListsAddButton = ({ origin, propertyId, small, ...buttonProps }) => {
  const { showModalAddToPublicList } = useAddLists()
  return (
    <ButtonStyled 
      {...buttonProps} 
      small={small} 
      size={small ? "small" : "medium"}
      variant="outlined"
      onClick={() => showModalAddToPublicList(origin, propertyId)}
    >
      <AddToList />
    </ButtonStyled>
  )
}

PublicListsAddButton.propTypes = {
  origin: PropTypes.string,
  propertyIds: PropTypes.array,
  small: PropTypes.bool
}

const ButtonStyled = styled(Button)`
  border-radius: ${({ small }) => small ? '50px': '50%'}!important;
  > svg { max-width: 16px; }
`

export default PublicListsAddButton
