import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import createFilter from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'
import axios from 'axios'
import { multiClientMiddleware } from 'redux-axios-middleware'
import { sendThroughChannel } from './tab-sync-middleware'

import { composeWithDevTools } from 'redux-devtools-extension'
// import logger from 'redux-logger'
import * as reducers from './reducers'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { SetAppVersion } from 'reduxActions/general'
import { clearCookies } from 'utils/handleCookies'

const appReducer = combineReducers(reducers)

const rootReducer = (state, action) => {
  if (action.type === 'SUBMIT_LOGOUT_REQUEST_SUCCESS') {
    localStorage.clear()
    sessionStorage.clear()
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
    })
    state = undefined

    // king-bob-omb
    // https://stackoverflow.com/a/65544086
    // window.location.replace(window.location.href)
    clearCookies([
      'auth_user',
      'profile_user',
      'properties_rules',
      'CHAT',
      'CONTACTS'
    ])
  }
  return appReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'authReducer',
    'profileReducer',
    'servicesReducer',
    'generalReducer',
    'addonsReducer',
    'tokensReducer',
    'freemiumReducer'
  ],
  transforms: [
    createFilter('profileReducer', [
      'agencyId',
      'userId',
      'profileId',
      'email',
      'fullname',
      'photo',
      'verified',
      'trialUsed',
      'primeType',
      'primeAvailableUntil',
      'fullPrimeUsed',
      'domain',
      'subdomain',
      'domainCertificateStatus',
      'userScoresSectionConfig',
      'userPaymentStatus',
      'phoneNumberVerified',
      'phoneNumber',
      'notificationsReminder',
      'termsAndConditions',
      'specialPermission'
    ]),
    createFilter('servicesReducer', ['hideModalPlatforms']),
    createFilter('generalReducer', [
      'amenitiesById',
      'amenities',
      'developmentAmenitiesById',
      'developmentAmenities'
    ])
  ],
  stateReconciler: autoMergeLevel2
}

const httpClient = {
  default: {
    client: axios.create({
      baseURL: `${process.env.REACT_APP_API_BASE_URL}`
    })
  },
  geolocation: {
    client: axios.create({
      baseURL: 'https://maps.googleapis.com'
    })
  },
  images: {
    client: axios.create({
      baseURL: process.env.REACT_APP_IMAGE_UPLOAD_URL
    })
  },

  apiServices: {
    client: axios.create({
      baseURL: process.env.REACT_APP_API_SERVICES
    })
  },
  parcaServices: {
    client: axios.create({
      baseURL: process.env.REACT_APP_PARCA_SERVICES,
      timeout: 15 * 1000
    })
  },
  pdf: {
    client: axios.create({
      baseURL: process.env.REACT_APP_PDF_API_URL
    })
  },
  inventoryTemplates: {
    client: axios.create({
      baseURL: process.env.REACT_APP_INVENTORY_TEMPLATES
    })
  }
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

const makeStore = () => {
  const middleware = [
    thunkMiddleware,
    multiClientMiddleware(httpClient, {
      returnRejectedPromiseOnError: true,
      interceptors: {
        request: [
          {
            success: function ({ getState, dispatch, getSourceAction }, req) {
              if (
                req.baseURL !== process.env.REACT_APP_PARCA_SERVICES &&
                req.baseURL !== process.env.REACT_APP_PDF_API_URL
              ) {
                req.headers[
                  'Application-Version'
                ] = `${process.env.REACT_APP_VERSION}` //contains information about request object
              }

              if (req.url === '/pdf/live_generation') {
                req.headers['Accept'] = 'application/pdf'
                req.responseType = 'blob'
              }

              return req
            },
            error: function ({ getState, dispatch, getSourceAction }, error) {
              return error
            }
          }
        ],
        response: [
          {
            success: ({ dispatch }, req) => {
              const date = new Date()

              try {
                window.dataLayer.push({
                  event: 'response',
                  data: {
                    method: req.config.method,
                    date: date.toISOString(),
                    errorCode: '',
                    errorMessage: '',
                    statusCode: req.status,
                    url: req.request.responseURL
                  }
                })
              } catch (e) {
                //Not handled
              }
              const { headers = {}, config: { baseURL = '' } = {} } = req

              if (
                baseURL.indexOf('/api/v2') !== -1 &&
                headers['application-version'] !== process.env.REACT_APP_VERSION
              ) {
                dispatch(SetAppVersion(headers['application-version']))
              }
              return req
            },
            error: ({ dispatch }, submitError) => {
              let {
                response: {
                  config: { url = '' } = {},
                  data: { error = {} } = {},
                  status = ''
                } = {}
              } = submitError
              const date = new Date()
              if (window.dataLayer) {
                try {
                  window.dataLayer.push({
                    event: 'response',
                    data: {
                      date: date.toISOString(),
                      errorCode: error.code,
                      errorMessage: error.message,
                      method: submitError.response.config.method,
                      statusCode: submitError.response.status,
                      url: submitError.response.request.responseURL
                    }
                  })
                } catch (e) {
                  //Not handled
                }
              }
              const { code = '' } = error

              switch (status) {
                case 401:
                  switch (code) {
                    case 'AUTHORIZATION_REQUIRED':
                      if (url.includes('login') || url.includes('/roles')) {
                        return Promise.reject(submitError)
                      }
                      dispatch({ type: 'SUBMIT_LOGOUT_REQUEST_SUCCESS' })
                      break
                    default:
                      break
                  }
                  break
                default:
                  break
              }

              return Promise.reject(submitError)
            }
          }
        ]
      }
    }),
    sendThroughChannel()
  ]

  if (process.env.NODE_ENV !== 'production') {
    // middleware.push(logger)
  }

  const enhancer = composeWithDevTools(applyMiddleware(...middleware))
  let store = createStore(persistedReducer, enhancer)
  let persistor = persistStore(store)
  return { store, persistor }
}

export const { store, persistor } = makeStore()
