import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Grid,
  FormContainer,
  TextField,
  Typography,
  Select,
  SubmitButton,
  FormControlLabel,
  Checkbox
} from 'rc'
import { SuitcaseRounded } from 'rc/Icons'
import { Button } from 'components'
import { MONTHS } from 'utils/constants'
import {
  AddWorkExperience,
  DeleteWorkExperience,
  EditWorkExperience
} from 'reduxActions/profile'
import { HideModal } from 'reduxActions/services'

const ProfileExperienceMC = ({
  experience,
  AddWorkExperience,
  DeleteWorkExperience,
  EditWorkExperience,
  HideModal,
  token,
  profileContext,
  ...props
}) => {
  const isNewExperience = !experience.user_work_experience_id
  const { user_work_experience_id: experienceId } = experience
  const isCurrentJob =
    !isNewExperience && !experience.end_month && !experience.end_year
  const [currentJob, setCurrentJob] = useState(isCurrentJob)
  const [monthsAvailable, setMonthsAvailable] = useState({
    start: MONTHS,
    end: MONTHS
  })
  const currentYear = new Date().getFullYear()
  const firstYear = 1950
  const years = [...Array(currentYear - firstYear + 1).keys()].map(index => ({
    label: currentYear - index,
    value: currentYear - index
  }))

  const handleSubmit = async values => {
    const { is_current_job, ...otherValues } = values
    values = otherValues
    let fields_with_errors = []

    Object.keys(values).forEach(key => {
      if (currentJob && (key === 'end_year' || key === 'end_month')) {
        values[key] = ''
        return
      }

      if (!values[key]) fields_with_errors.push(key)
    })

    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }

    if (
      !currentJob &&
      isStartDateGreater(
        values.start_year,
        values.start_month,
        values.end_year,
        values.end_month
      )
    ) {
      const error = new Error({ message: 'End date should be smaller' })
      error.code = 'END_DATE_SHOULD_BE_SMALLER'
      error.metadata = ['end_year', 'end_month']
      throw error
    }
    if (isNewExperience) {
      return AddWorkExperience(token, values)
    } else {
      return EditWorkExperience(token, experienceId, values)
    }
  }
  const handleSubmitSuccess = (response, formValues) => {
    const {
      payload: { data: { user_work_experience_id } = {} }
    } = response

    const {
      customChange,
      profile: { work_experience = [] }
    } = profileContext
    HideModal()
    if (isNewExperience)
      customChange({
        work_experience: [
          ...work_experience,
          { user_work_experience_id, ...formValues }
        ]
      })
    else
      customChange({
        work_experience: work_experience.map(savedExperience =>
          experienceId === savedExperience.user_work_experience_id
            ? { ...formValues }
            : savedExperience
        )
      })
  }

  const handleSubmitFail = (errors, fc, submitErrors) => {
    if (submitErrors) {
      const { code, metadata } = submitErrors
      switch (code) {
        case 'REQUIRED':
          {
            const fieldErrors = metadata.reduce((previous, current) => {
              return {
                ...previous,
                [current]: 'Campo requerido'
              }
            }, {})

            fc.setCustomErrors({
              ...fieldErrors
            })
          }
          break
        case 'END_DATE_SHOULD_BE_SMALLER':
          fc.setCustomErrors({
            end_month: ' ',
            end_year: 'Tu fecha de finalización no puede ser menor'
          })

          break
        default:
          break
      }
    }
  }

  const onDeleteExperience = async () => {
    try {
      const response = await DeleteWorkExperience(token, experienceId)
      const { payload: { status } = {} } = response
      if (status === 200) {
        const {
          customChange,
          profile: { work_experience = [] }
        } = profileContext
        customChange({
          work_experience: work_experience.filter(
            ({ user_work_experience_id }) =>
              experienceId !== user_work_experience_id
          )
        })
        HideModal()
      }
    } catch (error) {
      alert('Error')
    }
  }

  const isRequired = newValue => {
    return !newValue && 'Campo requerido.'
  }

  const handleFormChange = (
    newValues,
    fieldChanged,
    newValue,
    previousValues,
    childContext
  ) => {
    if (fieldChanged === 'is_current_job' && newValue === true) {
      childContext.setCustomErrors({
        ...childContext.errors,
        end_year: '',
        end_month: ''
      })
    }
    if (
      (fieldChanged === 'end_year' || fieldChanged === 'start_year') &&
      newValue
    ) {
      const correctMonths = fieldChanged === 'end_year' ? 'end' : 'start'
      if (newValue === currentYear) {
        const d = new Date()
        const monthNumber = d.getMonth()
        setMonthsAvailable({
          ...monthsAvailable,
          [`${correctMonths}`]: MONTHS.slice(0, monthNumber + 1)
        })
      } else {
        setMonthsAvailable({
          ...monthsAvailable,
          [`${correctMonths}`]: MONTHS
        })
      }
    }

    if (
      (fieldChanged === 'end_year' || fieldChanged === 'end_month') &
      newValues.start_year &
      newValues.start_month &
      newValues.end_year &
      newValues.end_month &
      newValue
    ) {
      const { end_year, end_month, ...otherErrors } = childContext.errors
      if (
        !currentJob &&
        !isStartDateGreater(
          newValues.start_year,
          newValues.start_month,
          newValues.end_year,
          newValues.end_month
        )
      ) {
        if (
          childContext.errors['end_year'] ===
          'Tu fecha de finalización no puede ser menor'
        ) {
          childContext.setCustomErrors({
            ...otherErrors,
            end_year: '',
            end_month: ''
          })
        }
      } else {
        childContext.setCustomErrors({
          ...otherErrors,
          end_year: 'Tu fecha de finalización no puede ser menor',
          end_month: '  '
        })
      }
    }
  }

  const isStartDateGreater = (startYear, startMonth, endYear, endMonth) => {
    const MONTHS_BY_YEARS = 12

    if (
      endYear &&
      startYear * MONTHS_BY_YEARS + startMonth >
        endYear * MONTHS_BY_YEARS + endMonth
    )
      return true

    return false
  }
  return (
    <div
      css={`
        padding: ${({ theme }) => theme.remCalc('30 20')};
        width: ${({ theme }) => theme.remCalc('1080')};
        max-width: 100%;
        ${({ theme }) => theme.media.desktop`
          padding: ${({ theme }) => theme.remCalc('30 60')};
        `}
      `}
    >
      <Typography variant="h6" color="palette.primary.main" textTransform="initial">
        {isNewExperience ? 'Añadir nueva' : 'Editar'} experiencia
      </Typography>
      <Grid container>
        <Grid
          container
          center
          item
          xsShrink
          css={`
            ${({ theme }) => theme.box(60, 60, true)}
            background: ${({ theme }) => theme.palette.primary.main};
            color: ${({ theme }) => theme.palette.base.white};
            margin: ${({ theme }) => theme.remCalc('28 30')};
            display: none;
            ${({ theme }) => theme.media.desktop`
              display:flex;
            `}
          `}
        >
          <SuitcaseRounded
            css={`
              ${({ theme }) => theme.box(30, 30)}
            `}
          />
        </Grid>
        <Grid item xsAuto flat>
          <FormContainer
            initialValues={{
              position: '',
              company_name: '',
              start_month: '',
              start_year: '',
              end_month: '',
              end_year: '',
              is_current_job: isCurrentJob,
              ...experience
            }}
            onFormChange={handleFormChange}
            onSubmit={handleSubmit}
            onSubmitSuccess={handleSubmitSuccess}
            onSubmitFail={handleSubmitFail}
          >
            <Grid container>
              <Grid item md={12}>
                <TextField
                  id="profile-experience-mc-input-position"
                  fullWidth
                  name="position"
                  label="Puesto"
                  required
                  validate={isRequired}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  id="profile-experience-mc-input-companyName"
                  fullWidth
                  name="company_name"
                  label="Empresa"
                  required
                  validate={isRequired}
                />
              </Grid>
              <Grid item flat>
                <FormControlLabel
                  css={`
                    margin: 12px 12px 24px;
                  `}
                  control={
                    <Checkbox
                      onChange={(e, checked) => {
                        setCurrentJob(checked)
                      }}
                      name="is_current_job"
                      id="profile-experience-mc-checkbox-currentJob"
                    />
                  }
                  label="Actualmente tengo este cargo"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item flat md={12} container>
                <Typography
                  variant="subtitle2"
                  display="block"
                  css={`
                    width: 100%;
                    padding: ${({ theme }) => theme.remCalc('0 12')};
                  `}
                >
                  Desde
                </Typography>
                <Grid item md={12}>
                  <Select
                    id="profile-experience-mc-select-startMonth"
                    selectId="profile-experience-mc-select-startMonth"
                    iconId="profile-experience-mc-select-startMonth"
                    name="start_month"
                    css={`
                      width: 100%;
                      min-width: min-content;
                    `}
                    placeholder="Mes"
                    optionLabelKey="label"
                    optionValueKey="value"
                    options={monthsAvailable.start}
                    customHeight="200"
                    required
                    validate={isRequired}
                  />
                </Grid>
                <Grid item md={12}>
                  <Select
                    id="profile-experience-mc-select-startYear"
                    selectId="profile-experience-mc-select-startYear"
                    iconId="profile-experience-mc-select-startYear"
                    name="start_year"
                    css={`
                      width: 100%;
                      min-width: min-content;
                    `}
                    placeholder="Año"
                    optionLabelKey="label"
                    optionValueKey="value"
                    options={years}
                    customHeight="200"
                    required
                    validate={isRequired}
                  />
                </Grid>
              </Grid>
              <Grid item flat md={12} container>
                {currentJob ? (
                  ''
                ) : (
                  <Fragment>
                    {' '}
                    <Typography
                      variant="subtitle2"
                      display="block"
                      css={`
                        width: 100%;
                        padding: ${({ theme }) => theme.remCalc('0 12')};
                      `}
                    >
                      Hasta
                    </Typography>
                    <Grid item md={12}>
                      <Select
                        id="profile-experience-mc-select-endMonth"
                        selectId="profile-experience-mc-select-endMonth"
                        iconId="profile-experience-mc-select-endMonth"
                        name="end_month"
                        css={`
                          width: 100%;
                          min-width: min-content;
                        `}
                        placeholder="Mes"
                        optionLabelKey="label"
                        optionValueKey="value"
                        options={monthsAvailable.end}
                        customHeight="200"
                        required
                        validate={isRequired}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Select
                        id="profile-experience-mc-select-endYear"
                        selectId="profile-experience-mc-select-endYear"
                        iconId="profile-experience-mc-select-endYear"
                        name="end_year"
                        css={`
                          width: 100%;
                          min-width: min-content;
                          & + p {
                            left: -200px;
                          }
                        `}
                        placeholder="Año"
                        optionLabelKey="label"
                        optionValueKey="value"
                        options={years}
                        customHeight="200"
                        required
                        validate={isRequired}
                      />
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
            <Grid container justify="space-between" item fullWidth>
              {!isNewExperience ? (
                <Button
                  css={`
                    width: ${({ theme }) => theme.remCalc('110')};
                    ${({ theme }) => theme.media.desktop`
                      width: ${({ theme }) => theme.remCalc('150')};
                    `}
                  `}
                  size="medium"
                  type="alert"
                  id="profile-experience-mc-button-deleteExperience"
                  onClick={onDeleteExperience}
                >
                  Borrar
                </Button>
              ) : (
                <div />
              )}

              <SubmitButton
                css={`
                  width: ${({ theme }) => theme.remCalc('110')};
                  ${({ theme }) => theme.media.desktop`
                    width: ${({ theme }) => theme.remCalc('150')};
                  `}
                `}
                size="medium"
                id="profile-experience-mc-button-submit"
              >
                {isNewExperience ? 'Añadir' : 'Guardar cambios'}
              </SubmitButton>
            </Grid>
          </FormContainer>
        </Grid>
      </Grid>
    </div>
  )
}
ProfileExperienceMC.defaultProps = {
  experience: {}
}
ProfileExperienceMC.propTypes = {
  profileContext: PropTypes.object.isRequired,
  experience: PropTypes.object,
  token: PropTypes.string.isRequired,
  AddWorkExperience: PropTypes.func.isRequired,
  DeleteWorkExperience: PropTypes.func.isRequired,
  EditWorkExperience: PropTypes.func.isRequired,
  HideModal: PropTypes.func.isRequired
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  DeleteWorkExperience,
  EditWorkExperience,
  AddWorkExperience,
  HideModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileExperienceMC)
