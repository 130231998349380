import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Button, Typography, TextField, Spinner, Checkbox } from 'rc'
import { Search, Cross, Checkmark } from 'rc/Icons'
import { useDebounceCallback } from 'rc/Hooks'
import { ShowFlashNotification, ShowModal } from 'reduxActions/services'
import { CreateOrUpdatePublicListMC } from 'components'
import moment from 'moment'
import { basicFetchRequest } from 'src/api/lists-auth-x'

const AddToPublicListMC = ({ propertyId, origin, HideModal }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [listsList, setListLists] = useState(null)
  const [listListCache, setListListCache] = useState(null)
  const [propertyAdded, setPropertyAdded] = useState(false)

  const showCreateOrUpdateListModal = () => {
    dispatch(ShowModal(CreateOrUpdatePublicListMC, { propertyId, origin }))
  }

  const handleCheckbox = (listId, checked) => {
    let newCacheVal = listListCache[listId]
    newCacheVal.isLoading = true
    setListListCache({ ...listListCache, [listId]: newCacheVal })
    if (checked) addProperty(listId, newCacheVal)
    else removeProperty(listId, newCacheVal)
  }

  const addProperty = async (listId, newCacheVal) => {
    const addPropertyResponse = await basicFetchRequest(
      { listId, propertyId },
      'addProperty'
    )
    if (!addPropertyResponse?.message) {
      dispatch(
        ShowFlashNotification(
          'danger',
          'Hubo un error al añadir la propiedad a la lista.'
        )
      )
    }

    newCacheVal.isLoading = false
    newCacheVal.propertyExists = true
    setPropertyAdded(true)
    setListListCache({ ...listListCache, [listId]: newCacheVal })

  }

  const removeProperty = async (listId, newCacheVal) => {
    const removePropertyResponse = await basicFetchRequest(
      { listId, propertyId },
      'removeProperty'
    )
    if (!removePropertyResponse?.message) {      
      dispatch(
        ShowFlashNotification(
          'danger',
          'Hubo un error al remover la propiedad de la lista.'
        )
      )
    }
    newCacheVal.isLoading = false
    newCacheVal.propertyExists = false
    setPropertyAdded(true)
    setListListCache({ ...listListCache, [listId]: newCacheVal })
  }

  const renderCreateDate = createdAt => {
    moment.locale('es')
    const momenCreatedDate = moment(createdAt, 'YYYY-MM-DD HH:mm:ss ZZ')

    return momenCreatedDate.format('DD MMM YYYY')
  }

  const getListsList = async (filters = {}) => {
    setIsLoading(true)
    const listsResponse = await basicFetchRequest(
      { ...filters, propertyId },
      'lists'
    )
    const {
      data: { lists = [] }
    } = listsResponse || { data: {} }

    if (!listsResponse?.message) {
      dispatch(
        ShowFlashNotification(
          'danger',
          'Hubo un error al obtener las listas.'
        )
      )
    }

    setIsLoading(false)
    setListLists({ lists })

  }

  const [getListsListDebounced] = useDebounceCallback(query => {
    getListsList({ search: query })
  }, 800)

  const renderListsList = () => {
    return listsList && listListCache && listsList.lists.length > 0 ? (
      listsList.lists.map((item, index) => (
        <div
          key={index}
          css={`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            {listListCache[item.id].isLoading ? (
              <MiniSpinnerContainer>
                <Spinner />
              </MiniSpinnerContainer>
            ) : (
              <Checkbox
                value={listListCache[item.id].propertyExists}
                onChange={({ target: { checked } }) =>
                  handleCheckbox(item.id, checked)
                }
              />
            )}
            <div
              css={`
                display: flex;
                flex-direction: column;
                margin: 0 10px;
              `}
            >
              <MediumText>{item?.listPropertiesCount} propiedades</MediumText>
              <LargeText>{item?.name}</LargeText>
              <SmallText>
                Lista creada: {renderCreateDate(item.createdAt)}
              </SmallText>
            </div>
          </div>
          {listListCache[item.id].propertyExists && (
            <CheckmarkContainer>
              <Checkmark />
            </CheckmarkContainer>
          )}
        </div>
      ))
    ) : (
      <LargeText>No se encontró ningún resultado</LargeText>
    )
  }

  useEffect(() => {
    getListsList()
  }, [])

  useEffect(() => {
    if (!listListCache && listsList) {
      if (listsList.total === 0) showCreateOrUpdateListModal()
      let cache = {}
      listsList.lists.forEach(item => {
        cache[item.id] = {
          propertyExists: item.propertyExists,
          isLoading: false
        }
      })
      setListListCache(cache)
    }
  }, [listsList])

  return (
    <ContainerStyled>
      <TitleStyled variant="h6" color="palette.primary.main">
        Añadir a lista pública
        <CloseIconStyled
          onClick={() => {
            if (propertyAdded)
              dispatch(
                ShowFlashNotification(
                  'success',
                  `Se han guardado tus cambios con éxito.`
                )
              )
            HideModal()
          }}
        />
      </TitleStyled>
      <TextField
        name="f_name"
        fullWidth
        required
        placeholder="Nombre de la lista"
        disabled={isLoading}
        InputProps={{
          startAdornment: <SearchStyled />
        }}
        onChange={({ target: { value } }) => getListsListDebounced(value)}
        css={`
          margin: ${({ theme }) => theme.remCalc('32 0')};
        `}
      />
      <div
        css={`
          width: 100%;
          height: 100%;
          overflow-y: auto;
          > * :not(:first-child) {
            margin-top: ${({ theme }) => theme.remCalc('20')};
          }
        `}
      >
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          renderListsList()
        )}
      </div>
      <ButtonsContainer>
        <Button
          variant="outlined"
          color="gray"
          disabled={isLoading}
          onClick={showCreateOrUpdateListModal}
        >
          Nueva lista
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => {
            if (propertyAdded)
              dispatch(
                ShowFlashNotification(
                  'success',
                  `Se han guardado tus cambios con éxito.`
                )
              )
            HideModal()
          }}
        >
          Listo
        </Button>
      </ButtonsContainer>
    </ContainerStyled>
  )
}

AddToPublicListMC.propTypes = {
  propertyId: PropTypes.string,
  origin: PropTypes.string,
  HideModal: PropTypes.func
}

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.remCalc('20')};
  width: ${({ theme }) => theme.remCalc('428')};
  max-height: ${({ theme }) => theme.remCalc('544')};
`
const TitleStyled = styled(Typography)`
  font-weight: 700;
  text-transform: none;
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
`
const CloseIconStyled = styled(Cross)`
  ${({ theme }) => theme.box(25, 25)}
  fill: ${({ theme }) => theme.palette.gray.dark};
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
  &:hover {
    fill: ${({ theme }) => theme.palette.gray.main};
  }
`
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
const MiniSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.box(25, 25)}
`
const SmallText = styled.span`
  font-weight: 400;
  font-size: ${({ theme }) => theme.remCalc('10')};
  line-height: ${({ theme }) => theme.remCalc('15')};
  letter: 2%;
  color: #7d7f9c;
`
const MediumText = styled.span`
  font-weight: 400;
  font-size: ${({ theme }) => theme.remCalc('12')};
  line-height: ${({ theme }) => theme.remCalc('18')};
  letter: 2%;
  color: #7d7f9c;
`
const LargeText = styled.span`
  font-weight: 500;
  font-size: ${({ theme }) => theme.remCalc('14')};
  line-height: ${({ theme }) => theme.remCalc('21')};
  letter: 2%;
  color: #605d75;
`
const CheckmarkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.remCalc('4')};
  border: 2px solid #00ba88;
  border-radius: 50%;
  > svg {
    width: ${({ theme }) => theme.remCalc('22')};
    color: #00ba88;
  }
`
const SearchStyled = styled(Search)`
  ${({ theme }) => theme.box(20, 20)}
  margin-left: ${({ theme }) => theme.remCalc('15')};
  fill: ${({ theme }) => theme.palette.gray.dark};
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: ${({ theme }) => theme.remCalc('30')};
  > button {
    border-radius: 50px !important;
    width: 48%;
  }
`

export default AddToPublicListMC
