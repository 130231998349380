import React from 'react'
import PropTypes from 'prop-types'

import { ReduxForm as Form } from 'components'

import { MarginContainer } from 'c3-ui/elements/Layouts'
import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'
import {
  TextInput,
  CheckboxGroup,
  SimpleCheckboxItem
} from 'c3-ui/elements/FormInputs'
import { SubmitButton } from 'c3-ui/elements/Buttons'
import { Text } from 'c3-ui/elements/Texts'
import { MainLink } from 'c3-ui/elements/Links'

const PasswordRecoveryForm = ({
  handleSubmit,
  handleSubmitSuccess,
  handleSubmitFail,
  isNewAgency,
  activeError
}) => {
  return (
    <Form
      form="passwordRecovery"
      onSubmit={handleSubmit}
      onSubmitSuccess={handleSubmitSuccess}
      onSubmitFail={handleSubmitFail}
      withFeaturedImage
      noResponsive
    >
      <MarginContainer>
        <TextInput
          id="login-passwordRecoveryForm-input-password"
          fontAwesomeIcon="envelope"
          name="password"
          type="password"
          label="Contraseña"
          validations={[{ validationName: 'emptiness' }]}
          leftFontAwesomeIcon="lock"
        />
      </MarginContainer>
      {isNewAgency && (
        <Layout>
          <LayoutItem shrink className="register-form__checkbox-square-wrapper">
            <CheckboxGroup name="termsAndConditions" isRequired>
              <SimpleCheckboxItem
                id="login-passwordRecoveryForm-checkbox-acceptTCPP"
                value={true}
                className={
                  !activeError ? '' : 'register-form__simple-checkbox-square'
                }
              />
            </CheckboxGroup>
          </LayoutItem>
          <Text auto>
            He leído y acepto los{' '}
            <MainLink
              id="login-passwordRecoveryForm-link-termsAndConditions"
              noPadding
              route="/legal/terms"
            >
              términos y condiciones
            </MainLink>{' '}
            así como el{' '}
            <MainLink
              id="login-passwordRecoveryForm-link-privacyPolicies"
              noPadding
              route="/legal/privacy"
            >
              aviso de privacidad.
            </MainLink>
          </Text>
          {!activeError ? (
            ''
          ) : (
            <Text
              center
              className="register-form__danger-message"
              extraSmallSize
            >
              Debes aceptar los términos y condiciones.
            </Text>
          )}
        </Layout>
      )}
      {isNewAgency && (
        <div
          css={`
            ${({ theme }) => theme.box(30, 30)}
          `}
        />
      )}
      <SubmitButton id="login-passwordRecoveryForm-button-submit">
        Confirmar
      </SubmitButton>
    </Form>
  )
}

PasswordRecoveryForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleSubmitSuccess: PropTypes.func,
  handleSubmitFail: PropTypes.func,
  activeError: PropTypes.bool,
  isNewAgency: PropTypes.bool
}

export default PasswordRecoveryForm
