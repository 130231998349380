var _jsxFileName = 'src/lib/elements/Checkboxes/PaletteCheckbox.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var BASE_CLASSNAME = 'palette-checkbox';

var PaletteCheckbox = function PaletteCheckbox(_ref) {
  var _classNames;

  var className = _ref.className,
      color = _ref.color,
      value = _ref.value,
      isSelected = _ref.isSelected,
      _onClick = _ref.onClick;

  var checkboxContainerClasses = classNames(className, BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--checked', isSelected), _defineProperty(_classNames, BASE_CLASSNAME + '--unchecked', !isSelected), _classNames));
  return React.createElement('div', {
    onClick: function onClick() {
      _onClick(value);
    },
    className: checkboxContainerClasses,
    style: { backgroundColor: color },
    __source: {
      fileName: _jsxFileName,
      lineNumber: 13
    },
    __self: _this
  });
};

PaletteCheckbox.propTypes = {
  /** Value passed onClick event. */
  value: PropTypes.any.isRequired,
  /** Custom styles to applied due to class. */
  className: PropTypes.string,
  /** Color to render inside the circle. */
  color: PropTypes.string,
  /** Gives a diferent style. */
  isSelected: PropTypes.bool,
  /** Handler of click action passing value. */
  onClick: PropTypes.func
};

export default PaletteCheckbox;