import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import {
  FormContainer,
  TextField,
  Typography,
  Grid,
  Button,
  SubmitButton
} from 'rc'
import { HideModal, ShowFlashNotification } from 'reduxActions/services'
import { GetProperty } from 'reduxActions/properties'
import { SearcherByPerson } from 'src/components/Icons'

const SearchByCodeMC = ({
  ShowFlashNotification,
  HideModal,
  GetProperty,
  token,
  history,
  isFromSearcher,
  from,
  onLoad
}) => {
  const { roles } = useSelector(({ authReducer: { roles } }) => ({ roles }))
  const baseId = 'searchByCode-'

  useEffect(() => {
    onLoad && onLoad()
  }, [onLoad])

  const requestGetProperty = propertyId => {
    return GetProperty(token, propertyId, 'view', from)
  }

  const handleSubmit = values => {
    const required_fields = ['property_id']
    const fields_with_errors = required_fields.filter(
      field =>
        !values[field] ||
        (values[field] &&
          typeof values[field] === 'string' &&
          values[field].trim().length === 0)
    )
    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }

    return requestGetProperty(values?.property_id && values?.property_id?.trim())
  }

  const handleSubmitSuccess = (response, formValues) => {
    const { payload: { data: { user_owned, agency_owned } = {} } = {} } =
      response
    const { property_id } = formValues
    HideModal()
    const propertyId = property_id?.trim() || ''
    if (user_owned || (agency_owned && roles?.AGENCY)) {
      window.location.replace(
        `${process.env.REACT_APP_NEW_APLICATION_URL}/my-properties/property-detail/${propertyId}?view=detail`
      )
    } else {
      history.push(
        `/property/${propertyId}${!isFromSearcher ? '?view=new' : ''}`
      )
    }
  }

  const handleSubmitFail = (values, fc, submitErrors) => {
    if (submitErrors) {
      let { code, metadata } = submitErrors
      const {
        error: {
          response: { data: { error: { code: responseCode } = {} } = {} } = {}
        } = {}
      } = submitErrors
      code = code || responseCode
      switch (code) {
        case 'REQUIRED':
          {
            const fieldErrors = metadata.reduce((previous, current) => {
              return {
                ...previous,
                [current]: 'Campo requerido'
              }
            }, {})

            fc.setCustomErrors({
              ...fieldErrors
            })
          }
          break
        case 'VALIDATION_ERROR':
          ShowFlashNotification('danger', `Código de propiedad incorrecto.`)
          break
        case 'NOT_FOUND':
          ShowFlashNotification(
            'danger',
            `La propiedad que estás intentando buscar no existe o no tiene un estatus de publicada, por favor intenta nuevamente con un ID válido.`
          )
          break
        case 'CUSTOM':
          fc.setCustomErrors({
            ...metadata
          })
          break

        default:
          ShowFlashNotification(
            'danger',
            `Ha ocurrido un error, intenta mas tarde.`
          )
          break
      }
    }
  }

  const searchPropertyByKeyPressed = propertyId => {
    if (propertyId !== '') {
      new Promise((_resolve, _reject) => {
        requestGetProperty(propertyId)
          .then(res => {
            handleSubmitSuccess(res, { property_id: propertyId })
          })
          .catch(err => {
            handleSubmitFail({}, {}, err)
          })
      })
    }
  }

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  return (
    <div
      css={`
        max-width: 100%;
        width: ${({ theme }) => theme.remCalc('400')};
        padding: 20px;
      `}
    >
      <ContainerSvg>
        <SearcherByPerson css="width: 125px;" />
      </ContainerSvg>
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
        display="block"
        align="center"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        Buscar por ID
      </Typography>
      <FormContainer
        initialValues={{
          property_id: ''
        }}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={handleSubmitFail}
        autoComplete="off"
        onKeyPress={e => {
          if (e.code === 'Enter') {
            e.preventDefault()
            searchPropertyByKeyPressed(e.target.value)
          }
        }}
      >
        <div
          css={`
            display: flex;
            justify-content: center;
          `}
        >
          <TextField
            id={`${baseId}-input-propertyCode`}
            name="property_id"
            fullWidth
            placeholder="Ingresa el ID de la propiedad"
            validate={isRequired}
          />
        </div>
        <Grid
          container
          justify="space-between"
          css={`
            margin: 0 auto;
            margin-top: ${({ theme }) => theme.remCalc('30')};
          `}
        >
          <Button
            id={`${baseId}-button-cancel`}
            type="tertiary"
            variant="outlined"
            onClick={HideModal}
            css={`
              flex: 1;
              margin-right: ${({ theme }) => theme.remCalc('20')};
              border-radius: ${({ theme }) => theme.remCalc('50')} !important;
            `}
          >
            Cancelar
          </Button>
          <SubmitButton
            id={`${baseId}-button-seeDetail`}
            css={`
              flex: 1;
              border-radius: ${({ theme }) => theme.remCalc('50')} !important;
            `}
          >
            Buscar
          </SubmitButton>
        </Grid>
      </FormContainer>
    </div>
  )
}

SearchByCodeMC.defaultProps = {
  isFromSearcher: false,
  from: ''
}
SearchByCodeMC.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  history: PropTypes.object,
  token: PropTypes.string,
  onLoad: PropTypes.func,
  HideModal: PropTypes.func.isRequired,
  GetProperty: PropTypes.func.isRequired,
  ShowFlashNotification: PropTypes.func.isRequired,
  isFromSearcher: PropTypes.bool,
  from: PropTypes.string
}

const ContainerSvg = styled.div`
  display: flex;
  justify-content: center;
`

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  HideModal,
  GetProperty,
  ShowFlashNotification
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchByCodeMC)
)
