var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/FormInputs/Textarea.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { default as InputLabelContainer } from './InputLabelContainer';
import { Textarea as TextareaView } from '../../generics/FormInputViews';
import { inputLabelContainerProp } from './_types';
var Textarea = (_temp2 = _class = function (_PureComponent) {
  _inherits(Textarea, _PureComponent);

  function Textarea() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Textarea);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Textarea.__proto__ || Object.getPrototypeOf(Textarea)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      value: _this.props.componentType === 'CT' ? _this.props.value : undefined
    }, _this.onChangeCT = function (event) {
      if (event) {
        var value = event.target.value;


        _this.setState({ value: value }, function () {
          _this.props.secondaryAction(value);
        });
      }
    }, _this.onChangeRF = function (event) {
      if (event) {
        var value = event.target.value;

        _this.props.input.onChange(value);
        _this.props.secondaryAction(value);
      }
    }, _this.inputProp = function () {
      var _this$props = _this.props,
          input = _this$props.input,
          componentType = _this$props.componentType,
          placeholder = _this$props.placeholder,
          disabled = _this$props.disabled,
          meta = _this$props.meta,
          maxLength = _this$props.maxLength;


      switch (componentType) {
        case 'CT':
          return {
            value: _this.state.value,
            onChange: _this.onChangeCT,
            placeholder: placeholder,
            disabled: disabled,
            maxLength: maxLength
          };
        case 'extCT':
          return {
            value: _this.props.value,
            onChange: _this.props.onChange,
            placeholder: placeholder,
            disabled: disabled,
            maxLength: maxLength
          };
        case 'RF':
          return {
            disabled: disabled,
            error: meta.error,
            onBlur: input.onBlur,
            onChange: _this.onChangeRF,
            onFocus: input.onFocus,
            placeholder: placeholder,
            touched: meta.touched,
            value: input.value,
            maxLength: maxLength
          };
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Textarea, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          labelProps = _props.labelProps,
          label = _props.label,
          isRequired = _props.isRequired;

      return React.createElement(
        InputLabelContainer,
        Object.assign({
          isRequired: isRequired,
          label: label
        }, labelProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 119
          },
          __self: this
        }),
        React.createElement(TextareaView, Object.assign({ className: className }, this.inputProp(), {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 124
          },
          __self: this
        }))
      );
    }
  }]);

  return Textarea;
}(PureComponent), _class.propTypes = {
  /** ClassName to set if necessary */
  className: PropTypes.string,
  /** Component Type (helper for redux form) */
  componentType: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** Validation when component is RF*/
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func
  }),
  /** */
  isRequired: PropTypes.bool,
  /** */
  label: PropTypes.string,
  /** */
  labelProps: inputLabelContainerProp,
  /** */
  maxLength: PropTypes.number,
  /** */
  meta: PropTypes.object,
  /** Input name */
  name: PropTypes.string,
  /** Callback to handle input change (Only valid when is extCT) */
  onChange: PropTypes.func,
  /** */
  placeholder: PropTypes.string,
  /** CallBack to get the input value */
  secondaryAction: PropTypes.func,
  /** Initial value for input (only valid when is CT)*/
  value: PropTypes.any
}, _class.defaultProps = {
  secondaryAction: function secondaryAction() {
    return null;
  },
  type: 'text',
  label: '',
  sizeLabel: 2
}, _temp2);


export default Textarea;