export const GetNotifications = (Authorization, UserId, p = 0, l = 10) => ({
  type: 'GET_NOTIFICATIONS',
  payload: {
    request: {
      method: 'get',
      url: `/wall/user/${UserId}/notifications?p=${p}&l=${l}`,
      headers: {
        Authorization
      }
    }
  }
})

export const ResetNotifications = () => ({
  type: 'RESET_NOTIFICATIONS'
})

export const SetNotificationRead = index => ({
  type: 'SET_NOTIFICATION_READ',
  payload: {
    index
  }
})

export const CheckNotification = (Authorization, UserId, NotificationId) => ({
  type: 'CHECK_NOTIFICATION',
  payload: {
    request: {
      method: 'patch',
      url: `/wall/user/${UserId}/${NotificationId}/check`,
      headers: { Authorization }
    }
  }
})

export const MarkAllAsRead = (Authorization, UserId) => ({
  type: 'MARK_ALL_AS_READ',
  payload: {
    request: {
      method: 'patch',
      url: `/wall/user/${UserId}/remove_count`,
      headers: { Authorization }
    }
  }
})
