var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _jsxFileName = 'src/lib/elements/Selects/thirdParty/StaticSelect.js';

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';

var StaticSelect = (_temp = _class = function (_Component) {
  _inherits(StaticSelect, _Component);

  function StaticSelect(props) {
    _classCallCheck(this, StaticSelect);

    var _this = _possibleConstructorReturn(this, (StaticSelect.__proto__ || Object.getPrototypeOf(StaticSelect)).call(this, props));

    var _this$props = _this.props,
        onChange = _this$props.onChange,
        value = _this$props.value,
        initialValue = _this$props.initialValue;

    if (initialValue !== undefined && (value === undefined || value === '' || value === null)) {
      onChange(initialValue);
    }
    return _this;
  }

  _createClass(StaticSelect, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          disabled = _props.disabled,
          error = _props.error,
          getOptionLabel = _props.getOptionLabel,
          getOptionValue = _props.getOptionValue,
          onChange = _props.onChange,
          options = _props.options,
          placeholder = _props.placeholder,
          touched = _props.touched,
          value = _props.value,
          warning = _props.warning,
          props = _objectWithoutProperties(_props, ['className', 'disabled', 'error', 'getOptionLabel', 'getOptionValue', 'onChange', 'options', 'placeholder', 'touched', 'value', 'warning']);

      var inputClasses = classNames(className, 'select-input-container', {
        'select-input-container--error': touched && error
      });

      return React.createElement(
        Fragment,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 66
          },
          __self: this
        },
        React.createElement(Select, Object.assign({
          className: inputClasses,
          getOptionValue: getOptionValue,
          getOptionLabel: getOptionLabel,
          classNamePrefix: 'select-input',
          isDisabled: disabled,
          value: value,
          onChange: onChange,
          options: options,
          placeholder: placeholder
        }, props, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 67
          },
          __self: this
        })),
        touched && (error && React.createElement(
          'span',
          { className: 'input-container__error-message', __source: {
              fileName: _jsxFileName,
              lineNumber: 81
            },
            __self: this
          },
          error
        ) || warning && React.createElement(
          'span',
          {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 83
            },
            __self: this
          },
          warning
        ))
      );
    }
  }]);

  return StaticSelect;
}(Component), _class.propTypes = {
  /** */
  className: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** */
  error: PropTypes.string,
  /** */
  getOptionLabel: PropTypes.func,
  /** */
  getOptionValue: PropTypes.func,
  /** */
  initialValue: PropTypes.any,
  /** */
  onChange: PropTypes.func,
  /** */
  options: PropTypes.array,
  /** */
  placeholder: PropTypes.string,
  /** */
  touched: PropTypes.bool,
  /** */
  value: PropTypes.any,
  /** */
  warning: PropTypes.string
}, _temp);


export default StaticSelect;