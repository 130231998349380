var _jsxFileName = 'src/lib/elements/Layouts/Block.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';

var Block = function Block(_ref) {
  var children = _ref.children,
      className = _ref.className,
      onClick = _ref.onClick,
      index = _ref.index;

  return React.createElement(
    'div',
    { className: className, onClick: onClick, index: index, __source: {
        fileName: _jsxFileName,
        lineNumber: 6
      },
      __self: _this
    },
    children
  );
};

Block.propTypes = {
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  index: PropTypes.number,
  /** */
  onClick: PropTypes.func,
  /** */
  props: PropTypes.any
};

export default Block;