import { PROPERTY_TYPES } from 'utils/constants'

const _sufixAttr = ['sale', 'lease', 'presale']

const _keyLabel = { sale: 'Venta', lease: 'Renta', presale: 'Preventa' }

export const propertyNameGenerator = ({
  property_type,
  neighborhood,
  ...data
}) => {
  const activeOperations = _sufixAttr.filter(item => data[`${item}_active`])

  return `${property_type && PROPERTY_TYPES[property_type].name} ${
    activeOperations.length > 0
      ? activeOperations.length === 1
        ? `en ${_keyLabel[activeOperations[0]]}`
        : `en ${_keyLabel[activeOperations[0]]} y ${
            _keyLabel[activeOperations[1]]
          }`
      : ''
  } ${neighborhood ? `en ${neighborhood}` : ''}`
}

export const timeFormater = new Intl.DateTimeFormat('es-MX', {
  hour: 'numeric',
  minute: 'numeric'
})

export const dateFormater = new Intl.DateTimeFormat('es-MX', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
})

export const titleGenerator = (type, other) => {
  switch (type) {
    case 'CALL_CONTACT':
      return 'Llamar a'
    case 'VISIT_PROPERTY':
      return 'Visitar inmueble con'
    case 'SEND_DOCUMENTS':
      return 'Enviar documentos a'
    case 'GET_CONTACT_DOCUMENTS':
      return 'Recabar documentación de'
    case 'SIGN_AGREEMENT':
      return 'Firma contrato con'
    case 'SIGN_PROPERTY_DEED':
      return 'Firma escrituras con'
    case 'SEND_OFFER':
      return 'Enviar Oferta de compra a'
    case 'SEND_INFO_VIA_EMAIL':
      return 'Enviar información por correo electrónico a'
    default:
      return other ? `Otra tarea (${other}) -` : ''
  }
}

export const appointmentFormatter = item => {
  const date = typeof item.date === 'string' ? item.date.getDate() : item.date
  return {
    ...item,
    date,
    title: titleGenerator(item.task_type, item.other),
    timeFormated: timeFormater.format(date),
    dateFormated: dateFormater.format(date)
  }
}
