import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { FormBottomActions } from 'c3-ui/elements/Forms'
import { ReduxForm as Form } from 'components'
import { MainLink } from 'c3-ui/elements/Links'

import { SubmitButton } from 'c3-ui/elements/Buttons'
import { TextInput } from 'c3-ui/elements/FormInputs'
import { Text } from 'c3-ui/elements/Texts'
import { MarginContainer } from 'c3-ui/elements/Layouts'

const ForgotPasswordForm = ({
  handleSubmit,
  handleSubmitSuccess,
  handleSubmitFail,
  isSubmitting,
  hasSubmitSucceeded
}) => {
  return (
    <Form
      className="recover-password-module-form"
      form="recover-password-form"
      onSubmit={handleSubmit}
      onSubmitSuccess={handleSubmitSuccess}
      onSubmitFail={handleSubmitFail}
      withFeaturedImage
      noResponsive
    >
      {!hasSubmitSucceeded && (
        <Fragment>
          <MarginContainer>
            <TextInput
              id="login-forgotPasswordForm-input-email"
              fontAwesomeIcon="envelope"
              name="email"
              label="Correo electrónico"
              validations={[
                { validationName: 'emptiness' },
                {
                  validationName: 'validEmail'
                }
              ]}
            />
          </MarginContainer>
          <div
            css={`
              button[type='submit'] {
                background: ${({ theme }) => theme.palette.primary.main};
              }
            `}
          >
            <SubmitButton
              id="login-forgotPasswordForm-button-submit"
              disabled={isSubmitting}
            >
              Enviar Correo
            </SubmitButton>
          </div>
        </Fragment>
      )}
      <FormBottomActions center>
        <Text center>
          <MainLink
            noPadding
            route="/login"
            id="login-forgotPasswordForm-link-goToLogin"
          >
            Ir a inicio de sesión.
          </MainLink>
        </Text>
      </FormBottomActions>
    </Form>
  )
}

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleSubmitSuccess: PropTypes.func,
  handleSubmitFail: PropTypes.func,
  isSubmitting: PropTypes.bool,
  hasSubmitSucceeded: PropTypes.bool
}

export default ForgotPasswordForm
