import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grid, TextField, PhoneInput, withForm } from 'rc'
import { ContactSearcher } from 'components'
import { useFreemium } from 'modules/freemium/hooks/useFreemium'
import { RULES } from 'modules/freemium/utils/constants'
import { SearchContact } from 'reduxActions/contacts'

const ContainerStyled = styled(Grid)`
  ${({ theme }) => theme.media.desktop`
    width: ${({ theme }) => theme.remCalc('400')};
    max-width: 100%;
`}
`

const ContactForm = ({
  fc,
  match,
  shouldDisableNameOnStart,
  idBase,
  ...props
}) => {
  const hasContactName = fc && fc.values.contact_name ? true : false
  const [isNameDisabled, setIsNameDisabled] = useState(false)
  const { token } = useSelector(({ authReducer: { token } }) => ({ token }))
  const dispatch = useDispatch()
  const { validatePermission } = useFreemium()

  const hasNoPermission = !validatePermission({
    section: RULES.REQUIREMENTS_ASSIGN_CONTACT
  }).value

  useEffect(() => {
    if (shouldDisableNameOnStart && hasContactName) {
      setIsNameDisabled(true)
    }
  }, [setIsNameDisabled, shouldDisableNameOnStart, hasContactName])

  useEffect(() => {
    async function findContact() {
      await dispatch(SearchContact(token, 'Wiggot')).then(res => {
        const {
          payload: { data }
        } = res
        let initialFormBody = {}
        if (data.length === 0) {
          initialFormBody = {
            contact_name: 'Wiggot',
            contact_email: 'hola@wiggot.com',
            contact_id: null,
            contact_phone: null,
            contact_source: null,
            id: null
          }
        } else {
          initialFormBody = {
            contact_name: data[0].name,
            contact_email: data[0].main_email,
            contact_id: data[0].contact_id,
            contact_phone: null,
            contact_source: null,
            id: data[0].contact_id
          }
        }

        fc.customChange(initialFormBody)
      })
    }
    if (hasNoPermission && fc.customChange) findContact()
  }, [])

  return (
    <ContainerStyled container direction="column">
      <ContactSearcher
        idBase={idBase}
        name="contact_name"
        disabled={isNameDisabled}
      />
      <TextField
        id={`${idBase}-input-contactEmail`}
        name="contact_email"
        label="Correo"
        disabled={!hasContactName || hasNoPermission}
        autoComplete="no-autocomplete"
      />
      <PhoneInput
        id={`${idBase}-input-contactPhone`}
        name="contact_phone"
        label="Whatsapp"
        required
        disabled={!hasContactName || hasNoPermission}
      />
    </ContainerStyled>
  )
}

ContactForm.propTypes = {
  fc: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  shouldDisableNameOnStart: PropTypes.bool,
  idBase: PropTypes.string
}

export default withRouter(withForm(ContactForm))
