import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M7.90006 4.66669V1.16669L6.7334 2.33335"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.8999 1.16669L9.06657 2.33335"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.98324 7C2.6499 7 2.6499 8.04417 2.6499 9.33333V9.91667C2.6499 11.5267 2.6499 12.8333 5.56657 12.8333H10.2332C12.5666 12.8333 13.1499 11.5267 13.1499 9.91667V9.33333C13.1499 8.04417 13.1499 7 10.8166 7C10.2332 7 10.0699 7.1225 9.76657 7.35L9.17157 7.98C8.48324 8.715 7.31657 8.715 6.6224 7.98L6.03324 7.35C5.7299 7.1225 5.56657 7 4.98324 7Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.81641 6.99998V5.83332C3.81641 4.66082 3.81641 3.69248 5.56641 3.52332"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9834 6.99998V5.83332C11.9834 4.66082 11.9834 3.69248 10.2334 3.52332"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'FolderUp',
  '0 0 15 14'
)
