import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography, Button, Spinner } from 'rc'
import { Warning } from 'rc/Icons'

const GenericWarningMC = ({
  arrayText,
  HideModal,
  action,
  title,
  subtitle,
  buttonText,
  isLoadingMC,
  setIsLoadingMC
}) => {
  return (
    <ModalContainer>
      {!isLoadingMC ? <WarningIconStyled /> : <SpinnerStyled />}
      <Typography
        variant="h2"
        align="center"
        textTransform="initial"
        color="palette.primary.main"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('20')};
        `}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="body1"
          color="palette.new.text"
          fontWeight={400}
          arrayText={arrayText}
          css={`
            margin-bottom: ${({ theme, arrayText }) =>
              arrayText ? theme.remCalc('5') : theme.remCalc('20')};
            ${arrayText
              ? `
              width: 100%;
              text-align: left;
            `
              : ''}
          `}
        >
          {subtitle}
        </Typography>
      )}
      {arrayText &&
        arrayText.map((text, index) => (
          <Typography
            key={index}
            variant="body1"
            color="palette.new.text"
            fontWeight={400}
            align="left"
            css={`
              margin-bottom: ${({ theme }) => theme.remCalc('5')};
              width: 100%;
              &:last-child {
                margin-bottom: ${({ theme }) => theme.remCalc('20')};
              }
            `}
          >
            {text}
          </Typography>
        ))}
      <ButtonsContainer arrayText={arrayText}>
        <ButtonStyled
          size="large"
          variant="outlined"
          color="gray"
          onClick={HideModal}
          disabled={isLoadingMC}
        >
          {arrayText ? 'Aceptar' : 'Cancelar'}
        </ButtonStyled>
        {!arrayText ? (
          <ButtonStyled
            size="large"
            color="alert"
            onClick={() => {
              action()
              HideModal()
            }}
            disabled={isLoadingMC}
          >
            {buttonText}
          </ButtonStyled>
        ) : (
          ''
        )}
      </ButtonsContainer>
    </ModalContainer>
  )
}

GenericWarningMC.propTypes = {
  HideModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  isLoadingMC: PropTypes.bool,
  setIsLoadingMC: PropTypes.func,
  action: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  arrayText: PropTypes.array
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.remCalc('520')};
  padding: ${({ theme }) => theme.remCalc('32')};
  align-items: center;
`
const WarningIconStyled = styled(Warning)`
  fill: #ffbc08;
  width: ${({ theme }) => theme.remCalc('55')};
  margin-bottom: ${({ theme }) => theme.remCalc('24')};
`
const SpinnerStyled = styled(Spinner)`
  width: ${({ theme }) => theme.remCalc('55')};
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: ${({ arrayText }) =>
    arrayText ? 'center' : 'space-between'};
  width: 100%;
  margin-top: ${({ theme }) => theme.remCalc('10')};
`
const ButtonStyled = styled(Button)`
  border-radius: 50px !important;
  width: 49%;
`

export default GenericWarningMC
