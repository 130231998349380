import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path d="M65.56,39.14a3.44,3.44,0,0,0-3.44,3.44V64.43c1.76-2.71,4-4.83,6.88-4.36V42.58A3.44,3.44,0,0,0,65.56,39.14Z" />
    <path d="M74.25,41.85a3.41,3.41,0,0,0,2.35.91,4.33,4.33,0,0,0,2.72-1.09,3.28,3.28,0,0,0,0-4.88L42.58,1a3.61,3.61,0,0,0-4.89,0L1,36.79a3.6,3.6,0,0,0,0,4.88,3.61,3.61,0,0,0,4.89,0L40,8.37Z" />
    <path d="M58,76.56A8.3,8.3,0,0,1,59.24,73l-8,.15V54.7a4.67,4.67,0,0,0-4.7-4.7h-13a4.67,4.67,0,0,0-4.71,4.7V73.34H18V42.58a3.44,3.44,0,1,0-6.87,0v34.2a3.43,3.43,0,0,0,3.43,3.44H32.44a3.44,3.44,0,0,0,3.44-3.44V56.87h8.33V76.78a3.43,3.43,0,0,0,3.43,3.44l10.3-.1A10.27,10.27,0,0,1,58,76.56Z" />
    <path d="M97.84,72.44a24.93,24.93,0,0,0-6.17-6.34c-3-2.44-4.58-3.81-4.69-6a20.4,20.4,0,0,1,.23-3.88,3.53,3.53,0,0,0-.6-3.42l-.16-.16A3.5,3.5,0,0,0,83,52a16.85,16.85,0,0,0-6.88,5.42,20.57,20.57,0,0,0-3.76,8.5A3.63,3.63,0,0,0,70,63.6l-.17,0c-2-.47-3.45,1.1-4.4,2.65a30.62,30.62,0,0,0-2.87,6.07c-.08.22-.15.45-.22.67-2.16,6.62-1.62,12.83,1.55,18a18.51,18.51,0,0,0,11.33,8.27,21.84,21.84,0,0,0,5.48.76,3.88,3.88,0,0,0,1.35-.16,22.11,22.11,0,0,0,7.64-2.11c3.1-1.49,8.51-5.26,10-13.79A15.76,15.76,0,0,0,97.84,72.44Zm-19.48,5.5A3.5,3.5,0,0,0,79,75.09c-1-5.12-.49-9.35,1.61-12.63.94,4,4,6.53,6.6,8.6l.32.25c3.64,2.84,6.79,5.29,5.71,11.5a11.56,11.56,0,0,1-6.3,8.84,13.37,13.37,0,0,1-5.31,1.61h-.24c-1.32.07-8.09.15-11.79-5.9-2.15-3.48-2.45-7.47-.94-12.13l.13-.32a13.83,13.83,0,0,0,2.06,2.34,8.51,8.51,0,0,0,4.84,1.95A3.35,3.35,0,0,0,78.36,77.94Z" />
  </Fragment>,
  'ShareOffer',
  '0 0 100.23 99.87'
)
