import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dots3 } from 'rc/Icons'
import Dropdown, { DropdownAction, DropdownMenu } from 'rc/DropDown'
import { FreemiumWrapper } from 'modules/freemium'

const ButtonDropdown = ({ dropdownOptions }) => {
  return (
    <Dropdown
      css={`
        margin-left: auto;
      `}
    >
      <DropdownActionStyled>
        <Dots3Styled />
      </DropdownActionStyled>
      <DropdownMenuStyled>
        {dropdownOptions.map(
          ({ action, icon: Icon, label, spcColor, freemium }, index) => (
            <FreemiumWrapper key={index} section={freemium} custom>
              <DropdownElementStyled onClick={action} spcColor={spcColor}>
                <Icon /> {label}
              </DropdownElementStyled>
            </FreemiumWrapper>
          )
        )}
      </DropdownMenuStyled>
    </Dropdown>
  )
}

ButtonDropdown.propTypes = {
  dropdownOptions: PropTypes.array
}

const Dots3Styled = styled(Dots3)`
  width: 20px;
  transform: rotate(90deg);
  fill: ${({ theme }) => theme.palette.new.text} !important;
`

const DropdownElementStyled = styled.span`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  line-height: 150%;
  letter-spacing: 0.28px;
  align-items: center;
  color: ${({ theme, spcColor }) =>
    spcColor ? theme.palette.colors.red : '#605D75'};
  cursor: pointer;
  padding: ${({ theme }) => theme.remCalc('8 0')};
  &:hover {
    background-color: #f5f7fd;
    color: ${({ theme, spcColor }) =>
      spcColor ? theme.palette.colors.red : '#0076FF'};
    > svg {
      fill: ${({ theme, spcColor }) =>
        spcColor ? theme.palette.colors.red : '#0076FF'};
      color: ${({ theme, spcColor }) =>
        spcColor ? theme.palette.colors.red : '#0076FF'};
    }
  }
  > svg {
    width: ${({ theme }) => theme.remCalc('18')};
    fill: ${({ theme, spcColor }) =>
      spcColor ? theme.palette.colors.red : '#A0A3BD'};
    color: ${({ theme, spcColor }) =>
      spcColor ? theme.palette.colors.red : '#A0A3BD'};
    margin: ${({ theme }) => theme.remCalc('0 8 0 16')};
  }
`

const DropdownMenuStyled = styled(DropdownMenu)`
  right: 0;
  width: 240px;
  background-color: white;
  border-radius: 0.625rem;
`

const DropdownActionStyled = styled(DropdownAction)`
  cursor: pointer;
  ${({ theme }) => theme.box(40, 40, true)}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background-color: ${({ theme }) => theme.palette.gray.text};
  border: 2px solid #e8ecf9;
  svg {
    fill: #a0a3bd;
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.gray.light};
  }
`

export default ButtonDropdown
