import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1626 18.6868L25.3473 0.319092H30.4407L27.2538 18.6868H22.1626Z"
      fill="#004F8B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.7266 0.771259C44.7175 0.399 43.1364 0 41.1616 0C36.1291 0 32.5836 2.49111 32.5535 6.06135C32.5252 8.70059 35.0846 10.1731 37.0165 11.0517C38.9988 11.9517 39.6657 12.5259 39.656 13.3299C39.6435 14.561 38.0728 15.1236 36.6088 15.1236C34.5702 15.1236 33.4874 14.8453 31.8147 14.1596L31.1582 13.8675L30.4434 17.9793C31.6332 18.4919 33.8332 18.9363 36.1169 18.9592C41.4712 18.9592 44.9466 16.4966 44.9863 12.6839C45.0057 10.5946 43.6483 9.00424 40.7102 7.69359C38.9297 6.84387 37.8394 6.27669 37.8509 5.41641C37.8509 4.65289 38.7737 3.83659 40.7678 3.83659C42.4334 3.81126 43.6397 4.16804 44.5797 4.5403L45.0361 4.75211L45.7266 0.771259Z"
      fill="#004F8B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.7872 0.33667H54.8515C53.6323 0.33667 52.7199 0.66354 52.1844 1.85984L44.6201 18.6928H49.9687C49.9687 18.6928 50.8432 16.4293 51.0411 15.9325C51.6256 15.9325 56.821 15.9402 57.5638 15.9402C57.7162 16.5831 58.1837 18.6928 58.1837 18.6928H62.9098L58.7872 0.33667ZM52.5069 12.1754C52.9279 11.1171 54.5362 7.04085 54.5362 7.04085C54.5057 7.08976 54.9543 5.97756 55.2113 5.28793L55.5556 6.87161C55.5556 6.87161 56.5311 11.256 56.7347 12.1754H52.5069Z"
      fill="#004F8B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8904 0.33252L12.9038 12.8581L12.3726 10.3124C11.4444 7.378 8.55212 4.19902 5.31836 2.60759L9.87798 18.671L15.267 18.6651L23.2855 0.33252H17.8904Z"
      fill="#004F8B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.27806 0.321289H0.0651477L0 0.7034C6.38985 2.22375 10.6176 5.89779 12.3727 10.3125L10.5869 1.8719C10.2783 0.708678 9.38414 0.361752 8.27806 0.321289Z"
      fill="#ED9F2D"
    />
  </Fragment>,
  'Visa',
  '0 0 63 19'
)
