import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Typography, Grid } from 'rc'
import { useMedia } from 'rc/Hooks'
const ProfileSectionHeader = props => {
  const {
    theme: {
      devices: { tabletDown }
    }
  } = props
  const isTabletDown = useMedia(tabletDown)
  return <Typography variant={isTabletDown ? 'subtitle1' : 'h2'} {...props} />
}

ProfileSectionHeader.defaultProps = {
  color: 'palette.primary.main'
}
ProfileSectionHeader.propTypes = {
  theme: PropTypes.object
}
const SimpleText = props => {
  return (
    <Typography
      css={`
        padding-top: ${({ theme, flat }) => (flat ? 0 : theme.remCalc('8'))};
      `}
      {...props}
    />
  )
}

SimpleText.defaultProps = {
  variant: 'body1',
  color: 'black'
}

const ContainerBoxStyled = styled(Grid)`
  margin-top: ${props => (props.flat ? 0 : ({ theme }) => theme.remCalc('25'))};
  padding: 0;
`

ContainerBoxStyled.defaultProps = {
  container: true
}

const ProfileSectionHeaderWithTheme = withTheme(ProfileSectionHeader)
export {
  ProfileSectionHeaderWithTheme as ProfileSectionHeader,
  SimpleText,
  ContainerBoxStyled
}
