import stairsIcon from 'static/img/stairs-icon.png'
import serviceIcon from 'static/img/service-icon.png'
import parkingIcon from 'static/img/parking-icon.png'
import halfBathroomIcon from 'static/img/half-bathroom-icon.png'
import bathroomIcon from 'static/img/bathroom-icon.png'
import bedroomIcon from 'static/img/bedroom-icon.png'
import searchMiniIcon from 'static/img/search.png'
import uploadPropertyMiniIcon from 'static/img/upload-property.png'
import listMiniIcon from 'static/img/property-list.png'
import exitMiniIcon from 'static/img/log-out.png'
import userMiniIcon from 'static/img/user.png'
import exitIcon from 'static/img/exit-icon.svg'
import logoIcon from 'static/img/wiggot-logo.svg'
import userIcon from 'static/img/user-icon.svg'
import noPhotoHolderIcon from 'static/img/no-photo.png'
import surfaceIcon from 'static/img/ruler-icon.svg'
import restroomsIcon from 'static/img/restrooms-icon.svg'
import roomsIcon from 'static/img/rooms-icon.svg'
import checkmarkIcon from 'static/img/green-checkmark.svg'
import alertIcon from 'static/img/alert-icon.jpg'
import wiggotMiniIcon from 'static/img/wiggot-mini-icon-light.svg'
import questionMarkIcon from 'static/img/question-mark.svg'
import starCheckmarkIcon from 'static/img/star-checkmark.svg'
import alertGrayIcon from 'static/img/alert-icon-gray.jpg'
import externalLinkIcon from 'static/img/link.svg'
import wiggotMapMarker from 'static/img/wiggot-map-marker.svg'
import view360Icon from 'static/img/view360.png'
import videoIcon from 'static/img/video.png'
import stairsWithIndicatorIcon from 'static/img/stairs-with-indicator.jpg'
import halfbathrromModernIcon from 'static/img/halfbathroom-modern-icon.jpg'
import chairIcon from 'static/img/chair-icon.svg'
import bathroomModernIcon from 'static/img/bathroom-modern-icon.svg'
import mailIcon from 'static/img/contact-mail-icon.svg'
import phoneIcon from 'static/img/contact-phone-icon.svg'
import navigatiorIcon from 'static/img/contact-navigator-icon.svg'
import sheetIcon from 'static/img/contact-sheet-icon.svg'
import percentIcon from 'static/img/percent-outline.png'
import agencyWaitingRoom from 'static/img/agency-waiting-room.jpg'
import mexicoFlagIcon from 'static/img/mexico-flag-icon.png'
import sheetAddIcon from 'static/img/sheet-add-icon.svg'
import houseSearchIcon from 'static/img/house-search-icon.svg'
import handsUpIcon from 'static/img/hands-up.svg'
import wifiIcon from 'static/img/wifi.svg'
import noUserImageIcon from 'static/img/no-user-photo-icon.svg'
import grayCheckIcon from 'static/img/gray-checkbox-icon.svg'
import groundSizeIcon from 'static/img/ground-size-icon.svg'
import constructionSizeIcon from 'static/img/construction-size-icon.svg'
import gardenSizeIcon from 'static/img/garden-size-icon.svg'
import parkingLotsIcon from 'static/img/parking-lots-icon.svg'
import terraceSizeIcon from 'static/img/terrace-size-icon.svg'
import totalUnitsIcon from 'static/img/unit-count-icon.svg'
import bathroomsIcon from 'static/img/bathrooms-icon.svg'
import bedroomsIcon from 'static/img/bedrooms-icon.svg'
import deliveryDateIcon from 'static/img/delivery-date-icon.svg'
import floorsIcon from 'static/img/floors-icon.svg'
import halfBathroomsIcon from 'static/img/half-bathrooms-icon.svg'
import sharedCommissionIcon from 'static/img/share-comission-icon.svg'
import dropDownBlueIcon from 'static/img/drop-down-blue-icon.svg'
import wiggotIsotype from 'static/img/wiggot-isotype.svg'
import saveTenPercentFlag from 'static/img/save-ten-percent-flag.svg'
import profileSearchIcon from 'static/img/wt_profile_search.png'
import i24Isotype from 'static/img/i24-isotype.svg'
import mlIsotype from 'static/img/ml-isotype.svg'
import cytIsotype from 'static/img/cyt-isotype.svg'
import vivanunciosIsotype from 'static/img/vivanuncios-isotype.svg'
import propiedadescomIsotype from 'static/img/propiedadescom-isotype.svg'
import allpropertyIsotype from 'static/img/allproperty-isotype.svg'
import portalTerrenoIsotype from 'static/img/portal-terreno-isotype.svg'
import proppitIsotype from 'static/img/proppit-isotype.svg'
import excelsiorIsotype from 'static/img/excelsior-isotype.svg'
import segundaManoIsotype from 'static/img/segunda-mano-isotype.svg'
import mitulaIsotype from 'static/img/mitula-isotype.svg'
import icasasIsotype from 'static/img/icasas-isotype.svg'
import neximoIsotype from 'static/img/neximo-isotype.svg'
import houmIsotype from 'static/img/houm-isotype.svg'
import mudafyIsotype from 'static/img/mudafy-isotype.svg'
import flatmxIsotype from 'static/img/flatmx-isotype.svg'
import laHauseIsotype from 'static/img/la-hause-isotype.svg'
import elInformadorLogo from 'static/img/el-informador-logo.svg'
import elInformadorIsotype from 'static/img/el-informador-isotype.svg'
import wiggotBoostIsotype from 'static/img/wiggot-boost-isotype.svg'
import monopolioIsotype from 'static/logos/monopolio_icon.svg'

export const ICONS = {
  stairsIcon,
  serviceIcon,
  parkingIcon,
  halfBathroomIcon,
  bathroomIcon,
  bedroomIcon,
  searchMiniIcon,
  uploadPropertyMiniIcon,
  listMiniIcon,
  exitMiniIcon,
  userMiniIcon,
  exitIcon,
  logoIcon,
  userIcon,
  noPhotoHolderIcon,
  surfaceIcon,
  restroomsIcon,
  roomsIcon,
  checkmarkIcon,
  alertIcon,
  wiggotMiniIcon,
  questionMarkIcon,
  starCheckmarkIcon,
  alertGrayIcon,
  externalLinkIcon,
  wiggotMapMarker,
  view360Icon,
  videoIcon,
  stairsWithIndicatorIcon,
  halfbathrromModernIcon,
  chairIcon,
  bathroomModernIcon,
  mailIcon,
  phoneIcon,
  navigatiorIcon,
  sheetIcon,
  percentIcon,
  agencyWaitingRoom,
  mexicoFlagIcon,
  sheetAddIcon,
  houseSearchIcon,
  handsUpIcon,
  wifiIcon,
  noUserImageIcon,
  grayCheckIcon,
  groundSizeIcon,
  constructionSizeIcon,
  gardenSizeIcon,
  parkingLotsIcon,
  terraceSizeIcon,
  totalUnitsIcon,
  bathroomsIcon,
  bedroomsIcon,
  deliveryDateIcon,
  floorsIcon,
  halfBathroomsIcon,
  sharedCommissionIcon,
  dropDownBlueIcon,
  wiggotIsotype,
  saveTenPercentFlag,
  profileSearchIcon,
  i24Isotype,
  mlIsotype,
  cytIsotype,
  vivanunciosIsotype,
  propiedadescomIsotype,
  allpropertyIsotype,
  portalTerrenoIsotype,
  proppitIsotype,
  excelsiorIsotype,
  segundaManoIsotype,
  mitulaIsotype,
  icasasIsotype,
  neximoIsotype,
  houmIsotype,
  mudafyIsotype,
  flatmxIsotype,
  laHauseIsotype,
  elInformadorLogo,
  elInformadorIsotype,
  wiggotBoostIsotype,
  monopolioIsotype
}
