import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

function CircleLogo({ src, icon: Icon }) {
  return (
    <LogoStyled>
      {Icon && <Icon />}
      {src && (
        <img
          alt="Logo"
          src={src}
          css={`
            height: ${({ theme }) => theme.remCalc('20')};
          `}
        />
      )}
    </LogoStyled>
  )
}

CircleLogo.propTypes = {
  icon: PropTypes.any,
  src: PropTypes.string
}

const LogoStyled = styled.div`
  ${({ theme }) => theme.box(35, 35, true)}
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 7px rgba(160, 163, 189, 0.5);
  margin-right: ${({ theme }) => theme.remCalc('10')};
  svg {
    width: ${({ theme }) => theme.remCalc('20')};
    height: ${({ theme }) => theme.remCalc('20')};
  }
`

export default CircleLogo
