import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography, Button, Link } from 'rc'

const WhyMyTaxIdMC = ({ HideModal }) => {
  return (
    <ReasonModalContainer>
      <Typography
        variant={'h6'}
        color="palette.primary.main"
        textTransform="initial"
        align="center"
        css={`margin-bottom: ${({ theme }) => theme.remCalc('22')};`}
      >
        ¿Por qué solicitamos tu RFC y razón social?
      </Typography>
      <Typography align="justify">
        La Ley Federal de Protección de Datos Personales en Posesión de los Particulares dispone que todas las páginas web están obligadas a establecer una política de privacidad completa, clara y actualizada.
        Ésta deberá mostrar la información del responsable del tratamiento de los datos que se recaben en el sitio, es decir, la identidad y domicilio de la empresa o de quien la representa.
      </Typography>
      <Typography css={`margin: ${({ theme }) => theme.remCalc('24 0')};`}>
        Si lo deseas, puedes consultar los lineamientos oficiales{' '}
        <Link 
          underline="always"
          target="_blank"
          href="https://www.dof.gob.mx/nota_detalle.php?codigo=5284966&fecha=17/01/2013#gsc.tab=0"
        >
          aquí.
        </Link>
      </Typography>
      <ButtonStyled onClick={HideModal}>
        Cerrar
      </ButtonStyled>
    </ReasonModalContainer>
  )
}

const ReasonModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.remCalc('512')};
  padding: ${({ theme }) => theme.remCalc('30')};
  align-items: center;
`

const ButtonStyled = styled(Button)`
  border-radius: 50px !important;
  width: 50%;
`

WhyMyTaxIdMC.propTypes = {
  HideModal: PropTypes.func
}

export default WhyMyTaxIdMC
