var _jsxFileName = 'src/lib/elements/Layouts/PanelContainer.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InlineBlock } from './';
var PanelContainer = function PanelContainer(_ref) {
  var children = _ref.children,
      className = _ref.className;

  var panelClasses = classNames(className, 'panel-container');
  return React.createElement(
    InlineBlock,
    { className: panelClasses, __source: {
        fileName: _jsxFileName,
        lineNumber: 8
      },
      __self: _this
    },
    children
  );
};

PanelContainer.propTypes = {
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string
};

export default PanelContainer;