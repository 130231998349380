import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Typography } from 'rc'
import { CrossIcon } from 'components/Icons'

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.remCalc('13 25 44 25')};
  max-width: ${({ theme }) => theme.remCalc('340')};
`

const ButtonStyled = styled(Button)`
  height: ${({ theme }) => theme.remCalc('54')};
  margin-top: ${({ theme }) => theme.remCalc('34')};
`

const CrossIconStyled = styled(CrossIcon)`
  height: ${({ theme }) => theme.remCalc('70')};
`

const ErrorMC = props => {
  return (
    <ContainerStyled className={props.className}>
      <CrossIconStyled />
      <Typography variant="h6" textTransform="none" align="center">
        {props.message}
      </Typography>
      <Typography variant="p2" align="center">
        {props.secondaryText}
      </Typography>
      <ButtonStyled variant="outlined" color="gray" full onClick={props.onNext}>
        Cerrar
      </ButtonStyled>
    </ContainerStyled>
  )
}

ErrorMC.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  secondaryText: PropTypes.string,
  onNext: PropTypes.func
}

export default ErrorMC
