import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { Trash } from 'rc/Icons'

import { Badge } from 'components'
import AlertwarningIcon from 'static/img/alert-warning.svg'
import {
  CardContainer,
  ImageContainer,
  Image,
  Subtitle,
  Text,
  InLineRow,
  ContainerAmenities,
  LocationIcon,
  CarIcon,
  BedIcon,
  BathtubIcon,
  AreaIcon,
  BricksIcon,
  Circle
} from './CommonCardStyles'
import {
  renderTitle,
  buildTitle,
  getFormattedPrices,
  goToDetail
} from './cardHelpers'

const PropertyVerticalCard = ({
  isPublished = true,
  propertyType,
  title,
  image,
  presaleActive,
  saleActive,
  developmentId,
  presalePrice,
  presaleSharedCommission,
  salePrice,
  saleSharedCommission,
  presaleCurrency,
  saleCurrency,
  leaseActive,
  leasePrice,
  leaseSharedCommission,
  leaseCurrency,
  logo,
  city,
  neighborhood,
  bedrooms,
  bathrooms,
  parkingLots,
  groundSize,
  minGroundSize,
  constructionSize,
  minConstructionSize,
  propertyId,
  modelDevelopmentId,
  deleteAction,
  profilePhoto,
  profileId,
  isMobile,
  userOwned,
  agencyOwned,
  ...props
}) => {
  const isProperty = !developmentId
  const isModelDevelopment = modelDevelopmentId === propertyId
  const { roles } = useSelector(({ authReducer: { roles } }) => ({ roles }))

  const propertyDetail = [
    {
      icon: BedIcon,
      value: bedrooms
    },
    {
      icon: BathtubIcon,
      value: bathrooms
    },
    {
      icon: CarIcon,
      value: parkingLots
    },
    {
      icon: AreaIcon,
      value: isProperty && groundSize ? `${groundSize}m²` : ''
    },
    {
      icon: AreaIcon,
      value: isModelDevelopment && minGroundSize ? `${minGroundSize}m²` : ''
    },
    {
      icon: BricksIcon,
      value: isProperty && constructionSize ? `${constructionSize}m²` : ''
    },
    {
      icon: BricksIcon,
      value:
        isModelDevelopment && minConstructionSize
          ? `${minConstructionSize}m²`
          : ''
    }
  ].filter(({ value }) => value && (value > 0 || value !== 0))

  const handleGoToDetail = () => {
    const goToNewDetail = () =>
      window.open(
        `${process.env.REACT_APP_NEW_APLICATION_URL}/my-properties/property-detail/${propertyId}`,
        '_blank'
      )

    if (isPublished) {
      if (
        isProperty &&
        (userOwned === 1 || (agencyOwned === 1 && roles?.AGENCY))
      ) {
        goToNewDetail()
      } else {
        goToDetail(developmentId, modelDevelopmentId, propertyId)
      }
    }
  }

  const renderNotAvailableTag = () => {
    return (
      <>
        <NotAvailableTag>
          <img
            src={AlertwarningIcon}
            alt="CertifiedTag"
            css={`
              color: #fff;
              margin-right: 3px;
              ${({ theme }) => theme.box(20, 20)};
            `}
          />
          No disponible
        </NotAvailableTag>
      </>
    )
  }

  return (
    <CardContainer
      onClick={handleGoToDetail}
      isMobile={isMobile}
      isPublished={isPublished}
    >
      <ImageContainer>
        {deleteAction && (
          <Circle
            onClick={deleteAction}
            css={`
              top: 10px;
              right: 10px;
              cursor: pointer;
            `}
          >
            <Trash
              css={`
                width: 17px;
                fill: #a0a3bd;
              `}
            />
          </Circle>
        )}
        <Image id={image} objectFit="cover" />
        {isProperty && profileId && profilePhoto && (
          <Circle
            width="30px"
            height="30px"
            css={`
              bottom: 10px;
              left: 10px;
            `}
          >
            <Image id={profilePhoto} objectFit="cover" />
          </Circle>
        )}
        {isModelDevelopment && logo && (
          <Circle
            width="30px"
            height="30px"
            css={`
              bottom: 10px;
              left: 10px;
            `}
          >
            <Image id={logo} objectFit="cover" />
          </Circle>
        )}
        {!isPublished && renderNotAvailableTag()}
      </ImageContainer>
      <Badge>
        {isProperty
          ? 'Propiedad'
          : isModelDevelopment
          ? 'Modelo'
          : 'Desarrollo'}
      </Badge>
      <InLineRow
        vertical
        css={`
          width: 100%;
          height: 135px;
          justify-content: space-between;
        `}
      >
        <InLineRow vertical>
          <Subtitle color="palette.primary.main">
            {title
              ? renderTitle(title)
              : buildTitle({
                  propertyType,
                  saleActive,
                  presaleActive,
                  leaseActive
                })}
          </Subtitle>
          <InLineRow
            css={`
              margin-top: -2px;
            `}
          >
            <LocationIcon />
            <Text variant="body3" lineHeight="14px">
              {renderTitle(`${neighborhood}, ${city}`, 35)}
            </Text>
          </InLineRow>
        </InLineRow>
        {getFormattedPrices({
          isDevelopment: developmentId,
          presaleActive,
          saleActive,
          presalePrice,
          presaleSharedCommission,
          salePrice,
          saleSharedCommission,
          presaleCurrency,
          saleCurrency,
          leaseActive,
          leasePrice,
          leaseSharedCommission,
          leaseCurrency
        }).map(({ price, currency, label }, index) => (
          <InLineRow key={`propertyCard-price-${index}`}>
            <Subtitle>
              {developmentId ? 'Desde ' : ''}
              {price} {currency ? currency.toLowerCase() : ''}
            </Subtitle>
            <Text variant="body3">{label}</Text>
          </InLineRow>
        ))}
        <ContainerAmenities>
          {propertyDetail.map(({ icon: Icon, value }, index) => (
            <InLineRow key={`propertyDetail-amenities-${index}`}>
              <Icon />
              <span>{value}</span>
            </InLineRow>
          ))}
        </ContainerAmenities>
      </InLineRow>
    </CardContainer>
  )
}

const NotAvailableTag = styled.span`
  background: #605d75;
  padding: 1px 10px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 10%;
  z-index: 4;

  &:before {
    content: '';
    position: absolute;
    right: -9%;
    top: 0;
    border-style: solid;
    border-width: 11px 11px 11px 0;
    border-color: #605d75 transparent #605d75 #605d75;
  }
`

PropertyVerticalCard.propTypes = {
  propertyType: PropTypes.string,
  image: PropTypes.string,
  city: PropTypes.string,
  neighborhood: PropTypes.string,
  title: PropTypes.string,
  presaleActive: PropTypes.bool,
  saleActive: PropTypes.bool,
  developmentId: PropTypes.string,
  deleteAction: PropTypes.func,
  presalePrice: PropTypes.number,
  presaleSharedCommission: PropTypes.number,
  salePrice: PropTypes.number,
  saleSharedCommission: PropTypes.number,
  presaleCurrency: PropTypes.string,
  saleCurrency: PropTypes.string,
  leaseActive: PropTypes.bool,
  leasePrice: PropTypes.number,
  leaseSharedCommission: PropTypes.number,
  leaseCurrency: PropTypes.string,
  bedrooms: PropTypes.number,
  bathrooms: PropTypes.number,
  parkingLots: PropTypes.number,
  groundSize: PropTypes.number,
  constructionSize: PropTypes.number,
  propertyId: PropTypes.string,
  profilePhoto: PropTypes.string,
  profileId: PropTypes.string,
  isMobile: PropTypes.bool,
  userOwned: PropTypes.number,
  agencyOwned: PropTypes.number
}

export default PropertyVerticalCard
