import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Grid, Typography } from 'rc'
import { useMedia } from 'rc/Hooks'
import { PencilRounded } from 'rc/Icons'
import { Button } from 'components'

const LineStyled = styled.span`
  position: relative;
  flex: 1;
  width: 2px;
  background-color: ${({ theme, isVisible }) => isVisible && '#E1E6ED'};
`

const ProfileEditableItem = ({
  title,
  subtitle,
  icon: Icon,
  iconColor,
  isLast,
  isFirst,
  isEditable,
  onEditClick,
  index,
  theme: {
    devices: { tabletDown }
  },
  idBase,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const isTabletDown = useMedia(tabletDown)
  return (
    <Grid
      {...props}
      container
      css={`
        width: 100%;
      `}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Grid
        xsShrink
        item
        flat
        css={`
          margin-right: ${({ theme }) => theme.remCalc('40')};
        `}
        container
        direction="column"
        justify="center"
      >
        <LineStyled isVisible={!isFirst} />
        <Grid
          iconColor={iconColor}
          item
          flat
          xsShrink
          css={` 
            ${({ theme }) => theme.box(45, 45, true)}
            background-color: ${({ theme, iconColor }) =>
              theme.palette[iconColor].main};
            svg{
              color: ${({ theme }) => theme.palette.base.white};
              ${({ theme }) => theme.box(25, 20)}
            }
            ${({ theme }) => theme.media.desktop`
            ${({ theme }) => theme.box(60, 60, true)}
            svg{
              ${({ theme }) => theme.box(40, 30)}
            }
            `}
          `}
          container
          center
        >
          <Icon />
        </Grid>
        <LineStyled isVisible={!isLast} />
      </Grid>

      <Grid
        item
        flat
        xsAuto
        alignSelf="center"
        isLastItem={isLast}
        css={`
          position: relative;
          padding: 20px 0;
          border-bottom: ${({ isLastItem }) =>
            !isLastItem && '1px solid #E1E6ED'};
        `}
      >
        <div
          css={`
            max-width: calc(100% - 40px);
            ${({ theme }) => theme.media.desktop`
          max-width: 100%;
          `}
          `}
        >
          <Typography variant="h4" display="block">
            {title}
          </Typography>
          <Typography variant="body2" display="block">
            {subtitle}
          </Typography>
        </div>{' '}
        {isEditable && (isHovered || isFirst || isTabletDown) ? (
          <Button
            id={`${idBase}-button-editElement-${index}`}
            radius="100%"
            css={`
              ${({ theme }) => theme.position('absolute', 10, 10)}
              ${({ theme }) => theme.box(30, 30)}
              min-height: 30px;
              padding: 0;
              ${({ theme }) => theme.media.desktop`
              padding: initial;
              min-height: 55px;
              
              ${({ theme }) => theme.box(55, 55)}
              `}
            `}
            onClick={onEditClick}
          >
            <PencilRounded
              css={`
                ${({ theme }) => theme.box(20, 20)}

                ${({ theme }) => theme.media.desktop`
                   ${({ theme }) => theme.box(29, 29)}
              `}
              `}
            />
          </Button>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  )
}

ProfileEditableItem.defaultProps = {
  iconColor: 'primary'
}

ProfileEditableItem.propTypes = {
  theme: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  isEditable: PropTypes.bool,
  onEditClick: PropTypes.func,
  idBase: PropTypes.string,
  index: PropTypes.number
}

export default withTheme(ProfileEditableItem)
