import React,  { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Banner = ({ banners }) => {
  const [itemToShow, setItemToShow] = useState(0)

  return (
    <Container>
      <Content
        css={`
          background-image: url(${banners[itemToShow].image});
          background-repeat: no-repeat, no-repeat;
          background-size: contain;
        `}
      >
        <Title>
          <div>
            <h3>
              {banners[itemToShow].title}
            </h3>
          </div>
        </Title>
        <Actions>
          {banners.map((_, index) => (
            <div
              key={index}
              className={index === itemToShow ? 'current' : ''}
              onClick={(e) => {
                e.preventDefault()
                setItemToShow(index)
              }}
            />
          ))}
        </Actions>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  /*min-height: ${({ theme }) => theme.remCalc('300')};*/
`
const Content = styled.div`
  margin-top: ${({ theme }) => theme.remCalc('24')};
  padding: ${({ theme }) => theme.remCalc('10')};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
const Title = styled.div`
  div {
    width: ${({ theme }) => theme.remCalc('530')};
    padding: ${({ theme }) => theme.remCalc('45 25 10')};

    ${({ theme }) => theme.media.tabletDown`width: 100%;`}
    ${({ theme }) => theme.media.tabletDown`padding: ${({ theme }) => theme.remCalc('0 13 10')};`}

    h3 {
      font-weight: 700;
      color: white;
      font-size: ${({ theme }) => theme.remCalc('32')};
      min-height: ${({ theme }) => theme.remCalc('140')};

      ${({ theme }) => theme.media.tabletDown`min-height: ${({ theme }) => theme.remCalc('30')};`}
      ${({ theme }) => theme.media.tabletDown`font-size: ${({ theme }) => theme.remCalc('12')};`}
    }
  }
`
const Actions = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.remCalc('10')};

  div {
    width: ${({ theme }) => theme.remCalc('15')};
    height: ${({ theme }) => theme.remCalc('15')};
    border: 2px solid white;
    border-radius: 50%;
    margin-right: ${({ theme }) => theme.remCalc('8')};
    :hover {
      cursor: pointer;
    }
  }
  div.current {
    background-color: white;
  }
`

Banner.propTypes = {
  banners: PropTypes.array,
}

export default Banner