import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <rect width="26" height="26" rx="15" fill="transparent" />
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M3 19C2.45 19 1.97917 18.8042 1.5875 18.4125C1.19583 18.0208 1 17.55 1 17V7C1 6.45 1.19583 5.97917 1.5875 5.5875C1.97917 5.19583 2.45 5 3 5H13C13.55 5 14.0208 5.19583 14.4125 5.5875C14.8042 5.97917 15 6.45 15 7V17C15 17.55 14.8042 18.0208 14.4125 18.4125C14.0208 18.8042 13.55 19 13 19H3ZM3 15.35C3.73333 14.9167 4.51667 14.5833 5.35 14.35C6.18333 14.1167 7.06667 14 8 14C8.93333 14 9.81667 14.1167 10.65 14.35C11.4833 14.5833 12.2667 14.9167 13 15.35V7H3V15.35ZM8 16C7.31667 16 6.65 16.0833 6 16.25C5.35 16.4167 4.73333 16.6667 4.15 17H11.85C11.2667 16.6667 10.65 16.4167 10 16.25C9.35 16.0833 8.68333 16 8 16ZM8 13.25C7.25 13.25 6.60417 12.9792 6.0625 12.4375C5.52083 11.8958 5.25 11.25 5.25 10.5C5.25 9.75 5.52083 9.10417 6.0625 8.5625C6.60417 8.02083 7.25 7.75 8 7.75C8.75 7.75 9.39583 8.02083 9.9375 8.5625C10.4792 9.10417 10.75 9.75 10.75 10.5C10.75 11.25 10.4792 11.8958 9.9375 12.4375C9.39583 12.9792 8.75 13.25 8 13.25ZM8 11.4C8.25 11.4 8.4625 11.3125 8.6375 11.1375C8.8125 10.9625 8.9 10.75 8.9 10.5C8.9 10.25 8.8125 10.0375 8.6375 9.8625C8.4625 9.6875 8.25 9.6 8 9.6C7.75 9.6 7.5375 9.6875 7.3625 9.8625C7.1875 10.0375 7.1 10.25 7.1 10.5C7.1 10.75 7.1875 10.9625 7.3625 11.1375C7.5375 11.3125 7.75 11.4 8 11.4ZM17 19V5H19V19H17ZM21 19V5H23V19H21Z"
    />
  </Fragment>,
  'LeadManagement',
  '0 0 24 24'
)
