var _jsxFileName = 'src/lib/elements/Texts/Paragraph.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
var Paragraph = function Paragraph(_ref) {
  var className = _ref.className,
      children = _ref.children,
      center = _ref.center,
      gray = _ref.gray;

  var paragraphClasses = classNames(className, 'paragraph', {
    'paragraph--centered': center,
    'paragraph--gray': gray
  });

  return React.createElement(
    'p',
    { className: paragraphClasses, __source: {
        fileName: _jsxFileName,
        lineNumber: 10
      },
      __self: _this
    },
    children
  );
};

Paragraph.propTypes = {
  /** */
  children: PropTypes.node.isRequired,
  /** */
  center: PropTypes.bool,
  /** */
  className: PropTypes.string,
  /** */
  gray: PropTypes.bool
};

export default Paragraph;