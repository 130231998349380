var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/Buttons/SectionButton.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

/** Displays a section button styled. Mainly used for sectionView. */
export var SectionButton = (_temp2 = _class = function (_Component) {
  _inherits(SectionButton, _Component);

  function SectionButton() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SectionButton);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SectionButton.__proto__ || Object.getPrototypeOf(SectionButton)).call.apply(_ref, [this].concat(args))), _this), _this.onButtonClick = function () {
      var onClick = _this.props.onClick;

      _this._btn.blur();
      onClick();
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(SectionButton, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          isSelected = _props.isSelected,
          children = _props.children,
          id = _props.id;

      var baseClassname = ClassNames('section-button', {
        'section-button--selected': isSelected
      });
      return React.createElement(
        'div',
        { className: baseClassname, __source: {
            fileName: _jsxFileName,
            lineNumber: 30
          },
          __self: this
        },
        React.createElement(
          'button',
          {
            ref: function ref(node) {
              return _this2._btn = node;
            },
            onClick: this.onButtonClick,
            id: id,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 31
            },
            __self: this
          },
          React.createElement(
            'div',
            { className: 'text', __source: {
                fileName: _jsxFileName,
                lineNumber: 36
              },
              __self: this
            },
            children
          ),
          React.createElement('i', { className: 'fa fa-chevron-right', __source: {
              fileName: _jsxFileName,
              lineNumber: 37
            },
            __self: this
          })
        )
      );
    }
  }]);

  return SectionButton;
}(Component), _class.propTypes = {
  /** Text to be displayed. */
  children: PropTypes.any,
  /**Identifier */
  id: PropTypes.string,
  /** When button is in active mode. */
  isSelected: PropTypes.bool,
  /** Handler of click action on button. */
  onClick: PropTypes.func
}, _temp2);

export default SectionButton;