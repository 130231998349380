import React, { useEffect, useState } from 'react'
import { number, func, any, string } from 'prop-types'
import styled from 'styled-components'
import { DualSlider } from './index'
import LineChart from './LineChart'

function ChartSlider(props) {
  const totalUnits = props.max - props.min

  const [minEnd, setMinEnd] = useState(() =>
    props.minValue ? (props.minValue * 100) / totalUnits : 0
  )
  const [maxEnd, setMaxEnd] = useState(() =>
    props.maxValue ? (props.maxValue * 100) / totalUnits : 0
  )

  useEffect(() => {
    const val = ((props.minValue - props.min) * 100) / totalUnits
    if (!isNaN(val)) {
      setMinEnd(Math.ceil(val))
    }
  }, [props.minValue, totalUnits])

  useEffect(() => {
    const val = ((props.maxValue - props.min) * 100) / totalUnits

    if (!isNaN(val)) {
      setMaxEnd(Math.ceil(100 - val))
    } else {
      setMaxEnd(0)
    }
  }, [props.maxValue, totalUnits])

  return (
    <div className={props.className}>
      <ChartContainer>
        <LineChart
          width={props.chartWidth}
          height={props.chartHeight}
          data={props.chartPoints}
        />
        <Limit className="left-limit" style={{ width: `${minEnd}%` }} />
        <Limit className="right-limit" style={{ width: `${maxEnd}%` }} />
      </ChartContainer>
      {props.min > -1 && props.max && (
        <DualSlider
          disabled={props.disabled}
          min={props.min}
          debouncedSubmit={props.debouncedSubmit}
          max={props.max}
          minValue={props.minValue}
          maxValue={props.maxValue}
          onChange={props.onChange}
          step={props.step}
        />
      )}
    </div>
  )
}

ChartSlider.propTypes = {
  children: any,
  className: string,
  chartHeight: number,
  chartPoints: number,
  chartWidth: number,
  step: number,
  min: number,
  max: number,
  minValue: number,
  maxValue: number,
  onChange: func
}

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  .left-limit {
    left: 0;
  }
  .right-limit {
    right: 0;
  }
`

const Limit = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  opacity: 0.3;
  background: #97a4b1;
`

export default ChartSlider
