import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Image } from 'c3-ui/generics/Images'
import { Layout } from 'c3-ui/elements/Layouts'
import { Text, Title } from 'c3-ui/elements/Texts'
import { SecondaryButton } from 'c3-ui/elements/Buttons'

import AlertImg from 'static/img/alert-big.svg'

import { HideModal } from 'reduxActions/services'
class AssociationInvitationErrorMC extends Component {
  static propTypes = {
    HideModal: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string
  }
  BASE_CLASSNAME = 'association-invitation-error-mc'

  render() {
    return (
      <Layout className={`${this.BASE_CLASSNAME}`} center>
        <Title
          className={`${this.BASE_CLASSNAME}__title`}
          smallSize
          gray
          uppercase
          center
        >
          {this.props.title}
        </Title>
        <Image src={AlertImg} small={3} fitContent />
        <Text
          gray
          largeSize
          center
          className={`${this.BASE_CLASSNAME}__description`}
        >
          {this.props.description}
        </Text>
        <SecondaryButton
          id="associationAgencies-invitationError-mc-button-close"
          small={4}
          onClick={() => {
            this.props.HideModal()
          }}
        >
          Cerrar
        </SecondaryButton>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  HideModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociationInvitationErrorMC)
