import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FadeContainer } from 'rc'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import DropDown, { DropdownAction, DropdownMenu } from 'rc/DropDown'

import {
  GetNotifications,
  CheckNotification,
  SetNotificationRead,
  MarkAllAsRead
} from 'reduxActions/notifications'
import { InitializePusher, ShowModal, HideModal } from 'reduxActions/services'
import Notification from './Notification'
import { BellIcon } from 'src/components/monopolioUI'
import notificationModalResolver from './notificationModalResolver'
import { EventRx, EVENTS } from 'utils/event'

class NotificationsModule extends Component {
  componentDidMount() {
    const { success } = this.props
    if (!success) {
      this.fetchNotifications()
    }
    EventRx.subscribe(this, EVENTS.AVACLICK_ADDED_CREDITS, () => {
      this.fetchNotifications()
    })
  }

  markAllAsRead = () => {
    const { token, userId, MarkAllAsRead } = this.props
    MarkAllAsRead(token, userId)
  }

  fetchNotifications = () => {
    const { GetNotifications, token, userId, page } = this.props
    GetNotifications(token, userId, page)
  }

  handleCheckNotification = (
    type,
    read,
    notification_id,
    nav,
    index,
    expired,
    ...props
  ) => {
    const { token, userId, CheckNotification, SetNotificationRead } = this.props

    if (!read) {
      !expired && this.handleNotificationAction(type, nav, ...props)
      CheckNotification(token, userId, notification_id).then(res => {
        SetNotificationRead(index)
      })
    } else {
      !expired && this.handleNotificationAction(type, nav, ...props)
    }
  }

  moveToURL = nav => {
    const { url = '', params = {}, state } = nav
    url &&
      (state
        ? this.props.history.push({ pathname: url, state })
        : this.props.history.push(url, params))
  }

  handleNotificationAction = (type, nav, data, metadata) => {
    const { ShowModal } = this.props
    const modal = notificationModalResolver({
      type,
      data,
      ShowModal,
      fetchNotifications: this.fetchNotifications,
      metadata
    })
    if (modal) {
      return ShowModal(modal.component, modal.props)
    } else {
      this.moveToURL(nav)
    }
  }

  renderNotifications = (
    {
      type,
      notification_id,
      badge,
      created,
      expired,
      description,
      nav,
      read,
      metadata,
      ...props
    },
    index
  ) => {
    const uniqueId = Math.random().toString(36).substring(2)
    return (
      <Notification
        read={read}
        key={uniqueId + index}
        badge={badge}
        expired={expired}
        description={description}
        created={created}
        extraData={metadata}
        type={type}
        actionHandler={() =>
          this.handleCheckNotification(
            type,
            read,
            notification_id,
            nav,
            index,
            expired,
            { ...props },
            metadata
          )
        }
      />
    )
  }

  render() {
    const { pages, page, unread, notifications } = this.props
    return (
      <DropDown>
        <DropdownActionStyled id="general-notificationsModule-button-notificationBell">
          <BellContainer>
            <Bell />
            {unread && unread > 0 ? <Count>{`${unread}`}</Count> : null}
          </BellContainer>
        </DropdownActionStyled>
        <DropdownMenuStyled>
          <NotificationContainer animation="opacity" duration={100}>
            <HeaderContainer>
              <ActionLink onClick={this.markAllAsRead}>
                Marcar todas como leídas
              </ActionLink>
            </HeaderContainer>
            <ElementsContainer>
              {notifications && notifications.length > 0 ? (
                notifications.map(this.renderNotifications)
              ) : (
                <EmptyMessage>No hay notificaciones para mostrar</EmptyMessage>
              )}
              {pages > page && (
                <AddMoreStyled
                  value="persist"
                  onClick={this.fetchNotifications}
                >
                  Ver más
                </AddMoreStyled>
              )}
            </ElementsContainer>
          </NotificationContainer>
        </DropdownMenuStyled>
      </DropDown>
    )
  }
}

NotificationsModule.propTypes = {
  fullname: PropTypes.string,
  CheckNotification: PropTypes.func,
  GetNotifications: PropTypes.func,
  HideModal: PropTypes.func,
  InitializePusher: PropTypes.func,
  MarkAllAsRead: PropTypes.func,
  SetNotificationRead: PropTypes.func,
  ShowModal: PropTypes.func,
  handleNotificationClick: PropTypes.func,
  history: PropTypes.object,
  isLogged: PropTypes.bool,
  isNotificationsOpen: PropTypes.bool,
  notifications: PropTypes.array,
  page: PropTypes.number,
  pages: PropTypes.any,
  pusher: PropTypes.object,
  success: PropTypes.bool,
  theme: PropTypes.object,
  token: PropTypes.string,
  unread: PropTypes.number,
  userId: PropTypes.number
}

const DropdownMenuStyled = styled(DropdownMenu)`
  width: unset;
  right: ${({ theme }) => theme.remCalc('5')};
  margin-top: ${({ theme }) => theme.remCalc('10')};
`

const DropdownActionStyled = styled(DropdownAction)`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
`

const BellContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  &:hover {
    background-color: #e9ebf1;
  }
`

const Bell = styled(BellIcon)`
  fill: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  width: 20px;
  height: 19px;
  margin-right: 16px;
`

const Count = styled.div`
  position: absolute;
  top: 0;
  left: 13px;
  ${({ theme }) => theme.box(20, 20, true)}
  background-color: #ea5858;
  color: ${({ theme }) => theme.palette.text.white};
  font-size: ${({ theme }) => theme.remCalc('10')};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
`

const NotificationContainer = styled(FadeContainer)`
  width: ${({ theme }) => theme.remCalc('400')};
  max-height: ${({ theme }) => theme.remCalc('370')};
  background-color: white;
  ${({ theme }) => theme.radius(10)};
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.17);
  display: flex;
  flex-direction: column;
  &::-ms-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &::-o-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.media.tabletDown`
      max-width: ${({ theme }) => theme.remCalc('300')};
      max-height: ${({ theme }) => theme.remCalc('450')};
  `}
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: ${({ theme }) => theme.remCalc('0 10')};
  border-bottom-width: ${({ theme }) => theme.remCalc('1')};
  border-bottom-color: rgba(151, 164, 177, 0.52);
  border-bottom-style: solid;
`

const ElementsContainer = styled.div`
  flex: 1;
  overflow: auto;
`

const ActionLink = styled.div`
  padding: 10px 0;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  text-decoration-line: underline;
  font-weight: 500;
`

const AddMoreStyled = styled(ActionLink)`
  text-align: center;
  width: 100%;
`

const EmptyMessage = styled.p`
  text-align: center;
  margin-top: ${({ theme }) => theme.remCalc('16')};
`

const mapStateToProps = ({
  authReducer: { token },
  notificationsReducer: { notifications, pages, unread, success, page },
  profileReducer: { userId, fullname },
  servicesReducer: { pusher }
}) => ({
  isLogged: token ? true : false,
  fullname,
  token,
  unread,
  userId,
  notifications,
  success,
  pages,
  page,
  pusher
})

const mapDispatchToProps = {
  CheckNotification,
  GetNotifications,
  HideModal,
  InitializePusher,
  MarkAllAsRead,
  SetNotificationRead,
  ShowModal
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsModule)
)
