import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  ContentContainer,
  WiggotPrimeLogo
} from './StyledComponents'

import DatasheetsPrime from 'static/drawings/datasheets-prime.svg'

const Datasheets = props => {
  return (
    <Container className={props.className}>
      <WiggotPrimeLogo />
      <ContentContainer>
        <h3>Edita fichas técnicas de otros asesores</h3>
        <img
          src={DatasheetsPrime}
          alt="Edita fichas técnicas de otros asesores"
        />
      </ContentContainer>
      <p>
        Modifica el título, descripción y fotos de las propiedades antes de
        compartirle fichas a tus clientes.
      </p>
    </Container>
  )
}

Datasheets.propTypes = {
  className: PropTypes.string
}

export default Datasheets
