import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { SecondaryButton, DangerButton } from 'c3-ui/elements/Buttons'
import { Layout } from 'c3-ui/elements/Layouts'
import { Title, Text } from 'c3-ui/elements/Texts'

import { DeleteAssociationAgenciesInvitations } from 'reduxActions/association'
import { ShowFlashNotification, HideModal } from 'reduxActions/services'

class DeleteAssociationAgencyInvitationMC extends Component {
  static propTypes = {
    token: PropTypes.string,
    invitationsSelected: PropTypes.array,

    updateCallback: PropTypes.func,
    DeleteAssociationAgenciesInvitations: PropTypes.func,
    HideModal: PropTypes.func,
    ShowFlashNotification: PropTypes.func
  }

  BASE_CLASSNAME = 'delete-association-agency-invitation-mc'

  deleteAssociationAgenciesInvitations = () => {
    const {
      token,
      invitationsSelected,
      updateCallback,
      DeleteAssociationAgenciesInvitations,
      HideModal,
      ShowFlashNotification
    } = this.props

    const agenciesIds = invitationsSelected.map(({ agencyId }) => agencyId)
    DeleteAssociationAgenciesInvitations(token, agenciesIds)
      .then(() => {
        HideModal()
        updateCallback()
        ShowFlashNotification(
          'success',
          `${
            agenciesIds.length > 1
              ? 'Se han eliminado las invitaciones.'
              : 'Se ha eliminado la invitación.'
          }`
        )
      })
      .catch(
        ({
          error: {
            response: { data: { error: { code = '' } = {} } = {} } = {}
          } = {}
        }) => {
          HideModal()

          switch (code) {
            case 'NOT_FOUND':
              ShowFlashNotification(
                'danger',
                'Esta invitación ya no está disponible. Código de error: WIG-24'
              )
              break
            default:
              ShowFlashNotification(
                'danger',
                'Error desconocido, por favor intenta de nuevo más tarde.'
              )
              break
          }
        }
      )
  }

  render() {
    const { invitationsSelected, HideModal } = this.props

    return (
      <Layout className={this.BASE_CLASSNAME} vertical>
        <Title
          noResponsive
          className={`${this.BASE_CLASSNAME}__title`}
          center
          smallSize
          uppercase
        >
          ¿Deseas Eliminar{' '}
          {invitationsSelected.length > 1
            ? 'las invitaciones'
            : 'la invitación'}
          {invitationsSelected.length === 1 &&
            ` de ${invitationsSelected.map(({ name }) => name)}`}
          ?
        </Title>

        <Text gray className={`${this.BASE_CLASSNAME}__brief-description`}>
          El código de invitación se volvera inválido.
        </Text>

        {invitationsSelected.length > 1 && (
          <Layout className={`${this.BASE_CLASSNAME}__description-wrapper`}>
            <Text uppercase>Eliminar la Invitación de:</Text>
            <Text bold>
              {`${invitationsSelected
                .map(({ name }) => name)
                .join('; ')
                .split(-2)}.`}
            </Text>
          </Layout>
        )}
        <Layout center middle marginX>
          <SecondaryButton
            id="associationAgencies-deleteInvitation-mc-button-cancel"
            onClick={HideModal}
            medium={5}
          >
            CANCELAR
          </SecondaryButton>

          <DangerButton
            id="associationAgencies-deleteInvitation-mc-button-delete"
            onClick={this.deleteAssociationAgenciesInvitations}
            medium={5}
          >
            Eliminar invitación{invitationsSelected.length > 1 ? 'es' : ''}
          </DangerButton>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  ShowFlashNotification,
  DeleteAssociationAgenciesInvitations,
  HideModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAssociationAgencyInvitationMC)
