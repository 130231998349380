import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid, Typography } from 'rc'
import { Button } from 'components'
import { ConfirmationPlane } from 'components/Icons'
import { HideModal } from 'reduxActions/services'

const NotificationMC = ({
  title,
  message,
  type,
  buttonText,
  buttonType,
  HideModal,
  onClose,
  ...props
}) => {
  const ButtonProps = {
    onClick: onClose ? onClose : HideModal,
    type: buttonType
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      css={`
        width: ${({ theme }) => theme.remCalc('350')};
        max-width: 100%;
        padding: ${({ theme }) => theme.remCalc('24 18 30')};
      `}
    >
      <Grid
        container
        inline
        css={`
          ${({ theme }) => theme.box(70, 70)}
        `}
      >
        <ConfirmationPlane></ConfirmationPlane>
      </Grid>
      <Typography
        variant="h2"
        textTransform="initial"
        align="center"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('20')};
          padding: ${({ theme }) => theme.remCalc('0 25')};
        `}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('20')};
        `}
      >
        {message}
      </Typography>
      <Button
        css={`
          margin-top: ${({ theme }) => theme.remCalc('30')};
        `}
        width="large"
        {...ButtonProps}
      >
        {buttonText}
      </Button>
    </Grid>
  )
}

NotificationMC.defaultProps = {
  type: 'success',
  title: 'Title!',
  message: 'Message.',
  buttonText: 'Cerrar',
  buttonType: 'tertiary'
}

NotificationMC.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['success']),
  HideModal: PropTypes.func,
  buttonText: PropTypes.string,
  buttonType: PropTypes.string
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  HideModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationMC)
