import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Typography, Button } from 'rc'
import { UnlockModal, HideModal } from 'reduxActions/services'
import { Checkmark } from 'rc/Icons'

const EndMissingInfoForPassMC = ({
  UnlockModal,
  HideModal
}) => {
  const close = () => {
    UnlockModal()
    HideModal()
  }

  return (
    <div
      css={`
        max-width: 100%;
        width: ${({ theme }) => theme.remCalc('400')};
        padding: 20px;
      `}
    >
      <ContainerCheck>
        <p>
          <Checkmark />
        </p>
      </ContainerCheck>
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
        display="block"
        align="center"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        ¡Gracias por completar tu información!
      </Typography>
      <Typography
        textTransform="initial"
        display="block"
        align="center"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('10')};
          color: #605D75;
        `}
      >
        Los cambios se han guardado correctamente.
      </Typography>
      <Containerbutton>
        <Button
          onClick={close}
          css={`
            border-radius: ${({ theme }) => theme.remCalc('50')} !important;
            width: ${({ theme }) => theme.remCalc('200')};
          `}
        >
          Aceptar
        </Button>
      </Containerbutton>
    </div>
  )
}

const ContainerCheck = styled.div`
  display: flex;
  justify-content: center;

  p {
    height: ${({ theme }) => theme.remCalc('50')};
    border: 3px solid #00BA88;
    border-radius: 50%;
    width: ${({ theme }) => theme.remCalc('50')};
    color: #00BA88;
    padding: ${({ theme }) => theme.remCalc('10')};
  }
`
const Containerbutton = styled.div`
  display: flex;
  justify-content: center;
`

EndMissingInfoForPassMC.propTypes = {
  UnlockModal: PropTypes.func.isRequired,
  HideModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  UnlockModal,
  HideModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndMissingInfoForPassMC)
