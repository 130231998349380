import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from 'rc'
import { LinkButton, Button } from 'components'

const GenericShareMC = ({
  HideModal,
  title,
  children,
  icon,
  copy,
  okText,
  cancelText,
  onOk,
  loading
}) => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      css={`
        width: ${({ theme }) => theme.remCalc('450')};
        max-width: 100%;
        padding: ${({ theme }) => theme.remCalc('30 30 40')};
      `}
    >
      <Typography
        variant="h2"
        textTransform="initial"
        display="block"
        align="center"
        color="palette.primary.main"
        css={`
          font-size: ${({ theme }) => theme.remCalc('24')};
        `}
      >
        {title}
      </Typography>
      {children}
      {icon}
      <Typography
        variant="body2"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('20')};
          width: 300px;
          max-width: 90%;
        `}
        align="center"
      >
        {copy}
      </Typography>
      <Grid
        container
        justify="space-between"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('40')};
        `}
      >
        <Grid item flat md={11}>
          <Button
            full
            type="tertiary"
            css={`
              ${({ theme }) => theme.media.tabletDown`
              margin-bottom: ${({ theme }) => theme.remCalc('10')};
            `}
            `}
            rounded
            onClick={HideModal}
          >
            {cancelText || 'Cancelar'}
          </Button>
        </Grid>
        <Grid item flat md={11}>
          <LinkButton
            full
            onClick={onOk}
            disabled={loading}
            rounded
            css={`
              border-radius: ${({ theme }) => theme.remCalc('30')};
            `}
          >
            {okText || 'Compartir'}
          </LinkButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

GenericShareMC.propTypes = {
  HideModal: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.any,
  copy: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  loading: PropTypes.bool
}

export default GenericShareMC
