import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FreemiumWrapper } from 'modules/freemium'

function FreemiumOverlay({children, validator, ...props}) {
  return (
      <>
        {children}
        <FreemiumWrapper
          section={validator}
          custom
          withPermissionHide
          goBackOnClose
          {...props}
        >
          <OverlayBlur />
        </FreemiumWrapper>
      </>
  
  )
}

FreemiumOverlay.propTypes = {
  children: PropTypes.any,
  validator: PropTypes.object
}

const OverlayBlur = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(8px);
  left: 0;
  z-index: 1;
  margin-top: ${({ theme }) => theme.remCalc('-10')};
`

export default FreemiumOverlay