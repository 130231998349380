import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M45.2227 61.0964H48.303C48.303 54.2549 53.8211 48.6903 60.6053 48.6903V45.584C52.1203 45.603 45.2227 52.5588 45.2227 61.0964Z"
      fill="#97A4B1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.148 86.1756L82.1858 74.1126L81.0898 75.2179L78.7087 72.7976C80.863 69.4246 82.1291 65.4226 82.1291 61.1157C82.1291 51.8731 76.3654 44.0026 68.2961 40.8582V39.4098H78.1606L39.0803 0L23.6976 15.5124V6.84145H11.3953V27.8994L0 39.4098H9.86457V70.4155H41.178C44.6362 77.7334 52.0252 82.8216 60.5858 82.8216C64.8567 82.8216 68.8252 81.5448 72.1701 79.3723L74.5701 81.7925L73.474 82.8978L85.4362 94.9609C88.9701 98.3721 92.7874 96.3139 94.1291 94.9609C96.1701 92.9218 96.926 89.0914 94.148 86.1756ZM14.4756 9.94774H20.6173V18.5996L14.4756 24.7931V9.94774ZM12.9449 36.3035V67.3092H39.9874C39.4016 65.3464 39.0803 63.2692 39.0803 61.1157C39.0803 58.3715 39.5905 55.7798 40.5165 53.3595H28.3087V31.6536H49.852V42.3446C53.0079 40.4961 56.674 39.4098 60.6047 39.4098C62.1921 39.4098 63.7228 39.6004 65.2157 39.9244V36.3035H70.7339L39.0803 4.3831L7.42677 36.3035H12.9449ZM42.1417 61.0967C42.1417 50.844 50.4189 42.4971 60.5858 42.4971C70.7528 42.4971 79.0299 50.844 79.0299 61.0967C79.0299 71.3493 70.7528 79.6963 60.5858 79.6963C50.4189 79.6963 42.1417 71.3684 42.1417 61.0967ZM76.8378 75.2941C76.1575 76.0754 75.4394 76.7996 74.6646 77.4857L76.7622 79.601L78.9354 77.4094L76.8378 75.2941Z"
      fill="#97A4B1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.4246 42C50.2576 42 41.9805 50.347 41.9805 60.5996C41.9805 66.0235 44.2884 70.9055 47.9638 74.3054C44.8758 70.9805 42.9824 66.5124 42.9824 61.5996C42.9824 51.347 51.2596 43 61.4265 43C66.2225 43 70.598 44.8574 73.8825 47.8961C70.5143 44.2698 65.7264 42 60.4246 42Z"
      fill="#E0E0E0"
    />
    <path
      d="M14.4805 9.94922H20.6222V18.6011L14.4805 24.7946V9.94922Z"
      fill="#E0E0E0"
    />
    <path
      d="M12.9458 36.3032V67.3089L17.9805 67.3089V32L42.4805 7.5L39.0813 4.38281L7.42773 36.3032H12.9458Z"
      fill="#E0E0E0"
    />
    <path d="M31.3887 20.791H34.469V23.8973H31.3887V20.791Z" fill="#97A4B1" />
    <path d="M37.5312 20.791H40.6116V23.8973H37.5312V20.791Z" fill="#97A4B1" />
    <path d="M43.6914 20.791H46.7717V23.8973H43.6914V20.791Z" fill="#97A4B1" />
    <path
      d="M41.9902 50.2526H31.3887V34.7402H46.7713V44.4974C44.8627 46.1172 43.2375 48.0611 41.9902 50.2526Z"
      fill="#FFC834"
    />
    <path
      d="M91.9753 92.7507C90.7847 93.9513 88.8194 93.9513 87.6288 92.7507L77.8398 82.8792L82.1863 78.4961L91.9753 88.3676C93.1847 89.5873 93.1847 91.5501 91.9753 92.7507Z"
      fill="#0076CC"
    />
  </Fragment>,
  'properties',
  '0 0 96 97'
)
