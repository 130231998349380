import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HideModal, ShowModal, UnlockModal } from 'reduxActions/services'
import { GetRules } from 'reduxActions/freemium'
import { useHistory } from 'react-router-dom'
import { PlansMC } from 'components'

export const useFreemium = initialData => {
  const {
    section: initialSection,
    launchOnLoad,
    isLocked: isLockedInitial,
    goBackOnClose
  } = initialData || {}
  const history = useHistory()
  const dispatch = useDispatch()
  const { rules, lastGetDate, token } = useSelector(
    ({
      authReducer: { roles, token },
      freemiumReducer: { rules, lastGetDate }
    }) => ({
      lastGetDate,
      roles,
      rules,
      token
    })
  )

  const closeModal = () => {
    dispatch(UnlockModal())
    dispatch(HideModal())
    if (goBackOnClose) {
      history.goBack()
    }
  }
  const showFreemiumModal = ({ modal, isLocked, modalProps }) =>
    dispatch(ShowModal(modal, { ...modalProps }, { isLocked }))

  const validatePermission = ({ section, isLocked }) => {
    const rule = rules[section?.name]
    const modal = section?.modal || PlansMC
    return rule === undefined
      ? { value: true }
      : {
          ...rule,
          modal: modalProps =>
            showFreemiumModal({ modal, isLocked, modalProps })
        }
  }

  const validateShowAdd = sectionName => {
    // const rule = rules[sectionName]
    // return rule === undefined || !rule.rawValue ? false : true
    return false
  }

  const validateLastGetRules = lastGetDate => {
    const currentDate = new Date()
    const lastDate = new Date(lastGetDate)

    const diff = lastDate - currentDate
    const hoursDiff = Math.abs(diff) / (1000 * 60 * 60)

    if (lastGetDate && hoursDiff > 3) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (launchOnLoad) {
      const hasPermission = validatePermission({
        section: initialSection,
        isLocked: isLockedInitial
      })
      return !hasPermission?.value && hasPermission?.modal()
    }
  }, [])

  useEffect(() => {
    if (lastGetDate && validateLastGetRules(lastGetDate)) {
      dispatch(GetRules(token))
    }
  }, [])

  const ruleValidator = (action, rule) => {
    return !validatePermission({ section: rule }).value
      ? validatePermission({ section: rule }).modal
      : action
  }

  return {
    closeModal,
    validatePermission,
    showFreemiumModal,
    validateShowAdd,
    ruleValidator
  }
}
