var _jsxFileName = 'src/lib/elements/Texts/HighlightedText.js',
    _this = this;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
var HighlightedText = function HighlightedText(_ref) {
  var children = _ref.children,
      uppercase = _ref.uppercase,
      props = _objectWithoutProperties(_ref, ['children', 'uppercase']);

  var highlightedTextClasses = classNames('highlighted-text', {
    'highlighted-text--uppercase': uppercase
  });
  return React.createElement(
    'b',
    Object.assign({ className: highlightedTextClasses }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 9
      },
      __self: _this
    }),
    children
  );
};

HighlightedText.propTypes = {
  /** */
  children: PropTypes.node.isRequired,
  /** */
  uppercase: PropTypes.bool
};

export default HighlightedText;