import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <svg width="75" height="103" viewBox="0 0 75 103" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.2774 18.9511L32.6629 20.905L40.4413 29.2666C40.4413 29.2666 48.6881 32.7503 53.0457 32.5976L52.5838 33.7611C52.5838 33.7611 42.7537 34.5447 38.9437 31.7259C38.9437 31.7259 30.4264 26.0169 27.319 20.1418C24.2116 14.2666 31.2774 18.9511 31.2774 18.9511Z" fill="#FDC0C5"/>
    <path d="M21.6361 74.8371L4.60481 95.2678L4.02754 99.3689C4.02754 99.3689 6.58734 100.763 5.42949 101.085C4.27164 101.408 2.29241 100.841 2.20995 100.163C2.12748 99.4843 1.71514 93.124 2.29241 92.9612C2.86969 92.7984 4.27164 92.5474 4.27164 92.5474L14.5603 72.8086L21.6361 74.8371Z" fill="#FDC0C5"/>
    <path d="M4.52277 96.5573L4.60524 95.2683C4.60524 95.2683 3.61563 94.9834 3.86303 94.0709C4.11044 93.1584 4.68771 92.0085 3.45069 91.6523C2.21367 91.2961 0.722656 94.8104 0.722656 94.8104L0.887592 99.1286L1.71227 101.004L5.18252 102.002L6.67024 100.458L4.52277 96.5573Z" fill="#064580"/>
    <path d="M27.1218 38.7064C27.1218 38.7064 20.2275 42.6311 20.0824 49.6189C19.5744 74.015 6.38281 81.3284 6.38281 81.3284C6.38281 81.3284 10.9152 85.9553 13.4849 85.6704C13.4849 85.6704 39.3205 66.5829 32.1854 42.4276C30.5327 36.8238 27.1218 38.7064 27.1218 38.7064Z" fill="#4098FF"/>
    <path d="M52.5093 63.998L65.1202 87.8888C65.1202 87.8888 70.8963 87.0713 70.7775 87.8108C70.7775 87.8108 64.9223 91.3453 64.6386 90.8637C64.3549 90.382 63.5632 88.5095 63.5632 88.5095L46.6738 66.4607L52.5093 63.998Z" fill="#FDC0C5"/>
    <path d="M63.3054 88.9896C63.3054 88.9896 63.9651 88.2535 64.4566 88.6266C64.9482 88.9998 65.2417 87.1511 65.2417 87.1511L71.7666 86.2148L71.3675 89.7935L62.9062 92.5683L63.3054 88.9896Z" fill="#064580"/>
    <path d="M21.5532 44.8394C21.5532 44.8394 20.2733 52.9059 26.4518 55.796C48.0485 65.9011 52.5842 80.3685 52.5842 80.3685C52.5842 80.3685 58.5219 77.9092 59.2641 75.3719C59.2641 75.3719 52.3995 43.4384 28.0055 40.6535C22.3581 40.0056 21.5532 44.8394 21.5532 44.8394Z" fill="#0076FF"/>
    <path d="M30.4676 10.9487V17.5905L25.8164 15.4637L27.1096 9.08984L30.4676 10.9487Z" fill="#FDC0C5"/>
    <path d="M28.1406 10.9492C28.1406 10.9492 29.1731 14.4024 31.7692 13.8698C34.3653 13.3373 30.4827 10.9492 30.4827 10.9492H28.1406Z" fill="#F9A7A9"/>
    <path d="M30.4839 17.5233C30.4839 17.5233 32.8095 21.9331 34.3566 23.5443C35.9037 25.1556 34.8745 30.1861 33.5814 32.045C32.2883 33.9039 32.2916 41.0748 32.2916 41.0748C32.1016 41.3626 31.858 41.6088 31.5749 41.7992C31.2918 41.9896 30.9748 42.1204 30.6423 42.1841C30.4056 42.2317 30.1768 42.3139 29.9628 42.4283C29.303 42.7675 28.5443 42.2756 27.7955 41.6684C26.8661 40.9139 25.7103 40.5157 24.5265 40.5423C24.5265 40.5423 25.0444 32.062 23.494 28.3306C21.9436 24.5993 22.7419 16.4582 25.8394 14.8809L30.4839 17.5233Z" fill="#F8D53D"/>
    <path d="M30.2091 1.65409C30.2091 1.65409 35.3749 8.57402 32.792 11.7457C30.2091 14.9173 24.5254 8.29247 24.5254 8.29247C24.5254 8.29247 25.5579 1.38611 30.2091 1.65409Z" fill="#FDC0C5"/>
    <path d="M25.0596 24.355L22.9649 25.4507L13.7054 32.0246C13.7054 32.0246 9.04102 39.8435 8.51323 44.2939L7.46094 43.6528C7.46094 43.6528 8.26582 33.5511 11.5711 30.1284C11.5711 30.1284 18.3995 22.3808 24.5351 20.159C30.6707 17.9371 25.0596 24.355 25.0596 24.355Z" fill="#FDC0C5"/>
    <path d="M31.4131 0.864262C30.2981 0.440247 29.1271 1.60714 29.1271 1.60714C24.941 -0.858936 22.0348 3.68651 22.6946 6.54607C23.3543 9.40563 23.3114 10.6946 19.0429 10.8066C14.7743 10.9185 15.3384 12.8724 15.8596 16.1118C16.3808 19.3513 11.6373 17.9368 11.0006 22.0447C10.364 26.1525 18.8417 35.162 25.7525 27.5874C28.685 24.3683 29.579 15.6471 27.9494 10.5827C27.7911 10.0908 27.0456 8.41852 27.3655 8.07252C28.9192 6.40699 27.7944 4.12069 27.9263 3.75773C28.3387 2.61119 29.4536 3.0386 29.4536 3.0386C30.6115 2.17361 31.9013 4.39545 31.9013 4.39545C31.9013 4.39545 32.528 1.29167 31.4131 0.864262Z" fill="#064580"/>
    <path d="M28.0521 7.52013C28.3523 8.10358 28.2896 8.74468 27.9135 8.9516C27.5375 9.15852 26.9833 8.85323 26.6831 8.27318C26.3829 7.69312 26.4423 7.04863 26.8217 6.8451C27.201 6.64158 27.7486 6.94008 28.0521 7.52013Z" fill="#FDC0C5"/>
    <path d="M54.7217 39.218C54.7137 39.2268 54.704 39.2339 54.6932 39.2387C54.6824 39.2435 54.6708 39.246 54.659 39.246C54.6473 39.246 54.6356 39.2435 54.6249 39.2387C54.6141 39.2339 54.6044 39.2268 54.5963 39.218C54.0853 38.6593 53.6051 38.0715 53.1581 37.4575C52.1388 36.0463 51.5879 34.8591 51.5087 33.9263C51.4756 33.6508 51.5078 33.3711 51.6026 33.1112C51.6974 32.8513 51.8519 32.6189 52.053 32.4337C52.3188 32.1804 52.6389 31.995 52.9873 31.8924C53.3358 31.7899 53.7028 31.7731 54.0587 31.8435C55.6354 32.1454 57.2914 33.9466 58.7098 35.6902C58.7266 35.7079 58.7359 35.7316 58.7359 35.7563C58.7359 35.781 58.7266 35.8047 58.7098 35.8225C58.6999 35.8296 58.6886 35.8346 58.6768 35.8373C58.6649 35.84 58.6527 35.8402 58.6407 35.838C58.6288 35.8358 58.6174 35.8312 58.6072 35.8245C58.5969 35.8177 58.5881 35.809 58.5812 35.7987C57.1891 34.0857 55.5728 32.3218 54.0587 32.0334C53.7253 31.9644 53.3808 31.979 53.0541 32.0758C52.7274 32.1726 52.4281 32.3489 52.1817 32.5898C51.9989 32.7581 51.8584 32.9694 51.7722 33.2057C51.6861 33.442 51.6568 33.6962 51.6869 33.9466C51.8188 35.8903 54.25 38.6142 54.692 39.0925C54.7087 39.1102 54.7181 39.1339 54.7181 39.1586C54.7181 39.1833 54.7087 39.207 54.692 39.2248L54.7217 39.218Z" fill="#2B2B2B"/>
    <path d="M70.7676 45.2905C70.9655 45.2905 72.4994 44.3203 72.4994 44.3203L73.8189 44.8732L72.572 46.6202L70.7676 45.2905Z" fill="#064580"/>
    <path d="M60.9073 31.7946L60.7324 30.7227L73.8052 44.8747L72.5088 45.1324L72.555 46.6216L60.9073 31.7946Z" fill="#0075D1"/>
    <path d="M50.2656 39.3794L60.7324 30.7227L72.555 46.6216L62.5203 55.0375L50.2656 39.3794Z" fill="#008DF2"/>
    <path d="M54.8939 39.3338C54.8312 39.422 54.6762 39.405 54.564 39.2965C54.4519 39.1879 54.3925 39.0217 54.4551 38.9301C54.5178 38.8385 54.6729 38.8555 54.785 38.9674C54.8972 39.0794 54.9599 39.2422 54.8939 39.3338Z" fill="#F0F0F0"/>
    <path d="M58.9317 36.0509C58.8559 36.129 58.7008 36.0883 58.6018 35.9627C58.5029 35.8372 58.46 35.6744 58.5392 35.5964C58.6183 35.5184 58.7734 35.5591 58.8855 35.6846C58.9977 35.8101 59.0109 35.9627 58.9317 36.0509Z" fill="#F0F0F0"/>
    <path d="M54.7217 39.218C54.7137 39.2268 54.704 39.2339 54.6932 39.2387C54.6824 39.2435 54.6708 39.246 54.659 39.246C54.6473 39.246 54.6356 39.2435 54.6249 39.2387C54.6141 39.2339 54.6044 39.2268 54.5963 39.218C54.0853 38.6593 53.6051 38.0715 53.1581 37.4575C52.1388 36.0463 51.5879 34.8591 51.5087 33.9263C51.4756 33.6508 51.5078 33.3711 51.6026 33.1112C51.6974 32.8513 51.8519 32.6189 52.053 32.4337C52.3188 32.1804 52.6389 31.995 52.9873 31.8924C53.3358 31.7899 53.7028 31.7731 54.0587 31.8435C55.6354 32.1454 57.2914 33.9466 58.7098 35.6902C58.7266 35.7079 58.7359 35.7316 58.7359 35.7563C58.7359 35.781 58.7266 35.8047 58.7098 35.8225C58.6999 35.8296 58.6886 35.8346 58.6768 35.8373C58.6649 35.84 58.6527 35.8402 58.6407 35.838C58.6288 35.8358 58.6174 35.8312 58.6072 35.8245C58.5969 35.8177 58.5881 35.809 58.5812 35.7987C57.1891 34.0857 55.5728 32.3218 54.0587 32.0334C53.7253 31.9644 53.3808 31.979 53.0541 32.0758C52.7274 32.1726 52.4281 32.3489 52.1817 32.5898C51.9989 32.7581 51.8584 32.9694 51.7722 33.2057C51.6861 33.442 51.6568 33.6962 51.6869 33.9466C51.8188 35.8903 54.25 38.6142 54.692 39.0925C54.7087 39.1102 54.7181 39.1339 54.7181 39.1586C54.7181 39.1833 54.7087 39.207 54.692 39.2248L54.7217 39.218Z" fill="#064580"/>
    <path d="M55.3258 38.4764C55.3197 38.4867 55.3117 38.4957 55.3022 38.5027C55.2927 38.5097 55.2819 38.5146 55.2704 38.5171C55.259 38.5197 55.2472 38.5198 55.2357 38.5174C55.2243 38.5151 55.2134 38.5104 55.2037 38.5036C54.6704 38.1167 54.1635 37.6928 53.6863 37.2349C52.5977 36.1834 51.9776 35.1996 51.8423 34.3313C51.7939 34.0527 51.8045 33.7667 51.8734 33.4927C51.9423 33.2187 52.0678 32.9633 52.2415 32.7438C52.459 32.43 52.737 32.1656 53.0582 31.9672C53.3795 31.7687 53.737 31.6405 54.1085 31.5904C55.6424 31.4174 57.371 32.659 58.8686 33.914C58.8851 33.9312 58.8944 33.9544 58.8944 33.9785C58.8944 34.0026 58.8851 34.0258 58.8686 34.0429C58.8606 34.0512 58.8511 34.0578 58.8406 34.0623C58.8302 34.0668 58.8189 34.0692 58.8076 34.0692C58.7962 34.0692 58.785 34.0668 58.7745 34.0623C58.764 34.0578 58.7545 34.0512 58.7465 34.0429C57.2786 32.8116 55.5864 31.5938 54.1283 31.76C53.7798 31.8055 53.4443 31.9252 53.1432 32.1115C52.8422 32.2978 52.5823 32.5467 52.38 32.8421C52.2213 33.043 52.1065 33.2766 52.0433 33.5271C51.9801 33.7776 51.9701 34.0391 52.0139 34.294C52.2976 36.1155 54.8442 38.0253 55.3126 38.3442C55.3313 38.3572 55.3443 38.3774 55.3486 38.4003C55.353 38.4232 55.3483 38.4469 55.3357 38.4663L55.3258 38.4764Z" fill="#F7B978"/>
    <path d="M71.1562 39.6028C71.3443 39.5486 72.7297 38.168 72.7297 38.168L74.0261 38.3138L72.9738 40.3491L71.1562 39.6028Z" fill="#E79E00"/>
    <path d="M60.6368 29.5556L60.3828 28.582L74.0263 38.314L72.809 38.9383L72.974 40.3561L60.6368 29.5556Z" fill="#FFC500"/>
    <path d="M51.0781 39.9355L60.3838 28.582L72.9749 40.3561L64.0618 51.3535L51.0781 39.9355Z" fill="#F8D53D"/>
    <path d="M55.5018 38.5376C55.4653 38.5897 55.4107 38.6254 55.3493 38.6374C55.288 38.6494 55.2245 38.6367 55.1719 38.602C55.1137 38.5772 55.0673 38.5299 55.0427 38.4703C55.018 38.4107 55.017 38.3435 55.04 38.2832C55.076 38.2304 55.1306 38.1941 55.1922 38.1821C55.2538 38.1701 55.3175 38.1832 55.3698 38.2187C55.4275 38.2443 55.4733 38.2916 55.4979 38.351C55.5225 38.4104 55.5239 38.4772 55.5018 38.5376Z" fill="#FFC500"/>
    <path d="M59.0932 34.1991C59.0507 34.2447 58.9929 34.2718 58.9316 34.2749C58.8703 34.2781 58.8101 34.2571 58.7634 34.2161C58.7355 34.2003 58.7109 34.1788 58.6912 34.153C58.6716 34.1272 58.6571 34.0976 58.6488 34.0659C58.6405 34.0343 58.6385 34.0012 58.6429 33.9688C58.6472 33.9363 58.6579 33.905 58.6743 33.8769C58.7168 33.8314 58.7747 33.8042 58.836 33.8011C58.8973 33.7979 58.9575 33.819 59.0042 33.8599C59.0321 33.8758 59.0566 33.8972 59.0763 33.923C59.096 33.9488 59.1104 33.9785 59.1187 34.0101C59.127 34.0418 59.1291 34.0748 59.1247 34.1073C59.1203 34.1398 59.1096 34.171 59.0932 34.1991Z" fill="#FFC500"/>
    <path d="M55.3258 38.4764C55.3197 38.4867 55.3117 38.4957 55.3022 38.5027C55.2927 38.5097 55.2819 38.5146 55.2704 38.5171C55.259 38.5197 55.2472 38.5198 55.2357 38.5174C55.2243 38.5151 55.2134 38.5104 55.2037 38.5036C54.6704 38.1167 54.1635 37.6928 53.6863 37.2349C52.5977 36.1834 51.9776 35.1996 51.8423 34.3313C51.7939 34.0527 51.8045 33.7667 51.8734 33.4927C51.9423 33.2187 52.0678 32.9633 52.2415 32.7438C52.459 32.43 52.737 32.1656 53.0582 31.9672C53.3795 31.7687 53.737 31.6405 54.1085 31.5904C55.6424 31.4174 57.371 32.659 58.8686 33.914C58.8851 33.9312 58.8944 33.9544 58.8944 33.9785C58.8944 34.0026 58.8851 34.0258 58.8686 34.0429C58.8606 34.0512 58.8511 34.0578 58.8406 34.0623C58.8302 34.0668 58.8189 34.0692 58.8076 34.0692C58.7962 34.0692 58.785 34.0668 58.7745 34.0623C58.764 34.0578 58.7545 34.0512 58.7465 34.0429C57.2786 32.8116 55.5864 31.5938 54.1283 31.76C53.7798 31.8055 53.4443 31.9252 53.1432 32.1115C52.8422 32.2978 52.5823 32.5467 52.38 32.8421C52.2213 33.043 52.1065 33.2766 52.0433 33.5271C51.9801 33.7776 51.9701 34.0391 52.0139 34.294C52.2976 36.1155 54.8442 38.0253 55.3126 38.3442C55.3313 38.3572 55.3443 38.3774 55.3486 38.4003C55.353 38.4232 55.3483 38.4469 55.3357 38.4663L55.3258 38.4764Z" fill="#E79E00"/>
    <path d="M4.93563 51.3148C4.92349 51.314 4.91163 51.3107 4.90074 51.3051C4.88985 51.2995 4.88014 51.2918 4.87217 51.2823C4.8642 51.2728 4.85813 51.2619 4.8543 51.25C4.85047 51.2381 4.84896 51.2256 4.84986 51.2131C4.85363 50.4466 4.89989 49.681 4.98841 48.92C5.19293 47.1731 5.58877 45.9248 6.16605 45.1887C6.32507 44.9645 6.53514 44.7839 6.77784 44.6627C7.02054 44.5414 7.28846 44.4833 7.55811 44.4933C7.92087 44.4956 8.27789 44.5866 8.59968 44.7588C8.92147 44.931 9.19878 45.1795 9.40868 45.4838C10.3521 46.8203 10.3455 49.2965 10.2103 51.5659C10.2103 51.6235 10.1674 51.6676 10.1212 51.6642C10.0972 51.6601 10.0758 51.6463 10.0616 51.626C10.0473 51.6057 10.0415 51.5804 10.0453 51.5557C10.1806 49.3305 10.1872 46.9051 9.28333 45.6229C9.08778 45.3367 8.82789 45.1034 8.52579 44.9428C8.22368 44.7821 7.88831 44.6989 7.54821 44.7002C7.30246 44.6928 7.05861 44.7466 6.83746 44.857C6.61632 44.9675 6.42444 45.1313 6.2782 45.3345C5.07417 46.8372 5.0148 50.5381 5.0148 51.1961C5.01606 51.2205 5.00876 51.2445 4.99423 51.2638C4.97969 51.2831 4.9589 51.2964 4.93563 51.3013V51.3148Z" fill="#2B2B2B"/>
    <path d="M12.5078 67.0684C12.6464 67.2108 14.4079 67.5907 14.4079 67.5907L14.9785 68.9171L12.9136 69.2936L12.5078 67.0684Z" fill="#064580"/>
    <path d="M14.3899 50.2973L14.9804 49.3984L14.9903 68.9167L13.8786 68.1875L12.9121 69.3L14.3899 50.2973Z" fill="#0075D1"/>
    <path d="M1.5933 48.2695L14.9795 49.3991L12.9112 69.3007L0 68.3034L1.5933 48.2695Z" fill="#008DF2"/>
    <path d="M4.98047 51.5113C4.87162 51.5316 4.77266 51.4095 4.75616 51.2399C4.73967 51.0703 4.81554 50.9211 4.9211 50.9007C5.02666 50.8804 5.12892 50.9991 5.14541 51.1687C5.1619 51.3383 5.08273 51.4909 4.98047 51.5113Z" fill="#F0F0F0"/>
    <path d="M10.1139 51.9943C10.0083 51.9943 9.92257 51.8553 9.92587 51.6856C9.92917 51.516 10.0182 51.377 10.1271 51.377C10.236 51.377 10.3184 51.5126 10.3151 51.6823C10.3118 51.8519 10.2228 51.9943 10.1139 51.9943Z" fill="#F0F0F0"/>
    <path d="M4.93563 51.3148C4.92349 51.314 4.91163 51.3107 4.90074 51.3051C4.88985 51.2995 4.88014 51.2918 4.87217 51.2823C4.8642 51.2728 4.85813 51.2619 4.8543 51.25C4.85047 51.2381 4.84896 51.2256 4.84986 51.2131C4.85363 50.4466 4.89989 49.681 4.98841 48.92C5.19293 47.1731 5.58877 45.9248 6.16605 45.1887C6.32507 44.9645 6.53514 44.7839 6.77784 44.6627C7.02054 44.5414 7.28846 44.4833 7.55811 44.4933C7.92087 44.4956 8.27789 44.5866 8.59968 44.7588C8.92147 44.931 9.19878 45.1795 9.40868 45.4838C10.3521 46.8203 10.3455 49.2965 10.2103 51.5659C10.2103 51.6235 10.1674 51.6676 10.1212 51.6642C10.0972 51.6601 10.0758 51.6463 10.0616 51.626C10.0473 51.6057 10.0415 51.5804 10.0453 51.5557C10.1806 49.3305 10.1872 46.9051 9.28333 45.6229C9.08778 45.3367 8.82789 45.1034 8.52579 44.9428C8.22368 44.7821 7.88831 44.6989 7.54821 44.7002C7.30246 44.6928 7.05861 44.7466 6.83746 44.857C6.61632 44.9675 6.42444 45.1313 6.2782 45.3345C5.07417 46.8372 5.0148 50.5381 5.0148 51.1961C5.01606 51.2205 5.00876 51.2445 4.99423 51.2638C4.97969 51.2831 4.9589 51.2964 4.93563 51.3013V51.3148Z" fill="#064580"/>
    <path d="M5.86552 51.1972C5.85447 51.1997 5.84305 51.1999 5.83192 51.1978C5.82079 51.1958 5.81017 51.1915 5.80066 51.1852C5.79116 51.1788 5.78296 51.1707 5.77654 51.1611C5.77012 51.1515 5.76561 51.1407 5.76326 51.1294C5.63389 50.4713 5.54902 49.8049 5.50926 49.1348C5.42349 47.6015 5.62801 46.4516 6.11622 45.7223C6.26751 45.4859 6.46669 45.2859 6.70012 45.136C6.93356 44.9862 7.19573 44.8899 7.4687 44.8539C7.83622 44.7808 8.21444 44.7861 8.57989 44.8695C8.94533 44.953 9.2902 45.1128 9.59307 45.339C10.8235 46.3024 11.2424 48.4224 11.4898 50.3933C11.4917 50.4051 11.4912 50.4172 11.4883 50.4289C11.4855 50.4405 11.4803 50.4514 11.4732 50.461C11.4661 50.4705 11.4572 50.4784 11.447 50.4843C11.4368 50.4901 11.4256 50.4938 11.414 50.495C11.4027 50.4964 11.3912 50.4955 11.3803 50.4924C11.3694 50.4892 11.3592 50.4838 11.3503 50.4766C11.3414 50.4693 11.334 50.4603 11.3285 50.4501C11.323 50.4399 11.3195 50.4286 11.3183 50.417C11.0775 48.4835 10.6586 46.4041 9.48751 45.4849C9.20584 45.273 8.88464 45.1233 8.54406 45.045C8.20347 44.9668 7.85084 44.9618 7.50828 45.0303C7.25887 45.0639 7.01938 45.1522 6.80598 45.2891C6.59258 45.4261 6.41024 45.6085 6.27126 45.8241C5.25856 47.3505 5.83253 50.529 5.94139 51.092C5.94378 51.1036 5.94387 51.1156 5.94165 51.1272C5.93943 51.1389 5.93496 51.1499 5.92849 51.1597C5.92202 51.1696 5.9137 51.178 5.90401 51.1844C5.89433 51.1908 5.88348 51.1952 5.87212 51.1972H5.86552Z" fill="#F7B978"/>
    <path d="M16.5957 63.2148C16.7672 63.3132 18.7003 63.2929 18.7003 63.2929L19.5415 64.3105L17.4105 65.0466L16.5957 63.2148Z" fill="#E79E00"/>
    <path d="M15.6914 48.483L16.1598 47.5977L19.541 64.3141L18.238 63.907L17.41 65.0502L15.6914 48.483Z" fill="#FFC500"/>
    <path d="M1.80859 49.2462L16.1613 47.5977L17.4115 65.0502L3.58665 66.7191L1.80859 49.2462Z" fill="#F8D53D"/>
    <path d="M5.95162 51.3624C5.89041 51.3755 5.82664 51.3632 5.77415 51.3283C5.72166 51.2934 5.68468 51.2386 5.67123 51.1758C5.65626 51.1459 5.64738 51.1132 5.64511 51.0796C5.64284 51.0461 5.64725 51.0124 5.65805 50.9807C5.66885 50.9489 5.68583 50.9198 5.70795 50.895C5.73006 50.8702 5.75685 50.8504 5.78668 50.8366C5.84781 50.8244 5.91116 50.837 5.96344 50.8718C6.01572 50.9065 6.05286 50.9608 6.06707 51.0231C6.08204 51.053 6.09092 51.0858 6.09319 51.1193C6.09546 51.1529 6.09105 51.1865 6.08025 51.2183C6.06945 51.25 6.05247 51.2792 6.03036 51.3039C6.00824 51.3287 5.98145 51.3486 5.95162 51.3624Z" fill="#FFC500"/>
    <path d="M11.4627 50.7583C11.4315 50.7606 11.4002 50.7566 11.3705 50.7464C11.3408 50.7362 11.3134 50.7201 11.2898 50.699C11.2662 50.6779 11.2469 50.6522 11.233 50.6233C11.2191 50.5945 11.2108 50.5632 11.2087 50.5311C11.1912 50.4694 11.1981 50.4032 11.2277 50.3467C11.2573 50.2902 11.3074 50.248 11.3671 50.2292C11.4296 50.2245 11.4913 50.2454 11.5389 50.2873C11.5865 50.3293 11.616 50.3888 11.6211 50.4531C11.6387 50.5151 11.6319 50.5818 11.6023 50.6389C11.5728 50.6959 11.5227 50.7388 11.4627 50.7583Z" fill="#FFC500"/>
    <path d="M5.86552 51.1972C5.85447 51.1997 5.84305 51.1999 5.83192 51.1978C5.82079 51.1958 5.81017 51.1915 5.80066 51.1852C5.79116 51.1788 5.78296 51.1707 5.77654 51.1611C5.77012 51.1515 5.76561 51.1407 5.76326 51.1294C5.63389 50.4713 5.54902 49.8049 5.50926 49.1348C5.42349 47.6015 5.62801 46.4516 6.11622 45.7223C6.26751 45.4859 6.46669 45.2859 6.70012 45.136C6.93356 44.9862 7.19573 44.8899 7.4687 44.8539C7.83622 44.7808 8.21444 44.7861 8.57989 44.8695C8.94533 44.953 9.2902 45.1128 9.59307 45.339C10.8235 46.3024 11.2424 48.4224 11.4898 50.3933C11.4917 50.4051 11.4912 50.4172 11.4883 50.4289C11.4855 50.4405 11.4803 50.4514 11.4732 50.461C11.4661 50.4705 11.4572 50.4784 11.447 50.4843C11.4368 50.4901 11.4256 50.4938 11.414 50.495C11.4027 50.4964 11.3912 50.4955 11.3803 50.4924C11.3694 50.4892 11.3592 50.4838 11.3503 50.4766C11.3414 50.4693 11.334 50.4603 11.3285 50.4501C11.323 50.4399 11.3195 50.4286 11.3183 50.417C11.0775 48.4835 10.6586 46.4041 9.48751 45.4849C9.20584 45.273 8.88464 45.1233 8.54406 45.045C8.20347 44.9668 7.85084 44.9618 7.50828 45.0303C7.25887 45.0639 7.01938 45.1522 6.80598 45.2891C6.59258 45.4261 6.41024 45.6085 6.27126 45.8241C5.25856 47.3505 5.83253 50.529 5.94139 51.092C5.94378 51.1036 5.94387 51.1156 5.94165 51.1272C5.93943 51.1389 5.93496 51.1499 5.92849 51.1597C5.92202 51.1696 5.9137 51.178 5.90401 51.1844C5.89433 51.1908 5.88348 51.1952 5.87212 51.1972H5.86552Z" fill="#E79E00"/>
    <path d="M7.47084 43.7068C7.47084 43.7068 6.04909 44.8397 6.88366 45.3452C7.71824 45.8506 8.94206 45.3452 8.94206 45.3452L8.52972 44.1545C8.52972 44.1545 7.72154 43.4557 7.47084 43.7068Z" fill="#FDC0C5"/>
    <path d="M52.2878 34.0146C52.4099 34.0146 52.2878 34.3945 52.776 33.638C53.0596 33.2315 53.2296 32.7529 53.2675 32.254C53.2675 32.254 52.2779 31.2364 51.7963 31.8741C51.3147 32.5119 51.0937 32.9393 51.5687 33.2886C51.8455 33.4884 52.0885 33.7336 52.2878 34.0146Z" fill="#FDC0C5"/>
  </svg>,
  'WiggotStoreLogo',
  '0 0 75 103'
)
