import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Typography } from 'rc'
import { Button } from 'components'

const RemoveSlotMC = ({
  HideModal, 
  brokerName,
  RemoveSlotFunction,
  brokerId,
  ShowFlashNotification,
  getAgency,
  token 
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleRemoveCbSlot = () => {
    setIsLoading(true)
    RemoveSlotFunction(token, brokerId)
      .then(res =>{
        getAgency()
        ShowFlashNotification('success', '¡Se eliminó Prime exitosamente!')
        HideModal()
      })
      .catch(err =>{        
        ShowFlashNotification('danger', 'Ocurrió un error')
        HideModal()
      })
  }
  return (
    <ModalContainerStyled>
        <Typography variant="h2" align="center">
            {`Estás a punto de quitar PRIME a tu asesor ${brokerName}`}
        </Typography>
        <Typography variant="subtitle1" align="center">
            Al continuar con esta acción tu asesor dejará de disfrutar de los beneficios de PRIME
            y se liberará un espacio disponible para asignar a alguien más.
        </Typography>
        <ButtonsContainer>                   
            <Button
                type="tertiary"
                onClick={HideModal}
                disabled={isLoading}
                css={`
                    width: ${({ theme }) => theme.remCalc('200')};
                    ${({ theme }) => theme.media.tabletDown`
                        width: ${({ theme }) => theme.remCalc('100')};
                    `}
                `}
            >
                Cancelar
            </Button>                         
            <Button
                type="alert"
                onClick={ () => handleRemoveCbSlot()}
                disabled={isLoading}
                css={`
                    width: ${({ theme }) => theme.remCalc('200')};
                    ${({ theme }) => theme.media.tabletDown`
                    width: ${({ theme }) => theme.remCalc('100')};
                    `}
                `}
            >
                Eliminar
            </Button>                    
        </ButtonsContainer>
    </ModalContainerStyled>
  )
}

RemoveSlotMC.propTypes = {
  HideModal: PropTypes.func,
  brokerName: PropTypes.string,
  brokerId: PropTypes.number,
  RemoveSlotFunction: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  getAgency: PropTypes.func,
  token: PropTypes.string
}

const ModalContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: ${({ theme }) => theme.remCalc('650')};
  max-width: 100%;
  height: ${({ theme }) => theme.remCalc('400')};
  padding: ${({ theme }) => theme.remCalc('20 50')};
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.remCalc('0 35')};
  width: 100%;
  ${({ theme }) => theme.media.tabletDown`
      padding: ${({ theme }) => theme.remCalc('0')};
  `}
`

export default RemoveSlotMC
