import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'

export default createSvgIcon(
  <Fragment>
    <path
      d="M22.2991 29.3337H19.7986C19.4542 29.3337 19.1728 29.0171 19.1728 28.63C19.1728 28.2428 19.4542 27.9262 19.7986 27.9262H22.3004C22.6448 27.9262 22.9262 28.2428 22.9262 28.63C22.9262 29.0171 22.6435 29.3337 22.2991 29.3337V29.3337Z"
      fill="white"
    />
    <path
      d="M22.101 31.6149H18.8253C18.7208 31.6244 18.6153 31.6133 18.5157 31.5824C18.4161 31.5515 18.3245 31.5015 18.2468 31.4355C18.169 31.3695 18.1069 31.2891 18.0644 31.1994C18.022 31.1096 18 31.0126 18 30.9145C18 30.8163 18.022 30.7193 18.0644 30.6295C18.1069 30.5398 18.169 30.4594 18.2468 30.3934C18.3245 30.3274 18.4161 30.2774 18.5157 30.2465C18.6153 30.2156 18.7208 30.2045 18.8253 30.214H22.101C22.2055 30.2045 22.311 30.2156 22.4106 30.2465C22.5102 30.2774 22.6018 30.3274 22.6795 30.3934C22.7573 30.4594 22.8194 30.5398 22.8619 30.6295C22.9044 30.7193 22.9263 30.8163 22.9263 30.9145C22.9263 31.0126 22.9044 31.1096 22.8619 31.1994C22.8194 31.2891 22.7573 31.3695 22.6795 31.4355C22.6018 31.5015 22.5102 31.5515 22.4106 31.5824C22.311 31.6133 22.2055 31.6244 22.101 31.6149V31.6149Z"
      fill="white"
    />
    <path
      d="M25.3683 26.0824L26.5516 29.2923C26.5828 29.3661 26.599 29.4453 26.599 29.5254C26.599 29.6055 26.5828 29.6847 26.5516 29.7585L25.3683 32.9684L34.3024 29.5212L25.3683 26.0824ZM23.9942 35.0255C23.7883 35.0265 23.589 34.9537 23.4327 34.8204C23.3151 34.7316 23.2293 34.6075 23.1881 34.4665C23.1469 34.3255 23.1525 34.1751 23.2039 34.0374L24.8883 29.5101L23.2039 24.9827C23.153 24.8452 23.1478 24.6951 23.189 24.5544C23.2301 24.4138 23.3156 24.29 23.4327 24.2011C23.5548 24.0988 23.7031 24.0323 23.861 24.0092C24.019 23.986 24.1803 24.007 24.3268 24.0699L36.7377 28.8402C36.8788 28.8891 37.0019 28.9792 37.0907 29.0987C37.1796 29.2182 37.2302 29.3615 37.236 29.5101C37.2282 29.6605 37.1771 29.8056 37.0887 29.9281C37.0004 30.0506 36.8786 30.1452 36.7377 30.2009L24.3268 34.9697C24.2198 35.0065 24.1074 35.0254 23.9942 35.0255V35.0255Z"
      fill="white"
    />
    <path
      d="M25.6378 11.9962C26.1207 12.4739 26.1207 13.2171 25.6378 13.6417C25.4231 13.854 25.1548 13.9602 24.8328 13.9602C24.5108 13.9602 24.2425 13.854 24.0279 13.6417L13.0268 2.76019L2.0258 13.6417C1.59649 14.1194 0.845201 14.1194 0.362229 13.6417C-0.120743 13.2171 -0.120743 12.4739 0.362229 11.9962L12.2219 0.318483C12.4365 0.106161 12.7049 0 13.0268 0C13.3488 0 13.6171 0.106161 13.8318 0.318483L25.6378 11.9962Z"
      fill="white"
    />
    <path
      d="M20.8501 12C21.5072 12 22 12.5534 22 13.2174V24.7826C22 25.4466 21.5072 26 20.8501 26H15.3746C14.7176 26 14.17 25.4466 14.17 24.7826V17.4783H10.83V24.7826C10.83 25.4466 10.3372 26 9.68012 26H4.20461C3.54755 26 3 25.4466 3 24.7826V13.2174C3 12.5534 3.54755 12 4.20461 12C4.86167 12 5.40922 12.5534 5.40922 13.2174V23.6206H8.4755V16.2609C8.4755 15.5968 9.02305 15.0435 9.68012 15.0435H15.3746C16.0317 15.0435 16.5793 15.5968 16.5793 16.2609V23.6206H19.6455V13.2174C19.6455 12.5534 20.1931 12 20.8501 12Z"
      fill="white"
    />
  </Fragment>,
  'PropertySent',
  '0 0 38 36'
)
