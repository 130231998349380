import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g>
      <path
        d="M58.8,49.2l-14.4-2c-6.2-0.9-11.1-5.8-12-12l-2-14.4c-0.1-0.8-1.3-0.8-1.4,0l-2,14.4c-0.9,6.2-5.8,11.1-12,12l-14.4,2
		c-0.8,0.1-0.8,1.3,0,1.4l14.4,2c6.2,0.9,11.1,5.8,12,12l2,14.4c0.1,0.8,1.3,0.8,1.4,0l2-14.4c0.9-6.2,5.8-11.1,12-12l14.4-2
		C59.6,50.5,59.6,49.3,58.8,49.2L58.8,49.2z"
      />
      <path
        d="M42.2,20l9.4,1.3c4.1,0.6,7.3,3.8,7.9,7.9l1.3,9.4c0.1,0.6,0.9,0.6,1,0l1.3-9.4c0.6-4.1,3.8-7.3,7.9-7.9l9.4-1.3
		c0.6-0.1,0.6-0.9,0-1L71,17.7c-4.1-0.6-7.3-3.8-7.9-7.9l-1.3-9.4c-0.1-0.6-0.9-0.6-1,0L59.5,10c-0.6,4.1-3.8,7.3-7.9,7.9l-9.4,1.3
		C41.6,19.2,41.6,20,42.2,20L42.2,20z"
      />
      <path
        d="M94.5,64.5l-11.2-1.6c-4.9-0.7-8.7-4.5-9.4-9.4l-1.6-11.2c-0.1-0.7-1-0.7-1.1,0l-1.6,11.2c-0.7,4.9-4.5,8.7-9.4,9.4
		L49,64.5c-0.7,0.1-0.7,1,0,1.1l11.2,1.6c4.9,0.7,8.7,4.5,9.4,9.4l1.6,11.2c0.1,0.7,1,0.7,1.1,0l1.6-11.2c0.7-4.9,4.5-8.7,9.4-9.4
		l11.2-1.6C95.2,65.5,95.2,64.5,94.5,64.5z"
      />
    </g>
  </Fragment>,
  'Sparkles',
  '0 0 95 88.3'
)
