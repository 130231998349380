var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/FormInputs/ExtendedWysiwyg.js';

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { default as InputLabelContainer } from './InputLabelContainer';
import { ExtendedWisywig as FeaturedExtendedWysiWyg } from '../Wysiwyg';

var ExtendedWisywig = (_temp2 = _class = function (_PureComponent) {
  _inherits(ExtendedWisywig, _PureComponent);

  function ExtendedWisywig() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ExtendedWisywig);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ExtendedWisywig.__proto__ || Object.getPrototypeOf(ExtendedWisywig)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      value: _this.props.componentType === 'CT' ? _this.props.value : undefined
    }, _this.onFocus = function (event) {
      var onFocus = _this.props.onFocus;

      if (onFocus) {
        onFocus(event);
      }
    }, _this.onBlur = function (event) {
      var onBlur = _this.props.onBlur;

      if (onBlur) {
        onBlur(event);
      }
    }, _this.onChangeCT = function (value) {
      if (value) {
        _this.setState({ value: value }, function () {
          _this.props.onChange(value);
        });
      }
    }, _this.onChangeRF = function (value) {
      if (value) {
        _this.props.input.onChange(value);
        _this.props.onChange(value);
      }
    }, _this.inputProp = function () {
      var forceTouched = _this.props.touched;
      var _this$props = _this.props,
          input = _this$props.input,
          componentType = _this$props.componentType,
          _this$props$meta = _this$props.meta;
      _this$props$meta = _this$props$meta === undefined ? {} : _this$props$meta;

      var touched = _this$props$meta.touched,
          error = _this$props$meta.error,
          warning = _this$props$meta.warning,
          validations = _this$props.validations,
          className = _this$props.className,
          forcedErrorMessage = _this$props.forcedErrorMessage,
          restProps = _objectWithoutProperties(_this$props, ['input', 'componentType', 'meta', 'validations', 'className', 'forcedErrorMessage']);

      var handledProps = {};

      switch (componentType) {
        case 'CT':
          handledProps = {
            onBlur: _this.onBlur,
            onChange: _this.onChangeCT,
            onFocus: _this.onFocus,

            value: _this.state.value
          };
          break;
        case 'extCT':
          handledProps = {
            error: error,
            onBlur: _this.onBlur,
            onChange: _this.props.onChange,
            onFocus: _this.onFocus,
            touched: touched === undefined ? true : touched,

            value: _this.props.value
          };
          break;
        case 'RF':
          handledProps = {
            error: forcedErrorMessage || error,
            onBlur: input.onBlur,
            onChange: _this.onChangeRF,
            onFocus: input.onFocus,
            touched: forceTouched !== undefined ? forceTouched : touched,
            validations: validations,
            value: input.value,
            warning: warning
          };
          break;
        default:
          break;
      }

      return Object.assign({}, restProps, handledProps);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ExtendedWisywig, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          isRequired = _props.isRequired;
      var _props2 = this.props,
          labelProps = _props2.labelProps,
          label = _props2.label,
          onWysiwygMounted = _props2.onWysiwygMounted;

      return React.createElement(
        InputLabelContainer,
        Object.assign({
          isRequired: isRequired,
          label: label
        }, labelProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 227
          },
          __self: this
        }),
        React.createElement(FeaturedExtendedWysiWyg, Object.assign({
          ref: onWysiwygMounted,
          className: className
        }, this.inputProp(), {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 232
          },
          __self: this
        }))
      );
    }
  }]);

  return ExtendedWisywig;
}(PureComponent), _class.propTypes = {
  /** Class to be passed */
  className: PropTypes.string,
  /** Component Type (helper for redux form) */
  componentType: PropTypes.string,
  /** Disables the input */
  disabled: PropTypes.bool,
  /** */
  disabledTransparent: PropTypes.bool,
  /** */
  forcedErrorMessage: PropTypes.string,
  /** Used for testing purposes */
  id: PropTypes.string,
  /** Validation when component is RF*/
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func
  }),
  /** */
  isRequired: PropTypes.bool,

  /** */
  label: PropTypes.string,
  /** */
  labelProps: PropTypes.shape({
    /** */
    labelWrapperClassName: PropTypes.string,
    /** */
    labelClassName: PropTypes.string,
    /** */
    shrinkLabel: PropTypes.bool,
    /** */
    mdShrinkLabel: PropTypes.bool,
    /** */
    lgShrinkLabel: PropTypes.bool,
    /** */
    smallSizeLabel: PropTypes.bool,
    /** */
    mediumSizeLabel: PropTypes.number,
    /** */
    largeSizeLabel: PropTypes.bool,
    /** */
    autoLabel: PropTypes.bool,
    /** */
    mdAutoLabel: PropTypes.bool,
    /** */
    lgAutoLabel: PropTypes.bool,
    /** */
    centerAlignLabel: PropTypes.bool,
    /** */
    justifyAlignLabel: PropTypes.bool,
    /** */
    rightAlignLabel: PropTypes.bool,
    /** */
    bottomAlignLabel: PropTypes.bool,
    /** */
    middleAlignLabel: PropTypes.bool,
    /** */
    topAlignLabel: PropTypes.bool,
    /** */
    inlineLabel: PropTypes.bool,
    /** */
    labelIcon: PropTypes.string,
    /** */
    inputWrapperClassName: PropTypes.string,
    /** */
    shrinkInput: PropTypes.bool,
    /** */
    mdShrinkInput: PropTypes.bool,
    /** */
    lgShrinkInput: PropTypes.bool,
    /** */
    smallSizeInput: PropTypes.bool,
    /** */
    mediumSizeInput: PropTypes.number,
    /** */
    largeSizeInput: PropTypes.bool,
    /** */
    autoInput: PropTypes.bool,
    /** */
    mdAutoInput: PropTypes.bool,
    /** */
    lgAutoInput: PropTypes.bool,
    /** */
    bottomAlignInput: PropTypes.bool,
    /** */
    middleAlignInput: PropTypes.bool,
    /** */
    topAlignInput: PropTypes.bool,
    /** Only works if you, through an inputClassName, set a specific size for the input*/
    rightAlignInput: PropTypes.bool,
    /** Only works if you, through an inputClassName, set a specific size for the input*/
    centerAlignInput: PropTypes.bool,
    /** Only works if you, through an inputClassName, set a specific size for the input*/
    leftAlignInput: PropTypes.bool
  }),
  /** Meta information */
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
    warning: PropTypes.any
  }),
  /** Name of the component */
  name: PropTypes.string,
  /** Callback function to be called on onBlur event. */
  onBlur: PropTypes.func,
  /** Callback function to retrieve the current input value. */
  onChange: PropTypes.func,
  /** Callback function to be called on onFocus event. */
  onFocus: PropTypes.func,
  /** ref of WYSIWYG */
  onWysiwygMounted: PropTypes.func,
  /** Placeholder to set when no text entered */
  placeholder: PropTypes.string,
  /** */
  touched: PropTypes.bool,
  /** Possible validations */
  validations: PropTypes.array,
  /** Current value set in Input. */
  value: PropTypes.any
}, _class.defaultProps = {
  onChange: function onChange() {
    return null;
  },
  type: 'text',
  label: ''
}, _temp2);


export default ExtendedWisywig;