import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography } from 'rc'
import { useDispatch } from 'react-redux'
import { Button } from 'components'
import { SetOnBoarding } from 'reduxActions/auth'

import FirstStep from 'static/drawings/ilus_modal.svg'
import SecondStep from 'static/drawings/ilus_modal_2.svg'
import ThirdStep from 'static/drawings/status-zero-tokens.svg'

const OnBoardingMC = ({ HideModal }) => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(0)

  const steps = [
    {
      title: 'Bienvenido a Desarrollos',
      draw: FirstStep,
      description: `Esta es una versión beta de la nueva sección de desarrollos en wiggot. Explora las funciones disponibles ahora y saca el máximo de tus proyectos.`
    },
    {
      title: 'Cargar tus proyectos',
      draw: SecondStep,
      description:
        'Sube tus desarrollos con sus respectivos modelos y muéstralos de manera atractiva para que los más de 25,000 asesores en wiggot lo comercialicen.'
    },
    {
      title: 'Monitorea tus desarrollos',
      draw: ThirdStep,
      description:
        'Obtén reportes con información relevante de tus desarrollos. Ve cuáles han sido los más buscados, los más compartidos y cuántos matches tiene cada uno.'
    }
  ]

  const renderStep = step => {
    return (
      <>
        <Typography
          variant="h2"
          color="palette.primary.main"
          css={`
            font-size: ${({ theme }) => theme.remCalc('25')};
          `}
        >
          {steps[step].title}
        </Typography>
        <img
          src={steps[step].draw}
          alt="Draw"
          css={`
            transition: 0.3s ease-in-out;
          `}
        />
        <Typography variant="body1" color="palette.new.text" align="center">
          {steps[step].description}
        </Typography>
        <Button
          rounded
          css={`
            width: ${({ theme }) => theme.remCalc('200')};
          `}
          onClick={() => {
            if (step === 2) {
              dispatch(SetOnBoarding())
              HideModal()
            } else {
              setStep(step + 1)
            }
          }}
        >
          {step === 2 ? 'Cerrar' : 'Siguiente'}
        </Button>
      </>
    )
  }

  return <Container>{renderStep(step)}</Container>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.remCalc('10')};
  padding: ${({ theme }) => theme.remCalc('12')};
  ${({ theme }) => theme.box(450, 500)}
`

export default OnBoardingMC
