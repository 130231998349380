/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-globals */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import {
  Spinner,
  Checkbox,
  Typography,
  Select,
  TextField,
  Autocomplete
} from 'rc'

import { CheckmarkSquare } from 'rc/Icons'
import { Debounce } from 'rc/helpers/globalHelpers'

import { Image, MenuButton } from 'components'
import { Minus } from 'components/Icons'

import { snakeToCamelCase, camelize } from 'utils/helpers'

import {
  BodyColumnsStyled,
  BulkButtonStyled,
  CheckboxStyled,
  CrossStyled,
  Dots3Styled,
  EmptyListContainerStyled,
  EmptyStateSubtitleButton,
  EmptyStateSubtitleStyled,
  EmptyStateTitleStyled,
  HeaderBulkActionsStyled,
  HeaderCellStyled,
  HeaderColumnsStyled,
  HeaderContainerStyled,
  HeaderFilterActionsStyled,
  HeaderOrderContainerStyled,
  SearchStyled,
  TableCellStyled,
  TableContainerStyled,
  TableGridContainerStyled,
  TableLoadingRowContainerStyled,
  TableRowStyled,
  TextStyled,
  TriangleDownStyled,
  TriangleUpStyled
} from './_StyledComponents'

class Table extends Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    history: PropTypes.object,
    tableId: PropTypes.string,
    initialPage: PropTypes.number,
    className: PropTypes.string
  }

  static defaultProps = {
    initialPage: 0
  }

  _isMounted = false

  constructor(props) {
    super(props)

    const { initialPage, config, history } = this.props

    let filterInit = {}

    config.filters.forEach(({ name, initialValue = '' }) => {
      filterInit[name] = initialValue
    })

    const { filters, sort, page } =
      history && history.location.state
        ? this.urlParamConstructor()
        : { filters: filterInit, sort: {}, page: initialPage }

    this.state = {
      data: [],
      selected: [],
      filters: { ...filterInit, ...filters },
      fetchAction: 'INIT',
      page,
      pages: 0,
      sort,
      emptySearch: true,
      allChbxSelected: false
    }

    this.props.config.add = () => {
      this.setState({ sort: {}, filters: {} }, () => {
        this.load({ page: 0, fetchAction: 'INIT' })
      })
    }
  }

  componentDidMount() {
    const { page } = this.state
    this._isMounted = true
    this.load({ page, fetchAction: 'INIT' })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      history: { location: { state = {} } = {} } = {},
      config: { tableId } = {}
    } = this.props

    const { filters } = prevState

    if (state && Object.keys(state).length > 0) {
      Object.keys(filters).forEach(filterKey => {
        const locationStateFilter = state[`${tableId}_filter-${filterKey}`]
        const stateFilter = filters[filterKey]

        if (
          locationStateFilter !== stateFilter &&
          locationStateFilter !== undefined
        ) {
          this.changeFilterValue(filterKey, locationStateFilter)

          return true
        }
      })
    }
  }

  urlParamConstructor = () => {
    const {
      history,
      config: { columns, filters, tableId = 'table' }
    } = this.props
    const search = history.location.state
    let newSort = {},
      newFilter = {}

    for (let i = 0; i < columns.length; i++) {
      let col = columns[i]
      if (col.sort && search.hasOwnProperty(`${tableId}_sort-${col.id}`)) {
        newSort[col.id] = search[`${tableId}_sort-${col.id}`]
        break
      }
    }

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i]
      if (search.hasOwnProperty(`${tableId}_filter-${filter.name}`)) {
        let val = search[`${tableId}_filter-${filter.name}`]

        let findRes

        switch (filter.type) {
          case 'dropdown':
            findRes = val
            break
          case 'async-dropdown':
            findRes = val
            break
          case 'search':
            findRes = val
            break
          default:
            findRes = null
        }

        if (findRes) {
          newFilter[filter.name] = findRes
        }
      }
    }

    return {
      filters: newFilter,
      sort: newSort,
      page: search.hasOwnProperty(tableId) ? search[tableId] : 0
    }
  }

  urlUpdater = () => {
    const {
      history,
      config: { filters: filtersConfig, tableId = 'table' }
    } = this.props
    const { filters, sort, page } = this.state

    let prevSearch = history.location.state ? history.location.state : {}
    let search = {}

    for (let keySearch in prevSearch) {
      if (
        keySearch.includes(`${tableId}_filter`) ||
        keySearch.includes(`${tableId}_sort`) ||
        keySearch.includes(`${tableId}_page`)
      ) {
        prevSearch[keySearch] = null
      }
    }

    Object.keys(filters).forEach(filterKey => {
      const filter = filtersConfig.find(item => item.name === filterKey)

      if (!filter) return

      if (
        filter.type === 'dropdown' ||
        filter.type === 'async-dropdown' ||
        filter.type === 'search'
      ) {
        prevSearch[`${tableId}_filter-${filterKey}`] = filters[filterKey]
      }
    })

    Object.keys(sort).forEach(sortKey => {
      prevSearch[`${tableId}_sort-${sortKey}`] = sort[sortKey]
    })

    prevSearch[`${tableId}_page`] = page

    for (let keySearch in prevSearch) {
      if (prevSearch[keySearch]) {
        search[keySearch] = prevSearch[keySearch]
      }
    }

    history.replace({
      pathname: history.location.pathname,
      hash: location.hash,
      search: history.location.search,
      state: {
        ...search
      }
    })
  }

  load = ({ page, paramSort, paramFilters, afterCb, fetchAction }) => {
    const {
      config: { action, onLoad }
    } = this.props

    const { sort: stateSort, filters: stateFilters, status } = this.state

    let resolvedSort = paramSort || stateSort
    let resolvedFilters = paramFilters || stateFilters

    let sort = {},
      filters = {},
      emptySearch = true

    for (let orderKey in resolvedSort) {
      sort = { order: orderKey, orderDirection: resolvedSort[orderKey] }
    }

    for (let filterKey in resolvedFilters) {
      if (
        resolvedFilters[filterKey] !== null &&
        resolvedFilters[filterKey] !== ''
      ) {
        emptySearch = false

        if (resolvedFilters[filterKey]) {
          filters[`${filterKey}`] = resolvedFilters[filterKey]
        }
      }
    }

    onLoad && onLoad({ isPre: true, filters, status, fetchAction })

    const actualPage = parseInt(page)

    this.setState(
      {
        page: actualPage,
        status: `LOADING`,
        fetchAction,
        emptySearch,
        allChbxSelected: false,
        selected: []
      },
      () => {
        action({
          page: actualPage.toString(),
          sort,
          filters
        }).then(
          response => {
            const { config: { responseDataKey = 'data' } = {} } = this.props
            let {
              data: {
                [responseDataKey]: data = [],
                total,
                pages: resPages
              } = {}
            } = response

            let pages = parseInt(resPages)

            let dataFormatted = data.map(item => snakeToCamelCase(item))

            for (let iData = 0; iData < data.length; iData++) {
              dataFormatted[iData].selected = false
              dataFormatted[iData].showActions = false
            }

            onLoad &&
              onLoad({
                isPre: false,
                filters,
                total: total || dataFormatted.length
              })

            const newData =
              actualPage === 0
                ? dataFormatted
                : [...this.state.data, ...dataFormatted]

            this._isMounted &&
              this.setState(
                {
                  data: newData,
                  total: total || newData.length,
                  pages,
                  page: actualPage + 1,
                  status: `FETCH_SUCCESS`
                },
                () => afterCb && afterCb()
              )
          },
          error => {
            this._isMounted &&
              this.setState({
                data: [],
                total: 0,
                pages: 0,
                status: `FETCH_ERROR`
              })
          }
        )
      }
    )
  }

  updateAndLoad = () => {
    this.urlUpdater()
    this.load({
      page: 0,
      pages: 0,
      fetchAction: 'SEARCH',
      afterCb: this.state.unlock ? this.state.unlock : () => null
    })
  }

  changeFilterValue = (filterId, value) => {
    const { filters } = this.state
    const { filters: filtersConfig } = this.props.config
    let filtersToClean = {}

    filtersConfig.forEach(item => {
      if (
        item.hasOwnProperty('show') &&
        !item.show({ filters: { ...filters, [filterId]: value } })
      ) {
        filtersToClean[item.name] = ''
      }
    })

    this.setState(
      prevState => ({
        filters: { ...prevState.filters, ...filtersToClean, [filterId]: value }
      }),
      this.updateAndLoad
    )
  }

  sort = (colid, direction) => {
    if (
      Object.keys(this.state.sort).length === 0 ||
      this.state.sort[colid] === undefined
    ) {
      this.setState({ sort: { [colid]: direction } }, this.updateAndLoad)
    } else {
      const newDirection = this.state.sort[colid] === 'a' ? 'd' : 'a'
      this.setState({ sort: { [colid]: newDirection } }, this.updateAndLoad)
    }
  }

  debounceSearch = Debounce(this.updateAndLoad, 300)

  searchOnChange = (event, id) => {
    const {
      target: { value }
    } = event

    this.setState(
      prevState => ({
        filters: {
          ...prevState.filters,
          [id]: value
        }
      }),
      this.debounceSearch
    )
  }

  onClickRow = (columnIndex, dataIndex) => {
    const { click, columns } = this.props.config

    let column = columnIndex

    if (columns[column].clickable) {
      const data = this.state.data[dataIndex]
      click && click(data)
    }
  }

  onBulkAction = buttonIndex => {
    const { bulkActions } = this.props.config

    const selected = []
    const indexes = []
    let data = this.state.data

    for (let iSelected = 0; iSelected < data.length; iSelected++) {
      if (data[iSelected].selected) {
        selected.push(data[iSelected])
        indexes.push(iSelected)
      }
    }

    bulkActions[buttonIndex].onClick(selected, this.onUpdate, indexes)
  }

  onUpdate = (action, record, index) => {
    let data = this.state.data.concat([])

    switch (action) {
      case 'delete':
        this.load({ page: 0, fetchAction: 'RESET' })
        break
      case 'update':
        data[index] = { ...data[index], ...record }
        this.setState({ data })
        break
      case 'edit':
        data[index] = record
        this.setState({ data })
        break
      case 'initialize':
        this.load({ page: 0, fetchAction: 'INIT' })
        break
      case 'reset':
      default:
        this.load({ page: 0, fetchAction: 'RESET' })
        break
    }
  }

  toggleChbx = (index, column) => {
    let data = this.state.data.concat([])

    const cb = () => {
      const selected = []
      let allChbxSelected = false
      for (let iData = 0; iData < data.length; iData++) {
        allChbxSelected = allChbxSelected || data[iData].selected
      }

      for (let iSelected = 0; iSelected < data.length; iSelected++) {
        if (data[iSelected].selected) {
          selected.push(data[iSelected])
        }
      }
      this.setState({ selected, allChbxSelected })
    }

    if (index < 0) {
      let allChbxSelected = index === -1 && !this.state.allChbxSelected
      for (let iData = 0; iData < data.length; iData++) {
        if (column && column.selectIf ? column.selectIf(data[iData]) : true) {
          data[iData].selected = allChbxSelected
        }
      }
      this.setState({ data, allChbxSelected }, cb)
    } else {
      if (column && (column.selectIf ? column.selectIf(data[index]) : true)) {
        data[index].selected = !data[index].selected
      }
      this.setState({ data }, cb)
    }
  }

  renderBulkActions = () => {
    const { bulkActions, tableId } = this.props.config
    const { selected, filters } = this.state
    return bulkActions.map((button, buttonIndex) => {
      let enabled = button.enabled && !button.enabled(selected)
      let _show = button.show ? button.show({ filters }) : true
      return _show ? (
        <BulkButtonStyled
          id={`${tableId}-${camelize(
            filters.f_table ? filters.f_table.toLowerCase() : ''
          )}Table-bulkActions-${button.id}`}
          key={`${buttonIndex}-${Math.random().toString(36).substring(2)}`}
          index={buttonIndex}
          disabled={enabled}
          onClick={() => this.onBulkAction(buttonIndex)}
          variant="invertedOutlined"
        >
          {button.icon}
          {button.name}
        </BulkButtonStyled>
      ) : null
    })
  }

  renderFilter = () => {
    const { filters } = this.props.config
    const { filters: stateFilters } = this.state
    const {
      config: { tableId = 'table' }
    } = this.props

    return filters
      .filter(item => {
        return item.show ? item.show({ filters: this.state.filters }) : true
      })
      .map((filter, key) => {
        const value = this.state.filters[filter.name]
        switch (filter.type) {
          case 'search': {
            return (
              <TextField
                type="search"
                id={`${tableId}-${camelize(
                  stateFilters.f_table ? stateFilters.f_table.toLowerCase() : ''
                )}Table-filter-input-${camelize(filter.id)}`}
                name={filter.name}
                key={Math.random().toString(36).substring(2)}
                css={filter.style}
                placeholder={filter.placeholder}
                onChange={event => this.searchOnChange(event, filter.name)}
                InputProps={{
                  startAdornment: <SearchStyled />
                }}
                value={value}
              />
            )
          }
          case 'async-dropdown':
            return (
              <Autocomplete
                id={`${tableId}-${camelize(
                  stateFilters.f_table ? stateFilters.f_table.toLowerCase() : ''
                )}Table-filter-autocomplete-${camelize(filter.id)}`}
                name={filter.name}
                css={`
                  > div {
                    margin: 0;
                  }
                `}
                fetchFunction={value => filter.loadOptions(value)}
                labelItemKey={filter.getOptionLabel}
                onChange={(name, value) => {
                  this.changeFilterValue(filter.name, value)
                }}
                value={value && value[filter.getOptionLabel]}
                placeholder={filter.placeholder}
                key={Math.random().toString(36).substring(2)}
              />
            )

          case 'dropdown':
            return (
              <Select
                id={`${tableId}-${camelize(
                  stateFilters.f_table ? stateFilters.f_table.toLowerCase() : ''
                )}Table-filter-dropdown-${camelize(filter.id)}`}
                name={filter.name}
                css={filter.style}
                fullWidth={false}
                onChange={({ target: { value } }) => {
                  this.changeFilterValue(filter.name, value)
                }}
                optionLabelKey={filter.getOptionLabel}
                optionValueKey={filter.getOptionValue}
                customHeight={filter.customHeight}
                initialValue={filter.initialValue}
                withClean={filter.isClearable}
                placeholder={filter.placeholder}
                value={value}
                options={filter.options}
                key={Math.random().toString(36).substring(2)}
              />
            )
          default:
            return null
        }
      })
  }

  renderGridContainer = () => {
    const { filters } = this.state
    const { columns: columnsConfig } = this.props.config

    const columns = columnsConfig
      .map(({ show, ...items }) =>
        show ? (show({ filters }) ? items : null) : items
      )
      .filter(item => item !== null)

    const columnWidths = columns.map(({ width }) => (width ? width : '1fr'))

    return (
      <TableGridContainerStyled>
        {this.renderHeader(columns, columnWidths)}
        {this.renderBody(columns, columnWidths)}
      </TableGridContainerStyled>
    )
  }

  renderHeader = (columns, columnWidths) => {
    const { sort, allChbxSelected, filters, selected, data } = this.state
    const {
      config: { tableId = 'table' }
    } = this.props
    return (
      <HeaderColumnsStyled columnWidths={columnWidths}>
        {columns.map((column, index) => {
          let arrows = []
          if (column.sort) {
            arrows.push(
              <TriangleUpStyled
                id={`${tableId}-${camelize(
                  filters.f_table ? filters.f_table.toLowerCase() : ''
                )}Table-header-${camelize(column.id)}-sortAscending`}
                key={`${column.id}-${Math.random()
                  .toString(36)
                  .substring(2)}-a`}
                onClick={() => this.sort(column.id, 'a')}
                selected={sort[column.id] === 'a'}
              />
            )
            arrows.push(
              <TriangleDownStyled
                id={`${tableId}-${camelize(
                  filters.f_table ? filters.f_table.toLowerCase() : ''
                )}Table-header-${camelize(column.id)}-sortDescending`}
                key={`${column.id}-${Math.random()
                  .toString(36)
                  .substring(2)}-d`}
                onClick={() => this.sort(column.id, 'd')}
                selected={sort[column.id] === 'd'}
              />
            )
          }
          return (
            <HeaderCellStyled
              key={Math.random().toString(36).substring(2)}
              index={index}
              css={column.columnStyle && column.columnStyle({ filters })}
            >
              {column.type === 'checkbox' ? (
                <CheckboxStyled
                  checkedIcon={
                    selected.length === data.length ? CheckmarkSquare : Minus
                  }
                  value={allChbxSelected}
                  id={`${tableId}-${camelize(
                    filters.f_table ? filters.f_table.toLowerCase() : ''
                  )}Table-header-${camelize(column.id)}-selectAll`}
                  onClick={() => this.toggleChbx(-1, column)}
                />
              ) : (
                <Fragment>
                  {column.name}
                  <HeaderOrderContainerStyled>
                    {arrows}
                  </HeaderOrderContainerStyled>
                </Fragment>
              )}
            </HeaderCellStyled>
          )
        })}
      </HeaderColumnsStyled>
    )
  }

  renderBody = (columns, columnWidths) => {
    const { status, data, fetchAction } = this.state

    return status !== 'FETCH_ERROR' ? (
      status === 'LOADING' &&
      (fetchAction === 'SEARCH' || fetchAction === 'INIT') ? (
        <TableLoadingRowContainerStyled>
          <Spinner />
        </TableLoadingRowContainerStyled>
      ) : (
        <BodyColumnsStyled onNearEnd={this.onNearEnd}>
          {data.length > 0
            ? [
                ...this.renderRows(columns, columnWidths),
                status === 'LOADING' ? (
                  <TableLoadingRowContainerStyled>
                    <Spinner />
                  </TableLoadingRowContainerStyled>
                ) : null
              ]
            : status &&
              status !== 'LOADING' && (
                <EmptyListContainerStyled>
                  {this.renderEmptyList()}
                </EmptyListContainerStyled>
              )}
        </BodyColumnsStyled>
      )
    ) : null
  }

  renderRows = (columns, columnWidths) => {
    const { data, filters, selected } = this.state
    const { tableId, footerRowElement } = this.props.config
    return data.map((row, rowIndex) => (
      <TableRowStyled
        key={`${Math.random().toString(36).substring(2)}-tableRow-${rowIndex}}`}
        id={`${tableId}-${camelize(
          filters.f_table ? filters.f_table.toLowerCase() : ''
        )}Table-tableRow-${rowIndex}`}
        columnWidths={columnWidths}
        footerRowElement={footerRowElement !== undefined}
        selected={row.selected}
      >
        {columns.map((column, columnIndex) => {
          let cell_content = null

          const willShow = column.show ? column.show({ filters }) : true
          const showCell = column.showCell ? column.showCell({ row }) : true

          const content =
            (column.type === 'text' && column.render && column.render(row)) ||
            row[column.id]

          switch (column.type) {
            case 'checkbox':
              cell_content = (
                <Checkbox
                  id={`${tableId}-${camelize(
                    filters.f_table ? filters.f_table.toLowerCase() : ''
                  )}Table-tableRow-${rowIndex}-checkbox-selectRow`}
                  value={row.selected}
                  onClick={() => this.toggleChbx(rowIndex, column)}
                />
              )
              break
            case 'html':
              cell_content =
                column.render &&
                column.render(row, this.onUpdate, rowIndex, selected, filters)
              break
            case 'text':
              cell_content = (
                <TextStyled
                  css={column.contentStyle && column.contentStyle({ filters })}
                  title={typeof content === 'string' ? content : undefined}
                >
                  {content}
                </TextStyled>
              )
              break
            case 'image':
              cell_content = (
                <Image objectFit="cover" id={column.src && column.src(row)} />
              )
              break
            case 'actions':
              cell_content =
                column.options.length > 0 ? (
                  <MenuButton
                    idBase={`${tableId}-${camelize(
                      filters.f_table ? filters.f_table.toLowerCase() : ''
                    )}Table-tableRow-${rowIndex}-menuButton`}
                    options={column.options.filter(item =>
                      item.show ? item.show({ data: row, filters }) : true
                    )}
                    onUpdate={this.onUpdate}
                    objectData={row}
                    disabled={selected.length > 0}
                    buttonChildren={() => (
                      <Dots3Styled disabled={selected.length > 0} />
                    )}
                  />
                ) : null
              break
            default:
              cell_content = null
              break
          }

          if (willShow) {
            return (
              <TableCellStyled
                index={columnIndex}
                key={`${Math.random()
                  .toString(36)
                  .substring(2)}-${columnIndex}`}
                hide={willShow && !showCell}
                columnType={column.type}
                onClick={
                  column.type !== 'actions' &&
                  column.type !== 'checkbox' &&
                  column.clickable
                    ? () => this.onClickRow(columnIndex, rowIndex)
                    : undefined
                }
                showOverflow={
                  column.type === 'actions' || column.type === 'html'
                }
                textAlign={column.textAlign}
                css={
                  column.columnStyle
                    ? column.columnStyle({ filters })
                    : column.cellStyle
                    ? column.cellStyle({ filters })
                    : undefined
                }
                justifyContent={
                  column.type === 'checkbox' ? 'center' : column.justifyContent
                }
              >
                {cell_content}
              </TableCellStyled>
            )
          }
          return null
        })}
        {footerRowElement && (
          <div
            columnWidths={columnWidths}
            css={`
              grid-area: 2 / 1 / 3 /
                ${({ columnWidths }) => columnWidths.length + 1};
            `}
          >
            {footerRowElement({ data: row, filters })}
          </div>
        )}
      </TableRowStyled>
    ))
  }

  renderEmptyList = () => {
    const { status, filters, fetchAction } = this.state
    const { noResults } = this.props.config

    const data = noResults({
      filters,
      status,
      fetchAction
    }).filter(item => item !== null)

    return data
      ? data.map((item, key) => {
          const _onClick = () => {
            if (item.route) {
              this.props.history.push(item.route)
            }
            item.onClick && item.onClick()
          }
          switch (item.type) {
            case 'image':
              return (
                <img
                  key={`splash-item-${key}-${Math.random()
                    .toString(36)
                    .substring(2)}`}
                  alt="img"
                  src={item.src}
                />
              )
            case 'title':
              return (
                <EmptyStateTitleStyled
                  key={`splash-item-${key}-${Math.random()
                    .toString(36)
                    .substring(2)}`}
                >
                  {item.text}
                </EmptyStateTitleStyled>
              )
            case 'subtitle':
              return (
                <EmptyStateSubtitleStyled
                  variant="body2"
                  key={`splash-item-${key}-${Math.random()
                    .toString(36)
                    .substring(2)}`}
                >
                  {item.text}
                </EmptyStateSubtitleStyled>
              )
            case 'button':
              return (
                <EmptyStateSubtitleButton
                  id={item.id}
                  key={`splash-item-${key}-${Math.random()
                    .toString(36)
                    .substring(2)}`}
                  onClick={_onClick}
                >
                  {item.text}
                </EmptyStateSubtitleButton>
              )
            case 'html':
              return item.src
            default:
              return null
          }
        })
      : null
  }

  onNearEnd = unlock => {
    const { page, pages, status } = this.state

    if (page < pages && status !== 'FETCH_ERROR') {
      this.load({
        page,
        afterCb: unlock,
        fetchAction: 'NEXT_PAGE'
      })
    } else {
      this.setState({ status: 'FINISH_REACHED', unlock })
    }
  }

  render() {
    const { className } = this.props
    const { headerFilterStyle } = this.props.config
    const { selected, filters } = this.state

    const isFiltersEmpty = Object.entries(filters || {}).length
    return (
      <TableContainerStyled className={className}>
        <HeaderContainerStyled
          isFiltersEmpty={isFiltersEmpty || selected.length}
        >
          {selected.length > 0 ? (
            <HeaderBulkActionsStyled>
              <Typography variant="h4" component="div" color="white">
                {`${selected.length} seleccionado${
                  selected.length > 1 ? 's' : ''
                }`}
              </Typography>
              <div>
                {this.renderBulkActions()}
                <CrossStyled onClick={() => this.toggleChbx(-2)} />
              </div>
            </HeaderBulkActionsStyled>
          ) : (
            <HeaderFilterActionsStyled
              filters={filters}
              css={headerFilterStyle ? headerFilterStyle : undefined}
            >
              {this.renderFilter()}
            </HeaderFilterActionsStyled>
          )}
        </HeaderContainerStyled>
        {this.renderGridContainer()}
      </TableContainerStyled>
    )
  }
}

export default withRouter(Table)
