import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <g>
    <polygon fill="currentColor" points="177,0 0,0 27,27 177,27 	" />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M49.2,6.6l-2.3,6.9l-2.3-6.9h-1.1l-2.4,6.8l-2.3-6.8h-1.2l2.8,8.4h1.3L44,8.3l2.3,6.7h1.3l2.8-8.4H49.2z
          M52.5,7.4c0.5,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.7-0.8-0.7c-0.4,0-0.8,0.3-0.8,0.8S52,7.4,52.5,7.4z M51.9,15H53V8.6h-1.2V15z
          M60.5,8.6v1c-0.5-0.7-1.4-1-2.4-1c-1.8,0-3.2,1.2-3.2,3.1s1.4,3.1,3.2,3.1c0.9,0,1.7-0.3,2.3-1v0.5c0,1.4-0.7,2.1-2.2,2.1
          c-0.9,0-1.9-0.3-2.4-0.8l-0.6,0.9c0.7,0.6,1.9,0.9,3,0.9c2.2,0,3.3-1,3.3-3.3V8.6H60.5z M58.2,13.7c-1.3,0-2.2-0.9-2.2-2.1
          c0-1.2,0.9-2.1,2.2-2.1c1.3,0,2.2,0.8,2.2,2.1C60.4,12.9,59.5,13.7,58.2,13.7z M69,8.6v1c-0.5-0.7-1.4-1-2.4-1
          c-1.8,0-3.2,1.2-3.2,3.1s1.4,3.1,3.2,3.1c0.9,0,1.7-0.3,2.3-1v0.5c0,1.4-0.7,2.1-2.2,2.1c-0.9,0-1.9-0.3-2.4-0.8l-0.6,0.9
          c0.7,0.6,1.9,0.9,3,0.9c2.2,0,3.3-1,3.3-3.3V8.6H69z M66.8,13.7c-1.3,0-2.2-0.9-2.2-2.1c0-1.2,0.9-2.1,2.2-2.1
          c1.3,0,2.2,0.8,2.2,2.1C69,12.9,68,13.7,66.8,13.7z M75.2,15.1c1.9,0,3.3-1.4,3.3-3.3s-1.4-3.2-3.3-3.2c-1.9,0-3.3,1.3-3.3,3.2
          S73.3,15.1,75.2,15.1z M75.2,14.1c-1.2,0-2.1-0.9-2.1-2.2s0.9-2.2,2.1-2.2c1.2,0,2.1,0.9,2.1,2.2S76.5,14.1,75.2,14.1z M83.6,13.8
          c-0.2,0.2-0.6,0.3-0.9,0.3c-0.6,0-1-0.4-1-1.1V9.6h1.8V8.6h-1.8V7.2h-1.2v1.4h-1.1v0.9h1.1v3.5c0,1.3,0.7,2,2,2
          c0.5,0,1-0.1,1.4-0.4L83.6,13.8z M89.2,17.3h1.1V6.1h-1.1V17.3z M96.2,15h3.8c2.7,0,4.6-1.7,4.6-4.2s-1.9-4.2-4.6-4.2h-3.8V15z
          M98.2,13.4V8.2h1.8c1.7,0,2.8,1,2.8,2.6s-1.1,2.6-2.8,2.6H98.2z M112.6,11.8c0-2.1-1.5-3.3-3.4-3.3c-2,0-3.4,1.4-3.4,3.3
          c0,1.9,1.5,3.3,3.7,3.3c1.2,0,2.1-0.4,2.7-1l-1-1.1c-0.4,0.4-0.9,0.6-1.6,0.6c-1,0-1.7-0.5-1.8-1.3h4.9
          C112.6,12.1,112.6,11.9,112.6,11.8z M109.2,9.9c0.8,0,1.5,0.5,1.6,1.3h-3.2C107.8,10.4,108.4,9.9,109.2,9.9z M116.2,15.1
          c1.9,0,3-0.9,3-2.1c0-2.5-3.9-1.5-3.9-2.6c0-0.3,0.4-0.6,1.2-0.6c0.6,0,1.2,0.1,1.9,0.5l0.6-1.3c-0.6-0.4-1.6-0.6-2.5-0.6
          c-1.9,0-3,0.9-3,2.1c0,2.6,3.9,1.5,3.9,2.6c0,0.4-0.3,0.6-1.2,0.6c-0.8,0-1.7-0.3-2.2-0.6l-0.6,1.3
          C114.1,14.8,115.2,15.1,116.2,15.1z M123.3,8.4c-1,0-2.1,0.3-2.8,0.8l0.7,1.3c0.5-0.4,1.2-0.6,1.9-0.6c1,0,1.5,0.5,1.5,1.3H123
          c-2,0-2.8,0.8-2.8,1.9c0,1.1,0.9,2,2.4,2c0.9,0,1.6-0.3,2-0.9V15h1.8v-3.7C126.4,9.4,125.3,8.4,123.3,8.4z M123.1,13.8
          c-0.7,0-1.1-0.3-1.1-0.8c0-0.4,0.3-0.8,1.2-0.8h1.3V13C124.3,13.5,123.8,13.8,123.1,13.8z M130.1,9.4V8.5h-1.8V15h1.9v-3
          c0-1.2,0.7-1.8,1.7-1.8c0.1,0,0.3,0,0.4,0V8.4C131.3,8.4,130.6,8.8,130.1,9.4z M135.5,9.4V8.5h-1.8V15h1.9v-3
          c0-1.2,0.7-1.8,1.7-1.8c0.1,0,0.3,0,0.4,0V8.4C136.7,8.4,135.9,8.8,135.5,9.4z M142.1,15.1c2.1,0,3.5-1.4,3.5-3.3s-1.5-3.3-3.5-3.3
          s-3.6,1.4-3.6,3.3S140,15.1,142.1,15.1z M142.1,13.6c-0.9,0-1.7-0.7-1.7-1.8s0.7-1.8,1.7-1.8s1.6,0.7,1.6,1.8S143,13.6,142.1,13.6z
          M147.1,15h1.9V6.1h-1.9V15z M151,15h1.9V6.1H151V15z M157.9,15.1c2.1,0,3.5-1.4,3.5-3.3s-1.5-3.3-3.5-3.3s-3.6,1.4-3.6,3.3
          S155.8,15.1,157.9,15.1z M157.9,13.6c-0.9,0-1.7-0.7-1.7-1.8s0.7-1.8,1.7-1.8s1.6,0.7,1.6,1.8S158.8,13.6,157.9,13.6z"
    />
  </g>,
  'DevelopmentPlate',
  '0 0 177 27'
)
