import styled, { css } from 'styled-components'
import { CheckboxInput } from 'rc/FormComponents'
import RadioInputLib, { stylesGenerator } from 'rc/FormComponents/RadioInput'

const customRadioInputStyle = stylesGenerator({
  checked: css`
    color: white;
    background-color: ${({ theme }) => theme.palette.primary.dark};
  `,
  hover: css``,
  hover_focus: css``,
  focus: css``
})

export const CustomRadioInput = styled(RadioInputLib)`
  height: ${({ theme }) => theme.remCalc('50')};
  flex: 1;
  justify-content: space-between;
  font-weight: 800;
  font-size: ${({ theme }) => theme.remCalc('18')};
  color: ${({ theme, isDevelop }) => isDevelop ? '#7D7F9C' : theme.palette.primary.main};
  ${customRadioInputStyle}
  &&& {
    .radio-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const LeftLabelChekboxInput = styled(CheckboxInput)`
  flex-direction: row-reverse;
  font-weight: bold;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.base.white};
  min-height: ${({ theme }) => theme.remCalc('55')};
  &&& {
    .check-label {
      margin: ${({ theme }) => theme.remCalc('0 10')};
    }
  }
`
export const LeftLabelRadioInput = styled(RadioInputLib)`
  flex-direction: row-reverse;
  justify-content: space-between;
  font-weight: bold;
  background: ${({ theme }) => theme.palette.base.white};
  min-height: ${({ theme }) => theme.remCalc('55')};
  &&& {
    .radio-label {
      margin: ${({ theme }) => theme.remCalc('0 10')};
    }
  }
`
