var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/FormInputs/Previewer.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { default as YouTube } from '../Previewers/YouTubePreviewer';
import { default as VirtualTour } from '../Previewers/VirtualTourPreviewer';
var Previewer = (_temp2 = _class = function (_Component) {
  _inherits(Previewer, _Component);

  function Previewer() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Previewer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Previewer.__proto__ || Object.getPrototypeOf(Previewer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      value: _this.props.input ? _this.props.input.value : _this.props.value ? _this.props.value : ''
    }, _this.inputProp = function () {
      var _this$props = _this.props,
          componentType = _this$props.componentType,
          height = _this$props.height,
          relatedVideo = _this$props.relatedVideo,
          controls = _this$props.controls,
          url = _this$props.url,
          width = _this$props.width;

      switch (componentType) {
        case 'CT':
          return {
            url: url,
            height: height,
            relatedVideo: relatedVideo,
            controls: controls
          };
        case 'RF':
          return {
            url: _this.props.input.value,
            height: height,
            width: width,
            relatedVideo: relatedVideo,
            controls: controls
          };
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Previewer, [{
    key: 'render',
    value: function render() {
      return this.props.type == 'youtube' ? React.createElement(YouTube, Object.assign({}, this.inputProp(), {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 75
        },
        __self: this
      })) : React.createElement(VirtualTour, Object.assign({}, this.inputProp(), {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 77
        },
        __self: this
      }));
    }
  }]);

  return Previewer;
}(Component), _class.propTypes = {
  /** */
  type: PropTypes.string.isRequired,
  /** Component Type (helper for redux form). */
  componentType: PropTypes.string,
  /** */
  controls: PropTypes.bool,
  /** */
  height: PropTypes.string,
  /** Validation when component is RF. */
  input: PropTypes.shape({
    /** */
    value: PropTypes.any
  }),
  /** */
  name: PropTypes.string,
  /** */
  relatedVideo: PropTypes.bool,
  /** */
  url: PropTypes.string,
  /** */
  value: PropTypes.any,
  /** */
  width: PropTypes.string
}, _class.defaultProps = {
  width: '100%',
  height: '300px'
}, _temp2);


export default Previewer;