import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <svg width="84" height="84" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45.9112 45.4501H8.11124C7.48124 45.4501 6.89624 45.0901 6.53624 44.5501C6.22124 44.0101 6.22124 43.3351 6.53624 42.7501L25.4362 9.45005C25.7512 8.86505 26.3362 8.55005 27.0112 8.55005C27.6862 8.55005 28.2712 8.91005 28.5862 9.45005L47.4862 42.7501C47.8012 43.2901 47.8012 44.0101 47.4862 44.5501C47.1262 45.0901 46.5412 45.4501 45.9112 45.4501ZM11.2162 41.8501H42.8512L27.0562 13.995L11.2162 41.8501ZM28.8112 31.095V22.725C28.8112 21.735 28.0012 20.925 27.0112 20.925C26.0212 20.925 25.2112 21.735 25.2112 22.725V31.095C25.2112 32.085 26.0212 32.895 27.0112 32.895C28.0012 32.895 28.8112 32.085 28.8112 31.095ZM28.5862 38.565C28.9912 38.16 29.2612 37.575 29.2612 36.99C29.2612 36.405 29.0362 35.82 28.5862 35.415C28.1812 35.01 27.5962 34.74 27.0112 34.74C26.4262 34.74 25.8412 34.965 25.4362 35.415C25.0312 35.82 24.7612 36.405 24.7612 36.99C24.7612 37.575 24.9862 38.16 25.4362 38.565C25.8412 38.97 26.4262 39.24 27.0112 39.24C27.5962 39.24 28.1812 38.97 28.5862 38.565Z" fill="#FFBC08"/>
  </svg>
,
  'WiggotIconWarning',
  '0 0 95 103'
)
