import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M13.7156 10.6925C13.7156 10.9025 13.6689 11.1183 13.5697 11.3283C13.4706 11.5383 13.3422 11.7367 13.1731 11.9233C12.8872 12.2383 12.5722 12.4658 12.2164 12.6117C11.8664 12.7575 11.4872 12.8333 11.0789 12.8333C10.4839 12.8333 9.84807 12.6933 9.17724 12.4075C8.50641 12.1217 7.83557 11.7367 7.17057 11.2525C6.49974 10.7625 5.86391 10.22 5.25724 9.61916C4.65641 9.0125 4.11391 8.37666 3.62974 7.71166C3.15141 7.04666 2.76641 6.38166 2.48641 5.7225C2.20641 5.0575 2.06641 4.42166 2.06641 3.815C2.06641 3.41833 2.13641 3.03916 2.27641 2.68916C2.41641 2.33333 2.63807 2.00666 2.94724 1.715C3.32057 1.3475 3.72891 1.16666 4.16057 1.16666C4.32391 1.16666 4.48724 1.20166 4.63307 1.27166C4.78474 1.34166 4.91891 1.44666 5.02391 1.59833L6.37724 3.50583C6.48224 3.65166 6.55807 3.78583 6.61057 3.91416C6.66307 4.03666 6.69224 4.15916 6.69224 4.27C6.69224 4.41 6.65141 4.55 6.56974 4.68416C6.49391 4.81833 6.38307 4.95833 6.24307 5.09833L5.79974 5.55916C5.73557 5.62333 5.70641 5.69916 5.70641 5.7925C5.70641 5.83916 5.71224 5.88 5.72391 5.92666C5.74141 5.97333 5.75891 6.00833 5.77057 6.04333C5.87557 6.23583 6.05641 6.48666 6.31307 6.79C6.57557 7.09333 6.85557 7.4025 7.15891 7.71166C7.47391 8.02083 7.77724 8.30666 8.08641 8.56916C8.38974 8.82583 8.64057 9.00083 8.83891 9.10583C8.86807 9.1175 8.90307 9.135 8.94391 9.1525C8.99057 9.17 9.03724 9.17583 9.08974 9.17583C9.18891 9.17583 9.26474 9.14083 9.32891 9.07666L9.77224 8.63916C9.91807 8.49333 10.0581 8.3825 10.1922 8.3125C10.3264 8.23083 10.4606 8.19 10.6064 8.19C10.7172 8.19 10.8339 8.21333 10.9622 8.26583C11.0906 8.31833 11.2247 8.39416 11.3706 8.49333L13.3014 9.86416C13.4531 9.96916 13.5581 10.0917 13.6222 10.2375C13.6806 10.3833 13.7156 10.5292 13.7156 10.6925Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M11.6916 5.25C11.6916 4.9 11.4174 4.36333 11.0091 3.92583C10.6357 3.52333 10.1399 3.20833 9.6499 3.20833"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7332 5.25C13.7332 2.9925 11.9074 1.16666 9.6499 1.16666"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'PhoneCall',
  '0 0 15 14'
)
