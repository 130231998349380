import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Modal, Typography } from 'rc'
import { Cross } from 'rc/Icons'

const CrossStyled = styled(Cross)`
  fill: ${({ theme }) => theme.palette.gray.dark};
  cursor: pointer;
  ${({ theme }) => theme.box(20, 20)}
`

const ContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.remCalc('15 20')};
  background-color: ${({ isPrimary }) => (isPrimary ? '#E5E5E5' : '#f2f2f2')};
  > button {
    height: 100%;
    margin-left: ${({ theme }) => theme.remCalc('15')};
  }
  > *:nth-child(1) > * {
    width: 100%;
  }
`

const ModalContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.gray.light};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: ${({ theme }) => theme.remCalc('10')};
  width: ${({ theme }) => theme.remCalc('280')};
`

const ModalHeaderContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.remCalc('5 30')};
`

const ModalFilterContainerStyled = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${({ theme }) => theme.remCalc('0 30')};
  > div {
    margin-top: ${({ theme }) => theme.remCalc('30')};
  }
`

const ModalButtonContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.remCalc('5 30')};
  > button {
    width: 100%;
  }
`

const PrimaryElementStyled = styled.div`
  width: 100%;
`

const SecondaryElementStyled = styled.div`
  margin: 0;
  flex: 1;
`

const ListFilterMobile = ({
  config,
  secondaryContainerFilterCss,
  debounceRequestSearch,
  params = {},
  requestSearch,
  renderElement,
  onFilterModalHandled,
  idBase
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [localParams, setLocalParams] = useState({})

  const setParam = data => {
    setLocalParams({ ...localParams, ...data })
  }

  const searchAndClose = () => {
    setIsOpen(false)
    onFilterModalHandled && onFilterModalHandled(false)
    requestSearch(localParams)
  }

  const cleanAndClose = () => {
    setIsOpen(false)
    onFilterModalHandled && onFilterModalHandled(false)
    setLocalParams({})
  }

  const setAndOpen = () => {
    setLocalParams(params)
    onFilterModalHandled && onFilterModalHandled(true)
    setIsOpen(true)
  }

  let PrimarySet = null,
    SecondarySet = null,
    OthersSet = []

  config.forEach(item => {
    if (item.primary) {
      PrimarySet = (
        <PrimaryElementStyled>
          {renderElement(
            item,
            params[item.name],
            requestSearch,
            debounceRequestSearch
          )}
        </PrimaryElementStyled>
      )
    } else if (item.secondary) {
      SecondarySet = (
        <SecondaryElementStyled>
          {renderElement(
            item,
            params[item.name],
            requestSearch,
            debounceRequestSearch
          )}
        </SecondaryElementStyled>
      )
    } else {
      OthersSet.push({
        component: (
          <PrimaryElementStyled key={item.name + '-other'}>
            {renderElement(item, localParams[item.name], setParam)}
          </PrimaryElementStyled>
        ),
        value: params[item.name]
      })
    }
  })

  const renderTotalFiltersApplied = () => {
    let applied = 0

    OthersSet.forEach(item => {
      if (item.value) {
        applied++
      }
    })

    return applied > 0 ? `(${applied})` : ''
  }

  /*
  css={`
            > *:nth-child(1) > * {
              width: ${({ OthersSet }) =>
                OthersSet.length > 0 ? '100%' : '0'};
            }
          `}
  */

  return (
    <Fragment>
      {PrimarySet && <ContainerStyled isPrimary>{PrimarySet}</ContainerStyled>}
      {(SecondarySet || OthersSet.length > 0) && (
        <ContainerStyled
          OthersSet={OthersSet}
          css={secondaryContainerFilterCss}
        >
          {SecondarySet}
          {OthersSet.length > 0 && (
            <Button
              onClick={setAndOpen}
              id={`${idBase}-button-openFiltersModalMobile`}
            >
              {`Filtros ${renderTotalFiltersApplied()}`}
            </Button>
          )}
        </ContainerStyled>
      )}
      <Modal open={isOpen} onClose={cleanAndClose}>
        <ModalContainerStyled>
          <ModalHeaderContainerStyled>
            <Typography color="palette.primary.main" variant="h4">
              Filtros
            </Typography>
            <CrossStyled onClick={cleanAndClose} />
          </ModalHeaderContainerStyled>
          <ModalFilterContainerStyled>
            <Typography variant="subtitle3">Filtrar por:</Typography>
            {OthersSet.map(item => item.component)}
          </ModalFilterContainerStyled>
          <ModalButtonContainerStyled>
            <Button
              color="primary"
              onClick={searchAndClose}
              id={`${idBase}-button-applyFiltersMobile`}
            >
              Aplicar filtros
            </Button>
          </ModalButtonContainerStyled>
        </ModalContainerStyled>
      </Modal>
    </Fragment>
  )
}

ListFilterMobile.propTypes = {
  config: PropTypes.array,
  secondaryContainerFilterCss: PropTypes.string,
  debounceRequestSearch: PropTypes.func,
  params: PropTypes.object,
  renderElement: PropTypes.func,
  requestSearch: PropTypes.func,
  onFilterModalHandled: PropTypes.func,
  idBase: PropTypes.string
}

export default ListFilterMobile
