var _jsxFileName = 'src/lib/generics/Icons/Icon.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '../Images';
/**
 * Generic Icon which you can customize to get the correct style.
 * */

var BASE_CLASSNAME = 'wig-icon';
var Icon = function Icon(_ref) {
  var _classNames, _classNames2;

  var className = _ref.className,
      dripiconIcon = _ref.dripiconIcon,
      fontAwesomeIcon = _ref.fontAwesomeIcon,
      imageUrl = _ref.imageUrl,
      alt = _ref.alt,
      square = _ref.square,
      semiRounded = _ref.semiRounded,
      circle = _ref.circle,
      gray = _ref.gray,
      stillGray = _ref.stillGray,
      transparent = _ref.transparent,
      primary = _ref.primary,
      secondary = _ref.secondary,
      danger = _ref.danger,
      warning = _ref.warning,
      smallSize = _ref.smallSize,
      mediumSize = _ref.mediumSize,
      largeSize = _ref.largeSize,
      onClick = _ref.onClick,
      white = _ref.white,
      black = _ref.black,
      fitContent = _ref.fitContent,
      iconClassName = _ref.iconClassName;

  var iconWrapperClasses = classNames(className, BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--square', !semiRounded && !circle || square), _defineProperty(_classNames, BASE_CLASSNAME + '--semiRounded', semiRounded), _defineProperty(_classNames, BASE_CLASSNAME + '--circle', circle), _defineProperty(_classNames, BASE_CLASSNAME + '--primary', (!black, !secondary && !gray && !transparent && !stillGray && !danger && !warning && !white) || primary), _defineProperty(_classNames, BASE_CLASSNAME + '--secondary', secondary), _defineProperty(_classNames, BASE_CLASSNAME + '--black', black), _defineProperty(_classNames, BASE_CLASSNAME + '--gray', gray), _defineProperty(_classNames, BASE_CLASSNAME + '--still-gray', stillGray), _defineProperty(_classNames, BASE_CLASSNAME + '--transparent', transparent), _defineProperty(_classNames, BASE_CLASSNAME + '--danger', danger), _defineProperty(_classNames, BASE_CLASSNAME + '--warning', warning), _defineProperty(_classNames, BASE_CLASSNAME + '--white', white), _defineProperty(_classNames, BASE_CLASSNAME + '--small', !mediumSize && !largeSize || smallSize), _defineProperty(_classNames, BASE_CLASSNAME + '--medium', mediumSize), _defineProperty(_classNames, BASE_CLASSNAME + '--large', largeSize), _defineProperty(_classNames, '' + dripiconIcon, dripiconIcon), _defineProperty(_classNames, '' + fontAwesomeIcon, fontAwesomeIcon), _classNames));

  var dripiconIconString = 'dripicons-' + dripiconIcon;
  var fontAwesomeIconString = 'fa fa-' + fontAwesomeIcon;

  var iconClassNames = classNames(iconClassName, BASE_CLASSNAME + '__displayed-icon', (_classNames2 = {}, _defineProperty(_classNames2, '' + dripiconIconString, dripiconIcon), _defineProperty(_classNames2, '' + fontAwesomeIconString, !dripiconIcon && fontAwesomeIcon), _classNames2));

  return React.createElement(
    'span',
    { className: iconWrapperClasses, onClick: onClick, __source: {
        fileName: _jsxFileName,
        lineNumber: 78
      },
      __self: _this
    },
    imageUrl ? React.createElement(Image, {
      className: iconClassNames,
      fitContent: fitContent,
      src: imageUrl,
      alt: alt,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 80
      },
      __self: _this
    }) : React.createElement('i', { className: iconClassNames, __source: {
        fileName: _jsxFileName,
        lineNumber: 87
      },
      __self: _this
    })
  );
};

Icon.defaultProps = {
  dripiconIcon: '',
  fontAwesomeIcon: '',
  imageUrl: '',
  alt: 'icon',
  smallSize: false,
  mediumSize: false,
  largeSize: false,
  className: '',
  gray: false,
  stillGray: false,
  transparent: false,
  primary: false,
  secondary: false,
  danger: false,
  warning: false,
  square: false,
  semiRounded: false,
  circle: false
};

Icon.propTypes = {
  /** When it is not possible to render the image.*/
  alt: PropTypes.string,
  /** */
  black: PropTypes.bool,
  /** Sets a icon which has a border-radius of 100%*/
  circle: PropTypes.bool,
  /** Prefixes the icon which custom classes you passed. */
  className: PropTypes.string,
  /** Sets a icon which bg-color is danger*/
  danger: PropTypes.bool,
  /** DripiconIcon string shown. */
  dripiconIcon: PropTypes.string,
  /** */
  fitContent: PropTypes.bool,
  /** FontAwesomeIcon string shown. */
  fontAwesomeIcon: PropTypes.string,
  /** Sets a icon which bg-color is gray */
  gray: PropTypes.bool,
  /** */
  iconClassName: PropTypes.string,
  /** Image in case you want to displayed and image instead an icon.*/
  imageUrl: PropTypes.string,
  /** largeSize size of the icon. */
  largeSize: PropTypes.bool,
  /** mediumSize size of the icon. */
  mediumSize: PropTypes.bool,
  /** onClick event */
  onClick: PropTypes.func,
  /** Sets a icon which bg-color is primary*/
  primary: PropTypes.bool,
  /** Sets a icon which bg-color is secondary*/
  secondary: PropTypes.bool,
  /** Sets a icon which has a little border-radius*/
  semiRounded: PropTypes.bool,
  /** smallSize size of the icon. */
  smallSize: PropTypes.bool,
  /** Sets a icon which border-radius is none*/
  square: PropTypes.bool,
  /** Sets a icon which bg-color is transparent but color gray and hovered stays transparent bg-colro */
  stillGray: PropTypes.bool,
  /** Sets a icon which bg-color is transparent but hovered bg-color gray*/
  transparent: PropTypes.bool,
  /** Sets a icon which bg-color is warning*/
  warning: PropTypes.bool,
  /** */
  white: PropTypes.bool
};

export default Icon;