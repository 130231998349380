'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var hasValue = function hasValue(key, value) {
  return Object.prototype.hasOwnProperty.call(value, key) && value[key] !== undefined && value[key] !== '' && value[key] !== null && (Array.isArray(value[key]) ? value[key].length > 0 : true);
};
var isMobile = function isMobile() {
  var check = false;
  var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMobile) {
    check = true;
  }
  return check;
};
var debounce = function debounce(func, wait, immediate) {
  var timeout;
  return function executedFunction() {
    var context = this;
    var args = arguments;
    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
function debounceWithFunc(func, wait, immediate) {
  var timeout;
  var executedFunction = function executedFunction() {
    var context = this;
    var args = arguments;
    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
  executedFunction.clear = function () {
    clearTimeout(timeout);
    timeout = null;
  };
  return executedFunction;
}
var getTimeDifference = function getTimeDifference(fromTime) {
  var fromDate = new Date(fromTime);
  var now = new Date();
  var datefromAPITimeStamp = new Date(fromDate).getTime();
  var nowTimeStamp = now.getTime();
  var microSecondsDiff = Math.abs(datefromAPITimeStamp - nowTimeStamp);
  var types = [{
    type: 'segundo',
    formula: 1000,
    max: 60
  }, {
    type: 'minuto',
    formula: 1000 * 60,
    max: 60
  }, {
    type: 'hora',
    formula: 1000 * 60 * 60,
    max: 60
  }, {
    type: 'día',
    formula: 1000 * 60 * 60 * 24,
    max: 24
  }, {
    type: 'semana',
    formula: 1000 * 60 * 60 * 24 * 7,
    max: 4
  }, {
    type: 'month',
    formula: 1000 * 60 * 60 * 24 * 30,
    max: 30
  }, {
    type: 'año',
    formula: 1000 * 60 * 60 * 24 * 365,
    max: 30
  }];
  var differenceText = types.reduce(function (previous, current) {
    var type = current.type,
      formula = current.formula,
      max = current.max;
    var quantity = Math.floor(microSecondsDiff / formula);
    if (quantity > 0 && quantity < max) {
      return "Hace ".concat(quantity, " ").concat(type).concat(quantity > 1 ? 's' : '');
    }
    return previous;
  }, '');
  return differenceText;
};

exports.debounce = debounce;
exports.debounceWithFunc = debounceWithFunc;
exports.getTimeDifference = getTimeDifference;
exports.hasValue = hasValue;
exports.isMobile = isMobile;
