import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Grid, Typography, Select } from 'rc'
import { Button } from 'components'
import { Checkmark } from 'rc/Icons'

const PlanDetailMC = ({
  logo,
  benefits: { title, list = [] } = {},
  HideModal
}) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="space-between"
      css={`
        width: ${({ theme }) => theme.remCalc('450')};
        min-height: ${({ theme }) => theme.remCalc('300')};
        max-height: 100%;
        padding: ${({ theme }) => theme.remCalc('12 24')};
      `}
    >
      <HeaderStyled>
        <img
          src={logo}
          alt="logo"
          css={`
            height: ${({ theme }) => theme.remCalc('45')};
          `}
        />
        <PriceContainerStyled>
          <PriceStyled>
            <Typography
              variant="subtitle3"
              color="palette.primary.main"
              css={`
                font-size: ${({ theme }) => theme.remCalc('12')};
              `}
            >
              desde
            </Typography>
            <Typography
              color="palette.primary.main"
              textTransform="initial"
              variant="h1"
              css={`
                font-size: ${({ theme }) => theme.remCalc('30')};
                margin: ${({ theme }) => theme.remCalc('-9 5 0 0')};
              `}
            >
              $398
            </Typography>
          </PriceStyled>
          <CurrencyStyled>
            <Typography variant="h3" color="palette.primary.main">
              mxn
            </Typography>
            <Typography
              variant="subtitle3"
              color="palette.primary.main"
              textTransform="initial"
              css={`
                font-size: ${({ theme }) => theme.remCalc('12')};
              `}
            >
              al mes
            </Typography>
          </CurrencyStyled>
        </PriceContainerStyled>
      </HeaderStyled>
      <BodyStyled>
        <Select />
        <Typography
          variant="subtitle2"
          css={`
            margin-top: ${({ theme }) => theme.remCalc('12')};
            font-size: ${({ theme }) => theme.remCalc('14')};
            margin-bottom: ${({ theme }) => theme.remCalc('5')};
          `}
        >
          {title}
        </Typography>
        {list.map((item, index) => {
          return (
            <div
              key={index}
              css={`
                display: flex;
                align-items: center;
              `}
            >
              <Checkmark
                css={`
                  height: ${({ theme }) => theme.remCalc('15')};
                  margin-right: ${({ theme }) => theme.remCalc('10')};
                  fill: #0076ff;
                  color: #0076ff;
                `}
              />
              <Typography variant="body2">{item}</Typography>
            </div>
          )
        })}
      </BodyStyled>
      <FooterStyled>
        <ButtonStyled type="tertiary" onClick={HideModal}>
          Cancelar
        </ButtonStyled>
        <ButtonStyled>Elegir plan</ButtonStyled>
      </FooterStyled>
    </Grid>
  )
}

PlanDetailMC.propTypes = {
  benefits: PropTypes.object,
  HideModal: PropTypes.func,
  logo: PropTypes.string
}

const ColumStyled = css`
  display: flex;
  flex-direction: column;
`
const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${({ theme }) => theme.remCalc('58')};
`
const PriceContainerStyled = styled.div`
  display: flex;
  height: 100%;
`
const PriceStyled = styled.div`
  justify-content: flex-start;
  ${ColumStyled}
`
const CurrencyStyled = styled.div`
  justify-content: flex-end;
  ${ColumStyled}
`
const BodyStyled = styled.div`
  width: 100%;
  ${ColumStyled}
  margin: ${({ theme }) => theme.remCalc('12 0')};
`
const FooterStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.remCalc('12')};
`
const ButtonStyled = styled(Button)`
  border-radius: ${({ theme }) => theme.remCalc('20')} !important;
  width: 48%;
`

export default PlanDetailMC
