var _jsxFileName = 'src/lib/elements/Checkboxes/CheckboxImageItem.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '../../generics/Images';
import { Layout, LayoutItem } from '../Layouts';
import { Text } from '../Texts';
import { checkmarkGreenIcon } from '../../utils/icons';
var BASE_CLASSNAME = 'checkbox-image-item';
/** Show a checkbox with an image inside. */
var CheckboxImageItem = function CheckboxImageItem(_ref) {
  var _classNames;

  var className = _ref.className,
      label = _ref.label,
      value = _ref.value,
      isSelected = _ref.isSelected,
      _onClick = _ref.onClick,
      disabled = _ref.disabled,
      imageUrl = _ref.imageUrl,
      onBlur = _ref.onBlur,
      onFocus = _ref.onFocus;

  var checkboxClasses = classNames(className, BASE_CLASSNAME);
  var checkboxContainerClasses = classNames(className, BASE_CLASSNAME + '__container');

  var checkboxWrapperClasses = classNames(BASE_CLASSNAME + '__wrapper', (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '__wrapper--selected', isSelected), _defineProperty(_classNames, BASE_CLASSNAME + '__wrapper--disabled', disabled), _classNames));

  var checkboxLabelClasses = classNames(BASE_CLASSNAME + '__label', _defineProperty({}, BASE_CLASSNAME + '__label--selected', isSelected));

  return React.createElement(
    'button',
    {
      onBlur: !disabled ? function () {
        return onBlur();
      } : null,
      onClick: function onClick() {
        return !disabled && _onClick(value);
      },
      onFocus: !disabled ? function () {
        return onFocus();
      } : null,
      tabIndex: disabled ? '-1' : '0',
      type: 'button',
      className: checkboxClasses,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 37
      },
      __self: _this
    },
    React.createElement(
      Layout,
      { className: checkboxContainerClasses, center: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 45
        },
        __self: _this
      },
      React.createElement(
        Layout,
        { className: checkboxWrapperClasses, center: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 46
          },
          __self: _this
        },
        React.createElement(
          Layout,
          { className: BASE_CLASSNAME + '__img-wrapper', fullHeight: true, __source: {
              fileName: _jsxFileName,
              lineNumber: 47
            },
            __self: _this
          },
          React.createElement(
            LayoutItem,
            { showSmallOnly: true, small: 9, __source: {
                fileName: _jsxFileName,
                lineNumber: 48
              },
              __self: _this
            },
            React.createElement(
              Text,
              { className: checkboxLabelClasses, __source: {
                  fileName: _jsxFileName,
                  lineNumber: 49
                },
                __self: _this
              },
              label
            )
          ),
          React.createElement(Image, {
            noResponsive: true,
            src: imageUrl,
            wrapperClassName: BASE_CLASSNAME + '__img',
            fitContent: true,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 52
            },
            __self: _this
          })
        ),
        isSelected && React.createElement(
          Layout,
          {
            noResponsive: true,
            middle: true,
            center: true,
            fullHeight: true,
            className: BASE_CLASSNAME + '__checkmark-wrapper',
            __source: {
              fileName: _jsxFileName,
              lineNumber: 61
            },
            __self: _this
          },
          React.createElement(Image, {
            wrapperClassName: BASE_CLASSNAME + '__checkmark',
            noResponsive: true,
            src: checkmarkGreenIcon,
            fitContent: true,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 68
            },
            __self: _this
          })
        )
      ),
      React.createElement(
        LayoutItem,
        { hideSmallOnly: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 77
          },
          __self: _this
        },
        React.createElement(
          Text,
          { className: checkboxLabelClasses, __source: {
              fileName: _jsxFileName,
              lineNumber: 78
            },
            __self: _this
          },
          label
        )
      )
    )
  );
};

CheckboxImageItem.propTypes = {
  /**  Handles onBlur action.*/
  onBlur: PropTypes.func.isRequired,
  /**  Handles onFocus action.*/
  onFocus: PropTypes.func.isRequired,
  /** Value passed onClick event. */
  value: PropTypes.any.isRequired,
  /** Custom styles to applied due to class. */
  className: PropTypes.string,
  /** Applies disable stylish. */
  disabled: PropTypes.bool,
  /** Image url that should be displayed. */
  imageUrl: PropTypes.string,
  /** Gives a diferent style. */
  isSelected: PropTypes.bool,
  /** Text to render inside the bubble. */
  label: PropTypes.string,
  /** Handler of click action passing value. */
  onClick: PropTypes.func
};

CheckboxImageItem.defaultProps = {
  disabled: false,
  isSelected: false
};

export default CheckboxImageItem;