var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/Modals/ModalContainer.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Divider from '../General/Divider';
import { CloseButton } from '../Buttons';

var ModalContainer = (_temp2 = _class = function (_Component) {
  _inherits(ModalContainer, _Component);

  function ModalContainer() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ModalContainer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ModalContainer.__proto__ || Object.getPrototypeOf(ModalContainer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      lock: false,
      isOpen: _this.props.isOpen,
      isClosing: false,
      isTransitioning: false
    }, _this.toggleModal = function () {
      var _this$props = _this.props,
          animationDuration = _this$props.animationDuration,
          animationDelay = _this$props.animationDelay,
          noScroll = _this$props.noScroll;
      var lock = _this.state.lock;


      if (!lock) {
        _this.setState(function (prevState) {
          return {
            isTransitioning: true,
            isClosing: _this.state.isOpen
          };
        }, function () {
          setTimeout(function () {
            _this.setState({
              isTransitioning: false,
              isOpen: !_this.state.isOpen,
              isClosing: false
            }, function () {
              if (_this.state.isOpen && noScroll) {
                document.body.classList.add('no-scroll');
              } else {
                document.body.classList.remove('no-scroll');
              }
            });
          }, animationDuration + animationDelay);
        });
      }
    }, _this.closeModal = function () {
      var _this$props2 = _this.props,
          animationDuration = _this$props2.animationDuration,
          animationDelay = _this$props2.animationDelay;
      var lock = _this.state.lock;


      if (!lock) {
        _this.setState(function (prevState) {
          return {
            isTransitioning: true,
            isClosing: true
          };
        }, function () {
          setTimeout(function () {
            _this.setState({
              isTransitioning: false,
              isOpen: false,
              isClosing: false
            }, function () {
              if (_this.state.isOpen) {
                document.body.classList.add('no-scroll');
              } else {
                document.body.classList.remove('no-scroll');
              }
            });
          }, animationDuration + animationDelay);
        });
      }
    }, _this.openModal = function () {
      var _this$props3 = _this.props,
          animationDuration = _this$props3.animationDuration,
          animationDelay = _this$props3.animationDelay;
      var lock = _this.state.lock;


      if (!lock) {
        _this.setState(function (prevState) {
          return {
            isTransitioning: true,
            isClosing: false
          };
        }, function () {
          setTimeout(function () {
            _this.setState({
              isTransitioning: false,
              isOpen: true,
              isClosing: true
            }, function () {
              if (_this.state.isOpen) {
                document.body.classList.add('no-scroll');
              } else {
                document.body.classList.remove('no-scroll');
              }
            });
          }, animationDuration + animationDelay);
        });
      }
    }, _this.lockModal = function () {
      _this.setState({ lock: true });
    }, _this.unlockModal = function () {
      _this.setState({ lock: false });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ModalContainer, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _state = this.state,
          lock = _state.lock,
          isOpen = _state.isOpen,
          isTransitioning = _state.isTransitioning,
          isClosing = _state.isClosing;
      var _props = this.props,
          animationDuration = _props.animationDuration,
          children = _props.children,
          className = _props.className,
          contentClassName = _props.contentClassName,
          description = _props.description,
          flatContent = _props.flatContent,
          handleCloseModal = _props.handleCloseModal,
          noClosingButton = _props.noClosingButton,
          overflow = _props.overflow,
          semiRounded = _props.semiRounded,
          title = _props.title,
          wrapperClassName = _props.wrapperClassName;


      var modalClasses = classNames(className, 'modal-container', 'animated', {
        fadeOut: isTransitioning && isClosing,
        'modal-container--hidden': !isTransitioning && !isOpen,
        'modal-container--shown': isTransitioning
      });

      var overlayClasses = classNames('modal-container__overlay');

      var wrapperClasses = classNames(wrapperClassName, 'modal-container__wrapper');
      var contentClasses = classNames(contentClassName, 'modal-container__content', 'animated', {
        'modal-container__content--hidden': !isOpen,
        pulse: isOpen,
        'modal-container__content--semi-rounded': semiRounded,
        'modal-container__content--normal-padding': !flatContent,
        'modal-container__content--overflow': overflow
      });

      return React.createElement(
        'div',
        { className: modalClasses, style: { animationDuration: animationDuration }, __source: {
            fileName: _jsxFileName,
            lineNumber: 203
          },
          __self: this
        },
        React.createElement('div', {
          className: overlayClasses,
          onClick: function onClick() {
            handleCloseModal ? handleCloseModal() : _this2.toggleModal();
          },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 204
          },
          __self: this
        }),
        React.createElement(
          'div',
          { className: wrapperClasses, __source: {
              fileName: _jsxFileName,
              lineNumber: 210
            },
            __self: this
          },
          React.createElement(
            'div',
            { className: contentClasses, __source: {
                fileName: _jsxFileName,
                lineNumber: 211
              },
              __self: this
            },
            noClosingButton ? '' : !lock ? React.createElement(CloseButton, {
              id: 'modalContainer-button-close',
              onClick: function onClick() {
                handleCloseModal ? handleCloseModal() : _this2.toggleModal();
              },
              large: true,
              className: 'modal-container__close-button',
              __source: {
                fileName: _jsxFileName,
                lineNumber: 215
              },
              __self: this
            }) : '',
            (title || description) && React.createElement(
              Fragment,
              {
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 227
                },
                __self: this
              },
              React.createElement(
                'div',
                { className: 'modal-container__header', __source: {
                    fileName: _jsxFileName,
                    lineNumber: 228
                  },
                  __self: this
                },
                title && React.createElement(
                  'div',
                  { className: 'modal-container__title', __source: {
                      fileName: _jsxFileName,
                      lineNumber: 230
                    },
                    __self: this
                  },
                  title
                ),
                description && React.createElement(
                  'div',
                  { className: 'modal-container__description', __source: {
                      fileName: _jsxFileName,
                      lineNumber: 234
                    },
                    __self: this
                  },
                  description
                )
              ),
              React.createElement(Divider, {
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 239
                },
                __self: this
              })
            ),
            React.createElement(
              'div',
              { className: 'modal-container__main-content', __source: {
                  fileName: _jsxFileName,
                  lineNumber: 242
                },
                __self: this
              },
              children
            )
          )
        )
      );
    }
  }]);

  return ModalContainer;
}(Component), _class.propTypes = {
  /** */
  animationDelay: PropTypes.number,
  /** */
  animationDuration: PropTypes.number,
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  contentClassName: PropTypes.string,
  /** */
  description: PropTypes.string,
  /** */
  flatContent: PropTypes.bool,
  /** */
  handleCloseModal: PropTypes.func,
  /** */
  isOpen: PropTypes.bool,
  /** */
  noClosingButton: PropTypes.bool,
  /** */
  noScroll: PropTypes.bool,
  /** */
  overflow: PropTypes.bool,
  /** */
  semiRounded: PropTypes.bool,
  /** */
  title: PropTypes.string,
  /** */
  wrapperClassName: PropTypes.string
}, _class.defaultProps = {
  isOpen: false,
  animationDuration: 400,
  animationDelay: 0,
  noScroll: true
}, _temp2);


export default ModalContainer;