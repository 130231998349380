var _jsxFileName = 'src/lib/elements/Checkboxes/GalleryItemCheckbox.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Layout } from '../Layouts';
import { Image } from '../../generics/Images';

import { checkmarkGreenIcon } from '../../utils/icons';
var BASE_CLASSNAME = 'gallery-item-checkbox';

/** Used to display an image functioning as a checkbox */
var GalleryItemCheckbox = function GalleryItemCheckbox(_ref) {
  var className = _ref.className,
      value = _ref.value,
      isSelected = _ref.isSelected,
      _onClick = _ref.onClick,
      imageURL = _ref.imageURL,
      baseURL = _ref.baseURL;

  var checkboxContainerClasses = classNames(className, BASE_CLASSNAME);
  return React.createElement(
    Layout,
    {
      onClick: function onClick() {
        _onClick(value);
      },
      className: checkboxContainerClasses,
      middle: true,
      center: true,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 22
      },
      __self: _this
    },
    React.createElement(Image, { noResponsive: true, src: imageURL, baseURL: baseURL, __source: {
        fileName: _jsxFileName,
        lineNumber: 30
      },
      __self: _this
    }),
    isSelected && React.createElement(Image, {
      noResponsive: true,
      src: checkmarkGreenIcon,
      fitContent: true,
      wrapperClassName: BASE_CLASSNAME + '__check-icon',
      __source: {
        fileName: _jsxFileName,
        lineNumber: 32
      },
      __self: _this
    })
  );
};

GalleryItemCheckbox.propTypes = {
  /** Value passed onClick event. */
  value: PropTypes.any.isRequired,
  /** Added as a prefix to image  */
  baseURL: PropTypes.string,
  /** Custom styles to applied due to class. */
  className: PropTypes.string,
  /** URL of the image displayed. */
  imageURL: PropTypes.string,
  /** Renders a checkmark. */
  isSelected: PropTypes.bool,
  /** Handler of click action passing value. */
  onClick: PropTypes.func
};

export default GalleryItemCheckbox;