import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  FormContainer,
  SubmitButton,
  Button,
  TextField,
  Typography,
  Select
} from 'rc'
import { ShowFlashNotification } from 'reduxActions/services'
import { GenetareLeadForPortalPlans } from 'reduxActions/payments'
import { Cross } from 'rc/Icons'
import { isEmail } from 'rc/helpers/stringHelpers'
import { PORTAL_PLANS_TYPES } from 'modules/store/constants'

const FormLeadPlanMC = ({ HideModal, portal, plans, planInfo }) => {
  const dispatch = useDispatch()
  const { fullname, phoneNumber, email } = useSelector(
    ({ profileReducer: { fullname, phoneNumber, email } }) => ({
      fullname,
      phoneNumber,
      email
    })
  )
  const { token } = useSelector(({ authReducer: { token } }) => ({ token }))
  const [isLoading, setIsLoading] = useState(false)
  const [portalSelected, setPortalSelected] = useState(portal)
  const [creditPlans, setCreditPlans] = useState(getCreditPlans(portal))
  const [creditSelected, setCreditSelected] = useState(planInfo.type_id)

  const plansTypes = Object.keys(plans).map(item => ({
    value: item,
    label: PORTAL_PLANS_TYPES[item]
  }))

  const _initForm = {
    name: fullname ? fullname : '',
    email: email ? email : '',
    phone: phoneNumber ? phoneNumber : '',
    portal: portalSelected,
    plan_id: planInfo.type_id
  }

  useEffect(() => {
    setCreditPlans(getCreditPlans(portalSelected))
  }, [portalSelected])

  function getCreditPlans(key) {
    return plans[key].map(item => ({
      value: item.type_id,
      label: item.type_description
    }))
  }

  const validateHandler = field => value => {
    switch (field) {
      case 'fullname':
        return value === '' ? 'Campo requerido.' : null
      case 'email':
        return value === ''
          ? 'Campo requerido.'
          : !isEmail(value)
          ? 'Ingresa un correo válido.'
          : null
      case 'phone':
        if (value) {
          const PhoneRegex = /^((\+?[0-9]{2})?[0-9]{10})$/gm
          const res = PhoneRegex.exec(value) === null
          return res ? 'Ingresa un teléfono válido.' : null
        }
        return 'Campo requerido.'
      default:
        return null
    }
  }

  const handleSubmit = async data => {
    let errors = {}

    Object.keys(data).forEach(key => {
      let res = validateHandler(key)(data[key])
      if (res) errors[key] = res
    })

    if (Object.keys(errors).length > 0) {
      const errObj = new Error()
      errObj.code = 'local-errors'
      errObj.metadata = errors
      throw errObj
    }

    setIsLoading(true)
    await dispatch(GenetareLeadForPortalPlans(token, data)).then(_ =>
      handleSubmitSuccess()
    )
  }

  const handleSubmitSuccess = () => {
    dispatch(
      ShowFlashNotification(
        'success',
        'El equipo de comercial te contactará en breve.'
      )
    )
    HideModal()
  }

  const handleSubmitFail = (localErrors, fc, submitErrors) => {
    setIsLoading(false)
    if (Object.keys(localErrors).length === 0 && submitErrors) {
      if (submitErrors.code === 'local-errors') {
        fc.setCustomErrors({
          ...submitErrors.metadata
        })
      }
    }

    dispatch(
      ShowFlashNotification(
        'danger',
        'Ocurrió un error. Asegurese de llenar todos los datos e intente de nuevo.'
      )
    )
  }

  const onChangeFilters = ({ target: { name, value } = {} }) => {
    if (name === 'portal') {
      setPortalSelected(value)
      setDefaultCreditPlan(value)
    } else {
      if (value) {
        setCreditSelected(value)
      } else {
        setDefaultCreditPlan(portalSelected)
      }
    }
  }

  const setDefaultCreditPlan = newPortal => {
    const newCreditPlans = getCreditPlans(newPortal)

    if (portalSelected === portal) {
      setCreditSelected(planInfo.type_id)
    } else {
      const first = 0
      setCreditSelected(newCreditPlans[first].value)
    }
  }

  return (
    <Container
      name="portal_plans_quotation"
      initialValues={_initForm}
      onSubmit={handleSubmit}
      onSubmitSuccess={handleSubmitSuccess}
      onSubmitFail={handleSubmitFail}
    >
      <CustomCloseButton onClick={HideModal}>
        <CrossIconStyled />
      </CustomCloseButton>
      <Typography
        variant="h3"
        textTransform="initial"
        color="palette.primary.main"
        display="block"
        align="center"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        Completa la información y nos contactaremos contigo para brindarte el
        mejor servicio.
      </Typography>
      <FieldsContainer>
        <Select
          placeholder="Portal"
          css={``}
          name="portal"
          label="Portal"
          optionLabelKey="label"
          optionValueKey="value"
          options={plansTypes}
          onChange={onChangeFilters}
          value={portalSelected}
          required
        />
        <Select
          placeholder="Plan"
          css={``}
          name="plan_id"
          label="Plan"
          optionLabelKey="label"
          optionValueKey="value"
          options={creditPlans}
          onChange={onChangeFilters}
          value={creditSelected}
          required
        />
        <TextField
          name="name"
          label="Nombre completo"
          placeholder="Nombre completo"
          validate={validateHandler('name')}
          required
        />
        <TextField
          name="email"
          label="Correo"
          placeholder="Correo"
          validate={validateHandler('email')}
          required
        />
        <TextField
          name="phone"
          label="Teléfono"
          placeholder="Teléfono"
          validate={validateHandler('phone')}
          required
        />
      </FieldsContainer>
      <ActionsContainer>
        <Button
          size="large"
          variant="outlined"
          color="gray"
          onClick={HideModal}
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <SubmitButton disabled={isLoading} size="large">
          Enviar
        </SubmitButton>
      </ActionsContainer>
    </Container>
  )
}

FormLeadPlanMC.propTypes = {
  HideModal: PropTypes.func,
  action: PropTypes.func,
  planInfo: PropTypes.object
}

const Container = styled(FormContainer)`
  width: ${({ theme }) => theme.remCalc('450')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.remCalc('40')};
  border-radius: ${({ theme }) => theme.remCalc('20')};
  position: relative;
  ${({ theme }) => theme.media.tabletDown`
    width:100%;
  `}
`
const CustomCloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.remCalc('12')};
  right: ${({ theme }) => theme.remCalc('12')};
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
`
const CrossIconStyled = styled(Cross)`
  ${({ theme }) => theme.box(24, 24)}
  fill: #A0A3BD;
`
const FieldsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  > * {
    margin: ${({ theme }) => theme.remCalc('10 0 20 0')};
    width: 100%;
  }
`
const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.remCalc('10')};
  > button {
    width: 48%;
    border-radius: 30px !important;
  }
`

export default FormLeadPlanMC
