import React from 'react'
import styled from 'styled-components'
import { Typography } from 'rc'
import Image from 'static/img/wiggot-404.svg'

function EmptyPage() {
  return (
    <Container>
      <InfoContainer>
        <Typography variant="h1" color="theme.palette.primary.main">
          Ooops…
        </Typography>
        <br />
        <Typography
          variant="custom"
          component="h6"
          css={`
            ${({ theme }) =>
              theme.typography.buildTextVariant({
                ...theme.typography.subtitle1,
                color: theme.palette.secondary.main
              })}
            ${({ theme }) => theme.media.desktop`
              ${({ theme }) =>
                theme.typography.buildTextVariant({
                  ...theme.typography.h1,
                  color: theme.palette.secondary.main
                })}
              `}
          `}
        >
          La página que buscas no está disponible
        </Typography>
      </InfoContainer>
      <HouseContainer>
        <img src={Image} alt="404" />
      </HouseContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  > div {
    height: 100%;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${({ theme }) => theme.remCalc('600')};
  margin-left: ${({ theme }) => theme.remCalc('80')};
`

const HouseContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  > img {
    height: 100%;
    width: 100%;
  }
`

export default EmptyPage
