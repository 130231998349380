import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Block, Layout, LayoutItem } from 'c3-ui/elements/Layouts'
import Divider from 'c3-ui/elements/General/Divider'
import { TextInline } from 'c3-ui/elements/Texts'
import { MainLink } from 'c3-ui/elements/Links'
import { useSelector } from 'react-redux'

const Footer = ({ extended, extraTopMargin }) => {
  const footerClasses = classNames('footer', {
    'footer--extra-top-margin': extraTopMargin
  })

  const year = useRef(new Date().getFullYear())

  const theme = useSelector(({ authReducer: { theme } }) => theme)

  return (
    <Layout className={footerClasses}>
      {extended && (
        <Layout className="footer__extended" justify>
          <LayoutItem mediumShrink>
            <Block>
              {' '}
              <MainLink
                href="https://www.wiggot.com"
                id="general-footer-link-aboutWiggot"
              >
                {`Sobre Wiggot >`}{' '}
              </MainLink>
            </Block>
            <Block>
              {' '}
              <MainLink
                route="/properties"
                id="general-footer-link-aboutProperties"
              >
                {`Sobre Propiedades >`}{' '}
              </MainLink>
            </Block>
          </LayoutItem>
          <LayoutItem mediumShrink>
            <Block>{`Contactanos`}</Block>
            <Block>
              <MainLink
                href="tel:+523324690493"
                id="general-footer-link-phoneNumber"
              >
                {`+52(33)24690493`}{' '}
              </MainLink>
            </Block>
            <Block>
              <MainLink
                href="mailto:hola@wiggot.com"
                id="general-footer-link-email"
              >
                {`hola@wiggot.com`}{' '}
              </MainLink>
            </Block>
          </LayoutItem>
        </Layout>
      )}
      <Layout className="footer__simple" center>
        <Divider className="footer__divider" />
        <LayoutItem>
          <Layout center middle className="footer__main-content">
            {theme === 'LOCAL' ? (
              <TextInline shrink>Wiggot © {year.current} </TextInline>
            ) : null}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_TERMS_URL}
              id="general-footer-link-termsAndConditions"
            >
              Términos y Condiciones{' '}
            </a>
            <MainLink
              shrink
              route="/legal/privacy"
              id="general-footer-link-privacyPolicies"
            >
              Aviso de Privacidad{' '}
            </MainLink>
          </Layout>
        </LayoutItem>
      </Layout>
    </Layout>
  )
}

Footer.propTypes = {
  extended: PropTypes.bool,
  extraTopMargin: PropTypes.bool
}
export default Footer
