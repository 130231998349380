var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/Autocompletes/AutocompleteSearcher.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { FeaturedInputView } from '../../generics/FormInputViews';

var AutocompleteSearcher = (_temp2 = _class = function (_Component) {
  _inherits(AutocompleteSearcher, _Component);

  function AutocompleteSearcher() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, AutocompleteSearcher);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AutocompleteSearcher.__proto__ || Object.getPrototypeOf(AutocompleteSearcher)).call.apply(_ref, [this].concat(args))), _this), _this.BASE_CLASSNAME = 'autocomplete-searcher', _this.state = {
      isListOpened: false,
      selectedValue: _this.props.selectedValue || '',
      searchingValue: '',
      fullList: _this.props.list || [],
      filteredList: _this.props.list || []
    }, _this.handleOutsideClick = function (e) {
      if (_this._autocomplete) {
        if (_this._autocomplete.contains(e.target)) {
          return;
        }
      }
      _this.toggleSearcherContent();
    }, _this.toggleSearcherContent = function () {
      if (!_this.state.isListOpened) {
        document.addEventListener('click', _this.handleOutsideClick, false);
      } else {
        document.removeEventListener('click', _this.handleOutsideClick, false);
      }
      _this.setState(function (prevState) {
        var isListOpened = !prevState.isListOpened;
        var filteredList = _this.state.fullList;

        return {
          isListOpened: isListOpened,
          filteredList: filteredList,
          searchingValue: ''
        };
      }, function () {
        if (_this._input) {
          _this._input.focus();
        }
      });
    }, _this.onChange = function (e) {
      var action = _this.props.action;
      var searchingValue = e.target.value;

      if (action) {
        _this.setState({
          searchingValue: searchingValue
        }, function () {
          action(searchingValue).then(function () {
            var response = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
            var formatMethod = _this.props.formatMethod;

            var data = response;

            var dataFormatted = data.map(function (item) {
              return formatMethod ? formatMethod(item) : item;
            });

            _this.setState({
              filteredList: dataFormatted
            });
          });
        });
      } else {
        _this.setState({
          searchingValue: searchingValue,
          filteredList: _this.getFilteredListByString(searchingValue)
        });
      }
    }, _this.getFilteredListByString = function (searchingValue) {
      var fullList = _this.state.fullList;
      var valueKey = _this.props.valueKey;

      var filteredList = fullList;
      if (searchingValue.length > 0) {
        filteredList = fullList.filter(function (item) {
          var value = item['' + valueKey];
          var valueToSearch = searchingValue;
          if (value) {
            value = value.toLowerCase();
          }

          if (searchingValue) {
            valueToSearch = valueToSearch.toLowerCase();
          }
          return value.indexOf(valueToSearch) > -1;
        });
      }
      return filteredList;
    }, _this.handleSelection = function (item) {
      var _this$props = _this.props,
          valueShownKey = _this$props.valueShownKey,
          onSelectedItem = _this$props.onSelectedItem,
          renderValue = _this$props.renderValue;


      _this.toggleSearcherContent();
      if (_.isEmpty(item)) {
        return;
      }
      _this.setState({
        originalSelectedValue: item,
        selectedValue: renderValue ? renderValue(item) : valueShownKey ? item['' + valueShownKey] : ''
      });
      if (onSelectedItem) {
        onSelectedItem(item);
      }
    }, _this.onKeyDown = function (e) {
      var _e$keyCode = e.keyCode,
          keyCode = _e$keyCode === undefined ? '' : _e$keyCode;


      switch (keyCode) {
        case 13:
          _this.handleSelection(_.first(_this.state.filteredList));
          break;

        default:
          break;
      }
    }, _this.clearSelection = function () {
      var onSelectedItem = _this.props.onSelectedItem;

      _this.setState({
        selectedValue: ''
      }, function () {
        onSelectedItem(_this.state.selectedValue);
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(AutocompleteSearcher, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _state = this.state,
          isListOpened = _state.isListOpened,
          searchingValue = _state.searchingValue,
          filteredList = _state.filteredList,
          originalSelectedValue = _state.originalSelectedValue,
          selectedValueState = _state.selectedValue;
      var _props = this.props,
          forceOpened = _props.forceOpened,
          valueShownKey = _props.valueShownKey,
          disabled = _props.disabled,
          placeholder = _props.placeholder,
          selectedValue = _props.selectedValue,
          renderValue = _props.renderValue;


      return React.createElement(
        'div',
        {
          className: this.BASE_CLASSNAME,
          ref: function ref(node) {
            _this2._autocomplete = node;
          },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 212
          },
          __self: this
        },
        React.createElement(FeaturedInputView, {
          rightDripiconIcon: isListOpened || forceOpened ? 'chevron-up' : 'chevron-down',
          reserved: true,
          onClick: this.toggleSearcherContent,
          placeholder: placeholder,
          value: selectedValue || selectedValueState,
          disabled: disabled,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 218
          },
          __self: this
        }),
        (isListOpened || forceOpened) && React.createElement(
          'div',
          { className: this.BASE_CLASSNAME + '__secondary-content', __source: {
              fileName: _jsxFileName,
              lineNumber: 230
            },
            __self: this
          },
          React.createElement(FeaturedInputView, {
            onInputMounted: function onInputMounted(node) {
              _this2._input = node;
            },
            className: this.BASE_CLASSNAME + '__input',
            type: 'text',
            onChange: this.onChange,
            value: searchingValue,
            rightDripiconIcon: 'search',
            onKeyDown: this.onKeyDown,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 231
            },
            __self: this
          }),
          React.createElement(
            'div',
            { className: this.BASE_CLASSNAME + '__suggestions-list', __source: {
                fileName: _jsxFileName,
                lineNumber: 243
              },
              __self: this
            },
            filteredList && filteredList.length > 0 ? filteredList.map(function (item, key) {
              return React.createElement(
                'div',
                {
                  key: key,
                  onClick: function onClick() {
                    return _this2.handleSelection(item);
                  },
                  className: _this2.BASE_CLASSNAME + '__suggestions-item ' + (item === originalSelectedValue ? _this2.BASE_CLASSNAME + '__suggestions-item--selected' : ''),
                  __source: {
                    fileName: _jsxFileName,
                    lineNumber: 247
                  },
                  __self: _this2
                },
                valueShownKey ? item['' + valueShownKey] : renderValue ? renderValue(item) : ''
              );
            }) : []
          )
        )
      );
    }
  }], [{
    key: 'getDerivedStateFromProps',
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (!_.isEqual(prevState.fullList, nextProps.list)) {
        return Object.assign({}, prevState, {
          fullList: nextProps.list,
          filteredList: nextProps.list
        });
      }
      return null;
    }
  }]);

  return AutocompleteSearcher;
}(Component), _class.propTypes = {
  /** Attribute of object to be searched. */
  valueKey: PropTypes.string.isRequired,
  /** Attribute of object to be shown. */
  valueShownKey: PropTypes.string.isRequired,
  /** Promise used to fetch according to value written. */
  action: PropTypes.func,
  /** In case you want to disable the autocomplete. */
  disabled: PropTypes.bool,
  /** Flag to initialize the autocomplete in an opened state. */
  forceOpened: PropTypes.bool,
  /** Method to format the response in case of an action is passed. */
  formatMethod: PropTypes.func,
  /** Elements to render in list. */
  list: PropTypes.arrayOf(PropTypes.object),
  /** Callback when item is selected. */
  onSelectedItem: PropTypes.func,
  /** Used on the top in order to identify what to do. */
  placeholder: PropTypes.string,
  /** Function that renders the value */
  renderValue: PropTypes.func,
  /** Selected Value to be shown on first input. */
  selectedValue: PropTypes.string
}, _class.defaultProps = {
  placeholder: 'Escribe ...',
  selectedValue: '',
  forceOpened: false,
  disabled: false
}, _temp2);


export default AutocompleteSearcher;