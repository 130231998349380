'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var STATE = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};
var ACTIONS = {
  INIT: 'INIT',
  INIT_SELECTED: 'INIT_SELECTED',
  END_REACHED: 'END_REACHED',
  NEXT_PAGE: 'NEXT_PAGE',
  SORT_COLUMN: 'SORT_COLUMN',
  FILTER_CHANGE: 'FILTER_CHANGE',
  SEARCH: 'SEARCH',
  ITEM_SELECT: 'ITEM_SELECT',
  ITEM_IGNORE: 'ITEM_IGNORE',
  ALL_ITEM_SELECT: 'ALL_ITEM_SELECT',
  ADD_DATA: 'ADD_DATA',
  EDIT_DATA: 'EDIT_DATA',
  UPDATE_DATA: 'UPDATE_DATA',
  DELETE_DATA: 'DELETE_DATA',
  RESET_TABLE: 'RESET_DATA',
  INITIALIZE_TABLE: 'INITIALIZE_TABLE',
  REGISTER_FILTERS: 'REGISTER_FILTERS'
};
var FILTER_TYPES = {
  SEARCH: 'SEARCH',
  ASYNC_DROPDOWN: 'ASYNC_DROPDOWN',
  DROPDOWN: 'DROPDOWN',
  NESTED_DROPDOWN: 'NESTED_DROPDOWN',
  CUSTOM: 'CUSTOM'
};
var FILTER_BEHAVIOUR_TYPES = {
  PASIVE: 'PASIVE',
  ACTIVE: 'ACTIVE',
  ACTIVE_DEBOUNCED: 'ACTIVE_DEBOUNCED'
};
var COLUMN_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  HTML: 'html',
  ACTIONS: 'actions'
};
var COLUMN_SORT_ORDER = {
  ASCENDANT: 'ASCENDANT',
  DESCENDANT: 'DESCENDANT'
};
var ROW_VERTICAL_SEPARATOR = 20;
var ROW_HEIGHT = 70;
var ROW_TOTAL = ROW_HEIGHT + ROW_VERTICAL_SEPARATOR;

exports.ACTIONS = ACTIONS;
exports.COLUMN_SORT_ORDER = COLUMN_SORT_ORDER;
exports.COLUMN_TYPES = COLUMN_TYPES;
exports.FILTER_BEHAVIOUR_TYPES = FILTER_BEHAVIOUR_TYPES;
exports.FILTER_TYPES = FILTER_TYPES;
exports.ROW_HEIGHT = ROW_HEIGHT;
exports.ROW_TOTAL = ROW_TOTAL;
exports.ROW_VERTICAL_SEPARATOR = ROW_VERTICAL_SEPARATOR;
exports.STATE = STATE;
