import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'rc'
import { Checkmark, Cross } from 'rc/Icons'
import { Button } from 'components'
import BuyTokensFormMC from './_BuyTokensFormMC'
import WiggotDevelopment from 'static/logos/wiggot-development.svg'
import Star from 'static/img/Star.svg'
import Elite from 'static/img/Elite.svg'

//Added for Jorge Mena
import { ShowModal } from 'reduxActions/services'
import { useDispatch } from 'react-redux'
import { BuyTokensFormMobile } from 'modules/store/modalContent'
//End

const BuyTokensMC = ({ HideModal, location }) => {
  const [isBuying, setIsBuying] = useState(false)

  //Added for Jorge Mena
  const dispatch = useDispatch()
  //End

  const premiumBenefits = [
    'Acceso a la bolsa inmobiliaria',
    'Carga de desarrollos y modelos',
    'Permisos: administrador y editor',
    'Visibilidad y mayor exposición en el buscador de desarrollos',
    'Reportes (fichas compartidas, búsquedas y matches)',
    'Desarrollo en sitio web inmobiliario (*solo para cuentas de inmobiliaria)',
    'Integración con I24',
    'Reportes leads I24'
  ]

  const eliteBenefits = [
    'Sitio web para cada desarrollo',
    'Manejo de unidades',
    'Planes de financiamiento',
    'Webinar para exponer tus desarrollos a los asesores Wiggot',
    'Promoción por notificaciones/mailing a nuestra red de asesores'
  ]

  const Benefits = ({ children }) => {
    return (
      <div
        css={`
          width: 100%;
          display: flex;
        `}
      >
        <Checkmark
          css={`
            color: #00ba88;
            margin-right: ${({ theme }) => theme.remCalc('5')};
            ${({ theme }) => theme.box(17, 20)}
            min-width: ${({ theme }) => theme.remCalc('17')};
          `}
        />
        <Typography variant="body3" color="palette.new.text">
          {children}
        </Typography>
      </div>
    )
  }

  //Added for Jorge Mena
  const showModalFormMobile = () => {
    dispatch(ShowModal(BuyTokensFormMobile))
  }
  //End

  const NotIncluded = ({ children }) => {
    return (
      <div
        css={`
          width: 100%;
          display: flex;
        `}
      >
        <Cross
          css={`
            fill: red;
            margin-right: ${({ theme }) => theme.remCalc('5')};
            ${({ theme }) => theme.box(17, 20)}
            min-width: ${({ theme }) => theme.remCalc('17')};
          `}
        />
        <Typography variant="body3" color="palette.new.text">
          {children}
        </Typography>
      </div>
    )
  }

  return isBuying ? (
    <BuyTokensFormMC HideModal={HideModal} location={location} />
  ) : (
    <Container>
      <Header>
        <img
          src={WiggotDevelopment}
          alt="Wiggot development"
          css={`
            margin-right: ${({ theme }) => theme.remCalc('20')};
          `}
        />
        <Typography variant="subtitle1">
          Hazte premium y disfruta <br /> de herramientas únicas para ti:
        </Typography>
      </Header>
      <Body>
        <BenefitsCard>
          <BenefitsList>
            <Typography variant="subtitle1" align="center">
              Desarrollo Free:
            </Typography>
            <div
              css={`
                width: 100%;
                display: flex;
                margin-top: ${({ theme }) => theme.remCalc('10')};
              `}
            >
              <Checkmark
                css={`
                  color: #00ba88;
                  margin-right: ${({ theme }) => theme.remCalc('5')};
                  ${({ theme }) => theme.box(30, 20)}
                `}
              />
              <Typography variant="body3" color="palette.new.text">
                Carga de desarrollos y modelos con fines administrativos.
              </Typography>
            </div>
            <div
              css={`
                width: 100%;
                display: flex;
                margin-top: ${({ theme }) => theme.remCalc('10')};
              `}
            >
              <Checkmark
                css={`
                  color: #00ba88;
                  margin-right: ${({ theme }) => theme.remCalc('5')};
                  ${({ theme }) => theme.box(30, 20)}
                `}
              />
              <Typography variant="body3" color="palette.new.text">
                Envío de fichas técnicas de desarrollos.
              </Typography>
            </div>
          </BenefitsList>
          <ActionContainer>
            <Typography variant="subtitle1" align="center">
              Sin costo
            </Typography>
            <Button
              type="tertiary"
              onClick={HideModal}
              rounded
              css={`
                font-size: ${({ theme }) => theme.remCalc('16')} !important;
                color: ${({ theme }) => theme.palette.new.text};
              `}
            >
              Continuar sin beneficios extra
            </Button>
          </ActionContainer>
        </BenefitsCard>
        <BenefitsCard large>
          <BenefitsList>
            <div
              css={`
                display: flex;
                align-items: center;
                margin-bottom: ${({ theme }) => theme.remCalc('10')};
              `}
            >
              <div
                css={`
                  background-color: ${({ theme }) => theme.palette.prime.dark};
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-width: ${({ theme }) => theme.remCalc('38')};
                  height: 95%;
                  border-radius: ${({ theme }) => theme.remCalc('5')};
                  box-shadow: 0px 4px 10px rgba(160, 163, 189, 0.5);
                  margin-right: ${({ theme }) => theme.remCalc('10')};
                  ${({ theme }) => theme.media.tabletDown`
                  width:38px;
                  height:20px;
                  `}
                `}
              >
                <img src={Star} alt="star-icon" />
              </div>
              <Typography variant="subtitle3">Desarrollo Premium</Typography>
            </div>
            {premiumBenefits.map((b, index) => (
              <Benefits key={index}>{b}</Benefits>
            ))}
            {eliteBenefits.map((b, index) => (
              <NotIncluded key={index}>{b}</NotIncluded>
            ))}
          </BenefitsList>
          <ActionContainer
            css={`
              height: ${({ theme }) => theme.remCalc('110')};
            `}
          >
            <div />
            {/* <div
              css={`
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <Typography variant="body2">desde</Typography>
              <Typography
                variant="h1"
                css={`
                  margin: ${({ theme }) => theme.remCalc('0 3 0 5')};
                `}
              >
                $79
              </Typography>
              <Typography variant="body2">/mes</Typography>
            </div> */}
            <Button
              type="prime"
              rounded
              onClick={() => {
                location === 'store' ? showModalFormMobile() : setIsBuying(true)
              }}
            >
              <img
                src={Star}
                alt="star-icon"
                css={`
                  margin-right: ${({ theme }) => theme.remCalc('5')};
                  ${({ theme }) => theme.box(15, 15)}
                `}
              />
              Convertir a premium
            </Button>
          </ActionContainer>
        </BenefitsCard>
        <BenefitsCard large>
          <div
            css={`
              padding: ${({ theme }) => theme.remCalc('5')};
              background-color: ${({ theme }) => theme.palette.primary.main};
              color: #fff;
              position: absolute;
              top: -15px;
              border-radius: ${({ theme }) => theme.remCalc('20')};
              font-size: ${({ theme }) => theme.remCalc('12')};
              ${({ theme }) => theme.media.tabletDown`
              width:60%;
              text-align:center;
              top: -20px;
              padding-top:6px !important;
              `}
            `}
          >
            ¡Mayores beneficios!
          </div>
          <BenefitsList>
            <div
              css={`
                display: flex;
                align-items: center;
                margin-bottom: ${({ theme }) => theme.remCalc('10')};
              `}
            >
              <div
                css={`
                  background-color: ${({ theme }) => theme.palette.elite.main};
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-width: ${({ theme }) => theme.remCalc('38')};
                  height: 95%;
                  border-radius: ${({ theme }) => theme.remCalc('5')};
                  box-shadow: 0px 4px 10px rgba(160, 163, 189, 0.5);
                  margin-right: ${({ theme }) => theme.remCalc('10')};
                `}
              >
                <img
                  src={Elite}
                  css={`
                    width: ${({ theme }) => theme.remCalc('20')};
                  `}
                  alt="elite-icon"
                />
              </div>
              <Typography variant="subtitle3">Desarrollo Elite</Typography>
            </div>
            {premiumBenefits.map((b, index) => (
              <Benefits key={index}>{b}</Benefits>
            ))}
            {eliteBenefits.map((b, index) => (
              <Benefits key={index}>{b}</Benefits>
            ))}
          </BenefitsList>
          <ActionContainer
            css={`
              height: ${({ theme }) => theme.remCalc('110')};
            `}
          >
            <div />
            <Button
              type="elite"
              rounded
              onClick={() => {
                location === 'store' ? showModalFormMobile() : setIsBuying(true)
              }}
            >
              <img
                src={Elite}
                alt="elite-icon"
                css={`
                  margin-right: ${({ theme }) => theme.remCalc('5')};
                  ${({ theme }) => theme.box(20, 20)}
                `}
              />
              Convertir a elite
            </Button>
          </ActionContainer>
        </BenefitsCard>
      </Body>
      <Typography variant="body3" align="center">
        * Todos nuestros precios son con IVA incluido, en moneda nacional.
      </Typography>
    </Container>
  )
}

BuyTokensMC.propTypes = {
  children: PropTypes.any,
  HideModal: PropTypes.func
}

const Container = styled.div`
  width: ${({ theme }) => theme.remCalc('850')};
  height: ${({ theme }) => theme.remCalc('600')};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.remCalc('12')};
  ${({ theme }) => theme.media.tabletDown`
    width:100%;
    height:100%;
  `}
`
const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.remCalc('80')};
  background-color: #fff;
  ${({ theme }) => theme.media.tabletDown`
  display:none;
  `}
`
const Body = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  background-color: #fff;
  ${({ theme }) => theme.media.tabletDown`
    justify-content: start;
    padding-top:35px;
    background-color: transparent;
  `}
`
const BenefitsCard = styled.div`
  width: ${({ theme }) => theme.remCalc('260')};
  height: ${({ large }) => (large ? '97%' : '97%')};
  background-color: #fff;
  border-radius: ${({ theme }) => theme.remCalc('10')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.remCalc('12')};
  ${({ large, theme }) =>
    large &&
    `
    border: 1px solid ${theme.palette.primary.main};
    box-shadow: 0px 4px 10px rgba(160, 163, 189, 0.5);
    position: relative;
  `}
  ${({ theme }) => theme.media.tabletDown`
    border: 2px solid #E8ECF9;
    border-radius: 20px;
    box-shadow: 0px 0px 0px ;
    position: relative;
  `}
`
const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ theme }) => theme.remCalc('100')};
  width: 100%;
`
const BenefitsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`
export default BuyTokensMC
