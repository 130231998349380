import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import WiggotPrimeLogo from 'static/logos/wiggot-prime-white.svg'
import SuccessPrime from 'static/drawings/success-prime.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: ${({ theme }) => theme.remCalc('10')};
`

const Title = styled.p`
  color: white;
  font-size: ${({ theme }) => theme.remCalc('25')};
  margin-bottom: ${({ theme }) => theme.remCalc('13')};
  line-height: ${({ theme }) => theme.remCalc('31')};
`

const Intro = props => {
  return (
    <Container className={props.className}>
      <Title>
        <b>Comienza hoy mismo a usar las herramientas de</b>
      </Title>
      <img src={WiggotPrimeLogo} alt="Wiggot Prime" />
      <img src={SuccessPrime} alt="Wiggot Prime" />
    </Container>
  )
}

Intro.propTypes = {
  className: PropTypes.string
}

export default Intro
