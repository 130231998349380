var _jsxFileName = 'src/lib/elements/Layouts/PageContent.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout, Container } from './';

var PageContent = function PageContent(_ref) {
  var className = _ref.className,
      children = _ref.children,
      semiWhite = _ref.semiWhite,
      withTopLayout = _ref.withTopLayout;

  var pageContentClasses = classNames(className, {
    'page-content--with-page-top-layout': withTopLayout,
    'page-content--full-page': !withTopLayout,
    'page-content--white': !semiWhite
  });
  return React.createElement(
    Layout,
    { className: pageContentClasses, vertical: true, __source: {
        fileName: _jsxFileName,
        lineNumber: 13
      },
      __self: _this
    },
    React.createElement(
      Container,
      {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 14
        },
        __self: _this
      },
      children
    )
  );
};

PageContent.propTypes = {
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  semiWhite: PropTypes.bool,
  /** */
  withTopLayout: PropTypes.bool
};

export default PageContent;