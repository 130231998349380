var _jsxFileName = 'src/lib/elements/Texts/FormTitle.js',
    _this = this;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { HighlightedText } from './';
var FormTitle = function FormTitle(_ref) {
  var text = _ref.children,
      props = _objectWithoutProperties(_ref, ['children']);

  return React.createElement(
    HighlightedText,
    { className: 'form-title', __source: {
        fileName: _jsxFileName,
        lineNumber: 5
      },
      __self: _this
    },
    text
  );
};

FormTitle.propTypes = {
  /** */
  children: PropTypes.string
};

export default FormTitle;