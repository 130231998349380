import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ShowModal, ShowFlashNotification } from 'reduxActions/services'
import { Typography, Select, Spinner } from 'rc'
import { Location, BarCode, Price } from 'rc/Icons'
import { AssignTokenToDevelopment } from 'reduxActions/tokens'
import { GetDevelopmentList } from 'reduxActions/development'
import { Button, Image } from 'components'
import { formatPrice } from 'utils/helpers'
import AssignTokenConfirmationMC from './_AssignTokenConfirmationMC'
import Star from 'static/img/Star.svg'
import Elite from 'static/img/Elite.svg'

const AssignTokenMC = ({
  cb,
  HideModal,
  token,
  tokenSummary,
  token_id,
  type,
  development_id,
  developments: developmentsArr = [],
  buyTokens,
  fromDevelopment
}) => {
  const dispatch = useDispatch()
  const { tokens = [] } = useSelector(({ tokensReducer: { tokens } }) => ({
    tokens
  }))
  const [tokenType, setTokenType] = useState('premium')
  const [value, setValue] = useState(development_id || '')
  const [isLoading, setIsLoading] = useState(false)
  const [developments, setDevelopments] = useState([])
  const [development, setDev] = useState({})

  const renderTokenCount = () => {
    if (fromDevelopment) {
      const premiumArr =
        (tokens && tokens.filter(item => item.type === tokenType)) || []
      const assigned =
        (premiumArr &&
          premiumArr.filter(
            item => item.development_id && item.type === tokenType
          )) ||
        []
      return `${premiumArr.length - assigned.length}/${premiumArr.length}`
    }

    if (tokenType === 'elite') {
      const eliteArr = tokenSummary.eliteArr || []
      const assigned =
        (eliteArr && eliteArr.filter(item => item.assigned)) || []
      return `${eliteArr.length - assigned.length}/${tokenSummary[tokenType]}`
    }

    const premiumArr = tokenSummary.premiumArr || []
    const assigned =
      (premiumArr && premiumArr.filter(item => item.assigned)) || []
    return `${premiumArr.length - assigned.length}/${tokenSummary[tokenType]}`
  }

  const getDevelopmentData = (data = []) => {
    const developmentData =
      (value && developmentsArr && developmentsArr.length > 0
        ? developmentsArr.filter(dev => dev.development_id === value)
        : data.filter(dev => dev.development_id === value)) || []
    const development = developmentData[0] || {}
    const operationTypes = [
      {
        value: formatPrice(development.min_presale_price),
        label: 'Preventa:',
        active: development.has_presale
      },
      {
        value: formatPrice(development.min_sale_price),
        label: 'Venta:',
        active: development.has_sale
      },
      {
        value: formatPrice(development.min_lease_price),
        label: 'Renta:',
        active: development.has_lease
      }
    ]
    setIsLoading(false)
    return { ...development, operationTypes }
  }

  useEffect(() => {
    if (developmentsArr && developmentsArr.length > 0) {
      setValue(developmentsArr[0].development_id)
    }
  }, [developmentsArr])

  useEffect(() => {
    setIsLoading(true)
    dispatch(GetDevelopmentList(token, true)).then(res => {
      const { payload: { data } = {} } = res
      setIsLoading(false)
      data && setDevelopments(data)
    })
  }, [token])

  useEffect(() => {
    if (value) {
      setDev(getDevelopmentData(developments))
    }
  }, [value, developments])

  const assignToken = () => {
    setIsLoading(true)
    const status = 'Inactivo'
    const activeToken = tokens.find(
      tkn => tkn.status === status && tkn.type === tokenType && !tkn.active
    )
    dispatch(
      AssignTokenToDevelopment(
        token,
        tokenType,
        value,
        token_id || (activeToken && activeToken.token_id)
      )
    )
      .then(res => {
        setIsLoading(false)
        cb && cb()
        HideModal()
      })
      .catch(err => {
        const {
          error: {
            response: { data: { error: { message } = {} } = {} } = {}
          } = {}
        } = err
        setIsLoading(false)
        if (message === 'Development already linked to another token') {
          return dispatch(
            ShowFlashNotification(
              'danger',
              'Este desarrollo ya está asignado a un token.'
            )
          )
        }

        if (message === 'Not available tokens') {
          return buyTokens && buyTokens()
        }

        return dispatch(
          ShowFlashNotification(
            'danger',
            'Ocurrió un error al asignar el token.'
          )
        )
      })
  }

  const tokenValidator = type => tokenType === type

  return (
    <Container>
      <Typography variant="h3" color="palette.primary.main" align="center">
        Convierte tu desarrollo en premium y mejora su desempeño:
      </Typography>
      <Typography variant="body1" color="palette.new.text">
        {`Se utilizará un token ${tokenType} para tu desarrollo:`}
      </Typography>
      <Select
        placeholder="Selecciona un desarrollo:"
        optionLabelKey="development_name"
        optionValueKey="development_id"
        options={developments}
        customHeight="200"
        onChange={({ target: { value } = {} }) => value && setValue(value)}
        value={value}
        css={`
          margin: ${({ theme }) => theme.remCalc('10 0')};
        `}
      />
      <div
        css={`
          width: 100%;
          min-height: ${({ theme }) => theme.remCalc('130')};
          border: 2px solid #e8ecf9;
          border-radius: ${({ theme }) => theme.remCalc('10')};
          display: flex;
          padding: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        <div
          css={`
            min-width: ${({ theme }) => theme.remCalc('110')};
            width: ${({ theme }) => theme.remCalc('110')};
            height: 100%;
            overflow: hidden;
            border-radius: ${({ theme }) => theme.remCalc('10')};
          `}
        >
          <Image
            id={development.cover_image}
            objectFit="cover"
            css={`
              width: 100%;
              height: 100%;
            `}
          />
        </div>
        <div
          css={`
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-left: ${({ theme }) => theme.remCalc('10')};
          `}
        >
          <Typography variant="subtitle3">
            {development.development_name}
          </Typography>
          <TypographyStyled>
            <Location
              css={`
                ${CommonIcon}
              `}
            />{' '}
            {development.city}, {development.state}.
          </TypographyStyled>
          {development.operationTypes &&
            development.operationTypes.map(
              ({ value, label, active }, index) =>
                active && (
                  <TypographyStyled>
                    <Price
                      css={`
                        ${CommonIcon}
                      `}
                    />{' '}
                    {label} {`$${value}`} mxn
                  </TypographyStyled>
                )
            )}
          <TypographyStyled variant="body2">
            <BarCode
              css={`
                ${CommonIcon}
              `}
            />{' '}
            {development.development_id}
          </TypographyStyled>
        </div>
      </div>
      <Typography variant="subtitle2" color="palette.new.text">
        Selecciona un tipo de token:
      </Typography>
      <div
        css={`
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <RadioStyled
          active={tokenValidator('premium')}
          onClick={() => setTokenType('premium')}
        />
        <Typography variant="body2" color="palette.new.text">
          Desarrollo premium
        </Typography>
        <RadioStyled
          active={tokenValidator('elite')}
          onClick={() => setTokenType('elite')}
        />
        <Typography variant="body2" color="palette.new.text">
          Desarrollo elite
        </Typography>
      </div>
      <div
        css={`
          display: flex;
          align-items: center;
        `}
      >
        <Typography variant="subtitle3" color="palette.new.text">
          Tokens {tokenType}:
        </Typography>
        <Typography
          variant="h2"
          css={`
            margin: ${({ theme }) => theme.remCalc('0 5')};
          `}
        >
          {renderTokenCount(tokenType)}
        </Typography>
        <Typography variant="body2" color="palette.new.text">
          Disponibles
        </Typography>
      </div>
      <Typography variant="body3" color="palette.new.text" align="justify">
        Tu desarrollo se volverá a suscripción {tokenType} y podrás aprovechar
        de todos los beneficios y herramientas de reportes y promoción. Puedes
        cancelar tu desarrollo de la suscripción cuando desees y este dejará de
        ser {tokenType}{' '}
        <strong>
          posterior a los 30 días después de la facturación del token.
        </strong>
      </Typography>
      <div
        css={`
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <Button
          type="tertiary"
          rounded
          onClick={HideModal}
          css={`
            margin-right: ${({ theme }) => theme.remCalc('20')};
          `}
        >
          Cancelar
        </Button>
        <Button
          type={tokenType === 'elite' ? 'elite' : 'prime'}
          rounded
          onClick={() =>
            dispatch(
              ShowModal(AssignTokenConfirmationMC, {
                action: () => assignToken(),
                title: 'Confirmar la activación de token',
                description:
                  'Se activará un token Premium a tu Desarrollo, la activación tiene una duración de 30 días posteriores al periodo de facturación. Por lo que si deseas cancelar esta activación no podrás realizarlo hasta después de los 30 días.'
              })
            )
          }
        >
          {tokenType === 'elite' ? (
            <img
              src={Elite}
              alt="elite-icon"
              css={`
                margin-right: ${({ theme }) => theme.remCalc('5')};
              `}
            />
          ) : (
            <img
              src={Star}
              alt="star-icon"
              css={`
                margin-right: ${({ theme }) => theme.remCalc('5')};
              `}
            />
          )}
          Convertir a {tokenType}
        </Button>
      </div>
      {isLoading && (
        <div
          css={`
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
          `}
        >
          <Spinner />
        </div>
      )}
    </Container>
  )
}

AssignTokenMC.propTypes = {
  buyTokens: PropTypes.func,
  cb: PropTypes.func,
  developments: PropTypes.array,
  development_id: PropTypes.string,
  fromDevelopment: PropTypes.bool,
  HideModal: PropTypes.func,
  tokenSummary: PropTypes.object,
  token: PropTypes.string,
  token_id: PropTypes.string,
  type: PropTypes.string,
  getTokens: PropTypes.func
}
const CommonIcon = css`
  color: #7d7f9c;
  fill: #7d7f9c;
  margin-right: ${({ theme }) => theme.remCalc('5')};
  ${({ theme }) => theme.box(13, 13)}
`

const Container = styled.div`
  width: ${({ theme }) => theme.remCalc('470')};
  height: ${({ theme }) => theme.remCalc('580')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('30')};
  position: relative;
`
const TypographyStyled = styled(Typography).attrs({
  variant: 'body2',
  color: 'palette.new.text'
})``
const RadioStyled = styled.span`
  border: 1px solid gray;
  margin: ${({ theme }) => theme.remCalc('0 10')};
  cursor: pointer;
  ${({ theme }) => theme.box(23, 23, true)}
  ${({ active, theme }) =>
    active &&
    `background-color: ${theme.palette.primary.main}; border: 1px solid ${theme.palette.primary.main};`}
`

export default AssignTokenMC
