import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'

import { RegisterForm } from 'modules/login'
import { Footer } from 'modules/app'

import { Register } from 'reduxActions/auth'
import { ShowFlashNotification } from 'reduxActions/services'
import { Logo } from 'modules/app/NavBar'
import { TERMS_AND_CONDITIONS_VERSION } from 'utils/constants'

import eventGoogle from 'utils/eventsGoogle'

class RegisterModule extends Component {
  static propTypes = {
    history: PropTypes.object,
    Register: PropTypes.func,
    ShowFlashNotification: PropTypes.func
  }

  state = {
    activeError: false
  }

  handleSubmit = data => {
    const { Register } = this.props
    const { confirmEmail, ...formValues } = data
    return new Promise((resolve, reject) => {
      if (data.termsAndConditions) {
        this.setState(
          {
            activeError: false
          },
          () =>
            Register({
              ...formValues,
              email: data.email.trim(),
              terms_and_conditions: TERMS_AND_CONDITIONS_VERSION
            })
              .then(res => {
                eventGoogle.eventsLogin.register()
                resolve(res)
              })
              .catch(err => reject(err))
        )
      } else {
        this.setState(
          {
            activeError: true
          },
          () => reject()
        )
      }
    })
  }

  handleSubmitSuccess = (...data) => {
    const { history } = this.props
    return history.push('/profile')
  }

  handleSubmitFail = (formErrors, dispatch, submitError) => {
    if (this.state.activeError === false) {
      const { ShowFlashNotification } = this.props
      if (formErrors && Object.keys(formErrors).length > 0) {
        return
      }
      const {
        error: { response: { data: { error: { code } = {} } = {} } = {} } = {}
      } = submitError

      let errorMessage = ''
      switch (code) {
        case 'REGISTER_USER_ALREADY_EXISTS':
          errorMessage = `Este correo ya está registrado. 
           Intenta con otro correo.
           Código de error: WIG-02`

          break
        case 'INTERNAL_SERVER_ERROR':
          errorMessage =
            'Error desconocido, por favor intenta de nuevo más tarde.'

          break
        default:
          //PREGUNTAR QUE OTROS ERRORES HAY
          errorMessage =
            'Error desconocido, por favor intenta de nuevo más tarde.'
          break
      }
      ShowFlashNotification('danger', errorMessage, null, {
        inFeaturedImage: true
      })
    }
  }

  validation = values => {
    const { termsAndConditions } = values

    if (Object.prototype.hasOwnProperty.call(values, 'termsAndConditions')) {
      switch (termsAndConditions) {
        case true:
          this.setState({
            activeError: false
          })
          break
        default:
          this.setState({
            activeError: true
          })
          break
      }
    }
  }

  isEqualValidation = (value, allValues, props, name) => {
    return allValues.email !== value ? 'No coinciden' : ''
  }

  render() {
    const { activeError } = this.state

    return (
      <Layout className="register-module" windowSize vertical justify>
        <LayoutItem>
          <div
            css={`
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 30px;
            `}
          >
            <Logo login={true} width="190" />
          </div>
        </LayoutItem>
        <RegisterForm
          onSubmit={this.handleSubmit}
          onSubmitSuccess={this.handleSubmitSuccess}
          onSubmitFail={this.handleSubmitFail}
          validation={this.validation}
          activeError={activeError}
          isEqualValidation={this.isEqualValidation}
        />
        <Footer />
      </Layout>
    )
  }
}

const mapDispatchToProps = {
  Register,
  ShowFlashNotification
}

export default withRouter(connect(null, mapDispatchToProps)(RegisterModule))
