import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components'
import { PDF } from 'rc/Icons'

const CreatePDFButton = ({
  disabled,
  propertyId,
  token,
  CreatePDF,
  extraDataSheetAttributes,
  customContent,
  ...props
}) => {
  return (
    <Button
      {...props}
      id="propertyDetail-wiggotModule-button-shareDatasheet"
      disabled={disabled}
    >
      {customContent ? (
        customContent
      ) : (
        <Fragment>
          {' '}
          <PDF
            css={`
          ${({ theme }) => theme.box(25, 27)}
          margin-right: ${({ theme }) => theme.remCalc('12')};
          ${({ theme }) => theme.media.tabletDown`
            display: none;
          `}
        `}
          ></PDF>
          Descargar PDF
        </Fragment>
      )}
    </Button>
  )
}

CreatePDFButton.defaultProps = {
  extraDataSheetAttributes: {},
  customContent: ''
}
CreatePDFButton.propTypes = {
  CreatePDF: PropTypes.func,
  disabled: PropTypes.bool,
  propertyId: PropTypes.string,
  token: PropTypes.string,
  extraDataSheetAttributes: PropTypes.object,
  customContent: PropTypes.any
}

export default CreatePDFButton
