import { isEnableForFeature } from 'utils/featureFlags'

const mapLeadsFeatureEnabled = new Set([
  'inunezcigdl@gmail.com',
  'cesarhernandez@inmovent.com.mx',
  'igutierrez@grupoguia.mx',
  'ventas@placeme.mx',
  'juliogilb@propiedadesgdl.com',
  'agbienesraices07@gmail.com',
  'hugo14nmh@gmail.com',
  'casablancabienesinmuebles@gmail.com',
  'blancapaloma.ruelas@gmail.com',
  'rfm865@gmail.com',
  'jdanielcarrillo25@gmail.com',
  'abacogdl@gmail.com',
  'direccion@inmobiliariaroble.com',
  'aa.inmobiliaria@gmail.com',
  'bienesraicesmendoza.gdl@gmail.com',
  'contrasteinmobiliaria@gmail.com',
  'palmex@me.com',
  'colomosinmobiliaria@hotmail.com',
  'info@grupoemco.com.mx',
  'opreciado@estotal.com.mx',
  'avancedireccion@gmail.com',
  'alain@habika.com.mx',
  'contacto@imovel.mx',
  'lizsanchez@guadalajaraenventa.net',
  'gamainmobiliaria@gmail.com',
  'enriquezbienesraices@hotmail.com',
  'anapaula.proyectoscomerciales@hotmail.com',
  'ramsabr@hotmail.com',
  'pizarroryasociados@gmail.com',
  'osi.jalisco@gmail.com',
  'romainmobiliariakm@gmail.com',
  'elibatiz@hotmail.com',
  'contacto.mundoinmobiliario@gmail.com',
  'asesor@telorento.com',
  'info@zai.mx',
  'gramirez@grupoguia.mx',
  'lhernandez@grupoguia.mx',
  'crivero@grupoguia.mx',
  'laemiguelangel@hotmail.com',
  'mzorozco@grupoguia.mx',
  'mcjauregui@grupoguia.mx',
  'fhernandez@grupoguia.mx',
  'acsrdireccion@gmail.com',
  'arcasagrupo@gmail.com',
  'cdiaz@grupoguia.mx',
  'ccovarrubias@grupoguia.mx',
  'nharo@grupoguia.mx',
  'dmoller@grupoguia.mx',
  'kespelosin@grupoguia.mx',
  'mbecerril@grupoguia.mx',
  'ggonzalez@grupoguia.mx',
  'estinver@hotmail.com',
  'ljurado@grupoguia.mx',
  'mteran@grupoguia.mx',
  'ventasnorman@hotmail.com',
  'grandhome.eduardo@gmail.com',
  'nsilva@grupoguia.mx',
  'mramonet@grupoguia.mx',
  'kortiz@grupoguia.mx',
  'aramos@grupoguia.mx',
  'valeriam@grupoguia.mx',
  'iberlinksinmuebles@gmail.com',
  'ehernandez@grupoguia.mx',
  'mperez@grupoguia.mx',
  'fvenegas@grupoguia.mx',
  'dorea@grupoguia.mx',
  'vhbriones@grupoguia.mx',
  'eituarte@grupoguia.mx',
  'mjlomeli@grupoguia.mx',
  'sgonzalez@grupoguia.mx',
  'cs.guzman@live.com',
  'alejandro@goldspotbrokers.com',
  'mistrettagi@grupoguia.mx',
  'grismartinez@tohome.mx',
  'joaquin.garcia@jcbienesraices.com.mx',
  'Mcrodriguez@grupoguia.mx',
  'pvega@grupoguia.mx',
  'cperalta@grupoguia.mx',
  'abarajas@grupoguia.mx',
  'mgutierrez@grupoguia.mx',
  'ventascultura@hotmail.com',
  'rmartinez@grupoguia.mx',
  'direccion@descubrelevante.com',
  'Caguilar@mhabitat.com',
  'lramirez@grupoguia.mx',
  'omendoza@grupoguia.mx',
  'jorgecuevasrt@icloud.com',
  'floridagdl@gmail.com',
  'rosalbavelasco@hotmail.com',
  'leggdiamante.inmobiliaria@gmail.com',
  'kstorinmobiliaria@gmail.com',
  'grismartinez8@hotmail.com',
  'prospectos.espacio@gmail.com',
  'yoli_padilla@hotmail.com',
  'oswaldoaraujo@fidelisbienesraices.com',
  'atencion_asin@hotmail.com',
  'dannydm@yahoo.com',
  'carlosgw@grupoguia.mx',
  'abelmorones83@gmail.com',
  'acanedo1a@hotmail.com',
  'gabyrc@icloud.com',
  'dmedina@grupoguia.mx',
  'kperez@grupoguia.mx',
  'coordinacionpuntosur@grupoguia.mx',
  'mc.hemuda@inmuda.com',
  'lupita.sanchez@tuhistoriainmobiliaria.com',
  'zivotmexico@gmail.com',
  'abgdiamanteinmorelia@gmail.com',
  'c.alcala@grupoalor.com',
  'lorenzzmm@hotmail.com',
  'direccion@espacioadhoc.mx',
  'laugeorr@gmail.com',
  'hola@vitareinmobiliaria.com',
  'fpulido53@gmail.com',
  'urbanajulieta@gmail.com',
  'renem1998@hotmail.com',
  'resultabieninmobiliaria@gmail.com',
  'eraquel@grupoguia.mx',
  'omar@vardeinmobiliaria.net',
  'cguevarau@gmail.com',
  'karen.kasten.cruz@gmail.com',
  'celizondo@grupoguia.mx',
  'social@avilesyasociados.com',
  'contacto@distincioninm.com',
  'jprc310180@gmail.com',
  'bienesraiceshermosillo@gmail.com',
  'inmobiliariaabr@hotmail.com',
  'flores.juan@jucaba.mx',
  'ilynunez@hotmail.com',
  'info.housingonline@gmail.com',
  'promotora_alcar@yahoo.com.mx',
  'm.estrada@galileoinmobiliaria.com.mx',
  'f.estrada@galileoinmobiliaria.com.mx',
  'daniel.gutierrez@inmobiliariapsi.com',
  'ventas1@casaadministraciones.com',
  'vianovainmobiliaria@hotmail.com',
  'jlalcerreca@gmail.com',
  'll.b.carlosluna@gmail.com',
  'ventas@sienainmobiliaria.com',
  'alianzainmobiliaria@hotmail.com',
  'informes@casaadministraciones.com',
  'contacto@sadesi.com.mx',
  'efrain@domusvallarta.com',
  'juanenrique_hg@hotmail.com',
  'chavezali27@gmail.com',
  'dorisortega@hotmail.com',
  'alex@vinculacioninmobiliaria.com',
  'lnoriega.patrimonia@gmail.com',
  'ventas@punto13.mx',
  'contacto@desarrollosromo.com',
  'eduardo.f@anedy.com.mx',
  'Krivas@grupoguia.mx',
  'cristy1603@hotmail.com',
  'lupita@vinculacioninmobiliaria.com',
  'hmedina@gcrinmuebles.com',
  'kwandares@kwmexico.mx',
  'fzinmobiliariajalisco@gmail.com',
  'auroriojas@gmail.com',
  'cecinavarrog@hotmail.com',
  'contactoadonaibr@gmail.com',
  'fcobersainz@gmail.com',
  'nieblaginmobiliaria@outlook.com',
  'arantza@viprosa.com.mx',
  'aidainmobiliaria75@gmail.com',
  'laurabenhumea1@gmail.com',
  'sierraplusventas@gmail.com',
  'macc625@hotmail.com',
  'contacto@remax-activa.com.mx',
  'auricabienesraices@gmail.com',
  'reneb.keiz@gmail.com',
  'DIRECCION@RAMCES.COM.MX',
  'mielisaa@hotmail.com',
  'antonieta@ofarrill.mx',
  'america@grupoinmobiliariomya.com',
  'i.alvarez@sipasesores.mx',
  'hogargli@gmail.com',
  'guille@damavabienesraices.net',
  'hola@retmad.com',
  'estinverguardia@gmail.com',
  'brmartinezg9@gmail.com',
  'lidetg@tohome.mx',
  'dagredano@grupoguia.mx',
  'hola@grandhomeluxury.com',
  'mf.asesoresinmob@gmail.com',
  'cuesanbienesraices@gmail.com',
  'merrejon@grupoguia.mx',
  'ortizbr14@gmail.com',
  'pattychamshome@gmail.com',
  'lucy.robles@openhouse-inmobiliaria.mx',
  'edithvd03@gmail.com',
  'roberto@dysi.com.mx',
  'alviseya@gmail.com',
  'bienesraices.jerico@gmail.com',
  'orozco.cdkrealestate@gmail.com',
  'benjaminar1604@gmail.com',
  'marcela.landell@2mas2.mx',
  'jomasesoria@gmail.com',
  'aginmobiliario.eayala@gmail.com',
  'contacto@gaxiolainmobiliaria.com',
  'olivosinmobiliaria@hotmail.com',
  'lauraelenatapiadominguez@gmail.com',
  'oficina@greciabr.mx',
  'aidamarquez.ab@gmail.com',
  'industrial@novara.com.mx',
  'hola@atia.mx',
  'aoviedo.afg@gmail.com',
  'promotoramobler@gmail.com',
  'angelica.leggdiamante@gmail.com',
  'puentetresinmobiliaria@gmail.com',
  'marumartinezv@gmail.com',
  'carlo@compramostuinmueble.com',
  'rparedes.puntotufuturo@gmail.com',
  'arturo@castanedaasesores.com',
  'publicidad@reiinmobiliaria.mx',
  'gisela.jrinmobiliaria@hotmail.com',
  'mjtovarr@gmail.com',
  'Prorentgdl@hotmail.com',
  'arquaimx@gmail.com',
  'loriluchuerta@gmail.com',
  'info@buyhaus.mx',
  'haus8a@yahoo.com',
  'inmueblesbien@outlook.com',
  'ventas.safeinmobiliaria@outlook.com',
  'ns.ainmob@hotmail.com',
  'ebech.b3@gmail.com',
  'Ciga59@gmail.com',
  'gchristy@grupoguia.mx',
  'paredesgrupoinmobiliario@gmail.com',
  'jesus@fincainmuebles.com',
  'eduardo@areaquattro.mx',
  'contacto@alianzaindustrial.com',
  'lucia.vel@grupohbienesraices.com',
  'valores@ormanrealestate.com',
  'asesoramora@gmail.com',
  'contactomucino@gmail.com',
  'vmanimas@gmail.com',
  'distintivainmobiliaria@gmail.com',
  'fabi_jimenez@sajiinmobiliaria.com',
  'alex.zuniga.s@live.com',
  'Info@contextoinmobiliario.com',
  'anperav76@gmail.com',
  'abundanzabr@gmail.com',
  'somos@inmueblespremiummx.com',
  'kibarra@grupoguia.mx',
  'promocionactiva@hotmail.com',
  'marlene.alatorre.a@gmail.com',
  'angiesdu@hotmail.com',
  'ing.cristiandiarte@gmail.com',
  'yeseniacoronela@gmail.com',
  'consultas.acierta@gmail.com',
  'nasociados1@gmail.com',
  'area.uno@outlook.com',
  'opcionesventayrenta@gmail.com',
  'rtrujillo@tuinmobiliaria.com.mx',
  'mgmbienespatrimoniales@gmail.com',
  'Ma0275398@gmail.com',
  's.victor@keepcastle.com.mx',
  'ventas1homee@gmail.com',
  'silvia.lanamax@hotmail.com',
  'inlet.enz@gmail.com',
  'bernardo@bext.com.mx',
  'patyhdez1970@gmail.com',
  'cgomez_michel@hotmail.com',
  'sceasesores2017@gmail.com',
  'contacto.delvivaryasociados@gmail.com',
  'iarzaluzh@gmail.com',
  'milydelgado67@hotmail.com',
  'elva_urias@live.com.mx',
  'fernanda@aciertainmobiliaria.mx',
  'liliana.gonzalez@rginmueblesmty.com',
  'inmuebles@cr8.com.mx',
  'inprocapital@gmail.com',
  'marcela@velmarinmobiliaria.com',
  'jgcbienesraices@gmail.com',
  'karina_aceves@coldwellbanker.com.mx',
  'gabriela_carrasco@coldwellbanker.com.mx',
  'pienasecurezza019@gmail.com',
  'a.arteagacampos@gmail.com',
  'perla_ambriz@coldwellbanker.com.mx',
  'lilian_gonzalez@coldwellbanker.com.mx',
  'martha_villasenor@coldwellbanker.com.mx',
  'quirozsantana@gmail.com',
  'arq.franciscomartorre@gmail.com',
  'jesus.huertac@outlook.com',
  'carla_ibarra@coldwellbanker.com.mx',
  'leticia_villasenor@coldwellbanker.com.mx',
  'sebastian@chapainmobiliaria.com.mx',
  'martha@habita7.com.mx',
  'elicastella01@gmail.com',
  'rosa_lopez@coldwellbanker.com.mx',
  'acastasan@hotmail.com',
  'l.teresaespinozav@gmail.com',
  'adanelyr@hotmail.com',
  'asotelo@inmueblesqueretaro.mx',
  'mgarces@grupoguia.mx',
  'marketing@viveenti.com',
  'sadithchav2018@gmail.com',
  'creacapital@consultores360.mx',
  'xaviersanchez665@gmail.com',
  'lalomayomail@gmail.com',
  'bienesraices@gbramirez.com.mx',
  'contacto.inmobiliario.erika@gmail.com',
  'alejandro@teresadevela.com',
  'dpcr85@hotmail.com',
  'edna.castillo.r@gmail.com',
  'gil.barrong@gmail.com',
  'tallerdeasistencia@gmail.com',
  'luisgarevalos@gmail.com',
  '88bienesraices@gmail.com',
  'rocinanunoa@gmail.com',
  'marketing@habitaya.com.mx',
  'c.gonzalez@torres24.com',
  'Veroloretom@gmail.com',
  'laurasada@ymail.com',
  'itzelsaavedragarcia@gmail.com',
  'beatriz.arizaga.h@gmail.com',
  'chat_carmona@hotmail.com',
  'esteban@seaport.com.mx',
  'contacto@casasur.mx',
  'ventas@conocetucasa.com',
  'contactoinmobiliario@aureaibarguen.com.mx',
  'lc.lsbienesraices@gmail.com',
  'mrbrokermx@gmail.com',
  'anabrjs@cbginmob.com',
  'Felixycobienesraices@gmail.com',
  'contacto.grupocuenca@gmail.com',
  'atencionclientes.ibr@gmail.com',
  'minmueblemoreliaanuncios@yahoo.com',
  'dammbienesraices20@gmail.com',
  'ivan@jaliscoinmobiliaria.com',
  'raul.rivera@brymsa.com.mx',
  'gabriel@ariassolucionesinmobiliarias.com',
  'decimopisoinmobiliaria@gmail.com',
  'carlos@altimobienesraices.com.mx',
  'hector.nava.2017@gmail.com',
  'andrea.barcenasa@gmail.com',
  'tbarcenas_a@hotmail.com',
  'materesaran@gmail.com',
  'saul.inmobiliaria@gmail.com',
  'elisavazquezgomez68@gmail.com',
  'contacto@fincainmuebles.com',
  'ileanaoo@hotmail.com',
  'inmosflores@gmail.com',
  'alfa2.chavez@gmail.com',
  'morganbienesraices@yahoo.com',
  'ventas@queretarosothebysrealty.com',
  'hector.m.corona@outlook.com',
  'igpgestionpatrimonial@gmail.com',
  'yesmin.mayette@outlook.com',
  'contacto@orangehomeinmobiliaria.com.mx',
  'blancasalgadoventas@hotmail.com',
  'miguelanngel93@gmail.com',
  'movaadmon@gmail.com',
  'direccion@altidia.mx',
  'luis.rich@homeland.com.mx',
  'mcarlo@exitopublicitario.net',
  'lsilva@capitalseguro.mx',
  'carmelitaortegam32@gmail.com',
  'diana.espinoza@kwmexico.mx',
  'taniareginainzunza@gmail.com',
  'info@remax-vanguardia.com.mx',
  'Isabel@aciertainmobiliaria.mx',
  'evolution@realtyworld.com.mx',
  'gdiaz@grupoguia.mx',
  'aguilera.inversionbienesraices@gmail.com',
  'js@jessecasoto.com',
  'jylbienesinmuebles@gmail.com',
  'contacto@tumejorhogar.mx',
  'rbarrera@hamoc.rent',
  'info@gyainmobiliaria.com',
  'leticia.agl22@gmail.com',
  'contacto@c21continental.com',
  'inmobiliaria@grupomavian.com',
  'jorge@nanoinversiones.com',
  'cdiaznoriega67@gmail.com',
  'bienesraicesmucino@gmail.com',
  'dzermeno.igp@gmail.com',
  'rcalva7@gmail.com',
  'ventasterralia@gmail.com',
  'edgarquirozbecerril@gmail.com',
  'rociorebollo85@gmail.com',
  'info@thevenue-realstatebrokers.com',
  'aramendisena2021@gmail.com',
  'sigestion2019@gmail.com',
  'qualityhomearios@gmail.com',
  'resides.mexico@gmail.com',
  'veronicaoviedo.bienesraices@gmail.com',
  'crisrosas.bienesraices@gmail.com',
  'ambarbienesraices@gmail.com',
  'contacto@gogarbienesraices.com',
  'asesorerick1@gmail.com',
  'monica.lilian.camacho@gmail.com',
  'varhmx@hotmail.com',
  'rbarajas.jomar@gmail.com',
  'ginmobiliarioca@gmail.com',
  'crsinmob@gmail.com',
  'grupomavian.jose.correa@gmail.com',
  'mcminmob@gmail.com',
  'alizamudio@prodigy.net.mx',
  'spav29@hotmail.com',
  'monicai.pereza@gmail.com',
  'kgudino@grupoguia.mx',
  'gpartidavargas@yahoo.com.mx',
  'cliente.inmova@gmail.com',
  'clientesbona@bmasconsulting.com.mx',
  'lomeli1235@gmail.com',
  'joseluis@universalrealestate.mx',
  'quintintorresr@hotmail.com',
  'contacto@bmasconstruction.com',
  'emma.gonzalez@baribienesraices.com',
  'betsy.ortega@live.com',
  'arcenio.duran@hotmail.com',
  'tonatiuh.carrera@gmail.com',
  'adrianamat.am@gmail.com',
  'aizayazmin@gmail.com',
  'lia.mallard@gmail.com',
  'jaimesolcar@hotmail.com',
  'gpena.autocountry@gmail.com',
  'pazgaca@yahoo.com.mx',
  'brenda.real@yahoo.com.mx',
  'ventas@oportunidaddecasas.com.mx',
  'marag@grupoguia.mx',
  'asesoria.bienes.raices.pro@gmail.com',
  'INMOBILIARIAINMEX@HOTMAIL.COM',
  'victordiaz.igp@gmail.com',
  'ing.jutroncoso@gmail.com',
  'yancesinmobiliaria@gmail.com',
  'sgomez.mavian.inmobiliaria@gmail.com',
  'dortiz.unique@gmail.com',
  'mater_bienesraices@yahoo.com',
  'coordinacionindustrial@grupoguia.mx',
  'maritza.guevara@guemer.com',
  'jcinmobiliaria@hotmail.com',
  'marydelaf.mavian@gmail.com',
  'gruporossqro@gmail.com',
  'ecainmuebles@gmail.com',
  'montalvobienesraices@gmail.com',
  'erikmalvido@gmail.com',
  'julito_26@hotmail.com',
  'any_ramirez2@hotmail.com',
  'serdan_bienesraices@hotmail.com',
  'carloschg@hotmail.com',
  'zarguibienesraices@hotmail.com',
  'cvillarreal@carvibienesraices.com',
  'monica365br@yahoo.com',
  'contacto@ereamx.com',
  'neri.raneri@gmail.com',
  'publicidadcentro@zrygbienesraices.com',
  'mercedes@kallida.com.mx',
  'cassaterra19@gmail.com',
  'minerva.covarrubias@grupocasillas.mx',
  'inmobiliariopromotor@gmail.com',
  'rosa.elenaorm@gmail.com',
  'franciscopacheco.mavianinmobiliaria@hotmail.com',
  'bienesraices.salvatore@gmail.com',
  'genesisinmuebles@gmail.com',
  'contacto@peinmobiliaria.com',
  'cecilia.kuver@gmail.com',
  'viterra.digital@gmail.com',
  'debraarnau@top-email.net',
  'leoruizinmobiliaria1@gmail.com',
  'calasbienesraices@gmail.com',
  'depablo@rwopus.com',
  'info@inmuebleperfecto.mx',
  'tresmodularinmobiliaria@gmail.com',
  'prohogargdl@gmail.com',
  'mm_am2000@hotmail.com',
  'estrada.plusvaliahome@gmail.com',
  'mesquivel199@gmail.com',
  'rocio.malja@gmail.com',
  'tamara@bieninmobiliario.mx',
  'alfredograyeb@gmail.com',
  'alismalopez.igp@gmail.com',
  'andrea.mundoinmobiliario@gmail.com',
  'casalemx@gmail.com',
  'christian.solorio.gomez@gmail.com',
  'claudiazorrillabr@gmail.com',
  'erik_angel@live.com.mx',
  'jmalamillah@hotmail.com',
  'ceustorgio@gmail.com',
  'info@floresdupont.com',
  'diosdadoinmobiliaria@gmail.com',
  'jgmarting@icloud.com',
  'beatriz@ubikabr.com',
  'inmobiliaria.vallerealestate@gmail.com',
  'diegoderre@gmail.com',
  'selectbri1@gmail.com',
  'mipedrayes@gmail.com',
  'pgonzalez@grupoguia.mx',
  'ventasc21excelencia@gmail.com',
  'granilloleticia@gmail.com',
  'inmueblahi@gmail.com',
  'friasv01@gmail.com',
  'casayucatan@hotmail.com',
  'ag.aguilar1223@gmail.com',
  'guillermo@gaprealestate.com.mx',
  'noemigma@hotmail.com',
  'benjaglezdiaz@gmail.com',
  'arn.becerril@gmail.com',
  'ventas@gamezgrupoinmobiliario.com',
  'casas.abrajan@gmail.com',
  'fusion.director1@gmail.com',
  'clara.atilanodelatorre@gmail.com',
  'lucia.arechiga@ab-bienesraices.com.mx',
  'arqliog@gmail.com',
  'samwermar.c@gmail.com',
  'contacto@laagenciainmobiliaria.com.mx',
  'marifer.quijano@kwmexico.mx',
  'retail@grupoguia.mx',
  'contacto@inh.mx',
  'erikarosasinmuebles@gmail.com',
  'maluinm@hotmail.com',
  'innis.res@gmail.com',
  'esiller.bienesraices@gmail.com',
  'ruby030512@gmail.com',
  'elena_guadarrama@coldwellbanker.com.mx',
  'rruizf92@yahoo.com.mx',
  'joseareal64@gmail.com',
  'alegaralt@hotmail.com',
  '3solesmx@gmail.com',
  'videlg2012@gmail.com',
  'rcastaneda@rackandrick.com.mx',
  'giseth_trejo@coldwellbanker.com.mx',
  'meridacasasenventa@gmail.com',
  'antonio.nav.ramz@gmail.com',
  'atencionaclientes@c21laprimavera.com',
  'argrupoinmobiliariogdl@gmail.com',
  'anarealstate18@gmail.com',
  'contacto@c21invercapital.com',
  'info@inmobili.art',
  'kvvv2416@gmail.com',
  'mariacaryastorga@gmail.com',
  'mapbienesraices@gmail.com',
  'adrianaezquerrosotomayor@yahoo.com.mx',
  'ilianao46@gmail.com',
  'info@eencanto.com',
  'inmobiliariaursa@grupourias.com',
  'j.gpe.leal.g@gmail.com',
  'marucalderon@marcainmobiliaria.com.mx',
  'gestioninmobiliariapr@gmail.com',
  'magda_bri@hotmail.com',
  'atencionaclientes@miinmueblegdl.com',
  'jazmin@grupoguia.mx',
  'chaconbienesraices@gmail.com',
  'fernando@c21grupooro.com',
  'womenatwork-bienesraices@outlook.com',
  'carlosmunozcontacto@gmail.com',
  'amigocuauhtemoc@gmail.com',
  'arq_mfacundo@hotmail.com',
  'tonyquintanilla@hotmail.com',
  'contacto@c21camelott.com',
  'lidiafernandez@inmobili.mx',
  'ademarbr@hotmail.com',
  'carlos.v@carverinmobiliaria.com',
  'oscar_mario@hotmail.com',
  'living.bienesraices@hotmail.com',
  'carloscas99@hotmail.com',
  'guigh79@gmail.com',
  'jorgechavezasesorbr@gmail.com',
  'cynthia.cabrera@gmail.com',
  'contacto@mobigea.com',
  'alejandra.h@grupoguia.mx',
  'sheila@sumaplussi.com',
  'belinda.lopezgax@gmail.com',
  'animaz15@gmail.com',
  'adriana@sumaplussi.com',
  'pamela@sumaplussi.com',
  'vv.inmobiliaria@hotmail.com',
  'admon@magnohome.com',
  'gabriela@brokersnetwork.mx',
  'direccionkalsoli@gmail.com',
  'adrianasilva34445@gmail.com',
  'ventas@vitacasa.com.mx',
  'hola@hogarika.mx',
  'ana.puentehoku@gmail.com',
  'h.tamborrellvaliabr@gmail.com',
  'contactocyabienesraices@gmail.com',
  'alex8athc@gmail.com',
  'compraventayrentadecasasenags@gmail.com',
  'contacto@inrate.mx',
  'ale.kallida@gmail.com',
  'carmentorreschacon1@gmail.com',
  'ventas@rolveinmobiliaria.com',
  'erick.serrano@engrandece.mx',
  'fsanchez@canterarealestate.mx',
  'laura@canterarealestate.mx',
  'georgina.galvan.habittanova@gmail.com',
  'admego29@gmail.com',
  'denni.yazmin@gmail.com',
  'asesor@crescere.mx',
  'goldhouseinm@outlook.com',
  'blancacabrerared@gmail.com',
  'jano.jonguitud@gmail.com',
  'planosietebr@gmail.com',
  'contactokira.socios@gmail.com',
  'jfjimenez1@hotmail.com',
  'contacto@terram.one',
  'codigo.m2mx@gmail.com',
  'valecejim@gmail.com',
  'raul.ventas.inmobiliarias@gmail.com',
  'construcciones.braco@gmail.com',
  'adrixmedina@hotmail.com',
  'betsabera@hotmail.com',
  'lizquinza74@gmail.com',
  'aliancimx@gmail.com',
  'caualvarez@gmail.com',
  'portales@bekermexico.mx',
  'ventas@redhouser.com',
  'cs@terram.one',
  'projudbienesraices@gmail.com',
  'cecyasesorainmobiliaria@gmail.com',
  'japandurop@gmail.com',
  'iiplanes@hotmail.com',
  'santiago.arana@mettabienesraices.com',
  'mfontes@grupoguia.mx',
  'ethanaserviciosinmobiliarios@yahoo.com',
  'gracia_g@hotmail.com',
  'pilygaher@gmail.com',
  'barroso.bernardo@gmail.com',
  'johnymsz06@hotmail.com',
  'Sgomes@grupoguia.mx',
  'christian.riosmtz@gmail.com',
  'anuar.cabrera@hotmail.com',
  'lupitadegil@caralda.com',
  'vperez@caralda.com',
  'amendoza@caralda.com',
  'sheredia@caralda.com',
  'bchavez@caralda.com',
  'daniel.bca@hotmail.com',
  'a.ramirez@citymax-mx.com',
  'kabelihouse@gmail.com',
  'glmoreno@grupoguia.mx',
  'vinmobiliario21@gmail.com',
  'sfigueroast@gmail.com',
  'rons@inmuebleskappa.com.mx',
  'chema.brokergdl@gmail.com',
  'nexus.b.c.13@gmail.com',
  'contacto@vivaltainmobiliaria.com',
  'lvaldivia@caralda.com',
  'arqaliarvizu@gmail.com',
  'inmobilifegdl@gmail.com',
  'itotal@inmueblatotal.com',
  'm.vazquez@frava.com.mx',
  'analaura500@yahoo.com.mx',
  'mariana@dimares.com.mx',
  'angel.delacalleja.serra@gmail.com',
  'patriciapellicer3000@gmail.com',
  'marketing@alimacbienesraices.com',
  'victorhugo.mavian@gmail.com',
  'eubanos@gmail.com',
  'danitza@canterarealestate.mx',
  'marcela@dimares.com.mx',
  'epadilla@frava.com.mx',
  'vrgrupoinmobiliario@gmail.com',
  'contacto@martinezsayeg.com',
  'GBCABALINMOBILIARIA@GMAIL.COM',
  'piturbide@caralda.com',
  'alvarezmanzano.inmobiliaria@gmail.com',
  'celsalunaperez@jcbienesraices.com.mx',
  'enatera@frava.com.mx',
  'jcontreras@grupoguia.mx',
  'bienesraicesmerida123@gmail.com',
  'florencionavarrol@hotmail.com',
  'rociog@grupoguia.mx',
  'cvazquez@frava.com.mx',
  'asainz@frava.com.mx',
  'veralsolucioninmobiliaria@outlook.com',
  'info@bienesraicesse.com',
  'ochopuntosinmuebles@gmail.com',
  'infinityrealtorsmx0@gmail.com',
  'contacto.shinmobiliaria@gmail.com',
  'gmacias@frava.com.mx',
  'elopez@punto11realestate.com',
  'blancaflor@hotmail.com',
  'tessydltm10@hotmail.com',
  'azulvi2010@gmail.com',
  'esalazar@punto11realestate.com',
  'inmobigon@gmail.com',
  'griseldaps@hotmail.com',
  'inmobiliariamazamitla@gmail.com',
  'gpoperpendicular@gmail.com',
  'gypbienesraicesmx@gmail.com',
  'blanca@magnohome.com',
  'zanyae@eversainmobiliaria.com.mx',
  'contacto@centralmex.net',
  'melissa@grupoguia.mx',
  'juanpruiz66@gmail.com',
  'rociopp65@gmail.com',
  'marthee7@hotmail.com',
  'nunez_04@hotmail.com',
  'jcarlos@baezainmuebles.com',
  'dbartczak@grupoguia.mx',
  'diezbrokers@gmail.com',
  'contacto@gsbrokers.mx',
  'sara_8708@hotmail.com',
  'markagpo@gmail.com',
  'alanis@bienestarbienesraices.com.mx',
  'israel@zona360in.com',
  'cdelportillo@tlte.mx',
  'jgutierrez@grupoguia.mx',
  'info@hcinco.com.mx',
  'ventabshinfo@gmail.com',
  'velcobienesraices@gmail.com',
  'Tottorules@gmail.com',
  'contacto@hauslander.mx',
  'dmontano@grupoguia.mx',
  'Albalopez.br@gmail.com',
  'contacto@msginmobiliaria.com.mx',
  'gromanmarissa@hotmail.com',
  'Ventas08eme2@gmail.com',
  'marcela.rojas@hauslander.mx',
  'orgazvero12@gmail.com',
  'gabyjdelp@gmail.com',
  'irvingvargas727@gmail.com',
  'cervyp@hotmail.com',
  'fsenties.br@gmail.com',
  'masviviendare@gmail.com',
  'lbarragan@heicommunity.com',
  'raul.soto@hauslander.mx',
  'elizabethgzmz@gmail.com',
  'antaseinmobiliaria@gmail.com',
  'd.zamora@grupoguia.mx',
  'espaciosrrbienesraices@gmail.com',
  'rankinsolinmo@gmail.com',
  'mmagdareyest@gmail.com',
  'quidea.mx@gmail.com',
  'avalencia@punto11realestate.com',
  'haustergto@gmail.com',
  'karinahernandezcastellanos@gmail.com',
  'lili.mendoro@hotmail.com',
  'fernandagbroker@outlook.com',
  'tello.gilberto2013@gmail.com',
  'allterrarealstate@gmail.com',
  'orientainmuebles@gmail.com',
  'info@plusscity.com',
  'lourdesgh2762@hotmail.com',
  'ierivez@grupoguiacdmx.mx',
  'legio.inmobiliaria@gmail.com',
  'aomar.naranjo.22@gmail.com',
  'contacto@remax-essence.mx',
  'rgomez@grupoguiacdmx.mx',
  'mterrones@grupoguiacdmx.mx',
  'lorena@hcinco.com.mx',
  'jpruenes@habisnes.com',
  'mariana@hcinco.com.mx',
  'firmattobienesraices@gmail.com',
  'astorgaz999@gmail.com',
  'bienesraicescdmx.06@gmail.com',
  'somosabitare@gmail.com',
  'consultorascigdl10@gmail.com',
  'liarealestatesa@gmail.com',
  'jennifer.perez@aztrallgroup.com',
  'antoniotrevilla@hotmail.com',
  'antonio.jasso@inveralia10.com',
  'inmuebles.house24@outlook.com',
  'arcadiabienesraicesqro@gmail.com',
  'jimenaef@grupoguia.mx',
  'info@inmueblesoutlet.com',
  'aquintero_inmoquinto@hotmail.com',
  'victormanuelalvarez69@gmail.com',
  'marisol.contreras@hauslander.mx',
  'nury.ksainmobiliaria@gmail.com',
  'olinda.inmoval@gmail.com',
  'Olivia_santana@hotmail.com',
  'soportecorretaje@c360.com.mx',
  'info@sensso.mx',
  'contacto@remax-cumbre.com.mx',
  'ogonzalez@grupoguia.mx',
  'administracion@terra25.com',
  'soytuagentesma@gmail.com',
  'noerios.agente@gmail.com',
  'conecta.inmobiliaria@hotmail.com',
  'asesorjuancarloscastrogaray@gmail.com',
  'gariasociados@hotmail.com',
  'ventas@aeinmobiliaria.com.mx',
  'guardias@mctbienesraices.com',
  'mabelhomeadviser@gmail.com',
  'Ae.patriciareyes@gmail.com',
  'grupomapech@hotmail.com',
  'dsgroup.rs@gmail.com',
  'mariamedellinbienesraices@gmail.com',
  'md.escamilla3010@gmail.com',
  'daniel.visionseguros@gmail.com',
  'eda.reyes@hotmail.com',
  'contacto@movinver.com',
  'gsinmueblessg@gmail.com',
  'bobemaru@hotmail.com',
  'carlos.neri15@hotmail.com',
  'vale@baezainmuebles.com',
  'rosario.avalos@sensso.mx',
  'ventasmascasasinmobiliaria@gmail.com',
  'oficina@capitalamg.com.mx',
  'sibarra@grupoguia.mx',
  'andres.sanchez@alianza360.com.mx',
  'smarthomebienesraices@gmail.com',
  'karla.leggdiamanteinmobiliaria@gmail.com',
  'raulfloresmtz@hotmail.com',
  'diversainmobiliariosqueretaro@gmail.com',
  'malugus57@gmail.com',
  'myownspaceinmobiliaria@gmail.com',
  'mduran@grupoguiaqueretaro.mx',
  'idominguez@grupoguiaqueretaro.mx',
  'gesemdesarrollos@gmail.com',
  'storres@grupoguia.mx',
  'mdiaz@grupoguia.mx',
  'lfhernandez@grupoguia.mx',
  'casalerentas@gmail.com',
  'hornafaminne@gmail.com',
  'nadia@hcinco.com.mx',
  'grupobr.profin@gmail.com',
  'i.adrianam@hotmail.com',
  'ggarciaj68@gmail.com',
  'ivanfloto@gmail.com',
  'abraham.martinez438@gmail.com',
  'o2realstate@gmail.com',
  'skyinmuebles24@gmail.com',
  'eldacamm78@gmail.com',
  'contacto@realtyplusasesores.mx',
  'ludy@brokersnetwork.mx',
  'mhernandez@docerealestate.com',
  'elery.segura@brymsa.com.mx',
  'chapersi@hotmail.com',
  'agilmexinmobiliaria@gmail.com',
  'dmartinez@grupoguia.mx',
  'contacto@oportunidadesbancarias.com',
  'jesusserdan.mavian@gmail.com',
  'cristina_l@coldwellbanker.com.mx',
  'bcastanobr@gmail.com',
  'norma@navilaoportunidad.com',
  'Mrodriguez@grupoguia.mx',
  'crivera@grupoguia.mx',
  'sergio@grupoguia.mx',
  'alicia_cardenas@coldwellbanker.com.mx',
  'ventas.apvinternacional@gmail.com',
  'belsay0429@yahoo.com',
  'globalbrokersmexico@gmail.com',
  'jgonzalez@grupoguia.mx',
  'asistente@brezone.com.mx',
  'carolina@grupocampsa.com',
  'armasan12009@hotmail.com',
  'germancordero11@gmail.com',
  'conceptosbienesraices@gmail.com',
  'almodovar.ar.real.estate@outlook.com',
  'nancy_morales@coldwellbanker.com.mx',
  'livihlife@gmail.com',
  'compraventayrentadecasasnena@gmail.com',
  'monnica.garcia@hotmail.com',
  'yramirez@grupoguia.mx',
  'corozco@punto11realestate.com',
  'inmobipro2@gmail.com',
  'claudiajim.inmobiliaria@gmail.com',
  'alopez@grupoguia.mx',
  'excelenciabr@outlook.com',
  'lmagana@bexalta.mx',
  'jorgemontoya.sn95@gmail.com',
  's.velazquez@humusgi.com',
  'j.cortes@humusgi.com',
  'y.garcia@humusgi.com',
  'chirivizcaino@gmail.com',
  'viphomeculiacan@gmail.com',
  'manuelhroma@gmail.com',
  'mcoronado@grupoguia.mx',
  'a.hernandez@grupoguia.mx',
  'smartagenciainmobiliaria@gmail.com',
  'asesoresestinver@gmail.com',
  'joseluisc@realtyplus.mx',
  'admin@luxurymastermx.com',
  'lorena.ruiz@frannabienesraices.com.mx',
  'paoasesorinmobiliario@gmail.com',
  'tophomesre.mkt@gmail.com',
  'ventas@liainmobiliaria.com',
  'tmonroy22@hotmail.es',
  'lougoldproperties@gmail.com',
  'contacto@husrealty.com.mx',
  'miguelhernandez@liainmobiliaria.com',
  'espaciohabitat.inmobiliaria@gmail.com',
  'isalazar@tlte.mx',
  'g.martinez@grupoguiaqueretaro.mx',
  'contacto@remax-elnevado.mx',
  'r.toscano@homeland.com.mx',
  'kena.kallida@gmail.com',
  'msalazar@grupoguiacdmx.mx',
  'edgarpemor@gmail.com',
  'amanrique@grupoguia.mx',
  'cb@baezainmuebles.com',
  'joseluis@ubikabr.com',
  'enrique841219@gmail.com',
  'marellano@punto11realestate.com',
  'hugoibarra78@gmail.com',
  'pdelgadillo@grupoguia.mx',
  'male.muce@gmail.com',
  'grupoavro@avroinmobiliaria.com',
  'cgalindo@grupoguia.mx',
  'aquintero.inmoquinto@gmail.com',
  'lbarbosa@grupoguia.mx',
  'migue.minmuebles@gmail.com',
  'pau.minmuebles@gmail.com',
  'daniel.minmuebles@gmail.com',
  'leonardo.minmuebles@yahoo.com',
  'ecervantes.intelligence@gmail.com',
  'guguinmob@gmail.com',
  'imelda_cerezo@outlook.com',
  'maerrejon@grupoguia.mx',
  'ventas@renovainmuebles.com',
  'ale.aguirreab@gmail.com',
  'patymp27@yahoo.com.mx',
  'nestor2187@hotmail.com',
  'jcegespinosa@yahoo.com',
  'omar@gdlpropiedades.com',
  'Cgomez@tiamet.mx',
  'arqmfloresc@gmail.com',
  'mglinmobiliaria9@gmail.com',
  'lpalafox@grupoguia.mx',
  'lsalcedo@grupoguia.mx',
  'ale@dd360.mx',
  'gcg@grupogreyca.com',
  'juan.inhouse@gmail.com',
  'dreamhomemorelia@gmail.com',
  'elvira.mavian@gmail.com',
  'nhurtado@grupoguia.mx',
  'ventasluxuryestates@gmail.com',
  'aaron@baezainmuebles.com',
  'ixtainmobiliaria@gmail.com',
  'v.soto@grupoguia.mx',
  'acolmenero@grupoguia.mx',
  'pach@dd360.mx',
  'harambula@grupocic.com.mx',
  'administracion@blisshome.mx',
  'binaghi27@hotmail.com',
  'mvargas@grupoguia.mx',
  'h.patrimonial@hotmail.com',
  'bit.home.inmo@gmail.com',
  'cblvgmc@gmail.com',
  'lic.abelflores@gmail.com',
  'dalcantara@grupoguiacdmx.mx',
  'claudiacantoralpunto22@gmail.com',
  'isacecenareyes@gmail.com',
  'telesforogomez@hotmail.com',
  'ixalbaramirez@gmail.com',
  'christofer.minmuebles@yahoo.com',
  'm.haro@grupoarcadia.mx',
  'mccelis@bexalta.mx',
  'recepcion@diezbrokers.mx',
  'karlaaparicioch1@gmail.com',
  'alineamg14@gmail.com',
  'trois.luisfernando@gmail.com',
  'administracion@luvrainmobiliaria.com',
  'ventas@tibermx.com',
  'wiggot@inmueblesdomo.com',
  'ciconsultoresinmobiliarios19@gmail.com',
  'c.esparza@caesinmobiliaria.com',
  'ubvimaster@gmail.com',
  'eliramirez@grupoguia.mx',
  'realtylifebienesraices@gmail.com',
  'procasa_gdl@hotmail.com',
  'treuespacios@gmail.com',
  'jzaratecabral@gmail.com',
  'inverlandsmx@gmail.com',
  'javierdelgado@nexusinmobili.mx',
  'atencion@4inmx.com',
  'karla.garciap77@gmail.com',
  'info@cunna.mx',
  'Coorporativo.lasso@gmail.com',
  'brillodeunangel@hotmail.com',
  'yazjorinmobiliaria@gmail.com',
  'Pyrs0212@gmail.com',
  'omarealestatemx@gmail.com',
  'franciaruiz13@gmail.com',
  'rosorio@grupoguia.mx',
  'iayon@ayde.com.mx',
  'viviqueen.gdl@gmail.com',
  'ventasbi13@gmail.com',
  'julietark.igp@gmail.com',
  'perla.juarez@ayde.com.mx',
  'ogda.games@gmail.com',
  'inbero@hotmail.com',
  'squinainmobiliaria@gmail.com',
  'zoeinmobilariagdl@gmail.com',
  'contacto@inmobiliariaenpuebla.com.mx',
  'marumtzav@gmail.com',
  'silviaperezcarbajal@gmail.com',
  'rorgegobayo.I0VI@gmail.com',
  'leyva.I0VIII@gmail.com',
  'alejandro.pcix@gmail.com',
  'aezquel.jorgecx@gmail.com',
  'angeles.mora@activoinmobiliario.mx',
  'hector.peredo@activoinmobiliario.mx',
  'dulce.barrones@activoinmobiliario.mx',
  'adriana.javier@activoinmobiliario.mx',
  'ruben.morales@activoinmobiliario.mx',
  'bianca.morales@activoinmobiliario.mx',
  'lorena.jimenez@activoinmobiliario.mx'
])

export const isEnableForFeatureLeadManagement = (userEmail) => {
  return (
    isEnableForFeature(userEmail) ||
    mapLeadsFeatureEnabled.has(userEmail)
  )
}
