import React from 'react'

import { Image } from 'c3-ui/generics/Images'
import { Layout } from 'c3-ui/elements/Layouts'
import { Title, Text } from 'c3-ui/elements/Texts'

import { ICONS } from 'utils/icons'

const { wifiIcon } = ICONS

const BASE_CLASSNAME = 'offline-mc'

const OfflineMC = () => {
  return (
    <Layout className={BASE_CLASSNAME} center>
      <Image
        shrink
        src={wifiIcon}
        wrapperClassName={`${BASE_CLASSNAME}__img`}
        fitContent
      />
      <Title smallSize className={`${BASE_CLASSNAME}__title`} center>
        Sin conexión a Internet.
      </Title>
      <Text mediumSize center>
        Verifica tu conexión e intentalo de nuevo.
      </Text>
    </Layout>
  )
}

export default OfflineMC
