var _jsxFileName = 'src/lib/generics/Buttons/ImageButton.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Displays a button with an image it will be square.
 */
var BASE_CLASSNAME = 'wig-button-image';

var ImageButton = function ImageButton(_ref) {
  var _classNames;

  var alt = _ref.alt,
      className = _ref.className,
      disabled = _ref.disabled,
      noShadow = _ref.noShadow,
      onClick = _ref.onClick,
      src = _ref.src,
      thumbnail = _ref.thumbnail;

  var imgBtnWrapperClasses = classNames(className, BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--disabled', disabled), _defineProperty(_classNames, BASE_CLASSNAME + '--no-shadow', noShadow), _classNames));
  var imgBtnClasses = classNames(BASE_CLASSNAME + '__img', _defineProperty({}, BASE_CLASSNAME + '__img--thumbnail', thumbnail));
  return React.createElement(
    'div',
    { className: imgBtnWrapperClasses, onClick: disabled ? null : onClick, __source: {
        fileName: _jsxFileName,
        lineNumber: 27
      },
      __self: _this
    },
    React.createElement('img', {
      className: imgBtnClasses,
      src: src,
      alt: alt,
      style: { objectFit: 'contain' },
      __source: {
        fileName: _jsxFileName,
        lineNumber: 28
      },
      __self: _this
    })
  );
};

ImageButton.defaultProps = {
  className: '',
  src: '',
  alt: 'image-button',
  onClick: function onClick() {},
  thumbnail: false,
  disabled: false
};

ImageButton.propTypes = {
  /** If it is not posible to show the image, alt will be displayed*/
  alt: PropTypes.string,
  /** Add Class to apply css */
  className: PropTypes.string,
  /** Disables the button */
  disabled: PropTypes.bool,
  /** remove the box shadow style */
  noShadow: PropTypes.bool,
  /** Callback when button is clicked */
  onClick: PropTypes.func,
  /** Sets the src of the image */
  src: PropTypes.string,
  /** Applies thumbnail styles */
  thumbnail: PropTypes.bool
};

export default ImageButton;