import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AgencyBrokerStars } from 'components/Icons'
import {
  Grid,
  Typography,
  FormContainer,
  TextField,
  RatingField,
  FormContext,
  Spinner
} from 'rc'
import { StarRounded } from 'rc/Icons'
import { usePrevious } from 'rc/Hooks'
import { Image, SubmitButton, Button } from 'components'
import { GradeProfile, UpdateProfileConfig } from 'reduxActions/profile'
import {
  HideModal,
  ShowFlashNotification,
  ShowModal
} from 'reduxActions/services'
import { GetToKnowProfileScoresMC } from 'modules/profile/modalContent'
import NotificationMC from 'modules/app/modalContent/NotificationMC'
import { EventRx, EVENTS } from 'utils/event'

const GradeAgencyBrokerMC = ({
  profileId,
  profilePhoto,
  profileName,
  initialValues: initialValuesProp,
  token,
  GradeProfile,
  HideModal,
  ShowModal,
  ShowFlashNotification,
  userScoresSectionConfig,
  UpdateProfileConfig
}) => {
  /** In initial values
   * score
   * comment
   */
  const [initialValues, setInitialValues] = useState(initialValuesProp)
  const previousUserConfig = usePrevious(userScoresSectionConfig)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    EventRx.subscribe(this, EVENTS.SET_PROFILE_SCORE, ({ data }) => {
      setIsLoading(true)
      setInitialValues(data)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    })

    return () => {
      EventRx.unsubscribe(this, EVENTS.SET_PROFILE_SCORE)
    }
  }, [])

  useEffect(() => {
    if (previousUserConfig && userScoresSectionConfig !== previousUserConfig) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [previousUserConfig, userScoresSectionConfig])

  const handleSubmit = values => {
    const required_fields = ['comment']
    const fields_with_errors = required_fields.filter(
      field =>
        !values[field] ||
        (values[field] &&
          typeof values[field] === 'string' &&
          values[field].trim().length === 0)
    )
    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }

    return GradeProfile(token, profileId, values)
  }

  const handleSubmitSuccess = (response, formValues) => {
    EventRx.dispatch(EVENTS.RELOAD_PROFILE_VIEW)
    ShowModal(
      NotificationMC,
      {
        title: '¡Gracias por calificar a este asesor!',
        message: 'Se envío la calificación correctamente.'
      },
      null,
      true
    )
  }

  const handleSubmitFail = (values, fc, submitErrors) => {
    if (submitErrors) {
      let { code, metadata } = submitErrors
      const {
        error: {
          response: { data: { error: { code: responseCode } = {} } = {} } = {}
        } = {}
      } = submitErrors
      code = code || responseCode
      switch (code) {
        case 'REQUIRED':
          {
            const fieldErrors = metadata.reduce((previous, current) => {
              return {
                ...previous,
                [current]: 'Campo requerido'
              }
            }, {})

            fc.setCustomErrors({
              ...fieldErrors
            })
          }
          break

        case 'NOT_FOUND':
          break

        default:
          ShowFlashNotification(
            'danger',
            `Ha ocurrido un error, intenta mas tarde.`
          )
          break
      }
    }
  }

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  return !isLoading ? (
    userScoresSectionConfig === 'HIDDEN' ? (
      <Grid
        container
        direction="column"
        justify="center"
        css={`
          width: ${({ theme }) => theme.remCalc('630')};
          max-width: 100%;
          padding: ${({ theme }) => theme.remCalc('30')};
        `}
      >
        <Typography
          variant="h2"
          textTransform="initial"
          color="palette.primary.main"
          align="center"
        >
          Activa la sección de tus calificaciones
        </Typography>
        <AgencyBrokerStars
          css={`
            height: ${({ theme }) => theme.remCalc('200')};
          `}
        ></AgencyBrokerStars>
        <Typography
          variant="body4"
          align="center"
          css={`
            margin-top: ${({ theme }) => theme.remCalc('15')};
            width: ${({ theme }) => theme.remCalc('420')};
            max-width: 100%;
          `}
        >
          Para poder calificar a otros asesores es necesario que tu también
          actives esta sección en tu perfil.
        </Typography>
        <Grid
          container
          css={`
            margin-top: ${({ theme }) => theme.remCalc('18')};
          `}
        >
          <Grid item md={11}>
            <Button
              full
              type="tertiary"
              onClick={() =>
                ShowModal(
                  GetToKnowProfileScoresMC,
                  {
                    withModalHistory: true
                  },
                  null,
                  true
                )
              }
            >
              Conoce más
            </Button>
          </Grid>
          <Grid item md={12}>
            <Button
              full
              onClick={() =>
                UpdateProfileConfig(token, {
                  user_scores_section_config: 'SHOWN'
                })
              }
            >
              Activar calificaciones
            </Button>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <FormContainer
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={handleSubmitFail}
        css={`
          width: ${({ theme }) => theme.remCalc('500')};
          max-width: 100%;
          padding: ${({ theme }) => theme.remCalc('15 20 20')};

          ${({ theme }) => theme.media.desktop`
          width: ${({ theme }) => theme.remCalc('600')};
          padding: ${({ theme }) => theme.remCalc('30 60 40')};
        `}
        `}
      >
        <Grid container direction="column" justify="center">
          <Typography
            variant="h2"
            textTransform="initial"
            color="palette.primary.main"
            align="center"
          >
            Calificar Asesor
          </Typography>
          <Typography
            variant="body1"
            css={`
              margin: ${({ theme }) => theme.remCalc('8')};
            `}
            align="center"
          >
            ¿Cómo calificaría el trabajo de <b>{profileName}</b>?
          </Typography>

          <div
            css={`
              overflow: hidden;
              ${({ theme }) => theme.box(80, 80, true)}
              ${({ theme }) =>
                theme.bordered(
                  '7',
                  theme.palette.base.white
                )}
              display: inline-block;
              box-sizing: border-box;
              box-shadow: 0px 0px 10px rgba(54, 57, 70, 0.24);
              ${({ theme }) => theme.media.desktop`
              margin: ${({ theme }) => theme.remCalc('26')};
            `}
            `}
          >
            <Image id={profilePhoto}></Image>
          </div>
          <RatingField
            maxGrade={5}
            name="score"
            ratingElement={({ selected, ...props }) => {
              return (
                <StarRounded
                  {...props}
                  selected={selected}
                  css={`
                  ${({ theme }) => theme.box(40, 40)}
                  margin: ${({ theme }) => theme.remCalc('10 5')};
                  fill: ${({ theme, selected }) =>
                    selected
                      ? theme.palette.warning.main
                      : theme.palette.base.grayMedium};
                  color: ${({ theme, selected }) =>
                    selected
                      ? theme.palette.warning.main
                      : theme.palette.base.grayMedium};
                  transition: 200ms all linear;
                  cursor: pointer;
                `}
                />
              )
            }}
          ></RatingField>
          <Grid
            container
            direction="column"
            css={`
              ${({ theme }) => theme.media.desktop`
              max-width: 80%;
              `}
            `}
          >
            <TextField
              name="comment"
              label="Comentarios"
              placeholder="Excelente trabajo, tratos directos, propiedades con la información correcta."
              multiline
              css={`
              textarea {
                resize: none;
                height ${({ theme }) => theme.remCalc('110')};
              }
            `}
              required
              validate={isRequired}
            ></TextField>
          </Grid>
          <Grid
            container
            justify="space-between"
            css={`
              margin-top: ${({ theme }) => theme.remCalc('25')};
            `}
          >
            <Grid
              item
              flat
              md={11}
              css={`
                ${({ theme }) => theme.media.tabletDown`
                order: 2;
              `}
              `}
            >
              <Button type="tertiary" full onClick={HideModal}>
                Cancelar
              </Button>
            </Grid>
            <Grid
              item
              flat
              md={11}
              css={`
                ${({ theme }) => theme.media.tabletDown`
                margin-bottom: ${({ theme }) => theme.remCalc('5')};
              `}
              `}
            >
              <FormContext.Consumer>
                {({ values }) => (
                  <SubmitButton full disabled={!values['score']}>
                    Calificar
                  </SubmitButton>
                )}
              </FormContext.Consumer>
            </Grid>
          </Grid>
        </Grid>
      </FormContainer>
    )
  ) : (
    <Grid
      container
      direction="column"
      justify="center"
      css={`
        max-width: 100%;
        padding: ${({ theme }) => theme.remCalc('100')};
      `}
    >
      {' '}
      <Typography variant="subtitle1" align="center">
        {' '}
        Cargando
      </Typography>
      <Spinner
        css={`
          ${({ theme }) => theme.box(100, 100)}
        `}
      ></Spinner>
    </Grid>
  )
}

GradeAgencyBrokerMC.defaultProps = {
  initialValues: {
    score: undefined,
    comment: ''
  }
}

GradeAgencyBrokerMC.propTypes = {
  token: PropTypes.string,
  photo: PropTypes.string,
  fullname: PropTypes.string,
  profileId: PropTypes.string,
  profilePhoto: PropTypes.string,
  profileName: PropTypes.string,
  initialValues: PropTypes.shape({
    score: PropTypes.number,
    comment: PropTypes.string
  }),
  GradeProfile: PropTypes.func,
  HideModal: PropTypes.func,
  ShowModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  userScoresSectionConfig: PropTypes.oneOf([
    'HIDDEN',
    'SHOWN',
    'SCORE_WITHOUT_COMMENT'
  ]),
  UpdateProfileConfig: PropTypes.func
}

const mapStateToProps = ({
  authReducer: { token },
  profileReducer: { photo, fullname, userScoresSectionConfig }
}) => ({
  token,
  photo,
  fullname,
  userScoresSectionConfig
})

const mapDispatchToProps = {
  GradeProfile,
  HideModal,
  ShowModal,
  ShowFlashNotification,
  UpdateProfileConfig
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GradeAgencyBrokerMC)
