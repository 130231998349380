import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <svg width="175" height="44" viewBox="0 0 175 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4587 17.3858C20.4576 17.9566 20.2876 18.5142 19.97 18.9882C19.6523 19.4623 19.2015 19.8314 18.6743 20.0491C18.1471 20.2667 17.5672 20.323 17.0081 20.2109C16.4489 20.0988 15.9355 19.8233 15.5327 19.4193C15.13 19.0152 14.8559 18.5007 14.7453 17.9408C14.6346 17.3809 14.6923 16.8007 14.911 16.2736C15.1297 15.7465 15.4996 15.2961 15.974 14.9794C16.4484 14.6627 17.006 14.4938 17.5763 14.4941C18.3409 14.496 19.0735 14.8016 19.613 15.3439C20.1525 15.8861 20.4549 16.6206 20.4535 17.3858" fill="#FFC835" />
      <path d="M76.3149 2.64422C76.3163 2.11995 76.473 1.60786 76.7651 1.17268C77.0572 0.737498 77.4717 0.398775 77.9562 0.199331C78.4406 -0.000112842 78.9733 -0.0513238 79.4868 0.0521732C80.0003 0.15567 80.4717 0.409229 80.8413 0.780792C81.2108 1.15235 81.462 1.62524 81.5631 2.13966C81.6641 2.65408 81.6105 3.18695 81.4091 3.67089C81.2076 4.15483 80.8672 4.56811 80.4311 4.85849C79.9949 5.14887 79.4825 5.30332 78.9587 5.30229C78.2561 5.3 77.5832 5.01883 77.0875 4.5205C76.5919 4.02216 76.314 3.34737 76.3149 2.64422" fill="#FFC835" />
      <path d="M74.1246 8.70119L67.5385 27.6261L62.6936 27.6174L58.8965 15.4139L55.0493 27.6019L50.2044 27.5915L43.6943 8.64062L48.5392 8.64928L52.6406 20.5881L56.7905 8.66658L61.0284 8.67523L65.1298 20.6141L69.2797 8.69081L74.1246 8.70119Z" fill="#363946" />
      <path d="M76.5207 8.70193L76.4844 27.6406L81.3292 27.6499L81.3656 8.71124L76.5207 8.70193Z" fill="#363946" />
      <path d="M104.657 8.76051L104.622 26.1851C104.498 31.9442 99.6077 35.7202 94.8389 35.7029C88.2528 35.6908 85.6125 30.989 85.3133 30.1531L89.1381 28.1544C89.4026 28.9487 91.5934 31.3403 94.8441 31.3472C97.9564 31.3472 99.5818 28.5143 99.7737 26.4342C98.1937 27.4525 96.3515 27.9877 94.4723 27.9744C93.1662 28.0099 91.8662 27.7827 90.6494 27.3063C89.4326 26.8298 88.3237 26.1138 87.3885 25.2006C86.4532 24.2874 85.7106 23.1956 85.2047 21.99C84.6987 20.7843 84.4397 19.4893 84.4429 18.1816C84.4461 16.8739 84.7116 15.5801 85.2235 14.377C85.7355 13.1739 86.4835 12.0858 87.4232 11.1773C88.363 10.2687 89.4754 9.5582 90.6946 9.08778C91.9137 8.61736 93.2148 8.39662 94.5207 8.43864C96.3866 8.43753 98.2126 8.97858 99.7772 9.9961V8.74667L104.657 8.76051ZM99.606 18.2576C99.606 15.2292 97.5311 13.066 94.6954 13.066C91.8182 13.066 89.7692 15.2153 89.764 18.2437C89.764 21.3119 91.7974 23.4768 94.6746 23.482C97.5121 23.482 99.5991 21.3327 99.606 18.2628" fill="#363946" />
      <path d="M127.759 8.80904L127.719 26.2319C127.595 31.991 122.705 35.767 117.938 35.7497C111.35 35.7358 108.71 31.0358 108.411 30.1999L112.235 28.2012C112.5 28.9955 114.691 31.3871 117.941 31.394C121.043 31.394 122.677 28.5611 122.869 26.4811C121.391 27.4258 119.686 27.9552 117.933 28.014C116.18 28.0727 114.444 27.6586 112.906 26.815C111.368 25.9713 110.085 24.7293 109.191 23.2189C108.297 21.7085 107.826 19.9853 107.826 18.23C107.838 15.6403 108.873 13.1606 110.707 11.3332C112.54 9.50581 115.022 8.47941 117.609 8.47852C119.476 8.47792 121.303 9.01891 122.869 10.036V8.78655L127.759 8.80904ZM122.705 18.3044C122.705 15.276 120.63 13.1128 117.794 13.1128C114.917 13.1128 112.868 15.2621 112.863 18.2923C112.863 21.3605 114.896 23.5236 117.774 23.5305C120.613 23.5305 122.698 21.3812 122.705 18.3113" fill="#363946" />
      <path d="M130.708 18.2844C130.712 16.3517 131.289 14.4634 132.365 12.8585C133.441 11.2536 134.968 10.004 136.754 9.26785C138.539 8.53169 140.503 8.342 142.396 8.72276C144.29 9.10351 146.028 10.0376 147.39 11.4069C148.753 12.7763 149.68 14.5193 150.053 16.4157C150.426 18.312 150.229 20.2765 149.486 22.0607C148.744 23.8449 147.489 25.3687 145.882 26.4393C144.274 27.51 142.385 28.0794 140.454 28.0757C137.866 28.0642 135.388 27.0278 133.562 25.1929C131.736 23.3581 130.71 20.8742 130.708 18.2844V18.2844ZM145.579 18.3138C145.579 15.282 143.504 13.0808 140.668 13.0756C137.791 13.0756 135.742 15.2612 135.737 18.2948C135.732 21.3284 137.77 23.5227 140.646 23.5296C143.485 23.5296 145.572 21.3422 145.579 18.3138" fill="#363946" />
      <path d="M165.341 25.8537C165.341 25.8537 163.292 28.1207 159.999 28.1138C156.745 28.1138 154.25 26.5148 154.257 22.5761L154.276 13.2314H151.968V8.87568H154.276L154.288 2.55065L159.13 2.44336L159.118 8.88433L163.584 8.89471V13.2504L159.118 13.2418L159.102 21.1191C159.102 22.6765 160.006 23.6231 161.064 23.6248C162.351 23.6248 163.26 22.6038 163.262 21.9999L165.341 25.8537Z" fill="#363946" />
      <path d="M34.4953 18.2675C34.4452 18.2052 34.4227 18.1291 34.3656 18.0703L19.1618 2.79502C19.131 2.74201 19.0975 2.69061 19.0615 2.641C18.6487 2.24471 18.0989 2.02344 17.5269 2.02344C16.9549 2.02344 16.4051 2.24471 15.9923 2.641C15.9558 2.69059 15.9217 2.74199 15.8903 2.79502L0.631117 18.0028C0.425325 18.2093 0.264654 18.4564 0.159287 18.7284C0.05392 19.0004 0.006155 19.2913 0.0190201 19.5827C0.0190201 19.5827 0.0190201 19.5931 0.0190201 19.6L0 28.6437C0.000902218 29.1491 0.201306 29.6336 0.557558 29.9918C0.91381 30.35 1.39704 30.5527 1.902 30.5559H2.65588L32.4463 30.6148H33.202C33.7071 30.6139 34.1914 30.4133 34.5496 30.0568C34.9077 29.7003 35.1107 29.2167 35.1143 28.7112L35.1316 19.6693C35.1301 19.4039 35.0729 19.1417 34.9635 18.8999C34.8542 18.6581 34.6953 18.4421 34.497 18.2658L34.4953 18.2675ZM4.58381 20.0863L17.5191 7.19225L30.5599 20.2957L30.5443 26.3438L4.56998 26.2885L4.58381 20.0863Z" fill="#0076FF" />
      <path d="M173.007 23.3274C173.392 23.5456 173.711 23.8644 173.929 24.2497C174.151 24.6485 174.267 25.0973 174.267 25.5537C174.267 26.01 174.151 26.4588 173.929 26.8576C173.707 27.2474 173.386 27.5707 172.997 27.7938C172.601 28.0168 172.154 28.134 171.7 28.134C171.246 28.134 170.799 28.0168 170.403 27.7938C170.019 27.5702 169.7 27.2483 169.48 26.8611C169.258 26.4647 169.141 26.0178 169.141 25.5632C169.141 25.1086 169.258 24.6616 169.48 24.2653C169.7 23.8766 170.021 23.5545 170.409 23.3326C170.806 23.1014 171.258 22.9819 171.717 22.9865C172.17 22.9856 172.614 23.1031 173.007 23.3274V23.3274ZM172.745 27.382C173.059 27.1994 173.318 26.9351 173.495 26.6171C173.672 26.29 173.765 25.9239 173.765 25.5519C173.765 25.18 173.672 24.8139 173.495 24.4868C173.322 24.1744 173.067 23.9157 172.757 23.7392C172.433 23.555 172.066 23.4607 171.693 23.4658C171.321 23.4601 170.954 23.5559 170.632 23.7427C170.32 23.9234 170.063 24.1854 169.888 24.5007C169.713 24.8277 169.621 25.193 169.621 25.5641C169.621 25.9351 169.713 26.3004 169.888 26.6275C170.065 26.9467 170.325 27.2119 170.64 27.3948C170.956 27.5776 171.315 27.6713 171.679 27.6658C172.054 27.6704 172.422 27.5722 172.745 27.382V27.382ZM172.905 25.6843C172.811 25.8368 172.673 25.9574 172.509 26.0304L173.127 26.977H172.371L171.839 26.1619H171.337V26.9805H170.58V24.1511H171.811C172.135 24.1315 172.455 24.2285 172.713 24.4245C172.821 24.5128 172.907 24.6248 172.964 24.7518C173.021 24.8789 173.048 25.0174 173.042 25.1565C173.046 25.3417 172.999 25.5245 172.905 25.6843V25.6843ZM171.759 25.5926C171.9 25.6018 172.04 25.5621 172.155 25.4801C172.203 25.4415 172.241 25.392 172.266 25.3357C172.291 25.2794 172.302 25.218 172.298 25.1565C172.302 25.0943 172.29 25.0322 172.265 24.9752C172.241 24.9181 172.203 24.8676 172.155 24.8277C172.04 24.7445 171.9 24.7035 171.759 24.7118H171.33V25.5961L171.759 25.5926Z" fill="#363946" />
      <path d="M139.568 33.2102C140.231 33.4924 140.796 33.9633 141.193 34.5644C141.59 35.1655 141.802 35.8702 141.802 36.5908C141.802 37.3114 141.59 38.0161 141.193 38.6172C140.796 39.2183 140.231 39.6892 139.568 39.9714C138.79 40.298 137.951 40.4566 137.108 40.4369H135.638V42.9444H132.202V32.7395H137.108C137.952 32.7223 138.79 32.8827 139.568 33.2102V33.2102ZM137.938 37.4725C138.162 37.2337 138.286 36.9184 138.286 36.5908C138.286 36.2632 138.162 35.9479 137.938 35.7091C137.704 35.5014 137.355 35.3959 136.89 35.3959H135.638V37.7857H136.883C137.353 37.7823 137.705 37.6761 137.938 37.4673V37.4725Z" fill="#0076FF" />
      <path d="M149.308 41.1609H145.435L144.743 42.9399H141.257L145.711 32.7402H149.09L153.544 42.9399H149.993L149.308 41.1609ZM148.362 38.6845L147.371 36.1199L146.382 38.6845H148.362Z" fill="#0076FF" />
      <path d="M155.431 42.886C154.736 42.7389 154.07 42.4806 153.458 42.1211L154.565 39.6153C155.077 39.9088 155.625 40.1362 156.195 40.2919C156.757 40.457 157.339 40.5432 157.924 40.5481C158.269 40.5677 158.615 40.5207 158.943 40.4096C159.031 40.3811 159.107 40.3256 159.162 40.2512C159.217 40.1767 159.247 40.0868 159.249 39.9943C159.249 39.7797 159.116 39.6205 158.848 39.5132C158.418 39.3629 157.978 39.2471 157.53 39.1671C156.864 39.0348 156.208 38.8573 155.566 38.6358C155.039 38.444 154.568 38.1234 154.196 37.7031C153.809 37.2705 153.615 36.685 153.614 35.9466C153.609 35.3222 153.797 34.7116 154.153 34.1988C154.558 33.6383 155.116 33.2077 155.761 32.9598C156.587 32.6387 157.469 32.4852 158.355 32.5081C159.048 32.5078 159.739 32.5839 160.416 32.7348C161.04 32.8657 161.641 33.0891 162.199 33.3976L161.161 35.8878C160.287 35.4164 159.314 35.1574 158.322 35.1315C157.44 35.1315 156.997 35.3444 156.997 35.7718C156.997 35.976 157.129 36.1283 157.392 36.2304C157.814 36.3744 158.247 36.4843 158.687 36.5592C159.354 36.6766 160.011 36.8444 160.653 37.0611C161.182 37.252 161.657 37.5698 162.036 37.9869C162.428 38.4149 162.624 38.9975 162.626 39.7347C162.631 40.3576 162.443 40.9668 162.086 41.4773C161.678 42.0343 161.121 42.4635 160.478 42.7147C159.653 43.0407 158.771 43.1967 157.884 43.1732C157.058 43.1732 156.235 43.0768 155.431 42.886Z" fill="#0076FF" />
      <path d="M164.996 42.886C164.301 42.7389 163.635 42.4806 163.023 42.1211L164.13 39.6153C164.642 39.9088 165.19 40.1362 165.76 40.2919C166.322 40.457 166.904 40.5432 167.489 40.5481C167.834 40.5677 168.18 40.5207 168.508 40.4096C168.596 40.3811 168.672 40.3256 168.727 40.2512C168.782 40.1767 168.812 40.0868 168.814 39.9943C168.814 39.7797 168.681 39.6205 168.412 39.5132C167.983 39.3629 167.543 39.2471 167.095 39.1671C166.429 39.0348 165.773 38.8573 165.131 38.6358C164.603 38.4448 164.132 38.1241 163.761 37.7031C163.373 37.2705 163.179 36.685 163.179 35.9466C163.174 35.3222 163.362 34.7116 163.718 34.1988C164.122 33.6383 164.681 33.2077 165.326 32.9598C166.152 32.6387 167.034 32.4852 167.92 32.5081C168.613 32.5078 169.304 32.5839 169.981 32.7348C170.605 32.866 171.205 33.0894 171.763 33.3976L170.726 35.8878C169.852 35.4164 168.879 35.1574 167.887 35.1315C167.003 35.1315 166.561 35.345 166.562 35.7718C166.562 35.976 166.694 36.1283 166.957 36.2304C167.379 36.3744 167.812 36.4843 168.252 36.5592C168.919 36.6766 169.576 36.8444 170.218 37.0611C170.747 37.252 171.222 37.5698 171.601 37.9869C171.993 38.4149 172.189 38.9975 172.191 39.7347C172.196 40.3576 172.008 40.9668 171.651 41.4773C171.243 42.0343 170.686 42.4635 170.043 42.7147C169.218 43.0407 168.336 43.1967 167.449 43.1732C166.623 43.1732 165.8 43.0768 164.996 42.886Z" fill="#0076FF" />
    </svg>
  </Fragment>,
  'WiggotPassLogoBar',
  '0 0 175 44'
)