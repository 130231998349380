import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M106.32,19c10.43-4,19.78-11,30.91-14.94,9.92-3.48,21-4.34,31.09-1.07A32.07,32.07,0,0,1,186,16.61c10.33,17.14-5.64,36.38.05,54.61,4.39,14.09,17.44,22.36,24.35,34.82,7.86,14.18,8.76,32.57,2.74,47.71-14.46,36.35-65.31,50.17-94.65,28.71-12.43-9.09-21.27-21.58-36.81-24.53-15.27-2.91-31.46,1.43-46.28-3.44-19-6.23-31.49-27-33.52-48.1C-.78,78.83,11.11,43.63,31.33,25.58c7.51-6.7,17.34-11.36,27.21-11.16,10.18.21,19.12,6.18,29.13,7.27A39.33,39.33,0,0,0,106.32,19Z"
      style={{ fill: '#c3ecfe', 'fill-opacity': 0.5 }}
    />
    <polygon
      points="99.94 66.76 99.88 74.89 102.76 74.87 102.79 66.77 99.94 66.76"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="103.5 74.9 106.09 74.91 106.12 66.78 103.56 66.76 103.5 74.9"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="97.27 80.75 97.5 80.75 97.44 88.96 105.24 88.99 105.31 80.82 105.5 80.82 105.52 79.64 97.3 79.57 97.27 80.75"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="108.58 74.76 108.51 89.03 108.62 74.76 108.58 74.76"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="99.14 66.77 96.52 66.76 96.46 74.89 99.11 74.87 99.14 66.77"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="99.22 56.16 94.5 62.21 94.5 62.21 94.5 62.24 94.48 63.82 94.5 62.21 108.8 62.28 94.5 62.21 99.22 56.16"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="118.04 72.85 118.12 62.34 118.02 72.85 118.04 72.85"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="113.49 56.2 108.8 62.28 113.49 56.2 113.49 56.2"
      style={{ fill: '#fff' }}
    />
    <path
      d="M143.89,47.9a5.1,5.1,0,0,0-5.07-5.08h-.18L78.8,43a5.13,5.13,0,0,0-5.06,5.13l.09,88.34a5.09,5.09,0,0,0,5.07,5.07h.18l59.85-.22a5.16,5.16,0,0,0,5.09-5.13Zm-7.5,41.3-12-.1L108.51,89,94.3,89,81.92,89a1.73,1.73,0,0,1-2-1.69l-.3-36.58a2,2,0,0,1,2-2l54.48-.44a2,2,0,0,1,2,2l.3,36.58A2.42,2.42,0,0,1,136.39,89.2Z"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="121.19 77.81 121.16 85.94 121.16 85.94 121.19 77.81 118.63 77.8 121.19 77.81"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="114.18 77.8 114.15 85.93 114.18 77.8 111.55 77.79 114.18 77.8"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="114.98 77.79 117.83 77.8 117.83 77.8 114.98 77.79 114.98 77.79"
      style={{ fill: '#fff' }}
    />
    <path
      d="M148.11,43.72a5.22,5.22,0,0,0-5.21-5.17l-5.7,0V34.17A5.2,5.2,0,0,0,132,29l-68.19.27a5.25,5.25,0,0,0-5.19,5.23l.16,96.78a5.22,5.22,0,0,0,5.2,5.17l5.71,0v4.41a5.2,5.2,0,0,0,5.2,5.17l68.18-.27a5.18,5.18,0,0,0,5.19-5.19Zm-78.58.33.14,88H68.16A5.09,5.09,0,0,1,63.09,127L63,38.63a5.13,5.13,0,0,1,5.06-5.13l59.84-.23A5.09,5.09,0,0,1,133,38.35v.24l-58.25.24A5.23,5.23,0,0,0,69.53,44.05Zm69.4,97.32-59.85.22H78.9a5.09,5.09,0,0,1-5.07-5.07l-.09-88.34A5.13,5.13,0,0,1,78.8,43l59.84-.23h.18a5.1,5.1,0,0,1,5.07,5.08l.13,88.34A5.16,5.16,0,0,1,138.93,141.37Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M129.2,129.94,88,130.12a1.89,1.89,0,0,1-1.89-1.87v-7a1.88,1.88,0,0,1,1.86-1.9l41.22-.18a1.9,1.9,0,0,1,1.89,1.88v7A1.86,1.86,0,0,1,129.2,129.94Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M129.8,110.14l-29.06.13,0-3.69,29.07-.17Z"
      style={{ fill: '#81caf7' }}
    />
    <path
      d="M129.75,102.43l-29,.14,0-3.69,29.07-.17Z"
      style={{ fill: '#81caf7' }}
    />
    <path
      d="M69.53,44.05l.14,88H68.16A5.09,5.09,0,0,1,63.09,127L63,38.63a5.13,5.13,0,0,1,5.06-5.13l59.84-.23A5.09,5.09,0,0,1,133,38.35v.24l-58.25.24A5.23,5.23,0,0,0,69.53,44.05Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M163.14,162.43l3.9-3.2-8.49-9.92-3.72,3Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M162.59,165l17.08,20.66a4.59,4.59,0,0,0,6.31.71,4.4,4.4,0,0,0,.75-6.19l-17.08-20.66a4.58,4.58,0,0,0-6.31-.71A4.35,4.35,0,0,0,162.59,165Z"
      style={{ fill: '#171d3e' }}
    />
    <path
      d="M125.1,112.12l1-.75.21,0a28.33,28.33,0,0,1,18.94-4.81c14.56,1.61,25.35,14.61,23.86,28.88l-.11.86-.14.64a26.71,26.71,0,0,1-20.07,22l-.63.08-.85.1a27.89,27.89,0,0,1-8.32.37A26.22,26.22,0,0,1,115,131l.11-.86.13-.64A26.29,26.29,0,0,1,125.1,112.12Zm39.4,22.59c1.37-11.74-7.22-22.48-19.67-24.35a22,22,0,0,0-15.81,4l-.59.49-.77.73a22,22,0,0,0-7.93,14.21l-.11.85-.11.86c-.36,11.19,8.13,21.11,19.92,22.84a21.27,21.27,0,0,0,7.26-.25l.84-.1.82-.31a23.6,23.6,0,0,0,15.93-17.29l.11-.85Z"
      style={{ fill: '#171d3e', 'fill-rule': 'evenodd' }}
    />
    <polygon
      points="105.31 80.74 105.31 80.73 97.5 80.7 97.44 89 105.31 80.74"
      style={{ fill: '#4fb8dc' }}
    />
    <polygon
      points="97.3 79.57 97.27 80.75 97.5 80.75 97.44 88.96 97.44 88.96 97.5 80.67 105.31 80.7 105.31 80.82 105.5 80.82 105.52 79.64 97.3 79.57"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M94.3,89l.18-25.14h0l0-1.58v0h0l4.72-6.05,14.27,0h0l4.63,6.14L118,72.86l6,0v1.95h.44l-.11,14.24,12,.1a2.42,2.42,0,0,0,2.05-2.39l-.3-36.58a2,2,0,0,0-2-2l-54.48.44a2,2,0,0,0-2,2L80,87.29a1.73,1.73,0,0,0,2,1.69Z"
      style={{ fill: '#d9dde7' }}
    />
    <path
      d="M113.49,56.2l-14.27,0L94.5,62.21l14.3.08Z"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="113.49 56.2 108.8 62.28 94.5 62.21 94.48 63.82 108.63 63.89 108.55 72.8 118.02 72.87 118.12 62.34 113.49 56.2"
      style={{ fill: '#ffc834' }}
    />
    <path d="M124,74.86l-15.51-.1v-2l15.49.1Z" style={{ fill: '#0076cc' }} />
    <path
      d="M108.62,74.76,108.51,89l15.84.07.11-14.24Zm5.53,11.17h-2.66l.06-8.13h2.63Zm3.65,0-2.88,0,.06-8.14h2.85Zm3.36,0h-2.6l.07-8.13h2.56Z"
      style={{ fill: '#f2f2f2' }}
    />
    <path d="M114.15,85.93h-2.66l.06-8.13h2.63Z" style={{ fill: '#0076cc' }} />
    <path d="M117.8,85.9l-2.88,0,.06-8.14h2.85Z" style={{ fill: '#0076cc' }} />
    <path d="M121.16,85.94h-2.6l.07-8.13h2.56Z" style={{ fill: '#0076cc' }} />
    <path
      d="M94.48,63.82,94.3,89l14.21.07.12-25.14Zm5.46,2.94h2.85l0,8.1-2.88,0Zm-3.42,0h2.62l0,8.1-2.65,0Zm9,14.06h-.2L105.24,89l-7.8,0,.06-8.21h-.23l0-1.18,8.22.07Zm.59-5.91H103.5l.06-8.14,2.56,0Z"
      style={{ fill: '#e4e6e4' }}
    />
    <polygon
      points="99.11 74.87 96.46 74.89 96.52 66.76 99.14 66.77 99.11 74.87"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="102.76 74.87 99.88 74.89 99.94 66.76 102.79 66.77 102.76 74.87"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="106.09 74.91 103.5 74.9 103.56 66.76 106.12 66.78 106.09 74.91"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="97.44 88.96 97.44 88.96 105.24 89 105.31 80.7 97.44 88.96"
      style={{ fill: '#4fb8dc', opacity: 0.5, isolation: 'isolate' }}
    />
  </Fragment>,
  'ZeroNotifications',
  '0 0 218 195'
)
