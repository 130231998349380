import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <rect width="30" height="30" rx="15" fill="transparent" />
    <path
      d="M14.2163 15.2416C14.2163 15.5732 14.7379 15.8011 15.0508 15.6768L16.4276 15.138C16.8031 14.993 16.8449 14.7029 16.8449 14.3092V12.2371C18.9518 11.6777 20.4956 9.75066 20.4956 7.4921C20.5164 4.77769 18.3051 2.5813 15.5932 2.5813C14.1329 2.5813 12.8395 3.20292 11.9425 4.19751C11.1497 5.06778 10.6699 6.22814 10.6699 7.4921C10.6699 7.57499 10.6699 7.65787 10.6699 7.74075C10.6908 7.9894 10.7116 8.21733 10.7534 8.46598C10.7534 8.4867 10.7534 8.52814 10.7742 8.54886C11.1706 10.3516 12.4223 11.7813 14.2163 12.2371V15.2416ZM15.614 10.0615C14.1329 10.0615 12.9855 8.81823 13.1316 7.32634C13.2359 6.2903 14.0077 5.37859 15.0299 5.15067C16.6571 4.77769 18.1174 6.00021 18.1174 7.57499C18.1174 8.96327 16.97 10.0615 15.614 10.0615Z"
      fill="currentColor"
    />
    <path
      d="M16.8451 16.651V18.4951C16.8451 19.0546 16.6991 19.2618 16.1567 19.469L12.5268 20.8158C12.0262 21.0023 11.5881 21.0437 11.2543 20.8573L10.0026 20.3185C9.52281 20.1113 9.50195 19.8005 9.50195 19.3032V18.9302C9.50195 18.4122 9.85659 18.2465 10.3573 18.06L15.9689 15.9879C16.5531 15.7807 16.8451 16.0293 16.8451 16.651Z"
      fill="currentColor"
    />
    <path
      d="M16.8451 20.6912V23.0326C16.8451 23.2605 16.7825 23.5092 16.4279 23.6335L12.1305 25.1876C12.0053 25.229 11.7341 25.3533 11.3169 25.1668L9.85659 24.5867C9.64798 24.5038 9.50195 24.2966 9.50195 24.0686V22.9704C9.50195 22.7425 9.64798 22.5353 9.85659 22.4524L16.0941 20.1524C16.4488 20.0281 16.8451 20.2975 16.8451 20.6912Z"
      fill="currentColor"
    />
    <path
      d="M16.888 24.8562L16.8671 27.0111C16.8671 27.4048 16.6585 27.5084 16.3039 27.3427L13.571 26.1823C13.1121 25.9958 13.0912 25.4363 13.7797 25.2913L16.3039 24.3796C16.6585 24.2345 16.888 24.4832 16.888 24.8562Z"
      fill="currentColor"
    />
  </Fragment>,
  'I24',
  '0 0 30 30'
)
