var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _jsxFileName = 'src/lib/elements/FormInputs/Checkbox.js';

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox as CheckboxView } from '../Checkboxes';
import { default as InputLabelContainer } from './InputLabelContainer';
import { inputProp, inputLabelContainerProp } from './_types';
var Checkbox = (_temp = _class = function (_Component) {
  _inherits(Checkbox, _Component);

  function Checkbox(props) {
    _classCallCheck(this, Checkbox);

    var _this = _possibleConstructorReturn(this, (Checkbox.__proto__ || Object.getPrototypeOf(Checkbox)).call(this, props));

    _this.onChangeCT = function (value) {
      var onChange = _this.props.onChange;

      if (onChange) {
        onChange(value);
      }
    };

    _this.onChangeRF = function (value) {
      var onChange = _this.props.onChange;
      var onChangeForm = _this.props.input.onChange;

      onChangeForm(!value);
      onChange(!value);
    };

    _this.inputProp = function () {
      var _this$props = _this.props,
          inputContainerLabel = _this$props.label,
          componentType = _this$props.componentType,
          labelProps = _this$props.labelProps,
          className = _this$props.className,
          label = _this$props.checkboxLabel,
          meta = _this$props.meta,
          input = _this$props.input,
          restProps = _objectWithoutProperties(_this$props, ['label', 'componentType', 'labelProps', 'className', 'checkboxLabel', 'meta', 'input']);

      var handledProps = {};
      switch (componentType) {
        case 'CT':
          handledProps = {
            label: label,
            onClick: _this.onChangeCT,
            className: className
          };
          break;
        case 'RF':
          handledProps = {
            error: meta.error,
            label: label,
            onBlur: function onBlur() {
              return input.onBlur();
            },
            onClick: _this.onChangeRF,
            onFocus: function onFocus() {
              return input.onFocus();
            },
            touched: meta.touched,
            value: input.value
          };
          break;
      }

      return Object.assign({}, handledProps, restProps);
    };

    return _this;
  }

  _createClass(Checkbox, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          labelProps = _props.labelProps,
          label = _props.label,
          isRequired = _props.isRequired,
          checkboxType = _props.checkboxType,
          disabled = _props.disabled;

      return React.createElement(
        InputLabelContainer,
        Object.assign({
          isRequired: isRequired,
          label: label
        }, labelProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 114
          },
          __self: this
        }),
        React.createElement(CheckboxView, Object.assign({
          checkboxType: checkboxType,
          className: className,
          disabled: disabled
        }, this.inputProp(), {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 119
          },
          __self: this
        }))
      );
    }
  }]);

  return Checkbox;
}(Component), _class.propTypes = {
  /** */
  checkboxLabel: PropTypes.string,
  /** */
  checkboxType: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  componentType: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** */
  initialValue: PropTypes.any,
  /** */
  input: inputProp,
  /** */
  isRequired: PropTypes.bool,
  /** */
  label: PropTypes.string,
  /** See InputLabelContainer */
  labelProps: inputLabelContainerProp,
  /** */
  meta: PropTypes.object,
  /** */
  multiSelectable: PropTypes.bool,
  /** */
  name: PropTypes.string,
  /** */
  onChange: PropTypes.func,
  /** */
  value: PropTypes.any
}, _class.defaultProps = {
  disabled: false,
  label: '',
  onChange: function onChange() {
    return null;
  }
}, _temp);


export default Checkbox;