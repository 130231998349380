import { createReducer } from '../store'
import { snakeToCamelCase } from 'utils/helpers'

export const updateAssociation = (state, action) => {
  let { payload: { data = {} } = {} } = action
  const responseFormatted = snakeToCamelCase(data)

  let {
    associationName = state.associationName,
    associationPhoneNumber = state.associationPhoneNumber,
    associationPhoneNumber2 = state.associationPhoneNumber2,
    associationLogo = state.associationLogo,
    associationBusinessName = state.associationBusinessName,
    associationEmail = state.associationEmail,
    associationState = state.associationState,
    associationCity = state.associationCity,
    associationAddress = state.associationAddress
  } = responseFormatted

  return {
    ...state,
    associationName,
    associationPhoneNumber,
    associationPhoneNumber2,
    associationLogo,
    associationBusinessName,
    associationEmail,
    associationState,
    associationCity,
    associationAddress,
    hasLoaded: true
  }
}

const initialState = {
  associationName: null,
  associationPhoneNumber: null,
  associationPhoneNumber2: null,
  associationLogo: null,
  associationBusinessName: null,
  associationEmail: null,
  associationState: null,
  associationCity: null,
  associationAddress: null,
  hasLoaded: false
}

const associationReducer = createReducer(
  {
    ...initialState
  },
  {
    GET_ASSOCIATION_INFO_SUCCESS: updateAssociation
  }
)

export default associationReducer
