import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { TextField, PhoneInput, Select, withForm } from 'rc'

import { USER_GENDER_OPTIONS } from 'utils/constants'
import { LocationSelectFields } from 'components'

const BrokerAccountForm = ({ isLoading, fc: { values = {} } = {} }) => {
  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  return (
    <StepContainer>
      <Select
        required
        placeholder="Género"
        name="gender"
        optionLabelKey="label"
        optionValueKey="value"
        options={USER_GENDER_OPTIONS}
        validate={isRequired}
        disabled={isLoading}
      />
      <TextField
        fullWidth
        required
        type="date"
        label="Fecha de nacimiento"
        name="birthdate"
        validate={isRequired}
        InputProps={{ placeholder: 'dd-mm-yyyy' }}
        disabled={isLoading}
      />
      <PhoneInput
        required
        type="text"
        name="phone_number"
        label="Teléfono"
        validate={isRequired}
        disabled={isLoading}
      />
      <TextField
        fullWidth
        required
        label="C.P."
        name="postal_code"
        validate={isRequired}
        disabled={isLoading}
        css={`
          margin-bottom: 30px;
        `}
      />
      <LocationSelectFields
        direction="column"
        optionsHeight="150"
        stateFieldConfig={{
          name: 'state',
          gridItem: { flat: true, md: 11 },
          disabled: isLoading
        }}
        cityFieldConfig={{
          name: 'city',
          gridItem: { flat: true, md: 11 },
          disabled: isLoading,
          isHiddenCity: true
        }}
        neighborhoodFieldConfig={{ isHidden: true }}
        itemStateCss={`margin: 0 0 20px;`}
        itemCityCss={`margin: 0 0 20px;`}
      />
      <TextField
        fullWidth
        required
        label="Ciudad"
        name="city"
        validate={isRequired}
        disabled={isLoading || !values?.state}
        css={`
          margin-bottom: 30px;
          margin-top: -10px !important;
        `}
      />
    </StepContainer>
  )
}

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  > *:not(:nth-child(4)) {
    margin: ${({ theme }) => theme.remCalc('0 0 20')};
  }
`

BrokerAccountForm.propTypes = {
  isLoading: PropTypes.bool,
  fc: PropTypes.object
}

export default withForm(BrokerAccountForm)
