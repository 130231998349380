import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styled from 'styled-components'
import { Grid, Typography, FormContainer, TextField, Spinner } from 'rc'
import { Checkmark } from 'rc/Icons'
import { Button, SubmitButton } from 'components'
import { RenovatePrime } from 'reduxActions/prime'
import { ShowFlashNotification, HideModal } from 'reduxActions/services'

import SendRenovationPrime from 'static/img/send_renovation_prime.png'
import BackgroundPrime from 'static/img/background_prime.png'

import { WiggotPrimeLogo } from 'components/Icons'

const SendPrimeRenovation = ({
  HideModal,
  ShowFlashNotification,
  token,
  RenovatePrime,
  callBackAction
}) => {
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = values => {
    setIsLoading(true)

    const required_fields = ['name', 'email', 'phone']
    const fields_with_errors = required_fields.filter(
      field =>
        !values[field] ||
        (values[field] &&
          typeof values[field] === 'string' &&
          values[field].trim().length === 0)
    )

    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }
    return RenovatePrime(token, values)
      .then(res => {
        setIsLoading(false)
        setSuccess(true)
      })
      .catch(err => {
        setIsLoading(false)

        ShowFlashNotification(
          'danger',
          'Ha ocurrido un error al activar tu cuenta prime de prueba, intenta de nuevo.'
        )
      })
  }

  const handleSubmitSuccess = value => {}

  const handleSubmitFail = (values, fc, submitErrors) => {
    setIsLoading(false)

    if (submitErrors) {
      const { code, metadata } = submitErrors

      switch (code) {
        case 'REQUIRED':
          {
            const fieldErrors = metadata.reduce((previous, current) => {
              return {
                ...previous,
                [current]: 'Campo requerido'
              }
            }, {})

            fc.setCustomErrors({
              ...fieldErrors
            })
          }
          break

        case 'CUSTOM':
          fc.setCustomErrors({
            ...metadata
          })
          break
        default:
          break
      }
    }
  }

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  return success ? (
    <Base
      container
      direction="column"
      alignItems="space-between"
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="space-between"
        css={`
          height: ${({ theme }) => theme.remCalc('180')};
          background-image: url(${BackgroundPrime});
          background-size: cover;
          background-repeat: no-repeat;
          ${({ theme }) => theme.media.desktop`
          background-size: contain;
          height: ${({ theme }) => theme.remCalc('260')};
        `}
        `}
      />
      <Grid
        container
        justify="center"
        alignItems="space-around"
        direction="column"
        css={`
          position: absolute;
          width: 100%;
          height: 100%;
        `}
      >
        <Grid
          container
          direction="column"
          alignItems="space-between"
          css={`
            width: 100%;
            height: 100%;
          `}
        >
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            css={`
              height: ${({ theme }) => theme.remCalc('180')};
              ${({ theme }) => theme.media.desktop`
              height: ${({ theme }) => theme.remCalc('260')};
            `}
            `}
          >
            <Grid container justify="space-around">
              <Grid container direction="column" justify="center">
                <WiggotPrimeLogo
                  css={`
                    height: ${({ theme }) => theme.remCalc('40')};
                    margin-top: ${({ theme }) => theme.remCalc('20')};
                  `}
                />
                <Typography
                  variant="h2"
                  color="white"
                  textTransform="initial"
                  align="center"
                  css={`
                    font-size: ${({ theme }) => theme.remCalc('25')};
                    margin-top: ${({ theme }) => theme.remCalc('20')};
                    display: none;
                    ${({ theme }) => theme.media.desktop`
                    display: block;
                  `}
                  `}
                >
                  {'¡Tu solicitud de renovación ha sido enviada exitósamente! '}
                </Typography>
                <CheckmarkContainerStyled container center>
                  <CheckmarkStyled />
                </CheckmarkContainerStyled>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="space-around"
            direction="column"
            css={`
              height: ${({ theme }) => theme.remCalc('305')};
              ${({ theme }) => theme.media.desktop`
              height: ${({ theme }) => theme.remCalc('180')};
              margin-bottom: ${({ theme }) => theme.remCalc('10')};
            `}
            `}
          >
            <Grid
              container
              center
              css={`
                width: 85%;
              `}
            >
              <Typography
                variant="h2"
                textTransform="initial"
                align="center"
                css={`
                  display: block;
                  margin-bottom: ${({ theme }) => theme.remCalc('20')};
                  line-height: 1;
                  ${({ theme }) => theme.media.desktop`
                    display: none;
                  `}
                `}
              >
                ¡Tu solicitud de renovación ha sido enviada exitósamente!
              </Typography>
              <Typography
                variant="body1"
                css={`
                  text-align: center;
                `}
              >
                En breve un asesor de wiggot se pondrá en contacto contigo para
                dar seguimiento a tu cuenta.
              </Typography>
            </Grid>
            <Button
              onClick={() => {
                HideModal()
                callBackAction && callBackAction()
              }}
              css={`
                height: ${({ theme }) => theme.remCalc('50')};
                width: ${({ theme }) => theme.remCalc('220')};
              `}
              id="components-wiggotPrime-mc-button-welcomeToPrimeAccept"
            >
              Aceptar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Base>
  ) : (
    <GridContainerStyled container alignItems="space-between">
      {isLoading && (
        <Grid
          container
          justify="center"
          alignItems="center"
          css={`
            width: ${({ theme }) => theme.remCalc('600')};
            height: ${({ theme }) => theme.remCalc('450')};
          `}
        >
          <Spinner
            css={`
              ${({ theme }) => theme.box(100, 100)}
            `}
          />
        </Grid>
      )}
      <Grid
        container
        justify="center"
        css={`
          width: 100%;
          background-color: #f5f5f5;
          height: ${({ theme }) => theme.remCalc('170')};
          flex-direction: column-reverse;
          padding: ${({ theme }) => theme.remCalc('10')};
          align-items: center;
          ${({ theme }) => theme.media.desktop`
            flex-direction: column;
            align-items: center;
            width: 50%;
            height: 100%;
          `}
        `}
      >
        <Typography
          variant="h1"
          color="palette.primary.main"
          css={`
            font-size: ${({ theme }) => theme.remCalc('21')};
            display: none;
            ${({ theme }) => theme.media.desktop`
              font-size: ${({ theme }) => theme.remCalc('32')};
              margin-top: ${({ theme }) => theme.remCalc('30')};
              display: block;
            `}
          `}
        >
          ¡Contrata tu plan!
        </Typography>
        <Typography
          align="center"
          css={`
            font-weight: 500;
            font-size: ${({ theme }) => theme.remCalc('16')};
            line-height: 1;
            ${({ theme }) => theme.media.desktop`
              line-height: 1.3;
              font-size: ${({ theme }) => theme.remCalc('21')};
              margin-top: ${({ theme }) => theme.remCalc('30')};
            `}
          `}
        >
          Dinos cuantos asesores forman parte de tu equipo y nos comunicaremos
          contigo.
        </Typography>
        <Typography
          variant="h1"
          color="palette.primary.main"
          css={`
            font-size: ${({ theme }) => theme.remCalc('21')};
            display: block;
            ${({ theme }) => theme.media.desktop`
              display: none;
            `}
          `}
        >
          ¡Contrata tu plan!
        </Typography>
        <Grid
          css={`
            background-image: url(${SendRenovationPrime});
            background-size: contain;
            background-repeat: no-repeat;
            ${({ theme }) => theme.box(85, 65)}
            ${({ theme }) => theme.media.desktop`
              margin-top: ${({ theme }) => theme.remCalc('30')};
              ${({ theme }) => theme.box(200, 180)}
            `}
          `}
        />
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="space-around"
        direction="column"
        item
        css={`
          width: 100%;
          height: 65%;
          ${({ theme }) => theme.media.desktop`
            height: 100%;
            width: 50%;
          `}
        `}
      >
        <FormContainer
          css={`
            width: 100%;
            height: 100%;
          `}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFail}
        >
          <Grid
            container
            direction="column"
            alignItems="space-between"
            css={`
              height: 100%;
            `}
          >
            <Grid container>
              <TextFieldStyled
                id="profile-agency-mc-input-editAgencyName"
                fullWidth
                type="text"
                name="name"
                label="Nombre completo"
                required
                validate={isRequired}
              />
              <TextFieldStyled
                id="profile-agency-mc-input-editAgencyName"
                fullWidth
                type="text"
                name="email"
                label="Correo"
                required
                validate={isRequired}
              />
              <TextFieldStyled
                id="profile-agency-mc-input-editAgencyName"
                fullWidth
                type="tel"
                name="phone"
                label="Teléfono"
                required
                validate={isRequired}
              />
              <TextFieldStyled
                id="profile-agency-mc-input-editAgencyName"
                fullWidth
                type="text"
                name="brokers_count"
                label="Cantidad de asesores"
                required
                validate={isRequired}
              />
            </Grid>
            <Grid container justify="space-between">
              <Button
                type="tertiary"
                css={`
                  ${({ theme }) => theme.box(130, 50)}
                `}
                onClick={() => {
                  HideModal()
                  callBackAction && callBackAction()
                }}
              >
                Cancelar
              </Button>
              <SubmitButton
                css={`
                  ${({ theme }) => theme.box(130, 50)}
                `}
              >
                Enviar
              </SubmitButton>
            </Grid>
          </Grid>
        </FormContainer>
      </Grid>
    </GridContainerStyled>
  )
}

SendPrimeRenovation.propTypes = {
  token: PropTypes.string,
  HideModal: PropTypes.func,
  RenovatePrime: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  callBackAction: PropTypes.func
}

const Base = styled(Grid)`
  width: ${({ theme }) => theme.remCalc('600')};
  max-width: 100%;
  position: relative;
  height: ${({ theme }) => theme.remCalc('500')};
  ${({ theme }) => theme.media.desktop`
    height: ${({ theme }) => theme.remCalc('450')};
  `}
`

const GridContainerStyled = styled(Grid)`
  width: ${({ theme }) => theme.remCalc('640')};
  max-width: 100%;
  flex-direction: column;
  height: ${({ theme }) => theme.remCalc('530')};
  ${({ theme }) => theme.media.desktop`
    height: ${({ theme }) => theme.remCalc('450')};
    flex-direction: row;
  `}
`

const TextFieldStyled = styled(TextField)`
  ${({ theme }) => theme.media.desktop`
    margin-bottom: ${({ theme }) => theme.remCalc('10')};
  `}
`
const CheckmarkContainerStyled = styled(Grid)`
  background-color: #6fcf97;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  ${({ theme }) => theme.bordered(5, 'white')}
  ${({ theme }) => theme.box(85, 85, true)}
  margin-top: ${({ theme }) => theme.remCalc('25')};
  ${({ theme }) => theme.media.desktop`
    margin-top: 0;
  `}
`

const CheckmarkStyled = styled(Checkmark)`
  fill: white;
  ${({ theme }) => theme.box(70, 70)}
`

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  RenovatePrime,
  HideModal,
  ShowFlashNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(SendPrimeRenovation)
