import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M12 9.5V3.5L9 0.5L6 3.5V5.5H0V19.5H18V9.5H12ZM4 17.5H2V15.5H4V17.5ZM4 13.5H2V11.5H4V13.5ZM4 9.5H2V7.5H4V9.5ZM10 17.5H8V15.5H10V17.5ZM10 13.5H8V11.5H10V13.5ZM10 9.5H8V7.5H10V9.5ZM10 5.5H8V3.5H10V5.5ZM16 17.5H14V15.5H16V17.5ZM16 13.5H14V11.5H16V13.5Z"
      fill="currentColor"
    />
  </>,
  'Building',
  '0 0 18 20'
)
