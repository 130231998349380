import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g fill="none" fillRule="evenodd">
      <defs>
        <filter
          id="a"
          width="166.7%"
          height="157.1%"
          x="-33.3%"
          y="-22.9%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="3"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.592 0 0 0 0 0.592 0 0 0 0 0.592 0 0 0 0.774314991 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#a)"
        transform="translate(6 4)"
      >
        <path
          fill="#2975B8"
          d="M0 3.467v25.46h10.755l4.231 6.061 4.231-6.06h10.755V3.468z"
        />
        <path fill="#2975B8" d="M0 28.928h29.972V0H0z" />
        <path
          fill="#FFC835"
          d="M16.558 14.64c.003.88-.699 1.596-1.567 1.599a1.583 1.583 0 0 1-1.577-1.59c-.003-.88.699-1.596 1.567-1.599a1.583 1.583 0 0 1 1.577 1.59"
        />
        <path
          fill="#FEFEFE"
          d="M7.898 16.177l7.017-7.16 7.146 7.202.01 3.34-14.163.044-.01-3.426zm16.304-1.09c-.027-.034-.04-.076-.07-.108L15.8 6.58c-.022-.026-.031-.06-.056-.085a1.149 1.149 0 0 0-.837-.338 1.149 1.149 0 0 0-.836.344c-.024.025-.033.058-.055.085l-8.28 8.449c-.236.24-.341.559-.33.874l-.002.01.015 4.996a1.05 1.05 0 0 0 1.044 1.052l.285-.001h.127l16.244-.05.289-.002h.123a1.051 1.051 0 0 0 1.037-1.058l-.015-4.997c-.001-.308-.14-.58-.35-.773z"
        />
      </g>
    </g>
  </Fragment>,
  'MapMarker',
  '0 0 42 46'
)
