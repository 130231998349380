import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import PropTypes from 'prop-types'
import * as amplitude from '@amplitude/analytics-browser'

import { AmplitudeEvents } from 'utils/amplitudeEvents'

/* The base context to feed the provider */
const AmplitudeContext = createContext({
  trackEvent: () => null,
  isInitialized: false,
  AmplitudeEvents
})

/* A simple hook for use the context of the amplitude provider */
export const useAmplitude = () => {
  return useContext(AmplitudeContext)
}

/* The amplitude provide to config and tracking events */
export const AmplitudeProvider = ({ children, userId, amplitudeKey }) => {
  const [isInitialized, setIsInitialized] = useState(false)
  useEffect(() => {
    const initializeAmplitude = () => {
      if (!amplitudeKey || amplitudeKey === '') {
        return
      }
      if (amplitude.getUserId() !== userId) {
        amplitude.setUserId(userId ?? null)
      }
      amplitude.init(amplitudeKey, userId, {
        includeReferrer: true,
        disableCookies: true,
        defaultTracking: true,
        flushQueueSize: 1
      })
    }
    initializeAmplitude()
    setIsInitialized(true)
  }, [userId, amplitudeKey])

  const trackEvent = ({ name, eventProperties = {}, configs }) => {
    const { pathname } = window.location
    if (configs) {
      if (configs.path) {
        eventProperties.path = pathname
      }
      if (configs.login) {
        eventProperties.login = Boolean(userId)
      }
    }
    amplitude.logEvent(name, eventProperties)
  }
  const contextValue = useMemo(
    () => ({ trackEvent, isInitialized, AmplitudeEvents }),
    [trackEvent, isInitialized]
  )
  return (
    <AmplitudeContext.Provider value={contextValue}>
      {children}
    </AmplitudeContext.Provider>
  )
}

AmplitudeProvider.propTypes = {
  children: PropTypes.node,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  amplitudeKey: PropTypes.string
}
