export const GetAddressStates = (Authorization, search = '', params = {}) => ({
  type: 'GET_ADDRESS_STATES',
  payload: {
    request: {
      headers: {
        Authorization
      },
      method: 'get',
      url: `/autocomplete/state/${search}`,
      params
    }
  }
})

export const GetAddressCities = (Authorization, state, search = '') => ({
  type: 'GET_ADDRESS_CITIES',
  payload: {
    request: {
      headers: {
        Authorization
      },
      method: 'get',
      url: `/autocomplete/state/${state}/city/${search}`
    }
  }
})

export const GetAddressSettlements = (Authorization, state, city, search, showGeneric = false) => ({
  type: 'GET_ADDRESS_SETTLEMENTS',
  payload: {
    request: {
      headers: {
        Authorization
      },
      method: 'get',
      url: `/autocomplete/state/${state}/city/${city}/neighborhood/${search}`,
      params: showGeneric ? { show_generic: showGeneric } : {}
    }
  }
})

export const UploadPhoto = (
  Authorization,
  data,
  onUploadProgress,
  type = `/photo`
) => ({
  type: 'UPLOAD_PHOTO',
  payload: {
    client: 'images',
    request: {
      onUploadProgress,
      headers: {
        Authorization
      },
      method: 'post',
      url: type,
      data
    }
  }
})

export const UploadDocument = (type, data, onUploadProgress) => ({
  type: 'UPLOAD_DOCUMENT',
  payload: {
    client: 'pdf',
    request: {
      headers: {
        filename: data.name
      },
      onUploadProgress,
      method: 'post',
      url: type,
      data
    }
  }
})

export const EditUploadedPhoto = (Authorization, id, params) => ({
  type: 'EDIT_UPLOADED_PHOTO',
  payload: {
    client: 'images',
    request: {
      headers: {
        Authorization
      },
      method: 'put',
      url: `/photo/${id}`,
      params
    }
  }
})

export const UploadAvatar = (Authorization, data) => ({
  type: 'UPLOAD_AVATAR',
  payload: {
    client: 'images',
    request: {
      headers: {
        Authorization
      },
      method: 'post',
      url: `/avatar`,
      data
    }
  }
})

export const UploadLogo = (Authorization, data) => ({
  type: 'UPLOAD_LOGO',
  payload: {
    client: 'images',
    request: {
      headers: {
        Authorization
      },
      method: 'post',
      url: `/logo`,
      data
    }
  }
})

export const SetAppVersion = version => ({
  type: 'SET_SERVER_VERSION',
  payload: {
    serverVersion: version
  }
})

/**
 * Get all amenities
 * @param {*} Authorization
 */
export const GetAmenities = Authorization => ({
  type: 'GET_AMENITIES',
  payload: {
    request: {
      headers: {
        Authorization
      },
      method: 'get',
      url: `/catalogs/amenities`
    }
  }
})
/**
 * Get all amenities
 * @param {*} Authorization
 */
export const GetDevelopmentAmenities = Authorization => ({
  type: 'GET_DEVELOPMENT_AMENITIES',
  payload: {
    request: {
      headers: {
        Authorization
      },
      method: 'get',
      url: `/development/amenities`
    }
  }
})

export const SendError = (Authorization, data) => ({
  type: 'SEND_ERROR',
  payload: {
    request: {
      url: '/log/frontend/error',
      method: 'post',
      headers: {
        Authorization
      },
      data: { data }
    }
  }
})

export const GetPlatformsList = Authorization => ({
  type: 'GET_PLATFORMS_LIST',
  payload: {
    request: {
      url: '/portals/status',
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})
export const RequestInfo = data => ({
  type: 'REQUEST_INFO',
  payload: {
    request: {
      url: '/landing/contact',
      method: 'post',
    },
    data: data
  }
})

export const SendPersistKey = (Authorization, data) => ({
  type: 'SEND_PERSIST_KEY',
  payload: {
    request: {
      url: '/persist',
      method: 'post',
      headers: {
        Authorization
      },
      data
    }
  }
})

export const GetPersistKey = (Authorization, key) => ({
  type: 'GET_PERSIST_KEY',
  payload: {
    request: {
      url: `/persist/key/${key}`,
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})
