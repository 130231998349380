var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/FormInputs/TextInputGroup.js';

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FeaturedInputView } from '../../generics/FormInputViews';
import { default as InputLabelContainer } from './InputLabelContainer';
import { Layout, LayoutItem } from '../Layouts';
import { inputProp, inputLabelContainerProp, inputMetaProp } from './_types';

var TextInputGroup = (_temp2 = _class = function (_PureComponent) {
  _inherits(TextInputGroup, _PureComponent);

  function TextInputGroup() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, TextInputGroup);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = TextInputGroup.__proto__ || Object.getPrototypeOf(TextInputGroup)).call.apply(_ref, [this].concat(args))), _this), _this.BASE_CLASSNAME = 'text-input-group', _this.state = {
      value: _this.props.componentType === 'CT' ? _this.props.value : undefined
    }, _this.onChangeCT = function (event) {
      if (event) {
        var value = event.target.value;


        _this.setState({ value: value }, function () {
          _this.props.onChange(value);
        });
      }
    }, _this.onFocus = function (event) {
      var onFocus = _this.props.onFocus;

      if (onFocus) {
        onFocus(event);
      }
    }, _this.onBlur = function (event) {
      var onBlur = _this.props.onBlur;

      if (onBlur) {
        onBlur(event);
      }
    }, _this.onChangeRF = function (event) {
      if (event) {
        var value = event.target.value;


        _this.props.input.onChange(value);
        _this.props.onChange(value);
      }
    }, _this.handlePasswordInput = function () {
      _this.setState({
        passwordShown: !_this.state.passwordShown
      });
    }, _this.inputProp = function () {
      var forceTouched = _this.props.touched;
      var _this$props = _this.props,
          input = _this$props.input,
          type = _this$props.type,
          min = _this$props.min,
          componentType = _this$props.componentType,
          _this$props$meta = _this$props.meta;
      _this$props$meta = _this$props$meta === undefined ? {} : _this$props$meta;

      var touched = _this$props$meta.touched,
          error = _this$props$meta.error,
          warning = _this$props$meta.warning,
          validations = _this$props.validations,
          disabledTransparent = _this$props.disabledTransparent,
          className = _this$props.className,
          forcedErrorMessage = _this$props.forcedErrorMessage,
          restProps = _objectWithoutProperties(_this$props, ['input', 'type', 'min', 'componentType', 'meta', 'validations', 'disabledTransparent', 'className', 'forcedErrorMessage']);

      var handledProps = {};

      switch (componentType) {
        case 'CT':
          handledProps = {
            disabledTransparent: disabledTransparent,
            min: min,
            onBlur: _this.onBlur,
            onChange: _this.onChangeCT,
            onFocus: _this.onFocus,
            type: type,
            value: _this.state.value
          };
          break;
        case 'extCT':
          handledProps = {
            disabledTransparent: disabledTransparent,
            error: error,
            min: min,
            onBlur: _this.onBlur,
            onChange: _this.props.onChange,
            onFocus: _this.onFocus,
            touched: touched === undefined ? true : touched,
            type: type,
            value: _this.props.value
          };
          break;
        case 'RF':
          handledProps = {
            disabledTransparent: disabledTransparent,
            error: forcedErrorMessage || error,
            min: min,
            onBlur: input.onBlur,
            onChange: _this.onChangeRF,
            onFocus: input.onFocus,
            touched: forceTouched !== undefined ? forceTouched : touched,
            type: type,
            validations: validations,
            value: input.value,
            warning: warning
          };
          break;
        default:
          break;
      }
      if (type && type === 'password' || _this.state.passwordShown) {
        var correctIcon = 'eye';
        if (_this.state.passwordShown) {
          correctIcon = 'eye-slash';
        }
        handledProps = Object.assign({}, handledProps, {
          rightFontAwesomeIcon: correctIcon,
          rightIconOnClick: _this.handlePasswordInput,
          type: _this.state.passwordShown ? 'text' : 'password'
        });
      }
      return Object.assign({}, restProps, handledProps);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(TextInputGroup, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          labelProps = _props.labelProps,
          label = _props.label,
          className = _props.className,
          isRequired = _props.isRequired,
          labelGroupLeft = _props.labelGroupLeft,
          labelGroupRight = _props.labelGroupRight,
          lowerized = _props.lowerized;


      var mainInputGroupClasses = classNames(className, this.BASE_CLASSNAME);

      return React.createElement(
        InputLabelContainer,
        Object.assign({
          isRequired: isRequired,
          label: label
        }, labelProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 211
          },
          __self: this
        }),
        React.createElement(
          Layout,
          { className: mainInputGroupClasses, __source: {
              fileName: _jsxFileName,
              lineNumber: 216
            },
            __self: this
          },
          labelGroupLeft && React.createElement(
            LayoutItem,
            {
              shrink: true,
              className: this.BASE_CLASSNAME + '__label ' + this.BASE_CLASSNAME + '__label-left',
              __source: {
                fileName: _jsxFileName,
                lineNumber: 218
              },
              __self: this
            },
            React.createElement(
              Layout,
              { middle: true, center: true, fullHeight: true, __source: {
                  fileName: _jsxFileName,
                  lineNumber: 224
                },
                __self: this
              },
              labelGroupLeft
            )
          ),
          React.createElement(FeaturedInputView, Object.assign({
            lowerized: lowerized,
            flat: true,
            auto: true
          }, this.inputProp(), {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 229
            },
            __self: this
          })),
          labelGroupRight && React.createElement(
            LayoutItem,
            {
              shrink: true,
              className: this.BASE_CLASSNAME + '__label ' + this.BASE_CLASSNAME + '__label-right',
              __source: {
                fileName: _jsxFileName,
                lineNumber: 236
              },
              __self: this
            },
            React.createElement(
              Layout,
              { middle: true, center: true, fullHeight: true, __source: {
                  fileName: _jsxFileName,
                  lineNumber: 242
                },
                __self: this
              },
              labelGroupRight
            )
          )
        )
      );
    }
  }]);

  return TextInputGroup;
}(PureComponent), _class.propTypes = {
  /** Enables input to make suggestions according to input */
  autoCompleteOn: PropTypes.bool,
  /** Class to be passed */
  className: PropTypes.string,
  /** Component Type (helper for redux form) */
  componentType: PropTypes.string,
  /** Disables the input */
  disabled: PropTypes.bool,
  /** Disables input in a transparent way. */
  disabledTransparent: PropTypes.bool,
  /** In case it is useful to add a dripiconIcon */
  dripiconIcon: PropTypes.string,
  /** Added fontAwesome icon */
  fontAwesomeIcon: PropTypes.string,
  /** Shows an error message without validation. */
  forcedErrorMessage: PropTypes.string,
  /** Used for testing purposes */
  id: PropTypes.string,
  /** Validation when component is RF*/
  input: inputProp,
  /** Adds required ui. */
  isRequired: PropTypes.bool,
  /** Label used in input label container. */
  label: PropTypes.string,
  labelGroupLeft: PropTypes.string,
  labelGroupRight: PropTypes.string,
  /** See InputLabelContainer */
  labelProps: inputLabelContainerProp,
  lowerized: PropTypes.bool,
  /** Meta information */
  meta: inputMetaProp,
  /** Minimum numbers allowed */
  min: PropTypes.number,
  /** Name which will be used to register Field in form. */
  name: PropTypes.string,
  /** Callback function to be called on onBlur event. */
  onBlur: PropTypes.func,
  /** Callback function to retrieve the current input value. */
  onChange: PropTypes.func,
  /** Callback function to be called on onFocus event. */
  onFocus: PropTypes.func,
  /** Handle key press callback */
  onKeyPress: PropTypes.func,

  /** Placeholder to set when no text entered */
  placeholder: PropTypes.string,
  /** If the input has already been touched. */
  touched: PropTypes.bool,
  /** Input type, it could be the following password, phone, email, password, etc.*/
  type: PropTypes.string,
  /** Possible validations */
  validations: PropTypes.array,
  /** Current value set in Input. */
  value: PropTypes.any
}, _class.defaultProps = {
  onChange: function onChange() {
    return null;
  },
  type: 'text',
  label: ''
}, _temp2);


export default TextInputGroup;