import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 12px;
  > input {
    margin-right: ${({ theme }) => theme.remCalc('7')};
  }
  > input:last-child {
    margin-right: 0;
  }
`

const Bullet = styled.input.attrs({ type: 'radio' })`
  width: 12px;
  height: 12px;
`

const BulletIndicator = props => {
  const { className, step, handleStepChange } = props
  return (
    <Container className={className}>
      <Bullet
        name="slide"
        value="1"
        onChange={handleStepChange}
        checked={step === 1}
      />
      <Bullet
        name="slide"
        value="2"
        onChange={handleStepChange}
        checked={step === 2}
      />
      <Bullet
        name="slide"
        value="3"
        onChange={handleStepChange}
        checked={step === 3}
      />
      <Bullet
        name="slide"
        value="4"
        onChange={handleStepChange}
        checked={step === 4}
      />
      <Bullet
        name="slide"
        value="5"
        onChange={handleStepChange}
        checked={step === 5}
      />
      <Bullet
        name="slide"
        value="6"
        onChange={handleStepChange}
        checked={step === 6}
      />
    </Container>
  )
}

BulletIndicator.propTypes = {
  className: PropTypes.string,
  step: PropTypes.string,
  handleStepChange: PropTypes.func
}

export default BulletIndicator
