import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from 'rc'

import styled from 'styled-components'

const SectionBox = ({
  title = '',
  children,
  drawing,
  alignLeft,
  alignRight,
  height,
  full,
  error
}) => {
  return (
    <SectionContainer height={height} error={error}>
      {!full && (
        <LeftSection align={alignLeft}>
          <PrimaryTitle align="center">{title}</PrimaryTitle>
          <Draw src={drawing} />
        </LeftSection>
      )}
      <RightSection align={alignRight} height={height} full={full}>
        {full && <PrimaryTitle>{title}</PrimaryTitle>}
        {children}
      </RightSection>
    </SectionContainer>
  )
}

SectionBox.propTypes = {
  alignLeft: PropTypes.string,
  alignRight: PropTypes.string,
  children: PropTypes.any,
  drawing: PropTypes.string,
  full: PropTypes.bool,
  height: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.bool
}

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  width: ${({ theme }) => theme.remCalc('768')};
  max-width: 100%;
  min-height: ${({ theme }) => theme.remCalc('150')};
  height: ${({ theme, height }) => (height ? theme.remCalc(height) : 'auto')};
  border-radius: ${({ theme }) => theme.remCalc('20')};
  margin: ${({ theme }) => theme.remCalc('12 0')};
  padding: ${({ theme }) => theme.remCalc('20')};
  ${({ error }) => error && 'border: 1px solid red;'}
`

const PrimaryTitle = styled(Typography).attrs(props => ({
  variant: props.variant ? props.variant : 'h2',
  color: 'palette.primary.main',
  textTransform: 'initial'
}))``

const LeftSection = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ align }) => (align ? align : 'space-between')};
  border-right: 1px solid #e8ecf9;
`
const Draw = styled.img.attrs({ alt: 'Drawing' })`
  height: ${({ theme }) => theme.remCalc('120')};
`
const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ align, full }) =>
    align ? align : full ? 'flex-start' : 'space-between'};
  flex: 1;
  padding-left: ${({ theme, full }) => !full && theme.remCalc('20')};
  height: 100%;
`

export default SectionBox
