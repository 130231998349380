export const GetTasksList = (Authorization, params) => {
  return {
    type: 'GET_TASKS_LIST',
    payload: {
      request: {
        method: 'get',
        url: `/tasks`,
        headers: {
          Authorization
        },
        params
      }
    }
  }
}

export const GetTaskGroupsList = (Authorization, params) => {
  return {
    type: 'GET_GROUPED_TASKS_LIST',
    payload: {
      request: {
        method: 'get',
        url: `/tasks/grouped`,
        headers: {
          Authorization
        },
        params
      }
    }
  }
}

export const GetDoneTaskList = (Authorization, params) => {
  return {
    type: 'GET_DONE_TASKS_LIST',
    payload: {
      request: {
        method: 'get',
        url: `/tasks/done`,
        headers: {
          Authorization
        },
        params
      }
    }
  }
}

export const CompleteTask = (Authorization, taskId, resolution) => {
  return {
    type: 'COMPLETE_TASK',
    payload: {
      request: {
        method: 'post',
        url: `/task/${taskId}/complete`,
        headers: {
          Authorization
        },
        data: {
          resolution
        }
      }
    }
  }
}

export const IgnoreTask = (Authorization, taskId) => {
  return {
    type: 'IGNORE_TASK',
    payload: {
      request: {
        method: 'post',
        url: `/task/${taskId}/ignore`,
        headers: {
          Authorization
        }
      }
    }
  }
}

export const GetReports = (
  Authorization,
  tables = [
    'PROPERTIES',
    'SHARED_COMMISSION',
    'PROPERTIES_IN_PORTALS',
    'MY_PROPERTIES_SHARED',
    'PROPERTIES_SHARED',
    'TOP_SHARED_PROPERTIES',
    'REQUIREMENTS',
    'OPPORTUNITIES'
  ]
) => {
  return {
    type: 'GET_REPORTS',
    payload: {
      request: {
        method: 'get',
        url: '/dashboard',
        headers: {
          Authorization
        },
        params: {
          tables
        }
      }
    }
  }
}

export const GetAgencyReports = (
  Authorization,
  tables = [
    'PROPERTIES',
    'SHARED_COMMISSION',
    'PROPERTIES_IN_PORTALS',
    'MY_PROPERTIES_SHARED',
    'PROPERTIES_SHARED',
    'TOP_SHARED_PROPERTIES',
    'REQUIREMENTS',
    'OPPORTUNITIES'
  ]
) => {
  return {
    type: 'GET_REPORTS',
    payload: {
      request: {
        method: 'get',
        url: '/agency/dashboard',
        headers: {
          Authorization
        },
        params: {
          tables
        }
      }
    }
  }
}

export const DownloadReports = (Authorization, type) => {
  return {
    type: 'DOWNLOAD_REPORTS',
    payload: {
      request: {
        method: 'get',
        url: '/dashboard/download_url',
        headers: {
          Authorization
        },
        params: {
          type
        }
      }
    }
  }
}

export const DownloadAgencyReports = (Authorization, type) => {
  return {
    type: 'DOWNLOAD_AGENCY_REPORTS',
    payload: {
      request: {
        method: 'get',
        url: '/agency/dashboard/download_url',
        headers: {
          Authorization
        },
        params: {
          type
        }
      }
    }
  }
}

export const RemindProperty = (Authorization, propertyId) => {
  return {
    type: 'REMIND_PROPERTY',
    payload: {
      request: {
        method: 'post',
        url: `/property/${propertyId}/review_reminder`,
        headers: {
          Authorization
        }
      }
    }
  }
}

export const GetLeads = (Authorization, params) => {
  return {
    type: 'GET_LEADS',
    payload: {
      request: {
        method: 'get',
        url: '/leads',
        headers: {
          Authorization
        },
        params: {
          ...params
        }
      }
    }
  }
}

export const AssignLead = (Authorization, leadId, userId, shortName) => {
  return {
    type: 'ASSIGN_LEAD',
    payload: {
      request: {
        method: 'post',
        url: `/leads/assign_assesor/${leadId}/${userId}`,
        headers: {
          Authorization
        },
        data: {
          portal_type: shortName
        }
      }
    }
  }
}

export const GetOriginLeads = (Authorization) => {
  return {
    type: 'GET_ORIGIN_LEADS',
    payload: {
      request: {
        method: 'get',
        url: '/leads/origins',
        headers: {
          Authorization
        }
      }
    }
  }
}

export const GetDetailChartData = (Authorization, filters) => {
  return {
    type: 'GET_DETAIL_CHART_DATA',
    payload: {
      request: {
        method: 'get',
        url: '/leads/detail_chart',
        headers: {
          Authorization
        },
        params: {
          ...filters
        }
      }
    }
  }
}

export const GetTendencyChartData = (Authorization, filters) => {
  return {
    type: 'GET_TENDENCY_CHART_DATA',
    payload: {
      request: {
        method: 'get',
        url: '/leads/tendency_chart',
        headers: {
          Authorization
        },
        params: {
          ...filters
        }
      }
    }
  }
}
