var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _jsxFileName = 'src/lib/elements/FormInputs/_InputLabel.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout, LayoutItem } from '../Layouts';
import { Image } from '../../generics/Images';
import { TextInline } from '../Texts';

var InputLabel = (_temp = _class = function (_Component) {
  _inherits(InputLabel, _Component);

  function InputLabel() {
    _classCallCheck(this, InputLabel);

    return _possibleConstructorReturn(this, (InputLabel.__proto__ || Object.getPrototypeOf(InputLabel)).apply(this, arguments));
  }

  _createClass(InputLabel, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          label = _props.children,
          className = _props.className,
          centerAlign = _props.centerAlign,
          justifyAlign = _props.justifyAlign,
          rightAlign = _props.rightAlign,
          bottomAlign = _props.bottomAlign,
          middleAlign = _props.middleAlign,
          topAlign = _props.topAlign,
          required = _props.required,
          imageIconUrl = _props.imageIconUrl,
          inline = _props.inline,
          textAlignRight = _props.textAlignRight,
          textAlignCenter = _props.textAlignCenter;

      var inputLabelClasses = classNames(className, 'input-label', {
        'input-label--vertical': !inline,
        'input-label--inline': inline,
        'input-label--with-icon': imageIconUrl
      });

      return React.createElement(
        Layout,
        {
          className: inputLabelClasses,
          top: topAlign,
          middle: middleAlign,
          bottom: bottomAlign,
          right: rightAlign,
          justify: justifyAlign,
          center: centerAlign,
          fullHeight: true,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 61
          },
          __self: this
        },
        React.createElement(
          TextInline,
          {
            alignRight: textAlignRight,
            alignCenter: textAlignCenter,
            noResponsive: true,
            className: 'input-label__text',
            __source: {
              fileName: _jsxFileName,
              lineNumber: 71
            },
            __self: this
          },
          label,
          required ? React.createElement(
            TextInline,
            { danger: true, __source: {
                fileName: _jsxFileName,
                lineNumber: 78
              },
              __self: this
            },
            '*'
          ) : ''
        ),
        imageIconUrl && React.createElement(
          LayoutItem,
          { shrink: true, className: 'input-label__img-icon-wrapper', __source: {
              fileName: _jsxFileName,
              lineNumber: 82
            },
            __self: this
          },
          React.createElement(Image, {
            src: imageIconUrl,
            wrapperClassName: 'input-label__img-wrapper',
            fitContent: true,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 83
            },
            __self: this
          })
        )
      );
    }
  }]);

  return InputLabel;
}(Component), _class.propTypes = {
  /** */
  bottomAlign: PropTypes.bool,
  /** */
  centerAlign: PropTypes.bool,
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  imageIconUrl: PropTypes.string,
  /** */
  inline: PropTypes.bool,
  /** */
  justifyAlign: PropTypes.bool,
  /** */
  middleAlign: PropTypes.bool,
  /** */
  required: PropTypes.bool,
  /** */
  rightAlign: PropTypes.bool,
  /** */
  textAlignCenter: PropTypes.bool,
  /** */
  textAlignRight: PropTypes.bool,
  /** */
  topAlign: PropTypes.bool
}, _temp);


export default InputLabel;