import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <title>ilustraciones dashboard </title>
    <path
      d="M33.91,350.59c-60.36,48.62,35.67,107.83,92.48,123.06,114.76,30.77,254.08,12.51,362.19-30.33C542.19,422.08,596,386.84,609.77,333.57c11.57-44.87-7.33-93.33-59-109.76-103.86-33,96.52-137.2.26-189.13C507.14,11,452.11,11.29,401.33,17.85c-47,6.08-93.62,18.36-141.47,15.8C221,31.57,187.69,11.94,150.35,4.48,111-3.37,68,8,36.42,29.63c-39.09,26.79-49.74,89.63-16.75,124C73.79,210.08,104.73,293.55,33.91,350.59Z"
      style={{ fill: '#b2e2f7' }}
    />
    <path
      d="M140.53,61S292.1,163.91,451.76,102.25c0,0,16.44,315.72-140.84,340,0,0-132.74-1.9-235.74-56.32C75.21,385.89,220.06,313.62,140.53,61Z"
      style={{ fill: '#f8f8f8' }}
    />
    <path
      d="M311.06,376.87c-43.65,0-75.84-6.78-88.75-11.3l8.46-19.63c14.63,5.12,57.72,13.46,116.08,8.28l2.28,20.95C335.7,376.36,323,376.87,311.06,376.87Z"
      style={{ fill: '#98a3af' }}
    />
    <path
      d="M337.34,284.74c-29.14,0-54.11-4.05-80.32-10.06l5.75-20.4c35.39,8.11,68.28,12.39,113.85,6.94l3.07,20.87A354.81,354.81,0,0,1,337.34,284.74Z"
      style={{ fill: '#98a3af' }}
    />
    <path
      d="M341.51,191.53c-21.28,0-41-2.22-61.51-5.57l4.16-20.72c35.92,5.86,69.08,8,114.1-.26L403,185.6A333.91,333.91,0,0,1,341.51,191.53Z"
      style={{ fill: '#98a3af' }}
    />
    <path
      d="M310.92,442.21c49.16-7.57,81.35-43.62,102.36-89.59,0,0-49.79,41.69-98.66,10.64,0,0,1.55,71.34-11.09,78.32Z"
      style={{ fill: '#ececec' }}
    />
    <path
      d="M210,150.24l27.11,2.62c.44,7-1.13,19.84-2.6,25.17l-5.38-.52c-7-.68-14.13-1.37-21.28-2a80.78,80.78,0,0,0,2-14.78,94.8,94.8,0,0,0,.16-10.45m-8.58-6.87h0c3.72.36,1.94,37.22-3.85,37.22h-.11c13.55,1.18,27.13,2.55,40.69,3.86,5.43-2.24,6.87-36.87,4.37-37.1l-41.1-4Z"
      style={{ fill: '#65757e' }}
    />
    <path
      d="M197.5,238.47l27.12,2.62c.45,6.95-1.13,19.84-2.61,25.17l-5.17-.5c-7.05-.69-14.27-1.39-21.49-2.06a80.06,80.06,0,0,0,2-14.81,96.5,96.5,0,0,0,.15-10.42m-8.58-6.88h0c3.72.37,1.94,37.22-3.84,37.22H185c13.56,1.18,27.13,2.55,40.69,3.85,5.44-2.24,6.91-36.87,4.37-37.09-.07,0-41-4-41.1-4Z"
      style={{ fill: '#65757e' }}
    />
    <path
      d="M169,326.64,190,332l5.34,1.35c-.83,6.9-4.74,19.37-7.18,24.4l-4-1c-7.18-1.83-14.53-3.7-21.89-5.53.66-1.47,1.25-3,1.81-4.59a108.43,108.43,0,0,0,4.77-19c0-.31.09-.63.14-1m-7.14-8.05h0c3.56.88-4.74,36.11-10.43,36.11a1.17,1.17,0,0,1-.32,0c13.16,3.2,26.31,6.59,39.44,9.92,5.79-1.4,13.58-35.3,11.1-35.91-.07,0-39.74-10.08-39.79-10.08Z"
      style={{ fill: '#65757e' }}
    />
  </Fragment>,
  'ZeroDone',
  '0 0 613.51 490.33'
)
