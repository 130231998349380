import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <title>ilustraciones dashboard </title>
    <path
      d="M70.07,294.72c17,6.38,40.23,7.78,38.28,32.21-.92,11.6-7.68,21.8-14.09,31.51-41,62.07,36.83,107.81,88.55,120.94C287.28,505.9,411.66,481.74,506.9,434c47.23-23.7,94-61.59,104-116.13C619.33,271.88,600.2,223.55,553,209,476.3,185.31,582.89,93,569.36,46c-8-27.71-40.78-38.42-66.21-43C472.24-2.69,440.28.5,409.66,6.39c-42,8.07-83.3,22.39-126.41,21.74-35-.53-65.8-19.07-99.69-25.11-35.67-6.36-73.84,6.9-101.25,30.1C47.22,62.81,48.89,108.53,61.92,148.26c5.29,16.13,9.46,34.15,2.6,49.68-8.35,18.93-29.94,27.7-44.41,42.53-21.15,21.7,8.48,37.39,26.43,45.34C54.17,289.19,62.3,291.8,70.07,294.72Z"
      style={{ fill: '#b2e2f7' }}
    />
    <path
      d="M479.39,214.09a23.28,23.28,0,0,1-7.65-7.41c-2.67-4.4-3.81-9-2.72-13.91,1-4.52,4.78-7.23,9.76-7.14a15.81,15.81,0,0,1,7.84,2.25c1.42.85,2.86,1.67,4.23,2.58a5.57,5.57,0,0,0,5.88.21,14.41,14.41,0,0,0,6.5-6c1.56-2.78,3.09-5.57,4.7-8.31a20.4,20.4,0,0,1,8.43-8.1c6.06-3.09,12.89-2.28,17.52,2.07a11.53,11.53,0,0,1,3.7,6.79c.15,1,.27,2,.51,3,.9,3.73,3.87,5.72,7.92,5.34A18.31,18.31,0,0,0,552.9,183a37.28,37.28,0,0,0,5-3.32A37.11,37.11,0,0,1,568.18,174c11.65-4,24.06-.35,31.32,11.51,5.83,9.53.34,20.27-6.29,24.41a26.66,26.66,0,0,1-8.51,3.52c-6,1.3-12.07,2.56-18.1,3.86-1,.21-1.91.46-2.86.73A11.76,11.76,0,0,0,555,227.1c-.66,2.69-1.26,5.41-2,8.09-1.46,5.42-4.94,9.41-10.07,12.16a12.17,12.17,0,0,1-14.29-1.59c-2.12-1.8-4-3.79-6-5.71a32.85,32.85,0,0,0-4.54-3.76c-2.2-1.46-3.24-1.43-5.38.07-1,.69-1.9,1.45-2.93,2.08-4.19,2.55-8.51,1.82-11.7-1.93a37.1,37.1,0,0,1-4.15-6.72c1.33-1.2,2.59-2.27,4.77-2.26a3.73,3.73,0,0,0,3.63-2.93,2.34,2.34,0,0,0-.79-2.74,2.1,2.1,0,0,0-2.52,0,.77.77,0,0,1-1.08-.07c-.83-1.72-.15-3.41.69-5.08.21-.41.48-.89.13-1.29s-.92-.11-1.32.1c-3.67,2-9.53-.38-11.42-3.37a2.49,2.49,0,0,0-1.27-1.28,8.61,8.61,0,0,0-2.38,2.43A1.92,1.92,0,0,1,479.39,214.09Z"
      style={{ fill: '#171d3e' }}
    />
    <path
      d="M482.72,233a3.61,3.61,0,0,1-.19.41,30.25,30.25,0,0,1-3.6,5.19,3.35,3.35,0,0,1-.86.82c-1.54.94-4.88.37-6.63.32-2.53-.07-5.06-.24-7.58-.47s-5.3-.57-7.94-.95c-1.32-.19-2.65-.4-4-.62-.62-.1-3.58-.22-3.9-.69,4.1,6,13,20,32.37,21.45,14.19,1.07,25.92-4.82,33.63-7.45a150.83,150.83,0,0,1-21.12-5.65,5.12,5.12,0,0,1-1.22-.6,7,7,0,0,1-2.12-4.38c-.17-1.54-.49-3.06-.65-4.61a7.88,7.88,0,0,1,0-2.37,12.16,12.16,0,0,0,5.56-4.12l.23-.14.05.06c1.71,1.51,4.6,1.05,6.44-1s1.95-5,.24-6.52a3.87,3.87,0,0,0-3.46-.76c.88-6.32-1.8-12.05-6.57-13.37-5.35-1.48-11.31,3.16-13.3,10.36C476.29,224.37,478.35,230.73,482.72,233Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M477.82,214a5.89,5.89,0,0,0,.89,2.07,4.9,4.9,0,0,0,3.46,1.55c2.83.29,6.06-.73,7.34-3.28a4.17,4.17,0,0,0,5.06,4.92,4.19,4.19,0,0,0,1.95,6,8.34,8.34,0,0,1,7.21-7.23,36.34,36.34,0,0,0-2.91-13.24,14,14,0,0,0-2.78-4.49c-3.9-3.91-9.38-2.81-14.33-3.06-1.45-.07-3-.23-4.24.67-1.81,1.3-2.51,4.06-2.78,6.12A25.11,25.11,0,0,0,477.82,214Z"
      style={{ fill: '#171d3e' }}
    />
    <path
      d="M409,444.06c2.88-.31,5.76-.77,8.59.26a3.93,3.93,0,0,0,.1.92c1.11,3,0,5.26-2.14,7.29a28.54,28.54,0,0,1-4.21,3.29A76.68,76.68,0,0,0,401,463.26a8.94,8.94,0,0,1-3.85,2.11,2.35,2.35,0,0,1-2.56-.64,2.71,2.71,0,0,1-.41-2.85,5.65,5.65,0,0,1,1.69-2.18c1.74-1.55,3.45-3.14,5.26-4.6C404.75,452.15,406.91,448.14,409,444.06Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M608.06,264.24s5.75-4.29,5.44-5.2c-.5-1.43-7,3.54-7,3.54s6.89-5.59,5.69-6.74-7.4,5.16-7.4,5.16,5.46-6.29,4.26-7.09-7.92,7.59-7.92,7.59-.07-5.44-1.18-5.5-1.74,4.47-2,7.85a3.35,3.35,0,0,1-2.5,1.71,110.16,110.16,0,0,1-14.35,1.82c-6.76.48-13.53.13-20.29.05a115.59,115.59,0,0,1-17.45-1.06c-3.15-.52-6.27-1.19-9.4-1.79a75,75,0,0,0-1.4,14.41c0,1.44,21.14,2.18,38-.22,9.12-1.3,17.91-4,26.89-7.22,0,0,16.44-8.12,15.83-9.21S608.06,264.24,608.06,264.24Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M409,444.06c-2.3-.72-2.5-.93-2.64-2.92-.68-9.7-6.77-63.58-.36-82.25a34.35,34.35,0,0,1,6.32-11.09c2.73-3.31,20.35-19.65,25.29-23.83a112,112,0,0,0,12.08-12c.78,0,1.56,0,2.32.11,3.87.4,7.73,1,11.6,1.2,9.48.54,18.87,1.91,28.31,2.86a6.45,6.45,0,0,1,1.32.4c2.44,6.58,4.46,13.24,4.28,20.38a49.51,49.51,0,0,1-1.42,8.49,96.81,96.81,0,0,1-3.49,13.28c-.57,1.61-.34,1.93,1.44,1.78a74,74,0,0,0,7.87-.89c8.3-1.59,16.76-2.37,24.92-4.76a9.08,9.08,0,0,0,2.17-.79,2.92,2.92,0,0,1,1,1.8l1.86,8.36a2,2,0,0,1-1.11,1.11c-7.92,4.16-15.74,8.54-23.78,12.43s-15.76,8.18-24.34,10.66c-4.11,1.19-9,2.75-13.16,1-8.95-3.66-6.38-20.28-6.55-27.86-.07-3.43-.41-12.69-1.08-13.91a7.35,7.35,0,0,0-2.36,1.58,326,326,0,0,1-30.09,20.89c-4.64,2.9-4.65,2.84-5.14,8.3-1.31,14.73-2.17,29.48-2.87,44.24-.31,6.52-.65,13.05-1.43,19.55-.22,1.88-.27,1.88-2.32,2.08C414.75,443.29,411.87,443.75,409,444.06Z"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M453.16,286c-.73,8.51-4.82,17.38-8.64,24.23-1,1.86-.22,2.3,1.87,2.56,12.51,3.18,58.47,5.71,61.18,4.77,2.22,0,3.29-.87,3.65-3,.31-1.89-.79-4.43-1.25-6.22a105.52,105.52,0,0,1-2.27-10.56c-.44-3.05-.86-6.58.25-9.55a4.73,4.73,0,0,1,3.11-3c1.95-.62,4,.1,6,.32a52.77,52.77,0,0,0,14.85-.26,4.1,4.1,0,0,0,2.47-1.26c.73-.92.39-2.14.42-3.24s.08-2.11.13-3.16c.11-2.18.27-4.35.48-6.53s.48-4.5.79-6.74c.13-.92,1.15-4.48.71-5.67a.59.59,0,0,0-.31-.36c-3.57-1.35-7.18-2.56-10.82-3.73-1.88-.6-3.77-1.21-5.64-1.85s-3.56-1.54-5.37-2c-1.38-.56-2.8.74-4.11,1.36q-2.32,1.1-4.74,2a52.42,52.42,0,0,1-30.43,1.67c-9.9-2.4-20.3-8.18-23.22-18.31l-.13,0c-.85-.09-1.69-.29-2.54-.37a16.85,16.85,0,0,1-8.65-3.69c-3-2.24-5.73-4.76-8.52-7.22-2.37-2.09-3.52-1.83-4.86,1.08l-4.32,14.13c0,1.17-2.64,11.9-3.15,16.23-.27,2.34,0,2.83,2.29,3.59a138.39,138.39,0,0,0,16.37,4.57c6.31,1.24,12.31,5.5,13.87,12A25.94,25.94,0,0,1,453.16,286Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M416.06,218.8c-3.76-3.27-7.23-6.85-10.79-10.33-6.41-6.25-26.13-25.86-25.85-26.73-3.27,1-2.32,1.82-5.56,3,1.11,1.73,2.07,3.56,3.19,5.28,9.25,14.19,19.48,27.57,31.68,39.37,2.13,2.06,4.43,3.94,6.45,6.08a25.51,25.51,0,0,0,8.06,5.85l4.32-14.13A86.77,86.77,0,0,1,416.06,218.8Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M531.81,364.21,530,355.85a2.92,2.92,0,0,0-1-1.8,26.1,26.1,0,0,1,1.6-2.83c2-2.72,4.85-2.75,7-.15a9.21,9.21,0,0,1,2.2,4.57c.57,4.34,3.16,7.49,5.93,10.56a24.94,24.94,0,0,1,3.52,4.6,5.1,5.1,0,0,1,.61,3.81,2.44,2.44,0,0,1-3.33,1.95,4.33,4.33,0,0,1-2.41-1.74,22.73,22.73,0,0,1-1.61-2.55,10.45,10.45,0,0,0-3.49-4C536.75,366.72,534.69,364.71,531.81,364.21Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M366.52,173.42c.89-.89,4.5,3.25,4.5,3.25s-4.37-5.69-3.82-6.48c.87-1.24,5.73,5.3,5.73,5.3s-5.11-7.26-3.64-8,5.72,7,5.72,7-3.55-7.54-2.18-8,5.57,9.46,5.57,9.46,1.54-5.22,2.62-5c1.83.41-1,11.63-1,11.63l-5.4,3.33S365.64,174.31,366.52,173.42Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M241.38,272.42s63.13,5.54,96.35-42.08c0,0,66.45,96.35,26.58,131.79,0,0-38,22.87-77.89,24C286.42,386.11,313.37,337.76,241.38,272.42Z"
      style={{ fill: '#f9f9f9' }}
    />
    <path
      d="M364.31,362.13c12.46-11.08,14.54-28.1,11.5-46.27,0,0-6,21.9-25.9,20.8,0,0,14.4,22.14,12.18,26.57Z"
      style={{ fill: '#ededed' }}
    />
    <path
      d="M280.92,300.94a1,1,0,0,1-.9-.55,1,1,0,0,1,.19-1.16c.06-1.54-3.81-9.42-5.54-10.69a1,1,0,0,1-.35-1.89h0l6.73-3.25,5.71-2.77c.83-.4,1.5.36,1.75.65,1.06,1.2,7.12,10.85,5.54,13.24a1,1,0,0,1-.4.35l-3,1.46c-3.09,1.49-6.19,3-9.27,4.52A1,1,0,0,1,280.92,300.94Zm-4.29-13.19a29.31,29.31,0,0,1,3.75,6.11,20.46,20.46,0,0,1,1.71,4.39q3.82-1.89,7.67-3.73l2.6-1.26c-.05-1.89-3.86-8.94-5.26-10.58l-5.18,2.51ZM293.22,294h0Zm-.88-.46Zm-17.15-5.06h0Z"
      style={{ fill: '#62757f' }}
    />
    <path
      d="M294.64,330.88a1,1,0,0,1-.89-.55,1,1,0,0,1,.19-1.16c.06-1.57-4-9.77-5.65-10.76a.75.75,0,0,0,.63,0L288,316.6h0l12.43-6c.83-.4,1.5.37,1.75.66,1.05,1.2,7.12,10.84,5.54,13.23a1,1,0,0,1-.4.35l-3,1.46c-3.1,1.5-6.2,3-9.29,4.52A1,1,0,0,1,294.64,330.88Zm-4.28-13.19a30,30,0,0,1,3.74,6.11,19.42,19.42,0,0,1,1.71,4.39l7.69-3.73,2.58-1.25c-.05-1.89-3.86-8.94-5.25-10.59Zm16.58,6.21h0Zm-5.59-11.53Z"
      style={{ fill: '#62757f' }}
    />
    <path
      d="M301.86,363.86a1,1,0,0,1-.58-1.82c.32-1.51-2.19-9.92-3.68-11.46a1,1,0,0,1-.07-1.92h.06l6.91-2,6.33-1.85c.89-.26,1.43.61,1.63.93.84,1.36,5.21,11.88,3.26,14a1,1,0,0,1-.45.27l-3.24,1q-4.95,1.43-9.88,2.9A1,1,0,0,1,301.86,363.86Zm-2.19-13.74a29.87,29.87,0,0,1,2.67,6.65,20.59,20.59,0,0,1,1,4.61c2.72-.81,5.45-1.6,8.17-2.4l2.78-.8c.27-1.87-2.31-9.46-3.42-11.32l-5.77,1.69ZM315,359h0Zm-.79-.6h0Z"
      style={{ fill: '#62757f' }}
    />
    <path
      d="M281,288.54a21.26,21.26,0,0,1,3.31,1.53c.54.28,1,.59,1.55.9s1,.64,1.5,1l-2.36.86a18.29,18.29,0,0,1,1.78-5.56c.79-1.76,1.63-3.49,2.57-5.16a39.09,39.09,0,0,1,3.17-4.77,38.26,38.26,0,0,1,3.77-4.26l.23.2a48.71,48.71,0,0,0-3,4.73,53,53,0,0,0-2.39,5,40,40,0,0,0-1.84,5.19c-.45,1.76-.87,3.58-1.43,5.21l-.11.33a1.37,1.37,0,0,1-1.73.86,1.4,1.4,0,0,1-.52-.33c-.42-.41-.87-.81-1.27-1.24s-.83-.86-1.22-1.32a22.21,22.21,0,0,1-2.22-2.9Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M293.16,318.44a22.82,22.82,0,0,1,3.32,1.53c.53.28,1,.59,1.54.9s1,.65,1.5,1l-2.36.86a18.38,18.38,0,0,1,1.78-5.56c.79-1.76,1.63-3.48,2.57-5.15a39.6,39.6,0,0,1,3.17-4.78,37.36,37.36,0,0,1,3.78-4.26l.22.2a48.71,48.71,0,0,0-3,4.73,50.57,50.57,0,0,0-2.38,5,40.59,40.59,0,0,0-1.85,5.19c-.45,1.76-.87,3.58-1.43,5.22l-.11.32a1.36,1.36,0,0,1-1.73.86,1.52,1.52,0,0,1-.52-.32c-.42-.42-.86-.81-1.27-1.25s-.83-.86-1.22-1.32a23.12,23.12,0,0,1-2.22-2.9Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M303.13,351.66a23.27,23.27,0,0,1,3.31,1.53c.53.28,1,.6,1.55.9s1,.65,1.5,1l-2.36.86a18.38,18.38,0,0,1,1.78-5.56c.79-1.76,1.63-3.48,2.57-5.15a40,40,0,0,1,3.17-4.78,38.16,38.16,0,0,1,3.77-4.25l.23.2a47.87,47.87,0,0,0-3,4.72,52,52,0,0,0-2.39,5,40.4,40.4,0,0,0-1.84,5.18c-.45,1.77-.87,3.58-1.43,5.22l-.11.32a1.36,1.36,0,0,1-1.73.86,1.38,1.38,0,0,1-.52-.32c-.42-.42-.87-.81-1.27-1.25s-.83-.85-1.22-1.31a21.37,21.37,0,0,1-2.22-2.9Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M417.49,30.4s35.28,45,87.18,37.59c0,0-22.57,103.24-70.53,98.8,0,0-38.54-11-63.85-36.58C370.29,130.21,418.79,118.17,417.49,30.4Z"
      style={{ fill: '#f9f9f9' }}
    />
    <path
      d="M434.14,166.79c15,1.39,27.5-7.74,37.61-20.94,0,0-18.13,9.57-29.69-4.26,0,0-5.73,23.16-10,24.43Z"
      style={{ fill: '#ededed' }}
    />
    <path
      d="M435.26,78.86a1.09,1.09,0,0,1-.36-.06L432,77.68c-2.87-1.11-5.74-2.22-8.61-3.3a1,1,0,0,1,.35-1.94h0c1.21-1.08,3.66-8.49,3.56-10.12a1,1,0,0,1-.12-.94,1,1,0,0,1,1.28-.58h0l11.64,4.49c.85.33.74,1.33.71,1.66-.14,1.44-2.8,11.46-5.39,11.9Zm-9.54-5.74,7,2.69,2.45.94c1.2-1.28,3.4-7.83,3.66-9.81l-9.6-3.7a27,27,0,0,1-1.72,6.13A19.3,19.3,0,0,1,425.72,73.12Z"
      style={{ fill: '#62757f' }}
    />
    <path
      d="M424,106.38a1.13,1.13,0,0,1-.36-.06l-2.9-1.13c-2.87-1.1-5.74-2.21-8.62-3.29a1,1,0,0,1,.35-1.94h0c1.21-1.08,3.66-8.49,3.57-10.12a1,1,0,0,1,1.16-1.52h0l11.64,4.49c.85.33.75,1.33.72,1.66-.15,1.44-2.8,11.46-5.4,11.9Zm-9.54-5.74,7,2.69,2.44.94c1.21-1.28,3.4-7.83,3.66-9.81l-9.6-3.71a26.9,26.9,0,0,1-1.72,6.14A18.73,18.73,0,0,1,414.43,100.64Z"
      style={{ fill: '#62757f' }}
    />
    <path
      d="M405.78,133.34a1,1,0,0,1-.51-.14l-2.61-1.56q-4-2.37-8-4.72a1,1,0,0,1-.36-1.37,1,1,0,0,1,1-.48c1.38-.86,5-7.76,5.21-9.38a1,1,0,0,1,1.4-1.31h0l3.26,1.93,7.48,4.44c.78.46.51,1.43.42,1.75C412.7,123.9,408.42,133.34,405.78,133.34Zm-.13-2Zm-8.49-5.29,6.52,3.85,2.19,1.31c1.4-1.07,4.65-7.16,5.24-9.08L404.16,118l-1.9-1.12a26.6,26.6,0,0,1-2.72,5.77A18.64,18.64,0,0,1,397.16,126.07Zm3.74-10Z"
      style={{ fill: '#62757f' }}
    />
    <path
      d="M431.3,66.45a18.82,18.82,0,0,1,1.12,3.12c.15.53.27,1.05.39,1.58s.2,1,.3,1.58l-2.24-1.14a15.81,15.81,0,0,1,4.78-2.25c1.66-.55,3.33-1,5-1.46a37,37,0,0,1,5.13-.82,35.31,35.31,0,0,1,5.14-.09v.3a45,45,0,0,0-4.94,1,42.46,42.46,0,0,0-4.75,1.54,35.39,35.39,0,0,0-4.54,2c-1.43.8-2.88,1.65-4.29,2.27l-.35.16a1.36,1.36,0,0,1-1.78-.7,1.39,1.39,0,0,1-.12-.59c0-.54,0-1.07.06-1.61s.07-1.08.14-1.62a18.19,18.19,0,0,1,.63-3.26Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M419.09,92.92a19.25,19.25,0,0,1,1.12,3.13c.16.52.27,1,.39,1.57s.2,1.06.3,1.59l-2.24-1.14a15.78,15.78,0,0,1,4.79-2.26c1.65-.55,3.32-1,5-1.45a35.58,35.58,0,0,1,5.13-.82,32.7,32.7,0,0,1,5.14-.09v.3a42.75,42.75,0,0,0-4.94,1A44.67,44.67,0,0,0,429,96.29a35.33,35.33,0,0,0-4.54,2,48.26,48.26,0,0,1-4.29,2.28l-.35.15a1.35,1.35,0,0,1-1.78-.69,1.28,1.28,0,0,1-.11-.6c0-.53,0-1.07,0-1.61s.07-1.07.14-1.61a18.93,18.93,0,0,1,.63-3.26Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M403.32,120a18,18,0,0,1,1.13,3.12c.15.52.26,1,.38,1.58s.2,1,.3,1.58l-2.24-1.14a15.63,15.63,0,0,1,4.79-2.25c1.66-.55,3.32-1.06,5-1.46a35.67,35.67,0,0,1,5.12-.82,34.09,34.09,0,0,1,5.15-.09v.3a45.18,45.18,0,0,0-5,1,43.84,43.84,0,0,0-4.75,1.54,35.25,35.25,0,0,0-4.53,2c-1.43.8-2.88,1.65-4.3,2.27l-.35.16a1.36,1.36,0,0,1-1.78-.7,1.37,1.37,0,0,1-.11-.59c0-.54,0-1.08.05-1.61s.07-1.08.14-1.62A19.12,19.12,0,0,1,403,120Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M221.53,65s55.54,13.76,91.84-24.08c0,0,45.81,95.24,5.29,121.28,0,0-37.11,15.1-72.84,10.51C245.82,172.68,276.62,133.32,221.53,65Z"
      style={{ fill: '#f9f9f9' }}
    />
    <path
      d="M318.66,162.17c12.66-8.14,16.89-23,16.73-39.67,0,0-8.39,18.71-26,14.93,0,0,9.74,21.78,7.15,25.42Z"
      style={{ fill: '#ededed' }}
    />
    <path
      d="M253,96a1,1,0,0,1-.62-1.79c.3-1.64-2.5-9.38-3.51-10.26a.77.77,0,0,0,.66.09l-.59-1.91h0l11.93-3.63c.87-.27,1.41.59,1.58.87.78,1.22,4.86,10.75,3.09,12.7a1.07,1.07,0,0,1-.45.28l-3,.9c-2.94.9-5.89,1.79-8.82,2.71A1.11,1.11,0,0,1,253,96Zm-2-12.42a26.52,26.52,0,0,1,2.42,5.89,19.25,19.25,0,0,1,.91,4c2.39-.74,4.78-1.46,7.17-2.19l2.5-.76c.16-1.76-2.15-8.27-3.16-10Zm13.77,7.8h0Zm-.81-.57Z"
      style={{ fill: '#62757f' }}
    />
    <path
      d="M261,124.67a1,1,0,0,1-.61-1.79c.3-1.64-2.5-9.38-3.51-10.26a.75.75,0,0,0,.65.09L257,110.8h0l11.94-3.64c.86-.27,1.4.59,1.57.86.78,1.23,4.86,10.76,3.08,12.71a1,1,0,0,1-.44.28l-2.88.87c-3,.91-6,1.81-8.93,2.75A1,1,0,0,1,261,124.67Zm-2-12.42a26.85,26.85,0,0,1,2.43,5.9,19.18,19.18,0,0,1,.91,4c2.42-.75,4.84-1.48,7.27-2.22l2.4-.73c.16-1.76-2.15-8.26-3.16-10Zm13.78,7.8h0Zm-3.35-11Z"
      style={{ fill: '#62757f' }}
    />
    <path
      d="M262.85,155.09a1,1,0,0,1-1-.86,1,1,0,0,1,.51-1c.55-1.52-.82-9.2-1.68-10.59a1,1,0,0,1-.56-.78,1,1,0,0,1,.86-1.12h0l3.37-.43,9-1.17c.9-.1,1.28.82,1.41,1.12.56,1.34,3,11.41.92,13a1,1,0,0,1-.49.2l-3.06.4c-3.06.39-6.12.78-9.18,1.2Zm0-12.59a27.52,27.52,0,0,1,1.41,6.22,19.53,19.53,0,0,1,.22,4.14l7.45-1,2.58-.33c.45-1.7-.74-8.51-1.46-10.37l-8.4,1.08Zm12.28,10h0Zm-.71-.69Zm-13.14-9.09Zm12.38-1.6h0Z"
      style={{ fill: '#62757f' }}
    />
    <path
      d="M254.61,84.87a18.08,18.08,0,0,1,2.81,1.77c.45.31.86.66,1.28,1s.8.71,1.21,1.06l-2.47.49a16,16,0,0,1,2.39-4.73c1-1.45,2-2.87,3.05-4.23a35.3,35.3,0,0,1,3.53-3.8,33.24,33.24,0,0,1,4-3.24l.2.23a42.27,42.27,0,0,0-3.28,3.83,45.55,45.55,0,0,0-2.8,4.14,35.23,35.23,0,0,0-2.33,4.37c-.64,1.52-1.25,3.08-2,4.44l-.17.34a1.37,1.37,0,0,1-1.84.55,1.34,1.34,0,0,1-.45-.4c-.32-.44-.65-.85-.95-1.31s-.61-.88-.89-1.36a19,19,0,0,1-1.51-3Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M261.3,113.24a18.86,18.86,0,0,1,2.81,1.77c.45.32.86.67,1.28,1s.8.71,1.21,1.06l-2.47.48a15.9,15.9,0,0,1,2.39-4.72c1-1.46,2-2.88,3.05-4.23a34.31,34.31,0,0,1,3.53-3.8,33.24,33.24,0,0,1,4-3.24l.2.23a41.06,41.06,0,0,0-3.29,3.83,44.73,44.73,0,0,0-2.79,4.14,35.23,35.23,0,0,0-2.33,4.37c-.64,1.51-1.25,3.08-2,4.44l-.18.33a1.34,1.34,0,0,1-2.28.15c-.32-.43-.66-.85-.95-1.3s-.61-.89-.89-1.36a19.94,19.94,0,0,1-1.51-3Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M265.55,144.28a17.36,17.36,0,0,1,2.81,1.77c.44.31.86.66,1.28,1s.8.71,1.21,1.06l-2.47.48a15.76,15.76,0,0,1,2.38-4.72c1-1.46,2-2.88,3.05-4.23a36.34,36.34,0,0,1,3.53-3.8,34.14,34.14,0,0,1,4-3.24l.2.23a41.06,41.06,0,0,0-3.29,3.83,44.09,44.09,0,0,0-2.79,4.14,36.69,36.69,0,0,0-2.34,4.37,47.58,47.58,0,0,1-2,4.44l-.18.33a1.34,1.34,0,0,1-1.83.55,1.43,1.43,0,0,1-.45-.39c-.32-.44-.66-.85-.95-1.31s-.61-.89-.89-1.36a19.46,19.46,0,0,1-1.51-3Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M122.49,377c-5.37,13.09-29.07,35.85-30.8,32.87s9.25-37.32,16.84-39.73S122.49,377,122.49,377Z"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M158.51,386.26c-.46,14.15-14.75,43.74-17.41,41.54s-4.33-38.2,1.94-43.1S158.51,386.26,158.51,386.26Z"
      style={{ fill: '#ffc834' }}
    />
    <polygon
      points="27.8 307.77 18.61 314.19 13.72 308.46 22.17 302.12 27.8 307.77"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M17.41,310.56c3.31,4.67,0,8.3-3.94,10.4s-8.3,2.26-8.52,1.82,2.22-1.68,2.22-1.68-3.38.51-5.34.56c-1,0,3.28-2.77,3.15-3.07S.39,320.28.07,319.65c-.66-.81,3.72-3.73,3.72-3.73S-.32,317.12,0,316s7.84-3.84,7.84-3.84-3.61.4-2.9-1.06S13.52,305.07,17.41,310.56Z"
      style={{ fill: '#fcb9b9' }}
    />
    <polygon
      points="195.23 86.7 190.92 76.35 183.72 78.57 187.44 88.45 195.23 86.7"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M188,78.77c5.3-2.16,4.45-7,2.26-10.88s-5.78-6.36-6.2-6.12,1,2.62,1,2.62-2.58-2.25-4.21-3.35c-.84-.56,1.28,4.1,1,4.29s-3-3.89-3.56-3.54c-1,.33,1.13,5.15,1.13,5.15s-2.82-3.22-3.11-2.12,4.55,7.46,4.55,7.46-2.83-2.28-3-.67S181.73,81.31,188,78.77Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M177.7,286.58s67.38,54.5,60,82c-4.44,16.53-79.34,31.61-79.34,31.61l-3.45-14.67,40.79-16.94-38-42-3-36Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M136.7,289.58s-18,36-3,52,30,47,30,47l-44.17-15.14-3.46,14.67S177.34,433,199.78,408c21.81-24.34-34.08-114.38-34.08-114.38Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M141.05,163c6.87,8.11,4.64,23.43-5.43,28.61s-21.56,1.85-29-13-3.36-27.15,5.72-33.91C120.05,139,130.05,150,141.05,163Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M106.19,216.38c13.87-12.95,50-24.06,60.14-37s15.73-40.71,15.73-55.52.92-39.78.92-39.78l15.5-3.16s7.4,50.47,6.47,69.9S191,202.28,186.74,226c-5.69,32,9.56,74.27,9.56,74.27s-34.88,48.6-73.71,25.3c0,0-6.56-45.58-10.87-49C104.86,271,95.05,278,76.81,290.6,61,301.52,25,315.19,25,315.19l-7.77-11.65s36-30.31,51.77-47.89S92.31,229.34,106.19,216.38Z"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M124.44,207.32c3.1-6.54-8.3-28.18-8.3-28.18l14-6.31,12.24,15.07s8,10.82,3.11,16.1S121.5,213.53,124.44,207.32Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M104.31,164.78S120,171,120.53,156.42c.24-6.08,4.81-5.77,9.52-5.11,1.81.25,4.13.69,5.73-.48,3.31-2.42,6.05-5.79,5.06-11.92a9.33,9.33,0,0,0-9.55-7.91c-3.37.16-5.7,4-8,6.07a12.27,12.27,0,0,1-5.4,3.07c-2.77.62-5.58-.31-8.36,0-6.86.78-15.1,8-16.95,14.64-3.17,11.33,4,24.35,13.19,30.8,12.19,8.58,9.9,2.86,6.71-3.18S104.31,164.78,104.31,164.78Z"
      style={{ fill: '#171d3e' }}
    />
    <ellipse
      cx="107.48"
      cy="177.84"
      rx="5.58"
      ry="5.64"
      style={{ fill: '#fcb9b9' }}
    />
  </Fragment>,
  'ZeroPending',
  '0 0 613.51 490.33'
)
