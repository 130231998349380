import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <svg width="189" height="35" viewBox="0 0 189 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5736 14.1031C16.5736 15.3941 15.5229 16.4465 14.234 16.4465C12.9451 16.4465 11.8943 15.3941 11.9083 14.1031C11.9083 12.8122 12.9591 11.7598 14.248 11.7598C15.5369 11.7598 16.5736 12.8122 16.5736 14.1031Z" fill="#FFC835"/>
    <path d="M61.84 2.14692C61.84 0.954186 62.8067 0 63.9975 0C65.1884 0 66.141 0.968219 66.141 2.16095C66.141 3.35368 65.1743 4.30787 63.9975 4.30787C62.7927 4.30787 61.826 3.33965 61.84 2.14692Z" fill="#FFC835"/>
    <path d="M60.0608 7.05772L54.723 22.4089L50.8002 22.3948L47.718 12.5022L44.6078 22.3808H40.6851L35.4033 7.01562H39.3261L42.6464 16.6978L46.0088 7.02966L49.4412 7.04369L52.7756 16.7259L56.138 7.05772H60.0608Z" fill="#363946"/>
    <path d="M62.005 7.07267L61.9756 22.4238L65.8984 22.4314L65.9278 7.08021L62.005 7.07267Z" fill="#363946"/>
    <path d="M84.8022 7.1139L84.7741 21.2443C84.6761 25.917 80.7113 28.976 76.8445 28.962C71.5068 28.9479 69.3633 25.1452 69.1251 24.4576L72.2213 22.8439C72.4314 23.4894 74.2107 25.4259 76.8445 25.4399C79.3663 25.4399 80.6833 23.1386 80.8374 21.4548C79.5625 22.2827 78.0634 22.7176 76.5363 22.7036C72.1652 22.8159 68.5367 19.378 68.4106 15C68.2845 10.6219 71.7309 6.98762 76.102 6.86133C76.2561 6.86133 76.4102 6.86133 76.5643 6.86133C78.0774 6.86133 79.5625 7.29632 80.8234 8.12422V7.1139H84.8022ZM80.7113 14.8176C80.7113 12.3619 79.0301 10.6079 76.7325 10.6079C74.4068 10.6079 72.7396 12.3479 72.7396 14.8035C72.7396 17.2872 74.3928 19.0412 76.7184 19.0553C79.0021 19.0412 80.6973 17.3012 80.7113 14.8176Z" fill="#363946"/>
    <path d="M103.52 7.15688L103.492 21.2872C103.394 25.9599 99.4287 29.019 95.562 29.0049C90.2242 28.9909 88.0807 25.1882 87.8426 24.5006L90.9387 22.8869C91.1489 23.5324 92.9281 25.4688 95.562 25.4829C98.0698 25.4829 99.4007 23.1816 99.5548 21.4977C95.8702 23.8551 90.9668 22.7747 88.6131 19.0842C87.8005 17.8073 87.3662 16.3199 87.3662 14.8184C87.3802 10.4404 90.9247 6.91833 95.2958 6.9043C96.8089 6.9043 98.2939 7.33929 99.5548 8.16719V7.15688H103.52ZM99.4287 14.8465C99.4287 12.3909 97.7476 10.6368 95.4499 10.6368C93.1243 10.6368 91.4571 12.3768 91.4571 14.8325C91.4571 17.3161 93.1103 19.0702 95.4359 19.0842C97.7195 19.0842 99.4147 17.3442 99.4287 14.8465Z" fill="#363946"/>
    <path d="M105.9 14.8321C105.914 10.4541 109.459 6.91797 113.83 6.91797C118.201 6.91797 121.732 10.4821 121.732 14.8602C121.718 19.2382 118.173 22.7743 113.802 22.7743C109.431 22.7603 105.914 19.2101 105.9 14.8321ZM117.949 14.8602C117.949 12.4045 116.268 10.6225 113.97 10.6084C111.644 10.6084 109.977 12.3765 109.977 14.8461C109.977 17.3018 111.63 19.0838 113.956 19.0838C116.254 19.0838 117.949 17.3158 117.949 14.8602Z" fill="#363946"/>
    <path d="M133.962 20.964C133.962 20.964 132.309 22.8022 129.633 22.8022C127 22.8022 124.982 21.5113 124.982 18.3119L124.996 10.7346H123.133V7.19847H124.996L125.01 2.06271L128.933 1.97852L128.919 7.19847L132.533 7.2125V10.7486L128.919 10.7346L128.905 17.1192C128.905 18.3821 129.633 19.1539 130.488 19.1539C131.525 19.1539 132.267 18.326 132.267 17.8348L133.962 20.964Z" fill="#363946"/>
    <path d="M27.9498 14.8188C27.9077 14.7626 27.8937 14.7065 27.8377 14.6644L15.523 2.27402C15.495 2.23193 15.4669 2.18983 15.4389 2.14774C14.7384 1.47419 13.6456 1.47419 12.9452 2.14774C12.9171 2.18983 12.8891 2.23193 12.8611 2.27402L0.518366 14.6083C0.182129 14.945 0 15.4081 0.0280198 15.8852V15.8992L0 23.238C0 24.094 0.686485 24.7816 1.54109 24.7816H2.15753L26.2966 24.8237H26.913C27.7676 24.8237 28.4541 24.1361 28.4681 23.2801L28.4821 15.9554C28.4681 15.5204 28.272 15.1134 27.9498 14.8188ZM3.71262 16.2921L14.192 5.83819L24.7555 16.4605L24.7415 21.3718L3.69861 21.3297L3.71262 16.2921Z" fill="#0076FF"/>
    <path d="M140.183 18.9154C140.491 19.0978 140.757 19.3504 140.926 19.6591C141.29 20.3186 141.29 21.1185 140.926 21.778C140.743 22.1007 140.491 22.3533 140.169 22.5357C139.51 22.9005 138.726 22.9005 138.067 22.5357C137.759 22.3533 137.493 22.0867 137.325 21.778C136.961 21.1185 136.961 20.3327 137.325 19.6731C137.507 19.3644 137.759 19.0978 138.081 18.9154C138.404 18.733 138.768 18.6348 139.146 18.6348C139.496 18.6488 139.861 18.747 140.183 18.9154ZM139.973 22.213C140.225 22.0586 140.435 21.8481 140.575 21.5955C140.869 21.0623 140.869 20.4028 140.575 19.8696C140.435 19.617 140.225 19.4065 139.973 19.2662C139.707 19.1119 139.412 19.0417 139.104 19.0417C138.796 19.0417 138.502 19.1119 138.25 19.2662C137.997 19.4065 137.787 19.631 137.647 19.8836C137.353 20.4168 137.353 21.0764 137.647 21.6096C137.941 22.1428 138.502 22.4655 139.104 22.4515C139.412 22.4375 139.707 22.3673 139.973 22.213ZM140.099 20.8378C140.029 20.9641 139.917 21.0623 139.777 21.1185L140.281 21.8902H139.665L139.23 21.2307H138.824V21.8902H138.208V19.5889H139.202C139.468 19.5749 139.721 19.6451 139.931 19.8135C140.113 19.9538 140.211 20.1783 140.197 20.4028C140.211 20.5572 140.169 20.6975 140.099 20.8378ZM139.174 20.7536C139.286 20.7677 139.398 20.7256 139.496 20.6694C139.581 20.5993 139.623 20.5151 139.609 20.4028C139.609 20.3046 139.567 20.2064 139.496 20.1362C139.398 20.066 139.286 20.038 139.174 20.038H138.824V20.7536H139.174Z" fill="#363946"/>
    <path d="M109.894 26.5909C110.594 26.5769 111.295 26.7032 111.953 26.9698C112.5 27.1802 112.962 27.5591 113.298 28.0362C113.943 29.0325 113.943 30.3094 113.298 31.2917C112.962 31.7688 112.486 32.1336 111.953 32.3581C111.295 32.6107 110.594 32.737 109.894 32.7229H108.381V34.8839H105.915V26.5909H109.894ZM109.726 30.8707C110.23 30.8707 110.622 30.7584 110.875 30.562C111.365 30.0989 111.393 29.3272 110.931 28.822C110.917 28.808 110.889 28.7799 110.875 28.7659C110.622 28.5554 110.23 28.4431 109.726 28.4431H108.353V30.8707H109.726Z" fill="#FFC834"/>
    <path d="M118.776 32.6945H117.431V34.8976H114.965V26.6046H118.944C119.644 26.5905 120.345 26.7168 120.989 26.9694C121.535 27.1799 121.998 27.5588 122.334 28.0358C122.656 28.5129 122.824 29.0883 122.81 29.6636C122.824 30.2249 122.67 30.7721 122.362 31.2352C122.054 31.6982 121.606 32.049 121.101 32.2736L122.979 34.8835H120.345L118.776 32.6945ZM120.331 29.6636C120.345 29.3268 120.205 28.99 119.938 28.7655C119.686 28.555 119.294 28.4428 118.79 28.4428H117.417V30.8703H118.79C119.294 30.8703 119.686 30.7581 119.938 30.5616C120.205 30.3512 120.345 30.0144 120.331 29.6636Z" fill="#FFC834"/>
    <path d="M124.184 26.5918H126.649V34.8848H124.184V26.5918Z" fill="#FFC834"/>
    <path d="M136.274 34.8844L136.246 30.4924L134.004 34.0706H132.912L130.684 30.6187V34.8985H128.4V26.6055H130.432L133.5 31.3904L136.484 26.6055H138.516L138.544 34.8985L136.274 34.8844Z" fill="#FFC834"/>
    <path d="M147.286 33.0746V34.8848H140.281V26.5918H147.118V28.416H142.719V29.8192H146.6V31.5732H142.719V33.1027H147.286V33.0746Z" fill="#FFC834"/>
    <path d="M154.137 31.6419H152.427V33.2977H150.578V31.6419H148.883V29.8879H150.578V28.2461H152.427V29.8879H154.137V31.6419Z" fill="#363946"/>
    <path d="M161.703 26.9407C163.216 27.5862 163.916 29.3402 163.272 30.8417C162.977 31.5573 162.403 32.1186 161.703 32.4133C161.072 32.6799 160.386 32.8062 159.713 32.7921H158.522V34.8268H155.734V26.5478H159.713C160.386 26.5338 161.072 26.6741 161.703 26.9407ZM160.386 30.3926C160.764 29.9857 160.764 29.3683 160.386 28.9614C160.204 28.793 159.909 28.7088 159.531 28.7088H158.522V30.6452H159.531C159.909 30.6452 160.19 30.561 160.386 30.3926Z" fill="#0076FF"/>
    <path d="M169.59 33.3821H166.452L165.891 34.8274H163.075L166.69 26.5625H169.422L173.036 34.8274H170.164L169.59 33.3821ZM168.819 31.3755L168.021 29.2988L167.222 31.3755H168.819Z" fill="#0076FF"/>
    <path d="M174.549 34.7857C173.989 34.6594 173.442 34.4629 172.952 34.1683L173.849 32.1336C174.269 32.3722 174.703 32.5546 175.166 32.6809C175.614 32.8212 176.09 32.8913 176.567 32.8913C176.847 32.9054 177.127 32.8633 177.393 32.7791C177.533 32.737 177.645 32.5967 177.645 32.4423C177.645 32.2739 177.533 32.1336 177.323 32.0494C176.973 31.9231 176.623 31.8389 176.258 31.7688C175.712 31.6565 175.194 31.5162 174.661 31.3338C174.241 31.1794 173.849 30.9128 173.555 30.576C173.246 30.2252 173.078 29.7481 173.078 29.1588C173.078 28.6536 173.232 28.1625 173.513 27.7415C173.835 27.2925 174.297 26.9417 174.815 26.7312C175.488 26.4646 176.202 26.3524 176.917 26.3664C177.477 26.3664 178.038 26.4225 178.584 26.5488C179.088 26.6611 179.579 26.8295 180.027 27.082L179.187 29.1027C178.472 28.7238 177.688 28.5133 176.889 28.4853C176.174 28.4853 175.81 28.6536 175.81 29.0044C175.81 29.1728 175.922 29.2991 176.132 29.3693C176.469 29.4815 176.819 29.5798 177.183 29.6359C177.73 29.7341 178.262 29.8604 178.78 30.0428C179.215 30.1972 179.593 30.4498 179.901 30.8006C180.223 31.1514 180.377 31.6144 180.377 32.2178C180.377 32.723 180.223 33.2141 179.943 33.635C179.607 34.0841 179.159 34.4349 178.64 34.6313C177.968 34.8979 177.253 35.0242 176.539 34.9962C175.866 35.0102 175.208 34.94 174.549 34.7857Z" fill="#0076FF"/>
    <path d="M182.297 34.7857C181.737 34.6594 181.191 34.4629 180.7 34.1683L181.597 32.1336C182.017 32.3722 182.451 32.5546 182.914 32.6809C183.362 32.8212 183.838 32.8913 184.315 32.8913C184.595 32.9054 184.875 32.8633 185.141 32.7791C185.281 32.737 185.394 32.5967 185.394 32.4423C185.394 32.2739 185.281 32.1336 185.071 32.0494C184.721 31.9231 184.371 31.8389 184.007 31.7688C183.46 31.6565 182.942 31.5162 182.409 31.3338C181.975 31.1794 181.597 30.9128 181.303 30.576C180.994 30.2252 180.826 29.7481 180.826 29.1588C180.826 28.6536 180.98 28.1625 181.261 27.7415C181.583 27.2925 182.045 26.9417 182.564 26.7312C183.236 26.4646 183.95 26.3524 184.665 26.3664C185.225 26.3664 185.786 26.4225 186.332 26.5488C186.837 26.6611 187.327 26.8295 187.775 27.082L186.935 29.1027C186.22 28.7238 185.436 28.5133 184.637 28.4853C183.922 28.4853 183.558 28.6536 183.558 29.0044C183.558 29.1728 183.67 29.2991 183.88 29.3693C184.217 29.4815 184.567 29.5798 184.931 29.6359C185.478 29.7341 186.01 29.8604 186.528 30.0428C186.963 30.1972 187.341 30.4498 187.649 30.8006C187.971 31.1514 188.125 31.6144 188.125 32.2178C188.125 32.723 187.971 33.2141 187.691 33.635C187.355 34.0841 186.907 34.4349 186.388 34.6313C185.716 34.8979 185.001 35.0242 184.287 34.9962C183.628 35.0102 182.956 34.94 182.297 34.7857Z" fill="#0076FF"/>
  </svg>
  ,
  'WiggotPrimePlusPassLogoBar',
  '0 0 189 35'
)