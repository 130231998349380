var _jsxFileName = 'src/lib/elements/Layouts/MarginContainer.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
var MarginContainer = function MarginContainer(_ref) {
  var children = _ref.children,
      className = _ref.className;

  var marginContainerClasses = classNames(className, 'margin-container');
  return React.createElement(
    'div',
    { className: marginContainerClasses, __source: {
        fileName: _jsxFileName,
        lineNumber: 6
      },
      __self: _this
    },
    children
  );
};

MarginContainer.propTypes = {
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string
};

export default MarginContainer;