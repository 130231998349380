import React, { useContext } from 'react'
import { number, string, shape, arrayOf } from 'prop-types'
import { ThemeContext } from 'styled-components'

// se tiene que trabajar desde aqui!!!!

// se tiene que obtener el equivalente en pixeles en base a las proporciones de la grafica

// 1 px NO representa un punto en el mapa forzosamente

const STROKE = 3

const PADDING = 1.2

function LineChart(props) {
  const { data, height, width } = props

  const { palette } = useContext(ThemeContext)

  const maximumXFromData = Math.max(...data.map(e => e.x))
  const minimunXFromData = Math.min(...data.map(e => e.x))
  const maximumYFromData = Math.max(...data.map(e => e.y))

  const chartWidth = width
  const chartHeight = height

  const points = data
    .map(element => {
      const x =
        (maximumXFromData / (maximumXFromData - minimunXFromData)) *
        (element.x / maximumXFromData - minimunXFromData / maximumXFromData) *
        chartWidth
      const y =
        chartHeight - (element.y / (maximumYFromData * PADDING)) * chartHeight
      return `${x},${y}`
    })
    .join(' ')

  return (
    <svg className={props.className} viewBox={`0 0 ${width} ${height}`}>
      <polyline
        fill="none"
        stroke={palette.primary.main}
        strokeWidth={STROKE}
        points={points}
      />
    </svg>
  )
}

LineChart.defaultProps = {
  height: 200,
  width: 500,
  horizontalGuides: 4,
  verticalGuides: null,
  precision: 2
}

LineChart.propTypes = {
  className: string,
  data: arrayOf(
    shape({
      value: number,
      label: string
    })
  ).isRequired,
  height: number,
  width: number,
  horizontalGuides: number,
  verticalGuides: number,
  precision: number
}

export default LineChart
