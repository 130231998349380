import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M27.0595 21.5006C27.0595 14.7542 30.167 8.7342 35.0288 4.79221C31.3346 1.79661 26.6258 0 21.4987 0C9.62445 0 0 9.62558 0 21.5002C0 33.3744 9.62445 42.9993 21.4987 42.9993C26.6266 42.9993 31.3346 41.2038 35.0288 38.2071C30.167 34.2662 27.0595 28.2466 27.0595 21.5006Z"
      fill="#EA001B"
    />
    <path
      d="M48.5594 0.000366211C43.4315 0.000366211 38.725 1.79698 35.0292 4.79257C39.8918 8.73456 42.9993 14.7546 42.9993 21.5009C42.9993 28.2469 39.8918 34.2666 35.0292 38.2078C38.725 41.2041 43.4323 43 48.5594 43C60.4344 43 70.0588 33.3752 70.0588 21.5009C70.0588 9.62595 60.4336 0.000366211 48.5594 0.000366211Z"
      fill="#F79F1A"
    />
    <path
      d="M42.9989 21.5006C42.9989 14.7542 39.8914 8.73423 35.0288 4.79224C30.1674 8.73423 27.0595 14.7542 27.0595 21.5006C27.0595 28.2466 30.167 34.2662 35.0288 38.2075C39.891 34.2662 42.9989 28.2466 42.9989 21.5006Z"
      fill="#FF5F01"
    />
  </Fragment>,
  'MasterCard',
  '0 0 71 43'
)
