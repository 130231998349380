var _jsxFileName = 'src/lib/elements/Checkboxes/SimpleCheckboxItem.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IconButton } from '../../generics/Buttons';
import { Layout } from '../Layouts';
import { Text } from '../Texts';

var SimpleCheckboxItem = function SimpleCheckboxItem(_ref) {
  var id = _ref.id,
      center = _ref.center,
      circle = _ref.circle,
      className = _ref.className,
      disabled = _ref.disabled,
      isSelected = _ref.isSelected,
      label = _ref.label,
      noCheckMark = _ref.noCheckMark,
      onBlur = _ref.onBlur,
      _onClick = _ref.onClick,
      onFocus = _ref.onFocus,
      uppercase = _ref.uppercase,
      value = _ref.value;

  var checkboxContainerClasses = classNames(className, 'simple-checkbox-item', {
    'simple-checkbox-item--checked': isSelected,
    'simple-checkbox-item--unchecked': !isSelected,
    'simple-checkbox-item--disabled': disabled
  });

  var checkboxClasses = classNames('simple-checkbox-item__checkbox', {
    'simple-checkbox-item__checkbox--checked': isSelected,
    'simple-checkbox-item__checkbox--unchecked': !isSelected,
    'simple-checkbox-item__checkbox--disabled': disabled,
    'simple-checkbox-item__checkbox--circle': circle
  });

  var labelCheckbox = classNames('simple-checkbox-item__label', {
    'simple-checkbox-item__disabled': disabled
  });

  return React.createElement(
    Layout,
    { center: center, className: checkboxContainerClasses, middle: true, __source: {
        fileName: _jsxFileName,
        lineNumber: 46
      },
      __self: _this
    },
    React.createElement(IconButton, {
      id: id,
      className: checkboxClasses,
      disabled: disabled,
      dripiconIcon: isSelected && !noCheckMark ? 'checkmark' : null,
      noResponsive: true,
      onClick: function onClick() {
        return _onClick(value);
      },
      onBlur: onBlur,
      onFocus: onFocus,
      stillGray: !isSelected,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 47
      },
      __self: _this
    }),
    label && React.createElement(
      Text,
      {
        onClick: function onClick() {
          return !disabled && _onClick(value);
        },
        shrink: true,
        className: labelCheckbox,
        uppercase: uppercase,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 59
        },
        __self: _this
      },
      label
    )
  );
};

SimpleCheckboxItem.propTypes = {
  /** Value to give on callback. */
  value: PropTypes.any.isRequired,
  /** Render's the element in a centered position */
  center: PropTypes.bool,
  /** Sets a button which has a border-radius of 100%. */
  circle: PropTypes.bool,
  /** Custom className in Layout Wrapper.  */
  className: PropTypes.string,
  /** Gives a disable stylish. */
  disabled: PropTypes.bool,
  /** Icon button identifier. */
  id: PropTypes.string,
  /** Image url that should be displayed. */
  imageUrl: PropTypes.string,
  /** Gives a diferent style. */
  isSelected: PropTypes.bool,
  /** Text to render in checkbox */
  label: PropTypes.string,
  noCheckMark: PropTypes.bool,
  /**  Handles onBlur action.*/
  onBlur: PropTypes.func,
  /** Handler of click action passing value. */
  onClick: PropTypes.func,
  /**  Handles onFocus action.*/
  onFocus: PropTypes.func,
  /** Display's the label prop in a uppercase form */
  uppercase: PropTypes.bool
};

SimpleCheckboxItem.defaultProps = {
  onBlur: function onBlur() {
    return null;
  },
  onFocus: function onFocus() {
    return null;
  }
};

export default SimpleCheckboxItem;