import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M71.32,56.53V31.34a2.54,2.54,0,0,0-5.07,0V53.86h-8V40.27a3.44,3.44,0,0,0-3.46-3.46H45.2a3.44,3.44,0,0,0-3.46,3.46V54h-8V31.34a2.53,2.53,0,1,0-5.06,0V56.53a2.53,2.53,0,0,0,2.53,2.53H44.4a2.53,2.53,0,0,0,2.53-2.53V41.87h6.13V56.53a2.53,2.53,0,0,0,2.53,2.53h13.2a2.53,2.53,0,0,0,2.53-2.53m5.59-25a2.54,2.54,0,0,1-1.73-.67L50,6.16,24.82,30.68a2.55,2.55,0,0,1-3.6-3.6L48.27.7a2.66,2.66,0,0,1,3.59,0L78.91,27.08a2.42,2.42,0,0,1,0,3.6,3.2,3.2,0,0,1-2,.8"
      style={{ 'fill-rule': 'evenodd' }}
    />
    <path d="M95.91,69.88h0a3,3,0,0,1-1.29,2L69.57,89A16,16,0,0,1,58,91.67a85.5,85.5,0,0,1-10.48-2.29,105,105,0,0,0-11.46-2.62,21.28,21.28,0,0,0-8.7.46L23.51,70.67c2.43-1.58,8.19-4.83,14.6-4.77,5.77,0,7.86,1.42,10,2.89a17.31,17.31,0,0,0,3.46,1.94c3,1.14,9.54,1.14,12.26,1l4-.27a3.93,3.93,0,0,1,4,3.19.91.91,0,0,1-.25.77,3.27,3.27,0,0,1-2,.65L52.94,77.16a2.17,2.17,0,0,0,.29,4.32l16.68-1.13a7.07,7.07,0,0,0,4.69-1.88,5.15,5.15,0,0,0,1.26-2.07l15.43-9.77a3,3,0,0,1,4.12.85,3.11,3.11,0,0,1,.5,2.37ZM22.83,93,12.38,95.5a1.49,1.49,0,0,1-1.79-1.14L4.32,67.51a1.52,1.52,0,0,1,.19-1.14,1.45,1.45,0,0,1,1-.67L16,63.46h.21a1.5,1.5,0,0,1,1.52,1.14l1.16,5a2.22,2.22,0,0,0,.38,1.4L24,91.14a1.56,1.56,0,0,1-.19,1.16,1.46,1.46,0,0,1-.94.67ZM98.9,65A7.22,7.22,0,0,0,89,62.89l-13.7,8.72a8.24,8.24,0,0,0-7.75-4.49l-4,.27c-3,.2-8.58,0-10.46-.69a13.21,13.21,0,0,1-2.66-1.51c-2.33-1.57-5.3-3.54-12.32-3.59a31.15,31.15,0,0,0-15.68,4.66l-.63-2.64h0a5.72,5.72,0,0,0-6.71-4.44L4.55,61.43A5.73,5.73,0,0,0,.89,64a5.94,5.94,0,0,0-.73,4.49L6.51,95.37a5.79,5.79,0,0,0,6,4.49,6,6,0,0,0,.95-.14l10.45-2.53a5.82,5.82,0,0,0,4.39-5.7A17.47,17.47,0,0,1,35.46,91a107.56,107.56,0,0,1,11,2.5,85.45,85.45,0,0,0,11,2.4,20.52,20.52,0,0,0,4.36.17A20,20,0,0,0,72,92.48l25-17a7.47,7.47,0,0,0,3.13-4.82A7.59,7.59,0,0,0,98.9,65Z" />
  </Fragment>,
  'PropertOffer',
  '0 0 100.23 99.87'
)
