import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  ContentContainer,
  WiggotPrimeLogo
} from './StyledComponents'

import ReportsPrime from 'static/drawings/reports-prime.svg'

const Reports = props => {
  return (
    <Container className={props.className}>
      <WiggotPrimeLogo />
      <ContentContainer>
        <h3>Descarga reportes de tu inmobiliaria</h3>
        <img src={ReportsPrime} alt="Descarga reportes de tu inmobiliaria" />
      </ContentContainer>
      <p>
        Analiza el desempeño de tu inmobiliaria con reportes de tus propiedades
        y asesores.
      </p>
    </Container>
  )
}

Reports.propTypes = {
  className: PropTypes.string
}

export default Reports
