export const LeadPremiumUserInterested = (Authorization, data) => ({
  type: 'LEAD_PREMIUM_USER_INTERESTED',
  payload: {
    request: {
      url: '/admin/lead_generation/user_lead_premiun',
      method: 'post',
      headers: {
        Authorization
      },
      data
    }
  }
})
