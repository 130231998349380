import React, { Component } from 'react'
import { string, func, object } from 'prop-types'
import styled from 'styled-components'
import { Button } from 'rc'
import { Archive } from 'rc/Icons'

import { ModelForm } from '../forms/model'

class ModelMC extends Component {
  state = {
    loading: false
  }

  handleSubmit = values => {
    const model = {
      ...values,
      model_development_id:
        this.props.model && this.props.model.model_development_id
    }
    this.setState({ loading: true }, () => {
      this.props.cb(model, () => this.setState({ loading: false }))
    })
  }

  render() {
    const { className, model, HideModal } = this.props
    return (
      <Container className={className}>
        <ModelFormStyled
          form="modal"
          model={model}
          handleSubmit={this.handleSubmit}
        />
        <Footer>
          <ButtonStyled color="gray" variant="outlined" onClick={HideModal}>
            Cancelar
          </ButtonStyled>
          <ButtonStyled  color="gray" variant="outlined" onClick={HideModal}>
            <Archive 
              css={`
                 width: 20px;
                 margin: 0 8px;
              `}/> Guardar borrador
          </ButtonStyled>
          <ButtonStyled type="submit" formNoValidate={this.props.model === undefined} form="modal">
            Enviar
          </ButtonStyled>
        </Footer>
      </Container>
    )
  }
}

ModelMC.propTypes = {
  HideModal: func,
  className: string,
  model: object,
  cb: func
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.remCalc('790')};
  max-height: ${({ theme }) => theme.remCalc('600')};
  width: 100%;
  height: 80vh;
  h3 {
    padding-left: 0;
    padding-right: 0;
  }
`

const ModelFormStyled = styled(ModelForm)`
  flex: 1;
  width: 100%;
  overflow-y: auto;
`

const Footer = styled.footer`
  display: flex;
  justify-content: space-evenly;
  background-color: ${({ theme }) => theme.palette.base.white};
  padding: ${({ theme }) => theme.remCalc('12 5')};
  ${({ theme }) => theme.bordered('1', theme.palette.gray.main, 'top')}
`

const ButtonStyled = styled(Button)`
  width: 30%;
  border-radius: 50px !important;
`

export default ModelMC
