var _jsxFileName = 'src/lib/elements/Texts/ErrorText.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout, Block } from '../Layouts';
import { Text } from './';

var ErrorText = function ErrorText(_ref) {
  var className = _ref.className,
      text = _ref.children,
      center = _ref.center;

  var errorClasses = classNames(className, 'error-text');
  return React.createElement(
    Block,
    { className: errorClasses, __source: {
        fileName: _jsxFileName,
        lineNumber: 10
      },
      __self: _this
    },
    React.createElement(
      Layout,
      { alert: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 11
        },
        __self: _this
      },
      React.createElement(
        Text,
        { className: 'error-text__text', center: center, __source: {
            fileName: _jsxFileName,
            lineNumber: 12
          },
          __self: _this
        },
        text
      )
    )
  );
};

ErrorText.propTypes = {
  /** */
  center: PropTypes.bool,
  /** */
  children: PropTypes.string,
  /** */
  className: PropTypes.string
};

export default ErrorText;