import React, { Component } from 'react'
import { oneOf, func, string, bool, object } from 'prop-types'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { Button } from 'rc'

import {
  Agenda,
  Datasheets,
  HTTPS,
  Intro,
  Offerings,
  Reports,
  Suggestions
} from './steps'

import BulletIndicator from './BulletIndicator'

import { PrimeButton } from 'modules/prime'

import { ShowModal } from 'reduxActions/services'

import Background from 'static/prime/prime-modal.svg'

const FLOW = {
  INIT: 'INIT',
  REMINDER: 'REMINDER'
}

class OnBoardingMC extends Component {
  state = {
    step: this.props.flow === FLOW.INIT ? 0 : 1
  }

  openPaymentSection = () => {
  }

  handleStep = step => {
    this.setState({ step })
  }

  handleRadioStep = e => {
    const {
      target: { value }
    } = e
    this.handleStep(parseInt(value))
  }

  getButtonProps = () => {
    const { step } = this.state
    switch (step) {
      case 0:
        return {
          primary: {
            id: "onBoardingMC-button-knowTools",
            label: 'Conocer herramientas',
            action: () => this.handleStep(step + 1)
          }
        }
      case 6:
        return {
          primary: null
        }
      default:
        return {
          primary: {
            id: "",
            label: 'Siguiente',
            action: () => this.handleStep(step + 1)
          }
        }
    }
  }

  renderTopContent = () => {
    switch (this.state.step) {
      case 0:
        return <Intro />
      case 1:
        return <Suggestions />
      case 2:
        return <Offerings />
      case 3:
        return <Reports />
      case 4:
        return <Datasheets />
      case 5:
        return <Agenda />
      case 6:
        return <HTTPS />
      default:
        return null
    }
  }

  render() {
    const { step, loading } = this.state
    const { primary } = this.getButtonProps()
    return (
      <Container>
        <ImageBackground />
        {this.renderTopContent()}
        {step > 0 && (
          <BulletIndicatorStyled
            step={step}
            handleStepChange={this.handleRadioStep}
          />
        )}
        <ButtonContainer>
          {primary && (
            <Button onClick={primary.action} id={primary.id} disabled={loading}>
              {primary.label}
            </Button>
          )}
          {this.props.flow === FLOW.INIT && (
            <PrimeButton onClick={this.openPaymentSection} id="onBoardingMC-button-hireWiggotPrime" >
              Contratar wiggot prime
            </PrimeButton>
          )}
        </ButtonContainer>
      </Container>
    )
  }
}

OnBoardingMC.propTypes = {
  ActivePrime: func,
  HideModal: func,
  ShowModal: func,
  LockModal: func,
  UnlockModal: func,
  ShowFlashNotification: func,
  action: func,
  className: string,
  flow: oneOf([FLOW.INIT, FLOW.REMINDER]),
  isAdmin: bool,
  token: string,
  type: string,
  roles: object
}

OnBoardingMC.defaultProps = {
  flow: FLOW.INIT
}

const ImageBackground = styled.img.attrs({
  src: Background,
  alt: 'Background'
})`
  position: absolute;
  top: 0;
  z-index: -1;
`

const BulletIndicatorStyled = styled(BulletIndicator)`
  margin-bottom: ${({ theme }) => theme.remCalc('10')};
`

const mobileContainerStyle = css`
  ${ImageBackground} {
    height: 250px;
    object-fit: cover;
  }
`

const desktopContainerStyle = css`
  width: ${({ theme }) => theme.remCalc('600')};
  padding: ${({ theme }) => theme.remCalc('30 40')};
  ${ImageBackground} {
    left: 0;
    right: 0;
    z-index: -1;
  }
  ${BulletIndicatorStyled} {
    width: ${({ theme }) => theme.remCalc('600')};
    margin-bottom: ${({ theme }) => theme.remCalc('25')};
  }
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.remCalc('10')};
  ${({ theme }) => theme.media.desktopDown`${mobileContainerStyle}`}
  ${({ theme }) => theme.media.desktop`${desktopContainerStyle}`}
  > div {
    width: 100%;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  > button {
    flex: 1;
    height: ${({ theme }) => theme.remCalc('50')};
    margin-right: ${({ theme }) => theme.remCalc('10')};
  }
  > button:last-child {
    margin-left: ${({ theme }) => theme.remCalc('10')};
    margin-right: 0;
  }
`

const mapDispatchToProps = {
  ShowModal
}

const mapStateToProps = ({ authReducer: { roles } }) => ({ roles })

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingMC)

export { FLOW }
