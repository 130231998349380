import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { connect } from 'react-redux'

import {
  Grid,
  Typography,
  FormContainer,
  TextField,
  SubmitButton
} from 'rc'
import { Eye, EyeSlash } from 'rc/Icons'
import { useMedia } from 'rc/Hooks'
import { ChangePassword } from 'reduxActions/auth'
import { ShowFlashNotification } from 'reduxActions/services'
import { HideModal } from 'reduxActions/services'
import { RULES } from 'modules/freemium/utils/constants'
import FreemiumWrapper from 'modules/freemium/FreemiumWrapper'

const ContainerShadowStyled = styled(Grid)`
  border-radius: 5px;
  width: 100%;
  min-height: ${({ theme }) => theme.remCalc('230')};
  padding: ${({ theme }) => theme.remCalc('10 0')};
  ${({ theme }) => theme.media.desktop`
    margin-bottom: ${({ theme }) => theme.remCalc('60')};
    padding: ${theme.remCalc('40 50')};
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.2);    
  `}
`

const ProfileSettingsMC = ({
  ChangePassword,
  HideModal,
  ShowFlashNotification,
  token,
  theme: {
    devices: { desktop }
  }
}) => {
  const [visible, setVisible] = useState({
    previous: false,
    new: false
  })


  const isDesktop = useMedia(desktop)

  const handleSubmitNewPassword = data => {
    const required_fields = ['password', 'new_password']
    const fields_with_errors = required_fields.filter(
      field =>
        !data[field] ||
        (typeof data[field] === 'string' && data[field].trim().length === 0)
    )
    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }

    return ChangePassword(token, data)
  }

  const handleSubmitNewPasswordSuccess = () => {
    HideModal()
    ShowFlashNotification('success', 'Tu contraseña se ha actualizado.')
  }

  const handleSubmitNewPasswordFail = (formErrors, fc, submitErrors) => {
    if (formErrors && Object.keys(formErrors).length > 0) {
      for (const prop in formErrors) {
        if (formErrors[prop]) {
          return ShowFlashNotification(
            'danger',
            'Ninguno de los campos puede estar vacío'
          )
        }
      }
    }

    if (!submitErrors) {
      submitErrors = {}
    }
    const {
      error: { response: { data: { error: { code } = {} } = {} } = {} } = {},
      code: customCode,
      metadata
    } = submitErrors

    switch (code || customCode) {
      case 'VALIDATION_ERROR':
        ShowFlashNotification(
          'danger',
          'Verifica tu contraseña anterior. Código de error: WIG-04'
        )
        break
      case 'INTERNAL_SERVER_ERROR':
        ShowFlashNotification(
          'danger',
          'Error desconocido, por favor intenta de nuevo más tarde.'
        )
        break
      case 'REQUIRED':
        {
          const fieldErrors = metadata.reduce((previous, current) => {
            return {
              ...previous,
              [current]: 'Campo requerido'
            }
          }, {})

          fc.setCustomErrors({
            ...fieldErrors
          })
        }
        break
      default:
        ShowFlashNotification(
          'danger',
          'Error desconocido, por favor intenta de nuevo más tarde.'
        )
        break
    }
  }

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  return (
    <Grid
      container
      center
      direction="column"
      css={`
        width: ${({ theme }) => theme.remCalc('1080')};
        max-width: 100%;
        padding: ${({ theme }) => theme.remCalc('20 20')};
        ${({ theme }) => theme.media.desktop`
            padding: ${theme.remCalc('30')};            
        `}
      `}
    >
      <Typography
        variant="h6"
        color="palette.primary.main"
        textTransform="initial"
        css={`
          width: 100%;
          text-align: left;
          ${({ theme }) => theme.media.desktop`          
            margin-bottom: ${({ theme }) => theme.remCalc('50')};
          `}
        `}
      >
        Configuraciones
      </Typography>
      {(
        <Fragment>
          <ContainerShadowStyled
            direction="column"
            alignItems="space-between"
            container
            item
            flat
          >
            <Typography variant="h6" textTransform="initial">
              Cambiar contraseña
            </Typography>
            <Grid item alignItems="flex-end" flat>
              <FormContainer
                initialValues={{
                  password: '',
                  new_password: ''
                }}
                onSubmit={handleSubmitNewPassword}
                onSubmitSuccess={handleSubmitNewPasswordSuccess}
                onSubmitFail={handleSubmitNewPasswordFail}
              >
                <Grid
                  css={`
                    ${({ theme }) => theme.media.desktop`          
                    margin-left: ${({ theme }) => theme.remCalc('-12')};
               `}
                  `}
                  container
                  justify="space-between"
                >
                  <Grid item flat={isDesktop ? false : true} md={7.5}>
                    <TextField
                      id="profile-settings-mc-input-oldPassword"
                      fullWidth
                      type={visible.previous ? 'text' : 'password'}
                      name="password"
                      label="Contraseña anterior"
                      required
                      validate={isRequired}
                      InputProps={{
                        endAdornment: (
                          <div
                            onClick={() =>
                              setVisible({
                                ...visible,
                                previous: !visible.previous
                              })
                            }
                            css={`
                              padding-right: ${({ theme }) =>
                                theme.remCalc('5')};
                              padding-top: ${({ theme }) => theme.remCalc('8')};
                              cursor: pointer;
                            `}
                          >
                            {!visible.previous ? (
                              <Eye
                                css={`
                                  ${({ theme }) => theme.box(25, 'auto')}
                                  color: ${({ theme }) =>
                                    theme.palette.text.grayDark};
                                `}
                              />
                            ) : (
                              <EyeSlash
                                css={`
                                  ${({ theme }) => theme.box(25, 'auto')}
                                  color: ${({ theme }) =>
                                    theme.palette.text.grayDark};
                                `}
                              />
                            )}
                          </div>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item flat={isDesktop ? false : true} md={7.5}>
                    <TextField
                      id="profile-settings-mc-input-newPassword"
                      fullWidth
                      type={visible.new ? 'text' : 'password'}
                      name="new_password"
                      label="Nueva contraseña"
                      required
                      validate={isRequired}
                      InputProps={{
                        endAdornment: (
                          <div
                            onClick={() =>
                              setVisible({ ...visible, new: !visible.new })
                            }
                            css={`
                              padding-right: ${({ theme }) =>
                                theme.remCalc('5')};
                              padding-top: ${({ theme }) => theme.remCalc('8')};
                              cursor: pointer;
                            `}
                          >
                            {!visible.new ? (
                              <Eye
                                css={`
                                  ${({ theme }) => theme.box(25, 'auto')}
                                  color: ${({ theme }) =>
                                    theme.palette.text.grayDark};
                                `}
                              />
                            ) : (
                              <EyeSlash
                                css={`
                                  ${({ theme }) => theme.box(25, 'auto')}
                                  color: ${({ theme }) =>
                                    theme.palette.text.grayDark};
                                `}
                              />
                            )}
                          </div>
                        )
                      }}
                    />
                  </Grid>
                  <Grid flat={isDesktop ? false : true} item md={7.5}>
                    <FreemiumWrapper
                      section={RULES.ACCOUNT_EXCLUSIVE_API}
                      label={"Cambiar contraseña"}
                    >
                      <SubmitButton
                        css={`
                          width: 100%;
                          height: ${({ theme }) => theme.remCalc('46')};
                          margin-top: ${({ theme }) => theme.remCalc('16')};
                        `}
                        id="profile-settings-mc-button-submit"
                      >
                        Cambiar contraseña
                      </SubmitButton>
                    </FreemiumWrapper>
                  </Grid>
                </Grid>
              </FormContainer>
            </Grid>
          </ContainerShadowStyled>
        </Fragment>
      )}
    </Grid>
  )
}

ProfileSettingsMC.propTypes = {
  ChangePassword: PropTypes.func,
  HideModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  theme: PropTypes.object,
  token: PropTypes.string,
}

const mapStateToProps = ({ authReducer: { token } }) => ({ token })

const mapDispatchToProps = {
  ChangePassword,
  HideModal,
  ShowFlashNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ProfileSettingsMC))
