var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/FormInputs/thirdParty/AsyncSelect.js';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import InputLabelContainer from '../InputLabelContainer';

import { AsyncSelect as SelectComponent } from '../../Selects/thirdParty';
import { inputProp, inputMetaProp, inputLabelContainerProp } from '../_types';

var AsyncSelect = (_temp2 = _class = function (_Component) {
  _inherits(AsyncSelect, _Component);

  function AsyncSelect() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, AsyncSelect);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AsyncSelect.__proto__ || Object.getPrototypeOf(AsyncSelect)).call.apply(_ref, [this].concat(args))), _this), _this.getOptionLabel = function (item) {
      var getOptionLabel = _this.props.getOptionLabel;

      return getOptionLabel ? getOptionLabel(item) : item['name'];
    }, _this.getOptionValue = function (item) {
      var getOptionValue = _this.props.getOptionValue;

      return getOptionValue ? getOptionValue(item) : item['name'];
    }, _this.onChangeRF = function (item) {
      var value = Array.isArray(item) ? item.length > 0 ? item : _this.props.input.value : item;

      _this.props.input.onChange(value ? value['name'] : '');
      _this.props.onChangeCb(value);
    }, _this.valueResolver = function (value) {
      var valueResolver = _this.props.valueResolver;

      var valueResolved = value ? valueResolver ? valueResolver(value) : _defineProperty({}, 'name', value) : undefined;
      return valueResolved;
    }, _this.inputProp = function () {
      var _this$props = _this.props,
          input = _this$props.input,
          meta = _this$props.meta,
          componentType = _this$props.componentType;

      switch (componentType) {
        case 'RF':
          return {
            error: meta.error,
            touched: meta.touched,
            onChange: _this.onChangeRF,
            value: _this.valueResolver(input.value),
            onFocus: function onFocus() {
              return input.onFocus();
            },
            onBlur: function onBlur() {
              return input.onBlur();
            }
          };
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(AsyncSelect, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          label = _props.label,
          labelProps = _props.labelProps,
          isRequired = _props.isRequired,
          disabled = _props.disabled,
          loadOptions = _props.loadOptions,
          placeholder = _props.placeholder,
          cacheOptions = _props.cacheOptions,
          styles = _props.styles,
          isClearable = _props.isClearable;

      return React.createElement(
        InputLabelContainer,
        Object.assign({
          isRequired: isRequired,
          label: label
        }, labelProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 108
          },
          __self: this
        }),
        React.createElement(SelectComponent, Object.assign({
          disabled: disabled,
          placeholder: placeholder,
          cacheOptions: cacheOptions,
          getOptionLabel: this.getOptionLabel,
          getOptionValue: this.getOptionValue,
          loadOptions: _.debounce(loadOptions, 300),
          styles: styles,
          isClearable: isClearable
        }, this.inputProp(), {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 113
          },
          __self: this
        }))
      );
    }
  }]);

  return AsyncSelect;
}(Component), _class.propTypes = {
  /** */
  loadOptions: PropTypes.func.isRequired,
  /** */
  cacheOptions: PropTypes.bool,
  /** */
  componentType: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** */
  getOptionLabel: PropTypes.func,
  /** */
  getOptionValue: PropTypes.func,
  /** */
  input: inputProp,
  isClearable: PropTypes.bool,
  /** */
  isRequired: PropTypes.bool,
  /** */
  label: PropTypes.string,
  /** */
  labelProps: inputLabelContainerProp,
  /** */
  meta: inputMetaProp,
  /** */
  onChangeCb: PropTypes.func,
  /** */
  placeholder: PropTypes.string,
  valueResolver: PropTypes.func
}, _class.defaultProps = {
  placeholder: 'Seleccionar',
  disabled: false,
  label: '',
  onChangeCb: function onChangeCb() {
    return null;
  }
}, _temp2);


export default AsyncSelect;