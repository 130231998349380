var _jsxFileName = 'src/lib/elements/Buttons/StepButton.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var BASE_CLASSNAME = 'step-button';

var StepButton = function StepButton(_ref) {
  var _classNames;

  var stepName = _ref.stepName,
      primary = _ref.primary,
      isSelected = _ref.isSelected,
      onClick = _ref.onClick,
      danger = _ref.danger,
      className = _ref.className,
      index = _ref.index,
      id = _ref.id;

  var stepBtnClasses = classNames(className, '' + BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--active', isSelected), _defineProperty(_classNames, BASE_CLASSNAME + '--primary', primary), _defineProperty(_classNames, BASE_CLASSNAME + '--danger', danger), _classNames));
  var stepNumber = parseInt(index) + 1;
  stepNumber = stepNumber < 10 ? '' + stepNumber : stepNumber;
  return React.createElement(
    'span',
    { onClick: onClick, id: id, className: stepBtnClasses, __source: {
        fileName: _jsxFileName,
        lineNumber: 25
      },
      __self: _this
    },
    stepNumber + '. ' + (stepName ? stepName : 'Paso')
  );
};

StepButton.propTypes = {
  /** Custom class. */
  className: PropTypes.string,
  /** Show danger icon and states a danger stylish. */
  danger: PropTypes.bool,
  /** Used to identify button. */
  id: PropTypes.string,
  /** Step button index. */
  index: PropTypes.number,
  /** Gives a specific style on activeness. */
  isSelected: PropTypes.bool,
  /** Handler of click action on button. */
  onClick: PropTypes.func,
  /** Gives a primary style. */
  primary: PropTypes.bool,
  /** Text to be displayed #.{StepName}. */
  stepName: PropTypes.string
};

export default StepButton;