function EventStream() {
  this.listeners = []
}

EventStream.prototype.dispatch = function (type, data) {
  for (var iListener = 0; iListener < this.listeners.length; iListener++) {
    if (this.listeners[iListener].events[type]) {
      this.listeners[iListener].events[type]({ type, data })
    }
  }
}
EventStream.prototype.unsubscribe = function (instance) {
  for (var iListener = 0; iListener < this.listeners.length; iListener++) {
    if (this.listeners[iListener].instance === instance) {
      this.listeners.splice(iListener, 1)
    }
  }
}
EventStream.prototype.subscribe = function (instance, type, callback) {
  for (var iListener = 0; iListener < this.listeners.length; iListener++) {
    if (this.listeners[iListener].instance === instance) {
      return true
    }
  }

  let events = {}

  if (Array.isArray(type) && Array.isArray(callback)) {
    if (type.length === callback.length) {
      for (let i in type) {
        events[type[i]] = callback[i]
      }
    }
  } else {
    events[type] = callback
  }

  this.listeners.push({ instance: instance, events: events })
}
export const EventRx = new EventStream()

export const EVENTS = {
  ASSOCIATION_INVITATION_SENT: 'association-invitation-sent',
  CREATED_AGENCY_BROKER: 'created-agency-broker',
  CREATED_CONTACT: 'created-contact',
  CREATED_CONTACT_REQUIREMENT: 'created-contact-requirement',
  DELETED_CONTACT_REQUIREMENT: 'deleted-contact-requirement',
  UPDATE_SITE_INFORMATION: 'update-site-information',
  UPDATING_SITE_INFORMATION: 'updating-site-information',
  HAS_UPDATED_SITE_INFORMATION: 'has-updated-site-information',
  CHANGE_RECORD_STATUS: 'change-record-status',
  DEVELOPMENT_REMOVED: 'development-removed',
  RELOAD_PROFILE_VIEW: 'reload-profile-view',
  SET_PROFILE_SCORE: 'set-profile-score',
  RELOAD_TASKS: 'reload-tasks',
  PAYMENT_EVENTS: 'payment-event',
  AVACLICK_ADDED_CREDITS: 'avaclick_added_credits',
  SEARCHER_BACK: 'searcher_back',
  SET_TAB_BAR_STEP: 'set_tab_bar_step',
  SIDE_MENU_OPENED: 'side_menu_opened'
}

export const SEARCH_REQUIREMENT_EVENTS = {
  ADD_PROPERTY_TO_HOLDER: 'add-property-to-holder',
  CHANGE_PROPERTY_STATUS: 'change-property-status',
  IS_PROPERTY_CHECKED: 'is-property-checked',
  IS_PROPERTY_CHECKED_RETURN: 'is-property-checked-return',
  RESET_PROPERTY_CARD_STATUS: 'reset-property-card-status'
}
