'use strict';

var Themes_createTheme = require('./createTheme.js');
require('@babel/runtime/helpers/defineProperty');
require('@babel/runtime/helpers/objectWithoutProperties');
require('./createTypography.js');
require('./globalUtils.js');
require('@babel/runtime/helpers/typeof');
require('./createPalette.js');
require('./createMixin.js');
require('styled-components');
require('./createButton.js');

var defaultTheme = Themes_createTheme();

module.exports = defaultTheme;
