import React from 'react'
import { object } from 'prop-types'
import { Typography, withForm } from 'rc'
import { UploadImage } from 'modules/development/forms/development/components'
import { ModelFormSectionContainer } from 'modules/development/static-components'

function Photos(props) {
  const {
    fc: {
      values: { images = [] },
      customChange
    }
  } = props
  return (
    <ModelFormSectionContainer>
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
      >
        Fotos del modelo
      </Typography>
      <div
        css={`
          display: flex;
        `}
      >
        <Typography variant="subtitle1" color="palette.new.text">
          Carga imágenes o renders del prototipo o modelo.
        </Typography>
        <Typography
          variant="body3"
          color="palette.new.text"
          css={`
            margin-left: ${({ theme }) => theme.remCalc('8')};
          `}
        >
          (Se requieren mínimo 1 imagen)
        </Typography>
      </div>
      <UploadImage
        name="images"
        values={images || []}
        customChange={customChange}
        multiple
        btnTitle="Subir fotos"
      />
    </ModelFormSectionContainer>
  )
}

Photos.propTypes = {
  fc: object
}

export default withForm(Photos)
