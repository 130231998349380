var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/Selects/SelectMenu.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SelectMenuOption from './SelectMenuOption';

import { Layout } from '../Layouts';

var SelectMenu = (_temp2 = _class = function (_PureComponent) {
  _inherits(SelectMenu, _PureComponent);

  function SelectMenu() {
    var _ref,
        _this2 = this;

    var _temp, _this, _ret;

    _classCallCheck(this, SelectMenu);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SelectMenu.__proto__ || Object.getPrototypeOf(SelectMenu)).call.apply(_ref, [this].concat(args))), _this), _this.renderMenuOptions = function (option, key) {
      var _this$props$value = _this.props.value,
          value = _this$props$value === undefined ? {} : _this$props$value;

      var valueId = value;
      if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
        var _value$id = value.id,
            id = _value$id === undefined ? '' : _value$id;

        valueId = id;
      }
      var optionId = option.id;

      if (typeof optionId !== 'undefined') {
        optionId = option;
      }

      return React.createElement(
        SelectMenuOption,
        {
          key: key,
          id: 'select-' + (option.id ? String(option.id).toLowerCase() : option.name),
          selected: valueId === optionId ? true : false,
          option: option,
          onClick: _this.props.onOptionSelected,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 41
          },
          __self: _this2
        },
        option.name
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(SelectMenu, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          open = _props.open,
          options = _props.options,
          className = _props.className;

      return open && React.createElement(
        Layout,
        { className: className, vertical: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 59
          },
          __self: this
        },
        options.map(this.renderMenuOptions)
      );
    }
  }]);

  return SelectMenu;
}(PureComponent), _class.propTypes = {
  /** State of menu. */
  open: PropTypes.bool.isRequired,
  /** List of options to render. */
  options: PropTypes.arrayOf(PropTypes.shape({
    /** */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** */
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired,
  /** Posible classes to add. */
  className: PropTypes.string,
  /** Callback to retrieve selected option. */
  onOptionSelected: PropTypes.func,
  /** In case it has a default selected value. */
  value: PropTypes.any
}, _temp2);


export default SelectMenu;