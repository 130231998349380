/**
 *
 * @typedef {Object} Requirement
 * @property {string} operation_type
 * @property {string} min_price
 * @property {string} max_price
 * @property {string} bedrooms
 * @property {string} bathrooms
 * @property {string} half_bathrooms
 * @property {string} parking_lots
 * @property {string} min_construction_size
 * @property {string} max_construction_size
 * @property {string} min_ground_size
 * @property {string} max_ground_size
 * @property {string} width
 * @property {string} height
 * @property {string} min_maintenance
 * @property {string} max_maintenance
 * @property {string} covered_parking
 * @property {[string]} antiquity_type
 * @property {[string]} use_type
 * @property {string} ground_shape
 * @property {string} ground_relief
 * @property {string} currency
 * @property {string} contact_id
 * @property {[string]} amenities
 * @property {string} property_type
 * @property {string} notification_enabled
 * @property {[Object]} locations
 */

/**
 * Creates a new requirement to a contact
 * @param {string} Authorization
 * @param {Requirement} data
 */
export const CreateRequirement = (Authorization, data) => {
  return {
    type: 'CREATE_REQUIREMENT',
    payload: {
      request: {
        method: 'post',
        url: `/requirement`,
        data,
        headers: { Authorization }
      }
    }
  }
}

/**
 * Edit a requirement to a contact
 * @param {string} Authorization
 * @param {Requirement} data
 */
export const EditRequirement = (Authorization, requirementId, data) => {
  return {
    type: 'EDIT_REQUIREMENT',
    payload: {
      request: {
        method: 'patch',
        url: `/requirement/${requirementId}`,
        data,
        headers: { Authorization }
      }
    }
  }
}

/**
 * All requirements from that user will be gotten
 * @param {string} Authorization
 * @param {number} contactId
 */
export const GetRequirements = (
  Authorization,
  contactId,
  { page, order, orderDirection, filters }
) => ({
  type: 'GET_REQUIREMENTS',
  payload: {
    request: {
      method: 'get',
      url: `/contact/${contactId}/requirements`,
      headers: { Authorization },
      params: { l: 20, p: page, o: order, d: orderDirection, ...filters }
    }
  }
})

/**
 * Removes in single action any requirement belonging to the contact given.
 * @param {string} Authorization
 * @param {number} contactId
 * @param {number} requirementId
 */
export const DeleteRequirement = (Authorization, contactId, requirementId) => ({
  type: 'DELETE_REQUIREMENT',
  payload: {
    request: {
      method: 'delete',
      url: `/contact/${contactId}/requirement/${requirementId}`,
      headers: { Authorization }
    }
  }
})

export const GetRequirement = (Authorization, requirementId) => ({
  type: 'GET_REQUIREMENT',
  payload: {
    request: {
      method: 'get',
      url: `/requirement/${requirementId}`,
      headers: { Authorization }
    }
  }
})

export const GetPropertiesToSend = (requirementId, Authorization) => ({
  type: 'GET_PROPERTIES_TO_SEND',
  payload: {
    request: {
      method: 'get',
      url: `/requirement/${requirementId}/properties`,
      headers: { Authorization }
    }
  }
})

export const UpdateNotes = (
  contactId,
  requirementId,
  notes,
  Authorization
) => ({
  type: 'UPDATE_REQUIREMENT_NOTES',
  payload: {
    request: {
      method: 'patch',
      url: `/contact/${contactId}/requirement/${requirementId}`,
      data: {
        additional_notes: notes
      },
      headers: { Authorization }
    }
  }
})

export const SetPropertyToSend = (
  requirementId,
  propertyId,
  Authorization
) => ({
  type: 'SET_PROPERTY_TO_SEND',
  payload: {
    request: {
      method: 'post',
      url: `/requirement/${requirementId}/add_property/${propertyId}`,
      headers: { Authorization }
    }
  }
})

export const SendRequirementProperties = (requirementId, Authorization) => ({
  type: 'SEND_REQUIREMENT_PROPERTIES',
  payload: {
    request: {
      method: 'post',
      url: `/requirement/${requirementId}/send_properties`,
      headers: { Authorization }
    }
  }
})

// export const GetPropertiesSuggested = (requirementId, Authorization) => ({
//   type: 'GET_PROPERTIES_SUGGESTED',
//   payload: {
//
//     request: {
//       method: 'get',
//       headers: { Authorization },
//       url: `/requirement/${requirementId}/recommendations`
//     }
//   }
// })
export const GetPropertiesSuggested = (Authorization, requirementId, params = {l: 10, p: 0}) => ({
  type: 'GET_PROPERTIES_SUGGESTED',
  payload: {
    request: {
      method: 'get',
      headers: { Authorization },
      url: `/requirement/${requirementId}/suggestions`,
      params: {
        ...params
      }
    }
  }
})

export const RemovePropertySuggested = (
  requirementId,
  propertyId,
  Authorization
) => ({
  type: 'REMOVE_PROPERTY_SUGGESTED',
  payload: {
    request: {
      method: 'post',
      headers: { Authorization },
      url: `/requirement/${requirementId}/ignore/${propertyId}`
    }
  }
})

export const GetPropertiesSent = (requirementId, Authorization) => ({
  type: 'GET_PROPERTIES_SENT',
  payload: {
    request: {
      method: 'get',
      headers: { Authorization },
      url: `/requirement/${requirementId}/sent_properties`
    }
  }
})

export const RemovePropertyToSend = (
  requirementId,
  propertyId,
  Authorization
) => ({
  type: 'REMOVE_PROPERTY_TO_SEND',
  payload: {
    request: {
      method: 'delete',
      url: `/requirement/${requirementId}/remove_property/${propertyId}`,
      headers: { Authorization }
    }
  }
})

export const GetRequirementsList = (
  Authorization,
  { page, order, orderDirection, filters }
) => ({
  type: 'GET_REQUIREMENT_LIST',
  payload: {
    request: {
      method: 'get',
      url: `/requirements/list`,
      headers: { Authorization },
      params: { l: 20, p: page, o: order, d: orderDirection, ...filters }
    }
  }
})

export const GetAllAgencyRequirements = (Authorization, params) => ({
  type: 'GET_ALL_AGENCY_REQUIREMENT',
  payload: {
    request: {
      method: 'get',
      url: `/agency/requirements`,
      headers: { Authorization },
      params: { l: 100, ...params }
    }
  }
})

export const DeleteRequirements = (Authorization, requirementIds) => ({
  type: 'DELETE_REQUIREMENTS',
  payload: {
    request: {
      method: 'delete',
      url: `/requirement`,
      data: {
        requirement_ids: requirementIds
      },
      headers: { Authorization }
    }
  }
})

export const ToggleNotifications = (Authorization, requirementIds, value) => ({
  type: 'TOGGLE_NOTIFICATIONS',
  payload: {
    request: {
      method: 'post',
      url: `/requirement/toggle_notifications`,
      data: {
        requirement_ids: requirementIds,
        notifications_enabled: value
      },
      headers: { Authorization }
    }
  }
})

export const GetSharedProperties = (requirementIds, Authorization, params = {l: 10, p: 0}) => ({
  type: 'GET_SHARED_PROPERTIES',
  payload: {
    request: {
      method: 'get',
      url: `/requirement/${requirementIds}/properties_sent`,
      headers: { Authorization },
      params: {
        ...params
      }
    }
  }
})

export const GetOfferedProperties = (requirementIds, Authorization, params = {l: 10, p: 0}) => ({
  type: 'GET_OFFERED_PROPERTIES',
  payload: {
    request: {
      method: 'get',
      url: `/requirement/${requirementIds}/offered_properties`,
      headers: { Authorization },
      params: {
        ...params
      }
    }
  }
})

export const DeleteRequirementSuggestion = (
  Authorization,
  requirementId,
  propertyId
) => ({
  type: 'DELETE_REQUIREMENTS_SUGGESTION',
  payload: {
    request: {
      method: 'delete',
      url: `/requirement/${requirementId}/suggestion/${propertyId}`,
      headers: { Authorization }
    }
  }
})

export const GetRequirementPropertyCoincidence = (
  Authorization,
  requirementId,
  propertyId,
  isSuggestion
) => ({
  type: 'GET_REQUIREMENT_PROPERTY_COINCIDENCE',
  payload: {
    request: {
      method: 'get',
      url: `/requirement/${requirementId}/${
        isSuggestion ? 'suggestion' : 'property_sent'
      }/${propertyId}`,
      headers: { Authorization }
    }
  }
})

export const EditRequirementNotes = (Authorization, requirementId, notes) => ({
  type: 'EDIT_REQUIREMENT_NOTES',
  payload: {
    request: {
      method: 'patch',
      url: `/requirement/${requirementId}/notes`,
      data: {
        additional_notes: notes
      },
      headers: { Authorization }
    }
  }
})

export const GetRequirementNewPropertiesCount = (
  Authorization,
  requirementId
) => ({
  type: 'GET_REQUIREMENT_NEW_PROPERTIES_COUNT',
  payload: {
    request: {
      method: 'get',
      url: `/requirement/${requirementId}/new_properties_count`,
      headers: { Authorization }
    }
  }
})

export const GetRequirementPropertySuggestion = (
  Authorization,
  requirementId,
  propertyId
) => {
  return {
    type: 'GET_REQUIREMENT_PROPERTY_SUGGESTION',
    payload: {
      request: {
        url: `/requirement/${requirementId}/suggestion/${propertyId}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetRequirementPropertySent = (
  Authorization,
  requirementId,
  propertyId
) => {
  return {
    type: 'GET_REQUIREMENT_PROPERTY_SENT',
    payload: {
      request: {
        url: `/requirement/${requirementId}/property_sent/${propertyId}`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetPropertyMatchesAndOffereds = (
  Authorization,
  propertyId,
  params
) => {
  return {
    type: 'GET_PROPERTY_MATCHES',
    payload: {
      request: {
        url: `/matchs/property/${propertyId}/matches_and_offereds`,
        method: 'get',
        headers: { Authorization },
        params
      }
    }
  }
}

export const DeletePropertyMatch = (Authorization, propertyId, requirementId) => ({
  type: 'DELETE_PROPERTY_MATCH',
  payload: {
    request: {
      method: 'delete',
      url: `/matchs/property/${propertyId}/discart/${requirementId}`,
      headers: { Authorization }
    }
  }
})

export const DeletePropertyMatchs = (Authorization, propertyId, requirementIds) => ({
  type: 'DELETE_PROPERTY_MATCH',
  payload: {
    request: {
      method: 'delete',
      url: `/matchs/property/${propertyId}/discarts?requirement_ids=${requirementIds}`,
      headers: { Authorization }
    }
  }
})

export const OfferPropertyMatch = (Authorization, propertyId, requirementId) => ({
  type: 'OFFER_PROPERTY_MATCH',
  payload: {
    request: {
      method: 'put',
      url: `/matchs/property/${propertyId}/offer/${requirementId}`,
      headers: { Authorization }
    }
  }
})

export const GetPropertyRequirement = (Authorization, propertyId, requirementId) => ({
  type: 'GET_PROPERTY_REQUIREMENT',
  payload: {
    request: {
      method: 'get',
      url: `/matchs/property/${propertyId}/requirement/${requirementId}`,
      headers: { Authorization }
    }
  }
})
