import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  ContentContainer,
  WiggotPrimeLogo
} from './StyledComponents'
import HTTPSPrime from 'static/drawings/https-prime.svg'

const HTTPS = props => {
  return (
    <Container className={props.className}>
      <WiggotPrimeLogo />
      <ContentContainer>
        <h3>Activa tu certificado SSL en tu sitio web</h3>
        <img src={HTTPSPrime} alt="Activa tu certificado SSL en tu sitio web" />
      </ContentContainer>
      <p>
        Agrega una conexión segura a tu página web y protege tu información.
      </p>
    </Container>
  )
}

HTTPS.propTypes = {
  className: PropTypes.string
}

export default HTTPS
