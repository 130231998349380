import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid, Typography, FormContainer, RadioGroup, Radio } from 'rc'
import { SubmitButton, Button } from 'components'
import { UpdateProfileConfig } from 'reduxActions/profile'
import { FormControlLabelStyled } from 'components/CommonStyled'
import {
  HideModal,
  ShowFlashNotification,
  ShowModal
} from 'reduxActions/services'
import NotificationMC from 'modules/app/modalContent/NotificationMC'
import { EventRx, EVENTS } from 'utils/event'

const ProfileScoresSectionModal = ({
  token,
  HideModal,
  ShowModal,
  ShowFlashNotification,
  UpdateProfileConfig,
  initialValues
}) => {
  const handleSubmit = values => {
    return UpdateProfileConfig(token, values)
  }

  const handleSubmitSuccess = (response, formValues) => {
    let message = '',
      title = ''
    const { user_scores_section_config } = formValues
    switch (user_scores_section_config) {
      case 'SHOWN':
        title = '¡Se activó la sección correctamente!'
        message =
          'Se mostrará tu calificación y los comentarios de asesores en tu perfil. '
        break
      case 'SCORE_WITHOUT_COMMENT':
        title = '¡Se activó la sección correctamente!'
        message = 'Se mostrará sólo tu calificación en tu perfil.'
        break
      case 'HIDDEN':
        title = '¡Se desactivó la sección correctamente! '
        message =
          'Se ocultará tu calificación y los comentarios de asesores en tu perfil.'
        break

      default:
        break
    }
    EventRx.dispatch(EVENTS.RELOAD_PROFILE_VIEW)
    ShowModal(
      NotificationMC,
      {
        title,
        message,
        onClose: () => {
          HideModal()
        }
      },
      null,
      true
    )
  }

  const handleSubmitFail = (values, fc, submitErrors) => {
    if (submitErrors) {
      let { code } = submitErrors
      const {
        error: {
          response: { data: { error: { code: responseCode } = {} } = {} } = {}
        } = {}
      } = submitErrors
      code = code || responseCode
      switch (code) {
        default:
          ShowFlashNotification(
            'danger',
            `Ha ocurrido un error, intenta mas tarde.`
          )
          break
      }
    }
  }

  return (
    <FormContainer
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSubmitSuccess={handleSubmitSuccess}
      onSubmitFail={handleSubmitFail}
      css={`
        width: ${({ theme }) => theme.remCalc('500')};
        max-width: 100%;
        padding: ${({ theme }) => theme.remCalc('15 20 20')};

        ${({ theme }) => theme.media.desktop`
          width: ${({ theme }) => theme.remCalc('600')};
          padding: ${({ theme }) => theme.remCalc('30 60 40')};
        `}
      `}
    >
      <Grid container direction="column" justify="center">
        <Typography
          variant="h2"
          textTransform="initial"
          color="palette.primary.main"
          align="center"
        >
          ¿Cómo quieres mostrar esta sección en tu perfil?
        </Typography>

        <Grid container direction="column">
          <RadioGroup
            id={`profileScoresSection-radioGroup-operationType`}
            name="user_scores_section_config"
          >
            <FormControlLabelStyled
              css={`
                font-weight: 400;
                padding: 10px 0;
              `}
              control={
                <Radio
                  id={`profileScoresSection-radio-hidden`}
                  value="HIDDEN"
                />
              }
              label="No mostrar mi calificación ni los comentarios de los asesores"
              labelPlacement="end"
            />
            <FormControlLabelStyled
              css={`
                font-weight: 400;
                padding: 10px 0;
              `}
              control={
                <Radio id={`profileScoresSection-radio-shown`} value="SHOWN" />
              }
              label="Mostrar mi calificación con los comentarios de los asesores"
              labelPlacement="end"
            />
            <FormControlLabelStyled
              css={`
                font-weight: 400;
                padding: 10px 0;
              `}
              control={
                <Radio
                  id={`profileScoresSection-radio-swcomment`}
                  value="SCORE_WITHOUT_COMMENT"
                />
              }
              label="Mostrar mi calificación sin los comentarios de los asesores"
              labelPlacement="end"
            />
          </RadioGroup>
        </Grid>
        <Grid
          container
          justify="space-between"
          css={`
            margin-top: ${({ theme }) => theme.remCalc('25')};
          `}
        >
          <Grid
            item
            flat
            md={11}
            css={`
              ${({ theme }) => theme.media.tabletDown`
                order: 2;
              `}
            `}
          >
            <Button type="tertiary" full onClick={HideModal}>
              Cancelar
            </Button>
          </Grid>
          <Grid
            item
            flat
            md={11}
            css={`
              ${({ theme }) => theme.media.tabletDown`
                margin-bottom: ${({ theme }) => theme.remCalc('5')};
              `}
            `}
          >
            <SubmitButton full onClick={HideModal}>
              Guardar
            </SubmitButton>
          </Grid>
        </Grid>
      </Grid>
    </FormContainer>
  )
}

ProfileScoresSectionModal.defaultProps = {
  initialValues: {
    user_scores_section_config: 'HIDDEN'
  }
}

ProfileScoresSectionModal.propTypes = {
  token: PropTypes.string,
  UpdateProfileConfig: PropTypes.func,
  HideModal: PropTypes.func,
  ShowModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  initialValues: PropTypes.object
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  UpdateProfileConfig,
  HideModal,
  ShowModal,
  ShowFlashNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileScoresSectionModal)
