var _jsxFileName = 'src/lib/generics/FormInputViews/SelectView.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout, LayoutItem } from '../../elements/Layouts';

var BASE_CLASSNAME = 'select-view';

var SelectView = function SelectView(_ref) {
  var _classNames, _classNames2;

  var selectedOptionText = _ref.selectedOptionText,
      insideLabel = _ref.insideLabel,
      className = _ref.className,
      disabled = _ref.disabled,
      secondary = _ref.secondary,
      primaryLight = _ref.primaryLight,
      open = _ref.open,
      onClick = _ref.onClick;

  var selectClasses = classNames(className, 'input', BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--normal', !secondary), _defineProperty(_classNames, BASE_CLASSNAME + '--secondary', secondary), _defineProperty(_classNames, BASE_CLASSNAME + '--primary-light', primaryLight), _defineProperty(_classNames, BASE_CLASSNAME + '--disabled', disabled), _classNames));

  var currentTextClasses = classNames(BASE_CLASSNAME + '__current-text', (_classNames2 = {}, _defineProperty(_classNames2, BASE_CLASSNAME + '__current-text--with-inside-label', insideLabel), _defineProperty(_classNames2, BASE_CLASSNAME + '__current-text--disabled', disabled), _classNames2));
  return React.createElement(
    Layout,
    {
      middle: true,
      className: selectClasses,
      onClick: !disabled ? onClick : null,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 30
      },
      __self: _this
    },
    insideLabel && React.createElement(
      LayoutItem,
      { className: BASE_CLASSNAME + '__inside-label', fullHeight: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 36
        },
        __self: _this
      },
      insideLabel
    ),
    React.createElement(
      LayoutItem,
      {
        className: currentTextClasses,
        auto: true,
        selfBottom: insideLabel ? true : false,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 40
        },
        __self: _this
      },
      selectedOptionText
    ),
    React.createElement(
      LayoutItem,
      { className: BASE_CLASSNAME + '__arrow', shrink: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 47
        },
        __self: _this
      },
      React.createElement('i', {
        className: open ? 'dripicons-chevron-up' : 'dripicons-chevron-down',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 48
        },
        __self: _this
      })
    )
  );
};

SelectView.propTypes = {
  /** */
  className: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** */
  insideLabel: PropTypes.string,
  /** */
  onClick: PropTypes.func,
  /** */
  open: PropTypes.bool,
  /** */
  placeholder: PropTypes.string,
  /** */
  primaryLight: PropTypes.bool,
  /** */
  secondary: PropTypes.bool,
  /** */
  selectedOptionText: PropTypes.string
};

export default SelectView;