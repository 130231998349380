import { useDispatch, useSelector } from 'react-redux'
import { UploadPhoto } from 'reduxActions/general'

export const useUploadImage = type => {
  const dispatch = useDispatch()
  const { token } = useSelector(({ authReducer: { token } }) => ({
    token
  }))

  const upload = (file, onUploadProgress, promise, cb) => {
    dispatch(UploadPhoto(token, file, onUploadProgress, type))
      .then(res => {
        const {
          payload: {
            data: { id }
          }
        } = res
        cb && cb(id)
        return promise.resolve(id)
      })
      .catch(err => {
        promise.reject(err)
      })
  }

  return { upload }
}
