export const makePayment = (Authorization, data) => ({
  type: 'SINGLE_PAYMENT',
  payload: {
    request: {
      method: 'post',
      url: '/payments/payment',
      headers: { Authorization },
      data
    }
  }
})

export const PaymentUnsubscribe = (Authorization, subscriptionId) => ({
  type: 'PAYMENT_UNSUBSCRIBE',
  payload: {
    request: {
      method: 'post',
      url: `/payments/subscription/${subscriptionId}/unsubscribe`,
      headers: { Authorization }
    }
  }
})

export const PaymentResumeSubscription = (Authorization, subscriptionId) => ({
  type: 'PAYMENT_SUBSCRIBE',
  payload: {
    request: {
      method: 'post',
      url: `/payments/subscription/${subscriptionId}/resume`,
      headers: { Authorization }
    }
  }
})

export const GetPaymentPlans = (Authorization, group = '', group_type_id = null, userGenerals = {}) => ({
  type: 'GET_PAYMENT_PLANS',
  payload: {
    request: {
      method: 'get',
      url: '/payments/plans',
      headers: { Authorization },
      params: {
        group,
        group_type_id,
        ...userGenerals
      }
    }
  }
})

export const GetTemporalyPlan = (Authorization, planId) => ({
  type: 'GET_PAYMENT_PLANS',
  payload: {
    request: {
      method: 'get',
      url: `/payments/temporaly_plans/${planId}`,
      headers: { Authorization }
    }
  }
})

export const GenetareLeadForPortalPlans = (Authorization, data) => ({
  type: 'POST_GENERATE_LEAD_FOR_PORTAL_PLANS',
  payload: {
    request: {
      method: 'post',
      url: `/payments/leads/portal_plans`,
      headers: { Authorization },
      data
    }
  }
})
