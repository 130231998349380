import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M7.55455 1.03156C6.77727 0.365618 5.68636 0 4.5 0C3.31363 0 2.22272 0.365618 1.44545 1.03156C0.504539 1.84113 -3.32919e-06 3.0555 -3.32919e-06 4.55713C-3.32919e-06 7.83461 3.94092 11.6866 4.11819 11.8433C4.22728 11.9478 4.36363 12 4.5 12C4.63636 12 4.78637 11.9478 4.88182 11.8433C5.04546 11.6866 9 7.83461 9 4.55713C8.98636 3.0555 8.49545 1.84113 7.55455 1.03156ZM4.48636 10.7203C4.11818 10.3286 3.53182 9.67573 2.9591 8.89227C1.73182 7.22089 1.07727 5.73232 1.07727 4.57019C1.07727 1.30577 3.68182 1.04461 4.48636 1.04461C7.65 1.04461 7.89545 3.74755 7.89545 4.57019C7.89545 6.86834 5.45455 9.70185 4.48636 10.7203ZM4.48636 2.25898C3.28636 2.25898 2.31818 3.21219 2.31818 4.37432C2.31818 5.53645 3.28636 6.48966 4.48636 6.48966C5.68636 6.48966 6.65455 5.53645 6.65455 4.37432C6.65455 3.19913 5.68636 2.25898 4.48636 2.25898ZM4.48636 5.56256C3.81818 5.56256 3.27272 5.02721 3.27272 4.36127C3.27272 3.69532 3.81818 3.15996 4.48636 3.15996C5.15455 3.15996 5.7 3.69532 5.7 4.36127C5.7 5.02721 5.15455 5.56256 4.48636 5.56256Z"
      fill="#7D859A"
    />
  </Fragment>,
  'PinLocation',
  '0 0 9 12'
)
