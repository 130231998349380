import { createReducer } from '../store'

const initialState = {
  credits: 0
}

const passReducer = createReducer(
  {
    ...initialState
  },
  {
    GET_AVAILABLE_CREDITS_FOR_PASS_SUCCESS: (state, action) => {
      const { data: { credits } } = action.payload
      return { ...state, credits }
    }
  }
)

export default passReducer
