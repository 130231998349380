import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Grid, Typography } from 'rc'
import { Button } from 'components'

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.remCalc('0 35')};
  width: 100%;
  ${({ theme }) => theme.media.tabletDown`
      padding: ${({ theme }) => theme.remCalc('0')};
  `}
`

const ReactivatePrimeMC = ({ reactivatePrime, reactivateChargebeeSubscription, HideModal, isChargeBee }) => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="space-around"
      css={`
        width: ${({ theme }) => theme.remCalc('650')};
        max-width: 100%;
        height: ${({ theme }) => theme.remCalc('400')};
        padding: ${({ theme }) => theme.remCalc('20 50')};
      `}
    >
        <Typography variant="h2" align="center">
            Estás a punto de reactivar tu plan PRIME
        </Typography>
        <Typography variant="subtitle1" align="center">
            Al terminar el periodo actual de prime contratado se renovará
            automáticamente, hasta que realices de nuevo el proceso de cancelación.
        </Typography>
        <ButtonsContainer>                   
            <Button
                id="account-primeSection-button-reactivatePrimeClose"
                type="tertiary"
                onClick={HideModal}
                css={`
                    width: ${({ theme }) => theme.remCalc('200')};
                    ${({ theme }) => theme.media.tabletDown`
                        width: ${({ theme }) => theme.remCalc('100')};
                    `}
                `}
            >
                Cancelar
            </Button>                         
            <Button
                id="account-primeSection-button-reactivatePrime"        
                onClick={isChargeBee ? () => reactivateChargebeeSubscription() : reactivatePrime()}
                css={`
                    width: ${({ theme }) => theme.remCalc('200')};
                    ${({ theme }) => theme.media.tabletDown`
                    width: ${({ theme }) => theme.remCalc('100')};
                    `}
                `}
            >
                Reactivar
            </Button>                    
        </ButtonsContainer>
    </Grid>
  )
}

ReactivatePrimeMC.propTypes = {
  reactivatePrime: PropTypes.func,
  reactivateChargebeeSubscription: PropTypes.func,
  HideModal: PropTypes.func,
  isChargeBee: PropTypes.bool,  
}

export default ReactivatePrimeMC
