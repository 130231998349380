import React from 'react'
import { createSvgIcon } from 'rc'

export default createSvgIcon(
  <>
    <path d="M720-40v-120H600v-80h120v-120h80v120h120v80H800v120h-80ZM80-160v-240H40v-80l40-200h600l40 200v80h-40v120h-80v-120H440v240H80Zm80-80h200v-160H160v160Zm-38-240h516-516ZM80-720v-80h600v80H80Zm42 240h516l-24-120H146l-24 120Z" />
  </>,
  'AgencyIcon',
  '0 -960 960 960'
)
