import React from 'react'
import styled from 'styled-components'
import { object } from 'prop-types'
import {
  Typography,
  CheckboxControlGroup,
  FormControlLabel,
  CheckboxControl,
  withForm
} from 'rc'
import { CheckmarkSquare as CheckBoxIcon } from 'rc/Icons'
import { ModelFormSectionContainer } from 'modules/development/static-components'

function Equipment(props) {
  const { modelEquipment, fc: { values: { property_type } } = {} } = props
  const isTerrain = property_type === 'TERRAIN'
  return !isTerrain ? (
    <ModelFormSectionContainer>
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
      >
        Equipamiento
      </Typography>
      <Typography variant="subtitle4" fontWeight="regular">
        Selecciona el equipamiento disponible en el modelo.
      </Typography>
      {modelEquipment ? (
        <CheckboxControlGroupStyled name="equipments">
          {Object.keys(modelEquipment).map((key, index) => {
            return (
              <FormControlLabel
                css={`
                  width: 50%;
                  margin-bottom: ${({ theme }) => theme.remCalc('10')};
                `}
                key={index}
                control={
                  <CheckboxControl
                    checkedIcon={CheckBoxIcon}
                    value={key.toString()}
                  />
                }
                label={modelEquipment[key]}
                labelPlacement="end"
              />
            )
          })}
        </CheckboxControlGroupStyled>
      ) : null}
    </ModelFormSectionContainer>
  ) : null
}

Equipment.propTypes = {
  fc: object,
  modelEquipment: object
}

const CheckboxControlGroupStyled = styled(CheckboxControlGroup)`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  margin-top: ${({ theme }) => theme.remCalc('32')};
  > label {
    width: 50%;
    margin-bottom: ${({ theme }) => theme.remCalc('16')};
    font-size: ${({ theme }) => theme.remCalc('16')};
    font-weight: 500;
  }
`

export default withForm(Equipment)
