import Pusher from 'pusher-js'
import { DownloadRoles, SetUserAuthInfo } from 'reduxActions/auth'
import { GetUserInfo } from 'reduxActions/profile'
import { WelcomeToPrimeMC } from 'modules/prime'
import { unregister } from 'src/registerServiceWorker'
import { EventRx, EVENTS } from 'utils/event'
import { notificationModalResolver } from 'modules/app/notifications'
import { GetNotifications } from 'reduxActions/notifications'
import { RemovePDF } from 'reduxActions/pdf'
import { openPDF } from 'modules/app/PDFListener'
import { PaymentModalSuccess } from 'modules/payments/modalContent'
import { NewSessionStartedModal } from 'modules/login'
import { ShowModal } from 'reduxActions/services'
import { GetAllActiveAddons } from 'reduxActions/addons'
import { GetRules } from 'reduxActions/freemium'
import { NewWiggotLeadMC } from 'modules/dashboard/Leads/modalContent'

const pusherHandler = (dispatch, args) => data => {
  const { token, isAdmin, primeType, userId } = args
  const getActiveAddons = () => {
    return dispatch(GetAllActiveAddons(token)).then(
      ({ payload: { data = [] } = {} }) => {}
    )
  }

  const notifications = ({ data: ntf } = {}, ...props) => {
    switch (ntf.type) {
      case 'SINGLE_PAYMENT_PAID': {
        const { metadata: { product } = {} } = ntf
        EventRx.dispatch(EVENTS.AVACLICK_ADDED_CREDITS)
        return dispatch(
          ShowModal(PaymentModalSuccess, {
            message: `Tu pago para ${product}, se ha realizado con éxito.`
          })
        )
      }

      case 'SINGLE_PAYMENT_SUCCESS': {
        const { metadata: { product } = {} } = ntf
        EventRx.dispatch(EVENTS.AVACLICK_ADDED_CREDITS)
        return dispatch(
          ShowModal(PaymentModalSuccess, {
            message: `Tu pago para ${product}, se ha realizado con éxito.`
          })
        )
      }

      case 'WELCOME_TO_PRO': {
        getActiveAddons()
        return
      }

      default:
        return (function () {
          const modal = notificationModalResolver(ntf)
          if (modal) {
            dispatch(ShowModal(modal.component, modal.props))
          }
        })()
    }
  }

  switch (data.event) {
    case 'ROLES_UPDATED':
      return (async function () {
        const rolesRes = await dispatch(DownloadRoles(token))
        dispatch(SetUserAuthInfo({ token, roles: rolesRes.payload.data.roles }))
        const userRes = await dispatch(GetUserInfo(token, isAdmin))
        await dispatch(GetRules(token))
        if (
          primeType !== userRes.payload.data.prime_type &&
          (userRes.payload.data.prime_type === 'FULL_PRIME' ||
            userRes.payload.data.prime_type === 'TRIAL_PRIME')
        ) {
          dispatch(ShowModal(WelcomeToPrimeMC))
        }
      })()

    case 'USER_VERIFIED':
      return (async function () {
        const res = await dispatch(DownloadRoles(token))
        const {
          payload: { data }
        } = res
        dispatch(SetUserAuthInfo({ token, roles: data?.roles || [] }))
        dispatch(GetUserInfo(token, isAdmin))
      })()

    case 'RESET_CACHE':
      localStorage.clear()
      sessionStorage.clear()
      document.cookie.split(';').forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
      })
      unregister()
      window.location.replace(window.location.href)
      break
    case 'RELOAD_PROFILE':
      return (async function () {
        await GetUserInfo(token)
        EventRx.dispatch(EVENTS.RELOAD_PROFILE_VIEW)
      })()
    case 'CALENDAR_TASK_CREATED':
    case 'CALENDAR_TASK_UPDATED':
    case 'CALENDAR_TASK_DELETED':
      EventRx.dispatch(EVENTS.RELOAD_TASKS)
      break
    case 'PDF_READY':
      openPDF(data?.data.pdf_id)
      return dispatch(RemovePDF(data?.data.pdf_id))
    case 'NEW_SESSION_STARTED':
      return dispatch(ShowModal(NewSessionStartedModal, {}, { isLocked: true }))
    case 'ASSIGN_LEAD':
      setTimeout(() => {
        dispatch(GetNotifications(token, userId, 1))
      }, 2500)
      return dispatch(
        ShowModal(NewWiggotLeadMC, { metadata: data?.data || {} })
      )

    case 'NOTIFICATION':
      return (async function () {
        return await notifications(data)
      })()
    default:
      break
  }
}

export function InitializePusher(dispatch, args) {
  const { token, userId } = args
  if (!userId) return

  let inst = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    authEndpoint: `${process.env.REACT_APP_API_BASE_URL}/user/channel`,
    auth: {
      headers: {
        Authorization: token
      }
    }
  })

  let instance = pusherHandler(dispatch, args)

  const channel = inst.subscribe(`private-${userId}`)

  channel.bind('general', instance)

  return () => {
    inst.unsubscribe(`private-${userId}`)?.unbind('general', instance)
  }
}
