import React, { useState } from 'react'
import styled from 'styled-components'
import { Accordion as AccordionLib, Button } from 'rc'
import { ChevronDown as ChevronDownLib } from 'rc/Icons'
import { any, bool, string } from 'prop-types'

function SeeMoreAccordion(props) {
  const [isVisible, setIsVisible] = useState(false)

  function toggleVisibility() {
    setIsVisible(!isVisible)
  }

  return (
    <Accordion title={props.title} isOpen={props.isOpen}>
      {props.children?.(isVisible, toggleVisibility)}
      <ShowMoreButton onClick={toggleVisibility}>
        {isVisible ? 'Ocultar' : 'Ver todas'}{' '}
        <ChevronDown isVisible={isVisible} />
      </ShowMoreButton>
    </Accordion>
  )
}

SeeMoreAccordion.propTypes = {
  children: any,
  isOpen: bool,
  title: string
}

export const Accordion = styled(AccordionLib)`
  background: white;
  padding: ${({ theme }) => theme.remCalc('0 5')};
  &&& {
    ~ .Accordion__Header {
      padding: ${({ theme }) => theme.remCalc('0 2')};
    }
    .range-container {
      display: flex;
      .range-input {
        flex: 1;
      }
      > div:first-child {
        margin-right: 10px;
      }
    }
  }
`

const ShowMoreButton = styled(Button)`
  &[type='button'] {
    width: 100%;
    height: ${({ theme }) => theme.remCalc('50')};
    background-color: ${({ theme }) => theme.palette.base.white};
    border-radius: 0;
    ${({ theme }) =>
      theme.typography.buildTextVariant({
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.primary.main,
        fontSize: theme.typography.small,
        lineHeight: 1.5
      })};
    outline: 0;
    -webkit-appearance: none;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ChevronDown = styled(ChevronDownLib)`
  ${({ theme }) => theme.box(20, 20)}
  fill: currentColor;
  transform: ${({ isVisible }) =>
    isVisible ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: all 200ms ease;
`

export default SeeMoreAccordion
