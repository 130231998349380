import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'rc'
import { Button } from 'components'

const HasExclusivityMC = ({
  HideModal,
  UnlockModal,
  customChange,
  development_id,
  updateExclusivity,
  exclusivity,
  ...props
}) => {
  const [step, setStep] = useState(0)
  const [hasExclusivity, setHasExclusivity] = useState(true)
  const finish = () => {
    UnlockModal()
    HideModal()
  }
  return (
    <Container>
      <Typography
        variant="h2"
        color="palette.primary.main"
        textTransform="initial"
        align="center"
      >
        {step === 0
          ? '¿Tienes la exclusiva para comercializar este desarrollo?'
          : hasExclusivity
          ? 'Exclusividad sujeta a revisión'
          : 'Publicación sujeta a cambios'}
      </Typography>
      {step === 0 ? (
        <RadioContainer>
          <RadioStyled
            active={hasExclusivity}
            onClick={() => setHasExclusivity(true)}
          />
          <Typography variant="body2" color="palette.new.text">
            Si la tengo
          </Typography>
          <RadioStyled
            active={!hasExclusivity}
            onClick={() => setHasExclusivity(false)}
          />
          <Typography variant="body2" color="palette.new.text">
            No la tengo
          </Typography>
        </RadioContainer>
      ) : (
        <Typography variant="body1" color="palette.new.text" align="center">
          {hasExclusivity
            ? 'Haremos una revisión para corroborar que el desarrollo no esté duplicado. En caso de que alguien más compruebe que tiene la exclusiva, podrás seguir con el desarrollo cargado únicamente en modalidad gratuita.'
            : 'Tu desarrollo podrá ser publicado siempre y cuando no haya sido previamente cargado por la inmobiliaria y/o desarrollador con la exclusiva. Si después este lo sube, podrás seguir con el desarrollo cargado únicamente en modalidad gratuita.'}
        </Typography>
      )}
      <ButtonsContainer>
        <Button
          type="tertiary"
          onClick={() => finish()}
          firstStep={step === 0}
          css={`
            width: ${({ firstStep }) => (firstStep ? '48%' : '30%')};
          `}
          rounded
        >
          Cancelar
        </Button>
        {step > 0 ? (
          <Button
            type="secondary"
            css={`
              width: 30%;
            `}
            rounded
            onClick={() => setStep(step - 1)}
          >
            Atrás
          </Button>
        ) : (
          ''
        )}
        <Button
          type="primary"
          firstStep={step === 0}
          css={`
            width: ${({ firstStep }) => (firstStep ? '48%' : '30%')};
          `}
          rounded
          onClick={
            step === 0
              ? () => setStep(step + 1)
              : development_id
              ? () => {
                  updateExclusivity(hasExclusivity)
                  customChange({
                    exclusivity: hasExclusivity,
                    has_exclusivity: hasExclusivity
                  })
                  finish()
                }
              : () => {
                  customChange({
                    exclusivity: hasExclusivity,
                    has_exclusivity: hasExclusivity
                  })
                  finish()
                }
          }
        >
          {step === 0 ? 'Siguiente' : 'Aceptar'}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

HasExclusivityMC.propTypes = {
  development_id: PropTypes.string,
  exclusivity: PropTypes.bool,
  updateExclusivity: PropTypes.func,
  customChange: PropTypes.func,
  HideModal: PropTypes.func,
  UnlockModal: PropTypes.func
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('20')};
  ${({ theme }) => theme.box(500, 300)}
`
const RadioStyled = styled.span`
  border: 1px solid gray;
  margin: ${({ theme }) => theme.remCalc('0 10')};
  cursor: pointer;
  ${({ theme }) => theme.box(23, 23, true)}
  ${({ active, theme }) =>
    active &&
    `background-color: ${theme.palette.primary.main}; border: 1px solid ${theme.palette.primary.main};`}
`
const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
`
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export default HasExclusivityMC
