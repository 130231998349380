import React from 'react'
import styled from 'styled-components'

import {  
  TextField,
  PhoneInput
} from 'rc'

const StepThree = () => {
  const isRequired = newValue => {
    return ((!newValue || (newValue && newValue.trim().length === 0)) && 'Campo requerido.')
  }  
  return (
    <StepContainer>
      <TextField
        fullWidth
        required
        label="Nombre de la inmobiliaria"
        name="agency_name"
        validate={isRequired}
      />
      <TextField
        fullWidth
        required
        label="Razón social"
        name="agency_business_name"
        validate={isRequired}
      />
      <TextField
        fullWidth
        required
        label="RFC"
        name="agency_tax_id"
        validate={isRequired}          
      />
      <TextField
        fullWidth
        required
        label="Correo electrónico"
        name="agency_email"
        validate={isRequired}          
      />
      <PhoneInput
        required
        type="text"
        name="agency_phone_number"
        label="Teléfono"
        validate={isRequired}
      />
    </StepContainer>
  )
}

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * { margin: ${({ theme }) => theme.remCalc('0 0 20')}; }
`

StepThree.propTypes = {}

export default StepThree
