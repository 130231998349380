'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var getWindowHeight = function getWindowHeight() {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
};
var getAllOffsetFrom = function getAllOffsetFrom(fromElement, element) {
  var fromElementRect = fromElement.getBoundingClientRect();
  var elementRect = element.getBoundingClientRect();
  return {
    childBottomToParentTop: elementRect.bottom - fromElementRect.top,
    childBottomToParentBottom: fromElementRect.bottom - elementRect.bottom,
    childTopToParentBottom: elementRect.top - fromElementRect.bottom,
    childTopToParentTop: elementRect.top - fromElementRect.top
  };
};
var getSize = function getSize(element) {
  var positionInfo = element.getBoundingClientRect();
  return {
    height: positionInfo.height,
    width: positionInfo.width
  };
};
var scrollTo = function scrollTo(to, duration) {
  var easeInOutQuad = function easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };
  var start = window.scrollY,
    change = to - start,
    currentTime = 0,
    increment = 20;
  var _animateScroll = function animateScroll() {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    window.scrollTo(0, val);
    if (currentTime < duration) {
      setTimeout(_animateScroll, increment);
    }
  };
  _animateScroll();
};
var preventDefault = function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }
  e.returnValue = false;
};
var keys = {
  37: 1,
  38: 1,
  39: 1,
  40: 1
};
var preventDefaultForScrollKeys = function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
};
var disableScroll = function disableScroll() {
  if (window.addEventListener)
    window.addEventListener('DOMMouseScroll', preventDefault, false);
  document.addEventListener('wheel', preventDefault, {
    passive: false
  });
  window.onwheel = preventDefault;
  window.onmousewheel = document.onmousewheel = preventDefault;
  window.ontouchmove = preventDefault;
  document.onkeydown = preventDefaultForScrollKeys;
};
var enableScroll = function enableScroll() {
  if (window.removeEventListener) window.removeEventListener('DOMMouseScroll', preventDefault, false);
  document.removeEventListener('wheel', preventDefault, {
    passive: false
  });
  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
  document.onkeydown = null;
};

exports.disableScroll = disableScroll;
exports.enableScroll = enableScroll;
exports.getAllOffsetFrom = getAllOffsetFrom;
exports.getSize = getSize;
exports.getWindowHeight = getWindowHeight;
exports.scrollTo = scrollTo;
