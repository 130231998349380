import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M2.91504 8.91496C3.74347 8.91496 4.41504 8.24339 4.41504 7.41496C4.41504 6.58653 3.74347 5.91496 2.91504 5.91496C2.08661 5.91496 1.41504 6.58653 1.41504 7.41496C1.41504 8.24339 2.08661 8.91496 2.91504 8.91496Z"
      fill="currentColor"
    />
    <path
      d="M20.585 7.08496C20.585 3.21496 17.455 0.0849609 13.585 0.0849609C9.71504 0.0849609 6.58504 3.21496 6.58504 7.08496C6.58504 10.555 9.10504 13.425 12.415 13.975V17.915H4.41504V14.915H5.41504V10.915C5.41504 10.365 4.96504 9.91496 4.41504 9.91496H1.41504C0.865039 9.91496 0.415039 10.365 0.415039 10.915V14.915H1.41504V19.915H17.415V17.915H14.415V14.035C17.885 13.625 20.585 10.675 20.585 7.08496ZM13.585 12.085C10.825 12.085 8.58504 9.84496 8.58504 7.08496C8.58504 4.32496 10.825 2.08496 13.585 2.08496C16.345 2.08496 18.585 4.32496 18.585 7.08496C18.585 9.84496 16.345 12.085 13.585 12.085Z"
      fill="currentColor"
    />
  </>,
  'EcoGreen',
  '0 0 21 20'
)
