import axios from 'axios'

export const getUserLocationByIP = () =>
  new Promise((resolve, reject) => {
    return axios({
      method: 'get',
      url: `https://api.ipstack.com/check?access_key=88fcf6f0cc2be71d53f30ce09b3b2873`
    })
      .then(({ data = {} }) => {
        const {
          city,
          country_name: country,
          region_name: regionName,
          latitude,
          longitude
        } = data

        if (Number.isSafeInteger(latitude) && Number.isSafeInteger(longitude)) {
          return reject()
        }

        const addressFormatted = `${city ? city + ', ' : ''}${
          regionName ? regionName + ', ' : ''
        }${country ? country + ', ' : ''}`.slice(0, -2)

        return resolve({
          addressFormatted,
          coordinates: { lat: latitude, lng: longitude }
        })
      })
      .catch(error => {
        return reject()
      })
  })

const postPlaceToStandardize = data => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/location/standardize`

  return axios({
    method: 'post',
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data
  }).then(data => {
    return data
  })
}

export const getCustomStandardizePlace = place => {
  return new Promise((resolve, reject) => {
    let realPlace = place
    const { types } = place
    const isEstablishment =
      (types.length === 1 && types[0] === 'geocode') ||
      types.includes('establishment')

    if (!isEstablishment) {
      try {
        postPlaceToStandardize(place).then(response => {
          const { data } = response
          realPlace = {
            name: place && place.description,
            ...place,
            ...data
          }
          resolve(realPlace)
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log({ flag: error })
      }
    } else {
      resolve(realPlace)
    }
  })
}
