import { encodeSHA256, encryptAES } from 'utils/apiHelpers';
import { getCookie } from 'utils/handleCookies';

const AUTH_KEY_SECRET = process.env.REACT_APP_AUTH_KEY_SECRET;
const NEW_WIGGOT_API = process.env.REACT_APP_NEW_WIGGOT_API;

const getListsUrl = (filters) => {
  let url = `${NEW_WIGGOT_API}/properties-lists/lists/property_exists?perPage=50&page=1`;

  if (filters?.search) {
    url += `&search=${filters.search}`;
  }
  if (filters?.propertyId) {
    url += `&validateProperty=${filters.propertyId}`;
  }

  return url;
};

const addPropertyUrl = (filters) => 
  `${NEW_WIGGOT_API}/properties-lists/lists/${filters?.listId}/add-properties`;

const removePropertyUrl = (filters) => 
  `${NEW_WIGGOT_API}/properties-lists/lists/${filters?.listId}/property`;

export async function basicFetchRequest(filters, requestType) {
  const timestamp = Date.now();
  const token = encodeSHA256(timestamp, AUTH_KEY_SECRET);

  const cookieProfileUser = JSON.parse(getCookie('profile_user'));
  const cookieAuthUser = JSON.parse(getCookie('auth_user'));

  const xCustomAuth = {
    userId: cookieProfileUser?.userId,
    roles: cookieAuthUser?.binaryRoles || undefined,
    agencyId: cookieProfileUser?.agencyId || undefined,
  };

  const encryptedXCustomAuth = encryptAES(xCustomAuth, AUTH_KEY_SECRET);

  const headers = {
    timestamp: `${timestamp}`,
    Authorization: `${token}`,
    'X-Custom-Auth': `${encryptedXCustomAuth}`,
    'Content-Type': 'application/json',
  };

  let url = '';
  let method = 'GET';
  let body;

  switch (requestType) {
    case 'lists':
      method = 'GET';
      url = getListsUrl(filters);
      break;
    case 'addProperty':
      url = addPropertyUrl(filters);
      method = 'POST';
      body = JSON.stringify({ propertyIds: [filters?.propertyId] });
      break;
    case 'removeProperty':
      url = removePropertyUrl(filters);
      method = 'PATCH';
      body = JSON.stringify({ propertyId: filters?.propertyId });
      break;
    case 'createList':
      url = `${NEW_WIGGOT_API}/properties-lists/lists`;
      method = 'POST';
      body = JSON.stringify({ list: { name: filters?.name } });
      break;
    default:
      throw new Error(`Request type "${requestType}" is not supported.`);
  }

  const response = await fetch(url, {
    method,
    headers,
    body
  });

  if (!response?.ok) {
    throw new Error(`Error: ${response?.status} - ${response?.statusText}`);
  }

  const data = await response?.json();
  return data;
}
