var _jsxFileName = 'src/lib/elements/Texts/PageTitle.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var PageTitle = function PageTitle(_ref) {
  var text = _ref.children,
      className = _ref.className,
      center = _ref.center,
      primaryLight = _ref.primaryLight,
      noMargin = _ref.noMargin;

  var pageTitleClassNames = classNames(className, 'page-title', {
    'page-title--centered': center,
    'page-title--primary-light': primaryLight,
    'page-title--no-margin': noMargin
  });
  return React.createElement(
    'h6',
    { className: pageTitleClassNames, __source: {
        fileName: _jsxFileName,
        lineNumber: 17
      },
      __self: _this
    },
    text
  );
};

PageTitle.propTypes = {
  /** */
  children: PropTypes.any.isRequired,
  /** */
  center: PropTypes.bool,
  /** */
  className: PropTypes.string,
  /** */
  noMargin: PropTypes.bool,
  /** */
  primaryLight: PropTypes.bool
};

export default PageTitle;