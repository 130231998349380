import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g fill="none" fillRule="evenodd">
      <circle cx="30" cy="30" r="30" fill="#114577" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M43.638 27.996c.483.478.483 1.221 0 1.646a1.098 1.098 0 0 1-.805.318c-.322 0-.59-.106-.805-.318L31.027 18.76 20.026 29.642c-.43.477-1.18.477-1.664 0-.483-.425-.483-1.168 0-1.646l11.86-11.678c.215-.212.483-.318.805-.318.322 0 .59.106.805.318l11.806 11.678z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M38.85 28c.657 0 1.15.553 1.15 1.217v11.566c0 .664-.493 1.217-1.15 1.217h-5.475a1.222 1.222 0 0 1-1.205-1.217v-7.305h-3.34v7.305c0 .664-.493 1.217-1.15 1.217h-5.475A1.222 1.222 0 0 1 21 40.783V29.217c0-.664.548-1.217 1.205-1.217s1.204.553 1.204 1.217v10.404h3.067v-7.36c0-.664.547-1.218 1.204-1.218h5.695c.657 0 1.204.554 1.204 1.218v7.36h3.067V29.217c0-.664.547-1.217 1.204-1.217z"
      />
    </g>
  </Fragment>,
  'PropertyMatch',
  '18 5 25 50'
)
