import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  ContentContainer,
  WiggotPrimeLogo
} from './StyledComponents'

import OfferingsPrime from 'static/drawings/offerings-prime.svg'

const Offerings = props => {
  return (
    <Container className={props.className}>
      <WiggotPrimeLogo />
      <ContentContainer>
        <h3>Ofrece tus propiedades a otros asesores interesados</h3>
        <img
          src={OfferingsPrime}
          alt="Ofrece tus propiedades a otros asesores interesados"
        />
      </ContentContainer>
      <p>
        Conoce que asesores están buscando inmuebles como el tuyo y haz negocios
        más rápido.
      </p>
    </Container>
  )
}

Offerings.propTypes = {
  className: PropTypes.string
}

export default Offerings
