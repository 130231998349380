import PropTypes from 'prop-types';

/**
 * Used as handler of proptypes refered to InputContainerLabel
 */
export var inputLabelContainerProp = PropTypes.shape({
  /** */
  autoInput: PropTypes.bool,
  /** */
  autoLabel: PropTypes.bool,
  /** */
  bottomAlignInput: PropTypes.bool,
  /** */
  bottomAlignLabel: PropTypes.bool,
  /** */
  centerAlignInput: PropTypes.bool,
  /** */
  centerAlignLabel: PropTypes.bool,
  /** */
  className: PropTypes.string,
  /** */
  inlineLabel: PropTypes.bool,
  /** */
  inputWrapperClassName: PropTypes.string,
  /** */
  isRequired: PropTypes.bool,
  /** */
  justifyAlignLabel: PropTypes.bool,
  /** */
  label: PropTypes.string,
  /** */
  labelClassName: PropTypes.string,
  /** */
  labelIcon: PropTypes.string,
  /** */
  labelTextAlignRight: PropTypes.bool,
  /** */
  labelWrapperClassName: PropTypes.string,
  /** */
  largeSizeInput: PropTypes.bool,
  /** */
  largeSizeLabel: PropTypes.bool,
  /** */
  leftAlignInput: PropTypes.bool,
  /** */
  lgAutoInput: PropTypes.bool,
  /** */
  lgAutoLabel: PropTypes.bool,
  /** */
  lgShrinkInput: PropTypes.bool,
  /** */
  lgShrinkLabel: PropTypes.bool,
  /** */
  mdAutoInput: PropTypes.bool,
  /** */
  mdAutoLabel: PropTypes.bool,
  /** */
  mdShrinkInput: PropTypes.bool,
  /** */
  mdShrinkLabel: PropTypes.bool,
  /** */
  mediumSizeInput: PropTypes.number,
  /** */
  mediumSizeLabel: PropTypes.number,
  /** */
  middleAlignInput: PropTypes.bool,
  /** */
  middleAlignLabel: PropTypes.bool,
  /** */
  rightAlignInput: PropTypes.bool,
  /** */
  rightAlignLabel: PropTypes.bool,
  /** */
  shrinkInput: PropTypes.bool,
  /** */
  shrinkLabel: PropTypes.bool,
  /** */
  smallSizeInput: PropTypes.bool,
  /** */
  smallSizeLabel: PropTypes.bool,
  /** */
  topAlignInput: PropTypes.bool,
  /** */
  topAlignLabel: PropTypes.bool
});

export var inputProp = PropTypes.shape({
  value: PropTypes.any,
  onChange: PropTypes.func
});

export var inputMetaProp = PropTypes.shape({
  touched: PropTypes.bool,
  error: PropTypes.any,
  warning: PropTypes.any
});