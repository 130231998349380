var _jsxFileName = 'src/lib/elements/Layouts/PageContainer.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout, LayoutItem } from './';

var PageContainer = function PageContainer(_ref) {
  var children = _ref.children,
      className = _ref.className,
      absoluteCenter = _ref.absoluteCenter,
      middle = _ref.middle,
      center = _ref.center,
      noHeading = _ref.noHeading,
      semiWhite = _ref.semiWhite;

  var pageClasses = classNames(className, 'page-container', {
    'page-container--no-header': noHeading,
    'page-container--header': !noHeading,
    'page-container--semi-white': semiWhite
  });
  return React.createElement(
    Layout,
    {
      className: pageClasses,
      center: center || absoluteCenter,
      middle: middle || absoluteCenter,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 21
      },
      __self: _this
    },
    React.createElement(
      LayoutItem,
      {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 26
        },
        __self: _this
      },
      children
    )
  );
};

PageContainer.propTypes = {
  /** */
  absoluteCenter: PropTypes.bool,
  /** */
  center: PropTypes.bool,
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  middle: PropTypes.bool,
  /** */
  noHeading: PropTypes.bool,
  /** */
  semiWhite: PropTypes.bool
};

export default PageContainer;