import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M8.11572 5.17999H11.1782"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.62158 5.17999L5.05908 5.61749L6.37158 4.30499"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.11572 9.26334H11.1782"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.62158 9.26334L5.05908 9.70084L6.37158 8.38834"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.14974 12.8333H9.64974C12.5664 12.8333 13.7331 11.6667 13.7331 8.74999V5.24999C13.7331 2.33332 12.5664 1.16666 9.64974 1.16666H6.14974C3.23307 1.16666 2.06641 2.33332 2.06641 5.24999V8.74999C2.06641 11.6667 3.23307 12.8333 6.14974 12.8333Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'DocumentCheck',
  '0 0 15 14'
)
