var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/generics/Images/Image.js';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Displays and Image through a src prop.
 */
var Image = (_temp2 = _class = function (_Component) {
  _inherits(Image, _Component);

  function Image() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, Image);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Image.__proto__ || Object.getPrototypeOf(Image)).call.apply(_ref, [this].concat(args))), _this), _this.BASE_CLASSNAME = 'image', _this.sourceResolver = function () {
      var _this$props = _this.props,
          src = _this$props.src,
          baseURL = _this$props.baseURL,
          imgSize = _this$props.imgSize,
          format = _this$props.format,
          notCached = _this$props.notCached;

      var url = baseURL + '/' + src + '-' + imgSize + '.' + format;
      return src instanceof File ? src.preview : baseURL ? notCached ? url + '?' + performance.now() : url : src;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Image, [{
    key: 'render',
    value: function render() {
      var _classNames;

      var _props = this.props,
          className = _props.className,
          onClick = _props.onClick,
          alt = _props.alt,
          wrapperClassName = _props.wrapperClassName,
          fitContent = _props.fitContent;

      var wrapperClasses = classNames(wrapperClassName, this.BASE_CLASSNAME);
      var imgClasses = classNames(className, this.BASE_CLASSNAME + '__img', (_classNames = {}, _defineProperty(_classNames, this.BASE_CLASSNAME + '__img--fit-cover', !fitContent), _defineProperty(_classNames, this.BASE_CLASSNAME + '__img--fit-content', fitContent), _classNames));

      return React.createElement(
        'div',
        { className: wrapperClasses, __source: {
            fileName: _jsxFileName,
            lineNumber: 64
          },
          __self: this
        },
        React.createElement('img', {
          onClick: onClick,
          src: this.sourceResolver(),
          className: imgClasses,
          alt: alt,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 65
          },
          __self: this
        })
      );
    }
  }]);

  return Image;
}(Component), _class.propTypes = {
  /** */
  alt: PropTypes.string,
  /** URL to use if src it's a bucket ID.*/
  baseURL: PropTypes.string,
  /** */
  className: PropTypes.string,
  /** */
  fitContent: PropTypes.bool,
  /** Image format to use (valid only when src come from wiggot's bucket.*/
  format: PropTypes.string,
  /** Size to use in image if source come from wiggot's bucket.
   *  Could be l (large), m (medium), s (small) or t (thumbnail).*/
  imgSize: PropTypes.string,
  notCached: PropTypes.string,
  /** Click action handler.*/
  onClick: PropTypes.func,
  /** Image source; could be a blob or a url.
   *  If it's an ID from wiggot's bucket you need to specify the prop 'baseURL'.*/
  src: PropTypes.any,
  /** */
  wrapperClassName: PropTypes.string
}, _class.defaultProps = {
  className: '',
  alt: 'image',
  src: null,
  imgSize: 'm',
  format: 'jpg'
}, _temp2);


export default Image;