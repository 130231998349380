import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Grid, Typography, Select } from 'rc'
import { WhatsappFlat } from 'rc/Icons'
import { isMobile } from 'rc/helpers/miscellaneous'

import {
  GenerateDevelopmentDataSheetId,
  GetDevelopmentModelsBasics
} from 'reduxActions/development'
import { LinkButton, Button } from 'components'
import { DEVELOPMENT_DATASHEET_TYPES } from 'utils/constants'
import { useAmplitude } from 'src/hooks/useAmplitude'

const ShareDevelopmentsMC = ({
  developmentId,
  HideModal,
  requirementId,
  title,
  userOwned,
  units,
  ownerId,
  ...props
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { token, profileId, userId } = useSelector(
    ({ authReducer: { token }, profileReducer: { profileId, userId } }) => ({
      token,
      profileId,
      userId
    })
  )
  const [isLoading, setIsLoading] = useState(false)
  const [developmentDatasheetId, setDevelopmentDatasheetId] = useState(null)
  const [models, setModels] = useState(
    units && units.modelId
      ? []
      : [{ label: `Desarrollo - ${title}`, value: 'development' }]
  )
  const [modelSelected, setModelSelected] = useState(
    units && units.modelId ? units.modelId : 'development'
  )
  const [url, setUrl] = useState('')
  const { trackEvent, AmplitudeEvents } = useAmplitude()

  const tracker = (name, description, extra = {}) =>
    trackEvent({
      name,
      eventProperties: {
        description,
        ...extra
      },
      configs: {
        path: true
      }
    })

  useEffect(() => {
    const { pathname } = location || {}

    const isDevelopmentSearcher = pathname.includes('developmentsSearcher')
    const isModelDetail = pathname.includes('model')
    const isDevelopmentDetail =
      pathname.includes('/development') &&
      !isModelDetail &&
      !isDevelopmentSearcher

    if (isDevelopmentSearcher)
      tracker(
        AmplitudeEvents.DEVELOPMENTS_SEARCH_SHARE_VIEW,
        'User is viewing share development modal',
        {
          where: 'developmentsSearcher'
        }
      )

    if (isDevelopmentDetail)
      tracker(
        AmplitudeEvents.DEVELOPMENTS_DETAIL_SHARE_VIEW,
        'User is viewing share development modal from development detail',
        {
          where: 'developmentDetail'
        }
      )

    if (isModelDetail)
      tracker(
        AmplitudeEvents.DEVELOPMENTS_MODELS_DETAIL_SHARE_VIEW,
        'User is viewing share development modal from model detail',
        {
          where: 'modelDetail'
        }
      )
  }, [])

  useEffect(() => {
    dispatch(GetDevelopmentModelsBasics(token, developmentId)).then(res => {
      const { payload: { data = [] } = {} } = res
      const arr = data.map(model => ({
        label: `Modelo - ${model.model_name}`,
        value: model.model_development_id
      }))
      if (units && units.modelId) {
        const unit = arr.filter(u => u.value === units.modelId)
        setModels(unit)
        setModelSelected(units.modelId)
      } else {
        data &&
          setModels([
            { label: `Desarrollo - ${title}`, value: 'development' },
            ...arr
          ])
      }
    })
  }, [developmentId])

  useEffect(() => {
    if (developmentDatasheetId) {
      openWhatsapp(developmentDatasheetId)
      setIsLoading(false)
    }
  }, [developmentDatasheetId])

  const openWhatsapp = dataSheetId => {
    let urlEncoded = encodeURI(url)
    if (!isMobile()) {
      const popUp = window.open(
        `https://web.whatsapp.com/send?text=${urlEncoded}`,
        '_blank'
      )
      if (popUp) {
        popUp.focus()
      }
    } else {
      window.location = `whatsapp://send?text=${urlEncoded}`
    }
  }

  const generateDataSheetId = async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    let datasheetType = DEVELOPMENT_DATASHEET_TYPES.DEVELOPMENT
    if (units?.unitId) {
      datasheetType = DEVELOPMENT_DATASHEET_TYPES.UNIT
    }
    if (modelSelected && modelSelected !== 'development') {
      datasheetType = DEVELOPMENT_DATASHEET_TYPES.MODEL
    }
    const response = await dispatch(
      GenerateDevelopmentDataSheetId(
        token,
        developmentId,
        requirementId,
        datasheetType
      )
    )
    const {
      payload: {
        data: { datasheet_id: datasheetId }
      }
    } = response
    const unitsQuery =
      units && units.unit
        ? `?unit=${units.unit}-${units.apartment}${
            profileId ? `-${profileId}` : ''
          }`
        : ''

    setUrl(
      `${process.env.REACT_APP_DEVELOPMENT_DATASHEET}/${datasheetId}${
        modelSelected && modelSelected !== 'development'
          ? `/model/${modelSelected}${unitsQuery}`
          : ''
      }${
        userOwned === 1 && ownerId === userId
          ? ''
          : `${units && units.modelId ? '&' : '?'}profileId=${profileId}`
      }`
    )
    setDevelopmentDatasheetId(datasheetId)
    setIsLoading(false)
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      css={`
        width: ${({ theme }) => theme.remCalc('450')};
        max-width: 100%;
        padding: ${({ theme }) => theme.remCalc('30 50 40')};
      `}
    >
      <Typography
        variant="h2"
        textTransform="initial"
        display="block"
        align="center"
        color="palette.primary.main"
      >
        Compartir ficha
      </Typography>
      <Select
        placeholder={title || 'Desarrollo'}
        optionLabelKey="label"
        optionValueKey="value"
        options={models}
        value={modelSelected}
        customHeight="200"
        onChange={({ target: { value } }) => {
          setModelSelected(value)
          setUrl(
            `${
              process.env.REACT_APP_DEVELOPMENT_DATASHEET
            }/${developmentDatasheetId}${
              value && value !== 'development'
                ? `/model/${value}${
                    units
                      ? `?unit=${units.unit}&apartment=${units.apartment}`
                      : ''
                  }`
                : ''
            }`
          )
        }}
        css={`
          margin-top: ${({ theme }) => theme.remCalc('15')};
        `}
      />
      <WhatsappFlat
        css={`
          margin-top: ${({ theme }) => theme.remCalc('20')};
          ${({ theme }) => theme.box(60, 60)}
          color: ${({ theme }) => theme.palette.success.main};
        `}
      />
      <Typography
        variant="body2"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('20')};
          width: 300px;
          max-width: 90%;
        `}
        align="center"
      >
        Comparte la ficha de los modelos de tu desarrollo con tus clientes en
        whatsapp.
      </Typography>
      <Grid
        container
        justify="space-between"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('40')};
        `}
      >
        <Grid item flat md={11}>
          <Button
            full
            type="tertiary"
            css={`
              ${({ theme }) => theme.media.tabletDown`
              margin-bottom: ${({ theme }) => theme.remCalc('10')};
            `}
            `}
            rounded
            onClick={HideModal}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item flat md={11}>
          <LinkButton
            full
            onClick={() => modelSelected && generateDataSheetId()}
            disabled={isLoading || !modelSelected}
            rounded
            css={`
              border-radius: ${({ theme }) => theme.remCalc('30')};
            `}
          >
            {' '}
            Abrir whatsapp
          </LinkButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

ShareDevelopmentsMC.propTypes = {
  token: PropTypes.string,
  developmentId: PropTypes.string,
  GenerateDevelopmentDataSheetId: PropTypes.func,
  HideModal: PropTypes.func,
  requirementId: PropTypes.string,
  title: PropTypes.string,
  userOwned: PropTypes.bool,
  units: PropTypes.object,
  ownerId: PropTypes.number
}

export default ShareDevelopmentsMC
