var _jsxFileName = 'src/lib/elements/Texts/TextInline.js',
    _this = this;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
var TextInline = function TextInline(_ref) {
  var alignRight = _ref.alignRight,
      danger = _ref.danger,
      className = _ref.className,
      children = _ref.children,
      alignCenter = _ref.alignCenter,
      noResponsive = _ref.noResponsive,
      props = _objectWithoutProperties(_ref, ['alignRight', 'danger', 'className', 'children', 'alignCenter', 'noResponsive']);

  var textInlineClasses = classNames(className, 'text-inline', {
    'text-inline--danger': danger,
    'text-inline--right': alignRight
  });
  return React.createElement(
    'span',
    Object.assign({ className: textInlineClasses }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 18
      },
      __self: _this
    }),
    children
  );
};

TextInline.propTypes = {
  /** */
  alignCenter: PropTypes.bool,
  /** */
  alignRight: PropTypes.bool,
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  danger: PropTypes.bool,
  /** */
  noResponsive: PropTypes.bool
};

export default TextInline;