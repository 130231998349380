import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Grid, Typography } from 'rc'
import { WiggotLogo } from 'components/Icons'
import { PasswordRecoveryForm } from 'modules/login'
import { Footer } from 'modules/app'
import { ResetPassword } from 'reduxActions/auth'
import { ShowFlashNotification } from 'reduxActions/services'

class PasswordRecoveryModule extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,

    ResetPassword: PropTypes.func,
    ShowFlashNotification: PropTypes.func
  }

  state = {
    activeError: false,
    isNewAgency: false
  }

  componentDidMount = () => {
    const {
      match: { path }
    } = this.props
    const pathArr = path.split('/:token')
    this.setState({ isNewAgency: pathArr[0] === '/passwordNewAgAsc' })
  }

  handleSubmit = data => {
    const {
      ResetPassword,
      match: {
        params: { token }
      }
    } = this.props
    const { termsAndConditions } = data
    const { isNewAgency } = this.state

    return new Promise((resolve, reject) => {
      if (isNewAgency) {
        if (termsAndConditions) {
          ResetPassword({ verification_code: token, ...data })
            .then(res => resolve(res))
            .catch(err => reject(err))
        } else {
          this.setState(
            {
              activeError: true
            },
            () => reject({ termsAndConditions: true })
          )
        }
      } else {
        ResetPassword({ verification_code: token, ...data })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }
    })
  }

  handleSubmitSuccess = () => {
    const { history, ShowFlashNotification } = this.props
    history.push('/login')

    setTimeout(() => {
      ShowFlashNotification(
        'success',
        'Tu contraseña se ha actualizado exitosamente.',
        null,
        {
          inFeaturedImage: true
        }
      )
    }, 100)
  }

  handleSubmitFail = (formErrors, dispatch, submitError) => {
    const { ShowFlashNotification } = this.props
    if (formErrors && Object.keys(formErrors).length > 0) {
      return
    }
    if (!submitError) {
      submitError = {}
    }
    const {
      error: { response: { data: { error: { code } = {} } = {} } = {} } = {}
    } = submitError
    let errorMessage = ''
    switch (code) {
      case 'NOT_FOUND':
        errorMessage =
          'Esta solicitud para recuperar tu contraseña ya caducó. Solicita la recuperación de nuevo. Código de error: WIG-03'
        break
      case 'INTERNAL_SERVER_ERROR':
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'
        break
      default:
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'
        break
    }
    if (!submitError.termsAndConditions) {
      ShowFlashNotification('danger', errorMessage, null, {
        inFeaturedImage: true
      })
    }
  }

  render() {
    const { activeError, isNewAgency } = this.state
    return (
      <Grid container direction="column" fullHeight alignItems="space-between">
        <WiggotLogo
          css={`
            width: ${({ theme }) => theme.remCalc('280')};
            margin: 30px auto;
          `}
        />
        <div
          css={`
            padding: ${({ theme }) => theme.remCalc('0 10')};
          `}
        >
          <Typography
            variant="h2"
            color="secondary"
            css={`
              max-width: 400px;
              margin: 0 auto;
            `}
            display="block"
            textTransform="initial"
          >
            Ingresa tu nueva contraseña
          </Typography>
          <PasswordRecoveryForm
            handleSubmit={this.handleSubmit}
            handleSubmitSuccess={this.handleSubmitSuccess}
            handleSubmitFail={this.handleSubmitFail}
            isNewAgency={isNewAgency}
            activeError={activeError}
          />
        </div>

        <Footer />
      </Grid>
    )
  }
}

const mapDispatchToProps = {
  ResetPassword,
  ShowFlashNotification
}

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(PasswordRecoveryModule)
)
