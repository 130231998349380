import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Bell } from 'rc/Icons'

const emptyContainerStyle = css`
  justify-content: center;
  height: ${({ theme }) => theme.remCalc('70')};
  margin-bottom: ${({ theme }) => theme.remCalc('30')};
`

const expiredStyled = css`
  text-decoration-line: line-through;
  background-color: #ededed;
  cursor: default;
`

const hoverStyle = css`
  :hover {
    background-color: #f4f7fa;
  }
`

const ContainerStyled = styled.div`
  width: 100%;
  min-height: ${({ theme }) => theme.remCalc('100')};
  border-bottom-width: 1px;
  border-bottom-color: rgba(151, 164, 177, 0.52);
  border-bottom-style: solid;
  padding: 10px;
  display: flex;
  cursor: pointer;
  ${({ empty }) => empty && emptyContainerStyle}
  ${({ expired }) => (expired ? expiredStyled : hoverStyle)}
`
const ImageContainer = styled.div`
  ${({ theme }) => theme.box(32, 32, true)}
  min-width: ${({ theme }) => theme.remCalc('32')};
  min-height: ${({ theme }) => theme.remCalc('32')};
  margin-right: ${({ theme }) => theme.remCalc('10')};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
  color: #fff;
`
const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
`
const Information = styled.p`
  margin-right: ${({ marginRight }) =>
    marginRight ? ({ theme }) => theme.remCalc('5') : null};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.main};
  line-height: ${({ theme }) => theme.remCalc('17')};
  margin-bottom: 0;
`

const Created = styled.span`
  color: #726e7d;
  font-size: ${({ theme }) => theme.remCalc('14')};
`
const Badge = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #0038ff;
`

const Notifications = props => {
  const {
    id,
    className,
    description,
    created,
    actionHandler,
    read,
    expired,
    extraData,
    type
  } = props

  const addContent = text => ({ __html: text })

  const handlePaymentType = value => {
    switch (value) {
      case 'SINGLE_PAYMENT_PAID':
        return addContent(
          `Tu pago para ${
            JSON.parse(extraData).product
          }, se ha realizado con éxito.`
        )
      case 'SINGLE_PAYMENT_SUCCESS':
        return addContent(
          `Tu pago para ${
            JSON.parse(extraData).product
          }, se ha realizado con éxito.`
        )
      case 'SINGLE_PAYMENT_FAILED':
        return addContent(
          `Ha ocurrido un error con tu pago para ${
            JSON.parse(extraData).product
          }.`
        )
      case 'WELCOME_TO_PRO':
        return addContent(
          'Bienvenido al plan PRO, ahora puedes disfrutar de todos los beneficios.'
        )

      case 'ASSIGN_LEAD':
        return addContent(JSON.parse(extraData).title)

      default:
        return addContent(description)
    }
  }

  return (
    <ContainerStyled
      id={id}
      read={read}
      className={className}
      onClick={actionHandler}
      expired={expired}
    >
      <div css="display: flex; align-items: center; gap: 5px; height: fit-content;">
        {!read && <Badge />}
        <ImageContainer>
          <Bell css="fill: #fff; width: 16px; height: 16px;" />
        </ImageContainer>
      </div>
      <InformationContainer>
        <Information dangerouslySetInnerHTML={handlePaymentType(type)} />
        <Created>{created}</Created>
      </InformationContainer>
    </ContainerStyled>
  )
}

Notifications.propTypes = {
  actionHandler: PropTypes.func,
  badge: PropTypes.object,
  read: PropTypes.bool,
  expired: PropTypes.bool,
  className: PropTypes.string,
  created: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  extraData: PropTypes.string,
  type: PropTypes.string
}

export default Notifications
