import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Title, Text } from 'c3-ui/elements/Texts'
import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'
import { PrimaryButton, SecondaryButton } from 'c3-ui/elements/Buttons'

import {
  HideModal,
  ShowFlashNotification,
  LockModal,
  UnlockModal
} from 'reduxActions/services'
import {
  AcceptAgencyInvitation,
  RejectAgencyInvitation
} from 'reduxActions/agency'

class AcceptAgencyInvitationMC extends Component {
  static propTypes = {
    invitationCode: PropTypes.string,
    agencyName: PropTypes.string,
    AcceptAgencyInvitation: PropTypes.func,
    LockModal: PropTypes.func,
    UnlockModal: PropTypes.func,
    RejectAgencyInvitation: PropTypes.func,
    HideModal: PropTypes.func,
    ShowFlashNotification: PropTypes.func
  }

  BASE_CLASSNAME = 'accept-agency-invitation-mc'

  componentDidMount() {
    this.props.LockModal()
  }

  acceptAgencyInvitation = () => {
    const {
      invitationCode,
      AcceptAgencyInvitation,
      ShowFlashNotification
    } = this.props
    AcceptAgencyInvitation(invitationCode)
      .then(action => {
        this.props.UnlockModal()
        this.props.HideModal()
        window.history.pushState(null, null, '/search')
        this.props.ShowFlashNotification(
          'success',
          'Has aceptado la invitación a la inmobiliaria'
        )
      })
      .catch(error => {
        const {
          error: { response: { data: { error: { code = '' } = {} } = {} } = {} }
        } = error

        switch (code) {
          case 'INVALID_INVITATION':
            ShowFlashNotification(
              'danger',
              'Esta invitación ya fue aceptada o rechazada. Código de error: WIG-14'
            )
            break
          case 'NOT_FOUND':
            ShowFlashNotification(
              'danger',
              'Esta invitación ya fue aceptada o rechazada. Código de error: WIG-14'
            )
            break
          default:
            ShowFlashNotification(
              'danger',
              'Error desconocido, por favor intenta de nuevo más tarde.'
            )
            break
        }
        this.props.UnlockModal()
        this.props.HideModal()
        window.history.pushState(null, null, '/search')
      })
  }

  rejectAgencyInvitation = () => {
    const { invitationCode, ShowFlashNotification } = this.props
    this.props
      .RejectAgencyInvitation(invitationCode)
      .then(() => {
        this.props.HideModal()
        window.history.pushState(null, null, '/search')
      })
      .catch(
        ({
          error: {
            response: { data: { error: { code = '' } = {} } = {} } = {}
          } = {}
        }) => {
          if (code === 'INVALID_INVITATION') {
            ShowFlashNotification(
              'danger',
              'Esta invitación ya fue aceptada o rechazada. Código de error: WIG-17'
            )
          }

          this.props.HideModal()
          window.history.pushState(null, null, '/search')
        }
      )
  }

  render() {
    const { agencyName } = this.props
    return (
      <Layout className={this.BASE_CLASSNAME} vertical justify>
        <Title
          gray
          smallSize
          center
          uppercase
          className={`${this.BASE_CLASSNAME}__title`}
        >
          {agencyName} te ha invitado a formar parte de su inmobiliaria
        </Title>
        <Text center className={`${this.BASE_CLASSNAME}__main-description`}>
          El aceptar la invitación implica que todas tus propiedades y contactos
          formarán parte de la inmobiliara y te dara accesos a las propiedades
          de la misma
        </Text>
        <LayoutItem>
          <Layout paddingX>
            <LayoutItem className={`${this.BASE_CLASSNAME}__cancel-button`}>
              <SecondaryButton
                id="agencyBroker-acceptAgencyInvitation-mc-button-reject"
                onClick={this.rejectAgencyInvitation}
              >
                Rechazar invitacion
              </SecondaryButton>
            </LayoutItem>

            <PrimaryButton
              id="agencyBroker-acceptAgencyInvitation-mc-button-accept"
              mediumAuto
              onClick={this.acceptAgencyInvitation}
            >
              Aceptar invitación
            </PrimaryButton>
          </Layout>
        </LayoutItem>
      </Layout>
    )
  }
}

const mapDispatchToProps = {
  HideModal,
  ShowFlashNotification,
  AcceptAgencyInvitation,
  LockModal,
  UnlockModal,
  RejectAgencyInvitation
}

export default connect(
  null,
  mapDispatchToProps
)(AcceptAgencyInvitationMC)
