import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AgencyBrokerProfile } from 'components/Icons'
import { Typography, Grid } from 'rc'
import { Button } from 'components'
import { ShowModal } from 'reduxActions/services'
const ProfileNotLoggedMC = props => {
  const baseId = 'profile-profileNotLogged-mc'
  return (
    <div
      css={`
        width: ${({ theme }) => theme.remCalc('520')};
        max-width: 100%;
      `}
    >
      <AgencyBrokerProfile></AgencyBrokerProfile>
      <div
        css={`
          padding: ${({ theme }) => theme.remCalc('25 60')};
        `}
      >
        <Typography
          variant="h3"
          color="palette.primary.main"
          css={`
            width: ${({ theme }) => theme.remCalc('340')};
            max-width: 100%;
            margin: 0 auto ${({ theme }) => theme.remCalc('20')};
          `}
          display="block"
          align="center"
        >
          Para ver el perfil este asesor crea tu cuenta de wiggot o inicia
          sesión
        </Typography>
        <Grid container>
          <Grid item md={12}>
            <Button id={`${baseId}-button-createAccount`} full>
              Crear cuenta
            </Button>
          </Grid>
          <Grid item md={12}>
            <Button id={`${baseId}-button-createAccount`} full variant="outlined">
              Inicia sesión
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

ProfileNotLoggedMC.propTypes = {
  ShowModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  ShowModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileNotLoggedMC)
