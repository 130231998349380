import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { TextField } from 'rc'
import { Search } from 'rc/Icons'

import { FreemiumWrapper } from 'modules/freemium'
import { useFreemium } from 'modules/freemium/hooks/useFreemium'
import { ButtonOptions } from 'components'

const ListFilters = ({
  fetchFunction,
  optionsComponent,
  isMobile,
  filtersProps: { searchValidation, filtersValidation } = {},
  ...props
}) => {
  const { validatePermission } = useFreemium()

  return (
    <FiltersContainer>
      <FreemiumWrapper section={searchValidation} custom>
        <TextFieldStyled
          id="search-lists"
          placeholder="Buscar lista"
          onChange={({ target: { value } }) => {
            fetchFunction(value)
          }}
          InputProps={{
            startAdornment: <SearchStyled />
          }}
          disabled={
            !validatePermission({
              section: searchValidation
            }).value
          }
        />
      </FreemiumWrapper>
      <FreemiumWrapper section={filtersValidation} custom>
        <ButtonOptions
          id="lists-header-button-filters-options"
          margin="0 0 0 10px"
          isMobile={isMobile}
          options={
            <OptionsContainer>
              {optionsComponent && optionsComponent()}
            </OptionsContainer>
          }
        />
      </FreemiumWrapper>
    </FiltersContainer>
  )
}

ListFilters.propTypes = {
  fetchFunction: PropTypes.func.isRequired,
  filtersProps: PropTypes.shape({
    searchValidation: PropTypes.object,
    filtersValidation: PropTypes.object
  }),
  optionsComponent: PropTypes.func,
  isMobile: PropTypes.bool
}

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`
const TextFieldStyled = styled(TextField)`
  background-color: white;
  margin: 0;
`
const SearchStyled = styled(Search)`
  width: 20px;
  margin-left: ${({ theme }) => theme.remCalc('15')};
  color: ${({ theme }) => theme.palette.gray.main};
`
const OptionsContainer = styled.div`
  width: 190px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
`

export default ListFilters
