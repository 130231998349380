export const CreatePublicList = (
  Authorization,
  name,
  propertyIds = [],
  origin
) => ({
  type: 'CREATE_PUBLIC_LIST',
  payload: {
    request: {
      method: 'post',
      url: `/public_lists`,
      headers: { Authorization },
      data: {
        origin,
        name,
        property_ids: propertyIds
      }
    }
  }
})

export const UpdatePublicList = (Authorization, name, listId) => ({
  type: 'UPDATE_PUBLIC_LIST',
  payload: {
    request: {
      method: 'patch',
      url: `/public_lists/${listId}`,
      headers: { Authorization },
      data: { name }
    }
  }
})

export const GetPublicLists = (Authorization, filters, p = 0, l = 20) => ({
  type: 'GET_PUBLIC_LIST',
  payload: {
    request: {
      method: 'get',
      url: `/public_lists`,
      headers: { Authorization },
      params: {
        p,
        l,
        ...filters
      }
    }
  }
})

export const AddItemToPublicList = (
  Authorization,
  listId,
  propertyIds,
  origin
) => ({
  type: 'ADD_ITEM_TO_PUBLIC_LIST',
  payload: {
    request: {
      method: 'post',
      url: `/public_lists/${listId}/items`,
      headers: { Authorization },
      data: { property_ids: propertyIds, origin }
    }
  }
})

export const GetListItems = (Authorization, listId, p = 0, l = 20) => ({
  type: 'GET_LIST_ITEMS',
  payload: {
    request: {
      method: 'get',
      url: `/public_lists/${listId}/items`,
      headers: {
        Authorization
      },
      params: {
        p,
        l
      }
    }
  }
})

export const GetDetailList = (Authorization, listId) => ({
  type: 'GET_DETAIL_LIST',
  payload: {
    request: {
      method: 'get',
      url: `/public_lists/${listId}`,
      headers: {
        Authorization
      }
    }
  }
})

export const DeleteList = (Authorization, listId, hard = false) => ({
  type: 'DELETE_LIST',
  payload: {
    request: {
      method: 'delete',
      url: `/public_lists/${listId}`,
      headers: {
        Authorization
      },
      data: {
        delete_reason: 'USER',
        hard
      }
    }
  }
})

export const DeletePropertyFromList = (Authorization, listId, propertyIds) => ({
  type: 'DELETE_PROPERTY_FROM_LIST',
  payload: {
    request: {
      method: 'delete',
      url: `/public_lists/${listId}/items`,
      headers: {
        Authorization
      },
      data: {
        property_ids: propertyIds
      }
    }
  }
})

export const RegisterActivityInList = (Authorization, publicListIds = []) => ({
  type: 'REGISTER_ACTIVITY_IN_LIST',
  payload: {
    request: {
      method: 'put',
      url: `/public_lists/touch`,
      headers: { Authorization },
      data: { public_list_ids: publicListIds }
    }
  }
})
