import React from 'react'
import styled from 'styled-components'
import { Typography, Checkbox, Button, ScrollBottomHandler } from 'rc'
import { Cross, Search, ChevronUp, ChevronDown, Dots3 } from 'rc/Icons'

export const TableContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const HeaderContainerStyled = styled.div`
  width: 100%;
  height: ${({ theme, isFiltersEmpty }) =>
    isFiltersEmpty > 0 ? theme.remCalc('75') : theme.remCalc('15')};
  padding: ${({ theme }) => theme.remCalc('0 5')};
`
export const HeaderTitleStyled = styled.div`
  font-size: ${({ theme }) => theme.remCalc('18')};
  font-weight: bold;
  line-height: ${({ theme }) => theme.remCalc('40')};
  color: #4a4a4a;
  padding-left: ${({ theme }) => theme.remCalc('5')};
  padding-bottom: ${({ theme }) => theme.remCalc('20')};
`
export const HeaderBulkActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: ${({ theme }) => theme.remCalc('11 0')};
  background-color: ${({ theme }) => theme.palette.primary.dark};
  padding: ${({ theme }) => theme.remCalc('8 26 8 38')};
  border-radius: ${({ theme }) => theme.remCalc('10')};
  color: ${({ theme }) => theme.palette.base.white};
  transition: height 0.1s ease-in;
  > div {
    flex: 1;
    display: flex;
    align-items: center;
    > button {
      margin-right: ${({ theme }) => theme.remCalc('20')};
    }
  }
  > div:nth-child(odd) {
    justify-content: flex-start;
  }
  > div:nth-child(even) {
    justify-content: flex-end;
  }
`

export const HeaderFilterActionsStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.remCalc('20 0')};
  > div {
    margin-right: ${({ theme }) => theme.remCalc('20')};
  }
  > div:last-child {
    margin: 0;
  }
`

export const TableGridContainerStyled = styled.div`
  width: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Lol = props => <Cross {...props} />

export const CrossStyled = styled(Lol)`
  ${({ theme }) => theme.box(20, 20)}
  cursor: pointer;
  fill: ${({ theme }) => theme.palette.base.white};
`
export const SearchStyled = styled(Search)`
  ${({ theme }) => theme.box(20, 20)}
  margin-left: ${({ theme }) => theme.remCalc('15')};
  fill: ${({ theme }) => theme.palette.gray.dark};
`

export const TriangleUpStyled = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid ${({ theme }) => theme.palette.base.white};
  opacity: 0.3;
  margin-bottom: ${({ theme }) => theme.remCalc('10')};
  cursor: pointer;
  ${({ selected }) => selected && `opacity: 1;`}
  :hover {
    opacity: 1;
  }
`

export const TriangleDownStyled = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid ${({ theme }) => theme.palette.base.white};
  opacity: 0.3;
  cursor: pointer;
  ${({ selected }) => selected && `opacity: 1;`}
  :hover {
    opacity: 1;
  }
`

export const TableHeaderStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.light};
  height: ${({ theme }) => theme.remCalc('48')};
  border-radius: ${({ theme }) => theme.remCalc('10')};
  padding: ${({ theme }) => theme.remCalc('0 5')};
  color: ${({ theme }) => theme.palette.base.white};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
`

export const HeaderColumnsStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => theme.remCalc('10')};
  margin: ${({ theme }) => theme.remCalc('0 5')};
  color: ${({ theme }) => theme.palette.base.white};
  display: grid;
  grid-template-columns: ${({ columnWidths, theme }) =>
    columnWidths.map(item =>
      item !== '1fr' && item !== 'auto' ? `${theme.remCalc(item)} ` : `${item} `
    )};
  grid-template-rows: ${({ theme }) => theme.remCalc('48')};
`

export const HeaderCellStyled = styled(Typography).attrs(props => ({
  ...props,
  component: 'div',
  variant: 'subtitle3',
  color: 'white'
}))`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.remCalc('0 5')};
`
export const HeaderOrderContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${({ theme }) => theme.remCalc('5')};
`
export const CheckboxStyled = styled(Checkbox)`
  ${({ theme }) => theme.bordered('1', theme.palette.base.white)}
  background-color: ${({ theme, disabled, color, checked }) =>
    disabled
      ? theme.palette.base.disabled
      : color && checked
      ? ''
      : 'transparent !important'};
`

export const BodyColumnsStyled = styled(ScrollBottomHandler)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: ${({ theme }) => theme.remCalc('0 5')};
`

export const TableRowStyled = styled.div`
  display: grid;
  grid-template-columns: ${({ columnWidths, theme }) =>
    columnWidths.map(item =>
      item !== '1fr' && item !== 'auto' ? `${theme.remCalc(item)} ` : `${item} `
    )};
  grid-template-rows: ${({ footerRowElement }) =>
    footerRowElement ? '70px auto' : '70px'};
  width: 100%;
  background: #ffffff;
  box-shadow: ${({ theme }) => theme.remCalc('0 2 10')}
    rgba(125, 133, 154, 0.25);
  border: ${({ selected, theme }) =>
    selected ? `  1px solid ${theme.palette.primary.main}` : ''};
  border-radius: ${({ theme }) => theme.remCalc('10')};
  margin: ${({ theme }) => theme.remCalc('20 0')};
  font-size: ${({ theme }) => theme.remCalc('14')};
  cursor: pointer;
  :hover {
    box-shadow: ${({ theme }) => theme.remCalc('0 2 15')}
      rgba(0, 118, 204, 0.25);
  }
`

export const TableLoadingRowContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.remCalc('90')};
`
export const TableCellStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  grid-column: ${({ index }) => `${index + 1}/${index + 2}`};
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};
  overflow: ${({ showOverflow }) => (!showOverflow ? 'hidden' : '')};
  flex: ${({ width }) => (!width ? '1' : '')};
  text-align: ${({ textAlign }) => textAlign || ''};
  padding: ${({ theme, columnType }) =>
    columnType !== 'html' && columnType !== 'image' ? theme.remCalc('0 5') : 0};
`
export const ChevronUpStyled = styled(ChevronUp)`
  ${({ theme }) => theme.box(10, 10)}
  margin-bottom: ${({ theme }) => theme.remCalc('10')};
  fill: ${({ theme }) => theme.palette.base.white};
  opacity: 0.3;
  cursor: pointer;
  ${({ selected }) => selected && `opacity: 1;`}
  :hover {
    opacity: 1;
  }
`
export const ChevronDownStyled = styled(ChevronDown)`
  ${({ theme }) => theme.box(10, 10)}
  fill: ${({ theme }) => theme.palette.base.white};
  opacity: 0.3;
  cursor: pointer;
  ${({ selected }) => selected && `opacity: 1;`}
  :hover {
    opacity: 1;
  }
`
export const Dots3Styled = styled(Dots3)`
  ${({ theme }) => theme.box(30, 30, true)}
`

export const BulkButtonStyled = styled.button`
  font-weight: 600;
  min-height: 2.5rem;
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  padding: 0 0.625rem;
  text-decoration: none;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.base.white};
  border-width: 0.125rem;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.base.white};
  justify-content: center;
  font-family: 'Muli', 'Helvetica Neue', 'Arial', sans-serif;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  align-items: center;
  display: inline-flex;
  > svg {
    ${({ theme }) => theme.box(20, 20)}
    fill: ${({ theme }) => theme.palette.base.white};
    margin-right: ${({ theme }) => theme.remCalc('10')};
  }
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.base.white};
    ${({ theme }) => theme.bordered(2, theme.palette.base.white)};
    > svg {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }
`
export const InputContainerStyled = styled.div`
  width: 250px !important;
  margin-right: ${({ theme }) => theme.remCalc('10')};
`

export const EmptyListContainerStyled = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  padding-top: ${({ theme }) => theme.remCalc('100')};
`

const Text = props => <p {...props} />

export const TextStyled = styled(Text)`
  margin: 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const EmptyStateTitleStyled = styled(Typography).attrs({
  variant: 'h4'
})`
  padding-top: ${({ theme }) => theme.remCalc('20')};
`

export const EmptyStateSubtitleStyled = styled(Typography).attrs({
  variant: 'subtitle4'
})``

export const EmptyStateSubtitleButton = styled(Button).attrs({
  variant: 'standard'
})`
  display: inline-block;
  margin-top: 20px;
`
