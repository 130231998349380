import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from 'rc'
import { Button } from 'components'

const DeleteModelMC = props => {
  return (
    <Grid
      container
      direction="column"
      css={`
        width: 100%;
        max-width: ${({ theme }) => theme.remCalc('520')};
        padding: ${({ theme }) => theme.remCalc('45 30 30')};
      `}
    >
      <Typography
        variant="h2"
        align="center"
        textTransform="initial"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('20')};
        `}
      >
        ¿Quieres eliminar este modelo?
      </Typography>
      <Typography
        variant="body1"
        align="center"
        color="grayMedium"
        lineHeight="20px"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('25')};
        `}
      >
        La información de este modelo se borrará para tí y todos los usuarios de
        Wiggot.
      </Typography>
      <Grid
        container
        justify="space-between"
        css={`
          padding: ${({ theme }) => theme.remCalc('0 10')};
        `}
      >
        <Grid
          item
          flat
          md={10}
          css={`
            ${({ theme }) => theme.media.desktopDown`
            order: 1;
            margin-top: 10px;
            `}
          `}
        >
          <Button full type="tertiary" onClick={props.HideModal}>
            Cancelar
          </Button>
        </Grid>
        <Grid item flat md={11}>
          <Button
            full
            type="alert"
            onClick={() => {
              props.deleteModel()
              props.HideModal()
            }}
          >
            Eliminar modelo
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

DeleteModelMC.propTypes = {
  deleteModel: PropTypes.func,
  HideModal: PropTypes.func
}

export default DeleteModelMC
