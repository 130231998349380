import React, { useState } from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import { ButtonOptions, Typography } from 'rc'

const Order = ({ onChange }) => {
  const [orderLabel, setOrderLabel] = useState(null)

  const orders = [
    {
      label: 'Mayor a menor precio',
      value: 'HIGHER_PRICE'
    },
    {
      label: 'Menor a mayor precio',
      value: 'LOWER_PRICE'
    },
    {
      label: 'Reciente a antigüo',
      value: 'RECENTS'
    },
    {
      label: 'Antigüo a reciente',
      value: 'OLDS'
    },
    {
      label: 'Orden alfabético',
      value: 'ALPHABETICAL'
    },
  ]

  const handleClickOption = (e, item) => {
    e.preventDefault()

    setOrderLabel(item.label)

    if (onChange)
      onChange(item.value)
  }

  return (
    <div
      css={`
        display: flex;
        align-items: center;
      `}
    >
      <TotalTitle>Ordenar por:</TotalTitle>
      <ButtonOptionsStyled
        onlyOneAction
        id="searchDetail-order"
        type="button"
        variant="outlined"
        size="small"
        options={
          orders.map((item, index) => (
            <ContainerOption
              key={`searchDetail-order-${index}`}
              onClick={(e) => handleClickOption(e, item)}
            >
              <TypographyStyled>
                {item.label}
              </TypographyStyled>
            </ContainerOption>
          ))
        }
      >
        {orderLabel || 'selecionar orden'}
      </ButtonOptionsStyled>
    </div>
  )
}

const TotalTitle = styled(Typography)`
  color: #7D7F9C;
  font-weight: 600;
`

const ButtonOptionsStyled = styled(ButtonOptions)`
  border-radius: unset;
  background-color: unset;
  border: unset;
  padding-left: ${({ theme }) => theme.remCalc('10')} !important;
  > div {
    border-radius: unset;
    background-color: unset;
    border: unset;
  }
  &:hover, &:hover > div {
    color: ${({ theme }) => theme.palette.primary.main};
    border-radius: unset;
    background-color: unset;
    border: unset;
  }
`
const ContainerOption = styled.div`
  cursor: pointer;
  &:hover p {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
const TypographyStyled = styled(Typography)`
  white-space: nowrap;
`

Order.propTypes = {
  onChange: func,
}

export default Order