import React, { useState } from 'react'


const Checkbox = ({ className, label, checked, ...props }) => {
  const defaultChecked = checked ? checked : false;
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const css = `
    .checkbox-wrapper input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        width: 1.2em;
        height: 1.2em;
        border-radius: 0.15em;
        margin-right: 0.5em;
        border: 0.15em solid;
        outline: none;
        cursor: pointer;
      }
    input.checked {
        background-color: #007a7e;
        position: relative;
      }
    input.checked::before {
        content: "\\2713";
        font-size: 1em;
        color: #fff;
        position: absolute;
        right: 1px;
        top: -2px;
      }
      .checbox-header{
        border-color:#fff !important
      }
    `

  return (
    <div className="checkbox-wrapper">
      <style>{css}</style>
      <label>
        <input css={`
                      border-color:${({ theme }) => theme.palette.primary.main} !important;
                    `}
          className={isChecked ? className + ' checked' : className}
          {...props}
          type="checkbox"
          onChange={() => setIsChecked((value) => !value)}
          checked={isChecked} />
        <span>{label}</span>
      </label>
    </div>
  );
};
export default Checkbox;