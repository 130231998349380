var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _jsxFileName = 'src/lib/elements/FormInputs/Select.js';

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { Select as SelectView } from '../Selects';

import InputLabelContainer from './InputLabelContainer';
import { inputLabelContainerProp } from './_types';

var Select = (_temp = _class = function (_PureComponent) {
  _inherits(Select, _PureComponent);

  function Select(props) {
    _classCallCheck(this, Select);

    var _this = _possibleConstructorReturn(this, (Select.__proto__ || Object.getPrototypeOf(Select)).call(this, props));

    _this.componentDidMount = function () {
      var onSelectMounted = _this.props.onSelectMounted;

      if (onSelectMounted) {
        onSelectMounted(_this);
      }
    };

    _this.onOptionSelectedCT = function (option) {
      var retrieveOnlyId = _this.props.retrieveOnlyId;

      _this.setState({ value: option }, function () {
        if (option) {
          if (retrieveOnlyId) {
            var optionId = option;
            if ((typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object') {
              var _option$id = option.id,
                  id = _option$id === undefined ? '' : _option$id;

              optionId = id;
            }
            _this.props.onOptionSelected(optionId);
          } else {
            _this.props.onOptionSelected(option);
          }
        }
      });
    };

    _this.onOptionSelectedRF = function (option) {
      var retrieveOnlyId = _this.props.retrieveOnlyId;


      if (option) {
        if (retrieveOnlyId) {
          var optionId = option;
          if ((typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object') {
            var _option$id2 = option.id,
                id = _option$id2 === undefined ? '' : _option$id2;

            optionId = id;
          }
          _this.props.input.onChange(optionId);
          _this.props.onOptionSelected(optionId);
        } else {
          _this.props.input.onChange(option);
          _this.props.onOptionSelected(option);
        }
      }
    };

    _this.inputProp = function () {
      var _this$props = _this.props,
          input = _this$props.input,
          componentType = _this$props.componentType;

      switch (componentType) {
        case 'CT':
          return {
            onOptionSelected: _this.onOptionSelectedCT,
            value: _this.state.value
          };
        case 'extCT':
          return {
            onOptionSelected: _this.props.onOptionSelected,
            value: _this.props.value
          };
        case 'RF':
          return {
            onOptionSelected: _this.onOptionSelectedRF,
            value: input.value
          };
      }
    };

    var value = props.value;

    var valueId = value;
    if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
      var id = value.id;

      valueId = id;
    }
    _this.state = {
      options: Select.optionsConverter(_this.props.options),
      value: _this.props.componentType !== 'RF' ? _this.props.value : _this.props.value ? _this.onOptionSelectedRF(_.find(Select.optionsConverter(_this.props.options), {
        id: valueId
      })) : undefined
    };
    return _this;
  }

  _createClass(Select, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          id = _props.id,
          disabled = _props.disabled,
          placeholder = _props.placeholder,
          selectBoxMenuClassName = _props.selectBoxMenuClassName,
          wrapperClassName = _props.wrapperClassName,
          secondary = _props.secondary,
          insideLabel = _props.insideLabel,
          primaryLight = _props.primaryLight,
          className = _props.className,
          retrieveOnlyId = _props.retrieveOnlyId;
      var options = this.state.options;
      var _props2 = this.props,
          labelProps = _props2.labelProps,
          label = _props2.label;


      return React.createElement(
        InputLabelContainer,
        Object.assign({ label: label }, labelProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 185
          },
          __self: this
        }),
        React.createElement(SelectView, Object.assign({
          id: id,
          wrapperClassName: wrapperClassName,
          className: className,
          insideLabel: insideLabel,
          selectBoxMenuClassName: selectBoxMenuClassName,
          placeholder: placeholder,
          disabled: disabled,
          options: options,
          secondary: secondary,
          primaryLight: primaryLight,
          retrieveOnlyId: retrieveOnlyId
        }, this.inputProp(), {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 186
          },
          __self: this
        }))
      );
    }
  }], [{
    key: 'getDerivedStateFromProps',
    value: function getDerivedStateFromProps(_ref2, _ref) {
      var propOptions = _ref2.options;

      var stateOptions = _ref.options,
          state = _objectWithoutProperties(_ref, ['options']);

      var shouldUpdate = !_.isEqual(propOptions, stateOptions);
      return shouldUpdate ? Object.assign({}, state, { options: Select.optionsConverter(propOptions) }) : null;
    }
  }]);

  return Select;
}(PureComponent), _class.propTypes = {
  /** CallBack to get the input value */
  onOptionSelected: PropTypes.func.isRequired,
  /** Select options */
  options: PropTypes.array.isRequired,
  /** ClassName to set if necessary */
  className: PropTypes.string,
  /** Component Type (helper for redux form) */
  componentType: PropTypes.string,
  /** Input disabled state */
  disabled: PropTypes.bool,
  id: PropTypes.string,
  /** Validation when component is RF*/
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func
  }),
  /** */
  insideLabel: PropTypes.string,
  /** */
  label: PropTypes.string,
  /** */
  labelProps: inputLabelContainerProp,
  /** Input name */
  name: PropTypes.string,
  /** */
  onSelectMounted: PropTypes.func,
  /** Input placeholder */
  placeholder: PropTypes.string,
  /** */
  primaryLight: PropTypes.bool,
  /** */
  retrieveOnlyId: PropTypes.bool,
  /** */
  secondary: PropTypes.bool,
  /** */
  selectBoxMenuClassName: PropTypes.string,
  /** Initial value for input (only valid when is CT)*/
  value: PropTypes.any,
  /** */
  wrapperClassName: PropTypes.string
}, _class.defaultProps = {
  onOptionSelected: function onOptionSelected() {
    return null;
  },
  placeholder: 'Seleccionar',
  disabled: false,
  label: ''
}, _class.optionsConverter = function (options) {
  return options.map(function (option) {
    return (typeof option === 'undefined' ? 'undefined' : _typeof(option)) !== 'object' ? { id: option, name: option } : option;
  });
}, _temp);


export default Select;