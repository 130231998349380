var _jsxFileName = 'src/lib/generics/Buttons/CounterButton.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from './IconButton';

/**
 * A button that displays a counter in the up-right part.
 * You can pass the icon to be rendered.
 */
var BASE_CLASSNAME = 'wig-button-counter';

var CounterButton = function CounterButton(_ref) {
  var number = _ref.number,
      className = _ref.className,
      dripiconIcon = _ref.dripiconIcon,
      fontAwesomeIcon = _ref.fontAwesomeIcon,
      square = _ref.square,
      semiRounded = _ref.semiRounded,
      circle = _ref.circle,
      gray = _ref.gray,
      stillGray = _ref.stillGray,
      primary = _ref.primary,
      secondary = _ref.secondary,
      transparent = _ref.transparent,
      success = _ref.success,
      danger = _ref.danger,
      warning = _ref.warning,
      smallSize = _ref.smallSize,
      mediumSize = _ref.mediumSize,
      largeSize = _ref.largeSize,
      disabled = _ref.disabled,
      onClick = _ref.onClick;

  var counterButtonClasses = classNames(className, BASE_CLASSNAME);
  return React.createElement(
    IconButton,
    {
      onClick: onClick,
      dripiconIcon: dripiconIcon,
      fontAwesomeIcon: fontAwesomeIcon,
      square: square,
      semiRounded: semiRounded,
      circle: circle,
      gray: gray,
      stillGray: !success && !danger && !warning && !gray && !secondary && !primary && !transparent || stillGray,
      primary: primary,
      secondary: secondary,
      transparent: transparent,
      success: success,
      danger: danger,
      warning: warning,
      smallSize: smallSize,
      mediumSize: mediumSize,
      largeSize: !smallSize && !mediumSize || largeSize,
      disabled: disabled,
      className: counterButtonClasses,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 36
      },
      __self: _this
    },
    number > 0 ? React.createElement(
      'span',
      { className: BASE_CLASSNAME + '__number', __source: {
          fileName: _jsxFileName,
          lineNumber: 67
        },
        __self: _this
      },
      number
    ) : ''
  );
};

CounterButton.defaultProps = {
  number: 0,
  className: '',
  dripiconIcon: '',
  fontAwesomeIcon: '',
  square: false,
  semiRounded: false,
  circle: false,
  gray: false,
  stillGray: false,
  primary: false,
  secondary: false,
  transparent: false,
  success: false,
  danger: false,
  warning: false,
  smallSize: false,
  mediumSize: false,
  largeSize: false,
  disabled: false,
  onClick: function onClick() {}
};

CounterButton.propTypes = {
  /** Sets a button which has a border-radius of 100%. */
  circle: PropTypes.bool,
  /** Prefixes the button which custom classes you passed. */
  className: PropTypes.string,
  /** Sets a button which bg-color is danger. */
  danger: PropTypes.bool,
  /** Sets a button which has been disallowed. */
  disabled: PropTypes.bool,
  /** DripiconIcon string shown. */
  dripiconIcon: PropTypes.string,
  /** FontAwesomeIcon string shown. */
  fontAwesomeIcon: PropTypes.string,
  /** Sets a button which bg-color is gray. */
  gray: PropTypes.bool,
  /** Large size of the icon. */
  largeSize: PropTypes.bool,
  /** Medium size of the icon. */
  mediumSize: PropTypes.bool,
  /** Number displayed in red box/ if 0 it won't display anything */
  number: PropTypes.number,
  /** Callback to call when button is pressed. */
  onClick: PropTypes.func,
  /** Sets a button which bg-color is primary. */
  primary: PropTypes.bool,
  /** Sets a button which bg-color is secondary. */
  secondary: PropTypes.bool,
  /** Sets a button which has a little border-radius. */
  semiRounded: PropTypes.bool,
  /** Small size of the icon. */
  smallSize: PropTypes.bool,
  /** Sets a button which border-radius is none. */
  square: PropTypes.bool,
  /** Sets a button which bg-color is transparent but color gray and hovered stays transparent bg-colro. */
  stillGray: PropTypes.bool,
  /** Sets a button which bg-color is success. */
  success: PropTypes.bool,
  /** Sets a button which bg-color is transparent but hovered bg-color gray. */
  transparent: PropTypes.bool,
  /** Sets a button which bg-color is warning. */
  warning: PropTypes.bool
};

export default CounterButton;