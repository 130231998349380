var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/generics/Previewers/VirtualTour.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
/**
 * Used to display the site menu
 */

var VirtualTour = (_temp2 = _class = function (_PureComponent) {
  _inherits(VirtualTour, _PureComponent);

  function VirtualTour() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, VirtualTour);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = VirtualTour.__proto__ || Object.getPrototypeOf(VirtualTour)).call.apply(_ref, [this].concat(args))), _this), _this.getIframe = function () {
      return {
        __html: '<iframe allow="fullscreen" src="' + _this.props.url + '" style="border:none" width="100%" height="100%"></iframe>'
      };
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(VirtualTour, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          height = _props.height,
          width = _props.width;

      return React.createElement('div', {
        style: { height: height, width: width },
        className: 'previewers__container',
        dangerouslySetInnerHTML: this.props.url ? this.getIframe() : '',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 31
        },
        __self: this
      });
    }
  }]);

  return VirtualTour;
}(PureComponent), _class.propTypes = {
  /** */
  height: PropTypes.string,
  /** */
  url: PropTypes.string,
  /** */
  width: PropTypes.string
}, _class.defaultProps = {
  width: 350,
  height: 250
}, _temp2);


export default VirtualTour;