import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Grid,
  FormContainer,
  TextField,
  Typography,
  Select,
  SubmitButton,
  FormControlLabel,
  Checkbox
} from 'rc'

import { Diploma } from 'rc/Icons'
import { Button } from 'components'

import { MONTHS } from 'utils/constants'
import {
  AddCertification,
  DeleteCertification,
  EditCertification
} from 'reduxActions/profile'
import { HideModal } from 'reduxActions/services'

const ProfileCertificationMC = ({
  certification,
  AddCertification,
  DeleteCertification,
  EditCertification,
  HideModal,
  token,
  profileContext,
  ...props
}) => {
  const isNewCertification = !certification.user_certification_id
  const { user_certification_id: certificationId } = certification
  const doesNotExpire =
    !isNewCertification &&
    !certification.expiration_year &&
    !certification.expiration_month
  const [noExpirationDate, setNoExpirationDate] = useState(doesNotExpire)

  const currentYear = new Date().getFullYear()
  const firstYear = 2050
  const years = [...Array(firstYear - currentYear + 1).keys()].map(index => ({
    label: currentYear + index,
    value: currentYear + index
  }))

  const handleSubmit = async values => {
    const { no_expiration_date, ...otherValues } = values
    values = otherValues
    let fields_with_errors = []

    Object.keys(values).forEach(key => {
      if (key === 'certification_code') return
      if (
        noExpirationDate &&
        (key === 'expiration_month' || key === 'expiration_year')
      ) {
        values[key] = ''
        return
      }
      if (!values[key]) fields_with_errors.push(key)
    })

    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }
    if (isNewCertification) {
      return AddCertification(token, values)
    } else {
      return EditCertification(token, certificationId, values)
    }
  }
  const handleSubmitSuccess = (response, formValues) => {
    const {
      payload: { data: { user_certification_id } = {} }
    } = response

    const {
      customChange,
      profile: { certifications = [] }
    } = profileContext
    HideModal()
    if (isNewCertification)
      customChange({
        certifications: [
          ...certifications,
          { user_certification_id, ...formValues }
        ]
      })
    else
      customChange({
        certifications: certifications.map(savedCertification =>
          certificationId === savedCertification.user_certification_id
            ? { ...formValues }
            : savedCertification
        )
      })
  }

  const handleSubmitFail = (values, fc, submitErrors) => {
    if (submitErrors) {
      const { code, metadata } = submitErrors
      switch (code) {
        case 'REQUIRED':
          {
            const fieldErrors = metadata.reduce((previous, current) => {
              return {
                ...previous,
                [current]: 'Campo requerido'
              }
            }, {})

            fc.setCustomErrors({
              ...fieldErrors
            })
          }
          break

        default:
          break
      }
    }
  }

  const onDeleteCertification = async () => {
    try {
      const response = await DeleteCertification(token, certificationId)
      const { payload: { status } = {} } = response
      if (status === 200) {
        const {
          customChange,
          profile: { certifications = [] }
        } = profileContext
        customChange({
          certifications: certifications.filter(
            ({ user_certification_id }) =>
              certificationId !== user_certification_id
          )
        })
        HideModal()
      }
    } catch (error) {
      /**
       *
       */
    }
  }

  const isRequired = newValue => {
    return !newValue && 'Campo requerido.'
  }

  const handleFormChange = (
    newValues,
    fieldChanged,
    newValue,
    previousValues,
    childContext
  ) => {
    if (
      fieldChanged === 'no_expiration_date' &&
      previousValues.no_expiration_date === false &&
      newValue === true
    ) {
      childContext.setCustomErrors({
        ...childContext.errors,
        expiration_year: '',
        expiration_month: ''
      })
      childContext.customChange({
        no_expiration_date: true,
        expiration_year: '',
        expiration_month: ''
      })
    }
  }
  return (
    <div
      css={`
        padding: ${({ theme }) => theme.remCalc('30 20')};
        width: ${({ theme }) => theme.remCalc('1080')};
        max-width: 100%;
        ${({ theme }) => theme.media.desktop`
          padding: ${({ theme }) => theme.remCalc('30 60')};
        `}
      `}
    >
      <Typography variant="h6" color="palette.primary.main" textTransform="initial">
        {isNewCertification ? 'Añadir nueva' : 'Editar'} certificación
      </Typography>
      <Grid container>
        <Grid
          container
          center
          item
          xsShrink
          css={` 
            ${({ theme }) => theme.box(60, 60, true)}
            background: ${({ theme }) => theme.palette.warning.main};
            color: ${({ theme }) => theme.palette.base.white};
            margin: ${({ theme }) => theme.remCalc('28 30')};
            display: none;
            ${({ theme }) => theme.media.desktop`
              display:flex;
            `}
          `}
        >
          <Diploma
            css={`
              ${({ theme }) => theme.box(30, 30)}
            `}
          />
        </Grid>
        <Grid item xsAuto flat>
          <FormContainer
            initialValues={{
              title: '',
              institution: '',
              expiration_month: '',
              expiration_year: '',
              no_expiration_date: doesNotExpire,
              ...certification
            }}
            onSubmit={handleSubmit}
            onSubmitSuccess={handleSubmitSuccess}
            onSubmitFail={handleSubmitFail}
            onFormChange={handleFormChange}
          >
            <Grid container>
              <Grid item md={12}>
                <TextField
                  id="profile-certification-mc-input-title"
                  fullWidth
                  name="title"
                  label="Título de certificación"
                  required
                  validate={isRequired}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  id="profile-certification-mc-input-institution"
                  fullWidth
                  name="institution"
                  label="Institución / Empresa"
                  required
                  validate={isRequired}
                />
              </Grid>
              <Grid item flat>
                <FormControlLabel
                  css={`
                    margin: 12px 12px 24px;
                  `}
                  control={
                    <Checkbox
                      onChange={(e, checked) => {
                        setNoExpirationDate(checked)
                      }}
                      name="no_expiration_date"
                      id="profile-certification-mc-checkbox-noExpirationDate"
                    />
                  }
                  label="No tiene vigencia"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item flat md={12} container>
                <Typography
                  variant="subtitle2"
                  display="block"
                  css={`
                    width: 100%;
                    padding: ${({ theme }) => theme.remCalc('0 12')};
                  `}
                >
                  Vigencia
                </Typography>
                <Grid item md={12}>
                  <Select
                    id="profile-certification-mc-select-expirationMonth"
                    selectId="profile-certification-mc-select-expirationMonth"
                    iconId="profile-certification-mc-select-expirationMonth"
                    name="expiration_month"
                    css={`
                      width: 100%;
                      min-width: min-content;
                    `}
                    placeholder="Mes"
                    optionLabelKey="label"
                    optionValueKey="value"
                    options={MONTHS}
                    customHeight="200"
                    required
                    validate={isRequired}
                    disabled={noExpirationDate}
                  />
                </Grid>
                <Grid item md={12}>
                  <Select
                    id="profile-certification-mc-select-expirationYear"
                    selectId="profile-certification-mc-select-expirationYear"
                    iconId="profile-certification-mc-select-expirationYear"
                    name="expiration_year"
                    css={`
                      width: 100%;
                      min-width: min-content;
                    `}
                    placeholder="Año"
                    optionLabelKey="label"
                    optionValueKey="value"
                    options={years}
                    customHeight="200"
                    required
                    validate={isRequired}
                    disabled={noExpirationDate}
                  />
                </Grid>
              </Grid>
              <Grid item flat md={12}>
                <div
                  css={`
                    height: 6px;
                  `}
                />
                <Grid item>
                  <TextField
                    id="profile-certification-mc-input-certificationCode"
                    fullWidth
                    name="certification_code"
                    label="Matrícula / Certificado"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify="space-between" item fullWidth>
              {!isNewCertification ? (
                <Button
                  id="profile-certification-mc-button-deleteCertification"
                  css={`
                    width: ${({ theme }) => theme.remCalc('110')};
                    ${({ theme }) => theme.media.desktop`
                      width: ${({ theme }) => theme.remCalc('150')};
                    `}
                  `}
                  size="medium"
                  type="alert"
                  onClick={onDeleteCertification}
                >
                  Borrar
                </Button>
              ) : (
                <div />
              )}

              <SubmitButton
                css={`
                  width: ${({ theme }) => theme.remCalc('110')};
                  ${({ theme }) => theme.media.desktop`
                      width: ${({ theme }) => theme.remCalc('150')};
                    `}
                `}
                size="medium"
                id="profile-certification-mc-button-submit"
              >
                {isNewCertification ? 'Añadir' : 'Guardar cambios'}
              </SubmitButton>
            </Grid>
          </FormContainer>
        </Grid>
      </Grid>
    </div>
  )
}
ProfileCertificationMC.defaultProps = {
  certification: {}
}
ProfileCertificationMC.propTypes = {
  profileContext: PropTypes.object.isRequired,
  certification: PropTypes.object,
  token: PropTypes.string.isRequired,
  AddCertification: PropTypes.func.isRequired,
  DeleteCertification: PropTypes.func.isRequired,
  EditCertification: PropTypes.func.isRequired,
  HideModal: PropTypes.func.isRequired
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  DeleteCertification,
  EditCertification,
  AddCertification,
  HideModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileCertificationMC)
