/**
 * Creates a new contact
 * @param {object} data
 * @param {string} data.contact_name
 * @param {string} data.contact_email
 * @param {string} data.contact_phone
 * @param {string} data.contact_phone_2
 * @param {string} data.contact_source
 */
export const CreateContact = (Authorization, data) => ({
  type: 'CREATE_CONTACT',
  payload: {
    request: {
      method: 'post',
      url: `/contact`,
      data,
      headers: { Authorization }
    }
  }
})

/**
 * Gets all contacts belonging to user
 * @param {string} Authorization - current user token
 * @param {number} [page=0] - page to download users
 * @param {number} [limit=10] - items to download per page
 */
export const GetMyContacts = (
  Authorization,
  page = 0,
  limit = 20,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'GET_MY_CONTACTS',
  payload: {
    request: {
      method: 'get',
      url: `/contacts`,
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

export const GetContactList = (
  Authorization,
  page = 0,
  limit = 7,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'GET_CONTACT_LIST',
  payload: {
    request: {
      method: 'get',
      url: `/contacts/list`,
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 * Gets all contacts belonging to agency
 * @param {string} Authorization - current user token
 * @param {number} [page=0] - page to download users
 * @param {number} [limit=10] - items to download per page
 */
export const GetAgencyContacts = (
  Authorization,
  page = 0,
  limit = 10,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'GET_AGENCY_CONTACTS',
  payload: {
    request: {
      method: 'get',
      url: `/agency/contacts`,
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 * Delete by passing multiple contacts ids
 * @param {string} Authorization - current user token
 * @param {string[]} contactsIds - contact identifier
 */
export const DeleteContacts = (Authorization, contactsIds) => ({
  type: 'DELETE_CONTACTS',
  payload: {
    request: {
      method: 'delete',
      url: '/contacts',
      data: {
        contact_id: contactsIds
      },
      headers: { Authorization }
    }
  }
})

/**
 * Delete a single contact
 * @param {string} Authorization - current user token
 * @param {string} contactId
 */
export const DeleteContact = (Authorization, contactId) => ({
  type: 'DELETE_CONTACT',
  payload: {
    request: {
      method: 'delete',
      url: `/contact/${contactId}`,
      headers: { Authorization }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} contactId
 */
export const GetContactInfo = (Authorization, contactId) => ({
  type: 'GET_CONTACT_INFO',
  payload: {
    request: {
      method: 'get',
      url: `/contact/${contactId}`,
      headers: { Authorization }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} contactId
 * @param {*} newContactInfo
 */
export const EditContact = (Authorization, contactId, newContactInfo) => ({
  type: 'EDIT_CONTACT',
  payload: {
    request: {
      method: 'patch',
      url: `/contact/${contactId}`,
      data: {
        ...newContactInfo
      },
      headers: { Authorization }
    }
  }
})

/**
 * Delete by passing multiple agency contacts ids
 * @param {string} Authorization - current user token
 * @param {string[]} contactsIds - contact identifier
 */
export const DeleteAgencyContacts = (Authorization, contactsIds) => ({
  type: 'DELETE_AGENCY_CONTACTS',
  payload: {
    request: {
      method: 'delete',
      url: '/agency/contacts',
      data: {
        contact_id: contactsIds
      },
      headers: { Authorization }
    }
  }
})

/**
 * Delete a single agency contact
 * @param {string} Authorization - current user token
 * @param {string} contactId
 */
export const DeleteAgencyContact = (Authorization, contactId) => ({
  type: 'DELETE_AGENCY_CONTACT',
  payload: {
    request: {
      method: 'delete',
      url: `/agency/contact/${contactId}`,
      headers: { Authorization }
    }
  }
})

/**
 *
 * @param {*} token
 * @param {*} brokerId
 * @param {*} contactsIds
 */
export const ReasignContacts = (Authorization, brokerId, contactIds) => ({
  type: 'REASSIGN_CONTACTS',
  payload: {
    request: {
      url: `/agency/broker/${brokerId}/assign_contact`,
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        contact_ids: contactIds
      }
    }
  }
})

/**
 * Retrieves basic info of contact using name
 * @param {string} token
 * @param {string} contactName
 */
export const SearchContact = (Authorization, contactName = '') => ({
  type: 'SEARCH_CONTACT',
  payload: {
    request: {
      url: `/contacts/search?name=${contactName}`,
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const ContactSentProperties = (Authorization, property_id) => ({
  type: 'CONTACT_SENT_PROPERTIES',
  payload: {
    request: {
      url: `/contact/${property_id}/properties_sent`,
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const ContactListSentProperties = (
  Authorization,
  contactId,
  page = 0,
  limit = 10,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'CONTACT_SENT_PROPERTIES',
  payload: {
    request: {
      url: `contact/${contactId}/list_properties_sent`,
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

export const GetContactsKpis = (Authorization, f_year = 2023) => ({
  type: 'CONTACTS_KPIS',
  payload: {
    request: {
      url: `/contacts/kpis?f_year=${f_year}`,
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const DownloadContactsList = Authorization => {
  return {
    type: 'DOWNLOAD_CONTACTS_LIST',
    payload: {
      client: 'inventoryTemplates',
      request: {
        url: `/download_contacts?token=${Authorization}&f_table=MY_CONTACTS`,
        method: 'get',
        headers: { Authorization }
      }
    }
  }
}

export const GetContactsOrigins = Authorization => ({
  type: 'GET_CONTACTS_ORIGINS',
  payload: {
    request: {
      url: `/contacts/list/origins`,
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})
