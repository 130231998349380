import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <rect x="0.5" width="60" height="60" rx="10" fill="#F5F7FD" />
    <path
      d="M44.0974 20.7C44.0974 21.69 43.2874 22.5 42.2974 22.5H25.7674C24.7774 22.5 23.9674 21.69 23.9674 20.7C23.9674 19.71 24.7774 18.9 25.7674 18.9H42.2974C43.2874 18.9 44.0974 19.71 44.0974 20.7ZM25.7374 31.8H35.1274C36.1174 31.8 36.9274 30.99 36.9274 30C36.9274 29.01 36.1174 28.2 35.1274 28.2H25.7374C24.7474 28.2 23.9374 29.01 23.9374 30C23.9374 30.99 24.7474 31.8 25.7374 31.8ZM38.9974 37.5H25.7374C24.7474 37.5 23.9374 38.31 23.9374 39.3C23.9374 40.29 24.7474 41.1 25.7374 41.1H38.9974C39.9874 41.1 40.7974 40.29 40.7974 39.3C40.7974 38.31 40.0174 37.5 38.9974 37.5ZM18.7474 18.75C18.2374 18.75 17.7274 18.96 17.3674 19.32C17.0074 19.68 16.7974 20.19 16.7974 20.7C16.7974 21.21 17.0074 21.72 17.3674 22.08C17.7274 22.44 18.2374 22.65 18.7474 22.65C19.2574 22.65 19.7674 22.44 20.1274 22.08C20.4874 21.72 20.6974 21.21 20.6974 20.7C20.6974 20.19 20.4874 19.68 20.1274 19.32C19.7674 18.96 19.2574 18.75 18.7474 18.75ZM18.7474 28.05C18.2374 28.05 17.7274 28.26 17.3674 28.62C17.0074 28.98 16.7974 29.49 16.7974 30C16.7974 30.51 17.0074 31.02 17.3674 31.38C17.7274 31.74 18.2374 31.95 18.7474 31.95C19.2574 31.95 19.7674 31.74 20.1274 31.38C20.4874 31.02 20.6974 30.51 20.6974 30C20.6974 29.49 20.4874 28.98 20.1274 28.62C19.7674 28.26 19.2574 28.05 18.7474 28.05ZM18.7474 37.35C18.2374 37.35 17.7274 37.56 17.3674 37.92C17.0074 38.28 16.7974 38.79 16.7974 39.3C16.7974 39.81 17.0074 40.32 17.3674 40.68C17.7274 41.04 18.2374 41.25 18.7474 41.25C19.2574 41.25 19.7674 41.04 20.1274 40.68C20.4874 40.32 20.6974 39.81 20.6974 39.3C20.6974 38.79 20.4874 38.28 20.1274 37.92C19.7674 37.56 19.2574 37.35 18.7474 37.35Z"
      fill="#A0A3BD"
    />
  </>,
  'ListBox',
  '0 0 61 60'
)
