import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { GetUserInfo } from 'reduxActions/profile'
import { DownloadRoles, SetUserAuthInfo, SetToken } from 'reduxActions/auth'
import { GetRules } from 'reduxActions/freemium'
import { getCookie, clearCookies } from 'utils/handleCookies'

export const useCookies = () => {
  const dispatch = useDispatch()

  const { token, adminToken, roles } = useSelector(
    ({ authReducer: { token, adminToken, roles } }) => ({
      token,
      adminToken,
      roles
    })
  )

  const getUser = async (token, adminToken, roles) => {
    await dispatch(GetUserInfo(token, !!adminToken, roles))
  }
  const updateRoles = async token => {
    const res = await dispatch(DownloadRoles(token))
    dispatch(
      SetUserAuthInfo({
        token,
        roles: res?.payload?.data?.roles
      })
    )
  }

  const existCookie = (arr, name) => arr?.some(cookie => cookie?.name === name)
  const updatedCookies = getCookie('updated_cookies')
  const authUserCookie = getCookie('auth_user')

  useEffect(() => {
    const path = window.location.pathname
    if (path && !path.includes('login')) {
      const authUser = JSON.parse(authUserCookie)
      if (authUser) {
        dispatch(SetToken(authUser.token))
        dispatch(GetRules(authUser.token))
      }
    }
  }, [authUserCookie])

  useEffect(() => {
    const updatedCookiesFormatted = updatedCookies && JSON.parse(updatedCookies)
    if (updatedCookiesFormatted && token && roles) {
      existCookie(updatedCookiesFormatted, 'auth_user') && updateRoles(token)
      existCookie(updatedCookiesFormatted, 'profile_user') &&
        getUser(token, !!adminToken, roles)
      dispatch(GetRules(token))
      clearCookies('updated_cookies')
    }
  }, [updatedCookies])
  return {}
}
