export const MEXICAN_STATES = [
  {
    location_id: 'WIGS-2',
    state: 'Aguascalientes',
    name: 'Aguascalientes',
    latitude: 21.885734749999997,
    longitue: -102.29129954999999
  },
  {
    location_id: 'WIGS-3',
    state: 'Baja California',
    name: 'Baja California',
    latitude: 30.359326600000003,
    longitue: -115.53094490000001
  },
  {
    location_id: 'WIGS-4',
    state: 'Baja California Sur',
    name: 'Baja California Sur',
    latitude: 25.4359778,
    longitue: -112.31846865
  },
  {
    location_id: 'WIGS-5',
    state: 'Campeche',
    name: 'Campeche',
    latitude: 19.8305716,
    longitue: -90.54481694999998
  },
  {
    location_id: 'WIGS-8',
    state: 'Chiapas',
    name: 'Chiapas',
    latitude: 16.25869305,
    longitue: -92.25468484999999
  },
  {
    location_id: 'WIGS-9',
    state: 'Chihuahua',
    name: 'Chihuahua',
    latitude: 28.670931250000002,
    longitue: -106.06461815
  },
  {
    location_id: 'WIGS-1',
    state: 'Ciudad de México',
    name: 'Ciudad de México',
    latitude: 19.3907336,
    longitue: -99.14361265
  },
  {
    location_id: 'WIGS-6',
    state: 'Coahuila de Zaragoza',
    name: 'Coahuila de Zaragoza',
    latitude: 27.211302,
    longitue: -101.90160094999999
  },
  {
    location_id: 'WIGS-7',
    state: 'Colima',
    name: 'Colima',
    latitude: 19.240047699999998,
    longitue: -103.7286077
  },
  {
    location_id: 'WIGS-10',
    state: 'Durango',
    name: 'Durango',
    latitude: 24.5949798,
    longitue: -104.8414145
  },
  {
    location_id: 'WIGS-11',
    state: 'Guanajuato',
    name: 'Guanajuato',
    latitude: 21.0251078,
    longitue: -101.25788015
  },
  {
    location_id: 'WIGS-12',
    state: 'Guerrero',
    name: 'Guerrero',
    latitude: 17.60189955,
    longitue: -100.09581370000001
  },
  {
    location_id: 'WIGS-13',
    state: 'Hidalgo',
    name: 'Hidalgo',
    latitude: 20.49813945,
    longitue: -98.9222351
  },
  {
    location_id: 'WIGS-14',
    state: 'Jalisco',
    name: 'Jalisco',
    latitude: 20.83805885,
    longitue: -103.60297249999999
  },
  {
    location_id: 'WIGS-16',
    state: 'Michoacán de Ocampo',
    name: 'Michoacán de Ocampo',
    latitude: 19.154732000000003,
    longitue: -101.90057999999999
  },
  {
    location_id: 'WIGS-17',
    state: 'Morelos',
    name: 'Morelos',
    latitude: 18.7320374,
    longitue: -99.06368035
  },
  {
    location_id: 'WIGS-15',
    state: 'México',
    name: 'México',
    latitude: 23.5540767,
    longitue: -102.62050005
  },
  {
    location_id: 'WIGS-18',
    state: 'Nayarit',
    name: 'Nayarit',
    latitude: 21.84386215,
    longitue: -105.204311
  },
  {
    location_id: 'WIGS-19',
    state: 'Nuevo León',
    name: 'Nuevo León',
    latitude: 25.480910100000003,
    longitue: -99.8141693
  },
  {
    location_id: 'WIGS-20',
    state: 'Oaxaca',
    name: 'Oaxaca',
    latitude: 17.0812981,
    longitue: -96.73573149999999
  },
  {
    location_id: 'WIGS-21',
    state: 'Puebla',
    name: 'Puebla',
    latitude: 19.0400472,
    longitue: -98.19296514999999
  },
  {
    location_id: 'WIGS-22',
    state: 'Querétaro',
    name: 'Querétaro',
    latitude: 20.8425118,
    longitue: -99.81980775
  },
  {
    location_id: 'WIGS-23',
    state: 'Quintana Roo',
    name: 'Quintana Roo',
    latitude: 19.749744800000002,
    longitue: -88.00348349999999
  },
  {
    location_id: 'WIGS-24',
    state: 'San Luis Potosí',
    name: 'San Luis Potosí',
    latitude: 22.11271955,
    longitue: -100.95606950000001
  },
  {
    location_id: 'WIGS-25',
    state: 'Sinaloa',
    name: 'Sinaloa',
    latitude: 24.7547198,
    longitue: -107.41995635
  },
  {
    location_id: 'WIGS-26',
    state: 'Sonora',
    name: 'Sonora',
    latitude: 29.395450500000003,
    longitue: -111.73864655
  },
  {
    location_id: 'WIGS-27',
    state: 'Tabasco',
    name: 'Tabasco',
    latitude: 17.950965850000003,
    longitue: -92.5588219
  },
  {
    location_id: 'WIGS-28',
    state: 'Tamaulipas',
    name: 'Tamaulipas',
    latitude: 24.94304605,
    longitue: -98.6445869
  },
  {
    location_id: 'WIGS-29',
    state: 'Tlaxcala',
    name: 'Tlaxcala',
    latitude: 19.41699465,
    longitue: -98.16691879999999
  },
  {
    location_id: 'WIGS-30',
    state: 'Veracruz de Ignacio de la Llave',
    name: 'Veracruz de Ignacio de la Llave',
    latitude: 19.8043579,
    longitue: -96.1447432
  },
  {
    location_id: 'WIGS-31',
    state: 'Yucatán',
    name: 'Yucatán',
    latitude: 21.08248695,
    longitue: -89.92972255
  },
  {
    location_id: 'WIGS-32',
    state: 'Zacatecas',
    name: 'Zacatecas',
    latitude: 23.08355245,
    longitue: -102.54792855
  }
]
