import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M2 3V1H0V3H2ZM2 39H0V41H2V39ZM38 39V41H40V39H38ZM40 22.0588V20.0588H36V22.0588H40ZM0 3V39H4V3H0ZM2 41H38V37H2V41ZM40 39V22.0588H36V39H40ZM2 5H20V1H2V5Z"
      fill="currentColor"
    />
    <path
      d="M20 26.625L18.7721 28.2037L20.3666 29.4439L21.5909 27.8371L20 26.625ZM39.5909 4.21209L40.8029 2.62122L37.6212 0.19705L36.4091 1.78791L39.5909 4.21209ZM8.64712 20.3287L18.7721 28.2037L21.2279 25.0463L11.1029 17.1713L8.64712 20.3287ZM21.5909 27.8371L39.5909 4.21209L36.4091 1.78791L18.4091 25.4129L21.5909 27.8371Z"
      fill="currentColor"
    />
  </Fragment>,
  'CheckMarkBox',
  '0 0 41 41'
)
