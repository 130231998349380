import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography } from 'rc'
import { SubmitButton } from 'c3-ui/elements/Buttons'
import {
  FormGroup,
  TextInput,
  CheckboxGroup,
  SimpleCheckboxItem
} from 'c3-ui/elements/FormInputs'
import { FormBottomActions } from 'c3-ui/elements/Forms'
import { ReduxForm as Form } from 'components'

import { Text } from 'c3-ui/elements/Texts'
import { MainLink } from 'c3-ui/elements/Links'
import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'

const RegisterForm = ({
  onSubmit,
  onSubmitSuccess,
  onSubmitFail,
  activeError,
  validation,
  isEqualValidation
}) => {
  return (
    <Form
      form="register"
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
      withFeaturedImage
      className="register-form"
      validate={validation}
      noResponsive
    >
      <Layout className="register-form__container">
        <div
          css={`
            display: flex;
            ${({ theme }) => theme.media.tabletDown`
              justify-content: center;
            `}
          `}
        >
          <Typography
            variant="h2"
            color="palette.primary.main"
            textTransform="initial"
          >
            Regístrate
          </Typography>
        </div>
        <div
          css={`
            margin: ${({ theme }) => theme.remCalc('25 0')};
          `}
        >
          <FormGroup paddingX={false}>
            <TextInput
              id="login-registerForm-input-name"
              leftFontAwesomeIcon="user"
              name="fullname"
              label="Nombre"
              placeholder=""
              validations={[{ validationName: 'emptiness' }]}
              required
            />
            <TextInput
              id="login-registerForm-input-email"
              leftFontAwesomeIcon="envelope"
              name="email"
              label="Correo electrónico"
              placeholder=""
              validations={[
                { validationName: 'emptiness' },
                {
                  validationName: 'validEmail'
                }
              ]}
              required
            />
            <TextInput
              id="login-registerForm-input-confirmEmail"
              leftFontAwesomeIcon="envelope"
              name="confirmEmail"
              label="Confirma correo electrónico"
              placeholder=""
              validations={isEqualValidation}
              required
            />
            <TextInput
              id="login-registerForm-input-phone"
              leftFontAwesomeIcon="phone"
              type="number"
              noCommas
              name="phone_number"
              label="Teléfono"
              placeholder=""
              validations={[{ validationName: 'validPhone' }]}
              required
            />
            <TextInput
              id="login-registerForm-input-password"
              leftFontAwesomeIcon="lock"
              name="password"
              type="password"
              label="Contraseña"
              placeholder="Contraseña"
              validations={[
                { validationName: 'emptiness' },
                {
                  validationName: 'checkSize',
                  params: {
                    type: 'moreThan',
                    size: '3'
                  }
                }
              ]}
              required
            />

            <Layout>
              <LayoutItem
                shrink
                className="register-form__checkbox-square-wrapper"
              >
                <CheckboxGroup name="termsAndConditions" isRequired>
                  <SimpleCheckboxItem
                    id="login-registerForm-checkbox-acceptTCPP"
                    value={true}
                    className={
                      !activeError
                        ? ''
                        : 'register-form__simple-checkbox-square'
                    }
                  />
                </CheckboxGroup>
              </LayoutItem>
              <Text auto>
                He leído y acepto los{' '}
                <TermsLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href={process.env.REACT_APP_TERMS_URL}
                  id="login-registerForm-link-termsAndConditions"
                >
                  términos y condiciones.
                </TermsLink>{' '}
                así como el{' '}
                <MainLink
                  id="login-registerForm-link-privacyPolicies"
                  noPadding
                  route="/legal/privacy"
                >
                  aviso de privacidad.
                </MainLink>
              </Text>
              {!activeError ? (
                ''
              ) : (
                <Text
                  center
                  className="register-form__danger-message"
                  extraSmallSize
                >
                  Debes aceptar los términos y condiciones.
                </Text>
              )}
            </Layout>
          </FormGroup>
        </div>
        <div
          css={`
            button[type='submit'] {
              background: ${({ theme }) => theme.palette.primary.main};
            }
          `}
        >
          <SubmitButton id="login-registerForm-button-submit">
            Registrar
          </SubmitButton>
        </div>
        <FormBottomActions center>
          <Text center>
            ¿Ya tienes una cuenta?{' '}
            <MainLink
              id="login-registerForm-link-logIn"
              noPadding
              route="/login"
            >
              Inicia sesión.
            </MainLink>
          </Text>
        </FormBottomActions>
      </Layout>
    </Form>
  )
}

RegisterForm.propTypes = {
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  activeError: PropTypes.bool,
  validation: PropTypes.func,
  isEqualValidation: PropTypes.func
}

RegisterForm.defaultProps = {
  activeError: false,
  validation: () => {}
}

const TermsLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
  }
`

export default RegisterForm
