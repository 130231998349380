import React from 'react'
import styled from 'styled-components'

export const OldWiggotUserMCText = textAction => {
  return (
    <CustomText>
      A partir del <b>31 de julio</b> los planes gratuitos ya no estarán
      disponibles en Wiggot®. Haz clic en &apos;Activar&apos; para probar gratis
      el plan Avanzado durante 30 días. Conoce aquí{' '}
      <span onClick={textAction}>los beneficios.</span>
      <br /> <br />
      Activa tu prueba, de lo contrario, sigue usando el plan gratuito hasta esa
      fecha.
    </CustomText>
  )
}

export const NewWiggotUserMCText = textAction => {
  return (
    <CustomText>
      A partir de hoy tienes 30 días para probar gratis el plan <b>Avanzado</b>.
      <br />
      Conoce aquí <span onClick={textAction}>los beneficios.</span>
    </CustomText>
  )
}

export const AdvancedTrialEnded = () => {
  return (
    <CustomText>
      Para continuar usando tu cuenta, adquiere un plan en nuestra tienda.
    </CustomText>
  )
}

export const NoActivePlan = () => {
  return (
    <CustomText>
      No cuentas con un plan activo. Para continuar, adquiere uno en nuestra
      tienda.
    </CustomText>
  )
}

export const PlanUpgrade = (availableUntil, textAction) => {
  return (
    <CustomText>
      En Wiggot® estamos renovándonos.
      <br />
      Tu plan ahora se llama: <b>Avanzado.</b>
      <br />
      <br />
      Revisa aquí tus <span onClick={textAction}>beneficios</span>
      <br />
      <br />
      Tu vigencia al plan actual es: {availableUntil}
      <br />
      <br />
      Todos tus beneficios y complementos se conservarán a un menor costo.
    </CustomText>
  )
}

export const UpgradeToAdvanced = textAction => {
  return (
    <CustomText>
      Aprovecha al máximo los beneficios de ser Avanzado como: vinculaciones
      ilimitadas, descargar PDF, compartir listas y mucho más.
      <br />
      <span onClick={textAction}>Compara los planes</span>
    </CustomText>
  )
}

export const NoReportsWarning = () => {
  return <CustomText>Has alcanzado tu límite de reportes.</CustomText>
}

export const Blocked = () => {
  return (
    <CustomText>
      Contacta con nuestro equipo de soporte para acceder a esta sección/acción.
    </CustomText>
  )
}

const CustomText = styled.span`
  color: inherit;
  text-align: center;
  font-size: ${({ theme }) => theme.remCalc('16')};
  font-weight: 400;
  line-height: ${({ theme }) => theme.remCalc('24')};
  > b {
    font-weight: 600;
  }
  > span {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
`
