import React from 'react'
import BenefitLayoutMC from 'modules/freemium/BenefitLayoutMC'
import ListImg from 'static/drawings/avaclick-description.svg'

const MonthlyListsMC = (props) => {
  return (<BenefitLayoutMC
    title='Crea listas ilimitadas'
    image={ListImg}
    description='Haz llegado a tu límite de listas mensuales. Si deseas crear más listas, contrata Prime y crea listas ilimitadas para compartir con tus clientes.'
  />)
}

export default MonthlyListsMC