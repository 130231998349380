import styled, { css } from 'styled-components'

import { Typography } from 'rc'
import { Home, Bed, Location, Bathtub, Area, Bricks, Car } from 'rc/Icons'

import { Image as ImageLib } from 'components'

export const CardContainer = styled.div`
  width: ${({ isMobile }) => isMobile ? '100%' : '290px'};
  height: 350px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(160, 163, 189, 0.3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
  cursor: pointer;
`
export const ImageContainer = styled.div`
  width: 100%;
  height: 150px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
`
export const Image = styled(ImageLib)`
  width: 100%;
  height: 100%;
`
export const Circle = styled.div`
  width: ${({ width }) => (width ? width : '40px')};
  height: ${({ height }) => (height ? height : '40px')};
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  border: 3px solid #e8ecf9;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
export const InLineRow = styled.div`
  display: flex;
  gap: 5px;
  ${({ vertical }) => !vertical && 'align-items: center;'};
  ${({ vertical }) => vertical && 'flex-direction: column;'}
`
export const ContainerAmenities = styled.div`
  display: flex;
  background-color: #f5f7fd;
  justify-content: space-around;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('3 0')};
  ${({ theme }) => theme.radius(10)}
  svg {
    width: ${({ theme }) => theme.remCalc('12')};
    color: #7d7f9c;
  }
  span {
    font-size: ${({ theme }) => theme.remCalc('12')};
    font-weight: 700;
    color: #605d75;
  }
`
export const Subtitle = styled(Typography).attrs(({ color }) => ({
  variant: 'subtitle2',
  color: color ? color : 'palette.new.text'
}))``
export const Text = styled(Typography).attrs(({ variant }) => ({
  variant: variant ? variant : 'body2',
  color: 'palette.new.text'
}))`
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
`
export const commonIconStyles = css`
  color: #7d7f9c;
  width: 11px;
`
export const LocationIcon = styled(Location)`
  ${commonIconStyles}
`
export const HomeIcon = styled(Home)`
  ${commonIconStyles}
`
export const BedIcon = styled(Bed)`
  ${commonIconStyles}
`
export const CarIcon = styled(Car)`
  ${commonIconStyles}
`
export const BathtubIcon = styled(Bathtub)`
  ${commonIconStyles}
`
export const AreaIcon = styled(Area)`
  ${commonIconStyles}
`
export const BricksIcon = styled(Bricks)`
  ${commonIconStyles}
`
