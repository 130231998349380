import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      fill="#FFC834"
      d="M366,61.2c0-6.2,5.2-11.2,11.4-11.2c6.2,0,11.4,5,11.4,11.2c0,6.3-5.2,11.4-11.4,11.4
	C371.2,72.6,366,67.6,366,61.2"
    />
    <polygon
      fill="#3B3D42"
      points="356.6,87.2 328.5,168.5 307.7,168.5 291.3,116.2 274.9,168.5 254.1,168.5 226,87.2 246.8,87.2 
	264.5,138.4 282.2,87.2 300.4,87.2 318.1,138.4 335.8,87.2 "
    />
    <rect x="367" y="87.2" fill="#3B3D42" width="20.8" height="81.3" />
    <path
      fill="#3B3D42"
      d="M487.7,87.2V162c-0.5,24.7-21.5,40.9-41.9,40.9c-28.3,0-39.7-20.1-41-23.7l16.4-8.6
	c1.1,3.4,10.6,13.6,24.5,13.6c13.3,0,20.3-12.2,21.1-21.1c-6.5,4.2-14.3,6.7-22.7,6.7c-23.1,0-41.9-18.7-41.9-41.9
	c0-23.1,18.9-41.9,41.9-41.9c8.3,0,16.1,2.4,22.6,6.7v-5.4H487.7z M466.1,128c0-13-8.9-22.3-21.1-22.3c-12.4,0-21.1,9.3-21.1,22.3
	c0,13.2,8.8,22.4,21.1,22.4C457.2,150.4,466.1,141.2,466.1,128"
    />
    <path
      fill="#3B3D42"
      d="M586.9,87.2V162c-0.5,24.7-21.5,40.9-41.9,40.9c-28.3,0-39.7-20.1-41-23.7l16.4-8.6
	c1.1,3.4,10.6,13.6,24.5,13.6c13.3,0,20.3-12.2,21.1-21.1c-6.5,4.2-14.3,6.7-22.8,6.7c-23.1,0-41.9-18.7-41.9-41.9
	c0-23.1,18.9-41.9,41.9-41.9c8.3,0,16.1,2.4,22.6,6.7v-5.4H586.9z M565.3,128c0-13-8.9-22.3-21.1-22.3c-12.4,0-21.1,9.3-21.1,22.3
	c0,13.2,8.8,22.4,21.1,22.4C556.4,150.4,565.3,141.2,565.3,128"
    />
    <path
      fill="#3B3D42"
      d="M599.7,127.8c0-23.1,18.9-41.9,41.9-41.9c23.2,0,41.9,18.8,41.9,41.9c0,23.1-18.7,41.9-41.9,41.9
	C618.5,169.8,599.7,150.9,599.7,127.8 M663.6,127.8c0-13-8.9-22.4-21.1-22.4c-12.4,0-21.1,9.4-21.1,22.4c0,13,8.8,22.4,21.1,22.4
	C654.6,150.3,663.6,140.8,663.6,127.8"
    />
    <path
      fill="#3B3D42"
      d="M748.5,160c0,0-8.8,9.7-22.9,9.7c-14,0-24.7-6.8-24.7-23.7v-40.1h-9.9V87.2h9.9V60.1l20.8-0.5v27.6h19.2v18.7
	h-19.2v33.8c0,6.7,3.9,10.7,8.4,10.7c5.5,0,9.4-4.4,9.4-7L748.5,160z"
    />
    <path
      fill="#FFC834"
      d="M126.3,122.8c0,6.8-5.5,12.4-12.4,12.4c-6.8,0-12.4-5.5-12.4-12.4c0-6.8,5.5-12.4,12.4-12.4
	C120.7,110.4,126.3,115.9,126.3,122.8"
    />
    <path
      fill="#0076CC"
      d="M186.6,126.4c-0.2-0.3-0.3-0.6-0.6-0.8l-65.4-65.4c-0.2-0.2-0.2-0.5-0.4-0.7c-1.8-1.8-4.2-2.7-6.6-2.7
	c-2.4,0-4.8,0.8-6.6,2.7c-0.2,0.2-0.3,0.5-0.4,0.7l-65.4,65.4c-1.9,1.9-2.7,4.3-2.6,6.8c0,0,0,0,0,0.1v38.8c0,4.5,3.7,8.2,8.2,8.2
	h2.2h1h127.9h2.3h1c4.5,0,8.2-3.7,8.2-8.2v-38.8C189.3,130,188.2,127.9,186.6,126.4 M58.1,134.5L113.6,79l56.1,56.1v25.9H58.1V134.5
	z"
    />
  </Fragment>,
  'WiggotLogo',
  '0 0 776.6 236.7'
)
