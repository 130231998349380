/*global FB*/
import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'
import { Typography, Grid, TextField } from 'rc'
import { isMobile } from 'rc/helpers/miscellaneous'
import {
  WhatsappFlat as Whatsapp,
  Facebook,
  Mail2,
  Messenger,
  Twitter
} from 'rc/Icons'
import { Button } from 'components'
import { ShowModal, HideModal } from 'reduxActions/services'
import { ShareProfileViaEmailsMC } from 'modules/profile/modalContent'

const ShareProfileMC = ({
  type,
  profileId,
  ShowModal,
  HideModal,
  location
}) => {
  const [isCopied, setIsCopied] = useState(false)
  const isForCustomer = type === 'customer'
  const title = `Compartir perfil para ${isForCustomer ? 'Cliente' : 'Asesor'}`
  const subtitle = isForCustomer
    ? 'No muestra comisión compartida'
    : 'Muestra comisión compartida'
  const inputLink = useRef()
  const profileLink = isForCustomer
    ? `${process.env.REACT_APP_WIGGOT_SSR}/profile/${profileId}`
    : `${process.env.REACT_APP_URL}/profile/${profileId}`

  const shareViaWhatsapp = () => {
    let url = encodeURI(profileLink)

    if (!isMobile()) {
      const popUp = window.open(
        `https://web.whatsapp.com/send?text=${url}`,
        '_blank'
      )
      if (popUp) {
        popUp.focus()
      }
    } else {
      window.location = `whatsapp://send?text=${url}`
    }
  }

  useEffect(() => {
    const query = qs.parse(location.search)

    if (query.success) {
      window.close()
    }
    loadFBApi()
  }, [location.search])

  const loadFBApi = () => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: '1658793284250667',
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: 'v5.0' // use version 2.1
      })
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  const shareViaTwitter = () => {
    let url = encodeURI(profileLink)

    if (!isMobile()) {
      const popUp = window.open(
        `https://twitter.com/intent/tweet?text=${url}`,
        '_blank'
      )
      if (popUp) {
        popUp.focus()
      }
    } else {
      window.location = `twitter://send?text=${url}`
    }
  }

  const socials = {
    whatsapp: {
      icon: function Whats() {
        return (
          <Whatsapp
            css={`
              color: #6fcf97;
            `}
          ></Whatsapp>
        )
      },
      onClick: shareViaWhatsapp,
      name: 'Whatsapp',
      isShown: true
    },
    facebook: {
      icon: function Face() {
        return (
          <Facebook
            css={`
              color: #0076cc;
            `}
          ></Facebook>
        )
      },
      name: 'Facebook',
      isShown: isForCustomer,
      onClick: () => {
        FB.ui(
          {
            method: 'share',
            href: profileLink // Current url
          },
          function (response) {}
        )
      }
    },
    email: {
      icon: Mail2,
      name: 'Email',
      isShown: true,
      onClick: () => {
        ShowModal(
          ShareProfileViaEmailsMC,
          { isForCustomer, profileLink },
          null,
          true
        )
      }
    },
    messenger: {
      onClick: () => {
        FB.ui({
          method: 'send',
          link: profileLink
        })
      },
      icon: function Mes() {
        return (
          <Messenger
            css={`
              color: #0076cc;
            `}
          ></Messenger>
        )
      },
      name: 'Messenger',
      isShown: isForCustomer
    },
    twitter: {
      onClick: shareViaTwitter,
      icon: function Twit() {
        return (
          <Twitter
            css={`
              color: #65b9fc;
            `}
          ></Twitter>
        )
      },
      name: 'Twitter',
      isShown: isForCustomer
    }
  }

  useEffect(() => {
    let _ref = inputLink
    _ref.current?.addEventListener(
      'select',
      function () {
        this.selectionStart = this.selectionEnd
      },
      false
    )
    return () => {
      _ref.current?.removeEventListener('select', function () {
        this.selectionStart = this.selectionEnd
      })
    }
  }, [inputLink])

  const copyToClipboard = () => {
    if (inputLink.current) {
      inputLink.current.select()
      inputLink.current.setSelectionRange(0, 99999)
      document.execCommand('copy')
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1200)
    }
  }

  return (
    <Grid
      container
      direction="column"
      css={`
        padding: ${({ theme }) => theme.remCalc('20 30')};
        text-align: center;
        width: ${({ theme }) => theme.remCalc('450')};
        max-width: 100%;
      `}
    >
      <Typography variant="h2" color="palette.primary.main" textTransform="initial">
        {title}
      </Typography>
      <Typography
        variant="body1"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('20')};
        `}
      >
        {subtitle}
      </Typography>
      <Grid
        container
        css={`
          margin-top: ${({ theme }) => theme.remCalc('40')};
        `}
      >
        {Object.keys(socials)
          .map((key, index) => {
            const { icon: Icon, name, onClick, isShown } = socials[key]
            return isShown ? (
              <Grid
                key={index}
                item
                flat
                xs={12}
                md={8}
                container
                direction="column"
                onClick={onClick}
                justify="center"
                css={`
                  cursor: pointer;
                  margin-top: ${({ theme }) => theme.remCalc('10')};
                `}
              >
                <span
                  css={`
                    ${({ theme }) => theme.box(30, 30)}
                  `}
                >
                  <Icon></Icon>
                </span>
                <Typography
                  variant="body1"
                  css={`
                    margin-top: ${({ theme }) => theme.remCalc('10')};
                  `}
                >
                  {name}
                </Typography>
              </Grid>
            ) : null
          })
          .filter(item => item)}
      </Grid>
      <Grid
        container
        css={`
          margin-top: ${({ theme }) => theme.remCalc('20')};
          ${({ theme }) => theme.media.desktop`
        margin-top: ${({ theme }) => theme.remCalc('40')};
        `}
        `}
        alignItems="flex-end"
      >
        <Grid item flat mdAuto>
          <TextField
            inputRef={inputLink}
            value={profileLink}
            fullWidth
            inputProps={{
              readOnly: true
            }}
            id="profile-shareProfile-mc-input-copyToClipboard"
            css={`
              input {
                user-select: none;
                &[readonly] {
                  background-color: #fff;
                }
              }
            `}
            onClick={copyToClipboard}
          ></TextField>
        </Grid>
        <Grid
          item
          flat
          mdShrink
          css={`
            position: relative;
            ${({ theme }) => theme.media.desktop`
          padding-left: ${({ theme }) => theme.remCalc('10')};
          `}
          `}
        >
          <span
            isCopied={isCopied}
            css={`
              opacity: ${({ isCopied }) => (isCopied ? 1 : 0)};
              background-color: rgba(0, 0, 0, 0.6);
              color: white;
              border-radius: 5px;
              position: absolute;
              right: 0;
              bottom: 103%;
              padding: 2px 15px;
              transition: all ease 500ms;
            `}
          >
            Enlace copiado
          </span>

          <Button
            variant="outlined"
            css={`
              min-height: 46px;
              width: 100%;
              margin-top: 10px;
              ${({ theme }) => theme.media.desktop`
              margin-top: 0;
              width: initial;
              `}
            `}
            id="profile-shareProfile-mc-button-copyToClipboard"
            onClick={copyToClipboard}
          >
            Copiar enlace
          </Button>
        </Grid>
      </Grid>
      <Button
        width="large"
        type="tertiary"
        css={`
          margin: 0 auto;
          margin-top: ${({ theme }) => theme.remCalc('30')};
        `}
        id="profile-shareProfile-mc-button-cancel"
        onClick={HideModal}
      >
        Cancelar
      </Button>
    </Grid>
  )
}

ShareProfileMC.propTypes = {
  location: PropTypes.object,
  type: PropTypes.oneOf(['customer', 'agencyBroker']),
  profileId: PropTypes.string.isRequired,
  ShowModal: PropTypes.func.isRequired,
  HideModal: PropTypes.func.isRequired
}

const mapStateToProps = ({ profileReducer: { profileId } }) => ({
  profileId
})

const mapDispatchToProps = {
  ShowModal,
  HideModal
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShareProfileMC)
)
