import React, { useState, useEffect } from 'react'
import { func, number, string } from 'prop-types'
import styled from 'styled-components'

function Slider(props) {
  const totalUnits = props.max - props.min

  const [trackWidth, setValue] = useState(() =>
    props.value ? (props.value * 100) / totalUnits : 0
  )

  useEffect(() => {
    const val = ((props.value - props.min) * 100) / totalUnits
    if (!isNaN(val)) {
      setValue(val)
    }
  }, [props.value, totalUnits])

  function handleMinEnd(event) {
    props.onChange?.(event)
  }

  return (
    <Container className={props.className} style={props.style}>
      <InputRange
        type="range"
        min={props.min}
        step={props.step}
        max={props.max}
        name={props.name}
        value={props.value}
        defaultValue={props.defaultValue}
        onInput={handleMinEnd}
      />

      <BarsContainer>
        <Track
          active
          style={{
            width: `${trackWidth}%`,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5
          }}
        />
        {!isNaN(trackWidth) && <Track style={{ flex: 1, borderRadius: 5 }} />}
      </BarsContainer>
    </Container>
  )
}

Slider.propTypes = {
  max: number,
  min: number,
  maxValue: number,
  minValue: number,
  className: string,
  onChange: func
}

const InputRange = styled.input`
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  margin: 0;
  padding: 0;
  /* Add some L/R padding to ensure box shadow of handle is shown */
  overflow: hidden;
  border: 0;
  border-radius: 1px;
  outline: none;
  background: linear-gradient(transparent, transparent) no-repeat center;
  /* Use a linear gradient to generate only the 2px height background */
  background-size: 100% 2px;
  pointer-events: none;

  &:active,
  &:focus {
    outline: none;
  }
  &::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    margin: 5px 0;
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
  }

  &::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    margin: 5px 0;
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
  }
`

const Container = styled.div`
  position: relative;
  ${InputRange} {
    position: absolute;
    &:nth-child(2) {
      background: none;
    }
  }
`

const BarsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 100%;
`

const Track = styled.div`
  height: 10px;
  background-color: ${({ active }) => (active ? '#0076FF' : '#d9dde7')};
`
export default Slider
