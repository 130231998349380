import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'rc'
import { Button } from 'components'
import { ShowFlashNotification } from 'reduxActions/services'
import { CreateContact } from 'reduxActions/contacts'

const AddContactMC = ({
  HideModal,
  contact_name,
  contact_email,
  contact_phone,
  contact_source,
  lead_id,
  token
}) => {
  const dispatch = useDispatch()

  const HandleCreateContact = () => {
    dispatch(
      CreateContact(token, {
        contact_name,
        contact_email,
        contact_phone,
        contact_source,
        lead_id
      })
    )
      .then(_res => {
        dispatch(
          ShowFlashNotification(
            'success',
            '¡Se ha creado con éxito el contacto!'
          )
        )
        HideModal()
      })
      .catch(err => {
        const {
          error: {
            response: { status }
          }
        } = err
        if (status === 409) {
          dispatch(
            ShowFlashNotification(
              'danger',
              'Ya existe un contacto con esta información.'
            )
          )
          HideModal()
        } else {
          dispatch(
            ShowFlashNotification('danger', 'Ocurrió un error inesperado.')
          )
        }
      })
  }
  return (
    <ContainerStyled>
      <Typography variant="p2" color="palette.primary.main" fontWeight={700}>
        {`¿Deseas agregar como contacto a ${contact_name}?`}
      </Typography>
      <ButtonsContainer>
        <Button type="secondary" onClick={HideModal}>
          Cancelar
        </Button>
        <Button onClick={HandleCreateContact}>Agregar</Button>
      </ButtonsContainer>
    </ContainerStyled>
  )
}

AddContactMC.propTypes = {
  HideModal: PropTypes.func,
  contact_name: PropTypes.string,
  contact_email: PropTypes.string,
  contact_phone: PropTypes.string,
  contact_source: PropTypes.string,
  lead_id: PropTypes.number,
  token: PropTypes.string
}

const ContainerStyled = styled.div`
  width: ${({ theme }) => theme.remCalc('480')};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('30')};
  text-align: center;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: ${({ theme }) => theme.remCalc('20 8 0')};
  > button {
    border-radius: 50px !important;
    width: 48%;
  }
`

export default AddContactMC
