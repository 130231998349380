'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function throttle(func) {
  var threshold = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 250;
  var scope = arguments.length > 2 ? arguments[2] : undefined;
  var last, deferTimer;
  return function () {
    var context = scope || this;
    var now = Date.now(),
      args = arguments;
    if (last && now < last + threshold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        func.apply(context, args);
      }, threshold);
    } else {
      last = now;
      func.apply(context, args);
    }
  };
}

exports.throttle = throttle;
