const domain = process.env.REACT_APP_DOMAIN_COOKIE

export const createCookie = (
  name,
  value,
  days = 365,
  httpOnly,
  secure = true
) => {
  let expires = ''

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }

  const cookie = `${name}=${JSON.stringify(
    value
  )};expires=${expires};path=/;domain=${domain};${httpOnly ? 'HttpOnly;' : ''}${
    secure ? 'Secure;' : ''
  }SameSite=Lax;`
  document.cookie = cookie
}

export const getCookie = name => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export const clearCookies = cookiesName => {
  const clear = name => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`
  }
  if (typeof cookiesName === 'string') {
    clear(cookiesName)
  } else {
    cookiesName.forEach(name => clear(name))
  }
}
