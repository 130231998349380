import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M6.44141 8.02084C6.44141 8.58668 6.87891 9.04168 7.41558 9.04168H8.51223C8.9789 9.04168 9.35807 8.64501 9.35807 8.14918C9.35807 7.61835 9.12475 7.42585 8.78058 7.30335L7.02474 6.69085C6.68057 6.56835 6.44725 6.38168 6.44725 5.84501C6.44725 5.35501 6.8264 4.95251 7.29307 4.95251H8.38974C8.9264 4.95251 9.36391 5.40751 9.36391 5.97335"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.8999 4.375V9.625"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7331 7.00002C13.7331 10.22 11.1197 12.8334 7.89974 12.8334C4.67974 12.8334 2.06641 10.22 2.06641 7.00002C2.06641 3.78002 4.67974 1.16669 7.89974 1.16669"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7332 3.50002V1.16669H11.3999"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8164 4.08335L13.7331 1.16669"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'DollarArrow',
  '0 0 15 14'
)
