export const GetTokenSummary = Authorization => {
  return {
    type: 'GET_TOKENS_SUMMARY',
    payload: {
      request: {
        url: `/tokens/summary`,
        method: 'get',
        headers: {
          Authorization
        }
      }
    }
  }
}

export const GetTokenList = Authorization => {
  return {
    type: 'GET_TOKEN_LIST',
    payload: {
      request: {
        url: `/tokens`,
        method: 'get',
        headers: {
          Authorization
        }
      }
    }
  }
}

export const UnlinkTokenFromDevelopment = (Authorization, development_id) => ({
  type: 'UNLINK_TOKEN_FROM_DEVELOPMENT',
  payload: {
    request: {
      method: 'patch',
      url: '/tokens/unlink',
      headers: { Authorization },
      data: {
        development_id
      }
    }
  }
})

export const AssignTokenToDevelopment = (
  Authorization,
  type,
  development_id,
  token_id
) => ({
  type: 'ASSIGN_TOKEN_TO_DEVELOPMENT',
  payload: {
    request: {
      method: 'post',
      headers: { Authorization },
      url: `/tokens/link`,
      data: {
        type,
        development_id,
        token_id
      }
    }
  }
})

export const AssignCreditToDevelopment = (
  Authorization,
  credit,
  development_id
) => ({
  type: 'ASSIGN_CREDIT_TO_DEVELOPMENT',
  payload: {
    request: {
      method: 'post',
      headers: { Authorization },
      url: `/tokens/credits/link`,
      data: {
        development_id,
        credit
      }
    }
  }
})

export const UnlinkCreditFromDevelopment = (
  Authorization,
  credit,
  development_id
) => ({
  type: 'UNLINK_CREDIT_FROM_DEVELOPMENT',
  payload: {
    request: {
      method: 'patch',
      url: '/tokens/credits/unlink',
      headers: { Authorization },
      data: {
        credit,
        development_id
      }
    }
  }
})

export const GetTokenPersist = Authorization => {
  return {
    type: 'GET_TOKEN_PERSIST',
    payload: {
      request: {
        url: `/usertoken/persistent_token`,
        method: 'get',
        headers: {
          Authorization
        }
      }
    }
  }
}

export const GetRegenerateTokenPersist = Authorization => {
  return {
    type: 'GET_REGENERATE_TOKEN_PERSIST',
    payload: {
      request: {
        url: `/usertoken/regenerate_persistent_token`,
        method: 'get',
        headers: {
          Authorization
        }
      }
    }
  }
}
