import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormContainer, TextField, Button, SubmitButton } from 'rc'
import { isEmail } from 'rc/helpers/stringHelpers'
import PrimeImage from 'static/img/prime_illustration.svg'
import PaymentBackground from 'static/img/payment_background.svg'

import { RequestInfo } from 'reduxActions/general'

const ContactMC = props => {  
  const [isLoading, setIsLoading] = useState(false)

  const validateHandler = field => value => {
    switch (field) {
      case 'fullname':
        return value === '' ? 'Campo requerido.' : null
      case 'email':
        return value === ''
          ? 'Campo requerido.'
          : !isEmail(value)
            ? 'Ingresa un correo válido.'
            : null
      case 'phone_number':
        return value === '' ? 'Campo requerido.' : null
      default:
        return null
    }
  }

  const handleSubmit = data => {
    let errors = {}

    Object.keys(data).forEach(key => {
      let res = validateHandler(key)(data[key])
      if (res) {
        errors[key] = res
      }
    })

    if (Object.keys(errors).length > 0) {
      const errObj = new Error()
      errObj.code = 'local-errors'
      errObj.metadata = errors
      throw errObj
    }

    setIsLoading(true)

    return new Promise((resolve, reject) => {
      RequestInfo(data)
        .then(r => {
          return r.json()
        })
        .then(res => resolve())
        .catch(res => reject())
    })
  }

  const handleSubmitFail = (localErrors, fc, submitErrors) => {
    setIsLoading(false)
    if (Object.keys(localErrors).length === 0 && submitErrors) {
      if (submitErrors.code === 'local-errors') {
        fc.setCustomErrors({
          ...submitErrors.metadata
        })
      }
    }
  }

  return (
    <ModalContentStyled>
      <InfoContainerStyled>
        <TitleStyled>
          Agrega más asesores Prime
        </TitleStyled>
        <DescriptionStyled>
          Dinos a cuántos asesores de tu equipo quieres asignarles Prime y nos comunicaremos contigo.
        </DescriptionStyled>
        <IconStyled
          src={PrimeImage}
          alt="contactanos"
        />
      </InfoContainerStyled>
      <FormularyContainerStyled
        name="contact"
        initialValues={{
          fullname: props.fullname,
          email: props.email,
          phone_number: props.phoneNumber
        }}
        onSubmit={handleSubmit}
        onSubmitSuccess={null}
        onSubmitFail={handleSubmitFail}
      >
        <FieldsContainer>
          <TextField
            css={`margin-top: 0;`}
            placeholder="Nombre completo"
            label="Nombre completo"
            name="fullname"
            validate={validateHandler('fullname')}
            required
          />
          <TextFieldStyled
            placeholder="Correo"
            label="Correo"
            name="email"
            validate={validateHandler('email')}
            required
          />
          <TextFieldStyled
            placeholder="Teléfono"
            label="Teléfono"
            name="phone_number"
            validate={validateHandler('phone')}
            required
          />
          <TextFieldStyled
            placeholder="Cantidad de asesores"
            label="Cantidad de asesores"
            name="broker_count"
            required
          />          
        </FieldsContainer>
        <ButtonContainerStyled>
          <Button
            disabled={isLoading}
            variant="outlined"
            onClick={() => props.HideModal()}
          >
            Cancelar
          </Button>
          <SubmitButton
            disabled={isLoading}
          >
            Enviar
          </SubmitButton>
        </ButtonContainerStyled>
      </FormularyContainerStyled>
    </ModalContentStyled>
  )
}

const TitleStyled = styled.h3`
  font-size: ${({ theme }) => theme.remCalc('32')};
  text-align: center;
  color: #FFCD48;
  border: none;
  font-weight : 700;
  ${({ theme }) => theme.media.desktopDown`
      font-size: ${({ theme }) => theme.remCalc('28')};
      margin: ${({ theme }) => theme.remCalc('0 0 16 0')};
  `}
`

const IconStyled = styled.img`  
  height: ${({ theme }) => theme.remCalc('180')};
  ${({ theme }) => theme.media.desktopDown`display: none;`}
`
const DescriptionStyled = styled.p`
  font-size: ${({ theme }) => theme.remCalc('16')};
  font-weight: 500;
  text-align: center;
  margin: ${({ theme }) => theme.remCalc('0 0 16 0')};
  color: #FFFFFF;
`

const ModalContentStyled = styled.div`
  display: flex;
  width: 100%;
  max-width: ${({ theme }) => theme.remCalc('700')};
  ${({ theme }) => theme.media.desktopDown`
    flex-direction: column;
    height: 100%;
  `}
  ${({ theme }) => theme.media.desktop`
    height: 100%;
    max-height: ${({ theme }) => theme.remCalc('450')};
    border-radius: 10px;

  `}
`

const InfoContainerStyled = styled.div`
  background-image: url(${PaymentBackground});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;  
  padding: ${({ theme }) => theme.remCalc('28 50')};
  flex: 1;
  ${({ theme }) => theme.media.desktopDown`
    padding: ${({ theme }) => theme.remCalc('0 50')}
  `}
`

const FormularyContainerStyled = styled(FormContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  ${({ theme }) => theme.media.desktop`
    padding: ${({ theme }) => theme.remCalc('30')};;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  `}
`

const FieldsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${({ theme }) => theme.remCalc('16')};
  ${({ theme }) => theme.media.desktop`padding: ${({ theme }) => theme.remCalc('0 0 10 0')};;`}
`

const TextFieldStyled = styled(TextField)`
  margin-top: ${({ theme }) => theme.remCalc('25')};
  ${({ theme }) => theme.media.desktopDown`margin-top: ${({ theme }) => theme.remCalc('16')};`}
`

const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  > button {
    max-width: ${({ theme }) => theme.remCalc('150')};
    width: 100%;
  }
  > button:first-child {
    margin-right: ${({ theme }) => theme.remCalc('15')};
  }
  ${({ theme }) => theme.media.desktopDown`
    background: #ffffff;
    padding: ${({ theme }) => theme.remCalc('10')};
    border: 1px solid #f2f2f2;
    box-shadow: 0px -5px 15px rgba(151, 164, 177, 0.25);
  `}
`

ContactMC.propTypes = {
  HideModal: PropTypes.func,
  fullname: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string
}

export default ContactMC
