import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { ShowFlashNotification, ShowModal } from 'reduxActions/services'
import { Button, Typography, FormContainer, TextField, SubmitButton } from 'rc'

import { UpdatePublicList } from 'reduxActions/publicLists'
import { MonthlyListsMC } from 'modules/freemium'
import { basicFetchRequest } from 'src/api/lists-auth-x'
import { AddToPublicListMC } from 'components'

const CreateOrUpdatePublicListMC = ({
  listName,
  propertyId,
  origin,
  HideModal,
  cb
}) => {
  const dispatch = useDispatch()
  const { token } = useSelector(({ authReducer: { token } }) => ({ token }))
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async ({ name }) => {
    setIsLoading(true)
    if (!name) {
      const errObj = new Error()
      errObj.code = 'local-errors'
      errObj.metadata = { name: 'Campo requerido' }
      throw errObj
    }

    if (listName) {
      return dispatch(UpdatePublicList(token, name))
    } else {
      const createListResponse = await basicFetchRequest({ name }, 'createList')
      return createListResponse
    }
  }

  const handleSubmitFail = (localErrors, fc, submitErrors) => {
    setIsLoading(false)
    if (Object.keys(localErrors).length === 0 && submitErrors) {
      if (submitErrors.code === 'local-errors') {
        fc.setCustomErrors({ ...submitErrors.metadata })
        dispatch(
          ShowFlashNotification(
            'danger',
            'Ninguno de los campos puede ir vacío.'
          )
        )
      } else {
        const {
          error: {
            response: {
              data: {
                error: { message }
              }
            }
          }
        } = submitErrors
        switch (message) {
          case 'Number of month lists exceeded':
            dispatch(ShowModal(MonthlyListsMC))
            break
          default:
            dispatch(
              ShowFlashNotification(
                'danger',
                'Ocurrió un error. Inténtalo más tarde.'
              )
            )
        }
      }
    } else {
      dispatch(
        ShowFlashNotification(
          'danger',
          'Ocurrió un error. Inténtalo más tarde.'
        )
      )
    }
  }

  const handleSubmitSuccess = () => {
    cb && cb()
    dispatch(ShowModal(AddToPublicListMC, { propertyId, origin }))
    dispatch(
      ShowFlashNotification(
        'success',
        listName
          ? 'Se ha actualizado la lista con éxito.'
          : 'Se ha creado la lista con éxito.'
      )
    )
  }

  return (
    <ContainerStyled
      initialValues={{
        name: listName ? listName : ''
      }}
      onSubmit={handleSubmit}
      onSubmitSuccess={handleSubmitSuccess}
      onSubmitFail={handleSubmitFail}
    >
      <Typography
        variant="h6"
        color="palette.primary.main"
        css={`
          font-weight: 700;
          text-transform: none;
        `}
      >
        {listName ? 'Cambiar nombre' : 'Crear lista'}
      </Typography>
      <TextField
        name="name"
        fullWidth
        required
        placeholder="Nombre de la lista"
        disabled={isLoading}
        css={`
          margin: ${({ theme }) => theme.remCalc('32 0')};
        `}
      />
      <ButtonsContainer>
        <Button variant="outlined" color="gray" onClick={HideModal}>
          Cancelar
        </Button>
        <SubmitButton disabled={isLoading}>
          {listName ? 'Actualizar' : 'Crear'}
        </SubmitButton>
      </ButtonsContainer>
    </ContainerStyled>
  )
}

CreateOrUpdatePublicListMC.propTypes = {
  listName: PropTypes.string,
  HideModal: PropTypes.func,
  propertyId: PropTypes.string,
  origin: PropTypes.string,
  cb: PropTypes.func
}

const ContainerStyled = styled(FormContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.remCalc('20')};
  max-width: ${({ theme }) => theme.remCalc('428')};
  width: 100%;
  ${({ theme }) =>
    theme.media.tablet`width: ${({ theme }) => theme.remCalc('428')};`}
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > button {
    border-radius: 50px !important;
    width: 48%;
  }
`

export default CreateOrUpdatePublicListMC
