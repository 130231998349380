import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="-10.32"
        y1="109.97"
        x2="401.06"
        y2="257.85"
        gradientTransform="matrix(1, 0, 0, -1, 0, 295.53)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.27" stopColor="#0086df" />
        <stop offset="0.47" stopColor="#0c72dc" />
        <stop offset="0.85" stopColor="#2843d4" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="31.84"
        y1="184.64"
        x2="165.74"
        y2="184.64"
        gradientTransform="matrix(1, 0, 0, -1, 0, 295.53)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2843d4" />
        <stop offset="0.91" stopColor="#2843d4" />
      </linearGradient>
      <clipPath id="clip-path">
        <rect x="125.84" y="46.28" width="216.1" height="173.5" fill="none" />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          d="M262.44,187.88a21.57,21.57,0,0,0-3.2,6.9c-.3,1.2-1.2,3.9-.8,4.9s1.8.9,2.3-.3c.5.5,1.8.5,2-.5a2.13,2.13,0,0,0,2.3-.8c1.1.5,2-.2,2.3-1.7s1.2-3.2,2.2-7.6a15.63,15.63,0,0,1,1.1-2.6,41.37,41.37,0,0,1-7.1-2C263.34,185.08,263.34,186.78,262.44,187.88Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-3">
        <path
          d="M265.84,188.58a2.44,2.44,0,0,0-2.9,1.1c-1.7,2.3-1.8,5.4-2.9,8,.9-.3,1.7-.8,2.6-1.2a5.1,5.1,0,0,1,4.8.2c0-.2,0-.2.2-.3.3-1.2.9-2.6,1.7-5.4-.3-.2-.5-.3-.8-.5C267.44,189.88,266.74,189.08,265.84,188.58Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-4">
        <path
          d="M275.34,121c-3.2,7.2-3.7,28.7-3.9,32.4-.2,5.1,8.3,4.3,9.9,2.6,2.2-2.2,4.3-7.7,5.5-17.1,2-16.5,2.2-25.6,2.2-25.6S278.94,113.08,275.34,121Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-5">
        <path
          d="M276.24,125c-1.1,7.1,3.7,13,.8,21.3-1.2,3.5,3.1,11.3,4.5,9.7,2.2-2.2,4.3-7.7,5.5-17.1,2-16.5,2.2-25.6,2.2-25.6S277.84,114.48,276.24,125Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-6">
        <path
          d="M272.44,152.68c-2.5,2.3-3.1,8.2-3.7,11.6-1.1,5.6-6.5,21.7-6.5,21.7a58.85,58.85,0,0,0,9.4,2.3s8.2-23.3,10.6-30.5C285.94,147,275,150.08,272.44,152.68Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-7">
        <path
          d="M275.84,151c-4,5.4,0,13.6-2.6,23.3-.9,3.2-5.4,10.3-5.7,13.3,1.2.3,2.6.6,4,.9,0,0,9.9-28.4,12.3-35.6C286.94,143.88,278.54,147.28,275.84,151Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-8">
        <path
          d="M267.34,193.38l-1.8,4c-.2.5,0,.8.5,1.1s.8,0,1.1-.5c0,0,1.7-3.5,1.2-4.5C267.94,193.28,267.44,193.08,267.34,193.38Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-9">
        <path
          d="M259.94,192.78l-1.5,5.9a1,1,0,1,0,2,.4s1.4-5.4.6-6.5A.63.63,0,0,0,259.94,192.78Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-10">
        <path
          d="M262.24,192.28l-1.5,5.9a1,1,0,1,0,2,.4s1.4-5.4.6-6.5C263.14,192,262.44,191.88,262.24,192.28Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-11">
        <path
          d="M264.44,192.18l-1.4,5.4a1,1,0,1,0,2,.4s1.2-4.9.6-6C265.14,191.88,264.54,191.68,264.44,192.18Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-12">
        <path
          d="M281.44,163.18c-6,20.9-8.2,35.1-11.8,56.5-2.6,16.2-10.4,38.8-15.8,54.7,2,3.7,7.1,5.7,11.9,4.9,3.2-5.5,17.5-45.1,21.2-56.5,5.6-16.6,12.5-31.3,18.4-51.6C305.54,170.48,287.74,163.78,281.44,163.18Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-13">
        <path
          d="M277.14,181.68c-1.2,5.2,9.1,7.1,6.3,17.1-2.5,8.8.3,9.1.3,15.4a26.68,26.68,0,0,1-4,14.5c-2.9,4.5-5.8,7.4-6.5,12.9s-3.1,13.1-4.3,18.3c-2.2,9.6-8.8,8-12.4,17a12.14,12.14,0,0,0,9.5,2.3c3.2-5.5,17.4-45.1,21.1-56.6,5.5-16.6,12.4-31.3,18.3-51.6C305.34,170.78,282.64,183.68,277.14,181.68Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-14">
        <path
          d="M317.34,173.38c-4.9-.9-27.9-6.3-30.4-2-3.6,6.6.6,7.5,2,15.4,4.2,24.5,18.5,84.7,20.7,91.9,4.3,1.7,8.5.2,11.6-.6-.8-18.9-4.2-33.9-4.8-50.2C315.64,208.48,317.84,188.28,317.34,173.38Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-15">
        <path
          d="M317.64,173.58c-1.7-.3-8.8-1.1-13-1.8,1.7,2.2,2.8,11.1,2.3,20-.5,8.6,3.5,8.6,3.5,15.7,0,11.1,1.7,18.3,1.7,21.5a108.77,108.77,0,0,0,.3,18.6c.5,4.5,2.9,13.1,4.3,17.7.8,2.5-.8,10.9-.3,14.2,1.7-.3,3.4-.8,4.6-1.1-.8-18.9-4.2-33.9-4.8-50.2C315.94,208.68,318.14,188.48,317.64,173.58Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-16">
        <path
          d="M319.44,133.18c.2-9.6-5.7-22.3-20.6-22.3-6.2,0-18.2,2.3-20.6,7.6-1.8,4-3.1,62-2.3,63.7,1.4,3.2,43.4,4.5,43.9,2.2C320.24,181.58,319.34,143.88,319.44,133.18Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-17">
        <path
          d="M319.44,133.18c.2-9.6-5.7-22.3-20.7-22.3a42.82,42.82,0,0,0-5.3.6c9.7.8,20.3,5.3,19.6,16.6-.2,3.6-3.7,17.2-3.7,20.7,0,6.2,5.3,9.3,2.9,12.9-3.1,4.8-5.7,3.6-7,7.1-1.5,4.5,7.4,2.5,5.7,7.7-2,6-7,4.3-9,9,9.3.2,17.3-.2,17.6-1.2C320.24,181.68,319.34,143.88,319.44,133.18Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-18">
        <path
          d="M296.74,102.78c-.3,3.6-5.2,4.8-5.2,9,0,3.6,15.1,4.8,15.2-.3s.6-14.4.6-14.4S297.24,99.18,296.74,102.78Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-19">
        <path
          d="M305.34,80.58a10.62,10.62,0,0,0-6.4-3.2,18.8,18.8,0,0,0-7.4.5,5.65,5.65,0,0,0-4.1,2.7c-2.3,4.7-3,10.2-3.6,15.4-.3,2.6-.6,5.2.5,7.6,1.6,3.8,6.4,7,10.5,5.6,9.2-2.9,13-11.8,13-12.7-.2-2.7.3-5.5.2-8.4S307.24,82.68,305.34,80.58Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-20">
        <path
          d="M305.64,80.28a3.48,3.48,0,0,0-1.3-1.1c-1.4,9-4.4,17.3-11.1,24-2.5,2.5-4.9,3.4-7,3.1,2.2,2.5,5.6,4,8.6,2.9,9.2-2.9,13-11.9,13-12.9-.2-2.8.3-5.6.2-8.5S307.54,82.48,305.64,80.28Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-21">
        <path
          d="M299.44,74.78c-8.6-1.2-12.2,4.5-14.5,5,6.4,4.7,17.5,2.5,17.3,4-1.1,10.3,1.6,10.4,2.2,12.3a11.47,11.47,0,0,0,4.1,5C314.94,84.38,309.64,76.18,299.44,74.78Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-22">
        <path
          d="M303.74,75.88a18.49,18.49,0,0,0-4.2-1.1c-6.7-.9-10.3,2.4-12.7,4.1.2.2,8.4,3.5,16.4,1.9C305.84,80.48,306.14,76.78,303.74,75.88Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-23">
        <path
          d="M302.84,90a6.31,6.31,0,0,0-4,2.5,4.56,4.56,0,0,1,.5,5.1.71.71,0,0,0,.6.3,4.44,4.44,0,0,0,4.7-.2,6.35,6.35,0,0,0,2.3-2.8c.6-1.4.8-3.2-.2-4.3A4.39,4.39,0,0,0,302.84,90Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-24">
        <path
          d="M294.74,99.68a4.25,4.25,0,0,0-3.4-3.8c-1.8-.5-6.2-1.5-6.9-.6-1.8,2-.8,3.5-1.2,4.9-1.2,3.1-2.6,4.7-2.2,6.7,1.2,6.1,9.4,10.5,16.5,6.4,6.9-4.1,3.8-12.7,3.4-15-1.1-5.4,3.8-9.8,3.8-9.8l-2.3-3.4C302.24,85.18,298.44,98.78,294.74,99.68Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-25">
        <path
          d="M283.34,107.08c-.3-1.7.3-4.5,1.2-4.9.5-.3,5.6,0,5.8-1.5s-3-.6-4.5-2.5c-.6-.8-.2-2,.6-3.2-.9,0-1.7,0-2,.3-1.9,2-.8,3.6-1.2,4.9-1.2,3.1-2.6,4.8-2.2,6.8a10.43,10.43,0,0,0,15,7.1C289.84,115.58,284.24,112.38,283.34,107.08Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-26">
        <path
          d="M277.94,158.48c-1.4,5,5.4,5.9,7.7,5.4,2.9-.8,8.4-4,14.4-11.3,10.7-12.9,13.3-16.4,13.3-16.4a102.33,102.33,0,0,0-.6-12.9,11.27,11.27,0,0,0-2-4.1C310.74,119,279.44,153.48,277.94,158.48Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-27">
        <path
          d="M278.64,162.08c1.7,1.9,5.4,2.2,7,1.9,2.9-.8,8.4-4,14.4-11.3,10.7-12.9,13.3-16.4,13.3-16.4l-.2-8.1C309.64,138.18,291.54,156,278.64,162.08Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-28">
        <path
          d="M249.74,154c-2.6-1.1-6.2-1.1-8.8-.9-1.5.2-4.6.2-5.7.9-.9.8-.5,2.3,1.2,2.5-.3.8.2,2.2,1.2,2.2a2.33,2.33,0,0,0,1.9,2.3c0,1.4.9,2.2,2.6,1.9,1.9-.2,4,0,9.1-.3.8,0,8.7.5,9.5.3a64.67,64.67,0,0,1-1.1-8.7C258.64,154.18,251.14,154.48,249.74,154Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-29">
        <path
          d="M258.84,159.28c-5,.3-17.5-2.8-18.3-.9-.6,1.2,1.7,3.6,2.5,4.3,1.7-.2,4,0,8.4-.3.8,0,8.7.5,9.5.3a21.46,21.46,0,0,1-.6-3.9A5,5,0,0,1,258.84,159.28Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-30">
        <path
          d="M280.84,152c-7.6.3-24.5,1.9-24.5,1.9a27.19,27.19,0,0,0,.3,9s13,1.4,18.7,1.9c3.6.3,10.2.3,12.7-1.4C291.24,161.48,292.44,151.68,280.84,152Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-31">
        <path
          d="M264.44,160.68a55.46,55.46,0,0,1-8.1-.2c.2,1.6.3,2.3.3,2.3s13,1.4,18.7,1.9c3.6.3,10.2.3,12.7-1.4,1.9-1.2,2.9-5,1.2-7.9C282.74,160.78,272.44,160.68,264.44,160.68Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-32">
        <path
          d="M182.64,208.88c.9,13.2-1.9,16.9-2.7,24.6s1.4,45.4,1.4,45.4,5.2,1.2,9.4,0c0,0,4.2-44.1,6.1-51.7,2.8-11.2,5.5-46.9,5.5-46.9l-23.1-2C179.34,178.38,181.74,195.68,182.64,208.88Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-33">
        <path
          d="M180.34,187.08a6.43,6.43,0,0,1,6.9,2.5h.3c.9.2,1.9.3,2.8.5a14.4,14.4,0,0,1,3.9,2,10.28,10.28,0,0,1,3.4,7.8c0,4.3-2.8,7.1-4.8,10.6-2.8,4.9-2,9.4-1.7,14.8a132.93,132.93,0,0,1,0,16.7c-.2,4.5,1.4,8.8.3,13.1-1.1,4.1-3.7,7.5-4.2,12a99.37,99.37,0,0,0-.8,12.4,16.66,16.66,0,0,0,4.4-.6s4.2-44.1,6.1-51.6c2.8-11.2,5.5-46.9,5.5-46.9l-23.1-2C179.24,178.38,179.74,181.88,180.34,187.08Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-34">
        <path
          d="M189.74,109.18c1.8-3-2.6-5.2-5.9-6.5-2-.8-4-1.5-5.9-2.1a1.08,1.08,0,0,0-1.1,0c-.3.3-.2.9.2,1.4,1.5,2.9.9,12.8-.3,16.7-.2.3,13.9,2.3,13.9,2.3S187.54,112.78,189.74,109.18Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-35">
        <path
          d="M185.74,103.28c-1.7-.6-6.2-2.8-7.9-3.4a1.08,1.08,0,0,0-1.1,0c-.3.3-.2.9.2,1.4,1.6,3,.9,13.1-.3,17.1,0,.2,6.9,1.2,11.2,1.9C185.64,117.38,184.34,107.78,185.74,103.28Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-36">
        <path
          d="M172.64,208.88c.9,13.2-1.9,16.9-2.7,24.6s1.4,45.4,1.4,45.4a22.33,22.33,0,0,0,9.5,0s4.2-44.1,6.1-51.7c2.8-11.2,5.5-47.8,5.5-47.8l-23.1-1.1C167,184.68,171.34,191.08,172.64,208.88Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-37">
        <path
          d="M169.34,178.68s7.2,5.4,13.9,5.4c8.1,0,3.7,36.6.5,43.9-1.1,2.5-1.7,6.9-2.3,12.3-.2,1.8-1.6,2.9-1.7,4.9-.2,1.8.9,11.5.8,13.4-.6,7.5-1.2,8.3-2.2,13.7-.6,3.2-3.9,7.1-4.5,7.1a16.56,16.56,0,0,0,7.2-.5s4.2-44.1,6.1-51.6c2.8-11.2,5.5-47.8,5.5-47.8l-23.1-1.1A.36.36,0,0,0,169.34,178.68Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-38">
        <path
          d="M197.94,91.38c-2.8-1.7-5-3.4-5.4-8.6-.8-6.9-12.1-6.3-15.3.5-1.7,3.5-2.5,10.3-1.4,14,1.4,4.8,4.5,9.2,9,11.1,1.4.6,8.7,1.8,10.4.9,2.3-1.4,2.3-11.7,3.4-14.5C199.64,92.48,198.74,91.78,197.94,91.38Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-39">
        <path
          d="M180.44,100.88a15.73,15.73,0,0,1-2.9-15.7,12.46,12.46,0,0,1,2.9-4.7,14.21,14.21,0,0,0-4.6,5.1c-1.7,3.6-1.4,7.8-.3,11.5,1.4,4.8,4.5,9.3,8.9,11.2,1.4.6,7.7,1.7,10,1.1-.9,0-1.8-.2-2.8-.3C187.54,108,183.14,104.28,180.44,100.88Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-40">
        <path
          d="M190.54,119.68c-5-2.8-7.6-2.5-13.2-3.4s-10.4,18.4.9,39.3c.7,1.2-9.8,21.3-10.3,23.9,5.6,3.5,31,3.4,37.3,1.4-2.2-9.7-3.3-25.1-3.3-32.5,0-2.6,3.6-4.3,3.6-9.9C205.54,134.88,195.54,122.58,190.54,119.68Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-41">
        <path
          d="M190,119.58a21.36,21.36,0,0,0-4.6-2c0,1.1,7.1,8.3,9.5,12.3a12.74,12.74,0,0,1,1.5,8.6c-.2,1.2-14.9-4.1-15.5-2.8-3.4,8.3,12.4,23.5,12,24.1.3,1.5.2,3.2.3,3.7.6,3.4,2,5.4.8,8.7-1.7,4.6-6.3,8-11.4,9.4,8.8.5,18.4-.2,22-1.2-2.2-9.7-3.2-25-3.2-32.4,0-2.6,3.5-4.3,3.5-9.8C204.84,134.68,195,122.48,190,119.58Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-42">
        <path
          d="M189.94,77.68c-2.2-.5-2.5-.3-4.1-1.1-12-6.2-21.9,2.8-21.1,13.7.6,8.3,7.8,17.3,11.6,17.3,2.8,0-.3-2,6.7-7.3,1.9-1.4,3-2.6,2.2-3.1-2.8-1.7-3.6-6.5-2.3-7.6,1.9-1.7,2.7,1.4,3.9.6a11,11,0,0,0,4.7-7.6C191.54,80.18,192.74,78.28,189.94,77.68Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-43">
        <path
          d="M174.34,94a47.23,47.23,0,0,1,1.7-5.4c1.5-5.6-3.2-10.6-8-7.9-2.9,1.6-5.6,11.2-.2,19.3,2,3,6.3,7.6,8.8,7.8S173.44,99.28,174.34,94Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-44">
        <path
          d="M244.64,159.08a32.51,32.51,0,0,0-4.2,1.1c-.9.3-.9.8-.9,1.1s.6.6,1.2.3c1.9-.6,2.2-.8,4-1.4C246.34,159.58,245.94,159,244.64,159.08Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-45">
        <path
          d="M246.54,157.58c-1.5.3-2.8.5-4.3.9-.9.3-.9.6-.9,1.1,0,.3.6.6,1.2.5,1.9-.5,2.2-.6,4-1.2S247.74,157.38,246.54,157.58Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-46">
        <path
          d="M179.84,125.88a6.81,6.81,0,0,0-1.1,9.5c5.1,6.6,13.6,16.6,21.2,21.5l6.7-5.7c-2.6-3.1-8.7-13.8-16.7-24.1A6.93,6.93,0,0,0,179.84,125.88Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-47">
        <path
          d="M240.84,154.68c-2.8-.2-.3-2.3-2.6-2.1a10.87,10.87,0,0,0-6.8,2.6c-.9.9-2.2.5-3.4.6-5,.2-14.9-3.5-23.9-6.1-3.1-.9-6.4,4.1-5,6.1,3.4,4.9,23.1,5.1,29.9,5.7,5.1.5,8.2.2,10.1.5s2.8-.3,2.8-1.5a1.77,1.77,0,0,0,1.7-1.8,1.19,1.19,0,0,0,1.1-1.7c1.7,0,3.1-.2,2.8-1.4C247.14,154.68,242.34,154.78,240.84,154.68Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-48">
        <path
          d="M200.64,153.28a11,11,0,0,1-1.1-1.2c-.9,1.4-1.2,2.8-.6,3.7,3.4,4.9,23.1,5.1,29.9,5.7a51.76,51.76,0,0,0,6.4.3c-.6-.5-11.8-2.5-12.9-2.5C216.14,158.78,205.44,157.68,200.64,153.28Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-49">
        <path
          d="M228,154.68c-5,.2-15.8-4-24.8-6.6-3.1-.9-6.2,5.5-5,7.5,3.4,4.9,22.9,6.3,29.9,6.9-.5-1.8,0-6,.5-7.8Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-50">
        <path
          d="M204.84,148.48c-3.1-4.6-8.4-13.3-14.9-21.6a6.4,6.4,0,0,0-5-2.6,5.44,5.44,0,0,1,1.1,1.8c1.1,2.6,2.8,4.6,4.2,7.1,1.2,2.1,1.6,4.6,2.8,6.7a48.9,48.9,0,0,0,9.9,10.9c4.6,3.7,8.1,3.7,13.8,3.2a14.15,14.15,0,0,1,6.7,1.1c1.4.6,3.3,2.5,4.8,3.1a19.33,19.33,0,0,1,.6-3.4H228C223.44,154.78,213.34,151.08,204.84,148.48Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-51">
        <path
          d="M233.84,153.38l2.9.9a6.78,6.78,0,0,0,3.4-1.7c1.9,0,2.9-.9,3.1-2.1a43.64,43.64,0,0,1-4.3.8Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-52">
        <path
          d="M251,153.88c-1.5.3-2.8.5-4.3.9-.9.3-.9.6-.9,1.1,0,.3.6.6,1.2.5,1.9-.5,2.2-.6,4-1.2S252.24,153.78,251,153.88Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-53">
        <path
          d="M248.24,155.68c-1.5.3-2.8.5-4.3.9-.9.3-.9.6-.9,1.1,0,.3.6.6,1.2.5,1.9-.5,2.2-.6,4-1.2S249.44,155.58,248.24,155.68Z"
          fill="none"
        />
      </clipPath>
    </defs>
    <title>conoce mi perfil foto</title>
    <rect width="399.33" height="220.14" fill="url(#linear-gradient)" />
    <path
      d="M156,205.58H41.54a9.71,9.71,0,0,1-9.7-9.7v-170a9.71,9.71,0,0,1,9.7-9.7H156a9.71,9.71,0,0,1,9.7,9.7v169.9A9.8,9.8,0,0,1,156,205.58Z"
      fill="url(#linear-gradient-2)"
    />
    <rect x="39.54" y="23.58" width="118.4" height="174.6" fill="#c3ecfe" />
    <rect x="44.04" y="28.88" width="109.3" height="65.2" fill="#fff" />
    <rect x="44.04" y="100.48" width="109.3" height="92.3" fill="#fff" />
    <rect
      x="44.04"
      y="28.88"
      width="109.3"
      height="26.2"
      fill="#c3ecfe"
      style={{ opacity: 0.3499999940395355, isolation: 'isolate' }}
    />
    <circle cx="98.75" cy="50.75" r="14.2" fill="#fff" />
    <circle
      cx="98.74"
      cy="50.68"
      r="12.2"
      style={{ fill: '#0964af', opacity: 0.25, isolation: 'isolate' }}
    />
    <circle
      cx="98.75"
      cy="50.75"
      r="14.2"
      style={{
        fill: 'none',
        stroke: '#fcce08',
        strokeMiterlimit: 9.99963255861981,
        strokeWidth: '1.0563611249391454px'
      }}
    />
    <path
      d="M101.14,50.88a4.37,4.37,0,0,0,2-3.7,4.4,4.4,0,0,0-8.8,0,4.51,4.51,0,0,0,2,3.7,6.3,6.3,0,0,0-4.8,6v3.7a11.9,11.9,0,0,0,7.2,2.3,12.6,12.6,0,0,0,7.2-2.3v-3.7A6.11,6.11,0,0,0,101.14,50.88Z"
      fill="#fff"
    />
    <polygon
      points="87.24 67.28 87.74 68.48 89.14 68.68 88.14 69.68 88.34 70.98 87.24 70.38 86.04 70.98 86.24 69.68 85.24 68.68 86.64 68.48 87.24 67.28"
      fill="#ffc100"
    />
    <polygon
      points="92.94 67.28 93.54 68.48 94.84 68.68 93.94 69.68 94.14 70.98 92.94 70.38 91.74 70.98 92.04 69.68 91.04 68.68 92.34 68.48 92.94 67.28"
      fill="#ffc100"
    />
    <polygon
      points="98.74 67.28 99.34 68.48 100.64 68.68 99.74 69.68 99.94 70.98 98.74 70.38 97.54 70.98 97.84 69.68 96.84 68.68 98.14 68.48 98.74 67.28"
      fill="#ffc100"
    />
    <polygon
      points="104.54 67.28 105.14 68.48 106.44 68.68 105.54 69.68 105.74 70.98 104.54 70.38 103.34 70.98 103.54 69.68 102.64 68.68 103.94 68.48 104.54 67.28"
      fill="#ffc100"
    />
    <polygon
      points="110.34 67.28 110.94 68.48 112.24 68.68 111.24 69.68 111.54 70.98 110.34 70.38 109.14 70.98 109.34 69.68 108.44 68.68 109.74 68.48 110.34 67.28"
      fill="#ffc100"
    />
    <g style={{ opacity: 0.15000000596046448 }}>
      <line
        x1="62.74"
        y1="80.68"
        x2="80.74"
        y2="80.68"
        style={{
          fill: 'none',
          stroke: '#0964af',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2.112799882888794px'
        }}
      />
      <line
        x1="121.04"
        y1="87.88"
        x2="134.74"
        y2="87.88"
        style={{
          fill: 'none',
          stroke: '#0964af',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2.112799882888794px'
        }}
      />
      <line
        x1="62.74"
        y1="87.88"
        x2="115.14"
        y2="87.88"
        style={{
          fill: 'none',
          stroke: '#0964af',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2.112799882888794px'
        }}
      />
      <line
        x1="86.64"
        y1="80.68"
        x2="134.74"
        y2="80.68"
        style={{
          fill: 'none',
          stroke: '#0964af',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2.112799882888794px'
        }}
      />
    </g>
    <path
      d="M47.84,110.88v15.9A2.22,2.22,0,0,0,50,129H74a2.22,2.22,0,0,0,2.2-2.2v-15.9a2.22,2.22,0,0,0-2.2-2.2H50A2.22,2.22,0,0,0,47.84,110.88Z"
      fill="#bcd5ee"
    />
    <path
      d="M49.24,109.28v15.8a2.26,2.26,0,0,0,2.3,2.3h23.9a2.26,2.26,0,0,0,2.3-2.3v-15.8a2.26,2.26,0,0,0-2.3-2.3H51.54A2.33,2.33,0,0,0,49.24,109.28Z"
      fill="#f8f8f8"
    />
    <rect x="66.64" y="114.38" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="66.64" y="116.98" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="66.64" y="119.58" width="7.8" height="1.3" fill="#bcd5ee" />
    <polygon
      points="55.14 122.08 63.04 122.08 63.04 114.08 59.14 111.98 55.14 114.08 55.14 122.08"
      fill="#2877bc"
    />
    <polygon
      points="63.24 114.58 59.14 112.38 54.94 114.58 54.64 113.98 59.14 111.58 63.64 113.98 63.24 114.58"
      fill="#00bbe1"
    />
    <rect x="52.84" y="118.28" width="4.1" height="3.9" fill="#2877bc" />
    <rect x="52.84" y="117.58" width="2.9" height="0.7" fill="#00bbe1" />
    <circle cx="58.94" cy="118.98" r="0.8" fill="#dde361" />
    <circle cx="58.94" cy="116.48" r="0.8" fill="#fbd83e" />
    <path
      d="M83.74,110.88v15.9a2.22,2.22,0,0,0,2.2,2.2h24a2.22,2.22,0,0,0,2.2-2.2v-15.9a2.22,2.22,0,0,0-2.2-2.2h-24A2.22,2.22,0,0,0,83.74,110.88Z"
      fill="#bcd5ee"
    />
    <path
      d="M85.24,109.28v15.8a2.26,2.26,0,0,0,2.3,2.3h23.9a2.26,2.26,0,0,0,2.3-2.3v-15.8a2.26,2.26,0,0,0-2.3-2.3H87.54A2.26,2.26,0,0,0,85.24,109.28Z"
      fill="#f8f8f8"
    />
    <rect x="102.64" y="114.38" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="102.64" y="116.98" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="102.64" y="119.58" width="7.8" height="1.3" fill="#bcd5ee" />
    <polygon
      points="91.14 122.08 99.04 122.08 99.04 114.08 95.04 111.98 91.14 114.08 91.14 122.08"
      fill="#00bbe1"
    />
    <polygon
      points="99.24 114.58 95.04 112.38 90.94 114.58 90.64 113.98 95.04 111.58 99.54 113.98 99.24 114.58"
      fill="#fbd83e"
    />
    <rect x="88.84" y="118.28" width="4.1" height="3.9" fill="#00bbe1" />
    <rect x="88.84" y="117.58" width="2.9" height="0.7" fill="#fbd83e" />
    <circle cx="94.94" cy="118.98" r="0.8" fill="#0076cc" />
    <circle cx="94.94" cy="116.48" r="0.8" fill="#0076cc" />
    <path
      d="M120,111.18v15.9a2.22,2.22,0,0,0,2.2,2.2h24a2.22,2.22,0,0,0,2.2-2.2v-15.9a2.22,2.22,0,0,0-2.2-2.2h-24A2.22,2.22,0,0,0,120,111.18Z"
      fill="#bcd5ee"
    />
    <path
      d="M121.54,109.48v15.8a2.26,2.26,0,0,0,2.3,2.3h23.9a2.26,2.26,0,0,0,2.3-2.3v-15.8a2.26,2.26,0,0,0-2.3-2.3h-23.9A2.39,2.39,0,0,0,121.54,109.48Z"
      fill="#f8f8f8"
    />
    <rect x="138.84" y="114.58" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="138.84" y="117.18" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="138.84" y="119.88" width="7.8" height="1.3" fill="#bcd5ee" />
    <polygon
      points="127.44 122.38 135.24 122.38 135.24 114.38 131.34 112.28 127.44 114.38 127.44 122.38"
      fill="#fbd83e"
    />
    <polygon
      points="135.44 114.88 131.34 112.68 127.24 114.88 126.84 114.18 131.34 111.78 135.84 114.18 135.44 114.88"
      fill="#0076cc"
    />
    <rect x="125.04" y="118.58" width="4.1" height="3.9" fill="#fbd83e" />
    <rect x="125.04" y="117.78" width="2.9" height="0.7" fill="#0076cc" />
    <circle cx="131.14" cy="119.18" r="0.8" fill="#00bbe1" />
    <circle cx="131.14" cy="116.68" r="0.8" fill="#00bbe1" />
    <path
      d="M83.74,139.28v15.9a2.22,2.22,0,0,0,2.2,2.2h24a2.22,2.22,0,0,0,2.2-2.2v-15.9a2.22,2.22,0,0,0-2.2-2.2h-24A2.22,2.22,0,0,0,83.74,139.28Z"
      fill="#bcd5ee"
    />
    <path
      d="M85.24,137.58v15.8a2.26,2.26,0,0,0,2.3,2.3h23.9a2.26,2.26,0,0,0,2.3-2.3v-15.8a2.26,2.26,0,0,0-2.3-2.3H87.54A2.39,2.39,0,0,0,85.24,137.58Z"
      fill="#f8f8f8"
    />
    <rect x="102.64" y="142.68" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="102.64" y="145.38" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="102.64" y="147.98" width="7.8" height="1.3" fill="#bcd5ee" />
    <polygon
      points="91.14 150.48 99.04 150.48 99.04 142.48 95.04 140.38 91.14 142.48 91.14 150.48"
      fill="#2877bc"
    />
    <polygon
      points="99.24 142.98 95.04 140.78 90.94 142.98 90.64 142.38 95.04 139.88 99.54 142.38 99.24 142.98"
      fill="#00bbe1"
    />
    <rect x="88.84" y="146.68" width="4.1" height="3.9" fill="#2877bc" />
    <rect x="88.84" y="145.88" width="2.9" height="0.7" fill="#00bbe1" />
    <circle cx="94.94" cy="147.28" r="0.8" fill="#dde361" />
    <circle cx="94.94" cy="144.78" r="0.8" fill="#fbd83e" />
    <path
      d="M120,139.28v15.9a2.22,2.22,0,0,0,2.2,2.2h24a2.22,2.22,0,0,0,2.2-2.2v-15.9a2.22,2.22,0,0,0-2.2-2.2h-24A2.22,2.22,0,0,0,120,139.28Z"
      fill="#bcd5ee"
    />
    <path
      d="M121.54,137.58v15.8a2.26,2.26,0,0,0,2.3,2.3h23.9a2.26,2.26,0,0,0,2.3-2.3v-15.8a2.26,2.26,0,0,0-2.3-2.3h-23.9A2.39,2.39,0,0,0,121.54,137.58Z"
      fill="#f8f8f8"
    />
    <rect x="138.84" y="142.68" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="138.84" y="145.38" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="138.84" y="147.98" width="7.8" height="1.3" fill="#bcd5ee" />
    <polygon
      points="127.44 150.48 135.24 150.48 135.24 142.48 131.34 140.38 127.44 142.48 127.44 150.48"
      fill="#00bbe1"
    />
    <polygon
      points="135.44 142.98 131.34 140.78 127.24 142.98 126.84 142.38 131.34 139.88 135.84 142.38 135.44 142.98"
      fill="#fbd83e"
    />
    <rect x="125.04" y="146.68" width="4.1" height="3.9" fill="#00bbe1" />
    <rect x="125.04" y="145.88" width="2.9" height="0.7" fill="#fbd83e" />
    <circle cx="131.14" cy="147.28" r="0.8" fill="#0076cc" />
    <circle cx="131.14" cy="144.78" r="0.8" fill="#0076cc" />
    <path
      d="M47.84,139.58v15.9a2.22,2.22,0,0,0,2.2,2.2H74a2.22,2.22,0,0,0,2.2-2.2v-15.9a2.22,2.22,0,0,0-2.2-2.2H50A2.22,2.22,0,0,0,47.84,139.58Z"
      fill="#bcd5ee"
    />
    <path
      d="M49.24,137.88v15.8a2.26,2.26,0,0,0,2.3,2.3h23.9a2.26,2.26,0,0,0,2.3-2.3v-15.8a2.26,2.26,0,0,0-2.3-2.3H51.54A2.4,2.4,0,0,0,49.24,137.88Z"
      fill="#f8f8f8"
    />
    <rect x="66.64" y="142.98" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="66.64" y="145.58" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="66.64" y="148.28" width="7.8" height="1.3" fill="#bcd5ee" />
    <polygon
      points="55.14 150.78 63.04 150.78 63.04 142.78 59.14 140.68 55.14 142.78 55.14 150.78"
      fill="#fbd83e"
    />
    <polygon
      points="63.24 143.28 59.14 140.98 54.94 143.28 54.64 142.58 59.14 140.18 63.64 142.58 63.24 143.28"
      fill="#0076cc"
    />
    <rect x="52.84" y="146.88" width="4.1" height="3.9" fill="#fbd83e" />
    <rect x="52.84" y="146.18" width="2.9" height="0.7" fill="#0076cc" />
    <circle cx="58.94" cy="147.58" r="0.8" fill="#00bbe1" />
    <circle cx="58.94" cy="145.08" r="0.8" fill="#00bbe1" />
    <path
      d="M120,167.38v15.9a2.22,2.22,0,0,0,2.2,2.2h24a2.22,2.22,0,0,0,2.2-2.2v-15.9a2.22,2.22,0,0,0-2.2-2.2h-24A2.22,2.22,0,0,0,120,167.38Z"
      fill="#bcd5ee"
    />
    <path
      d="M121.54,165.68v15.8a2.26,2.26,0,0,0,2.3,2.3h23.9a2.26,2.26,0,0,0,2.3-2.3v-15.8a2.26,2.26,0,0,0-2.3-2.3h-23.9A2.39,2.39,0,0,0,121.54,165.68Z"
      fill="#f8f8f8"
    />
    <rect x="138.84" y="170.78" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="138.84" y="173.48" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="138.84" y="176.08" width="7.8" height="1.3" fill="#bcd5ee" />
    <polygon
      points="127.44 178.58 135.24 178.58 135.24 170.58 131.34 168.48 127.44 170.58 127.44 178.58"
      fill="#2877bc"
    />
    <polygon
      points="135.44 171.08 131.34 168.88 127.24 171.08 126.84 170.48 131.34 168.08 135.84 170.48 135.44 171.08"
      fill="#00bbe1"
    />
    <rect x="125.04" y="174.78" width="4.1" height="3.9" fill="#2877bc" />
    <rect x="125.04" y="174.08" width="2.9" height="0.7" fill="#00bbe1" />
    <circle cx="131.14" cy="175.48" r="0.8" fill="#dde361" />
    <circle cx="131.14" cy="172.98" r="0.8" fill="#fbd83e" />
    <path
      d="M47.84,167.38v15.9a2.22,2.22,0,0,0,2.2,2.2H74a2.22,2.22,0,0,0,2.2-2.2v-15.9a2.22,2.22,0,0,0-2.2-2.2H50A2.22,2.22,0,0,0,47.84,167.38Z"
      fill="#bcd5ee"
    />
    <path
      d="M49.24,165.68v15.8a2.26,2.26,0,0,0,2.3,2.3h23.9a2.26,2.26,0,0,0,2.3-2.3v-15.8a2.26,2.26,0,0,0-2.3-2.3H51.54A2.47,2.47,0,0,0,49.24,165.68Z"
      fill="#f8f8f8"
    />
    <rect x="66.64" y="170.78" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="66.64" y="173.48" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="66.64" y="176.08" width="7.8" height="1.3" fill="#bcd5ee" />
    <polygon
      points="55.14 178.58 63.04 178.58 63.04 170.58 59.14 168.48 55.14 170.58 55.14 178.58"
      fill="#00bbe1"
    />
    <polygon
      points="63.24 171.08 59.14 168.88 54.94 171.08 54.64 170.48 59.14 168.08 63.64 170.48 63.24 171.08"
      fill="#fbd83e"
    />
    <rect x="52.84" y="174.78" width="4.1" height="3.9" fill="#00bbe1" />
    <rect x="52.84" y="174.08" width="2.9" height="0.7" fill="#fbd83e" />
    <circle cx="58.94" cy="175.48" r="0.8" fill="#0076cc" />
    <circle cx="58.94" cy="172.98" r="0.8" fill="#0076cc" />
    <path
      d="M83.74,167.68v15.9a2.22,2.22,0,0,0,2.2,2.2h24a2.22,2.22,0,0,0,2.2-2.2v-15.9a2.22,2.22,0,0,0-2.2-2.2h-24A2.22,2.22,0,0,0,83.74,167.68Z"
      fill="#bcd5ee"
    />
    <path
      d="M85.24,166v15.8a2.26,2.26,0,0,0,2.3,2.3h23.9a2.26,2.26,0,0,0,2.3-2.3V166a2.26,2.26,0,0,0-2.3-2.3H87.54A2.33,2.33,0,0,0,85.24,166Z"
      fill="#f8f8f8"
    />
    <rect x="102.64" y="171.08" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="102.64" y="173.68" width="7.8" height="1.3" fill="#bcd5ee" />
    <rect x="102.64" y="176.38" width="7.8" height="1.3" fill="#bcd5ee" />
    <polygon
      points="91.14 178.88 99.04 178.88 99.04 170.88 95.04 168.78 91.14 170.88 91.14 178.88"
      fill="#fbd83e"
    />
    <polygon
      points="99.24 171.38 95.04 169.18 90.94 171.38 90.64 170.68 95.04 168.28 99.54 170.68 99.24 171.38"
      fill="#0076cc"
    />
    <rect x="88.84" y="175.08" width="4.1" height="3.9" fill="#fbd83e" />
    <rect x="88.84" y="174.28" width="2.9" height="0.7" fill="#0076cc" />
    <circle cx="94.94" cy="175.68" r="0.8" fill="#00bbe1" />
    <circle cx="94.94" cy="173.18" r="0.8" fill="#00bbe1" />
    <g style={{ opacity: 0.20000000298023224 }}>
      <path
        d="M338.54,179.49c-4.8-16,10.4-16.62,10.8-37.2.1-8.41-4.8-17-16.7-21-5.9-1.89-11.7-3.3-16.9-6.32-11.9-7-11.7-20.31-14.9-31.74-13.1-46-82.7-58.36-118.6-27.29-12.5,10.77-20.6,24.08-32.2,35.23-12.3,11.8-27.2,17.47-44,21.25-15.9,3.58-33.1,7.83-45.3,18.6-11.4,10.1-9.8,20.68-4.9,33,5.4,13.69-6.3,23-18.5,28.33-10.3,4.44-24,4.06-28.7,14.83-5.2,11.61,10.2,11.8,24.5,11.8s199.3-1,251,.38c13.9.38,58.3,3.87,64.6-12.28C352.94,196.4,341.84,190.64,338.54,179.49Z"
        fill="#c3ecfe"
      />
    </g>
    <g clipPath="url(#clip-path)">
      <g clipPath="url(#clip-path-2)">
        <rect x="256.04" y="181.98" width="16.9" height="20.6" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-3)">
        <rect x="257.64" y="186.18" width="13.8" height="13.8" fill="#f6f6f6" />
      </g>
      <g clipPath="url(#clip-path-4)">
        <rect x="269.24" y="110.98" width="22.1" height="48.5" fill="#5276b9" />
      </g>
      <g clipPath="url(#clip-path-5)">
        <rect x="273.84" y="110.98" width="17.7" height="47.5" fill="#1960a4" />
      </g>
      <g clipPath="url(#clip-path-6)">
        <rect x="259.94" y="147.88" width="25.3" height="42.7" fill="#5276b9" />
      </g>
      <g clipPath="url(#clip-path-7)">
        <rect x="265.24" y="144.88" width="21.6" height="45.9" fill="#1960a4" />
      </g>
      <g clipPath="url(#clip-path-8)">
        <rect x="263.14" y="190.98" width="7.5" height="9.9" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-9)">
        <rect x="256.04" y="190.08" width="7.4" height="12.1" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-10)">
        <rect x="258.44" y="189.78" width="7.4" height="12" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-11)">
        <rect x="260.74" y="189.58" width="7.4" height="11.5" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-12)">
        <rect
          x="251.54"
          y="160.88"
          width="56.1"
          height="120.9"
          fill="#144678"
        />
      </g>
      <g clipPath="url(#clip-path-13)">
        <rect
          x="254.24"
          y="168.78"
          width="53.4"
          height="112.9"
          fill="#14548e"
        />
      </g>
      <g clipPath="url(#clip-path-14)">
        <rect
          x="283.24"
          y="167.48"
          width="40.2"
          height="114.2"
          fill="#144678"
        />
      </g>
      <g clipPath="url(#clip-path-15)">
        <rect
          x="302.44"
          y="169.48"
          width="21.1"
          height="112.3"
          fill="#144678"
        />
      </g>
      <g clipPath="url(#clip-path-16)">
        <rect x="273.34" y="108.68" width="48.8" height="79.2" fill="#5276b9" />
      </g>
      <g clipPath="url(#clip-path-17)">
        <rect x="291.24" y="108.68" width="31" height="79.2" fill="#466bb2" />
      </g>
      <g clipPath="url(#clip-path-18)">
        <rect x="289.24" y="94.78" width="20.4" height="22.4" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-19)">
        <rect x="281.34" y="74.98" width="29" height="36.9" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-20)">
        <rect x="283.94" y="76.98" width="26.3" height="34.9" fill="#74b5e3" />
      </g>
      <g clipPath="url(#clip-path-21)">
        <rect x="282.64" y="72.28" width="31" height="31" fill="#144678" />
      </g>
      <g clipPath="url(#clip-path-22)">
        <rect x="284.64" y="72.28" width="23" height="11.2" fill="#144678" />
      </g>
      <g clipPath="url(#clip-path-23)">
        <rect x="296.54" y="87.48" width="13.1" height="13.1" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-24)">
        <rect x="278.64" y="82.88" width="28.3" height="34.3" fill="#144678" />
      </g>
      <g clipPath="url(#clip-path-25)">
        <rect x="278.64" y="92.58" width="20.4" height="24.8" fill="#74b5e3" />
      </g>
      <g clipPath="url(#clip-path-26)">
        <rect x="275.44" y="116.88" width="40.2" height="49.4" fill="#466cb3" />
      </g>
      <g clipPath="url(#clip-path-27)">
        <rect x="276.44" y="125.78" width="39.3" height="40.5" fill="#1960a4" />
      </g>
      <g clipPath="url(#clip-path-28)">
        <rect x="232.34" y="150.68" width="30.6" height="14.5" fill="#eae9ea" />
      </g>
      <g clipPath="url(#clip-path-29)">
        <rect x="238.14" y="155.48" width="25" height="9.5" fill="#efefef" />
      </g>
      <g clipPath="url(#clip-path-30)">
        <rect x="253.84" y="149.68" width="38.7" height="17.4" fill="#466cb3" />
      </g>
      <g clipPath="url(#clip-path-31)">
        <rect x="254.04" y="153.08" width="38.4" height="14" fill="#466cb3" />
      </g>
      <g clipPath="url(#clip-path-32)">
        <rect
          x="176.94"
          y="176.08"
          width="27.7"
          height="105.7"
          fill="#2875b8"
        />
      </g>
      <g clipPath="url(#clip-path-33)">
        <rect
          x="176.94"
          y="176.08"
          width="27.7"
          height="105.7"
          fill="#2875b8"
        />
      </g>
      <g clipPath="url(#clip-path-34)">
        <rect x="174.34" y="98.08" width="18.4" height="25" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-35)">
        <rect
          x="174.34"
          y="97.38"
          width="15.8"
          height="25"
          style={{
            fill: '#74b5e3',
            opacity: 0.7156000137329102,
            isolation: 'isolate'
          }}
        />
      </g>
      <path
        id="SVGID"
        d="M172.64,208.88c.9,13.2-1.9,16.9-2.7,24.6s1.4,45.4,1.4,45.4a22.33,22.33,0,0,0,9.5,0s4.2-44.1,6.1-51.7c2.8-11.2,5.5-47.8,5.5-47.8l-23.1-1.1C167,184.68,171.34,191.08,172.64,208.88Z"
        fill="#2875b8"
      />
      <g clipPath="url(#clip-path-36)">
        <rect
          x="166.34"
          y="176.08"
          width="28.3"
          height="105.7"
          fill="#2875b8"
        />
      </g>
      <g clipPath="url(#clip-path-37)">
        <rect
          x="167.04"
          y="176.08"
          width="27.7"
          height="105.7"
          style={{
            fill: '#144678',
            opacity: 0.550000011920929,
            isolation: 'isolate'
          }}
        />
      </g>
      <g clipPath="url(#clip-path-38)">
        <rect x="173.04" y="75.58" width="28.3" height="36.3" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-39)">
        <rect x="172.34" y="78.28" width="24.4" height="33.6" fill="#f6f6f6" />
      </g>
      <g clipPath="url(#clip-path-40)">
        <rect x="165.74" y="113.98" width="42.2" height="70.6" fill="#f5b419" />
      </g>
      <g clipPath="url(#clip-path-41)">
        <rect x="178.24" y="115.28" width="29" height="68.6" fill="#ed9621" />
      </g>
      <path
        id="SVGID-2"
        data-name="SVGID"
        d="M189.94,77.68c-2.2-.5-2.5-.3-4.1-1.1-12-6.2-21.9,2.8-21.1,13.7.6,8.3,7.8,17.3,11.6,17.3,2.8,0-.3-2,6.7-7.3,1.9-1.4,3-2.6,2.2-3.1-2.8-1.7-3.6-6.5-2.3-7.6,1.9-1.7,2.7,1.4,3.9.6a11,11,0,0,0,4.7-7.6C191.54,80.18,192.74,78.28,189.94,77.68Z"
        fill="#2875b8"
      />
      <g clipPath="url(#clip-path-42)">
        <rect x="162.44" y="72.28" width="31.6" height="37.6" fill="#2875b8" />
      </g>
      <path
        id="SVGID-3"
        data-name="SVGID"
        d="M174.34,94a47.23,47.23,0,0,1,1.7-5.4c1.5-5.6-3.2-10.6-8-7.9-2.9,1.6-5.6,11.2-.2,19.3,2,3,6.3,7.6,8.8,7.8S173.44,99.28,174.34,94Z"
        fill="#2875b8"
      />
      <g clipPath="url(#clip-path-43)">
        <rect x="162.44" y="77.58" width="17.1" height="32.3" fill="#2875b8" />
      </g>
      <g clipPath="url(#clip-path-44)">
        <rect x="237.24" y="156.78" width="10.8" height="7.2" fill="#f6f6f6" />
      </g>
      <g clipPath="url(#clip-path-45)">
        <rect x="238.94" y="155.28" width="10.9" height="7.1" fill="#f6f6f6" />
      </g>
      <g clipPath="url(#clip-path-46)">
        <rect x="175.04" y="121.98" width="33.9" height="37.1" fill="#f5b419" />
      </g>
      <g clipPath="url(#clip-path-47)">
        <rect x="196.54" y="147.18" width="53.3" height="17" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-48)">
        <rect x="196.34" y="149.78" width="41.1" height="14.2" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-49)">
        <rect x="195.74" y="145.68" width="35.2" height="19.1" fill="#f5b419" />
      </g>
      <g clipPath="url(#clip-path-50)">
        <rect x="182.74" y="121.98" width="48.4" height="38.4" fill="#ed9621" />
      </g>
      <g clipPath="url(#clip-path-51)">
        <rect x="231.54" y="148.28" width="14" height="8.4" fill="#f6f6f6" />
      </g>
      <g clipPath="url(#clip-path-52)">
        <rect x="243.44" y="151.58" width="11" height="7.1" fill="#fff" />
      </g>
      <g clipPath="url(#clip-path-53)">
        <rect x="240.74" y="153.38" width="10.9" height="7.1" fill="#f6f6f6" />
      </g>
      <path
        d="M191.54,77.08s-13.8-11-25.1,1.6c-12.8,14.3,3.8,24.8-6.9,33.6s-16,35.5-3.8,40.5c3.1,1.3,5.7,2.4,7,5.5s3.5,5.5,7.6,5.2a9.62,9.62,0,0,0,6.1-3.2,27.23,27.23,0,0,0,6.3-11.7c1.4-5.3.3-8.6-1.5-13.5-3.7-9.9-6.3-27,2.4-34.8,4.1-3.6-.9-2.1-1.9-5.5s-2.4-9.4,3.4-7.5C188.44,88.28,194.94,84.08,191.54,77.08Z"
        fill="#144678"
      />
    </g>
  </Fragment>,
  'AgencyBrokerProfile',
  '0 0 399.33 220.14'
)
