import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      className="icon__bg"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#0076CC"
    />
    <path
      d="M15.1711 5.5307C14.759 5.1129 14.0525 5.1129 13.6404 5.5307L8.3539 10.8651L6.2203 8.7015C5.8339 8.3128 5.1716 8.3128 4.7853 8.7015L3.9851 9.5067C3.5987 9.8954 3.5987 10.5617 3.9851 10.9504L7.4896 14.5043C7.7532 14.7695 8.1453 14.8537 8.4905 14.757C8.8564 14.8567 9.2698 14.7652 9.5486 14.4827L16.0248 7.9477C16.4369 7.53 16.4369 6.8138 16.0248 6.396L15.1711 5.5307Z"
      fill="white"
    />
  </Fragment>,
  'CheckMark',
  '0 0 20 20'
)
