import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormContainer, WordsChipIntroducer, Grid, Typography } from 'rc'
import { SubmitButton, Button } from 'components'
import {
  HideModal,
  ShowFlashNotification,
  GoBackModalHistory
} from 'reduxActions/services'
import { ShareProfileViaEmails } from 'reduxActions/profile'
const ShareProfileViaEmailsMC = ({
  token,
  isForCustomer,
  ShareProfileViaEmails,
  HideModal,
  ShowFlashNotification,
  GoBackModalHistory,
  ...props
}) => {
  const profile_type = isForCustomer ? 'public' : 'private'
  const baseId = 'profile-shareProfileViaEmails-mc'
  const handleSubmit = (values, errors) => {
    if (values.email_list && values.email_list.length === 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'CUSTOM'
      error.metadata = {
        email_list: 'Ingresa por lo menos uno.'
      }
      throw error
    }
    return ShareProfileViaEmails(token, { ...values, profile_type })
  }

  const handleSubmitSuccess = res => {
    HideModal()
    ShowFlashNotification('success', 'Se ha compartido tu perfil.')
  }

  const handleSubmitFail = (fielErrors, fc, submitErrors) => {
    if (submitErrors) {
      let { code, metadata } = submitErrors
      const {
        error: {
          response: { data: { error: { code: responseCode } = {} } = {} } = {}
        } = {}
      } = submitErrors
      code = code || responseCode
      switch (code) {
        case 'CUSTOM':
          fc.setCustomErrors({
            ...metadata
          })
          break

        default:
          ShowFlashNotification(
            'danger',
            `Ha ocurrido un error, intenta mas tarde.`
          )
          break
      }
    }
  }

  return (
    <FormContainer
      css={`
        width: ${({ theme }) => theme.remCalc('500')};
        max-width: 100%;
        padding: ${({ theme }) => theme.remCalc('30')};
      `}
      initialValues={{ email_list: [] }}
      onSubmit={handleSubmit}
      onSubmitSuccess={handleSubmitSuccess}
      onSubmitFail={handleSubmitFail}
    >
      <Typography
        variant="subtitle1"
        color="palette.primary.main"
        display="block"
        align="center"
      >
        Compartir perfil para{' '}
        {isForCustomer ? 'cliente' : 'asesor inmobiliario'}
      </Typography>
      <Typography
        variant="body1"
        css={`
          margin: ${({ theme }) => theme.remCalc('16 0')};
        `}
        display="block"
        align="center"
      >
        Este perfil <b>{isForCustomer ? 'no ' : ''}muestra las comisiones</b>{' '}
        compartidas de tus propiedades
      </Typography>
      <WordsChipIntroducer
        name="email_list"
        builtInChecker={[
          { type: 'isEmail', customErrorMessage: 'Ingresa un email válido.' }
        ]}
        repetitionErrorMessage="Este correo esta repetido"
        TextFieldProps={{
          label: 'Escribe correos',
          placeholder: 'Escribe correos',
          fullWidth: true
        }}
      />
      <Grid container>
        <Grid item xs={12}>
          <Button
            full
            type="tertiary"
            id={`${baseId}-button-cancel`}
            onClick={GoBackModalHistory}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <SubmitButton full id={`${baseId}-button-submit`}>
            Enviar
          </SubmitButton>
        </Grid>
      </Grid>
    </FormContainer>
  )
}

ShareProfileViaEmailsMC.propTypes = {
  token: PropTypes.string.isRequired,
  isForCustomer: PropTypes.bool,
  GoBackModalHistory: PropTypes.func.isRequired,
  ShareProfileViaEmails: PropTypes.func,
  HideModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  ShareProfileViaEmails,
  HideModal,
  ShowFlashNotification,
  GoBackModalHistory
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareProfileViaEmailsMC)
