import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { css } from 'styled-components'
import { Diploma } from 'rc/Icons'
import {
  ProfileSectionCard,
  StatusZero,
  ProfileEditableItem
} from 'modules/profile/components'
import { ProfileContext } from 'modules/profile'
import { ShowModal } from 'reduxActions/services'
import { ProfileCertificationMC } from 'modules/profile/modalContent'
import { LABELS_BY_KEY } from 'utils/constants'
import { RULES } from 'modules/freemium/utils/constants'

const ProfileWorkExperience = ({ ShowModal }) => {
  const profileContext = useContext(ProfileContext)
  let {
    profile: { certifications = [] },
    isMyProfile
  } = profileContext
  const hasCertifications = certifications && certifications.length > 0

  certifications = certifications.sort((a, b) => {
    if (a.start_year > b.start_year) {
      return -1
    } else if (a.start_year === b.start_year) {
      if (a.start_month > b.start_month) {
        return -1
      }
    }
    return 1
  })

  const renderCertification = (certification, index) => {
    const {
      title,
      institution,
      expiration_month,
      expiration_year,
      certification_code
    } = certification

    const certificationLabel = [title, institution]
      .filter(item => item)
      .join(' en ')
    const timeRange = [LABELS_BY_KEY[expiration_month], expiration_year].join(
      ' '
    )

    return (
      <ProfileEditableItem
        key={index}
        title={certificationLabel}
        subtitle={[
          `${timeRange.trim().length > 0 ? timeRange : 'Sin vigencia'} `,
          `${certification_code ? `Matrícula ${certification_code}` : ''}`
        ]
          .filter(item => item)
          .join(' - ')}
        icon={Diploma}
        iconColor="warning"
        isFirst={index === 0}
        isLast={index === certifications.length - 1}
        isEditable={isMyProfile}
        onEditClick={() => showProfileCertification(certification)}
        index={index}
        idBase="profile-certifications"
      />
    )
  }

  const showProfileCertification = certification => {
    ShowModal(
      ProfileCertificationMC,
      {
        profileContext,
        certification
      },
      { overflow: false, noClosingButton: false }
    )
  }

  return hasCertifications || isMyProfile ? (
    <ProfileSectionCard
      title="Certificaciones"
      isEditable={isMyProfile}
      actions={[
        {
          id: 'profile-profileCertifications-newCertification',
          name: 'Añadir Certificación',
          onClick: () => showProfileCertification(),
          variant: 'outlined',
          isShown: isMyProfile && hasCertifications,
          rule: RULES.PROFILE_CERTIFICATIONS
        }
      ]}
    >
      {hasCertifications ? (
        certifications.map(renderCertification)
      ) : (
        <StatusZero
          description="Aún no tienes agregado ningún certificado"
          buttonProps={{
            id: 'profile-profileCertifications-newCertificationStatusZero',
            text: 'Añadir certificación',
            onClick: () => showProfileCertification(),
            rule: RULES.PROFILE_CERTIFICATIONS
          }}
          iconProps={{
            className: css`
              background-color: ${({ theme }) => theme.palette.warning.main};
            `,
            icon: Diploma
          }}
          
        />
      )}
    </ProfileSectionCard>
  ) : null
}

ProfileWorkExperience.propTypes = {
  ShowModal: PropTypes.func.isRequired
}
const mapStateToProps = () => ({})

const mapDispatchToProps = {
  ShowModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileWorkExperience)
