/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'

import { LoginForm } from 'modules/login'
import { Footer } from 'modules/app'

import { LogIn } from 'reduxActions/auth'
import { GetRules } from 'reduxActions/freemium'
import { ShowFlashNotification, ShowModal } from 'reduxActions/services'
import { Logo } from 'modules/app/NavBar'

import { SessionExistModal } from 'modules/login'

class LoginModule extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  static propTypes = {
    history: PropTypes.object,
    token: PropTypes.string,
    location: PropTypes.object,
    LogIn: PropTypes.func,
    GetRules: PropTypes.func,
    ShowFlashNotification: PropTypes.func,
    ShowModal: PropTypes.func
  }

  handleSubmit = values => {
    const { LogIn } = this.props

    this.setState({ loading: true })

    return LogIn({ ...values, email: values.email.trim() })
  }

  handleSubmitSuccess = response => {
    this.setState({ loading: false })
    const { ShowModal, GetRules } = this.props
    const {
      payload: {
        data: {
          roles = [],
          exist_previous_session = false,
          token: authorization
        } = {}
      } = {}
    } = response
    const {
      token,
      history: { push },
      location: { state }
    } = this.props

    if (authorization) {
      setTimeout(async () => {
        await GetRules(authorization)
      }, 700)
    }

    if (exist_previous_session) {
      ShowModal(SessionExistModal)
    }
  }

  handleSubmitFail = (formErrors, dispatch, submitErrors) => {
    this.setState({ loading: false })
    const { ShowFlashNotification } = this.props
    if (formErrors && Object.keys(formErrors).length > 0) {
      return
    }
    if (!submitErrors) {
      submitErrors = {}
    }
    const {
      error: { response: { data: { error: { code } = {} } = {} } = {} } = {}
    } = submitErrors
    let errorMessage = ''
    switch (code) {
      case 'VALIDATION_ERROR':
      case 'LOGIN_FAILED':
        errorMessage =
          'Tu correo o contraseña son incorrectos. \nVerificalos e intenta de nuevo.\n Código de error: WIG-01'

        break
      case 'INTERNAL_SERVER_ERROR':
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'

        break
      default:
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'

        break
    }
    ShowFlashNotification('danger', errorMessage, null, {
      inFeaturedImage: true
    })
  }

  render() {
    const { loading } = this.state

    return (
      <Layout windowSize vertical justify>
        <LayoutItem>
          <div
            css={`
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 30px;
            `}
          >
            <Logo login={true} width="190" />
          </div>
        </LayoutItem>
        <LoginForm
          loading={loading}
          onSubmit={this.handleSubmit}
          onSubmitSuccess={this.handleSubmitSuccess}
          onSubmitFail={this.handleSubmitFail}
        />
        <Footer />
      </Layout>
    )
  }
}

const mapStateToProps = ({
  authReducer: { token, roles },
  freemiumReducer: { rules }
}) => ({ token, roles, rules })

const mapDispatchToProps = {
  LogIn,
  GetRules,
  ShowFlashNotification,
  ShowModal
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginModule)
)
