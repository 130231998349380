import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
// import { ShowFlashNotification } from 'reduxActions/services'
import { Typography, Chip, TextField } from 'rc'
import { Mail } from 'rc/Icons'
import { ConfirmCancelMC } from './templates'
import { CreateInvitation, GetAvailableBrokers } from 'reduxActions/developers'
import { ShowFlashNotification } from 'reduxActions/services'

const CreateInvitationMC = ({ HideModal, onUpdate }) => {
  const dispatch = useDispatch()
  const [emailsList, setEmailsList] = useState([])
  const { token } = useSelector(({ authReducer: { token } }) => ({ token }))
  const [error, setError] = useState(null)
  const [textFieldValue, setTextFieldValue] = useState('')
  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}\s*$)/

  const handleBubbleDelete = index => {
    emailsList.splice(index, 1)
    return setEmailsList([...emailsList])
  }

  const handleTextFieldEnterPress = ({ target: { value: email }, key }) => {
    if (key === 'Enter') {
      if (!emailRegex.test(email)) {
        return setError('¡Email invalido!')
      }
      setTextFieldValue('')
      dispatch(GetAvailableBrokers(token, email)).then(
        ({ payload: { data } }) => {
          if (data.length > 0) {
            return setError(
              '¡El correo se encuentra invitado o pertenece a tu desarrolladora!.'
            )
          }
          setEmailsList([...emailsList, email])
        }
      )
    }
  }

  return (
    <ConfirmCancelMC
      title="Agregar asesor"
      description={
        <>
          <Typography
            variant="subtitle4"
            color="palette.new.text"
            fontWeight={400}
            css={`
              margin: ${({ theme }) => theme.remCalc('20 0 4')};
            `}
          >
            Agrega asesores a tu desarrolladora ingresando su correo. Ellos
            recibirán una invitación para poder registrarse.
          </Typography>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: <MailStyled />,
              onKeyPress: handleTextFieldEnterPress,
              onChange: ({ target: { value } }) => {
                setTextFieldValue(value)
                error && setError(null)
              },
              value: textFieldValue
            }}
            placeholder="Correo electrónico del asesor"
          />
          <MessageStyled>
            Presiona &apos;Enter&lsquo; para agregar.
          </MessageStyled>
          {error && <ErrorStyled>{error}</ErrorStyled>}
          <ChipContainerStyled>
            {emailsList.map((email, index) => (
              <ChipStyled
                key={index}
                label={email}
                onClick={() => handleBubbleDelete(index)}
              />
            ))}
          </ChipContainerStyled>
        </>
      }
      confirmText="Enviar"
      onConfirm={() => {
        dispatch(CreateInvitation(token, emailsList))
          .then(() => {
            dispatch(
              ShowFlashNotification(
                'success',
                '¡Las invitaciones se enviaron correctamente! Te avisaremos cuando sean aceptadas.'
              )
            )
            HideModal()
            onUpdate()
          })
          .catch(() => {
            dispatch(
              ShowFlashNotification(
                'danger',
                'Ocurrio un error al enviar las invitaciones.'
              )
            )
            HideModal()
          })
      }}
      HideModal={HideModal}
      disabled={emailsList.length === 0}
    />
  )
}
CreateInvitationMC.propTypes = {
  HideModal: PropTypes.func,
  onUpdate: PropTypes.func
}

const ChipContainerStyled = styled.div`
  display: block;
  margin: ${({ theme }) => theme.remCalc('32 0 20')};
`

const MailStyled = styled(Mail)`
  margin-left: ${({ theme }) => theme.remCalc('10')};
  color: ${({ theme }) => theme.palette.gray.main};
  fill: ${({ theme }) => theme.palette.gray.main};
  ${({ theme }) => theme.box(20, 20)}
`

const ChipStyled = styled(Chip)`
  height: ${({ theme }) => theme.remCalc('20')};
  background-color: ${({ theme }) => theme.palette.primary.main};
  > * {
    font-size: ${({ theme }) => theme.remCalc('10')};
    font-weight: 700;
  }
`

const ErrorStyled = styled('span')`
  font-size: ${({ theme }) => theme.remCalc('12')};
  color: ${({ theme }) => theme.palette.tag.dangerBackground};
`

const MessageStyled = styled('span')`
  font-size: ${({ theme }) => theme.remCalc('12')};
  color: ${({ theme }) => theme.palette.gray.main};
`

export default CreateInvitationMC
