import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { ShowFlashNotification } from 'reduxActions/services'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import {
  FormContainer,
  SubmitButton,
  Button,
  Typography,
  Spinner,
  FormControlLabel,
  Checkbox,
  Link
} from 'rc'

import { Register } from 'reduxActions/auth'
import { GetRules } from 'reduxActions/freemium'
import { GetUserInfo } from 'reduxActions/profile'
import { AgencyAccountForm, BaseForm, BrokerAccountForm } from './SignUpForm'
import { TERMS_AND_CONDITIONS_VERSION } from 'utils/constants'
import eventGoogle from 'utils/eventsGoogle'

const SignUpForm = ({
  formStep,
  setFormStep,
  cancelFunction,
  tracker,
  AmplitudeEvents
}) => {
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [accountType, setAccountType] = useState('regular')
  const dispatch = useDispatch()

  const handleSubmit = values => {
    setIsLoading(true)
    const uncheckedFields =
      accountType === 'regular' ? 
      ['gender', 'birthdate', 'phone_number', 'postal_code', 'accept_terms', 'state', 'city']
        : [
            'agency_state',
            'agency_city',
            'agency_neighborhood',
            'agency_street',
            'agency_postal_code',
            'agency_exterior_number',
            'agency_state',
            'agency_city'
          ]
    const fields_with_errors = uncheckedFields.filter(
      field =>
        !values[field] ||
        (typeof values[field] === 'string' && values[field].trim().length === 0)
    )
    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }

    if (accountType === 'regular') {
      const phoneRegex = new RegExp(/^\+?\d{1,3}\d{6,}$/, 'i')
      if (!phoneRegex.test(values['phone_number'])) {
        const error = new Error({ message: 'Required fields' })
        error.code = 'VALIDATION_ERROR'
        error.metadata = ['phone_number']
        throw error
      }
    }

    const { confirm_password, user_type, ...formValues } = values
    return new Promise((resolve, reject) => {
      dispatch(
        Register({
          ...formValues,
          terms_and_conditions: TERMS_AND_CONDITIONS_VERSION,
          is_agency_account: user_type === 'agency'
        })
      )
        .then(res => {
          eventGoogle.eventsLogin.register()
          resolve(res)
        })
        .catch(err => reject(err))
    })
  }
  const handleSubmitSuccess = async response => {
    tracker(
      AmplitudeEvents.LOGIN_SIGN_UP_ACTION,
      'User submit sign up form success'
    )
    tracker(
      AmplitudeEvents.LOGIN_PROFILE_VIEW,
      'User view profile after sign up success'
    )

    const { payload: { data: { token: authorization } = {} } = {} } = response

    await dispatch(GetRules(authorization))
    await dispatch(GetUserInfo(authorization))

    const searchParams = new URLSearchParams(location.search);
    const callback = searchParams.get('callback');
    const allSearchParams = window.location.search ?? ''
    
    if (callback) return window.location.replace(
      `${process.env.REACT_APP_NEW_APLICATION_URL}/${callback}${allSearchParams}`
    )

    return window.location.replace(
      `${process.env.REACT_APP_NEW_APLICATION_URL}/home`
    )
  }
  const handleSubmitFail = (formErrors, fc, submitErrors) => {
    setIsLoading(false)
    if (!submitErrors) submitErrors = {}
    const {
      error: {
        response: { data: { error: { code, statusCode } = {} } = {} } = {}
      } = {},
      code: customCode
    } = submitErrors
    let errorMessage = ''
    switch (code || customCode) {
      case 'REGISTER_USER_ALREADY_EXISTS':
        if (statusCode === 422)
          errorMessage =
            'Este correo ya está registrado. Intenta con otro. Código de error: WIG-02'
        else
          errorMessage =
            'Este número de teléfono ya está registrado a una cuenta. Intenta con otro.'
        break
      case 'INTERNAL_SERVER_ERROR':
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'
        break
      case 'REQUIRED':
        if (!fc.values['accept_terms'])
          errorMessage = 'Se deben aceptar los términos y condiciones.'
        else errorMessage = 'Ninguno de los campos puede estar vacío'
        break
      case 'VALIDATION_ERROR':
        errorMessage = 'Formato de teléfono incorrecto.'
        break
      default:
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'
        break
    }
    tracker(
      AmplitudeEvents.LOGIN_SIGN_UP_ACTION,
      `User submit sign up form failed - Error: ${errorMessage}`
    )
    dispatch(ShowFlashNotification('danger', errorMessage))
  }

  const showErrorNotification = message => {
    dispatch(ShowFlashNotification('danger', message))
  }

  const currentDate = new Date()
  const formattedDate =
    currentDate.getFullYear() +
    '-' +
    String(currentDate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(currentDate.getDate()).padStart(2, '0')

  const initialValues = {
    user_type: 'regular',
    fullname: '',
    email: '',
    password: '',
    confirm_password: '',
    gender: '',
    birthdate: formattedDate,
    phone_number: '',
    state: '',
    city: '',
    postal_code: '',
    accept_terms: false,
    agency_name: '',
    agency_business_name: '',
    agency_tax_id: '',
    agency_email: '',
    agency_phone_number: '',
    agency_state: '',
    agency_city: '',
    agency_neighborhood: '',
    agency_street: '',
    agency_postal_code: '',
    agency_exterior_number: '',
    agency_interior_number: ''
  }

  return (
    <>
      {formStep === 2 ? (
        <Typography
          color="palette.new.text"
          css={`
            margin-bottom: ${({ theme }) => theme.remCalc('32')};
          `}
        >
          Por favor, ingresa tus datos <b>personales</b>
        </Typography>
      ) : formStep !== 1 ? (
        <Typography
          color="palette.new.text"
          css={`
            margin-bottom: ${({ theme }) => theme.remCalc('32')};
          `}
        >
          Por favor, ingresa los datos de la <b>inmobiliaria</b>
        </Typography>
      ) : (
        ''
      )}
      <FormContainerStyled
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={handleSubmitFail}
      >
        {formStep === 1 ? (
          <BaseForm
            accountType={accountType}
            setAccountType={setAccountType}
            formStep={formStep}
            setFormStep={setFormStep}
            showErrorNotification={showErrorNotification}
          />
        ) : accountType === 'regular' ? (
          <BrokerAccountForm isLoading={isLoading} />
        ) : (
          <AgencyAccountForm
            formStep={formStep}
            setFormStep={setFormStep}
            cancelFunction={cancelFunction}
            showErrorNotification={showErrorNotification}
            isLoading={isLoading}
          />
        )}
        {formStep === 2 && accountType === 'regular' && (
          <div
            css={`
              display: flex;
              flex: 1;
              justify-content: center;
              align-items: center;
            `}
          >
            <FooterActionsContainer>
              <FormControlLabel
                css={`
                  align-items: flex-start;
                  margin: 1rem 0;
                `}
                control={<Checkbox name="accept_terms" />}
                disabled={isLoading}
                label={
                  <Typography variant="caption4" fontWeight={500}>
                    Acepto los{' '}
                    <TermsLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href={process.env.REACT_APP_TERMS_URL}
                    >
                      términos y condiciones.
                    </TermsLink>{' '}
                    y{' '}
                    <Link
                      underline="always"
                      as={RouterLink}
                      target="_blank"
                      to="/legal/privacy"
                    >
                      políticas de privacidad
                    </Link>
                  </Typography>
                }
                labelPlacement="end"
              />
              <ButtonsContainer>
                <ButtonStyled
                  disabled={isLoading}
                  size="large"
                  variant="outlined"
                  onClick={() => setFormStep(formStep - 1)}
                >
                  Atrás
                </ButtonStyled>
                <SubmitButtonStyled size="large">
                  {isLoading ? (
                    <Spinner
                      css={`
                        height: 40px;
                      `}
                    />
                  ) : (
                    'Crear cuenta'
                  )}
                </SubmitButtonStyled>
              </ButtonsContainer>
              <TextlikeUrl
                onClick={() => (isLoading ? null : cancelFunction())}
              >
                Cancelar
              </TextlikeUrl>
            </FooterActionsContainer>
          </div>
        )}
      </FormContainerStyled>
    </>
  )
}

const FormContainerStyled = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FooterActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.remCalc('32')};
  > button {
    width: 48%;
  }
`
const SubmitButtonStyled = styled(SubmitButton)`
  border-radius: 50px !important;
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-color: ${({ theme }) => theme.palette.primary.light};
`
const ButtonStyled = styled(Button)`
  border-radius: 50px !important;
`
const TextlikeUrl = styled(Typography).attrs({
  variant: 'subtitle4',
  color: 'palette.primary.main',
  align: 'center'
})`
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`
const TermsLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
SignUpForm.propTypes = {
  AmplitudeEvents: PropTypes.object,
  cancelFunction: PropTypes.func,
  formStep: PropTypes.func,
  setFormStep: PropTypes.func,
  tracker: PropTypes.func
}

export default SignUpForm
