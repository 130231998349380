/* eslint-disable react/prop-types */
import React from 'react'
import styled, { css } from 'styled-components'
import { Typography, Button } from 'rc'
import { Cross, Photo } from 'rc/Icons'
import { RadioInput, CheckboxInput } from 'rc/FormComponents'
import { Image } from 'components'
import { string, func } from 'prop-types'

export const SectionContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.remCalc('950')};
  background: ${({ theme }) => theme.palette.base.white};
  box-shadow: 0px 4px 20px rgba(125, 133, 154, 0.25);
  padding: ${({ theme, noPadding }) => !noPadding && theme.remCalc('15')};
  margin-bottom: ${({ theme }) => theme.remCalc('20')};
  ${({ theme }) => theme.media.tablet`
    ${({ theme }) => theme.radius(10)}
    padding: ${({ theme, noPadding }) =>
      theme.remCalc(noPadding ? '20' : '8 38 50')};
    margin-bottom: ${({ theme }) => theme.remCalc('25')};
`}
`

const desktopSectionContentStyle = css`
  flex-wrap: nowrap;
  > div {
    flex: 1;
    width: auto;
  }
  > div:first-child {
    margin-right: ${({ theme }) => theme.remCalc('5')};
  }
  > div:last-child {
    margin-left: ${({ theme }) => theme.remCalc('5')};
  }
`

export const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > div {
    width: 100%;
  }
  ${({ theme }) => theme.media.desktop`
    ${desktopSectionContentStyle}
  `}
`

export const SectionTitle = styled(Typography).attrs({
  variant: 'subtitle2',
  component: 'h3',
  color: 'palette.primary.main',
  display: 'block'
})`
  margin-top: ${({ theme }) => theme.remCalc('10')};
  margin-bottom: ${({ theme }) => theme.remCalc('20')};
`
export const FileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: dashed 0.0625rem rgb(163, 170, 180);
  border-radius: 0.375rem;
  min-height: 124px;
  margin-top: 0.9375rem;
  background-color: rgb(246, 246, 247);
  margin-bottom: ${({ theme }) => theme.remCalc('10')};
  ${({ cover, theme }) =>
    cover &&
    `
  overflow: hidden;
  height: ${theme.remCalc('250')};
  `}
`

export const AltFileContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  border: dashed 0.0625rem rgb(163, 170, 180);
  border-radius: ${({ theme }) => theme.remCalc('5')};  
  background-color: #F5F7FD;
  justify-content: center;
  ${({ cover, theme }) => cover && `overflow: hidden; height: ${theme.remCalc('250')};`}
`

export const FilesContainer = styled.div.attrs({
  children: (
    <>
      <Photo />
      <Typography variant="body3" color="palette.new.text">
        No hay imagen adjunta.
      </Typography>
    </>
  )
})`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: dashed 0.0625rem rgb(163, 170, 180);
  border-radius: 0.375rem;
  min-height: ${({ theme }) => theme.remCalc('120')};
  min-width: ${({ theme }) => theme.remCalc('170')};
  margin: ${({ theme }) => theme.remCalc('10')};
  background-color: rgb(246, 246, 247);
  svg {
    width: ${({ theme }) => theme.box(20, 20)};
  }
`

export const GroupContainer = styled.div`
  display: flex;
  .group-input,
  .range-input {
    flex: 1;
  }
  .group-input {
    &:nth-child(2) {
      margin-left: ${({ theme }) => theme.remCalc('10')};
    }
  }
  .range-input {
    &:nth-child(4) {
      margin-left: ${({ theme }) => theme.remCalc('10')};
    }
  }
`

export const CardRangeContainer = styled.div`
  padding: ${({ theme }) => theme.remCalc('9 15 17')};
  box-shadow: 0px 4px 20px rgba(125, 133, 154, 0.25);
  margin-bottom: ${({ theme }) => theme.remCalc('10')};
  ${({ theme }) => theme.radius(10)}
`

export const RadioInputStyled = styled(RadioInput)`
  font-weight: bold;
`

export const CheckboxInputStyled = styled(CheckboxInput)`
  font-weight: bold;
`

export const CrossButton = styled(Button).attrs({
  color: 'primary',
  variant: 'standard',
  radius: '50%',
  children: <Cross />
})`
  padding: 0;
  z-index: 1;
  min-height: auto;
  svg {
    ${({ theme }) => theme.box(18, 18)}
  }
`

const Container = styled.div`
  position: relative;
  width: ${({ theme, cover }) => (cover ? '100%' : theme.remCalc('170'))};
  height: ${({ theme, cover }) =>
    cover ? theme.remCalc('250') : theme.remCalc('120')};
  margin: ${({ theme, cover }) => !cover && theme.remCalc('10')};
  ${({ cover }) => cover && 'overflow: hidden;'}
  ${CrossButton} {
    ${({ theme }) => theme.position('absolute', 10, 10)}
  }
`

export function ImageElement({
  className,
  handleDelete,
  preview,
  value,
  cover
}) {
  return (
    <Container className={className} cover={cover}>
      <CrossButton onClick={handleDelete} />
      {preview ? (
        <img
          src={preview}
          alt="Preview"
          cover={cover}
          css={`
            max-height: ${({ cover }) => (cover ? 'auto' : '100%')};
            object-fit: ${({ cover }) => (cover ? 'cover' : 'contain')};
          `}
        />
      ) : (
        <Image id={value} objectFit={cover ? 'cover' : 'contain'} />
      )}
    </Container>
  )
}

ImageElement.propTypes = {
  value: string,
  handleDelete: func,
  className: string
}

export const ModelFormSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.remCalc('950')};
  background: ${({ theme }) => theme.palette.base.white};
  box-shadow: 0px 4px 20px rgba(125, 133, 154, 0.25);
  padding: ${({ theme }) => theme.remCalc('30 20')};
  margin-bottom: ${({ theme }) => theme.remCalc('30')};
  ${({ theme }) => theme.radius(20)}
`
export const AmenitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  margin-top: ${({ theme }) => theme.remCalc('32')};
  > div {
    width: 50%;
    margin-bottom: ${({ theme }) => theme.remCalc('16')};
    font-size: ${({ theme }) => theme.remCalc('16')};
    font-weight: 500;
  }
  > div:nth-child(even) {
    padding: ${({ theme }) => theme.remCalc('0 20')};
  }
`
export const ModelFormStepContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ActiveInputTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.remCalc('30')};
`

export const MaskedInputContainerStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.remCalc('60')};
  > * {
    margin-top: 0px !important;
    width: ${({ fullWidth }) => (fullWidth ? '48%' : '100%')};
    > div > p {
      visibility: initial !important;
      font-size: ${({ theme }) => theme.remCalc('10')};
    }
  }
`
