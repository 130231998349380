/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Button } from 'rc'

import OnBoardingMC, { FLOW } from './OnBoardingMC'

import Background from 'static/prime/prime-modal.svg'
import WhiteLogo from 'static/logos/wiggot-prime-white.svg'
import AgendaPrime from 'static/drawings/agenda-prime.svg'
import DatasheetsPrime from 'static/drawings/datasheets-prime.svg'
import HTTPSPrime from 'static/drawings/https-prime.svg'
import OfferingsPrime from 'static/drawings/offerings-prime.svg'
import ReportsPrime from 'static/drawings/reports-prime.svg'
import SuggestionsPrime from 'static/drawings/suggestions-prime.svg'

const WiggotPrimeLogo = styled.img.attrs({
  src: WhiteLogo,
  alt: 'Wiggot Prime'
})`
  margin-left: ${({ theme }) => theme.remCalc('10')};
`

const Title = styled.h2`
  color: white;
  display: inline;
`

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  h4 {
    text-align: center;
    color: white;
  }
`

const CounterContainer = styled.div`
  background: #ffffff;
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.remCalc('10')};
  font-size: ${({ theme }) => theme.remCalc('25')};
  p {
    margin: 0;
  }
`

const mobileContainerStyle = css`
  padding: ${({ theme }) => theme.remCalc('10 20')};
  ${Title} {
    display: none;
  }
  ${InfoContainer} {
    flex-direction: column;
    align-items: center;
  }
  ${CounterContainer} {
    padding: ${({ theme }) => theme.remCalc('10')};
  }
`

const desktopContainerStyle = css`
  width: ${({ theme }) => theme.remCalc('600')};
  padding: ${({ theme }) => theme.remCalc('30 40')};
  ${InfoContainer} {
    justify-content: space-between;
    align-items: flex-end;
    margin-top: ${({ theme }) => theme.remCalc('28')};
    margin-bottom: ${({ theme }) => theme.remCalc('35')};
  }
  ${CounterContainer} {
    padding: ${({ theme }) => theme.remCalc('20 16')};
  }
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.media.desktopDown`${mobileContainerStyle}`}
  ${({ theme }) => theme.media.desktop`${desktopContainerStyle}`}
  > img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
  }
`

const Description = styled.p`
  color: #0076cc;
  font-weight: bold;
  text-align: center;
  font-size: ${({ theme }) => theme.remCalc('21')};
  line-height: ${({ theme }) => theme.remCalc('26')};
  ~ a {
    margin-bottom: ${({ theme }) => theme.remCalc('18')};
    text-align: center;
  }
`

const ButtonStyled = styled(Button)`
  max-width: ${({ theme }) => theme.remCalc('250')};
  height: ${({ theme }) => theme.remCalc('50')};
  width: 100%;
`

const TrialReminderMC = props => {
  const {
    type,
    action,
    className,
    HideModal,
    primeAvailableUntil,
    ShowModal
  } = props

  const getTemplateInfo = () => {
    switch (type) {
      case 'AGENDA':
        return {
          drawing: AgendaPrime,
          notHideModal: true,
          description: 'Agenda tareas para tus clientes',
          label: 'Crear recordatorio'
        }
      case 'DATASHEETS':
        return {
          drawing: DatasheetsPrime,
          description: 'Edita fichas técnicas de otros asesores',
          label: 'Editar ficha'
        }
      case 'HTTPS':
        return {
          drawing: HTTPSPrime,
          description: 'Activa tu certificado SSL en tu sitio web',
          label: 'Activar conexión segura'
        }
      case 'OFFERINGS':
        return {
          drawing: OfferingsPrime,
          notHideModal: true,
          description: 'Ofrece tus propiedades a otros asesores interesados',
          label: 'Ofrecer propiedad'
        }
      case 'REPORTS':
        return {
          drawing: ReportsPrime,
          description: 'Descarga reportes de tu inmobiliaria',
          label: 'Descargar reporte'
        }
      case 'SUGGESTIONS':
        return {
          drawing: SuggestionsPrime,
          description: 'Activa matches en tus requerimientos',
          label: 'Activar matches'
        }
      default:
        return {}
    }
  }

  const { drawing, description, label, notHideModal } = getTemplateInfo()

  const actionHandler = () => {
    action()
    !notHideModal && setTimeout(HideModal, 10)
  }

  const openOnBoarding = () =>
    ShowModal(OnBoardingMC, {
      flow: FLOW.REMINDER,
      action: () =>
        ShowModal(TrialReminderMC, {
          type,
          action,
          className,
          primeAvailableUntil
        })
    })

  const startDate = new Date()
  const endDate = new Date(primeAvailableUntil)
  let dayCount = 0

  while (endDate > startDate) {
    dayCount++
    startDate.setDate(startDate.getDate() + 1)
  }

  return (
    <Container className={className}>
      <img src={Background} alt="Wiggot-Prime" />
      <div>
        <Title>Esta es una herramienta</Title>
        <WiggotPrimeLogo />
      </div>
      <InfoContainer>
        <div>
          <h4>Cuenta de prueba prime</h4>
          <CounterContainer>
            <p>
              Te quedan:{' '}
              <b> {dayCount !== 1 ? `${dayCount} DÍAS` : `${dayCount} DÍA`}</b>
            </p>
          </CounterContainer>
        </div>
        <img src={drawing} alt="Drawing" />
      </InfoContainer>
      <Description>{description}</Description>
      <a onClick={openOnBoarding}>Conoce todas las herramientas prime</a>
      <ButtonStyled onClick={actionHandler}>{label}</ButtonStyled>
    </Container>
  )
}

TrialReminderMC.propTypes = {
  HideModal: PropTypes.func,
  ShowModal: PropTypes.func,
  action: PropTypes.func,
  className: PropTypes.string,
  primeAvailableUntil: PropTypes.string,
  type: PropTypes.string
}

export default TrialReminderMC
