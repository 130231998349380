import { createReducer } from '../store'

const generalReducer = createReducer(
  {
    localVersion: process.env.REACT_APP_VERSION,
    serverVersion: process.env.REACT_APP_VERSION,
    amenitiesById: {},
    amenities: [],
    developmentAmenitiesById: {},
    developmentAmenities: [],
    isSelectPropertyDevelop: null
  },
  {
    SET_SERVER_VERSION: (state, action) => {
      const {
        payload: { serverVersion }
      } = action

      return { ...state, serverVersion }
    },
    GET_AMENITIES_SUCCESS: (state, action) => {
      const {
        payload: { data: amenitiesById }
      } = action

      let amenities = []

      if (!amenitiesById.error) {
        amenities = Object.keys(amenitiesById).map(key => {
          return {
            label: amenitiesById[key],
            value: key
          }
        })
      }

      return {
        ...state,
        isLoadingAmenities: false,
        amenities,
        amenitiesById
      }
    },
    GET_DEVELOPMENT_AMENITIES_SUCCESS: (state, action) => {
      const {
        payload: { data: developmentAmenitiesById }
      } = action

      let developmentAmenities = Object.keys(developmentAmenitiesById).map(
        key => {
          return {
            label: developmentAmenitiesById[key],
            value: key
          }
        }
      )
      return {
        ...state,
        isLoadingDevelopmentAmenities: false,
        developmentAmenities,
        developmentAmenitiesById
      }
    },
    SET_IS_SELECTED_PROPERTY_DEVELOP: (state, action) => {
      return { ...state, isSelectPropertyDevelop: action.payload }
    },
  }
)

export default generalReducer
