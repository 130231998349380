import React from 'react'
import { connect } from 'react-redux'
import { Spinner } from 'rc'
import { LogOut } from 'reduxActions/auth'
import { SendError } from 'reduxActions/general'

class ErrorBoundary extends React.Component {
  shouldRender = () => {
    const { userId, roles, token } = this.props

    if (this.props.shouldCheck && token) {
      if (roles.ASSOCIATION) return true

      return roles.BASIC && userId
    }
    return true
  }

  state = { hasError: false }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (error) {
      if (process.env.REACT_APP_ENV === 'PROD' && this.props.token) {
        if (error.name === 'ChunkLoadError' && error.type === 'missing') {
          window.location.replace(window.location.href)
        }
      }
    }
  }

  render() {
    return this.shouldRender() ? (
      this.props.children
    ) : (
      <div
        css={`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
        `}
      >
        <Spinner />
      </div>
    )
  }
}

const mapStateToProps = ({
  authReducer: { token, roles },
  profileReducer: { userId, profileId, agencyId }
}) => ({ token, roles, userId, profileId, agencyId })

const mapDispatchToProps = {
  LogOut,
  SendError
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
