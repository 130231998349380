import { createTheme } from 'rc'
import WiggotNew from 'static/logos/wiggot-new.svg'
import HomeSearcherBackground from 'static/img/home-searcher.jpg'

const WiggotTheme = roles => {
  const logo = (() => {
    const type = roles.PRIME
      ? roles.PASS && roles.PRO
        ? 'FULL'
        : roles.PASS
        ? 'PASS'
        : roles.PRO
        ? 'PRO'
        : 'PRIME'
      : 'DEFAULT'
    switch (type) {
      case 'FULL':
        return WiggotNew
      case 'PRO':
        return WiggotNew
      case 'PASS':
        return WiggotNew
      case 'PRIME':
        return WiggotNew
      case 'DEFAULT':
      default:
        return WiggotNew
    }
  })()

  return createTheme('Wiggot Theme', {
    typography: {
      fontFamily: '"Muli", "Helvetica Neue", "Arial", sans-serif',
      fontWeightMedium: 600
    },
    palette: {
      primary: {
        main: '#222',
        light: '#0038FF',
        dark: '#222',
        text: '#fff'
      },
      secondary: {
        main: '#fff',
        light: '#fff',
        dark: '#fff',
        text: '#222'
      },
      prime: {
        light: '#000000b3',
        main: '#363946',
        dark: '#000000d4',
        text: 'white',
        yellow: '#ffc834'
      },
      elite: {
        light: '#937851',
        main: '#937851',
        dark: '#614d31',
        text: 'white',
        yellow: '#ffc834'
      },
      black: {
        light: '#f6f6f7',
        main: '#363636',
        dark: '#90A4AE',
        text: '#fff'
      },
      white: {
        light: '#efefef',
        main: '#ffffff',
        dark: '#efefef',
        text: '#363636'
      },
      pastel: {
        danger: '#FD9494',
        success: '#80DDC3',
        yellow: '#FFE399',
        blue: '#80BBFF'
      },
      colors: {
        red: '#FD7171',
        yellow: '#FFBC08',
        green: '#00BA88',
        blue: '#0076FF'
      },
      new: {
        text: '#222'
      },
      tag: {
        successBackground: '#34c47c',
        successText: '#fff',
        warningBackground: '#ee9708',
        warningText: '#fff',
        dangerBackground: '#ff5a60',
        dangerText: '#fff',
        grayBackground: '#97a4b1',
        grayText: '#222',
        primaryBackground: '#fff',
        primaryText: '#222',
        secondaryBackground: '#222',
        secondaryText: '#fff'
      }
    },
    logo,
    isExt: false,
    navigationBarHeight: 70,
    unverifiedBannerHeight: 45,
    navigationBarMobileHeight: 50,
    unverifiedBannerMobileHeight: 45,
    backgroundImage: HomeSearcherBackground
  })
}

export default WiggotTheme
