import React from 'react'
import PropTypes from 'prop-types'
import DropdownLib, { DropdownAction, DropdownMenu } from 'rc/DropDown'
import { Dots3 } from 'rc/Icons'
import { Grid, Typography } from 'rc'
const Dropdown = ({ actions, dropdownActionProps, className, noShadow, ...props }) => {
  return (
    <DropdownLib className={className} {...props}>
      <DropdownAction
        noShadow={noShadow}
        css={`
          cursor: pointer;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.palette.base.white};
          box-shadow: ${({ noShadow }) => noShadow ? 'none' : 'rgba(0, 0, 0, 0.3) 2px 0 7px;'};
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          &:hover {
            svg {
              fill: ${({ theme }) => theme.palette.base.white};
            }
            background-color: ${({ theme }) => theme.palette.primary.main};
          }
        `}
        {...dropdownActionProps}
      >
        <Dots3 />
      </DropdownAction>

      <DropdownMenu
        css={`
          width: max-content;
          right: 0;
          border-radius: 10px;
          > *:not(:last-child) { border-bottom: 1px solid #E8ECF9; }
          & > * {
            cursor: pointer;
            padding: 8px 15px;
            svg {
              margin-right: 10px;
            }
            &:hover { 
              border-radius: 10px;
              background-color: ${({ theme }) => theme.palette.gray.light}; 
            }
          }
        `}
      >
        {actions.map(({ icon: Icon, label, onClick }, index) => {
          return (
            <Grid
              key={index}
              item
              flat
              container
              alignItems="center"
              onClick={onClick}
              css={`
                svg{
                  ${({ theme }) => theme.box(16, 16)}
                  color: ${({ theme }) => theme.palette.gray.main}; 
                  fill: ${({ theme }) => theme.palette.gray.main};
                }
              `}
            >
              <Icon></Icon>
              <Typography variant="subtitle3" color="theme.palette.gray.main" css={`font-weight: 400;`}>
                {label}
              </Typography>
            </Grid>
          )
        })}
      </DropdownMenu>
    </DropdownLib>
  )
}

Dropdown.defaultProps = {
  actions: [],
  dropdownActionProps: {}
}
Dropdown.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      label: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  dropdownActionProps: PropTypes.object,
  className: PropTypes.string,
  noShadow: PropTypes.bool
}

export default Dropdown
