import { store } from 'reduxFold/store'

/**
 * Saves a property as Draft or updates it
 * @param {string} Authorization - current user token
 * @param {string} [propertyId] - in case you want to update the property
 * @param {string} data
 * @param {string} data.property_type
 * @param {boolean} data.use_residential
 * @param {boolean} data.use_comercial
 * @param {boolean} data.use_tourist
 * @param {number} data.ground_size
 * @param {number} data.construction_size
 * @param {number} data.width
 * @param {number} data.length
 * @param {string} data.ground_shape
 * @param {string} data.ground_relief
 * @param {string} data.country
 * @param {string} data.state
 * @param {string} data.city
 * @param {string} data.neighborhood
 * @param {string} data.street
 * @param {number} data.external_number
 * @param {number} data.internal_number
 * @param {number} data.postal_code
 * @param {string} data.address_reference
 * @param {number} data.latitude
 * @param {number} data.longitude
 * @param {string} data.antiquity
 * @param {number} data.antiquity_years
 * @param {number} data.delivery_month
 * @param {number} data.delivery_year
 * @param {boolean} data.inside_condo
 * @param {number} data.floors
 * @param {number} data.bedrooms
 * @param {number} data.bathrooms
 * @param {number} data.half_bathrooms
 * @param {number} data.parking_lots
 * @param {boolean} data.covered_parking
 * @param {boolean} data.has_service_room
 * @param {string} data.preservation
 * @param {string} data.description
 * @param {string} data.youtube_url
 * @param {string} data.matterport_url
 * @param {boolean} data.presale_active
 * @param {boolean} data.sale_active
 * @param {boolean} data.lease_active
 * @param {boolean} data.unencumbered_property
 * @param {string} data.legal_status
 * @param {string[]} data.amenities
 * @param {number} data.lease_price
 * @param {string} data.lease_currency
 * @param {number} data.lease_maintenance_price
 * @param {string} data.lease_maintenance_currency
 * @param {number} data.lease_deposit_months
 * @param {number} data.lease_anticipated_rents
 * @param {number} data.lease_forced_contract_months
 * @param {boolean} data.lease_endorsement
 * @param {number} data.lease_research
 * @param {string} data.lease_restrictions
 * @param {number} data.lease_commission
 * @param {boolean} data.lease_share_commission
 * @param {number} data.lease_shared_commission
 * @param {number} data.sale_price
 * @param {string} data.sale_currency
 * @param {number} data.sale_maintenance_price
 * @param {string} data.sale_maintenance_currency
 * @param {boolean} data.sale_cash_payment
 * @param {boolean} data.sale_credit_payment
 * @param {string} data.sale_credit_details
 * @param {number} data.sale_commission
 * @param {boolean} data.sale_share_commission
 * @param {number} data.sale_shared_commission
 * @param {number} data.presale_price
 * @param {string} data.presale_currency
 * @param {number} data.presale_maintenance_price
 * @param {string} data.presale_maintenance_currency
 * @param {boolean} data.presale_cash_payment
 * @param {boolean} data.presale_credit_payment
 * @param {string} data.presale_credit_details
 * @param {number} data.presale_commission
 * @param {boolean} data.presale_share_commission
 * @param {number} data.presale_shared_commission
 */
export const SaveProperty = (Authorization, propertyId, data) => ({
  type: 'SAVE_PROPERTY',
  payload: {
    request: {
      url: propertyId ? `/property/${propertyId}` : '/property',
      method: propertyId ? 'patch' : 'post',
      headers: { Authorization },
      data
    }
  }
})

export const GetPropertyHints = (Authorization, data) => ({
  type: 'GET_PROPERTY_HINTS',
  payload: {
    request: {
      url: '/property/validate',
      method: 'post',
      headers: { Authorization },
      data
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} id
 * @param {*} platformIds
 */
export const PublishProperty = (Authorization, id, data) => ({
  type: 'PUBLISH_PROPERTY',
  payload: {
    request: {
      url: `/property/${id}/publish`,
      headers: { Authorization },
      method: 'post',
      data
    }
  }
})

export const PublishProperties = (Authorization, ids, portals_data) => ({
  type: 'PUBLISH_PROPERTIES',
  payload: {
    request: {
      url: `/properties/publish`,
      headers: { Authorization },
      method: 'post',
      data: {
        ...portals_data,
        properties_id: ids
      }
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} id
 * @param {*} platformIds
 */
export const UpdateProperty = (Authorization, id, data) => ({
  type: 'UPDATE_PROPERTY',
  payload: {
    request: {
      url: `/property/${id}/update`,
      headers: { Authorization },
      method: 'post',
      data
    }
  }
})

/**
 *
 * @param {*} Authorization
 * @param {*} id
 * @param {*} platformIds
 */
export const SendToAgencyReview = (Authorization, id, data) => ({
  type: 'SEND_TO_AGENCY_REVIEW',
  payload: {
    request: {
      url: `/property/${id}/review`,
      headers: { Authorization },
      method: 'post',
      data
    }
  }
})

/**
 * Get Property details
 * @param {string} Authorization - current user token
 * @param {string} propertyId
 * @param {string} [type=''] - Could be view if empty will retrive all the info of a property
 * @param {string} searchFrom SEARCH_RESUME | SEARCH_DETAIL | PROPERTIES
 */
export const GetProperty = (
  Authorization,
  propertyId,
  type = '',
  searchFrom = '',
  showGenericNeighborhood = false
) => {
  let propertyTypeDetails = ''
  switch (type) {
    case 'view':
      propertyTypeDetails = '/view'
      break
    default:
      break
  }

  const params = {}

  if (searchFrom) params.search_from = searchFrom
  if (showGenericNeighborhood) params.show_generic_neigborhood = true

  return {
    type: 'GET_PROPERTY_DETAILS',
    payload: {
      request: {
        url: `/property/${propertyId}${propertyTypeDetails}`,
        method: 'get',
        headers: { Authorization },
        params
      }
    }
  }
}

/**
 * Gets all properties belonging to user
 * @param {string} Authorization - current user token
 * @param {number} [page=0] - page to download users
 * @param {number} [limit=10] - items to download per page
 */
export const GetMyProperties = (
  Authorization,
  page = 0,
  limit = 20,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'GET_MY_PROPERTIES',
  payload: {
    request: {
      method: 'get',
      url: `/properties`,
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 * Gets all properties belonging to user
 * @param {string} Authorization - current user token
 * @param {number} [page=0] - page to download users
 * @param {number} [limit=10] - items to download per page
 */
export const GetPropertiesList = (
  Authorization,
  page = 0,
  limit = 10,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'GET_MY_PROPERTIES',
  payload: {
    request: {
      method: 'get',
      url: `/properties/list`,
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 * Touch properties by passing multiple properties ids
 * @param {string} Authorization - current user token
 * @param {string[]} propertyIds - properties identifiers
 */
export const TouchProperties = (Authorization, propertyIds = []) => ({
  type: 'TOUCH_PROPERTIES',
  payload: {
    request: {
      url: '/properties/touch',
      headers: { Authorization },
      method: 'put',
      data: {
        property_ids: propertyIds
      }
    }
  }
})

/**
 * Delete properties by passing multiple properties ids
 * @param {string} Authorization - current user token
 * @param {string[]} propertyIds - properties identifiers
 */
export const UnpublishProperties = (Authorization, propertyIds, data) => ({
  type: 'UNPUBLISH_PROPERTIES',
  payload: {
    request: {
      url: '/properties/unpublish',
      headers: { Authorization },
      method: 'post',
      data: {
        ...data,
        property_ids: propertyIds
      }
    }
  }
})

/**
 * Delete properties by passing multiple properties ids
 * @param {string} Authorization - current user token
 * @param {string[]} propertyIds - properties identifiers
 */
export const DeleteProperties = (Authorization, propertyIds, data) => ({
  type: 'DELETE_PROPERTIES',
  payload: {
    request: {
      url: '/properties/delete',
      headers: { Authorization },
      method: 'post',
      data: {
        ...data,
        property_ids: propertyIds
      }
    }
  }
})

/**
 * Gets similar properties
 * @param {string} Authorization - current user token
 * @param {string} propertyId - property id
 * @param {number} [limit=10] - max items to show
 */
export const similarProperties = (Authorization, propertyId, limit = 10) => ({
  type: 'SIMILAR_PROPERTIES',
  payload: {
    request: {
      method: 'get',
      url: `/property/${propertyId}/similars`,
      headers: {
        Authorization
      },
      params: {
        l: limit
      }
    }
  }
})

export const SetQueryString = queryString => ({
  type: 'SET_QUERY_STRING',
  queryString
})

/**
 * Gets all properties belonging to user
 * @param {string} Authorization - current user token
 * @param {number} [page=0] - page to download users
 * @param {number} [limit=10] - items to download per page
 */
export const GetAgencyProperties = (
  Authorization,
  page = 0,
  limit = 10,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'GET_AGENCY_PROPERTIES',
  payload: {
    request: {
      method: 'get',
      url: '/agency/properties',
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 * Gets all pending properties belonging to user within agency
 * @param {string} Authorization - current user token
 * @param {number} [page=0] - page to download users
 * @param {number} [limit=10] - items to download per page
 */
export const GetAgencyPendingProperties = (
  Authorization,
  page = 0,
  limit = 10
) => ({
  type: 'GET_AGENCY_PENDING_PROPERTIES',
  payload: {
    request: {
      method: 'get',
      url: '/agency/pending_properties',
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit
      }
    }
  }
})

/**
 * Unpublish properties by passing multiple portal ids
 * @param {string} Authorization - current user token
 * @param {Object} data
 * @param {Object} data.portal_ids
 * @param {Object} data.unpublish_reason
 * @param {Object} data.other_reason
 */

export const UnpublishPropertyFromPortals = (
  Authorization,
  propertyId,
  data
) => ({
  type: 'UNPUBLISH_PROPERTY_FROM_PORTALS',
  payload: {
    request: {
      url: `/property/${propertyId}/unpublish`,
      headers: { Authorization },
      method: 'post',
      data
    }
  }
})

export const GetPropertyAssociations = (Authorization, propertyId) => ({
  type: 'GET_PROPERTY_ASSOCIATIONS',
  payload: {
    request: {
      url: '/property/my/associations',
      headers: { Authorization },
      params: {
        property_id: propertyId
      },
      method: 'get'
    }
  }
})

export const UpdatePropertyCommission = (Authorization, propertyId, data) => ({
  type: 'UPDATE_PROPERTY_COMMISSION',
  payload: {
    request: {
      url: `/property/${propertyId}/commission`,
      headers: { Authorization },
      method: 'put',
      data
    }
  }
})

export const OfferProperty = (Authorization, propertyId, data) => ({
  type: 'OFFER_PROPERTY',
  payload: {
    request: {
      url: `/property/${propertyId}/offer`,
      headers: { Authorization },
      method: 'post',
      data
    }
  }
})

export const PropertyRemovedReason = (Authorization, data) => ({
  type: 'PROPERTY_REMOVED_REASON',
  payload: {
    request: {
      url: `/properties/removed_reason`,
      headers: { Authorization },
      method: 'post',
      data
    }
  }
})

export const PropertyPublishedInPortals = (Authorization, propertyId) => ({
  type: 'PROPERTY_PUBLISHED_IN_PORTALS',
  payload: {
    request: {
      url: `/property/${propertyId}/published_in_portals`,
      headers: { Authorization },
      method: 'get'
    }
  }
})

export const PropertyInvertRequirement = (Authorization, propertyId) => ({
  type: 'PROPERTY_INVERT_REQUIREMENT',
  payload: {
    request: {
      url: `/property/invert_requirement/${propertyId}`,
      headers: { Authorization },
      method: 'get'
    }
  }
})

export const PropertyRejectDeleteReview = (Authorization, data) => ({
  type: 'REJECT_DELETE_REVIEW',
  payload: {
    request: {
      url: `/properties/reject_delete_review`,
      headers: { Authorization },
      method: 'post',
      data
    }
  }
})

export const GetPropertyRemovedReason = (Authorization, propertyId) => ({
  type: 'GET_PROPERTY_REMOVED_REASON',
  payload: {
    request: {
      url: `/property/removed_reason/${propertyId}`,
      headers: { Authorization },
      method: 'get'
    }
  }
})

export const ReportProperty = (Authorization, propertyId, report) => ({
  type: 'REPORT_PROPERTY',
  payload: {
    request: {
      url: `/property/${propertyId}/report`,
      headers: { Authorization },
      method: 'post',
      data: {
        ...report
      }
    }
  }
})

export const GetPropertyReports = (Authorization, propertyId) => ({
  type: 'GET_PROPERTY_REPORTS',
  payload: {
    request: {
      url: `/property/${propertyId}/reports`,
      headers: { Authorization },
      method: 'get'
    }
  }
})

export const ResolvePropertyReport = (Authorization, propertyId, data) => ({
  type: 'RESOLVE_PROPERTY_REPORT',
  payload: {
    request: {
      url: `/property/${propertyId}/resolve_report`,
      headers: { Authorization },
      method: 'post',
      data
    }
  }
})

export const GetPropertiesPublishedInPortals = Authorization => ({
  type: 'GET_PROPERTIES_PUBLISHED_IN_PORTALS',
  payload: {
    request: {
      url: `/properties/published_in_portals`,
      headers: { Authorization },
      method: 'get'
    }
  }
})

export const GetCityPublished = Authorization => ({
  type: 'GET_CITY_PUBLISHED',
  payload: {
    request: {
      url: `/properties/property_cities_list`,
      headers: { Authorization },
      method: 'get'
    }
  }
})

export const SetCurrentIndexProperty = index => {
  const { dispatch } = store
  dispatch({
    type: 'SET_CURRENT_INDEX_PROPERTY',
    payload: {
      index
    }
  })
}

export const ResetCurrentIndexProperty = () => ({
  type: 'RESET_CURRENT_INDEX_PROPERTY'
})

export const RecoverDeletedProperty = (Authorization, propertyId) => ({
  type: 'RECOVER_DELETED_PROPERTY',
  payload: {
    request: {
      url: `/property/${propertyId}/recover`,
      headers: { Authorization },
      method: 'put'
    }
  }
})

export const PropertyPerformance = (Authorization, propertyId) => ({
  type: 'PROPERTY_PERFORMANCE',
  payload: {
    request: {
      url: `/property/${propertyId}/performance`,
      headers: { Authorization },
      method: 'get'
    }
  }
})

export const UserPortalCredentials = Authorization => ({
  type: 'USER_PORTAL_CREDENTIALS',
  payload: {
    request: {
      url: '/portals/light-credentials',
      headers: { Authorization },
      method: 'get'
    }
  }
})

export const UnpublishPropertiesFromPortals = (
  Authorization,
  ids,
  portals,
  associations
) => ({
  type: 'UNPUBLISH_PROPERTIES_FROM_PORTALS',
  payload: {
    request: {
      url: '/properties/unpublish_from_portals',
      headers: { Authorization },
      method: 'post',
      data: {
        property_ids: ids,
        portal_ids: portals,
        associations
      }
    }
  }
})

export const GetPropertiesInPortalsCount = Authorization => ({
  type: 'GET_PROPERTIES_IN_PORTALS_COUNT',
  payload: {
    request: {
      url: '/properties/metrics/published_in_portals',
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const PropertiesContactMe = (Authorization, propertyId) => ({
  type: 'PROPERTIES_CONTACT_ME',
  payload: {
    request: {
      url: '/leads/wiggot_lead',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        property_id: propertyId
      }
    }
  }
})
