import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M.257 0H148.04a1 1 0 0 1 .694 1.72l-11.873 11.465a1 1 0 0 0-.046 1.391l12.18 13.407a1 1 0 0 1-.74 1.672h-148V0z"
      />
      <path
        fill="#FFF"
        d="M28.98 16.306c.003.776-.636 1.407-1.426 1.41-.79.002-1.433-.625-1.435-1.401-.003-.776.636-1.407 1.426-1.41.79-.002 1.433.625 1.435 1.401"
      />
      <text
        fill="#FFF"
        fontFamily="Muli', 'Helvetica Neue', 'Arial', sans-serif"
        fontSize="14"
        fontWeight="600"
        transform="translate(.249)"
      >
        <tspan x="53.639" y="20.31">
          wiggot
        </tspan>
      </text>
      <path fill="#114577" d="M.249 29.647l14.443 12.806-.236-12.83z" />
      <path
        fill="#FEFEFE"
        d="M19.083 17.317l7.44-7.454 7.574 7.499.01 3.477-15.013.046-.01-3.568zm17.147-.911c-.029-.035-.042-.077-.075-.11L27.446 7.84c-.023-.026-.032-.06-.058-.085a1.226 1.226 0 0 0-.875-.341 1.225 1.225 0 0 0-.874.346c-.026.026-.035.059-.057.086l-8.657 8.508a1.162 1.162 0 0 0-.344.88l-.002.01.016 5.031c.002.584.493 1.06 1.091 1.06l.298-.002h.132l16.981-.05.302-.002h.129c.598-.002 1.086-.481 1.084-1.066l-.016-5.03a1.04 1.04 0 0 0-.366-.78z"
      />
      <g transform="translate(15 6)">
        <ellipse
          cx="21.5"
          cy="11.842"
          fill="#FFF"
          stroke="currentColor"
          strokeWidth="2"
          rx="6.5"
          ry="6.158"
        />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M20.897 14.195a.406.406 0 0 1-.396-.1l-1.386-1.406a.413.413 0 0 1 0-.57l.316-.32a.407.407 0 0 1 .568 0l.844.856 2.091-2.11a.432.432 0 0 1 .606 0l.338.342a.446.446 0 0 1 0 .614l-2.562 2.586a.431.431 0 0 1-.419.108z"
        />
      </g>
    </g>
  </Fragment>,
  'WiggotBookMark',
  '0 0 150 43'
)
