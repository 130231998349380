import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Image } from 'c3-ui/generics/Images'
import { Button } from 'c3-ui/generics/Buttons'
import { Layout } from 'c3-ui/elements/Layouts'
import { Title, Text } from 'c3-ui/elements/Texts'
import { AsyncSelect } from 'c3-ui/elements/Selects/thirdParty'

import { FindAgencies, InviteAgencies } from 'reduxActions/association'
import { HideModal } from 'reduxActions/services'
import { ShowFlashNotification } from 'reduxActions/services'
import { ICONS } from 'utils/icons'
const { noPhotoHolderIcon } = ICONS

class NewAssociationAgencyMC extends Component {
  static propTypes = {
    token: PropTypes.string,

    updateCallback: PropTypes.func,
    ShowFlashNotification: PropTypes.func,
    HideModal: PropTypes.func,
    FindAgencies: PropTypes.func,
    InviteAgencies: PropTypes.func
  }

  BASE_CLASSNAME = 'new-association-agency-mc'

  state = {
    agencies: [],
    agenciesIds: []
  }

  findAgencies = inputValue => {
    const { token, FindAgencies } = this.props
    return new Promise((resolve, reject) => {
      FindAgencies(token, inputValue)
        .then(action => {
          const {
            payload: { data }
          } = action
          resolve(data)
        })
        .catch(action => {
          reject([])
        })
    })
  }

  inviteAgencies = params => {
    const {
      token,
      InviteAgencies,
      ShowFlashNotification,
      HideModal,
      updateCallback
    } = this.props
    const { agenciesIds } = this.state
    InviteAgencies(token, agenciesIds)
      .then(() => {
        HideModal()
        ShowFlashNotification('success', 'Se han enviado la(s) invitacion(es).')
        if (updateCallback) {
          updateCallback()
        }
      })
      .catch(
        ({
          error: {
            response: { data: { error: { code = '' } = {} } = {} } = {}
          } = {}
        }) => {
          HideModal()

          if (code === 'AGENCY_ASSOCIATION_CONFLICT') {
            ShowFlashNotification(
              'danger',
              'Una o varias de las inmobiliarias que quieres invitar ya pertenecen a tu asociación. Código de error: WIG-20'
            )
          } else {
            ShowFlashNotification(
              'danger',
              'Error desconocido, por favor intenta de nuevo más tarde.'
            )
          }
        }
      )
  }

  render() {
    const { HideModal } = this.props
    return (
      <Layout className={this.BASE_CLASSNAME} vertical>
        <Title
          className={`${this.BASE_CLASSNAME}__title`}
          center
          smallSize
          uppercase
        >
          Invitar inmobiliaria
        </Title>
        <Text gray className={`${this.BASE_CLASSNAME}__brief-description`}>
          Invita inmobiliarias para que compartan sus inventarios con tu
          asociación.
        </Text>

        <AsyncSelect
          id="associationAgencies-newAgencyAssociation-mc-select-agencyBar"
          onChange={agencies => {
            this.setState({
              agencies,
              agenciesIds: agencies.map(({ agency_id }) => {
                return agency_id
              })
            })
          }}
          value={this.state.agencies}
          isMulti
          getOptionValue={({ agency_id = '' }) => {
            return agency_id
          }}
          getOptionLabel={({ name = '' }) => {
            return name
          }}
          loadOptions={inputValue => {
            return this.findAgencies(inputValue)
          }}
          placeholder="Ingresa el nombre de la inmobiliaria..."
          components={{
            Option: ({ innerProps, isDisabled, data, ...others }) => {
              const { email, logo, name } = data

              return !isDisabled ? (
                <Layout
                  {...innerProps}
                  className={`${this.BASE_CLASSNAME}__list-item`}
                >
                  <Image
                    small={2}
                    src={logo || noPhotoHolderIcon}
                    baseURL={logo ? process.env.REACT_APP_IMAGE_BASE_URL : ''}
                    fitContent
                  />
                  <Layout
                    small={9}
                    className={`${this.BASE_CLASSNAME}__list-item-agency-wrapper`}
                  >
                    <Text
                      bold
                      className={`${this.BASE_CLASSNAME}__list-item-agency-name`}
                    >
                      {name}
                    </Text>
                    <Text
                      smallSize
                      gray
                      className={`${this.BASE_CLASSNAME}__list-item-agency-email`}
                    >
                      {email}
                    </Text>
                  </Layout>
                </Layout>
              ) : null
            }
          }}
        />
        <Layout
          className={`${this.BASE_CLASSNAME}__buttons-group`}
          middle
          marginX
          center
        >
          <Button
            id="associationAgencies-newAgencyAssociation-mc-button-cancel"
            medium={5}
            full
            secondary
            onClick={HideModal}
          >
            CANCELAR
          </Button>

          <Button
            medium={5}
            id="associationAgencies-newAgencyAssociation-mc-button-invite"
            full
            primary
            onClick={this.inviteAgencies}
          >
            Invitar
          </Button>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  ShowFlashNotification,
  HideModal,
  FindAgencies,
  InviteAgencies
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewAssociationAgencyMC)
