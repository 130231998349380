var _jsxFileName = 'src/lib/elements/Links/MainLink.js',
    _this = this;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../generics/Links';
import classNames from 'classnames';
var MainLink = function MainLink(_ref) {
  var text = _ref.children,
      disabled = _ref.disabled,
      route = _ref.route,
      likeButton = _ref.likeButton,
      className = _ref.className,
      id = _ref.id,
      href = _ref.href,
      props = _objectWithoutProperties(_ref, ['children', 'disabled', 'route', 'likeButton', 'className', 'id', 'href']);

  var mainLinkClasses = classNames(className, 'main-link');
  return React.createElement(
    Link,
    Object.assign({
      id: id,
      className: mainLinkClasses,
      route: route,
      href: href,
      likeButton: likeButton
    }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 17
      },
      __self: _this
    }),
    text
  );
};

MainLink.propTypes = {
  /** */
  children: PropTypes.any.isRequired,
  /** */
  className: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** */
  href: PropTypes.string,
  /** */
  id: PropTypes.string,
  /** */
  likeButton: PropTypes.bool,
  /** */
  route: PropTypes.string
};

MainLink.defaultProps = {
  likeButton: false
};

export default MainLink;