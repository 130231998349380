import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { SecondaryButton, PrimaryButton } from 'c3-ui/elements/Buttons'
import { Layout } from 'c3-ui/elements/Layouts'
import { Title, Text } from 'c3-ui/elements/Texts'

import { InviteAgencies } from 'reduxActions/association'
import { HideModal } from 'reduxActions/services'
import { ShowFlashNotification } from 'reduxActions/services'
class ResendAssociationAgencyInvitationMC extends Component {
  static propTypes = {
    token: PropTypes.string,
    invitationsSelected: PropTypes.array,

    updateCallback: PropTypes.func,
    InviteAgencies: PropTypes.func,
    ShowFlashNotification: PropTypes.func,
    HideModal: PropTypes.func
  }

  BASE_CLASSNAME = 'resend-association-agency-invitation-mc'

  sendAssociationInvitation = () => {
    const {
      token,
      updateCallback,
      invitationsSelected,
      InviteAgencies,
      ShowFlashNotification,
      HideModal
    } = this.props
    const agenciesIds = invitationsSelected.map(({ agencyId }) => agencyId)

    InviteAgencies(token, agenciesIds)
      .then(() => {
        HideModal()

        updateCallback()

        ShowFlashNotification('success', 'Se han enviado las invitationes')
      })
      .catch(() => {
        HideModal()
        ShowFlashNotification('danger', 'Vuelve a intentarlo')
      })
  }

  render() {
    const { invitationsSelected, HideModal } = this.props
    return (
      <Layout className={this.BASE_CLASSNAME} vertical>
        <Title
          noResponsive
          className={`${this.BASE_CLASSNAME}__title`}
          center
          smallSize
          uppercase
        >
          Reenviar invitación a{' '}
          {invitationsSelected.length === 1 &&
            `${invitationsSelected.map(({ name }) => name)}.`}
        </Title>

        <Text gray className={`${this.BASE_CLASSNAME}__brief-description`}>
          Se enviará nuevamente un correo de invitación para que formen parte de
          tu asociación.
        </Text>

        {invitationsSelected.length > 1 && (
          <Layout className={`${this.BASE_CLASSNAME}__description-wrapper`}>
            <Text uppercase>Reenviar Invitación a:</Text>
            <Text bold>
              {`${invitationsSelected
                .map(({ name }) => name)
                .join('; ')
                .split(-2)}.`}
            </Text>
          </Layout>
        )}
        <Layout center middle marginX>
          <SecondaryButton
            id="associationAgencies-resendInvitation-mc-button-cancel"
            onClick={HideModal}
            medium={5}
          >
            CANCELAR
          </SecondaryButton>

          <PrimaryButton
            id="associationAgencies-resendInvitation-mc-button-resend"
            onClick={this.sendAssociationInvitation}
            medium={5}
          >
            Enviar invitacion{invitationsSelected.length > 1 ? 'es' : ''}
          </PrimaryButton>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  ShowFlashNotification,
  InviteAgencies,
  HideModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResendAssociationAgencyInvitationMC)
