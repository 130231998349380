import { useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useMedia } from 'rc/Hooks'
import { GetRequirement } from 'reduxActions/requirements'
import { getUserLocationByIP } from 'utils/complexHelpers'

export function useValue(val, clear) {
  const [value, setValue] = useState(val)

  function handleValue(event) {
    if (event.target && event.target.getAttribute && event.target.getAttribute('data-isbool')) {
      setValue(event.target.checked)
    } else if (clear) {
      if (value === event.target.value) {
        setValue('')
      } else {
        setValue(event.target.value)
      }
    } else {
      setValue(event.target ? event.target.value : event)
    }
  }

  useEffect(() => {
    setValue(val || '')
  }, [val])

  return [value, handleValue, setValue]
}

export function useMultiValue(val) {
  const [value, setValue] = useState(() => val || [])

  function handleValue(event) {
    if (event.target.checked) {
      setValue([...value, event.target.value])
    } else {
      setValue(value.filter(item => item !== event.target.value))
    }
  }

  function isChecked(comp) {
    return value.findIndex
      ? value.findIndex(
          typeof comp === 'function' ? comp : item => item === comp
        ) >= 0
      : false
  }

  useEffect(() => {
    if (val) {
      if (val.length === 0 && value.length !== 0) {
        setValue([])
      } else if (val.length !== value.length) {
        setValue(val)
      }
    }
  }, [val])

  return [isChecked, handleValue, value, setValue]
}

export function useParamRequirementId() {
  const searchQuery = `${window.location.search}`

  const requirementId = useMemo(() => {
    const urlParams = new URLSearchParams(searchQuery)
    return urlParams.get('requirementId')
  }, [searchQuery])

  return requirementId
}

export function useRequirement(id) {
  const dispatch = useDispatch()
  const token = useSelector(({ authReducer }) => authReducer.token)
  const [loading, setLoading] = useState(true)
  const [requirement, setRequirement] = useState(null)
  const paramReqId = useParamRequirementId()

  useEffect(() => {
    async function getRequirement(_id) {
      setLoading(true)
      try {
        const {
          payload: { data }
        } = await dispatch(GetRequirement(token, _id))
        setRequirement(data)
        setLoading(false)
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
    if (id || paramReqId) {
      getRequirement(id || paramReqId)
    } else {
      setLoading(false)
    }
  }, [paramReqId, id])

  return { requirementId: id || paramReqId, requirement, loading }
}

export function useLocationByIP() {
  const [loading, setLoading] = useState(true)
  const [location, setLocation] = useState({})

  useEffect(() => {
    async function getLocationByIP() {
      try {
        const {
          coordinates: { lat: latitude, lng: longitude }
        } = await getUserLocationByIP()
        setLocation({ latitude, longitude })
        setLoading(false)
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }

    function success(position) {
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude

      setLocation({
        latitude,
        longitude
      })
      setLoading(false)
    }

    function error() {
      getLocationByIP()
    }

    if (!navigator.geolocation) {
      getLocationByIP()
    } else {
      navigator.geolocation.getCurrentPosition(success, error)
    }
  }, [])

  return [location, loading]
}

export function useDevice(device) {
  const theme = useContext(ThemeContext)
  const isDevice = useMedia(theme.devices[device])

  return isDevice
}
