'use strict';

var Typography_Typography = require('./Typography.js');
require('@babel/runtime/helpers/extends');
require('@babel/runtime/helpers/objectWithoutProperties');
require('@babel/runtime/helpers/defineProperty');
require('@babel/runtime/helpers/typeof');
require('react');
require('prop-types');
require('styled-components');



module.exports = Typography_Typography;
