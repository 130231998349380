import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography, Autocomplete, Chip } from 'rc'
import styled from 'styled-components'
import { ListBrokerHighlightedItem } from 'components/CommonStyled'
import { Button } from 'components'

const ChipContainerStyled = styled(Grid)`
  min-height: ${({ theme }) => theme.remCalc('100')};
  max-height: ${({ theme }) => theme.remCalc('170')};
  overflow: auto;
  margin-bottom: 0;
  ${({ theme }) => theme.media.tabletDown`
    max-height: ${({ theme }) => theme.remCalc('250')};
  `}
`

const AssignPrimeMC = ({
  token,
  GetAgencyAvailableBrokers,
  HideModal,
  AssignFunction,
  ShowFlashNotification,
  getAgency,
  currentUserData
}) => {
  const [brokersAssigned, setBrokersAssigned] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const { roles: { PRIME = false } } = currentUserData
    if (!PRIME) setBrokersAssigned([currentUserData])
  }, [])

  const getAgencyAvailableBrokers = value => {
    return new Promise((resolve, reject) => {
      GetAgencyAvailableBrokers(token, value).then(res => {
        let {
          payload: { data }
        } = res
        resolve(data)
      })
    })
  }

  const handleSelectedBrokerAssign = (autocomplete_name, selectedValue) => {
    if (selectedValue) {
      const duplicateValidation = brokersAssigned.find(broker => {
        return broker.user_id === selectedValue.user_id
      })

      !duplicateValidation &&
        setBrokersAssigned([...brokersAssigned, selectedValue])
    }
  }

  const handleBubbleDelete = index => {
    let brokerArr = brokersAssigned
    brokerArr.splice(index, 1)
    return setBrokersAssigned([...brokerArr])
  }

  const assignPrime = () => {
    let user_ids = []
    brokersAssigned.map(broker => user_ids.push(broker.user_id))
    setIsLoading(true)
    AssignFunction(token, { user_ids })
      .then(res => {
        getAgency()
        ShowFlashNotification('success','¡Se asignó exitosamente prime a tus asesores!')
        HideModal()
      })
      .catch(err => {
        setIsLoading(false)
        ShowFlashNotification('danger','Ocurrió un error al asignar prime a tus asesores.')
      })
  }

  return (
    <Grid
      container
      item
      direction="column"
      justify="center"
      alignItems="space-between"
      css={`
        width: ${({ theme }) => theme.remCalc('600')};
        max-width: 100%;
        height: ${({ theme }) => theme.remCalc('400')};
        max-height: 100%;
      `}
    >
      <Typography variant="h2" textTransform="initial">
        Asignación de cuentas prime
      </Typography>
      <Typography variant="body2" align="center">
        Escribe el correo o nombre de los asesores que deseas que tengan cuenta
        prime, les enviaremos un correo para confirmar operación.
      </Typography>
      <Autocomplete
        css={`
          width: 100%;
          > * > * {
            background-color: ${({ theme }) => theme.palette.base.white};
            border-radius: 6px;
          }
        `}
        fetchValueResolver={results => {
          return results
        }}
        name="auto"
        placeholder="Escribe correo o nombre"
        fetchFunction={getAgencyAvailableBrokers}
        labelItemKey="fullname"
        listItemComponent={ListBrokerHighlightedItem}
        onChange={handleSelectedBrokerAssign}
        customHeight={150}
      />
      <ChipContainerStyled container>
        {brokersAssigned.map((item, index) => (
          <Chip
            key={index}
            label={item && item.fullname}
            onClick={() => handleBubbleDelete(index)}
            crossId="searcher-module-button-closeFilterBubble"
            css={`
              height: ${({ theme }) => theme.remCalc('30')};
              width: 46%;
            `}
          ></Chip>
        ))}
      </ChipContainerStyled>
      <Grid container justify="space-between">
        <Button
          variant="outlined"
          onClick={HideModal}
          disabled={isLoading}
          css={`
            width: ${({ theme }) => theme.remCalc('240')};
            ${({ theme }) => theme.media.tabletDown`
              width: ${({ theme }) => theme.remCalc('140')};
            `}
          `}
        >
          Cerrar
        </Button>
        <Button
          onClick={() => assignPrime()}
          disabled={isLoading}
          css={`
            width: ${({ theme }) => theme.remCalc('240')};
            ${({ theme }) => theme.media.tabletDown`
              width: ${({ theme }) => theme.remCalc('140')};
            `}
          `}
        >
          Asignar
        </Button>
      </Grid>
    </Grid>
  )
}

AssignPrimeMC.propTypes = {
  AssignFunction: PropTypes.func,
  GetAgencyAvailableBrokers: PropTypes.func,
  HideModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  token: PropTypes.string,
  getAgency: PropTypes.func,
  currentUserData: PropTypes.object,
}

export default AssignPrimeMC
