import moment from 'moment'
import 'moment/locale/es'
import { createReducer } from '../store'
import { capitalizeFirstLetter } from 'utils/helpers'
import { notificationsTemplateInfo } from 'modules/app/notifications/utils'

moment.locale('es')

const preProcessData = item => {
  const { metadata = '{}', created_at = '' } = item

  const momenCreatedDate = moment(created_at, 'YYYY-MM-DD HH:mm:ss ZZ')

  let created =
    created_at && momenCreatedDate !== 'Invalid Date'
      ? `Hace ${capitalizeFirstLetter(momenCreatedDate.fromNow(true))}`
      : ''

  const meta = JSON.parse(metadata)

  const templateInfo = notificationsTemplateInfo(item.type, {
    ...meta,
    fullname: item.fullname,
    photo: item.photo
  })

  return { ...item, ...templateInfo, created }
}

const initialState = {
  notifications: [],
  page: 0,
  pages: 0,
  success: false,
  total: 0,
  unread: 0
}

const notificationsReducer = createReducer(
  { ...initialState },
  {
    RESET_NOTIFICATIONS: () => ({ ...initialState }),
    GET_NOTIFICATIONS_SUCCESS: (state, action) => {
      let {
        payload: {
          data: { notifications, total, pages, unread }
        }
      } = action

      return {
        notifications: [
          ...state.notifications,
          ...notifications.map(preProcessData)
        ],
        total,
        unread,
        page: notifications.length > 0 ? state.page + 1 : state.page,
        pages,
        success: true
      }
    },
    SET_NOTIFICATION_READ: (state, action) => {
      const {
        payload: { index }
      } = action

      return {
        ...state,
        notifications: [
          ...state.notifications.slice(0, index),
          { ...state.notifications[index], read: true },
          ...state.notifications.slice(index + 1)
        ],
        unread: state.unread - 1
      }
    },
    MARK_ALL_AS_READ_SUCCESS: (state, action) => {
      return {
        ...state,
        notifications: state.notifications.map(item => ({
          ...item,
          read: true
        })),
        unread: 0
      }
    }
  }
)

export default notificationsReducer
