var _jsxFileName = 'src/lib/elements/Checkboxes/TextCheckBoxItem.js',
    _this = this,
    _TextCheckBoxItem$pro;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Layout } from '../Layouts';
import { Text } from '../Texts';

var TextCheckBoxItem = function TextCheckBoxItem(_ref) {
  var _classNames;

  var className = _ref.className,
      center = _ref.center,
      disabled = _ref.disabled,
      isSelected = _ref.isSelected,
      label = _ref.label,
      onBlur = _ref.onBlur,
      _onClick = _ref.onClick,
      onFocus = _ref.onFocus,
      theme = _ref.theme,
      uppercase = _ref.uppercase,
      value = _ref.value;

  var checkboxContainerClasses = classNames(className, 'text-checkbox-item', (_classNames = {}, _defineProperty(_classNames, 'text-checkbox-item--checked-' + theme, isSelected), _defineProperty(_classNames, 'text-checkbox-item--unchecked-' + theme, !isSelected), _defineProperty(_classNames, 'text-checkbox-item--disabled', disabled), _classNames));

  var labelCheckbox = classNames('text-checkbox-item__label', {
    'text-checkbox-item__disabled': disabled
  });

  var _buttonDOM = null;

  return React.createElement(
    'button',
    {
      className: 'text-checkbox-item__button',
      onBlur: !disabled ? onBlur : null,
      onClick: function onClick() {
        if (_buttonDOM) {
          _buttonDOM.blur();
        }
        if (!disabled) {
          _onClick(value);
        }
      },
      onFocus: !disabled ? onFocus : null,
      ref: function ref(_ref2) {
        return _buttonDOM = _ref2;
      },
      tabIndex: disabled ? '-1' : '0',
      type: 'button',
      __source: {
        fileName: _jsxFileName,
        lineNumber: 34
      },
      __self: _this
    },
    React.createElement(
      Layout,
      { className: checkboxContainerClasses, middle: true, center: center, __source: {
          fileName: _jsxFileName,
          lineNumber: 50
        },
        __self: _this
      },
      React.createElement(
        Text,
        { shrink: true, className: labelCheckbox, uppercase: uppercase, __source: {
            fileName: _jsxFileName,
            lineNumber: 51
          },
          __self: _this
        },
        label
      )
    )
  );
};

TextCheckBoxItem.propTypes = (_TextCheckBoxItem$pro = {
  /** Value to give on callback. */
  value: PropTypes.any.isRequired,
  /** Render's the element in a centered position */
  center: PropTypes.bool,
  /** Custom className in Layout Wrapper.  */
  className: PropTypes.string,
  /** Gives a disable stylish. */
  disabled: PropTypes.bool,
  /** Gives a diferent style. */
  isSelected: PropTypes.bool,
  /** Text to render in checkbox */
  label: PropTypes.string,
  /**  Handles onBlur action.*/
  onBlur: PropTypes.func,
  /** Handler of click action on Checkbox. */
  theme: PropTypes.string
}, _defineProperty(_TextCheckBoxItem$pro, 'value', PropTypes.any.isRequired), _defineProperty(_TextCheckBoxItem$pro, 'isSelected', PropTypes.bool), _defineProperty(_TextCheckBoxItem$pro, 'onClick', PropTypes.func), _defineProperty(_TextCheckBoxItem$pro, 'onFocus', PropTypes.func), _defineProperty(_TextCheckBoxItem$pro, 'uppercase', PropTypes.bool), _TextCheckBoxItem$pro);

TextCheckBoxItem.defaultProps = {
  onBlur: function onBlur() {
    return null;
  },
  onFocus: function onFocus() {
    return null;
  },
  theme: 'default',
  uppercase: true
};
export default TextCheckBoxItem;