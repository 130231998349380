/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'rc'

import Background from 'static/prime/prime-modal.svg'
import Drawing from 'static/drawings/success-prime.svg'
import WhiteLogo from 'static/logos/wiggot-prime-white.svg'

const WiggotPrimeLogo = styled.img.attrs({
  src: WhiteLogo,
  alt: 'Wiggot Prime'
})`
  align-self: flex-start;
`

const ModalBackground = styled.img.attrs({
  src: Background,
  alt: 'Background'
})``

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.media.desktop`
      width: ${({ theme }) => theme.remCalc('600')};
  `}
  h2 {
    color: white;
  }
  > ${ModalBackground} {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
  }
  ${({ theme }) => theme.media.desktop`
    padding: ${({ theme }) => theme.remCalc('30 40')};
  `}
`

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.media.desktop`
    margin-top: ${({ theme }) => theme.remCalc('28')};
    margin-bottom: ${({ theme }) => theme.remCalc('48')};  
  `}
  h3 {
    text-align: center;
    color: white;
    max-width: ${({ theme }) => theme.remCalc('330')};
  }
`

const CounterContainer = styled.div`
  background: #ffffff;
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.remCalc('10')};
  font-size: ${({ theme }) => theme.remCalc('25')};
  ${({ theme }) => theme.media.desktop`
      padding: ${({ theme }) => theme.remCalc('20 16')};
  `}
  p {
    margin: 0;
  }
`

const Description = styled.p`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.remCalc('16')};
  line-height: ${({ theme }) => theme.remCalc('20')};
  ~ a {
    margin-bottom: ${({ theme }) => theme.remCalc('18')};
    text-align: center;
  }
`

const ButtonStyled = styled(Button)`
  max-width: ${({ theme }) => theme.remCalc('250')};
  height: ${({ theme }) => theme.remCalc('50')};
  width: 100%;
  align-self: center;
`

const PrimeStartMC = props => {
  const { HideModal, action, primeChannel, className } = props
  return (
    <Container className={className}>
      <ModalBackground />
      {primeChannel === 'PLAN' ? (
        <h2>
          Bienvenido a <WiggotPrimeLogo />
        </h2>
      ) : (
        <WiggotPrimeLogo />
      )}
      <InfoContainer>
        <div>
          <h3>
            {primeChannel === 'PLAN'
              ? 'Ahora eres parte de la comunidad de súper asesores'
              : '¡Tu prueba prime ha comenzado!'}
          </h3>
          {primeChannel === 'TRIAL' && (
            <CounterContainer>
              <p>
                Te quedan:
                <b> 30 días</b>
              </p>
            </CounterContainer>
          )}
        </div>
        <img src={Drawing} alt="Success" />
      </InfoContainer>
      <Description>
        {primeChannel === 'TRIAL' ? (
          'Usa gratis las herramientas de wiggot prime durante este periodo y te avisaremos cuando tu prueba haya finalizado.'
        ) : (
          <Fragment>
            Usa tus herramientas y <b>comineza a genera negocios.</b>
          </Fragment>
        )}
      </Description>
      {primeChannel === 'PLAN' && <a>Conoce todas las herramientas prime</a>}
      <ButtonStyled
        variant={!action ? 'outlined' : 'standard'}
        color={!action ? 'gray' : 'primary'}
        onClick={action || HideModal}
      >
        {action ? 'Continuar' : 'Cerrar'}
      </ButtonStyled>
    </Container>
  )
}

PrimeStartMC.propTypes = {
  HideModal: PropTypes.func,
  action: PropTypes.func,
  className: PropTypes.string,
  primeChannel: PropTypes.string
}

export default PrimeStartMC
