import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M18.47,120c2.1,2,6,3.5,7,13.35-.31,4.73-2.65,8.89-4.86,12.85-14.13,25.32,12.71,44,30.55,49.33,36,10.82,79,1,111.8-18.53,16.29-9.66,32.44-25.12,35.89-47.36,2.9-18.74-3.7-38.46-20-44.39-26.47-9.67,10.29-47.32,5.63-66.51C181.72,7.44,170.4,3.07,161.63,1.19,151-1.09,139.94.2,129.38,2.61c-14.48,3.29-28.74,9.13-43.61,8.87C73.69,11.26,63.07,3.7,51.38,1.24,39.07-1.36,25.91,4.05,16.45,13.51c-12.1,12.11-11.52,30.76-7,47C11.25,67.05,14.47,71,13,79,10.09,86.72,6.79,89,2,98.09c-6,11.41,2.92,15.25,9.11,18.49C13.74,118,15.79,118.81,18.47,120Z"
      style={{ fill: '#c3ecfe', 'fill-opacity': 0.5 }}
    />
    <path
      d="M143.36,44.7A6.36,6.36,0,0,0,137,38.37l-68.12.28A22.51,22.51,0,0,1,49.46,72.53c-.72,0-1.43,0-2.13-.1l.15,91a6.35,6.35,0,0,0,6.36,6.32l83.39-.33h0a6.32,6.32,0,0,0,6.3-6.34Z"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="84.67 72.91 84.6 82.84 88.11 82.81 88.15 72.92 84.67 72.91"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="89.01 82.84 92.18 82.86 92.22 72.93 89.09 72.91 89.01 82.84"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="81.41 89.99 81.68 90 81.61 100.02 91.15 100.06 91.22 90.07 91.46 90.08 91.48 88.64 81.44 88.56 81.41 89.99"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="95.22 82.68 95.14 100.11 95.27 82.68 95.22 82.68"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="83.7 72.92 80.49 72.9 80.42 82.84 83.66 82.81 83.7 72.92"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="83.78 59.96 78.02 67.35 78.02 67.35 78.02 67.39 78 69.31 78.02 67.35 95.49 67.44 78.02 67.35 83.78 59.96"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="106.77 80.35 106.87 67.51 106.75 80.35 106.77 80.35"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="101.22 60.02 95.49 67.44 101.22 60.02 101.22 60.02"
      style={{ fill: '#fff' }}
    />
    <path
      d="M138.34,49.88a6.21,6.21,0,0,0-6.19-6.2h-.22L58.85,44a6.25,6.25,0,0,0-6.17,6.27l.1,107.88a6.24,6.24,0,0,0,6.19,6.2h.22l73.09-.28a6.3,6.3,0,0,0,6.22-6.27ZM49.46,27.49A22.52,22.52,0,1,1,27,50,22.51,22.51,0,0,1,49.46,27.49Zm79.72,72.82-14.7-.12-19.35-.08L77.78,100H62.67a2.11,2.11,0,0,1-2.38-1.8,1.23,1.23,0,0,1,0-.2l-.36-44.67a2.45,2.45,0,0,1,2.42-2.47l66.53-.54a2.44,2.44,0,0,1,2.46,2.42h0l.37,44.67A3,3,0,0,1,129.18,100.31Z"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="110.62 86.4 110.58 96.33 110.58 96.33 110.62 86.4 107.49 86.39 110.62 86.4"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="102.06 86.39 102.02 96.32 102.06 86.39 98.85 86.38 102.06 86.39"
      style={{ fill: '#fff' }}
    />
    <polygon
      points="103.03 86.38 106.51 86.39 106.51 86.39 103.03 86.38 103.03 86.38"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M120.4,150.07l-50.35.21a2.3,2.3,0,0,1-2.3-2.28h0v-8.55A2.33,2.33,0,0,1,70,137.13l50.34-.21a2.32,2.32,0,0,1,2.31,2.28v8.55a2.28,2.28,0,0,1-2.22,2.32Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M121.14,125.88l-35.5.16v-4.5l35.5-.21Z"
      style={{ fill: '#81caf7' }}
    />
    <path
      d="M121.07,116.47l-35.44.16v-4.5l35.49-.21Z"
      style={{ fill: '#81caf7' }}
    />
    <polygon
      points="91.22 89.98 91.22 89.97 81.69 89.93 81.61 100.06 91.22 89.98"
      style={{ fill: '#4fb8dc' }}
    />
    <polygon
      points="81.44 88.56 81.41 89.99 81.68 90 81.61 100.02 81.61 100.02 81.69 89.89 91.22 89.93 91.22 90.07 91.46 90.08 91.48 88.64 81.44 88.56"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M77.78,100,78,69.31h0V67.39h0L83.79,60l17.43.06h0l5.65,7.49-.1,12.84,7.29,0v2.39h.53l-.13,17.4,14.7.12a3,3,0,0,0,2.51-2.92l-.37-44.67a2.44,2.44,0,0,0-2.46-2.42h0l-66.53.54a2.45,2.45,0,0,0-2.42,2.47L60.27,98a2.1,2.1,0,0,0,2.13,2.09l.27,0Z"
      style={{ fill: '#d9dde7' }}
    />
    <path d="M101.22,60H83.79L78,67.35l17.47.09Z" style={{ fill: '#0076cc' }} />
    <polygon
      points="101.22 60.02 95.49 67.44 78.02 67.35 78 69.31 95.28 69.41 95.19 80.28 106.75 80.37 106.87 67.51 101.22 60.02"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M114.08,82.79l-19-.11V80.27l18.91.12Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M95.27,82.68l-.14,17.43,19.35.08.13-17.4ZM102,96.32H98.78l.07-9.93h3.21Zm4.46,0h-3.52l.07-9.94h3.48Zm4.1,0h-3.17l.08-9.93h3.13Z"
      style={{ fill: '#f2f2f2' }}
    />
    <path d="M102,96.32H98.78l.07-9.93h3.21Z" style={{ fill: '#0076cc' }} />
    <path d="M106.48,96.29H103l.07-9.94h3.48Z" style={{ fill: '#0076cc' }} />
    <path d="M110.58,96.33h-3.17l.08-9.93h3.13Z" style={{ fill: '#0076cc' }} />
    <path
      d="M78,69.31,77.78,100l17.35.09.15-30.7Zm6.67,3.6h3.48V82.8H84.63Zm-4.18,0H83.7V82.8H80.46Zm11,17.18h-.24l-.07,10H81.64l.07-10h-.27V88.66l10,.08Zm.72-7.22H89l.08-9.94h3.13Z"
      style={{ fill: '#e4e6e4' }}
    />
    <polygon
      points="83.66 82.81 80.42 82.84 80.49 72.9 83.7 72.92 83.66 82.81"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="88.11 82.81 84.6 82.84 84.67 72.91 88.15 72.92 88.11 82.81"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="92.18 82.86 89.01 82.84 89.09 72.91 92.22 72.93 92.18 82.86"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="81.61 100.02 81.61 100.02 91.15 100.06 91.22 89.93 81.61 100.02"
      style={{ fill: '#4fb8dc', opacity: 0.5, isolation: 'isolate' }}
    />
    <ellipse
      cx="49.46"
      cy="50.01"
      rx="22.5"
      ry="22.52"
      style={{ fill: '#fd7171' }}
    />
    <path
      d="M57.4,54V45.51a.88.88,0,0,0-.27-.57.85.85,0,0,0-1.17,0,.87.87,0,0,0-.26.57v7.55H53V48.5a1.12,1.12,0,0,0-.09-.44,1.09,1.09,0,0,0-.63-.63,1.12,1.12,0,0,0-.44-.09h-3.2a1.12,1.12,0,0,0-.44.09,1.09,1.09,0,0,0-.63.63,1.12,1.12,0,0,0-.09.44v4.61H44.8v-7.6a.81.81,0,0,0-.24-.6.85.85,0,0,0-1.45.6V54a.85.85,0,0,0,.85.85h4.42a.86.86,0,0,0,.6-.25.89.89,0,0,0,.24-.6V49h2.06v5a.85.85,0,0,0,.85.85h4.42A.85.85,0,0,0,57.4,54Zm1.87-8.39a.83.83,0,0,1-.58-.23L50.25,37.1l-8.43,8.22a.83.83,0,0,1-.6.22.85.85,0,0,1-.83-.83.85.85,0,0,1,.22-.59l9.06-8.85a.88.88,0,0,1,1.21,0l9.06,8.85a1.05,1.05,0,0,1,.2.27.84.84,0,0,1,0,.66,1.05,1.05,0,0,1-.2.27,1,1,0,0,1-.67.27"
      style={{ fill: '#fff', 'fill-rule': 'evenodd' }}
    />
    <path
      d="M60.87,57.49a2,2,0,0,0-2.72-.58l-3,1.88a2.22,2.22,0,0,0-.69-.64,2.34,2.34,0,0,0-1.24-.29l-.94.06H51a4.82,4.82,0,0,1-1-.14,2.68,2.68,0,0,1-.57-.32,4.67,4.67,0,0,0-1.1-.59,5.89,5.89,0,0,0-2-.31,7.69,7.69,0,0,0-3.47.93v-.22A1.64,1.64,0,0,0,40.93,56l-2.47.52a1.89,1.89,0,0,0-.6.26,1.66,1.66,0,0,0-.68,1.1,1.43,1.43,0,0,0,0,.65l1.49,6.29a1.68,1.68,0,0,0,.62,1,1.64,1.64,0,0,0,1,.33h.37l2.45-.59A1.65,1.65,0,0,0,44,65a1.63,1.63,0,0,0,.33-.79,3.83,3.83,0,0,1,1.35,0c.86.15,1.7.34,2.54.57a19.6,19.6,0,0,0,2.61.57,5.75,5.75,0,0,0,.83,0H52a5,5,0,0,0,2.45-.88l5.86-4a2.21,2.21,0,0,0,.56-.58,2.19,2.19,0,0,0,0-2.36Zm-20.6,7v.15h0v-.17h0L38.8,58.19h0l2.49-.54h.06l.26,1.12a.88.88,0,0,0,.11.42l1.1,4.66a.06.06,0,0,1,0,.06Zm19.27-5.36h0l-2.94,2-2.93,2h0a3.63,3.63,0,0,1-2.6.6h0a22.56,22.56,0,0,1-2.44-.53,22.93,22.93,0,0,0-2.7-.62h0a5.19,5.19,0,0,0-2,.07L43.09,59a6.42,6.42,0,0,1,3.24-1h0a4.12,4.12,0,0,1,1.44.2,3.22,3.22,0,0,1,.81.45,3.85,3.85,0,0,0,.84.47h0a5.47,5.47,0,0,0,1.5.24,11.37,11.37,0,0,0,1.43,0h0l.93-.06a.72.72,0,0,1,.51.17.79.79,0,0,1,.28.45h0a.58.58,0,0,1-.36.12h0l-3.9.25h0a.62.62,0,0,0-.42.24.64.64,0,0,0-.15.46.68.68,0,0,0,.21.44.66.66,0,0,0,.45.17h0l3.9-.26a1.82,1.82,0,0,0,1.2-.45h0a1.24,1.24,0,0,0,.31-.5l3.57-2.25h0a.56.56,0,0,1,.42-.08.54.54,0,0,1,.34.23h0a.51.51,0,0,1,.1.21.89.89,0,0,1,0,.23h0a.44.44,0,0,1-.07.19.5.5,0,0,1-.13.19Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M166.94,180.33l3-4.05-10.63-7.58-2.89,3.78Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M167,183l21.56,15.94a4.6,4.6,0,0,0,6.29-.83,4.41,4.41,0,0,0-.76-6.19h0L172.56,176a4.59,4.59,0,0,0-6.29.83,4.35,4.35,0,0,0,.66,6.12Z"
      style={{ fill: '#171d3e' }}
    />
    <path
      d="M117.9,140.66l.77-1,.2-.08a28.27,28.27,0,0,1,17.21-9.23c14.52-1.95,28.13,8.07,30.13,22.28l.09.86v.66a26.71,26.71,0,0,1-14.19,26.18l-.59.23-.8.3a27.63,27.63,0,0,1-8,2.37,26.22,26.22,0,0,1-30.14-21.61l0-.21-.1-.85v-.67A26.25,26.25,0,0,1,117.9,140.66Zm43.68,12.43c-1.5-11.72-12.42-20.08-25-18.88a22,22,0,0,0-14.37,7.7l-.45.62-.58.89a22,22,0,0,0-4.27,15.7l.1.86.1.86c2.34,10.95,13,18.53,24.83,17.36a21.14,21.14,0,0,0,7-2l.79-.3.73-.5a23.59,23.59,0,0,0,11.29-20.61l-.1-.85Z"
      style={{ fill: '#171d3e', 'fill-rule': 'evenodd' }}
    />
  </Fragment>,
  'ZeroOpportunities',
  '0 0 200 224'
)
