import React, { useContext } from 'react'
import { array, object } from 'prop-types'
import styled from 'styled-components'

import {
  Typography,
  Select,
  TextField,
  CheckboxControlGroup,
  FormControlLabel,
  CheckboxControl,
  withForm,
  Checkbox
} from 'rc'
import { MaskedInput } from 'rc/FormComponents'
import { CheckmarkSquare } from 'rc/Icons'

import { ModelFormSectionContainer } from 'modules/development/static-components'
import ModelFormContext from '../ModelFormContext'

function GeneralInfo(props) {
  const {
    developmentSections,
    fc: {
      values: { development_type, property_type, max_construction_height },
      customChange
    }
  } = props
  const isVertical = ['VERTICAL'].includes(development_type)

  const isVerticalMixto = development_type === 'VERTICAL_MIXTO'

  const { formErrors = {}, cleanFormErrors } = useContext(ModelFormContext)

  const verticalOpt = [{ label: 'Departamento', value: 'APARTMENT' }]

  const verticalMixtoOpt = [
    { label: 'Departamento', value: 'APARTMENT' },
    {
      label: 'Consultorio',
      value: 'CONSULTING_ROOM'
    },
    {
      label: 'Oficina',
      value: 'OFFICE'
    },
    {
      label: 'Local comercial',
      value: 'SHOP'
    }
  ]

  const horizontalOpt = [
    { label: 'Casa', value: 'HOUSE' },
    { label: 'Terreno', value: 'TERRAIN' }
  ]

  const handleMaskedInputChange = ({ target }) => {
    const { value, name } = target
    cleanFormErrors(name)
    customChange({ [`${name}`]: value === '' ? null : parseInt(value) })
  }

  return (
    <ModelFormSectionContainer>
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
      >
        Modelo
      </Typography>
      <div
        css={`
          display: flex;
          justify-content: space-between;
        `}
      >
        <SectionColumnContainer>
          <TextField
            name="model_name"
            label="Nombre del modelo"
            placeholder="Nombre del modelo"
            autoComplete="no-autocomplete"
            required
          />
          <Select
            name="property_type"
            placeholder="Tipo de propiedad"
            optionLabelKey="label"
            optionValueKey="value"
            options={
              isVertical
                ? verticalOpt
                : isVerticalMixto
                ? verticalMixtoOpt
                : horizontalOpt
            }
            onChange={({ target: { value } }) => {
              if (value === 'TERRAIN') {
                return customChange({ ground_active: true })
              }
            }}
            required
          />
          <TextField
            name="units_available"
            label="Unidades totales"
            placeholder="unidades"
            type="number"
            min={0}
            required
            validate={value => {
              return !value || Number(value) <= 0
                ? 'Ingresa un valor válido.'
                : null
            }}
          />
          <TextField
            name="model_description"
            label="Descripción del modelo"
            placeholder="Agrega más características del modelo, con qué está equipado, que tipo de acabados tiene, etc."
            multiline
            required
            css={`
              height: 100%;
              > div {
                height: 100%;
                > textarea {
                  height: 100%;
                }
              }
            `}
          />
        </SectionColumnContainer>
        <SectionColumnContainer>
          {property_type === 'TERRAIN' ? (
            <>
              <TextField
                name="floors"
                label="Niveles máx permitidos"
                placeholder="Niveles máx permitidos"
                type="number"
                min={0}
                required
                validate={value => {
                  return !value || Number(value) <= 0
                    ? 'Ingresa un valor válido.'
                    : null
                }}
              />
              <MaskedInput
                name="max_construction_height"
                label="Altura max. de construcción"
                placeholder="Altura max. de construcción"
                mask="meters"
                value={max_construction_height}
                required
                error={formErrors['max_construction_height']}
                onChange={handleMaskedInputChange}
                css={`
                  > input {
                    height: 46px !important;
                  }
                `}
              />
              <div
                css={`
                  display: flex;
                  align-content: center;
                `}
              >
                <Checkbox
                  css={`
                    margin-right: 8px;
                  `}
                  name="allows_basement"
                />
                <Typography variant="subtitle4" fontWeight="regular">
                  Permite sotano
                </Typography>
              </div>
              <div
                css={`
                  display: flex;
                  align-content: center;
                `}
              >
                <Checkbox
                  css={`
                    margin-right: 8px;
                  `}
                  name="allows_semibasement"
                />
                <Typography variant="subtitle4" fontWeight="regular">
                  Permite semisotano
                </Typography>
              </div>
              <div
                css={`
                  display: flex;
                  align-content: center;
                `}
              >
                <Checkbox
                  css={`
                    margin-right: 8px;
                  `}
                  name="allows_roof_garden"
                />
                <Typography variant="subtitle4" fontWeight="regular">
                  Permite roof-garden
                </Typography>
              </div>
            </>
          ) : (
            <>
              <TextField
                name="floors"
                label="Niveles"
                placeholder="Niveles"
                type="number"
                required
                validate={value => {
                  return !value || Number(value) <= 0
                    ? 'Ingresa un valor válido.'
                    : null
                }}
              />
              <TextField
                name="bedrooms"
                label="Habitaciones"
                placeholder="Habitaciones"
                type="number"
                min={0}
                required={!isVerticalMixto}
                validate={value => {
                  if (!isVerticalMixto) {
                    return !value || Number(value) <= 0
                      ? 'Ingresa un valor válido.'
                      : null
                  }
                  return
                }}
              />
              <TextField
                name="bathrooms"
                label="Baños"
                placeholder="Baños"
                type="number"
                min={0}
                required={!isVerticalMixto}
                validate={value => {
                  if (!isVerticalMixto) {
                    return !value || Number(value) <= 0
                      ? 'Ingresa un valor válido.'
                      : null
                  }
                  return
                }}
              />
              {isVerticalMixto ? (
                <TextField
                  name="public_bathrooms"
                  label="Baños públicos"
                  placeholder="Baños públicos"
                  type="number"
                  min={0}
                />
              ) : (
                ''
              )}
              <TextField
                name="half_bathrooms"
                label="Medios baños"
                placeholder="Medios baños"
                type="number"
                min={0}
              />
              <TextField
                name="parking_lots"
                label="Estacionamiento"
                placeholder="Estacionamiento"
                type="number"
                min={0}
              />
              <TextField
                name="cellar"
                label="Bodegas"
                placeholder="Bodegas"
                type="number"
                min={0}
              />
            </>
          )}
        </SectionColumnContainer>
      </div>
      <Typography
        variant="subtitle2"
        fontWeight="medium"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('20')};
        `}
      >
        {isVertical || isVerticalMixto ? 'Torres' : 'Condominios'}
      </Typography>
      <Typography variant="subtitle4" fontWeight="regular">
        {`Selecciona ${
          isVertical || isVerticalMixto
            ? 'las torres a las'
            : 'los condominios a los'
        } que pertenece el modelo.`}
      </Typography>
      {developmentSections ? (
        <CheckboxControlGroupStyled name="towers">
          {developmentSections.map((tower, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <CheckboxControl
                    checkedIcon={CheckmarkSquare}
                    value={tower['section_id']}
                  />
                }
                label={tower['name']}
                labelPlacement="end"
              />
            )
          })}
        </CheckboxControlGroupStyled>
      ) : null}
    </ModelFormSectionContainer>
  )
}

GeneralInfo.propTypes = {
  fc: object,
  developmentSections: array
}

export const SectionColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '48%')};
  > * {
    margin-top: ${({ theme }) => theme.remCalc('20')} !important;
  }
`

const CheckboxControlGroupStyled = styled(CheckboxControlGroup)`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  margin-top: ${({ theme }) => theme.remCalc('12')};
  > label:not(:first-child) { margin-left: ${({ theme }) =>
    theme.remCalc('16')}; }
  > label > span:last-child {
      margin-left: ${({ theme }) => theme.remCalc('8')};
      font-size: ${({ theme }) => theme.remCalc('14')};
      font-weight: 400;
    }
  }
`

export default withForm(GeneralInfo)
