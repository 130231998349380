import {
  OnBoardingMC,
  // TrialReminderMC,
  PrimeEndMC,
  VerifyAccountMC
} from 'modules/prime'

import { store } from 'reduxFold/store'
import { ShowModal } from 'reduxActions/services'

const TYPES = {
  AGENDA: 'AGENDA',
  DATASHEETS: 'DATASHEETS',
  HTTPS: 'HTTPS',
  OFFERINGS: 'OFFERINGS',
  REPORTS: 'REPORTS',
  SUGGESTIONS: 'SUGGESTIONS',
  INFORMATION: 'INFORMATION'
}

const PrimeHandler = (type, action, actionBeforeModal = null) => () => {
  const { getState, dispatch } = store
  const {
    authReducer: { roles },
    profileReducer: { fullPrime, fullPrimeUsed, trialUsed, verified }
  } = getState()
  const isPrime = roles['PRIME']
  const isAdmin = roles['AGENCY'] ? true : false

  if (verified) {
    if (fullPrime) {
      action && action()
    } else if (!isPrime && (trialUsed || fullPrimeUsed)) {
      actionBeforeModal && actionBeforeModal()

      dispatch(
        ShowModal(
          PrimeEndMC,
          {
            action,
            type,
            isAdmin
          },
          { noClosingButton: false }
        )
      )
    } else if (isPrime) {
      action && action()

      // dispatch(
      //   ShowModal(
      //     TrialReminderMC,
      //     { action, type, primeAvailableUntil },
      //     { noClosingButton: false }
      //   )
      // )
    } else {
      actionBeforeModal && actionBeforeModal()

      dispatch(
        ShowModal(
          OnBoardingMC,
          { action, type, isAdmin },
          { noClosingButton: false }
        )
      )
    }
  } else {
    dispatch(ShowModal(VerifyAccountMC, null, { noClosingButton: false }))
  }
}

export default PrimeHandler

export { TYPES }
