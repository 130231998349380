import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isSubmitting, hasSubmitSucceeded } from 'redux-form'

import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'
import { Text } from 'c3-ui/elements/Texts'
import { Grid, Typography } from 'rc'
import { ForgotPasswordForm } from 'modules/login'
import { Footer } from 'modules/app'

import { ShowFlashNotification } from 'reduxActions/services'
import { ForgotPassword } from 'reduxActions/auth'
import { Logo } from 'modules/app/NavBar'

class ForgotPasswordModule extends Component {
  static propTypes = {
    ForgotPassword: PropTypes.func,
    isSubmitting: PropTypes.bool,
    hasSubmitSucceeded: PropTypes.bool,
    ShowFlashNotification: PropTypes.func
  }

  handleSubmit = data => {
    const { ForgotPassword } = this.props
    return ForgotPassword(data)
  }

  handleSubmitSuccess = () => {
    const { ShowFlashNotification } = this.props

    ShowFlashNotification(
      'success',
      'Hemos enviado un correo para recuperar tu contraseña.',
      null,
      {
        inFeaturedImage: true
      }
    )
  }

  handleSubmitFail = (formErrors, dispatch, submitError) => {
    const { ShowFlashNotification } = this.props

    if (formErrors && Object.keys(formErrors).length > 0) {
      return
    }
    if (!submitError) {
      submitError = {}
    }
    const {
      error: { response: { data: { error: { code } = {} } = {} } = {} } = {}
    } = submitError

    let errorMessage = ''
    let successMessage = ''

    switch (code) {
      case 'INTERNAL_SERVER_ERROR':
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'

        break
      default:
        successMessage = 'Correo de confirmación enviado.'
    }
    ShowFlashNotification(
      errorMessage ? 'danger' : 'success',
      errorMessage || successMessage,
      null,
      {
        inFeaturedImage: true
      }
    )
  }

  render() {
    const { isSubmitting, hasSubmitSucceeded } = this.props

    return (
      <Layout className="recover-password-module" windowSize vertical justify>
        <LayoutItem>
          <div
            css={`
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 30px;
            `}
          >
            <Logo width="190" login={true} />
          </div>
        </LayoutItem>
        <LayoutItem>
          <Grid container center>
            <Grid
              container
              center={hasSubmitSucceeded ? true : false}
              css={`
                max-width: ${({ theme }) => theme.remCalc('400')};
                width: 100%;
                padding-left: ${({ theme }) => theme.remCalc('12')};
              `}
            >
              <Typography
                variant="h2"
                color="palette.primary.main"
                textTransform="initial"
              >
                {hasSubmitSucceeded ? '¡Listo!' : 'Recupera tu contraseña'}
              </Typography>
            </Grid>
          </Grid>
          {hasSubmitSucceeded && (
            <Text
              thin
              mediumSize
              center
              className="recover-password-module__description"
            >
              Te hemos enviado un mensaje a tu correo electrónico para recuperar
              tu contraseña.
            </Text>
          )}
          <ForgotPasswordForm
            isSubmitting={isSubmitting}
            hasSubmitSucceeded={hasSubmitSucceeded}
            handleSubmit={this.handleSubmit}
            handleSubmitSuccess={this.handleSubmitSuccess}
            handleSubmitFail={this.handleSubmitFail}
          />
        </LayoutItem>
        <Footer />
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('recover-password-form')(state),
  hasSubmitSucceeded: hasSubmitSucceeded('recover-password-form')(state)
})

const mapDispatchToProps = {
  ForgotPassword,
  ShowFlashNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModule)
