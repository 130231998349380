import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography, Spinner } from 'rc'
import { Warning } from 'rc/Icons'
import { Button } from 'src/components'

const ConfirmDeleteMC = ({ title, description, deleteAction, HideModal, isWarning }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Container>
      <Warning
        css={`
          fill: #ffbc08;
          ${({ theme }) => theme.box(40, 40)}
        `}
      />
      <Typography variant="h2" color="palette.primary.main" align="center">
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="palette.new.text"
        align="center"
        textTransform="initial"
      >
        {description}
      </Typography>
      <ButtonsContainer>
        <ButtonStyled type="tertiary" onClick={HideModal}>
          Cancelar
        </ButtonStyled>
        <ButtonStyled
          type={isWarning ? 'primary' : "alert"}
          onClick={() => {
            setIsLoading(true)
            deleteAction && deleteAction(HideModal)
          }}
        >
          {isWarning ? "Vincular" : "Confirmar"}
        </ButtonStyled>
      </ButtonsContainer>
      {isLoading && (
        <div
          css={`
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
          `}
        >
          <Spinner />
        </div>
      )}
    </Container>
  )
}

ConfirmDeleteMC.propTypes = {
  deleteAction: PropTypes.func,
  description: PropTypes.string,
  HideModal: PropTypes.func,
  title: PropTypes.string,
  isWarning: PropTypes.string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('30')};
  ${({ theme }) => theme.box(450, 350)}
  @media (max-width: 920px) {
    width: 100%;
  }
`
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const ButtonStyled = styled(Button).attrs({ rounded: true })`
  width: 48%;
`

export default ConfirmDeleteMC
