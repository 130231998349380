var _jsxFileName = 'src/lib/elements/Layouts/InlineBlock.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';

var InlineBlock = function InlineBlock(_ref) {
  var className = _ref.className,
      children = _ref.children,
      onClick = _ref.onClick,
      props = _ref.props;

  return React.createElement(
    'div',
    Object.assign({
      className: (className ? className + ' ' : '') + 'inline-block'
    }, props, {
      onClick: onClick,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 6
      },
      __self: _this
    }),
    children
  );
};

InlineBlock.propTypes = {
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  onClick: PropTypes.func,
  /** */
  props: PropTypes.any
};

export default InlineBlock;