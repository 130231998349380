import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Typography } from 'rc'
import { Photo } from 'rc/Icons'
import { DNDFileUploader } from 'rc/FormComponents'
import { useUploadImage } from 'src/hooks/useUploadImage'
import { Button } from 'components/'
import {
  FileContainer,
  FilesContainer,
  ImageElement,
  AltFileContainer
} from 'modules/development/static-components'

const renderPhotos = (item, utils, { setCount, customChange, name }) => {
  const { value, preview, total } = item
  setCount(total)
  const cb = val => {
    const imgArr = val.map(item => item.value)
    customChange && customChange({ [`${name}`]: imgArr })
    setCount(imgArr.length)
  }
  return (
    <ImageElement
      value={value}
      preview={preview}
      handleDelete={() => utils.handleDelete(cb)}
    />
  )
}

const renderPhoto = (item, utils, { customChange, name }) => {
  const { value, preview, cover } = item
  const cb = val => {
    const imgArr = val.map(item => item.value)
    customChange && customChange({ [`${name}`]: imgArr })
  }
  return (
    <ImageElement
      value={value}
      preview={preview}
      handleDelete={() => utils.handleDelete(cb)}
      cover={cover}
    />
  )
}

const renderPlaceholder = count => {
  const loop = 4 - count

  const newArr = new Array(loop > 0 ? loop : 0).fill(0)
  return newArr.map((el, i) => <FilesContainer key={i} />)
}

const UploadImage = ({
  multiple,
  cover,
  btnTitle = 'Subir imágenes',
  name,
  customChange,
  values,
  type,
  alternativeType,
  customDropAreaText
}) => {
  const [count, setCount] = useState(0)
  const { upload } = useUploadImage(type)

  const onChange = (e = []) => {
    typeof e[0] == 'string' && customChange && customChange({ [`${name}`]: e })
  }

  const renderAltPlaceholder = () => {
    return count > 0 ? [] :
    [
      <AltFilesContainer key={0}>
        <Photo />
        <Typography variant="body3" color="palette.new.text" align="center">
          {customDropAreaText ? customDropAreaText : "No hay imagen adjunta."}
        </Typography>
        <FakeButton>
          {btnTitle}
        </FakeButton>
      </AltFilesContainer>
    ]
  }

  return (
    <DNDFileUploader
      name={name}
      multiple={multiple}
      cover={cover}
      defaultValue={values}
      onChange={onChange}
      fileUploader={upload}
    >
      {({ showFileDialog, renderItems }) => (
        <>
          {multiple ? (
            <>
              {renderItems((item, utils) =>
                renderPhotos(item, utils, { setCount, customChange, name })
              )}
              {renderPlaceholder(count)}
            </>
          ) : (
            alternativeType ? 
              <AltFileContainer cover={cover}>
                {renderItems((item, utils) => renderPhotos(item, utils, { setCount, customChange, name }))}
                {renderAltPlaceholder()}
              </AltFileContainer> :
              <FileContainer cover={cover}>
                {renderItems((item, utils) =>
                  renderPhoto(item, utils, { customChange, name })
                )}
              </FileContainer>
          )}
          {!alternativeType && 
            <div
              css={`
                width: 100%;
                display: flex;
                justify-content: flex-end;
              `}
            >
              <Button type="primary" rounded onClick={showFileDialog}>
                {btnTitle}
              </Button>
            </div>
          }
        </>
      )}
    </DNDFileUploader>
  )
}

export const AltFilesContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.remCalc('20')};
  background-color: rgb(246, 246, 247);
  svg { 
    ${({ theme }) => theme.box(24, 24)};
    margin-bottom: ${({ theme }) => theme.remCalc('12')};
  }
`

const FakeButton = styled.div`
  font-size: ${({ theme }) => theme.remCalc('12')};
  font-weight: 600;
  color: #FFFFFF;
  line-height: ${({ theme }) => theme.remCalc('18')};
  text-align: center;
  padding: ${({ theme }) => theme.remCalc('6 16')};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.remCalc('50')};
  margin-top: ${({ theme }) => theme.remCalc('8')};
`

UploadImage.propTypes = {
  btnTitle: PropTypes.string,
  cover: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  customChange: PropTypes.func,
  values: PropTypes.array,
  type: PropTypes.string,
  alternativeType: PropTypes.bool,
  customDropAreaText: PropTypes.string
}

export default UploadImage
