import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Spinner, Button } from 'rc'

import { FreemiumWrapper } from 'modules/freemium'
import { RULES } from 'modules/freemium/utils/constants'
import { ListItem, ListFilters } from './components'

const List = ({
  activeList,
  lists: listsObj = {},
  fetchFunction,
  labelsHandler,
  isLoading,
  clickHandler,
  iconHandler,
  optionsComponent,
  isMobile,
  bottomAction,
  ...props
}) => {
  const { lists = [] } = listsObj
  const { principal, secondary, tertiary } = labelsHandler || {}
  const builtLabels = (label, item) => label && label.handler(item)

  const renderList = (item, i) => {
    return (
      <ListItem
        key={item.publicListId}
        active={activeList === item.publicListId}
        principal={builtLabels(principal, item)}
        secondary={builtLabels(secondary, item)}
        tertiary={builtLabels(tertiary, item)}
        icon={iconHandler && iconHandler(item)}
        onClick={() => clickHandler && clickHandler(item)}
      />
    )
  }

  return (
    <Container {...props}>
      <ListFilters
        fetchFunction={fetchFunction}
        optionsComponent={optionsComponent}
        isMobile={isMobile}
        {...props}
      />
      <ListContainer>
        {isLoading ? (
          <Spinner
            css={`
              margin-top: 30px;
            `}
          />
        ) : (
          lists.map(renderList)
        )}
      </ListContainer>
      {bottomAction && (
        <div
          css={`
            display: flex;
            padding: 10px 14px 14px;
            justify-content: center;
            > button {
              border-radius: 50px !important;
            }
          `}
        >
          <FreemiumWrapper section={RULES.LIST_CREATE} custom>
            <Button full variant="outlined" color="gray" onClick={bottomAction}>
              Nueva lista
            </Button>
          </FreemiumWrapper>
        </div>
      )}
    </Container>
  )
}

List.propTypes = {
  fetchFunction: PropTypes.func.isRequired,
  lists: PropTypes.shape({
    lists: PropTypes.array,
    total: PropTypes.number,
    page: PropTypes.number,
    pages: PropTypes.number
  }),
  labelsHandler: PropTypes.object.isRequired,
  activeList: PropTypes.any,
  clickHandler: PropTypes.func,
  filtersProps: PropTypes.object,
  iconHandler: PropTypes.func,
  isLoading: PropTypes.bool,
  optionsComponent: PropTypes.func,
  width: PropTypes.string,
  isMobile: PropTypes.bool,
  bottomAction: PropTypes.func
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : 'auto')};
`
const ListContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`

export default List
