var _jsxFileName = 'src/lib/elements/FormInputs/InputLabelContainer.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout, LayoutItem } from '../Layouts';
import { default as Label } from './_InputLabel';

var InputLabelContainer = function InputLabelContainer(_ref) {
  var autoInput = _ref.autoInput,
      autoLabel = _ref.autoLabel,
      bottomAlignInput = _ref.bottomAlignInput,
      bottomAlignLabel = _ref.bottomAlignLabel,
      centerAlignInput = _ref.centerAlignInput,
      centerAlignLabel = _ref.centerAlignLabel,
      children = _ref.children,
      className = _ref.className,
      inlineLabel = _ref.inlineLabel,
      inputWrapperClassName = _ref.inputWrapperClassName,
      isRequired = _ref.isRequired,
      justifyAlignLabel = _ref.justifyAlignLabel,
      label = _ref.label,
      labelClassName = _ref.labelClassName,
      labelIcon = _ref.labelIcon,
      labelTextAlignRight = _ref.labelTextAlignRight,
      labelWrapperClassName = _ref.labelWrapperClassName,
      largeSizeInput = _ref.largeSizeInput,
      largeSizeLabel = _ref.largeSizeLabel,
      leftAlignInput = _ref.leftAlignInput,
      lgAutoInput = _ref.lgAutoInput,
      lgAutoLabel = _ref.lgAutoLabel,
      lgShrinkInput = _ref.lgShrinkInput,
      lgShrinkLabel = _ref.lgShrinkLabel,
      mdAutoInput = _ref.mdAutoInput,
      mdAutoLabel = _ref.mdAutoLabel,
      mdShrinkInput = _ref.mdShrinkInput,
      mdShrinkLabel = _ref.mdShrinkLabel,
      mediumSizeInput = _ref.mediumSizeInput,
      mediumSizeLabel = _ref.mediumSizeLabel,
      middleAlignInput = _ref.middleAlignInput,
      middleAlignLabel = _ref.middleAlignLabel,
      rightAlignInput = _ref.rightAlignInput,
      rightAlignLabel = _ref.rightAlignLabel,
      shrinkInput = _ref.shrinkInput,
      shrinkLabel = _ref.shrinkLabel,
      smallSizeInput = _ref.smallSizeInput,
      smallSizeLabel = _ref.smallSizeLabel,
      topAlignInput = _ref.topAlignInput,
      topAlignLabel = _ref.topAlignLabel;

  var labelClasses = classNames('input-label-container', {
    'input-label-container__label--top-alignment': !bottomAlignLabel && !middleAlignLabel && inlineLabel
  }, labelWrapperClassName);
  return React.createElement(
    Layout,
    { className: className, __source: {
        fileName: _jsxFileName,
        lineNumber: 58
      },
      __self: _this
    },
    label && React.createElement(
      LayoutItem,
      {
        className: labelClasses,
        shrink: shrinkLabel,
        mediumShrink: mdShrinkLabel,
        largeShrink: lgShrinkLabel,
        small: smallSizeLabel,
        medium: mediumSizeLabel,
        large: largeSizeLabel,
        auto: autoLabel,
        mediumAuto: mdAutoLabel,
        largeAuto: lgAutoLabel,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 60
        },
        __self: _this
      },
      React.createElement(
        Label,
        {
          className: labelClassName,
          centerAlign: centerAlignLabel,
          justifyAlign: justifyAlignLabel,
          rightAlign: rightAlignLabel,
          bottomAlign: bottomAlignLabel,
          topAlign: topAlignLabel,
          middleAlign: middleAlignLabel,
          inline: inlineLabel,
          imageIconUrl: labelIcon,
          textAlignRight: labelTextAlignRight,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 72
          },
          __self: _this
        },
        label,
        isRequired && React.createElement(
          'span',
          { className: 'input-label--is-required', __source: {
              fileName: _jsxFileName,
              lineNumber: 85
            },
            __self: _this
          },
          '*'
        )
      )
    ),
    React.createElement(
      LayoutItem,
      {
        className: inputWrapperClassName,
        shrink: shrinkInput,
        mediumShrink: mdShrinkInput,
        largeShrink: lgShrinkInput,
        small: smallSizeInput,
        medium: mediumSizeInput,
        large: largeSizeInput,
        auto: autoInput,
        mediumAuto: mdAutoInput,
        largeAuto: lgAutoInput,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 89
        },
        __self: _this
      },
      React.createElement(
        Layout,
        {
          bottom: bottomAlignInput,
          middle: middleAlignInput,
          top: topAlignInput,
          fullHeight: true,
          right: rightAlignInput,
          center: centerAlignInput,
          left: leftAlignInput,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 101
          },
          __self: _this
        },
        React.createElement(
          LayoutItem,
          {
            shrink: rightAlignInput || centerAlignInput || leftAlignInput,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 110
            },
            __self: _this
          },
          children
        )
      )
    )
  );
};

InputLabelContainer.propTypes = {
  /** */
  autoInput: PropTypes.bool,
  /** */
  autoLabel: PropTypes.bool,
  /** */
  bottomAlignInput: PropTypes.bool,
  /** */
  bottomAlignLabel: PropTypes.bool,
  /** */
  centerAlignInput: PropTypes.bool,
  /** */
  centerAlignLabel: PropTypes.bool,
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  inlineLabel: PropTypes.bool,
  /** */
  inputWrapperClassName: PropTypes.string,
  /** */
  isRequired: PropTypes.bool,
  /** */
  justifyAlignLabel: PropTypes.bool,
  /** */
  label: PropTypes.string,
  /** */
  labelClassName: PropTypes.string,
  /** */
  labelIcon: PropTypes.string,
  /** */
  labelTextAlignRight: PropTypes.bool,
  /** */
  labelWrapperClassName: PropTypes.string,
  /** */
  largeSizeInput: PropTypes.bool,
  /** */
  largeSizeLabel: PropTypes.bool,
  /** */
  leftAlignInput: PropTypes.bool,
  /** */
  lgAutoInput: PropTypes.bool,
  /** */
  lgAutoLabel: PropTypes.bool,
  /** */
  lgShrinkInput: PropTypes.bool,
  /** */
  lgShrinkLabel: PropTypes.bool,
  /** */
  mdAutoInput: PropTypes.bool,
  /** */
  mdAutoLabel: PropTypes.bool,
  /** */
  mdShrinkInput: PropTypes.bool,
  /** */
  mdShrinkLabel: PropTypes.bool,
  /** */
  mediumSizeInput: PropTypes.number,
  /** */
  mediumSizeLabel: PropTypes.number,
  /** */
  middleAlignInput: PropTypes.bool,
  /** */
  middleAlignLabel: PropTypes.bool,
  /** */
  rightAlignInput: PropTypes.bool,
  /** */
  rightAlignLabel: PropTypes.bool,
  /** */
  shrinkInput: PropTypes.bool,
  /** */
  shrinkLabel: PropTypes.bool,
  /** */
  smallSizeInput: PropTypes.bool,
  /** */
  smallSizeLabel: PropTypes.bool,
  /** */
  topAlignInput: PropTypes.bool,
  /** */
  topAlignLabel: PropTypes.bool
};

export default InputLabelContainer;