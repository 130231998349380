import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import {
  Typography,
  Grid,
  PhoneInput,
  TextField,
  FormContainer,
  SubmitButton,
  Button,
  Link
} from 'rc'
import { StarChecked } from 'rc/Icons'
import { HideModal, ShowFlashNotification } from 'reduxActions/services'
import { UpdateProfile, VerifyPhoneNumber } from 'reduxActions/profile'
import { SendPhoneVerificationCode } from 'reduxActions/auth'

const IconContainerStyled = styled.div`
  ${({ theme }) => theme.box(100, 100, true)}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.remCalc('30')};
`

const VerifyTelephoneMC = ({
  accountSection,
  HideModal,
  phoneNumber,
  currentPhoneNumber,
  phoneNumberVerified,
  idBase,
  token,
  UpdateProfile,
  SendPhoneVerificationCode,
  onInfoUpdated,
  VerifyPhoneNumber,
  ShowFlashNotification
}) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [sendCodeTo, setSendCodeTo] = useState('')

  const updateProfile = data => {
    UpdateProfile(token, data).then(res => {
      const { payload: { status } = {} } = res
      if (status === 200) {
        if (onInfoUpdated) onInfoUpdated(data)
      }
    })
  }

  const handleRenderModalContent = () => {
    switch (currentStep) {
      case 1:
        return phoneNumberForm
      case 2:
        return verificationCodeForm
      case 3:
        if (onInfoUpdated) onInfoUpdated({ phone_number_verified: true })
        return successVerification
      default:
        break
    }
  }

  const handlePhoneFormSubmit = async data => {
    const { user_phone_number: formPhoneNumber } = data
    if (!formPhoneNumber) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'CUSTOM'
      error.metadata = {
        field: 'user_phone_number',
        error: 'Campo requerido.'
      }
      throw error
    }

    if (!phoneNumber && formPhoneNumber) {
      await updateProfile(data)
    }

    if (phoneNumber && formPhoneNumber) {
      setSendCodeTo(formPhoneNumber)
      return SendPhoneVerificationCode(token, formPhoneNumber)
    }
    setSendCodeTo(phoneNumber)
    return SendPhoneVerificationCode(token, phoneNumber)
  }

  const handlePhoneFormSubmitSuccess = () => {
    setCurrentStep(2)
  }

  const handlePhoneFormSubmitFail = (values, fc, submitErrors) => {
    if (submitErrors) {
      const { code, metadata } = submitErrors
      switch (code) {
        case 'CUSTOM':
          fc.setCustomErrors({
            ...metadata
          })
          break
        case 'To many tries in a short time; try later':
          ShowFlashNotification(
            'danger',
            'Muchos intentos en poco tiempo, espera 1 minuto y vuelve a intentarlo.'
          )
          break
        default:
          ShowFlashNotification(
            'danger',
            'Ocurrió un error. Vuelve a intentarlo más tarde.'
          )
          break
      }
    }
  }

  const handleCodeFormSubmit = async data => {
    const { user_verification_code: code } = data
    if (!code) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'CUSTOM'
      error.metadata = {
        field: 'user_verification_code',
        error: 'Campo requerido.'
      }
      throw error
    } else if (code && code.length < 4) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'CUSTOM'
      error.metadata = {
        field: 'user_verification_code',
        error: 'El código es de 4 digitos.'
      }
      throw error
    }
    return VerifyPhoneNumber(token, code).then(async res => {
      await updateProfile({ ...data, phone_number_verified: true })
    })
  }
  const handleCodeFormSubmitSuccess = () => {
    setCurrentStep(3)
  }
  const handleCodeFormSubmitFail = (values, fc, submitErrors) => {
    if (submitErrors) {
      const {
        code,
        metadata,
        error: {
          response: { status }
        }
      } = submitErrors

      if (code) {
        switch (code) {
          case 'CUSTOM':
            fc.setCustomErrors({
              ...metadata
            })
            break
          default:
            ShowFlashNotification(
              'danger',
              'Ocurrió un error. Vuelve a intentarlo más tarde.'
            )
        }
      } else {
        switch (status) {
          case 404:
            ShowFlashNotification('danger', 'Código incorrecto.')
            break
          default:
            ShowFlashNotification(
              'danger',
              'Ocurrió un error. Vuelve a intentarlo más tarde.'
            )
        }
      }
    }
  }

  const titleVerifyModal = `Nos dimos cuenta que no has ${
    phoneNumber ? 'verificado' : 'agregado'
  } tu teléfono`

  const phoneNumberForm = (
    <FormContainer
      initialValues={{ user_phone_number: phoneNumber }}
      onSubmit={handlePhoneFormSubmit}
      onSubmitSuccess={handlePhoneFormSubmitSuccess}
      onSubmitFail={handlePhoneFormSubmitFail}
    >
      <Grid container center>
        <Typography
          variant="h2"
          textTransform="initial"
          align="center"
          css={`
            padding: ${({ theme }) => theme.remCalc('0 30')};
            margin-bottom: ${({ theme }) => theme.remCalc('30')};
          `}
        >
          {accountSection
            ? phoneNumberVerified
              ? 'Verifica tu nuevo número de teléfono'
              : 'Verifica tu número de teléfono'
            : titleVerifyModal}
        </Typography>
        <Typography
          variant="p"
          align="center"
          css={`
            margin-bottom: ${({ theme }) => theme.remCalc('30')};
          `}
        >
          Completa tus datos para poder ofrecer una mejor imágen a tus clientes.
          Te enviaremos un código a tu número celular por SMS para verificarlo.
        </Typography>
        <PhoneInput
          id={`${idBase}-verifyTelephone-mc-input-userPhoneNumber`}
          name="user_phone_number"
          label="Celular"
          required
          validate={(value, values, currentError, fc, localError) => {
            const err = !value ? 'Campo requerido.' : value ? localError : ''

            if (!err && !values.contact_email) {
              fc.setCustomErrors({ contact_email: '' })
            }

            return err
          }}
        />
      </Grid>
      <Grid
        container
        justify="space-between"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('60')};
        `}
      >
        <Grid item flat xs={10}>
          <Button
            id={`${idBase}-verifyTelephone-mc-button-later`}
            full
            variant="outlined"
            color="gray"
            onClick={HideModal}
            size="large"
          >
            Más tarde
          </Button>
        </Grid>
        <Grid item flat xs={10}>
          <SubmitButton
            id={`${idBase}-verifyTelephone-mc-button-next`}
            full
            size="large"
          >
            Siguiente
          </SubmitButton>
        </Grid>
      </Grid>
    </FormContainer>
  )

  const verificationCodeForm = (
    <FormContainer
      onSubmit={handleCodeFormSubmit}
      onSubmitSuccess={handleCodeFormSubmitSuccess}
      onSubmitFail={handleCodeFormSubmitFail}
    >
      <Grid container center>
        <Typography
          variant="h2"
          textTransform="initial"
          align="center"
          css={`
            padding: ${({ theme }) => theme.remCalc('0 30')};
            margin-bottom: ${({ theme }) => theme.remCalc('30')};
          `}
        >
          Añade el código personal que te enviamos por SMS
        </Typography>
        <TextField
          id={`${idBase}-verifyTelephone-mc-input-userVerificationCode`}
          label="Código"
          type="number"
          name="user_verification_code"
          validate={(value, values, currentError, fc, localError) => {
            const err =
              value && value.length < 4
                ? 'El código es de 4 digitos.'
                : value
                ? localError
                : ''

            if (!err && !values.contact_email) {
              fc.setCustomErrors({ contact_email: '' })
            }

            return err
          }}
        />
        <Typography
          variant="p"
          align="center"
          css={`
            margin-top: ${({ theme }) => theme.remCalc('30')};
          `}
        >
          ¿No recibiste ningun mensaje?{' '}
          <Link
            id={`${idBase}-verifyTelephone-mc-button-resendCode`}
            component="button"
            onClick={e => {
              e.preventDefault()
              return SendPhoneVerificationCode(token, sendCodeTo)
            }}
            css={`
              color: ${({ theme }) => theme.palette.primary.main};
            `}
          >
            Enviar de nuevo
          </Link>
        </Typography>
      </Grid>
      <Grid
        container
        justify="space-between"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('60')};
        `}
      >
        <Grid item flat xs={10}>
          <Button
            id={`${idBase}-verifyTelephone-mc-button-cancel`}
            full
            variant="outlined"
            color="gray"
            onClick={HideModal}
            size="large"
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item flat xs={10}>
          <SubmitButton
            id={`${idBase}-verifyTelephone-mc-button-submit`}
            full
            size="large"
          >
            Verificar
          </SubmitButton>
        </Grid>
      </Grid>
    </FormContainer>
  )

  const successVerification = (
    <Grid container center direction="column">
      <IconContainerStyled>
        <StarChecked
          css={`
            color: ${({ theme }) => theme.palette.primary.main};
          `}
        />
      </IconContainerStyled>
      <Typography
        variant="h2"
        textTransform="initial"
        align="center"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('30')};
        `}
      >
        Tu teléfono se verificó correctamente
      </Typography>
      <Button
        id={`${idBase}-verifyTelephone-mc-button-close`}
        full
        variant="outlined"
        color="gray"
        onClick={HideModal}
        size="large"
      >
        Cerrar
      </Button>
    </Grid>
  )
  return (
    <div
      currentStep={currentStep}
      css={`
        padding: ${({ theme }) => theme.remCalc('30 20')};
        width: ${({ theme, currentStep }) =>
          currentStep === 3 ? theme.remCalc('350') : theme.remCalc('550')};
        max-width: 100%;
        ${({ theme }) => theme.media.desktop`
          padding: ${({ theme }) => theme.remCalc('30 60')};
        `}
      `}
    >
      {handleRenderModalContent()}
    </div>
  )
}

VerifyTelephoneMC.defaultProps = {
  phoneNumber: null
}

VerifyTelephoneMC.propTypes = {
  accountSection: PropTypes.bool,
  currentPhoneNumber: PropTypes.number,
  HideModal: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  token: PropTypes.string,
  idBase: PropTypes.string,
  phoneNumberVerified: PropTypes.bool,
  UpdateProfile: PropTypes.func,
  SendPhoneVerificationCode: PropTypes.func,
  onInfoUpdated: PropTypes.func,
  VerifyPhoneNumber: PropTypes.func,
  ShowFlashNotification: PropTypes.func
}

const mapStateToProps = ({
  authReducer: { token },
  profileReducer: { phoneNumber: currentPhoneNumber, phoneNumberVerified }
}) => ({
  phoneNumberVerified,
  currentPhoneNumber,
  token
})

const mapDispatchToProps = {
  HideModal,
  UpdateProfile,
  SendPhoneVerificationCode,
  VerifyPhoneNumber,
  ShowFlashNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyTelephoneMC)
