import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
<svg width="200" height="200" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_2801_54717)">
    <circle cx="50" cy="50" r="45" fill="white"/>
  </g>  
</svg>,
'Ellipse',
'0 0 140 140'
)