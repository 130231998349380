export const STORE_PLANS = {
  FREE: {
    title: 'Incluye todas las características gratuitas:',
    list: [
      'Busca propiedades de multiples portales.',
      'Carga y administra tus propiedades.',
      'Crea requerimientos.',
      'Crea tu sitio web.',
      'Crea tu tarjeta de presentación digital.',
      'Accede a tu tablero de tareas.',
      'Comparte fichas de propiedades.',
      'Integración con portales.',
      'Agrega asesores ilimitados a tu equipo.',
      'Agrega contactos de tus clientes.'
    ],
    min_price: null
  },
  '1_USER_MONTHLY': {
    title: 'Incluye todas las características de Wiggot sin costo más:',
    list: [
      'Activa las sugerencias de tus requerimientos.',
      'Accede a tus reportes y los de tu inmobiliaria.',
      'Ofrece tus propiedades a otros asesores.',
      'Edita fichas técnicas de otros asesores.',
      'Descarga fichas técnicas sin datos de contacto.',
      'Agenda tareas para tus clientes.',
      'Conexión segura para tu dominio personal.',
      'Sección de propiedades en tu sitio web personal.',
      'Agrega asesores ilimitados a tu equipo.',
      'Data de tu sitio web. (A través de la instalación de tu cuenta en Google Tag Manager)'
    ],
    min_price: 398
  },
  '1_USER_MONTHLY_10_OFF': {
    title: 'Incluye todas las características de Wiggot sin costo más:',
    list: [
      'Busca propiedades de multiples portales.',
      'Carga y administra tus propiedades.',
      'Crea requerimientos.',
      'Crea tu sitio web.',
      'Crea tu tarjeta de presentación digital.',
      'Accede a tu tablero de tareas.',
      'Comparte fichas de propiedades.',
      'Integración con portales.',
      'Agrega asesores ilimitados a tu equipo.',
      'Agrega contactos de tus cliente.'
    ],
    min_price: 398,
    discount_price: 358
  },
  '1_USER_PRO_MONTHLY': {
    title: 'Incluye todas las características de Wiggot Prime más:',
    list: [
      '5 opiniones de valor de Avaclick para tus propiedades.',
      '1 cuenta en Wiggot Social para administrar tus publicaciones en diferentes redes sociales.',
      'Todos los planes de paga incluyen Prime para 1 asesor.'
    ],
    min_price: 799
  },
  '1_USER_PRO_MONTHLY_10_OFF': {
    title: 'Incluye todas las características de Wiggot Prime más:',
    list: [
      '5 opiniones de valor de Avaclick para tus propiedades.',
      '1 cuenta en Wiggot Social para administrar tus publicaciones en diferentes redes sociales.',
      'Todos los planes de paga incluyen Prime para 1 asesor.'
    ],
    min_price: 799,
    discount_price: 719
  },
  WPASS: {
    title: 'Incluye todas las características de Wiggot Prime más:',
    list: [
      '15 publicaciones al mes en inmbuebles24 (10 anuncios simples + 5 anuncios destacados)',
      'Todos los planes de paga incluyen Prime para 1 asesor.'
    ],
    min_price: 999
  },
  WPASS_10_OFF: {
    title: 'Incluye todas las características de Wiggot Prime más:',
    list: [
      '15 publicaciones al mes en inmbuebles24 (10 anuncios simples + 5 anuncios destacados)',
      'Todos los planes de paga incluyen Prime para 1 asesor.'
    ],
    min_price: 999,
    discount_price: 899
  },
  '1_USER_FULL_MONTHLY': {
    title: 'Incluye todas las características de Wiggot Prime más:',
    list: [
      '5 opiniones de valor de Avaclick para tus propiedades.',
      '1 cuenta en Wiggot Social para administrar tus posts en diferentes redes sociales.',
      '15 publicaciones al mes en inmbuebles24 (10 anuncios simples + 5 anuncios destacados).',
      'Todos los planes de paga incluyen Prime para 1 asesor. '
    ],
    min_price: 1400
  },
  '1_USER_FULL_MONTHLY_10_OFF': {
    title: 'Incluye todas las características de Wiggot Prime más:',
    list: [
      '5 opiniones de valor de Avaclick para tus propiedades.',
      '1 cuenta en Wiggot Social para administrar tus posts en diferentes redes sociales.',
      '15 publicaciones al mes en inmbuebles24 (10 anuncios simples + 5 anuncios destacados).',
      'Todos los planes de paga incluyen Prime para 1 asesor. '
    ],
    min_price: 1400,
    discount_price: 1260
  },
  TEMP_PLAN_PRIME: {
    title: 'Incluye todas las características de Wiggot sin costo más:',
    list: [
      'Activa las sugerencias de tus requerimientos.',
      'Accede a tus reportes y los de tu inmobiliaria.',
      'Ofrece tus propiedades a otros asesores.',
      'Edita fichas técnicas de otros asesores.',
      'Descarga fichas técnicas sin datos de contacto.',
      'Agenda tareas para tus clientes.',
      'Conexión segura para tu dominio personal.',
      'Sección de propiedades en tu sitio web personal.',
      'Agrega asesores ilimitados a tu equipo.',
      'Data de tu sitio web. (A través de la instalación de tu cuenta en Google Tag Manager)'
    ]
  },
  TEMP_PLAN_PRO: {
    title: 'Incluye todas las características de Wiggot Prime más:',
    list: [
      '5 opiniones de valor de Avaclick para tus propiedades.',
      '1 cuenta en Wiggot Social para administrar tus publicaciones en diferentes redes sociales.',
      'Todos los planes de paga incluyen Prime para 1 asesor.'
    ]
  },
  TEMP_PLAN_PASS: {
    title: 'Incluye todas las características de Wiggot Prime más:',
    list: [
      '15 publicaciones al mes en inmbuebles24 (10 anuncios simples + 5 anuncios destacados)',
      'Todos los planes de paga incluyen Prime para 1 asesor.'
    ]
  },
  TEMP_PLAN_FULL: {
    title: 'Incluye todas las características de Wiggot Prime más:',
    list: [
      '5 opiniones de valor de Avaclick para tus propiedades.',
      '1 cuenta en Wiggot Social para administrar tus posts en diferentes redes sociales.',
      '15 publicaciones al mes en inmbuebles24 (10 anuncios simples + 5 anuncios destacados).',
      'Todos los planes de paga incluyen Prime para 1 asesor. '
    ]
  }
}

export const SINGLE_PLANS_ORDERED = [
  'FREE',
  '1_USER_MONTHLY',
  '1_USER_PRO_MONTHLY',
  'WPASS',
  '1_USER_FULL_MONTHLY'
]

export const SINGLE_PLANS_AMPI_ORDERED = [
  'FREE',
  '1_USER_MONTHLY_10_OFF',
  '1_USER_PRO_MONTHLY_10_OFF',
  'WPASS_10_OFF',
  '1_USER_FULL_MONTHLY_10_OFF'
]

export const TEMPORALY_PLANS_LABELS = {
  month: 'Mensual',
  trimester: 'Trimestral',
  semester: 'Semestral',
  year: 'Anual'
}

export const TEMPORALY_PLANS_LABELS_MOBILE = {
  month: 'Mensual',
  trimester: '3 meses',
  semester: '6 meses',
  year: 'Anual'
}

export const PLAN_DESCRIPTION = {
  PRIME: 'Accede a las herramientas que mejorarán tus negocios inmobiliarios.',
  PRO: 'Maneja tus redes sociales y obtén métricas precisas del éxito de cada publicación.',
  PASS: '15 publicaciones al mes en inmuebles24 para promover tus propiedades.',
  FULL: 'Disfruta lo mejor de todos los planes Wiggot en uno solo.'
}

export const PLAN_BENEFITS = {
  PRIME: {
    title: 'Incluye todas las caracterísiticas de Wiggot gratuito más:',
    list: [
      { title: 'Busca propiedades a través del mapa' },
      { title: 'Activa los matches en requerimientos' },
      { title: 'Ofrece tus propiedades a otros asesores' },
      { title: 'Edita fichas técnicas de otros asesores' },
      { title: 'Descarga fichas técnicas sin datos de contacto' },
      { title: 'Accede a tu tablero de tareas' },
      {
        title: '100 reportes de Monopolio al mes para tus propiedades',
        description:
          '(Opiniones de valor de casas y departamentos que te brindan información confiable del precio de mercado del inmueble.)'
      },
      // { title: 'Publicaciones ilimitadas en portales Prime' },
      { title: 'Conexión segura para tu dominio personalizado' },
      { title: 'Sección de propiedades en tu sitio web personalizado' },
      {
        title:
          'Data de tu sitio web (a través de tu cuenta en Google Tag Manager)'
      }
    ]
  },
  PRO: {
    title: 'Incluye todas las caracterísiticas de Wiggot Prime más:',
    list: [
      {
        title: '100 reportes de Monopolio al mes para tus propiedades',
        description:
          '(Opiniones de valor de casas y departamentos que te brindan información confiable del precio de mercado del inmueble.)'
      },
      {
        title: '1 cuenta en Wiggot Social',
        description:
          '(Vincula las redes sociales de tu inmobiliaria para gestionar publicaciones y obtener métricas precisas de cada una de ellas.)'
      }
    ]
  },
  PASS: {
    title: 'Incluye todas las caracterísiticas de Wiggot Prime más:',
    list: [{ title: '15 anuncios en Inmuebles24' }]
  },
  FULL: {
    title: 'Incluye todas las características de Wiggot Prime más:',
    list: [
      {
        title: '100 reportes de Monopolio al mes para tus propiedades',
        description:
          '(Opiniones de valor de casas y departamentos que te brindan información confiable del precio de mercado del inmueble.)'
      },
      {
        title: '1 cuenta en Wiggot Social',
        description:
          '(Vincula las redes sociales de tu inmobiliaria para gestionar publicaciones y obtener métricas precisas de cada una de ellas.)'
      },
      { title: '15 anuncios en Inmuebles24' }
    ]
  }
}

export const PLANS_COMPARATIVE_BENEFITS = [
  {
    title: 'Busca propiedades',
    plans: ['FREE', 'PRIME', 'PASS', 'FULL']
  },
  {
    title: 'Carga propiedades',
    plans: ['FREE', 'PRIME', 'PASS', 'FULL']
  },
  {
    title: 'Crea requerimientos',
    plans: ['FREE', 'PRIME', 'PASS', 'FULL']
  },
  {
    title: 'Agrega contactos de tus clientes',
    plans: ['FREE', 'PRIME', 'PASS', 'FULL']
  },
  {
    title: 'Crea tu sitio web',
    plans: ['FREE', 'PRIME', 'PASS', 'FULL']
  },
  {
    title: 'Crea tu tarjeta de presentación digital',
    plans: ['FREE', 'PRIME', 'PASS', 'FULL']
  },
  {
    title: 'Agrega asesores ilimitados a tu equipo',
    plans: ['FREE', 'PRIME', 'PASS', 'FULL']
  },
  {
    title: 'Accede a tu tablero de tareas',
    plans: ['FREE', 'PRIME', 'PASS', 'FULL']
  },
  {
    title: 'Comparte fichas de propiedades',
    plans: ['FREE', 'PRIME', 'PASS', 'FULL']
  },
  {
    title: 'Integración con portales',
    plans: ['FREE', 'PRIME', 'PASS', 'FULL']
  },
  {
    title: 'Activa las sugerencias de requerimientos',
    plans: ['PRIME', 'PASS', 'FULL'],
    bold: true
  },
  {
    title: 'Accede a tus reportes y los de tu inmobiliaria',
    plans: ['PRIME', 'PASS', 'FULL'],
    bold: true
  },
  {
    title: 'Ofrece tus propiedades a otros asesores',
    plans: ['PRIME', 'PASS', 'FULL'],
    bold: true
  },
  {
    title: 'Edita fichas técnicas de otros asesores',
    plans: ['PRIME', 'PASS', 'FULL'],
    bold: true
  },
  {
    title: 'Descarga fichas técnicas sin datos de contacto',
    plans: ['PRIME', 'PASS', 'FULL'],
    bold: true
  },
  {
    title: 'Agenda tareas para tus clientes',
    plans: ['PRIME', 'PASS', 'FULL'],
    bold: true
  },
  {
    title: 'Conexión segura para tu dominino personalizado',
    plans: ['PRIME', 'PASS', 'FULL'],
    bold: true
  },
  {
    title: 'Sección de propiedades en tu sitio web personalizado',
    plans: ['PRIME', 'PASS', 'FULL'],
    bold: true
  },
  {
    title: 'Data de tu sitio web (a través de Google Tag Manager)',
    plans: ['PRIME', 'PASS', 'FULL'],
    bold: true
  },
  {
    title: '100 reportes de Monopolio al mes para tus propiedades',
    plans: ['PRIME'],
    bold: true
  },
  {
    title: '1 cuenta en Wiggot Social (administra redes sociales)',
    plans: ['FULL'],
    bold: true
  }
]

/*export const OLD_PLAN_SUMMARY = {
  '1_USER_MONTHLY': {
    value: '1_USER_MONTHLY',
    plan_id: 'PRIME',
    frequency: 'MONTH',
    price: '398.00',
    discount: null,
  },
  '1_USER_MONTHLY_10_OFF': {
    value: '1_USER_MONTHLY_10_OFF',
    plan_id: 'PRIME',
    frequency: 'MONTH',
    price: '358.00',
    discount: '10%',
  },
  '1_USER_PRO_MONTHLY': {
    value: '1_USER_PRO_MONTHLY',
    plan_id: 'PRO',
    frequency: 'MONTH',
    price: '799.00',
    discount: null,
  },
  'WIGGOT_PRO': {
    value: 'WIGGOT_PRO',
    plan_id: 'PRO',
    frequency: 'MONTH',
    price: '799.00',
    discount: null,
  },
  '1_USER_PRO_MONTHLY_10_OFF': {
    value: '1_USER_PRO_MONTHLY_10_OFF',
    plan_id: 'PRO',
    frequency: 'MONTH',
    price: '719.00',
    discount: '10%',
  },
  'WPASS': {
    value: 'WPASS',
    plan_id: 'PASS',
    frequency: 'MONTH',
    price: '999.00',
    discount: null,
  },
  'WPASS_10_OFF': {
    value: 'WPASS_10_OFF',
    plan_id: 'PASS',
    frequency: 'MONTH',
    price: '899.00',
    discount: '10%',
  },
  '1_USER_FULL_MONTHLY': {
    value: '1_USER_FULL_MONTHLY',
    plan_id: 'FULL',
    frequency: 'MONTH',
    price: '1400.00',
    discount: null,
  },
  'FULL': {
    value: 'FULL',
    plan_id: 'FULL',
    frequency: 'MONTH',
    price: '1400.00',
    discount: null,
  },
  '1_USER_FULL_MONTHLY_10_OFF': {
    value: '1_USER_FULL_MONTHLY_10_OFF',
    plan_id: 'FULL',
    frequency: 'MONTH',
    price: '1260.00',
    discount: '10%',
  }
}*/

export const PORTAL_PLANS_TYPES = {
  I24: 'Inmuebles24 + Vivanuncios',
  M3: 'MercadoLibre',
  LAMUDI: 'Lamudi'
}

export const I24_NEW_PLANS = [
  {
    type: 'i24 5 + 5',
    type_description: 'Simples 5',
    type_id: 1,
    payment: {
      allowed_by_frequencies: ['MONTH'],
      min_price_by_frequencies: {
        MONTH: '2662.00'
      },
      min_price: '2662.00'
    },
    credits: [
      {
        type: 'SIMPLE',
        type_pretty: 'simples en cada portal',
        credits: 5,
        portal: 'I24'
      }
    ],
    available_frecuencies: ['MONTH']
  },
  {
    type: 'i24 10 + 10',
    type_description: 'Simples 10',
    type_id: 2,
    payment: {
      allowed_by_frequencies: ['MONTH'],
      min_price_by_frequencies: {
        MONTH: '3995.00'
      },
      min_price: '3995.00'
    },
    credits: [
      {
        type: 'SIMPLE',
        type_pretty: 'simples en cada portal',
        credits: 10,
        portal: 'I24'
      }
    ],
    available_frecuencies: ['MONTH']
  },
  {
    type: 'i24 5d',
    type_description: 'Destacados 5',
    type_id: 9,
    payment: {
      allowed_by_frequencies: ['MONTH'],
      min_price_by_frequencies: {
        MONTH: '4307.00'
      },
      min_price: '4307.00'
    },
    credits: [
      {
        type: 'DESTACADO',
        type_pretty: 'destacados en cada portal',
        credits: 5,
        portal: 'I24'
      }
    ],
    available_frecuencies: ['MONTH']
  },
  {
    type: 'i24 5sd',
    type_description: '5 Súper destacados',
    type_id: 10,
    payment: {
      allowed_by_frequencies: ['MONTH'],
      min_price_by_frequencies: {
        MONTH: '8613.00'
      },
      min_price: '8613.00'
    },
    credits: [
      {
        type: 'SUPER_DESTACADO',
        type_pretty: 'súper destacados en cada portal',
        credits: 5,
        portal: 'I24'
      }
    ],
    available_frecuencies: ['MONTH']
  },
  {
    type: 'i24 30 + 30',
    type_description: '30 Simples',
    type_id: 11,
    payment: {
      allowed_by_frequencies: ['MONTH'],
      min_price_by_frequencies: {
        MONTH: '6699.00'
      },
      min_price: '6699.00'
    },
    credits: [
      {
        type: 'SIMPLE',
        type_pretty: 'anuncios simples para publicar en cualquier portal',
        credits: 30,
        portal: 'I24'
      }
    ],
    available_frecuencies: ['MONTH']
  },
  {
    type: 'i24 50 + 50',
    type_description: '50 Simples',
    type_id: 11,
    payment: {
      allowed_by_frequencies: ['MONTH'],
      min_price_by_frequencies: {
        MONTH: '8418.00'
      },
      min_price: '8418.00'
    },
    credits: [
      {
        type: 'SIMPLE',
        type_pretty: 'anuncios simples para publicar en cualquier portal',
        credits: 50,
        portal: 'I24'
      }
    ],
    available_frecuencies: ['MONTH']
  },
  {
    type: 'i24 100 + 100',
    type_description: '100 Simples',
    type_id: 11,
    payment: {
      allowed_by_frequencies: ['MONTH'],
      min_price_by_frequencies: {
        MONTH: '12789.00'
      },
      min_price: '12789.00'
    },
    credits: [
      {
        type: 'SIMPLE',
        type_pretty: 'anuncios simples para publicar en cualquier portal',
        credits: 100,
        portal: 'I24'
      }
    ],
    available_frecuencies: ['MONTH']
  }
]
