import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { Typography, Spinner } from 'rc'
import { Location, BarCode, Price } from 'rc/Icons'
import { AssignCreditToDevelopment } from 'reduxActions/tokens'
import { Button, Image } from 'components'
import { formatPrice } from 'utils/helpers'
import { ConfirmationMC } from 'modules/development/modalContent'
import Inm24Img from 'static/logos/inmuebles24.svg'

const AssignCreditMC = ({
  cb,
  developments,
  development_id,
  HideModal,
  token,
  token_id,
  unlinkCredit,
  unlink,
  ShowModal
}) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const getDevelopmentData = () => {
    const developmentData =
      (development_id &&
        developments.filter(dev => dev.development_id === development_id)) ||
      []
    const development = developmentData[0] || {}

    const operationTypes = [
      {
        value: formatPrice(development.min_presale_price),
        label: 'Preventa:',
        active: development.has_presale
      },
      {
        value: formatPrice(development.min_sale_price),
        label: 'Venta:',
        active: development.has_sale
      },
      {
        value: formatPrice(development.min_lease_price),
        label: 'Renta:',
        active: development.has_lease
      }
    ]

    return { ...development, operationTypes }
  }

  const development = getDevelopmentData()

  const assignCredit = (credit = 'inmuebles24') => {
    setIsLoading(true)
    dispatch(AssignCreditToDevelopment(token, credit, development_id))
      .then(res => {
        setIsLoading(false)
        cb && cb()
        ShowModal(ConfirmationMC, {
          title: '¡Tu desarrollo se ha publicado!',
          description:
            'Tu desarrollo ahora se encuentra publicado en el portal de Inmuebles24.'
        })
      })
      .catch(err => {
        console.log({ err }, 'Assign Token Error')
        setIsLoading(false)
      })
  }

  const handleUnlinkCredit = async () => {
    setIsLoading(true)
    await unlinkCredit(development_id, 'inmuebles24')
    setIsLoading(false)
    cb && cb()
    ShowModal(ConfirmationMC, {
      title: '¡Tu desarrollo se ha eliminado!',
      description:
        'Tu desarrollo se ha eliminado del portal de Inmuebles24 exitosamente.'
    })
  }

  return (
    <Container>
      <Typography variant="h3" color="palette.primary.main" align="center">
        {unlink ? 'Elimina tu publicación de' : 'Publica tu Desarrollo en'}
      </Typography>
      <img src={Inm24Img} alt="i24" />
      <Typography variant="body2" color="palette.new.text" align="center">
        {unlink
          ? 'Tu desarrollo se eliminará del portal de Inmuebles24 y no podrás acceder a la información de reportes de Leads.'
          : 'Publica tu desarrollo en inmuebles24 y obtén reportes de leads del portal.'}
      </Typography>
      <div
        css={`
          width: 100%;
          min-height: ${({ theme }) => theme.remCalc('130')};
          border: 2px solid #e8ecf9;
          border-radius: ${({ theme }) => theme.remCalc('10')};
          display: flex;
          padding: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        <div
          css={`
            min-width: ${({ theme }) => theme.remCalc('110')};
            width: ${({ theme }) => theme.remCalc('110')};
            height: 100%;
            overflow: hidden;
            border-radius: ${({ theme }) => theme.remCalc('10')};
          `}
        >
          <Image
            id={development.cover_image}
            objectFit="cover"
            css={`
              width: 100%;
              height: 100%;
            `}
          />
        </div>
        <div
          css={`
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-left: ${({ theme }) => theme.remCalc('10')};
          `}
        >
          <Typography variant="subtitle3">
            {development.development_name}
          </Typography>
          <TypographyStyled>
            <Location
              css={`
                ${CommonIcon}
              `}
            />{' '}
            {development.city}, {development.state}.
          </TypographyStyled>
          {development.operationTypes &&
            development.operationTypes.map(
              ({ value, label, active }, index) =>
                active && (
                  <TypographyStyled>
                    <Price
                      css={`
                        ${CommonIcon}
                      `}
                    />{' '}
                    {label} {`$${value}`} mxn
                  </TypographyStyled>
                )
            )}
          <TypographyStyled variant="body2">
            <BarCode
              css={`
                ${CommonIcon}
              `}
            />{' '}
            {development.development_id}
          </TypographyStyled>
        </div>
      </div>
      <div
        css={`
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <Button
          type="tertiary"
          rounded
          onClick={HideModal}
          css={`
            margin-right: ${({ theme }) => theme.remCalc('20')};
          `}
        >
          Cancelar
        </Button>
        <Button
          type={unlink ? 'alert' : 'primary'}
          rounded
          onClick={() => (unlink ? handleUnlinkCredit() : assignCredit())}
        >
          {unlink ? 'Eliminar de' : 'Publicar en'} Inmuebles24
        </Button>
      </div>
      {isLoading && (
        <div
          css={`
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
          `}
        >
          <Spinner />
        </div>
      )}
    </Container>
  )
}

AssignCreditMC.propTypes = {
  cb: PropTypes.func,
  developments: PropTypes.array,
  development_id: PropTypes.string,
  HideModal: PropTypes.func,
  ShowModal: PropTypes.func,
  unlink: PropTypes.bool,
  unlinkCredit: PropTypes.func,
  token: PropTypes.string,
  token_id: PropTypes.string
}
const CommonIcon = css`
  color: #7d7f9c;
  fill: #7d7f9c;
  margin-right: ${({ theme }) => theme.remCalc('5')};
  ${({ theme }) => theme.box(13, 13)}
`

const Container = styled.div`
  width: ${({ theme }) => theme.remCalc('470')};
  height: ${({ theme }) => theme.remCalc('430')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('30')};
  position: relative;
`
const TypographyStyled = styled(Typography).attrs({
  variant: 'body2',
  color: 'palette.new.text'
})``

export default AssignCreditMC
