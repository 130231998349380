var _jsxFileName = 'src/lib/generics/FormInputViews/Textarea.js',
    _this = this;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var BASE_CLASSNAME = 'text-area';
var Textarea = function Textarea(_ref) {
  var className = _ref.className,
      touched = _ref.touched,
      disabled = _ref.disabled,
      error = _ref.error,
      maxLength = _ref.maxLength,
      _onChange = _ref.onChange,
      props = _objectWithoutProperties(_ref, ['className', 'touched', 'disabled', 'error', 'maxLength', 'onChange']);

  var textareaClasses = classNames(className, BASE_CLASSNAME, {
    'input-container--error': touched && error
  });
  return React.createElement(
    Fragment,
    {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 19
      },
      __self: _this
    },
    React.createElement('textarea', Object.assign({
      className: textareaClasses,
      disabled: disabled,
      onChange: function onChange(e) {
        var _e$target$value = e.target.value,
            value = _e$target$value === undefined ? '' : _e$target$value;

        if (maxLength && value.length > maxLength) {
          return;
        }
        _onChange(e);
      }
    }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 20
      },
      __self: _this
    })),
    touched && error && React.createElement(
      'span',
      { className: 'input-container__error-message', __source: {
          fileName: _jsxFileName,
          lineNumber: 36
        },
        __self: _this
      },
      error
    )
  );
};

Textarea.propTypes = {
  /** */
  className: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** */
  error: PropTypes.string,
  /** */
  maxLength: PropTypes.number,
  /** */
  onChange: PropTypes.func,
  /** */
  touched: PropTypes.bool
};

export default Textarea;