import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g fill="#none" fillRule="evenodd">
      <path d="M116.236 109.962L91.341 85.066c7.592-9.008 12.181-20.629 12.181-33.303C103.522 23.221 80.301 0 51.761 0 30.269 0 0 16.06 0 51.763c0 28.542 23.22 51.76 51.761 51.76 12.271 0 23.552-4.301 32.434-11.463l24.971 24.972a4.983 4.983 0 0 0 3.535 1.465 5 5 0 0 0 3.535-8.535zM51.761 97.523C26.529 97.523 6 76.996 6 51.763 6 20.198 32.76 6 51.761 6c25.232 0 45.761 20.529 45.761 45.763 0 25.233-20.528 45.76-45.761 45.76z" />
      <path d="M79.825 51.294L54.176 30.747a3.406 3.406 0 0 0-4.253 0l-25.65 20.549a3.4 3.4 0 0 0-.526 4.779 3.397 3.397 0 0 0 4.78.526l1.985-1.589v16.16a3.398 3.398 0 0 0 3.4 3.397h36.272a3.401 3.401 0 0 0 3.402-3.397V55.01l1.984 1.593a3.39 3.39 0 0 0 2.123.745c1 0 1.982-.434 2.658-1.271a3.403 3.403 0 0 0-.526-4.784zM66.786 67.77h-8.998v-7.368a3.402 3.402 0 0 0-3.402-3.4h-4.675a3.398 3.398 0 0 0-3.397 3.4v7.368h-8.998V49.564l14.731-11.807 14.735 11.808v18.204h.004z" />
    </g>
  </Fragment>,
  'SearcherWiggotHouse',
  '0 0 118 119'
)
