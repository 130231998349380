import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography, Spinner } from 'rc'
import { Warning } from 'rc/Icons'
import { Button } from 'src/components'

const AssignTokenConfirmationMC = ({
  title,
  description,
  action,
  HideModal
}) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Container>
      <Warning
        css={`
          fill: #ffbc08;
          ${({ theme }) => theme.box(40, 40)}
        `}
      />
      <Typography variant="h2" color="palette.primary.main" align="center">
        {title}
      </Typography>
      <Typography variant="body1" color="palette.new.text" align="center">
        {description}
      </Typography>
      <ButtonsContainer>
        <ButtonStyled type="tertiary" onClick={HideModal}>
          Cancelar
        </ButtonStyled>
        <ButtonStyled
          type="primary"
          onClick={() => {
            setIsLoading(true)
            action && action(HideModal)
          }}
        >
          Confirmar
        </ButtonStyled>
      </ButtonsContainer>
      {isLoading && (
        <div
          css={`
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
          `}
        >
          <Spinner />
        </div>
      )}
    </Container>
  )
}

AssignTokenConfirmationMC.propTypes = {
  action: PropTypes.func,
  description: PropTypes.string,
  HideModal: PropTypes.func,
  title: PropTypes.string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('30')};
  ${({ theme }) => theme.box(450, 350)}
`
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const ButtonStyled = styled(Button).attrs({ rounded: true })`
  width: 48%;
`

export default AssignTokenConfirmationMC
