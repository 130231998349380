import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Grid, Button } from 'rc'
import { Camera } from 'rc/Icons'
import { Image } from 'components'
import { HideModal, ShowModal } from 'reduxActions/services'
import { CropImageMC } from 'modules/profile/modalContent'

const ImageContainerStyled = styled(Grid)`
  position: relative;
  ${({ theme, boxSize }) =>
    boxSize ? theme.box(boxSize, boxSize) : theme.box(200, 200)}
  color: ${({ theme }) => theme.palette.base.white};
  overflow: hidden;
  margin: 0 auto;
  border-radius: 100%;
  ${({ theme }) => theme.bordered(8, theme.palette.base.white)}
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #97a4b1;
`

const ImageCropperUploader = ({
  showCameraButton,
  Holder,
  aspect,
  imageId,
  cropShape,
  uploadType,
  onImageSelected,
  ShowModal,
  HideModal,
  idBase,
  boxSize,
  ...props
}) => {
  const fileInput = useRef()
  const [customFile, setCustomFile] = useState(null)

  const showCroppingModal = useCallback(field => {
    ShowModal(CropImageMC, {
      onImageSelected: imageId => {
        onImageSelected(imageId)
        HideModal()
      },
      imageId,
      customFile,
      aspect, // field === 'user_photo' ? 1 : 1080 / 250,
      cropShape, // field === 'user_photo' ? 'round' : null,
      type: uploadType // field === 'user_photo' ? 'avatar' : 'photo'
    })
  }, [HideModal, ShowModal, aspect, cropShape, customFile, imageId, onImageSelected, uploadType])

  useEffect(() => {
    if (customFile) {
      showCroppingModal(customFile)
    }
  }, [customFile, showCroppingModal])

  const handleUploadPhoto = () => {
    if (imageId) showCroppingModal(imageId)
    else {
      setCustomFile(null)
      if (fileInput && fileInput.current) {
        fileInput.current.click()
      }
    }
  }

  const onChange = e => {
    e.stopPropagation()
    e.preventDefault()
    const {
      target: { files = [] }
    } = e
    if (files.length > 0) setCustomFile(files[0])
  }

  return (
    <div
      css={`
        margin-bottom: 35px;
      `}
    >
      <Grid
        {...props}
        boxSize={boxSize}
        css={`
          ${({ theme, boxSize }) =>
            boxSize ? theme.box(boxSize, boxSize) : theme.box(200, 200)}
          position: relative;
        `}
        container
        justify="center"
      >
        <input
          ref={fileInput}
          type="file"
          accept="image/*"
          css={`
            display: none;
          `}
          onChange={onChange}
        />
        <ImageContainerStyled boxSize={boxSize}>
          {imageId ? (
            <Image
              id={imageId}
              objectFit="cover"
              size="l"
              alt="image-selected"
            />
          ) : (
            <Holder />
          )}
        </ImageContainerStyled>
        {showCameraButton && (
          <Button
            id={`${idBase}-button-showCroppingModal`}
            onClick={handleUploadPhoto}
            css={`
              ${({ theme }) => theme.position('absolute', null, null, -30)}
              ${({ theme }) => theme.box(60, 60)}
            `}
            radius="100%"
          >
            <Camera
              css={`
                &&& {
                  ${({ theme }) => theme.box(60, 30)}
                }
              `}
            />
          </Button>
        )}
      </Grid>
    </div>
  )
}

ImageCropperUploader.defaultProps = {
  aspect: 1,
  showCameraButton: false,
  type: 'photo'
}

ImageCropperUploader.propTypes = {
  onImageSelected: PropTypes.func,
  showCameraButton: PropTypes.bool,
  Holder: PropTypes.func,
  photo: PropTypes.string,
  ShowModal: PropTypes.func,
  HideModal: PropTypes.func,
  aspect: PropTypes.number,
  imageId: PropTypes.string,
  cropShape: PropTypes.oneOf(['round']),
  uploadType: PropTypes.oneOf(['avatar', 'logo', 'photo']),
  idBase: PropTypes.string,
  boxSize: PropTypes.number
}
const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  ShowModal,
  HideModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageCropperUploader)
