import { ShowModal } from 'reduxActions/services'
import { useDispatch } from 'react-redux'
import { AddToPublicListMC } from 'components'
import { useFreemium, RULES } from 'modules/freemium'

export const useAddLists = () => {
  const dispatch = useDispatch()
  const { ruleValidator } = useFreemium()

  const showModalAddToPublicList = ruleValidator(
    (origin, propertyId) =>
      dispatch(ShowModal(AddToPublicListMC, { origin, propertyId })),
    RULES.LIST_ADD
  )

  return { showModalAddToPublicList }
}
