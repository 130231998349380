import { createReducer } from '../store'
import { createCookie } from 'utils/handleCookies'

const setUserAuthInfo = (state, action) => {
  let { payload: { data: { token, roles = [] } = {} } = {} } = action

  let rolesObject = {}
  roles.forEach(role => {
    rolesObject[role] = role
  })

  const newState = {
    ...state,
    roles: rolesObject,
    token: rolesObject.DEVELOPER_USER || rolesObject.ASSOCIATION ? token : null,
    theme: roles.AMPI ? 'EXT' : 'LOCAL'
  }
  createCookie('auth_user', { ...newState, token })
  return newState
}
/**
 * Posible roles:
 *  - BASIC,
 *  - BROKER,
 *  - AGENCY_BROKER,
 *  - AGENCY,
 *  - ASSOCIATION,
 *  - ADMIN
 */
const authReducer = createReducer(
  {
    theme: null,
    token: null,
    roles: {},
    rolSelected: [
      {
        id: 1,
        isChecked: true,
        rol: 'USER_WIGGOT',
        name: 'USER_WIGGOT',
        text: 'Perfil de asesor'
      },
      {
        id: 2,
        isChecked: false,
        rol: 'USER_DEVELOP',
        name: 'USER_DEVELOP',
        text: 'Perfil de desarrollador'
      }
    ],
    isSelectDevelop: false,
    lastRoleUpdate: null,
    platformCredentials: [],
    pendingPortals: [],
    errorPortals: {},
    i24AvailableAdds: null,
    m3AvailableAdds: null,
    segundamanoAvailableAdds: null,
    adminToken: null,
    developmentOnboarding: false
  },
  {
    SET_USER_INFO: setUserAuthInfo,
    VERIFY_ACCOUNT_SUCCESS: setUserAuthInfo,
    SUBMIT_LOGIN_REQUEST_SUCCESS: setUserAuthInfo,
    SUBMIT_REGISTER_REQUEST_SUCCESS: setUserAuthInfo,
    ACCEPT_AGENCY_INVITATION_SUCCESS: setUserAuthInfo,
    ACCEPT_DEVELOPER_INVITATION_SUCCESS: setUserAuthInfo,
    ACCEPT_ASSOCIATION_INVITATION_SUCCESS: setUserAuthInfo,
    REJECT_ASSOCIATION_INVITATION_SUCCESS: setUserAuthInfo,
    SET_ROLE_STAMP: state => ({
      ...state,
      lastRoleUpdate: new Date()
    }),
    GET_AUTHORIZED_PLATFORMS_SUCCESS: (state, action) => {
      const {
        data: {
          connected_portals: platformCredentials,
          pending_portals,
          error_portals
        }
      } = action.payload
      return {
        ...state,
        platformCredentials,
        pendingPortals: pending_portals || [],
        errorPortals: error_portals || {}
      }
    },
    SET_TOKEN: (state, action) => {
      const { token } = action
      return { ...state, token }
    },
    SET_ADMIN_TOKEN: (state, action) => {
      const { token } = action
      return { ...state, adminToken: token }
    },
    REFRESH_PLATFORM_CREDENTIALS: (state, action) => {
      return {
        ...state,
        platformCredentials: [
          ...state.platformCredentials,
          { platformId: action.platformId }
        ]
      }
    },
    GET_I24_AVAILABLE_ADS_SUCCESS: (state, action) => {
      const { data: i24AvailableAdds } = action.payload
      return { ...state, i24AvailableAdds }
    },

    GET_M3_AVAILABLE_ADS_SUCCESS: (state, action) => {
      const { data: m3AvailableAdds } = action.payload
      return { ...state, m3AvailableAdds }
    },
    GET_SEGUNDAMANO_AVAILABLE_ADS_SUCCESS: (state, action) => {
      const { data: { available, id, name } = {} } = action.payload
      return {
        ...state,
        segundamanoAvailableAdds:
          available !== null
            ? [
                {
                  available: available === 'unlimited' ? 999999 : available,
                  id: id,
                  name: name
                    ? name
                        .toLowerCase()
                        .split(' ')
                        .map(w => w.replace(/./, m => m.toUpperCase()))
                        .join(' ')
                    : ''
                }
              ]
            : null
      }
    },
    SET_PENDING_PORTAL: (state, action) => {
      let pendingPortals = state.pendingPortals || []
      if (pendingPortals.length) {
        if (pendingPortals.find(portal => portal === action.portal)) {
          return { ...state }
        }
        return {
          ...state,
          pendingPortals: [...state.pendingPortals, action.portal]
        }
      }
      return { ...state, pendingPortals: [action.portal] }
    },
    SET_PLATFORM_CREDENTIALS: (state, action) => {
      const platformCredentials = [...state.platformCredentials]
      platformCredentials.push(action.portal)

      return { ...state, platformCredentials }
    },
    SET_IS_SELECTED_DEVELOP: (state, action) => {
      return { ...state, isSelectDevelop: action.payload }
    },
    SET_ROLE_SELECTED: (state, action) => {
      const newState = { ...state, rolSelected: action.payload }
      createCookie('auth_user', newState)
      return newState
    },
    SET_ONBOARDING: (state, action) => {
      return { ...state, developmentOnboarding: true }
    },
    SUBMIT_LOGOUT_REQUEST_SUCCESS: state => {
      return state
    }
  }
)

export default authReducer
