var _jsxFileName = 'src/lib/elements/Layouts/ContractedBlock.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var ContractedBlock = function ContractedBlock(_ref) {
  var children = _ref.children,
      className = _ref.className,
      props = _ref.props;

  var contractedBlockClasses = classNames((className ? className + '' : '') + ' cell shrink', {});
  return React.createElement(
    'div',
    Object.assign({ className: contractedBlockClasses }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 11
      },
      __self: _this
    }),
    children
  );
};

ContractedBlock.propTypes = {
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  props: PropTypes.any
};

export default ContractedBlock;