import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import {
  TextField,
  FormContainer,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  Grid
} from 'rc'
import { isEmail } from 'rc/helpers/stringHelpers'
import { SubmitButton, Button } from 'components'
import { WiggotLogo } from 'components/Icons'
import {
  ShowFlashNotification,
  HideFlashNotification,
  HideModal,
  UnlockModal,
  GoBackModalHistory
} from 'reduxActions/services'
import { Register } from 'reduxActions/auth'

const RegisterContentMC = ({
  className,
  ShowFlashNotification,
  HideFlashNotification,
  GoBackModalHistory,
  HideModal,
  UnlockModal,
  Register,
  ...props
}) => {
  const baseId = 'general-registerContent-mc'

  const handleSubmit = (values, errors) => {
    const required_fields = ['fullname', 'email', 'password']
    const fields_with_errors = required_fields.filter(
      field =>
        !values[field] ||
        (values[field] &&
          typeof values[field] === 'string' &&
          values[field].trim().length === 0)
    )
    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }

    if (!values.accept_terms) {
      const error = new Error({
        message: 'No has aceptado los términos y condiciones'
      })
      error.code = 'FLASH'
      throw error
    }
    const { accept_terms, ...apiValues } = values
    return Register(apiValues)
  }

  const handleSubmitSuccess = res => {
    UnlockModal()
    HideModal()
    setTimeout(() => {
      ShowFlashNotification('success', 'Registrado correctamente.')
    }, 200)
  }

  const handleFormChange = (values, fields, onUpdate) => {
    if (fields && fields === 'accept_terms') {
      HideFlashNotification()
    }
  }

  const handleSubmitFail = (fieldErrors, fc, submitErrors) => {
    if (submitErrors) {
      let { code, metadata } = submitErrors
      const {
        error: {
          response: { data: { error: { code: responseCode } = {} } = {} } = {}
        } = {}
      } = submitErrors
      code = code || responseCode
      switch (code) {
        case 'REQUIRED':
          {
            const fieldErrors = metadata.reduce((previous, current) => {
              return {
                ...previous,
                [current]: 'Campo requerido'
              }
            }, {})

            fc.setCustomErrors({
              ...fieldErrors
            })
          }
          break

        case 'CUSTOM':
          fc.setCustomErrors({
            ...metadata
          })
          break
        case 'FLASH':
          ShowFlashNotification(
            'danger',
            'No has aceptado los términos y condiciones',
            {},
            {},
            {},
            {},
            20000
          )
          break

        case 'REGISTER_USER_ALREADY_EXISTS':
          ShowFlashNotification('danger', 'Usuario ya registrado.')
          break
        default:
          ShowFlashNotification(
            'danger',
            `Ha ocurrido un error, intenta mas tarde.`
          )
          break
      }
    }
  }

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  const emailChecker = newValue => {
    return !isEmail(newValue) && 'Ingresa un email válido.'
  }

  return (
    <div
      className={className}
      css={`
        padding: ${({ theme }) => theme.remCalc('30')};
      `}
    >
      <div
        css={`
          width: ${({ theme }) => theme.remCalc('300')};
          max-width: 100%;
          margin: 0 auto;
        `}
      >
        <WiggotLogo
          css={`
            width: ${({ theme }) => theme.remCalc('140')};
            display: block;
            margin: 0 auto;
          `}
        ></WiggotLogo>
        <Typography
          variant="h2"
          textTransform="initial"
          color="palette.primary.main"
          align="center"
          display="block"
          css={`
            margin-top: ${({ theme }) => theme.remCalc('20')};
            margin-top: ${({ theme }) => theme.remCalc('15')};
          `}
        >
          Crea una cuenta nueva
        </Typography>
        <FormContainer
          initialValues={{
            name: '',
            email: '',
            password: ''
          }}
          onFormChange={handleFormChange}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFail}
          css={`
            > *:not(:last-child) {
              margin-bottom: ${({ theme }) => theme.remCalc('14')};
            }
          `}
          autoComplete="off"
        >
          <input
            id="username"
            style={{ display: 'none' }}
            type="text"
            name="new-password"
          />
          <input
            id="password"
            style={{ display: 'none' }}
            type="password"
            name="fakepasswordremembered"
          />
          <TextField
            id={`${baseId}-input-name`}
            name="fullname"
            fullWidth
            placeholder="Escribe tu nombre completo"
            label="Nombre"
            autoComplete="new-password"
            validate={isRequired}
          ></TextField>
          <TextField
            id={`${baseId}-input-email`}
            name="email"
            fullWidth
            placeholder="Escribe tu email"
            label="Email"
            autoComplete="new-password"
            validate={newValue => {
              let message = isRequired(newValue)
              if (newValue) message = emailChecker(newValue)
              return message
            }}
          ></TextField>

          <TextField
            id={`${baseId}-input-password`}
            name="password"
            type="password"
            fullWidth
            placeholder="Escribe tu contraseña"
            label="Contraseña"
            autoComplete="new-password"
            validate={newValue => {
              let message = isRequired(newValue)
              if (newValue)
                message = newValue.length < 3 ? 'Por lo menos 3 caracteres' : ''
              return message
            }}
          ></TextField>
          <FormControlLabel
            css={`
              align-items: flex-start;
              margin-top: 5px;
            `}
            control={
              <Checkbox
                id={`${baseId}-checkbox-termsAndConditions`}
                name="accept_terms"
              />
            }
            label={
              <div>
                <Typography variant="body1">
                  Acepto los{' '}
                  <Link
                    id={`${baseId}-link-terms`}
                    underline="always"
                    as={RouterLink}
                    target="_blank"
                    to="/legal/terms"
                  >
                    términos y condiciones
                  </Link>{' '}
                  y{' '}
                  <Link
                    id={`${baseId}-link-privacyPolicies`}
                    underline="always"
                    as={RouterLink}
                    target="_blank"
                    to="/legal/privacy"
                  >
                    políticas de privacidad
                  </Link>
                </Typography>
              </div>
            }
            labelPlacement="end"
          />
          <Grid container justify="space-between">
            <Grid item flat md={9}>
              <Button
                type="tertiary"
                id={`${baseId}-button-cancel`}
                full
                onClick={GoBackModalHistory}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item flat md={14}>
              <SubmitButton
                id={`${baseId}-button-submit`}
                full
                css={`
                  padding: 0;
                `}
              >
                Crear cuenta nueva
              </SubmitButton>
            </Grid>
          </Grid>
        </FormContainer>
      </div>
    </div>
  )
}

RegisterContentMC.propTypes = {
  Register: PropTypes.func.isRequired,
  HideModal: PropTypes.func.isRequired,
  UnlockModal: PropTypes.func.isRequired,
  ShowFlashNotification: PropTypes.func.isRequired,
  HideFlashNotification: PropTypes.func.isRequired,
  GoBackModalHistory: PropTypes.func.isRequired,
  className: PropTypes.string
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  ShowFlashNotification,
  HideFlashNotification,
  HideModal,
  UnlockModal,
  Register,
  GoBackModalHistory
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterContentMC)
