import React from 'react'
import styled from 'styled-components'

import { ButtonOptions as ButtonOptionsLib } from 'rc'
import { Dots3 } from 'rc/Icons'

const ButtonOptions = props => {
  return (
    <ButtonOptionsStyled type="button" variant="outlined" {...props}>
      <Dots3Styled />
    </ButtonOptionsStyled>
  )
}

const ButtonOptionsStyled = styled(ButtonOptionsLib)`
  padding: 0 !important;
  width: 45px;
  height: 45px;
  border-radius: 50% !important;
  border-color: #e8ecf9 !important;
  ${({ margin }) => margin && `margin: ${margin};`}
  div {
    display: none;
  }
  &:hover {
    background-color: #e8ecf9 !important;
  }
  ${({ isMobile }) => isMobile && `& + div { right: 0; }`} 
`
const Dots3Styled = styled(Dots3)`
  color: ${({ theme }) => theme.palette.new.text};
  width: 20px;
  transform: rotate(90deg);
`
export default ButtonOptions
