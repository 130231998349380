import React from 'react'
import styled from 'styled-components'
import  { Typography, Button } from 'rc'

import  PropTypes from 'prop-types'


const CannotUpgradeReasonMC = ({ isAgency, HideModal }) => {
  return(
    <ModalContainer>
      <Typography
        variant="h2"
        color="palette.primary.main"
        align="center"
        css={`margin-bottom: ${({ theme }) => theme.remCalc('20')};`}
        fontSize={24}
      >
        {isAgency ? `Tu inmobiliaria ya cuenta con un plan asignado` : "Ya cuentas con un plan asignado"}
      </Typography>
      <Typography align="justify" css={`margin-bottom: ${({ theme }) => theme.remCalc('20')};`}>
        {isAgency ? 
          "Otro administrador compró un plan Wiggot Prime para esta inmobiliaria. No es posible tener más de un plan activo en la misma cuenta." : 
          "Actualmente tienes un plan Wiggot Prime asignado por tu inmobiliaria. Contacta a tu administrador para que lo deshabilite y puedas comprar el plan que mejor se adapte a tus necesidades."
        }
      </Typography>
      <Button onClick={() => HideModal()}>
        Cerrar
      </Button>
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.remCalc('500')};
  padding: ${({ theme }) => theme.remCalc('30')};

`

CannotUpgradeReasonMC.propTypes = {
  isAgency: PropTypes.bool,
  HideModal: PropTypes.func
}

export default CannotUpgradeReasonMC
