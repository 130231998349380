var _jsxFileName = 'src/lib/elements/Checkboxes/BubbleCheckBoxItem.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Layout, LayoutItem } from '../Layouts';
import { Text } from '../Texts';

var BASE_CLASSNAME = 'bubble-checkbox-item';

var BubbleCheckBoxItem = function BubbleCheckBoxItem(_ref) {
  var _classNames;

  var className = _ref.className,
      label = _ref.label,
      value = _ref.value,
      isSelected = _ref.isSelected,
      _onClick = _ref.onClick;

  var checkboxContainerClasses = classNames(className, BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--checked', isSelected), _defineProperty(_classNames, BASE_CLASSNAME + '--unchecked', !isSelected), _classNames));
  return React.createElement(
    Layout,
    {
      onClick: function onClick() {
        _onClick(value);
      },
      className: checkboxContainerClasses,
      middle: true,
      center: true,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 22
      },
      __self: _this
    },
    React.createElement(
      LayoutItem,
      { auto: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 30
        },
        __self: _this
      },
      React.createElement(
        Text,
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 31
          },
          __self: _this
        },
        label
      )
    )
  );
};

BubbleCheckBoxItem.propTypes = {
  /** Value passed onClick event. */
  value: PropTypes.any.isRequired,
  /** Custom styles to applied due to class. */
  className: PropTypes.string,
  /** Gives a diferent style. */
  isSelected: PropTypes.bool,
  /** Text to render inside the bubble. */
  label: PropTypes.string,
  /** Handler of click action passing value. */
  onClick: PropTypes.func
};

export default BubbleCheckBoxItem;