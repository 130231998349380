import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path d="M0 15.4996H3.15502V10.2617H0V15.4996ZM0.682905 10.9583H2.44821V14.803H0.696563L0.682905 10.9583Z" fill="currentColor"/>
    <path d="M3.79694 15.5001H6.95196V7.89258H3.79694V15.5001ZM4.47984 8.58573H6.24515V14.8036H4.4935L4.47984 8.58573Z" fill="currentColor"/>
    <path d="M7.59387 15.4998H10.7489V5.59766H7.59387V15.4998ZM8.27678 6.28056H10.0421V14.8169H8.28702L8.27678 6.28056Z" fill="currentColor"/>
    <path d="M0.686329 7.29832L3.60916 4.31061L5.36423 6.08616L9.72799 1.68825V2.4975H10.4211V0.5H8.41681V1.19315H9.23971L5.36423 5.09936L3.60575 3.31698L0.191223 6.81345L0.686329 7.29832Z" fill="currentColor"/>
  </>,
  'MixedChartIcon',
  '0 0 11 16'
)
