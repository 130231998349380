var _jsxFileName = 'src/lib/generics/Buttons/LetterButton.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './';
import classNames from 'classnames';
/**
 * Displays a letter in a button
 */
var BASE_CLASSNAME = 'wig-button-letter';
var LetterButton = function LetterButton(_ref) {
  var defaultLetter = _ref.defaultLetter,
      letter = _ref.letter,
      className = _ref.className,
      submit = _ref.submit,
      square = _ref.square,
      semiRounded = _ref.semiRounded,
      circle = _ref.circle,
      gray = _ref.gray,
      stillGray = _ref.stillGray,
      primary = _ref.primary,
      secondary = _ref.secondary,
      transparent = _ref.transparent,
      danger = _ref.danger,
      warning = _ref.warning,
      success = _ref.success,
      disabled = _ref.disabled,
      margin = _ref.margin,
      bordered = _ref.bordered,
      onClick = _ref.onClick;

  var buttonLetterClasses = classNames(className, BASE_CLASSNAME);
  return React.createElement(
    Button,
    {
      uppercase: true,
      className: buttonLetterClasses,
      submit: submit,
      square: square,
      semiRounded: semiRounded,
      circle: circle,
      gray: gray,
      stillGray: stillGray,
      primary: primary,
      secondary: secondary,
      transparent: transparent,
      danger: danger,
      warning: warning,
      success: success,
      disabled: disabled,
      margin: margin,
      bordered: bordered,
      onClick: onClick,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 32
      },
      __self: _this
    },
    letter.charAt(0) ? letter.charAt(0) : defaultLetter.charAt(0)
  );
};

LetterButton.defaultProps = {
  defaultLetter: 'B',
  letter: '',
  className: '',
  submit: false,
  gray: false,
  stillGray: false,
  transparent: false,
  primary: false,
  secondary: false,
  danger: false,
  warning: false,
  success: false,
  square: false,
  semiRounded: false,
  circle: false,
  uppercase: false,
  disabled: false,
  margin: false,
  onClick: function onClick() {}
};

LetterButton.propTypes = {
  /** Sets border to button*/
  bordered: PropTypes.bool,
  /** Sets a button which has a border-radius of 100%. */
  circle: PropTypes.bool,
  /** Prefixes the button with custom classes passed. */
  className: PropTypes.string,
  /** Sets a button which bg-color is danger. */
  danger: PropTypes.bool,
  /** If a letter is not passed, this letter will be displayed */
  defaultLetter: PropTypes.string,
  /** Sets the disabled attribute. */
  disabled: PropTypes.bool,
  /** Sets a button which bg-color is gray. */
  gray: PropTypes.bool,
  /** Main letter to be displayed */
  letter: PropTypes.string,
  /** Sets the button with no margin. */
  margin: PropTypes.bool,
  /** Callback to call when button is pressed*/
  onClick: PropTypes.func,
  /** Sets a button which bg-color is primary. */
  primary: PropTypes.bool,
  /** Sets a button which bg-color is secondary. */
  secondary: PropTypes.bool,
  /** Sets a button which has a little border-radius. */
  semiRounded: PropTypes.bool,
  /** Sets a button which border-radius is none. */
  square: PropTypes.bool,
  /** Sets a button which bg-color is transparent but color gray and hovered stays transparent bg-colro. */
  stillGray: PropTypes.bool,
  /** Sets a 'submit' type. */
  submit: PropTypes.bool,
  /** Sets a button which bg-color is success. */
  success: PropTypes.bool,
  /** Sets a button which bg-color is transparent but hovered bg-color gray. */
  transparent: PropTypes.bool,
  /** Sets a button which bg-color is warning. */
  warning: PropTypes.bool
};

export default LetterButton;