import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Typography,
  Spinner,
  TextField,
  PhoneInput,
  FormContainer,
  SubmitButton,
  Select
} from 'rc'
import {
  UnlockModal,
  HideModal,
  ShowModal,
  ShowFlashNotification
} from 'reduxActions/services'
import { LocationSelectFields } from 'components'
import { UpdateProfile } from 'reduxActions/profile'
import { USER_GENDER_OPTIONS } from 'utils/constants'
import BirthdayInputs from 'modules/account/components/BirthdayInputs'
import EndMissingInfoForPassMc from './EndMissingInfoForPassMc'

const MissingInfoForPassMC = ({
  cb,
  userInfo,
  HideModal,
  ShowModal,
  token,
  ShowFlashNotification,
  UpdateProfile,
  UnlockModal
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const {
    fullname,
    email,
    birthday_year,
    birthday_month,
    birthday_day,
    gender,
    phone_number,
    state,
    city,
    postal_code,
    current_position,
    tax_id
  } = userInfo
  const handleSubmit = values => {
    setIsLoading(true)
    let userData = {}
    const requiredData = [
      'fullname',
      'birthday_year',
      'birthday_month',
      'birthday_day',
      'gender',
      'phone_number',
      'state',
      'city',
      'postal_code'
    ]

    Object.entries(values).forEach(([key, value]) => {
      if (userData && value) {
        userData[`user_${key}`] = values[key]
      } else if (requiredData.includes(key)) {
        userData = null
        return
      }
    })

    if (!userData) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      throw error
    }

    userData.user_birthday = `${values.birthday_year}-${values.birthday_month}-${values.birthday_day}`

    return UpdateProfile(token, userData)
  }

  const handleSubmitFail = (formErrors, fc, submitErrors) => {
    setIsLoading(false)
    if (formErrors && Object.keys(formErrors).length > 0) {
      for (const prop in formErrors) {
        if (formErrors[prop])
          return ShowFlashNotification(
            'danger',
            'Es necesario llenar los campos obligatorios'
          )
      }
    } else if (submitErrors.code === 'REQUIRED') {
      return ShowFlashNotification(
        'danger',
        'Es necesario llenar los campos obligatorios'
      )
    } else return ShowFlashNotification('danger', 'Error desconocido')
  }

  const handleSubmitSuccess = () => {
    if (cb) cb()
    else {
      UnlockModal()
      HideModal()

      ShowModal(EndMissingInfoForPassMc, {}, { isLocked: true })
    }
  }

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  return (
    <ModalContainer>
      {isLoading ? (
        <div
          css={`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          `}
        >
          <Spinner
            css={`
              ${({ theme }) => theme.box(100, 100)}
            `}
          />
        </div>
      ) : (
        <FormStyled
          initialValues={{
            fullname,
            email,
            birthday_year,
            birthday_month,
            birthday_day,
            gender,
            phone_number:
              phone_number && phone_number.length === 10
                ? `52${phone_number}`
                : phone_number,
            state,
            city,
            postal_code,
            current_position,
            tax_id
          }}
          onSubmit={handleSubmit}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFail}
        >
          <div
            css={`
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <TitleStyled
              variant="p2"
              align="center"
              color="palette.primary.main"
              fontWeight="bold"
            >
              Editar información personal
            </TitleStyled>
          </div>
          <DivGroup>
            <DivGroupColumn
              css={`
                width: 48%;
              `}
            >
              <LabelRequiredStyled>Nombre completo</LabelRequiredStyled>
              <TextField
                required
                type="text"
                name="fullname"
                validate={isRequired}
              />
            </DivGroupColumn>
            <DivGroupColumn
              css={`
                width: 48%;
              `}
            >
              <LabelRequiredStyled>Correo electrónico</LabelRequiredStyled>
              <TextField
                required
                disabled
                type="text"
                name="email"
                validate={isRequired}
              />
            </DivGroupColumn>
          </DivGroup>
          <ContainerBirthday>
            <DivGroup className="title">
              <LabelRequiredStyled
                relative
                css="margin-bottom: 5px; padding: 0;"
              >
                Fecha de nacimiento
              </LabelRequiredStyled>
            </DivGroup>
            <BirthdayInputs
              labelDay={<LabelRequiredStyled>Día</LabelRequiredStyled>}
              labelMonth={<LabelRequiredStyled>Mes</LabelRequiredStyled>}
              labelYear={<LabelRequiredStyled>Año</LabelRequiredStyled>}
            />
          </ContainerBirthday>
          <DivGroup>
            <DivGroupColumn
              css={`
                width: 48%;
                padding-top: 6px;
              `}
            >
              <LabelRequiredStyled>Género</LabelRequiredStyled>
              <Select
                required
                css={`
                  margin-top: 10px;
                `}
                placeholder="Género"
                name="gender"
                optionLabelKey="label"
                optionValueKey="value"
                options={USER_GENDER_OPTIONS}
                validate={isRequired}
              />
            </DivGroupColumn>
            <DivGroupColumn
              css={`
                width: 48%;
              `}
            >
              <LabelRequiredStyled>Celular</LabelRequiredStyled>
              <PhoneInput
                required
                type="text"
                name="phone_number"
                validate={isRequired}
              />
            </DivGroupColumn>
          </DivGroup>
          <DivGroupLocation>
            <LocationSelectFields
              direction="row"
              justify="space-between"
              optionsHeight="150"
              stateFieldConfig={{
                name: 'state',
                gridItem: { flat: true, md: 11 }
              }}
              cityFieldConfig={{
                name: 'city',
                gridItem: { flat: true, md: 11 }
              }}
              neighborhoodFieldConfig={{ isHidden: true }}
              itemStateLabel={<LabelRequiredStyled>Estado</LabelRequiredStyled>}
              itemStateCss={`
                margin-top: 10px;
              `}
              itemCityLabel={
                <LabelRequiredStyled css="line-height: 17px;">
                  Ciudad
                </LabelRequiredStyled>
              }
              itemCityCss={`
                margin-top: 10px;
              `}
            />
            <ContainerCP>
              <LabelRequiredStyled>C.P.</LabelRequiredStyled>
              <TextField
                required
                type="text"
                name="postal_code"
                validate={isRequired}
                css={`
                  margin-top: 10px;
                `}
              />
            </ContainerCP>
          </DivGroupLocation>
          <DivGroup>
            <DivGroupColumn
              css={`
                width: 48%;
              `}
            >
              <LabelStyled notRequired>Puesto actual</LabelStyled>
              <TextField
                type="text"
                name="current_position"
                placeholder="Puesto actual"
              />
            </DivGroupColumn>
            <DivGroupColumn
              css={`
                width: 48%;
              `}
            >
              <TextField
                type="text"
                name="tax_id"
                placeholder="Registro de Servicios Profesionales"
              />
            </DivGroupColumn>
          </DivGroup>
          <ButtonsContainer>
            <SubmitButton
              size="large"
              disabled={isLoading}
              css={`
                width: ${({ theme }) => theme.remCalc('280')};
                border-radius: ${({ theme }) => theme.remCalc('50')};
              `}
            >
              Guardar cambios
            </SubmitButton>
          </ButtonsContainer>
        </FormStyled>
      )}
    </ModalContainer>
  )
}
MissingInfoForPassMC.propTypes = {
  cb: PropTypes.func,
  HideModal: PropTypes.func,
  UpdateProfile: PropTypes.func,
  userInfo: PropTypes.object,
  token: PropTypes.string,
  ShowFlashNotification: PropTypes.func,
  UnlockModal: PropTypes.func
}

const ModalContainer = styled.div`
  width: ${({ theme }) => theme.remCalc('816')};
  height: ${({ theme }) => theme.remCalc('550')};
  padding: ${({ theme }) => theme.remCalc('0 50')};
  ${({ theme }) => theme.media.tabletDown`
    width: 90%;
    margin-left: 5%;
    height: auto;
    padding: ${({ theme }) => theme.remCalc('10')};
  `}
`
const FormStyled = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 51px);
  justify-content: space-evenly;
  height: 100%;
`
const TitleStyled = styled(Typography)`
  ${({ theme }) => theme.media.tabletDown`
    font-size: ${({ theme }) => theme.remCalc('16')};
  `}
`
const LabelStyled = styled(Typography)`
  color: #7d7f9c;
  background-color: white;
  transform: translate(5px, -5px);
  font-weight: 700;
  z-index: 1;
  padding: ${({ theme }) => theme.remCalc('0 5')};
  position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
  line-height: ${({ theme }) => theme.remCalc('21')};
  border-radius: ${({ theme }) => theme.remCalc('10')};
`
const LabelRequiredStyled = styled(LabelStyled)`
  :after {
    content: '*';
    color: #fd7171;
  }
`
const DivGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.media.tabletDown`
    margin-bottom: ${({ theme }) => theme.remCalc('10')};
  `}
`
const DivGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
`
const ContainerBirthday = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.tabletDown`
    margin-bottom: ${({ theme }) => theme.remCalc('10')};
    .title {
      display: none;
    }
  `}
`
const DivGroupLocation = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.media.tabletDown`
    flex-direction: column;
    > div {
      width: 100%;
      > div {
        margin-bottom: ${({ theme }) => theme.remCalc('10')};
      }
    }
  `}
`
const ContainerCP = styled.div`
  margin-left: ${({ theme }) => theme.remCalc('35')};
  ${({ theme }) => theme.media.tabletDown`
    margin-left: 0;
    > div {
      width: 100%;
    }
  `}
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 5%;
  ${({ theme }) => theme.media.tabletDown`
    padding: ${({ theme }) => theme.remCalc('10 15')};
  `}
`
const mapStateToProps = ({ authReducer: { token } }) => ({ token })

const mapDispatchToProps = {
  UpdateProfile,
  HideModal,
  UnlockModal,
  ShowModal,
  ShowFlashNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MissingInfoForPassMC)
