import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M42.15,48.71l1.48-9.27a1.39,1.39,0,0,0-.41-1.22l-6.79-6.48a1.39,1.39,0,0,1,.74-2.38l9.28-1.45a1.37,1.37,0,0,0,1-.77l4.07-8.45a1.39,1.39,0,0,1,2.49,0L58.28,27a1.39,1.39,0,0,0,1,.75L68.64,29a1.39,1.39,0,0,1,.79,2.36L62.78,38a1.37,1.37,0,0,0-.39,1.23l1.68,9.23a1.39,1.39,0,0,1-2,1.49l-8.34-4.28a1.42,1.42,0,0,0-1.3,0l-8.25,4.46A1.39,1.39,0,0,1,42.15,48.71Z"
      fill="#ffc834"
    />
    <path
      d="M90.69,48.71l1.48-9.27a1.39,1.39,0,0,0-.41-1.22L85,31.74a1.39,1.39,0,0,1,.74-2.38L95,27.91a1.37,1.37,0,0,0,1-.77l4.07-8.45a1.39,1.39,0,0,1,2.49,0L106.82,27a1.39,1.39,0,0,0,1.05.75L117.18,29a1.39,1.39,0,0,1,.79,2.36L111.32,38a1.37,1.37,0,0,0-.39,1.23l1.68,9.23a1.39,1.39,0,0,1-2,1.49l-8.34-4.28a1.42,1.42,0,0,0-1.3,0l-8.25,4.46A1.39,1.39,0,0,1,90.69,48.71Z"
      fill="#ffc834"
    />
    <path
      d="M139.23,48.71l1.48-9.27a1.39,1.39,0,0,0-.41-1.22l-6.79-6.48a1.39,1.39,0,0,1,.74-2.38l9.28-1.45a1.37,1.37,0,0,0,1-.77l4.07-8.45a1.39,1.39,0,0,1,2.49,0L155.36,27a1.39,1.39,0,0,0,1,.75L165.72,29a1.39,1.39,0,0,1,.79,2.36L159.86,38a1.37,1.37,0,0,0-.39,1.23l1.68,9.23a1.39,1.39,0,0,1-2,1.49l-8.34-4.28a1.42,1.42,0,0,0-1.3,0l-8.25,4.46A1.39,1.39,0,0,1,139.23,48.71Z"
      fill="#ffc834"
    />
    <path
      d="M187.77,48.71l1.48-9.27a1.39,1.39,0,0,0-.41-1.22l-6.79-6.48a1.39,1.39,0,0,1,.74-2.38l9.28-1.45a1.37,1.37,0,0,0,1-.77l4.07-8.45a1.39,1.39,0,0,1,2.49,0L203.9,27a1.39,1.39,0,0,0,1,.75L214.26,29a1.39,1.39,0,0,1,.79,2.36L208.4,38a1.37,1.37,0,0,0-.39,1.23l1.68,9.23a1.39,1.39,0,0,1-2,1.49l-8.34-4.28a1.42,1.42,0,0,0-1.3,0l-8.25,4.46A1.39,1.39,0,0,1,187.77,48.71Z"
      fill="#ffc834"
    />
    <path
      d="M236.31,48.71l1.48-9.27a1.39,1.39,0,0,0-.41-1.22l-6.79-6.48a1.39,1.39,0,0,1,.74-2.38l9.28-1.45a1.37,1.37,0,0,0,1-.77l4.07-8.45a1.39,1.39,0,0,1,2.49,0L252.44,27a1.39,1.39,0,0,0,1.05.75L262.8,29a1.39,1.39,0,0,1,.79,2.36L256.94,38a1.37,1.37,0,0,0-.39,1.23l1.68,9.23a1.39,1.39,0,0,1-2,1.49l-8.34-4.28a1.42,1.42,0,0,0-1.3,0l-8.25,4.46A1.39,1.39,0,0,1,236.31,48.71Z"
      fill="#ffc834"
    />
    <path
      d="M231.89,297.87a6.38,6.38,0,0,0-2.63-1.09c-.59-.17-1-.28-1-.28h0c-2.7-.68-5.87-1.26-5.87-1.26l-.83-.56.07.35-13.36.3,0-.06.09,3.27-.2,0h.2v.28c3.36.19,8.72.19,13.49.07a62.63,62.63,0,0,0,9.55-.57,1.23,1.23,0,0,0,.59-.19C232,298.07,232,298,231.89,297.87Z"
      fill="#0075d1"
    />
    <path
      d="M170.89,292.58l-12.42,0-1.24,4.54c-.1,0-.15,0-.15.05s0,0,.13,0l-.48,1.76,13.66-.18.11-1.38c.1,0,.15,0,.15-.05s-.05,0-.14-.05Z"
      fill="#0075d1"
    />
    <path
      d="M198,172.86h0l-.14-.53v0h0c-.84-3.31-1.63-6-2.36-8.29l-42.36,5c-.15,2-.19,3.33-.19,3.33-.38,1.46,5.06,120.31,5.06,120.31l14.63,0,4-112.71,31.57,115.47,13.36-.3Z"
      fill="#ffc500"
    />
    <path
      d="M154.27,160.82c.44-1.62.94-3.21,1.44-4.69A23.18,23.18,0,0,0,154.27,160.82Z"
      fill="#ffc500"
    />
    <path
      d="M150.4,98.64,135.14,85.47l.45-28.89L136,56c.25-.34.53-.71.85-1.11a5.35,5.35,0,0,1,1.36-1.19,8.86,8.86,0,0,1,2-1,.68.68,0,0,0,.26-1.27c-.57-.24-2.43.13-4.21,1.47s-.9-2-.6-3.24,1-5,.73-5.43c-.45-.56-1.15-.17-1.42,1.06s-1.33,4.86-2,4.69,1-6.38,1-6.38.56-1.28-.17-1.5c-1.33-.4-2.54,6.32-2.64,7-.07.44-.77.28-.67-.19s1.74-6.17.39-6.59c-1-.32-1,5.52-2,6.53s.46-4.45-.54-4.71c-.37-.1-.66-.13-1,3.11a62.85,62.85,0,0,0-.71,6.75h0l-4.08,34.8,17.31,19.45Z"
      fill="#fdc0c5"
    />
    <path
      d="M160.48,54.33a8.08,8.08,0,0,1,2.2.44,5.25,5.25,0,0,1,1.61.79c.43.31.79.59,1.11.86l.6.47L170.5,68a12.76,12.76,0,0,1,4.22.3,9.4,9.4,0,0,1,1.11.34,10.71,10.71,0,0,1,3.79,1.52,14.3,14.3,0,0,1,6,9.56c.55,3.23.14,6.72,1.35,9.69a12.53,12.53,0,0,0,1.22,2.25l2.93-7.59L174,52h0a62.49,62.49,0,0,0-2.42-6.34c-1.21-3-1.48-2.93-1.82-2.74-.89.52,1.92,5.41.7,4.69s-2.74-6.36-3.65-5.78c-1.2.75,1.84,5.82,2.07,6.27s-.42.77-.6.35c-.26-.59-3.16-6.78-4.34-6-.65.41.22,1.5.22,1.5s3.14,5.6,2.57,5.92-2.53-2.89-3.12-4-1.34-1.31-1.64-.66c-.19.45,1.51,4,2.11,5.06s2.31,4.12.25,3.29-4-.72-4.45-.33S159.8,54.35,160.48,54.33Z"
      fill="#fdc0c5"
    />
    <path
      d="M189.15,133.53a8.19,8.19,0,0,1-3,.5c-2.57-.06-5.19-1.25-7.66-.52s-4.26,3.4-6.74,4.43c-2.8,1.15-6,.06-8.8-1.05a24.88,24.88,0,0,1-6.1-3,9.34,9.34,0,0,1-3.77-5.73c-.85-5.11,3.84-9.72,3.23-14.86-.31-2.66-2-5-2.21-7.68a10.78,10.78,0,0,1,1.23-5.32c-2.1-1.33-3.77-2.48-3.68-2.72l-12.41,11.19L151.73,125l6.9,23.59S153,161.2,153,169l43-5s-.73-9.55-8.73-20.69Z"
      fill="#008df2"
    />
    <path
      d="M191.11,84.06l-2.93,7.59c1.44,2.16,3.26,4.07,3.61,6.72.43,3.29-1.58,6.29-2.13,9.56,0,.22,0,.45-.08.67-.35,3.41.94,6.85.64,10.28.57.78,1.2,1.52,1.8,2.3,2.46-5.26,3.9-3.82,5.1-16A30.28,30.28,0,0,0,191.11,84.06Z"
      fill="#008df2"
    />
    <path
      d="M193.78,124.2c-.71-2-2.3-3.6-3.56-5.32.3-3.43-1-6.87-.64-10.28,0-.22.06-.45.08-.67.55-3.27,2.56-6.27,2.13-9.56-.46-3.52-3.53-5.74-4.83-9-1.21-3-.8-6.46-1.35-9.69a14.3,14.3,0,0,0-6-9.56,10.71,10.71,0,0,0-3.79-1.52,9.4,9.4,0,0,0-1.11-.34,12.49,12.49,0,0,0-9.84,1.65,12,12,0,0,0-5,7.72A21.15,21.15,0,0,0,158.79,83c-.35,4.23,0,8.64-1.38,12.6-1.18,3.34-3.61,6.39-3.33,10,.2,2.67,1.9,5,2.21,7.68.61,5.14-4.08,9.75-3.23,14.86a9.34,9.34,0,0,0,3.77,5.73,24.88,24.88,0,0,0,6.1,3c2.84,1.11,6,2.2,8.8,1.05,2.48-1,4.17-3.67,6.74-4.43s5.09.46,7.66.52a8,8,0,0,0,6.34-2.81A7.5,7.5,0,0,0,193.78,124.2Z"
      fill="#064580"
    />
  </Fragment>,
  'AgencyBrokerStars',
  '0 0 300 300'
)
