var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _initialiseProps,
    _jsxFileName = 'src/lib/elements/Wysiwyg/ExtendedWisywig.js';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classNames from 'classnames';

var ExtendedWysiwyg = (_temp = _class = function (_Component) {
  _inherits(ExtendedWysiwyg, _Component);

  function ExtendedWysiwyg(props) {
    _classCallCheck(this, ExtendedWysiwyg);

    var _this = _possibleConstructorReturn(this, (ExtendedWysiwyg.__proto__ || Object.getPrototypeOf(ExtendedWysiwyg)).call(this, props));

    _initialiseProps.call(_this);

    var editorState = _this.createEditorState(props.value);

    _this.state = {
      editorState: editorState
    };
    return _this;
  }

  _createClass(ExtendedWysiwyg, [{
    key: 'render',
    value: function render() {
      var editorState = this.state.editorState;
      var editorClassName = this.props.editorClassName;

      var editorClassNames = classNames(this.BASE_CLASSNAME + '__editor-content', _defineProperty({}, editorClassName, editorClassName));
      return React.createElement(Editor, {
        toolbar: {
          options: ['inline', 'blockType', 'link', 'list', 'image', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline']
          },
          blockType: {
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
          },
          list: {
            options: ['unordered', 'ordered']
          }
        },
        localization: {
          locale: 'es'
        },
        defaultEditorState: editorState,
        editorState: editorState,
        editorClassName: editorClassNames,
        onEditorStateChange: this.onEditorStateChange,
        stripPastedStyles: true,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 69
        },
        __self: this
      });
    }
  }]);

  return ExtendedWysiwyg;
}(Component), _class.propTypes = {
  /** */
  editorClassName: PropTypes.string,
  /** */
  onChange: PropTypes.func,
  /** */
  value: PropTypes.string
}, _class.defaultProps = {
  onChange: function onChange() {}
}, _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  this.changeEditorState = function (value) {
    _this2.onEditorStateChange(_this2.createEditorState(value));
  };

  this.BASE_CLASSNAME = 'extended-wysiwyg';

  this.createEditorState = function (value) {
    return value ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(value))) : EditorState.createEmpty();
  };

  this.onEditorStateChange = function (editorState) {
    var onChange = _this2.props.onChange;

    var pureHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    _this2.setState({
      editorState: editorState
    });

    if (onChange) {
      onChange(pureHtml);
    }
  };
}, _temp);


export default ExtendedWysiwyg;