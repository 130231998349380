import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'
import { Title, Text } from 'c3-ui/elements/Texts'
import { PrimaryButton, SecondaryButton } from 'c3-ui/elements/Buttons'

import { ActivateAssociationAgencies } from 'reduxActions/association'
import { HideModal, ShowFlashNotification } from 'reduxActions/services'

export class UnblockAssociationAgencyMC extends Component {
  static propTypes = {
    token: PropTypes.string,
    agencies: PropTypes.array,
    updateCallback: PropTypes.func,
    ActivateAssociationAgencies: PropTypes.func,
    HideModal: PropTypes.func,
    ShowFlashNotification: PropTypes.func
  }

  BASE_CLASSNAME = 'unblock-association-agency-mc'

  activateAssociationAgencies = () => {
    const {
      agencies,
      token,
      updateCallback,
      HideModal,
      ShowFlashNotification,
      ActivateAssociationAgencies
    } = this.props

    const agenciesIds = agencies.map(({ agencyId }) => agencyId)
    ActivateAssociationAgencies(token, agenciesIds).then(action => {
      HideModal()
      updateCallback()
      ShowFlashNotification(
        'success',
        `Se ha${agenciesIds.length !== 1 ? 'n' : ''} desbloqueado la${
          agenciesIds.length !== 1 ? 's' : ''
        } inmobiliaria${agenciesIds.length !== 1 ? 's' : ''} de tu asociación.`
      )
    })
  }

  render() {
    const { agencies, HideModal } = this.props
    return (
      <Layout className={this.BASE_CLASSNAME} center>
        <Title
          className={`${this.BASE_CLASSNAME}__title`}
          center
          smallSize
          uppercase
        >
          {agencies.length === 1
            ? `¿DESEAS DESBLOQUEAR A ${agencies[0].name || agencies[0].email}?`
            : `¿DESEAS DESBLOQUEAR ${agencies.length} INMOBILIARIAS?`}
        </Title>
        <LayoutItem className={`${this.BASE_CLASSNAME}__main-content`}>
          <Layout className={`${this.BASE_CLASSNAME}__description`}>
            <Text gray>
              Al desbloquear una inmobiliaria nuevamente se activará y se darán
              de alta sus propiedades y sus asesores en tu asociación. Podrás
              bloquearla de nuevo en cualquier momento.
            </Text>
          </Layout>
        </LayoutItem>
        {agencies.length > 1 ? (
          <Layout>
            <Text uppercase>INMOBILIARIA(S): </Text>
            <Text bold>{agencies.map(({ name }) => name).join('; ')}.</Text>
          </Layout>
        ) : (
          ''
        )}
        <Layout center middle marginX paddingY>
          <SecondaryButton
            id="associationAgencies-unblockAssociationAgency-mc-button-cancel"
            onClick={HideModal}
            medium={5}
          >
            CANCELAR
          </SecondaryButton>

          <PrimaryButton
            id="associationAgencies-unblockAssociationAgency-mc-button-unblock"
            onClick={this.activateAssociationAgencies}
            medium={5}
          >
            DESBLOQUEAR
          </PrimaryButton>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  ActivateAssociationAgencies,
  HideModal,
  ShowFlashNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnblockAssociationAgencyMC)
