import { createReducer } from '../store'

const filesReducer = createReducer(
  {
    loading: false
  },
  {
    CREATE_PDF: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    CREATE_PDF_SUCCESS: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    CREATE_PDF_FAIL: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    REMOVE_PDF_IN_PROCESS_FILE: (state, action) => {
      return state
    }
  }
)

export default filesReducer
