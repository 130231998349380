import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Typography,
  Grid,
  FormContainer,
  TextField,
  SubmitButton,
  PhoneInput
} from 'rc'
import { LocationSelectFields } from 'components'
import { HideModal } from 'reduxActions/services'
import { UpdateProfile } from 'reduxActions/profile'

const ProfilePersonalInfoMC = ({
  onInfoUpdated,
  token,
  UpdateProfile,
  HideModal,
  profile,
  ...props
}) => {
  const {
    user_fullname = '',
    user_email = '',
    user_current_position = '',
    user_phone_number = '',
    user_state = '',
    user_city = '',
    user_neighborhood = '',
    phone_number_verified = null
  } = profile

  const handleSubmit = values => {
    delete values['email']
    const required_fields = [
      'user_fullname',
      'user_phone_number',
      'user_state',
      'user_city'
    ]
    const fields_with_errors = required_fields.filter(
      field =>
        !values[field] ||
        (typeof values[field] === 'string' && values[field].trim().length === 0)
    )
    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }

    if (!values.user_phone_number) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'CUSTOM'
      error.metadata = {
        field: 'user_phone_number',
        error: 'Campo requerido.'
      }
      throw error
    }

    return UpdateProfile(token, values)
  }
  const handleSubmitSuccess = (response, formValues) => {
    HideModal()

    if (onInfoUpdated) onInfoUpdated(formValues)
  }

  const handleSubmitFail = (values, fc, submitErrors) => {
    if (submitErrors) {
      const { code, metadata } = submitErrors
      switch (code) {
        case 'REQUIRED':
          {
            const fieldErrors = metadata.reduce((previous, current) => {
              return {
                ...previous,
                [current]: 'Campo requerido'
              }
            }, {})

            fc.setCustomErrors({
              ...fieldErrors
            })
          }
          break

        case 'CUSTOM':
          fc.setCustomErrors({
            ...metadata
          })
          break
        default:
          break
      }
    }
  }

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  return (
    <div
      css={`
        padding: ${({ theme }) => theme.remCalc('30 20')};
        width: ${({ theme }) => theme.remCalc('580')};
        max-width: 100%;
        ${({ theme }) => theme.media.desktop`
          padding: ${({ theme }) => theme.remCalc('30 60')};
        `}
      `}
    >
      <Typography variant="h6" color="palette.primary.main" textTransform="initial">
        Editar información personal
      </Typography>
      <FormContainer
        initialValues={{
          user_fullname,
          user_email,
          user_current_position,
          user_phone_number: user_phone_number && user_phone_number.length === 10 ? `52${user_phone_number}` : user_phone_number,
          user_state,
          user_city,
          user_neighborhood
        }}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={handleSubmitFail}
      >
        <Grid container>
          <Grid item md={12}>
            <TextField
              id="profile-personalInfo-mc-input-userName"
              required
              validate={isRequired}
              fullWidth
              name="user_fullname"
              label="Nombre completo"
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              id="profile-personalInfo-mc-input-userEmail"
              fullWidth
              name="user_email"
              label="Correo electrónico"
              disabled
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              id="profile-personalInfo-mc-input-userCurrentPosition"
              fullWidth
              name="user_current_position"
              label="Puesto actual"
            />
          </Grid>
          <Grid item md={12}>
            <PhoneInput
              id="profile-personalInfo-mc-input-userPhoneNumber"
              name="user_phone_number"
              label="Celular"
              required
              validate={(value, values, currentError, fc, localError) => {
                return !value && 'Campo requerido.'
              }}
              disabled={phone_number_verified}
            />
          </Grid>
          <LocationSelectFields
            stateFieldConfig={{ name: 'user_state', gridItem: { md: 12 } }}
            cityFieldConfig={{ name: 'user_city', gridItem: { md: 12 } }}
            neighborhoodFieldConfig={{ isHidden: true }}
            idBase="profile-personalInfo-mc"
          />
        </Grid>
        <Grid container justify="flex-end" item>
          <SubmitButton id="profile-personalInfo-mc-button-submit">
            Guardar cambios
          </SubmitButton>
        </Grid>
      </FormContainer>
    </div>
  )
}

ProfilePersonalInfoMC.defaultProps = {
  profile: {}
}

ProfilePersonalInfoMC.propTypes = {
  token: PropTypes.string.isRequired,
  profile: PropTypes.object,
  HideModal: PropTypes.func.isRequired,
  UpdateProfile: PropTypes.func.isRequired,
  onInfoUpdated: PropTypes.func
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  HideModal,
  UpdateProfile
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePersonalInfoMC)
