
import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <svg width="170" height="42" viewBox="0 0 170 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8697 17.332C19.8697 18.8759 18.6111 20.1345 17.0672 20.1178C15.5233 20.1178 14.2646 18.8591 14.2814 17.3152C14.2814 15.7713 15.54 14.5127 17.084 14.5295C18.6279 14.5462 19.8697 15.7881 19.8697 17.332Z" fill="#FFCD48"/>
    <path d="M74.0742 3.03404C74.0742 1.64116 75.2489 0.5 76.6418 0.5C78.0347 0.5 79.2094 1.64116 79.2094 3.03404C79.2094 4.46048 78.0347 5.60164 76.625 5.60164C75.2489 5.61842 74.0742 4.47726 74.0742 3.03404Z" fill="#FFCD48"/>
    <path d="M71.942 8.90777L65.5482 27.2669L60.8493 27.2502L57.1741 15.4191L53.4318 27.2502L48.733 27.2334L42.4062 8.85742H47.1219L51.0992 20.4368L55.1268 8.8742L59.2383 8.89098L63.2156 20.4704L67.2432 8.90777H71.942Z" fill="#363946"/>
    <path d="M74.2829 8.92695L74.2461 27.2861L78.945 27.2956L78.9818 8.93637L74.2829 8.92695Z" fill="#363946"/>
    <path d="M101.581 8.97431L101.547 25.8735C101.43 31.4618 96.6807 35.1202 92.0489 35.1034C85.6551 35.0866 83.0875 30.5388 82.8022 29.7165L86.511 27.7698C86.7627 28.5418 88.894 30.8576 92.0489 30.8744C95.0528 30.8744 96.6471 28.1222 96.8317 26.1084C95.3549 27.065 93.5928 27.602 91.6797 27.602C86.4606 27.5852 82.2148 23.3562 82.2148 18.1036C82.2316 12.8844 86.4942 8.63867 91.7133 8.63867C93.5928 8.63867 95.3549 9.19247 96.8149 10.149V8.94074L101.581 8.97431ZM96.6807 18.1875C96.6807 15.2507 94.6669 13.153 91.9147 13.153C89.1289 13.153 87.1319 15.2339 87.1319 18.1707C87.1319 21.141 89.1121 23.2388 91.8979 23.2555C94.6501 23.2555 96.6807 21.1578 96.6807 18.1875Z" fill="#363946"/>
    <path d="M123.999 9.02509L123.965 25.9243C123.848 31.5126 119.099 35.171 114.467 35.1542C108.073 35.1374 105.505 30.5896 105.22 29.7673L108.929 27.8206C109.181 28.5926 111.312 30.9084 114.467 30.9252C117.471 30.9252 119.065 28.173 119.25 26.1592C117.773 27.1158 116.011 27.6528 114.098 27.6528C108.879 27.636 104.633 23.407 104.633 18.1543C104.65 12.9352 108.912 8.68945 114.131 8.68945C116.011 8.68945 117.773 9.24325 119.233 10.1998V8.99152L123.999 9.02509ZM119.099 18.2215C119.099 15.2847 117.085 13.1869 114.333 13.1869C111.547 13.1869 109.55 15.2679 109.55 18.2047C109.55 21.175 111.53 23.2728 114.316 23.2895C117.068 23.2895 119.099 21.2086 119.099 18.2215Z" fill="#363946"/>
    <path d="M126.871 18.2051C126.888 12.986 131.15 8.74023 136.37 8.74023C141.622 8.75702 145.834 13.0196 145.834 18.2387C145.818 23.4578 141.589 27.7036 136.336 27.7036C131.117 27.6868 126.854 23.4242 126.871 18.2051ZM141.303 18.2387C141.303 15.3019 139.29 13.1706 136.537 13.1538C133.752 13.1538 131.755 15.2683 131.755 18.2219C131.755 21.1587 133.735 23.29 136.521 23.3067C139.273 23.3067 141.303 21.1755 141.303 18.2387Z" fill="#363946"/>
    <path d="M160.485 25.5554C160.485 25.5554 158.505 27.7538 155.3 27.7538C152.145 27.7538 149.711 26.2099 149.728 22.3837L149.745 13.3048H147.496L147.513 9.0758H149.762L149.778 2.9337L154.477 2.83301L154.46 9.0758L158.79 9.09258L158.773 13.3216L154.444 13.3048L154.427 20.9405C154.427 22.4508 155.3 23.3738 156.323 23.3738C157.565 23.3738 158.455 22.3837 158.455 21.7963L160.485 25.5554Z" fill="#363946"/>
    <path d="M33.4795 18.1877C33.4291 18.1206 33.4124 18.0535 33.362 18.0031L18.6109 3.18486C18.5773 3.13452 18.5606 3.08417 18.5102 3.03383C18.1075 2.63107 17.5537 2.42969 17.0166 2.42969C16.4796 2.42969 15.9426 2.61429 15.5231 3.03383C15.4727 3.08417 15.4559 3.13452 15.4224 3.18486L0.620923 17.936C0.20138 18.3555 0.0167817 18.9093 0.0335634 19.4631V19.4799L0 28.2567C0 29.2804 0.822303 30.1027 1.84599 30.1195H2.34944H2.5676L31.4825 30.1698H32.0027H32.2209C33.2445 30.1698 34.0669 29.3475 34.0836 28.3239L34.1004 19.547C34.1004 19.01 33.8655 18.5233 33.4795 18.1877ZM4.44715 19.9498L16.9999 7.44742L29.6533 20.1512L29.6365 26.008L4.43037 25.9576L4.44715 19.9498Z" fill="#0076FF"/>
    <path d="M167.917 23.1052C168.302 23.3233 168.588 23.6254 168.806 23.9946C169.024 24.3806 169.125 24.8001 169.125 25.2532C169.125 25.7063 169.007 26.1427 168.789 26.5286C168.571 26.9146 168.269 27.2167 167.883 27.4348C167.497 27.653 167.077 27.7705 166.624 27.7705C166.154 27.7705 165.735 27.653 165.366 27.4348C164.98 27.2167 164.694 26.9146 164.476 26.5286C164.258 26.1427 164.141 25.7231 164.141 25.27C164.141 24.8169 164.258 24.3974 164.476 24.0114C164.694 23.6254 164.996 23.3233 165.382 23.1052C165.768 22.887 166.188 22.7695 166.658 22.7695C167.111 22.7695 167.531 22.887 167.917 23.1052ZM167.665 27.0321C167.967 26.8475 168.219 26.5958 168.386 26.2937C168.571 25.9748 168.655 25.6392 168.655 25.2532C168.655 24.8672 168.571 24.5316 168.403 24.2295C168.235 23.9275 168 23.6757 167.682 23.5079C167.379 23.3233 167.027 23.2394 166.658 23.2394C166.272 23.2394 165.936 23.3233 165.634 23.5079C165.332 23.6925 165.08 23.9275 164.913 24.2463C164.745 24.5652 164.644 24.9008 164.644 25.27C164.644 25.6392 164.728 25.9916 164.913 26.2937C165.08 26.6125 165.332 26.8475 165.634 27.0321C165.936 27.2167 166.272 27.3006 166.658 27.3006C167.01 27.3006 167.363 27.2167 167.665 27.0321ZM167.816 25.3875C167.732 25.5385 167.598 25.6392 167.43 25.7231L168.034 26.6461H167.296L166.775 25.8574H166.742H166.289V26.6461H165.55V23.9107H166.742C167.111 23.9107 167.413 23.9946 167.614 24.1792C167.833 24.3638 167.933 24.5987 167.933 24.884C167.95 25.0686 167.9 25.2364 167.816 25.3875ZM166.708 25.3036C166.876 25.3036 166.994 25.27 167.094 25.1861C167.178 25.119 167.228 25.0015 167.228 24.8672C167.228 24.733 167.178 24.6323 167.094 24.5484C167.01 24.4813 166.876 24.4309 166.708 24.4309H166.289V25.2868H166.708V25.3036Z" fill="#363946"/>
    <path d="M130.626 33.2346V35.0782H135.112V37.8129H130.626V41.2542H127V30.5H135.742V33.2346H130.626Z" fill="#7000FF"/>
    <path d="M142.034 41.5C140.385 41.5 139.099 41.0545 138.178 40.1634C137.256 39.2723 136.795 38.0228 136.795 36.4148V30.5H140.421V36.3073C140.421 37.0959 140.559 37.6695 140.835 38.0279C141.122 38.3864 141.532 38.5656 142.064 38.5656C142.597 38.5656 143.002 38.3864 143.278 38.0279C143.565 37.6695 143.708 37.0959 143.708 36.3073V30.5H147.272V36.4148C147.272 38.0228 146.812 39.2723 145.89 40.1634C144.968 41.0545 143.683 41.5 142.034 41.5Z" fill="#7000FF"/>
    <path d="M148.934 30.5H152.56V38.4427H157.415V41.2542H148.934V30.5Z" fill="#7000FF"/>
    <path d="M158.416 30.5H162.042V38.4427H166.897V41.2542H158.416V30.5Z" fill="#7000FF"/>
  </svg>,
  'WiggotFullLogoBar',
  '0 0 170 42'
)