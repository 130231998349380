import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'rc'
import { Checkmark } from 'rc/Icons'
import { Button } from 'components'

function ConfirmationMC({ title, description, HideModal }) {
  return (
    <Container>
      <div
        css={`
          border: 2px solid #00ba88;
          display: flex;
          justify-content: center;
          align-items: center;
          ${({ theme }) => theme.box(60, 60, true)}
        `}
      >
        <Checkmark
          css={`
            color: #00ba88;
            ${({ theme }) => theme.box(40, 40)}
          `}
        />
      </div>
      <Typography variant="h2" color="palette.primary.main" align="center">
        {title}
      </Typography>
      <Typography variant="body1" color="palette.new.text" align="center">
        {description}
      </Typography>
      <Button
        onClick={HideModal}
        type="primary"
        rounded
        css={`
          width: ${({ theme }) => theme.remCalc('200')};
        `}
      >
        Aceptar
      </Button>
    </Container>
  )
}

ConfirmationMC.propTypes = {
  description: PropTypes.string,
  HideModal: PropTypes.func,
  title: PropTypes.string
}

const Container = styled.div`
  min-height: ${({ theme }) => theme.remCalc('350')};
  width: ${({ theme }) => theme.remCalc('450')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('20')};
`

export default ConfirmationMC
