var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/FormInputs/CheckboxGroup.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup as CheckboxGroupView } from '../Checkboxes';
import { default as InputLabelContainer } from './InputLabelContainer';
import { inputProp, inputLabelContainerProp, inputMetaProp } from './_types';

var CheckboxGroup = (_temp2 = _class = function (_Component) {
  _inherits(CheckboxGroup, _Component);

  function CheckboxGroup() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CheckboxGroup);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CheckboxGroup.__proto__ || Object.getPrototypeOf(CheckboxGroup)).call.apply(_ref, [this].concat(args))), _this), _this.onChangeCT = function (selectedCheckboxes) {
      var onChange = _this.props.onChange;

      if (onChange) {
        onChange(selectedCheckboxes);
      }
    }, _this.onChangeRF = function (selectedCheckboxes) {
      var changeCallback = _this.props.changeCallback;
      var onChange = _this.props.input.onChange;

      onChange(selectedCheckboxes);
      changeCallback(selectedCheckboxes);
    }, _this.inputProp = function () {
      var _this$props = _this.props,
          children = _this$props.children,
          className = _this$props.className,
          componentType = _this$props.componentType,
          disabled = _this$props.disabled,
          initialValue = _this$props.initialValue,
          _this$props$input = _this$props.input,
          values = _this$props$input.value,
          onFocus = _this$props$input.onFocus,
          onBlur = _this$props$input.onBlur,
          _this$props$meta = _this$props.meta,
          touched = _this$props$meta.touched,
          error = _this$props$meta.error,
          keyValue = _this$props.keyValue,
          multiSelectable = _this$props.multiSelectable,
          vertical = _this$props.vertical,
          shrinkItems = _this$props.shrinkItems;


      var handledProps = {};
      switch (componentType) {
        case 'CT':
          handledProps = {
            children: children,
            className: className,
            onChange: _this.onChangeCT
          };
          break;
        case 'RF':
          handledProps = {
            error: error,
            onBlur: onBlur,
            onChange: _this.onChangeRF,
            onFocus: onFocus,
            touched: touched,
            values: values
          };
          break;
      }

      return Object.assign({}, handledProps, {
        children: children,
        disabled: disabled,
        initialValue: initialValue,
        keyValue: keyValue,
        multiSelectable: multiSelectable,
        vertical: vertical,
        shrinkItems: shrinkItems
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CheckboxGroup, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          vertical = _props.vertical,
          smColumns = _props.smColumns,
          mdColumns = _props.mdColumns,
          lgColumns = _props.lgColumns,
          justify = _props.justify;
      var _props2 = this.props,
          labelProps = _props2.labelProps,
          label = _props2.label,
          isRequired = _props2.isRequired;

      return React.createElement(
        InputLabelContainer,
        Object.assign({
          isRequired: isRequired,
          label: label
        }, labelProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 138
          },
          __self: this
        }),
        React.createElement(CheckboxGroupView, Object.assign({
          lgColumns: lgColumns,
          mdColumns: mdColumns,
          smColumns: smColumns,
          className: className,
          justify: justify,
          vertical: vertical
        }, this.inputProp(), {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 143
          },
          __self: this
        }))
      );
    }
  }]);

  return CheckboxGroup;
}(Component), _class.propTypes = {
  /** */
  changeCallback: PropTypes.func,
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  componentType: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** */
  initialValue: PropTypes.any,
  /** */
  input: inputProp,
  /** */
  isRequired: PropTypes.bool,
  /** */
  justify: PropTypes.bool,
  /** */
  keyValue: PropTypes.string,
  /** */
  label: PropTypes.string,
  /** */
  labelProps: inputLabelContainerProp,
  /** */
  lgColumns: PropTypes.number,
  /** */
  mdColumns: PropTypes.number,
  /** */
  meta: inputMetaProp,
  /** */
  multiSelectable: PropTypes.bool,
  /** */
  name: PropTypes.string,
  /** */
  onChange: PropTypes.func,
  /** */
  shrinkItems: PropTypes.bool,
  /** */
  smColumns: PropTypes.number,
  /** */
  value: PropTypes.any,
  /** */
  vertical: PropTypes.bool
}, _class.defaultProps = {
  shrinkItems: true,
  inputWithChildren: true,
  disabled: false,
  label: '',
  sizeLabel: 2,
  changeCallback: function changeCallback() {
    return null;
  }
}, _temp2);


export default CheckboxGroup;