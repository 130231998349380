var _jsxFileName = 'src/lib/elements/Buttons/TabButton.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { CloseButton } from './';

var BASE_CLASSNAME = 'wig-tab-button';

var TabButton = function TabButton(_ref) {
  var _classNames;

  var className = _ref.className,
      data = _ref.data,
      _onClick = _ref.onClick,
      name = _ref.name,
      isSelected = _ref.isSelected,
      primary = _ref.primary,
      removeFn = _ref.removeFn;

  var tabBtnClasses = classNames(className, '' + BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--close', removeFn), _defineProperty(_classNames, BASE_CLASSNAME + '--active', isSelected), _defineProperty(_classNames, BASE_CLASSNAME + '--primary', primary), _classNames));
  return React.createElement(
    'span',
    { className: tabBtnClasses, __source: {
        fileName: _jsxFileName,
        lineNumber: 25
      },
      __self: _this
    },
    React.createElement('span', { className: BASE_CLASSNAME + '__border-top', __source: {
        fileName: _jsxFileName,
        lineNumber: 26
      },
      __self: _this
    }),
    React.createElement(
      'div',
      {
        className: BASE_CLASSNAME + '__text-container',
        onClick: function onClick() {
          _onClick(data);
        },
        __source: {
          fileName: _jsxFileName,
          lineNumber: 27
        },
        __self: _this
      },
      name
    ),
    removeFn && React.createElement(CloseButton, {
      className: BASE_CLASSNAME + '__close-button',
      onClick: removeFn,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 36
      },
      __self: _this
    })
  );
};

TabButton.propTypes = {
  /** Data used in callback on removeFn. */
  data: PropTypes.any.isRequired,
  /** Gives a specific style on activeness. */
  isSelected: PropTypes.bool.isRequired,
  /** Text displayed on step button. */
  name: PropTypes.string.isRequired,
  /** Handler of click action on button. */
  onClick: PropTypes.func.isRequired,
  /** Custom class. */
  className: PropTypes.string,
  /** Primary style used. */
  primary: PropTypes.bool,
  /** Callback when pressing cross. */
  removeFn: PropTypes.func
};

export default TabButton;