import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="-10.32"
        y1="-436.02"
        x2="401.06"
        y2="-288.14"
        gradientTransform="matrix(1, 0, 0, -1, 0, -250.47)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.27" stopColor="#0086df" />
        <stop offset="0.47" stopColor="#0c72dc" />
        <stop offset="0.85" stopColor="#2843d4" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="41.84"
        y1="-361.35"
        x2="175.7"
        y2="-361.35"
        gradientTransform="matrix(1, 0, 0, -1, 0, -250.47)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2843d4" />
        <stop offset="0.91" stopColor="#2843d4" />
      </linearGradient>
      <clipPath id="clipPath">
        <rect x="171.78" y="64.21" width="137.39" height="155.58" fill="none" />
      </clipPath>
      <clipPath id="clipPath-2">
        <path
          d="M218.15,184.32a21.35,21.35,0,0,0-2.9,7c-.25,1.21-1,4-.59,4.93s1.84.82,2.28-.4c.52.48,1.82.42,2-.59a2.12,2.12,0,0,0,2.26-.9c1.12.45,2-.29,2.22-1.8s1.06-3.25,1.87-7.69a15.54,15.54,0,0,1,1-2.64,42.09,42.09,0,0,1-7.18-1.69C218.93,181.48,219,183.18,218.15,184.32Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-3">
        <path
          d="M221.58,184.87a2.44,2.44,0,0,0-2.85,1.22c-1.6,2.37-1.57,5.47-2.55,8.12.89-.34,1.66-.87,2.55-1.31a5.1,5.1,0,0,1,4.8,0c0-.2,0-.2.19-.31.25-1.21.79-2.64,1.47-5.47-.31-.19-.51-.28-.82-.47C223.23,186.1,222.5,185.33,221.58,184.87Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-4">
        <path
          d="M230.5,117.1c-3.2,7.2-3.7,28.7-3.9,32.4-.2,5.1,8.3,4.3,9.9,2.6,2.2-2.2,4.3-7.7,5.5-17.1,2-16.5,2.2-25.6,2.2-25.6S234.1,109.18,230.5,117.1Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-5">
        <path
          d="M231.4,121.1c-1.1,7.1,3.7,13,.8,21.3-1.2,3.5,3.1,11.3,4.5,9.7,2.2-2.2,4.3-7.7,5.5-17.1,2-16.5,2.2-25.6,2.2-25.6S233,110.58,231.4,121.1Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-6">
        <path
          d="M226.63,148.72c-2.4,2.41-2.74,8.33-3.2,11.75-.86,5.64-5.56,22-5.56,22a59,59,0,0,0,9.49,1.89s7.19-23.63,9.28-30.93C239.87,142.46,229.07,146,226.63,148.72Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-7">
        <path
          d="M231,147.1c-4,5.4,0,13.6-2.6,23.3-.9,3.2-5.4,10.3-5.7,13.3,1.2.3,2.6.6,4,.9,0,0,9.9-28.4,12.3-35.6C242.1,140,233.7,143.38,231,147.1Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-8">
        <path
          d="M223.28,189.6l-1.63,4.07c-.18.51,0,.8.55,1.08s.8,0,1.08-.55c0,0,1.55-3.57,1-4.55C223.88,189.48,223.37,189.3,223.28,189.6Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-9">
        <path
          d="M215.1,188.88l-1.5,5.9a1,1,0,0,0,2,.4s1.4-5.4.6-6.5a.62.62,0,0,0-.88-.11A.59.59,0,0,0,215.1,188.88Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-10">
        <path
          d="M218.14,188.72l-1.24,6a1,1,0,1,0,2,.31s1.17-5.46.32-6.52C219,188.4,218.32,188.31,218.14,188.72Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-11">
        <path
          d="M220.34,188.53,219.17,194a1,1,0,1,0,2,.31s1-5,.34-6C221,188.2,220.41,188,220.34,188.53Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-12">
        <path
          d="M236.6,159.28c-6,20.9-8.2,35.1-11.8,56.5-2.6,16.2-10.4,38.8-15.8,54.7,2,3.7,7.1,5.7,11.9,4.9,3.2-5.5,17.5-45.1,21.2-56.5,5.6-16.6,12.5-31.3,18.4-51.6C260.7,166.58,242.9,159.88,236.6,159.28Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-13">
        <path
          d="M232.3,177.78c-1.2,5.2,9.1,7.1,6.3,17.1-2.5,8.8.3,9.1.3,15.4a26.68,26.68,0,0,1-4,14.5c-2.9,4.5-5.8,7.4-6.5,12.9s-3.1,13.1-4.3,18.3c-2.2,9.6-8.8,8-12.4,17a12.14,12.14,0,0,0,9.5,2.3c3.2-5.5,17.4-45.1,21.1-56.6,5.5-16.6,12.4-31.3,18.3-51.6C260.5,166.88,237.8,179.78,232.3,177.78Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-14">
        <path
          d="M272.5,169.48c-4.9-.9-27.9-6.3-30.4-2-3.6,6.6.6,7.5,2,15.4,4.2,24.5,18.5,84.7,20.7,91.9,4.3,1.7,8.5.2,11.6-.6-.8-18.9-4.2-33.9-4.8-50.2C270.8,204.58,273,184.38,272.5,169.48Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-15">
        <path
          d="M272.8,169.68c-1.7-.3-8.8-1.1-13-1.8,1.7,2.2,2.8,11.1,2.3,20-.5,8.6,3.5,8.6,3.5,15.7,0,11.1,1.7,18.3,1.7,21.5a109,109,0,0,0,.3,18.6c.5,4.5,2.9,13.1,4.3,17.7.8,2.5-.8,10.9-.3,14.2,1.7-.3,3.4-.8,4.6-1.1-.8-18.9-4.2-33.9-4.8-50.2C271.1,204.78,273.3,184.58,272.8,169.68Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-16">
        <path
          d="M274.6,129.28c.2-9.6-5.7-22.3-20.6-22.3-6.2,0-18.2,2.3-20.6,7.6-1.8,4-3.1,62-2.3,63.7,1.4,3.2,43.4,4.5,43.9,2.2C275.4,177.68,274.5,140,274.6,129.28Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-17">
        <path
          d="M274.6,129.28c.2-9.6-5.7-22.3-20.7-22.3a42.76,42.76,0,0,0-5.3.6c9.7.8,20.3,5.3,19.6,16.6-.2,3.6-3.7,17.2-3.7,20.7,0,6.2,5.3,9.3,2.9,12.9-3.1,4.8-5.7,3.6-7,7.1-1.5,4.5,7.4,2.5,5.7,7.7-2,6-7,4.3-9,9,9.3.2,17.3-.2,17.6-1.2C275.4,177.78,274.5,140,274.6,129.28Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-18">
        <path
          d="M251.9,98.88c-.3,3.6-5.2,4.8-5.2,9,0,3.6,15.1,4.8,15.2-.3s.6-14.4.6-14.4S252.4,95.28,251.9,98.88Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-19">
        <path
          d="M260.5,76.68a10.62,10.62,0,0,0-6.4-3.2,18.66,18.66,0,0,0-7.4.5,5.64,5.64,0,0,0-4.1,2.7c-2.3,4.7-3,10.2-3.6,15.4-.3,2.6-.6,5.2.5,7.6,1.6,3.8,6.4,7,10.5,5.6,9.2-2.9,13-11.8,13-12.7-.2-2.7.3-5.5.2-8.4A11.15,11.15,0,0,0,260.5,76.68Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-20">
        <path
          d="M260.8,76.38a3.45,3.45,0,0,0-1.3-1.1c-1.4,9-4.4,17.3-11.1,24-2.5,2.5-4.9,3.4-7,3.1,2.2,2.5,5.6,4,8.6,2.9,9.2-2.9,13-11.9,13-12.9-.2-2.8.3-5.6.2-8.5S262.7,78.58,260.8,76.38Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-21">
        <path
          d="M254.6,70.88c-8.6-1.2-12.2,4.5-14.5,5,6.4,4.7,17.5,2.5,17.3,4-1.1,10.3,1.6,10.4,2.2,12.3a11.44,11.44,0,0,0,4.1,5C270.1,80.48,264.8,72.28,254.6,70.88Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-22">
        <path
          d="M258.9,72a18.43,18.43,0,0,0-4.2-1.1C248,70,244.4,73.28,242,75c.2.2,8.4,3.5,16.4,1.9C261,76.58,261.3,72.88,258.9,72Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-23">
        <path
          d="M258,86.1a6.28,6.28,0,0,0-4,2.5,4.56,4.56,0,0,1,.5,5.1.71.71,0,0,0,.6.3,4.43,4.43,0,0,0,4.7-.2,6.36,6.36,0,0,0,2.3-2.8c.6-1.4.8-3.2-.2-4.3A4.37,4.37,0,0,0,258,86.1Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-24">
        <path
          d="M249.9,95.78a4.25,4.25,0,0,0-3.4-3.8c-1.8-.5-6.2-1.5-6.9-.6-1.8,2-.8,3.5-1.2,4.9-1.2,3.1-2.6,4.7-2.2,6.7,1.2,6.1,9.4,10.5,16.5,6.4,6.9-4.1,3.8-12.7,3.4-15-1.1-5.4,3.8-9.8,3.8-9.8l-2.3-3.4C257.4,81.28,253.6,94.88,249.9,95.78Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-25">
        <path
          d="M238.5,103.18c-.3-1.7.3-4.5,1.2-4.9.5-.3,5.6,0,5.8-1.5s-3-.6-4.5-2.5c-.6-.8-.2-2,.6-3.2-.9,0-1.7,0-2,.3-1.9,2-.8,3.6-1.2,4.9-1.2,3.1-2.6,4.8-2.2,6.8a10.42,10.42,0,0,0,12.37,8,10.19,10.19,0,0,0,2.63-.94C245,111.68,239.4,108.48,238.5,103.18Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-26">
        <path
          d="M233.1,154.58c-1.4,5,5.4,5.9,7.7,5.4,2.9-.8,8.4-4,14.4-11.3,10.7-12.9,13.3-16.4,13.3-16.4a102.89,102.89,0,0,0-.6-12.9,11.27,11.27,0,0,0-2-4.1C265.9,115.1,234.6,149.58,233.1,154.58Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-27">
        <path
          d="M233.8,158.18c1.7,1.9,5.4,2.2,7,1.9,2.9-.8,8.4-4,14.4-11.3,10.7-12.9,13.3-16.4,13.3-16.4l-.2-8.1C264.8,134.28,246.7,152.1,233.8,158.18Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-28">
        <path
          d="M204.9,150.1c-2.6-1.1-6.2-1.1-8.8-.9-1.5.2-4.6.2-5.7.9-.9.8-.5,2.3,1.2,2.5-.3.8.2,2.2,1.2,2.2a2.34,2.34,0,0,0,1.9,2.3c0,1.4.9,2.2,2.6,1.9,1.9-.2,4,0,9.1-.3.8,0,8.7.5,9.5.3a65.27,65.27,0,0,1-1.1-8.7C213.8,150.28,206.3,150.58,204.9,150.1Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-29">
        <path
          d="M214,155.38c-5,.3-17.5-2.8-18.3-.9-.6,1.2,1.7,3.6,2.5,4.3,1.7-.2,4,0,8.4-.3.8,0,8.7.5,9.5.3a21.38,21.38,0,0,1-.6-3.9A5.2,5.2,0,0,1,214,155.38Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-30">
        <path
          d="M236,148.1c-7.6.3-24.5,1.9-24.5,1.9a27.2,27.2,0,0,0,.3,9s13,1.4,18.7,1.9c3.6.3,10.2.3,12.7-1.4C246.4,157.58,247.6,147.78,236,148.1Z"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-31">
        <path
          d="M219.6,156.78a56.17,56.17,0,0,1-8.1-.2c.2,1.6.3,2.3.3,2.3s13,1.4,18.7,1.9c3.6.3,10.2.3,12.7-1.4,1.9-1.2,2.9-5,1.2-7.9C237.9,156.88,227.6,156.78,219.6,156.78Z"
          fill="none"
        />
      </clipPath>
    </defs>
    <title>conoce ms propiedades foto-01</title>
    <rect width="399.33" height="220.14" fill="url(#linear-gradient)" />
    <g opacity="0.2">
      <path
        d="M338.54,179.49c-4.8-16,10.4-16.62,10.8-37.2.1-8.41-4.8-17-16.7-21-5.9-1.89-11.7-3.3-16.9-6.32-11.9-7-11.7-20.31-14.9-31.74-13.1-46-82.7-58.36-118.6-27.29C169.74,66.71,161.64,80,150,91.17c-12.3,11.8-27.2,17.47-44,21.25-15.9,3.58-33.1,7.83-45.3,18.6-11.4,10.1-9.8,20.68-4.9,33,5.4,13.69-6.3,23-18.5,28.33-10.3,4.44-24,4.06-28.7,14.83-5.2,11.61,10.2,11.8,24.5,11.8s199.3-1,251,.38c13.9.38,58.3,3.87,64.6-12.28C352.94,196.4,341.84,190.64,338.54,179.49Z"
        fill="#c3ecfe"
      />
    </g>
    <path
      d="M166,205.58H51.54a9.72,9.72,0,0,1-9.7-9.7v-170a9.72,9.72,0,0,1,9.7-9.7H166a9.72,9.72,0,0,1,9.7,9.7v169.9A9.8,9.8,0,0,1,166,205.58Z"
      fill="url(#linear-gradient-2)"
    />
    <rect x="49.54" y="23.58" width="118.4" height="174.6" fill="#c3ecfe" />
    <rect x="54.04" y="28.88" width="109.3" height="65.2" fill="#fff" />
    <rect x="54.04" y="100.48" width="109.3" height="92.3" fill="#fff" />
    <rect
      x="54.04"
      y="28.88"
      width="109.3"
      height="41.59"
      fill="#c3ecfe"
      opacity="0.35"
      style={{ isolation: 'isolate' }}
    />
    <g opacity="0.15">
      <line
        x1="82.74"
        y1="79.68"
        x2="100.74"
        y2="79.68"
        fill="none"
        stroke="#0964af"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.11"
      />
      <line
        x1="141.04"
        y1="86.88"
        x2="154.74"
        y2="86.88"
        fill="none"
        stroke="#0964af"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.11"
      />
      <line
        x1="82.74"
        y1="86.88"
        x2="135.14"
        y2="86.88"
        fill="none"
        stroke="#0964af"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.11"
      />
      <line
        x1="106.64"
        y1="79.68"
        x2="154.74"
        y2="79.68"
        fill="none"
        stroke="#0964af"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.11"
      />
    </g>
    <g opacity="0.15">
      <line
        x1="82.74"
        y1="147.9"
        x2="100.74"
        y2="147.9"
        fill="none"
        stroke="#0964af"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.11"
      />
      <line
        x1="141.04"
        y1="155.1"
        x2="154.74"
        y2="155.1"
        fill="none"
        stroke="#0964af"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.11"
      />
      <line
        x1="82.74"
        y1="155.1"
        x2="135.14"
        y2="155.1"
        fill="none"
        stroke="#0964af"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.11"
      />
      <line
        x1="106.64"
        y1="147.9"
        x2="154.74"
        y2="147.9"
        fill="none"
        stroke="#0964af"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.11"
      />
    </g>
    <path
      d="M128.56,171.87v14.81a2.07,2.07,0,0,0,2.05,2H153a2.07,2.07,0,0,0,2.05-2V171.87a2.07,2.07,0,0,0-2.05-2.05H130.61A2.07,2.07,0,0,0,128.56,171.87Z"
      fill="#bcd5ee"
    />
    <path
      d="M130,170.29V185a2.1,2.1,0,0,0,2.07,2.14h22.35a2.1,2.1,0,0,0,2.14-2.07v-14.8a2.1,2.1,0,0,0-2.07-2.14H132.13A2.24,2.24,0,0,0,130,170.29Z"
      fill="#f8f8f8"
    />
    <rect x="146.11" y="175.04" width="7.27" height="1.21" fill="#bcd5ee" />
    <rect x="146.11" y="177.56" width="7.27" height="1.21" fill="#bcd5ee" />
    <rect x="146.11" y="179.98" width="7.27" height="1.21" fill="#bcd5ee" />
    <polygon
      points="135.49 182.31 142.76 182.31 142.76 174.85 139.12 172.9 135.49 174.85 135.49 182.31"
      fill="#2877bc"
    />
    <polygon
      points="142.94 175.32 139.12 173.27 135.3 175.32 134.93 174.76 139.12 172.52 143.32 174.76 142.94 175.32"
      fill="#00bbe1"
    />
    <rect x="133.26" y="178.77" width="3.82" height="3.63" fill="#2877bc" />
    <rect x="133.26" y="178.11" width="2.7" height="0.65" fill="#00bbe1" />
    <circle cx="138.94" cy="179.42" r="0.75" fill="#dde361" />
    <circle cx="138.94" cy="177.09" r="0.75" fill="#fbd83e" />
    <path
      d="M61.33,171.87v14.81a2.07,2.07,0,0,0,2.05,2H85.7a2.07,2.07,0,0,0,2-2V171.87a2.07,2.07,0,0,0-2-2.05H63.35A2.07,2.07,0,0,0,61.33,171.87Z"
      fill="#bcd5ee"
    />
    <path
      d="M62.64,170.29V185a2.1,2.1,0,0,0,2.07,2.14H87.06a2.1,2.1,0,0,0,2.14-2.07v-14.8a2.1,2.1,0,0,0-2.07-2.14H64.78A2.31,2.31,0,0,0,62.64,170.29Z"
      fill="#f8f8f8"
    />
    <rect x="78.85" y="175.04" width="7.27" height="1.21" fill="#bcd5ee" />
    <rect x="78.85" y="177.56" width="7.27" height="1.21" fill="#bcd5ee" />
    <rect x="78.85" y="179.98" width="7.27" height="1.21" fill="#bcd5ee" />
    <polygon
      points="68.14 182.31 75.5 182.31 75.5 174.85 71.86 172.9 68.14 174.85 68.14 182.31"
      fill="#00bbe1"
    />
    <polygon
      points="75.68 175.32 71.86 173.27 67.95 175.32 67.67 174.76 71.86 172.52 76.05 174.76 75.68 175.32"
      fill="#fbd83e"
    />
    <rect x="65.99" y="178.77" width="3.82" height="3.63" fill="#00bbe1" />
    <rect x="65.99" y="178.11" width="2.7" height="0.65" fill="#fbd83e" />
    <circle cx="71.68" cy="179.42" r="0.75" fill="#0076cc" />
    <circle cx="71.68" cy="177.09" r="0.75" fill="#0076cc" />
    <path
      d="M94.78,172.15V187a2.07,2.07,0,0,0,2,2h22.36a2.07,2.07,0,0,0,2-2V172.15a2.07,2.07,0,0,0-2-2.05H96.83A2.07,2.07,0,0,0,94.78,172.15Z"
      fill="#bcd5ee"
    />
    <path
      d="M96.18,170.59v14.72a2.1,2.1,0,0,0,2.07,2.14H120.6a2.1,2.1,0,0,0,2.14-2.07v-14.8a2.1,2.1,0,0,0-2.07-2.14H98.32A2.17,2.17,0,0,0,96.18,170.59Z"
      fill="#f8f8f8"
    />
    <rect x="112.39" y="175.32" width="7.27" height="1.21" fill="#bcd5ee" />
    <rect x="112.39" y="177.74" width="7.27" height="1.21" fill="#bcd5ee" />
    <rect x="112.39" y="180.26" width="7.27" height="1.21" fill="#bcd5ee" />
    <polygon
      points="101.67 182.59 109.03 182.59 109.03 175.13 105.31 173.18 101.67 175.13 101.67 182.59"
      fill="#fbd83e"
    />
    <polygon
      points="109.22 175.6 105.31 173.55 101.49 175.6 101.21 174.95 105.31 172.71 109.5 174.95 109.22 175.6"
      fill="#0076cc"
    />
    <rect x="99.53" y="179.05" width="3.82" height="3.63" fill="#fbd83e" />
    <rect x="99.53" y="178.3" width="2.7" height="0.65" fill="#0076cc" />
    <circle cx="105.21" cy="179.6" r="0.75" fill="#00bbe1" />
    <circle cx="105.21" cy="177.28" r="0.75" fill="#00bbe1" />
    <path
      d="M102.62,134.12c0,.44-.25.66-.76.66H62.29c-.51,0-.76-.22-.76-.66V106.81c0-.44.25-.66.76-.66h39.56c.51,0,.76.22.76.66v27.31Z"
      fill="#c7d6e5"
    />
    <path
      d="M74.21,113.64c0-.66.51-1.1,1.27-.66l13.19,6.83c.76.44.76,1.1,0,1.32L75.48,128c-.76.44-1.27,0-1.27-.66Z"
      fill="#fff"
    />
    <path
      d="M77.25,113.86,75.47,113c-.76-.44-1.27,0-1.27.66v13.65c0,.66.51,1.1,1.27.66l1.78-.88Z"
      fill="#e2e9f2"
      opacity="0.35"
      style={{ isolation: 'isolate' }}
    />
    <path
      d="M100.33,111.44a.51.51,0,0,1-.51.44h-3.3a.52.52,0,0,1-.51-.44v-2.86a.51.51,0,0,1,.51-.44h3.3a.52.52,0,0,1,.51.44Z"
      fill="#aebfce"
    />
    <path
      d="M100.33,116.73a.51.51,0,0,1-.51.44h-3.3a.52.52,0,0,1-.51-.44v-2.86a.51.51,0,0,1,.51-.44h3.3a.52.52,0,0,1,.51.44Z"
      fill="#aebfce"
    />
    <path
      d="M100.33,121.79a.51.51,0,0,1-.51.44h-3.3a.52.52,0,0,1-.51-.44v-2.86a.51.51,0,0,1,.51-.44h3.3a.52.52,0,0,1,.51.44Z"
      fill="#aebfce"
    />
    <path
      d="M100.33,127.08a.51.51,0,0,1-.51.44h-3.3a.52.52,0,0,1-.51-.44v-2.86a.51.51,0,0,1,.51-.44h3.3a.52.52,0,0,1,.51.44Z"
      fill="#aebfce"
    />
    <path
      d="M100.33,132.36a.51.51,0,0,1-.51.44h-3.3a.52.52,0,0,1-.51-.44V129.5a.51.51,0,0,1,.51-.44h3.3a.52.52,0,0,1,.51.44Z"
      fill="#aebfce"
    />
    <path
      d="M67.87,111.44a.51.51,0,0,1-.51.44h-3.3a.52.52,0,0,1-.51-.44v-2.86a.51.51,0,0,1,.51-.44h3.3a.52.52,0,0,1,.51.44Z"
      fill="#aebfce"
    />
    <path
      d="M67.87,116.73a.51.51,0,0,1-.51.44h-3.3a.52.52,0,0,1-.51-.44v-2.86a.51.51,0,0,1,.51-.44h3.3a.52.52,0,0,1,.51.44Z"
      fill="#aebfce"
    />
    <path
      d="M67.87,121.79a.51.51,0,0,1-.51.44h-3.3a.52.52,0,0,1-.51-.44v-2.86a.51.51,0,0,1,.51-.44h3.3a.52.52,0,0,1,.51.44Z"
      fill="#aebfce"
    />
    <path
      d="M67.87,127.08a.51.51,0,0,1-.51.44h-3.3a.52.52,0,0,1-.51-.44v-2.86a.51.51,0,0,1,.51-.44h3.3a.52.52,0,0,1,.51.44Z"
      fill="#aebfce"
    />
    <path
      d="M67.87,132.36a.51.51,0,0,1-.51.44h-3.3a.52.52,0,0,1-.51-.44V129.5a.51.51,0,0,1,.51-.44h3.3a.52.52,0,0,1,.51.44Z"
      fill="#aebfce"
    />
    <path
      d="M113,106.16a1.54,1.54,0,0,0-1.52,1.32v26.2a1.24,1.24,0,0,0,1,1.1h44.13a1.06,1.06,0,0,0,1-1.1V107a1.24,1.24,0,0,0-1-1.1H113v.23Z"
      fill="#c7d6e5"
    />
    <path
      d="M147.25,121.57a1,1,0,0,0-1.52,0l-5.83,6.17a1,1,0,0,1-1.52,0l-10.91-11.67a1,1,0,0,0-1.52,0l-15,16.08v1.54a1.24,1.24,0,0,0,1,1.1h44.13a1.06,1.06,0,0,0,1-1.1V133Z"
      fill="#aebfce"
    />
    <rect x="92.33" y="57.6" width="12.79" height="12.57" fill="#fbd83e" />
    <polygon
      points="99.7 70.17 124.4 70.17 124.4 43.95 112.05 37.02 99.7 43.95 99.7 70.17"
      fill="#fbd83e"
    />
    <polygon
      points="125.05 45.68 112.05 38.32 99.05 45.68 97.96 43.52 112.05 35.5 126.14 43.52 125.05 45.68"
      fill="#2962ff"
    />
    <rect x="91.24" y="55.22" width="9.1" height="2.38" fill="#2962ff" />
    <circle cx="111.62" cy="59.77" r="2.6" fill="#448aff" />
    <circle cx="111.62" cy="51.54" r="2.6" fill="#448aff" />
    <path
      d="M69.76,81.45c-.53-.18-1.07-.36-1.78-.53V78.25a6.31,6.31,0,0,1,2.31.89h.36a1.15,1.15,0,0,0,1.07-1.07,1,1,0,0,0-.53-.89h0a5.55,5.55,0,0,0-3-.89V74.51h0v-.18a.71.71,0,1,0-1.42,0v.18h0v1.78a6,6,0,0,0-2.67,1.07,3.38,3.38,0,0,0-.89,2.31,2.19,2.19,0,0,0,.53,1.6,3.17,3.17,0,0,0,1.25.89c.53.18,1.07.36,1.78.53v2.67a5.14,5.14,0,0,1-1.6-.36,5.55,5.55,0,0,1-1.42-.71h0a.65.65,0,0,0-.53-.18,1.15,1.15,0,0,0-1.07,1.07.82.82,0,0,0,.71.89h0a6.5,6.5,0,0,0,1.6.89,8.79,8.79,0,0,0,2.14.36V89.1a.76.76,0,0,0,.71.71A.77.77,0,0,0,68,89.1V87.32a6,6,0,0,0,2.67-1.07,3.29,3.29,0,0,0,.89-2.14,2.19,2.19,0,0,0-.53-1.6A2.5,2.5,0,0,0,69.76,81.45ZM65.67,80a.93.93,0,0,1-.36-.71.91.91,0,0,1,.36-.71,3.53,3.53,0,0,1,.89-.53v2.14A.79.79,0,0,1,65.67,80Zm3.2,5a1.35,1.35,0,0,1-.89.36V83.23c.36.18.53.36.89.36a.93.93,0,0,1,.36.71C69.05,84.47,69.05,84.83,68.87,85Z"
      fill="#aebfce"
    />
    <ellipse cx="69.64" cy="151.25" rx="6.54" ry="6.43" fill="#c7d6e5" />
    <ellipse cx="69.64" cy="151.25" rx="7.42" ry="7.46" fill="#aebfce" />
    <path
      d="M70.94,151.25A2.16,2.16,0,0,0,72,149.38a2.4,2.4,0,0,0-4.8,0,2.18,2.18,0,0,0,1.09,1.87,3.34,3.34,0,0,0-2.62,3.11v1.87a6.11,6.11,0,0,0,3.71,1.24,5.67,5.67,0,0,0,3.71-1.24v-1.87C73.56,152.91,72.47,151.67,70.94,151.25Z"
      fill="#fff"
    />
    <path
      d="M160.74,126.9a27,27,0,0,1-23.89-14.55A26.85,26.85,0,0,1,148.4,76.19h0a26.84,26.84,0,1,1,12.34,50.71Zm-.05-48.42a21.46,21.46,0,0,0-9.86,2.41h0a21.55,21.55,0,1,0,16.44-1.38A21.62,21.62,0,0,0,160.69,78.48Z"
      fill="#1b227a"
    />
    <path
      d="M207.43,169.48a2.63,2.63,0,0,1-2.18-1.15l-32-46.6a2.64,2.64,0,1,1,4.35-3l32,46.6a2.64,2.64,0,0,1-.68,3.67A2.68,2.68,0,0,1,207.43,169.48Z"
      fill="#1b227a"
    />
    <g clipPath="url(#clipPath)">
      <g clipPath="url(#clipPath-2)">
        <rect
          x="211.94"
          y="178.34"
          width="16.9"
          height="20.6"
          transform="translate(-7.91 9.65) rotate(-2.46)"
          fill="#fff"
        />
      </g>
      <g clipPath="url(#clipPath-3)">
        <rect
          x="212.84"
          y="182.3"
          width="13.8"
          height="13.8"
          transform="translate(-7.93 9.62) rotate(-2.46)"
          fill="#f6f6f6"
        />
      </g>
      <path
        id="SVGID"
        d="M230.5,117.1c-3.2,7.2-3.7,28.7-3.9,32.4-.2,5.1,8.3,4.3,9.9,2.6,2.2-2.2,4.3-7.7,5.5-17.1,2-16.5,2.2-25.6,2.2-25.6S234.1,109.18,230.5,117.1Z"
        fill="#fbd83e"
      />
      <g clipPath="url(#clipPath-4)">
        <rect x="224.4" y="107.08" width="22.1" height="48.5" fill="#fbd83e" />
      </g>
      <path
        id="SVGID-2"
        data-name="SVGID"
        d="M231.4,121.1c-1.1,7.1,3.7,13,.8,21.3-1.2,3.5,3.1,11.3,4.5,9.7,2.2-2.2,4.3-7.7,5.5-17.1,2-16.5,2.2-25.6,2.2-25.6S233,110.58,231.4,121.1Z"
        fill="#e5bf23"
      />
      <g clipPath="url(#clipPath-5)">
        <rect x="229" y="107.08" width="17.7" height="47.5" fill="#e5bf23" />
      </g>
      <path
        id="SVGID-3"
        data-name="SVGID"
        d="M226.63,148.72c-2.4,2.41-2.74,8.33-3.2,11.75-.86,5.64-5.56,22-5.56,22a59,59,0,0,0,9.49,1.89s7.19-23.63,9.28-30.93C239.87,142.46,229.07,146,226.63,148.72Z"
        fill="#fbd83e"
      />
      <g clipPath="url(#clipPath-6)">
        <rect
          x="214.84"
          y="143.91"
          width="25.3"
          height="42.7"
          transform="matrix(1, -0.04, 0.04, 1, -6.9, 9.93)"
          fill="#fbd83e"
        />
      </g>
      <path
        id="SVGID-4"
        data-name="SVGID"
        d="M231,147.1c-4,5.4,0,13.6-2.6,23.3-.9,3.2-5.4,10.3-5.7,13.3,1.2.3,2.6.6,4,.9,0,0,9.9-28.4,12.3-35.6C242.1,140,233.7,143.38,231,147.1Z"
        fill="#e5bf23"
      />
      <g clipPath="url(#clipPath-7)">
        <rect x="221.03" y="140.26" width="21.6" height="45.9" fill="#e5bf23" />
      </g>
      <g clipPath="url(#clipPath-8)">
        <rect
          x="219.19"
          y="187.23"
          width="7.5"
          height="9.9"
          transform="translate(-8.06 9.76) rotate(-2.46)"
          fill="#fff"
        />
      </g>
      <g clipPath="url(#clipPath-9)">
        <rect x="211.2" y="186.18" width="7.4" height="12.1" fill="#fff" />
      </g>
      <g clipPath="url(#clipPath-10)">
        <rect
          x="214.49"
          y="186.23"
          width="7.4"
          height="12"
          transform="translate(-8.06 9.56) rotate(-2.46)"
          fill="#fff"
        />
      </g>
      <g clipPath="url(#clipPath-11)">
        <rect
          x="216.77"
          y="185.93"
          width="7.4"
          height="11.5"
          transform="translate(-8.04 9.66) rotate(-2.46)"
          fill="#fff"
        />
      </g>
      <g clipPath="url(#clipPath-12)">
        <rect x="206.7" y="156.98" width="56.1" height="120.9" fill="#144678" />
      </g>
      <g clipPath="url(#clipPath-13)">
        <rect x="209.4" y="164.88" width="53.4" height="112.9" fill="#14548e" />
      </g>
      <g clipPath="url(#clipPath-14)">
        <rect x="238.4" y="163.58" width="40.2" height="114.2" fill="#144678" />
      </g>
      <g clipPath="url(#clipPath-15)">
        <rect x="257.6" y="165.58" width="21.1" height="112.3" fill="#144678" />
      </g>
      <path
        id="SVGID-5"
        data-name="SVGID"
        d="M274.6,129.28c.2-9.6-5.7-22.3-20.6-22.3-6.2,0-18.2,2.3-20.6,7.6-1.8,4-3.1,62-2.3,63.7,1.4,3.2,43.4,4.5,43.9,2.2C275.4,177.68,274.5,140,274.6,129.28Z"
        fill="#fbd83e"
      />
      <g clipPath="url(#clipPath-16)">
        <rect x="228.5" y="104.78" width="48.8" height="79.2" fill="#fbd83e" />
      </g>
      <path
        id="SVGID-6"
        data-name="SVGID"
        d="M274.6,129.28c.2-9.6-5.7-22.3-20.7-22.3a42.76,42.76,0,0,0-5.3.6c9.7.8,20.3,5.3,19.6,16.6-.2,3.6-3.7,17.2-3.7,20.7,0,6.2,5.3,9.3,2.9,12.9-3.1,4.8-5.7,3.6-7,7.1-1.5,4.5,7.4,2.5,5.7,7.7-2,6-7,4.3-9,9,9.3.2,17.3-.2,17.6-1.2C275.4,177.78,274.5,140,274.6,129.28Z"
        fill="#e5bf23"
      />
      <g clipPath="url(#clipPath-17)">
        <rect x="246.4" y="104.78" width="31" height="79.2" fill="#e5bf23" />
      </g>
      <g clipPath="url(#clipPath-18)">
        <rect x="244.4" y="90.88" width="20.4" height="22.4" fill="#fff" />
      </g>
      <g clipPath="url(#clipPath-19)">
        <rect x="236.5" y="71.08" width="29" height="36.9" fill="#fff" />
      </g>
      <g clipPath="url(#clipPath-20)">
        <rect x="239.1" y="73.08" width="26.3" height="34.9" fill="#74b5e3" />
      </g>
      <g clipPath="url(#clipPath-21)">
        <rect x="237.8" y="68.38" width="31" height="31" fill="#144678" />
      </g>
      <g clipPath="url(#clipPath-22)">
        <rect x="239.8" y="68.38" width="23" height="11.2" fill="#144678" />
      </g>
      <g clipPath="url(#clipPath-23)">
        <rect x="251.7" y="83.58" width="13.1" height="13.1" fill="#fff" />
      </g>
      <g clipPath="url(#clipPath-24)">
        <rect x="233.8" y="78.98" width="28.3" height="34.3" fill="#144678" />
      </g>
      <g clipPath="url(#clipPath-25)">
        <rect x="233.8" y="88.68" width="20.4" height="24.8" fill="#74b5e3" />
      </g>
      <path
        id="SVGID-7"
        data-name="SVGID"
        d="M233.1,154.58c-1.4,5,5.4,5.9,7.7,5.4,2.9-.8,8.4-4,14.4-11.3,10.7-12.9,13.3-16.4,13.3-16.4a102.89,102.89,0,0,0-.6-12.9,11.27,11.27,0,0,0-2-4.1C265.9,115.1,234.6,149.58,233.1,154.58Z"
        fill="#e5bf23"
      />
      <g clipPath="url(#clipPath-26)">
        <rect x="230.6" y="112.98" width="40.2" height="49.4" fill="#e5bf23" />
      </g>
      <path
        id="SVGID-8"
        data-name="SVGID"
        d="M233.8,158.18c1.7,1.9,5.4,2.2,7,1.9,2.9-.8,8.4-4,14.4-11.3,10.7-12.9,13.3-16.4,13.3-16.4l-.2-8.1C264.8,134.28,246.7,152.1,233.8,158.18Z"
        fill="#d8b41e"
      />
      <g clipPath="url(#clipPath-27)">
        <rect x="231.6" y="121.88" width="39.3" height="40.5" fill="#d8b41e" />
      </g>
      <g clipPath="url(#clipPath-28)">
        <rect x="187.5" y="146.78" width="30.6" height="14.5" fill="#eae9ea" />
      </g>
      <g clipPath="url(#clipPath-29)">
        <rect x="193.3" y="151.58" width="25" height="9.5" fill="#efefef" />
      </g>
      <path
        id="SVGID-9"
        data-name="SVGID"
        d="M236,148.1c-7.6.3-24.5,1.9-24.5,1.9a27.2,27.2,0,0,0,.3,9s13,1.4,18.7,1.9c3.6.3,10.2.3,12.7-1.4C246.4,157.58,247.6,147.78,236,148.1Z"
        fill="#e5bf23"
      />
      <g clipPath="url(#clipPath-30)">
        <rect x="209" y="145.78" width="38.7" height="17.4" fill="#e5bf23" />
      </g>
      <path
        id="SVGID-10"
        data-name="SVGID"
        d="M219.6,156.78a56.17,56.17,0,0,1-8.1-.2c.2,1.6.3,2.3.3,2.3s13,1.4,18.7,1.9c3.6.3,10.2.3,12.7-1.4,1.9-1.2,2.9-5,1.2-7.9C237.9,156.88,227.6,156.78,219.6,156.78Z"
        fill="#d8b41e"
      />
      <g clipPath="url(#clipPath-31)">
        <rect x="209.2" y="149.18" width="38.4" height="14" fill="#d8b41e" />
      </g>
    </g>
    <path
      d="M160.69,78.48a21.46,21.46,0,0,0-9.86,2.41h0a21.55,21.55,0,1,0,16.44-1.38A21.62,21.62,0,0,0,160.69,78.48Z"
      fill="#c3ecfe"
      opacity="0.5"
      style={{ isolation: 'isolate' }}
    />
  </Fragment>,
  'KnowMyProperties',
  '0 0 399.33 220.14'
)
