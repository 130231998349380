import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'

import { Grid, Typography } from 'rc'
import { CheckmarkSquare, User, StarRounded } from 'rc/Icons'
import { useMedia } from 'rc/Hooks'

import Image from 'components/Image'
import Button from 'components/Button'

import { shortenToInitials } from 'utils/helpers'

const ContainerImageStyled = styled(Grid)`
  ${({ theme, small, medium, large }) =>
    small
      ? theme.box(56, 56, true)
      : medium
      ? theme.box(95, 95, true)
      : large
      ? theme.box(120, 120, true)
      : null}
  background: ${({ theme }) => theme.palette.base.white};
  padding: ${({ theme, small, medium, large }) =>
    small
      ? theme.remCalc('3')
      : medium
      ? theme.remCalc('5')
      : large
      ? theme.remCalc('7')
      : null};
  position: relative;
  box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.25);
`

const CertifiedContainerStyled = styled(Grid)`
  ${({ theme }) => theme.position('absolute', null, null, -4, -8)}
  ${({ theme, small, medium, large }) =>
    small
      ? theme.box(22, 22, true)
      : medium
      ? theme.box(38, 38, true)
      : large
      ? theme.box(48, 48, true)
      : null}
    ${({ theme, small, medium, large }) =>
    small
      ? theme.bordered(2, 'white')
      : medium
      ? theme.bordered(4, 'white')
      : large
      ? theme.bordered(6, 'white')
      : null}
    padding: ${({ theme }) => theme.remCalc('3')};
  padding-right: ${({ theme }) => theme.remCalc('4')};
  background: ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  z-index: 2;
`
const CertifiedIcon = styled(CheckmarkSquare)`
  z-index: 2;
  width: 100%;
  height: 100%;
  background: transparent;
  fill: white;
`

const WalkthroughContainerStyled = styled(Grid)`
  ${({ theme }) => theme.position('absolute', null, null, null, -330)}
  ${({ theme }) => theme.radius(10)}
  ${({ theme }) => theme.box(330, 220)}
    z-index: 1;
  background-color: ${({ theme }) => theme.palette.base.white};
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.2);
`

const CertifiedProfileBadge = ({
  certified,
  profileImage,
  fullname,
  tooltip,
  profileId,
  theme: {
    devices: { tabletDown }
  },
  profileScore,
  scoreHidden,
  idBase,
  ...props
}) => {
  const [walkthrough, setWalkthrough] = useState(false)
  const isTabletDown = useMedia(tabletDown)

  return (
    <Fragment>
      {profileImage ? (
        <Link
          id={`${idBase}-link-seeProfile`}
          to={`/profile/${profileId}`}
          target="_blank"
        >
          <ContainerImageStyled
            container
            center
            {...props}
            onMouseEnter={() =>
              !isTabletDown && certified && tooltip
                ? setWalkthrough(true)
                : null
            }
            onMouseLeave={() =>
              !isTabletDown && certified && tooltip
                ? setWalkthrough(false)
                : null
            }
          >
            <Image
              alt="profile-cover-img"
              id={profileImage}
              objectFit="cover"
              css={`
                border-radius: 100%;
                overflow: hidden;
              `}
            />
            {certified ? (
              <CertifiedContainerStyled
                container
                center
                inline
                {...props}
                onMouseEnter={() =>
                  !isTabletDown && certified && tooltip
                    ? setWalkthrough(true)
                    : null
                }
                onMouseLeave={() =>
                  !isTabletDown && certified && tooltip
                    ? setWalkthrough(false)
                    : null
                }
              >
                <CertifiedIcon />
              </CertifiedContainerStyled>
            ) : null}

            {!scoreHidden && (
              <Typography
                variant={props.small ? 'subtitle5' : 'subtitle3'}
                color="white"
                {...props}
                css={`
                  display: inline-flex;
                  align-items: center;
                  height: ${({ theme, small }) =>
                    small ? theme.remCalc('23') : theme.remCalc('30')};
                  ${({ theme, small }) =>
                    theme.position(
                      'absolute',
                      small ? -10 : 5,
                      null,
                      null,
                      small ? '60%' : '75%'
                    )}
                  min-width: ${({ theme, small }) =>
                    theme.remCalc(small ? '35' : '50')};
                  ${({ theme, small }) =>
                    theme.bordered(small ? 2 : 4, theme.palette.base.white)}
                  background-color: ${({ theme }) =>
                    theme.palette.secondary.main};
                  ${({ theme, small }) => theme.radius(small ? 12 : 15)}
                  padding: 0 3px;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                `}
              >
                <StarRounded
                  css={`
                    ${({ theme }) => theme.box(8, 8)}
                    margin-right: ${({ theme }) => theme.remCalc('5')};
                  `}
                ></StarRounded>
                {profileScore ? (
                  Number(profileScore).toFixed(1)
                ) : (
                  <span
                    css={`
                      width: 30%;
                      height: 1px;
                      margin: 7px 2px;
                      background-color: ${({ theme }) =>
                        theme.palette.base.white};
                    `}
                  />
                )}
              </Typography>
            )}

            {walkthrough ? (
              <WalkthroughContainerStyled
                container
                item
                direction="column"
                justify="center"
                alignItems="space-around"
                onMouseEnter={() =>
                  !isTabletDown && certified && tooltip
                    ? setWalkthrough(true)
                    : null
                }
                onMouseLeave={() =>
                  !isTabletDown && certified && tooltip
                    ? setWalkthrough(false)
                    : null
                }
              >
                <Typography
                  variant="h3"
                  color="palette.primary.main"
                  css={`
                    text-align: center;
                    width: 100%;
                  `}
                >
                  Asesor con información completa
                </Typography>
                <Typography
                  variant="body1"
                  color="black"
                  css={`
                    text-align: center;
                    width: 100%;
                  `}
                >
                  Este asesor cuenta con toda la información completa en su
                  perfil.
                  <br />
                  Obtén tu insignia tu también.
                </Typography>
                <Link
                  id={`${idBase}-link-completeOwnProfile`}
                  to="/profile"
                  target="_blank"
                >
                  <Button
                    css={`
                      width: ${({ theme }) => theme.remCalc('200')};
                      height: ${({ theme }) => theme.remCalc('40')};
                    `}
                    color="primary"
                  >
                    Ir a completar perfil
                  </Button>
                </Link>
              </WalkthroughContainerStyled>
            ) : null}
          </ContainerImageStyled>
        </Link>
      ) : fullname ? (
        <Grid
          container
          center
          css={`
            ${({ theme }) => theme.box(48, 48, true)}
            margin-right: ${({ theme }) => theme.remCalc('10')};
            background-color: ${({ theme }) => theme.palette.primary.main};
            color: #ffffff;
          `}
        >
          <Typography variant="h1" color="white">
            {shortenToInitials(fullname)}
          </Typography>
        </Grid>
      ) : (
        <User
          fill="#ffffff"
          css={`
            color: white;
            ${({ theme }) => theme.box(30, 30)};
          `}
        />
      )}
    </Fragment>
  )
}

CertifiedProfileBadge.defaultProps = {
  medium: true
}

CertifiedProfileBadge.propTypes = {
  certified: PropTypes.bool,
  profileImage: PropTypes.string,
  fullname: PropTypes.string,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  tooltip: PropTypes.bool,
  profileId: PropTypes.string,
  theme: PropTypes.object,
  idBase: PropTypes.string,
  profileScore: PropTypes.string,
  scoreHidden: PropTypes.bool
}

export default withTheme(CertifiedProfileBadge)
