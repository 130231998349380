import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="question" fillRule="nonzero">
        <path
          d="M110.744688,449.147754 L20.079294,479.369552 C12.2201442,481.989269 3.7253446,477.741869 1.10562801,469.882719 C0.0792939681,466.803717 0.0792939681,463.474888 1.10562801,460.395886 L34.5911521,359.939314 C17.5828201,326.33026 8,288.326301 8,248.085 C8,111.063 119.07,0.003 256.083,0.003 C393.089,0.003 504.158,111.063 504.158,248.085 C504.158,385.086 393.089,496.155 256.083,496.155 C201.800155,496.155 151.589442,478.721101 110.744688,449.147754 Z"
          id="Combined-Shape"
          fill="#195fa3"
        />
        <path
          d="M146.216,173.592 C146.216,159.677 150.683,145.577 159.619,131.295 C168.552,117.013 181.592,105.185 198.73,95.809 C215.869,86.436 235.864,81.747 258.715,81.747 C279.953,81.747 298.705,85.668 314.965,93.502 C331.225,101.34 343.783,111.997 352.648,125.472 C361.509,138.951 365.941,153.597 365.941,169.417 C365.941,181.869 363.414,192.784 358.36,202.156 C353.306,211.532 347.298,219.625 340.342,226.435 C333.383,233.247 320.896,244.71 302.879,260.823 C297.898,265.365 293.904,269.358 290.904,272.799 C287.9,276.242 285.665,279.391 284.202,282.246 C282.736,285.103 281.599,287.959 280.796,290.816 C279.989,293.671 278.781,298.691 277.171,305.867 C274.387,321.103 265.67,328.719 251.024,328.719 C243.406,328.719 236.996,326.23 231.798,321.248 C226.597,316.269 223.998,308.871 223.998,299.056 C223.998,286.751 225.9,276.094 229.711,267.086 C233.519,258.076 238.572,250.166 244.872,243.356 C251.168,236.544 259.666,228.452 270.36,219.076 C279.733,210.874 286.51,204.684 290.685,200.509 C294.86,196.334 298.375,191.686 301.232,186.556 C304.088,181.43 305.517,175.865 305.517,169.857 C305.517,158.139 301.157,148.252 292.443,140.194 C283.726,132.14 272.482,128.109 258.715,128.109 C242.599,128.109 230.734,132.174 223.119,140.304 C215.501,148.434 209.057,160.409 203.783,176.229 C198.802,192.784 189.353,201.058 175.438,201.058 C167.232,201.058 160.311,198.167 154.674,192.379 C149.035,186.593 146.216,180.331 146.216,173.592 Z M253.442,414.412 C244.505,414.412 236.705,411.517 230.041,405.732 C223.374,399.948 220.043,391.855 220.043,381.453 C220.043,372.224 223.263,364.462 229.711,358.162 C236.155,351.865 244.065,348.714 253.441,348.714 C262.67,348.714 270.432,351.865 276.732,358.162 C283.028,364.462 286.18,372.224 286.18,381.453 C286.18,391.708 282.884,399.765 276.292,405.623 C269.7,411.481 262.084,414.412 253.442,414.412 Z"
          id="Shape"
          fill="#FFFFFF"
        />
      </g>
    </g>
  </Fragment>,
  'HelpIcon',
  '0 0 505 497'
)
