var _jsxFileName = 'src/lib/elements/Forms/FormBottomActions.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import { Block } from '../Layouts';
import classNames from 'classnames';
var FormBottomActions = function FormBottomActions(_ref) {
  var children = _ref.children,
      className = _ref.className;

  var formBottomActionsClasses = classNames(className, 'form-bottom-actions');
  return React.createElement(
    Block,
    { center: true, className: formBottomActionsClasses, __source: {
        fileName: _jsxFileName,
        lineNumber: 8
      },
      __self: _this
    },
    children
  );
};

FormBottomActions.propTypes = {
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string
};

export default FormBottomActions;