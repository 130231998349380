var _jsxFileName = 'src/lib/elements/Texts/FormSectionTitle.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Divider from '../General/Divider';
import { Layout } from '../Layouts';
import { Text } from './';

var BASE_CLASSNAME = 'form-section-title';
var FormSectionTitle = function FormSectionTitle(_ref) {
  var text = _ref.children,
      className = _ref.className,
      instructions = _ref.instructions;

  var containerClasses = classNames(className, BASE_CLASSNAME);
  return React.createElement(
    'div',
    { className: containerClasses, __source: {
        fileName: _jsxFileName,
        lineNumber: 12
      },
      __self: _this
    },
    React.createElement(
      Layout,
      {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 13
        },
        __self: _this
      },
      React.createElement(
        Text,
        {
          mediumShrink: instructions ? true : false,
          className: BASE_CLASSNAME + '__text',
          largeSize: true,
          uppercase: true,
          bold: true,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 14
          },
          __self: _this
        },
        text
      ),
      instructions && React.createElement(
        Text,
        {
          selfMiddle: true,
          mediumAuto: true,
          className: BASE_CLASSNAME + '__instructions',
          smallSize: true,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 24
          },
          __self: _this
        },
        instructions
      )
    ),
    text && React.createElement(Divider, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 35
      },
      __self: _this
    })
  );
};

FormSectionTitle.propTypes = {
  /** */
  children: PropTypes.string,
  /** */
  className: PropTypes.string,
  /** */
  instructions: PropTypes.string
};

export default FormSectionTitle;