import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g>
      <path
        d="M57.2607 76.2412H61.1046C61.1046 67.704 67.9905 60.76 76.4563 60.76V56.8838C65.8681 56.9076 57.2607 65.5875 57.2607 76.2412Z"
        fill="#97A4B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.315 107.536L103.387 92.483L102.02 93.8623L99.0484 90.8421C101.737 86.6329 103.317 81.639 103.317 76.2646C103.317 64.7309 96.1242 54.9095 86.0548 50.9857V49.1784H98.3645L49.5973 0L30.4017 19.3575V8.53726H15.0499V34.8149L0.830078 49.1784H13.1398V87.8695H52.2149C56.5303 97.0013 65.7508 103.351 76.4334 103.351C81.7629 103.351 86.7151 101.757 90.8891 99.0464L93.8839 102.067L92.5162 103.446L107.444 118.499C111.853 122.756 116.617 120.187 118.291 118.499C120.838 115.954 121.781 111.175 118.315 107.536ZM18.8934 12.4133H26.5575V23.2097L18.8934 30.9384V12.4133ZM16.9832 45.3019V83.993H50.7288C49.9977 81.5436 49.5968 78.9515 49.5968 76.2643C49.5968 72.8399 50.2336 69.6058 51.3891 66.5856H36.1552V39.4994H63.0385V52.8404C66.9767 50.5337 71.5515 49.1782 76.4565 49.1782C78.4374 49.1782 80.3475 49.416 82.2105 49.8202V45.3019H89.0964L49.5968 5.46932L10.0973 45.3019H16.9832ZM53.4169 76.2408C53.4169 63.4469 63.7457 53.0309 76.4327 53.0309C89.1198 53.0309 99.4486 63.4469 99.4486 76.2408C99.4486 89.0348 89.1198 99.4508 76.4327 99.4508C63.7457 99.4508 53.4169 89.0586 53.4169 76.2408ZM96.7133 93.9572C95.8644 94.9322 94.9683 95.8359 94.0014 96.692L96.619 99.3316L99.3309 96.5969L96.7133 93.9572Z"
        fill="#97A4B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.2317 52.4102C63.5447 52.4102 53.2158 62.8261 53.2158 75.6201C53.2158 82.3895 56.0967 88.4823 60.6844 92.7251C56.8298 88.5757 54.4662 82.9993 54.4662 76.8677C54.4662 64.0738 64.7951 53.6578 77.4821 53.6578C83.4659 53.6578 88.9251 55.9749 93.0235 59.7656C88.8205 55.2417 82.8466 52.4102 76.2317 52.4102Z"
        fill="#E0E0E0"
      />
      <path
        d="M18.8994 12.416H26.5635V23.2124L18.8994 30.9411V12.416Z"
        fill="#E0E0E0"
      />
      <path
        d="M16.9845 45.3023V83.9934L23.2671 83.9935V39.9324L53.8399 9.35958L49.5982 5.46973L10.0986 45.3023H16.9845Z"
        fill="#E0E0E0"
      />
      <path
        d="M39.999 25.9443H43.8429V29.8206H39.999V25.9443Z"
        fill="#97A4B1"
      />
      <path
        d="M47.6631 25.9443H51.5069V29.8206H47.6631V25.9443Z"
        fill="#97A4B1"
      />
      <path
        d="M55.3506 25.9443H59.1944V29.8206H55.3506V25.9443Z"
        fill="#97A4B1"
      />
      <path
        d="M53.2284 62.709H39.999V43.3516H59.1946V55.5273C56.8129 57.5486 54.7848 59.9742 53.2284 62.709Z"
        fill="#FFC834"
      />
      <path
        d="M115.604 115.741C114.118 117.239 111.666 117.239 110.18 115.741L97.9648 103.423L103.389 97.9531L115.604 110.272C117.113 111.793 117.113 114.243 115.604 115.741Z"
        fill="#0076CC"
      />
    </g>
  </Fragment>,
  'PropertyNotFound',
  '0 0 121 121'
)
