import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControlLabel,
  Typography,
  CheckboxControlGroup as CheckboxControl
} from 'rc'
import { CheckboxControlStyled } from 'components/CommonStyled'

const CheckboxControlGroup = ({
  values = [],
  title = '',
  name = '',
  ...props
}) => {
  const label = key => {
    return key.split('-').join(' ').capitalizeFirstLetter()
  }

  return (
    <>
      {title && (
        <Typography variant="subtitle1" color="palette.primary.main">
          {title}
        </Typography>
      )}
      <CheckboxControl
        id={`-checkboxControlGroup-${name}`}
        name={name}
        {...props}
      >
        {values &&
          values.map((id, index) => (
            <FormControlLabel
              css={`
                width: 50%;
                margin-bottom: ${({ theme }) => theme.remCalc('10')};
                span {
                  color: ${({ theme }) => theme.palette.new.text};
                }
              `}
              key={index}
              control={
                <CheckboxControlStyled
                  id={`${id}-checkbox-amenities_private`}
                  value={id}
                />
              }
              label={label(id)}
              labelPlacement="end"
            />
          ))}
      </CheckboxControl>
    </>
  )
}

CheckboxControlGroup.propTypes = {
  name: PropTypes.string,
  values: PropTypes.array,
  title: PropTypes.string
}

export default CheckboxControlGroup
