import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Text } from 'c3-ui/elements/Texts'
import { Button } from 'c3-ui/generics/Buttons'
import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'

import { ResendVerificationMail } from 'reduxActions/auth'
import { HideModal, ShowFlashNotification } from 'reduxActions/services'

class ResendVerificationMailMC extends Component {
  static propTypes = {
    email: PropTypes.string,
    actionMessage: PropTypes.string,
    ResendVerificationMail: PropTypes.func,
    HideModal: PropTypes.func,
    ShowFlashNotification: PropTypes.func
  }

  BASE_CLASSNAME = 'resend-verification-mail-mc'

  render() {
    const {
      email,
      actionMessage,
      HideModal,
      ResendVerificationMail,
      ShowFlashNotification
    } = this.props
    return (
      <Layout className={this.BASE_CLASSNAME}>
        <Text
          largeSize
          uppercase
          bold
          center
          className={`${this.BASE_CLASSNAME}__title`}
        >
          Activa tu cuenta
        </Text>
        <Text gray>
          Para poder {actionMessage} es necesario que actives tu cuenta de
          wiggot en el correo de activación que te enviamos.
        </Text>
        <Layout
          className={`${this.BASE_CLASSNAME}__buttons-group`}
          justify
          middle
          marginX
        >
          <LayoutItem auto>
            <Button
              id="general-resendVerificationMail-mc-button-cancel"
              full
              secondary
              onClick={HideModal}
            >
              CANCELAR
            </Button>
          </LayoutItem>
          <LayoutItem auto>
            <Button
              id="general-resendVerificationMail-mc-button-resend"
              full
              onClick={() => {
                ResendVerificationMail(email).then(action => {
                  HideModal()
                  ShowFlashNotification(
                    'success',
                    'Se ha enviado el correo para verificar tu cuenta.'
                  )
                })
              }}
            >
              Reenviar correo
            </Button>
          </LayoutItem>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ profileReducer: { email } }) => ({ email })

const mapDispatchToProps = {
  ResendVerificationMail,
  HideModal,
  ShowFlashNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResendVerificationMailMC)
