import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g>
      <path
        className="st0"
        d="M23.7,27l2.5-2.5c0.5-0.5,0.5-1.2,0-1.7s-1.2-0.5-1.7,0L22,25.3l-2.5-2.5c-0.5-0.5-1.2-0.5-1.7,0
      c-0.2,0.2-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8l2.5,2.5l-2.5,2.5c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8
      c0.5,0.5,1.2,0.5,1.7,0l2.5-2.5l2.5,2.5c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.5-0.5,0.5-1.2,0-1.7L23.7,27z"
      />
      <path
        className="st0"
        d="M7.6,14.1h8.5c0.7,0,1.2-0.6,1.2-1.2v-5c0-0.7-0.6-1.2-1.2-1.2H7.6c-0.7,0-1.2,0.6-1.2,1.2v4.9
      C6.4,13.6,6.9,14.1,7.6,14.1z M8.8,9.2h6v2.5h-6V9.2z"
      />
      <path
        className="st0"
        d="M24,16.2V1.4C24,0.6,23.4,0,22.6,0H1.4C0.6,0,0,0.6,0,1.4v28.2C0,30.4,0.6,31,1.4,31h10.4
      c1.6,4.1,5.6,7,10.2,7c6.1,0,11-4.9,11-11C33,21.6,29.1,17.1,24,16.2z M7.6,21.9c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2h3.7
      C11.1,25.2,11,26.1,11,27c0,0.4,0,0.8,0.1,1.2H2.8V2.8h18.4V16c-1.6,0.1-3,0.5-4.3,1.2c-0.2-0.2-0.5-0.3-0.9-0.3H7.6
      c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h6.5c-0.7,0.7-1.3,1.6-1.8,2.5H7.6V21.9z M22.9,36c-0.3,0-0.6,0-0.9,0
      c-0.4,0-0.8,0-1.1-0.1c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.7-0.2-1.1-0.4h-0.1
      c-0.3-0.2-0.7-0.3-1-0.5c0,0,0,0-0.1,0c-2.1-1.3-3.5-3.4-4-5.9c-0.1-0.3-0.1-0.5-0.1-0.5c0-0.2,0-0.3-0.1-0.5c0-0.2,0-0.5,0-0.7
      c0-0.4,0-0.8,0.1-1.2c0-0.1,0-0.2,0-0.3c0.3-2,1.3-3.8,2.7-5.1c0.2-0.2,0.4-0.4,0.7-0.6c0,0,0.1,0,0.1-0.1c0.2-0.2,0.5-0.3,0.7-0.5
      c0,0,0.1,0,0.1-0.1c0.3-0.1,0.5-0.3,0.8-0.4c0,0,0,0,0.1,0c0.9-0.4,1.8-0.7,2.9-0.8c0.3,0,0.5,0,0.8,0c2,0,3.9,0.7,5.4,1.8
      c0.2,0.1,0.4,0.3,0.5,0.4c0.7,0.6,1.3,1.4,1.8,2.2c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0.1,0.2,0.1,0.3
      c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4s0.1,0.3,0.1,0.4s0.1,0.2,0.1,0.4c0,0.2,0.1,0.4,0.1,0.5
      s0,0.2,0,0.3c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.9C30.5,32.2,27.2,35.5,22.9,36z"
      />
    </g>
  </Fragment>,
  'RejectedRecord',
  '0 -15 30 65'
)
