import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const List = styled.ul`
  list-style:none;
  margin-left:0;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 10px 40px rgba(125, 133, 154, 0.25);
    li:nth-child(odd){
      background-color:rgba(151, 164, 177, 0.1);
    }

`

const ListItem = styled.li`

  font-size: ${({theme})=>theme.remCalc('14')};
  line-height: ${({theme})=>theme.remCalc('28')};
  ::before{
    color: #FFC834;
    margin-right: ${({theme})=>theme.remCalc('16')};
    margin-left: ${({theme})=>theme.remCalc('12')};
    display: inline-block;
    content: "\\2022";
  }
`

const PrimeList = props => {
  return (
      <List className={props.className}>
      <ListItem>Busca propiedades</ListItem>
      <ListItem>Carga propiedades</ListItem>
      <ListItem>Crea contactos</ListItem>
      <ListItem>Agrega a tu equipo de asesores</ListItem>
      <ListItem>Crea requerimientos</ListItem>
      <ListItem>Crea tu sitio web</ListItem>
      <ListItem>Crea tu tarjeta de presentación digital</ListItem>
      <ListItem>Accede a tu tablero de tareas</ListItem>
      <ListItem>Comparte fichas de propiedades</ListItem>
      <ListItem>Integración con portales clasificación</ListItem>
      </List>
    
  )
}

PrimeList.propTypes = {
  className: PropTypes.string
}

export default PrimeList
