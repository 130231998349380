var _jsxFileName = 'src/lib/elements/Layouts/LayoutResponsiveSwitch.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';

import { Layout, LayoutItem } from './';
var LayoutResponsiveSwitch = function LayoutResponsiveSwitch(_ref) {
  var children = _ref.children;

  var firstChild = children ? children.length && children.length > 1 ? children[0] : children : '';
  var secondChild = children ? children.length && children.length > 1 ? children[1] : children : '';
  return React.createElement(
    Layout,
    {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 17
      },
      __self: _this
    },
    React.createElement(
      LayoutItem,
      { hideSmallOnly: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 18
        },
        __self: _this
      },
      firstChild
    ),
    React.createElement(
      LayoutItem,
      { showSmallOnly: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 19
        },
        __self: _this
      },
      secondChild
    )
  );
};

LayoutResponsiveSwitch.propTypes = {
  /** */
  children: PropTypes.array
};

export default LayoutResponsiveSwitch;