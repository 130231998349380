import React from 'react'
import PropTypes from 'prop-types'

import { ReduxForm as Form } from 'components'
import { FormGroup, TextInput } from 'c3-ui/elements/FormInputs'
import { AsyncSelect } from 'c3-ui/elements/FormInputs/thirdParty'

import { LABEL_PROPS, AGENCY_UPGRADE_FORM } from './_constants'

const AgencyUpgradeForm = ({
  handleSubmit,
  handleSubmitSuccess,
  handleSubmitFail,
  getCities,
  getStates,
  stateSelected,
  cityEnabled
}) => {
  return (
    <Form
      form={AGENCY_UPGRADE_FORM}
      onSubmit={handleSubmit}
      onSubmitSuccess={handleSubmitSuccess}
      onSubmitFail={handleSubmitFail}
      destroyOnUnmount={false}
      noResponsive
    >
      <FormGroup>
        <AsyncSelect
          id="profile-agencyUpgradeLocationForm-mc-state"
          name="state"
          label="ESTADO"
          placeholder="Elige un estado"
          loadOptions={getStates}
          onChangeCb={stateSelected}
          labelProps={LABEL_PROPS}
          validations={[{ validationName: 'emptiness' }]}
          isRequired
        />
        <AsyncSelect
          id="profile-agencyUpgradeLocationForm-mc-city"
          disabled={!cityEnabled}
          name="city"
          label="CIUDAD"
          placeholder="Elige una ciudad"
          loadOptions={getCities}
          labelProps={LABEL_PROPS}
          validations={[{ validationName: 'emptiness' }]}
          isRequired
        />
        <TextInput
          id="profile-agencyUpgradeLocationForm-mc-input-address"
          name="address"
          label="DIRECCIÓN"
          labelProps={LABEL_PROPS}
          validations={[{ validationName: 'emptiness' }]}
        />
      </FormGroup>
    </Form>
  )
}

AgencyUpgradeForm.propTypes = {
  cityEnabled: PropTypes.bool,
  getCities: PropTypes.func,
  getStates: PropTypes.func,
  stateSelected: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleSubmitSuccess: PropTypes.func,
  handleSubmitFail: PropTypes.func
}

export default AgencyUpgradeForm
