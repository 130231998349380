import React, { useState } from 'react'
import { object } from 'prop-types'
import styled from 'styled-components'
import { Typography, TextField, withForm } from 'rc'
import { Previewer } from 'c3-ui/elements/FormInputs'
import { ModelFormSectionContainer } from 'modules/development/static-components'

function Multimedia({
  fc: { values: { youtube_url, matterport_url } = {} } = {},
  ...props
}) {
  const [youtubeUrl, setYoutubeUrl] = useState(youtube_url || '')
  const [matterportUrl, setMatterportUrl] = useState(matterport_url || '')

  const handleInputs = ({ target: { value, name } = {}, target }) => {
    if (name === 'youtube_url') {
      return setYoutubeUrl(value)
    }
    return setMatterportUrl(value)
  }

  return (
    <ModelFormSectionContainer>
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
      >
        Multimedia
      </Typography>
      <InputsContainer>
        <MidSection>
          <TextFieldStyled
            name="youtube_url"
            label="Liga de youtube"
            placeholder="Liga de youtube"
            onChange={handleInputs}
            fullWidth
          />
          <Previewer
            name="youtubeUrl"
            width="100%"
            height="200px"
            relatedVideo={false}
            controls={false}
            type="youtube"
            url={youtubeUrl}
            componentType="CT"
          />
        </MidSection>
        <MidSection>
          <TextFieldStyled
            name="matterport_url"
            label="Liga de matterport / cloudpano"
            placeholder="Liga de matterport / cloudpano"
            onChange={handleInputs}
            fullWidth
          />
          <Previewer
            name="matterportUrl"
            width="100%"
            height="200px"
            type="virtualTour"
            componentType="CT"
            url={matterportUrl}
            css={`
              margin: 0;
            `}
          />
        </MidSection>
      </InputsContainer>
    </ModelFormSectionContainer>
  )
}

Multimedia.propTypes = {
  fc: object
}

const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.remCalc('30')};
`
const MidSection = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`
const TextFieldStyled = styled(TextField)`
  margin-top: 0px !important;
`

export default withForm(Multimedia)
