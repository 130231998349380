'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var getFormattedPlaceDetails = function getFormattedPlaceDetails(place) {
  var _place$formatted_addr = place.formatted_address,
    formatted_address = _place$formatted_addr === void 0 ? '' : _place$formatted_addr,
    _place$address_compon = place.address_components,
    address_components = _place$address_compon === void 0 ? {} : _place$address_compon,
    _place$geometry = place.geometry,
    _place$geometry2 = _place$geometry === void 0 ? {} : _place$geometry,
    _place$geometry2$loca = _place$geometry2.location,
    location = _place$geometry2$loca === void 0 ? {} : _place$geometry2$loca;
  var placeDetails = {
    extNumber: '',
    street: '',
    neighborhood: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    position: {},
    formatted_address: formatted_address
  };
  for (var index in address_components) {
    var component = address_components[index];
    if (Array.isArray(component.types) && component.types.length > 0) switch (component.types[0]) {
      case 'street_number':
        placeDetails.extNumber = component.long_name;
        break;
      case 'route':
        placeDetails.street = component.long_name;
        break;
      case 'political':
        placeDetails.neighborhood = !placeDetails.neighborhood && component.long_name;
        break;
      case 'sublocality_level_1':
        placeDetails.neighborhood = component.long_name;
        break;
      case 'locality':
        placeDetails.city = component.long_name;
        break;
      case 'administrative_area_level_1':
        placeDetails.state = component.long_name;
        break;
      case 'country':
        placeDetails.country = component.long_name;
        break;
      case 'postal_code':
        placeDetails.postalCode = component.long_name;
        break;
    }
  }
  if (location && location.lat && location.lng) {
    placeDetails.position = {
      lat: location.lat,
      lng: location.lng
    };
  }
  return placeDetails;
};
var getFormattedResults = function getFormattedResults(suggestion) {
  var _suggestion$structure = suggestion.structured_formatting,
    _suggestion$structure2 = _suggestion$structure.main_text,
    main_text = _suggestion$structure2 === void 0 ? '' : _suggestion$structure2,
    _suggestion$structure3 = _suggestion$structure.main_text_matched_substrings,
    main_text_matched_substrings = _suggestion$structure3 === void 0 ? [] : _suggestion$structure3,
    _suggestion$structure4 = _suggestion$structure.secondary_text,
    secondary_text = _suggestion$structure4 === void 0 ? '' : _suggestion$structure4;
  if (!main_text_matched_substrings.length) return {
    firstPartString: '',
    matchedString: '',
    lastPartString: '',
    secondary_text: secondary_text
  };
  var startIndex = main_text_matched_substrings[0].offset;
  var endIndex = startIndex + main_text_matched_substrings[0].length;
  return {
    firstPartString: main_text.substring(0, startIndex),
    matchedString: main_text.substring(startIndex, endIndex),
    lastPartString: main_text.substring(endIndex),
    secondary_text: secondary_text
  };
};
var getPlaceDetailsURI = function getPlaceDetailsURI(placeId, placeDetailOptions, options, apiKey, sessionToken) {
  var fields = placeDetailOptions.fields ? "&fields=".concat(placeDetailOptions.fields.join(',')) : '';
  var region = placeDetailOptions.region ? "&region=".concat(placeDetailOptions.region) : '';
  var language = placeDetailOptions.language ? "&language=".concat(placeDetailOptions.language) : options.language ? "&language=".concat(options.language, "}") : '';
  return "https://maps.googleapis.com/maps/api/place/details/json?placeid=".concat(placeId).concat(fields).concat(region).concat(language, "&key=").concat(apiKey, "&sessiontoken=").concat(sessionToken);
};
var getAutocompleteURI = function getAutocompleteURI(query, type, options, apiKey, sessionToken) {
  var types = options.types && type === 'places' ? "&types=".concat(options.types) : '';
  var strictbounds = options.strictbounds && types === 'places' ? "&strictbounds" : '';
  var offset = options.offset && type === 'query' ? "&offset=".concat(options.offset) : '';
  var language = options.language ? "&language=".concat(options.language) : '&language=es';
  var location = options.location ? "&location=".concat(options.location) : '';
  var radius = options.radius ? "&radius=".concat(options.radius) : '';
  var components = options.components ? "&components=".concat(options.components) : '&components=country:mx';
  return "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=".concat(query).concat(types).concat(language).concat(location).concat(radius).concat(strictbounds).concat(offset).concat(components, "&key=").concat(apiKey, "&sessiontoken=").concat(sessionToken);
};

exports.getAutocompleteURI = getAutocompleteURI;
exports.getFormattedPlaceDetails = getFormattedPlaceDetails;
exports.getFormattedResults = getFormattedResults;
exports.getPlaceDetailsURI = getPlaceDetailsURI;
