var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/Selects/Select.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectMenu from './SelectMenu';

import { Layout } from '../Layouts';
import { SelectView } from '../../generics/FormInputViews';
import isSize from '../General/isSize';
/** Used to display a variety of options.  */
var Select = (_temp2 = _class = function (_Component) {
  _inherits(Select, _Component);

  function Select() {
    var _ref,
        _this2 = this;

    var _temp, _this, _ret;

    _classCallCheck(this, Select);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Select.__proto__ || Object.getPrototypeOf(Select)).call.apply(_ref, [this].concat(args))), _this), _this.node = React.createRef(), _this.state = {
      open: false
    }, _this.componentWillUnmount = function () {
      document.removeEventListener('click', _this.handleOutsideClick, false);
    }, _this.handleClick = function () {
      if (_this.state.open === false) {
        document.addEventListener('click', _this.handleOutsideClick, false);
      } else {
        document.removeEventListener('click', _this.handleOutsideClick, false);
      }
      _this.setState(function (prevState) {
        return {
          open: !prevState.open
        };
      });
    }, _this.handleOutsideClick = function (e) {
      if (_this.node) {
        if (_this.node.current.contains && _this.node.current.contains(e.target)) {
          return;
        }
      }
      _this.handleClick();
    }, _this.handleName = function (value, options) {
      var placeholder = _this.props.placeholder;


      if (typeof value !== 'undefined') {
        var valueId = value;

        if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
          var _value$id = value.id,
              id = _value$id === undefined ? '' : _value$id;

          valueId = id;
        }

        var res = options.find(function (option) {
          var optionId = option.id;

          return valueId === optionId;
        });
        if (res) {
          return res.name;
        } else {
          return placeholder;
        }
      } else {
        return placeholder;
      }
    }, _this.selectOption = function (_ref2) {
      var value = _ref2.target.value;
      var retrieveOnlyId = _this.props.retrieveOnlyId;

      var option = _this.props.options.find(function (_ref3) {
        var id = _ref3.id;
        return id === value;
      });
      if (retrieveOnlyId) {
        var _option$id = option.id,
            id = _option$id === undefined ? '' : _option$id;

        _this.props.onOptionSelected(id);
      } else {
        _this.props.onOptionSelected(option);
      }
    }, _this.renderOption = function (item) {
      return React.createElement(
        'option',
        { key: item.id, id: 'select-' + item.id, value: item.id, __source: {
            fileName: _jsxFileName,
            lineNumber: 131
          },
          __self: _this2
        },
        item.name
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Select, [{
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          id = _props.id,
          options = _props.options,
          _onOptionSelected = _props.onOptionSelected,
          value = _props.value,
          selectBoxMenuClassName = _props.selectBoxMenuClassName,
          wrapperClassName = _props.wrapperClassName,
          disabled = _props.disabled,
          secondary = _props.secondary,
          className = _props.className,
          insideLabel = _props.insideLabel,
          primaryLight = _props.primaryLight;
      var open = this.state.open;


      var selectContainerClasses = classNames(wrapperClassName, 'select-wrapper');

      var selectBoxClasses = classNames(selectBoxMenuClassName, 'select-wrapper__options-box');

      return React.createElement(
        'div',
        { className: selectContainerClasses, vertical: true, ref: this.node, __source: {
            fileName: _jsxFileName,
            lineNumber: 164
          },
          __self: this
        },
        !isSize('small') ? React.createElement(
          Fragment,
          {
            __source: {
              fileName: _jsxFileName,
              lineNumber: 166
            },
            __self: this
          },
          React.createElement(SelectView, {
            id: id,
            insideLabel: insideLabel,
            disabled: disabled,
            secondary: secondary,
            primaryLight: primaryLight,
            className: className,
            onClick: this.handleClick,
            selectedOptionText: this.handleName(value, options),
            open: open,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 167
            },
            __self: this
          }),
          React.createElement(SelectMenu, {
            shrink: true,
            className: selectBoxClasses,
            open: open,
            options: options,
            value: value,
            onOptionSelected: function onOptionSelected(date) {
              return _onOptionSelected(date, _this3.handleClick);
            },
            __source: {
              fileName: _jsxFileName,
              lineNumber: 178
            },
            __self: this
          })
        ) : React.createElement(
          Layout,
          {
            className: classNames(className, 'input', 'select-view', {
              'select-view--normal': !secondary,
              'select-view--secondary': secondary,
              'select-view--primary-light': primaryLight,
              'select-view--disabled': disabled
            }),
            __source: {
              fileName: _jsxFileName,
              lineNumber: 188
            },
            __self: this
          },
          React.createElement(
            'select',
            {
              id: id,
              value: value ? value.id : value,
              disabled: disabled,
              onChange: this.selectOption,
              __source: {
                fileName: _jsxFileName,
                lineNumber: 196
              },
              __self: this
            },
            React.createElement(
              'option',
              { value: '', selected: 'true', disabled: 'disabled', __source: {
                  fileName: _jsxFileName,
                  lineNumber: 202
                },
                __self: this
              },
              'Seleccionar'
            ),
            options.map(this.renderOption)
          )
        )
      );
    }
  }]);

  return Select;
}(Component), _class.propTypes = {
  /** Array of options to be displayed. */
  options: PropTypes.arrayOf(PropTypes.shape({
    /** */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** */
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired,
  /** */
  className: PropTypes.string,
  /** Makes the select disabled. */
  disabled: PropTypes.bool,
  id: PropTypes.string,
  /** */
  insideLabel: PropTypes.string,
  /** Callback function which retrieves the selected option. */
  onOptionSelected: PropTypes.func,
  /** Placeholder to be displayed. */
  placeholder: PropTypes.string,
  /** */
  primaryLight: PropTypes.bool,
  /** */
  retrieveOnlyId: PropTypes.bool,
  /** */
  secondary: PropTypes.bool,
  /** */
  selectBoxMenuClassName: PropTypes.string,
  /** Default Selected Value. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    /** */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** */
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }), PropTypes.number]),

  /** */
  wrapperClassName: PropTypes.string

  /** */
}, _class.defaultProps = {
  placeholder: 'Seleccionar',
  retrieveOnlyId: false
}, _temp2);


export default Select;