import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Grid, Typography } from 'rc'
import { Button } from 'components'

const TriangleStyled = styled.span`
  ${({ theme }) => theme.box(20, 20)}
  display: inline-block;
  transform: rotate(45deg);
  background: ${({ theme }) => theme.palette.base.white};
  ${({ theme }) => theme.position('absolute', -10, null, null, 20)};
  z-index: 1;
`

const NotCompletedFields = ({
  percentage,
  ShowModal,
  profile,
  profileSection,
  ...props
}) => {
  const [isInfoModalShown, setIsInfoModalShown] = useState(false)
  const [isInfoModalStatic, setIsInfoModalStatic] = useState(false)
  const mainContainer = useRef()

  useEffect(() => {
    const _handleClickOutside = e => {
      if (mainContainer.current?.contains(e.target) && isInfoModalStatic) {
        setIsInfoModalShown(false)
        setIsInfoModalStatic(false)
      }
    }
    if (isInfoModalStatic)
      document.addEventListener('mousedown', _handleClickOutside)
    else document.removeEventListener('mousedown', _handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', _handleClickOutside)
    }
  }, [isInfoModalStatic])

  const absentFields = {
    section1: {
      title: 'Foto de perfil',
      shouldShow: !profile.user_photo,
      buttonLabel: 'Añadir foto',
      buttonProps: {
        id: 'profile-notCompletedFields-button-goToUserPhoto',
        onClick: () => {
          document
            .querySelector(
              '#profile-profileCover-button-showUploadUserPhotoModal'
            )
            .click()
        }
      }
    },
    section2: {
      title: 'Foto de portada',
      shouldShow: !profile.user_background_image,
      buttonLabel: 'Añadir foto',
      buttonProps: {
        id: 'profile-notCompletedFields-button-goToCoverImage',
        onClick: () => {
          document
            .querySelector(
              '#profile-profileCover-button-showUploadCoverPhotoModal'
            )
            .click()
        }
      }
    },
    section3: {
      title: 'Información del asesor',
      fieldsList: {
        user_phone_number: {
          label: 'Número de Teléfono'
        },
        user_city: {
          label: 'Ciudad'
        },
        user_state: {
          label: 'Estado'
        }
      },
      shouldShow:
        !profile.user_phone_number || !profile.user_city || !profile.user_state,
      buttonLabel: 'Ir a completar',
      buttonProps: {
        id: 'profile-notCompletedFields-button-goToEditPersonalInfo',
        onClick: () => {
          document
            .querySelector('#profile-personalInfo-button-editPersonalInfo')
            .click()
        }
      }
    },
    section4: {
      title: 'Experiencia laboral',
      extraLabel: 'Añade 1 Experiencia Laboral',
      shouldShow:
        profile.work_experience && profile.work_experience.length === 0,
      buttonLabel: 'Ir a completar',
      buttonProps: {
        id: 'profile-notCompletedFields-button-goToExperiences',
        onClick: () => {
          document
            .querySelector(
              '#profile-workExperience-button-newExperienceStatusZero'
            )
            .click()
        }
      }
    },
    section5: {
      title: 'Certificaciones',
      extraLabel: 'Añade 1 certificación',
      shouldShow: profile.certifications && profile.certifications.length === 0,
      buttonLabel: 'Ir a completar',
      buttonProps: {
        id: 'profile-notCompletedFields-button-goToCertifications',
        onClick: () => {
          document
            .querySelector(
              '#profile-profileCertifications-newCertificationStatusZero'
            )
            .click()
        }
      }
    }
  }

  const renderAbsentSection = section => {
    const {
      title = '',
      fieldsList = {},
      extraLabel = '',
      shouldShow = false,
      buttonLabel = '',
      buttonProps = {}
    } = section

    return shouldShow ? (
      <Grid
        container
        direction="column"
        css={`
          margin-top: 25px;
          > * {
            margin-top: 5px;
          }
        `}
      >
        <Typography variant="h4" color="palette.primary.main" fontWeight="bold">
          {title}
        </Typography>
        {extraLabel ? (
          <Typography variant="body4">{extraLabel}</Typography>
        ) : (
          ''
        )}

        {Object.keys(fieldsList)
          .map(key =>
            !profile[key] ? (
              <Typography variant="body4">{fieldsList[key].label}</Typography>
            ) : null
          )
          .filter(item => item)}

        <Button
          css={`
            width: fit-content;
          `}
          type="primary"
          size="small"
          fontWeight="medium"
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      </Grid>
    ) : null
  }

  return percentage !== 100 ? (
    <div
      ref={mainContainer}
      percentage={percentage}
      css={`
        z-index: 1;
        ${({ theme, percentage }) =>
          theme.position(
            'absolute',
            -17,
            null,
            null,
            `calc(${percentage}% - 20px)`
          )}
        user-select:none;
      `}
      onClick={() => {
        if (profileSection) {
          if (!isInfoModalStatic) {
            setIsInfoModalShown(true)
            setIsInfoModalStatic(true)
          } else {
            setIsInfoModalShown(false)
            setIsInfoModalStatic(false)
          }
        }
      }}
      onMouseEnter={() => {
        if (profileSection) setIsInfoModalShown(true)
      }}
      onMouseLeave={() => {
        if (profileSection && !isInfoModalStatic) setIsInfoModalShown(false)
      }}
    >
      <span
        profileSection={profileSection}
        css={`
          cursor: ${({ profileSection }) =>
            profileSection ? 'pointer' : 'default'};
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          ${({ theme }) => theme.box(50, 50, true)}
          ${({ theme }) => theme.bordered('4', theme.palette.base.white)}
            background: ${({ theme }) => theme.palette.primary.dark};
          &:hover {
            background: ${({ theme, profileSection }) =>
              profileSection
                ? theme.palette.primary.main
                : theme.palette.primary.dark};
          }
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        `}
      >
        <Typography variant="caption3" color="white">
          {percentage}%
        </Typography>
      </span>
      {isInfoModalShown && (
        <div
          css={`
            padding: ${({ theme }) => theme.remCalc('15 20 30')};
            position: relative;
            background-color: ${({ theme }) => theme.palette.base.white};
            ${({ theme }) => theme.radius(6)}
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            top: 10px;
            left: -10px;
          `}
        >
          <TriangleStyled />

          <TriangleStyled
            css={`
              z-index: -1;
              box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            `}
          />

          <Grid container direction="column" css={``}>
            <Typography variant="h4">Por Completar:</Typography>
            <div
              css={`
                > :first-child {
                  margin-top: 15px;
                }
              `}
            >
              {Object.keys(absentFields).map(key =>
                renderAbsentSection(absentFields[key])
              )}
            </div>
          </Grid>
        </div>
      )}
    </div>
  ) : null
}

NotCompletedFields.propTypes = {
  percentage: PropTypes.number,
  profile: PropTypes.object,
  profileSection: PropTypes.bool,
  ShowModal: PropTypes.func.isRequired
}

export default NotCompletedFields
