import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ShowFlashNotification, ShowModal } from 'reduxActions/services'
import { Typography, Autocomplete, Tooltip } from 'rc'
import { Location, BarCode, Price, User, Mail, Phone } from 'rc/Icons'
import { SearchAgencyBrokers } from 'reduxActions/agency'
import { AssignLead } from 'reduxActions/dashboard'
import { generateLocation, formatPrice, fromDateAgo } from 'utils/helpers'
import { Button } from 'components'
import { WiggotBoost } from 'components/Icons'
import Image from 'components/Image'
import { ListBrokerHighlightedItem } from 'components/CommonStyled'
import { LeadInterestedForm } from 'modules/dashboard/Leads/modalContent'

const NewWiggotLeadMC = ({ HideModal, leadId, shortName, metadata }) => {
  const history = useHistory()
  const { lead_data, property_data, url } = metadata || {}

  const {
    contact_email = '',
    contact_name = '',
    contact_phone = '',
    created_at
  } = lead_data || {}
  const {
    title,
    property_id,
    street,
    neighborhood,
    city,
    state,
    presale_active,
    presale_price,
    presale_currency,
    sale_active,
    sale_price,
    sale_currency,
    lease_active,
    lease_price,
    lease_currency,
    cover_image
  } = property_data || {}

  const dispatch = useDispatch()
  const [brokerSelected, setBrokerSelected] = useState()
  const { token, hasCampaignUsers, isCampaignUser, roles } = useSelector(
    ({
      authReducer: { token, roles },
      profileReducer: { hasCampaignUsers, isCampaignUser }
    }) => ({ token, hasCampaignUsers, isCampaignUser, roles })
  )

  useEffect(() => {
    if (!lead_data && url) {
      window.open(url, '_self')
    }
  }, [lead_data, url, history])

  const createdAt = fromDateAgo(created_at)
  const premiumValidator = hasCampaignUsers || isCampaignUser || roles.PRIME

  const handleSelectedBroker = (autocomplete_name, selectedValue) => {
    setBrokerSelected(selectedValue)
  }

  const searchAgencyBrokers = value => {
    return new Promise((resolve, reject) => {
      dispatch(SearchAgencyBrokers(token, value))
        .then(res => {
          let {
            payload: { data }
          } = res
          resolve(data)
        })
        .catch(() => {
          reject([])
        })
    })
  }

  const showFlashNotification = (type, message) => {
    return dispatch(ShowFlashNotification(type, message))
  }

  const assignLead = () => {
    const { user_id } = brokerSelected

    dispatch(AssignLead(token, leadId, user_id, shortName))
      .then(response => {
        HideModal()
        showFlashNotification(
          'success',
          'Se ha asignado el asesor correctamente'
        )
      })
      .catch(
        ({
          error: {
            response: { data: { error: { code = '', message = '' } } = {} } = {}
          }
        } = {}) => {
          if (code === 'NOT_FOUND' && message === 'User not found') {
            showFlashNotification(
              'danger',
              'El asesor al que quieres asignarle el Lead no existe. Código de error: WIG-09'
            )
          } else if (
            code === 'NOT_FOUND' &&
            message === 'Properties not found'
          ) {
            showFlashNotification(
              'danger',
              'El Lead que quieres asignar a este asesor no existe. Código de error: WIG-10'
            )
          } else {
            showFlashNotification(
              'danger',
              'Error desconocido, por favor intenta de nuevo más tarde.'
            )
          }
        }
      )
  }

  const tooltip = component => {
    return (
      <Tooltip
        css={`
          width: 100%;
        `}
        content={
          <div
            css={`
              width: 300px;
              white-space: normal;
            `}
          >
            Por ahora no es posible asignar este lead a otro asesor.
            Próximamente podrás hacerlo.
          </div>
        }
        direction="top"
        backGroundColor="#000000"
      >
        {component}
      </Tooltip>
    )
  }

  return (
    <ContainerStyled isFree={!premiumValidator}>
      <Header>
        <Typography
          variant="h2"
          textTransform="initial"
          color="palette.primary.main"
        >
          Nuevo lead
        </Typography>
        <LeadData>
          <WiggotBoost
            css={`
              height: ${({ theme }) => theme.remCalc('22')};
            `}
          />
          <Typography variant="subtitle4" color="palette.new.text">
            {createdAt}
          </Typography>
          <Point />
        </LeadData>
      </Header>
      {!premiumValidator && (
        <Typography variant="body1" align="center">
          Contrata un plan de leads para ver sus datos de contacto y comenzar el
          negocio.
        </Typography>
      )}
      {property_data && (
        <CardProperty>
          <ImageStyled alt="lead-img" id={cover_image} objectFit="cover" />
          <PropertyInfo>
            <Typography variant="subtitle1">{title}</Typography>
            <TypographyStyled>
              <LocationIconStyled />
              {generateLocation(street, neighborhood, city, state)}
            </TypographyStyled>
            {presale_active ? (
              <TypographyStyled>
                <PriceIconStyled />
                Venta: {formatPrice(presale_price)} {presale_currency}
              </TypographyStyled>
            ) : (
              ''
            )}
            {sale_active ? (
              <TypographyStyled>
                <PriceIconStyled />
                Preventa: {formatPrice(sale_price)} {sale_currency}
              </TypographyStyled>
            ) : (
              ''
            )}
            {lease_active ? (
              <TypographyStyled>
                <PriceIconStyled />
                Renta: {formatPrice(lease_price)} {lease_currency}
              </TypographyStyled>
            ) : (
              ''
            )}
            <TypographyStyled>
              <BarCodeStyled /> {property_id}
            </TypographyStyled>
          </PropertyInfo>
        </CardProperty>
      )}
      {premiumValidator &&
        tooltip(
          <AssignLeadCard>
            <Typography variant="subtitle1">Asesor:</Typography>
            <AutocompleteStyled
              fetchValueResolver={results => {
                return results
              }}
              name="broker"
              placeholder="Buscar asesor"
              fetchFunction={searchAgencyBrokers}
              labelItemKey="fullname"
              listItemComponent={ListBrokerHighlightedItem}
              onChange={handleSelectedBroker}
              customHeight={150}
              shouldFetchOnFocus
              disabled
            />
            <Button
              type="secondary"
              rounded
              css={`
                width: ${({ theme }) => theme.remCalc('230')};
              `}
              onClick={assignLead}
              disabled
            >
              Asignar lead
            </Button>
          </AssignLeadCard>
        )}

      {premiumValidator && (
        <LeadInfoCard>
          <Typography variant="subtitle1">Prospecto:</Typography>
          <TypographyStyled>
            <UserIconStyled />
            {contact_name}
          </TypographyStyled>
          <TypographyStyled>
            <MailIconStyled /> {contact_email}
          </TypographyStyled>
          <TypographyStyled>
            <PhoneIconStyled /> {contact_phone}
          </TypographyStyled>
        </LeadInfoCard>
      )}
      {premiumValidator && (
        <Button
          type="tertiary"
          rounded
          css={`
            width: ${({ theme }) => theme.remCalc('230')};
          `}
          onClick={HideModal}
        >
          Cerrar
        </Button>
      )}
      {!premiumValidator && (
        <Header>
          <Button
            type="tertiary"
            rounded
            css={`
              width: ${({ theme }) => theme.remCalc('230')};
            `}
            onClick={HideModal}
          >
            Cerrar
          </Button>
          <Button
            type="secondary"
            rounded
            css={`
              width: ${({ theme }) => theme.remCalc('230')};
            `}
            onClick={() => {
              dispatch(ShowModal(LeadInterestedForm))
            }}
          >
            Más información
          </Button>
        </Header>
      )}
    </ContainerStyled>
  )
}

NewWiggotLeadMC.propTypes = {
  HideModal: PropTypes.func,
  leadId: PropTypes.string,
  metadata: PropTypes.object,
  shortName: PropTypes.string
}

const ContainerStyled = styled.div`
  width: ${({ theme, isFree }) => theme.remCalc(!isFree ? '620' : '550')};
  height: ${({ theme, isFree }) => theme.remCalc(!isFree ? '570' : '450')};
  padding: ${({ theme }) => theme.remCalc('20')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const LeadData = styled.div`
  width: ${({ theme }) => theme.remCalc('250')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
// const CircleLogo = styled.div`
//   ${({ theme }) => theme.box(37, 37, true)}
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #fff;
//   box-shadow: 0px 2px 7px rgba(160, 163, 189, 0.5);
//   margin-right: ${({ theme }) => theme.remCalc('-5')};
// `
// const WiggotHouseLogo = styled(WiggotHouse)`
//   width: ${({ theme }) => theme.remCalc('20')};
// `
const Point = styled.div`
  ${({ theme }) => theme.box(10, 10, true)}
  background-color: ${({ theme }) => theme.palette.tag.dangerBackground};
  margin-left: ${({ theme }) => theme.remCalc('-8')};
`
const CommonCardStyled = css`
  width: 100%;
  padding: ${({ theme }) => theme.remCalc('15 10')};
  border-radius: ${({ theme }) => theme.remCalc('10')};
  border: 1px solid #e8ecf9;
  display: flex;
`
const CardProperty = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.remCalc('160')};
  ${CommonCardStyled}
`
const AssignLeadCard = styled.div`
  height: ${({ theme }) => theme.remCalc('80')};
  justify-content: space-between;
  align-items: center;
  ${CommonCardStyled}
`
const LeadInfoCard = styled.div`
  height: ${({ theme }) => theme.remCalc('140')};
  flex-direction: column;
  justify-content: space-around;
  ${CommonCardStyled}
`
const PropertyInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
const ImageStyled = styled(Image)`
  border-radius: ${({ theme }) => theme.remCalc('10')};
  overflow: hidden;
  width: ${({ theme }) => theme.remCalc('130')};
  height: ${({ theme }) => theme.remCalc('130')};
  margin-right: ${({ theme }) => theme.remCalc('20')};
`
const TypographyStyled = styled(Typography).attrs({
  variant: 'body2',
  color: 'palette.new.text'
})`
  display: flex;
  align-items: center;
`
const AutocompleteStyled = styled(Autocomplete)`
  > * {
    margin-top: 0;
    > * {
      border-radius: 6px;
    }
  }
`
const CommonIconStyled = css`
  color: #7d7f9c;
  fill: #7d7f9c;
  margin-right: ${({ theme }) => theme.remCalc('4')};
`
const LocationIconStyled = styled(Location)`
  width: ${({ theme }) => theme.remCalc('16')};
  ${CommonIconStyled}
`
const PriceIconStyled = styled(Price)`
  width: ${({ theme }) => theme.remCalc('16')};
  ${CommonIconStyled}
`
const BarCodeStyled = styled(BarCode)`
  width: ${({ theme }) => theme.remCalc('16')};
  ${CommonIconStyled}
`
const UserIconStyled = styled(User)`
  width: ${({ theme }) => theme.remCalc('16')};
  ${CommonIconStyled}
`
const MailIconStyled = styled(Mail)`
  width: ${({ theme }) => theme.remCalc('16')};
  ${CommonIconStyled}
`
const PhoneIconStyled = styled(Phone)`
  width: ${({ theme }) => theme.remCalc('16')};
  ${CommonIconStyled}
`
export default NewWiggotLeadMC
