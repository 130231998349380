const storageKey = 'tab-sync-channel'
const sourceId = Math.floor(Math.random() * 10000)

function wrapAction(action) {
  return {
    action,
    sourceId,
    time: Date.now()
  }
}

export function sendThroughChannel() {
  return () => next => action => {
    if (action.sendThroughChannel) {
      const wrappedAction = wrapAction(action)
      localStorage.setItem(storageKey, JSON.stringify(wrappedAction))
    }
    next(action)
  }
}

export function dispatchOnWrite(dispatch) {
  return () => {
    const wrappedAction = JSON.parse(localStorage.getItem(storageKey))
    if (wrappedAction) {
      if (wrappedAction.sourceId !== sourceId) {
        delete wrappedAction.action.sendThroughChannel
        dispatch(wrappedAction.action)
        localStorage.removeItem(storageKey)
      }
    }
  }
}
