import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ShowModal } from 'reduxActions/services'

import { Typography, Grid } from 'rc'
import { Engine } from 'rc/Icons'
import ContainerHeader from 'modules/profile/components/ContainerHeader'
import {
  ProfileSettingsMC,
  GradeAgencyBrokerMC
} from 'modules/profile/modalContent'
import { ProfileContext } from 'modules/profile'
import ShareProfileButton from './_ShareProfileButton'
import { Button } from 'components'
import FreemiumWrapper from 'modules/freemium/FreemiumWrapper'
import { RULES } from 'modules/freemium/utils/constants'

const ProfileHeader = ({ ShowModal }) => {
  const {
    isMyProfile,
    profile: {
      profile_id: profileId,
      user_fullname: profileName,
      user_photo: profilePhoto,
      scores = []
    }
  } = useContext(ProfileContext)

  const myScoreGiven = scores.find(({ comment_owner }) => comment_owner)

  return isMyProfile ? (
    <ContainerHeader>
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="h2" textTransform="initial">
          Mi perfil
        </Typography>
        <Grid
          container
          inline
          alignItems="center"
          css={`
            > :not(last-child) {
              margin-right: ${({ theme }) => theme.remCalc('10')};
            }
          `}
        >
          <FreemiumWrapper section={RULES.PROFILE_PREVIEW} label={"Previsualizar"}>
            <Button
              width="large"
              id="profile-profileDetail-button-showProfilePreview"
              css={`
                display: none;
                ${({ theme }) => theme.media.tablet`
                display: block;
              `}
              `}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_URL}/profile/public/preview`,
                  '_blank'
                )
              }}
            >
              Previsualizar
            </Button>
          </FreemiumWrapper>
          <ShareProfileButton />
          <Grid
            id="profile-header-button-config"
            container
            inline
            center
            alignItems="center"
            css={`
              background-color: ${({ theme }) => theme.palette.text.grayMedium};
              ${({ theme }) => theme.box(50, 50, true)}
              cursor: pointer;
            `}
            onClick={() =>
              ShowModal(
                ProfileSettingsMC,
                {},
                { overflow: false, noClosingButton: false }
              )
            }
          >
            <Engine
              css={`
                fill: ${({ theme }) => theme.palette.base.white};
                color: ${({ theme }) => theme.palette.base.white};
                ${({ theme }) => theme.box(25, 25)}
              `}
            />
          </Grid>
        </Grid>
      </Grid>
    </ContainerHeader>
  ) : (
    <ContainerHeader
      css={`
        margin-bottom: 10px;
      `}
    >
      <Button
        onClick={() => {
          ShowModal(GradeAgencyBrokerMC, {
            profileId,
            profileName,
            profilePhoto,
            initialValues: {
              score: myScoreGiven ? myScoreGiven.score : 0,
              comment: myScoreGiven ? myScoreGiven.comment : ''
            }
          })
        }}
        css={`
          margin-left: auto;
        `}
      >
        Calificar asesor
      </Button>
    </ContainerHeader>
  )
}

ProfileHeader.propTypes = {
  ShowModal: PropTypes.func
}

const mapDispatchToProps = {
  ShowModal
}

export default connect(null, mapDispatchToProps)(ProfileHeader)
