import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { connect } from 'react-redux'
import { ShowModal } from 'reduxActions/services'

import { Grid, Typography, Link } from 'rc'
import { StarRounded, Building } from 'rc/Icons'
import { ProfileSectionCard, SimpleText } from 'modules/profile/components'
import { ProfileContext } from 'modules/profile'
import { ProfileAgencyMC } from 'modules/profile/modalContent'
import { UpdateAgency } from 'reduxActions/profile'
import ImageCropperUploader from 'components/ImageCropperUploader'

const ScoreStyled = styled(Grid)`
  ${({ theme }) => theme.box(80, 40)}
  border-radius: ${({ theme }) => theme.remCalc('20')};
  ${({ theme }) => theme.position('absolute', 20, -20)}
  z-index: 1;
  padding: ${({ theme }) => theme.remCalc('0 12')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.base.white};
  display: none;
`

const ProfileAgencyInfo = ({ roles, token, ShowModal, UpdateAgency }) => {
  const {
    profile,
    profile: {
      agency_logo,
      agency_score,
      agency_name,
      agency_state,
      agency_city,
      agency_neighborhood,
      agency_street,
      agency_external_number,
      agency_internal_number,
      agency_postal_code,
      agency_email,
      agency_phone_number,
      agency_phone_number_2,
      agency_broker_count,
      agency_description,
      is_agency_broker
    } = {},
    isMyProfile,
    customChange
  } = useContext(ProfileContext)

  const showProfileAgencyMC = () => {
    ShowModal(
      ProfileAgencyMC,
      {
        profile,
        onInfoUpdated: customChange
      }      
    )
  }

  return is_agency_broker ? (
    <ProfileSectionCard
      id="agencyCard"
      title="Datos de la inmobiliaria"
      isEditable={isMyProfile && roles.AGENCY}
      actions={[
        {
          id: 'profile-detail-button-editAgencyInfo',
          isEditable: true,
          onClick: () => showProfileAgencyMC()
        }
      ]}
    >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        css={`
          flex-direction: column;
          ${({ theme }) => theme.media.desktop`
            flex-direction: row;
          `}
        `}
      >
        <Grid
          css={`
            position: relative;
          `}
        >
          <ImageCropperUploader
            idBase="profile-agencyInfo"
            showCameraButton={isMyProfile && roles.AGENCY}
            Holder={() => (
              <Building
                css={`
                  ${({ theme }) =>
                    theme.position('absolute', 40, 'calc(50% - 60px)')}
                  width:${({ theme }) => theme.remCalc('120')}
                  fill: white;
                `}
              />
            )}
            aspect={1}
            imageId={agency_logo}
            cropShape="round"
            uploadType="logo"
            onImageSelected={async imageId => {
              try {
                await UpdateAgency(token, {
                  agency_logo: imageId
                })

                customChange({ agency_logo: imageId })
              } catch (error) {
                /** */
              }
            }}
          />

          <ScoreStyled container>
            <StarRounded
              css={`
                ${({ theme }) => theme.box(20, 20)}
              `}
            />
            <Typography color="white" variant="h2">
              {agency_score}
            </Typography>
          </ScoreStyled>
        </Grid>
        <Grid
          container
          justify="space-around"
          css={`
            flex: 1;
            position: relative;
            ${({ theme }) => theme.media.desktop`
              padding: ${({ theme }) => theme.remCalc('10 30')};
            `}
          `}
        >
          <Grid
            container
            direction="column"
            css={`
              padding-top: ${({ theme }) => theme.remCalc('10')};
              ${({ theme }) => theme.media.desktop`
                padding: ${({ theme }) => theme.remCalc('10 20 10 0')};
                
                text-align: initial;              
                width: 45%;

                &:after{
                  content: '';
                  width: 1px; 
                  position: absolute;
                  top: 10px; 
                  bottom: 10px;
                  left: 50%;
                  background: ${({ theme }) => theme.palette.base.black};
                }
                
              `}
            `}
          >
            <SimpleText variant="subtitle3">Nombre de inmobiliaria</SimpleText>
            <SimpleText flat>{agency_name ? agency_name : '-'}</SimpleText>

            <SimpleText variant="subtitle3">Email de inmobiliaria</SimpleText>
            <SimpleText flat>
              {agency_email ? (
                <Link
                  underline="always"
                  id="profile-profileDetail-link-openAgencyEmail"
                  href={`mailto:${agency_email}`}
                >
                  {agency_email}
                </Link>
              ) : (
                '-'
              )}
            </SimpleText>

            <SimpleText variant="subtitle3">
              Asesores de inmobiliaria
            </SimpleText>
            <SimpleText flat>
              {agency_broker_count
                ? `${agency_broker_count} asesor${
                    agency_broker_count > 2 ? 'es' : ''
                  }`
                : '-'}
            </SimpleText>

            <SimpleText variant="subtitle3">
              Teléfono de inmobiliaria
            </SimpleText>
            <SimpleText>
              {agency_phone_number ? (
                <Link
                  id="profile-profileDetail-link-openAgencyPhone-1"
                  underline="always"
                  href={`tel:+${agency_phone_number}`}
                >
                  {agency_phone_number}
                </Link>
              ) : (
                '-'
              )}
            </SimpleText>

            <SimpleText variant="subtitle3">
              Teléfono 2 de inmobiliaria
            </SimpleText>
            <SimpleText>
              {agency_phone_number_2 ? (
                <Link
                  id="profile-profileDetail-link-openAgencyPhone-2"
                  underline="always"
                  href={`tel:+${agency_phone_number_2}`}
                >
                  {agency_phone_number_2}
                </Link>
              ) : (
                '-'
              )}
            </SimpleText>
          </Grid>
          <Grid
            container
            direction="column"
            css={`
              padding: ${({ theme }) => theme.remCalc('10 0 10 0')};
              ${({ theme }) => theme.media.desktop`
                height: 100%;
                width: 40%;
                text-align: initial; 
              `}
            `}
          >
            <SimpleText variant="subtitle3">Estado</SimpleText>
            <SimpleText flat>{agency_state || '-'}</SimpleText>

            <SimpleText variant="subtitle3">Ciudad</SimpleText>
            <SimpleText flat>{agency_city || '-'}</SimpleText>

            <SimpleText variant="subtitle3">Colonia</SimpleText>
            <SimpleText flat>{agency_neighborhood || '-'}</SimpleText>

            <SimpleText variant="subtitle3">Domicilio</SimpleText>
            <SimpleText flat>
              {agency_street || agency_neighborhood || agency_external_number
                ? `${agency_street ? `${agency_street}, ` : ''}${
                    agency_external_number
                      ? `Ext. ${agency_external_number}, `
                      : ''
                  }${
                    agency_internal_number
                      ? `Int. ${agency_internal_number}, `
                      : ''
                  }`
                : '-'}
            </SimpleText>
            <SimpleText variant="subtitle3">Código postal</SimpleText>
            <SimpleText flat>
              {agency_postal_code ? `CP. ${agency_postal_code}` : '-'}
            </SimpleText>
          </Grid>
        </Grid>
      </Grid>
      {agency_description && (
        <Grid
          container
          direction="column"
          css={`
            flex: 1;
            ${({ theme }) => theme.media.desktop`            
              padding: ${({ theme }) => theme.remCalc('30 0 0')};
              text-align: initial;            
            `}
          `}
        >
          <Typography color="black" variant="h2">
            Descripción
          </Typography>
          <SimpleText>{agency_description}</SimpleText>
        </Grid>
      )}
    </ProfileSectionCard>
  ) : null
}

ProfileAgencyInfo.propTypes = {
  roles: PropTypes.object,
  token: PropTypes.string,
  ShowModal: PropTypes.func,
  UpdateAgency: PropTypes.func
}

const mapStateToProps = ({ authReducer: { token, roles } }) => ({
  token,
  roles
})

const mapDispatchToProps = {
  ShowModal,
  UpdateAgency
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileAgencyInfo)
