import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { TextField, Typography, RadioGroup, Radio, Button, withForm } from 'rc'
import { Mail, Lock, Information } from 'rc/Icons'
import { isEmail } from 'rc/helpers/stringHelpers'

import Eye from 'static/img/visibility-on.svg'
import BlindEye from 'static/img/visibility-off.svg'

const BaseForm = ({
  accountType,
  setAccountType,
  formStep,
  setFormStep,
  showErrorNotification,
  fc
}) => {
  const [visible, setVisible] = useState(false)
  const [brokerAccInfo, setBrokerAccInfo] = useState(false)
  const [agencyAccInfo, setAgencyAccInfo] = useState(false)
  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }
  const handleNextStep = () => {
    const { values } = fc

    const minimumFields = ['fullname', 'email', 'password', 'confirm_password']
    const missingFields = minimumFields.filter(item => !values[item])
    const validEmail = isEmail(values['email'])

    if (missingFields.length > 0)
      return showErrorNotification('Ningun campo puede estar vacío.')
    if (!validEmail) return showErrorNotification('Ingresa un email válido.')
    if (values['password'] !== values['confirm_password'])
      return showErrorNotification('Las contraseñas no coinciden.')

    setFormStep(2)
  }

  const handleMouseEvnt = (brokerType, isActive = false) =>
    brokerType ? setBrokerAccInfo(isActive) : setAgencyAccInfo(isActive)
  return (
    <>
      <div
        css={`
          display: flex;
          flex-direction: column;
        `}
      >
        <RadioGroupStyled name="user_type" isRadioRequired={true}>
          <LabelStyled>
            <RadioStyled
              type="radio"
              value={'regular'}
              checked={accountType === 'regular'}
              onChange={() => setAccountType('regular')}
            />
            <LabelText>
              Crear una cuenta de <b>asesor</b>
              <Information
                onMouseEnter={() => handleMouseEvnt(true, true)}
                onMouseLeave={() => handleMouseEvnt(true)}
              />
            </LabelText>
            <InfoContainer className={brokerAccInfo ? 'is-active' : ''}>
              Maneja tu inventario, comparte tus propiedades y lleva tus
              negocios de manera individual
            </InfoContainer>
          </LabelStyled>
          <LabelStyled>
            <RadioStyled
              type="radio"
              value={'agency'}
              checked={accountType === 'agency'}
              onChange={() => setAccountType('agency')}
            />
            <LabelText>
              Crear una cuenta de <b>inmobiliaria</b>
              <Information
                onMouseEnter={() => handleMouseEvnt(false, true)}
                onMouseLeave={() => handleMouseEvnt(false)}
              />
            </LabelText>
            <InfoContainer className={agencyAccInfo ? 'is-active' : ''}>
              Agrega asesores a tu equipo, asígnales clientes, propiedades,
              tareas y monitorea su avance. Además, obtén un sitio web
              profesional con el inventario de tu inmobiliaria.
            </InfoContainer>
          </LabelStyled>
        </RadioGroupStyled>
        <TextFieldStyled
          fullWidth
          label="Nombre completo"
          name="fullname"
          validate={isRequired}
          InputProps={{
            startAdornment: (
              <StartIconContainer>
                <Mail />
              </StartIconContainer>
            )
          }}
        />
        <TextFieldStyled
          fullWidth
          label="Correo electrónico"
          name="email"
          validate={isRequired}
          InputProps={{
            startAdornment: (
              <StartIconContainer>
                <Mail />
              </StartIconContainer>
            )
          }}
        />
        <TextFieldStyled
          fullWidth
          type={visible ? 'text' : 'password'}
          label="Contraseña"
          name="password"
          validate={isRequired}
          InputProps={{
            startAdornment: (
              <StartIconContainer>
                <Lock />
              </StartIconContainer>
            ),
            endAdornment: (
              <EndIconContainer onClick={() => setVisible(!visible)}>
                {visible ? (
                  <img src={BlindEye} alt="blind-eye-icon" />
                ) : (
                  <img src={Eye} alt="eye-icon" />
                )}
              </EndIconContainer>
            )
          }}
        />
        <TextFieldStyled
          fullWidth
          type={visible ? 'text' : 'password'}
          label="Confirmar contraseña"
          name="confirm_password"
          validate={isRequired}
          InputProps={{
            startAdornment: (
              <StartIconContainer>
                <Lock />
              </StartIconContainer>
            ),
            endAdornment: (
              <EndIconContainer onClick={() => setVisible(!visible)}>
                {visible ? (
                  <img src={BlindEye} alt="blind-eye-icon" />
                ) : (
                  <img src={Eye} alt="eye-icon" />
                )}
              </EndIconContainer>
            )
          }}
        />
      </div>
      <div
        css={`
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
        `}
      >
        <ButtonStyled full size="large" onClick={handleNextStep}>
          Siguiente {accountType === 'agency' && `(${formStep}/4)`}
        </ButtonStyled>
      </div>
    </>
  )
}

const RadioGroupStyled = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  margin: 0;
  > * {
    margin-bottom: 1rem;
  }
`
const RadioStyled = styled(Radio)`
  margin-right: ${({ theme }) => theme.remCalc('8')};
`
const LabelText = styled(Typography).attrs(props => ({
  ...props,
  variant: 'subtitle4',
  fontWeight: 'regular',
  display: 'flex',
  color: 'palette.new.text'
}))`
  align-items: center;
  > b {
    margin: ${({ theme }) => theme.remCalc('0 8 0 4')};
  }
  > svg {
    width: ${({ theme }) => theme.remCalc('18')};
    fill: #a0a3bd;
    &:hover {
      fill: #605d75;
    }
  }
`
const InfoContainer = styled.div`
  display: none;
  width: 14rem;
  padding: 0.3125rem 0.625rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: ${({ theme }) => theme.remCalc('5')};
  background: #000000;
  color: #ffffff;
  font-size: ${({ theme }) => theme.remCalc('12')};
  font-weight: 600;
  line-height: 150%;
  position: absolute;
  right: -25%;
  top: 100%;
  z-index: 5;
  &.is-active {
    display: flex;
  }
`
const LabelStyled = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`
const TextFieldStyled = styled(TextField)`
  margin: ${({ theme }) => theme.remCalc('16 0')};
  > label {
    left: ${({ theme }) => theme.remCalc('20')};
  }
  > div > fieldset {
    padding-left: 28px;
  }
`
const StartIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.remCalc('10')};
  width: ${({ theme }) => theme.remCalc('20')};
  > svg {
    width: 100%;
    color: ${({ theme }) => theme.palette.text.grayDark};
    fill: ${({ theme }) => theme.palette.text.grayDark};
  }
`
const EndIconContainer = styled.div`
  margin-right: ${({ theme }) => theme.remCalc('5')};
  cursor: pointer;
  width: ${({ theme }) => theme.remCalc('38')};
  > img {
    width: 100%;
    color: ${({ theme }) => theme.palette.text.grayDark};
    fill: ${({ theme }) => theme.palette.text.grayDark};
  }
`
const ButtonStyled = styled(Button)`
  border-radius: 50px !important;
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-color: ${({ theme }) => theme.palette.primary.light};
`

BaseForm.propTypes = {
  accountType: PropTypes.string,
  setAccountType: PropTypes.func,
  formStep: PropTypes.number,
  setFormStep: PropTypes.func,
  showErrorNotification: PropTypes.func,
  fc: PropTypes.object
}

export default withForm(BaseForm)
