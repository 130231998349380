import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import TagManager from 'react-gtm-module'

export const useTagManagerInitialize = () => {
  const { profileReducer, token, adminToken, roles } = useSelector(
    ({ authReducer: { token, adminToken, roles }, profileReducer }) => ({
      profileReducer,
      token,
      adminToken,
      roles
    })
  )

  const { userId } = useSelector(({ profileReducer: { userId } }) => ({
    userId
  }))

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
      dataLayer: {
        profile: { ...profileReducer, ...roles },
        session: { isAdmin: !!adminToken }
      }
    }

    if (process.env.REACT_APP_ENV === 'PROD' && !adminToken) {
      TagManager.initialize(tagManagerArgs)
    }
  }, [adminToken, token, userId, roles, profileReducer])
}
