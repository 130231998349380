var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/Layouts/Layout.js';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'redux-form';
import { LayoutItem } from './';
import ResponsiveHandler from '../General/ResponsiveHandler';
/**
 * Creates a layout and its corresponding responsive.
 */
var Layout = (_temp2 = _class = function (_Component) {
  _inherits(Layout, _Component);

  function Layout() {
    var _ref,
        _this2 = this;

    var _temp, _this, _ret;

    _classCallCheck(this, Layout);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Layout.__proto__ || Object.getPrototypeOf(Layout)).call.apply(_ref, [this].concat(args))), _this), _this.renderChild = function (child, index) {
      if (!React.isValidElement(child)) {
        return child;
      }

      switch (child.type) {
        case ResponsiveHandler:
          {
            var _child$props = child.props,
                children = _child$props.children,
                rest = _objectWithoutProperties(_child$props, ['children']);

            return React.createElement(
              ResponsiveHandler,
              Object.assign({}, rest, {
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 122
                },
                __self: _this2
              }),
              React.Children.map(child.props.children, function (child, index) {
                return _this.renderChild(child, index);
              })
            );
          }
        case LayoutItem || Layout:
          return child;

        case Field:
          {
            var _child$props$props = child.props.props,
                auto = _child$props$props.auto,
                mediumAuto = _child$props$props.mediumAuto,
                largeAuto = _child$props$props.largeAuto,
                shrink = _child$props$props.shrink,
                mediumShrink = _child$props$props.mediumShrink,
                largeShrink = _child$props$props.largeShrink,
                selfBottom = _child$props$props.selfBottom,
                selfMiddle = _child$props$props.selfMiddle,
                selfTop = _child$props$props.selfTop,
                small = _child$props$props.small,
                medium = _child$props$props.medium,
                large = _child$props$props.large,
                smOffset = _child$props$props.smOffset,
                mdOffset = _child$props$props.mdOffset,
                lgOffset = _child$props$props.lgOffset;


            return React.createElement(
              LayoutItem,
              {
                auto: auto,
                mediumAuto: mediumAuto,
                largeAuto: largeAuto,
                shrink: shrink,
                mediumShrink: mediumShrink,
                largeShrink: largeShrink,
                selfBottom: selfBottom,
                selfMiddle: selfMiddle,
                selfTop: selfTop,
                small: small,
                medium: medium,
                large: large,
                smOffset: smOffset,
                mdOffset: mdOffset,
                lgOffset: lgOffset,
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 152
                },
                __self: _this2
              },
              React.createElement(child.type, child.props)
            );
          }

        default:
          {
            var _child$props2 = child.props,
                _auto = _child$props2.auto,
                _mediumAuto = _child$props2.mediumAuto,
                _largeAuto = _child$props2.largeAuto,
                _shrink = _child$props2.shrink,
                _mediumShrink = _child$props2.mediumShrink,
                _largeShrink = _child$props2.largeShrink,
                _selfBottom = _child$props2.selfBottom,
                _selfMiddle = _child$props2.selfMiddle,
                _selfTop = _child$props2.selfTop,
                _small = _child$props2.small,
                _medium = _child$props2.medium,
                _large = _child$props2.large,
                _smOffset = _child$props2.smOffset,
                _mdOffset = _child$props2.mdOffset,
                _lgOffset = _child$props2.lgOffset,
                noResponsive = _child$props2.noResponsive,
                restProps = _objectWithoutProperties(_child$props2, ['auto', 'mediumAuto', 'largeAuto', 'shrink', 'mediumShrink', 'largeShrink', 'selfBottom', 'selfMiddle', 'selfTop', 'small', 'medium', 'large', 'smOffset', 'mdOffset', 'lgOffset', 'noResponsive']);

            if (noResponsive) {
              return child;
            }
            return React.createElement(
              LayoutItem,
              {
                auto: _auto,
                mediumAuto: _mediumAuto,
                largeAuto: _largeAuto,
                shrink: _shrink,
                mediumShrink: _mediumShrink,
                largeShrink: _largeShrink,
                selfBottom: _selfBottom,
                selfMiddle: _selfMiddle,
                selfTop: _selfTop,
                small: _small,
                medium: _medium,
                large: _large,
                smOffset: _smOffset,
                mdOffset: _mdOffset,
                lgOffset: _lgOffset,
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 199
                },
                __self: _this2
              },
              React.createElement(child.type, restProps)
            );
          }
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(Layout, [{
    key: 'render',
    value: function render() {
      var _classNames,
          _this3 = this;

      var _props = this.props,
          tabIndex = _props.tabIndex,
          id = _props.id,
          className = _props.className,
          children = _props.children,
          vertical = _props.vertical,
          reverse = _props.reverse,
          smallColumns = _props.smallColumns,
          mediumColumns = _props.mediumColumns,
          largeColumns = _props.largeColumns,
          right = _props.right,
          center = _props.center,
          justify = _props.justify,
          spaced = _props.spaced,
          middle = _props.middle,
          top = _props.top,
          bottom = _props.bottom,
          onClick = _props.onClick,
          windowSize = _props.windowSize,
          decorated = _props.decorated,
          alert = _props.alert,
          warning = _props.warning,
          success = _props.success,
          marginX = _props.marginX,
          marginY = _props.marginY,
          smallMarginCollapse = _props.smallMarginCollapse,
          mediumMarginCollapse = _props.mediumMarginCollapse,
          largeMarginCollapse = _props.largeMarginCollapse,
          paddingX = _props.paddingX,
          paddingY = _props.paddingY,
          smallPaddingCollapse = _props.smallPaddingCollapse,
          mediumPaddingCollapse = _props.mediumPaddingCollapse,
          largePaddingCollapse = _props.largePaddingCollapse,
          alertLight = _props.alertLight,
          warningLight = _props.warningLight,
          successLight = _props.successLight,
          fullHeight = _props.fullHeight;

      var layoutClasses = classNames(className, (_classNames = {
        'grid-x': !vertical,
        'grid-y': vertical,
        'flex-dir-row-reverse': !vertical && reverse,
        'flex-dir-column-reverse': vertical && reverse
      }, _defineProperty(_classNames, 'small-up-' + smallColumns, smallColumns), _defineProperty(_classNames, 'medium-up-' + mediumColumns, mediumColumns), _defineProperty(_classNames, 'large-up-' + largeColumns, largeColumns), _defineProperty(_classNames, 'align-right', right), _defineProperty(_classNames, 'align-center', center), _defineProperty(_classNames, 'align-justify', justify), _defineProperty(_classNames, 'align-spaced', spaced), _defineProperty(_classNames, 'align-middle', middle), _defineProperty(_classNames, 'align-top', top), _defineProperty(_classNames, 'align-bottom', bottom), _defineProperty(_classNames, 'layout-container--window-size', windowSize), _defineProperty(_classNames, 'layout-container--decorated', decorated), _defineProperty(_classNames, 'layout-container--alert', alert), _defineProperty(_classNames, 'layout-container--warning', warning), _defineProperty(_classNames, 'layout-container--success', success), _defineProperty(_classNames, 'grid-margin-x', marginX), _defineProperty(_classNames, 'grid-margin-y', marginY), _defineProperty(_classNames, 'small-margin-collapse', smallMarginCollapse), _defineProperty(_classNames, 'medium-margin-collapse', mediumMarginCollapse), _defineProperty(_classNames, 'large-margin-collapse', largeMarginCollapse), _defineProperty(_classNames, 'grid-padding-x', paddingX), _defineProperty(_classNames, 'grid-padding-y', paddingY), _defineProperty(_classNames, 'small-padding-collapse', smallPaddingCollapse), _defineProperty(_classNames, 'medium-padding-collapse', mediumPaddingCollapse), _defineProperty(_classNames, 'large-padding-collapse', largePaddingCollapse), _defineProperty(_classNames, 'layout-container--alert-light', alertLight), _defineProperty(_classNames, 'layout-container--warning-light', warningLight), _defineProperty(_classNames, 'layout-container--success-light', successLight), _defineProperty(_classNames, 'layout-container--full-height', fullHeight), _classNames), 'layout-container');

      return React.createElement(
        'div',
        {
          id: id,
          tabIndex: tabIndex,
          className: layoutClasses,
          onClick: onClick,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 303
          },
          __self: this
        },
        React.Children.map(children, function (child, index) {
          return _this3.renderChild(child, index);
        })
      );
    }
  }]);

  return Layout;
}(Component), _class.propTypes = {
  /** Creates a layout with alert style*/
  alert: PropTypes.bool,
  /** */
  alertLight: PropTypes.bool,
  /** Align columns to bottom according to their size */
  bottom: PropTypes.bool,
  /** Align columns centered */
  center: PropTypes.bool,
  /** Things to render as children */
  children: PropTypes.any,
  /** Render necessary classes to layout */
  className: PropTypes.string,
  /** Specifies if the layout container must be decorated */
  decorated: PropTypes.bool,
  /** */
  fullHeight: PropTypes.bool,
  /** Sets the ID of the element */
  id: PropTypes.string,
  /** */
  inline: PropTypes.bool,
  /** Align columns justified */
  justify: PropTypes.bool,
  /** Up to number of columns in a large device */
  largeColumns: PropTypes.number,
  /** Removes margin-x (margin-y vertical) layout on large size. */
  largeMarginCollapse: PropTypes.bool,
  /** Removes padding-x (Padding-y vertical) layout on large size. */
  largePaddingCollapse: PropTypes.bool,
  /** Sets the margin of left & right side, half of $grid-margin-gutters
   *  to each element inside the Layout to cover all space*/
  marginX: PropTypes.bool,
  /** Sets a margin bottom to each element inside Layout which size is half of $grid-margin-gutters */
  marginY: PropTypes.bool,
  /** Up to number of columns in a medium device */
  mediumColumns: PropTypes.number,
  /** Removes margin-x (margin-y vertical) layout on medium size. */
  mediumMarginCollapse: PropTypes.bool,
  /** Removes padding-x (Padding-y vertical) layout on medium size. */
  mediumPaddingCollapse: PropTypes.bool,
  /** Align columns to middle according to their size */
  middle: PropTypes.bool,
  /** OnClick event added */
  onClick: PropTypes.func,
  /** Sets the padding of left & right side half of $grid-padding-gutters*/
  paddingX: PropTypes.bool,
  /** Sets the padding of top & bottom side half of $grid-padding-gutters*/
  paddingY: PropTypes.bool,
  /** Reverse Axis */
  reverse: PropTypes.bool,
  /** Align columns to right */
  right: PropTypes.bool,
  /** Up to number of columns in a small device */
  smallColumns: PropTypes.number,
  /** Removes margin-x (margin-y vertical) layout on small size. */
  smallMarginCollapse: PropTypes.bool,
  /** Removes padding-x (Padding-y vertical) layout on small size. */
  smallPaddingCollapse: PropTypes.bool,
  /** Align columns spaced */
  spaced: PropTypes.bool,
  /** Creates a layout with success style*/
  success: PropTypes.bool,
  /** */
  successLight: PropTypes.bool,
  /** */
  tabIndex: PropTypes.string,
  /** Align columns to top according to their size */
  top: PropTypes.bool,
  /** Axis to display the columns */
  vertical: PropTypes.bool,
  /** Creates a layout with warning style*/
  warning: PropTypes.bool,
  /** */
  warningLight: PropTypes.bool,
  /** Makes the layout full height (window height) */
  windowSize: PropTypes.bool
}, _class.defaultProps = {
  className: '',
  tabIndex: null,
  children: '',
  vertical: false,
  reverse: false,
  smallColumns: 0,
  mediumColumns: 0,
  largeColumns: 0,
  right: false,
  center: false,
  justify: false,
  spaced: false,
  middle: false,
  top: false,
  bottom: false,
  onClick: undefined,
  windowSize: false,
  decorated: false,
  alert: false,
  warning: false,
  success: false
}, _temp2);


export default Layout;