import React from 'react'
import PropTypes from 'prop-types'

import { SubmitButton as LibSubmitButton } from 'rc'

const SubmitButton = ({ type, ...props }) => {
  const getDefaultProps = () => {
    switch (type) {
      case 'primary':
        return {
          color: 'primary',
          size: 'large'
        }
      case 'secondary':
        return {
          color: 'secondary',
          variant: 'outlined',
          size: 'large'
        }
      case 'tertiary':
        return {
          color: 'tertiary',
          variant: 'outlined',
          size: 'large'
        }

      default:
        return {}
    }
  }

  const withDefaultProps = {
    ...getDefaultProps(),
    ...props
  }
  return (
    <LibSubmitButton
      css={`
        min-width: ${({ theme, width }) => {
          if (!width) {
            return
          }
          switch (width) {
            case 'large':
              return theme.remCalc('200')
            default:
              break
          }
        }};
      `}
      {...withDefaultProps}
    />
  )
}

SubmitButton.defaultProps = {
  type: 'primary'
}

SubmitButton.propTypes = {
  type: PropTypes.string,
  width: PropTypes.oneOf(['large'])
}

export default SubmitButton
