var _jsxFileName = 'src/lib/generics/Links/Link.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
/**
 * Used to render an <a> tag with c3 style.
 */
var BASE_CLASSNAME = 'wig-link';
var Link = function Link(_ref) {
  var _classNames;

  var small = _ref.small,
      medium = _ref.medium,
      large = _ref.large,
      gray = _ref.gray,
      white = _ref.white,
      primary = _ref.primary,
      secondary = _ref.secondary,
      uppercase = _ref.uppercase,
      black = _ref.black,
      bold = _ref.bold,
      semiBold = _ref.semiBold,
      regular = _ref.regular,
      light = _ref.light,
      text = _ref.children,
      spacing = _ref.spacing,
      route = _ref.route,
      href = _ref.href,
      colorNone = _ref.colorNone,
      primarySimple = _ref.primarySimple,
      target = _ref.target,
      likeButton = _ref.likeButton,
      id = _ref.id,
      className = _ref.className;

  var linkClasses = classNames(BASE_CLASSNAME, className, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--spacing', spacing), _defineProperty(_classNames, BASE_CLASSNAME + '--small', small), _defineProperty(_classNames, BASE_CLASSNAME + '--medium', medium), _defineProperty(_classNames, BASE_CLASSNAME + '--large', large), _defineProperty(_classNames, BASE_CLASSNAME + '--primary', !likeButton && primary), _defineProperty(_classNames, BASE_CLASSNAME + '--primary-simple', !likeButton && primarySimple), _defineProperty(_classNames, BASE_CLASSNAME + '--primary-button', likeButton && primary), _defineProperty(_classNames, BASE_CLASSNAME + '--primary-simple-button', likeButton && primarySimple), _defineProperty(_classNames, BASE_CLASSNAME + '--white', white), _defineProperty(_classNames, BASE_CLASSNAME + '--gray', gray), _defineProperty(_classNames, BASE_CLASSNAME + '--secondary', !likeButton && secondary), _defineProperty(_classNames, BASE_CLASSNAME + '--secondary-button', likeButton && secondary), _defineProperty(_classNames, BASE_CLASSNAME + '--uppercase', uppercase), _defineProperty(_classNames, BASE_CLASSNAME + '--black', black), _defineProperty(_classNames, BASE_CLASSNAME + '--bold', bold), _defineProperty(_classNames, BASE_CLASSNAME + '--semi-bold', semiBold), _defineProperty(_classNames, BASE_CLASSNAME + '--regular', regular), _defineProperty(_classNames, BASE_CLASSNAME + '--light', light), _defineProperty(_classNames, BASE_CLASSNAME + '--color-none', colorNone), _defineProperty(_classNames, BASE_CLASSNAME + '--like-button', likeButton), _classNames));

  if (href) {
    return React.createElement(
      'a',
      {
        id: id,
        className: linkClasses,
        href: href,
        target: target ? target : '_blank',
        __source: {
          fileName: _jsxFileName,
          lineNumber: 59
        },
        __self: _this
      },
      text
    );
  }
  return React.createElement(
    RouterLink,
    {
      id: id,
      className: linkClasses,
      to: route,
      onClick: function onClick(e) {
        e.stopPropagation();
      },
      __source: {
        fileName: _jsxFileName,
        lineNumber: 70
      },
      __self: _this
    },
    text
  );
};

Link.defaultProps = {
  spacing: false,
  small: false,
  medium: false,
  large: false,
  gray: false,
  white: false,
  primary: false,
  secondary: false,
  uppercase: false,
  black: false,
  bold: false,
  semiBold: false,
  regular: false,
  light: false,
  children: '',
  route: '/',
  href: undefined,
  colorNone: false,
  likeButton: false
};

Link.propTypes = {
  /** Elements to be rendered in component. */
  children: PropTypes.any.isRequired,
  /** Sets black color. */
  black: PropTypes.bool,
  /** Sets the link with bold. */
  bold: PropTypes.bool,
  /** */
  className: PropTypes.string,
  /** Sets the link with initial color font. */
  colorNone: PropTypes.bool,
  /** Sets gray color. */
  gray: PropTypes.bool,
  /** External Site to redirect when clicked. */
  href: PropTypes.string,
  /** */
  id: PropTypes.string,
  /** Sets a large font-size. */
  large: PropTypes.bool,
  /** Sets the link with light font. */
  light: PropTypes.bool,
  /** */
  likeButton: PropTypes.bool,
  /** Sets a medium font-size. */
  medium: PropTypes.bool,
  /** Sets the primary color. */
  primary: PropTypes.bool,
  /** */
  primarySimple: PropTypes.bool,
  /** Sets the link with semiBold font. */
  regular: PropTypes.bool,
  /** InternalSite to redirect when clicked. */
  route: PropTypes.string,
  /** Sets the secondary color. */
  secondary: PropTypes.bool,
  /** Sets the link with semiBold font. */
  semiBold: PropTypes.bool,
  /** Sets a small font-size. */
  small: PropTypes.bool,
  /** Sets the link spacing. */
  spacing: PropTypes.bool,
  /** */
  target: PropTypes.string,
  /** This uppercase the text. */
  uppercase: PropTypes.bool,
  /** Sets white color. */
  white: PropTypes.bool
};

export default Link;