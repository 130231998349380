import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { ShowFlashNotification } from 'reduxActions/services'
import { Typography, Autocomplete, Chip, Spinner } from 'rc'
import { Search } from 'rc/Icons'
import { Button } from 'components'
import { SearchAgencyBrokers } from 'reduxActions/agency'
import { AssignDevelopmentAdmin } from 'reduxActions/development'

const AssignAdminMC = ({ cb, HideModal }) => {
  const dispatch = useDispatch()
  const [brokerList, setBrokerList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { token } = useSelector(({ authReducer: { token } }) => ({ token }))

  const searchBroker = value => {
    return dispatch(SearchAgencyBrokers(token, value))
  }

  const assignBroker = () => {
    setIsLoading(true)
    const brokersToAdd = brokerList.map(item => { return item.user_id })
    dispatch(AssignDevelopmentAdmin(token, brokersToAdd))
      .then(async res => {
        setTimeout(() => {
          dispatch(
            ShowFlashNotification(
              'success',
              brokerList.length > 1 ? 
                `Se han agregado ${brokerList.length} administradores de esta cuenta` :
                `Se ha agregado a ${brokerList[0].fullname} como administrador de esta cuenta.`
              )
          )
        } , 200)
        HideModal()
        cb()
      })
      .catch(err => {
        dispatch(
          ShowFlashNotification('danger', `Ocurrió un error inesperado.`)
        )
        setIsLoading(false)
      })
  }

  const handleBubbleDelete = (index) => {
    let brokerArr = brokerList
      brokerArr.splice(index, 1)
      return setBrokerList([...brokerArr])
  }
  const handleSelectedBroker = (autocomplete_name, selectedValue) => {
    if (selectedValue) {
      const existsInArray = [...brokerList ].find(broker => { return broker.user_id === selectedValue.user_id} )
      if(existsInArray) dispatch(ShowFlashNotification('danger', 'Ya existe este asesor en la lista.'))
      else setBrokerList([...brokerList, selectedValue])
    }
  }

  return (
    <Container>
      <Typography 
        variant="h2"
        color="palette.primary.main" 
        align="center"
      >
        Agregar administrador
      </Typography>
      <Typography
        variant="subtitle4"
        color="palette.new.text"
        fontWeight={400}
        css={`margin: ${({ theme }) => theme.remCalc('20 0 4')};`}
      >
        Selecciona un asesor para asignarlo como administrador de la cuenta de
        desarrollador. Podrá cargar, publicar y editar desarrollos, así como
        asignar a los editores de los mismos y a otros administradores de esta
        cuenta.
      </Typography>
      <AutocompleteStyled
        placeholder="Buscar asesor"
        fetchFunction={value => searchBroker(value)}
        labelItemKey="fullname"
        subLabelItemKey="email"
        onChange={handleSelectedBroker}
        shouldFetchOnFocus
        InputProps={{
          autoFocus: true,
          startAdornment: <SearchIconStyled />
        }}
        fieldValueOnSelect={(data, isLastSelected) => {
          return isLastSelected ? data : data.name
        }}
        shouldSelectOnBlur={(results, fieldValue) => {
          if (fieldValue) return -1
          return 0
        }}
      />
      <ChipContainerStyled>
        {brokerList.map((item, index ) => (
          <ChipStyled
            key={index}
            label={item && item.fullname}
            onClick={() => handleBubbleDelete(index)}
          />
        ))}
      </ChipContainerStyled>
      <ButtonsContainer>
        <ButtonStyled type="tertiary" onClick={HideModal}>
          Cancelar
        </ButtonStyled>
        <ButtonStyled
          type="primary"
          onClick={assignBroker}
          disabled={brokerList.length === 0 || isLoading}
        >
          Agregar
        </ButtonStyled>
      </ButtonsContainer>
      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </Container>
  )
}
AssignAdminMC.propTypes = {
  developmentId: PropTypes.string,
  HideModal: PropTypes.func,
  getList: PropTypes.func,
  cb: PropTypes.func
}

const Container = styled.div`
  width: ${({ theme }) => theme.remCalc('500')};  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.remCalc('32')};
`
const AutocompleteStyled = styled(Autocomplete)`
`
const SearchIconStyled = styled(Search)`
  margin-left: ${({ theme }) => theme.remCalc('5')};
  color: ${({ theme }) => theme.palette.new.text};
  ${({ theme }) => theme.box(20, 20)}
`
const ChipContainerStyled = styled.div`
  display: block;
  margin: ${({ theme }) => theme.remCalc('32 0 20')};
`
const ChipStyled = styled(Chip)`
  height: ${({ theme }) => theme.remCalc('20')};
  background-color: ${({ theme }) =>  theme.palette.primary.main };
  > * { 
    font-size: ${({ theme }) => theme.remCalc('10')};
    font-weight: 700
  }
`
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const ButtonStyled = styled(Button).attrs({ rounded: true })`
  width: 48%;
`
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 0;
  left: 0;
`

export default AssignAdminMC
