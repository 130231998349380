import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const ContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  flex-wrap: nowrap;
  overflow: hidden;
`

const ContentStyled = styled.div`
  max-width: ${({ theme, noMaxWidth }) =>
    !noMaxWidth ? theme.remCalc('1085') : ''};

  width: ${({ marginLeft, theme }) =>
    marginLeft ? `calc(100% - ${theme.remCalc(marginLeft)})` : '100%'};

  ${({ marginLeft, theme }) =>
    marginLeft && `margin-left:${theme.remCalc(marginLeft)}`};

  ${({ marginRight, theme }) =>
    marginRight && `margin-right:${theme.remCalc(marginRight)}`};

  flex: 1;
  height: 100%;
  overflow: auto;
`

const HeaderStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.gray.light};
  width: 100%;
  ${({ theme }) => theme.media.desktop`
  min-height: ${({ theme }) => theme.remCalc('90')};
  `}

  display: flex;
  justify-content: ${({ center }) => center && 'center'};
  ${({ marginLeft, theme }) =>
    marginLeft && `margin-left:${theme.remCalc(marginLeft)}`};
  ${({ marginRight, theme }) =>
    marginRight && `margin-right:${theme.remCalc(marginRight)}`};
`

const HeaderContentStyled = styled.div`
  width: 100%;
  max-width: ${({ theme, noMaxWidth }) =>
    !noMaxWidth ? theme.remCalc('1085') : ''};
  height: 100%;
`

const PageFloatingHeader = ({
  children,
  headerComponent,
  marginLeft,
  marginRight,
  center,
  className,
  noMaxWidth
}) => {
  return (
    <ContainerStyled className={className}>
      <HeaderStyled
        center={center}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        <HeaderContentStyled noMaxWidth={noMaxWidth}>
          {headerComponent && headerComponent()}
        </HeaderContentStyled>
      </HeaderStyled>
      <ContentStyled
        marginLeft={marginLeft}
        marginRight={marginRight}
        noMaxWidth={noMaxWidth}
      >
        {children}
      </ContentStyled>
    </ContainerStyled>
  )
}

PageFloatingHeader.propTypes = {
  className: PropTypes.string,
  headerComponent: PropTypes.func,
  center: PropTypes.bool,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  children: PropTypes.any,
  hasEmailVerified: PropTypes.bool,
  noMaxWidth: PropTypes.bool
}

PageFloatingHeader.defaultProps = {
  center: true,
  noMaxWidth: false
}

export default PageFloatingHeader
