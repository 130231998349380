'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var styled = require('styled-components');

var fadeIn = function fadeIn() {
  return styled.keyframes(["0%{background-color:transparent;opacity:0;}100%{background-color:red;opacity:1;}"]);
};
var fadeOut = styled.keyframes(["0%{background-color:transparent;opacity:1;}100%{opacity:0;}"]);
var fadeInOut = function fadeInOut(_ref) {
  var isAnimatingOut = _ref.isAnimatingOut;
  return styled.keyframes(["0%{background-color:", ";opacity:", ";}100%{background-color:", ";opacity:", ";}"], isAnimatingOut ? 'black' : 'transparent', isAnimatingOut ? '0.5' : '0.01', !isAnimatingOut ? 'black' : 'transparent', !isAnimatingOut ? '0.5' : '0.01');
};
var bounce = function bounce() {
  return styled.keyframes(["0%{transform:translate(0px,0);}33%{transform:translate(5px,0);}66%{transform:translate(0px,0);}100%{transform:translate(-5px,0);}"]);
};

exports.bounce = bounce;
exports.fadeIn = fadeIn;
exports.fadeInOut = fadeInOut;
exports.fadeOut = fadeOut;
