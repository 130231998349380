var _jsxFileName = 'src/lib/elements/Selects/thirdParty/AsyncSelect.js',
    _this = this;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/lib/Async';
import classNames from 'classnames';

var AsyncSelect = function AsyncSelect(_ref) {
  var cacheOptions = _ref.cacheOptions,
      className = _ref.className,
      touched = _ref.touched,
      error = _ref.error,
      warning = _ref.warning,
      disabled = _ref.disabled,
      getOptionValue = _ref.getOptionValue,
      getOptionLabel = _ref.getOptionLabel,
      loadOptions = _ref.loadOptions,
      props = _objectWithoutProperties(_ref, ['cacheOptions', 'className', 'touched', 'error', 'warning', 'disabled', 'getOptionValue', 'getOptionLabel', 'loadOptions']);

  var inputClasses = classNames(className, 'select-input-container', {
    'select-input-container--error': !disabled && touched && error
  });

  return React.createElement(
    Fragment,
    {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 23
      },
      __self: _this
    },
    React.createElement(Async, Object.assign({
      className: inputClasses,
      getOptionValue: getOptionValue,
      getOptionLabel: getOptionLabel,
      classNamePrefix: 'select-input',
      isDisabled: disabled,
      noOptionsMessage: function noOptionsMessage() {
        return 'Sin opciones';
      },
      openMenuOnClick: false,
      backspaceRemovesValue: false,
      loadOptions: loadOptions
    }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 24
      },
      __self: _this
    })),
    touched && !disabled && (error && React.createElement(
      'span',
      { className: 'input-container__error-message', __source: {
          fileName: _jsxFileName,
          lineNumber: 39
        },
        __self: _this
      },
      error
    ) || warning && React.createElement(
      'span',
      {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 41
        },
        __self: _this
      },
      warning
    ))
  );
};

AsyncSelect.propTypes = {
  /** */
  cacheOptions: PropTypes.string,
  /** */
  className: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** */
  error: PropTypes.string,
  /** */
  getOptionLabel: PropTypes.func,
  /** */
  getOptionValue: PropTypes.func,
  /** */
  loadOptions: PropTypes.func,
  /** */
  touched: PropTypes.bool,
  /** */
  warning: PropTypes.string
};

export default AsyncSelect;