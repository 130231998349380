import React from 'react'
import { string, func, bool } from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { Grid, Typography, Button } from 'rc'
import { Cross, CheckmarkSquare } from 'rc/Icons'

import { WiggotPrimeLogo, WiggotPassLogoBarBlanco } from 'components/Icons'
import BackgroundPrime from 'static/img/background_prime.png'

const PaymentStatus = ({
  className,
  HideModal,
  declined,
  callBackAction,
  cardReference,
  planType,
  isAgency
}) => {
  const fullname = useSelector(({ profileReducer: { fullname } }) => fullname)

  const name = fullname && fullname.split(' ')
  return (
    <GridContainerStyled
      className={className}
      container
      direction="column"
      alignItems="space-between"
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="space-between"
        css={`
          height: ${({ theme }) => theme.remCalc('180')};
          background-image: url(${BackgroundPrime});
          background-size: cover;
          background-repeat: no-repeat;
          ${({ theme }) => theme.media.desktop`
          background-size: contain;
          height: ${({ theme }) => theme.remCalc('260')};
        `}
        `}
      />
      <Grid
        container
        justify="center"
        alignItems="space-around"
        direction="column"
        css={`
          position: absolute;
          width: 100%;
          height: 100%;
        `}
      >
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          css={`
            width: 100%;
            height: 100%;
          `}
        >
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            css={`
              height: ${({ theme }) => theme.remCalc('180')};
              ${({ theme }) => theme.media.desktop`
              height: ${({ theme }) => theme.remCalc('230')};
            `}
            `}
          >
            <Grid
              container
              justify="space-around"
              css={`
                flex-direction: column;
                align-items: center;
                position: relative;
                height: 100%;
                ${({ theme }) => theme.media.desktop`
                flex-direction: row;
                align-items: inherit;
              `}
              `}
            >
              <Grid
                container
                direction="column"
                justify="center"
                css={`
                  height: 100%;
                  margin-top: ${({ theme }) => theme.remCalc('20')};
                  ${({ theme }) => theme.media.desktop`
                  width: 35%;
                  margin-top: ${({ theme }) => theme.remCalc('60')};
                `}
                `}
              >
                {planType === 'Prime' ? (
                  <WiggotPrimeLogo
                    css={`
                      height: ${({ theme }) => theme.remCalc('40')};
                    `}
                  />
                ) : (
                  <WiggotPassLogoBarBlanco
                    css={`
                      height: ${({ theme }) => theme.remCalc('40')};
                    `}
                  />
                )}
                <Grid
                  container
                  center
                  declined={declined}
                  css={`
                    background: ${({ declined }) =>
                      declined ? '#fd6c6c' : '#6FCF97'};
                    ${({ theme }) => theme.box(85, 85, true)}
                    border: 5px solid #fff;
                    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
                    margin-top: ${({ theme }) => theme.remCalc('30')};
                  `}
                >
                  {declined && <CrossStyled />}
                  {!declined && <CheckmarkSquareStyled />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="space-around"
            direction="column"
            css={`
              height: ${({ theme }) => theme.remCalc('300')};
              ${({ theme }) => theme.media.desktop`
              height: ${({ theme }) => theme.remCalc('230')};
            `}
            `}
          >
            <Grid
              container
              center
              css={`
                width: 85%;
              `}
            >
              <Typography
                variant="subtitle1"
                color="palette.primary.main"
                align="center"
                css={`
                  margin-bottom: ${({ theme }) => theme.remCalc('10')};
                `}
              >
                {declined ? 'Pago rechazado' : 'Pago registrado correctamente'}
              </Typography>
              <Typography
                variant="body1"
                css={`
                  text-align: center;
                  margin-bottom: ${({ theme }) => theme.remCalc('10')};
                `}
              >
                {declined
                  ? `Hola ${
                      name[0] || ''
                    }, no se ha podido procesar el pago porque se ha rechazado la tarjeta con terminación **** **** ${
                      cardReference || ''
                    }`
                  : `Hola ${
                      name[0] || ''
                    }, tu pago ha sido registrado correctamente, sigue disfrutando de los beneficios de Wiggot ${planType}.`}
              </Typography>
              {isAgency && (
                <Typography
                  variant="body1"
                  css={`
                    text-align: center;
                    margin-bottom: ${({ theme }) => theme.remCalc('10')};
                  `}
                >
                  Por último ve a la sección de tu cuenta para asignar tus
                  suscripciones de prime a los asesores que tú quieras.
                </Typography>
              )}
              {!isAgency && (
                <Typography
                  variant="body1"
                  css={`
                    text-align: center;
                  `}
                >
                  Si tienes dudas puedes comunicarte por medio del chat a la
                  línea de atención a cliente o escríbenos a{' '}
                  <b>hola@wiggot.com</b>
                </Typography>
              )}
              <Grid
                container
                justify={isAgency ? 'space-between' : 'center'}
                css={`
                  margin-top: ${({ theme }) => theme.remCalc('16')};
                `}
              >
                {isAgency && (
                  <Button
                    variant="outlined"
                    color="gray"
                    onClick={() => {
                      HideModal()
                    }}
                    css={`
                      height: ${({ theme }) => theme.remCalc('50')};
                      width: ${({ theme }) => theme.remCalc('250')};
                    `}
                    id="account-primeSection-button-close"
                  >
                    En otro momento
                  </Button>
                )}
                <Button
                  onClick={
                    isAgency
                      ? () => {
                          callBackAction()
                        }
                      : () => {
                          HideModal()
                          declined && callBackAction()
                        }
                  }
                  css={`
                    height: ${({ theme }) => theme.remCalc('50')};
                    width: ${({ theme }) => theme.remCalc('250')};
                  `}
                  id="account-primeSection-button-PaymentStatusModal"
                >
                  {declined
                    ? 'Corregir método de pago'
                    : isAgency
                    ? 'Asignar Prime a usuarios'
                    : 'Aceptar'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GridContainerStyled>
  )
}

PaymentStatus.propTypes = {
  className: string,
  declined: bool,
  fullname: string,
  cardReference: string,
  callBackAction: func,
  HideModal: func,
  ShowModal: func,
  planType: string,
  isAgency: bool
}

const GridContainerStyled = styled(Grid)`
  width: ${({ theme }) => theme.remCalc('600')};
  max-width: 100%;
  position: relative;
  height: ${({ theme }) => theme.remCalc('500')};
  ${({ theme }) => theme.media.desktop`
    height: ${({ theme }) => theme.remCalc('470')};
  `}
`

const CrossStyled = styled(Cross)`
  ${({ theme }) => theme.box(50, 50)}
  fill: #fff;
`

const CheckmarkSquareStyled = styled(CheckmarkSquare)`
  ${({ theme }) => theme.box(50, 50)}
  fill: #fff;
`

export default PaymentStatus
