var _jsxFileName = 'src/lib/elements/Buttons/CloseButton.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '../../generics/Buttons';

/** Creates a button with a cross. */
var CloseButton = function CloseButton(_ref) {
  var onClick = _ref.onClick,
      className = _ref.className,
      id = _ref.id,
      smallSize = _ref.smallSize,
      mediumSize = _ref.mediumSize,
      largeSize = _ref.largeSize;

  return React.createElement(IconButton, {
    onClick: onClick,
    className: className,
    id: id,
    smallSize: smallSize,
    mediumSize: !smallSize && !largeSize || mediumSize,
    largeSize: largeSize,
    dripiconIcon: 'cross',
    circle: true,
    stillGray: true,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 16
    },
    __self: _this
  });
};

CloseButton.defaultProps = {};

CloseButton.propTypes = {
  /** ClassName to add custom styles. */
  className: PropTypes.string,
  /** Used to identify button. */
  id: PropTypes.string,
  /** Button with large size. */
  largeSize: PropTypes.bool,
  /** Button with medium size. */
  mediumSize: PropTypes.bool,
  /** Handler of click action on button. */
  onClick: PropTypes.func,
  /** Button with small size. */
  smallSize: PropTypes.bool
};
export default CloseButton;