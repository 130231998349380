import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Typography, Button } from 'rc'
import { UnlockModal, HideModal, ShowModal } from 'reduxActions/services'
import MissingInfoForPassMC from './MissingInfoForPassMC'
import { ICONS } from 'utils/icons'
const { profileSearchIcon } = ICONS

const InitMissingInfoForPassMC = ({
  userInfo,
  ShowModal,
  UnlockModal,
  HideModal
}) => {
  const nextStep = () => {
    UnlockModal()
    HideModal()

    ShowModal(
      MissingInfoForPassMC,
      { userInfo },
      { isLocked: true }
    )
  }

  return (
    <div
      css={`
        max-width: 100%;
        width: ${({ theme }) => theme.remCalc('400')};
        padding: 20px;
      `}
    >
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
        display="block"
        align="center"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        Faltan datos importantes en tu perfil
      </Typography>
      <ContainerSvg>
        <img src={profileSearchIcon} alt="profile-search-icon" />
      </ContainerSvg>
      <Typography
        textTransform="initial"
        display="block"
        align="center"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('10')};
          color: #605D75;
        `}
      >
        Por favor, ayúdanos a completar los campos a continuación.
      </Typography>
      <Containerbutton>
        <Button
          onClick={nextStep}
          css={`
            border-radius: ${({ theme }) => theme.remCalc('50')} !important;
            width: ${({ theme }) => theme.remCalc('200')};
          `}
        >
          Completar datos
        </Button>
      </Containerbutton>
    </div>
  )
}

const ContainerSvg = styled.div`
  display: flex;
  justify-content: center;
`
const Containerbutton = styled.div`
  display: flex;
  justify-content: center;
`

InitMissingInfoForPassMC.propTypes = {
  userInfo: PropTypes.object,
  UnlockModal: PropTypes.func.isRequired,
  HideModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  UnlockModal,
  HideModal,
  ShowModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InitMissingInfoForPassMC)
