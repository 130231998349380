import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  ContentContainer,
  WiggotPrimeLogo
} from './StyledComponents'
import AgendaPrime from 'static/drawings/agenda-prime.svg'

const Agenda = props => {
  return (
    <Container className={props.className}>
      <WiggotPrimeLogo />
      <ContentContainer>
        <h3>Agenda tareas para tus clientes</h3>
        <img src={AgendaPrime} alt="Agenda tareas para tus clientes" />
      </ContentContainer>
      <p>
        Agrega tareas y pendientes a tu agenda y deja que wiggot sea tu
        asistente y te recuerde.
      </p>
    </Container>
  )
}

Agenda.propTypes = {
  className: PropTypes.string
}

export default Agenda
