import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Typography } from 'rc'
import { Warning } from 'rc/Icons'
import { Button } from 'components'

const EMPTY_FIELDS = {
  cover_image: 'Imágenes',
  currency: 'Moneda',
  location: 'Ubicación',
  contact_phone: 'Teléfono de contacto',
  contact_email: 'Email',
  development_description: 'Descripción',
  cash_payment: 'Tipo de pago',
  credit_payment: 'Tipo de pago',
  credit_mortgage: 'Tipo de pago',
  neighborhood: 'Colonia',
  city: 'Ciudad',
  state: 'Estado',
  development_name: 'Nombre del desarrollo',
  development_currency: 'Moneda',
  shared_commission: 'Comisión compartida',
  images: 'Imágenes',
  sections: 'Torres/Condominios',
  logo: 'Logo',
  payment_type: 'Forma de pago',
  type: 'Tipo de desarrollo',
  street: 'Calle',
  postal_code: 'Código postal',
  external_number: 'Número exterior',
  model_name: 'Nombre del modelo',
  units_available: 'Unidades totales',
  model_description: 'Descripción del modelo',
  max_construction_height: 'Altura máx. de construcción',
  max_ground_size: 'm2 Superficie',
  max_terrace_size: 'm2 Terraza',
  max_construction_size: 'm2 Construcción',
  max_garden_size: 'm2 Jardín',
  max_fronthead_size: 'm Frente',
  max_background_size: 'm Fondo',
  floors: 'Niveles',
  max_presale_price: 'Precio preventa',
  min_presale_price: 'Precio preventa',
  max_sale_price: 'Precio venta',
  min_sale_price: 'Precio venta',
  max_lease_price: 'Precio renta',
  min_lease_price: 'Precio renta',
  property_type: 'Tipo de propiedad',
  bedrooms: 'Habitaciones',
  bathrooms: 'Baños',
  half_bathrooms: 'Medios baños',
  parking_lots: 'Estacionamiento',
  presale_commission: 'Precio preventa',
  sale_commission: 'Precio venta',
  lease_commission: 'Precio renta'
}

const EmptyFieldsMC = props => {
  const history = useHistory()
  const { HideModal, error = [], fields_with_errors, isModel } = props
  const hasIncompleteModels =
    !fields_with_errors && error[0] && error[0].model_development_id

  const modelsWithErrors =
    hasIncompleteModels && error.filter(e => e.empty_values)

  return (
    <Container>
      <Warning
        fill="#FFBC08"
        css={`
          ${({ theme }) => theme.box(50, 50)}
          margin-bottom: ${({ theme }) => theme.remCalc('8')};
        `}
      />
      <Typography
        variant="h3"
        color="palette.primary.main"
        align="center"
        css={`
          width: 90%;
          font-size: ${({ theme }) => theme.remCalc('25')};
          margin-bottom: ${({ theme }) => theme.remCalc('16')};
        `}
      >
        Existen campos requeridos sin completar
      </Typography>
      <Typography variant="body1" color="palette.new.text" align="center">
        {`No podemos publicar el ${
          isModel ? 'modelo' : 'desarrollo'
        } hasta que completes todos los campos
        requeridos:`}
      </Typography>
      <Typography
        variant="subtitle3"
        color="palette.new.text"
        hasIncompleteModels={hasIncompleteModels}
        css={`
          margin: ${({ theme }) => theme.remCalc('24 0')};
          width: ${({ hasIncompleteModels }) =>
            hasIncompleteModels ? '90%' : 'auto'};
        `}
      >
        {hasIncompleteModels
          ? modelsWithErrors &&
            modelsWithErrors.map((model, i) => {
              return (
                <div key={i}>
                  <ModelLink
                    onClick={() =>
                      history.push(
                        `${history.location.pathname}/uploadModel?modelId=${model.model_development_id}`
                      )
                    }
                  >
                    Completar modelo {model.model_development_id}
                  </ModelLink>
                  <span
                    css={`
                      font-weight: 400;
                      margin: 5px 0 10px;
                    `}
                  >
                    <b>Campos faltantes: </b>
                    {model.empty_values
                      .map(field => EMPTY_FIELDS[field])
                      .join(' ó ')}
                  </span>
                </div>
              )
            })
          : fields_with_errors
          ? fields_with_errors.map(field => EMPTY_FIELDS[field]).join(', ') +
            '.'
          : typeof error !== 'string' &&
            error.map(field => EMPTY_FIELDS[field]).join(', ') + '.'}
      </Typography>
      <Button
        type="secondary"
        rounded
        onClick={HideModal}
        css={`
          width: ${({ theme }) => theme.remCalc('180')};
        `}
      >
        Aceptar
      </Button>
    </Container>
  )
}

EmptyFieldsMC.propTypes = {
  HideModal: PropTypes.func,
  error: PropTypes.array,
  fields_with_errors: PropTypes.array
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  border-radius: ${({ theme }) => theme.remCalc('10')};
  padding: ${({ theme }) => theme.remCalc('20 30')};
  width: ${({ theme }) => theme.remCalc('450')};
`
const ModelLink = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`

export default EmptyFieldsMC
