var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _jsxFileName = 'src/lib/elements/Selects/SelectMenuOption.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var SelectMenuOption = (_temp = _class = function (_PureComponent) {
  _inherits(SelectMenuOption, _PureComponent);

  function SelectMenuOption() {
    _classCallCheck(this, SelectMenuOption);

    return _possibleConstructorReturn(this, (SelectMenuOption.__proto__ || Object.getPrototypeOf(SelectMenuOption)).apply(this, arguments));
  }

  _createClass(SelectMenuOption, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          id = _props.id,
          text = _props.children,
          _props$selected = _props.selected,
          selected = _props$selected === undefined ? false : _props$selected;

      var containerClasses = classNames('select-wrapper__select-option', {
        selected: selected
      });
      return React.createElement(
        'div',
        {
          id: id,
          className: containerClasses,
          onClick: function onClick() {
            return _this2.props.onClick(_this2.props.option);
          },
          __source: {
            fileName: _jsxFileName,
            lineNumber: 26
          },
          __self: this
        },
        text
      );
    }
  }]);

  return SelectMenuOption;
}(PureComponent), _class.propTypes = {
  /** Things to render inside option. */
  children: PropTypes.string.isRequired,

  /** Option clicked callback. */
  onClick: PropTypes.func.isRequired,

  /** Option */
  option: PropTypes.any.isRequired,
  id: PropTypes.string,
  /** Sets selected class if it is a selected option. */
  selected: PropTypes.bool
}, _temp);


export default SelectMenuOption;