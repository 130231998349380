var _jsxFileName = 'src/lib/elements/Texts/Text.js',
    _this = this;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
var Text = function Text(_ref) {
  var className = _ref.className,
      center = _ref.center,
      children = _ref.children,
      uppercase = _ref.uppercase,
      thin = _ref.thin,
      bold = _ref.bold,
      smallSize = _ref.smallSize,
      mediumSize = _ref.mediumSize,
      largeSize = _ref.largeSize,
      gray = _ref.gray,
      primary = _ref.primary,
      extraSmallSize = _ref.extraSmallSize,
      props = _objectWithoutProperties(_ref, ['className', 'center', 'children', 'uppercase', 'thin', 'bold', 'smallSize', 'mediumSize', 'largeSize', 'gray', 'primary', 'extraSmallSize']);

  var textClasses = classNames('text', className, {
    'text--centered': center,
    'text--uppercase': uppercase,
    'text--thin': thin,
    'text--bold': bold,
    'text--extra-small': extraSmallSize,
    'text--small': smallSize,
    'text--medium': mediumSize,
    'text--large': largeSize,
    'text--gray': gray,
    'text--primary': primary
  });
  return React.createElement(
    'div',
    Object.assign({ className: textClasses }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 32
      },
      __self: _this
    }),
    children
  );
};

Text.propTypes = {
  /** */
  bold: PropTypes.bool,
  /** */
  center: PropTypes.bool,
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  extraSmallSize: PropTypes.bool,
  /** */
  gray: PropTypes.bool,
  /** */
  largeSize: PropTypes.bool,
  /** */
  mediumSize: PropTypes.bool,
  /** */
  primary: PropTypes.bool,
  /** */
  smallSize: PropTypes.bool,
  /** */
  thin: PropTypes.bool,
  /** */
  uppercase: PropTypes.bool
};

export default Text;