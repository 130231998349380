import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M19.96 16.9145C19.96 18.467 18.6885 19.7237 17.1361 19.7237C15.5836 19.7237 14.3269 18.4522 14.3269 16.8998C14.3269 15.3473 15.5984 14.0906 17.1509 14.0906C18.7033 14.0906 19.96 15.3621 19.96 16.9145Z"
      fill="#FFCD48"
    />
    <path
      d="M74.4434 2.54305C74.4434 1.13846 75.6262 0 77.0308 0C78.4353 0 79.6182 1.15324 79.6034 2.55783C79.6034 3.99199 78.4206 5.14524 77.016 5.13045C75.6262 5.13045 74.4434 3.97721 74.4434 2.54305Z"
      fill="#FFCD48"
    />
    <path
      d="M72.3146 8.44214L65.883 26.894L61.1666 26.8792L57.4555 14.992L53.7001 26.8644H48.9836L42.626 8.383L47.3572 8.39778L51.3492 20.0337L55.4004 8.41257L59.5402 8.42735L63.5322 20.0633L67.5833 8.44214H72.3146Z"
      fill="#363946"
    />
    <path
      d="M74.651 8.45415L74.614 26.906L79.3453 26.9155L79.3823 8.46364L74.651 8.45415Z"
      fill="#363946"
    />
    <path
      d="M102.091 8.5008L102.062 25.489C101.944 31.1073 97.168 34.774 92.5255 34.774C86.0939 34.7592 83.5213 30.1758 83.2256 29.3627L86.9515 27.411C87.2028 28.1799 89.3467 30.5159 92.5255 30.5159C95.5564 30.5159 97.1384 27.7511 97.3307 25.7255C95.8521 26.6866 94.0779 27.2336 92.1558 27.2336C86.9071 27.2188 82.6342 22.9755 82.649 17.6972C82.6637 12.4484 86.9515 8.17553 92.2002 8.19032C94.0779 8.19032 95.8521 8.75215 97.3307 9.71319V8.5008H102.091ZM97.168 17.7563C97.168 14.7993 95.1424 12.6998 92.3776 12.685C89.5684 12.685 87.5724 14.7845 87.5724 17.7267C87.5724 20.7133 89.5537 22.8276 92.3628 22.8276C95.1277 22.8572 97.1532 20.7577 97.168 17.7563Z"
      fill="#363946"
    />
    <path
      d="M124.625 8.54569L124.595 25.5338C124.477 31.1522 119.701 34.8189 115.059 34.8189C108.627 34.8041 106.054 30.2207 105.759 29.4076L109.485 27.4559C109.736 28.2247 111.88 30.5608 115.059 30.5608C118.09 30.5608 119.672 27.796 119.864 25.7704C118.385 26.7314 116.611 27.2785 114.689 27.2785C109.44 27.2637 105.167 23.0204 105.182 17.7421C105.197 12.4933 109.485 8.22042 114.719 8.23521C116.596 8.23521 118.371 8.79704 119.849 9.75808V8.54569H124.625ZM119.701 17.8012C119.701 14.8442 117.676 12.7447 114.911 12.7299C112.102 12.7299 110.106 14.8294 110.106 17.7716C110.106 20.7582 112.087 22.8725 114.896 22.8725C117.661 22.9021 119.701 20.8026 119.701 17.8012Z"
      fill="#363946"
    />
    <path
      d="M127.507 17.787C127.522 12.5383 131.809 8.2654 137.058 8.28019C142.336 8.29497 146.58 12.5827 146.565 17.8166C146.55 23.0653 142.307 27.3383 137.029 27.3235C131.765 27.3235 127.492 23.021 127.507 17.787ZM142.011 17.8166C142.011 14.8596 139.986 12.7157 137.221 12.7157C134.412 12.7157 132.416 14.8448 132.416 17.8018C132.416 20.7589 134.397 22.9027 137.206 22.9027C139.971 22.9027 142.011 20.7737 142.011 17.8166Z"
      fill="#363946"
    />
    <path
      d="M161.291 25.164C161.291 25.164 159.295 27.367 156.086 27.367C152.907 27.367 150.483 25.8146 150.483 21.9704L150.497 12.848H148.25L148.265 8.60463H150.512L150.527 2.43922L155.258 2.33572L155.243 8.61942L159.605 8.6342L159.59 12.8775L155.229 12.8628L155.214 20.5363C155.214 22.0591 156.101 22.9758 157.121 22.9758C158.378 22.9758 159.265 21.9852 159.265 21.3938L161.291 25.164Z"
      fill="#363946"
    />
    <path
      d="M33.651 17.7716C33.6067 17.7124 33.5771 17.6385 33.518 17.5794L18.7032 2.6907C18.6589 2.64634 18.6441 2.5872 18.5997 2.54284C18.1857 2.12886 17.6535 1.93665 17.1064 1.93665C16.5594 1.92187 16.0271 2.12886 15.6131 2.54284C15.5688 2.5872 15.554 2.64634 15.5096 2.6907L0.620977 17.5054C0.192207 17.9342 0 18.4812 0.0295703 19.0431V19.0579L0 27.8846C0 28.9048 0.827969 29.7475 1.86293 29.7475H2.38041H2.60219L31.6698 29.8067H32.1873H32.4091C33.4292 29.8067 34.272 28.9787 34.272 27.9437L34.2868 19.1318C34.272 18.5995 34.0354 18.1116 33.651 17.7716ZM4.4799 19.5458L17.0916 6.97839L29.8069 19.7528L29.7921 25.652L4.45033 25.6077L4.4799 19.5458Z"
      fill="#0076FF"
    />
    <path
      d="M135.74 35.2176C137.013 35.6802 137.757 36.6076 137.757 37.8918C137.757 39.7777 136.159 40.8731 133.162 40.8731H127.046V30.0558H132.852C135.846 30.0558 137.244 31.2446 137.244 32.8836C137.244 33.8877 136.732 34.7217 135.74 35.2176ZM132.356 32.6056H130.649V34.2134H132.356C133.179 34.2134 133.55 33.9354 133.55 33.4105C133.55 32.8856 133.177 32.6076 132.356 32.6076V32.6056ZM132.852 38.3234C133.658 38.3234 134.062 38.0288 134.062 37.4727C134.062 36.9167 133.658 36.6221 132.852 36.6221H130.647V38.3213H132.852V38.3234Z"
      fill="#40CBA6"
    />
    <path
      d="M144.437 41.12C140.899 41.12 138.307 38.7404 138.307 35.4645C138.307 32.1885 140.899 29.8089 144.437 29.8089C147.976 29.8089 150.568 32.1885 150.568 35.4645C150.568 38.7404 147.976 41.12 144.437 41.12ZM144.437 38.1678C145.788 38.1678 146.875 37.147 146.875 35.4645C146.875 33.7819 145.79 32.7612 144.437 32.7612C143.085 32.7612 142.001 33.7819 142.001 35.4645C142.001 37.147 143.087 38.1678 144.437 38.1678Z"
      fill="#40CBA6"
    />
    <path
      d="M176.53 40.8731V32.8836H173.348V30.0558H183.373V32.8836H180.191V40.8731H176.528H176.53Z"
      fill="#40CBA6"
    />
    <path
      d="M157.684 36.0018C157.611 36.0018 157.538 35.9956 157.467 35.9748C157.165 35.896 156.934 35.6076 156.919 35.2985C156.898 34.8358 157.298 34.6159 157.667 34.4499C158.29 34.1719 158.911 33.896 159.536 33.6221C160.676 33.1263 161.818 32.6346 162.958 32.1367C163.018 32.1097 163.083 32.0806 163.118 32.0267C163.156 31.9707 163.158 31.896 163.152 31.8275C163.123 31.4002 162.952 30.9271 163.191 30.5703C163.325 30.3711 163.558 30.2632 163.779 30.1678C164.252 29.9603 164.727 29.7529 165.2 29.5454C165.448 29.4375 165.723 29.3276 165.98 29.4147C166.217 29.4956 166.373 29.7321 166.425 29.9769C166.478 30.2217 166.444 30.4749 166.409 30.7238C166.384 30.9126 166.357 31.1014 166.332 31.2923C166.325 31.34 166.319 31.3877 166.336 31.4333C166.359 31.4935 166.417 31.5288 166.473 31.562C166.623 31.6491 166.773 31.7363 166.921 31.8255C167.109 31.9375 167.294 32.0578 167.436 32.2238C167.578 32.3898 167.667 32.6118 167.628 32.8255C167.563 33.1844 167.184 33.3856 166.848 33.535C166.403 33.7342 165.955 33.9313 165.509 34.1304C165.304 34.2217 165.09 34.313 164.867 34.3005C164.446 34.2757 164.15 33.896 163.898 33.5599C163.871 33.5225 163.839 33.4852 163.796 33.4707C163.746 33.4541 163.689 33.4769 163.642 33.4976C161.902 34.2632 160.162 35.0267 158.422 35.7922C158.186 35.896 157.934 35.9997 157.684 36.0039V36.0018Z"
      fill="#0076FF"
    />
    <path
      d="M161.545 35.1242C161.547 35.1844 161.554 35.2425 161.554 35.3006C161.554 37.7632 159.547 39.7611 157.074 39.7611C154.6 39.7611 152.593 37.7632 152.593 35.3006C152.593 32.8379 154.6 30.84 157.074 30.84C158.472 30.84 159.722 31.479 160.543 32.479L161.762 31.9271C160.676 30.6346 158.98 29.8027 157.074 29.8027C153.794 29.8027 151.135 32.2632 151.135 35.3006C151.135 38.3379 153.794 40.7984 157.074 40.7984C160.353 40.7984 163.012 38.3379 163.012 35.3006C163.012 35.0246 162.989 34.7528 162.948 34.4894L161.545 35.1242Z"
      fill="#40CBA6"
    />
    <path
      d="M158.391 36.5516C157.703 36.8628 156.894 36.5599 156.582 35.8752L156.54 35.784C156.228 35.1014 156.532 34.2943 157.219 33.9831L159.028 33.1657C158.501 32.7508 157.82 32.4998 157.076 32.4998C155.407 32.4998 154.054 33.7529 154.054 35.3006C154.054 36.8483 155.407 38.1014 157.076 38.1014C158.559 38.1014 159.791 37.1097 160.047 35.8026L158.393 36.5516H158.391Z"
      fill="#40CBA6"
    />
    <path
      d="M166.867 31.1512C168.118 31.9811 168.118 32.6035 168.118 32.6035C168.124 32.6284 168.126 32.6533 168.13 32.6782C168.336 32.6222 168.603 32.591 168.947 32.591C169.893 32.591 170.902 32.8545 171.973 33.3939L173.075 30.7508C171.941 30.118 170.437 29.8089 168.978 29.8089C168.259 29.8089 167.622 29.8877 167.057 30.0247C167.069 30.0786 166.865 31.1512 166.865 31.1512H166.867Z"
      fill="#40CBA6"
    />
    <path
      d="M167.774 33.647C167.626 33.7819 167.49 33.8504 167.49 33.8504L165.715 34.6802C165.715 34.6802 164.99 35.0952 164.219 34.6802C164.188 34.6636 164.163 34.6491 164.133 34.6346C165.198 37.2736 169.924 36.5474 169.924 37.7549C169.924 38.1263 169.551 38.342 168.511 38.342C167.315 38.342 165.95 37.9561 164.925 37.3524L163.746 40.0101C164.832 40.674 166.663 41.1221 168.48 41.1221C171.848 41.1221 173.54 39.454 173.54 37.4748C173.54 33.9645 168.887 34.3835 167.772 33.647H167.774Z"
      fill="#40CBA6"
    />
  </Fragment>,
  'WiggotBoost',
  '0 0 184 42'
)
