import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'rc/Spinner'
import { Grid } from 'rc'
import { connect } from 'react-redux'
import { RemovePDF } from 'reduxActions/pdf'
import { isMobile } from 'rc/helpers/miscellaneous'

export const openPDF = pdfId => {
  const pdfURL = `${process.env.REACT_APP_PDF_BASE_URL}/${pdfId}.pdf`
  if (!isMobile()) {
    const popUp = window.open(pdfURL, '_blank')
    if (popUp) {
      popUp.focus()
    }
  } else {
    window.location = pdfURL
  }
}

export const openPDFFromBlob = (data, fileName) => {
  const blob = new Blob([data], { type: 'application/pdf' });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}.pdf`;
  link.click();
}

class PDFListener extends Component {
  static propTypes = {
    RemovePDF: PropTypes.func,
    pusher: PropTypes.object,
    userId: PropTypes.number,
    token: PropTypes.string,
    loading: PropTypes.bool
  }

  state = {
    isShown: false
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading && !this.state.isShown) {
      this.setState({
        isShown: true
      })
    }
    if (prevProps.loading && !this.props.loading) {
      this.setState({
        isShown: false
      })
    }
  }

  render() {
    const { isShown } = this.state
    return isShown ? (
      <Grid
        container
        inline
        alignItems="center"
        justify="center"
        css={`
          position: fixed;
          bottom: 10px;
          right: 10px;
          background: white;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
          border-radius: 10px;
          padding: 5px 10px;
        `}
      >
        <Spinner
          css={`
            ${({ theme }) => theme.box(40, 40)}
          `}
        ></Spinner>
        Generando PDF ...
      </Grid>
    ) : (
      <div />
    )
  }
}

const mapStateToProps = ({
  authReducer: { token },
  servicesReducer: { pusher },
  profileReducer: { userId },
  filesReducer: { loading }
}) => ({
  token,
  pusher,
  userId,
  loading
})

const mapDispatchToProps = {
  RemovePDF
}

export default connect(mapStateToProps, mapDispatchToProps)(PDFListener)
