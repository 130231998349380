import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  ContentContainer,
  WiggotPrimeLogo
} from './StyledComponents'

import SuggestionsPrime from 'static/drawings/suggestions-prime.svg'

const Suggestions = props => {
  return (
    <Container className={props.className}>
      <WiggotPrimeLogo />
      <ContentContainer>
        <h3>Activa matches en tus requerimientos</h3>
        <img
          src={SuggestionsPrime}
          alt="Activa matches en tus requerimientos"
        />
      </ContentContainer>
      <p>
        Recibe diario las mejores opciones de propiedades de la bolsa compartida
        y de otros portales para los requerimientos de tus clientes.
      </p>
    </Container>
  )
}

Suggestions.propTypes = {
  className: PropTypes.string
}

export default Suggestions
