
import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <svg width="170" height="42" viewBox="0 0 170 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8697 17.334C19.8697 18.8779 18.6111 20.1365 17.0672 20.1197C15.5233 20.1197 14.2646 18.8611 14.2814 17.3172C14.2814 15.7733 15.54 14.5146 17.084 14.5314C18.6279 14.5482 19.8697 15.79 19.8697 17.334Z" fill="#FFCD48"/>
    <path d="M74.0742 3.03404C74.0742 1.64116 75.2489 0.5 76.6418 0.5C78.0347 0.5 79.2094 1.64116 79.2094 3.03404C79.2094 4.46048 78.0347 5.60164 76.625 5.60164C75.2489 5.61842 74.0742 4.47726 74.0742 3.03404Z" fill="#FFCD48"/>
    <path d="M71.942 8.90581L65.5482 27.265L60.8493 27.2482L57.1741 15.4171L53.4318 27.2482L48.733 27.2314L42.4062 8.85547H47.1219L51.0992 20.4348L55.1268 8.87225L59.2383 8.88903L63.2156 20.4684L67.2432 8.90581H71.942Z" fill="#363946"/>
    <path d="M74.2829 8.92597L74.2461 27.2852L78.945 27.2946L78.9818 8.9354L74.2829 8.92597Z" fill="#363946"/>
    <path d="M101.581 8.97626L101.547 25.8754C101.43 31.4637 96.6807 35.1221 92.0489 35.1054C85.6551 35.0886 83.0875 30.5407 82.8022 29.7184L86.511 27.7718C86.7627 28.5437 88.894 30.8596 92.0489 30.8764C95.0528 30.8764 96.6471 28.1242 96.8317 26.1104C95.3549 27.0669 93.5928 27.6039 91.6797 27.6039C86.4606 27.5872 82.2148 23.3582 82.2148 18.1055C82.2316 12.8864 86.4942 8.64062 91.7133 8.64062C93.5928 8.64062 95.3549 9.19442 96.8149 10.151V8.9427L101.581 8.97626ZM96.6807 18.1894C96.6807 15.2526 94.6669 13.1549 91.9147 13.1549C89.1289 13.1549 87.1319 15.2358 87.1319 18.1726C87.1319 21.143 89.1121 23.2407 91.8979 23.2575C94.6501 23.2575 96.6807 21.1598 96.6807 18.1894Z" fill="#363946"/>
    <path d="M123.999 9.02704L123.965 25.9262C123.848 31.5145 119.099 35.1729 114.467 35.1561C108.073 35.1394 105.505 30.5915 105.22 29.7692L108.929 27.8225C109.181 28.5945 111.312 30.9104 114.467 30.9272C117.471 30.9272 119.065 28.175 119.25 26.1612C117.773 27.1177 116.011 27.6547 114.098 27.6547C108.879 27.6379 104.633 23.409 104.633 18.1563C104.65 12.9372 108.912 8.69141 114.131 8.69141C116.011 8.69141 117.773 9.2452 119.233 10.2018V8.99348L123.999 9.02704ZM119.099 18.2234C119.099 15.2866 117.085 13.1889 114.333 13.1889C111.547 13.1889 109.55 15.2698 109.55 18.2066C109.55 21.177 111.53 23.2747 114.316 23.2915C117.068 23.2915 119.099 21.2106 119.099 18.2234Z" fill="#363946"/>
    <path d="M126.871 18.2032C126.888 12.9841 131.15 8.73828 136.37 8.73828C141.622 8.75506 145.834 13.0176 145.834 18.2367C145.818 23.4558 141.589 27.7016 136.336 27.7016C131.117 27.6848 126.854 23.4223 126.871 18.2032ZM141.303 18.2367C141.303 15.2999 139.29 13.1687 136.537 13.1519C133.752 13.1519 131.755 15.2664 131.755 18.2199C131.755 21.1567 133.735 23.288 136.521 23.3048C139.273 23.3048 141.303 21.1735 141.303 18.2367Z" fill="#363946"/>
    <path d="M160.485 25.5545C160.485 25.5545 158.505 27.7529 155.3 27.7529C152.145 27.7529 149.711 26.2089 149.728 22.3827L149.745 13.3038H147.496L147.513 9.07482H149.762L149.778 2.93272L154.477 2.83203L154.46 9.07482L158.79 9.09161L158.773 13.3206L154.444 13.3038L154.427 20.9395C154.427 22.4498 155.3 23.3728 156.323 23.3728C157.565 23.3728 158.455 22.3827 158.455 21.7953L160.485 25.5545Z" fill="#363946"/>
    <path d="M33.4795 18.1877C33.4291 18.1206 33.4124 18.0535 33.362 18.0031L18.6109 3.18486C18.5773 3.13452 18.5606 3.08417 18.5102 3.03383C18.1075 2.63107 17.5537 2.42969 17.0166 2.42969C16.4796 2.42969 15.9426 2.61429 15.5231 3.03383C15.4727 3.08417 15.4559 3.13452 15.4224 3.18486L0.620923 17.936C0.20138 18.3555 0.0167817 18.9093 0.0335634 19.4631V19.4799L0 28.2567C0 29.2804 0.822303 30.1027 1.84599 30.1195H2.34944H2.5676L31.4825 30.1698H32.0027H32.2209C33.2445 30.1698 34.0669 29.3475 34.0836 28.3239L34.1004 19.547C34.1004 19.01 33.8655 18.5233 33.4795 18.1877ZM4.44715 19.9498L16.9999 7.44742L29.6533 20.1512L29.6365 26.008L4.43037 25.9576L4.44715 19.9498Z" fill="#0076FF"/>
    <path d="M167.917 23.1052C168.302 23.3233 168.588 23.6254 168.806 23.9946C169.024 24.3806 169.125 24.8001 169.125 25.2532C169.125 25.7063 169.007 26.1427 168.789 26.5286C168.571 26.9146 168.269 27.2167 167.883 27.4348C167.497 27.653 167.077 27.7705 166.624 27.7705C166.154 27.7705 165.735 27.653 165.366 27.4348C164.98 27.2167 164.694 26.9146 164.476 26.5286C164.258 26.1427 164.141 25.7231 164.141 25.27C164.141 24.8169 164.258 24.3974 164.476 24.0114C164.694 23.6254 164.996 23.3233 165.382 23.1052C165.768 22.887 166.188 22.7695 166.658 22.7695C167.111 22.7695 167.531 22.887 167.917 23.1052ZM167.665 27.0321C167.967 26.8475 168.219 26.5958 168.386 26.2937C168.571 25.9748 168.655 25.6392 168.655 25.2532C168.655 24.8672 168.571 24.5316 168.403 24.2295C168.235 23.9275 168 23.6757 167.682 23.5079C167.379 23.3233 167.027 23.2394 166.658 23.2394C166.272 23.2394 165.936 23.3233 165.634 23.5079C165.332 23.6925 165.08 23.9275 164.913 24.2463C164.745 24.5652 164.644 24.9008 164.644 25.27C164.644 25.6392 164.728 25.9916 164.913 26.2937C165.08 26.6125 165.332 26.8475 165.634 27.0321C165.936 27.2167 166.272 27.3006 166.658 27.3006C167.01 27.3006 167.363 27.2167 167.665 27.0321ZM167.816 25.3875C167.732 25.5385 167.598 25.6392 167.43 25.7231L168.034 26.6461H167.296L166.775 25.8574H166.742H166.289V26.6461H165.55V23.9107H166.742C167.111 23.9107 167.413 23.9946 167.614 24.1792C167.833 24.3638 167.933 24.5987 167.933 24.884C167.95 25.0686 167.9 25.2364 167.816 25.3875ZM166.708 25.3036C166.876 25.3036 166.994 25.27 167.094 25.1861C167.178 25.119 167.228 25.0015 167.228 24.8672C167.228 24.733 167.178 24.6323 167.094 24.5484C167.01 24.4813 166.876 24.4309 166.708 24.4309H166.289V25.2868H166.708V25.3036Z" fill="#363946"/>
    <path d="M132.064 30.7404C133.036 30.7404 133.883 30.9007 134.604 31.2213C135.325 31.5419 135.881 32.0027 136.272 32.6038C136.663 33.2049 136.858 33.9062 136.858 34.7077C136.858 35.5091 136.663 36.2104 136.272 36.8115C135.881 37.4126 135.325 37.8734 134.604 38.194C133.883 38.5146 133.036 38.6749 132.064 38.6749H130.546V41.2596H127V30.7404H132.064ZM131.839 35.9399C132.32 35.9399 132.68 35.8347 132.921 35.6243C133.161 35.4039 133.281 35.0984 133.281 34.7077C133.281 34.3169 133.161 34.0164 132.921 33.806C132.68 33.5856 132.32 33.4754 131.839 33.4754H130.546V35.9399H131.839Z" fill="#40CBA6"/>
    <path d="M142.529 38.6148H141.567V41.2596H138.021V30.7404H143.085C144.057 30.7404 144.904 30.9007 145.625 31.2213C146.346 31.5419 146.902 32.0027 147.293 32.6038C147.684 33.2049 147.879 33.9062 147.879 34.7077C147.879 35.459 147.709 36.1152 147.368 36.6762C147.027 37.2373 146.536 37.6831 145.895 38.0137L148.104 41.2596H144.317L142.529 38.6148ZM144.302 34.7077C144.302 34.3169 144.182 34.0164 143.942 33.806C143.701 33.5856 143.341 33.4754 142.86 33.4754H141.567V35.9399H142.86C143.341 35.9399 143.701 35.8347 143.942 35.6243C144.182 35.4039 144.302 35.0984 144.302 34.7077Z" fill="#40CBA6"/>
    <path d="M154.733 41.5C153.601 41.5 152.584 41.2646 151.683 40.7937C150.781 40.3229 150.075 39.6717 149.564 38.8402C149.053 37.9986 148.797 37.0519 148.797 36C148.797 34.9481 149.053 34.0064 149.564 33.1749C150.075 32.3333 150.781 31.6771 151.683 31.2063C152.584 30.7354 153.601 30.5 154.733 30.5C155.865 30.5 156.882 30.7354 157.784 31.2063C158.685 31.6771 159.392 32.3333 159.903 33.1749C160.413 34.0064 160.669 34.9481 160.669 36C160.669 37.0519 160.413 37.9986 159.903 38.8402C159.392 39.6717 158.685 40.3229 157.784 40.7937C156.882 41.2646 155.865 41.5 154.733 41.5ZM154.733 38.6298C155.174 38.6298 155.57 38.5246 155.92 38.3142C156.281 38.0938 156.566 37.7883 156.777 37.3975C156.987 36.9968 157.092 36.531 157.092 36C157.092 35.469 156.987 35.0082 156.777 34.6175C156.566 34.2168 156.281 33.9112 155.92 33.7008C155.57 33.4804 155.174 33.3702 154.733 33.3702C154.292 33.3702 153.892 33.4804 153.531 33.7008C153.18 33.9112 152.9 34.2168 152.689 34.6175C152.479 35.0082 152.374 35.469 152.374 36C152.374 36.531 152.479 36.9968 152.689 37.3975C152.9 37.7883 153.18 38.0938 153.531 38.3142C153.892 38.5246 154.292 38.6298 154.733 38.6298Z" fill="#40CBA6"/>
  </svg>,
  'WiggotProLogoBar',
  '0 0 170 42'
)