import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <g clipPath="url(#clip0_5470_38426)">
      <path d="M23.1111 0H8.88889C3.97969 0 0 3.97969 0 8.88889V23.1111C0 28.0203 3.97969 32 8.88889 32H23.1111C28.0203 32 32 28.0203 32 23.1111V8.88889C32 3.97969 28.0203 0 23.1111 0Z" fill="currentColor"/>
      <path d="M24.9106 23.6031H7.08984V15.4679L16.0468 8.39844L24.9106 15.4715V23.6031ZM9.26643 21.4533H22.7338V16.4995L16.0395 11.1572L9.26643 16.5031V21.4533Z" fill="white"/>
      <path d="M16.0559 17.8417C17.058 17.8417 17.8704 17.0394 17.8704 16.0496C17.8704 15.0598 17.058 14.2574 16.0559 14.2574C15.0539 14.2574 14.2415 15.0598 14.2415 16.0496C14.2415 17.0394 15.0539 17.8417 16.0559 17.8417Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_5470_38426">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </>,
  'WiggotIcon',
  '0 0 32 32'
)