import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Image } from 'c3-ui/generics/Images'
import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'
import { Title, Text } from 'c3-ui/elements/Texts'
import { SecondaryButton } from 'c3-ui/elements/Buttons'

import { HideModal } from 'reduxActions/services'

import AlertImg from 'static/img/alert-big.svg'

class AgencyInvitationErrorMC extends Component {
  static propTypes = {
    adminsEmails: PropTypes.array,
    HideModal: PropTypes.func
  }
  BASE_CLASSNAME = 'agency-invitation-error-mc'

  render() {
    const { adminsEmails = [], HideModal } = this.props
    return (
      <Layout className={this.BASE_CLASSNAME} center>
        <Title
          className={`${this.BASE_CLASSNAME}__title`}
          smallSize
          gray
          uppercase
          center
        >
          Invitación incorrecta
        </Title>
        <Image src={AlertImg} small={3} fitContent />
        <Text
          gray
          largeSize
          center
          className={`${this.BASE_CLASSNAME}__description`}
        >
          Detectamos que esta invitación no corresponde con tu correo. Para
          recibir una invitación nueva contacta a tu administrador.
        </Text>
        <LayoutItem
          className={
            adminsEmails.length > 0
              ? `${this.BASE_CLASSNAME}__admins-wrapper`
              : ''
          }
        >
          {adminsEmails.map((adminEmail, index) => {
            return (
              <Title key={index} center smallSize gray>
                {adminEmail}
              </Title>
            )
          })}
        </LayoutItem>

        <SecondaryButton
          id="agencyBroker-agencyInvitationError-mc-button-close"
          small={4}
          onClick={() => {
            window.history.pushState(null, null, `/search`)
            HideModal()
          }}
        >
          Cerrar
        </SecondaryButton>
      </Layout>
    )
  }
}

const mapDispatchToProps = {
  HideModal
}

export default connect(
  null,
  mapDispatchToProps
)(AgencyInvitationErrorMC)
