import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { TextField, Select, Autocomplete } from 'rc'
import { useMedia } from 'rc/Hooks'
import { Search } from 'rc/Icons'
import ListFilterMobile from './_mobile'

const TextFieldStyled = styled(TextField)`
  background-color: white;
  margin: 0;
`

const SelectStyled = styled(Select)`
  background-color: white;
`

const AutocompleteStyled = styled(Autocomplete)`
  > div {
    margin: 0;
    input {
      background-color: white;
    }
  }
`

const SearchStyled = styled(Search)`
  ${({ theme }) => theme.box(20, 20)}
  margin-left: ${({ theme }) => theme.remCalc('15')};
  fill: ${({ theme }) => theme.palette.gray.main};
`

const ListFilter = ({
  config = [],
  debounceRequestSearch,
  secondaryContainerFilterCss,
  params = {},
  onFilterModalHandled,
  requestSearch,
  theme: {
    devices: { desktop }
  },
  listId,
  ...props
}) => {
  const history = useHistory()
  const {
    location: { state: locationState = {}, pathname }
  } = history

  const [stateParams, setParams] = useState(params)
  useEffect(() => {
    if (locationState && Object.keys(locationState).length > 0) {
      Object.keys(params).forEach(filterKey => {
        const locationKey = `${listId}_filter-${filterKey}`
        const locationStateFilter = locationState[locationKey]
        const stateFilter = params[filterKey]

        if (locationStateFilter && locationStateFilter !== stateFilter) {
          requestSearch({ ...stateParams, [filterKey]: locationStateFilter })
          history.replace(pathname, {
            ...locationState,
            [locationKey]: null
          })

          return true
        }
      })
    }
  }, [
    history,
    listId,
    locationState,
    params,
    pathname,
    requestSearch,
    stateParams
  ])

  useEffect(() => {
    setParams(params)
  }, [params])

  const renderElement = (element, value, onChange, debounceOnChange) => {
    switch (element.type) {
      case 'search':
        return (
          <TextFieldStyled
            id={element.id}
            placeholder={element.placeholder}
            onChange={({ target: { value } }) =>
              debounceOnChange({ [element.name]: value })
            }
            InputProps={{
              startAdornment: <SearchStyled />
            }}
            value={value}
            name={element.name}
          />
        )
      case 'async-dropdown':
        return (
          <AutocompleteStyled
            id={element.id}
            name={element.name}
            fetchFunction={value => element.loadOptions(value)}
            onChange={(name, value) => onChange({ [name]: value })}
            labelItemKey={element.getOptionLabel}
            placeholder={element.placeholder}
            value={value && value[element.getOptionLabel]}
            shouldClearOnEmpty
          />
        )

      case 'dropdown':
        return (
          <SelectStyled
            native
            id={element.id}
            selectId={element.id}
            iconId={element.id}
            name={element.name}
            optionLabelKey={element.getOptionLabel}
            optionValueKey={element.getOptionValue}
            placeholder={
              element.shouldShowPlaceHolderOnMobile && !isDesktop
                ? element.placeholder
                : ''
            }
            initialValue={element.initialValue ? element.initialValue : value}
            withClean={element.isClearable}
            customHeight="240"
            onChange={({ target: { value } }) => {
              onChange({ [element.name]: value })
            }}
            options={element.options}
            value={value}
          />
        )
      default:
        return null
    }
  }

  const showFilter = item => (item.show ? item.show({ filters: params }) : true)

  const isDesktop = useMedia(desktop)
  return isDesktop ? null : (
    <ListFilterMobile
      config={config.filter(showFilter)}
      onFilterModalHandled={onFilterModalHandled}
      debounceRequestSearch={debounceRequestSearch}
      params={stateParams}
      requestSearch={requestSearch}
      renderElement={renderElement}
      secondaryContainerFilterCss={secondaryContainerFilterCss}
    />
  )
}

ListFilter.propTypes = {
  config: PropTypes.array,
  secondaryContainerFilterCss: PropTypes.string,
  debounceRequestSearch: PropTypes.func,
  params: PropTypes.object,
  onFilterModalHandled: PropTypes.func,
  renderElement: PropTypes.func,
  requestSearch: PropTypes.func,
  theme: PropTypes.object,
  listId: PropTypes.string
}

export default withTheme(ListFilter)
