import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M3 8.80421C3 8.32558 3 8.08627 3.06169 7.86588C3.11633 7.67065 3.20614 7.48704 3.32669 7.32405C3.46278 7.14005 3.65168 6.99312 4.02949 6.69927L9.68141 2.30333C9.97418 2.07562 10.1206 1.96177 10.2822 1.918C10.4248 1.87938 10.5752 1.87938 10.7178 1.918C10.8794 1.96177 11.0258 2.07562 11.3186 2.30333L16.9705 6.69927C17.3483 6.99312 17.5372 7.14005 17.6733 7.32405C17.7939 7.48704 17.8837 7.67065 17.9383 7.86588C18 8.08627 18 8.32558 18 8.80421V14.8333C18 15.7667 18 16.2335 17.8183 16.59C17.6586 16.9036 17.4036 17.1585 17.09 17.3183C16.7335 17.5 16.2668 17.5 15.3333 17.5H5.66667C4.73325 17.5 4.26654 17.5 3.91002 17.3183C3.59641 17.1585 3.34144 16.9036 3.18166 16.59C3 16.2335 3 15.7667 3 14.8333V8.80421Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5093 7.49904L10.5093 10.8324M10.5093 14.1657L10.5009 14.1657"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Fragment>,
  'HomeWarning',
  '0 0 21 20'
)
