import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography } from 'rc'

const ListItem = ({
  icon: Icon,
  onClick,
  principal,
  secondary,
  tertiary,
  active
}) => {
  return (
    <ItemContainer onClick={onClick} active={active}>
      {Icon && <Icon />}
      <ItemBody>
        {secondary && (
          <Typography variant="body2" color="palette.new.text">
            {secondary}
          </Typography>
        )}
        {principal && (
          <Typography
            variant="subtitle3"
            css={`
              color: #605d75;
            `}
          >
            {principal}
          </Typography>
        )}
        {tertiary && (
          <Typography variant="body3" color="palette.new.text">
            {tertiary}
          </Typography>
        )}
      </ItemBody>
    </ItemContainer>
  )
}

ListItem.propTypes = {
  principal: PropTypes.string,
  active: PropTypes.bool,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  secondary: PropTypes.string,
  tertiary: PropTypes.string
}

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 80px;
  box-sizing: border-box;
  padding: 10px;
  background-color: ${({ active }) => (active ? '#f5f7fd' : 'transparent')};
  &:hover {
    background-color: #f5f7fd;
  }
  svg {
    max-width: 17px;
    max-height: 17px;
    margin: 0 15px 0 10px;
    color: ${({ theme }) => theme.palette.new.text};
    fill: ${({ theme }) => theme.palette.new.text};
  }
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`
const ItemBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
`

export default ListItem
