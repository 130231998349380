import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'rc'
import { Button } from 'components'

const MessageMC = ({ msg, HideModal }) => {
  return (
    <ContainerStyled>
      <Typography variant="h3" color="palette.primary.main">
        Mensaje completo
      </Typography>
      <span
        css={`
          margin: ${({ theme }) => theme.remCalc('20 0')};
        `}
      >
        {msg}
      </span>
      <Button
        type="secondary"
        onClick={HideModal}
        css={`
          width: ${({ theme }) => theme.remCalc('180')};
        `}
      >
        Cerrar
      </Button>
    </ContainerStyled>
  )
}
MessageMC.propTypes = {
  HideModal: PropTypes.func,
  msg: PropTypes.string
}

const ContainerStyled = styled.div`
  width: ${({ theme }) => theme.remCalc('350')};
  min-height: ${({ theme }) => theme.remCalc('300')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('20')};
  text-align: center;
`

export default MessageMC
