import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M2.06641 12.8334H13.7331"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.62061 12.8333L2.64977 5.81581C2.64977 5.45997 2.81894 5.12167 3.09894 4.9L7.18227 1.72083C7.60227 1.39416 8.19144 1.39416 8.61727 1.72083L12.7006 4.89417C12.9864 5.11583 13.1498 5.45414 13.1498 5.81581V12.8333"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M8.48307 9.91663H7.31641C6.83224 9.91663 6.44141 10.3075 6.44141 10.7916V12.8333H9.35807V10.7916C9.35807 10.3075 8.96724 9.91663 8.48307 9.91663Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M6.44141 8.02079H5.27474C4.95391 8.02079 4.69141 7.75829 4.69141 7.43746V6.56246C4.69141 6.24163 4.95391 5.97913 5.27474 5.97913H6.44141C6.76224 5.97913 7.02474 6.24163 7.02474 6.56246V7.43746C7.02474 7.75829 6.76224 8.02079 6.44141 8.02079Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10.5249 8.02079H9.35824C9.0374 8.02079 8.7749 7.75829 8.7749 7.43746V6.56246C8.7749 6.24163 9.0374 5.97913 9.35824 5.97913H10.5249C10.8457 5.97913 11.1082 6.24163 11.1082 6.56246V7.43746C11.1082 7.75829 10.8457 8.02079 10.5249 8.02079Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.9831 4.08337L11.9656 2.33337H9.39893"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'Home',
  '0 0 15 14'
)
