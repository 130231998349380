export var isSize = function isSize(size) {
  var Sizes = {
    small: [null, 640],
    medium: [641, 1024],
    large: [1025, 1440]
  };
  switch (size) {
    case 'small':
      return window.innerWidth <= Sizes.small[1];
    case 'medium':
      return window.innerWidth <= Sizes.medium[1];
    case 'large':
      return window.innerWidth <= Sizes.large[1];
  }
};

export default isSize;