import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { Grid, SubmitButton, Typography, FormContext } from 'rc'
import { ButtonStyled } from './CommonStyles'
import TabBar from 'components/TabBarSection/TabBar'
import GeneralDevelopmentContext from 'modules/development/forms/development/GeneralDevelopmentContext'
import { EventRx, EVENTS } from 'utils/event'
import FreemiumWrapper from 'modules/freemium/FreemiumWrapper'

const TabBarWithButtons = ({
  tabs = [],
  totalTabs,
  customChangeStepHandler,
  sectionTitle = '',
  goBack,
  isForm,
  withButtons,
  action,
  restart,
  customHeader,
  disabled = false,
  saveDraftRule,
  ...props
}) => {
  const history = useHistory()
  const location = useLocation()
  const data = useContext(GeneralDevelopmentContext)

  const matchUrl = tabs.filter(tab => tab.matchUrl)
  const initialStep = tabs.findIndex(tab => tab.initialStep)
  const hasInitialStep = initialStep !== -1
  const match = history.location.pathname.includes(
    matchUrl[0] && matchUrl[0].matchUrl
  )
  const models = location.state && location.state.modelsList
  const units = location.state && location.state.unitsList
  const matches = location.state && location.state.matchesList
  const offereds = location.state && location.state.offeredList

  const [currentStep, setCurrentStep] = useState(
    hasInitialStep ? initialStep + 1 : match ? 2 : 1
  )

  useEffect(() => {
    restart && setCurrentStep(1)
  }, [restart])

  useEffect(() => {
    EventRx.subscribe(this, EVENTS.SET_TAB_BAR_STEP, step =>
      setCurrentStep(step)
    )

    return () => {
      EventRx.unsubscribe(this, EVENTS.SET_TAB_BAR_STEP)
    }
  }, [])

  const customChangeStep = step => {
    setTimeout(() => {
      setCurrentStep(step)
    }, 500)
  }

  useEffect(() => {
    if (models) customChangeStep(2)
    else if (units || matches) customChangeStep(3)
    else if (offereds) customChangeStep(4)
  }, [models, units, matches, offereds, location.state])

  useEffect(() => {
    if (customChangeStepHandler && customChangeStepHandler && totalTabs > 1) {
      customChangeStepHandler(setCurrentStep)
    }
  }, [tabs, totalTabs])

  return (
    <Grid
      container
      direction="column"
      css={`
        background-color: #f5f7fd;
        height: 100%;
      `}
    >
      {customHeader && customHeader}
      <Grid
        container
        xsShrink
        alignItems="center"
        justify="space-between"
        withButtons={withButtons}
        css={`
          padding: ${({ theme }) => theme.remCalc('12 0')};
          ${({ withButtons, theme }) =>
            withButtons && `padding-left: ${theme.remCalc('12')};`}
          ${({ theme }) => theme.media.desktopDown`
          padding: 0;
        `}
        `}
      >
        <Typography
          variant="h2"
          textTransform="initial"
          color="palette.primary.main"
        >
          {sectionTitle}
        </Typography>
        {withButtons && (
          <div>
            {currentStep === 1 ? (
              <ButtonStyled
                type="tertiary"
                css={`
                  min-height: ${({ theme }) => theme.remCalc('40')};
                  margin-right: ${({ theme }) => theme.remCalc('15')};
                `}
                disabled={disabled}
                onClick={() => (goBack ? goBack() : history.goBack())}
              >
                Cancelar
              </ButtonStyled>
            ) : (
              <ButtonStyled
                type="tertiary"
                css={`
                  min-height: ${({ theme }) => theme.remCalc('40')};
                  margin-right: ${({ theme }) => theme.remCalc('15')};
                `}
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Atrás
              </ButtonStyled>
            )}
            {isForm &&
              withButtons &&
              tabs.length > 1 &&
              (saveDraftRule ? (
                <FreemiumWrapper
                  section={saveDraftRule}
                  label={'Guardar borrador'}
                >
                  <FormContext.Consumer>
                    {({ values }) => (
                      <ButtonStyled
                        type="secondary"
                        onClick={() => {
                          return data && data.handleRequest(values)
                        }}
                        disabled={disabled}
                        css={`
                          min-height: ${({ theme }) => theme.remCalc('40')};
                          border-radius: ${({ theme }) => theme.remCalc('20')};
                          margin-right: ${({ theme }) => theme.remCalc('15')};
                        `}
                      >
                        Guardar borrador
                      </ButtonStyled>
                    )}
                  </FormContext.Consumer>
                </FreemiumWrapper>
              ) : (
                <FormContext.Consumer>
                  {({ values }) => (
                    <ButtonStyled
                      type="secondary"
                      onClick={() => {
                        return data && data.handleRequest(values)
                      }}
                      disabled={disabled}
                      css={`
                        min-height: ${({ theme }) => theme.remCalc('40')};
                        border-radius: ${({ theme }) => theme.remCalc('20')};
                        margin-right: ${({ theme }) => theme.remCalc('15')};
                      `}
                    >
                      Guardar borrador
                    </ButtonStyled>
                  )}
                </FormContext.Consumer>
              ))}
            {currentStep === tabs.length ? (
              isForm ? (
                <SubmitButton
                  id="development-create-development"
                  disabled={disabled}
                  css={`
                    min-height: ${({ theme }) => theme.remCalc('40')};
                    width: ${({ theme }) => theme.remCalc('120')};
                    border-radius: ${({ theme }) => theme.remCalc('20')};
                  `}
                >
                  Finalizar
                </SubmitButton>
              ) : (
                <ButtonStyled
                  type="primary"
                  disabled={disabled}
                  css={`
                    min-height: ${({ theme }) => theme.remCalc('40')};
                    margin-right: ${({ theme }) => theme.remCalc('15')};
                  `}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  Finalizar
                </ButtonStyled>
              )
            ) : (
              <ButtonStyled
                type="primary"
                css={`
                  min-height: ${({ theme }) => theme.remCalc('40')};
                  margin-right: ${({ theme }) => theme.remCalc('15')};
                `}
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                Siguiente
              </ButtonStyled>
            )}
          </div>
        )}
      </Grid>
      <TabBar
        tabs={tabs}
        center={withButtons}
        currentStep={currentStep}
        customSize={700}
        setStep={setCurrentStep}
        action={action}
        history={history}
        isForm={isForm}
        match={match}
        {...props}
      />
    </Grid>
  )
}
TabBarWithButtons.propTypes = {
  action: PropTypes.any,
  isForm: PropTypes.bool,
  goBack: PropTypes.func,
  restart: PropTypes.bool,
  sectionTitle: PropTypes.string,
  tabs: PropTypes.array,
  totalTabs: PropTypes.number,
  customChangeStepHandler: PropTypes.func,
  withButtons: PropTypes.bool,
  customHeader: PropTypes.any,
  disabled: PropTypes.bool,
  saveDraftRule: PropTypes.object
}

export default TabBarWithButtons
