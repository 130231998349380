import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Typography } from 'rc'

const SubTitle = ({ children }) => {
  return (
    <Container>
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
        css={`
          
          ${({ theme }) => theme.media.tabletDown`display: none;`}
        `}
      >
        {children}
      </Typography>
      <Typography variant="subtitle2" fontWeight={600}
        css={`
        display: none;
        ${({ theme }) => theme.media.tabletDown`display: block;`}
      `}
      >
        {children}
      </Typography>
    </Container>
  )
}

SubTitle.propTypes = {
  children: PropTypes.any
}

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.remCalc('16')};
`

export default SubTitle
