import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M13.5 0L9.28 5.63L10.53 7.3L13.5 3.33L18.5 10H10.04L6.03 4.63L0.5 12H22.5L13.5 0ZM4.5 10L6.02 7.97L7.54 10H4.5Z"
      fill="currentColor"
    />
  </>,
  'Mountain',
  '0 0 23 12'
)
