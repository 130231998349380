import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AgencyBrokerStars } from 'components/Icons'
import { Grid, Typography } from 'rc'
import { Button } from 'components'
import { UpdateProfileConfig } from 'reduxActions/profile'
import {
  HideModal,
  ShowFlashNotification,
  ShowModal,
  GoBackModalHistory
} from 'reduxActions/services'
import NotificationMC from 'modules/app/modalContent/NotificationMC'
import FreemiumWrapper from 'modules/freemium/FreemiumWrapper'
import { RULES } from 'modules/freemium/utils/constants'

const GetToKnowProfileScoresMC = ({
  token,
  HideModal,
  ShowModal,
  ShowFlashNotification,
  userScoresSectionConfig,
  UpdateProfileConfig,
  withModalHistory,
  GoBackModalHistory
}) => {
  const activateProfileScores = async params => {
    try {
      await UpdateProfileConfig(token, {
        user_scores_section_config: 'SHOWN'
      })
      if (withModalHistory) {
        GoBackModalHistory()
      } else {
        ShowModal(
          NotificationMC,
          {
            title: '¡Se activó la sección correctamente!',
            message:
              'Se mostrará tu calificación y los comentarios de asesores en tu perfil. '
          },
          null,
          true
        )
      }
    } catch (error) {
      ShowFlashNotification('danger', 'Ha ocurrido un error.')
    }
  }
  const activeScoresLabel = 'Activar calificaciones'
  return (
    <Grid
      container
      direction="column"
      justify="center"
      css={`
        width: ${({ theme }) => theme.remCalc('630')};
        max-width: 100%;
        padding: ${({ theme }) => theme.remCalc('10 0')};
        ${({ theme }) => theme.media.desktop`
          padding: ${({ theme }) => theme.remCalc('30 0 20')};
        `}
      `}
    >
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
        align="center"
      >
        {'Conoce "Calificaciones"'}
      </Typography>
      <Grid
        container
        css={`
          padding: ${({ theme }) => theme.remCalc('10')};
          ${({ theme }) => theme.media.desktop`
            padding: ${({ theme }) => theme.remCalc('20 40 0 0')};
          `}
        `}
      >
        <Grid item md={9}>
          <AgencyBrokerStars
            css={`
              width: 100%;
              height: ${({ theme }) => theme.remCalc('100')};
              ${({ theme }) => theme.media.desktop`
                height: 100%;
              `}
            `}
          ></AgencyBrokerStars>
        </Grid>
        <Grid
          item
          md={15}
          container
          direction="column"
          flat
          css={`
            ${({ theme }) => theme.media.desktop`
              > * {
                margin-bottom: 10px;
              }
            `}
          `}
        >
          <Typography variant="body2">
            Es la nueva sección de tu perfil que te permite mostrar la
            calificación y comentarios que te han dado otros asesores.
          </Typography>
          <Typography variant="body2">
            Desde tu perfil tu puedes elegir si quieres o no mostrar esta
            sección al dar click en el botón de configuración (Engrane) del
            módulo calificaciones.
          </Typography>
          <Typography variant="body2">
            Si activas esta sección en tu perfil tu también podrás calificar a
            otros asesores entrando a sus perfiles, por el momento sólo recibes
            calificaciones de otros asesores y NO de tu cliente final.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        center
        css={`
          margin-top: ${({ theme }) => theme.remCalc('18')};
        `}
      >
        <Grid item md={11}>
          <Button full type="tertiary" onClick={HideModal}>
            Cerrar
          </Button>
        </Grid>
        {userScoresSectionConfig === 'HIDDEN' && (
          <Grid item md={12}>
            <FreemiumWrapper
              section={RULES.PROFILE_RATINGS}
              label={activeScoresLabel}
              full
            >
              <Button full onClick={activateProfileScores}>
                {activeScoresLabel}
              </Button>
            </FreemiumWrapper>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

GetToKnowProfileScoresMC.propTypes = {
  token: PropTypes.string,
  HideModal: PropTypes.func,
  ShowModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  userScoresSectionConfig: PropTypes.oneOf([
    'HIDDEN',
    'SHOWN',
    'SCORE_WITHOUT_COMMENT'
  ]),
  UpdateProfileConfig: PropTypes.func,
  withModalHistory: PropTypes.bool,
  GoBackModalHistory: PropTypes.func
}

const mapStateToProps = ({
  authReducer: { token },
  profileReducer: { userScoresSectionConfig }
}) => ({
  token,
  userScoresSectionConfig
})

const mapDispatchToProps = {
  HideModal,
  ShowModal,
  ShowFlashNotification,
  UpdateProfileConfig,
  GoBackModalHistory
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetToKnowProfileScoresMC)
