import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { css } from 'styled-components'

import { SuitcaseRounded } from 'rc/Icons'

import {
  ProfileSectionCard,
  StatusZero,
  ProfileEditableItem
} from 'modules/profile/components'
import { ProfileContext } from 'modules/profile'
import { ShowModal } from 'reduxActions/services'
import { ProfileExperienceMC } from 'modules/profile/modalContent'
import { RULES } from 'modules/freemium/utils/constants'

const ProfileWorkExperience = ({ ShowModal }) => {
  const profileContext = useContext(ProfileContext)

  let {
    profile: { work_experience = [] },
    isMyProfile
  } = profileContext

  work_experience = work_experience.sort((a, b) => {
    if (!a.end_year && !b.end_year) {
      if (
        a.start_year > b.start_year ||
        (a.start_year === b.start_year && a.start_month > b.start_month)
      ) {
        return -1
      }
    }

    if (!a.end_year && b.end_year) {
      return -1
    }

    if (a.end_year && !b.end_year) {
      return 1
    }

    if (a.end_year > b.end_year) {
      return -1
    } else if (a.end_year === b.end_year) {
      if (a.end_month > b.end_month) {
        return -1
      }
    }
    return 1
  })

  const renderExperience = (experience, index) => {
    const { position, company_name, start_year, end_year } = experience

    const positionLabel = [position, company_name]
      .filter(item => item)
      .join(' en ')
    const timeRange = [start_year, end_year || 'Actualidad'].join(' - ')

    return (
      <ProfileEditableItem
        key={index}
        index={index}
        idBase="profile-workExperience"
        title={positionLabel}
        subtitle={timeRange}
        icon={SuitcaseRounded}
        isFirst={index === 0}
        isLast={index === work_experience.length - 1}
        isEditable={isMyProfile}
        onEditClick={() => showProfileExperienceMC(experience)}
      />
    )
  }

  const showProfileExperienceMC = experience => {
    ShowModal(
      ProfileExperienceMC,
      {
        profileContext,
        experience
      },
      { overflow: false, noClosingButton: false }
    )
  }

  const hasExperience = work_experience && work_experience.length > 0

  return hasExperience || isMyProfile ? (
    <ProfileSectionCard
      title="Experiencia laboral"
      isEditable={isMyProfile}
      actions={[
        {
          id: 'profile-workExperience-button-newExperience',
          name: 'Añadir Experiencia',
          onClick: () => showProfileExperienceMC(),
          variant: 'outlined',
          isShown: isMyProfile && hasExperience,
          rule: RULES.PROFILE_WORK_EXPERIENCE
        }
      ]}
    >
      {hasExperience ? (
        work_experience.map(renderExperience)
      ) : (
        <StatusZero
          description="Aún no tienes agregado ningúna experiencia laboral"
          buttonProps={{
            id: 'profile-workExperience-button-newExperienceStatusZero',
            text: 'Añadir experiencia',
            onClick: () => showProfileExperienceMC(),
            rule: RULES.PROFILE_WORK_EXPERIENCE
          }}
          iconProps={{
            className: css`
              background-color: ${({ theme }) => theme.palette.primary.main};
            `,
            icon: SuitcaseRounded
          }}
        />
      )}
    </ProfileSectionCard>
  ) : null
}

ProfileWorkExperience.propTypes = {
  ShowModal: PropTypes.func.isRequired
}
const mapStateToProps = () => ({})

const mapDispatchToProps = {
  ShowModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileWorkExperience)
