import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'rc'

const Section = ({
  title = '',
  description = '',
  children,
  minHeight,
  initial,
  ...props
}) => {
  return (
    <Container minHeight={minHeight}>
      <Typography
        variant="h2"
        color="palette.primary.main"
        textTransform={initial ? 'initial' : 'uppercase'}
      >
        {title}
      </Typography>
      <Typography
        variant="body3"
        color="palette.new.text"
        css={`
          margin: ${({ theme }) => theme.remCalc('5 0 10')};
        `}
      >
        {description}
      </Typography>
      <div
        {...props}
        css={`
          flex: 1;
        `}
      >
        {children}
      </div>
    </Container>
  )
}

Section.propTypes = {
  children: PropTypes.any,
  description: PropTypes.string,
  initial: PropTypes.bool,
  minHeight: PropTypes.number,
  title: PropTypes.string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  width: ${({ theme }) => theme.remCalc('800')};
  max-width: 100%;
  min-height: ${({ theme, minHeight }) =>
    minHeight ? theme.remCalc(`${minHeight}`) : theme.remCalc('250')};
  height: ${({ theme, height }) => (height ? theme.remCalc(height) : 'auto')};
  border-radius: ${({ theme }) => theme.remCalc('20')};
  margin: ${({ theme }) => theme.remCalc('12 0')};
  padding: ${({ theme }) => theme.remCalc('20')};
  ${({ error }) => error && 'border: 1px solid red;'}
`
export default Section
