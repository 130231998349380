var _jsxFileName = 'src/lib/elements/FormInputs/FormGroup.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout } from '../Layouts';

var FormGroup = function FormGroup(_ref) {
  var children = _ref.children,
      paddingX = _ref.paddingX,
      marginY = _ref.marginY,
      centerAlign = _ref.centerAlign,
      justifyAlign = _ref.justifyAlign,
      rightAlign = _ref.rightAlign,
      bottomAlign = _ref.bottomAlign,
      middleAlign = _ref.middleAlign,
      topAlign = _ref.topAlign,
      fullHeight = _ref.fullHeight;

  var formGroupClasses = classNames('wig-form-group', {
    'wig-form-group--padding-x': paddingX,
    'wig-form-group--margin-y': marginY
  });
  return React.createElement(
    Layout,
    {
      className: formGroupClasses,
      center: centerAlign,
      justify: justifyAlign,
      right: rightAlign,
      bottom: bottomAlign,
      middle: middleAlign,
      top: topAlign,
      fullHeight: fullHeight,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 23
      },
      __self: _this
    },
    children
  );
};

FormGroup.defaultProps = {
  paddingX: true,
  marginY: true
};

FormGroup.propTypes = {
  /** */
  bottomAlign: PropTypes.bool,
  /** */
  centerAlign: PropTypes.bool,
  /** */
  children: PropTypes.any,
  /** */
  fullHeight: PropTypes.bool,
  /** */
  justifyAlign: PropTypes.bool,
  /** */
  marginY: PropTypes.bool,
  /** */
  middleAlign: PropTypes.bool,
  /** */
  paddingX: PropTypes.bool,
  /** */
  rightAlign: PropTypes.bool,
  /** */
  topAlign: PropTypes.bool
};

export default FormGroup;