import React, { Fragment } from 'react'
import { string, func, array } from 'prop-types'
import styled from 'styled-components'
import { DNDFileUploader, Input } from 'rc/FormComponents'
import { Button } from 'components'
import { CrossButton } from 'modules/development/static-components'
import { getShortFileName, isObject } from 'utils/helpers'

const CrossButtonStyled = styled(CrossButton)`
  margin: 10px 0 10px 10px;
  min-width: ${({ theme }) => theme.remCalc('22')};
  max-width: ${({ theme }) => theme.remCalc('22')};
  min-height: ${({ theme }) => theme.remCalc('22')};
  max-height: ${({ theme }) => theme.remCalc('22')};
`

function UploadFiles(props) {
  const { files, fileUploader, customChange, name } = props

  const renderFiles = (item, utils) => {
    const fileName = item.value.file || '...'
    const shortFileName = getShortFileName(fileName)

    const cb = val => {
      const dev_files = val.map(fil => ({
        file: fil.value.file,
        file_id: fil.value.id || fil.value.file_id,
        id: fil.value.id || fil.value.file_id,
        name: fil.value.file
      }))
      customChange && customChange({ [`${name}`]: dev_files, files: dev_files })
    }
    return (
      <div
        className="file-element"
        css={`
          display: flex;
          align-items: center;
        `}
      >
        <p>
          {isObject(item.value) && (
            <Fragment>
              <Input
                label={'Titulo del archivo'}
                name={`filesTitle${item.value.file_id}`}
                defaultValue={shortFileName}
                placeholder="Agregue el título del archivo."
                required
              />
            </Fragment>
          )}
        </p>
        <CrossButtonStyled onClick={() => utils.handleDelete(cb)} />
      </div>
    )
  }

  const onChange = (e = []) => {
    const files = e
      .filter(({ file_id }) => typeof file_id === 'string')
      .map(({ file_id, file }) => ({ id: file_id, name: file }))

    typeof e[0] == 'object' &&
      customChange &&
      customChange({ [`${name}`]: e, files })
  }

  return (
    <DNDFileUploader
      name={name}
      defaultValue={files}
      fileUploader={fileUploader}
      multiple
      onChange={onChange}
    >
      {({ showFileDialog, renderItems }) => (
        <Fragment>
          <div
            css={`
              flex: 1;
            `}
          >
            {renderItems(renderFiles)}
          </div>
          <Button
            onClick={showFileDialog}
            css={`
              min-height: ${({ theme }) => theme.remCalc('40')};
              max-height: ${({ theme }) => theme.remCalc('40')};
            `}
            rounded
          >
            Subir archivo
          </Button>
        </Fragment>
      )}
    </DNDFileUploader>
  )
}

UploadFiles.propTypes = {
  name: string,
  className: string,
  files: array,
  fileUploader: func,
  customChange: func
}

export default UploadFiles
