import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path d="M52 37.7048C52 50.8249 40.3364 61.4991 26 61.4991C11.6636 61.4991 0 50.8249 0 37.7048C0 33.4004 1.2623 28.8247 3.7518 24.1075C5.72 20.3742 8.4656 16.5314 11.9106 12.6838C12.3448 12.1984 13.0117 11.9414 13.6942 11.9938C14.3767 12.0461 14.9864 12.4019 15.327 12.9468C16.6192 15.0192 18.8474 15.3298 20.4997 14.9276C22.1533 14.5267 23.92 13.2466 23.92 10.86V2.48915C23.92 1.76104 24.3737 1.09718 25.0887 0.778335C25.8037 0.459491 26.6552 0.541582 27.2818 0.990105C27.5327 1.16975 33.5049 5.47296 39.5551 12.0937C43.1327 16.0091 45.9901 19.9232 48.0467 23.7292C50.6701 28.5832 52 33.285 52 37.7048Z" fill="#FD7171"/>
    <path d="M51.9995 37.7057C51.9995 50.8259 41.1999 61.5 27.9255 61.5C27.2779 61.5 26.6351 61.475 25.9995 61.4251C38.3772 60.4519 48.1477 50.1858 48.1477 37.7057C48.1477 33.2859 46.9163 28.5841 44.4872 23.7301C42.583 19.9242 39.9372 16.01 36.6246 12.0946C32.2853 6.96578 26.2954 2.85559 24.4706 1.34822C24.6511 0.944908 24.819 0.786384 25.2355 0.586512C25.8975 0.267668 27.7139 0.899889 28.2941 1.34841C28.5264 1.52806 34.8745 5.47387 40.4765 12.0946C43.7891 16.01 46.4348 19.9242 48.3391 23.7301C50.7682 28.5841 51.9995 33.2859 51.9995 37.7057Z" fill="#FD5353"/>    
  </Fragment>,
  'SuperMatch',
  '0 0 52 62'
)
