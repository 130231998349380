import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { ShowFlashNotification } from 'reduxActions/services'
import { LogIn } from 'reduxActions/auth'
import { GetRules } from 'reduxActions/freemium'
import { GetUserInfo } from 'reduxActions/profile'

import { FormContainer, TextField, SubmitButton, Typography, Spinner } from 'rc'
import { Mail, Lock } from 'rc/Icons'

import Eye from 'static/img/visibility-on.svg'
import BlindEye from 'static/img/visibility-off.svg'

import eventGoogle from 'utils/eventsGoogle'

const LoginForm = ({ setActiveSection, tracker, AmplitudeEvents }) => {
  const history = useHistory()
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }
  
  const handleSubmit = values => {
    setIsLoading(true)
    const required_fields = ['email', 'password']
    const fields_with_errors = required_fields.filter(
      field =>
        !values[field] ||
        (typeof values[field] === 'string' && values[field].trim().length === 0)
    )
    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }
    return dispatch(LogIn({ ...values, email: values.email.trim() }))
  }

  const handleSubmitSuccess = async response => {
    const { payload: { data: { token: authorization, roles } = {} } = {} } =
      response

    tracker(AmplitudeEvents.LOGIN_FORM_ACTION, 'User login successfully')

    await dispatch(GetRules(authorization))
    await dispatch(GetUserInfo(authorization))
    eventGoogle.eventsProfile.getProfile()

    if (roles?.includes('DEVELOPER_USER')) return history.push('/search')

    if (roles?.includes('ASSOCIATION'))
      return history.push('/association/agencies')

    const searchParams = new URLSearchParams(location.search);
    const callback = searchParams.get('callback');
    const allSearchParams = window.location.search ?? ''
    
    if (callback) return window.location.replace(
      `${process.env.REACT_APP_NEW_APLICATION_URL}/${callback}${allSearchParams}`
    )

    return window.location.replace(
      `${process.env.REACT_APP_NEW_APLICATION_URL}/home`
    )
  }
  const handleSubmitFail = (formErrors, fc, submitErrors) => {
    tracker(AmplitudeEvents.LOGIN_FORM_ACTION, 'User login failed')
    setIsLoading(false)
    if (formErrors && Object.keys(formErrors).length > 0) {
      for (const prop in formErrors) {
        if (formErrors[prop]) {
          return dispatch(
            ShowFlashNotification(
              'danger',
              'Ninguno de los campos puede estar vacío'
            )
          )
        }
      }
    }
    if (!submitErrors) submitErrors = {}

    const {
      error: { response: { data: { error: { code } = {} } = {} } = {} } = {},
      code: customCode,
      metadata
    } = submitErrors
    let errorMessage = ''
    switch (code || customCode) {
      case 'VALIDATION_ERROR':
      case 'LOGIN_FAILED':
        errorMessage =
          'Tu correo o contraseña son incorrectos. \nVerificalos e intenta de nuevo.\n Código de error: WIG-01'
        break
      case 'INTERNAL_SERVER_ERROR':
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'
        break
      case 'REQUIRED':
        {
          const fieldErrors = metadata.reduce((previous, current) => {
            return {
              ...previous,
              [current]: 'Campo requerido'
            }
          }, {})

          fc.setCustomErrors({
            ...fieldErrors
          })
        }
        break
      default:
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'
        break
    }
    dispatch(ShowFlashNotification('danger', errorMessage))
  }
  return (
    <>
      <FormContainerStyled
        initialValues={{
          email: '',
          password: ''
        }}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={handleSubmitFail}
      >
        <TextFieldStyled
          fullWidth
          label="Correo electrónico"
          name="email"
          validate={isRequired}
          InputProps={{
            startAdornment: (
              <StartIconContainer>
                <Mail />
              </StartIconContainer>
            )
          }}
        />
        <TextFieldStyled
          fullWidth
          type={visible ? 'text' : 'password'}
          label="Contraseña"
          name="password"
          validate={isRequired}
          InputProps={{
            startAdornment: (
              <StartIconContainer>
                <Lock />
              </StartIconContainer>
            ),
            endAdornment: (
              <EndIconContainer onClick={() => setVisible(!visible)}>
                {visible ? (
                  <img src={BlindEye} alt="blind-eye-icon" />
                ) : (
                  <img src={Eye} alt="eye-icon" />
                )}
              </EndIconContainer>
            )
          }}
        />
        <SubmitButtonStyled size="large">
          {isLoading ? (
            <Spinner
              css={`
                height: 40px;
              `}
            />
          ) : (
            'Iniciar sesión'
          )}
        </SubmitButtonStyled>
      </FormContainerStyled>
      <TextlikeUrl
        onClick={() => {
          setActiveSection('forgotPassword')
          tracker(
            AmplitudeEvents.LOGIN_FORGOT_PASSWORD_VIEW,
            'User view forgot password section'
          )
        }}
      >
        Olvidé mi contraseña
      </TextlikeUrl>
    </>
  )
}

const FormContainerStyled = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const TextFieldStyled = styled(TextField)`
  margin: ${({ theme }) => theme.remCalc('16 0')};
  > label {
    left: ${({ theme }) => theme.remCalc('20')};
  }
  > div > fieldset {
    padding-left: 28px;
  }
`
const StartIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.remCalc('10')};
  width: ${({ theme }) => theme.remCalc('20')};
  > svg {
    width: 100%;
    color: ${({ theme }) => theme.palette.primary.main};
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`
const EndIconContainer = styled.div`
  margin-right: ${({ theme }) => theme.remCalc('5')};
  cursor: pointer;
  width: ${({ theme }) => theme.remCalc('38')};
  > img {
    width: 100%;
    color: ${({ theme }) => theme.palette.primary.main};
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`
const SubmitButtonStyled = styled(SubmitButton)`
  border-radius: 50px !important;
  margin: ${({ theme }) => theme.remCalc('30 0')};
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-color: ${({ theme }) => theme.palette.primary.light};
`
const TextlikeUrl = styled(Typography).attrs({
  variant: 'subtitle4',
  color: 'palette.primary.main'
})`
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`

LoginForm.propTypes = {
  AmplitudeEvents: PropTypes.object,
  setActiveSection: PropTypes.func,
  tracker: PropTypes.func
}

export default LoginForm
