import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Title } from 'c3-ui/elements/Texts'
import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'
import { PrimaryButton, SecondaryButton } from 'c3-ui/elements/Buttons'

import { HideModal, ShowFlashNotification } from 'reduxActions/services'
import { SearchAgencyBrokers } from 'reduxActions/agency'
import { AutocompleteSearcher } from 'c3-ui/elements/FormInputs'
import { AssignLead } from 'reduxActions/dashboard'

const AssignLeadMC = ({
  token,
  leadId,
  shortName,
  finishCallback,
  cancelCallback,
  HideModal,
  ShowFlashNotification,
  AssignLead,
  SearchAgencyBrokers
}) => {
  const [allBrokers, setAllBrokers] = useState([])
  const [selectedBroker, setSelectedBroker] = useState(null)

  const searchAgencyBrokers = useCallback(
    name => {
      return new Promise((resolve, reject) => {
        SearchAgencyBrokers(token, name)
          .then(action => {
            const {
              payload: { data: allBrokers }
            } = action

            resolve(allBrokers)
          })
          .catch(() => {
            reject([])
          })
      })
    },
    [SearchAgencyBrokers, token]
  )

  useEffect(() => {
    searchAgencyBrokers().then(allBrokers => {
      allBrokers && setAllBrokers(allBrokers)
    })
  }, [searchAgencyBrokers])

  const onSelectedBroker = selectedBroker =>
    selectedBroker && setSelectedBroker(selectedBroker)

  const assignLead = () => {
    const { user_id: userId } = selectedBroker

    AssignLead(token, leadId, userId, shortName)
      .then(response => {
        if (finishCallback) {
          finishCallback()
        }
        HideModal()
        ShowFlashNotification(
          'success',
          'Se ha asignado el asesor correctamente'
        )
      })
      .catch(
        ({
          error: {
            response: { data: { error: { code = '', message = '' } } = {} } = {}
          }
        } = {}) => {
          if (cancelCallback) {
            cancelCallback()
          } else {
            HideModal()
          }

          if (code === 'NOT_FOUND' && message === 'User not found') {
            ShowFlashNotification(
              'danger',
              'El asesor al que quieres asignarle el Lead no existe. Código de error: WIG-09'
            )
          } else if (
            code === 'NOT_FOUND' &&
            message === 'Properties not found'
          ) {
            ShowFlashNotification(
              'danger',
              'El Lead que quieres asignar a este asesor no existe. Código de error: WIG-10'
            )
          } else {
            ShowFlashNotification(
              'danger',
              'Error desconocido, por favor intenta de nuevo más tarde.'
            )
          }
        }
      )
  }

  return (
    <Layout vertical className="assign-properties-mc">
      <Title smallSize center className="assign-properties-mc__title">
        Asignar asesor
      </Title>
      <LayoutItem className="assign-properties-mc__autocomplete-searcher">
        <AutocompleteSearcher
          id="properties-assignProperties-mc-autocomplete-brokersList"
          componentType="extCT"
          disabled={false}
          list={allBrokers}
          action={searchAgencyBrokers}
          onChange={onSelectedBroker}
          placeholder="Buscar asesor"
          selectedValue={
            selectedBroker && selectedBroker.fullname
              ? selectedBroker.fullname
              : ''
          }
          renderValue={({ fullname = '', email = '' }) => {
            return fullname ? fullname : email
          }}
          forceOpened
        />
      </LayoutItem>
      <LayoutItem className="assign-properties-mc__action-buttons-group">
        <SecondaryButton
          id="properties-assignProperties-mc-button-cancel"
          onClick={HideModal}
        >
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          id="properties-assignProperties-mc-button-assign"
          mediumAuto
          onClick={assignLead}
        >
          Asignar
        </PrimaryButton>
      </LayoutItem>
    </Layout>
  )
}

AssignLeadMC.propTypes = {
  token: PropTypes.string,
  leadId: PropTypes.string,
  shortName: PropTypes.string,
  cancelCallback: PropTypes.func,
  finishCallback: PropTypes.func,
  AssignLead: PropTypes.func,
  HideModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  SearchAgencyBrokers: PropTypes.func
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  AssignLead,
  HideModal,
  ShowFlashNotification,
  SearchAgencyBrokers
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignLeadMC)
