import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from 'rc'

const ContainerHeader = ({ children, className }) => {
  return (
    <Grid
      container
      center
      css={`
        background-color: ${({ theme }) => theme.palette.warning.light};
      `}
      className={className}
    >
      <Grid
        container
        css={`
          width: 95%;
          max-width: ${({ theme }) => theme.remCalc('1080')};
          padding: ${({ theme }) => theme.remCalc('15 0')};
        `}
      >
        {children}
      </Grid>
    </Grid>
  )
}

ContainerHeader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
}

export default ContainerHeader
