var _jsxFileName = 'src/lib/elements/Checkboxes/PreviewImageCheckbox.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Image } from '../../generics/Images';
import { Layout, LayoutItem } from '../Layouts';
import { Text } from '../Texts';

var BASE_CLASSNAME = 'preview-image-checkbox';

var PreviewImageCheckbox = function PreviewImageCheckbox(_ref) {
  var _classNames;

  var value = _ref.value,
      baseURL = _ref.baseURL,
      className = _ref.className,
      fitContent = _ref.fitContent,
      imageURL = _ref.imageURL,
      isSelected = _ref.isSelected,
      label = _ref.label,
      _onClick = _ref.onClick;

  var checkboxContainerClasses = classNames(className, BASE_CLASSNAME);

  var checkboxIconClasses = classNames(BASE_CLASSNAME + '__check-icon', (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '__check-icon--checked', isSelected), _defineProperty(_classNames, BASE_CLASSNAME + '__check-icon--unchecked', !isSelected), _classNames));

  return React.createElement(
    Layout,
    {
      onClick: function onClick() {
        _onClick(value);
      },
      className: checkboxContainerClasses,
      vertical: true,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 29
      },
      __self: _this
    },
    React.createElement(
      Layout,
      { middle: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 36
        },
        __self: _this
      },
      React.createElement(LayoutItem, { className: checkboxIconClasses, __source: {
          fileName: _jsxFileName,
          lineNumber: 37
        },
        __self: _this
      }),
      React.createElement(
        Text,
        { bold: true, auto: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 38
          },
          __self: _this
        },
        label
      )
    ),
    React.createElement(
      LayoutItem,
      { className: BASE_CLASSNAME + '__img-wrapper', __source: {
          fileName: _jsxFileName,
          lineNumber: 42
        },
        __self: _this
      },
      React.createElement(Image, {
        baseURL: baseURL,
        src: imageURL,
        className: BASE_CLASSNAME + '__img',
        fitContent: fitContent,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 43
        },
        __self: _this
      })
    )
  );
};

PreviewImageCheckbox.propTypes = {
  /** Value passed onClick event. */
  value: PropTypes.any.isRequired,
  /** Added as a prefix to image */
  baseURL: PropTypes.string,
  /** Custom styles to applied due to class. */
  className: PropTypes.string,
  /** Makes image to specifically fit area on img-wrapper. */
  fitContent: PropTypes.bool,
  /** If added base url, this should be an id, otherwise it will be the full image url. */
  imageURL: PropTypes.string,
  /** Gives a diferent style. */
  isSelected: PropTypes.bool,
  /** Text to render inside the bubble. */
  label: PropTypes.string,
  /** Handler of click action passing value. */
  onClick: PropTypes.func
};

PreviewImageCheckbox.defaultProps = {
  fitContent: true
};

export default PreviewImageCheckbox;