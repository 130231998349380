import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { TextField, withForm } from 'rc'

import { LocationSelectFields } from 'components'

const StepFour = ({ isLoading, fc: { values = {} } = {} }) => {
  const isRequired = newValue => {
    return ((!newValue || (newValue && newValue.trim().length === 0)) &&'Campo requerido.')
  }
  
  return (
    <StepContainer>
      <LocationSelectFields
        direction="column"
        optionsHeight="150"
        stateFieldConfig={{ name: 'agency_state', gridItem: { flat: true }, disabled: isLoading }}
        cityFieldConfig={{ name: 'agency_city', gridItem: { flat: true }, disabled: isLoading, isHiddenCity: true }}
        neighborhoodFieldConfig={{ name: 'agency_neighborhood', gridItem: { flat: true }, disabled: isLoading, isHidden: true }}
        itemStateCss={`margin: 0 0 20px;`}
        itemCityCss={`margin: 0 0 4px;`}
        css={`flex-wrap: initial;`}
      />
      <TextField
        fullWidth
        required
        label="Ciudad"
        name="agency_city"
        validate={isRequired}
        disabled={isLoading}
      />
      <TextField
        fullWidth
        required
        label="Colonia"
        name="agency_neighborhood"
        validate={isRequired}
        disabled={isLoading}
      />
      <TextField
        fullWidth
        required
        label="Calle"
        name="agency_street"
        validate={isRequired}
        disabled={isLoading}
      />
      <TextField
        fullWidth
        required
        label="C.P."
        name="agency_postal_code"
        validate={isRequired}
        disabled={isLoading}
      />
      <AddressContainer>
        <TextField
          fullWidth
          required
          label="No. exterior"
          name="agency_exterior_number"
          validate={isRequired}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          label="No. interior"
          name="agency_interior_number"
          validate={isRequired}
          disabled={isLoading}
        />
      </AddressContainer>
    </StepContainer>
  )
}

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * { margin: ${({ theme }) => theme.remCalc('0 0 20')}; }
`
const AddressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > * { 
    width: 48%;
    margin: 0;
  }
`

StepFour.propTypes = {
  isLoading: PropTypes.bool,
  fc: PropTypes.object
}

export default withForm(StepFour)
