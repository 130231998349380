import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'

export default createSvgIcon(
  <Fragment>
    <path
      d="M18.4072 8.17454L12.5814 2.44085L13.1685 2.40631C13.6176 2.38328 13.9515 2.00334 13.9284 1.56583C13.9054 1.1168 13.5255 0.794424 13.0879 0.805938L10.7853 0.921072C10.3708 0.944099 10.0369 1.27799 10.0254 1.69247L9.91023 4.1103C9.88721 4.55932 10.2326 4.92775 10.6816 4.95078H10.7162C11.1422 4.95078 11.4991 4.61689 11.5221 4.17938L11.5451 3.67279L15.8857 7.93276H15.667C15.2179 7.93276 14.861 8.28968 14.861 8.7387V15.0711H9.91023C9.46121 15.0711 9.10429 15.428 9.10429 15.877C9.10429 16.3261 9.46121 16.683 9.91023 16.683H15.667C16.116 16.683 16.4729 16.3261 16.4729 15.877V9.54464H17.8545C18.1769 9.54464 18.4762 9.34892 18.6029 9.03805C18.7295 8.7387 18.6489 8.3933 18.4072 8.17454Z"
    />
    <path
      d="M9.43815 0.241782C9.12729 -0.0805941 8.60918 -0.0805941 8.29832 0.241782L0.238909 8.17455C0.00863962 8.40481 -0.060441 8.75022 0.0546934 9.04957C0.181341 9.34892 0.469177 9.54465 0.803067 9.54465H2.18468V15.877C2.18468 16.3261 2.5416 16.683 2.99062 16.683H5.6272L5.2818 17.0514C4.98245 17.3738 4.99396 17.8919 5.31634 18.1912C5.46601 18.3409 5.67326 18.41 5.86898 18.41C6.08774 18.41 6.29498 18.3179 6.45617 18.1567L8.06805 16.4297C8.35589 16.1188 8.35589 15.6353 8.06805 15.3244L6.45617 13.5974C6.15682 13.275 5.63872 13.252 5.31634 13.5628C4.99396 13.8622 4.97093 14.3803 5.2818 14.7027L5.6272 15.0711H3.79656V8.73871C3.79656 8.28968 3.43965 7.93276 2.99062 7.93276H2.77187L9.42664 1.38161C9.74901 1.07075 9.76053 0.564159 9.43815 0.241782Z"
    />
  </Fragment>,
  'UsedHouse',
  '0 0 19 19'
)
