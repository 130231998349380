import React from 'react'
import { string, number } from 'prop-types'
import styled from 'styled-components'
import { Input, Textarea } from 'rc/FormComponents'
import {
  SectionTitle,
  RadioInputStyled,
  GroupContainer
} from 'modules/development/static-components'

const TextareaStyled = styled(Textarea)`
  height: ${({ theme }) => theme.remCalc('120')};
`

function Details(props) {
  const {
    className,
    ground_shape,
    floors,
    bedrooms,
    bathrooms,
    half_bathrooms,
    parking_lots,
    cellar,
    model_description,
    ground_relief
  } = props
  return (
    <div className={className}>
      <SectionTitle>Detalle de la propiedad</SectionTitle>
      <GroupContainer>
        <Input
          name="floors"
          label="Niveles"
          type="number"
          min={0}
          className="group-input"
          defaultValue={floors}
        />
        <Input
          name="bedrooms"
          label="Cuartos"
          type="number"
          min={0}
          className="group-input"
          defaultValue={bedrooms}
        />
      </GroupContainer>
      <GroupContainer>
        <Input
          name="bathrooms"
          label="Baños"
          type="number"
          min={0}
          className="group-input"
          defaultValue={bathrooms}
        />
        <Input
          name="half_bathrooms"
          label="Medios baños"
          type="number"
          min={0}
          className="group-input"
          defaultValue={half_bathrooms}
        />
      </GroupContainer>
      <GroupContainer>
        <Input
          name="parking_lots"
          label="Estacionamiento"
          type="number"
          min={0}
          className="group-input"
          defaultValue={parking_lots}
        />
        <Input
          name="cellar"
          label="Bodegas"
          type="number"
          min={0}
          className="group-input"
          defaultValue={cellar}
        />
      </GroupContainer>

      <TextareaStyled
        name="model_description"
        label="Características del modelo"
        placeholder="Agrega más características del modelo, con qué está equipado, que tipo de acabados tiene, etc."
        defaultValue={model_description}
      />
      <SectionTitle>Detalle de la propiedad</SectionTitle>
      <RadioInputStyled
        name="ground_shape"
        value="REGULAR"
        defaultChecked={ground_shape === 'REGULAR'}
        required
        label="Terreno regular"
      />
      <RadioInputStyled
        name="ground_shape"
        value="IRREGULAR"
        defaultChecked={ground_shape === 'IRREGULAR'}
        required
        label="Terreno irregular"
      />
      <SectionTitle>Topografía</SectionTitle>
      <RadioInputStyled
        name="ground_relief"
        value="FLAT"
        defaultChecked={ground_relief === 'FLAT'}
        required
        label="Terreno plano"
      />
      <RadioInputStyled
        label="Terreno en desnivel"
        name="ground_relief"
        value="TILTED"
        defaultChecked={ground_relief === 'TILTED'}
        required
      />
    </div>
  )
}

Details.propTypes = {
  className: string,
  ground_shape: string,
  floors: number,
  bedrooms: number,
  bathrooms: number,
  half_bathrooms: number,
  parking_lots: number,
  cellar: number,
  model_description: string,
  ground_relief: string
}

export default Details
