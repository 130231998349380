import React, { useContext, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Grid, Typography, Spinner } from 'rc'

import {
  CheckboxControlStyled,
  FormControlLabelStyled
} from 'components/CommonStyled'
import { SimpleText } from 'modules/profile/components'
import ProfileSectionCard from 'modules/profile/components/ProfileSectionCard'
import { ProfileContext } from 'modules/profile'
import {
  ToggleProfileProperties,
  GetProfilePropertiesCount
} from 'reduxActions/profile'

import { FreemiumWrapper } from 'modules/freemium'
import { RULES } from 'modules/freemium/utils/constants'
import { useFreemium } from 'modules/freemium/hooks/useFreemium'

const ProfilePropertiesShownInProfile = ({
  token,
  roles,
  ToggleProfileProperties,
  GetProfilePropertiesCount
}) => {
  const idBase = 'profilePropertiesShownInProfile'
  const {
    profile: { profile_id, show_agency_properties_in_profile, associations },
    isMyProfile
  } = useContext(ProfileContext)
  const [propertiesCounter, setPropertiesCounter] = useState(null)
  const { validatePermission } = useFreemium()

  const getPropertiesCount = useCallback(async () => {
    setPropertiesCounter(null)
    try {
      const {
        payload: {
          data: { total_properties: totalProperties = 0 }
        }
      } = await GetProfilePropertiesCount(token, profile_id)
      setPropertiesCounter(totalProperties)
    } catch (error) {
      setPropertiesCounter(null)
    }
  }, [GetProfilePropertiesCount, profile_id, token])

  useEffect(() => {
    if (roles.AGENCY_BROKER || roles.AGENCY) {
      getPropertiesCount()
    }
  }, [getPropertiesCount])

  const toggleProperties = async (type, checked) => {
    try {
      await ToggleProfileProperties(token, {
        profile_id,
        type,
        is_enabled: checked
      })
      getPropertiesCount()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ flags: error })
    }
  }

  const hasPermission = validatePermission({
    section: RULES.PROFILE_PROPERTIES_SHOWN
  }).value

  return isMyProfile && roles.AGENCY_BROKER ? (
    <ProfileSectionCard
      title={`Propiedades que se mostrarán`}
      css="position: relative;"
    >
      <Grid container direction="column">
        <Typography
          variant="h2"
          css={`
            margin-bottom: ${({ theme }) => theme.remCalc('32')};
            display: flex;
            align-items: center;
            ${({ theme }) => theme.media.desktop`
              ${({ theme }) => theme.position('absolute', 20, 20)}
            `}
          `}
        >
          Total de propiedades:
          {propertiesCounter !== null ? (
            ` ${propertiesCounter}`
          ) : (
            <Spinner
              css={`
                ${({ theme }) => theme.box(25, 25)}
                margin-left: ${({ theme }) => theme.remCalc('3')};
              `}
            ></Spinner>
          )}
        </Typography>
        <Typography variant="h2" display="block">
          Elige las propiedades que quieres mostrar al compartir tu tarjeta
          digital:
        </Typography>
      </Grid>
      <Grid
        container
        css={`
          margin: ${({ theme }) => theme.remCalc('0 -12')};
        `}
      >
        <SimpleText
          css={`
            padding: ${({ theme }) => theme.remCalc('15 20 15 15')};
          `}
        >
          Mis propiedades opcionadas
        </SimpleText>
        <FreemiumWrapper section={RULES.PROFILE_PROPERTIES_SHOWN} custom>
          <FormControlLabelStyled
            control={
              <CheckboxControlStyled
                id={`${idBase}-checkbox-agency`}
                defaultChecked={show_agency_properties_in_profile}
                onChange={(e, checked) => toggleProperties('AGENCY', checked)}
              />
            }
            label={<SimpleText>Propiedades de mi inmobiliaria</SimpleText>}
            disabled={!hasPermission}
          />
        </FreemiumWrapper>
        {associations && Array.isArray(associations)
          ? associations.map(
              ({ name, id: associationId, is_enabled }, index) => {
                return (
                  <FreemiumWrapper
                    key={`${index}-${associationId}`}
                    section={RULES.PROFILE_PROPERTIES_SHOWN}
                    custom
                  >
                    <FormControlLabelStyled
                      control={
                        <CheckboxControlStyled
                          id={`${idBase}-checkbox-association-${index}`}
                          defaultChecked={is_enabled}
                          onChange={(e, checked) =>
                            toggleProperties(associationId, checked)
                          }
                        />
                      }
                      label={<SimpleText>Propiedades de {name}</SimpleText>}
                      disabled={!hasPermission}
                    />
                  </FreemiumWrapper>
                )
              }
            )
          : null}
      </Grid>
    </ProfileSectionCard>
  ) : null
}

ProfilePropertiesShownInProfile.propTypes = {
  ToggleProfileProperties: PropTypes.func,
  GetProfilePropertiesCount: PropTypes.func,
  token: PropTypes.string,
  roles: PropTypes.object
}

const mapStateToProps = ({ authReducer: { token, roles } }) => ({
  token,
  roles
})

const mapDispatchToProps = {
  ToggleProfileProperties,
  GetProfilePropertiesCount
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePropertiesShownInProfile)
