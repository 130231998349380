var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/FormInputs/DatePickerMonth.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePickerForMonth } from '../Dates';
import { default as InputLabelContainer } from './InputLabelContainer';

import { inputProp, inputLabelContainerProp } from './_types';
var DatePickerMonth = (_temp2 = _class = function (_Component) {
  _inherits(DatePickerMonth, _Component);

  function DatePickerMonth() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, DatePickerMonth);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = DatePickerMonth.__proto__ || Object.getPrototypeOf(DatePickerMonth)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      value: _this.props.input ? _this.props.input.value : _this.props.value ? _this.props.value : ''
    }, _this.onChangeCT = function (selectedDate) {
      var onChange = _this.props.onChange;

      if (onChange) {
        onChange(selectedDate);
      }
    }, _this.onChangeRF = function (selectedDate) {
      var onChange = _this.props.input.onChange;

      if (onChange) {
        onChange(selectedDate);
      }
    }, _this.inputProp = function () {
      var _this$props = _this.props,
          componentType = _this$props.componentType,
          disabled = _this$props.disabled;

      switch (componentType) {
        case 'CT':
          return {
            onDateChanged: _this.onChangeCT,
            value: _this.props.value,
            disabled: disabled
          };
        case 'RF':
          return {
            onDateChanged: _this.onChangeRF,
            value: _this.props.input.value,
            disabled: disabled
          };
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(DatePickerMonth, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          label = _props.label,
          labelProps = _props.labelProps;


      return React.createElement(
        InputLabelContainer,
        Object.assign({ label: label }, labelProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 81
          },
          __self: this
        }),
        React.createElement(DatePickerForMonth, Object.assign({}, this.inputProp(), {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 82
          },
          __self: this
        }))
      );
    }
  }]);

  return DatePickerMonth;
}(Component), _class.propTypes = {
  /** */
  changeCallback: PropTypes.func,
  /** Component Type (helper for redux form). */
  componentType: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** Validation when component is RF. */
  input: inputProp,
  /** */
  label: PropTypes.string,
  /** */
  labelProps: inputLabelContainerProp,
  /** */
  name: PropTypes.string,
  /** */
  onChange: PropTypes.func,
  /** Text Align */
  right: PropTypes.bool,
  /** */
  value: PropTypes.any
}, _class.defaultProps = {
  inputWithChildren: true,
  disabled: false,
  label: ''
}, _temp2);


export default DatePickerMonth;