import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g>
      <path
        d="M19.9035 21.2129H18.1917C17.6123 21.2129 17.1429 20.7865 17.1429 20.2605C17.1429 19.7345 17.6125 19.3082 18.1917 19.3082H19.9035C20.4829 19.3082 20.9524 19.7345 20.9524 20.2605C20.9524 20.7864 20.4829 21.2129 19.9035 21.2129Z"
        fill="#ffffff"
      />
      <path
        d="M24.3479 21.2129H22.6362C22.0568 21.2129 21.5873 20.7865 21.5873 20.2605C21.5873 19.7345 22.0569 19.3082 22.6362 19.3082H24.3479C24.9273 19.3082 25.3968 19.7345 25.3968 20.2605C25.3968 20.7864 24.9272 21.2129 24.3479 21.2129Z"
        fill="#ffffff"
      />
      <path
        d="M32.4565 20.2605L34.5763 18.1405C35.0354 17.6814 35.0354 16.9369 34.5763 16.4778C34.1173 16.0187 33.3731 16.0187 32.9135 16.4778L30.7936 18.5977L28.6737 16.4778C28.2145 16.0187 27.4703 16.0187 27.011 16.4778C26.5519 16.9369 26.5519 17.6814 27.011 18.1405L29.1308 20.2605L27.011 22.3804C26.5519 22.8395 26.5519 23.584 27.011 24.0431C27.2406 24.2727 27.5415 24.3875 27.8423 24.3875C28.1431 24.3875 28.444 24.2726 28.6736 24.0431L30.7935 21.9231L32.9133 24.0431C33.143 24.2727 33.4438 24.3875 33.7447 24.3875C34.0455 24.3875 34.3464 24.2726 34.576 24.0431C35.0351 23.584 35.0351 22.8395 34.576 22.3804L32.4565 20.2605Z"
        fill="#ffffff"
      />
      <path
        d="M6.51508 23.0778C6.23785 23.3551 6.23785 23.8046 6.51508 24.0816C6.79236 24.3589 7.24174 24.3589 7.51897 24.0816C8.2742 23.3264 9.50338 23.3264 10.2586 24.0816C10.3973 24.2202 10.579 24.2896 10.7606 24.2896C10.9423 24.2896 11.1239 24.2203 11.2626 24.0816C11.5398 23.8045 11.5398 23.355 11.2626 23.0778C9.95369 21.7689 7.824 21.7689 6.51508 23.0778Z"
        fill="#ffffff"
      />
      <path
        d="M4.43459 20.9973C4.15738 21.2745 4.15738 21.7239 4.43459 22.0011C4.71181 22.2782 5.16118 22.2784 5.43851 22.0011C7.34102 20.0986 10.4368 20.0986 12.3392 22.0011C12.4778 22.1396 12.6596 22.209 12.8412 22.209C13.0228 22.209 13.2046 22.1397 13.3432 22.0011C13.6204 21.7239 13.6204 21.2744 13.3432 20.9973C10.8871 18.5413 6.89072 18.5413 4.43459 20.9973Z"
        fill="#ffffff"
      />
      <path
        d="M15.6651 18.6754C11.9286 14.9389 5.84908 14.9389 2.11268 18.6754C1.83546 18.9526 1.83546 19.4021 2.11268 19.6792C2.38998 19.9564 2.83936 19.9564 3.11657 19.6792C4.65833 18.1373 6.70831 17.2883 8.88882 17.2883C11.0694 17.2883 13.1194 18.1374 14.6611 19.6792C14.7998 19.8177 14.9815 19.8871 15.1631 19.8871C15.3447 19.8871 15.5265 19.8178 15.6651 19.6792C15.9423 19.4021 15.9423 18.9526 15.6651 18.6754Z"
        fill="#ffffff"
      />
    </g>
  </Fragment>,
  'Unlinked',
  '0 0 40 40'
)
