import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ShowModal } from 'reduxActions/services'
import { TokenStatus } from 'reduxActions/auth'
import { ActivateTrialPlan } from 'reduxActions/freemium'
import { GetPersistKey } from 'reduxActions/general'

import { PlansMC } from 'components'
import { VARIANTS } from 'components/PlansModal/constants'

export const usePlans = onlyRequests => {
  const dispatch = useDispatch()
  const {
    token,
    planAvailableUntil,
    planType,
    oldRoles,
    trialUsed,
    fullPlanUsed,
    roles
  } = useSelector(
    ({
      authReducer: { token, roles },
      profileReducer: {
        planAvailableUntil,
        planType,
        oldRoles,
        trialUsed,
        fullPlanUsed
      },
      addonsReducer: { initialLoadOfActives, actives }
    }) => ({
      token,
      roles,
      planAvailableUntil,
      planType,
      oldRoles,
      trialUsed,
      fullPlanUsed
    })
  )

  const advanced = 'ADVANCED'
  // const starter = 'STARTER'
  // const trial = 'TRIAL_ADVANCED'

  const handleModal = (modalProps, extraProps = {}, validateToken = true) => {
    if (validateToken) {
      dispatch(TokenStatus(token))
        .then(() => {
          dispatch(ShowModal(PlansMC, modalProps, {...extraProps, isLocked: true }))
        })
    } else {
      dispatch(ShowModal(PlansMC, modalProps, {...extraProps, isLocked: true }))
    }
  }

  const activateTrialPlan = () =>
    dispatch(ActivateTrialPlan(token))
      .then(_res => {
        handleModal({ variant: VARIANTS.NEW_WIGGOT_USER.name }, {}, false)
      })
      .catch(err => console.log({ err }))

  const formatDate = dateString => {
    if (dateString) {
      const date = new Date(dateString)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    }

    return '-'
  }

  const getChoice = async (keyName, cb) =>
    await dispatch(GetPersistKey(token, keyName)).then(({ payload } = {}) => {
      !payload?.data?.value && cb()
    })

  useEffect(() => {
    if (!onlyRequests && !window.location.href.includes('privacy')) {
      if (token && planType === advanced && oldRoles && roles.ADVANCED) {
        getChoice(VARIANTS.PLAN_UPGRADE.name, () =>
          handleModal({
            variant: VARIANTS.PLAN_UPGRADE.name,
            availableUntil: formatDate(planAvailableUntil)
          })
        )
      }

      // if (token && !trialUsed && !oldRoles && planType === trial)
      //   getChoice(VARIANTS.NEW_WIGGOT_USER.name, () =>
      //     handleModal({
      //       variant: VARIANTS.NEW_WIGGOT_USER.name
      //     })
      //   )

      if (
        token &&
        !planType &&
        trialUsed &&
        !fullPlanUsed &&
        !roles.ADVANCED &&
        !roles.STARTER
      ) {
        handleModal({
          variant: VARIANTS.ADVANCED_TRIAL_ENDED.name
        })
      }
      if (
        token &&
        !planType &&
        fullPlanUsed &&
        !roles.ADVANCED &&
        !roles.STARTER
      ) {
        handleModal({
          variant: VARIANTS.NO_ACTIVE_PLAN.name
        })
      }
      // if (token && planType === trial && !fullPlanUsed) {
      //   getChoice(VARIANTS.NEW_WIGGOT_USER.name, () =>
      //     handleModal({
      //       variant: VARIANTS.NEW_WIGGOT_USER.name
      //     })
      //   )
      // }
    }
  }, [token, planType])

  return { activateTrialPlan }
}
