var _jsxFileName = 'src/lib/generics/Previewers/Empty.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../elements/Texts';
import { Image } from '../Images';
import classNames from 'classnames';

var BASE_CLASSNAME = 'previewers';
var Empty = function Empty(_ref) {
  var height = _ref.height,
      width = _ref.width,
      urlImage = _ref.urlImage,
      label = _ref.label,
      broken = _ref.broken,
      matterport = _ref.matterport;

  var textClasses = classNames(BASE_CLASSNAME + '__container__adjust__text', _defineProperty({}, BASE_CLASSNAME + '__container__adjust__text-broken', broken));
  var textBrokenClasses = classNames(BASE_CLASSNAME + '__container__adjust__text', BASE_CLASSNAME + '__container__adjust__text-broken', BASE_CLASSNAME + '__container__adjust__text-b');
  return React.createElement(
    'div',
    {
      className: BASE_CLASSNAME + '__container',
      style: { height: height, width: width },
      __source: {
        fileName: _jsxFileName,
        lineNumber: 18
      },
      __self: _this
    },
    React.createElement(
      'div',
      { className: BASE_CLASSNAME + '__container__adjust', __source: {
          fileName: _jsxFileName,
          lineNumber: 22
        },
        __self: _this
      },
      React.createElement(Image, {
        wrapperClassName: BASE_CLASSNAME + '__container__adjust__image' + (!matterport && broken ? '-youtube' : '') + (matterport && !broken ? '-Matterport' : ''),
        src: urlImage,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 23
        },
        __self: _this
      }),
      React.createElement(
        Text,
        { className: textClasses, __source: {
            fileName: _jsxFileName,
            lineNumber: 29
          },
          __self: _this
        },
        label
      ),
      broken && React.createElement(
        'div',
        {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 31
          },
          __self: _this
        },
        React.createElement(
          Text,
          { className: textBrokenClasses, __source: {
              fileName: _jsxFileName,
              lineNumber: 32
            },
            __self: _this
          },
          '  ' + (!matterport ? 'Revisa que la liga de tu video esté bien escrita; sólo puedes cargar videos que estén en' : 'Revisa que la liga de tu recorrido virtual esté bien escrita; sólo puedes cargar urls de') + ' ' + (!matterport ? 'youtube.com, por el momento no aceptamos videos de Facebook ni de otros sitios.' : 'Matterport.com o Cloudpano.com')
        )
      )
    )
  );
};

Empty.propTypes = {
  /** */
  broken: PropTypes.bool,
  /** */
  height: PropTypes.string,
  /** */
  label: PropTypes.string,
  /** */
  matterport: PropTypes.bool,
  /** */
  urlImage: PropTypes.string,
  /** */
  width: PropTypes.string
};

export default Empty;