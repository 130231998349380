import React, { useContext, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Image as LibImage } from 'rc'

import { Image, Button } from 'components'
import { ProfileContext } from 'modules/profile'
import CoverImageHolder from 'static/img/profile-cover-holder.jpg'

import { Camera, UserProfile, StarRounded } from 'rc/Icons'
import { connect } from 'react-redux'
import { ShowModal, HideModal } from 'reduxActions/services'
import { UpdateProfile } from 'reduxActions/profile'
import { CropImageMC } from 'modules/profile/modalContent'
import { SCORES_SECTION_CONFIG } from 'utils/constants'

const ImageContainerStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.palette.base.white};
  overflow: hidden;
  margin: 0 auto;
  border-radius: 100%;
  ${({ theme }) => theme.bordered(8, theme.palette.base.white)}
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #97a4b1;
`

const ProfileCover = ({ token, ShowModal, HideModal, UpdateProfile }) => {
  const {
    profile: {
      user_photo,
      user_background_image,
      scores = [],
      user_scores_section_config
    } = {},
    profile,
    isMyProfile,
    customChange
  } = useContext(ProfileContext)
  const fileInput = useRef()
  const [customFile, setCustomFile] = useState({
    name: '',
    file: null
  })
  const showScore = user_scores_section_config !== SCORES_SECTION_CONFIG.HIDDEN

  const showCroppingModal = field => {
    const handleImageSelected = (imageId, field) => {
      UpdateProfile(token, { [field]: imageId }).then(response => {
        setCustomFile({ name: '', file: null })
        customChange({ [field]: imageId })

        HideModal()
      })
    }

    ShowModal(
      CropImageMC,
      {
        onImageSelected: imageId => handleImageSelected(imageId, field),
        imageId: profile[field],
        customFile: customFile.file,
        aspect: field === 'user_photo' ? 1 : 1080 / 250,
        cropShape: field === 'user_photo' ? 'round' : null,
        type: field === 'user_photo' ? 'avatar' : 'photo'
      },
      { noClosingButton: false }
    )
  }

  useEffect(() => {
    if (customFile.name && customFile.file) {
      showCroppingModal(customFile.name)
    }
  }, [customFile])

  const handleUploadPhoto = field => {
    if (profile[field]) showCroppingModal(field)
    else {
      setCustomFile({ name: field })
      if (fileInput && fileInput.current) {
        fileInput.current.click()
      }
    }
  }

  const onChange = e => {
    e.stopPropagation()
    e.preventDefault()
    const {
      target: { files = [] }
    } = e
    if (files.length > 0) setCustomFile({ ...customFile, file: files[0] })
  }

  return (
    <Grid
      container
      center
      css={`
        position: relative;
        height: ${({ theme }) => theme.remCalc('350')};
        ${({ theme }) => theme.media.desktop`
          height: ${({ theme }) => theme.remCalc('250')};
        `}
      `}
    >
      {user_background_image ? (
        <Image
          id={user_background_image}
          objectFit="cover"
          size="l"
          alt="profile-cover-img"
        />
      ) : (
        <LibImage src={CoverImageHolder} />
      )}
      {isMyProfile && (
        <Button
          id="profile-profileCover-button-showUploadCoverPhotoModal"
          variant="outlined"
          css={`
            ${({ theme }) => theme.position('absolute', 10, null, null, 10)}
          `}
          onClick={() => handleUploadPhoto('user_background_image')}
        >
          <Camera
            css={`
              &&& {
                margin: ${({ theme }) => theme.remCalc('0 5 0 0')};
              }
            `}
          />
          Cambiar foto de portada
        </Button>
      )}
      <input
        id="profile-profileCover-input-uploadphotoField"
        ref={fileInput}
        type="file"
        accept="image/*"
        css={`
          display: none;
        `}
        onChange={onChange}
      />
      <Grid
        css={`
          ${({ theme }) => theme.box(200, 200)}
          ${({ theme }) => theme.position('absolute', 100)}
        `}
        container
        justify="center"
      >
        <ImageContainerStyled>
          {user_photo ? (
            <Image
              id={user_photo}
              objectFit="cover"
              size="l"
              alt="profile-img-thumbnail"
            />
          ) : (
            <UserProfile
              css={`
                ${({ theme }) => theme.position('absolute', 20, '10%')}
                width: 80%;
              `}
            />
          )}
        </ImageContainerStyled>
        {showScore && (
          <div
            hasScore={scores.length > 0}
            css={`
              ${({ theme }) => theme.position('absolute', 16, null, null, 135)}
              height: ${({ theme }) => theme.remCalc('42')};
              background-color: ${({ theme }) => theme.palette.primary.dark};
              padding: ${({ theme }) => theme.remCalc('0 15')};
              display: inline-flex;
              border-radius: 21px;
              align-items: center;
              width: max-content;
              ${({ theme }) =>
                theme.typography.buildTextVariant({
                  ...theme.typography.h2,
                  color: theme.palette.base.white,
                  textTransform: 'initial'
                })}
              ${({ theme }) => theme.bordered('4', theme.palette.base.white)}
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

              ${({ theme }) => theme.media.tabletDown`
              ${({ theme, hasScore }) =>
                theme.position(
                  'absolute',
                  hasScore ? 16 : 0,
                  null,
                  null,
                  hasScore ? 130 : 0
                )}
            `}
            `}
          >
            <StarRounded
              css={`
                width: ${({ theme }) => theme.remCalc('18')};
                margin-right: ${({ theme }) => theme.remCalc('8')};
                margin-top: ${({ theme }) => theme.remCalc('-2')};
              `}
            ></StarRounded>
            <span>
              {scores.length > 0
                ? Number(
                    scores.reduce((previous, current) => {
                      return previous + current.score
                    }, 0) / scores.length
                  ).toFixed(1)
                : 'Sin calificación'}
            </span>
          </div>
        )}
        {isMyProfile && (
          <Button
            id="profile-profileCover-button-showUploadUserPhotoModal"
            onClick={() => handleUploadPhoto('user_photo')}
            css={`
              ${({ theme }) => theme.position('absolute', null, null, -30)}
              ${({ theme }) => theme.box(60, 60)}
            `}
            radius="100%"
          >
            <Camera
              css={`
                &&& {
                  ${({ theme }) => theme.box(60, 30)}
                }
              `}
            />
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

ProfileCover.propTypes = {
  token: PropTypes.string,
  UpdateProfile: PropTypes.func,
  ShowModal: PropTypes.func,
  HideModal: PropTypes.func
}

const mapStateToProps = ({ authReducer: { token } }) => ({ token })

const mapDispatchToProps = {
  ShowModal,
  HideModal,
  UpdateProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCover)
