var _jsxFileName = 'src/lib/elements/Buttons/DeleteButton.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../generics/Buttons';

/**
 * Shows a button with deletion purpose.
 */
var DeleteButton = function DeleteButton(_ref) {
  var id = _ref.id,
      children = _ref.children,
      onClick = _ref.onClick,
      className = _ref.className;

  return React.createElement(
    Button,
    { buttonDelete: true, id: id, danger: true, onClick: onClick, className: className, __source: {
        fileName: _jsxFileName,
        lineNumber: 11
      },
      __self: _this
    },
    children
  );
};

DeleteButton.propTypes = {
  /** Text to be displayed in button. */
  children: PropTypes.string,
  /** ClassName to add custom styles. */
  className: PropTypes.string,
  /** Used to identify button. */
  id: PropTypes.string,
  /** Handler of click action on button. */
  onClick: PropTypes.func
};

export default DeleteButton;