import React, { useCallback, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Grid, withForm, SubmitButton } from 'rc'
import { Information, Plus, BarCode } from 'rc/Icons'
import { Section, Button } from 'components/'
import { SendIcon } from 'components/Icons'
import {
  ShareDevelopmentsMC,
  BuyTokensFormMC
} from 'modules/development/modalContent'
import { ShowModal } from 'reduxActions/services'
import { useDevice } from 'modules/searcher/filters/components'
import { FreemiumWrapperStyled } from 'modules/propertyDetail/actions/CommonStyles'
import { SearchByCodeMC } from 'components/ModalContent'
import DevelopmentDetailContext from 'modules/development/developmentDetail/DevelopmentDetailContext'
import FreemiumWrapper from 'modules/freemium/FreemiumWrapper'
import { RULES } from 'modules/freemium/utils/constants'

const TabBar = ({
  account,
  alignCenter,
  tabs: allTabs = [],
  center,
  customSize,
  section,
  setStep,
  currentStep,
  isForm,
  list,
  isPublished,
  userOwned,
  myDevelopments,
  fc: { values: { property_id } = {} } = {},
  title,
  stepFieldsErr,
  onLoadProperties,
  changeFilter,
  sections,
  promotions,
  tableSelectValue,
  onLoadRequirements,
  goToUploadRequirement,
  match,
  ownerId,
  extraFilter,
  customWidth,
  ...props
}) => {
  const dispatch = useDispatch()
  const { isUnitsEmpty } = useContext(DevelopmentDetailContext) || {}
  const tabs = allTabs.filter(t => t.show)
  const isDevice = useDevice('tabletDown')
  const history = useHistory()
  const { modelId, developmentId } = useParams()
  const centered = tabs && tabs.length === 1
  const [flagActive, setFlagActive] = useState(false)
  const roles = useSelector(({ authReducer: { roles } }) => roles)

  const handleUploadNewDevelopment = () => {
    if (!roles.DEVELOPER && !roles.ROLE_ADMIN_DEVELOPER)
      dispatch(ShowModal(BuyTokensFormMC, {}))
    else history.push(`/uploadDevelopmentNew`)
  }
  const shareDevelopment = units =>
    dispatch(
      ShowModal(ShareDevelopmentsMC, {
        developmentId,
        title,
        units,
        userOwned: userOwned ? 1 : 0,
        ownerId
      })
    )

  const currentTab = tabs[currentStep - 1]
  const customAction = currentTab && currentTab.action
  const customActionComponent = currentTab && currentTab.customActionComponent
  const handleAddProperties = useCallback(onLoadProperties)

  const handleChangeFilter = useCallback(changeFilter)

  const handleGoToUploadRequirement = useCallback(goToUploadRequirement)

  useEffect(() => {
    if (window.location.hash === '#expiring-properties') {
      setFlagActive(true)
    }
  }, [])
  return (
    <>
      <HeaderBar
        container
        justify={center ? 'center' : 'space-between'}
        alignItems="center"
        customWidth={customWidth}
        alignCenter={alignCenter}
      >
        <div
          css={`
            display: flex;
            ${!center
              ? 'flex: 1;'
              : `justify-content: ${centered ? 'center' : 'space-between'};`}
            ${customSize && `width: ${customSize}px;`}
          `}
        >
          {tabs.map((tab, index) => {
            const { requiredFields = [] } = tab
            const required = requiredFields.filter(field =>
              stepFieldsErr.includes(field)
            )

            return (
              tab.show && (
                <FreemiumWrapperStyled
                  key={index}
                  section={tab.freemiumSection}
                  label={tab.label}
                  css={`
                    width: fit-content;
                    margin: ${({ theme }) => theme.remCalc('0 10')};
                    border-radius: ${({ theme }) =>
                      theme.remCalc('5')} !important;
                  `}
                >
                  <Tab
                    active={
                      flagActive ? 3 === index + 1 : currentStep === index + 1
                    }
                    onClick={() => {
                      setFlagActive(false)
                      setStep(index + 1)
                      tab.cb && tab.cb()
                      if (tab.properties) {
                        handleChangeFilter(tab.tableName, tab.id)
                      }
                      if (tab.development) {
                        return history.replace({
                          pathname: `/development/${developmentId}`
                        })
                      }
                      if (tab.model) {
                        if (match) {
                          if (currentStep > 2) {
                            return setStep(index + 1)
                          }
                          return history.replace({
                            pathname: `/development/${developmentId}`,
                            state: {
                              modelsList: true
                            }
                          })
                        }
                        return history.replace({
                          pathname: `/development/${developmentId}`
                        })
                      }
                    }}
                  >
                    <Typography
                      variant="subtitle3"
                      color={
                        required.length > 0
                          ? 'palette.tag.dangerBackground'
                          : 'palette.new.text'
                      }
                    >
                      {tab.label}
                      {tab.id === 'EXPIRING_PROPERTIES' &&
                        sections &&
                        sections.expiring > 0 && (
                          <TotalLabel>{sections.expiring}</TotalLabel>
                        )}
                      {tab.id === 'PENDING' &&
                        sections &&
                        sections.pending > 0 && (
                          <TotalLabel>{sections.pending}</TotalLabel>
                        )}
                    </Typography>
                    {required.length > 0 && (
                      <span
                        css={`
                          ${({ theme }) => theme.box(15, 15, true)}
                          color: #fff;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          margin-left: ${({ theme }) => theme.remCalc('5')};
                          font-size: ${({ theme }) => theme.remCalc('12')};
                          background-color: ${({ theme }) =>
                            theme.palette.tag.dangerBackground};
                        `}
                      >
                        !
                      </span>
                    )}
                  </Tab>
                </FreemiumWrapperStyled>
              )
            )
          })}
        </div>
        {!center && !isDevice ? (
          list ? (
            customAction && customAction[0] ? (
              customAction.map((action, index) => {
                return (
                  <ButtonStyled
                    key={`button-action-custom-${index}`}
                    type={action.type || 'primary'}
                    variant={action.variant || 'standard'}
                    onClick={() => action.action()}
                    css={`
                      ${!action.label &&
                      action.icon &&
                      'min-width: auto !important;'}
                    `}
                  >
                    {action.icon}
                    {action.label}
                  </ButtonStyled>
                )
              })
            ) : customActionComponent ? (
              customActionComponent
            ) : userOwned || myDevelopments ? (
              <ButtonStyled
                type="primary"
                onClick={() => handleUploadNewDevelopment()}
              >
                <PlusIconStyled />
                Cargar desarrollo
              </ButtonStyled>
            ) : (
              ''
            )
          ) : currentStep === 2 && !modelId && userOwned ? (
            <>
              {isPublished && currentStep < 3 && (
                <ButtonStyled type="primary" onClick={shareDevelopment}>
                  <SendIcon
                    css={`
                      ${({ theme }) => theme.box(15, 15)}
                    `}
                  />
                  Compartir ficha
                </ButtonStyled>
              )}
              <ButtonStyled
                type="primary"
                onClick={() =>
                  history.push(`/development/${developmentId}/uploadModel`)
                }
              >
                Cargar modelos
              </ButtonStyled>
            </>
          ) : (
            <>
              {isPublished && currentStep < 3 && currentStep !== 2 && (
                <ButtonStyled type="primary" onClick={shareDevelopment}>
                  <SendIcon
                    css={`
                      ${({ theme }) => theme.box(15, 15)}
                    `}
                  />
                  Compartir ficha
                </ButtonStyled>
              )}
              {userOwned && !customAction ? (
                <ButtonStyled
                  type="primary"
                  onClick={() =>
                    history.push(
                      modelId && currentStep > 1
                        ? `/development/${developmentId}/uploadModel?modelId=${modelId}`
                        : `/uploadDevelopmentNew?development_id=${developmentId}`
                    )
                  }
                >
                  Editar {modelId && currentStep > 1 ? 'modelo' : 'desarrollo'}
                </ButtonStyled>
              ) : (customAction && (account || promotions)) || customAction ? (
                customAction.map((action, index) =>
                  userOwned ? (
                    <ButtonStyled
                      key={`button-action-custom-${index}-owned`}
                      type={action.type || 'primary'}
                      onClick={() =>
                        action.label === 'Compartir ficha'
                          ? action.action(shareDevelopment)
                          : action.action()
                      }
                      disabled={
                        action.label === 'Compartir lista'
                          ? isUnitsEmpty
                          : action.disabled
                      }
                    >
                      {action.icon}
                      {action.label}
                    </ButtonStyled>
                  ) : action.show ? (
                    <FreemiumWrapper
                      section={action.freemium}
                      custom
                      key={`button-action-custom-${index}-noOwned`}
                    >
                      <ButtonStyled
                        key={index}
                        type={action.type || 'primary'}
                        onClick={() => action.action(shareDevelopment)}
                        disabled={
                          action.label === 'Compartir lista'
                            ? isUnitsEmpty
                            : action.disabled
                        }
                        css={`
                          ${!action.label &&
                          action.icon &&
                          'min-width: auto !important;'}
                        `}
                      >
                        {action.icon}
                        {action.label}
                      </ButtonStyled>
                    </FreemiumWrapper>
                  ) : currentStep === 2 && customAction ? (
                    customAction.map((action, index) => (
                      <FreemiumWrapper
                        key={`tabBar-action-custom-${index}-${currentStep}`}
                        section={action.freemium}
                        custom
                      >
                        <ButtonStyled
                          type={action.type || 'primary'}
                          onClick={() => action.action(shareDevelopment)}
                          disabled={action.disabled}
                        >
                          {action.icon}
                          {action.label}
                        </ButtonStyled>
                      </FreemiumWrapper>
                    ))
                  ) : (
                    ''
                  )
                )
              ) : currentStep === 2 && customAction ? (
                customAction.map((action, index) => (
                  <FreemiumWrapper
                    section={action.freemium}
                    custom
                    key={`tabBar-action-custom-${index}`}
                  >
                    <ButtonStyled
                      type={action.type || 'primary'}
                      onClick={() => action.action(shareDevelopment)}
                      disabled={action.disabled}
                    >
                      {action.icon}
                      {action.label}
                    </ButtonStyled>
                  </FreemiumWrapper>
                ))
              ) : (
                ''
              )}
            </>
          )
        ) : (
          ''
        )}

        {onLoadProperties ? (
          <>
            <ButtonStyled
              variant="outlined"
              onClick={() =>
                dispatch(ShowModal(SearchByCodeMC, { from: 'PROPERTIES' }))
              }
            >
              <BarCode
                css={`
                  margin: ${({ theme }) => theme.remCalc('0 5 0 0')};
                `}
              />
              Buscar por ID
            </ButtonStyled>
            <ButtonStyled type="primary" onClick={handleAddProperties}>
              Cargar propiedad
            </ButtonStyled>
          </>
        ) : (
          ''
        )}
        {onLoadRequirements && (
          <FreemiumWrapper
            section={RULES.REQUIREMENTS_CREATE}
            label={'Crear requerimiento'}
          >
            <ButtonStyled
              id="requirements-module-button-createRequirement"
              type="primary"
              size="large"
              onClick={handleGoToUploadRequirement}
            >
              Crear requerimiento
            </ButtonStyled>
          </FreemiumWrapper>
        )}
      </HeaderBar>
      <SectionContainer customWidth={customWidth} alignCenter={alignCenter}>
        {['/portal/properties', '/properties'].includes(
          window.location.pathname
        ) &&
          ['EXPIRING_PROPERTIES', 'DELETED_RECENTLY'].includes(
            tableSelectValue
          ) && (
            <BannerExperingProperty>
              <div
                css={`
                  width: ${({ isDesktop }) => (isDesktop ? '75%' : '100%')};
                `}
              >
                {tableSelectValue === 'EXPIRING_PROPERTIES' ? (
                  <>
                    Estas propiedades tienen <b>45 días o más sin actividad</b>.
                    Puedes renovarlas si siguen disponibles o, de lo contrario,
                    archivarlas.
                    <br />
                  </>
                ) : (
                  tableSelectValue === 'DELETED_RECENTLY' && (
                    <>
                      Las propiedades eliminadas se mostrarán en esta sección
                      por 30 días. Después de este plazo serán eliminadas
                      permanentemente y no podrás recuperarlas. <br />
                    </>
                  )
                )}
              </div>
              <Information
                css={`
                  height: ${({ theme }) => theme.remCalc('22')};
                  width: ${({ theme }) => theme.remCalc('22')};
                  margin: auto ${({ theme }) => theme.remCalc('15')};
                `}
              />
            </BannerExperingProperty>
          )}
        {window.location.pathname.includes('/agency/mysite') && (
          <div
            style={{
              padding: '0 2.5rem',
              maxWidth: 1024,
              width: '100%',
              paddingBottom: 24
            }}
          >
            <BannerSiteWeb>
              <div
                style={{
                  position: 'absolute',
                  transform: 'translate(-92%, -72%)',
                  zIndex: 2
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={33}
                  height={36}
                  viewBox="0 0 33 36"
                  fill="none"
                >
                  <g mask="url(#mask0_3611_142753)">
                    <path
                      d="M6.70365 26.1321C-9.65677 33.7209 -17.5887 19.9825 -2.83635 9.60832C-10.4251 -6.7521 3.31328 -14.684 13.6874 0.0683098C30.0453 -7.52117 37.9798 6.21796 23.2274 16.5921C30.819 32.9423 17.0752 40.8837 6.70365 26.1321Z"
                      fill="#388E3C"
                    />
                  </g>
                </svg>
              </div>
              <div
                style={{
                  position: 'absolute',
                  transform: 'translate(0%, 15%)',
                  bottom: 0,
                  right: 0,
                  zIndex: 2
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={35}
                  height={36}
                  viewBox="0 0 35 36"
                  fill="none"
                >
                  <mask
                    id="mask0_3611_142730"
                    style={{ maskType: 'luminance' }}
                    maskUnits="userSpaceOnUse"
                    x={0}
                    y={0}
                    width={42}
                    height={42}
                  >
                    <path d="M41.5 0.5H0.5V41.5H41.5V0.5Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_3611_142730)">
                    <path
                      d="M18.936 1.75637C19.8022 0.0812102 22.1978 0.0812102 23.0639 1.75637L28.5795 12.4237C28.8005 12.8512 29.1488 13.1995 29.5764 13.4205L40.2436 18.936C41.9188 19.8022 41.9188 22.1978 40.2436 23.0639L29.5764 28.5795C29.1488 28.8005 28.8005 29.1488 28.5795 29.5764L23.0639 40.2436C22.1978 41.9188 19.8022 41.9188 18.936 40.2436L13.4205 29.5764C13.1995 29.1488 12.8512 28.8005 12.4237 28.5795L1.75637 23.0639C0.0812102 22.1978 0.0812102 19.8022 1.75637 18.936L12.4237 13.4205C12.8512 13.1995 13.1995 12.8512 13.4205 12.4237L18.936 1.75637Z"
                      fill="#EF5350"
                    />
                  </g>
                </svg>
              </div>
              <h2
                style={{
                  fontSize: '1.5rem',
                  margin: 0
                }}
              >
                ¡Descubre los nuevos diseños de sitios web que tenemos para ti!
              </h2>
            </BannerSiteWeb>
          </div>
        )}
        {currentTab && currentTab.section}
        {center && (
          <Section minHeight={100}>
            <div
              css={`
                display: flex;
                justify-content: center;
                flex: 1;
                align-items: center;
              `}
            >
              <ButtonStyled
                type="tertiary"
                onClick={() => currentStep !== 1 && setStep(currentStep - 1)}
              >
                Atrás
              </ButtonStyled>
              {isForm && currentStep === tabs.length ? (
                <SubmitButton
                  css={`
                    padding: ${({ theme }) => theme.remCalc('16 0')};
                    width: ${({ theme }) => theme.remCalc('150')};
                    border-radius: ${({ theme }) => theme.remCalc('25')};
                  `}
                >
                  Finalizar
                </SubmitButton>
              ) : (
                <ButtonStyled
                  type="primary"
                  onClick={() =>
                    currentStep !== tabs.length && setStep(currentStep + 1)
                  }
                >
                  Siguiente
                </ButtonStyled>
              )}
            </div>
          </Section>
        )}
        {extraFilter && (
          <ContainerExtraFilter>{extraFilter}</ContainerExtraFilter>
        )}
      </SectionContainer>
    </>
  )
}

TabBar.propTypes = {
  account: PropTypes.bool,
  action: PropTypes.array,
  alignCenter: PropTypes.bool,
  center: PropTypes.bool,
  currentStep: PropTypes.number,
  customSize: PropTypes.number,
  customWidth: PropTypes.number,
  fc: PropTypes.object,
  section: PropTypes.any,
  setStep: PropTypes.func,
  tabs: PropTypes.array,
  LastElement: PropTypes.any,
  list: PropTypes.bool,
  isPublished: PropTypes.bool,
  userOwned: PropTypes.bool,
  myDevelopments: PropTypes.bool,
  isForm: PropTypes.bool,
  stepFieldsErr: PropTypes.array,
  title: PropTypes.string,
  onLoadProperties: PropTypes.func,
  changeFilter: PropTypes.func,
  sections: PropTypes.object,
  onLoadRequirements: PropTypes.bool,
  goToUploadRequirement: PropTypes.func,
  freemiumSection: PropTypes.object,
  totalTableElements: PropTypes.number
}

const HeaderBar = styled(Grid)`
  background-color: white;
  height: 60px;
  padding: 0 15px 0 10px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.remCalc('5')};
  width: ${({ theme, customWidth }) =>
    customWidth ? theme.remCalc(`${customWidth}`) : '100%'};
  max-width: 100%;
  margin: ${({ alignCenter }) => (alignCenter ? '0 auto' : '0')};
  ${({ theme }) => theme.media.tabletDown`
    z-index: 1;
  `}
`
const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : ''};
  h6 {
    ${({ active }) => (active ? 'color: white !important' : null)};
  }
`
const PlusIconStyled = styled(Plus)`
  margin-right: ${({ theme }) => theme.remCalc('8')};
  ${({ theme }) => theme.box(12, 12)}
`
const ButtonStyled = styled(Button).attrs({ rounded: true })`
  min-width: ${({ theme }) => theme.remCalc('150')};
  margin-right: ${({ theme }) => theme.remCalc('10')};
  min-height: ${({ theme }) => theme.remCalc('40')};
  &:last-child {
    margin-right: 0;
  }
`
const TotalLabel = styled.span`
  background: #fd7171;
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  text-align: center;
  width: 27px;
`

const BannerSiteWeb = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #03070a;
  color: #fff;
  padding: 30.5px;
  ${({ theme }) => theme.radius(12)}
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`

const BannerExperingProperty = styled.div`
  background-color: #fff1cc;
  color: #2c2c3f;
  border: 1px solid #ffbc08;
  padding: ${({ theme }) => theme.remCalc('10 5')};
  ${({ theme }) => theme.radius(5)}
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-bottom: 12px;
`

const SectionContainer = styled(Grid).attrs({
  container: true,
  item: true,
  xsAuto: true,
  direction: 'column',
  justify: 'center'
})`
  overflow-y: scroll;
  width: ${({ theme, customWidth }) =>
    customWidth ? theme.remCalc(`${customWidth}`) : '100%'};
  max-width: 100%;
  margin: ${({ alignCenter }) => (alignCenter ? '0 auto' : '0')};
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
`

const ContainerExtraFilter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export default withForm(TabBar)
