'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var capitalizeFirstLetter = function capitalizeFirstLetter(data) {
  var string = data.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};
var isJSON = function isJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
var commaSeparator = function commaSeparator(val) {
  var mask = '';
  if (/^\d+(\.\d{1,2}$)?(\.)?$/gm.test(val)) {
    mask = parseFloat(val).toLocaleString('en');
  } else if (!val) {
    mask = "0";
  } else {
    return false;
  }
  return mask;
};
var isEmail = function isEmail(email) {
  var pattern = /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/;
  var regex = new RegExp(pattern, 'i');
  return regex.test(email);
};

exports.capitalizeFirstLetter = capitalizeFirstLetter;
exports.commaSeparator = commaSeparator;
exports.isEmail = isEmail;
exports.isJSON = isJSON;
