var _jsxFileName = 'src/lib/elements/Texts/Hint.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';

import { Text } from './';

var Hint = function Hint(_ref) {
  var text = _ref.children;

  return React.createElement(
    Text,
    { className: 'hint', __source: {
        fileName: _jsxFileName,
        lineNumber: 7
      },
      __self: _this
    },
    text
  );
};

Hint.propTypes = {
  /** */
  children: PropTypes.string
};

export default Hint;