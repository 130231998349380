var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class, _temp;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export var ResponsiveHandler = (_temp = _class = function (_PureComponent) {
  _inherits(ResponsiveHandler, _PureComponent);

  function ResponsiveHandler(props) {
    _classCallCheck(this, ResponsiveHandler);

    var _this = _possibleConstructorReturn(this, (ResponsiveHandler.__proto__ || Object.getPrototypeOf(ResponsiveHandler)).call(this, props));

    _this.sizeResolver = function () {
      return window.innerWidth <= ResponsiveHandler.sizesDictionary.small[1] ? 'small' : window.innerWidth >= ResponsiveHandler.sizesDictionary.medium[0] && window.innerWidth <= ResponsiveHandler.sizesDictionary.medium[1] ? 'medium' : window.innerWidth >= ResponsiveHandler.sizesDictionary.large[0] && window.innerWidth <= ResponsiveHandler.sizesDictionary.large[1] ? 'large' : 'unknown';
    };

    _this.innerWidthUpdated = function () {
      var sizeType = _this.state.sizeType;

      var actualSizeType = _this.sizeResolver();

      if (sizeType !== actualSizeType) {
        _this.setState({
          sizeType: actualSizeType
        });
      }
    };

    _this.handleRender = function () {
      var sizeType = _this.state.sizeType;
      var _this$props = _this.props,
          children = _this$props.children,
          onSize = _this$props.onSize,
          action = _this$props.action;


      switch (action) {
        case 'IF':
          return sizeType === onSize ? children[0] : children[1];
        case 'HIDE':
          return sizeType === onSize ? null : children;
        case 'SHOW':
          return sizeType === onSize ? children : null;
        default:
          return React.isValidElement(children) ? React.cloneElement(children, { sizeType: sizeType }) : React.Children.count(children) === 1 ? React.cloneElement(children[0], { sizeType: sizeType }) : null;
      }
    };

    _this.state = {
      sizeType: _this.sizeResolver()
    };

    window.addEventListener('resize', _this.innerWidthUpdated);
    return _this;
  }

  _createClass(ResponsiveHandler, [{
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      window.removeEventListener('resize', this.innerWidthUpdated);
    }
  }, {
    key: 'render',
    value: function render() {
      return this.handleRender();
    }
  }]);

  return ResponsiveHandler;
}(PureComponent), _class.propTypes = {
  /** */
  action: PropTypes.string,
  /** */
  children: PropTypes.any,
  /** */
  onSize: PropTypes.string
}, _class.sizesDictionary = {
  small: [null, 640],
  medium: [641, 1024],
  large: [1025, 1440]
}, _temp);

export default ResponsiveHandler;