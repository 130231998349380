import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path d="M232.67,250.56A16.68,16.68,0,1,1,216,233.85a16.68,16.68,0,0,1,16.64,16.71" fill="#ffc835"/>
    <path d="M555.71,165.37A15.32,15.32,0,1,1,571,180.73a15.34,15.34,0,0,1-15.29-15.36" fill="#ffc835"/>
    <polygon points="543.04 200.37 504.95 309.73 476.93 309.68 454.97 239.16 432.72 309.59 404.7 309.53 367.05 200.02 395.07 200.07 418.79 269.06 442.79 200.17 467.3 200.22 491.02 269.21 515.02 200.31 543.04 200.37" fill="#363946"/>
    <rect x="516.13" y="241.14" width="109.44" height="28.02" transform="translate(314.56 825.48) rotate(-89.89)" fill="#363946"/>
    <path d="M719.62,200.72l-.2,100.69c-.72,33.28-29,55.1-56.58,55-38.09-.07-53.36-27.24-55.09-32.07l22.12-11.55c1.53,4.59,14.2,18.41,33,18.45,18,0,27.4-16.37,28.51-28.39a55.9,55.9,0,0,1-30.66,8.9A56.47,56.47,0,1,1,661,198.86a55.73,55.73,0,0,1,30.4,9l0-7.22ZM690.41,255.6c0-17.5-12-30-28.4-30-16.64,0-28.49,12.42-28.52,29.92,0,17.73,11.76,30.24,28.4,30.27,16.41,0,28.48-12.42,28.52-30.16" fill="#363946"/>
    <path d="M853.23,201,853,301.68c-.72,33.28-29,55.1-56.57,55-38.1-.08-53.37-27.24-55.1-32.07l22.12-11.55c1.53,4.59,14.2,18.41,33,18.45,17.94,0,27.39-16.37,28.5-28.39a56.56,56.56,0,0,1-87-47.68,56.6,56.6,0,0,1,56.58-56.35,55.85,55.85,0,0,1,30.42,9v-7.22ZM824,255.87c0-17.5-12-30-28.4-30-16.64,0-28.49,12.42-28.52,29.93,0,17.73,11.76,30.23,28.4,30.27,16.42,0,28.48-12.42,28.52-30.16" fill="#363946"/>
    <path d="M870.29,255.75a56.47,56.47,0,1,1,56.36,56.58,56.61,56.61,0,0,1-56.36-56.58m86,.17c0-17.52-12-30.24-28.4-30.27-16.64,0-28.49,12.63-28.52,30.16s11.76,30.21,28.39,30.25c16.42,0,28.49-12.64,28.53-30.14" fill="#363946"/><path d="M1070.58,299.49s-11.85,13.1-30.89,13.06c-18.82,0-33.25-9.24-33.21-32l.11-54-13.35,0,0-25.17,13.35,0,.07-36.55,28-.62-.07,37.22,25.83.06,0,25.17-25.83-.05-.09,45.52c0,9,5.23,14.47,11.35,14.48,7.44,0,12.7-5.9,12.71-9.39Z" fill="#363946"/>
    <path d="M313.85,255.65c-.29-.36-.42-.8-.75-1.14l-87.93-88.27a10.44,10.44,0,0,0-.58-.89,12.82,12.82,0,0,0-17.75,0,10.55,10.55,0,0,0-.59.89L118,254.12a12.17,12.17,0,0,0-3.54,9.13s0,.06,0,.1l-.11,52.26a11.07,11.07,0,0,0,11,11.05h4.36L302,327h4.37a11.08,11.08,0,0,0,11.06-11l.1-52.25a10.93,10.93,0,0,0-3.67-8.11m-173,10.52,74.81-74.51,75.42,75.72L291,302.32,140.78,302Z" fill="#0076ff"/>
    <path d="M1114.92,284.89a14.13,14.13,0,0,1,5.33,5.33,15.5,15.5,0,0,1,0,15.07,14.5,14.5,0,0,1-5.39,5.41,15.29,15.29,0,0,1-15,0,14.54,14.54,0,0,1-5.34-5.39,15.31,15.31,0,0,1,0-15,14.39,14.39,0,0,1,5.37-5.39,14.77,14.77,0,0,1,7.57-2A15,15,0,0,1,1114.92,284.89Zm-1.52,23.43a11.66,11.66,0,0,0,4.34-4.42,12.92,12.92,0,0,0,0-12.31,11.22,11.22,0,0,0-4.27-4.32,12.11,12.11,0,0,0-6.15-1.58,11.89,11.89,0,0,0-6.14,1.6,11.52,11.52,0,0,0-4.3,4.38,13,13,0,0,0,0,12.29,11.64,11.64,0,0,0,10.36,6A11.84,11.84,0,0,0,1113.4,308.32Zm.93-9.81a5.17,5.17,0,0,1-2.29,2l3.57,5.47h-4.37l-3.08-4.71h-2.9V306h-4.38V289.65H1108a7.86,7.86,0,0,1,5.22,1.58,5.2,5.2,0,0,1,1.9,4.23A5.77,5.77,0,0,1,1114.33,298.51Zm-6.63-.53a3.55,3.55,0,0,0,2.29-.65,2.24,2.24,0,0,0,.83-1.87,2.32,2.32,0,0,0-.83-1.9,3.55,3.55,0,0,0-2.29-.67h-2.48V298Z" fill="#363946"/>
    <path d="M898.74,339.57a36.28,36.28,0,0,1,14.66,2.67,20.84,20.84,0,0,1,9.61,7.61,21.36,21.36,0,0,1,0,23.22,22,22,0,0,1-9.61,7.61,38.29,38.29,0,0,1-14.66,2.57H887.9v15.42H870.29v-59.1ZM897.6,370c3.62,0,6.38-.77,8.19-2.19a8.75,8.75,0,0,0,0-12.76c-1.81-1.52-4.57-2.28-8.19-2.28h-9.8v17.32h9.8Z" fill="#ffc834"/>
    <path d="M962.13,383h-9.62v15.7h-17.6v-59.1h28.45A36.28,36.28,0,0,1,978,342.24a20.84,20.84,0,0,1,9.61,7.61,19.73,19.73,0,0,1,3.43,11.61,19.47,19.47,0,0,1-3.24,11.23,20.93,20.93,0,0,1-9,7.42l13.42,18.56H973.45Zm11.13-21.51a7.88,7.88,0,0,0-2.76-6.38c-1.81-1.52-4.57-2.28-8.18-2.28h-9.81v17.32h9.81c3.61,0,6.37-.76,8.18-2.19A8.2,8.2,0,0,0,973.26,361.46Z" fill="#ffc834"/>
    <path d="M1000.76,339.57h17.61v59.1h-17.61Z" fill="#ffc834"/>
    <path d="M1087.08,398.67l-.19-31.31-16,25.51h-7.8l-15.9-24.65v30.45h-16.27v-59.1h14.47l21.89,34.07,21.31-34.07h14.47l.19,59.1Z" fill="#ffc834"/>
    <path d="M1165.69,385.73v12.94h-50v-59.1h48.82v12.95h-31.41v10h27.69V375h-27.69v10.85h32.55Z" fill="#ffc834"/>
    <path d="M1214.59,375.55h-12.15v11.78h-13.25V375.55h-12.14V363h12.14V351.26h13.25V363h12.15Z" fill="#363946"/>
    <path d="M1268.53,342a21.23,21.23,0,0,1,0,39.07,34.71,34.71,0,0,1-14.23,2.69h-8.5v14.49h-19.87V339.28h28.37A34.87,34.87,0,0,1,1268.53,342Zm-9.43,24.63a7.44,7.44,0,0,0,0-10.19c-1.35-1.2-3.37-1.81-6.06-1.81h-7.24v13.81H1253Q1257.08,368.41,1259.1,366.6Z" fill="#0076ff"/>
    <path d="M1324.86,387.94h-22.4l-4,10.28H1278.3l25.76-58.94h19.54l25.76,58.94h-20.54Zm-5.47-14.31-5.73-14.82-5.72,14.82Z" fill="#0076ff"/>
    <path d="M1360.27,397.92a38.16,38.16,0,0,1-11.41-4.42l6.4-14.48a40.48,40.48,0,0,0,9.43,3.91,36.58,36.58,0,0,0,10,1.48,15.58,15.58,0,0,0,5.89-.8,2.56,2.56,0,0,0,1.77-2.4c0-1.24-.77-2.16-2.32-2.78a49.31,49.31,0,0,0-7.62-2,86.63,86.63,0,0,1-11.36-3.07,19.46,19.46,0,0,1-7.92-5.39q-3.36-3.75-3.37-10.15a17.46,17.46,0,0,1,3.12-10.1,20.57,20.57,0,0,1,9.3-7.16,38.68,38.68,0,0,1,15-2.61,54.7,54.7,0,0,1,11.92,1.31,37.05,37.05,0,0,1,10.31,3.83l-6,14.39a36.62,36.62,0,0,0-16.42-4.37c-5.1,0-7.66,1.23-7.66,3.7,0,1.18.76,2.06,2.28,2.65a48.43,48.43,0,0,0,7.49,1.9,77.6,77.6,0,0,1,11.37,2.9,19.93,19.93,0,0,1,8,5.35q3.4,3.71,3.41,10.1a17.3,17.3,0,0,1-3.12,10.07,21,21,0,0,1-9.3,7.15,38.1,38.1,0,0,1-15,2.65A61.48,61.48,0,0,1,1360.27,397.92Z" fill="#0076ff"/>
    <path d="M1415.59,397.92a38.16,38.16,0,0,1-11.41-4.42l6.4-14.48a40.48,40.48,0,0,0,9.43,3.91,36.58,36.58,0,0,0,10,1.48,15.58,15.58,0,0,0,5.89-.8,2.56,2.56,0,0,0,1.77-2.4c0-1.24-.77-2.16-2.32-2.78a49.31,49.31,0,0,0-7.62-2,86.63,86.63,0,0,1-11.36-3.07,19.33,19.33,0,0,1-7.92-5.39q-3.37-3.75-3.37-10.15a17.46,17.46,0,0,1,3.12-10.1,20.57,20.57,0,0,1,9.3-7.16,38.68,38.68,0,0,1,15-2.61,54.7,54.7,0,0,1,11.92,1.31,37.17,37.17,0,0,1,10.31,3.83l-6,14.39a36.62,36.62,0,0,0-16.42-4.37q-7.67,0-7.66,3.7c0,1.18.76,2.06,2.28,2.65a48.43,48.43,0,0,0,7.49,1.9,77.6,77.6,0,0,1,11.37,2.9,19.93,19.93,0,0,1,8,5.35q3.4,3.71,3.41,10.1a17.3,17.3,0,0,1-3.12,10.07,21,21,0,0,1-9.3,7.15,38.1,38.1,0,0,1-15,2.65A61.48,61.48,0,0,1,1415.59,397.92Z" fill="#0076ff"/>
  </Fragment>,
  'WiggotPrimeLogoPass',
  '0 150 1602 280'
)
