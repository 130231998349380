import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Select } from 'rc'
import { MONTHS } from 'utils/constants'

const BirthdayInputs = ({
  isRequired,
  labelDay,
  labelMonth,
  labelYear
}) => {
  const dayValues = getDayValues()
  const monthValues = getMonthValues()
  const yearValues = getYearValues()

  function getDayValues() {
    const values = []
    const times = 31;

    for (let i = 1; i <= times; i++) {
      values.push({ label: i, value: `${i < 10 ? '0' : ''}${i}` })
    }

    return values
  }

  function getMonthValues() {
    const values = []

    MONTHS.forEach(({ label, value }) => { values.push({ label, value: `${value < 10 ? '0' : ''}${value}` }) })

    return  values
  }

  function getYearValues() {
    const values = []
    const year = new Date().getFullYear()
    const yearsAgo = 80

    for (let i = 0; i <= yearsAgo; i++) {
      let newYear = year - i
      values.push({ label: newYear, value: `${newYear}` })
    }

    return values
  }

  return (
    <Container>
      <ContinerItem>
        {labelDay || null}
        <SelectStyled
          required
          css="margin-right: 16px;"
          placeholder="Día"
          name="birthday_day"
          label="Día"
          optionLabelKey="label"
          optionValueKey="value"
          options={dayValues}
          validate={isRequired}
        />
      </ContinerItem>
      <ContinerItem>
        {labelMonth || null}
        <SelectStyled
          required
          css="margin-right: 16px;"
          placeholder="Mes"
          name="birthday_month"
          label="Mes"
          optionLabelKey="label"
          optionValueKey="value"
          options={monthValues}
          validate={isRequired}
        />
      </ContinerItem>
      <ContinerItem>
        {labelYear || null}
        <SelectStyled
          required
          placeholder="Año"
          name="birthday_year"
          label="Año"
          optionLabelKey="label"
          optionValueKey="value"
          options={yearValues}
          validate={isRequired}
        />
      </ContinerItem>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const ContinerItem = styled.div`
  width: 33%;
`
const SelectStyled = styled(Select)`
  margin-top: ${({ theme }) => theme.remCalc('10')};
  > ul {
    height: 200px;
  }
`

BirthdayInputs.propTypes = {
  isRequired: PropTypes.func,
  labelDay: PropTypes.any,
  labelMonth: PropTypes.any,
  labelYear: PropTypes.any,
}

export default BirthdayInputs
