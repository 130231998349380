'use strict';

var Spinner_Spinner = require('./Spinner.js');
require('react');
require('prop-types');
require('styled-components');



module.exports = Spinner_Spinner;
