import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Typography } from 'rc'
import { Bell } from 'rc/Icons'

import { Button } from 'components'

const BellIconStyled = styled(Bell)`
  fill: #97a4b1;
  ${({ theme }) => theme.box(50, 50)}
`
const ContainerStyled = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: ${({ theme }) => theme.remCalc('12')};
  ${({ theme }) => theme.box(430, 320)}
`
const ButtonStyled = styled(Button)`
  width: ${({ theme }) => theme.remCalc('180')};
  max-width: 45%;
`

const MinimumFiltersMC = ({
  HideModal,
  editRequirement,
  contactId,
  requirementId
}) => {
  return (
    <ContainerStyled>
      <BellIconStyled />
      <Typography variant="h2" textTransform="initial">
        ¡Este requerimientos tiene pocos filtros de búsqueda!
      </Typography>
      <p>
        Agrega siempre el tipo de operación (comprar, rentar), el tipo de
        inmueble​, precio y la ​zona ​en la que buscas.
      </p>
      <div
        css={`
          width: 100%;
          display: flex;
          justify-content: space-around;
        `}
      >
        <ButtonStyled type="tertiary" onClick={HideModal}>
          Cancelar
        </ButtonStyled>
        <ButtonStyled onClick={editRequirement}>
          Agregar más filtros
        </ButtonStyled>
      </div>
    </ContainerStyled>
  )
}

MinimumFiltersMC.propTypes = {
  contactId: PropTypes.string,
  HideModal: PropTypes.func,
  editRequirement: PropTypes.func,
  requirementId: PropTypes.string
}

export default MinimumFiltersMC
