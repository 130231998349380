import enc from 'crypto-js/enc-base64'
import CryptoJS from 'crypto-js'

export const encodeSHA256 = (data: any, token: string) => {
  const hash = CryptoJS.HmacSHA256(JSON.stringify(data), token)
  const hashInBase64 = enc.stringify(hash)
  return hashInBase64
}

export const encryptAES = (message: any, token: string) => {
  return CryptoJS.AES.encrypt(JSON.stringify(message), token).toString()
}
