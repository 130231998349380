import { ICONS } from 'utils/icons'
import I24 from 'static/logos/i24.svg'
import SEGUNDAMANO from 'static/logos/SEGUNDAMANO.svg'
import M3 from 'static/logos/M3.svg'
import VIVAANUNCIOS from 'static/logos/VIVAANUNCIOS.svg'
import PROPIEDADESCOM from 'static/logos/PROPIEDADESCOM.svg'
import PORTALTERRENOCOM from 'static/logos/PORTALTERRENOCOM.png'
import ALLPROPERTY from 'static/logos/ALLPROPERTY.png'
import CyT from 'static/img/portalIcons/icon_portal_cyt.svg'
import LAMUDI from 'static/img/portalIcons/Proppit_by_lamudi_2lines.svg'
import ELINFORMADOR from 'static/img/el-informador-logo.svg'

const {
  i24Isotype,
  mlIsotype,
  cytIsotype,
  vivanunciosIsotype,
  propiedadescomIsotype,
  allpropertyIsotype,
  portalTerrenoIsotype,
  proppitIsotype,
  segundaManoIsotype,
  elInformadorIsotype,
  wiggotBoostIsotype,
  wiggotIsotype,
  monopolioIsotype
} = ICONS

export const PROSPECTS = [
  { label: 'Prosp. Inmobiliaria', value: 'AGENCY_PROPERTIES' },
  { label: 'Mis Prospectos', value: 'MY_PROPERTIES' }
]

export const ORIGIN_DEFAULT = 'PORTALS'

export const ORIGIN_TYPES = [
  { label: 'Portales', value: ORIGIN_DEFAULT },
  { label: 'Sitio web Wiggot', value: 'WEB_SITE' },
  { label: 'Wiggot Boost', value: 'WIGGOT' }
]

export const Logo = {
  I24: I24,
  SEGUNDAMANO: SEGUNDAMANO,
  M3: M3,
  VIVAANUNCIOS: VIVAANUNCIOS,
  PROPIEDADESCOM: PROPIEDADESCOM,
  PORTALTERRENOCOM: PORTALTERRENOCOM,
  CyT: CyT,
  ALLPROPERTY: ALLPROPERTY,
  LAMUDI: LAMUDI,
  ELINFORMADOR: ELINFORMADOR
}

export const Isotype = {
  I24: i24Isotype,
  SEGUNDAMANO: segundaManoIsotype,
  M3: mlIsotype,
  VIVAANUNCIOS: vivanunciosIsotype,
  PROPIEDADESCOM: propiedadescomIsotype,
  PORTALTERRENOCOM: portalTerrenoIsotype,
  CyT: cytIsotype,
  ALLPROPERTY: allpropertyIsotype,
  LAMUDI: proppitIsotype,
  ELINFORMADOR: elInformadorIsotype,
  WEB_SITE: wiggotIsotype,
  WIGGOT_BOOST: wiggotBoostIsotype,
  MONOPOLIO: monopolioIsotype
}

export const PORTALS_NAME = {
  I24: 'Inmuebles 24',
  VIVAANUNCIOS: 'Vivanuncios',
  M3: 'Mercado Libre',
  SEGUNDAMANO: 'Segunda mano',
  PROPIEDADESCOM: 'Propiedades.com',
  LAMUDI: 'Proppit by Lamudi',
  CyT: 'Casas y Terrenos',
  VIVANUNCIOS: 'Viva Anuncios',
  ALLPROPERTY: 'AllProperty',
  PORTALTERRENOCOM: 'PortalTerreno',
  ELINFORMADOR: 'El Informador',
  MONOPOLIO: 'Monopolio'
}

export const WIGGOT_PORTALS = {
  WEB_SITE: 'Sitio web',
  WIGGOT: 'Wiggot Boost',
  LANDING: 'Wiggot Boost',
  FACEBOOK_LEADS: 'Wiggot Boost',
  ORGANIC: 'Wiggot Boost'
}

export const ACTUAL_YEAR = new Date().getFullYear()

export const FILTER_YEARS = [
  { label: ACTUAL_YEAR, value: ACTUAL_YEAR },
  { label: ACTUAL_YEAR - 1, value: ACTUAL_YEAR - 1 }
]
