'use strict';

var RouterNavLink_RouterNavLink = require('./RouterNavLink.js');
require('@babel/runtime/helpers/extends');
require('@babel/runtime/helpers/objectWithoutProperties');
require('react');
require('prop-types');
require('react-router-dom');



module.exports = RouterNavLink_RouterNavLink;
