import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g fill="none" fillRule="evenodd">
      <circle cx="30" cy="30" r="30" fill="#0077E2" />
      <path
        fill="#FFF"
        d="M24.843 42c-.463 0-.927-.177-1.28-.531l-8.031-8.04a1.82 1.82 0 0 1-.002-2.57 1.81 1.81 0 0 1 2.563-.002l6.75 6.758 16.064-16.083a1.808 1.808 0 0 1 2.563.001 1.82 1.82 0 0 1-.002 2.57L26.124 41.47a1.804 1.804 0 0 1-1.281.531"
      />
    </g>
  </Fragment>,
  'Success',
  '0 0 60 60'
)
