import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <g>
    <path
      className="st0"
      d="M141.47,32.33c0.63,0,1.18,0.11,1.65,0.32c0.47,0.21,0.83,0.51,1.08,0.9c0.25,0.39,0.38,0.84,0.38,1.37
		c0,0.53-0.13,0.98-0.38,1.37c-0.25,0.39-0.61,0.69-1.08,0.9c-0.47,0.21-1.02,0.31-1.65,0.31h-1.22v1.83h-1.98v-7H141.47z
		 M141.35,35.94c0.41,0,0.71-0.09,0.92-0.26c0.21-0.18,0.31-0.43,0.31-0.76c0-0.33-0.1-0.58-0.31-0.76
		c-0.21-0.18-0.51-0.27-0.92-0.27h-1.1v2.05H141.35z M148.61,37.47h-1.08v1.86h-1.98v-7h3.2c0.63,0,1.18,0.11,1.65,0.32
		c0.47,0.21,0.83,0.51,1.08,0.9c0.25,0.39,0.38,0.84,0.38,1.37c0,0.51-0.12,0.95-0.36,1.33c-0.23,0.37-0.57,0.67-1.01,0.88l1.51,2.2
		h-2.12L148.61,37.47z M149.86,34.92c0-0.33-0.1-0.58-0.31-0.76c-0.21-0.18-0.51-0.27-0.92-0.27h-1.1v2.05h1.1
		c0.41,0,0.71-0.09,0.92-0.26C149.75,35.5,149.86,35.25,149.86,34.92z M152.95,32.33h1.98v7h-1.98V32.33z M162.66,39.33l-0.02-3.71
		l-1.8,3.02h-0.88l-1.79-2.92v3.61h-1.83v-7h1.63l2.46,4.04l2.4-4.04h1.63l0.02,7H162.66z M171.5,37.8v1.53h-5.62v-7h5.49v1.53
    h-3.53v1.18h3.11v1.48h-3.11v1.28H171.5z"
      style={{ fill: '#FFC834' }}
    />
    <g>
      <defs>
        <rect id="SVGID_1_" width="164.27" height="35.38" />
      </defs>
      <clipPath id="SVGID_2_"></clipPath>
      <g className="st1">
        <path
          className="st0"
          d="M20.31,17.34c0,0.57-0.17,1.12-0.48,1.59c-0.31,0.47-0.76,0.84-1.28,1.06c-0.52,0.22-1.1,0.27-1.65,0.16
				c-0.56-0.11-1.07-0.38-1.47-0.78c-0.4-0.4-0.67-0.91-0.79-1.47c-0.11-0.56-0.06-1.13,0.16-1.66c0.22-0.52,0.58-0.97,1.05-1.29
        c0.47-0.32,1.02-0.48,1.59-0.49c0.76,0,1.49,0.3,2.03,0.84C20,15.85,20.3,16.58,20.31,17.34"
          style={{ fill: '#FFC834' }}
        />
        <path
          className="st0"
          d="M75.77,2.6c0-0.52,0.15-1.03,0.44-1.46c0.29-0.43,0.7-0.77,1.18-0.97c0.48-0.2,1.01-0.25,1.52-0.15
				c0.51,0.1,0.98,0.35,1.34,0.72c0.37,0.37,0.62,0.83,0.72,1.34c0.1,0.51,0.05,1.04-0.15,1.52c-0.2,0.48-0.54,0.89-0.97,1.18
        c-0.43,0.29-0.94,0.44-1.46,0.44c-0.7,0-1.36-0.28-1.86-0.77C76.05,3.96,75.77,3.29,75.77,2.6"
          style={{ fill: '#FFC834' }}
        />
        <path
          className="st2"
          d="M73.61,8.61l-6.51,18.8h-4.81l-3.8-12.11l-3.8,12.11h-4.81l-6.51-18.8h4.81l4.1,11.84l4.1-11.84h4.21
        l4.1,11.84l4.1-11.84H73.61z"
          style={{ fill: '#ffffff' }}
        />
        <path
          className="st2"
          d="M80.81,8.61H76v18.8h4.81V8.61z"
          style={{ fill: '#ffffff' }}
        />
        <path
          className="st2"
          d="M103.94,8.61v17.3c-0.11,5.72-4.96,9.48-9.7,9.48c-6.54,0-9.18-4.66-9.48-5.49l3.8-1.99
				c0.26,0.79,2.44,3.16,5.68,3.16c3.08,0,4.7-2.82,4.89-4.89c-1.57,1.01-3.4,1.55-5.26,1.54c-2.57,0-5.04-1.02-6.86-2.84
				c-1.82-1.82-2.84-4.29-2.84-6.86s1.02-5.04,2.84-6.86c1.82-1.82,4.29-2.84,6.86-2.84c1.85,0,3.67,0.53,5.23,1.54V8.61H103.94z
				 M98.94,18.05c0-3-2.06-5.15-4.89-5.15c-2.86,0-4.89,2.14-4.89,5.15c0,3.05,2.03,5.19,4.89,5.19
        C96.87,23.24,98.94,21.1,98.94,18.05z"
          style={{ fill: '#ffffff' }}
        />
        <path
          className="st2"
          d="M126.9,8.61v17.3c-0.11,5.72-4.96,9.48-9.7,9.48c-6.54,0-9.18-4.66-9.48-5.49l3.8-1.99
				c0.26,0.79,2.44,3.16,5.68,3.16c3.08,0,4.7-2.82,4.89-4.89c-1.57,1.01-3.39,1.55-5.26,1.54c-2.54-0.05-4.96-1.09-6.74-2.91
				c-1.78-1.81-2.77-4.25-2.77-6.79c0-2.54,1-4.98,2.77-6.79c1.78-1.81,4.2-2.86,6.74-2.91c1.86,0,3.67,0.53,5.23,1.54V8.62
				L126.9,8.61z M121.89,18.05c0-3-2.06-5.15-4.89-5.15c-2.86,0-4.89,2.14-4.89,5.15c0,3.05,2.03,5.19,4.89,5.19
        C119.81,23.24,121.89,21.1,121.89,18.05"
          style={{ fill: '#ffffff' }}
        />
        <path
          className="st2"
          d="M129.84,18.01c0-1.92,0.57-3.8,1.63-5.39c1.07-1.6,2.58-2.84,4.35-3.57c1.77-0.73,3.72-0.93,5.61-0.55
				c1.88,0.37,3.61,1.3,4.97,2.65c1.36,1.36,2.28,3.09,2.66,4.97c0.37,1.88,0.18,3.83-0.55,5.61c-0.73,1.77-1.98,3.29-3.57,4.35
				c-1.6,1.07-3.47,1.64-5.39,1.64c-2.57-0.01-5.04-1.03-6.85-2.85C130.88,23.05,129.85,20.58,129.84,18.01L129.84,18.01z
				 M144.62,18.01c0-3-2.06-5.19-4.89-5.19c-2.86,0-4.89,2.18-4.89,5.19c0,3.01,2.03,5.19,4.89,5.19
        C142.55,23.2,144.62,21.02,144.62,18.01z"
          style={{ fill: '#ffffff' }}
        />
        <path
          className="st2"
          d="M164.27,25.46c0,0-2.03,2.25-5.3,2.25c-3.23,0-5.72-1.58-5.72-5.49v-9.28h-2.29V8.61h2.29V2.33l4.81-0.11
        v6.39h4.44v4.32h-4.44v7.82c0,1.54,0.9,2.48,1.95,2.48c1.28,0,2.18-1.02,2.18-1.62L164.27,25.46z"
          style={{ fill: '#ffffff' }}
        />
        <path
          className="st2"
          d="M34.26,18.19c-0.04-0.07-0.08-0.13-0.13-0.19L18.99,2.87c-0.03-0.05-0.06-0.1-0.1-0.15
				c-0.41-0.39-0.96-0.61-1.52-0.61c-0.57,0-1.11,0.22-1.52,0.61c-0.04,0.05-0.07,0.1-0.1,0.15L0.61,18
				c-0.2,0.21-0.36,0.45-0.47,0.72c-0.1,0.27-0.15,0.56-0.14,0.85c0,0,0,0.01,0,0.02v8.98c0,0.5,0.21,0.98,0.56,1.33
				c0.35,0.35,0.83,0.55,1.33,0.55H33c0.5,0,0.98-0.2,1.34-0.56c0.35-0.36,0.55-0.84,0.56-1.34v-8.97c0-0.26-0.06-0.52-0.17-0.76
        C34.62,18.58,34.46,18.36,34.26,18.19L34.26,18.19z M4.55,20.06L17.38,7.24l12.98,12.98v6H4.54L4.55,20.06z"
          style={{ fill: '#ffffff' }}
        />
      </g>
    </g>
  </g>,
  'WiggotPrimeLogo',
  '0 0 171.5 39.33'
)
