import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Layout } from 'c3-ui/elements/Layouts'
import { Title, Text } from 'c3-ui/elements/Texts'
import { DangerButton, SecondaryButton } from 'c3-ui/elements/Buttons'

import { BlockAssociationAgencies } from 'reduxActions/association'
import { HideModal, ShowFlashNotification } from 'reduxActions/services'

export class BlockAssociationAgencyMC extends Component {
  static propTypes = {
    token: PropTypes.string,
    agencies: PropTypes.array,

    updateCallback: PropTypes.func,
    BlockAssociationAgencies: PropTypes.func,
    HideModal: PropTypes.func,
    ShowFlashNotification: PropTypes.func
  }

  BASE_CLASSNAME = 'block-association-agency-mc'

  blockAssociationAgencies = () => {
    const {
      agencies,
      token,
      updateCallback,
      HideModal,
      ShowFlashNotification,
      BlockAssociationAgencies
    } = this.props

    const agenciesIds = agencies.map(({ agencyId }) => agencyId)
    BlockAssociationAgencies(token, agenciesIds).then(action => {
      HideModal()
      updateCallback()
      ShowFlashNotification(
        'success',
        `Se ha${agenciesIds.length !== 1 ? 'n' : ''} bloqueado la${
          agenciesIds.length !== 1 ? 's' : ''
        } inmobiliaria${agenciesIds.length !== 1 ? 's' : ''} de tu asociación.`
      )
    })
  }

  render() {
    const { agencies, HideModal } = this.props

    return (
      <Layout className={this.BASE_CLASSNAME} center>
        <Title
          className={`${this.BASE_CLASSNAME}__title`}
          center
          smallSize
          uppercase
        >
          {agencies.length === 1
            ? `¿DESEAS BLOQUEAR A ${agencies[0].name || agencies[0].email}?`
            : `¿DESEAS BLOQUEAR ${agencies.length} INMOBILIARIAS?`}
        </Title>

        <Text className={`${this.BASE_CLASSNAME}__description`} gray>
          Al bloquear una inmobiliaria se darán de baja temporalmente sus
          propiedades y sus asesores de tu asociación. Podrás activarla de nuevo
          en cualquier momento.
        </Text>

        {agencies.length > 1 ? (
          <Layout>
            <Text uppercase className={`${this.BASE_CLASSNAME}__subtitle`}>
              INMOBILIARIA(S):{' '}
            </Text>
            <Text bold>{agencies.map(({ name }) => name).join('; ')}.</Text>
          </Layout>
        ) : (
          ''
        )}
        <Layout center middle marginX paddingY>
          <SecondaryButton
            id="associationAgencies-blockAssociationAgency-mc-button-cancel"
            onClick={HideModal}
            medium={5}
          >
            CANCELAR
          </SecondaryButton>

          <DangerButton
            id="associationAgencies-blockAssociationAgency-mc-button-block"
            onClick={this.blockAssociationAgencies}
            medium={5}
          >
            BLOQUEAR
          </DangerButton>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  BlockAssociationAgencies,
  HideModal,
  ShowFlashNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockAssociationAgencyMC)
