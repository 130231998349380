/* eslint-disable no-console */
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export default function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location)
    if (publicUrl.origin !== window.location.origin) return

    window.addEventListener('load', () => {
      let swUrl = `${process.env.PUBLIC_URL}`
      if (isLocalhost) {
        swUrl += '/service-worker.js'
        checkValidServiceWorker(swUrl)
      } else {
        swUrl = 'https://wiggot.com/portal/service-worker.js'
        registerValidSW(swUrl)
      }
    })
  }
}

function checkValidServiceWorker(swUrl) {
  fetch(swUrl)
    .then(response => {
      if (
        response.status === 404 ||
        response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        registerValidSW(swUrl)
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    })
}

function registerValidSW(swUrl) {
  const ServiceWorkerRegister = navigator.serviceWorker.register(swUrl)
  if (ServiceWorkerRegister && typeof ServiceWorkerRegister === 'function') {
    ServiceWorkerRegister.then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log('New content is available; please refresh.')
            } else {
              console.log('Content is cached for offline use.')
            }
          }
        }
      }
    }).catch(error => {
      console.error('Error during service worker registration:', error)
    })
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()
    })
  }
}
