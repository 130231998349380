import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Grid, RouterNavLink, Typography } from 'rc'
import { Image } from 'components'
import { PlanCounter } from 'modules/app/components'

const CustomizedHeaderItemFlat = ({
  photo,
  fullname,
  roles,
  forwardRef,
  ...props
}) => {
  const toggleMenu = () => {
    const menuButton = document.querySelector('[data-toggle="menu-button"]')
    if (menuButton) menuButton.click()
  }
  const baseID = 'customized-menu-header'

  const profileUrl = !roles.ASSOCIATION
    ? roles.DEVELOPER_USER
      ? `/developmentAccount`
      : `/profile`
    : '/association/agencies'

  return (
    <Container ref={forwardRef}>
      <HeaderContainer>
        <RouterNavLink
          onClick={toggleMenu}
          to={profileUrl}
          id={`${baseID}__photo-link`}
        >
          <ImageContainer {...props}>
            {photo ? (
              <Image id={photo} />
            ) : fullname ? (
              <Typography component="span" variant="h2" color="inherit">
                {fullname
                  .split(' ')
                  .map((item, index) => {
                    if (index >= 2) {
                      return ''
                    }
                    return item.charAt(0)
                  })
                  .join('')}
              </Typography>
            ) : null}
          </ImageContainer>
        </RouterNavLink>

        <Grid
          item
          flat
          xsAuto
          mdShrink
          container
          inline
          direction="column"
          css={`
            align-items: flex-start;
          `}
        >
          <NameNavLink
            to={profileUrl}
            id={`${baseID}name-link`}
            onClick={toggleMenu}
          >
            {fullname}
          </NameNavLink>
        </Grid>
      </HeaderContainer>
      <PlanCounter isMobile />
    </Container>
  )
}

CustomizedHeaderItemFlat.propTypes = {
  photo: PropTypes.string,
  fullname: PropTypes.string,
  roles: PropTypes.object,
  forwardRef: PropTypes.any
}

const Container = styled.div`
  height: ${({ theme }) => theme.remCalc('120')};
  width: 100%;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.primary.main};
`
const HeaderContainer = styled(Grid).attrs({
  container: true,
  justify: 'center'
})`
  z-index: 1;
  padding: ${({ theme }) => theme.remCalc('0 10')};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
`
const ImageContainer = styled(Grid).attrs({
  container: true,
  inline: true,
  center: true
})`
  ${({ theme }) => theme.box(60, 60, true)}
  overflow: hidden;
  ${({ theme }) => theme.bordered('4', theme.palette.base.white)}
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.palette.base.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: ${({ theme }) => theme.remCalc('10')};
  margin-top: ${({ theme }) => theme.remCalc('5')};
`
const NameNavLink = styled(RouterNavLink)`
  display: block;

  padding: ${({ theme }) => theme.remCalc('5 0')};
  color: ${({ theme }) => theme.palette.base.white};

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  &:visited {
    color: ${({ theme }) => theme.palette.base.white};
  }
  ${({ theme }) => theme.media.desktop`
  text-align: center;
`}
`
export default connect(
  ({ authReducer: { roles }, profileReducer: { photo, fullname } }) => ({
    photo,
    fullname,
    roles
  })
)(CustomizedHeaderItemFlat)
