import React, { useContext } from 'react'
import { object } from 'prop-types'
import styled from 'styled-components'
import { Typography, RadioGroup, Radio, Checkbox, withForm } from 'rc'

import { MaskedInput } from 'rc/FormComponents'

import {
  ModelFormSectionContainer,
  ActiveInputTitleContainer
} from 'modules/development/static-components'
import ModelFormContext from '../ModelFormContext'
function TerrainDetail(props) {
  const {
    fc: {
      values: {
        details_range,
        ground_active,
        min_ground_size,
        max_ground_size,
        terrace_active,
        min_terrace_size,
        max_terrace_size,
        construction_active,
        min_construction_size,
        max_construction_size,
        garden_active,
        min_garden_size,
        max_garden_size,
        ground_shape,
        ground_relief,
        property_type,
        min_fronthead_size,
        max_fronthead_size,
        min_background_size,
        max_background_size
      },
      customChange
    }
  } = props

  const { formErrors = {}, cleanFormErrors } = useContext(ModelFormContext)
  const isTerrain = property_type === 'TERRAIN'

  const handleMaskedInputChange = ({ target }) => {
    const { value, name } = target
    cleanFormErrors(name)
    customChange({ [`${name}`]: value === '' ? null : value })
  }

  return (
    <ModelFormSectionContainer>
      <Typography
        variant="h2"
        textTransform="initial"
        color="palette.primary.main"
      >
        Especificaciones
      </Typography>
      <FlexContainerStyled
        css={`
          margin: ${({ theme }) => theme.remCalc('20 0 30')};
        `}
      >
        <RadioGroupStyled
          name="details_range"
          isRadioRequired={true}
          css={`
            justify-content: flex-start;
          `}
        >
          <LabelStyled>
            <RadioStyled
              checked={details_range === 'true'}
              type="radio"
              value={true}
            />
            <Typography variant="subtitle4" fontWeight="regular">
              Rango de metros
            </Typography>
          </LabelStyled>
          <LabelStyled>
            <RadioStyled
              checked={details_range === 'false'}
              type="radio"
              value={false}
            />
            <Typography variant="subtitle4" fontWeight="regular">
              Metros fijos
            </Typography>
          </LabelStyled>
        </RadioGroupStyled>
      </FlexContainerStyled>
      <FlexContainerStyled
        css={`
          justify-content: space-between;
        `}
      >
        <SectionColumnStyled>
          {isTerrain ? (
            <>
              <ActiveInputTitleContainer>
                <Typography variant="subtitle4" fontWeight="medium">
                  Superficie m2
                </Typography>
              </ActiveInputTitleContainer>
              <InputsContainerStyled
                fullWidth={false}
                css={`
                  flex-direction: column;
                `}
              >
                {details_range === 'true' && (
                  <MaskedInput
                    name="min_ground_size"
                    label="Desde"
                    placeholder="Tamaño mínimo"
                    mask="square-meters"
                    value={min_ground_size}
                    required={isTerrain}
                    error={formErrors['min_ground_size']}
                    onChange={handleMaskedInputChange}
                    css={`
                      margin-bottom: ${({ theme }) => theme.remCalc('24')};
                    `}
                  />
                )}
                <MaskedInput
                  name="max_ground_size"
                  label={details_range === 'true' ? 'Hasta' : 'Terreno'}
                  placeholder="Tamaño máximo"
                  mask="square-meters"
                  value={max_ground_size}
                  required={isTerrain}
                  onChange={handleMaskedInputChange}
                />
              </InputsContainerStyled>
            </>
          ) : (
            <>
              <ActiveInputTitleContainer>
                <CheckboxStyled name="ground_active" />
                <Typography variant="subtitle4" fontWeight="medium">
                  m2 de terreno
                </Typography>
              </ActiveInputTitleContainer>
              <InputsContainerStyled fullWidth={details_range === 'true'}>
                {details_range === 'true' && (
                  <MaskedInput
                    name="min_ground_size"
                    label="Desde"
                    placeholder="Tamaño mínimo"
                    mask="square-meters"
                    value={min_ground_size}
                    disabled={!ground_active}
                    required={ground_active}
                    error={formErrors['min_ground_size']}
                    onChange={handleMaskedInputChange}
                  />
                )}
                <MaskedInput
                  name="max_ground_size"
                  label={details_range === 'true' ? 'Hasta' : 'Terreno'}
                  placeholder="Tamaño máximo"
                  mask="square-meters"
                  value={max_ground_size}
                  disabled={!ground_active}
                  required={ground_active}
                  onChange={handleMaskedInputChange}
                />
              </InputsContainerStyled>
              <ActiveInputTitleContainer>
                <CheckboxStyled name="terrace_active" />
                <Typography variant="subtitle4" fontWeight="medium">
                  m2 de terraza
                </Typography>
              </ActiveInputTitleContainer>
              <InputsContainerStyled fullWidth={details_range === 'true'}>
                {details_range === 'true' && (
                  <MaskedInput
                    name="min_terrace_size"
                    label="Desde"
                    placeholder="Tamaño mínimo"
                    mask="square-meters"
                    value={min_terrace_size}
                    disabled={!terrace_active}
                    required={terrace_active}
                    error={formErrors['min_terrace_size']}
                    onChange={handleMaskedInputChange}
                  />
                )}
                <MaskedInput
                  name="max_terrace_size"
                  label={details_range === 'true' ? 'Hasta' : 'Terraza'}
                  placeholder="Tamaño máximo"
                  mask="square-meters"
                  value={max_terrace_size}
                  disabled={!terrace_active}
                  required={terrace_active}
                  onChange={handleMaskedInputChange}
                />
              </InputsContainerStyled>
            </>
          )}
          <Typography variant="subtitle4" fontWeight="medium">
            Forma de terreno
          </Typography>
          <FlexContainerStyled
            css={`
              margin-top: ${({ theme }) => theme.remCalc('18')};
            `}
          >
            <RadioGroupStyled name="ground_shape" isRadioRequired={true}>
              <LabelStyled
                css={`
                  width: 48%;
                `}
              >
                <RadioStyled
                  checked={ground_shape === 'REGULAR'}
                  type="checkbox"
                  value={'REGULAR'}
                />
                <Typography variant="subtitle4" fontWeight="regular">
                  Regular
                </Typography>
              </LabelStyled>
              <LabelStyled
                css={`
                  width: 48%;
                `}
              >
                <RadioStyled
                  checked={ground_shape === 'IRREGULAR'}
                  type="checkbox"
                  value={'IRREGULAR'}
                />
                <Typography variant="subtitle4" fontWeight="regular">
                  Irregular
                </Typography>
              </LabelStyled>
            </RadioGroupStyled>
          </FlexContainerStyled>
        </SectionColumnStyled>
        <SectionColumnStyled>
          {isTerrain ? (
            <>
              {details_range === 'false' && (
                <div
                  css={`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: ${({ theme }) => theme.remCalc('30')};
                    > * {
                      width: 48%;
                    }
                  `}
                >
                  <Typography variant="subtitle4" fontWeight="medium">
                    m Frente
                  </Typography>
                  <Typography variant="subtitle4" fontWeight="medium">
                    m Fondo
                  </Typography>
                </div>
              )}
              <div
                column={details_range === 'true'}
                css={`
                  display: flex;
                  ${({ column, theme }) =>
                    column
                      ? `
                    flex-direction: column;
                    margin-top: ${theme.remCalc('40')};
                    margin-bottom: ${theme.remCalc('10')};
                  `
                      : `
                    justify-content: space-between;
                    margin-bottom: ${theme.remCalc('20')};
                  `}
                `}
              >
                <InputsContainerStyled
                  fullWidth={details_range === 'true' ? true : false}
                  range={details_range === 'true'}
                  css={`
                    margin-bottom: 0;
                    ${({ range }) => range && 'align-items: center;'}
                  `}
                >
                  {details_range === 'true' && (
                    <Typography
                      variant="subtitle4"
                      fontWeight="medium"
                      css={`
                        width: 23%;
                      `}
                    >
                      m Frente
                    </Typography>
                  )}
                  {details_range === 'true' && (
                    <MaskedInput
                      name="min_fronthead_size"
                      label="Desde"
                      placeholder="min metros de frente"
                      mask="meters"
                      value={min_fronthead_size}
                      required={isTerrain}
                      onChange={handleMaskedInputChange}
                    />
                  )}
                  <MaskedInput
                    name="max_fronthead_size"
                    label={details_range === 'true' ? 'Hasta' : 'Frente'}
                    placeholder="Máx metros de frente"
                    mask="meters"
                    value={max_fronthead_size}
                    required={isTerrain}
                    onChange={handleMaskedInputChange}
                  />
                </InputsContainerStyled>
                <InputsContainerStyled
                  fullWidth={details_range === 'true' ? true : false}
                  range={details_range === 'true'}
                  css={`
                    margin-bottom: 0;
                    ${({ range }) => range && 'align-items: center;'}
                  `}
                >
                  {details_range === 'true' && (
                    <Typography
                      variant="subtitle4"
                      fontWeight="medium"
                      css={`
                        width: 23%;
                      `}
                    >
                      m Fondo
                    </Typography>
                  )}
                  {details_range === 'true' && (
                    <MaskedInput
                      name="min_background_size"
                      label="Desde"
                      placeholder="Mín metros de fondo"
                      mask="meters"
                      value={min_background_size}
                      required={isTerrain}
                      onChange={handleMaskedInputChange}
                    />
                  )}
                  <MaskedInput
                    name="max_background_size"
                    label={details_range === 'true' ? 'Hasta' : 'Fondo'}
                    placeholder="Máx metros de fondo"
                    mask="meters"
                    value={max_background_size}
                    required={isTerrain}
                    onChange={handleMaskedInputChange}
                  />
                </InputsContainerStyled>
              </div>
            </>
          ) : (
            <>
              <ActiveInputTitleContainer>
                <CheckboxStyled name="construction_active" />
                <Typography variant="subtitle4" fontWeight="medium">
                  m2 de construcción
                </Typography>
              </ActiveInputTitleContainer>
              <InputsContainerStyled fullWidth={details_range === 'true'}>
                {details_range === 'true' && (
                  <MaskedInput
                    name="min_construction_size"
                    label="Desde"
                    placeholder="Tamaño mínimo"
                    mask="square-meters"
                    value={min_construction_size}
                    disabled={!construction_active}
                    required={construction_active}
                    error={formErrors['min_construction_size']}
                    onChange={handleMaskedInputChange}
                  />
                )}
                <MaskedInput
                  name="max_construction_size"
                  label={details_range === 'true' ? 'Hasta' : 'Construcción'}
                  placeholder="Tamaño máximo"
                  mask="square-meters"
                  value={max_construction_size}
                  disabled={!construction_active}
                  required={construction_active}
                  onChange={handleMaskedInputChange}
                />
              </InputsContainerStyled>
              <ActiveInputTitleContainer>
                <CheckboxStyled name="garden_active" />
                <Typography variant="subtitle4" fontWeight="medium">
                  m2 de jardin
                </Typography>
              </ActiveInputTitleContainer>
              <InputsContainerStyled fullWidth={details_range === 'true'}>
                {details_range === 'true' && (
                  <MaskedInput
                    name="min_garden_size"
                    label="Desde"
                    placeholder="Tamaño mínimo"
                    mask="square-meters"
                    value={min_garden_size}
                    disabled={!garden_active}
                    required={garden_active}
                    error={formErrors['min_garden_size']}
                    onChange={handleMaskedInputChange}
                  />
                )}
                <MaskedInput
                  name="max_garden_size"
                  label={details_range === 'true' ? 'Hasta' : 'Jardin'}
                  placeholder="Tamaño máximo"
                  mask="square-meters"
                  value={max_garden_size}
                  disabled={!garden_active}
                  required={garden_active}
                  onChange={handleMaskedInputChange}
                />
              </InputsContainerStyled>
            </>
          )}
          <Typography variant="subtitle4" fontWeight="medium">
            Relieve de terreno
          </Typography>
          <FlexContainerStyled
            css={`
              margin-top: ${({ theme }) => theme.remCalc('18')};
            `}
          >
            <RadioGroupStyled name="ground_relief" isRadioRequired={true}>
              <LabelStyled
                css={`
                  width: 48%;
                `}
              >
                <RadioStyled
                  checked={ground_relief === 'FLAT'}
                  type="checkbox"
                  value={'FLAT'}
                />
                <Typography variant="subtitle4" fontWeight="regular">
                  Plano
                </Typography>
              </LabelStyled>
              <LabelStyled
                css={`
                  width: 48%;
                `}
              >
                <RadioStyled
                  checked={ground_relief === 'TILTED'}
                  type="checkbox"
                  value={'TILTED'}
                />
                <Typography variant="subtitle4" fontWeight="regular">
                  Inclinado
                </Typography>
              </LabelStyled>
            </RadioGroupStyled>
          </FlexContainerStyled>
        </SectionColumnStyled>
      </FlexContainerStyled>
    </ModelFormSectionContainer>
  )
}

TerrainDetail.propTypes = {
  fc: object
}

const FlexContainerStyled = styled.div`
  display: flex;
`

const RadioStyled = styled(Radio)`
  margin-right: ${({ theme }) => theme.remCalc('8')};
`
const CheckboxStyled = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.remCalc('8')};
`
const RadioGroupStyled = styled(RadioGroup)`
  margin-top: 0 !important;
  justify-content: space-between;
`
const LabelStyled = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 20%;
`
const SectionColumnStyled = styled(FlexContainerStyled)`
  flex-direction: column;
  width: 48%;
`
const InputsContainerStyled = styled(FlexContainerStyled)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.remCalc('20')};
  min-height: ${({ theme }) => theme.remCalc('60')};
  > * {
    margin-top: 0px !important;
    width: ${({ fullWidth }) => (fullWidth ? '48%' : '100%')};
    > div > p {
      visibility: initial !important;
      font-size: 10px;
    }
  }
`

export default withForm(TerrainDetail)
