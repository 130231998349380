export const ResendInvitations = (
  Authorization,
  emails
) => ({
  type: 'RESEND_INVITATIONS',
  payload: {
    request: {
      url: `/developers/invitations/resend`,
      method: 'post',
      headers: { Authorization },
      data: {
        emails
      }
    }
  }
})

export const DeleteInvitations = (
  Authorization,
  invitationCodes
) => ({
  type: 'DELETE_INVITATIONS',
  payload: {
    request: {
      url: '/developers/invitations',
      method: 'delete',
      headers: { Authorization },
      data: {
        invitation_codes: invitationCodes
      }
    }
  }
})

export const CreateInvitation = (
  Authorization,
  emails
) => ({
  type: 'CREATE_INVITATION',
  payload: {
    request: {
      url: '/developers/invitations',
      method: 'post',
      headers: { Authorization },
      data: {
        emails
      }
    }
  }
})

export const GetAvailableBrokers = (
  Authorization,
  email
) => ({
  type: 'GET_AVAILABLE_BROKERS',
  payload: {
    request: {
      url: `/developers/advisors/available?email=${email}`,
      method: 'get',
      headers: { Authorization }
    }
  }
})

export const AcceptDeveloperInvitation = (
  invitationCode,
  { fullname,
    password
  } = {}
) => ({
  type: 'ACCEPT_DEVELOPER_INVITATION',
  payload: {
    request: {
      url: `/developers/invitations/${invitationCode}/accept`,
      method: 'post',
      data: {
        fullname,
        password
      }
    }
  }
})

export const RejectDeveloperInvitation = (
  invitationCode
) => ({
  type: 'REJECT_DEVELOPER_INVITATION',
  payload: {
    request: {
      url: `/developers/invitations/${invitationCode}/reject`,
      method: 'post',
    }
  }
})