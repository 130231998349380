'use strict';

var React = require('react');

var useDebounceCallback = function useDebounceCallback(callback, delay) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var maxWait = options.maxWait;
  var maxWaitHandler = React.useRef(null),
    maxWaitArgs = React.useRef([]),
    functionTimeoutHandler = React.useRef(null),
    isComponentUnmounted = React.useRef(false);
  var debouncedFunction = callback;
  var cancelDebouncedCallback = React.useCallback(function () {
    clearTimeout(functionTimeoutHandler.current);
    clearTimeout(maxWaitHandler.current);
    maxWaitHandler.current = null;
    maxWaitArgs.current = [];
    functionTimeoutHandler.current = null;
  }, []);
  React.useEffect(function () {
    return function () {
      isComponentUnmounted.current = true;
    };
  }, []);
  var debouncedCallback = React.useCallback(function () {
    var _arguments = arguments;
    maxWaitArgs.current = arguments;
    clearTimeout(functionTimeoutHandler.current);
    functionTimeoutHandler.current = setTimeout(function () {
      cancelDebouncedCallback();
      if (!isComponentUnmounted.current) {
        debouncedFunction.apply(null, _arguments);
      }
    }, delay);
    if (maxWait && !maxWaitHandler.current) {
      maxWaitHandler.current = setTimeout(function () {
        var args = maxWaitArgs.current;
        cancelDebouncedCallback();
        if (!isComponentUnmounted.current) {
          debouncedFunction.apply(null, args);
        }
      }, maxWait);
    }
  }, [debouncedFunction, maxWait, delay, cancelDebouncedCallback]);
  var callPending = function callPending() {
    if (!functionTimeoutHandler.current) {
      return;
    }
    debouncedFunction.apply(null, maxWaitArgs.current);
    cancelDebouncedCallback();
  };
  return [debouncedCallback, cancelDebouncedCallback, callPending];
};

module.exports = useDebounceCallback;
