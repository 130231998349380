import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import Grid from 'rc/Grid'
import { Pencil } from 'rc/Icons'
import { Button } from 'components'
import {
  ContainerBoxStyled,
  ProfileSectionHeader
} from 'modules/profile/components'
import { useMedia } from 'rc/Hooks'
import FreemiumWrapper from 'modules/freemium/FreemiumWrapper'

const GridContainerStyled = styled(Grid)`
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.2);
    width: 95%;
    max-width: ${({ theme }) => theme.remCalc('1080')};
    height: auto;
    padding: ${({ theme, flat }) => (flat ? 0 : theme.remCalc('30 15'))};
    ${({ theme }) => theme.radius(5)}
    margin-bottom: ${({ theme }) => theme.remCalc('40')};
    overflow: hidden;
  ${({ theme }) => theme.media.desktop`
    padding: ${({ theme, flat }) => (flat ? 0 : theme.remCalc('30 50'))};  
  `}
`

const ProfileSectionCard = ({
  idBase,
  title,
  children,
  isEditable,
  actions,
  containerBoxProps,
  theme: {
    devices: { tabletDown }
  },
  ...props
}) => {
  const isTabletDown = useMedia(tabletDown)

  const buttons = actions
    .map(
      (
        { isEditable: isActionEditable, name, isShown = true, rule = null, ...actionProps },
        index
      ) => {
        const commonProps = {
          radius: isActionEditable && '100%',
          ...actionProps,
          size: isTabletDown ? 'small' : 'large'
        }
        return isShown ? rule ? (
          <FreemiumWrapper section={rule} label={name}>
            <Button
              id={`${idBase}-button-sectionCardAction`}
              {...commonProps}
              key={`action-button-${index}`}
              isActionEditable={isActionEditable}
              css={`${({ theme, isActionEditable }) =>
                isActionEditable && theme.box(50, 50)}
                margin-left: ${({ theme }) => theme.remCalc('10')};
              `}
            >
              {isActionEditable ? (<Pencil css={`${({ theme }) => theme.box(30, 30)}`} />) : (name)}
            </Button>
          </FreemiumWrapper>
        ) : 
          (
            <Button
              id={`${idBase}-button-sectionCardAction`}
              {...commonProps}
              key={`action-button-${index}`}
              isActionEditable={isActionEditable}
              css={`${({ theme, isActionEditable }) =>
                isActionEditable && theme.box(50, 50)}
          margin-left: ${({ theme }) => theme.remCalc('10')};`}
            >
              {isActionEditable ? (
                <Pencil
                  css={`
                    ${({ theme }) => theme.box(30, 30)}
                  `}
                />
              ) : (
                name
              )}
            </Button>
        ) : null
      }
    )
    .filter(button => button)

  return (
    <GridContainerStyled id={props.id} container justify="center" {...props}>
      {props.flat ? (
        children
      ) : (
        <Fragment>
          <Grid container alignItems="center">
            {title ? (
              <ProfileSectionHeader>{title}</ProfileSectionHeader>
            ) : null}
            {isEditable && (
              <Grid
                item
                flat
                xsAuto
                container
                justify="flex-end"
                alignItems="center"
              >
                {buttons}
              </Grid>
            )}
          </Grid>
          <ContainerBoxStyled {...containerBoxProps}>
            {children}
          </ContainerBoxStyled>
        </Fragment>
      )}
    </GridContainerStyled>
  )
}

ProfileSectionCard.defaultProps = {
  actions: []
}

ProfileSectionCard.propTypes = {
  theme: PropTypes.object,
  actions: PropTypes.array,
  children: PropTypes.any,
  containerBoxProps: PropTypes.object,
  flat: PropTypes.bool,
  isEditable: PropTypes.bool,
  title: PropTypes.string,
  idBase: PropTypes.string,
  id: PropTypes.string
}

export default withTheme(ProfileSectionCard)
