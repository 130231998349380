/**
 * Get pdf
 * @param {string} Authorization - current user token
 * @param {string} [propertyId] - in case you want to update the property
 * @param {string} data
 * @param {string} data.type - DATASHEET
 * @param {string} data.property_id
 * */
export const CreatePDF = (Authorization, data) => ({
  type: 'CREATE_PDF',
  payload: {
    request: {
      headers: {
        Authorization
      },
      method: 'post',
      url: `/pdf/live_generation`,
      data
    }
  }
})

export const RemovePDF = pdfId => ({
  type: 'REMOVE_PDF_IN_PROCESS_FILE',
  pdfId
})
