import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <g>
    <path
      d="M312.15,445c-29.84,9.67-56.78,27.28-88.63,36.76-28.38,8.45-59.94,10.18-88.52,1.46-20-6.1-38.54-18.12-49.72-35.29C56.65,404,103,355.67,87.59,309.1c-11.87-36-48.63-57.53-67.74-89.48C-1.9,183.27-3.63,136.48,14.19,98.23,57,6.4,202.31-26.68,284.86,29c35,23.6,59.56,55.7,103.66,63.84,43.33,8,89.59-2.38,131.54,10.57,53.76,16.61,88.42,70,93.26,123.64,6.33,70.15-29.09,159.16-87.44,204.23-21.67,16.74-49.86,28.18-78,27.28-28.94-.93-54.13-16.48-82.57-19.65C345.87,436.78,328.57,439.7,312.15,445Z"
      style={{ fill: '#b2e2f7' }}
    />
    <rect
      x="115.43"
      y="87.83"
      width="372"
      height="300.06"
      rx="18.41"
      style={{ fill: '#f9f9f9' }}
    />
    <path
      d="M132.34,87.83H470.51a16.92,16.92,0,0,1,16.92,16.92v23.4a3,3,0,0,1-3,3h-369a0,0,0,0,1,0,0V104.74A16.92,16.92,0,0,1,132.34,87.83Z"
      style={{ fill: '#0076cc' }}
    />
    <circle cx="165.86" cy="169.12" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="233.65" cy="169.12" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="301.43" cy="169.12" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="369.21" cy="169.12" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="302.44" cy="223.75" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="436.99" cy="169.12" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="165.86" cy="223.9" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="233.65" cy="223.9" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="369.21" cy="223.9" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="436.99" cy="223.9" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="165.86" cy="278.69" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="233.65" cy="278.69" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="301.43" cy="278.69" r="17.12" style={{ fill: '#ffc834' }} />
    <circle cx="165.86" cy="333.47" r="17.12" style={{ fill: '#c5d6dd' }} />
    <circle cx="233.65" cy="333.47" r="17.12" style={{ fill: '#c5d6dd' }} />
    <circle cx="301.43" cy="333.47" r="17.12" style={{ fill: '#c5d6dd' }} />
    <circle cx="369.21" cy="333.47" r="17.12" style={{ fill: '#c5d6dd' }} />
    <circle cx="436.99" cy="333.47" r="17.12" style={{ fill: '#c5d6dd' }} />
    <circle cx="369.21" cy="278.69" r="17.12" style={{ fill: '#c5d6dd' }} />
    <circle cx="436.99" cy="278.69" r="17.12" style={{ fill: '#c5d6dd' }} />
    <circle cx="466.54" cy="362.29" r="50.74" style={{ fill: '#171d3e' }} />
    <path
      d="M466.54,421.41a59.12,59.12,0,1,1,59.11-59.12A59.19,59.19,0,0,1,466.54,421.41Zm0-109.56A50.44,50.44,0,1,0,517,362.29,50.5,50.5,0,0,0,466.54,311.85Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M460.61,385.28a6.06,6.06,0,0,1-4.49-2l-18.21-19.94a6.07,6.07,0,1,1,9-8.19L460.43,370l26.4-31.32a6.07,6.07,0,1,1,9.29,7.83l-30.87,36.61a6.08,6.08,0,0,1-4.52,2.15Z"
      style={{ fill: '#fff' }}
    />
    <circle cx="419.8" cy="106.68" r="15.84" style={{ fill: '#fff' }} />
    <rect
      x="408.03"
      y="68.75"
      width="23.41"
      height="47.7"
      rx="10.76"
      style={{ fill: '#171d3e' }}
    />
    <circle cx="183.05" cy="106.68" r="15.84" style={{ fill: '#fff' }} />
    <rect
      x="171.28"
      y="68.75"
      width="23.41"
      height="47.7"
      rx="10.76"
      style={{ fill: '#171d3e' }}
    />
  </g>,
  'ZeroAccummulated',
  '0 0 613.51 490.33'
)
