import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MainLink } from 'c3-ui/elements/Links'
import { Grid, Typography } from 'rc'
import { Warning } from 'rc/Icons'
import { Button } from 'components'

const SessionExistModal = ({ HideModal, UnlockModal }) => {
  return (
    <Container
      container
      item
      direction="column"
      alignItems="space-between"
      justify="center"
    >
      <CheckmarkSquareStyled />
      <Title>Ya existe una sesión abierta con esta cuenta</Title>
      <Typography variant="p" align="center">
        Recuerda que tu cuenta solo puede ser utilizada desde la app y el sitio
        web de manera simultánea. Procederemos a cerrar la sesión anterior para
        que puedas continuar aquí.
      </Typography>
      <Typography variant="p" align="center">
        Para más información escríbenos a{' '}
        <MainLink href="mailto:hola@wiggot.com">{`hola@wiggot.com`} </MainLink>{' '}
        o consulta nuestro{' '}
        <MainLink
          route="/legal/privacy"
          id="session-exist-modal-link-privacyPolicies"
        >
          aviso de privacidad.
        </MainLink>
      </Typography>
      <Button
        onClick={() => {
          UnlockModal()
          HideModal()
        }}
        css={`
          border-radius: 50px !important;
          width: 100%;
        `}
      >
        Aceptar
      </Button>
    </Container>
  )
}

SessionExistModal.propTypes = {
  HideModal: PropTypes.func,
  UnlockModal: PropTypes.func
}

const Title = styled.h2`
  margin: 0;
  ${({ theme }) =>
    theme.typography.buildTextVariant({
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: 36,
      color: theme.palette.primary.main
    })}
`

const Container = styled(Grid)`
  padding: 2rem 2.5rem 2rem 2.5rem;
  ${({ theme }) => theme.box(500, 423)}
`
const CheckmarkSquareStyled = styled(Warning)`
  ${({ theme }) => theme.box(50, 50)}
  fill: ${({ theme }) => theme.palette.warning.main};
`

export default SessionExistModal
