/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { any } from 'prop-types'

import Router from './Router'
import { useDispatch, useSelector } from 'react-redux'

import {
  AcceptAgencyInvitationMC,
  AgencyInvitationErrorMC
} from 'modules/agencyBroker/modalContent'

import { InitializePusher } from 'modules/services'

import {
  DownloadRoles,
  SetAdminToken,
  setRoleStamp,
  SetUserAuthInfo
} from 'reduxActions/auth'
import { GetUserInfo } from 'reduxActions/profile'
import { VerifyAccount } from 'reduxActions/auth'
import {
  ShowModal,
  ShowFlashNotification,
  UnlockModal,
  HideModal
} from 'reduxActions/services'
import { GetAgencyInvitationInfo } from 'reduxActions/agency'
import { GetAssociationInvitation } from 'reduxActions/association'
import {
  AcceptAssociationInvitationMC,
  AssociationInvitationErrorMC,
  AssociationInvitationMC
} from 'modules/associationAgencies/modalContent'
import { OfflineMC } from 'modules/app/modalContent'
import { GetAmenities, GetDevelopmentAmenities } from 'reduxActions/general'
import { GetAllActiveAddons, ActiveAddon } from 'reduxActions/addons'

import eventGoogle from 'utils/eventsGoogle'
import { useTagManagerInitialize, useCookies } from 'hooks'
import { AcceptDeveloperInvitationMC } from 'modules/development/modalContent'
import { usePlans } from 'modules/freemium/hooks/usePlans'

let unsubscribePusher

function App() {
  const dispatch = useDispatch()
  usePlans()
  useCookies()
  const {
    token,
    adminToken,
    primeType,
    roles,
    lastRoleUpdate,
    termsAndConditions,
    initialLoadOfActives,
    actives
  } = useSelector(
    ({
      authReducer: { token, adminToken, roles, lastRoleUpdate },
      profileReducer: { primeType, termsAndConditions },
      addonsReducer: { initialLoadOfActives, actives }
    }) => ({
      token,
      adminToken,
      primeType,
      roles,
      lastRoleUpdate,
      termsAndConditions,
      initialLoadOfActives,
      actives
    })
  )

  const { userEmail, userId } = useSelector(
    ({ profileReducer: { email, userId } }) => ({
      userEmail: email,
      userId
    })
  )

  const { amenities, developmentAmenities } = useSelector(
    ({ generalReducer: { amenities, developmentAmenities } }) => ({
      amenities,
      developmentAmenities
    })
  )
  const [show, setShow] = useState(false)

  const activeAvaclick = token => {
    dispatch(ActiveAddon(token, 2))
  }

  // google tag manager
  useTagManagerInitialize()

  // Carga de datos
  useEffect(() => {
    if (token) {
      if (!amenities.length) {
        dispatch(GetAmenities(token))
      }
      if (!developmentAmenities.length) {
        dispatch(GetDevelopmentAmenities(token))
      }
      if (!initialLoadOfActives) {
        dispatch(GetAllActiveAddons(token)).then(
          ({ payload: { data = [] } = {} }) => {
            if (token) {
              const isActiveAvaclick =
                data &&
                data.length &&
                data.some(({ short_name }) => {
                  if (short_name === 'AVACLICK') {
                    return true
                  }
                })
              if (!isActiveAvaclick) {
                activeAvaclick(token)
              }
            }
          }
        )
      }
    }
  }, [token, dispatch, initialLoadOfActives])

  // Ingreso por admin
  useEffect(() => {
    const urlParams = new URLSearchParams(`${window.location.search}`)
    const superAdminToken = urlParams.get('ad_superuser_tk')
    if (superAdminToken) {
      dispatch(SetAdminToken(superAdminToken))
      dispatch(DownloadRoles(superAdminToken))
        .then(res => {
          dispatch(
            SetUserAuthInfo({
              token: superAdminToken,
              roles: res.payload.data.roles
            })
          )
          setShow(true)
          // http://localhost:3001/profile?type=adminLogin&ad_superuser_tk=783c5904-c70c-4603-9237-066ec34457e8&uid=10919
        })
        .catch(err => {
          setShow(true)
        })
    } else {
      setShow(true)
    }
    // Sólo se necesita ejecutar esto una vez
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // roles
  useEffect(() => {
    async function _get() {
      if (
        token &&
        (!lastRoleUpdate ||
          (lastRoleUpdate instanceof Date && !isUpdated(lastRoleUpdate)))
      ) {
        dispatch(DownloadRoles(token)).then(res => {
          dispatch(
            SetUserAuthInfo({
              token: token,
              roles: res.payload.data.roles
            })
          )
          dispatch(setRoleStamp())
        })
      }
    }
    _get()
  }, [dispatch, token, lastRoleUpdate])

  // perfil
  useEffect(async () => {
    try {
      if (token && roles && !userId) {
        await dispatch(GetUserInfo(token, !!adminToken, roles))

        eventGoogle.eventsProfile.getProfile()
      }
    } catch (err) {
      console.log({ err })
    }
  }, [adminToken, dispatch, token, userId, roles])

  // Manejo de pusher
  useEffect(() => {
    if (!token && unsubscribePusher) {
      unsubscribePusher()
      unsubscribePusher = null
      return
    } else if (token && userId && !unsubscribePusher) {
      unsubscribePusher = InitializePusher(dispatch, {
        token,
        userId,
        primeType,
        isAdmin: adminToken !== null || roles.DEVELOPER_USER
      })
    }
  }, [token, userId])

  // Activación de cuentas
  useEffect(() => {
    const urlParams = new URLSearchParams(`${window.location.search}`)

    async function handleActivateAccount(invitationCode) {
      try {
        await dispatch(VerifyAccount(token, invitationCode))
      } catch (err) {
        switch (err?.error.response.data?.error?.code) {
          case 'NOT_FOUND':
            dispatch(
              ShowFlashNotification(
                'danger',
                'Esta invitación ya no está disponible. Código de error: WIG-05'
              )
            )
            break
          default:
            dispatch(
              ShowFlashNotification(
                'danger',
                'Error desconocido, por favor intenta de nuevo más tarde.'
              )
            )
        }
      }
    }

    async function handleAgencyInvitation(invitationCode) {
      try {
        const { payload } = await dispatch(
          GetAgencyInvitationInfo(invitationCode)
        )

        const {
          email: invitationEmail,
          administrators: adminsEmails,
          agency_name: agencyName
        } = payload.data

        if (
          userEmail &&
          invitationEmail.toLowerCase() !== userEmail.toLowerCase()
        ) {
          dispatch(ShowModal(AgencyInvitationErrorMC, { adminsEmails }))
        } else {
          dispatch(
            ShowModal(AcceptAgencyInvitationMC, {
              invitationCode,
              agencyName
            })
          )
        }
      } catch (err) {
        const {
          error: {
            response: { data: { error: { code = '', message = '' } } = {} } = {}
          }
        } = err

        if (code === 'NOT_FOUND' && message === 'Invitation code not found') {
          dispatch(
            ShowFlashNotification(
              'danger',
              'Esta invitación ya no está disponible. Código de error: WIG-13'
            )
          )
        } else {
          dispatch(
            ShowFlashNotification(
              'danger',
              'Error desconocido, por favor intenta de nuevo más tarde.'
            )
          )
        }
      }
    }

    async function handleDeveloperInvitation(invitationCode) {
      try {
        const { payload } = await dispatch(
          GetAgencyInvitationInfo(invitationCode)
        )

        const {
          email: invitationEmail,
          administrators: adminsEmails,
          agency_name: agencyName
        } = payload.data

        if (invitationEmail.toLowerCase() !== userEmail?.toLowerCase()) {
          return dispatch(ShowModal(AgencyInvitationErrorMC, { adminsEmails }))
        }
        dispatch(
          ShowModal(AcceptDeveloperInvitationMC, {
            invitationCode,
            agencyName
          })
        )
      } catch (err) {
        dispatch(
          ShowFlashNotification(
            'danger',
            'Error desconocido, por favor intenta de nuevo más tarde.'
          )
        )
      }
    }

    async function handleAssociationInvitation(
      targetEmail,
      invitationCode,
      associationName
    ) {
      if (userEmail && userEmail !== targetEmail) {
        dispatch(
          ShowModal(AssociationInvitationErrorMC, {
            title:
              'Esta invitación no coincide con la cuenta que tienes abierta.',
            description:
              'Cierra sesión o inicia sesión con el correo al que recibiste la invitación y vuelve a intentarlo.'
          })
        )
        return
      }
      if (!associationName || !invitationCode) return

      try {
        const action = await dispatch(
          GetAssociationInvitation(targetEmail, invitationCode)
        )

        if (action.payload.data.status !== 'SENT') {
          dispatch(
            ShowModal(AssociationInvitationMC, {
              status: action.payload.data.status
            })
          )
        } else if (targetEmail && invitationCode && associationName) {
          dispatch(
            ShowModal(AcceptAssociationInvitationMC, {
              invitationCode,
              email: targetEmail,
              associationName: decodeURI(associationName)
            })
          )
        }
      } catch (err) {
        switch (err?.error.response.data?.error?.code) {
          case 'NOT_FOUND':
            dispatch(
              ShowModal(AssociationInvitationErrorMC, {
                title: 'Esta invitación ya no esta disponible',
                description:
                  'Para recibir una nueva invitación contacta al administrador que te invitó.'
              })
            )
            dispatch(
              ShowFlashNotification(
                'danger',
                'Esta invitación ya no está disponible. Código de error: WIG-21'
              )
            )
            break
          default:
            dispatch(
              ShowFlashNotification(
                'danger',
                'Error desconocido, por favor intenta de nuevo más tarde.'
              )
            )
        }
      }
    }

    switch (urlParams.get('type')) {
      case 'activateAccount':
        handleActivateAccount(urlParams.get('id'))
        break
      case 'agencyInvitation':
        handleAgencyInvitation(urlParams.get('id'))
        break
      case 'developerInvitation':
        handleDeveloperInvitation(urlParams.get('id'))
        break
      case 'associationInvitation':
        handleAssociationInvitation(
          urlParams.get('email'),
          urlParams.get('code'),
          urlParams.get('name')
        )
        break
      default:
    }
  }, [])

  // Modal de no conexión
  useEffect(() => {
    function toggleNoConnectionModal(event) {
      if (event.type === 'online') {
        dispatch(UnlockModal())
        dispatch(HideModal())
      } else {
        dispatch(ShowModal(OfflineMC, null, { isLocked: true }))
      }
    }
    window.addEventListener('offline', toggleNoConnectionModal)
    window.addEventListener('online', toggleNoConnectionModal)
    return () => {
      window.removeEventListener('offline', toggleNoConnectionModal)
      window.removeEventListener('online', toggleNoConnectionModal)
    }
  }, [])

  return <>{show && <Router />}</>
}

App.propTypes = {
  children: any
}

function isUpdated(day) {
  let today = new Date()
  return today.getMonth() === day.getMonth() && today.getDay() === day.getDay()
}

export default App
