const mailsEnabledForNewFeatures = [
  'dd3tech.mx',
  'wiggot.com',
  'dd360.mx',
  'grupoguia.mx',
  'marucalderon@marcainmobiliaria.com.mx',
  'villasenor@coldwellbanker.com.mx',
  'remax-activa.com.mx',
  'activoinmobiliario.mx',
  'grupodmi.com.mx'
]

export const isEnableForFeature = userEmail => {
  const emailIsEnableForFeature = email => {
    if (!email) {
      return false
    }
    if (mailsEnabledForNewFeatures?.includes(email)) {
      return true
    }
    const domain = email?.split('@')[1]
    return mailsEnabledForNewFeatures?.includes(domain)
  }

  const isEnableForFeature = emailIsEnableForFeature(userEmail)

  return isEnableForFeature
}
