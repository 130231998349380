var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/FormInputs/AutocompleteSearcher.js';

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { AutocompleteSearcher as AutocompleteSearcherView } from '../Autocompletes';
import { default as InputLabelContainer } from './InputLabelContainer';
import { inputProp, inputMetaProp, inputLabelContainerProp } from './_types';

var AutocompleteSearcher = (_temp2 = _class = function (_PureComponent) {
  _inherits(AutocompleteSearcher, _PureComponent);

  function AutocompleteSearcher() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, AutocompleteSearcher);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AutocompleteSearcher.__proto__ || Object.getPrototypeOf(AutocompleteSearcher)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      value: _this.props.componentType === 'CT' ? _this.props.value : undefined
    }, _this.onChangeCT = function (selectedItem) {
      _this.setState({ value: selectedItem }, function () {
        _this.props.onChange(selectedItem);
      });
    }, _this.onChangeRF = function (selectedItem) {
      _this.props.input.onChange(selectedItem);
      _this.props.onChange(selectedItem);
    }, _this.inputProp = function () {
      var _this$props = _this.props,
          input = _this$props.input,
          componentType = _this$props.componentType,
          _this$props$meta = _this$props.meta;
      _this$props$meta = _this$props$meta === undefined ? {
        touched: undefined,
        error: undefined,
        warning: undefined
      } : _this$props$meta;

      var touched = _this$props$meta.touched,
          error = _this$props$meta.error,
          warning = _this$props$meta.warning,
          validations = _this$props.validations,
          onChange = _this$props.onChange,
          restProps = _objectWithoutProperties(_this$props, ['input', 'componentType', 'meta', 'validations', 'onChange']);

      var handledProps = {};

      switch (componentType) {
        case 'CT':
          handledProps = {
            onBlur: _this.onBlur,
            onFocus: _this.onFocus,
            onSelectedItem: _this.onChangeCT,
            value: _this.state.value
          };
          break;
        case 'extCT':
          handledProps = {
            error: error,
            onBlur: _this.onBlur,
            onFocus: _this.onFocus,
            onSelectedItem: onChange,
            value: _this.props.value
          };
          break;
        case 'RF':
          handledProps = {
            error: error,
            onBlur: input.onBlur,
            onFocus: input.onFocus,
            onSelectedItem: _this.onChangeRF,
            touched: touched,
            validations: validations,
            value: input.value,
            warning: warning
          };
          break;
        default:
          break;
      }

      return Object.assign({}, restProps, handledProps);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(AutocompleteSearcher, [{
    key: 'render',
    value: function render() {
      var className = this.props.className;
      var _props = this.props,
          labelProps = _props.labelProps,
          label = _props.label;


      return React.createElement(
        InputLabelContainer,
        Object.assign({ label: label }, labelProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 121
          },
          __self: this
        }),
        React.createElement(AutocompleteSearcherView, Object.assign({ className: className }, this.inputProp(), {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 122
          },
          __self: this
        }))
      );
    }
  }]);

  return AutocompleteSearcher;
}(PureComponent), _class.propTypes = {
  /** Class to be passed */
  className: PropTypes.string,
  /** Component Type (helper for redux form) */
  componentType: PropTypes.string,
  /** Disables the input */
  disabled: PropTypes.bool,
  /** Used for testing purposes */
  id: PropTypes.string,
  /** Validation when component is RF*/
  input: inputProp,
  /** */
  label: PropTypes.string,
  /** */
  labelProps: inputLabelContainerProp,
  /** Meta information */
  meta: inputMetaProp,
  /** Name of the component */
  name: PropTypes.string,
  /** Callback function to retrieve the current input value. */
  onChange: PropTypes.func,
  /** */
  onSelectedItem: PropTypes.func,
  /** Placeholder to set when no text entered */
  placeholder: PropTypes.string,
  /** Input type, it could be the following password, phone, email, password, etc.*/
  type: PropTypes.string,
  /** Possible validations */
  validations: PropTypes.array,
  /** Current value set in Input. */
  value: PropTypes.any
}, _class.defaultProps = {
  onChange: function onChange() {
    return null;
  },
  type: 'text',
  label: ''
}, _temp2);


export default AutocompleteSearcher;