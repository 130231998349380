import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { FlashNotification } from 'c3-ui/generics/Notifications'
import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'
import { HideFlashNotification } from 'reduxActions/services'

class FlashNotificationService extends Component {
  static propTypes = {
    location: PropTypes.object,
    isLogged: PropTypes.bool,
    hasEmailVerified: PropTypes.bool,
    isFlashNotificationOpen: PropTypes.bool,
    flashNotificationType: PropTypes.string,
    flashNotificationMessage: PropTypes.string,
    flashNotificationImage: PropTypes.string,
    isModalOpen: PropTypes.bool,
    HideFlashNotification: PropTypes.func,
    flashNotificationContainerProps: PropTypes.object,
    flashNotificationWrapperProps: PropTypes.object,
    flashNotificationProps: PropTypes.object
  }

  componentDidUpdate(prevProps) {
    const { location, HideFlashNotification } = this.props
    if (location !== prevProps.location) {
      HideFlashNotification()
    }
  }

  render() {
    const {
      isLogged,
      isModalOpen,
      hasEmailVerified,
      flashNotificationType,

      flashNotificationImage,
      isFlashNotificationOpen,
      flashNotificationMessage,

      flashNotificationContainerProps: {
        center = true,
        className = '',
        inFeaturedImage,
        ...flashNotificationContainerProps
      },
      flashNotificationWrapperProps,
      flashNotificationProps: { noResponsive = true, ...flashNotificationProps }
    } = this.props
    const flashNotificationClasses = classNames(
      className,
      'main-site-routes__flash-notification animated fast',
      {
        'main-site-routes__flash-notification--in-featured-image-layout': inFeaturedImage,
        'main-site-routes__flash-notification--hidden': !isFlashNotificationOpen,
        fadeInDown: isFlashNotificationOpen,
        'main-site-routes__flash-notification--normal-location':
          (!isLogged || hasEmailVerified) && !isModalOpen,
        'main-site-routes__flash-notification--normal-location-w-mini-badge':
          isLogged && !hasEmailVerified && !isModalOpen,
        'main-site-routes__flash-notification--on-top-location': isModalOpen
      }
    )
    return (
      <Layout
        center={center}
        className={flashNotificationClasses}
        {...flashNotificationContainerProps}
      >
        <LayoutItem
          {...flashNotificationWrapperProps}
          medium={inFeaturedImage ? 4 : undefined}
          mdOffset={inFeaturedImage ? 8 : undefined}
        >
          <Layout center>
            <FlashNotification
              noResponsive={noResponsive}
              danger={flashNotificationType === 'danger'}
              warning={flashNotificationType === 'warning'}
              success={flashNotificationType === 'success'}
              customIconSrc={flashNotificationImage}
              onClick={this.props.HideFlashNotification}
              {...flashNotificationProps}
            >
              {flashNotificationMessage}
            </FlashNotification>
          </Layout>
        </LayoutItem>
      </Layout>
    )
  }
}

const mapStateToProps = ({
  authReducer: { token },
  profileReducer: { verified: hasEmailVerified },
  servicesReducer: {
    flashNotification: {
      isOpen: isFlashNotificationOpen,
      type: flashNotificationType,
      message: flashNotificationMessage,
      flashNotificationContainerProps,
      flashNotificationWrapperProps,
      flashNotificationProps
    },
    modal: { isModalOpen }
  }
}) => ({
  isLogged: token ? true : false,
  hasEmailVerified,
  isFlashNotificationOpen,
  flashNotificationType,
  flashNotificationMessage,
  flashNotificationContainerProps,
  flashNotificationWrapperProps,
  flashNotificationProps,
  isModalOpen
})

const mapDispatchToProps = {
  HideFlashNotification
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FlashNotificationService)
)
