import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.938 8.0285C11.9402 8.72888 11.3636 9.29832 10.6502 9.30043C9.93684 9.3026 9.35671 8.73661 9.3545 8.03622C9.35235 7.33571 9.92891 6.76627 10.6423 6.76417C11.3557 6.76206 11.9359 7.32799 11.938 8.0285Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00295 8.94028L9.71842 2.2108L16.5568 8.98026L16.5665 12.1197L3.0128 12.1608L3.00295 8.94028ZM18.4819 8.11744C18.4563 8.08607 18.4446 8.04805 18.4148 8.019L10.5525 0.384995C10.5317 0.36084 10.5232 0.330628 10.5001 0.308079C10.2825 0.0968885 9.99555 -0.00403614 9.70968 0.000123448C9.42365 -0.00235771 9.13718 0.100245 8.921 0.312749C8.89791 0.335517 8.88955 0.365729 8.86915 0.39003L1.05466 8.07067C0.83235 8.28916 0.733298 8.57887 0.74398 8.86581C0.74398 8.8688 0.742188 8.87106 0.742188 8.87405L0.75638 13.4162C0.758024 13.9435 1.20114 14.3739 1.74122 14.3722L2.01067 14.3714L2.12974 14.3711L17.4596 14.3254L17.732 14.3246L17.8481 14.3243C18.388 14.3227 18.8285 13.8896 18.8269 13.3623L18.8127 8.82027C18.8118 8.53968 18.6808 8.29266 18.4819 8.11744Z"
      fill="#FEFEFE"
    />
    <path
      className="special-stroke"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7264 14.9728C21.9672 14.9728 24.5944 12.4839 24.5944 9.41365C24.5944 6.34341 21.9672 3.85449 18.7264 3.85449C15.4856 3.85449 12.8584 6.34341 12.8584 9.41365C12.8584 12.4839 15.4856 14.9728 18.7264 14.9728Z"
      fill="white"
      stroke="#0076FF"
      strokeWidth="2"
    />
    <path
      className="special-fill"
      d="M18.6232 10.6434C18.7611 10.7822 18.7611 11.0202 18.6232 11.159L18.3374 11.4466C18.1994 11.5854 17.9628 11.5854 17.8249 11.4466L16.5732 10.1773C16.4352 10.0385 16.4352 9.80053 16.5732 9.6617L16.859 9.37414C16.997 9.23531 17.2335 9.23531 17.3715 9.37414L18.6232 10.6434Z"
      fill="#0076FF"
    />
    <path
      className="special-fill"
      d="M20.021 8.24179C20.1682 8.09258 20.4206 8.09258 20.5678 8.24179L20.8727 8.55085C21.0198 8.70005 21.0198 8.95583 20.8727 9.10503L18.5597 11.439C18.4125 11.5882 18.1601 11.5882 18.0129 11.439L17.708 11.1299C17.5609 10.9807 17.5609 10.725 17.708 10.5758L20.021 8.24179Z"
      fill="#0076FF"
    />
  </Fragment>,
  'WiggotCheck',
  '0 0 26 16'
)
