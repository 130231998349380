import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { ShowFlashNotification } from 'reduxActions/services'
import { Typography, Autocomplete, Spinner } from 'rc'
import { Search } from 'rc/Icons'
import { Button } from 'components'
import { SearchAgencyBrokers } from 'reduxActions/agency'
import { AssignDevelopmentBroker } from 'reduxActions/development'

const AssignDevelopmentMC = ({ developmentId, getList, HideModal, cb }) => {
  const dispatch = useDispatch()
  const [brokerSelected, setBrokerSelected] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { token } = useSelector(({ authReducer: { token } }) => ({
    token
  }))

  const searchBroker = value => {
    return dispatch(SearchAgencyBrokers(token, value))
  }

  const assignBroker = () => {
    setIsLoading(true)
    cb && cb()
    dispatch(
      AssignDevelopmentBroker(token, developmentId, brokerSelected.user_id)
    )
      .then(async res => {
        dispatch(
          ShowFlashNotification(
            'success',
            `Se ha agregado a ${brokerSelected.fullname} como editor de este desarrollo.`
          )
        )
        setIsLoading(false)
        await getList()
        HideModal()
      })
      .catch(err => {
        console.log({ err }, 'ERR ASSIGN')
        dispatch(
          ShowFlashNotification(
            'danger',
            `Ocurrió un error al agregar el editor.`
          )
        )
      })
  }

  return (
    <Container>
      <Typography variant="h2" color="palette.primary.main" align="center">
        Agregar editor
      </Typography>
      <Typography variant="body1" color="palette.new.text">
        Selecciona un asesor para asignarlo como editor de este desarrollo.
      </Typography>
      <Autocomplete
        // id={`${idBase}-autocomplete-contactName`}
        fetchFunction={value => searchBroker(value)}
        onChange={(name, value, isLastSelected) => {
          setBrokerSelected(value)
        }}
        autoFocus={true}
        shouldFetchOnFocus
        InputProps={{
          autoFocus: true,
          startAdornment: (
            <Search
              css={`
                margin-left: ${({ theme }) => theme.remCalc('5')};
                color: ${({ theme }) => theme.palette.new.text};
                ${({ theme }) => theme.box(20, 20)}
              `}
            />
          )
        }}
        label="Buscar asesor"
        required
        labelItemKey="fullname"
        fieldValueOnSelect={(data, isLastSelected) => {
          return isLastSelected ? data : data.name
        }}
        placeholder="Buscar asesor"
        subLabelItemKey="email"
        shouldSelectOnBlur={(results, fieldValue) => {
          if (fieldValue) {
            return -1
          }
          return 0
        }}
        value={brokerSelected && brokerSelected.fullname}
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('100')};
          label {
            margin-left: ${({ theme }) => theme.remCalc('10')};
          }
        `}
      />
      <ButtonsContainer>
        <ButtonStyled type="tertiary" onClick={HideModal}>
          Cancelar
        </ButtonStyled>
        <ButtonStyled
          type="primary"
          onClick={assignBroker}
          disabled={!brokerSelected}
        >
          Agregar
        </ButtonStyled>
      </ButtonsContainer>
      {isLoading && (
        <div
          css={`
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
          `}
        >
          <Spinner />
        </div>
      )}
    </Container>
  )
}
AssignDevelopmentMC.propTypes = {
  cb: PropTypes.func,
  developmentId: PropTypes.string,
  HideModal: PropTypes.func,
  getList: PropTypes.func
}

const Container = styled.div`
  width: ${({ theme }) => theme.remCalc('450')};
  height: ${({ theme }) => theme.remCalc('350')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.remCalc('15')};
`
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const ButtonStyled = styled(Button).attrs({ rounded: true })`
  width: 48%;
`

export default AssignDevelopmentMC
