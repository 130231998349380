import React, { useContext, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Grid, Typography } from 'rc'
import { ProfileSectionCard, PropertyCard } from 'modules/profile/components'
import { ProfileContext } from 'modules/profile'
import { GetProfileProperties } from 'reduxActions/profile'

const ProfileProperties = props => {
  const { token, GetProfileProperties } = props
  const {
    profile: { profile_id },
    isMyProfile
  } = useContext(ProfileContext)
  const [propertiesList, setPropertiesList] = useState({
    total: 0,
    pages: 0,
    page: 0
  })
  const [properties, setProperties] = useState([])

  const { total, page: currentPage, pages } = propertiesList

  // THE METHOD IS EXECUTED ONLY WHEN IT'S NOT MY PROFILE
  const getProperties = useCallback(
    async (page = 0) => {
      if (!isMyProfile) {
        const propertiesRequest = await GetProfileProperties(
          token,
          profile_id,
          page,
          9
        )

        const {
          payload: {
            data: { properties: responseProperties = [], ...requestConfig }
          } = {}
        } = propertiesRequest
        setPropertiesList(requestConfig)

        setProperties(p => [...p, ...responseProperties])
      }
    },
    [GetProfileProperties, profile_id, token, isMyProfile]
  )

  useEffect(() => {
    getProperties()
  }, [getProperties])

  return (
    properties.length > 0 &&
    !isMyProfile && (
      <ProfileSectionCard title={`Propiedades (${total})`}>
        <Grid
          container
          item
          xsAuto
          flat
          css={`
            margin: ${({ theme }) => theme.remCalc('0 -12')};
          `}
        >
          {properties.map((property, index) => {
            return (
              <PropertyCard
                id={`profile-profileDetail-button-propertyCard-${index}`}
                key={index}
                data={property}
                index={index}
              />
            )
          })}
        </Grid>
        {Number(currentPage) < pages - 1 && (
          <Grid
            container
            center
            css={`
              margin-top: ${({ theme }) => theme.remCalc('37')};
            `}
          >
            <Typography
              id="profile-profileDetail-button-moreProperties"
              onClick={() => getProperties(Number(currentPage) + 1)}
              css={`
                cursor: pointer;
              `}
              color="palette.primary.main"
              variant="h2"
            >
              Ver más
            </Typography>
          </Grid>
        )}
      </ProfileSectionCard>
    )
  )
}

ProfileProperties.propTypes = {
  GetProfileProperties: PropTypes.func,
  token: PropTypes.string
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  GetProfileProperties
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileProperties)
