import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.0459 17.4973C8.44566 17.5455 6.89144 16.9493 5.72108 15.8383C4.55073 14.7274 3.85885 13.1915 3.79587 11.5646C3.82501 10.3777 4.13364 9.21513 4.69587 8.17455C5.18257 7.22093 5.77232 6.3255 6.4542 5.50485C6.52618 5.4214 6.61451 5.35416 6.71353 5.30746C6.81255 5.26075 6.92007 5.23561 7.0292 5.23364C7.1371 5.23574 7.24336 5.26098 7.34101 5.30772C7.43866 5.35445 7.5255 5.42163 7.59587 5.50485C7.73811 5.65589 7.91708 5.76602 8.11464 5.82406C8.3122 5.88211 8.52134 5.88602 8.72087 5.83538C8.95337 5.78556 9.16238 5.65701 9.31374 5.47074C9.46511 5.28447 9.54988 5.05149 9.5542 4.80988V3.25892C9.55491 3.11577 9.59522 2.97571 9.6705 2.85477C9.74579 2.73382 9.85303 2.63686 9.97995 2.57497C10.1069 2.51308 10.2483 2.48877 10.3882 2.5048C10.5281 2.52084 10.6607 2.57658 10.7709 2.66565C11.6647 3.41884 12.5004 4.24051 13.2709 5.12347C14.0668 6.00614 14.7525 6.98537 15.3125 8.03894C15.9183 9.10574 16.256 10.308 16.2959 11.5392C16.2393 13.1706 15.5503 14.7129 14.3792 15.8293C13.2081 16.9457 11.6503 17.5453 10.0459 17.4973ZM7.1292 7.06429C6.71494 7.61975 6.34699 8.20942 6.0292 8.82714C5.5784 9.65991 5.32715 10.5891 5.29587 11.5392C5.35212 12.7661 5.88304 13.9205 6.77285 14.7509C7.66266 15.5812 8.83919 16.0201 10.0459 15.9718C11.2525 16.0201 12.4291 15.5812 13.3189 14.7509C14.2087 13.9205 14.7396 12.7661 14.7959 11.5392C14.7654 10.5593 14.4993 9.60197 14.0209 8.75086C13.5147 7.80921 12.8992 6.93281 12.1875 6.14049C11.7959 5.69131 11.4042 5.2845 11.0459 4.91159C11.0275 5.47574 10.8251 6.01765 10.4706 6.45191C10.116 6.88617 9.62943 7.18808 9.08753 7.31007C8.42781 7.48494 7.72733 7.39703 7.1292 7.06429Z" />
  </svg>,
  'MatchOutlined',
  '0 0 21 20'
)
