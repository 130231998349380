import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useDispatch } from 'react-redux'
import { Typography, Spinner } from 'rc'
import { Location, BarCode, Price } from 'rc/Icons'
import { GetDevelopmentList } from 'reduxActions/development'
import { Button, Image } from 'components'
import { formatPrice } from 'utils/helpers'
import { capitalizeFirstLetter } from 'utils/helpers'

const UnlinkTokenMC = ({
  unlinkToken,
  development_id,
  leftDays,
  HideModal,
  authorization,
  tokenType
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [devArr, setDevArr] = useState({})

  // const { tokens } = useSelector(({ tokensReducer: { tokens } }) => ({ tokens }))

  const getDevelopmentData = (data = []) => {
    const developmentData =
      (development_id &&
        data.filter(dev => dev.development_id === development_id)) ||
      []

    const development = developmentData[0] || {}
    const operationTypes = [
      {
        value: formatPrice(development.min_presale_price),
        label: 'Preventa:',
        active: development.has_presale
      },
      {
        value: formatPrice(development.min_sale_price),
        label: 'Venta:',
        active: development.has_sale
      },
      {
        value: formatPrice(development.min_lease_price),
        label: 'Renta:',
        active: development.has_lease
      }
    ]
    setIsLoading(false)
    return {
      ...development,
      operationTypes
    }
  }

  useEffect(() => {
    setIsLoading(true)
    dispatch(GetDevelopmentList(authorization))
      .then(res => {
        const { payload: { data } = {} } = res
        setIsLoading(false)
        return data && setDevArr(getDevelopmentData(data))
      })
      .catch(err => {
        console.log({ err }, 'Development List err')
      })
  }, [authorization])

  return (
    <Container>
      <Typography variant="h2" color="palette.primary.main" align="center">
        {`Desvincular Token ${capitalizeFirstLetter(tokenType || '')}`}
      </Typography>
      <Typography variant="body1" color="palette.new.text" align="center">
        {`La vinculación del token ${capitalizeFirstLetter(
          tokenType || ''
        )} de tu desarrollo tiene una duración
        mínima de 30 días, si deseas desvincular el token de tu desarrollo antes
        de este periodo de tiempo, tendrás que esperar a que el periodo de 30
        días finalice para utilizarlo nuevamente.`}
      </Typography>
      <div
        css={`
          width: 100%;
          min-height: ${({ theme }) => theme.remCalc('130')};
          border: 2px solid #e8ecf9;
          border-radius: ${({ theme }) => theme.remCalc('10')};
          display: flex;
          padding: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        <div
          css={`
            min-width: ${({ theme }) => theme.remCalc('110')};
            width: ${({ theme }) => theme.remCalc('110')};
            height: 100%;
            overflow: hidden;
            border-radius: ${({ theme }) => theme.remCalc('10')};
          `}
        >
          {devArr.cover_image && (
            <Image
              id={devArr.cover_image}
              objectFit="cover"
              css={`
                width: 100%;
                height: 100%;
              `}
            />
          )}
        </div>
        <div
          css={`
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-left: ${({ theme }) => theme.remCalc('10')};
          `}
        >
          <Typography variant="subtitle3">{devArr.development_name}</Typography>
          <TypographyStyled>
            <Location css={CommonIcon} /> {devArr.city}, {devArr.state}.
          </TypographyStyled>
          {devArr.operationTypes &&
            devArr.operationTypes.map(
              ({ value, label, active }, index) =>
                active && (
                  <TypographyStyled key={index}>
                    <Price css={CommonIcon} /> {label} {`$${value}`} mxn
                  </TypographyStyled>
                )
            )}
          <TypographyStyled variant="body2">
            <BarCode css={CommonIcon} /> {devArr.development_id}
          </TypographyStyled>
        </div>
      </div>
      <Typography variant="subtitle1" color="palette.new.text">
        {`Tu suscripción ${capitalizeFirstLetter(tokenType || '')}:`}
      </Typography>
      <div
        css={`
          display: flex;
          align-items: center;
          margin-top: ${({ theme }) => theme.remCalc('-10')};
        `}
      >
        <Typography
          variant="h1"
          css={`
            margin-right: ${({ theme }) => theme.remCalc('5')};
          `}
        >
          {devArr.token_days_till_release}
        </Typography>
        <Typography variant="body2" color="palette.new.text">
          Días restantes
        </Typography>
      </div>
      <div
        css={`
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <Button
          type="tertiary"
          rounded
          onClick={HideModal}
          css={`
            margin-right: ${({ theme }) => theme.remCalc('20')};
            width: 100%;
          `}
        >
          Cancelar
        </Button>
        <Button
          type="alert"
          rounded
          css={`
            width: 100%;
          `}
          onClick={() => {
            setIsLoading(true)
            unlinkToken(() => {
              setIsLoading(false)
              HideModal()
            })
          }}
        >
          Desvincular token
        </Button>
      </div>
      {isLoading && (
        <div
          css={`
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
          `}
        >
          <Spinner />
        </div>
      )}
    </Container>
  )
}

UnlinkTokenMC.propTypes = {
  unlinkToken: PropTypes.func,
  developments: PropTypes.array,
  development_id: PropTypes.string,
  leftDays: PropTypes.number,
  HideModal: PropTypes.func,
  tokenSummary: PropTypes.object,
  authorization: PropTypes.string,
  tokenType: PropTypes.string
}
const CommonIcon = css`
  color: #7d7f9c;
  fill: #7d7f9c;
  margin-right: ${({ theme }) => theme.remCalc('5')};
  ${({ theme }) => theme.box(13, 13)}
`

const Container = styled.div`
  width: ${({ theme }) => theme.remCalc('470')};
  height: ${({ theme }) => theme.remCalc('550')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('30')};
  position: relative;
  @media (max-width: 920px) {
    width: 100%;
  }
`
const TypographyStyled = styled(Typography).attrs({
  variant: 'body2',
  color: 'palette.new.text'
})``

export default UnlinkTokenMC
