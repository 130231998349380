import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M9.89653 7.524C9.89653 8.30382 9.26609 8.9351 8.49636 8.9351C7.72663 8.9351 7.10352 8.29639 7.10352 7.51657C7.10352 6.73675 7.73396 6.10547 8.50369 6.10547C9.27342 6.10547 9.89653 6.74418 9.89653 7.524Z"
      fill="#FFCD48"
    />
    <path
      d="M16.6848 7.95454C16.6628 7.92483 16.6481 7.8877 16.6188 7.85799L9.27339 0.379141C9.2514 0.356861 9.24407 0.327153 9.22208 0.304873C9.01682 0.0969208 8.75291 0.000371618 8.48167 0.000371618C8.21044 -0.00705524 7.94653 0.0969208 7.74127 0.304873C7.71928 0.327153 7.71195 0.356861 7.68995 0.379141L0.307891 7.82085C0.0952997 8.03623 0 8.31103 0.0146615 8.59325V8.60067L0 13.0345C0 13.547 0.410522 13.9703 0.923674 13.9703H1.18025H1.29021L15.7025 14H15.959H16.069C16.5748 14 16.9927 13.5841 16.9927 13.0642L17 8.63781C16.9927 8.37044 16.8754 8.12535 16.6848 7.95454ZM2.22122 8.84576L8.47434 2.53293L14.7788 8.94974L14.7715 11.9131L2.20655 11.8908L2.22122 8.84576Z"
      fill="#0076FF"
    />
  </Fragment>,
  'WiggotHouse',
  '0 0 17 14'
)
