import React from 'react'
import { Switch } from 'react-router-dom'
import { lazy } from '@loadable/component'

import { RedirectRoute } from '../components'
import ErrorBoundary from '../components/ErrorBoundary'

function PublicRoutes() {
  return (
    <ErrorBoundary>
      <Switch>
        {PUBLIC_ROUTES.map(({ path, component }) => (
          <RedirectRoute key={path} exact path={path} component={component} />
        ))}
      </Switch>
    </ErrorBoundary>
  )
}

export default PublicRoutes

export const PUBLIC_ROUTES = [
  {
    component: lazy(() => import('pages/ProfilePreviewer')),
    path: '/profile/public/preview'
  },
  {
    component: lazy(() => import('pages/DataSheetPreview')),
    path: '/dataSheetPreview'
  },
  { component: lazy(() => import('pages/Validator')), path: '/validator' },
  {
    component: lazy(() => import('pages/CustomDatasheet')),
    path: '/customDataSheet'
  },
  {
    component: lazy(() => import('pages/Privacy')),
    path: '/legal/privacy'
  },
  { component: lazy(() => import('pages/Terms')), path: '/legal/terms' }
]
