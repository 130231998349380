import React from 'react'
import { useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import { lazy } from '@loadable/component'

import { RedirectRoute } from '../components'
import ErrorBoundary from '../components/ErrorBoundary'

function ProtectedRoutes() {
  const { token } = useSelector(({ authReducer: { token } }) => ({
    token
  }))

  return (
    <ErrorBoundary>
      <Switch>
        {PROTECTED_ROUTES.map(
          ({
            path,
            component,
            fallbackTo = '/search'
          }) => (
            <RedirectRoute
              exact
              fallbackTo={fallbackTo}
              canAccessIf={!token}
              key={path}
              path={path}
              component={component}
            />
          )
        )}
      </Switch>
    </ErrorBoundary>
  )
}

export default ProtectedRoutes

const PasswordRecovery = lazy(() => import('pages/PasswordRecovery'))

export const PROTECTED_ROUTES = [
  {
    component: lazy(() => import('pages/Login')),
    path: '/login'
  },
  {
    component: lazy(() => import('pages/ForgotPassword')),
    path: '/forgotPassword'
  },
  {
    component: PasswordRecovery,
    path: '/passwordRecovery/:token'
  },
  { component: PasswordRecovery, path: '/passwordNewAgAsc/:token' },
  {
    component: lazy(() => import('pages/AgencyBrokerRegistration')),
    canAccessIf: true,
    path: '/agencyBrokerRegistration/:email/:invitationCode/:isDeveloper'
  },
  { component: lazy(() => import('pages/Register')), path: '/register' }
]
