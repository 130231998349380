import { createReducer } from '../store'

const servicesReducer = createReducer(
  {
    modal: {
      isModalOpen: false,
      isLocked: false,
      history: [],
      withHistory: false,
      Component: null,
      componentProps: {},
      modalProps: {
        animationDuration: 100,
        semiRounded: true,
        noClosingButton: true,
        flatContent: true,
        overflow: true
      }
    },
    flashNotification: {
      isOpen: false,
      type: '',
      message: '',
      image: '',

      flashNotificationContainerProps: {},
      flashNotificationWrapperProps: {},
      flashNotificationProps: {}
    },
    hideModalPlatforms: false
  },
  {
    SHOW_MODAL: (state, action) => {
      if (!action.payload.modalProps) action.payload.modalProps = {}
      const {
        Component,
        componentProps,
        modalProps: { isLocked = state.modal.isLocked, ...modalProps },
        withHistory
      } = action.payload

      const history = withHistory
        ? state.modal.Component
          ? [
              ...state.modal.history,
              {
                Component: state.modal.Component,
                componentProps: state.modal.componentProps,
                modalProps: state.modal.modalProps
              }
            ]
          : [...state.modal.history]
        : []

      return {
        ...state,
        modal: {
          isLocked,
          Component,
          componentProps,
          history,
          withHistory,
          isModalOpen: true,
          modalProps: {
            ...state.modal.modalProps,
            ...modalProps
          }
        }
      }
    },
    HIDE_MODAL: state => {
      if (!state.modal.isLocked) {
        return {
          ...state,
          modal: {
            ...state.modal,
            isLocked: false,
            Component: '',
            componentProps: {},
            modalProps: {
              animationDuration: 100,
              semiRounded: true,
              noClosingButton: true,
              flatContent: true,
              overflow: true
            },
            isModalOpen: false
          }
        }
      } else {
        return { ...state }
      }
    },
    SHOW_FLASH_NOTIFICATION: (state, action) => {
      const {
        type = '',
        message = '',
        image = '',
        flashNotificationContainerProps = {},
        flashNotificationWrapperProps = {},
        flashNotificationProps = {}
      } = action.payload
      return {
        ...state,
        flashNotification: {
          isOpen: true,
          type,
          message,
          image,
          flashNotificationContainerProps,
          flashNotificationWrapperProps,
          flashNotificationProps
        }
      }
    },
    HIDE_FLASH_NOTIFICATION: state => {
      return {
        ...state,
        flashNotification: {
          isOpen: false,
          type: '',
          message: '',
          image: '',
          flashNotificationContainerProps: {},
          flashNotificationWrapperProps: {},
          flashNotificationProps: {}
        }
      }
    },
    HIDE_MODAL_PERMANENT: state => {
      return {
        ...state,
        hideModalPlatforms: true
      }
    },
    GO_BACK_MODAL_HISTORY: state => {
      const {
        modal: { history, withHistory }
      } = state

      if (withHistory) {
        const lastModal = history.pop()
        return {
          ...state,
          modal: {
            ...state.modal,
            ...lastModal,
            history
          }
        }
      } else {
        return { ...state }
      }
    },
    LOCK_MODAL: state => {
      return { ...state, modal: { ...state.modal, isLocked: true } }
    },
    UNLOCK_MODAL: state => {
      return { ...state, modal: { ...state.modal, isLocked: false } }
    }
  }
)

export default servicesReducer
