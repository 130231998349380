import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { datadogRum } from '@datadog/browser-rum'

import { Provider, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'

import App from './App'
import registerServiceWorker from './registerServiceWorker'
import { ModalService, FlashNotificationService } from 'modules/services'
import { DefaultTheme, ExtTheme } from 'modules/app/themes'
import { AmplitudeProvider } from './hooks/useAmplitude'

import 'rc/helpers/globalHelpers'
import './styles/index.css'

if (process.env.REACT_APP_ENV === 'PROD') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'wiggot',
    env: process.env.REACT_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
}

function ThemeWrapper(props) {
  const roles = useSelector(({ authReducer: { roles } }) => roles)
  // Resolución de tema
  const theme = (() => {
    const hostArr = window.location.host.split('.') || []
    const subdomain = roles.AMPI ? 'ampi' : hostArr[0]

    switch (subdomain) {
      case 'ampi':
        return ExtTheme('AMPI')
      case 'sicamara':
        return ExtTheme('CDC')
      case 'macrobolsa':
        return ExtTheme('MACROBOLSA')
      default:
        return DefaultTheme(roles)
    }
  })()
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
ThemeWrapper.propTypes = {
  children: PropTypes.any
}

const AmplitudeRender = () => {
  const email = useSelector(({ profileReducer: { email } }) => email)
  return (
    <AmplitudeProvider
      userId={email}
      amplitudeKey={process.env.REACT_APP_AMPLITUDE_KEY}
    >
      <App />
      <ModalService />
    </AmplitudeProvider>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router basename={process.env.PUBLIC_URL}>
        <ThemeWrapper>
          <AmplitudeRender />
          <FlashNotificationService />
        </ThemeWrapper>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

registerServiceWorker()
