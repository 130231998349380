import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ShowModal, ShowFlashNotification } from 'reduxActions/services'
import {
  GetTokenList,
  UnlinkTokenFromDevelopment,
  GetTokenSummary,
  UnlinkCreditFromDevelopment
} from 'reduxActions/tokens'
import { GetDevelopmentList } from 'reduxActions/development'
import {
  BuyTokensMC,
  AssignTokenMC,
  AssignCreditMC,
  UnlinkTokenMC
} from 'modules/development/modalContent'

export const useBuyTokens = tokensSection => {
  const dispatch = useDispatch()
  const { token } = useSelector(({ authReducer: { token } }) => ({
    token
  }))
  const [tokens, setTokens] = useState([])
  const [developments, setDevelopments] = useState([])
  const [tokenSummary, setTokenSummary] = useState({
    premium: 0,
    elite: 0,
    i24: 0
  })

  const getDevelopmentList = unlink =>
    dispatch(GetDevelopmentList(token, true))
      .then(res => {
        const { payload: { data } = {} } = res
        !unlink && data && setDevelopments(data)
        return data || []
      })
      .catch(err => {
        console.log({ err }, 'Development List err')
      })

  const buyTokens = location => {
    return dispatch(ShowModal(BuyTokensMC, { location }))
  }

  const getTokens = () => {
    return dispatch(GetTokenList(token))
      .then(res => {
        const { payload: { data } = {} } = res
        data && setTokens(data)
        return data || []
      })
      .catch(err => {
        console.log({ err }, 'Get tokens err')
      })
  }

  const assignToken = (token_id, type, development_id, cb) => {
    return dispatch(
      ShowModal(AssignTokenMC, {
        token,
        developments,
        tokenSummary,
        token_id,
        type,
        development_id,
        buyTokens,
        fromDevelopment: cb && true,
        cb: () => {
          getTokenSummary()
          getTokens()
          cb && setTimeout(() => cb(), 1500)
        }
      })
    )
  }

  const unlinkCredit = (development_id, credit) => {
    return dispatch(
      UnlinkCreditFromDevelopment(token, credit, development_id)
    ).catch(err => {
      console.log({ err }, 'Unlink err')
      dispatch(
        ShowFlashNotification(
          'danger',
          'Ocurrió un error al desvincular el crédito.'
        )
      )
    })
  }

  const assignCredit = (token_id, development_id, unlink) => {
    return dispatch(
      ShowModal(AssignCreditMC, {
        token,
        developments,
        development_id,
        token_id,
        unlink,
        unlinkCredit,
        cb: () => {
          getTokenSummary()
          getTokens()
        }
      })
    )
  }

  const getTokenSummary = () => {
    return dispatch(GetTokenSummary(token))
      .then(res => {
        const {
          payload: { data, data: { elite, premium, credits } = {} } = {}
        } = res

        const eliteCount = (elite && elite.length) || 0
        const premiumCount = (premium && premium.length) || 0
        const i24Count = (credits && credits.length) || 0

        return (
          data &&
          setTokenSummary({
            elite: eliteCount,
            premium: premiumCount,
            i24: i24Count,
            premiumArr: (premium && premium) || [],
            eliteArr: (elite && elite) || [],
            credits: (credits && credits) || []
          })
        )
      })
      .catch(err => {
        console.log({ err }, 'Get Summary err')
      })
  }

  const unassignToken = (development_id, credit, cb) => {
    return dispatch(UnlinkTokenFromDevelopment(token, development_id))
      .then(async res => {
        if (credit) await unlinkCredit(development_id, 'inmuebles24')
        getTokenSummary()
        getTokens()
        cb && setTimeout(() => cb(), 500)
      })
      .catch(err => {
        console.log({ err }, 'Unlink err')
        dispatch(
          ShowFlashNotification(
            'danger',
            'Ocurrió un error al desvincular el token.'
          )
        )
      })
  }

  const unlinkToken = (
    development_id,
    credit,
    leftDays,
    customCb,
    tokenType
  ) => {
    return dispatch(
      ShowModal(UnlinkTokenMC, {
        authorization: token,
        development_id,
        leftDays,
        tokenType,
        unlinkToken: cb =>
          unassignToken(development_id, credit, () => {
            cb()
            customCb && setTimeout(() => customCb(), 500)
          })
      })
    )
  }

  useEffect(() => {
    if (tokensSection && token) {
      getTokenSummary()
      getTokens()
      getDevelopmentList()
    }
  }, [token])

  return {
    assignToken,
    assignCredit,
    buyTokens,
    unlinkToken,
    tokens,
    tokenSummary,
    getDevelopmentList,
    token
  }
}
