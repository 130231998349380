import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { withForm } from 'rc'
import { CheckboxInput } from 'rc/FormComponents'
import { stylesGenerator as checkGenerator } from 'rc/FormComponents/CheckboxInput'

function ButtonCheckbox({
  name,
  keyProp,
  value,
  checked,
  onClick,
  children,
  fc,
  fc: { customChange = () => {} } = {},
  ...props
}) {
  return (
    <ItemType
      name={name}
      key={keyProp}
      value={value}
      checked={checked}
      onChange={({ target: { checked, name } = {} } = {}) => {
        customChange({ name: checked })
      }}
    >
      {children}
    </ItemType>
  )
}

const customCheckInputStyle = checkGenerator({
  checked: css`
    border: ${({ theme }) => theme.remCalc('2')} solid
      ${({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => theme.remCalc('50')};
    color: ${({ theme }) => theme.palette.primary.main};
    svg path {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  `,
  hover: css``,
  hover_focus: css``,
  focus: css``
})

const ItemType = styled(CheckboxInput)`
  flex: 1;
  height: ${({ theme }) => theme.remCalc('45')};
  font-weight: 600;
  border: ${({ theme }) => theme.remCalc('2')} solid
    ${({ theme }) => theme.palette.white.light};
  border-radius: ${({ theme }) => theme.remCalc('50')};
  svg {
    height: ${({ theme }) => theme.remCalc('18')};
    width: ${({ theme }) => theme.remCalc('40')};
  }
  ${customCheckInputStyle}
  &&& {
    .check-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

ButtonCheckbox.propTypes = {
  name: PropTypes.string,
  keyProp: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
  fc: PropTypes.object
}

export default withForm(ButtonCheckbox)
