import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from 'rc'
import { Clockwise } from 'rc/Icons'
import { Button } from 'components'

const UpdateBanner = props => {
  return (
    <div
      isLogged={props.isLogged}
      css={`
        ${({ theme, isLogged }) =>
          theme.position('fixed', '80px', 35, undefined, 35)}
        padding: ${({ theme }) => theme.remCalc('24 34')};

        max-width: ${({ theme }) => theme.remCalc('470')};
        z-index: 99999;
        background: ${({ theme }) => theme.palette.primary.main};
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.24);
        ${({ theme }) => theme.radius(10)}
      `}
    >
      <Typography
        variant="h2"
        color="white"
        align="center"
        display="block"
        textTransform="initial"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        ¡Nueva actualización!
      </Typography>
      <Typography variant="p" color="white" display="block">
        Hay una nueva actualización para wiggot. Si cierras o refrescas la
        página, la actualización será automática.
      </Typography>
      <Typography
        variant="subtitle1"
        color="white"
        display="block"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('25')};
        `}
      >
        ¿Deseas actualizar a la última versión ahora?
      </Typography>
      <Grid container justify="flex-end">
        <Button
          id="general-mainSiteRoutes-button-updateNow"
          type="quaternary"
          size="large"
          onClick={() => {
            window.location.replace(window.location.href)
          }}
          setBorder
        >
          Actualizar ahora
          <Clockwise />
        </Button>
      </Grid>
    </div>
  )
}

UpdateBanner.propTypes = {
  isLogged: PropTypes.bool
}

export default UpdateBanner
