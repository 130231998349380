import { Checkmark, Trash, Information } from 'rc/Icons'

import CasYTerImg from 'static/img/casasYTerrenos-logo.png'
import PropImg from 'static/img/propiedades-logo.png'
import LamImg from 'static/img/portalIcons/Proppit_by_lamudi_2lines.svg'
import MetCubImg from 'static/img/mercado-libre-logo.png'
import Inm24Img from 'static/img/inmuebles24-logo.png'
import AllPropertyLogo from 'static/img/all-property-logo.svg'
import ElIinformador from 'static/img/el-informador-logo.svg'

import newIcon from 'static/img/use-type-new-icon.png'
import usedIcon from 'static/img/use-type-used-icon.png'
import constructionIcon from 'static/img/use-type-construction-icon.png'
import houseSaleIcon from 'static/img/house-sale-icon.png'
import houseRentIcon from 'static/img/house-rent-icon.png'

import I24 from 'static/logos/i24.svg'
import SEGUNDAMANO from 'static/logos/SEGUNDAMANO.svg'
import M3 from 'static/logos/M3.svg'
import VIVAANUNCIOS from 'static/logos/VIVAANUNCIOS.svg'
import PROPIEDADESCOM from 'static/logos/PROPIEDADESCOM.svg'
import PORTALTERRENOCOM from 'static/logos/PORTALTERRENOCOM.png'
import ALLPROPERTY from 'static/logos/ALLPROPERTY.png'
import CyT from 'static/img/portalIcons/icon_portal_cyt.svg'
import LAMUDI from 'static/img/portalIcons/Proppit_by_lamudi_2lines.svg'
import ELINFORMADOR from 'static/img/el-informador-logo.svg'
import MONOPOLIO from 'static/logos/monopolio_icon.svg'

import {
  WiggotPrimeLogoBar,
  WiggotLogo,
  WiggotFullLogoBar,
  WiggotPrimeLogoPass,
  WiggotProLogoBar
} from 'components/Icons'

export const TYPE_VALIDATIONS = {
  TYPE_1: {
    HOUSE: 'HOUSE',
    DUPLEX_HOUSE: 'DUPLEX_HOUSE',
    APARTMENT: 'APARTMENT',
    RANCH: 'RANCH',
    COTTAGE: 'COTTAGE'
  },
  TYPE_2: {
    OFFICE: 'OFFICE',
    CONSULTING_ROOM: 'CONSULTING_ROOM'
  },
  TYPE_3: {
    EVENT_HALL: 'EVENT_HALL'
  },
  TYPE_4: {
    WAREHOUSE: 'WAREHOUSE',
    INDUSTRIAL_BUILDING: 'INDUSTRIAL_BUILDING'
  },
  TYPE_5: {
    HOTEL: 'HOTEL'
  },
  TYPE_6: {
    SHOP: 'SHOP',
    SHOP_SHOPPING_CENTER: 'SHOP_SHOPPING_CENTER'
  },
  TYPE_7: {
    BUILDING: 'BUILDING'
  },
  TYPE_8: {
    TERRAIN: 'TERRAIN'
  }
}

export const PROPERTY_TYPES_SEARCHER = {
  HOUSE: {
    name: 'Casa'
  },
  DUPLEX_HOUSE: {
    name: 'Casa Duplex'
  },
  APARTMENT: {
    name: 'Departamento'
  },
  OFFICE: {
    name: 'Oficina'
  },
  CONSULTING_ROOM: {
    name: 'Consultorio'
  },
  WAREHOUSE: {
    name: 'Bodega'
  },
  INDUSTRIAL_BUILDING: {
    name: 'Nave industrial'
  },
  SHOP: {
    name: 'Local'
  },
  SHOP_SHOPPING_CENTER: {
    name: 'Local en centro comercial'
  },
  TERRAIN: {
    name: 'Terreno/Lote'
  },
  RANCH: {
    name: 'Rancho/Hacienda'
  },
  BUILDING: {
    name: 'Edificio'
  },
  HOTEL: {
    name: 'Hotel'
  },
  EVENT_HALL: {
    name: 'Salon/Terraza de eventos'
  },
  COTTAGE: {
    name: 'Cabaña'
  },
  OTHER: {
    name: 'Otro'
  }
}

export const PROPERTY_TYPES = {
  HOUSE: {
    name: 'Casa',
    type: 1
  },
  DUPLEX_HOUSE: {
    name: 'Casa Duplex',
    type: 1
  },
  APARTMENT: {
    name: 'Departamento',
    type: 1
  },
  OFFICE: {
    name: 'Oficina',
    type: 2
  },
  CONSULTING_ROOM: {
    name: 'Consultorio',
    type: 2
  },
  WAREHOUSE: {
    name: 'Bodega',
    type: 4
  },
  INDUSTRIAL_BUILDING: {
    name: 'Nave industrial',
    type: 4
  },
  SHOP: {
    name: 'Local',
    type: 6
  },
  SHOP_SHOPPING_CENTER: {
    name: 'Local en centro comercial',
    type: 6
  },
  TERRAIN: {
    name: 'Terreno/Lote',
    type: 8
  },
  RANCH: {
    name: 'Rancho/Hacienda',
    type: 1
  },
  BUILDING: {
    name: 'Edificio',
    type: 7
  },
  HOTEL: {
    name: 'Hotel',
    type: 5
  },
  EVENT_HALL: {
    name: 'Salon/Terraza de eventos',
    type: 3
  },
  COTTAGE: {
    name: 'Cabaña',
    type: 1
  },
  OTHER: {
    name: 'Otro',
    type: 0
  }
}

export const PROPERTY_TYPES_PLURAL = {
  HOUSE: 'Casas',
  DUPLEX_HOUSE: 'Casas duplex',
  APARTMENT: 'Departamentos',
  OFFICE: 'Oficinas',
  CONSULTING_ROOM: 'Consultorios',
  WAREHOUSE: 'Bodegas',
  INDUSTRIAL_BUILDING: 'Naves industriales',
  SHOP: 'Locales',
  SHOP_SHOPPING_CENTER: 'Locales en centro comercial',
  TERRAIN: 'Terrenos',
  RANCH: 'Ranchos',
  BUILDING: 'Edificios',
  HOTEL: 'Hoteles',
  EVENT_HALL: 'Salones de eventos',
  COTTAGE: 'Cabañas',
  OTHER: 'Otros'
}

export const SIMPLIFIED_PROPERTY_TYPES = {
  HOUSE: {
    name: 'Casa'
  },
  APARTMENT: {
    name: 'Departamento'
  },
  OFFICE: {
    name: 'Oficina'
  },
  WAREHOUSE: {
    name: 'Bodega'
  },
  INDUSTRIAL_BUILDING: {
    name: 'Nave industrial'
  },
  TERRAIN: {
    name: 'Terreno/Lote'
  },
  RANCH: {
    name: 'Rancho/Hacienda'
  },
  BUILDING: {
    name: 'Edificio'
  },
  SHOP: {
    name: 'Local'
  },
  OTHER: {
    name: 'Otro'
  }
}

export const GROUND_SHAPE = {
  REGULAR: {
    name: 'REGULAR'
  },
  IRREGULAR: {
    name: 'IRREGULAR'
  }
}

export const GROUND_RELIEF = {
  FLAT: {
    name: 'PLANO'
  },
  TILTED: {
    name: 'INCLINADO'
  }
}

export const PRESERVATION = {
  EXCELENT: {
    name: 'EXCELENTE'
  },
  GOOD: {
    name: 'BUENO'
  },
  REGULAR: {
    name: 'REGULAR'
  },
  BAD: {
    name: 'MALO'
  }
}

export const ANTIQUITY_TYPES = {
  NEW: {
    name: 'Nuevo',
    url: newIcon
  },
  USED: {
    name: 'Usado',
    url: usedIcon
  },
  CONSTRUCTION: {
    name: 'Construcción',
    url: constructionIcon
  }
}

export const OPERATION_TYPES = {
  PRESALE: {
    name: 'Preventa',
    url: constructionIcon
  },
  SALE: {
    name: 'Venta',
    url: houseSaleIcon
  },
  LEASE: {
    name: 'Renta',
    url: houseRentIcon
  }
}

export const CUSTOMER_OPERATION_TYPES = {
  SALE: {
    name: 'Venta'
  },
  LEASE: {
    name: 'Renta'
  },
  PRESALE: {
    name: 'Preventa'
  }
}
export const CURRENCY_TYPES = { MXN: { name: 'MXN' }, USD: { name: 'USD' } }

export const PLATFOMRS_SOURCE = {
  WIGGOT: 'Wiggot',
  SEGUNDAMANO: 'Segunda mano',
  CASASYTERRENOS: 'Casa y terrenos',
  I24: 'Inmuebles 24'
}

// update /src/modules/authorizeUserPlatform/AuthorizeTokenPlatform.js with this constant
export const PLATFORM_TYPES = {
  I24: {
    hasPlans: true,
    isEnabled: true,
    logo: Inm24Img,
    fieldPrefix: 'I24',
    name: 'Inmuebles 24',
    requireAuth: true,
    key: 'portal_i24'
  },
  M3: {
    hasPlans: true,
    isEnabled: true,
    logo: MetCubImg,
    name: 'MercadoLibre',
    fieldPrefix: 'M3',
    requireAuth: true,
    key: 'portal_m3'
  },
  CyT: {
    hasPlans: false,
    isEnabled: true,
    logo: CasYTerImg,
    name: 'Casas y Terrenos',
    fieldPrefix: 'Cyt',
    requireAuth: true,
    key: 'portal_cyt'
  },
  PROPIEDADESCOM: {
    hasPlans: false,
    isEnabled: true,
    logo: PropImg,
    name: 'Propiedades.com',
    fieldPrefix: 'Propiedadescom',
    requireAuth: true,
    key: 'portal_propiedadescom'
  },
  LAMUDI: {
    hasPlans: true,
    isEnabled: true,
    logo: LamImg,
    fieldPrefix: 'Lamudi',
    name: 'Proppit by Lamudi',
    requireAuth: true,
    key: 'portal_lamudi',
    plans: [
      {
        name: 'Simple',
        id: 'SIMPLE',
        available: 999
      },
      {
        name: 'Destacada',
        id: 'DESTACADO',
        available: 999
      }
    ]
  },
  ALLPROPERTY: {
    hasPlans: true,
    isEnabled: true,
    plans: [
      {
        name: 'Sencilla',
        id: 's',
        available: 999
      },
      {
        name: 'Destacada',
        id: 'd',
        available: 999
      },
      {
        name: 'Premium',
        id: 'p',
        available: 999
      }
    ],
    logo: AllPropertyLogo,
    fieldPrefix: 'Allproperty',
    id: 'ALLPROPERTY',
    name: 'AllProperty',
    requireAuth: true,
    key: 'portal_allproperty'
  },
  ELINFORMADOR: {
    hasPlans: false,
    isEnabled: true,
    logo: ElIinformador,
    name: 'El Informador',
    fieldPrefix: 'Elinformador',
    requireAuth: true,
    key: 'portal_elinformador'
  },
  WIGGOT: {
    hasPlans: false,
    isEnabled: true,
    logo: 'https://s3-us-west-2.amazonaws.com/wiggot.logo/logo.png',
    fieldPrefix: 'Wiggot',
    id: 'WIGGOT',
    name: 'Wiggot',
    requireAuth: false,
    key: 'portal_wiggot'
  }
}

export const getPortalsIds = () => {
  const wiggot = {
    portalId: 'WIGGOT',
    name: 'Wiggot'
  }

  return [
    wiggot,
    ...Object.keys(PLATFORM_TYPES).map(key => ({
      portalId: key,
      name: PLATFORM_TYPES[key].name || ''
    }))
  ]
}

export const getPortalsByIds = () => {
  const portalsArray = getPortalsIds()
  let portalsById = {}

  portalsArray.forEach(({ portalId, name }) => {
    portalsById[portalId] = name
  })

  return portalsById
}

export const PORTALS = getPortalsIds()
export const PORTALS_BY_ID = getPortalsByIds()

export const DEPOSIT = [
  {
    name: 'Sin depósito',
    id: 0
  },
  {
    name: '1 mes',
    id: 1
  },
  {
    name: '2 meses',
    id: 2
  },
  {
    name: '3 meses',
    id: 3
  },
  {
    name: '4 meses',
    id: 4
  },
  {
    name: '5 meses',
    id: 5
  },
  {
    name: '6 meses',
    id: 6
  },
  {
    name: '7 meses',
    id: 7
  },
  {
    name: '8 meses',
    id: 8
  },
  {
    name: '9 meses',
    id: 9
  },
  {
    name: '10 meses',
    id: 10
  },
  {
    name: '11 meses',
    id: 11
  },
  {
    name: '12 meses',
    id: 12
  }
]

export const ANTICIPATED_RENTS = [
  {
    name: 'Sin renta anticipada',
    id: 0
  },
  {
    name: '1 mes',
    id: 1
  },
  {
    name: '2 meses',
    id: 2
  },
  {
    name: '3 meses',
    id: 3
  },
  {
    name: '4 meses',
    id: 4
  },
  {
    name: '5 meses',
    id: 5
  },
  {
    name: '6 meses',
    id: 6
  },
  {
    name: '7 meses',
    id: 7
  },
  {
    name: '8 meses',
    id: 8
  },
  {
    name: '9 meses',
    id: 9
  },
  {
    name: '10 meses',
    id: 10
  },
  {
    name: '11 meses',
    id: 11
  },
  {
    name: '12 meses',
    id: 12
  }
]

export const FORCED_MONTHS = [
  {
    name: 'Sin contrato forzoso',
    id: 0
  },
  {
    name: '6 meses',
    id: 6
  },
  {
    name: '12 meses',
    id: 12
  },
  {
    name: '18 meses',
    id: 18
  },
  {
    name: '24 meses',
    id: 24
  },
  {
    name: '36 meses',
    id: 36
  }
]

export const CONTACT_SOURCES = [
  { label: 'Inmuebles 24', value: 'inmuebles-24' },
  { label: 'MercadoLibre', value: 'metros-cubicos' },
  { label: 'Casas y Terrenos', value: 'casas-y-terrenos' },
  { label: 'Segunda mano', value: 'segunda-mano' },
  { label: 'Lamudi', value: 'lamudi' },
  { label: 'Vivanuncios', value: 'vivanuncios' },
  { label: 'Redes sociales', value: 'redes-sociales' },
  { label: 'Lona', value: 'lona' },
  { label: 'Guardia', value: 'guardia' },
  { label: 'Referido', value: 'referido' },
  { label: 'Mi sitio web', value: 'mi-sitio-web' },
  { label: 'Impresos', value: 'impresos' },
  { label: 'Prospección', value: 'prospeccion' },
  { label: 'Perfil Público', value: 'perfil-publico' }
]

export const UPLOAD_PROPERTY_FIELDS = {
  SECTION_1: {
    property_type: 'Tipo de propiedad',
    use_residential: '',
    use_comercial: '',
    use_tourist: '',
    ground_size: 'Metros cuadrados de terreno',
    construction_size: '',
    width: '',
    length: '',
    ground_shape: '',
    ground_relief: '',
    country: '',
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    external_number: '',
    internal_number: '',
    postal_code: 'Código postal',
    address_reference: '',
    latitude: '',
    longitude: ''
  },
  SECTION_2: {
    antiquity: '',
    antiquity_years: '',
    delivery_month: '',
    delivery_year: '',
    inside_condo: '',
    floors: '',
    bedrooms: '',
    bathrooms: '',
    half_bathrooms: '',
    parking_lots: '',
    covered_parking: '',
    has_service_room: '',
    preservation: '',
    description: '',
    cover_image: '',
    youtube_url: '',
    matterport_url: ''
  },
  SECTION_3: {},
  SECTION_4: {
    presale_active: '',
    sale_active: '',
    lease_active: '',
    unencumbered_property: '',
    legal_status: '',
    presale_price: '',
    presale_currency: '',
    presale_maintenance_price: '',
    presale_maintenance_currency: '',
    presale_cash_payment: '',
    presale_credit_payment: '',
    presale_credit_details: '',
    presale_commission: '',
    presale_share_commission: '',
    presale_shared_commission: 'Comisión compartida de preventa',
    sale_price: '',
    sale_currency: '',
    sale_maintenance_price: '',
    sale_maintenance_currency: '',
    sale_cash_payment: '',
    sale_credit_payment: '',
    sale_credit_details: '',
    sale_commission: '',
    sale_share_commission: '',
    sale_shared_commission: 'Comisión compartida de venta',
    lease_price: '',
    lease_currency: '',
    lease_maintenance_price: '',
    lease_maintenance_currency: '',
    lease_deposit_months: '',
    lease_anticipated_rents: '',
    lease_forced_contract_months: '',
    lease_endorsement: '',
    lease_research: '',
    lease_restrictions: '',
    lease_commission: '',
    lease_share_commission: '',
    lease_shared_commission: 'Comisión compartida de renta'
  }
  // property_id: '',
  // created_at: '',
  // modified_at: '',
  // published_at: '',
  // deleted_at: '',
  // publishing_status: '',
  //sale_status: '',
  //blocked: '',
  // score: '',
  // user_id: '',
}

export const REMOVE_REASONS = {
  SOLD: { label: 'Ya se vendió' },
  EXCLUSIVE_LOST: { label: 'Perdí exclusiva' },
  AUTHORIZATION_LOST: { label: 'Perdí autorización del cliente' },
  OTHER: { label: 'Otro' }
}

const getUnpublishReasons = () => {
  return Object.keys(REMOVE_REASONS).map(key => ({
    value: key,
    label: REMOVE_REASONS[key].label
  }))
}
export const UNPUBLISH_REASONS = getUnpublishReasons()

export const DELIVERY_DATES = {
  FIRST_TRIMESTER: {
    name: 'Primer trimestre'
  },
  SECOND_TRIMESTER: {
    name: 'Segundo trimestre'
  },
  THIRD_TRIMESTER: {
    name: 'Tercer trimestre'
  },
  FOURTH_TRIMESTER: {
    name: 'Cuarto trimestre'
  },
  JANUARY: {
    name: 'Enero'
  },
  FEBRUARY: {
    name: 'Febrero'
  },
  MARCH: {
    name: 'Marzo'
  },
  APRIL: {
    name: 'Abril'
  },
  MAY: {
    name: 'Mayo'
  },
  JUNE: {
    name: 'Junio'
  },
  JULY: {
    name: 'Julio'
  },
  AUGUST: {
    name: 'Agosto'
  },
  SEPTEMBER: {
    name: 'Septiembre'
  },
  OCTOBER: {
    name: 'Octubre'
  },
  NOVEMBER: {
    name: 'Noviembre'
  },
  DECEMBER: {
    name: 'Diciembre'
  }
}

export const ORIENTATION_POINTS = {
  NORTH: {
    name: 'Norte'
  },
  NORTHEAST: {
    name: 'Noreste'
  },
  EAST: {
    name: 'Este'
  },
  SOUTHEAST: {
    name: 'Sureste'
  },
  SOUTH: {
    name: 'Sur'
  },
  SOUTHWEST: {
    name: 'Suroeste'
  },
  WEST: {
    name: 'Oeste'
  },
  NORTHWEST: {
    name: 'Noroeste'
  }
}

export const MONTHS = [
  { label: 'Enero‎', value: 1 },
  { label: 'Febrero‎', value: 2 },
  { label: 'Marzo‎', value: 3 },
  { label: 'Abril‎', value: 4 },
  { label: 'Mayo‎', value: 5 },
  { label: 'Junio‎', value: 6 },
  { label: 'Julio‎', value: 7 },
  { label: 'Agosto‎', value: 8 },
  { label: 'Septiembre‎', value: 9 },
  { label: 'Octubre‎', value: 10 },
  { label: 'Noviembre‎', value: 11 },
  { label: 'Diciembre‎', value: 12 }
]

/** Used mainly on Requirement */
export const USE_TYPE_VALUES = [
  { label: 'Habitacional', value: 'HABITACIONAL' },
  { label: 'Comercial', value: 'COMMERCIAL' },
  { label: 'Turístico', value: 'TOURIST' }
]

export const USE_TYPES = [
  { HABITACIONAL: 'Habitacional' },
  { COMMERCIAL: 'Comercial' },
  { TOURIST: 'Turístico' }
]

export const USE_TYPES_VALUE = {
  HABITACIONAL: 'Habitacional',
  COMMERCIAL: 'Comercial',
  TOURIST: 'Turístico'
}

export const USE_TYPE_REPORTS_VALUES = [
  { label: 'Residencial', value: 'RESIDENTIAL' },
  { label: 'Comercial', value: 'COMERCIAL' },
  { label: 'Turístico', value: 'TOURIST' }
  // { label: 'Residencial', value: 'HOUSING' }
]
export const PROPERTY_TYPE_VALUES = [
  { label: 'Casa', value: 'HOUSE' },
  { label: 'Casa duplex', value: 'DUPLEX_HOUSE' },
  { label: 'Departamento', value: 'APARTMENT' },
  { label: 'Oficina', value: 'OFFICE' },
  { label: 'Consultorio', value: 'CONSULTING_ROOM' },
  { label: 'Bodega', value: 'WAREHOUSE' },
  { label: 'Nave industrial', value: 'INDUSTRIAL_BUILDING' },
  { label: 'Local Comercial', value: 'SHOP' },
  { label: 'Local en Centro Comercial', value: 'SHOP_SHOPPING_CENTER' },
  { label: 'Terreno', value: 'TERRAIN' },
  { label: 'Rancho', value: 'RANCH' },
  { label: 'Edificio', value: 'BUILDING' },
  { label: 'Hotel', value: 'HOTEL' },
  { label: 'Salon', value: 'EVENT_HALL' },
  { label: 'Cabaña', value: 'COTTAGE' },
  { label: 'Otro', value: 'OTHER' }
]

export const PROPERTY_OBJ_VALUES = {
  HOUSE: 'Casa',
  DUPLEX_HOUSE: 'Casa duplex',
  APARTMENT: 'Departamento',
  OFFICE: 'Oficina',
  CONSULTING_ROOM: 'Consultorio',
  WAREHOUSE: 'Bodega',
  INDUSTRIAL_BUILDING: 'Nave industrial',
  SHOP: 'Local Comercial',
  SHOP_SHOPPING_CENTER: 'Local en Centro Comercial',
  TERRAIN: 'Terreno',
  RANCH: 'Rancho',
  BUILDING: 'Edificio',
  HOTEL: 'Hotel',
  EVENT_HALL: 'Salon',
  COTTAGE: 'Cabaña',
  OTHER: 'Otro'
}

export const OPERATION_TYPES_VALUES = [
  { label: 'Venta', value: 'SALE' },
  { label: 'Renta', value: 'LEASE' },
  { label: 'Preventa', value: 'PRESALE' }
]

export const ANTIQUITY_TYPES_VALUES = [
  { label: 'Nuevo', value: 'NEW' },
  { label: 'Usado', value: 'USED' },
  { label: 'En Construcción', value: 'CONSTRUCTION' }
]

export const GROUND_SHAPE_TYPES_VALUES = [
  { label: 'Terreno regular', value: 'REGULAR' },
  { label: 'Terreno irregular', value: 'IRREGULAR' }
]

export const GROUND_RELIEF_TYPES_VALUES = [
  { label: 'Terreno plano', value: 'FLAT' },
  { label: 'Terreno en desnivel', value: 'TILTED' }
]

export const DETAIL_LABELS = {
  bedrooms: {
    singular: 'Cuarto',
    plural: 'Cuartos'
  },
  bathrooms: {
    singular: 'Baño',
    plural: 'Baños'
  },
  half_bathrooms: {
    singular: 'Medio baño',
    plural: 'Medios baños'
  },
  floors: {
    singular: 'Piso',
    plural: 'Pisos'
  },
  parking_lots: {
    singular: 'Estacionamiento',
    plural: 'Estacionamientos'
  }
}

export const REAL_ESTATE_EXCHANGE_TYPE = [
  { label: 'Todas las propiedades', value: 'ALL' },
  { label: 'Sólo propiedades que comparten comisión', value: 'WIGGOT' }
]

export const SEARCH_TYPE_ITEMS = [
  { label: 'Desarrollos', value: 'DEVELOPMENTS' },
  { label: 'Corretaje', value: 'CORRETAJE' }
]

export const PRESERVATION_OPTIONS = [
  {
    label: 'Excelente',
    value: 'EXCELENT'
  },
  {
    label: 'Bueno',
    value: 'GOOD'
  },
  {
    label: 'Regular',
    value: 'REGULAR'
  },
  {
    label: 'Malo',
    value: 'BAD'
  }
]

export const DEVELOPMENT_DELIVERY_DATES = [
  { value: 'IMMEDIATELY', label: 'Entrega inmediata' },
  { value: 'FIRST_TRIMESTER', label: 'Primer trimestre' },
  { value: 'SECOND_TRIMESTER', label: 'Segundo trimestre' },
  { value: 'THIRD_TRIMESTER', label: 'Tercer trimestre' },
  { value: 'FOURTH_TRIMESTER', label: 'Cuarto trimestre' },
  { value: 'JANUARY', label: 'Enero' },
  { value: 'FEBRUARY', label: 'Febrero' },
  { value: 'MARCH', label: 'Marzo' },
  { value: 'APRIL', label: 'Abril' },
  { value: 'MAY', label: 'Mayo' },
  { value: 'JUNE', label: 'Junio' },
  { value: 'JULY', label: 'Julio' },
  { value: 'AUGUST', label: 'Agosto' },
  { value: 'SEPTEMBER', label: 'Septiembre' },
  { value: 'OCTOBER', label: 'Octubre' },
  { value: 'NOVEMBER', label: 'Noviembre' },
  { value: 'DECEMBER', label: 'Diciembre' }
]
export const DEVELOPMENT_CURRENCY_TYPES = [
  { label: 'MXN', value: 'MXN' },
  { label: 'USD', value: 'USD' }
]

export const DEVELOPMENT_REQUIRED_FIELDS = [
  {
    label: 'Completa la información requerida de los modelos',
    value: 'ALL_MODELS_INCOMPLETE'
  },
  { label: 'Nombre de desarrollo', value: 'development_name' },
  { label: 'Descripción del desarrollo', value: 'development_description' },
  { label: 'Logotipo del desarrollo', value: 'logo' },
  { label: 'Teléfono del desarrollo', value: 'contact_phone' },
  { label: 'Correo del desarrollo', value: 'contact_email' },
  { label: 'Ubicación', value: 'location_description' },
  { label: 'Ubicación', value: 'latitude' },
  { label: 'Ubicación', value: 'longitude' },
  { label: 'Ubicación', value: 'location' },
  { label: 'Forma de pago', value: 'cash_payment' },
  { label: 'Forma de pago', value: 'credit_payment' },
  { label: 'Forma de pago', value: 'credit_mortgage' },
  { label: 'Mi comisión', value: 'commission' },
  { label: 'Comisión compartida', value: 'shared_commission' }
]
export const MODEL_REQUIRED_FIELDS = [
  { label: 'Foto de portada de modelo', value: 'cover_image' },
  { label: 'Nombre de modelo', value: 'model_name' },
  { label: 'Descripción del desarrollo', value: 'model_description' },
  { label: 'Unidades de modelo', value: 'units_model' },
  { label: 'Tipo de propiedad', value: 'property_type' },
  { label: 'Por lo menos un tipo de operación', value: 'presale_active' },
  { label: 'Por lo menos un tipo de operación', value: 'sale_active' },
  { label: 'Precio mínimo del tipo de operación', value: 'min_presale_price' },
  { label: 'Precio mínimo del tipo de operación', value: 'min_sale_price' }
]
const generateLabelNames = () => {
  const objectLabels = {}

  const commonLabels = [
    ...MONTHS,
    ...USE_TYPE_VALUES,
    ...PROPERTY_TYPE_VALUES,
    ...OPERATION_TYPES_VALUES,
    ...ANTIQUITY_TYPES_VALUES,
    ...GROUND_SHAPE_TYPES_VALUES,
    ...GROUND_RELIEF_TYPES_VALUES,
    ...REAL_ESTATE_EXCHANGE_TYPE,
    ...SEARCH_TYPE_ITEMS,
    ...CONTACT_SOURCES,
    ...PRESERVATION_OPTIONS,
    ...DEVELOPMENT_DELIVERY_DATES,
    ...DEVELOPMENT_CURRENCY_TYPES,
    ...DEVELOPMENT_REQUIRED_FIELDS,
    ...MODEL_REQUIRED_FIELDS
  ]

  commonLabels.forEach(({ value, label }) => {
    objectLabels[value] = label
  })
  return { ...objectLabels, ...DETAIL_LABELS }
}

export const LABELS_BY_KEY = generateLabelNames()

export const MINIMAL_DATA_SEARCH = {
  property_type: ['HOUSE'],
  operation_type: 'SALE',
  locations: [],
  share_commission: 'false'
}

export const REQUIREMENT_FORM_EMPTY = {
  operation_type: '',
  min_price: '',
  max_price: '',
  bedrooms: '',
  bathrooms: '',
  half_bathrooms: '',
  parking_lots: '',
  min_construction_size: '',
  max_construction_size: '',
  min_ground_size: '',
  max_ground_size: '',
  width: '',
  height: '',
  min_maintenance: '',
  max_maintenance: '',
  covered_parking: '',
  antiquity_type: [],
  use_type: [],
  ground_shape: '',
  ground_relief: '',
  currency: '',
  amenities: [],
  property_type: '',
  locations: [],
  floors: '',
  contact_name: '',
  contact_id: '',
  inside_condo: false,
  has_service_room: false
}

export const RECORDS_STATUS = {
  PENDING: 'Pendiente',
  ACCEPTED: 'Aprobado',
  REJECTED: 'Rechazado',
  CANCELED: 'Cancelado',
  EXPIRED: 'Caduco'
}

export const REJECT_RECORD_REASONS = [
  {
    value: 1,
    label:
      'Cliente registrado y vigente para esta propiedad por otra inmobiliaria.'
  },
  {
    value: 2,
    label:
      'Cliente registrado y vigente para esta propiedad en mi base de datos.'
  },
  { value: 3, label: 'Cliente no apto para registrar.' },
  {
    value: 4,
    label:
      'Los datos del cliente proporcionados no están completos o son erróneos.'
  },
  { value: 5, label: 'La propiedad ya no se encuentra disponible.' },
  {
    value: 6,
    label:
      'No acepto registro de asesores o inmobiliarias no afiliadas/independientes.'
  },
  { value: 7, label: 'No acepto registro de esta inmobiliaria.' },
  { value: 8, label: 'No acepto registro por calificación del asesor.' },
  { value: 9, label: 'Otra' }
]

export const ALREADY_CLEAN = {
  ALREADY_SOLD: 'SOLD',
  ALREADY_LEASED: 'LEASED',
  ALREADY_TRADED: 'TRADED',
  OTHER: 'OTHER',
  AUTHORIZATION_LOST: 'AUTHORIZATION_LOST'
}

export const PLANS_PRIME = [
  { label: '1 Usuario Prime $398/mes iva incluido', value: '1_USER_MONTHLY' },
  { label: '2 Usuarios Prime $699/mes iva incluido', value: '2_USERS_MONTHLY' },
  { label: '3 Usuarios Prime $999/mes iva incluido', value: '3_USERS_MONTHLY' },
  {
    label: '4 Usuarios Prime $1199/mes iva incluido',
    value: '4_USERS_MONTHLY'
  },
  {
    label: '5 Usuarios Prime $1398/mes iva incluido',
    value: '5_USERS_MONTHLY'
  },
  { label: '1 Usuario Pro $699/mes iva incluido', value: '1_USER_PRO_MONTHLY' },
  { label: '1 Usuario Pass $999/mes iva incluido', value: 'WPASS' },
  {
    label: '1 Usuario Full $1,400/mes iva incluido',
    value: '1_USER_FULL_MONTHLY'
  },
  {
    label: '1 Usuario Prime $358/mes iva incluido',
    value: '1_USER_MONTHLY_10_OFF'
  },
  {
    label: '2 Usuarios Prime $629/mes iva incluido',
    value: '2_USERS_MONTHLY_10_OFF'
  },
  {
    label: '3 Usuarios Prime $899/mes iva incluido',
    value: '3_USERS_MONTHLY_10_OFF'
  },
  {
    label: '4 Usuarios Prime $1079/mes iva incluido',
    value: '4_USERS_MONTHLY_10_OFF'
  },
  {
    label: '5 Usuarios Prime $1258/mes iva incluido',
    value: '5_USERS_MONTHLY_10_OFF'
  }
]
export const SINGLE_PLANS_PRIME = [
  { label: '1 Usuario Prime $398/mes iva incluido', value: '1_USER_MONTHLY' },
  { label: '1 Usuario Pro $799/mes iva incluido', value: '1_USER_PRO_MONTHLY' },
  { label: '1 Usuario Pass $999/mes iva incluido', value: 'WPASS' },
  {
    label: '1 Usuario Full $1,400/mes iva incluido',
    value: '1_USER_FULL_MONTHLY'
  }
]

export const SINGLE_PLANS_PRIME_10_DISCOUNT = [
  {
    label: '1 Usuario Prime $358/mes iva incluido',
    value: '1_USER_MONTHLY_10_OFF'
  },
  {
    label: '1 Usuario Pro $719.1/mes iva incluido',
    value: '1_USER_PRO_MONTHLY_10_OFF'
  },
  { label: '1 Usuario Pass $899.1/mes iva incluido', value: 'WPASS_10_OFF' },
  {
    label: '1 Usuario Full $1,260/mes iva incluido',
    value: '1_USER_FULL_MONTHLY_10_OFF'
  }
]

export const AGENCY_PLANS_PRIME = [
  { label: '1 Usuario Prime $398/mes iva incluido', value: '1_USER_MONTHLY' },
  { label: '2 Usuarios Prime $699/mes iva incluido', value: '2_USERS_MONTHLY' },
  { label: '3 Usuarios Prime $999/mes iva incluido', value: '3_USERS_MONTHLY' },
  {
    label: '4 Usuarios Prime $1199/mes iva incluido',
    value: '4_USERS_MONTHLY'
  },
  { label: '5 Usuarios Prime $1398/mes iva incluido', value: '5_USERS_MONTHLY' }
]

export const AGENCY_PLANS_PRIME_10_DISCOUNT = [
  {
    label: '1 Usuario Prime $358/mes iva incluido',
    value: '1_USER_MONTHLY_10_OFF'
  },
  {
    label: '2 Usuarios Prime $629/mes iva incluido',
    value: '2_USERS_MONTHLY_10_OFF'
  },
  {
    label: '3 Usuarios Prime $899/mes iva incluido',
    value: '3_USERS_MONTHLY_10_OFF'
  },
  {
    label: '4 Usuarios Prime $1079/mes iva incluido',
    value: '4_USERS_MONTHLY_10_OFF'
  },
  {
    label: '5 Usuarios Prime $1258/mes iva incluido',
    value: '5_USERS_MONTHLY_10_OFF'
  }
]

export const MULTI_PLANS_PRIME = [
  { users: '2 Usuarios Prime', price: '$699', id: '2_USERS_MONTHLY' },
  { users: '3 Usuarios Prime', price: '$999', id: '3_USERS_MONTHLY' },
  { users: '4 Usuarios Prime', price: '$1,199', id: '4_USERS_MONTHLY' },
  { users: '5 Usuarios Prime', price: '$1,398', id: '5_USERS_MONTHLY' }
]
export const MULTI_PLANS_PRIME_10_DISCOUNT = [
  {
    users: '2 Usuarios',
    regularPrice: '$699',
    price: '$629',
    id: '2_USERS_MONTHLY_10_OFF'
  },
  {
    users: '3 Usuarios',
    regularPrice: '$999',
    price: '$899',
    id: '3_USERS_MONTHLY_10_OFF'
  },
  {
    users: '4 Usuarios',
    regularPrice: '$1,199',
    price: '$1,079',
    id: '4_USERS_MONTHLY_10_OFF'
  },
  {
    users: '5 Usuarios',
    regularPrice: '$1,398',
    price: '$1,258',
    id: '5_USERS_MONTHLY_10_OFF'
  }
]
export const PLANS_PRIME_DATA = {
  FREE: {
    users: '1 usuario Wiggot',
    price: '-',
    count: 1,
    frequency: '-'
  },
  '1_USER_MONTHLY': {
    users: '1 usuario Prime',
    price: '$398 mxn',
    count: 1,
    frequency: 'Mensual'
  },
  '1_USER_MONTHLY_TRIAL': {
    users: '1 usuario Prime',
    price: '$459 mxn',
    count: 1,
    frequency: 'Mensual'
  },
  '2_USERS_MONTHLY': {
    users: '2 usuarios Prime',
    price: '$699 mxn',
    count: 2,
    frequency: 'Mensual'
  },
  '3_USERS_MONTHLY': {
    users: '3 usuarios Prime',
    price: '$999 mxn',
    count: 3,
    frequency: 'Mensual'
  },
  '4_USERS_MONTHLY': {
    users: '4 usuarios Prime',
    price: '$1,199 mxn',
    count: 4,
    frequency: 'Mensual'
  },
  '5_USERS_MONTHLY': {
    users: '5 usuarios Prime',
    price: '$1,398 mxn',
    count: 5,
    frequency: 'Mensual'
  },
  '1_USER_PRO_MONTHLY': {
    users: '1 usuario Pro',
    price: '$799 mxn',
    count: 1,
    frequency: 'Mensual'
  },
  '1_USER_PRO_MONTHLY_10_OFF': {
    users: '1 usuario Pro precio AMPI',
    price: '$799 mxn',
    discount_price: '$719.1 mxn',
    count: 1,
    frequency: 'Mensual'
  },
  // '1_USER_PRO_QUARTERLY': {
  //   users: '1 usuario Pro',
  //   price: '$1,918 mxn',
  //   count: 1,
  //   frequency: 'Trimestral'
  // },
  '1_USER_FULL_MONTHLY': {
    users: '1 usuario Full',
    price: '$1,400 mxn',
    count: 1,
    frequency: 'Mensual'
  },
  '1_USER_FULL_MONTHLY_10_OFF': {
    users: '1 usuario Full precio AMPI',
    price: '$1,400 mxn',
    discount_price: '$1,260 mxn',
    count: 1,
    frequency: 'Mensual'
  },
  WPASS: {
    users: '1 usuario Pass',
    price: '$999 mxn',
    count: 1,
    frequency: 'Mensual'
  },
  WPASS_10_OFF: {
    users: '1 usuario Pass precio AMPI',
    price: '$999 mxn',
    discount_price: '$899.1 mxn',
    count: 1,
    frequency: 'Mensual'
  },
  '1_USER_MONTHLY_10_OFF': {
    users: '1 usuario Prime precio AMPI',
    price: '$398 mxn',
    discount_price: '$358 mxn',
    count: 1,
    frequency: 'Mensual'
  },
  '2_USERS_MONTHLY_10_OFF': {
    users: '2 usuarios Prime precio AMPI',
    price: '$699 mxn',
    discount_price: '$629 mxn',
    count: 2,
    frequency: 'Mensual'
  },
  '3_USERS_MONTHLY_10_OFF': {
    users: '3 usuarios Prime precio AMPI',
    price: '$999 mxn',
    discount_price: '$899 mxn',
    count: 3,
    frequency: 'Mensual'
  },
  '4_USERS_MONTHLY_10_OFF': {
    users: '4 usuarios Prime precio AMPI',
    price: '$1,199 mxn',
    discount_price: '$1,079 mxn',
    count: 4,
    frequency: 'Mensual'
  },
  '5_USERS_MONTHLY_10_OFF': {
    users: '5 usuarios Prime precio AMPI',
    price: '$1,398 mxn',
    discount_price: '$1,258 mxn',
    count: 5,
    frequency: 'Mensual'
  }
}

const todayDate = new Date()
const currentYear = todayDate.getFullYear()
let years = []
for (var i = currentYear; i < currentYear + 12; i++) {
  years.push({ label: `${i}`, value: i })
}

export const YEARS = years

export const SCORES_SECTION_CONFIG = {
  HIDDEN: 'HIDDEN',
  SHOWN: 'SHOWN',
  SCORE_WITHOUT_COMMENT: 'SCORE_WITHOUT_COMMENT'
}

export const PROFILE_SECTIONS = {
  section_1: {
    fields: ['user_email', 'user_fullname', 'user_phone_number', 'user_photo'],
    sectionValue: 35
  },
  section_2: {
    fields: ['user_background_image', 'user_city', 'user_state'],
    sectionValue: 15
  },
  section_3: {
    fields: ['work_experience'],
    sectionValue: 25
  },
  section_4: {
    fields: ['certifications'],
    sectionValue: 25
  }
}

export const REQUIREMENT_PERCENTAGE_LIST = [
  {
    param: 'location',
    name: 'Ubicación'
  },
  {
    param: 'price',
    name: 'Precio'
  },
  {
    param: 'bedrooms',
    name: 'Cuartos'
  },
  {
    param: 'bathrooms',
    name: 'Baños'
  },
  {
    param: 'half_bathrooms',
    name: 'Medios baños'
  },
  {
    param: 'parking_lots',
    name: 'Estacionamientos'
  },
  {
    param: 'floors',
    name: 'Pisos'
  }
  // {
  //   param: 'commission',
  //   name: 'Comisión'
  // },
  // {
  //   param: 'constructionSize',
  //   name: 'Metros de construcción'
  // }
]

export const PORTAL_FAILURES = {
  PENDING_SYNC_IN_PORTAL:
    'La sincronización con este portal no ha finalizado. Comunícate con nuestro equipo de soporte para solucionarlo.',
  DOCUMENT_PENDING:
    'Tus publicaciones están bloqueadas en este portal temporalmente. Ingresa a tu cuenta de mercado libre y completa la información regulatoria que se te pide.',
  INACTIVE_ACCOUNT_FOR_PAYMENT:
    'Tu cuenta de Segundamano está desactivada por un pago pendiente, contacta a este portal para activarla de nuevo.',
  INACTIVE_ACCOUNT:
    'Tu cuenta de Segundamano está desactivada, contacta a este portal para activarla de nuevo.',
  PENDING_LOCATION_MAPPING_IN_PORTAL:
    'La ubicación de tu propiedad no existe en este portal. Comunícate con nuestro equipo de soporte para solucionarlo.',
  UNAVAILABLE_TYPE:
    'Anuncios insuficientes, selecciona otro tipo o amplía tu plan con el portal.',
  UNKNOW:
    'Ocurrió un error, intenta publicar la propiedad de nuevo en este portal.',
  AGENCY_BLOCKED:
    'Tu inmobiliaria fue bloqueada temporalmente por este portal. Comunícate con nuestro equipo de soporte para solucionarlo.',
  SHOP_EXPIRED:
    'Tu "tienda" dentro de este portal expiró. Comunícate con nuestro equipo de soporte para solucionarlo.',
  SHOP_NOT_ACTIVE:
    'Tu "tienda" dentro de este portal no está activada. Comunícate con nuestro equipo de soporte para solucionarlo.'
}

export const TERMS_AND_CONDITIONS_VERSION = 'v2024.02.26'

export const PERSIST_STATE_KEY = {
  key: 'default_state',
  keyValue: 'state'
}

export const GROUP_MORE_FILTERS_INPUT_NAMES = [
  ['bedrooms', 'parking_lots', 'floors', 'bathrooms', 'half_bathrooms'],
  ['use_type'],
  [
    'min_ground_size',
    'max_ground_size',
    'ground_shape',
    'min_construction_size',
    'max_construction_size',
    'ground_relief'
  ],
  ['antiquity_type'],
  ['min_maintenance', 'max_maintenance'],
  ['amenities'],
  ['commission']
]

export const VALUES_MACROBOLSA_SEARCH = [
  { label: 'Macrobolsa', value: 'onlyMacrobolsa' },
  { label: 'Portal Wiggot®', value: 'all' }
]

export const VALUES_ASSOCIATION_SEARCH = [
  { label: 'Portal Wiggot®', value: '' }
]

export const SECTIONS_TO_SAVE_VISIT = [
  'SEARCH',
  'PROPERTIES',
  'DASHBOARD',
  'DEVELOPMENTS_SEARCHER',
  'REQUIREMENTS',
  'MANAGE_PLATFORMS',
  'CRM',
  'LISTS'
]

export const AVACLICK_ERROR_TITLES = {
  INSUFFICIENT_INFO: 'No es posible generar reporte',
  LIMIT_APPRAISAL: 'No es posible generar reporte',
  UNEXPECTED_ERROR: 'Ocurrió un problema',
  UNKNOW_ERROR: 'Ocurrió un problema',
  INVALID_DATA: 'No es posible generar reporte',
  NO_ACCESS: 'No es posible generar reporte',
  CANT_BE_APPRAISAL: 'No es posible generar reporte',
  INSUFFICIENT_CREDIT: 'Sin reportes disponibles'
}

export const AVACLICK_ERROR_DESCRIPTIONS = {
  INSUFFICIENT_INFO:
    'No existe suficiente información para generar una opinión de valor, Monopolio está trabajando para hacerte llegar un reporte por email en un máximo de 72 horas.',
  LIMIT_APPRAISAL: 'Límite de avalúos alcanzado.',
  UNEXPECTED_ERROR:
    'No es posible obtener información de Monopolio, espere un momento e intente de nuevo.',
  UNKNOW_ERROR:
    'No es posible obtener información de Monopolio, espere un momento e intente de nuevo.',
  INVALID_DATA: 'Ocurrió un problema, contacte al equipo de soporte.',
  NO_ACCESS: 'Ocurrió un problema, contacte al equipo de soporte.',
  CANT_BE_APPRAISAL:
    'Es necesario activar Monopolio en el listado de complementos.',
  INSUFFICIENT_CREDIT:
    'No tienes más opiniones de valor disponibles. Puedes contratar más en la sección de "Paquetes y precios”.'
}

export const FRECUENCY_LABELS = {
  WEEK: 'Semanal',
  HALF_MONTH: 'Medio Mes',
  MONTH: 'Mensual',
  TRIMESTER: 'Trimestral',
  QUARTER: 'Trimestral',
  SEMESTER: 'Semestral',
  YEAR: 'Anual'
}

export const UNITS_ACTIVITY_TYPES_DICTIONARY = {
  PRICE_UPDATE: 'Actualización de precios',
  STATUS_UPDATE: 'Cambio de estatus',
  TEMPLATE_UPLOAD: 'Carga de plantilla',
  DELETED: 'Unidad eliminada',
  UNIT_UPDATE: 'Unidad modificada'
}

export const UNITS_STATUS_DICTIONARY = {
  AVAILABLE: `Disponible`,
  PULLED_APART: `Apartado`,
  SOLD: `Vendido`,
  BLOCKED: `Bloqueado`
}

export const DEVELOPMENT_DATASHEET_TYPES = Object.freeze({
  DEVELOPMENT: 'DEVELOPMENT',
  MODEL: 'MODEL',
  UNITS: 'UNITS',
  UNIT: 'UNIT'
})

export const USER_GENDER_OPTIONS = [
  { value: 'FEMENINE', label: 'Femenino' },
  { value: 'MASCULINE', label: 'Masculino' },
  { value: 'NO_BINARY', label: 'No binario' },
  { value: 'OTHER', label: 'Perfiero no especificar' }
]

export const REGISTERED_PORTALS_LOGO_IDS = {
  WIGGOT: 'i7YrKFv',
  I24: 'ijI1Ddv',
  CyT: 'i1bX0B4',
  CYT: 'i1bX0B4',
  M3: 'i78sy79',
  LAMUDI: 'imIrF7F',
  SEGUNDAMANO: 'i6RiJod',
  PROPIEDADESCOM: 'iiNnfWg',
  VIVANUNCIOS: 'i9cXjdH',
  MITULA: 'iNqiLjS',
  ICASAS: 'idkzNrk',
  ALLPROPERTY: 'ibn9x57',
  EXCELSIOR: 'iUnEWdx',
  LAHAUS: 'i6XS8QH',
  NEXIMO: 'iKZcboA',
  PORTALTERRENOCOM: 'ivtGl4W',
  HOUM: 'izO6mgh',
  MUDAFY: 'i62xBCr',
  FLATMX: 'iYY41ry',
  ELINFORMADOR: 'isBExYq'
}

export const REGISTERED_PORTALS_DICTIONARY = {
  WIGGOT: 'Wiggot',
  I24: 'Inmuebles 24',
  CyT: 'Casas y Terrenos',
  CYT: 'Casas y Terrenos',
  M3: 'Mercado Libre',
  LAMUDI: 'Proppit by Lamudi',
  SEGUNDAMANO: 'Segunda Mano',
  PROPIEDADESCOM: 'Propiedades.com',
  VIVANUNCIOS: 'Vivanuncios',
  MITULA: 'Mitula',
  ICASAS: 'ICasas',
  ALLPROPERTY: 'AllProperty',
  EXCELSIOR: 'Excelsior',
  LAHAUS: 'Lahaus',
  NEXIMO: 'Neximo',
  PORTALTERRENOCOM: 'PortalTerreno',
  HOUM: 'Houm',
  MUDAFY: 'Mudafy',
  FLATMX: 'Flat.mx',
  ELINFORMADOR: 'El Informador',
  MONOPOLIO: 'Monopolio'
}

export const DAYS_WEEK = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado'
]

export const MONTHS_YEAR = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

export const PORTALS_LOGO = {
  I24: I24,
  SEGUNDAMANO: SEGUNDAMANO,
  M3: M3,
  VIVAANUNCIOS: VIVAANUNCIOS,
  PROPIEDADESCOM: PROPIEDADESCOM,
  PORTALTERRENOCOM: PORTALTERRENOCOM,
  CyT: CyT,
  ALLPROPERTY: ALLPROPERTY,
  LAMUDI: LAMUDI,
  ELINFORMADOR: ELINFORMADOR,
  MONOPOLIO
}

export const EXTERNAL_PORTALS = {
  vivanuncios: {
    logo: VIVAANUNCIOS,
    name: 'Vivanuncios'
  },
  'segunda-mano': {
    logo: SEGUNDAMANO,
    name: 'Segunda mano'
  },
  'casas-y-terrenos': {
    logo: CyT,
    name: 'Casas y terrenos'
  },
  'inmuebles-24': {
    logo: I24,
    name: 'Inmuebles24'
  },
  lamudi: {
    logo: LAMUDI,
    name: 'Lamudi'
  },
  'metros-cubicos': {
    logo: M3,
    name: 'Mercado libre'
  },
  MONOPOLIO: {
    logo: MONOPOLIO,
    name: 'Monopolio'
  }
}

export const PLANS_TYPE = {
  FREE: {
    name: 'Free',
    logo: WiggotLogo
  },
  PRIME: {
    name: 'Prime',
    logo: WiggotPrimeLogoBar
  },
  PASS: {
    name: 'Pass',
    logo: WiggotPrimeLogoPass
  },
  PRO: {
    name: 'Pro',
    logo: WiggotProLogoBar
  },
  FULL: {
    name: 'Full',
    logo: WiggotFullLogoBar
  },
  STARTER: {
    name: 'Starter',
    logo: WiggotLogo
  },
  ADVANCED: {
    name: 'Advanced',
    logo: WiggotLogo
  }
}

export const BROKER_TABLE_PLANS_DICT = {
  FREE: { title: 'Wiggot\nGratuito', color: '#222222' },
  STARTER: { title: 'Wiggot\nBásico', color: '#0038FF' },
  ADVANCED: { title: 'Wiggot\nAvanzado', color: '#F1A208' }
}

export const TASKS_TYPES = {
  CALL_CONTACT: {
    label: 'Llamar a'
  },
  VISIT_PROPERTY: {
    label: 'Visitar inmueble con'
  },
  SEND_DOCUMENTS: {
    label: 'Enviar documentos a'
  },
  GET_CONTACT_DOCUMENTS: {
    label: 'Recabar documentación de'
  },
  SIGN_AGREEMENT: {
    label: 'Firma contrato con'
  },
  SIGN_PROPERTY_DEED: {
    label: 'Firma escrituras con'
  },
  SEND_OFFER: {
    label: 'Enviar Oferta de compra a'
  },
  SEND_INFO_VIA_EMAIL: {
    label: 'Enviar información por corsreo a'
  },
  OTHER: {
    label: 'Otra tarea'
  }
}

export const LIST_STATUS = {
  ACTIVE: { label: 'Activa', color: 'green', icon: Checkmark },
  INACTIVE: { label: 'Inactiva', color: 'yellow', icon: Information },
  DELETED: { label: 'Eliminada', color: 'red', icon: Trash }
}
