// import {
//   ShareProfileMC,
//   LeadsMC,
//   PropertyMatchMC,
//   BrokersMC,
//   ContactsMC,
//   MatchMC,
//   BusinessRequestMC,
//   PropertiesSendMC,
//   PropertyPublicUrlMC
// } from 'modules/freemium/sections'

export const benefits = [
  'Busca propiedades a través del mapa',
  'Accede a tu tablero de tareas',
  'Ofrece tus propiedades a otros asesores',
  'Publicaciones ilimitadas en portales Prime',
  'Activa los matches en requerimientos',
  'Contrata complementos a precio preferencial',
  'Accede a tus reportes y los de tu inmobiliaria',
  'Conexión segura para tu dominio personalizado',
  'Edita fichas técnicas de otros asesores',
  'Sección de propiedades en tu sitio personalizado',
  'Descarga fichas técnicas sin datos de contacto',
  'Data de tu sitio web (a través de Google Tag Manager)'
]

export const RULES = {
  //GENERAL RULES
  CHAT_CONTACT_INFORMATION: { name: 'CHAT_CONTACT_INFORMATION' },
  AGENCY_ADD_BROKER: {
    name: 'AGENCY_ADD_BROKER'
    // modal: BrokersMC
  },
  //PROPERTIES RULES
  CONTACTS_SIDEBAR: {
    name: 'CONTACTS_SIDEBAR'
    // modal: ContactsMC
  },
  //ADS RULES
  PROPERTIES_MY_PROPERTIES_ADS: { name: 'PROPERTIES_MY_PROPERTIES_ADS' },
  CONTACTS_ADS: { name: 'CONTACTS_ADS' },
  PROFILE_ADS: { name: 'PROFILE_ADS' },
  DASHBOARD_ADS: { name: 'DASHBOARD_ADS' },
  SEARCHER_ADS: { name: 'SEARCHER_ADS' },
  PUBLIC_DATASHEET_ADS: { name: 'PUBLIC_DATASHEET_ADS' },
  CHAT_ADS: { name: 'CHAT_ADS' },
  DEVELOPMENTS_SEARCHER_ADS: { name: 'DEVELOPMENTS_SEARCHER_ADS' },
  REQUIREMENTS_ADS: { name: 'REQUIREMENTS_ADS' },
  STORE_ADS: { name: 'STORE_ADS' },
  PLUGINS_ADS: { name: 'PLUGINS_ADS' },
  WIGGOT_SOCIAL_ADS: { name: 'WIGGOT_SOCIAL_ADS' },
  SITE_ADS: { name: 'SITE_ADS' },
  ACCOUNT_ADS: { name: 'ACCOUNT_ADS' },
  DEVELOPMENTS_ADS: { name: 'DEVELOPMENTS_ADS' },
  PORTALS_ADS: { name: 'PORTALS_ADS' },
  // Reglas de acuerdo al documento
  // SEARCHER
  SEARCHER_SEARCH: { name: 'SEARCHER_SEARCH' },
  SEARCHER_SEARCH_BY_ID: { name: 'SEARCHER_SEARCH_BY_ID' },
  SEARCHER_RESULSTS: { name: 'SEARCHER_RESULSTS' },
  SEARCHER_MAP: { name: 'SEARCHER_MAP' },
  SEARCHER_RESULSTS_SAVE_AS_REQUIREMENT: {
    name: 'SEARCHER_RESULSTS_SAVE_AS_REQUIREMENT'
  },
  SEARCHER_RESULSTS_ADD_TO_LIST: { name: 'SEARCHER_RESULSTS_ADD_TO_LIST' },
  SEARCHER_BROKER_DATA: { name: 'SEARCHER_BROKER_DATA' },
  // DEVELOPMENTS Falta en el documento inventario de desarrollos
  DEVELOPMENTS_MY_DEVELOPMENTS_TAB: {
    name: 'DEVELOPMENTS_MY_DEVELOPMENTS_TAB'
  },
  DEVELOPMENTS_TOKENS_TAB: { name: 'DEVELOPMENTS_TOKENS_TAB' },
  DEVELOPMENTS_UPLOAD_DEVELOPMENT: { name: 'DEVELOPMENTS_UPLOAD_DEVELOPMENT' },
  DEVELOPMENTS_SEARCHER_SEARCH: { name: 'DEVELOPMENTS_SEARCHER_SEARCH' },
  DEVELOPMENTS_SEARCHER_VIEW: { name: 'DEVELOPMENTS_SEARCHER_VIEW' },
  DEVELOPMENTS_SEARCHER_VIEW_MODELS: {
    name: 'DEVELOPMENTS_SEARCHER_VIEW_MODELS'
  },
  DEVELOPMENT_DATASHEET_SHARE_DATASHEET: {
    name: 'DEVELOPMENT_DATASHEET_SHARE_DATASHEET'
  },
  DEVELOPMENT_DATASHEET_CHAT_REQUEST: {
    name: 'DEVELOPMENT_DATASHEET_CHAT_REQUEST'
  },
  DEVELOPMENT_DATASHEET_CONTACT_PHONE: {
    name: 'DEVELOPMENT_DATASHEET_CONTACT_PHONE'
  },
  DEVELOPMENT_DATASHEET_CONTACT_WHATSAPP: {
    name: 'DEVELOPMENT_DATASHEET_CONTACT_WHATSAPP'
  },
  DEVELOPMENT_DATASHEET_CONTACT_EMAIL: {
    name: 'DEVELOPMENT_DATASHEET_CONTACT_EMAIL'
  },
  DEVELOPMENT_DATASHEET_PAYMENT_PLANS: {
    name: 'DEVELOPMENT_DATASHEET_PAYMENT_PLANS'
  },
  DATASHEETS_DEVELOPMENT_CONTACT_INFO: {
    name: 'DATASHEETS_DEVELOPMENT_CONTACT_INFO'
  },
  DEVELOPMENT_DATASHEET_SALES_FORCE: {
    name: 'DEVELOPMENT_DATASHEET_SALES_FORCE'
  },
  DEVELOPMENT_DATASHEET_MODELS_SHARE_DATASHEET: {
    name: 'DEVELOPMENT_DATASHEET_MODELS_SHARE_DATASHEET'
  },
  DEVELOPMENT_DATASHEET_MODELS_CHAT_REQUEST: {
    name: 'DEVELOPMENT_DATASHEET_MODELS_CHAT_REQUEST'
  },
  DEVELOPMENT_DATASHEET_MODELS_CONTACT_PHONE: {
    name: 'DEVELOPMENT_DATASHEET_MODELS_CONTACT_PHONE'
  },
  DEVELOPMENT_DATASHEET_MODELS_CONTACT_WHATSAPP: {
    name: 'DEVELOPMENT_DATASHEET_MODELS_CONTACT_WHATSAPP'
  },
  DEVELOPMENT_DATASHEET_MODELS_CONTACT_EMAIL: {
    name: 'DEVELOPMENT_DATASHEET_MODELS_CONTACT_EMAIL'
  },
  DEVELOPMENT_DATASHEET_MODELS_PAYMENT_PLANS: {
    name: 'DEVELOPMENT_DATASHEET_MODELS_PAYMENT_PLANS'
  },
  DEVELOPMENT_DATASHEET_MODELS_ADD_TO_LIST: {
    name: 'DEVELOPMENT_DATASHEET_MODELS_ADD_TO_LIST'
  },
  DEVELOPMENT_DATASHEET_MODELS_UNITS_SHARE_LIST: {
    name: 'DEVELOPMENT_DATASHEET_MODELS_UNITS_SHARE_LIST'
  },
  DEVELOPMENT_DATASHEET_MODELS_UNITS_IM_INTERESTED: {
    name: 'DEVELOPMENT_DATASHEET_MODELS_UNITS_IM_INTERESTED'
  },
  // LIST
  LIST_CREATE: { name: 'LIST_CREATE' },
  LIST_SEARCH: { name: 'LIST_SEARCH' },
  LIST_EDIT_NAME: { name: 'LIST_EDIT_NAME' },
  LIST_ADD: { name: 'LIST_ADD' },
  LIST_DELETE: { name: 'LIST_DELETE' },
  LIST_FILTER_SEARCH: { name: 'LIST_FILTER_SEARCH' },
  LIST_RECOVER: { name: 'LIST_RECOVER' },
  LIST_SHARE: { name: 'LIST_SHARE' },
  // PROPERTIES
  PROPERTIES_AGENCY: { name: 'PROPERTIES_AGENCY' },
  PROPERTIES_AGENCY_PENDING: { name: 'PROPERTIES_AGENCY_PENDING' },
  PROPERTIES_MY_PROPERTIES_TAB: { name: 'PROPERTIES_MY_PROPERTIES_TAB' },
  PROPERTIES_PENDING_TO_ARCHIVE_TAB: {
    name: 'PROPERTIES_PENDING_TO_ARCHIVE_TAB'
  },
  PROPERTIES_DELETED_RECENTLY_TAB: { name: 'PROPERTIES_DELETED_RECENTLY_TAB' },
  PROPERTIES_UPLOAD_PROPERTY: { name: 'PROPERTIES_UPLOAD_PROPERTY' },
  PROPERTIES_COPY_PROPERTY_ID: { name: 'PROPERTIES_COPY_PROPERTY_ID' },
  PROPERTIES_MATCHES: {
    name: 'PROPERTIES_MATCHES'
    // modal: PropertyMatchMC
  },
  PROPERTIES_SHARE_DATASHEET: { name: 'PROPERTIES_SHARE_DATASHEET' },
  PROPERTIES_EDIT_PROPERTY: { name: 'PROPERTIES_EDIT_PROPERTY' },
  PROPERTIES_PUBLISH_PROPERTY: { name: 'PROPERTIES_PUBLISH_PROPERTY' },
  PROPERTIES_UNPUBLISH_PROPERTY: { name: 'PROPERTIES_UNPUBLISH_PROPERTY' },
  PROPERTIES_ASSIGN_BROKER_TO_PROPERTY: {
    name: 'PROPERTIES_ASSIGN_BROKER_TO_PROPERTY'
  },
  PROPERTIES_ARCHIVE_PROPERTY: { name: 'PROPERTIES_ARCHIVE_PROPERTY' },
  PROPERTIES_DELETE_PROPERTY: { name: 'PROPERTIES_DELETE_PROPERTY' },
  PROPERTIES_ADD_TO_LIST: { name: 'PROPERTIES_ADD_TO_LIST' },
  PROPERTIES_RECOVER_PROPERTY: { name: 'PROPERTIES_RECOVER_PROPERTY' },
  PROPERTIES_PROPERTIES_DELETE_PERMANENTLY_PROPERTY: {
    name: 'PROPERTIES_PROPERTIES_DELETE_PERMANENTLY_PROPERTY'
  },
  PROPERTIES_TOUCH_PROPERTY: { name: 'PROPERTIES_TOUCH_PROPERTY' },

  // DATASHEET
  DATASHEET_TAB: { name: 'DATASHEET_TAB' },
  PDF_ORIGINAL_DOWNLOAD: { name: 'PDF_ORIGINAL_DOWNLOAD' },
  PDF_EDIT_DOWNLOAD: { name: 'PDF_EDIT_DOWNLOAD' },
  DATASHEET_SHARE_DATASHEET: { name: 'DATASHEET_SHARE_DATASHEET' },
  DATASHEET_EDIT_SHARE_DATASHEET: { name: 'DATASHEET_EDIT_SHARE_DATASHEET' },
  DATASHEET_EDIT_PROPERTY: { name: 'DATASHEET_EDIT_PROPERTY' },
  DATASHEET_COPY_PUBLIC_URL: { name: 'DATASHEET_COPY_PUBLIC_URL' },
  DATASHEET_PREVIEW_DATASHEET: { name: 'DATASHEET_PREVIEW_DATASHEET' },
  DATASHEET_PUBLISH_PROPERTY: { name: 'DATASHEET_PUBLISH_PROPERTY' },
  DATASHEET_UNPUBLISH_PROPERTY: { name: 'DATASHEET_UNPUBLISH_PROPERTY' },
  DATASHEET_ASSIGN_BROKER_TO_PROPERTY: {
    name: 'DATASHEET_ASSIGN_BROKER_TO_PROPERTY'
  },
  DATASHEET_ARCHIVE_PROPERTY: { name: 'DATASHEET_ARCHIVE_PROPERTY' },
  DATASHEET_DELETE_PROPERTY: { name: 'DATASHEET_DELETE_PROPERTY' },
  DATASHEET_ADD_TO_LIST: { name: 'DATASHEET_ADD_TO_LIST' },
  DATASHEET_PROPERTY_PERFORMANCE_TAB: {
    name: 'DATASHEET_PROPERTY_PERFORMANCE_TAB'
  },
  DATASHEET_MATCHES_TAB: { name: 'DATASHEET_MATCHES_TAB' },
  DATASHEET_OFFERED_TAB: { name: 'DATASHEET_OFFERED_TAB' },
  PUBLIC_DATASHEET_AGENCY_PROPERTIES: {
    name: 'PUBLIC_DATASHEET_AGENCY_PROPERTIES'
  },
  CUSTOM_TECHNICAL_DATASHEET: {
    name: 'CUSTOM_TECHNICAL_DATASHEET'
    // modal: modalProps => PropertyPublicUrlMC({ ...modalProps })
  },
  CUSTOM_TECHNICAL_DATASHEET_MY_PROPERTIES: {
    name: 'CUSTOM_TECHNICAL_DATASHEET_MY_PROPERTIES'
  },
  CUSTOM_TECHNICAL_DATASHEET_AGENCY_PROPERTIES: {
    name: 'CUSTOM_TECHNICAL_DATASHEET_AGENCY_PROPERTIES'
  },
  CUSTOM_TECHNICAL_DATASHEET_EDIT_SHARE: {
    name: 'CUSTOM_TECHNICAL_DATASHEET_EDIT_SHARE'
  },
  // Detalle de resultados en buscador
  DATASHEET_SIMILAR_PROPERTIES: { name: 'DATASHEET_SIMILAR_PROPERTIES' },
  DATASHEET_CHAT_REQUEST: { name: 'DATASHEET_CHAT_REQUEST' },
  DATASHEETS_PROPERTY_CONTACT_INFO: {
    name: 'DATASHEETS_PROPERTY_CONTACT_INFO'
  },
  DATASHEET_CONTACT_PHONE: { name: 'DATASHEET_CONTACT_PHONE' },
  DATASHEET_CONTACT_WHATSAPP: { name: 'DATASHEET_CONTACT_WHATSAPP' },
  DATASHEET_CONTACT_EMAIL: { name: 'DATASHEET_CONTACT_EMAIL' },

  // CRM
  // -> leads
  CRM_LEADS_TAB: { name: 'CRM_LEADS_TAB' },
  CRM_LEADS_DETAIL_LIST: { name: 'CRM_LEADS_DETAIL_LIST' },
  CRM_LEADS_CHARTS: { name: 'CRM_LEADS_CHARTS' },
  CRM_LEADS_DETAIL_LIST_DOWNLOAD: { name: 'CRM_LEADS_DETAIL_LIST_DOWNLOAD' },
  CRM_LEADS_ASSIGN_BROKER_TO_LEAD: { name: 'CRM_LEADS_ASSIGN_BROKER_TO_LEAD' },
  // -> contacts
  CRM_CONTACTS_TAB: { name: 'CRM_CONTACTS_TAB' },
  CRM_CONTACTS_CREATE_CONTACT: { name: 'CRM_CONTACTS_CREATE_CONTACT' },
  CRM_CONTACTS_LIST_DOWNLOAD: { name: 'CRM_CONTACTS_LIST_DOWNLOAD' },
  CRM_CONTACTS_LIST_FILTER: { name: 'CRM_CONTACTS_LIST_FILTER' },
  CRM_CONTACTS_AGENCY_LIST: { name: 'CRM_CONTACTS_AGENCY_LIST' },
  CRM_CONTACTS_DELETE_CONTACT: { name: 'CRM_CONTACTS_DELETE_CONTACT' },
  CRM_CONTACTS_EDIT_CONTACT: { name: 'CRM_CONTACTS_EDIT_CONTACT' },
  CRM_CONTACTS_ASSIGN_BROKER_TO_CONTACT: {
    name: 'CRM_CONTACTS_ASSIGN_BROKER_TO_CONTACT'
  },
  CRM_CONTACTS_CONTACT_DETAIL: { name: 'CRM_CONTACTS_CONTACT_DETAIL' },
  CRM_CONTACTS_CONTACT_DETAIL_NOTES: {
    name: 'CRM_CONTACTS_CONTACT_DETAIL_NOTES'
  },
  CRM_CONTACTS_CONTACT_DETAIL_CREATE_REQUIREMENT: {
    name: 'CRM_CONTACTS_CONTACT_DETAIL_CREATE_REQUIREMENT'
  },
  CRM_CONTACTS_CONTACT_DETAIL_ACTIVATE_REQUIREMENT_MATCHES: {
    name: 'CRM_CONTACTS_CONTACT_DETAIL_ACTIVATE_REQUIREMENT_MATCHES'
  },
  CRM_CONTACTS_CONTACT_DETAIL_SENT_PROPERTIES_TAB: {
    name: 'CRM_CONTACTS_CONTACT_DETAIL_SENT_PROPERTIES_TAB'
  },
  CRM_CONTACTS_CONTACT_DETAIL_TASKS_TAB: {
    name: 'CRM_CONTACTS_CONTACT_DETAIL_TASKS_TAB'
  },
  CRM_CONTACTS_CONTACT_DETAIL_CREATE_TASK: {
    name: 'CRM_CONTACTS_CONTACT_DETAIL_CREATE_TASK'
  },

  // -> tasks
  CRM_TASKS_TAB: { name: 'CRM_TASKS_TAB' },
  CRM_TASKS_CREATE_TASK: { name: 'CRM_TASKS_CREATE_TASK' },
  CRM_TASKS_EDIT_TASK: { name: 'CRM_TASKS_EDIT_TASK' },
  CRM_TASKS_AGENCY_TASKS: { name: 'CRM_TASKS_AGENCY_TASKS' },
  CRM_TASKS_DELETE_TASK: { name: 'CRM_TASKS_DELETE_TASK' },
  CRM_TASKS_LIST_DOWNLOAD: { name: 'CRM_TASKS_LIST_DOWNLOAD' },
  CRM_TASKS_VIEW_TASK: { name: 'CRM_TASKS_VIEW_TASK' },
  // -> brokers
  CRM_BROKERS_TAB: { name: 'CRM_BROKERS_TAB' },
  CRM_BROKERS_ADD_BROKER: { name: 'CRM_BROKERS_ADD_BROKER' },
  CRM_BROKERS_LIST_DOWNLOAD: { name: 'CRM_BROKERS_LIST_DOWNLOAD' },
  CRM_BROKERS_CHANGE_ROLE: { name: 'CRM_BROKERS_CHANGE_ROLE' },
  CRM_BROKERS_DELETE_BROKER: { name: 'CRM_BROKERS_DELETE_BROKER' },
  CRM_BROKERS_PENDING_INVITATIONS: { name: 'CRM_BROKERS_PENDING_INVITATIONS' },
  CRM_BROKERS_RESEND_INVITATION: { name: 'CRM_BROKERS_RESEND_INVITATION' },
  // REQUIREMENTS
  REQUIREMENTS_CREATE: { name: 'REQUIREMENTS_CREATE' },
  REQUIREMENTS_ASSIGN_CONTACT: {
    name: 'REQUIREMENTS_ASSIGN_CONTACT'
    // modal: ContactsMC
  },
  REQUIREMENTS_SAVE_DRAFT: { name: 'REQUIREMENTS_SAVE_DRAFT' },
  REQUIREMENTS_ACTIVATE_REQUIREMENT_MATCHES: {
    name: 'REQUIREMENTS_ACTIVATE_REQUIREMENT_MATCHES'
  },
  REQUIREMENTS_DELETE: { name: 'REQUIREMENTS_DELETE' },
  REQUIREMENTS_SEARCH: { name: 'REQUIREMENTS_SEARCH' },
  REQUIREMENTS_EDIT: { name: 'REQUIREMENTS_EDIT' },
  REQUIREMENTS_REQUIREMENT_DETAIL_TAB: {
    name: 'REQUIREMENTS_REQUIREMENT_DETAIL_TAB'
  },
  REQUIREMENTS_NEW_TASK: { name: 'REQUIREMENTS_NEW_TASK' },
  REQUIREMENTS_AGENCY_SECTION: { name: 'REQUIREMENTS_AGENCY_SECTION' },
  REQUIREMENTS_MANUAL_SEARCH: { name: 'REQUIREMENTS_MANUAL_SEARCH' },
  REQUIREMENTS_MATCHES: {
    name: 'REQUIREMENTS_MATCHES'
    // modal: MatchMC
  },
  REQUIREMENTS_BUSSINESS_OPORTUNITIES: {
    name: 'REQUIREMENTS_BUSSINESS_OPORTUNITIES'
    // modal: BusinessRequestMC
  },
  REQUIREMENTS_SHARED_SECTION: {
    name: 'REQUIREMENTS_SHARED_SECTION'
    // modal: PropertiesSendMC
  },
  // DASHBOARD
  DASHBOARD_REPORTS_TAB: { name: 'DASHBOARD_REPORTS_TAB' },
  DASHBOARD_LEADS: {
    name: 'DASHBOARD_LEADS'
    // modal: LeadsMC
  },
  DASHBOARD_TASKS: { name: 'DASHBOARD_TASKS' },
  DASHBOARD_MY_AGENDA: { name: 'DASHBOARD_MY_AGENDA' },
  DASHBOARD_GRAPHIC_REPORT: { name: 'DASHBOARD_GRAPHIC_REPORT' },
  DASHBOARD_DOWNLOAD_REPORT: { name: 'DASHBOARD_DOWNLOAD_REPORT' },
  // PORTALS
  PORTALS_FREE_PORTALS_TAB: { name: 'PORTALS_FREE_PORTALS_TAB' },
  PORTALS_PAY_PORTALS_TAB: { name: 'PORTALS_PAY_PORTALS_TAB' },
  PORTALS_PROPERTIES_LINK: { name: 'PORTALS_PROPERTIES_LINK' },
  // WEBSITE
  WEBSITE_CREATE: { name: 'WEBSITE_CREATE' },
  WEBSITE_ADD_CERTIFICATE: { name: 'WEBSITE_ADD_CERTIFICATE' },
  WEBSITE_MORTGAGE_CREDITS: { name: 'WEBSITE_MORTGAGE_CREDITS' },
  WEBSITE_ADDS: { name: 'WEBSITE_ADDS' },
  WEBSITE_REQUIREMENTS: { name: 'WEBSITE_REQUIREMENTS' },
  WEBSITE_MAP_SEARCH: { name: 'WEBSITE_MAP_SEARCH' },
  WEBSITE_GO_TO_SITE: { name: 'WEBSITE_GO_TO_SITE' },
  WEBSITE_SAVE_CHANGES: { name: 'WEBSITE_SAVE_CHANGES' },
  // STORE
  STORE_BUY_PLANS: { name: 'STORE_BUY_PLANS' },
  STORE_BUY_ADDONS: { name: 'STORE_BUY_ADDONS' },
  STORE_DEVELOPMENTS_INFO: { name: 'STORE_DEVELOPMENTS_INFO' },
  STORE_COMPARE_PLANS: { name: 'STORE_COMPARE_PLANS' },
  // ACCOUNT
  ACCOUNT_PLANS_TAB: { name: 'ACCOUNT_PLANS_TAB' },
  ACCOUNT_EXCLUSIVE_API: { name: 'ACCOUNT_EXCLUSIVE_API' },
  ACCOUNT_CHANGE_PASSWORD: { name: 'ACCOUNT_CHANGE_PASSWORD' },
  // PROFILE
  PROFILE_PREVIEW: { name: 'PROFILE_PREVIEW' },
  PROFILE_SHARE: {
    name: 'PROFILE_SHARE'
    // modal: ShareProfileMC
  },
  PROFILE_RATINGS: { name: 'PROFILE_RATINGS' },
  PROFILE_WORK_EXPERIENCE: { name: 'PROFILE_WORK_EXPERIENCE' },
  PROFILE_CERTIFICATIONS: { name: 'PROFILE_CERTIFICATIONS' },
  PROFILE_PROPERTIES_SHOWN: { name: 'PROFILE_PROPERTIES_SHOWN' },
  // ROLE_CHANGER
  ROLE_CHANGER: { name: 'ROLE_CHANGER' },
  // COMPLEMENTS
  COMPLEMENTS_VIEW: { name: 'COMPLEMENTS_VIEW' },
  COMPLEMENTS_APPRAISALS_REPORTS: { name: 'COMPLEMENTS_APPRAISALS_REPORTS' }
}

export const MODULES = [
  { module: 'SEARCHER', name: 'searcher_rules', active: false },
  { module: 'PROFILE', name: 'profile_rules', active: false },
  { module: 'DASHBOARD', name: 'dashboard_rules', active: false },
  {
    module: 'PROPERTIES|PDFS|DATASHEETS',
    name: 'properties_rules',
    active: true
  },
  { module: 'REQUIREMENTS', name: 'requirements_rules', active: false },
  { module: 'CONTACTS', name: 'contacts_rules', active: false },
  { module: 'DATASHEETS', name: 'datasheets_rules', active: false },
  { module: 'CHAT', name: 'chat_rules' },
  { module: 'PDFS', name: 'pdfs_rules', active: false },
  { module: 'ACCOUNT_API', name: 'account_rules', active: true },
  { module: 'AGENCIES', name: 'agencies_rules', active: true },
  { module: 'TRIAL', name: 'trial_rules', active: false },
  { module: 'APPRAISAL', name: 'appraisals_rules', active: true },
  { module: 'LIST', name: 'list_rules', active: false },
  { module: 'CRM', name: 'crm_rules', active: false },
  { module: 'DEVELOPMENTS', name: 'developments_rules', active: false },
  {
    module: 'DEVELOPMENT_DATASHEET',
    name: 'developments_datasheet_rules',
    active: false
  },
  { module: 'PORTALS', name: 'portals_rules', active: true },
  { module: 'STORE', name: 'store_rules', active: true },
  { module: 'ACCOUNT', name: 'account_rules', active: false }
]
