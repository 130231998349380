import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M8.63502 2.09999L3.84585 7.16916C3.66502 7.36166 3.49002 7.74083 3.45502 8.00333L3.23919 9.89333C3.16335 10.5758 3.65335 11.0425 4.33002 10.9258L6.20835 10.605C6.47085 10.5583 6.83835 10.3658 7.01919 10.1675L11.8084 5.09833C12.6367 4.22333 13.01 3.22583 11.7209 2.00666C10.4375 0.799161 9.46335 1.22499 8.63502 2.09999Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.83594 2.94583C8.08677 4.55583 9.39344 5.78666 11.0151 5.95"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.6499 12.8333H13.1499"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'PencilEdit',
  '0 0 15 14'
)
