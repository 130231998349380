var _jsxFileName = 'src/lib/elements/Buttons/HamburgerButton.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Only displayed in small devices.
 * */
var HamburgerButton = function HamburgerButton(_ref) {
  var id = _ref.id,
      className = _ref.className,
      onClick = _ref.onClick,
      isActive = _ref.isActive;

  var hamburgerBtnClasses = classNames(className, 'wig-hamburger', 'wig-hamburger--collapse', {
    'wig-hamburger--is-active': isActive
  });

  return React.createElement(
    'button',
    {
      id: id,
      type: 'button',
      className: hamburgerBtnClasses,
      onClick: onClick,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 19
      },
      __self: _this
    },
    React.createElement(
      'span',
      { className: 'hamburger-box', __source: {
          fileName: _jsxFileName,
          lineNumber: 25
        },
        __self: _this
      },
      React.createElement('span', { className: 'hamburger-inner', __source: {
          fileName: _jsxFileName,
          lineNumber: 26
        },
        __self: _this
      })
    )
  );
};

HamburgerButton.defaultProps = {
  isActive: false
};

HamburgerButton.propTypes = {
  /** ClassName to add custom styles. */
  className: PropTypes.string,
  /** Used to identify button. */
  id: PropTypes.string,
  /** Changes the icon to an X when the component is active */
  isActive: PropTypes.bool,
  /** Handler of click action on button. */
  onClick: PropTypes.func
};

export default HamburgerButton;