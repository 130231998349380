import React from 'react'
import PropTypes from 'prop-types'
import { Button as LibButton } from 'rc'

export const getDefaultProps = type => {
  switch (type) {
    case 'primary':
      return {
        color: 'primary',
        size: 'large',
        variant: 'standard',
        fontWeight: 'medium'
      }
    case 'secondary':
      return {
        color: 'primary',
        variant: 'outlined',
        size: 'large',
        fontWeight: 'medium'
      }
    case 'tertiary':
      return {
        color: 'gray',
        variant: 'outlined',
        size: 'large',
        fontWeight: 'medium'
      }
    case 'secondary-monopolio':
      return {
        color: 'grayMonopolio',
        variant: 'standard',
        size: 'large',
        fontWeight: 'medium'
      }
    case 'quaternary':
      return {
        variant: 'invertedOutlined',
        color: 'primary',
        size: 'large'
      }
    case 'alert':
      return {
        color: 'alert',
        size: 'large',
        fontWeight: 'medium'
      }
    case 'prime':
      return {
        color: 'prime',
        size: 'large',
        fontWeight: 'medium'
      }
    case 'elite':
      return {
        color: 'elite',
        size: 'large',
        fontWeight: 'medium'
      }
    case 'custom':
      return {
        variant: 'inverted',
        size: 'large',
        fontWeight: 'medium',
        color: 'grayDark'
      }
    default:
      return {}
  }
}

const Button = ({ type, ...props }) => {
  const withDefaultProps = {
    ...getDefaultProps(type),
    ...props
  }

  return (
    <LibButton
      css={`
        min-width: ${({ theme, width }) => {
          if (!width) {
            return
          }
          switch (width) {
            case 'large':
              return theme.remCalc('200')
            case 'medium':
              return theme.remCalc('148')
            default:
              break
          }
        }};
        ${({ rounded, theme }) =>
          rounded && `border-radius: ${theme.remCalc('25')} !important;`}
      `}
      {...withDefaultProps}
    />
  )
}

Button.defaultProps = {
  type: 'primary'
}

Button.propTypes = {
  type: PropTypes.string,
  width: PropTypes.oneOf(['large'])
}

export default Button
