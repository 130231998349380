import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LoginForm from './forms/_LoginForm'
import ForgotPasswordForm from './forms/_ForgotPasswordForm'
import SignUpForm from './forms/_SignUpForm'

const LoginUnit = ({ activeSection, setActiveSection, ...props }) => {
  const [sliderPos, setSliderPos] = useState('25%')
  const [signUpFormStep, setSignUpFormStep] = useState(1)

  useEffect(() => {
    setSliderPos(activeSection === 'login' ? '25%' : '75%')
    setSignUpFormStep(1)
  }, [activeSection])

  const RenderSection = () => {
    switch (activeSection) {
      case 'login':
        return (
          <LoginForm
            setActiveSection={section => setActiveSection(section)}
            {...props}
          />
        )
      case 'signUp':
        return (
          <SignUpForm
            formStep={signUpFormStep}
            setFormStep={setSignUpFormStep}
            cancelFunction={() => setActiveSection('login')}
            {...props}
          />
        )
      case 'forgotPassword':
        return (
          <ForgotPasswordForm
            goBackFunc={() => setActiveSection('login')}
            {...props}
          />
        )
      default:
        return (
          <LoginForm
            setActiveSection={section => setActiveSection(section)}
            {...props}
          />
        )
    }
  }
  return (
    <Container>
      <Logo />
      {['login', 'signUp'].includes(activeSection) && signUpFormStep === 1 && (
        <CustomTabsContainer>
          <CustomTab
            isActive={activeSection === 'login'}
            onClick={() => setActiveSection('login')}
          >
            Iniciar sesión
          </CustomTab>
          <CustomTab
            isActive={activeSection === 'signUp'}
            onClick={() => {
              setActiveSection('signUp')
              props.tracker(
                props.AmplitudeEvents.LOGIN_SIGN_UP_VIEW,
                'User view sign up section'
              )
            }}
          >
            Registrarme
          </CustomTab>
          <SlideIndicator position={sliderPos} />
        </CustomTabsContainer>
      )}
      {RenderSection()}
    </Container>
  )
}

LoginUnit.propTypes = {
  AmplitudeEvents: PropTypes.object,
  activeSection: PropTypes.string,
  setActiveSection: PropTypes.func,
  tracker: PropTypes.func
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 60%;
  ${({ theme }) => theme.media.tabletDown`width: 100%;`}
`
const Logo = styled.img.attrs(({ theme, ...props }) => ({
  src: theme.logo,
  ...props
}))`
  width: 200px;
  margin-bottom: ${({ theme }) => theme.remCalc('32')};
`
const CustomTabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: ${({ theme }) => theme.remCalc('12')};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.remCalc('32')};
`
const CustomTab = styled.div`
  text-align: center;
  font-weight: 600;
  line-height: 150%;
  transition: all 0.3s ease-in-out;
  color: ${({ isActive }) => (isActive ? '#21232D' : '#CDCDD1')};
  cursor: pointer;
  font-size: 1.125rem;
  &:hover {
    filter: brightness(70%);
  }
  width: 50%;
`
const SlideIndicator = styled.div`
  background: #0076ff;
  width: 40px;
  height: 2px;
  position: absolute;
  left: ${({ position }) => `calc(${position} - 20px)`};
  bottom: 0;
  transition: left 0.3s ease-out;
`

export default LoginUnit
