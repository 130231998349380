var _jsxFileName = 'src/lib/generics/Buttons/IconButton.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Generic IconButton which you can customize to get the correct style.
 */
var BASE_CLASSNAME = 'wig-button-icon';
var IconButton = function IconButton(_ref) {
  var _classNames, _classNames2;

  var children = _ref.children,
      circle = _ref.circle,
      id = _ref.id,
      className = _ref.className,
      customColor = _ref.customColor,
      customSize = _ref.customSize,
      danger = _ref.danger,
      disabled = _ref.disabled,
      dripiconIcon = _ref.dripiconIcon,
      fontAwesomeIcon = _ref.fontAwesomeIcon,
      gray = _ref.gray,
      label = _ref.label,
      largeSize = _ref.largeSize,
      lightGray = _ref.lightGray,
      mediumSize = _ref.mediumSize,
      onBlur = _ref.onBlur,
      _onClick = _ref.onClick,
      onFocus = _ref.onFocus,
      primary = _ref.primary,
      secondary = _ref.secondary,
      semiRounded = _ref.semiRounded,
      smallSize = _ref.smallSize,
      spaced = _ref.spaced,
      square = _ref.square,
      stillGray = _ref.stillGray,
      success = _ref.success,
      transparent = _ref.transparent,
      warning = _ref.warning;

  var buttonClassNames = classNames(className, BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--mini-curved', !semiRounded && !circle && !square), _defineProperty(_classNames, BASE_CLASSNAME + '--square', square), _defineProperty(_classNames, BASE_CLASSNAME + '--semiRounded', semiRounded), _defineProperty(_classNames, BASE_CLASSNAME + '--circle', circle), _defineProperty(_classNames, BASE_CLASSNAME + '--primary', !customColor && !secondary && !gray && !transparent && !stillGray && !danger && !warning && !success && !lightGray || primary), _defineProperty(_classNames, BASE_CLASSNAME + '--secondary', secondary), _defineProperty(_classNames, BASE_CLASSNAME + '--light-gray', lightGray), _defineProperty(_classNames, BASE_CLASSNAME + '--gray', gray), _defineProperty(_classNames, BASE_CLASSNAME + '--still-gray', stillGray), _defineProperty(_classNames, BASE_CLASSNAME + '--transparent', transparent), _defineProperty(_classNames, BASE_CLASSNAME + '--danger', danger), _defineProperty(_classNames, BASE_CLASSNAME + '--warning', warning), _defineProperty(_classNames, BASE_CLASSNAME + '--success', success), _defineProperty(_classNames, BASE_CLASSNAME + '--small', !customSize && !label && (!mediumSize && !largeSize || smallSize)), _defineProperty(_classNames, BASE_CLASSNAME + '--medium', !customSize && !label && mediumSize), _defineProperty(_classNames, BASE_CLASSNAME + '--large', !customSize && !label && largeSize), _defineProperty(_classNames, BASE_CLASSNAME + '--with-label', label), _defineProperty(_classNames, BASE_CLASSNAME + '--with-label-small', !customSize && label && (!mediumSize && !largeSize || smallSize)), _defineProperty(_classNames, BASE_CLASSNAME + '--with-label-medium', !customSize && label && mediumSize), _defineProperty(_classNames, BASE_CLASSNAME + '--with-label-large', !customSize && label && largeSize), _defineProperty(_classNames, BASE_CLASSNAME + '--disabled', disabled), _classNames));

  var dripiconIconString = 'dripicons-' + dripiconIcon;
  var fontAwesomeIconString = 'fa fa-' + fontAwesomeIcon;

  var iconClassNames = classNames(BASE_CLASSNAME + '__icon', (_classNames2 = {}, _defineProperty(_classNames2, dripiconIconString, dripiconIcon), _defineProperty(_classNames2, fontAwesomeIconString, !dripiconIcon && fontAwesomeIcon), _classNames2));

  return React.createElement(
    'button',
    {
      id: id,
      className: classNames(buttonClassNames, iconClassNames),
      onBlur: !disabled ? function () {
        return onBlur();
      } : null,
      onClick: function onClick() {
        return !disabled && _onClick();
      },
      onFocus: !disabled ? function () {
        return onFocus();
      } : null,
      tabIndex: disabled ? '-1' : '0',
      type: 'button',
      __source: {
        fileName: _jsxFileName,
        lineNumber: 85
      },
      __self: _this
    },
    label
  );
};

IconButton.defaultProps = {
  dripiconIcon: '',
  fontAwesomeIcon: '',
  smallSize: false,
  mediumSize: false,
  largeSize: false,
  className: '',
  gray: false,
  stillGray: false,
  transparent: false,
  primary: false,
  secondary: false,
  danger: false,
  warning: false,
  success: false,
  square: false,
  semiRounded: false,
  circle: false,
  disabled: false,
  label: '',
  onClick: function onClick() {
    return null;
  },
  onFocus: function onFocus() {
    return null;
  },
  onBlur: function onBlur() {
    return null;
  },
  children: '',
  spaced: false
};

IconButton.propTypes = {
  /** Things to render inside icon button */
  children: PropTypes.any,
  /** Sets a button which has a border-radius of 100%. */
  circle: PropTypes.bool,
  /** Prefixes the button which custom classes you passed. */
  className: PropTypes.string,
  /** */
  customColor: PropTypes.bool,
  /** */
  customSize: PropTypes.bool,
  /** Sets a button which bg-color is danger. */
  danger: PropTypes.bool,
  /** Sets a button which has been disallowed. */
  disabled: PropTypes.bool,
  /** DripiconIcon string shown. */
  dripiconIcon: PropTypes.string,
  /** FontAwesomeIcon string shown. */
  fontAwesomeIcon: PropTypes.string,
  /** Sets a button which bg-color is gray. */
  gray: PropTypes.bool,
  /** Sets the ID of the element. */
  id: PropTypes.string,
  /** Label to render inside the button. */
  label: PropTypes.string,
  /** Large size of the icon. */
  largeSize: PropTypes.bool,
  /** */
  lightGray: PropTypes.bool,
  /** Medium size of the icon. */
  mediumSize: PropTypes.bool,
  /** */
  onBlur: PropTypes.func,
  /** Callback to call when button is pressed. */
  onClick: PropTypes.func,
  /** */
  onFocus: PropTypes.func,
  /** Sets a button which bg-color is primary. */
  primary: PropTypes.bool,
  /** Sets a button which bg-color is secondary. */
  secondary: PropTypes.bool,
  /** Sets a button which has a little border-radius. */
  semiRounded: PropTypes.bool,
  /** Small size of the icon. */
  smallSize: PropTypes.bool,
  /** */
  spaced: PropTypes.bool,
  /** Sets a button which border-radius is none. */
  square: PropTypes.bool,
  /** Sets a button which bg-color is transparent but color gray and hovered stays transparent bg-colro. */
  stillGray: PropTypes.bool,
  /** Sets a button which bg-color is success. */
  success: PropTypes.bool,
  /** Sets a button which bg-color is transparent but hovered bg-color gray. */
  transparent: PropTypes.bool,
  /** Sets a button which bg-color is warning. */
  warning: PropTypes.bool
};

export default IconButton;