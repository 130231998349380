import PropTypes from 'prop-types'
import { useFreemium } from 'modules/freemium/hooks/useFreemium'

const FreemiumAdWrapper = ({ children, section }) => {
  const { validateShowAdd } = useFreemium()
  const showAdds = validateShowAdd(section)
  return showAdds ? children : ''
}

FreemiumAdWrapper.propTypes = {
  section: PropTypes.string,
  children: PropTypes.any  
}

export default FreemiumAdWrapper