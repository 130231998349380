import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid, Typography } from 'rc'
import { isMobile } from 'rc/helpers/miscellaneous'
import { WhatsappFlat } from 'rc/Icons'
import { LinkButton, Button } from 'components'
import { GenerateDevelopmentDataSheetId } from 'reduxActions/development'
import { HideModal } from 'reduxActions/services'
import { DEVELOPMENT_DATASHEET_TYPES } from 'utils/constants'
const ShareDevelopmentMC = ({
  token,
  developmentId,
  GenerateDevelopmentDataSheetId,
  HideModal,
  requirementId,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [developmentDatasheetId, setDevelopmentDatasheetId] = useState(null)
  useEffect(() => {
    if (developmentDatasheetId) {
      openWhatsapp(developmentDatasheetId)
      setIsLoading(false)
    }
  }, [developmentDatasheetId])

  const openWhatsapp = dataSheetId => {
    let url = encodeURI(
      `${process.env.REACT_APP_DEVELOPMENT_DATASHEET}/${dataSheetId}`
    )

    if (!isMobile()) {
      const popUp = window.open(
        `https://web.whatsapp.com/send?text=${url}`,
        '_blank'
      )
      if (popUp) {
        popUp.focus()
      }
    } else {
      window.location = `whatsapp://send?text=${url}`
    }
  }
  const generateDataSheetId = async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    const response = await GenerateDevelopmentDataSheetId(
      token,
      developmentId,
      requirementId,
      DEVELOPMENT_DATASHEET_TYPES.DEVELOPMENT
    )
    const {
      payload: {
        data: { datasheet_id: datasheetId }
      }
    } = response
    setDevelopmentDatasheetId(datasheetId)
  }
  return (
    <Grid
      container
      direction="column"
      justify="center"
      css={`
        width: ${({ theme }) => theme.remCalc('450')};
        max-width: 100%;
        padding: ${({ theme }) => theme.remCalc('30 50 40')};
      `}
    >
      <Typography
        variant="h2"
        textTransform="initial"
        display="block"
        align="center"
        color="palette.primary.main"
      >
        Compartir ficha de desarrollo
      </Typography>
      <WhatsappFlat
        css={`
          margin-top: ${({ theme }) => theme.remCalc('30')};
          ${({ theme }) => theme.box(60, 60)}
          color: ${({ theme }) => theme.palette.success.main};
        `}
      ></WhatsappFlat>
      <Typography
        variant="body2"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('20')};
          width: 300px;
          max-width: 90%;
        `}
        align="center"
      >
        Comparte la ficha del desarrollo con tus clientes en whatsapp.
      </Typography>
      <Grid
        container
        justify="space-between"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('40')};
        `}
      >
        <Grid item flat md={11}>
          <Button
            full
            type="tertiary"
            css={`
              ${({ theme }) => theme.media.tabletDown`
                margin-bottom: ${({ theme }) => theme.remCalc('10')};
              `}
            `}
            onClick={HideModal}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item flat md={11}>
          <LinkButton full onClick={generateDataSheetId} disabled={isLoading}>
            {' '}
            Abrir whatsapp
          </LinkButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

ShareDevelopmentMC.propTypes = {
  token: PropTypes.string,
  requirementId: PropTypes.string,
  developmentId: PropTypes.string,
  GenerateDevelopmentDataSheetId: PropTypes.func,
  HideModal: PropTypes.func
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  GenerateDevelopmentDataSheetId,
  HideModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareDevelopmentMC)
