import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import {
  Typography,
  FormContainer,
  TextField,
  SubmitButton,
  PhoneInput,
  Button,
  Link
} from 'rc'
import { UpdateAgency } from 'reduxActions/profile'
import { HideModal } from 'reduxActions/services'
import { LocationSelectFields } from 'components'

const ProfileAgencyMC = ({
  token,
  profile,
  onInfoUpdated,
  HideModal,
  UpdateAgency,
  theme: {
    devices: { desktop }
  },
  ...props
}) => {
  const [showReason, setShowReason] = useState(false)

  const {
    agency_name,
    agency_business_name,
    agency_email,
    agency_phone_number,
    agency_phone_number_2,
    agency_state,
    agency_city,
    agency_neighborhood,
    agency_street,
    agency_external_number,
    agency_internal_number,
    agency_postal_code,
    agency_tax_id,
    agency_description
  } = profile

  const handleSubmit = values => {
    const required_fields = [
      'agency_name',
      'agency_business_name',
      'agency_email',
      'agency_phone_number',
      'agency_state',
      'agency_city',
      'agency_neighborhood',
      'agency_street',
      'agency_external_number',
      'agency_postal_code'
    ]
    const fields_with_errors = required_fields.filter(
      field =>
        !values[field] ||
        (values[field] &&
          typeof values[field] === 'string' &&
          values[field].trim().length === 0)
    )
    if (fields_with_errors.length > 0) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = fields_with_errors
      throw error
    }

    if (!values.agency_phone_number) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'CUSTOM'
      error.metadata = {
        agency_phone_number: 'Campo requerido'
      }
      throw error
    }
    if (
      values.agency_phone_number_2 &&
      values.agency_phone_number_2.length === 10 &&
      isNaN(values.agency_phone_number_2)
    ) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'CUSTOM'
      error.metadata = {
        agency_phone_number_2: 'Ingresa un teléfono válido'
      }
      throw error
    }

    return UpdateAgency(token, values)
  }
  const handleSubmitSuccess = (response, formValues) => {
    HideModal()

    if (onInfoUpdated) onInfoUpdated(formValues)
  }

  const handleSubmitFail = (values, fc, submitErrors) => {
    if (submitErrors) {
      const { code, metadata } = submitErrors

      switch (code) {
        case 'REQUIRED':
          {
            const fieldErrors = metadata.reduce((previous, current) => {
              return {
                ...previous,
                [current]: 'Campo requerido'
              }
            }, {})

            fc.setCustomErrors({
              ...fieldErrors
            })
          }
          break

        case 'CUSTOM':
          fc.setCustomErrors({
            ...metadata
          })
          break
        default:
          break
      }
    }
  }

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  return (
    <Fragment>
      {!showReason ?
        <ModalContainer>
          <Typography
            variant={'h6'}
            color="palette.primary.main"
            textTransform="initial"
            align="center"
            css={`margin-bottom: ${({ theme }) => theme.remCalc('10')};`}
          >
           Editar datos de la inmobiliaria
          </Typography>
          <FormContainer
            css={`display: flex; flex-direction: column; width: 100%;`}
            initialValues={{
              agency_name,
              agency_business_name,
              agency_email,
              agency_phone_number: agency_phone_number && agency_phone_number.length === 10 ? `52${agency_phone_number}` : agency_phone_number,
              agency_phone_number_2: agency_phone_number_2 && agency_phone_number_2.length === 10 ? `52${agency_phone_number_2}` : agency_phone_number_2,
              agency_state,
              agency_city,
              agency_neighborhood,
              agency_street,
              agency_external_number,
              agency_internal_number,
              agency_postal_code,
              agency_tax_id,
              agency_description
            }}
            onSubmit={handleSubmit}
            onSubmitSuccess={handleSubmitSuccess}
            onSubmitFail={handleSubmitFail}
          >
            <div
              css={`
                display: flex;
                justify-content: space-between;
                ${({ theme }) => theme.media.desktopDown`flex-direction:column;`}
              `}
            >
              <InputFieldsColumn>
                <TextField
                  id="profile-agency-mc-input-editAgencyName"
                  fullWidth
                  type="text"
                  name="agency_name"
                  validate={isRequired}
                  label="Nombre de la inmobiliaria"
                  required
                />
                <TextField
                  id="profile-agency-mc-input-editAgencyEmail"
                  fullWidth
                  type="text"
                  validate={isRequired}
                  name="agency_email"
                  label="Correo de inmobiliaria"
                  required
                />
                <TextField
                  id="profile-agency-mc-input-editAgencyBusinessName"
                  fullWidth
                  type="text"
                  name="agency_business_name"
                  required
                  validate={isRequired}
                  label="Razón social"
                />
                <TextField
                  id="profile-agency-mc-input-editAgencyTaxId"
                  fullWidth
                  type="text"
                  name="agency_tax_id"
                  label="RFC"
                />
                <PhoneInput
                  id="profile-agency-mc-input-editAgencyPhoneNumber"
                  name="agency_phone_number"
                  label="Teléfono de inmobiliaria"
                  required
                  validate={(value, values, currentError, fc, localError) => { return !value ? 'Campo requerido.' : ''}}
                />
                <PhoneInput
                  id="profile-agency-mc-input-editAgencyPhoneNumber2"
                  name="agency_phone_number_2"
                  label="Teléfono de inmobiliaria 2"
                  validate={value => { if (!value) return ''}}
                />
              </InputFieldsColumn>
              <InputFieldsColumn>
                <LocationSelectFields
                  stateFieldConfig={{
                    name: 'agency_state',
                    gridItem: {
                      flat: true
                    }
                  }}
                  cityFieldConfig={{
                    name: 'agency_city',
                    gridItem: {
                      flat: true
                    }
                  }}
                  neighborhoodFieldConfig={{
                    name: 'agency_neighborhood',
                    gridItem: {
                      flat: true
                    }
                  }}
                />
                <TextField
                  id="profile-agency-mc-input-editAgencyPostalCode"
                  fullWidth
                  type="text"
                  name="agency_postal_code"
                  label="CP"
                  required
                  validate={isRequired}
                />
                <TextField
                  id="profile-agency-mc-input-editAgencyStreet"
                  fullWidth
                  type="text"
                  name="agency_street"
                  label="Calle"
                  required
                  validate={isRequired}
                />
                <TwinsContainer>
                    <TextField
                      css={`width: 45%;`}
                      id="profile-agency-mc-input-editAgencyExternalNumber"
                      fullWidth
                      type="text"
                      name="agency_external_number"
                      label="Nº Exterior"
                      required
                      validate={isRequired}
                    />
                    <TextField
                      css={`width: 45%;`}
                      id="profile-agency-mc-input-editAgencyInternalNumber"
                      fullWidth
                      type="text"
                      name="agency_internal_number"
                      label="Nº Interior"
                    />
                </TwinsContainer>
              </InputFieldsColumn>
            </div>
            <TextField
              id="profile-agency-mc-input-editAgencyDescription"
              fullWidth
              type="text"
              name="agency_description"
              label="Descripción"
              multiline
              css={`margin-bottom: ${({ theme }) => theme.remCalc('16')}; textarea { padding: 0.75rem 0.9375rem; }`}
            />
            <TextLikeLinkContainer>
              <Typography
                  variant="subtitle4"
                  color="palette.primary.main"                          
                  css={`text-decoration: underline; cursor: pointer;`}
                  onClick={() => setShowReason(true)}
                >
                  ¿Por qué solicitamos tu RFC y razón social?
                </Typography>
            </TextLikeLinkContainer>
            <TwinsContainer>
              <RoundedButton 
                onClick={HideModal} 
                variant="outlined" 
                color="gray" 
                css={`width: 48%;`}
              >
                Cancelar
              </RoundedButton>
              <SubmitButton 
                id="profile-agency-mc-button-submit"
                css={`width: 48%; border-radius: 50px !important;`} 
              >
                Guardar cambios
              </SubmitButton>
            </TwinsContainer>
          </FormContainer>
        </ModalContainer> :
        <ReasonModalContainer>
          <Typography
            variant={'h6'}
            color="palette.primary.main"
            textTransform="initial"
            align="center"
            css={`margin-bottom: ${({ theme }) => theme.remCalc('22')};`}
          >
            ¿Por qué solicitamos tu RFC y razón social?
          </Typography>
          <Typography align="justify">
            La Ley Federal de Protección de Datos Personales en Posesión de los Particulares dispone que todas las páginas web están obligadas a establecer una política de privacidad completa, clara y actualizada.
            Ésta deberá mostrar la información del responsable del tratamiento de los datos que se recaben en el sitio, es decir, la identidad y domicilio de la empresa o de quien la representa.
          </Typography>
          <Typography css={`margin: ${({ theme }) => theme.remCalc('24 0')};`}>
            Si lo deseas, puedes consultar los lineamientos oficiales{' '}
            <Link 
              underline="always"
              target="_blank"
              href="https://www.dof.gob.mx/nota_detalle.php?codigo=5284966&fecha=17/01/2013#gsc.tab=0"
            >
              aquí.
            </Link>
          </Typography>
          <RoundedButton onClick={() => setShowReason(false)}>
            Atrás
          </RoundedButton>
        </ReasonModalContainer>
      }
    </Fragment>
  )
}

ProfileAgencyMC.propTypes = {
  token: PropTypes.string,
  profile: PropTypes.object,
  onInfoUpdated: PropTypes.func,
  HideModal: PropTypes.func,
  UpdateAgency: PropTypes.func,
  theme: PropTypes.object
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.remCalc('20')};
  width: ${({ theme }) => theme.remCalc('760')};
`

const InputFieldsColumn = styled.div`
  display: flex; 
  flex-direction: column;
  width: 47%;
  ${({ theme }) => theme.media.desktopDown`width: 100%;`}
`

const TwinsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const RoundedButton = styled(Button)`
  border-radius: 50px !important;
`

const TextLikeLinkContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.remCalc('16')};
  justify-content: center;
`

const ReasonModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.remCalc('512')};
  padding: ${({ theme }) => theme.remCalc('30')};
`

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  UpdateAgency,
  HideModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ProfileAgencyMC))
