import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <defs>
      <clipPath id="clip-path">
        <path
          d="M1879.68-867.82l14.57-262.3,125.83,10.49-118.94,19.82a7.77,7.77,0,0,0-5,3.16,7.7,7.7,0,0,0-1.3,5.78l40.45,229.23Zm161.4,14.47L1954.91-839l-43.56-246.9,262.28-43.7,32.42,183.65a7.72,7.72,0,0,0,8.93,6.25,7.72,7.72,0,0,0,6.24-8.93l-33.73-191.18a7.68,7.68,0,0,0-8.85-6.26L2082-1129.95l-194.35-16.2a7.53,7.53,0,0,0-5.71,1.86,7.69,7.69,0,0,0-2.63,5.4l-15.42,277.5a7.7,7.7,0,0,0,6.83,8.09l67.35,7.49,3,17a7.71,7.71,0,0,0,7.57,6.37,6.92,6.92,0,0,0,1.28-.11l93.69-15.61a7.71,7.71,0,0,0,6.33-8.87A7.77,7.77,0,0,0,2041.08-853.35Z"
          style={{ fill: 'none' }}
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          d="M2149.07-791.59A69.45,69.45,0,0,1,2079.7-861a69.46,69.46,0,0,1,69.37-69.38A69.46,69.46,0,0,1,2218.44-861,69.45,69.45,0,0,1,2149.07-791.59Zm0-154.16A84.89,84.89,0,0,0,2064.28-861a84.89,84.89,0,0,0,84.79,84.79A84.89,84.89,0,0,0,2233.86-861,84.89,84.89,0,0,0,2149.07-945.75Z"
          style={{ fill: 'none' }}
        />
      </clipPath>
      <clipPath id="clip-path-3">
        <path
          d="M2179.9-868.67h-23.12v-23.12a7.71,7.71,0,0,0-7.71-7.71,7.71,7.71,0,0,0-7.71,7.71v23.12h-23.12a7.71,7.71,0,0,0-7.71,7.71,7.71,7.71,0,0,0,7.71,7.71h23.12v23.12a7.71,7.71,0,0,0,7.71,7.71,7.71,7.71,0,0,0,7.71-7.71v-23.12h23.12a7.71,7.71,0,0,0,7.71-7.71A7.71,7.71,0,0,0,2179.9-868.67Z"
          style={{ fill: 'none' }}
        />
      </clipPath>
      <clipPath id="clip-path-4">
        <path
          d="M-492.06-1035.32l56.51,221.75a7.57,7.57,0,0,0,3.5,4.66,7.63,7.63,0,0,0,5.78.79l114.59-30.36-108.5,60.31-119.18-229.12Zm96.82-59.5a7.56,7.56,0,0,0-9.26-5.38l-90.26,23.91a8,8,0,0,0-1.19.42,7.59,7.59,0,0,0-4.21,8.77l4.2,16.47-57.32,34a7.57,7.57,0,0,0-2.84,10L-430-764.26a7.59,7.59,0,0,0,4.55,3.77,7.39,7.39,0,0,0,5.86-.64L-252-854.29-158.93-879a7.54,7.54,0,0,0,5.4-9.19l-47.13-184.94a7.6,7.6,0,0,0-9.21-5.47,7.59,7.59,0,0,0-5.47,9.21l45.26,177.67-252.68,67-60.86-238.84,83-22A7.62,7.62,0,0,0-395.24-1094.82Z"
          style={{ fill: 'none' }}
        />
      </clipPath>
      <clipPath id="clip-path-5">
        <path
          d="M1918.3-1508.6l52.78,221.86a7.55,7.55,0,0,0,3.27,4.65,6.67,6.67,0,0,0,5.38.8l106.68-30.48-101,60.44-111.15-229.18Zm90.12-59.63a7,7,0,0,0-8.63-5.37l-84,24a7,7,0,0,0-1.11.42,7.76,7.76,0,0,0-3.91,8.78l3.92,16.47-53.35,34a7.87,7.87,0,0,0-2.64,10l117.59,242.47a7.19,7.19,0,0,0,4.24,3.76,6.44,6.44,0,0,0,5.46-.64l156-93.35,86.68-24.76c3.72-1,6-5.17,5-9.2l-44-185a7,7,0,0,0-8.58-5.46,7.65,7.65,0,0,0-5.09,9.21l42.28,177.76L1983-1297.91l-56.85-239,77.28-22.08C2007.1-1560,2009.41-1564.18,2008.42-1568.23Z"
          style={{ fill: '#ffc834' }}
        />
      </clipPath>
    </defs>
    <title>iconos</title>
    <path
      d="M1918.3-1508.6l52.78,221.86a7.55,7.55,0,0,0,3.27,4.65,6.67,6.67,0,0,0,5.38.8l106.68-30.48-101,60.44-111.15-229.18Zm90.12-59.63a7,7,0,0,0-8.63-5.37l-84,24a7,7,0,0,0-1.11.42,7.76,7.76,0,0,0-3.91,8.78l3.92,16.47-53.35,34a7.87,7.87,0,0,0-2.64,10l117.59,242.47a7.19,7.19,0,0,0,4.24,3.76,6.44,6.44,0,0,0,5.46-.64l156-93.35,86.68-24.76c3.72-1,6-5.17,5-9.2l-44-185a7,7,0,0,0-8.58-5.46,7.65,7.65,0,0,0-5.09,9.21l42.28,177.76L1983-1297.91l-56.85-239,77.28-22.08C2007.1-1560,2009.41-1564.18,2008.42-1568.23Z"
      style={{ fill: '#ffc834' }}
    />
    <g style={{ clipPath: 'url(#clip-path-5)' }}>
      <polygon
        points="-12424.55 -2915.91 6090.53 -11920.28 12333.97 2905.89 -6181.11 11910.25 -12424.55 -2915.91"
        style={{ fill: '#ffc834' }}
      />
    </g>
    <rect
      x="16.86"
      y="20.96"
      width="24"
      height="429"
      style={{ fill: '#e0e0e0' }}
    />
    <path d="M219,466.42H149.93A10.22,10.22,0,0,1,139.7,456.2c0-5.65,4.58-6.22,10.23-6.22H219c5.65,0,10.22.57,10.22,6.22A10.21,10.21,0,0,1,219,466.42Z" />
    <polygon
      points="58.2 401.09 99.86 401.09 99.86 337.21 58.2 337.18 58.2 401.09"
      style={{ fill: '#ffc834' }}
    />
    <polygon
      points="61.2 257.49 96.86 257.49 96.86 202.8 61.2 202.78 61.2 257.49"
      style={{ fill: '#ffc834' }}
    />
    <polygon
      points="61.2 107.49 96.86 107.49 96.86 52.8 61.2 52.78 61.2 107.49"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M61.25,257.13H96.81V202.58H61.25ZM107,277.59H51a10.21,10.21,0,0,1-10.22-10.22v-75A10.21,10.21,0,0,1,51,182.16h56a10.21,10.21,0,0,1,10.22,10.22v75A10.23,10.23,0,0,1,107,277.59Z"
      style={{ fill: '#97a4b1' }}
    />
    <path
      d="M61.25,107.13H96.81V52.6H61.25ZM107,127.59H51a10.21,10.21,0,0,1-10.22-10.22l0-75A10.21,10.21,0,0,1,51,32.16h56a10.21,10.21,0,0,1,10.22,10.22v75A10.18,10.18,0,0,1,107,127.59Z"
      style={{ fill: '#97a4b1' }}
    />
    <path
      d="M61.25,396.41H96.81V341.88l-35.56,0ZM107,416.85H51a10.21,10.21,0,0,1-10.22-10.22v-75A10.21,10.21,0,0,1,51,321.42h56a10.21,10.21,0,0,1,10.22,10.22v75A10.21,10.21,0,0,1,107,416.85Z"
      style={{ fill: '#97a4b1' }}
    />
    <path
      d="M392.76,292.42h35.56V251.13H392.76Zm45.78,20.45h-56a10.21,10.21,0,0,1-10.22-10.22V240.89a10.21,10.21,0,0,1,10.22-10.22h56a10.21,10.21,0,0,1,10.22,10.22v61.76A10.21,10.21,0,0,1,438.54,312.87Z"
      style={{ fill: '#97a4b1' }}
    />
    <path
      d="M227,288h35.54V246.7H227Zm45.76,20.44h-56a10.21,10.21,0,0,1-10.22-10.22V236.48a10.21,10.21,0,0,1,10.22-10.22h56A10.21,10.21,0,0,1,283,236.48v61.76A10.21,10.21,0,0,1,272.78,308.46Z"
      style={{ fill: '#97a4b1' }}
    />
    <path
      d="M392.76,168.91h35.56V127.59H392.76Zm45.78,20.44h-56a10.21,10.21,0,0,1-10.22-10.22V117.37a10.21,10.21,0,0,1,10.22-10.22h56a10.21,10.21,0,0,1,10.22,10.22v61.76A10.21,10.21,0,0,1,438.54,189.35Z"
      style={{ fill: '#97a4b1' }}
    />
    <path
      d="M220.12,450h42.44v-70.6H220.12Zm52.66,16.46H209.9a10.21,10.21,0,0,1-10.22-10.22V369.13a10.21,10.21,0,0,1,10.22-10.22h62.88A10.21,10.21,0,0,1,283,369.13V456.2A10.21,10.21,0,0,1,272.78,466.42Z"
      style={{ fill: '#97a4b1' }}
    />
    <rect
      x="227.02"
      y="246.7"
      width="35.54"
      height="41.32"
      style={{ fill: '#ffc834' }}
    />
    <rect
      x="392.76"
      y="250.81"
      width="35.56"
      height="41.3"
      style={{ fill: '#ffc834' }}
    />
    <rect
      x="392.76"
      y="127.81"
      width="35.56"
      height="41.3"
      style={{ fill: '#ffc834' }}
    />
    <rect
      x="220.12"
      y="379.36"
      width="42.44"
      height="70.61"
      style={{ fill: '#0076cc' }}
    />
    <rect
      x="155.86"
      y="187.96"
      width="25"
      height="262"
      style={{ fill: '#e0e0e0' }}
    />
    <rect
      x="273.86"
      y="80.96"
      width="22"
      height="90"
      style={{ fill: '#e0e0e0' }}
    />
    <path
      d="M461,365.68c-43,0-78.33,36.19-78.33,81.1a81.88,81.88,0,0,0,25.54,59.73,81.92,81.92,0,0,1-21.28-55.37c0-44.91,35.33-81.1,78.33-81.1a78.21,78.21,0,0,1,53.21,21.37C504,375.71,483.57,365.68,461,365.68Z"
      style={{ fill: '#e0e0e0', fillRule: 'evenodd' }}
    />
    <path
      d="M396.3,449h13.2c0-29.65,23.41-54.07,52.36-54.07V381.38C425.68,381.38,396.3,411.9,396.3,449Z"
      style={{ fill: '#97a4b1' }}
    />
    <path
      d="M415.88,367.43c1-.56,1.94-1.09,2.92-1.62h-26V387A97.43,97.43,0,0,1,415.88,367.43Z"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M604.47,557.39l-51.08-52.75-4.68,4.79L538.49,499A94.91,94.91,0,0,0,553,448c0-40.11-24.69-74.56-58.74-88.51,0,0-3.13-1-7.36-2.14V74.49a10.21,10.21,0,0,0-10.22-10.22l-208.82,0A10.21,10.21,0,0,0,257.6,74.51l0,96.91H225.26V10.22A10.21,10.21,0,0,0,215,0H10.22A10.21,10.21,0,0,0,0,10.22v446a10.21,10.21,0,0,0,10.22,10.22H372.3c-1.52-6.58-2.31-9.28-2.37-16.45H346.37l0-268.33a10.21,10.21,0,0,0-10.22-10.22H274.06V80.73H470.42V353.92c-1.5-.09-7-.14-8.56-.14a88.31,88.31,0,0,0-13.14,1,10.21,10.21,0,0,0-10.18-9.4h-56a10.21,10.21,0,0,0-10.22,10.23v61.75a10.08,10.08,0,0,0,1.09,4.55,99.92,99.92,0,0,0-3.5,26.06,94.34,94.34,0,0,0,3.83,27l5.53,13.51c14.9,31.83,46,54.07,82.59,54.07,18.31,0,34.91-5.67,49.38-14.83l10.22,10.47L516.78,543l51.08,52.76c14.9,14.82,31.51,6.1,37,0A27.8,27.8,0,0,0,604.47,557.39Zm-454.57-386a10.21,10.21,0,0,0-10.22,10.22l0,268.34H16.44V20.46H208.8l0,151Zm180,16.44V450H156.14V187.86Zm62.83,178H417l-1.1.62A97.43,97.43,0,0,0,392.76,386ZM461.6,527.89c-43,0-78.33-36.19-78.33-81.09s35.33-81.1,78.33-81.1c43.42,0,78.33,36.19,78.33,81.1S504.59,527.89,461.6,527.89ZM595.52,586c-5.11,5.23-13.63,5.23-18.31,0L535.49,542.8l18.31-19.19,41.72,43.17A13.93,13.93,0,0,1,595.52,586Z"
      style={{ fill: '#97a4b1' }}
    />
    <path
      d="M595.53,586.17c-5.1,5.23-13.62,5.23-18.3,0L535.51,543l18.3-19.18L595.53,567A13.93,13.93,0,0,1,595.53,586.17Z"
      style={{ fill: '#0076cc' }}
    />
  </Fragment>,
  'Development',
  '0 0 612.38 603.92'
)
