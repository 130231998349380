import { createReducer } from '../store'
import { createCookie } from 'utils/handleCookies'
import { MODULES } from 'modules/freemium/utils/constants'

const initialState = {
  rules: {}
}

const freemiumReducer = createReducer(
  {
    ...initialState
  },
  {
    GET_RULES_SUCCESS: (state, action) => {
      const { data = [] } = action.payload
      const lastGetDate = new Date()
      const rulesList = data.map(rule => {
        const formatValue = Boolean(Number(rule?.value))
        const composedRule = {
          ...rule,
          value: formatValue,
          rawValue: rule?.value
        }
        return [rule.name, composedRule]
      })

      const rules = Object.fromEntries(rulesList)
      MODULES.forEach(({ module, name, active }) => {
        if (active) {
          const list = rulesList?.filter(r => {
            return module?.includes(r[1]?.module)
          })
          createCookie(name, Object.fromEntries(list))
        }
      })
      return { ...state, rules, lastGetDate }
    }
  }
)

export default freemiumReducer
