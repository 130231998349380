import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M59.44,301.21c4.83,1.5,9.66,2.93,14.42,4.39,8.84,2.71,17.85,7.34,22,15.6,6.24,12.43-.79,27.07-7,39.5-9.22,18.39-17.27,35.68-12.51,56.35C80.88,436.81,93,454.72,109.07,467c22.91,17.5,52.84,24.2,81.61,22.49,38.08-2.27,76.32-15.89,104.51-41.7,18.39-16.82,35.72-47.63,63-50.26,40.21-3.87,68.77,19.7,102.19,37.33,38.8,20.47,86.94-.07,113.71-31.56,45.33-53.33,61.87-163.71-22.19-189.71-43.59-13.48-14.8-47.73,1.34-72.83C579.66,99.63,590,50,541.67,19.86,520.07,6.38,494.12.41,468.85,0,430.68-.74,393.32,9.69,356.05,18c-29.82,6.66-60.63,12.11-91.29,9.24-31.13-2.92-59-18.7-89.6-24.17-36.37-6.5-75.28,7.05-103.24,30.75C36.14,64.18,37.86,110.9,51.14,151.51c5.39,16.48,9.64,34.89,2.65,50.76-9,20.36-35.86,28.11-48.1,46.37-3.12,4.66-5,10.44-3.84,15.93,1.2,5.69,5.4,10.27,9.89,13.95C25.57,289.84,42.5,296,59.44,301.21Z"
      style={{ fill: '#b2e2f7' }}
    />
    <path
      d="M396.27,293.51l-162,.74a12.24,12.24,0,0,1-12.34-12.19l-.39-228.74A12.33,12.33,0,0,1,233.85,41l162-.74a12.25,12.25,0,0,1,12.35,12.19l.38,228.75A12.33,12.33,0,0,1,396.27,293.51Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M387.21,283.42l-143.91.66a12.08,12.08,0,0,1-12.17-12L230.77,62.2a12.17,12.17,0,0,1,12.14-12.14l143.91-.66a12.08,12.08,0,0,1,12.17,12l.36,209.83A12.17,12.17,0,0,1,387.21,283.42Z"
      style={{ fill: '#fff' }}
    />
    <path
      d="M364.77,256.81l-96.63.45a4.38,4.38,0,0,1-4.41-4.37l0-16.3a4.41,4.41,0,0,1,4.4-4.4l96.63-.44a4.37,4.37,0,0,1,4.41,4.36l0,16.3A4.41,4.41,0,0,1,364.77,256.81Z"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="366.14 210.56 298.06 210.95 298.04 202.32 366.12 201.94 366.14 210.56"
      style={{ fill: '#81caf7' }}
    />
    <polygon
      points="366.1 192.59 298.01 192.97 298 184.35 366.08 183.96 366.1 192.59"
      style={{ fill: '#81caf7' }}
    />
    <polygon
      points="309.12 162.02 265.98 162.22 265.84 81.89 308.98 81.69 309.12 162.02"
      style={{ fill: '#e4e6e4' }}
    />
    <polygon
      points="308.99 86.5 265.85 86.7 265.84 81.89 308.98 81.69 308.99 86.5"
      style={{ fill: '#ffc834' }}
    />
    <polygon
      points="323.03 63.3 279.89 63.5 265.84 81.89 308.98 81.69 323.03 63.3"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="337.27 161.17 309.12 160.5 308.98 81.69 323.03 63.3 337.14 81.56 337.27 161.17"
      style={{ fill: '#ffc834' }}
    />
    <polygon
      points="280.14 119.79 272.13 119.89 272.09 95.47 279.99 95.43 280.14 119.79"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="291.14 119.67 282.43 119.77 282.39 95.35 290.98 95.31 291.14 119.67"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="301.23 119.63 293.4 119.72 293.36 95.3 301.08 95.27 301.23 119.63"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="302.09 104.22 270.53 104.42 270.52 101.68 302.08 101.48 302.09 104.22"
      style={{ fill: '#fcb9b9' }}
    />
    <polygon
      points="356.69 161.81 308.92 162.02 308.85 119.18 356.62 118.97 356.69 161.81"
      style={{ fill: '#f2f2f2' }}
    />
    <polygon
      points="352.53 118.97 309.05 119.17 309.04 113.7 352.52 113.5 352.53 118.97"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="333.15 109.94 330.82 109.95 330.83 119.08 333.17 119.07 333.15 109.94"
      style={{ fill: '#8a9099' }}
    />
    <polygon
      points="354.85 109.84 352.51 109.85 352.53 118.98 354.87 118.97 354.85 109.84"
      style={{ fill: '#8a9099' }}
    />
    <polygon
      points="311.37 110.04 309.03 110.05 309.05 119.18 311.38 119.17 311.37 110.04"
      style={{ fill: '#8a9099' }}
    />
    <path
      d="M328.78,119.08l-15.35.07v-3.73a4.41,4.41,0,0,1,4.41-4.41l6.51,0a4.39,4.39,0,0,1,4.43,4.37Z"
      style={{ fill: '#4fb8dc', opacity: 0.5 }}
    />
    <path
      d="M350.46,119l-15.35.07v-3.73a4.41,4.41,0,0,1,4.4-4.41l6.52,0a4.39,4.39,0,0,1,4.42,4.37Z"
      style={{ fill: '#4fb8dc', opacity: 0.5 }}
    />
    <polygon
      points="325.86 152.52 317.85 152.61 317.81 128.19 325.71 128.16 325.86 152.52"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="336.86 152.4 328.15 152.5 328.11 128.08 336.7 128.04 336.86 152.4"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="346.95 152.35 339.12 152.44 339.08 128.03 346.8 127.99 346.95 152.35"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="347.81 136.95 316.25 137.15 316.24 134.4 347.8 134.2 347.81 136.95"
      style={{ fill: '#ffc834' }}
    />
    <polygon
      points="299 162.07 275.49 162.18 275.45 137.18 298.96 137.08 299 162.07"
      style={{ fill: '#4fb8dc', opacity: 0.5 }}
    />
    <polygon
      points="275.49 159.95 275.45 136.77 292.28 136.69 275.49 159.95"
      style={{ fill: '#4fb8dc' }}
    />
    <polygon
      points="299.61 133.87 274.79 133.98 274.79 137.19 299.62 137.07 299.61 133.87"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M231.36,204c0-1.4,9.6-8.69,6.44-10.44-3-1.67-13.81,12.81-14.86,14.23C226,206.06,231.36,204,231.36,204Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M247.63,191.42c-1.74,3.78-10.72,8.66-13.53,10-3.66,2.16-8.09,4.62-11.16,6.3l-.09.12c1.4.24,5.84,8.29,5.84,8.29,7.71-2.1,20.78-17.78,23.37-24.74C254.74,184.17,250.44,185.36,247.63,191.42Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M174.34,468.66s-1.66,16.47,2.5,16.47c0,0,2.16-4.58,4.66-3.82,0,0,19.43,8.17,28.09,3.82,0,0,.55-1.78-23.33-16.47Z"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M117.1,468.85s1.85,16.46-2.31,16.51c0,0-2.21-4.57-4.7-3.78,0,0-19.34,8.39-28,4.14,0,0-.57-1.78,23.14-16.74Z"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M160.84,261.56c21.21,47.78,39.1,211.37,22,210.24,0,0-10.81,7.48-17.47-16.2,0,0-39.93-155.4-29.94-188.64Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M120.23,259.51C96.4,273.3,86.55,469.76,106.93,474.33c0,0,16.63,8.73,20-39.89,0,0,29.92-150.72,29.93-173.42C156.82,261,127.17,255.49,120.23,259.51Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M155.59,184.88c-2.32,5.92-5,11.19-7.23,11.19-6,0-14.39-11.7-19-18.91l9.47-23,13.6,1.85s-.27,1.36-.56,3.52c-.06.47.37,1.5-.18,1.53C144.67,161.46,138.54,177.81,155.59,184.88Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M233.33,219.22s-21,30.79-45.66,24.11a65.12,65.12,0,0,1-14.18-5.1c-1.37-.72-5.2-9.48-6.31-10.35,0,0,2,3,2,4.09.34,5.45-5.89,29.78-10.23,31.67,0,0-22.45,9.2-43.6.8-.71-.28-1.4-.58-2.09-.91l-.21-.13h0c-3.09-1.92-39.24-24.76-31.75-42.23,0,0,38.35-48.27,48-44s13,18.91,19,18.91c2.22,0,4.91-5.27,7.23-11.19a10.29,10.29,0,0,0,1.09,1.1c8.8,7.49,27.72,29.64,38.49,34.21s20.87-18.9,26.41-18.57S233.65,216.6,233.33,219.22Z"
      style={{ fill: '#ffc834' }}
    />
    <path
      d="M123.7,239.76l7.16-20.11,30.59,9.06L148.75,273l-29.07-13.1s9.92-13.09,7.61-17.31C126.31,240.78,123.7,239.76,123.7,239.76Z"
      style={{ fill: '#f8f8f8' }}
    />
    <path
      d="M169.72,235.83s-.23-.37-.38-.33-.29,0-.33-.13a125.64,125.64,0,0,0-7.43-19.07c-4.55-8.85,6.1,9.56,11.91,21.93A24.77,24.77,0,0,1,169.72,235.83Z"
      style={{ fill: '#e0a112' }}
    />
    <path
      d="M125.53,247s18.65-1.08,19.1.68c0,0,.62,1.35-10.45,3.65-.1,0,16.58,4.41,16.22,6.31a4.62,4.62,0,0,1-4.49,3.84c-1.94.19-18.75-.06-24.11-4.56Z"
      style={{ fill: '#c87a6f' }}
    />
    <path
      d="M129.3,242.53a36.11,36.11,0,0,1-13.91,21.91c-.71-.28-1.4-.58-2.09-.91l-.21-.13c5.7-6.06,13.47-15.4,12.85-20.72,0,0-27.52-9.31-22.37-16.58l9.21-9.95-7.33,12.94S127.71,238.74,129.3,242.53Z"
      style={{ fill: '#e0a112' }}
    />
    <path
      d="M162.53,143.92c0,10.25-6,18.56-13.36,18.56s-13.37-8.31-13.37-18.56,6-18.55,13.37-18.55S162.53,133.67,162.53,143.92Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M154.81,235l-.21,0-19.86-6.15a.71.71,0,0,1,.42-1.36L155,233.57a.73.73,0,0,1,.47.89A.71.71,0,0,1,154.81,235Z"
      style={{ fill: '#dde5fa' }}
    />
    <path
      d="M152.4,240.29a.76.76,0,0,1-.21,0l-19.85-6.15a.7.7,0,0,1-.47-.89.72.72,0,0,1,.89-.47l19.85,6.16a.71.71,0,0,1-.21,1.38Z"
      style={{ fill: '#dde5fa' }}
    />
    <path
      d="M150.88,245.8l-.21,0-19.85-6.15a.7.7,0,0,1-.47-.89.72.72,0,0,1,.89-.47l19.85,6.16a.71.71,0,0,1-.21,1.38Z"
      style={{ fill: '#dde5fa' }}
    />
    <path
      d="M148.46,204.24c-5.66,0-11.07-7.21-16.33-14.21-.79-1.05-1.58-2.1-2.38-3.13a.71.71,0,0,1,1.13-.87c.8,1,1.59,2.09,2.38,3.15,5.63,7.48,11.45,15.22,16.91,13.35,6.27-2.14,7.69-10.88,7.7-11a.7.7,0,0,1,.81-.59.71.71,0,0,1,.6.81c-.06.39-1.56,9.67-8.65,12.09A6.6,6.6,0,0,1,148.46,204.24Z"
      style={{ fill: '#e0a112' }}
    />
    <path
      d="M152.83,134.32c20.37,5.68,22.42-27-3-12.72-6.31,3.54-16.37-8.17-20.9,8.63-3.56,13.18-14.14,5.48-14.65,19.38-.36,9.8-17.75,7.76-12.64,26.15,6.12,22-13.3,16.35-6.14,32.7,7.39,16.88,44,4.53,38-12.26-12.38-34.74,14.32-22.48,8.7-46.08C137,128.45,147.34,132.79,152.83,134.32Z"
      style={{ fill: '#171d3e' }}
    />
    <path
      d="M111,258.79a.71.71,0,0,1-.3-1.36c6-2.78,9.65-15.48,9.69-15.61a.71.71,0,0,1,1.37.39c-.16.55-3.9,13.46-10.46,16.51A.66.66,0,0,1,111,258.79Z"
      style={{ fill: '#e0a112' }}
    />
    <path
      d="M228.8,221.48a.71.71,0,0,1-.7-.62c-.95-7.11-9.39-14.9-9.48-15a.71.71,0,0,1,0-1,.7.7,0,0,1,1,0c.37.33,8.91,8.21,9.93,15.84a.71.71,0,0,1-.61.79Z"
      style={{ fill: '#e0a112' }}
    />
    <path
      d="M502.34,267.94S499.81,452.63,484.56,477H472.08s-8.22-78.69-7.87-114.89Z"
      style={{ fill: '#e0a112' }}
    />
    <path
      d="M460.4,266.18S413,401.32,398.67,477.84H412s86.73-141.2,88.52-188.33C500.55,289.51,494.75,248,460.4,266.18Z"
      style={{ fill: '#ffc834' }}
    />
    <polygon
      points="479.07 287.49 471.1 297.3 479.07 306.61 488.95 294.94 479.07 287.49"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M455.69,191.48s-2.54-20.23-4.74-20.37c0,0-1.75-.4-2.21,11.93,0,.11-8.69-16.95-10.88-16.2a5.08,5.08,0,0,0-3.67,5.57c.17,2.12,3.92,20.13,10.41,25Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M479.07,222.18S461.81,181.66,455,186.67A124.27,124.27,0,0,1,442.68,193c-11,4.45-2.28,21.95,26.88,56Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M479.79,137.45c-.51,10.45-4.4,20.32,13.15,20.27,8.57,0,16.43-7,17.68-17.41s-4.64-19.47-13.15-20.26S480.87,115.34,479.79,137.45Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M514.06,178.17c-6.58,7.26-18.42,19-25.77,18.28-2.73-.26-5.33-6.26-7.42-12.92,6.5-5.3,11.27-16.63,11.35-24,0-.45,0-.9,0-1.32,0-.25,0-.49,0-.71s0-.66,0-1c-.07-2.37-3.72-3.87-3.72-3.87l16.94-.45Z"
      style={{ fill: '#fcb9b9' }}
    />
    <path
      d="M501.7,110.84c-7.56-1.25-12.6.6-20.59-1.78s-6.57,3.48-2,6.11c0,0,.59,4.1-6.32-.67s-3.08,11.25,13.82,11.58c0,0,16.49-.13,16.91,5.83,0,0-7.09,24.14,3.39,20.63,0,0,13.71-2.59,7.12-28.73C514.06,123.81,511.78,112.51,501.7,110.84Z"
      style={{ fill: '#171d3e' }}
    />
    <path
      d="M491.31,300.69l-16.45-14.48s5.23-8.17,6.36-17c.08-.61.14-1.24.17-1.85,0,0-1.24.42-3.14.88-7.62,1.89-25.78,4.52-16.58-16.24,0,0,4.44-53.21,19.2-68.47,0,0,21.88,1.05,31.22-11.29C518.2,164.17,547.8,261.17,491.31,300.69Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M481.22,269.21c.08-.61.14-1.24.17-1.85,0,0-1.24.42-3.14.88,3.8-5.63,12.88-21.49,17-49.46C495.24,218.78,494.86,251.5,481.22,269.21Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M412,477.84s4.22,10.05-2.8,10.68c0,0-30.1,2.7-31.54.8,0,0-4.57-2,21-12.07Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M485.11,476.09s4.33,10.69-2.69,11.33c0,0-30.1,2.69-31.54.79,0,0-4.57-2,21-12.07Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M469.3,292.08s6.4,14.39,14,16.72l-.7,3.24s-15.93-6-16.66-19.32Z"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="470.41 311.83 469.16 314.98 470.51 315.39 471.76 312.25 470.41 311.83"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="468.55 316.52 467.3 319.67 468.65 320.08 469.9 316.93 468.55 316.52"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="466.69 321.21 465.44 324.36 466.79 324.77 468.04 321.62 466.69 321.21"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="464.83 325.89 463.58 329.04 464.93 329.46 466.18 326.31 464.83 325.89"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="462.97 330.58 461.72 333.73 463.08 334.15 464.32 331 462.97 330.58"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="461.11 335.27 459.86 338.42 461.21 338.83 462.46 335.69 461.11 335.27"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="459.25 339.96 458 343.11 459.36 343.52 460.61 340.38 459.25 339.96"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="457.39 344.65 456.14 347.8 457.5 348.21 458.75 345.06 457.39 344.65"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="455.53 349.34 454.28 352.49 455.64 352.9 456.89 349.75 455.53 349.34"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="453.67 354.02 452.43 357.17 453.78 357.59 455.03 354.44 453.67 354.02"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="451.81 358.71 450.56 361.86 451.92 362.28 453.17 359.13 451.81 358.71"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="449.95 363.4 448.7 366.55 450.06 366.96 451.31 363.82 449.95 363.4"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="448.09 368.09 446.85 371.24 448.2 371.65 449.45 368.51 448.09 368.09"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="446.23 372.78 444.99 375.93 446.34 376.34 447.59 373.19 446.23 372.78"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="444.38 377.47 443.13 380.62 444.48 381.03 445.73 377.88 444.38 377.47"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="442.51 382.16 441.27 385.3 442.62 385.72 443.87 382.57 442.51 382.16"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="440.66 386.84 439.41 389.99 440.76 390.41 442.01 387.26 440.66 386.84"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="438.8 391.53 437.55 394.68 438.9 395.1 440.15 391.95 438.8 391.53"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="436.94 396.22 435.69 399.37 437.04 399.78 438.29 396.63 436.94 396.22"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="435.08 400.91 433.83 404.06 435.18 404.47 436.43 401.32 435.08 400.91"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="433.22 405.6 431.97 408.75 433.32 409.16 434.57 406.01 433.22 405.6"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="431.36 410.29 430.11 413.43 431.46 413.85 432.71 410.7 431.36 410.29"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="429.5 414.97 428.25 418.12 429.61 418.54 430.85 415.39 429.5 414.97"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="427.64 419.66 426.39 422.81 427.75 423.23 429 420.08 427.64 419.66"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="425.78 424.35 424.53 427.5 425.89 427.91 427.13 424.77 425.78 424.35"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="423.92 429.04 422.67 432.19 424.03 432.6 425.28 429.45 423.92 429.04"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="422.06 433.73 420.81 436.88 422.17 437.29 423.42 434.14 422.06 433.73"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="420.2 438.42 418.95 441.56 420.31 441.98 421.56 438.83 420.2 438.42"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="418.34 443.1 417.09 446.25 418.45 446.67 419.7 443.52 418.34 443.1"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="416.48 447.79 415.23 450.94 416.59 451.36 417.84 448.21 416.48 447.79"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="414.62 452.48 413.38 455.63 414.73 456.04 415.98 452.9 414.62 452.48"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="412.76 457.17 411.51 460.32 412.87 460.73 414.12 457.59 412.76 457.17"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="410.9 461.86 409.66 465.01 411.01 465.42 412.26 462.27 410.9 461.86"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="409.05 466.55 407.8 469.69 409.15 470.11 410.4 466.96 409.05 466.55"
      style={{ fill: '#e0a112' }}
    />
    <polygon
      points="407.19 471.24 405.94 474.38 407.29 474.8 408.54 471.65 407.19 471.24"
      style={{ fill: '#e0a112' }}
    />
    <path
      d="M469.33,280.72c-9.26,0-10.18-2.84-10.26-3.22a.73.73,0,0,1,.64-.83.82.82,0,0,1,.94.54c.05.12,1.43,3.08,14.66,1.76a.81.81,0,0,1,.9.63.73.73,0,0,1-.72.78C473.11,280.62,471.07,280.72,469.33,280.72Z"
      style={{ fill: '#e0a112' }}
    />
    <path
      d="M485.14,191.31c-1.83,0-3-.09-3-.09a.75.75,0,0,1-.72-.78.77.77,0,0,1,.88-.63c.24,0,23.61,1.84,31.06-11.95a.87.87,0,0,1,1.07-.34.68.68,0,0,1,.38.95C508.54,190.06,492,191.31,485.14,191.31Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M440.13,201.79a.78.78,0,0,1-.8-.62.73.73,0,0,1,.7-.79c9.74-1.08,17.83-10.67,17.91-10.77a.88.88,0,0,1,1.12-.15.65.65,0,0,1,.18,1c-.34.42-8.58,10.18-19,11.34Z"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M494.81,296.58a.81.81,0,0,1-.77-.48c-3.11-8.12-15.83-10.56-16-10.58a.72.72,0,0,1-.63-.84.82.82,0,0,1,1-.55c.56.1,13.76,2.64,17.16,11.51a.69.69,0,0,1-.51.9A.73.73,0,0,1,494.81,296.58Z"
      style={{ fill: '#0066a5' }}
    />
    <path
      d="M269.26,195.88a6.79,6.79,0,0,0,1.87-5,7.5,7.5,0,0,0-.8-3.69,5.7,5.7,0,0,0-2.31-2.38,7.39,7.39,0,0,0-6.71-.07,5.73,5.73,0,0,0-2.35,2.33,7.43,7.43,0,0,0-.88,3.67,6.73,6.73,0,0,0,1.77,5,7,7,0,0,0,9.41.09Zm-5.79-2.74a4.77,4.77,0,0,1-.41-2.34,4.6,4.6,0,0,1,.46-2.32,1.21,1.21,0,0,1,2.21,0,4.64,4.64,0,0,1,.42,2.34,4.58,4.58,0,0,1-.47,2.32,1.27,1.27,0,0,1-1.11.71A1.29,1.29,0,0,1,263.47,193.14Z"
      style={{ fill: '#0076cc' }}
    />
    <polygon
      points="279.1 184.48 262.06 208.85 268 208.91 285.03 184.54 279.1 184.48"
      style={{ fill: '#0076cc' }}
    />
    <path
      d="M288.21,199a5.7,5.7,0,0,0-2.31-2.38,6.87,6.87,0,0,0-3.34-.84,7,7,0,0,0-3.36.77,5.7,5.7,0,0,0-2.36,2.33,7.63,7.63,0,0,0-.88,3.67,6.77,6.77,0,0,0,1.77,5,7,7,0,0,0,9.41.1,6.76,6.76,0,0,0,1.88-5A7.62,7.62,0,0,0,288.21,199Zm-4.64,6a1.21,1.21,0,0,1-2.21,0,4.64,4.64,0,0,1-.42-2.34,4.76,4.76,0,0,1,.47-2.33,1.21,1.21,0,0,1,2.21,0,4.53,4.53,0,0,1,.41,2.33A4.61,4.61,0,0,1,283.57,204.92Z"
      style={{ fill: '#0076cc' }}
    />
  </Fragment>,
  'PropertOffer',
  '0 0 613.51 490.33'
)
