import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { Typography, Grid, Drawer } from 'rc'
import DropDown, { DropdownAction, DropdownMenu } from 'rc/DropDown'
import { useMedia } from 'rc/Hooks'
import { Share } from 'rc/Icons'
import { Button } from 'components'
import { ShareProfileMC } from 'modules/profile/modalContent'
import { ShowModal } from 'reduxActions/services'
import FreemiumWrapper from 'modules/freemium/FreemiumWrapper'
import { RULES } from 'modules/freemium/utils/constants'

const DropDownContentStyled = styled(DropdownMenu)`
  background-color: ${({ theme }) => theme.palette.base.white};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  width: ${({ theme }) => theme.remCalc('400')};
  right: 0;
  > * {
    cursor: pointer;
    min-height: ${({ theme }) => theme.remCalc('70')};
    padding: ${({ theme }) => theme.remCalc('0 40')};
    &:hover {
      * {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
    &:not(:first-child) {
      &:after {
        content: '';
        ${({ theme }) => theme.position('absolute', -1, 5, null, 5)}
        background: ${({ theme }) => theme.palette.gray.light};
        height: 2px;
      }
    }
  }
`
const DrawerStyled = styled(Drawer)`
  overflow: hidden;
  &&& {
    padding: ${({ theme }) => theme.remCalc('20')};
    z-index: 999;
    bottom: ${({ theme }) => theme.remCalc('0')};
  }
  > :nth-child(2) {
    border-radius: ${({ theme }) => theme.remCalc('10 10 0 0')};
  }
`
const ShareButtonMobileStyled = styled(Button)`
  display: flex;
  justify-content: center;
  width: 100%;
`
const ShareProfileButton = ({
  ShowModal,
  theme: {
    devices: { tablet }
  },
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isTablet = useMedia(tablet)

  const showShareProfileModal = type => params => {
    ShowModal(ShareProfileMC, {
      type
    })
    if (!isTablet) setIsOpen(false)
  }
  const Options = (
    <Fragment>
      <Grid
        item
        flat={isTablet ? true : false}
        container
        direction="column"
        alignItems="center"
        isTablet={isTablet}
        id="profile-profileDetail-button-openCustomerModal"
        onClick={showShareProfileModal('customer')}
        css={`
          ${({ theme, isTablet }) =>
            !isTablet && theme.bordered(1, '#E1E6ED', 'bottom')}
          width: 100%;
        `}
      >
        <Typography variant="subtitle2">
          Tarjeta digital para clientes
        </Typography>
        <Typography variant="subtitle4" color="theme.palette.gray.main">
          (No muestra comisión compartida)
        </Typography>
      </Grid>
      <Grid
        item
        flat={isTablet ? true : false}
        container
        direction="column"
        alignItems="center"
        onClick={showShareProfileModal('agencyBroker')}
        id="profile-profileDetail-button-openAgencyBrokerModal"
      >
        <Typography variant="subtitle2">
          Tarjeta digital para asesores
        </Typography>
        <Typography variant="subtitle4" color="theme.palette.gray.main">
          (Muestra tu comisión compartida)
        </Typography>
      </Grid>
    </Fragment>
  )
  const shareProfileLabel = 'Compartir tarjeta digital'

  return isTablet ? (
    <div
      css={`
        display: inline-block;
        z-index: 1;
      `}
    >
      <FreemiumWrapper label={shareProfileLabel} section={RULES.PROFILE_SHARE}>
        <DropDown>
          <DropdownAction>
            <Button
              width="large"
              css={`
                display: inline-flex;
                justify-content: initial;
              `}
              id="profile-profileDetail-button-shareProfileOpenMenu"
            >
              <Share
                css={`
                  margin-right: ${({ theme }) => theme.remCalc('10')};
                `}
              ></Share>
              {shareProfileLabel}
            </Button>
          </DropdownAction>
          <DropDownContentStyled>{Options}</DropDownContentStyled>
        </DropDown>
      </FreemiumWrapper>
    </div>
  ) : (
    <Fragment>
      <FreemiumWrapper section={RULES.PROFILE_SHARE} custom>
        <Grid
          id="profile-profileDetail-button-openShareProfileMobile"
          container
          inline
          center
          alignItems="center"
          css={`
            background-color: ${({ theme }) => theme.palette.primary.main};
            ${({ theme }) => theme.box(50, 50, true)}
            cursor: pointer;
          `}
          onClick={() => setIsOpen(true)}
        >
          <Share
            css={`
              fill: ${({ theme }) => theme.palette.base.white};
              color: ${({ theme }) => theme.palette.base.white};
              ${({ theme }) => theme.box(25, 25)}
            `}
          ></Share>
        </Grid>
      </FreemiumWrapper>

      <DrawerStyled
        open={isOpen}
        onClose={() => setIsOpen(false)}
        from="bottom"
        direction="bottom-top"
      >
        <Grid container item>
          {Options}
          <Grid
            item
            flat={isTablet ? true : false}
            container
            direction="column"
            alignItems="center"
            id="profile-profileDetail-button-showProfilePreview"
            css={`
              ${({ theme, isTablet }) =>
                !isTablet && theme.bordered(1, '#E1E6ED', 'top')}
              width: 100%;
            `}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_URL}/profile/public/preview`,
                '_blank'
              )
            }}
          >
            <Typography variant="subtitle2">Previsualizar</Typography>
            <Typography variant="subtitle4" color="theme.palette.gray.main">
              (Ve tu perfil antes de compartirlo)
            </Typography>
          </Grid>
          <Grid
            container
            justify="center"
            css={`
              padding-top: ${({ theme }) => theme.remCalc('30')};
            `}
          >
            <ShareButtonMobileStyled
              id="profile-profileDetail-button-closeShareProfileMobile"
              variant="outlined"
              color="gray"
              onClick={() => setIsOpen(false)}
            >
              Cancelar
            </ShareButtonMobileStyled>
          </Grid>
        </Grid>
      </DrawerStyled>
    </Fragment>
  )
}

ShareProfileButton.propTypes = {
  ShowModal: PropTypes.func,
  theme: PropTypes.object
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  ShowModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ShareProfileButton))
