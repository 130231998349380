import { useSelector, useDispatch } from 'react-redux'
import { getCookie } from 'utils/handleCookies'
import { SetToken } from 'reduxActions/auth'

export const useGetToken = () => {
  const dispatch = useDispatch()
  const { token, roles } = useSelector(({ authReducer: { token, roles } }) => ({ token, roles }))
  const authCookie = getCookie('auth_user')

  const { tokenValidated: tokenToValidate, rolesValidated: rolesToValidate } = getTokenToValidate()

  function getTokenToValidate() {
    let tokenValidated = token
    let rolesValidated = roles

    if (!tokenValidated && authCookie) {
      const authCookieParsed = JSON.parse(authCookie)

      if (authCookieParsed && authCookieParsed.token) {
        tokenValidated = authCookieParsed.token
        rolesValidated = authCookieParsed.roles

        dispatch(SetToken(tokenValidated))
      }
    }

    return { tokenValidated, rolesValidated }
  }

  return {
    token: tokenToValidate,
    roles: rolesToValidate
  }
}