import React from 'react'
import { string, func } from 'prop-types'
import styled from 'styled-components'
import { Grid, Typography, Button } from 'rc'

import { WiggotPrimeLogo } from 'components/Icons'
import OnBoardingMC, { FLOW } from 'modules/prime/OnBoardingMC'

import WelcomePrime from 'static/img/welcome_prime.png'
import BackgroundPrime from 'static/img/background_prime.png'

const WelcomeToPrimeMC = props => {
  return (
    <GridContainerStyled
      className={props.className}
      container
      direction="column"
      alignItems="space-between"
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="space-between"
        css={`
          height: ${({ theme }) => theme.remCalc('180')};
          background-image: url(${BackgroundPrime});
          background-size: cover;
          background-repeat: no-repeat;
          ${({ theme }) => theme.media.desktop`
          background-size: contain;
          height: ${({ theme }) => theme.remCalc('260')};
        `}
        `}
      />
      <Grid
        container
        justify="center"
        alignItems="space-around"
        direction="column"
        css={`
          position: absolute;
          width: 100%;
          height: 100%;
        `}
      >
        <Grid
          container
          direction="column"
          alignItems="space-between"
          css={`
            width: 100%;
            height: 100%;
          `}
        >
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            css={`
              height: ${({ theme }) => theme.remCalc('180')};
              ${({ theme }) => theme.media.desktop`
              height: ${({ theme }) => theme.remCalc('260')};
            `}
            `}
          >
            <Grid
              container
              justify="space-around"
              css={`
                flex-direction: column;
                align-items: center;
                position: relative;
                height: 100%;
                ${({ theme }) => theme.media.desktop`
                flex-direction: row;
                align-items: inherit;
              `}
              `}
            >
              <Grid
                container
                direction="column"
                justify="center"
                css={`
                  height: 100%;
                  margin-top: ${({ theme }) => theme.remCalc('20')};
                  ${({ theme }) => theme.media.desktop`
                  width: 35%;
                  margin-top: ${({ theme }) => theme.remCalc('80')};
                `}
                `}
              >
                <Typography
                  variant="h2"
                  color="white"
                  textTransform="initial"
                  css={`
                    font-size: ${({ theme }) => theme.remCalc('25')};
                    margin-left: ${({ theme }) => theme.remCalc('10')};
                    display: none;
                    ${({ theme }) => theme.media.desktop`
                    display: block;
                  `}
                  `}
                >
                  {'Bienvenido a'}
                </Typography>
                <WiggotPrimeLogo
                  css={`
                    height: ${({ theme }) => theme.remCalc('40')};
                  `}
                />
              </Grid>
              <ImageGridStyled container />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="space-around"
            direction="column"
            css={`
              height: ${({ theme }) => theme.remCalc('300')};
              ${({ theme }) => theme.media.desktop`
              margin-bottom: ${({ theme }) => theme.remCalc('10')};
              height: ${({ theme }) => theme.remCalc('180')};
            `}
            `}
          >
            <Grid
              container
              center
              css={`
                width: 85%;
              `}
            >
              <Typography
                variant="h2"
                textTransform="initial"
                align="center"
                css={`
                  display: block;
                  margin-bottom: ${({ theme }) => theme.remCalc('20')};
                  line-height: 1;
                  ${({ theme }) => theme.media.desktop`
                    display: none;
                  `}
                `}
              >
                ¡Bienvenido!
              </Typography>
              <Typography
                variant="body1"
                css={`
                  text-align: center;
                `}
              >
                Ya puedes disfrutar de las herramientas de wiggot prime,
                descarga reportes, haz ofrecimientos y genera más oportunidades
                con sugerencias.
              </Typography>
              <Typography
                variant="subtitle1"
                color="palette.primary.main"
                css={`
                  text-align: center;
                  text-decoration: underline;
                  margin-top: ${({ theme }) => theme.remCalc('10')};
                  cursor: pointer;
                `}
                id="components-wiggotPrime-mc-button-welcomeToPrimeShowOnboarding"
                onClick={() =>
                  props.ShowModal(OnBoardingMC, { flow: FLOW.REMINDER })
                }
              >
                Conoce prime
              </Typography>
            </Grid>
            <Button
              onClick={() => {
                props.callBackAction && props.callBackAction()
                props.UnlockModal && props.UnlockModal()
                props.HideModal()
              }}
              css={`
                height: ${({ theme }) => theme.remCalc('50')};
                width: ${({ theme }) => theme.remCalc('220')};
              `}
              id="components-wiggotPrime-mc-button-welcomeToPrimeAccept"
            >
              Aceptar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </GridContainerStyled>
  )
}

WelcomeToPrimeMC.propTypes = {
  callBackAction: func,
  className: string,
  HideModal: func,
  ShowModal: func,
  UnlockModal: func
}

const GridContainerStyled = styled(Grid)`
  width: ${({ theme }) => theme.remCalc('600')};
  max-width: 100%;
  position: relative;
  height: ${({ theme }) => theme.remCalc('500')};
  ${({ theme }) => theme.media.desktop`
    height: ${({ theme }) => theme.remCalc('450')};
  `}
`

const ImageGridStyled = styled(Grid)`
  height: ${({ theme }) => theme.remCalc('290')};
  width: ${({ theme }) => theme.remCalc('150')};
  background-image: url(${WelcomePrime});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  margin: 0 auto;
  top: ${({ theme }) => theme.remCalc('60')};
  ${({ theme }) => theme.media.desktop`
    width: ${({ theme }) => theme.remCalc('180')};
    top: 0;
    position: relative;
    margin: ${({ theme }) => theme.remCalc('50 0 0 20')};
  `}
`

export default WelcomeToPrimeMC
