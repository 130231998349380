import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getFormValues,
  getFormSyncErrors,
  submit,
  touch,
  destroy
} from 'redux-form'

import { GetAddressStates, GetAddressCities } from 'reduxActions/general'

import { PrimaryButton, SecondaryButton } from 'c3-ui/elements/Buttons'
import { Title, Text, TextInline } from 'c3-ui/elements/Texts'
import { BallsStepper } from 'c3-ui/elements/Steppers'
import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'

import AgencyUserForm from './_AgencyUpgradeUserForm'
import AgencyDataForm from './_AgencyUpgradeDataForm'
import AgencyLocationForm from './_AgencyUpgradeLocationForm'

import { HideModal, ShowFlashNotification } from 'reduxActions/services'
import { RequestAgencyUpgrade } from 'reduxActions/agency'
import { camelToSnakeCase } from 'utils/helpers'

import { AGENCY_UPGRADE_FORM } from './_constants'

class RequestAgencyUpgradeMC extends Component {
  static propTypes = {
    token: PropTypes.string,
    email: PropTypes.string,
    agencyName: PropTypes.string,
    agencyPhone: PropTypes.string,
    formErrors: PropTypes.object,
    fullname: PropTypes.string,
    phoneNumber: PropTypes.string,
    agencyUpgradeForm: PropTypes.object,

    touch: PropTypes.func,
    submit: PropTypes.func,
    destroy: PropTypes.func,
    HideModal: PropTypes.func,
    GetAddressStates: PropTypes.func,
    GetAddressCities: PropTypes.func,
    RequestAgencyUpgrade: PropTypes.func,
    ShowFlashNotification: PropTypes.func
  }

  BASE_CLASSNAME = 'request-agency-upgrade-mc'

  config = {
    stepsFields: [
      ['contactName', 'phoneNumber', 'email'],
      ['agencyName', 'businessName', 'contactEmail', 'agencyPhone'],
      ['state', 'city', 'address']
    ]
  }

  constructor(props) {
    super(props)

    this.state = {
      currentStep: 0,
      steps: 3,
      percentage: 0,
      cityEnabled: false
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { agencyUpgradeForm = {} } = this.props
    const { currentStep } = this.state
    const { currentStep: prevCurrentState } = prevState
    const { agencyUpgradeForm: prevAgencyUpgradeForm = {} } = prevProps
    const isEqual = Object.keys(prevAgencyUpgradeForm).every(key => {
      return agencyUpgradeForm[key] === prevAgencyUpgradeForm[key]
    })

    if (
      !isEqual ||
      !prevProps.agencyUpgradeForm ||
      currentStep !== prevCurrentState
    ) {
      this.handlePercentage()
    }
  }

  hideModal = () => {
    const { destroy, HideModal } = this.props

    destroy(AGENCY_UPGRADE_FORM)

    HideModal()
  }

  handlePercentage = () => {
    const { currentStep } = this.state
    const { agencyUpgradeForm, formErrors } = this.props
    const errorFields = Object.keys(formErrors)
    const stepsFields = this.config.stepsFields[currentStep]
    let count = 0

    if (!agencyUpgradeForm) return

    for (const field of stepsFields) {
      if (agencyUpgradeForm[field] && !errorFields.includes(field)) count++
    }

    const percentage = (100 / stepsFields.length) * count

    this.setState({ percentage })
  }

  handleSubmit = data => {
    const { token, RequestAgencyUpgrade } = this.props
    const formattedData = camelToSnakeCase(data)
    return RequestAgencyUpgrade(token, formattedData)
  }

  handleSubmitSuccess = () => {
    const { HideModal, ShowFlashNotification, destroy } = this.props

    destroy(AGENCY_UPGRADE_FORM)
    HideModal()
    ShowFlashNotification('success', 'Tu solicitud ha sido enviada.')
  }

  handleSubmitFail = (formErrors, dispatch, submitErrors) => {
    const {
      error: {
        response: { data: { error: { code = '' } = {} } = {} } = {}
      } = {}
    } = submitErrors

    switch (code) {
      case 'REGISTER_AGENCY_ALREADY_EXISTS':
        ShowFlashNotification(
          'danger',
          'Este correo ya está registrado. Intenta con otro correo. Código de error: WIG-06'
        )
        break

      default:
        ShowFlashNotification(
          'danger',
          'Error desconocido, por favor intenta de nuevo más tarde.'
        )
        break
    }
  }

  moveHandler = () => {
    const { currentStep } = this.state
    const { formErrors, touch, submit } = this.props

    const errorFields = Object.keys(formErrors)

    if (errorFields.length > 0) {
      touch(AGENCY_UPGRADE_FORM, ...errorFields)
    } else {
      if (currentStep < 2) {
        this.setState({ currentStep: currentStep + 1 })
      } else submit(AGENCY_UPGRADE_FORM)
    }
  }

  backHandler = () => {
    const { currentStep } = this.state

    this.setState({ currentStep: currentStep - 1 })
  }

  getStates = (search, resolve) => {
    const { GetAddressStates, token } = this.props
    GetAddressStates(token, search).then(res => {
      const {
        payload: { data }
      } = res
      resolve(data)
    })
  }

  getCities = (search, resolve) => {
    const {
      GetAddressCities,
      token,
      agencyUpgradeForm: { state }
    } = this.props

    GetAddressCities(token, state, search).then(res => {
      const {
        payload: { data }
      } = res
      resolve(data)
    })
  }

  stateSelected = () => {
    this.setState({ cityEnabled: true })
  }

  renderForm = () => {
    const { currentStep, cityEnabled } = this.state
    const {
      agencyName,
      fullname,
      phoneNumber,
      email,
      agencyPhone,
      HideModal
    } = this.props
    const data = {
      agencyName,
      contactName: fullname,
      phoneNumber: agencyPhone || phoneNumber,
      contactEmail: email,
      email
    }
    let render

    switch (currentStep) {
      case 0:
        render = <AgencyUserForm initialValues={data} />
        break
      case 1:
        render = <AgencyDataForm />
        break
      case 2:
        render = (
          <AgencyLocationForm
            getStates={this.getStates}
            stateSelected={this.stateSelected}
            cityEnabled={cityEnabled}
            getCities={this.getCities}
            handleSubmit={this.handleSubmit}
            handleSubmitSuccess={this.handleSubmitSuccess}
            handleSubmitFail={this.handleSubmitFail}
            cancelCallback={HideModal}
          />
        )
        break
      default:
        render = null
    }

    return render
  }

  renderRightButtons = () => {
    const { currentStep: stateStep, steps, percentage } = this.state
    const finished = stateStep === steps - 1 && percentage === 100
    const currentStep = finished ? stateStep + 1 : stateStep
    const render = []

    if (currentStep > 0)
      render.push(
        <SecondaryButton
          medium={6}
          id="profile-requestAgencyUpgrade-mc-button-goBack"
          onClick={this.backHandler}
        >
          Volver
        </SecondaryButton>
      )
    render.push(
      <PrimaryButton
        disabled={currentStep === 2}
        onClick={this.moveHandler}
        medium={6}
        id={`profile-requestAgencyUpgrade-mc-button-nextStep-${currentStep}`}
      >
        {currentStep >= 2 ? 'Enviar' : 'Siguiente'}
      </PrimaryButton>
    )

    return render
  }

  render() {
    const { currentStep: stateStep, steps, percentage } = this.state
    const finished = stateStep === steps - 1 && percentage === 100
    const currentStep = finished ? stateStep + 1 : stateStep

    return (
      <Layout className={this.BASE_CLASSNAME}>
        <Title
          bold
          gray
          uppercase
          center
          className={`${this.BASE_CLASSNAME}__title`}
          smallSize
        >
          Habilitar cuenta como inmobiliaria{' '}
        </Title>
        <Text className={`${this.BASE_CLASSNAME}__main-description`}>
          Para habilitar tu cuenta{' '}
          <TextInline uppercase>TOTALMENTE GRATIS </TextInline> solo envía este
          formulario y un asesor se comunicará contigo para integrar tu cuenta
          con tus propiedades y asesores
        </Text>
        <BallsStepper
          steps={steps}
          currentStep={currentStep}
          titles={['Datos Personales', 'Datos de la inmobiliaria', 'Ubicación']}
          percentage={percentage}
        />
        <LayoutItem className={`${this.BASE_CLASSNAME}__form`}>
          {this.renderForm()}
        </LayoutItem>
        <Layout
          paddingX
          justify
          className={`${this.BASE_CLASSNAME}__main-actions`}
        >
          <SecondaryButton
            invisible
            medium={3}
            id="profile-requestAgencyUpgrade-mc-button-cancel"
            onClick={this.hideModal}
          >
            Cancelar
          </SecondaryButton>
          <LayoutItem medium={8}>
            <Layout paddingX right>
              {this.renderRightButtons()}
            </Layout>
          </LayoutItem>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({
  authReducer: { token },
  profileReducer: { email, agencyName, agencyPhone, fullname, phoneNumber },
  ...state
}) => ({
  token,
  email,
  agencyName,
  agencyPhone,
  fullname,
  phoneNumber,
  agencyUpgradeForm: getFormValues(AGENCY_UPGRADE_FORM)(state),
  formErrors: getFormSyncErrors(AGENCY_UPGRADE_FORM)(state)
})

const mapDispatchToProps = {
  HideModal,
  ShowFlashNotification,
  RequestAgencyUpgrade,
  GetAddressStates,
  GetAddressCities,
  submit,
  touch,
  destroy
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestAgencyUpgradeMC)
