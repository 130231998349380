import React from 'react'
import { createSvgIcon } from 'rc'

export default createSvgIcon(
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.2501 19C10.25 18.9934 10.25 18.9867 10.25 18.98C10.25 17.9644 10.9995 17.1239 11.9755 16.9813C11.0008 16.8475 10.25 16.0114 10.25 15C10.25 13.8954 11.1454 13 12.25 13H12.27C11.1611 13 10.2608 12.1064 10.2501 11H18.2499C18.2392 12.1064 17.339 13 16.23 13H16.25C17.3546 13 18.25 13.8954 18.25 15C18.25 16.0114 17.4992 16.8475 16.5245 16.9813C17.5005 17.1239 18.25 17.9644 18.25 18.98C18.25 18.9867 18.25 18.9934 18.2499 19H10.2501Z" fill="#FFC107"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.25 13V11L4.25 11V12.9992C4.24957 11.895 3.35431 11 2.25 11H0.25L0.25 15H2.25C1.14543 15 0.25 15.8954 0.25 17L0.25 19H4.25V17C4.25 18.1046 5.14544 19 6.25 19H8.25V15H6.2508C7.355 14.9996 8.25 14.1043 8.25 13Z" fill="#FF5722"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.2279 7.13464C12.0221 7.44239 12.0551 7.86249 12.3268 8.13423L13.7523 9.55972C14.0616 9.869 14.5631 9.869 14.8724 9.55972L16.2978 8.13423C16.576 7.8561 16.604 7.42259 16.3819 7.11315C16.6897 7.31893 17.1098 7.28594 17.3815 7.0142L18.807 5.58871C19.1163 5.27943 19.1163 4.77797 18.807 4.46869L17.3815 3.0432C17.1034 2.76507 16.6699 2.73705 16.3605 2.95909C16.5662 2.65135 16.5332 2.23126 16.2615 1.95952L14.836 0.534027C14.5267 0.22474 14.0253 0.224746 13.716 0.534027L12.2905 1.95952C12.0124 2.23764 11.9843 2.67116 12.2064 2.98059C11.8986 2.77482 11.4786 2.80781 11.2068 3.07955L9.78132 4.50504C9.47203 4.81433 9.47203 5.31578 9.78132 5.62507L11.2068 7.05056C11.4849 7.32868 11.9184 7.35671 12.2279 7.13464Z" fill="#0038FF"/>
    <path d="M5.38831 1.65339C4.87716 0.782204 3.61762 0.782204 3.1065 1.65339L3.08794 1.68503C2.8521 2.08693 2.42226 2.33512 1.9563 2.33838L1.91958 2.33864C0.909544 2.34571 0.279784 3.43649 0.778704 4.31475L0.796824 4.34664C1.02698 4.75181 1.02698 5.2482 0.796824 5.65336L0.778704 5.68524C0.279784 6.56352 0.909544 7.65428 1.91958 7.66136L1.9563 7.6616C2.42226 7.66488 2.8521 7.91308 3.08794 8.31496L3.1065 8.3466C3.61762 9.2178 4.87716 9.2178 5.38831 8.3466L5.40687 8.31496C5.64268 7.91308 6.07256 7.66488 6.53852 7.6616L6.5752 7.66136C7.58524 7.65428 8.215 6.56352 7.71611 5.68524L7.69799 5.65336C7.46783 5.2482 7.46783 4.75181 7.69799 4.34664L7.71611 4.31475C8.215 3.43649 7.58524 2.34571 6.5752 2.33864L6.53852 2.33838C6.07255 2.33512 5.64268 2.08693 5.40687 1.68503L5.38831 1.65339Z" fill="#00C0C4"/>
  </>,
  'HomeIcon',
  '0 0 20 19'
)
