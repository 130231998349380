const AGENCY_UPGRADE_FORM = 'agency-upgrade-form'
const BASE_CLASSNAME = 'agency-upgrade-form'
const LABEL_PROPS = {
  rightAlignLabel: true,
  inlineLabel: true,
  mdAutoLabel: true,
  labelClassName: `${BASE_CLASSNAME}__label`,
  inputWrapperClassName: `${BASE_CLASSNAME}__input`,
  topAlignLabel: true
}

export { AGENCY_UPGRADE_FORM, BASE_CLASSNAME, LABEL_PROPS }
