import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M9.39,10a1.61,1.61,0,0,1,0,2.23L8.14,13.43a1.61,1.61,0,0,1-2.23,0L.45,7.93a1.62,1.62,0,0,1,0-2.24L1.7,4.45a1.61,1.61,0,0,1,2.23,0Z"
      fill="#fff"
    />
    <path
      d="M14.16.45a1.61,1.61,0,0,1,2.23,0L17.64,1.7a1.61,1.61,0,0,1,0,2.23L8.18,13.34a1.61,1.61,0,0,1-2.23,0L4.7,12.09a1.61,1.61,0,0,1,0-2.23Z"
      fill="#fff"
    />
  </Fragment>,
  'Check',
  '0 0 19 14'
)
