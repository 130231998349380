import React from 'react'
import { Typography } from 'rc'
import styled from 'styled-components'
import ActivePrimev2 from 'static/img/active_prime_v2.svg'
import { Button } from 'components'

const PromotionPayInfo = ({ HideModal }) => {
  return (
    <Container>
      <Typography
        variant="p2"
        align="center"
        color="palette.primary.main"
        fontWeight="bold"
      >
        Ahorra más con tu pago recurrente
      </Typography>
      <IconStyled src={ActivePrimev2} alt="ahorra" />
      <ContentText>
        <Typography>
          Al contratar un plan mensual personal y elegir la opción de pago con
          tarjeta, se te hará un <b>5% de descuento adicional</b> en cada
          mensualidad. Podrás cancelar esta suscripción en cualquier momento.
        </Typography>
        <br />
        <br />
        <Typography className="bold">
          *Solo aplica para suscripciones mensuales en planes individuales
          <br />
          *Solo aplica para suscripciones mensuales en planes individuales
        </Typography>
      </ContentText>
      <div
        css={`
          display: flex;
          justify-content: center;
          margin-top: 15px;
        `}
      >
        <ButtonStyled onClick={HideModal}>Cerrar</ButtonStyled>
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.remCalc('500')};
  padding: ${({ theme }) => theme.remCalc('20 5')};
`
const IconStyled = styled.img`
  height: ${({ theme }) => theme.remCalc('220')};
  padding: ${({ theme }) => theme.remCalc('20')};
  ${({ theme }) => theme.media.desktopDown`display: none;`}
`
const ContentText = styled.div`
  padding: ${({ theme }) => theme.remCalc('0 20')};
  p {
    font-weight: 400;
    color: #605d75;
  }
  p.bold {
    font-weight: 500;
  }
`
const ButtonStyled = styled(Button)`
  border-radius: ${({ theme }) => theme.remCalc('30')} !important;
  width: 48%;
`

export default PromotionPayInfo
