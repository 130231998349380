import React from 'react'
import { bool, func, string } from 'prop-types'
import { Typography } from 'rc'
import { CheckboxInput } from 'rc/FormComponents'

function LocationCheckbox(props) {
  return (
    <CheckboxInput
      name={props.name}
      value={props.value}
      label={
        <>
          <Typography
            component="span"
            variant="subtitle1"
            display="block"
          >
            {props.label}
          </Typography>
          <Typography
            className="sub-label"
            component="span"
            variant="body2"
            display="block"
            color="gray"
          >
            {props.sublabel}
          </Typography>
        </>
      }
      onChange={props.onChange}
      checked={props.checked}
    />
  )
}

LocationCheckbox.propTypes = {
  name: string,
  checked: bool,
  value: string,
  onChange: func,
  label: string,
  sublabel: string
}

export default LocationCheckbox
