import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Layout } from 'c3-ui/elements/Layouts'
import { Title, Text } from 'c3-ui/elements/Texts'
import { DangerButton, SecondaryButton } from 'c3-ui/elements/Buttons'

import { DeleteAssociationAgencies } from 'reduxActions/association'
import { HideModal, ShowFlashNotification } from 'reduxActions/services'
export class DeleteAssociationAgencyMC extends Component {
  static propTypes = {
    token: PropTypes.string,
    agencies: PropTypes.array,

    updateCallback: PropTypes.func,
    DeleteAssociationAgencies: PropTypes.func,
    HideModal: PropTypes.func,
    ShowFlashNotification: PropTypes.func
  }

  BASE_CLASSNAME = 'delete-association-agency-mc'

  deleteAssociationAgencies = () => {
    const {
      agencies,
      token,
      updateCallback,
      HideModal,
      ShowFlashNotification,
      DeleteAssociationAgencies
    } = this.props

    const agenciesIds = agencies.map(({ agencyId }) => agencyId)
    DeleteAssociationAgencies(token, agenciesIds).then(action => {
      HideModal()
      updateCallback()
      ShowFlashNotification(
        'success',
        `Se ha${agenciesIds.length !== 1 ? 'n' : ''} eliminado la${
          agenciesIds.length !== 1 ? 's' : ''
        } inmobiliaria${agenciesIds.length !== 1 ? 's' : ''} de tu agencia.`
      )
    })
  }

  render() {
    const { agencies, HideModal } = this.props

    return (
      <Layout className={this.BASE_CLASSNAME} center>
        <Title
          className={`${this.BASE_CLASSNAME}__title`}
          center
          smallSize
          uppercase
        >
          {agencies.length === 1
            ? `¿DESEAS ELIMINAR A ${agencies[0].name || agencies[0].email}?`
            : `¿DESEAS BORRAR ${agencies.length} INMOBILIARIAS?`}
        </Title>

        <Text className={`${this.BASE_CLASSNAME}__description`} gray>
          Al eliminar una inmobiliaria se darán de baja sus propiedades y sus
          asesores de tu asociación permanentenmente.
        </Text>

        {agencies.length > 1 ? (
          <Layout>
            <Text uppercase className={`${this.BASE_CLASSNAME}__subtitle`}>
              INMOBILIARIA(S):{' '}
            </Text>
            <Text bold>{agencies.map(({ name }) => name).join('; ')}.</Text>
          </Layout>
        ) : (
          ''
        )}
        <Layout center middle marginX paddingY>
          <SecondaryButton
            id="associationAgencies-deleteAssociationAgency-mc-button-cancel"
            onClick={HideModal}
            medium={5}
          >
            CANCELAR
          </SecondaryButton>

          <DangerButton
            id="associationAgencies-deleteAssociationAgency-mc-button-delete"
            onClick={this.deleteAssociationAgencies}
            medium={5}
          >
            BORRAR
          </DangerButton>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  DeleteAssociationAgencies,
  HideModal,
  ShowFlashNotification
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAssociationAgencyMC)
