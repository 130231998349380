import { PaymentStatus } from 'modules/account/modalContent'
import { SendPrimeRenovationMC, WelcomeToPrimeMC } from 'modules/prime'
import { NewWiggotLeadMC } from 'modules/dashboard/Leads/modalContent'

export default function notificationModalResolver(notf) {
  function moveToURL(url) {}
  const newPropertiesUrl = () =>
    window.location.replace(
      `${process.env.REACT_APP_NEW_APLICATION_URL}/my-properties`
    )
  let url = ''
  switch (notf.type) {
    case 'WELCOME_TO_PRIME':
    case 'WELCOME_BACK_TO_PRIME':
    case 'TRIAL_PRIME_ACTIVATED':
      return (function () {
        return { component: WelcomeToPrimeMC, props: {} }
      })()
    case 'EXPIRED_PRIME':
    case 'PRIME_ABOUT_TO_EXPIRE':
      return (function () {
        return { component: SendPrimeRenovationMC, props: {} }
      })()
    case 'THERE_ARE_PROPERTIES_TO_UPDATE':
      newPropertiesUrl()
      return (function () {
        return {
          props: {
            callBackAction: moveToURL(url)
          }
        }
      })()
    case 'THERE_ARE_PUBLIC_LISTS_TO_UPDATE':
      url = '/lists'
      window.location.href = url
      if (window.location.pathname === '/lists') {
        window.location.reload()
      }
      return (function () {
        return {
          props: {
            callBackAction: moveToURL(url)
          }
        }
      })()
    case 'AGENCY_SUBSCRIPTION_PAYMENT_FAILED':
      url = '?u=agency'
    // eslint-disable-next-line no-fallthrough
    case 'USER_SUBSCRIPTION_PAYMENT_FAILED':
      return (function () {
        url = `/account${url}`
        let metadata =
          typeof notf.data.metadata === 'string'
            ? JSON.parse(notf.data.metadata)
            : notf.data.metadata

        return {
          component: PaymentStatus,
          props: {
            declined: true,
            callBackAction: moveToURL(url),
            cardReference: metadata ? metadata.card_reference : '-'
          }
        }
      })()
    case 'AGENCY_SUBSCRIPTION_PAYMENT_SUCCESS':
      url = '?u=agency'
    // eslint-disable-next-line no-fallthrough
    case 'USER_PRIME_SUBSCRIPTION_PAYMENT_SUCCESS':
      return (function () {
        url = `/account${url}`
        return {
          component: PaymentStatus,
          props: {
            callBackAction: moveToURL(url),
            planType: 'Prime',
            isAgency: url !== '/account'
          }
        }
      })()
    case 'USER_PASS_SUBSCRIPTION_PAYMENT_SUCCESS':
      return (function () {
        return {
          component: PaymentStatus,
          props: {
            callBackAction: moveToURL(url),
            planType: 'Pass'
          }
        }
      })()

    case 'ASSIGN_LEAD':
      return (function (props) {
        const metadata = notf.metadata && JSON.parse(notf.metadata)
        if (metadata && metadata.lead_data) {
          return {
            component: NewWiggotLeadMC,
            props: { metadata }
          }
        } else {
          if (metadata && metadata.url) {
            window.open(metadata.url, '_self')
          }
        }
      })()

    default:
      return null
  }
}
