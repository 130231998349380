var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/Previewers/VirtualTourPreviewer.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Layout } from '../Layouts';
import { VirtualTour, Empty } from '../../generics/Previewers';

var VirtualTourPreviewer = (_temp2 = _class = function (_Component) {
  _inherits(VirtualTourPreviewer, _Component);

  function VirtualTourPreviewer() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, VirtualTourPreviewer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = VirtualTourPreviewer.__proto__ || Object.getPrototypeOf(VirtualTourPreviewer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      valid: true,
      videoId: null,
      url: null
    }, _this.getVirtualTourId = function (url) {
      if (url) {
        var regExpMatterport = /^.*(my\.matterport\.com\/|v\/|u\/\w\/|show\?m=)([^#&?]*).*/;
        var matchMatterport = url.match(regExpMatterport);

        var regExpCloudpano = /^.*(app\.cloudpano\.com\/tours\/)([^?/]+)/;
        var matchCloudpano = url.match(regExpCloudpano);

        if (matchMatterport || matchCloudpano) {
          return _this.setState({ valid: true });
        }

        return _this.setState({ valid: false });
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(VirtualTourPreviewer, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var url = this.props.url;

      if (url) {
        this.setState({ url: url });
        this.getVirtualTourId(url);
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      if (this.props.url !== prevProps.url) {
        this.getVirtualTourId(this.props.url);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          height = _props.height,
          width = _props.width,
          url = _props.url;
      var valid = this.state.valid;


      return React.createElement(
        Layout,
        { shrink: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 59
          },
          __self: this
        },
        url ? valid ? React.createElement(VirtualTour, {
          height: height,
          width: width,
          url: url.indexOf('http') == 0 ? url : 'http://' + url,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 62
          },
          __self: this
        }) : React.createElement(Empty, {
          height: height,
          width: width,
          urlImage: 'https://s3-us-west-2.amazonaws.com/icons-forms/Previewers/fill-1%403x.png',
          label: 'TU RECORRIDO VIRTUAL NO TIENE EL FORMATO CORRECTO',
          broken: true,
          matterport: true,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 68
          },
          __self: this
        }) : React.createElement(Empty, {
          height: height,
          width: width,
          urlImage: 'https://s3-us-west-2.amazonaws.com/icons-forms/MatterPort-icon.png',
          label: 'NO HAY RECORRIDO VIRTUAL ADJUNTO',
          matterport: true,
          __source: {
            fileName: _jsxFileName,
            lineNumber: 78
          },
          __self: this
        })
      );
    }
  }]);

  return VirtualTourPreviewer;
}(Component), _class.propTypes = {
  /** */
  height: PropTypes.string,
  /** */
  url: PropTypes.string,
  /** */
  value: PropTypes.any,
  /** */
  width: PropTypes.string
}, _temp2);


export default VirtualTourPreviewer;