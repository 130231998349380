import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from 'rc'
import { Button } from 'components'
import { WiggotPrimeLogo } from 'src/components/Icons'
import { useFreemium } from 'modules/freemium/hooks/useFreemium'

const BenefitLayoutMC = ({ children, description, title, image, goBackOnClose }) => {
  const { openPaymentSection, closeModal } = useFreemium({goBackOnClose})

  return (
    <Container>
      <WiggotPrimeLogo css={`width: ${({theme})=> theme.remCalc('180')};`}/>
      {children ? children : (
        <ContentContainer>
          <Typography 
            variant="h3" 
            align="center" 
            color="palette.prime.yellow"
          >
            {title}
          </Typography>
          <img 
            src={image} 
            alt="ilustración" 
            css={`max-height: ${({theme})=> theme.remCalc('160')};`} 
          />
          <Typography 
            variant="body" 
            align="center" 
            color="palette.white.main"
          >
            {description}
          </Typography>
        </ContentContainer>
      ) }
      <ButtonsContainer>
        <ButtonStyled type="custom" onClick={closeModal}>
          Cancelar
        </ButtonStyled>
        <ButtonStyled type="primary" onClick={openPaymentSection}>
          Contrata Prime
        </ButtonStyled>
      </ButtonsContainer>
    </Container>
  )
}

BenefitLayoutMC.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  goBackOnClose: PropTypes.bool,
  image: PropTypes.string,
  title: PropTypes.string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: ${({theme})=> theme.remCalc('450')};
  min-height: ${({theme})=> theme.remCalc('500')};
  padding: ${({theme})=> theme.remCalc('20')};
  background-color: ${({theme})=> theme.palette.prime.dark};
  color: ${({theme})=> theme.palette.prime.text};
  box-sizing: border-box;
  ${({theme})=> theme.media.tabletDown`
    min-width: auto;
    max-width: 100%;
  `}
`
const ContentContainer = styled.div`
  width: ${({theme})=> theme.remCalc('410')};
    max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1;
`
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const ButtonStyled = styled(Button).attrs({rounded: true})`
  width: 48%;
`

export default BenefitLayoutMC