import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M21.547 20.6188C21.547 21.2324 21.36 21.8224 21.0328 22.318C20.7056 22.8136 20.2148 23.2148 19.6773 23.4508C19.1164 23.6868 18.5088 23.734 17.9245 23.616C17.3402 23.498 16.8027 23.2148 16.3586 22.7664C15.9146 22.318 15.6575 21.8224 15.5407 21.2088C15.4238 20.6188 15.4939 20.0052 15.7043 19.4388C15.938 18.8724 16.3119 18.4004 16.8261 18.07C17.3402 17.7396 17.9011 17.5508 18.5088 17.5508C19.3267 17.5508 20.098 17.8812 20.6589 18.4476C21.2198 19.014 21.547 19.7928 21.547 20.6188Z"
      fill="#FFC834"
    />
    <path
      d="M80.418 2.80757C80.418 2.24117 80.5816 1.69837 80.8854 1.24997C81.1892 0.777964 81.6332 0.423962 82.124 0.211561C82.6382 -0.000839561 83.1991 -0.0480398 83.7366 0.0463607C84.2742 0.164361 84.7649 0.423962 85.1622 0.825164C85.5595 1.22637 85.8166 1.72197 85.9335 2.26477C86.0503 2.80757 85.9802 3.37398 85.7699 3.89318C85.5595 4.41238 85.209 4.83718 84.7416 5.16759C84.2742 5.47439 83.7366 5.63959 83.1991 5.63959C82.4512 5.63959 81.7501 5.33279 81.2359 4.81358C80.7218 4.27078 80.418 3.56278 80.418 2.80757Z"
      fill="#FFC834"
    />
    <path
      d="M78.1292 11.25L71.2348 31.4045H66.1166L62.0968 18.4244L58.077 31.4045H52.9588L46.041 11.25H51.1592L55.5062 23.9469L59.8532 11.25H64.317L68.664 23.9469L73.011 11.25H78.1292Z"
      fill="#22242F"
    />
    <path d="M85.7706 11.25H80.6523V31.4045H85.7706V11.25Z" fill="#22242F" />
    <path
      d="M110.311 11.2503V29.7764C110.194 35.9124 105.053 39.9245 100.005 39.9245C93.0636 39.9245 90.259 34.9212 89.9552 34.048L93.975 31.9004C94.2555 32.75 96.5692 35.2752 100.005 35.2752C103.277 35.2752 104.983 32.2544 105.193 30.036C103.534 31.1216 101.594 31.688 99.6074 31.688C96.873 31.688 94.2555 30.6024 92.3391 28.6436C90.3993 26.6848 89.3242 24.0416 89.3242 21.304C89.3242 18.5664 90.3993 15.8995 92.3391 13.9643C94.2788 12.0055 96.8964 10.9199 99.6074 10.9199C101.571 10.9199 103.51 11.4863 105.146 12.5719V11.2503H110.311V11.2503ZM105.006 21.3748C105.006 18.1652 102.809 15.8523 99.8177 15.8523C96.7795 15.8523 94.6294 18.1416 94.6294 21.3748C94.6294 24.6316 96.7795 26.9444 99.8177 26.9444C102.809 26.9444 105.006 24.6316 105.006 21.3748Z"
      fill="#22242F"
    />
    <path
      d="M134.687 11.2503V29.7764C134.57 35.9124 129.428 39.9245 124.404 39.9245C117.463 39.9245 114.658 34.9212 114.354 34.048L118.374 31.9004C118.654 32.75 120.968 35.2752 124.404 35.2752C127.676 35.2752 129.382 32.2544 129.592 30.036C127.933 31.1216 125.993 31.688 124.006 31.688C121.319 31.6408 118.748 30.508 116.855 28.5728C114.962 26.6376 113.91 24.018 113.91 21.304C113.91 18.59 114.962 15.9703 116.855 14.0351C118.748 12.0999 121.319 10.9671 124.006 10.9199C125.969 10.9199 127.909 11.4863 129.545 12.5719V11.2503H134.687ZM129.358 21.3748C129.358 18.1652 127.161 15.8523 124.17 15.8523C121.132 15.8523 118.982 18.1416 118.982 21.3748C118.982 24.6316 121.132 26.9444 124.17 26.9444C127.161 26.9444 129.358 24.6316 129.358 21.3748Z"
      fill="#22242F"
    />
    <path
      d="M137.795 21.3273C137.795 19.2741 138.403 17.268 139.524 15.5452C140.646 13.846 142.259 12.5008 144.152 11.722C146.045 10.9432 148.101 10.7308 150.111 11.132C152.098 11.5332 153.944 12.5244 155.393 13.9876C156.842 15.4508 157.824 17.2916 158.221 19.3213C158.618 21.3273 158.408 23.4277 157.637 25.3393C156.866 27.2509 155.533 28.8557 153.851 30.0121C152.168 31.1449 150.158 31.7585 148.125 31.7585C145.39 31.7585 142.773 30.6493 140.857 28.7141C138.893 26.7317 137.818 24.0885 137.795 21.3273ZM153.477 21.3273C153.477 18.1177 151.28 15.7576 148.288 15.7576C145.25 15.7576 143.1 18.0941 143.1 21.3273C143.1 24.5605 145.25 26.8969 148.288 26.8969C151.28 26.8969 153.477 24.5605 153.477 21.3273Z"
      fill="#22242F"
    />
    <path
      d="M174.346 29.3044C174.346 29.3044 172.196 31.7116 168.714 31.7116C165.279 31.7116 162.638 30.0124 162.638 25.8352V15.8995H160.207V11.2503H162.638V4.52425L167.756 4.40625V11.2503H172.453V15.8759H167.756V24.254C167.756 25.906 168.714 26.9208 169.836 26.9208C171.191 26.9208 172.15 25.8352 172.15 25.198L174.346 29.3044Z"
      fill="#22242F"
    />
    <path
      d="M36.3651 21.5173C36.3183 21.4465 36.2716 21.3757 36.2248 21.3049L20.1691 5.09163C20.1457 5.04443 20.0989 4.97363 20.0756 4.92643C19.6315 4.50163 19.0706 4.26562 18.463 4.26562C17.8553 4.26562 17.2711 4.50163 16.8504 4.92643C16.8037 4.97363 16.7803 5.04443 16.7335 5.09163L0.654384 21.3285C0.444046 21.5409 0.28045 21.8005 0.163596 22.1073C0.0467417 22.3905 0 22.6973 0 23.0041V23.0277V32.6566C0 33.1994 0.210338 33.695 0.607643 34.0726C0.981576 34.4502 1.49574 34.6626 2.03327 34.6626H35.0563C35.5938 34.6626 36.108 34.4502 36.4819 34.0726C36.8559 33.695 37.0662 33.1758 37.0662 32.633V23.0041C37.0662 22.7209 36.9961 22.4377 36.8792 22.1781C36.739 21.9421 36.5754 21.7061 36.3651 21.5173ZM4.83777 23.5233L18.4396 9.78805L32.2284 23.7121V30.1314H4.8144L4.83777 23.5233Z"
      fill="#0076CC"
    />
    <path
      d="M142.297 36C143.345 36 144.256 36.1676 145.028 36.5029C145.8 36.8276 146.396 37.299 146.816 37.9171C147.235 38.5248 147.445 39.2424 147.445 40.07C147.445 40.8976 147.235 41.6152 146.816 42.2229C146.396 42.8305 145.8 43.3019 145.028 43.6371C144.256 43.9619 143.345 44.1243 142.297 44.1243H140.277V47H137V36H142.297ZM142.098 41.6729C142.771 41.6729 143.279 41.5367 143.621 41.2643C143.963 40.9814 144.134 40.5833 144.134 40.07C144.134 39.5567 143.963 39.1586 143.621 38.8757C143.279 38.5929 142.771 38.4514 142.098 38.4514H140.277V41.6729H142.098Z"
      fill="#FFC834"
    />
    <path
      d="M154.108 44.0771H152.32V47H149.043V36H154.339C155.388 36 156.298 36.1676 157.071 36.5029C157.843 36.8276 158.439 37.299 158.858 37.9171C159.278 38.5248 159.487 39.2424 159.487 40.07C159.487 40.8662 159.289 41.5629 158.891 42.16C158.505 42.7467 157.948 43.2076 157.22 43.5429L159.719 47H156.21L154.108 44.0771ZM156.177 40.07C156.177 39.5567 156.006 39.1586 155.664 38.8757C155.322 38.5929 154.814 38.4514 154.141 38.4514H152.32V41.6729H154.141C154.814 41.6729 155.322 41.5367 155.664 41.2643C156.006 40.9814 156.177 40.5833 156.177 40.07Z"
      fill="#FFC834"
    />
    <path d="M161.295 36H164.573V47H161.295V36Z" fill="#FFC834" />
    <path
      d="M177.366 47L177.333 41.17L174.353 45.9157H172.897L169.934 41.3271V47H166.905V36H169.603L173.675 42.3486L177.647 36H180.345L180.378 47H177.366Z"
      fill="#FFC834"
    />
    <path
      d="M192 44.5957V47H182.697V36H191.785V38.4043H185.942V40.2586H191.09V42.5843H185.942V44.5957H192Z"
      fill="#FFC834"
    />
  </Fragment>,
  'WiggotPrimeLogoBar',
  '0 0 192 47'
)
