import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#EE9721"
    />
    <path
      d="M5.259 5.7681C5.3703 5.7681 5.4823 5.73059 5.5744 5.65369L6.9128 4.53759C7.1218 4.36329 7.1499 4.05249 6.9756 3.84339C6.8013 3.63439 6.4905 3.60619 6.2814 3.78059L4.9431 4.89669C4.734 5.07099 4.7059 5.3818 4.8802 5.5909C4.9777 5.7077 5.1178 5.7681 5.259 5.7681Z"
      fill="white"
    />
    <path
      d="M13.6079 4.5378L14.9462 5.6538C15.0384 5.7307 15.1503 5.7682 15.2616 5.7682C15.4028 5.7682 15.5429 5.7079 15.6404 5.591C15.8147 5.3819 15.7866 5.0711 15.5775 4.8968L14.2392 3.7807C14.0302 3.6064 13.7194 3.6345 13.545 3.8436C13.3707 4.0526 13.3988 4.3634 13.6079 4.5378Z"
      fill="white"
    />
    <path
      d="M14.3729 6.0225C13.3855 5.0352 12.1055 4.4468 10.7286 4.3378V3.8262C10.7286 3.554 10.5079 3.3333 10.2357 3.3333C9.9635 3.3333 9.7428 3.554 9.7428 3.8262V4.3421C8.3846 4.4611 7.1234 5.047 6.1478 6.0226C5.0493 7.121 4.4443 8.5815 4.4443 10.135C4.4443 11.6885 5.0493 13.1491 6.1478 14.2475C6.2497 14.3494 6.3547 14.447 6.4626 14.5403L6.0663 15.206C5.927 15.4399 6.0038 15.7424 6.2377 15.8817C6.3167 15.9287 6.4036 15.9511 6.4893 15.9511C6.6574 15.9511 6.8211 15.8651 6.9133 15.7103L7.2635 15.122C8.1591 15.6624 9.1875 15.951 10.2603 15.951C11.3289 15.951 12.3544 15.6656 13.2566 15.1211L13.6073 15.7103C13.6995 15.8651 13.8633 15.9511 14.0313 15.9511C14.1171 15.9511 14.2039 15.9287 14.283 15.8817C14.5169 15.7424 14.5936 15.4399 14.4544 15.206L13.8383 14.1711C13.7671 14.0516 13.6487 13.9677 13.5124 13.9401C13.3761 13.9126 13.2343 13.9439 13.1224 14.0264C12.2889 14.6406 11.2992 14.9653 10.2603 14.9653C7.5969 14.9653 5.4301 12.7984 5.4301 10.135C5.4301 7.4716 7.5969 5.3048 10.2603 5.3048C12.9237 5.3048 15.0905 7.4717 15.0905 10.1351C15.0905 10.9486 14.8846 11.7527 14.4951 12.4604C14.3639 12.6988 14.4508 12.9986 14.6893 13.1298C14.9277 13.261 15.2275 13.1741 15.3587 12.9357C15.8282 12.0827 16.0763 11.1143 16.0763 10.135C16.0763 8.5815 15.4713 7.121 14.3729 6.0225Z"
      fill="white"
    />
    <path
      d="M10.236 6.51241C9.9638 6.51241 9.7432 6.73311 9.7432 7.00531V10.135C9.7432 10.2658 9.7951 10.3912 9.8875 10.4836L11.2816 11.8777C11.3778 11.9739 11.504 12.022 11.6301 12.022C11.7563 12.022 11.8824 11.9739 11.9786 11.8777C12.1711 11.6852 12.1711 11.3731 11.9786 11.1806L10.7289 9.93091V7.00531C10.7289 6.73311 10.5083 6.51241 10.236 6.51241Z"
      fill="white"
    />
  </Fragment>,
  'PendingWatch',
  '0 0 20 20'
)
