/**
 * Get agency brokers related to the agency
 * @param {string} Authorization - current user token
 * @param {number} [page=0] - page to download users
 * @param {number} [limit=10] - items to download per page
 */
export const GetAgencyBrokers = (
  Authorization,
  page = 0,
  limit = 10,
  order = null,
  orderDirection = null,
  filters
) => ({
  type: 'GET_AGENCY_BROKERS',
  payload: {
    request: {
      url: `/agency/brokers`,
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 * Send invitation to emails passed
 * @param {string} Authorization - current user token
 * @param {string[]} emails - Emails to send invitation
 */
export const AgencySendInvitations = (Authorization, emails) => ({
  type: 'AGENCY_SEND_INVITATION',
  payload: {
    request: {
      url: '/agency/brokers',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        emails
      }
    }
  }
})

/**
 * Get invitations from agency
 * @param {string} Authorization - current user token
 * @param {number} [page=0] - page to download invitations
 * @param {number} [limit=10] - items to download per page
 */
export const GetAgencyInvitations = (
  Authorization,
  page = 0,
  limit = 10,
  order = null,
  orderDirection = null,
  filters
) => ({
  type: 'GET_AGENCY_INVITATIONS',
  payload: {
    request: {
      url: `/agency/invitations`,
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 * Delete invitations from agency
 * @param {string} Authorization - current user token
 * @param {string[]} invitationsEmails - invitations emails to delete
 */
export const DeleteAgencyInvitations = (Authorization, invitationsEmails) => ({
  type: 'DELETE_AGENCY_INVITATIONS',
  payload: {
    request: {
      url: '/agency/invitations',
      method: 'delete',
      headers: {
        Authorization
      },
      data: {
        emails: invitationsEmails
      }
    }
  }
})

/**
 * Sends a request to become agency
 * @param {string} Authorization - current user token
 * @param {object} data
 * @param {string} data.agencyName
 * @param {string} data.contactName
 * @param {string} data.phoneNumber
 * @param {string} data.email
 */
export const RequestAgencyUpgrade = (Authorization, data) => ({
  type: 'REQUEST_AGENCY_UPGRADE',
  payload: {
    request: {
      url: '/agency',
      method: 'post',
      headers: {
        Authorization
      },
      data
    }
  }
})

/**
 * Get information of the invitaion that was sent by agency
 * @param {string} invitationCode - a token sent via email
 */
export const GetAgencyInvitationInfo = invitationCode => ({
  type: 'GET_AGENCY_INVITATION_INFO',
  payload: {
    request: {
      url: `/agency/invitation/${invitationCode}`,
      method: 'get'
    }
  }
})

/**
 * Accepts the invitation to become part of an agency
 * @param {string} invitationCode - a token sent via email
 * @param {string} [data]
 * @param {string} [data.fullname]
 * @param {string} [data.password]
 */
export const AcceptAgencyInvitation = (invitationCode, data) => ({
  type: 'ACCEPT_AGENCY_INVITATION',
  payload: {
    request: {
      url: `/agency/invitation/${invitationCode}/accept`,
      method: 'post',
      data
    }
  }
})

/**
 * Rejects the invitation to become part of an agency
 * @param {string} invitationCode - a token sent via email
 */
export const RejectAgencyInvitation = invitationCode => ({
  type: 'REJECT_AGENCY_INVITATION',
  payload: {
    request: {
      url: `/agency/invitation/${invitationCode}/reject`,
      method: 'post'
    }
  }
})

/**
 * Gets the agency main information
 * @param {string} Authorization - current user token
 */
export const GetAgencyInfo = Authorization => ({
  type: 'GET_AGENCY_INFO',
  payload: {
    request: {
      url: '/user/agency',
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Updates the agency main information
 * @param {string} Authorization - current user token
 * @param {string} data
 * @param {string} [data.agency_name]
 * @param {string} [data.agency_phone]
 * @param {string} [data.agency_phone_2]
 * @param {string} [data.agency_address]
 * @param {string} [data.agency_description]
 */
export const UpdateAgency = (Authorization, data) => ({
  type: 'SUBMIT_UPDATE_AGENCY',
  payload: {
    request: {
      url: '/user/agency',
      method: 'patch',
      headers: {
        Authorization
      },
      data
    }
  }
})

/**
 * Delete one or multiple agency brokers from agency
 * @param {string} Authorization
 * @param {string[]} brokerIds
 */
export const DeleteBroker = (Authorization, brokerIds) => ({
  type: 'DELETE_BROKER',
  payload: {
    request: {
      client: 'default',
      method: 'delete',
      url: `/agency/brokers`,
      data: {
        broker_ids: brokerIds
      },
      headers: { Authorization }
    }
  }
})

/**
 * Change role of any agency broker in case of admin trying to change it
 * @param {*} Authorization
 * @param {*} brokerId - Agency broker to change role
 * @param {*} newRole - Could be agency'AGENCY_BROKER'|'AGENCY'
 */
export const ChangeAgencyBrokerRole = (Authorization, brokerId, newRole) => ({
  type: 'CHANGE_AGENCY_BROKER_ROLE',
  payload: {
    request: {
      method: 'post',
      url: `/agency/broker/${brokerId}/change_role`,
      data: {
        role: newRole
      },
      headers: { Authorization }
    }
  }
})

/**
 * Get unassigned properties
 * @param {string} Authorization - current user token
 * @param {number} [page=0] - page to download unassigned properties
 * @param {number} [limit=10] - properties to download per page
 */
export const GetUnassignedAgencyProperties = (
  Authorization,
  page = 0,
  limit = 10,
  order = null,
  orderDirection = null,
  filters = {}
) => ({
  type: 'GET_UNASSIGNED_AGENCY_PROPERTIES',
  payload: {
    request: {
      url: '/agency/unassigned_properties',
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        p: page,
        l: limit,
        o: order,
        d: orderDirection,
        ...filters
      }
    }
  }
})

/**
 * Search agency brokers related to the agency
 * @param {string} Authorization - current user token
 * @param {number} [name = ''] - page to download users
 */
export const SearchAgencyBrokers = (Authorization, name = '') => ({
  type: 'GET_AGENCY_BROKERS',
  payload: {
    request: {
      url: `/agency/find_brokers`,
      method: 'get',
      headers: {
        Authorization
      },
      params: {
        name
      }
    }
  }
})

/**
 *
 * @param {*} token
 * @param {*} brokerId
 * @param {*} propertiesIds
 */
export const AssignPendingProperties = (
  Authorization,
  brokerId,
  propertiesIds
) => ({
  type: 'ASSIGN_PROPERTIES',
  payload: {
    request: {
      url: `/agency/broker/${brokerId}/assign_property`,
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        property_ids: propertiesIds
      }
    }
  }
})

/**
 *
 * @param {*} token
 * @param {*} brokerId
 * @param {*} propertiesIds
 */
export const ReassignProperties = (Authorization, brokerId, propertiesIds) => ({
  type: 'REASSIGN_PROPERTIES',
  payload: {
    request: {
      url: `/agency/broker/${brokerId}/reasign_property`,
      method: 'put',
      headers: {
        Authorization
      },
      data: {
        property_ids: propertiesIds
      }
    }
  }
})

export const AgencyMissingMinimumFields = Authorization => ({
  type: 'AGENCY_MISSING_MINIMUM_FIELDS',
  payload: {
    request: {
      method: 'get',
      url: `/user/agency/missing_minimum_fields`,
      headers: { Authorization }
    }
  }
})

export const GetAgencyBrokersKpis = Authorization => ({
  type: 'GET_AGENCY_BROKERS_KPIS',
  payload: {
    request: {
      method: 'get',
      url: `/agency/brokers/kpis`,
      headers: { Authorization }
    }
  }
})

export const AgencyAssociationsAvailable = Authorization => ({
  type: 'AGENCY_ASSOCIATIONS_AVAILABLE',
  payload: {
    request: {
      method: 'get',
      url: `/agency/associations`,
      headers: { Authorization }
    }
  }
})
