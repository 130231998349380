'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var UPLOADING_STATUS = {
  notBeenUploaded: 'HAS_NOT_BEEN_UPLOADED',
  isUploading: 'UPLOADING',
  alreadyProcessed: 'ALREADY_PROCESSED',
  error: 'ERROR'
};

exports.UPLOADING_STATUS = UPLOADING_STATUS;
