import React from 'react'
import { createSvgIcon } from 'rc'

export default createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113 30" height="30">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#0076CC"
        d="M.034 0h109.25a1 1 0 0 1 .696 1.72l-7.53 7.275a1 1 0 0 0-.045 1.391l7.836 8.632a1 1 0 0 1-.74 1.672H.033V0z"
      />
      <path
        fill="#FFF"
        d="M20.06 11.376a.989.989 0 0 1-.995.983.989.989 0 0 1-1.001-.977.989.989 0 0 1 .995-.983.989.989 0 0 1 1 .977"
      />
      <text
        fill="#FFF"
        fontFamily="Avenir-Heavy, Avenir"
        fontSize="12"
        fontWeight="600"
        transform="translate(.03)"
      >
        <tspan x="35.422" y="15.379">
          wiggot
        </tspan>
      </text>
      <path fill="#114577" d="M.03 20.684l10.068 8.934-.164-8.951z" />
      <path
        fill="#FEFEFE"
        d="M13.16 12.082l5.185-5.2 5.281 5.23.008 2.427-10.467.032-.008-2.49zm11.953-.636c-.02-.024-.029-.054-.052-.076l-6.071-5.9c-.016-.019-.023-.042-.04-.06a.854.854 0 0 0-.611-.237.854.854 0 0 0-.61.241c-.017.018-.024.041-.04.06l-6.034 5.936a.811.811 0 0 0-.24.614l-.001.007.01 3.51c.002.407.344.74.761.739l.208-.001h.092l11.838-.036h.3a.752.752 0 0 0 .756-.744l-.01-3.51a.726.726 0 0 0-.256-.543z"
      />
      <g transform="translate(11.414 5.173)">
        <ellipse
          cx="14.298"
          cy="8.167"
          fill="#FFF"
          stroke="#0076CC"
          strokeWidth="2"
          rx="4.323"
          ry="4.247"
        />
        <path
          fill="#0076CC"
          fillRule="nonzero"
          d="M13.897 9.79a.264.264 0 0 1-.263-.07l-.922-.969a.293.293 0 0 1 0-.394l.21-.22a.264.264 0 0 1 .378 0l.561.59 1.39-1.455a.28.28 0 0 1 .404 0l.224.237a.316.316 0 0 1 0 .423l-1.704 1.783a.28.28 0 0 1-.278.075z"
        />
      </g>
    </g>
  </svg>,
  'CertifiedProperty'
)
