import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Typography, Grid, Autocomplete, withForm } from 'rc'
import { SearchContact } from 'reduxActions/contacts'

const MiniLabelStyled = styled.label`
  margin-right: 5px;
  text-align: center;
  ${({ theme }) => theme.box(110, 20, 10)}
  background-color: ${({ theme }) => theme.palette.warning.dark};
  ${({ theme }) =>
    theme.typography.buildTextVariant({
      fontSize: theme.typography.xSmall,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 20,
      color: theme.palette.text.white
    })}
`

const ContactSearcher = ({
  defaultValue,
  token,
  SearchContact,
  onChange,
  customChange,
  fc,
  value,
  disabled,
  idBase,
  ...props
}) => {
  const [isNewCustomer, setIsNewCustomer] = useState(false)
  const endAdornment = <MiniLabelStyled>Nuevo cliente</MiniLabelStyled>
  return (
    <Autocomplete
      id={`${idBase}-autocomplete-contactName`}
      fetchFunction={value => SearchContact(token, value)}
      onChange={(name, value, isLastSelected) => {
        if (value && isLastSelected) {
          setIsNewCustomer(true)
        } else {
          setIsNewCustomer(false)
        }

        if (fc && fc.customChange) {
          fc.customChange({
            contact_id: !isLastSelected && value ? value.contact_id : '',
            contact_name: !isLastSelected && value ? value.name : value,
            contact_email:
              !isLastSelected && value ? value.main_email || '' : '',
            contact_phone:
              !isLastSelected && value ? value.main_phone || '' : ''
          })
          fc.setCustomErrors({
            contact_email: '',
            contact_phone: ''
          })
        }
      }}
      shouldFetchOnFocus
      InputProps={{
        endAdornment: isNewCustomer ? endAdornment : ''
      }}
      disabled={disabled}
      label="Nombre Cliente"
      required
      labelItemKey="name"
      lastItemComponent={({ fieldValue, onLastItemSelected }) => {
        return fieldValue ? (
          <Grid
            container
            justify="space-between"
            onClick={() => {
              onLastItemSelected()
            }}
            css={`
              color: ${({ theme }) => theme.palette.text.white};
              background-color: ${({ theme }) => theme.palette.primary.main};
              padding: ${({ theme }) => theme.remCalc('10 22')};
              cursor: pointer;
              font-weight: ${({ theme }) => theme.typography.fontWeightBold};
            `}
          >
            <Typography variant="subtitle1" color="white">
              Nuevo Cliente
            </Typography>
            <span>+</span>
          </Grid>
        ) : (
          ''
        )
      }}
      fieldValueOnSelect={(data, isLastSelected) => {
        return isLastSelected ? data : data.name
      }}
      placeholder="Busca o agrega un cliente nuevo"
      subLabelItemKey="main_email"
      shouldSelectOnBlur={(results, fieldValue) => {
        if (fieldValue) {
          return -1
        }
        return 0
      }}
      value={
        fc && fc.values && fc.values['contact_name']
          ? fc.values['contact_name']
          : ''
      }
    />
  )
}

ContactSearcher.propTypes = {
  customChange: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  fc: PropTypes.object,
  onChange: PropTypes.func,
  SearchContact: PropTypes.func,
  token: PropTypes.string,
  value: PropTypes.string,
  idBase: PropTypes.string
}

const mapStateToProps = ({ authReducer: { token } }) => ({ token })

const mapDispatchToProps = {
  SearchContact
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withForm(ContactSearcher))
