import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <svg width="92" height="97" viewBox="0 0 92 97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.2562 6.73657L36.1131 17.6834C33.2293 18.4665 30.4858 19.5844 27.9422 21.0101L17.8594 15.4746L8.84601 17.9858V24.3746L14.4571 34.3277C13.0098 36.8389 11.8811 39.55 11.0872 42.396L0 45.5013V58.1115L11.0872 61.2168C11.8811 64.0628 13.0152 66.7739 14.4571 69.2851L8.84601 72.8548V79.2436L17.8594 88.1437L27.9422 82.6081C30.4858 84.0393 33.2293 85.1518 36.1131 85.9348L39.2562 96.8817H52.0338L55.1769 85.9348C58.0607 85.1518 60.8042 84.0339 63.3478 82.6081L73.4305 88.1437L82.444 79.2436V72.8548L76.8329 69.2851C78.2802 66.7739 79.4089 64.0628 80.2028 61.2168L91.29 58.1115V45.5013L80.2028 42.396C79.4089 39.55 78.2802 36.8389 76.8329 34.3277L82.444 24.3746V17.9858L73.4305 15.4746L63.3478 21.0101C60.8042 19.579 58.0607 18.4665 55.1769 17.6834L52.0338 6.73657H39.2562ZM45.645 24.3152C60.9824 24.3152 73.4953 36.6661 73.4953 51.8091C73.4953 66.9521 60.9824 79.303 45.645 79.303C30.3076 79.303 17.7946 66.9521 17.7946 51.8091C17.7946 36.6661 30.3076 24.3152 45.645 24.3152Z" fill="#064580"/>
    <path d="M39.2562 0.3479L36.1131 11.2947C33.2293 12.0778 30.4858 13.1957 27.9422 14.6214L17.8594 9.0859L8.84601 17.9859L14.4571 27.939C13.0098 30.4503 11.8811 33.1613 11.0872 36.0074L0 39.1127V51.7228L11.0872 54.8281C11.8811 57.6742 13.0152 60.3852 14.4571 62.8964L8.84601 72.8496L17.8594 81.7496L27.9422 76.2141C30.4858 77.6452 33.2293 78.7577 36.1131 79.5408L39.2562 90.4876H52.0338L55.1769 79.5408C58.0607 78.7577 60.8042 77.6398 63.3478 76.2141L73.4305 81.7496L82.444 72.8496L76.8329 62.8964C78.2802 60.3852 79.4089 57.6742 80.2028 54.8281L91.29 51.7228V39.1127L80.2028 36.0074C79.4089 33.1613 78.2802 30.4503 76.8329 27.939L82.444 17.9859L73.4305 9.0859L63.3478 14.6214C60.8042 13.1903 58.0607 12.0778 55.1769 11.2947L52.0338 0.3479H39.2562ZM45.645 17.9265C60.9824 17.9265 73.4953 30.2774 73.4953 45.4204C73.4953 60.5634 60.9824 72.9144 45.645 72.9144C30.3076 72.9144 17.7946 60.5634 17.7946 45.4204C17.7946 30.2774 30.3076 17.9265 45.645 17.9265Z" fill="#4098FF"/>
    <path d="M36.6046 45.7929L29.2815 47.9153L36.6046 50.0377V54.6497L24.3022 50.1781V45.6255L36.6046 41.1863V45.7983V45.7929Z" fill="#FFC500"/>
    <path d="M42.9339 58.7165H38.2679L49.3821 36.9688H54.0481L42.9339 58.7165Z" fill="#171D3E"/>
    <path d="M67.9328 45.6258V50.1784L55.7169 54.65V50.038L62.959 47.9156L55.7169 45.7932V41.1812L67.9328 45.6204V45.6258Z" fill="#FFC500"/>
  </svg>
,
  'WiggotIconConfigurationApi',
  '0 0 95 103'
)
