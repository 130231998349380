import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Title, Text } from 'c3-ui/elements/Texts'
import { Layout, LayoutItem } from 'c3-ui/elements/Layouts'
import { PrimaryButton, SecondaryButton } from 'c3-ui/elements/Buttons'
import { AcceptDeveloperInvitation, RejectDeveloperInvitation } from 'reduxActions/developers'

import {
  HideModal,
  ShowFlashNotification,
  LockModal,
  UnlockModal
} from 'reduxActions/services'

function AcceptDeveloperInvitationMC({
  agencyName,
  invitationCode,
  HideModal,
  ShowFlashNotification,
  AcceptDeveloperInvitation,
  LockModal,
  UnlockModal,
  RejectDeveloperInvitation
}) {

  const BASE_CLASSNAME = 'accept-agency-invitation-mc'

  useEffect(() => LockModal(), [])

  const handleAccept = async () => {
    try {
      await AcceptDeveloperInvitation(invitationCode)
      UnlockModal()
      HideModal()
      window.history.pushState(null, null, '/search')
      ShowFlashNotification('success', 'Has aceptado la invitación a la desarrolladora')
    } catch (err) {
      const { error: { response: { data: errorPayload } } } = err
      let errorMessage = 'Error desconocido, por favor intenta de nuevo más tarde.'

      if (errorPayload.error.message === 'Invitation code not found') {
        errorMessage = 'Esta invitación ya no está disponible. Código de error: WIG-13'
      }
      ShowFlashNotification('danger', errorMessage)
      UnlockModal()
      HideModal()
      window.history.pushState(null, null, '/search')
    }
  }

  const handleReject = async () => {
    try {
      await RejectDeveloperInvitation(invitationCode)
      UnlockModal()
      HideModal()
      window.history.pushState(null, null, '/search')
    }catch(err) {
      const { error: { response: { data: errorPayload } } } = err
      let errorMessage = 'Error desconocido, por favor intenta de nuevo más tarde.'

      if (errorPayload.error.message === 'Invitation code not found') {
        errorMessage = 'Esta invitación ya no está disponible. Código de error: WIG-13'
      }
      ShowFlashNotification('danger', errorMessage)
      UnlockModal()
      HideModal()
      window.history.pushState(null, null, '/search')
    }
  }

  return (
    <Layout className={BASE_CLASSNAME} vertical justify>
      <Title
        gray
        smallSize
        center
        uppercase
        className={`${BASE_CLASSNAME}__title`}
      >
        {agencyName} te ha invitado a formar parte de su desarrolladora
      </Title>
      <Text center className={`${BASE_CLASSNAME}__main-description`}>
        El aceptar la invitación implica que todas tus propiedades y contactos
        formarán parte de la desarrolladora y te dara accesos a los desarrollos
        de la misma
      </Text>
      <LayoutItem>
        <Layout paddingX>
          <LayoutItem className={`${BASE_CLASSNAME}__cancel-button`}>
            <SecondaryButton
              id="agencyBroker-handleAccept-mc-button-reject"
              onClick={handleReject}
            >
              Rechazar invitacion
            </SecondaryButton>
          </LayoutItem>

          <PrimaryButton
            id="agencyBroker-handleAccept-mc-button-accept"
            mediumAuto
            onClick={handleAccept}
          >
            Aceptar invitación
          </PrimaryButton>
        </Layout>
      </LayoutItem>
    </Layout>
  )
}

AcceptDeveloperInvitationMC.propTypes = {
  invitationCode: PropTypes.string,
  agencyName: PropTypes.string,
  AcceptDeveloperInvitation: PropTypes.func,
  LockModal: PropTypes.func,
  UnlockModal: PropTypes.func,
  RejectDeveloperInvitation: PropTypes.func,
  HideModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func
}

const mapDispatchToProps = {
  HideModal,
  ShowFlashNotification,
  AcceptDeveloperInvitation,
  LockModal,
  UnlockModal,
  RejectDeveloperInvitation
}

export default connect(
  null,
  mapDispatchToProps
)(AcceptDeveloperInvitationMC)
