import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      style={{ fill: '#E0E0E0' }}
      d="M3.5,3.5l57.8,0l0,8.5H11v95.8l-7.5,0L3.5,3.5z"
    />
    <path
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#E0E0E0' }}
      d="M85.3,91.4c-11.5,0-20.8,9.4-20.8,21c0,6.1,2.6,11.6,6.8,15.5c-3.5-3.8-5.6-8.8-5.6-14.4c0-11.6,9.4-21,20.8-21
	c5.4,0,10.4,2.1,14.1,5.5C96.7,94,91.3,91.4,85.3,91.4z"
    />
    <path
      style={{ fill: '#97A4B1' }}
      d="M69.3,112.1h3.1c0-6.8,5.5-12.4,12.3-12.4v-3.1C76.2,96.6,69.3,103.6,69.3,112.1z"
    />
    <g>
      <path
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#97A4B1' }}
        d="M34.5,66.3h33.7c0.7,0,1.2-0.8,1.2-1.8c0-1-0.6-1.8-1.2-1.8H34.5c-0.7,0-1.2,0.8-1.2,1.8
		C33.2,65.5,33.8,66.3,34.5,66.3z"
      />
      <path
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#97A4B1' }}
        d="M69.4,81.9c0-1-0.6-1.8-1.2-1.8H34.5c-0.7,0-1.2,0.8-1.2,1.8c0,1,0.6,1.8,1.2,1.8h33.7
		C68.8,83.7,69.4,82.9,69.4,81.9z"
      />
      <path
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#97A4B1' }}
        d="M34.5,49h33.7c0.7,0,1.2-0.8,1.2-1.8c0-1-0.6-1.8-1.2-1.8H34.5c-0.7,0-1.2,0.8-1.2,1.8
		C33.2,48.2,33.8,49,34.5,49z"
      />
      <path
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#97A4B1' }}
        d="M121.8,139.4l-12.8-12.9l-1.2,1.2l-2.2-2.2l0,0l-0.1-0.1l0.1,0.1c2.4-3.8,3.8-8.3,3.8-13.1
		c0-13-10.2-23.7-22.9-24.4l-0.2-65c0-0.5-0.2-0.9-0.5-1.2l-21-21.3C64.5,0.2,64,0,63.6,0H1.8C0.8,0,0,0.8,0,1.8v107.8
		c0,1,0.8,1.8,1.8,1.8H61c0,0.3,0,0.7,0,1c0,13.4,10.9,24.4,24.3,24.4c5,0,9.7-1.6,13.6-4.2l-0.5-0.5l0.5,0.5l0,0l2.1,2.1l-1.2,1.2
		l12.8,12.9c3.8,3.6,7.9,1.4,9.3,0C124,146.6,124.8,142.5,121.8,139.4z M64.8,6.2l15.1,15.1H64.8V6.2z M3.5,107.8V3.5h57.8v19.5
		c0,1,0.8,1.8,1.8,1.8h19.8l0.2,63.3c-10.8,1-19.7,9.2-21.6,19.7H3.5z M85.2,133.3c-11.4,0-20.7-9.4-20.7-20.9
		c0-11.5,9.3-20.9,20.7-20.9s20.7,9.4,20.7,20.9C106,123.9,96.7,133.3,85.2,133.3z M101,131.4c1.3-1.1,2.4-2.4,3.5-3.7l1.6,1.6
		c-1.1,1.3-2.2,2.6-3.5,3.7L101,131.4z"
      />
    </g>
    <path
      style={{ fill: '#0076CC' }}
      d="M119.5,146.5c-1.3,1.3-3.4,1.3-4.6,0l-10.5-10.5l4.6-4.7l10.5,10.5C120.8,143.1,120.8,145.2,119.5,146.5z"
    />
    <path
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFC834' }}
      d="M27.1,51.2h-5.3c-0.6,0-1.1-0.5-1.1-1.1v-5.3c0-0.6,0.5-1.1,1.1-1.1h5.3c0.6,0,1.1,0.5,1.1,1.1v5.3
	C28.2,50.8,27.7,51.2,27.1,51.2z"
    />
    <path
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFC834' }}
      d="M27.1,68.3h-5.3c-0.6,0-1.1-0.5-1.1-1.1v-5.3c0-0.6,0.5-1.1,1.1-1.1h5.3c0.6,0,1.1,0.5,1.1,1.1v5.3
	C28.2,67.8,27.7,68.3,27.1,68.3z"
    />
    <path
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFC834' }}
      d="M27.1,85.4h-5.3c-0.6,0-1.1-0.5-1.1-1.1V79c0-0.6,0.5-1.1,1.1-1.1h5.3c0.6,0,1.1,0.5,1.1,1.1v5.3
	C28.2,84.9,27.7,85.4,27.1,85.4z"
    />
  </Fragment>,
  'RequirementsZero',
  '0 0 123.8 150.7'
)
