import { createTheme } from 'rc'
import ampiLogo from 'static/logos/ext-ampi.svg'
import cdcLogo from 'static/logos/ext-cdc.svg'
import macrobolsaLogo from 'static/logos/ext-macrobolsa.svg'
import HomeSearcherBackgroundCdc from 'static/img/home-searcher-cdc.png'
import HomeSearcherBackgroundAmpi from 'static/img/home-searcher-ext.png'
import HomeSearcherBackgroundMacrobolsa from 'static/img/home-searcher-macrobolsa.png'

const AMPI = createTheme('AMPI Theme', {
  typography: {
    fontFamily: '"Muli", "Helvetica Neue", "Arial", sans-serif',
    fontWeightMedium: 600
  },
  palette: {
    primary: {
      main: '#0e9b5c',
      light: '#3BCC91',
      dark: '#026D42',
      text: '#fff'
    },
    elite: {
      light: '#937851',
      main: '#937851',
      dark: '#614d31',
      text: 'white',
      yellow: '#ffc834'
    },
    secondary: {
      main: '#fff',
      light: '#3BCC91',
      dark: '#026D42',
      text: '#fff'
    },
    prime: {
      light: '#000000b3',
      main: '#000000',
      dark: '#000000d4',
      text: 'white',
      yellow: '#ffc834'
    },
    black: {
      light: '#f6f6f7',
      main: '#363636',
      dark: '#90A4AE',
      text: '#fff'
    },
    white: {
      light: '#efefef',
      main: '#ffffff',
      dark: '#efefef',
      text: '#363636'
    },
    pastel: {
      danger: '#FD9494',
      success: '#80DDC3',
      yellow: '#FFE399',
      blue: '#80BBFF'
    },
    colors: {
      red: '#FD7171',
      yellow: '#FFBC08',
      green: '#00BA88',
      blue: '#0076FF'
    },
    new: {
      text: 'rgba(96, 93, 117, 1)'
    },
    tag: {
      successBackground: '#34c47c',
      successText: '#fff',
      warningBackground: '#ee9708',
      warningText: '#fff',
      dangerBackground: '#ff5a60',
      dangerText: '#fff',
      grayBackground: '#97a4b1',
      grayText: '#fff',
      primaryBackground: '#0076CC',
      primaryText: '#fff',
      secondaryBackground: '#01579B',
      secondaryText: '#fff'
    }
  },
  isExt: true,
  logo: ampiLogo,
  navigationBarHeight: 70,
  unverifiedBannerHeight: 45,
  navigationBarMobileHeight: 50,
  unverifiedBannerMobileHeight: 45,
  backgroundImage: HomeSearcherBackgroundAmpi
})

const CDC = createTheme('CDC Theme', {
  typography: {
    fontFamily: '"Muli", "Helvetica Neue", "Arial", sans-serif',
    fontWeightMedium: 600
  },
  palette: {
    primary: {
      main: '#A8182E',
      light: '#E0344F',
      dark: '#741020',
      text: '#fff'
    },
    secondary: {
      main: '#fff',
      light: '#E0344F',
      dark: '#741020',
      text: '#fff'
    },
    prime: {
      light: '#000000b3',
      main: '#363946',
      dark: '#000000d4',
      text: 'white',
      yellow: '#ffc834'
    },
    elite: {
      light: '#937851',
      main: '#937851',
      dark: '#614d31',
      text: 'white',
      yellow: '#ffc834'
    },
    black: {
      light: '#f6f6f7',
      main: '#363636',
      dark: '#90A4AE',
      text: '#fff'
    },
    white: {
      light: '#efefef',
      main: '#ffffff',
      dark: '#efefef',
      text: '#363636'
    },
    pastel: {
      danger: '#FD9494',
      success: '#80DDC3',
      yellow: '#FFE399',
      blue: '#80BBFF'
    },
    colors: {
      red: '#FD7171',
      yellow: '#FFBC08',
      green: '#00BA88',
      blue: '#0076FF'
    },
    new: {
      text: 'rgba(96, 93, 117, 1)'
    },
    tag: {
      successBackground: '#34c47c',
      successText: '#fff',
      warningBackground: '#ee9708',
      warningText: '#fff',
      dangerBackground: '#ff5a60',
      dangerText: '#fff',
      grayBackground: '#97a4b1',
      grayText: '#fff',
      primaryBackground: '#A8182E',
      primaryText: '#fff',
      secondaryBackground: '#A8182E',
      secondaryText: '#fff'
    }
  },
  isExt: true,
  logo: cdcLogo,
  navigationBarHeight: 70,
  unverifiedBannerHeight: 45,
  navigationBarMobileHeight: 50,
  unverifiedBannerMobileHeight: 45,
  backgroundImage: HomeSearcherBackgroundCdc
})

const MACROBOLSA = createTheme('MACROBOLSA Theme', {
  typography: {
    fontFamily: '"Muli", "Helvetica Neue", "Arial", sans-serif',
    fontWeightMedium: 600
  },
  palette: {
    primary: {
      main: '#00A3E3',
      light: '#00B7FF',
      dark: '#0185CF',
      text: '#fff'
    },
    secondary: {
      main: '#fff',
      light: '#00B7FF',
      dark: '#0185CF',
      text: '#fff'
    },
    prime: {
      light: '#000000b3',
      main: '#00A3E3',
      dark: '#000000d4',
      text: 'white',
      yellow: '#ffc834'
    },
    elite: {
      light: '#937851',
      main: '#937851',
      dark: '#614d31',
      text: 'white',
      yellow: '#ffc834'
    },
    black: {
      light: '#00B7FF',
      main: '#00A3E3',
      dark: '#90A4AE',
      text: '#fff'
    },
    white: {
      light: '#00B7FF',
      main: '#00A3E3',
      dark: '#efefef',
      text: '#363636'
    },
    pastel: {
      danger: '#FD9494',
      success: '#80DDC3',
      yellow: '#FFE399',
      blue: '#80BBFF'
    },
    colors: {
      red: '#FD7171',
      yellow: '#FFBC08',
      green: '#00BA88',
      blue: '#0076FF'
    },
    new: {
      text: 'rgba(96, 93, 117, 1)'
    },
    tag: {
      successBackground: '#34c47c',
      successText: '#fff',
      warningBackground: '#ee9708',
      warningText: '#fff',
      dangerBackground: '#ff5a60',
      dangerText: '#fff',
      grayBackground: '#97a4b1',
      grayText: '#fff',
      primaryBackground: '#A8182E',
      primaryText: '#fff',
      secondaryBackground: '#A8182E',
      secondaryText: '#fff'
    }
  },
  isExt: true,
  logo: macrobolsaLogo,
  navigationBarHeight: 70,
  unverifiedBannerHeight: 45,
  navigationBarMobileHeight: 50,
  unverifiedBannerMobileHeight: 45,
  backgroundImage: HomeSearcherBackgroundMacrobolsa
})

const NEW_THEME = createTheme('NEW Theme', {
  typography: {
    fontFamily: '"Muli", "Helvetica Neue", "Arial", sans-serif',
    fontWeightMedium: 600
  },
  palette: {
    primary: {
      main: '#000',
      light: '#000',
      dark: '#000',
      text: '#fff'
    },
    secondary: {
      main: '#fff',
      light: '#fff',
      dark: '#fff',
      text: '#000'
    },
    prime: {
      light: '#000000b3',
      main: '#00A3E3',
      dark: '#000000d4',
      text: 'white',
      yellow: '#ffc834'
    },
    elite: {
      light: '#937851',
      main: '#937851',
      dark: '#614d31',
      text: 'white',
      yellow: '#ffc834'
    },
    black: {
      light: '#00B7FF',
      main: '#00A3E3',
      dark: '#90A4AE',
      text: '#fff'
    },
    white: {
      light: '#00B7FF',
      main: '#00A3E3',
      dark: '#efefef',
      text: '#363636'
    },
    pastel: {
      danger: '#FD9494',
      success: '#80DDC3',
      yellow: '#FFE399',
      blue: '#80BBFF'
    },
    colors: {
      red: '#FD7171',
      yellow: '#FFBC08',
      green: '#00BA88',
      blue: '#0076FF'
    },
    new: {
      text: '#000'
    },
    tag: {
      successBackground: '#34c47c',
      successText: '#fff',
      warningBackground: '#ee9708',
      warningText: '#fff',
      dangerBackground: '#ff5a60',
      dangerText: '#fff',
      grayBackground: '#97a4b1',
      grayText: '#000',
      primaryBackground: '#fff',
      primaryText: '#000',
      secondaryBackground: '#000',
      secondaryText: '#fff'
    }
  },
  isExt: true,
  logo: macrobolsaLogo,
  navigationBarHeight: 70,
  unverifiedBannerHeight: 45,
  navigationBarMobileHeight: 50,
  unverifiedBannerMobileHeight: 45,
  backgroundImage: HomeSearcherBackgroundMacrobolsa
})

const ExtTheme = ext => {
  switch (ext) {
    case 'AMPI':
      return AMPI
    case 'CDC':
      return CDC
    case 'MACROBOLSA':
      return MACROBOLSA
    case 'NEW_THEME':
      return NEW_THEME
    default:
      return AMPI
  }
}

export default ExtTheme
