import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Button } from 'c3-ui/generics/Buttons'
import { Text, Title } from 'c3-ui/elements/Texts'

import { HideModal, ShowFlashNotification } from 'reduxActions/services'

import {
  AcceptAssociationInvitation,
  RejectAssociationInvitation
} from 'reduxActions/association'
class AcceptAssociationInvitationMC extends Component {
  static propTypes = {
    email: PropTypes.string,
    invitationCode: PropTypes.string,
    associationName: PropTypes.string,

    HideModal: PropTypes.func,
    AcceptAssociationInvitation: PropTypes.func,
    RejectAssociationInvitation: PropTypes.func,
    ShowFlashNotification: PropTypes.func
  }

  acceptAssociationInvitation = () => {
    const {
      email,
      invitationCode,
      AcceptAssociationInvitation,
      HideModal,
      ShowFlashNotification
    } = this.props
    AcceptAssociationInvitation(email, invitationCode)
      .then(response => {
        const { payload: { status = '' } = {} } = response

        switch (status) {
          case 200:
            HideModal()
            ShowFlashNotification('success', 'Has aceptado la invitación.')
            break

          default:
            break
        }
      })
      .catch(error => {
        HideModal()
        const {
          error: { response: { data: { error: { code = '' } = {} } = {} } = {} }
        } = error

        switch (code) {
          case 'NOT_FOUND':
            ShowFlashNotification(
              'danger',
              'Esta invitación ya no está disponible. Código de error: WIG-22'
            )
            break

          default:
            ShowFlashNotification(
              'danger',
              'Error desconocido, por favor intenta de nuevo más tarde.'
            )
            break
        }
      })
  }

  rejectAssociationInvitation = () => {
    const {
      email,
      invitationCode,
      RejectAssociationInvitation,
      HideModal,
      ShowFlashNotification
    } = this.props

    RejectAssociationInvitation(email, invitationCode)
      .then(response => {
        const { payload: { status = '' } = {} } = response

        switch (status) {
          case 200:
            HideModal()
            ShowFlashNotification('warning', 'Has rechazado la invitación.')
            break

          default:
            break
        }
      })
      .catch(error => {
        HideModal()
        const {
          error: { response: { data: { error: { code = '' } = {} } = {} } = {} }
        } = error
        switch (code) {
          case 'NOT_FOUND':
            ShowFlashNotification(
              'danger',
              'Esta invitación ya no está disponible. Código de error: WIG-23'
            )
            break

          default:
            ShowFlashNotification(
              'danger',
              'Error desconocido, por favor intenta de nuevo más tarde.'
            )
            break
        }
      })
  }

  render() {
    const { associationName } = this.props
    return (
      <div className="accept-association-invitation-mc">
        <Title
          uppercase
          center
          className={`accept-association-invitation-mc__title`}
          extraSmallSize
        >
          {associationName} te ha invitado a formar parte de su asociación.
        </Title>
        <Text className={`accept-association-invitation-mc__brief-description`}>
          El aceptar la invitación implica que todas tus propiedades se
          publicarán automáticamente en el sitio web de la asociación al igual
          que los datos de contacto de tus asesores y de tu inmobiliaria.
        </Text>
        <div className={`accept-association-invitation-mc__buttons-group`}>
          <Button
            id="associationAgencies-acceptInvitation-mc-button-declineInvitation"
            full
            danger
            onClick={this.rejectAssociationInvitation}
          >
            Rechazar invitación
          </Button>
          <Button
            id="associationAgencies-acceptInvitation-mc-button-acceptInvitation"
            full
            primary
            onClick={this.acceptAssociationInvitation}
          >
            Aceptar Invitación
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  HideModal,
  ShowFlashNotification,
  AcceptAssociationInvitation,
  RejectAssociationInvitation
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptAssociationInvitationMC)
