import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Typography, Spinner, Button } from 'rc'
import { useDispatch ,useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SetIsRolDevelop, SetRolSelectedDevelop } from 'reduxActions/auth'
import { ActivateDeveloperRole } from 'reduxActions/development'
import { ShowFlashNotification } from 'reduxActions/services'
import ModalImage from  'static/img/create-development-account-mc.svg'

const CreateDevelopmentAccount = ({ HideModal }) => {
  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const { token, rolSelected } = useSelector(
    ({ authReducer: { token, rolSelected } }) => ({ token, rolSelected })
  )
  const dispatch = useDispatch()
  const history = useHistory()

  const upgradeToDevelopmentAccount = async () => {
    setIsLoading(true)
    await dispatch(ActivateDeveloperRole(token))
      .then(_res => {
        const newRolSelected = [
          { ...rolSelected[0], "isChecked": false },
          { ...rolSelected[1], "isChecked": true }
        ]
        dispatch(SetIsRolDevelop(true))
        dispatch(SetRolSelectedDevelop(newRolSelected))
        history.push('/uploadDevelopmentNew')
      })
      .catch(err => {
        dispatch(ShowFlashNotification('danger', 'Ocurrió un error. Vuelve a intentarlo más tarde.'))
      })
    
  }
  return (
    <Container>
      {isLoading ?
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer> :
        <>
          <Typography
            variant="h2" 
            textTransform="none" 
            align="center"
            color="palette.primary.main"
          >
            Crea una cuenta de desarrollador
          </Typography>
          {step === 1 &&
            <img 
              src={ModalImage} 
              alt="development-account" 
              css={`margin-top: ${({ theme }) => theme.remCalc('20')};`} 
            />}
          <Typography
            variant="subtitle4"
            fontWeight={400}
            align="center"
            css={`
              margin: ${({ theme }) => theme.remCalc('20 0')};
              width: 85%;
              color: #605D75;
            `}
          >
            {step === 1 ? 
              <>
                Para cargar y administrar un desarrollo es necesario que tengas una cuenta desarrollador.
                Créala ahora de <b>forma gratuita</b> y no te pierdas de los beneficios de tener tu desarrollo en wiggot.
              </> : 
              "Se tomarán los datos de la cuenta en la que estás actualmente para crear la cuenta de desarrollador."
            }
          </Typography>
        </>
      }
      <div css={`
        display: flex;
        justify-content: space-between;
        width: 100%;
      `}>
        <ButtonStyled
          variant="outlined"
          color="gray"
          size="large"
          onClick={HideModal}
          disabled={isLoading}
        >
          Cancelar
        </ButtonStyled>
        <ButtonStyled 
          size="large"
          onClick={ () => step === 2 ? upgradeToDevelopmentAccount() : setStep(step + 1)}
          disabled={isLoading}
        >
          Crear cuenta
        </ButtonStyled>
      </div>
    </Container>
  )
}

CreateDevelopmentAccount.propTypes ={
  HideModal: PropTypes.func
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: ${({ theme }) => theme.remCalc('30')};
  align-items: center;
`
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  margin-bottom: ${({ theme }) => theme.remCalc('20')};
`
const ButtonStyled = styled(Button)`
  border-radius: ${({ theme }) => theme.remCalc('50')} !important;
  width: 48%;
`

export default CreateDevelopmentAccount
