/* eslint-disable no-prototype-builtins */
/* eslint-disable no-useless-escape */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray } from 'redux-form'
import classNames from 'classnames'

import {
  AutocompleteSearcher,
  CheckboxGroup,
  DatePickerMonth,
  ExtendedWysiwyg,
  Previewer,
  Select,
  TextInput,
  Textarea,
  Checkbox,
  TextInputGroup
} from 'c3-ui/elements/FormInputs'

import { AsyncSelect, StaticSelect } from 'c3-ui/elements/FormInputs/thirdParty'

const emailRegex =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}\s*$)/

const phoneRegex =
  /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/

export const isEqualTo = (errorMessage, params) => value => {
  return value === params.data ? errorMessage : undefined
}

export const isEmpty = errorMessage => value => {
  return (typeof value === 'string' && value.trim().length > 0) ||
    (typeof value !== 'string' && value)
    ? undefined
    : errorMessage
    ? errorMessage
    : 'No puede estar vacío.'
}

export const isAttributeEmpty = (errorMessage, params) => value => {
  const { attributeName = '' } = params

  return value && value[attributeName]
    ? undefined
    : errorMessage
    ? errorMessage
    : 'No puede estar vacío.'
}

export const isValidWithRegex = (errorMessage, params) => value => {
  const { regExp = '' } = params
  let result = ''
  result = (value && value.match(regExp)) || []
  return result.length > 0
    ? undefined
    : errorMessage
    ? errorMessage
    : 'Ingresa un texto valido'
}

export const lengthShouldBe = (errorMessage, params) => value => {
  const { type, size: Size } = params
  let size = parseInt(Size)
  let correctErrorMessage = ''
  if (value && type && size && size > 0) {
    switch (type) {
      case 'lessThan':
        if (value.length >= size)
          correctErrorMessage = `Debe ser menor a ${size} caractere${
            size > 1 ? 's' : ''
          }.`
        break
      case 'moreThan':
        if (value.length <= size)
          correctErrorMessage = `${1 + size} caracteres minimo.`
        break
      case 'equalTo':
        if (value.length === size)
          correctErrorMessage = `${size} caracter${
            size > 1 ? 'es' : ''
          } minimo${size > 1 ? 's' : ''}`
        break
    }
    return correctErrorMessage ? errorMessage || correctErrorMessage : undefined
  }
  return undefined
}
export const isEmail =
  errorMessage =>
  (value = '') => {
    return value
      ? emailRegex.test(value)
        ? undefined
        : errorMessage
        ? errorMessage
        : 'Formato de correo incorrecto.'
      : undefined
  }

export const isPhone =
  errorMessage =>
  (value = '') => {
    return value
      ? phoneRegex.test(value)
        ? undefined
        : errorMessage
        ? errorMessage
        : 'Formato de teléfono incorrecto.'
      : undefined
  }

class Form extends PureComponent {
  state = {
    children: [],
    childMap: null,
    flags: {},
    fields: []
  }

  static propTypes = {
    children: PropTypes.any.isRequired,
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    className: PropTypes.string,
    decorated: PropTypes.bool,
    fieldSelectors: PropTypes.array,
    fullPage: PropTypes.bool,
    withFeaturedImage: PropTypes.bool
  }

  static defaultProps = {
    updateSelectors: () => null,
    fieldSelectors: []
  }
  static CreateTextInput = props => React.createElement(TextInput, { ...props })
  static CreateTextarea = props => React.createElement(Textarea, { ...props })

  static transform = (children, pack) => {
    return React.Children.map(children, function (child) {
      if (!child || !child.props) {
        return child
      }

      if (
        child.props.hasOwnProperty('children') &&
        !child.props.inputWithChildren
      ) {
        const children = Form.transform(child.props.children, pack)

        return React.cloneElement(
          child,
          {
            ...child.props
          },
          children
        )
      } else if (child.type) {
        if (
          (child.props.hasOwnProperty('componentType') &&
            child.props.componentType === 'CT') ||
          child.props.hasOwnProperty('outsideForm')
        ) {
          return child
        }
        let validationsToApply = []

        if (child.props.validations) {
          switch (typeof child.props.validations) {
            case 'function':
              validationsToApply = [child.props.validations]
              break
            default:
              validationsToApply = child.props.validations.map(
                ({ validationName, errorMessage, params }) => {
                  switch (validationName) {
                    case 'emptiness':
                      return isEmpty(errorMessage)
                    case 'validEmail': {
                      return isEmail(errorMessage)
                    }
                    case 'validPhone': {
                      return isPhone(errorMessage)
                    }
                    case 'checkSize': {
                      return lengthShouldBe(errorMessage, params)
                    }
                    case 'isAttributeEmpty': {
                      return isAttributeEmpty(errorMessage, params)
                    }
                    case 'isEqualTo':
                      return isEqualTo(errorMessage, params)
                    case 'isValidWithRegex': {
                      return isValidWithRegex(errorMessage, params)
                    }
                    default:
                      break
                  }
                }
              )
              break
          }
        }

        switch (child.type) {
          case TextInput:
            return (
              <Field
                component={Form.CreateTextInput}
                name={child.props.name}
                validate={validationsToApply}
                props={{ ...child.props, componentType: 'RF' }}
              />
            )
          case Textarea:
            return (
              <Field
                component={Form.CreateTextarea}
                name={child.props.name}
                validate={validationsToApply}
                props={{ ...child.props, componentType: 'RF' }}
              />
            )

          case AsyncSelect:
          case AutocompleteSearcher:
          case Checkbox:
          case CheckboxGroup:
          case DatePickerMonth:
          case Previewer:
          case Select:
          case StaticSelect:
          case ExtendedWysiwyg:
          case TextInputGroup:
            return (
              <Field
                component={props =>
                  React.createElement(child.type, { ...props })
                }
                name={child.props.name}
                validate={validationsToApply}
                props={{ ...child.props, componentType: 'RF' }}
              />
            )
          default:
            if (child.props.formSection) {
              const instance = new child.type({ ...child.props })
              if (instance.hasOwnProperty('updater')) {
                return Form.transform(instance.render(), pack)
              } else {
                return Form.transform(instance, pack)
              }
            }
            if (child.props.arrayInput) {
              return (
                <FieldArray
                  component={child.type}
                  name={child.props.name}
                  validate={validationsToApply}
                  props={{ ...child.props }}
                  rerenderOnEveryChange={false}
                />
              )
            }
            if (child.props.arrayTemplate) {
              return (
                <FieldArray
                  component={props => {
                    const instance = new child.type({
                      ...props
                    })
                    const lol = Form.transform(instance.render(), pack)
                    return lol
                  }}
                  name={child.props.name}
                  validate={validationsToApply}
                  props={{ ...child.props }}
                  rerenderOnEveryChange={false}
                />
              )
            }
            return child
        }
      } else {
        return child
      }
    })
  }

  static DOMMap = children => {
    const res = []
    React.Children.forEach(children, child => {
      if (child) {
        if (child.props) {
          const { children, ...resProps } = child.props
          if (child.props.hasOwnProperty('children')) {
            const childrenChild = Form.DOMMap(child.props.children)
            res.push({ props: resProps, children: childrenChild })
          } else {
            res.push(resProps)
          }
        } else {
          res.push(1)
        }
      } else {
        res.push(null)
      }
    })
    return res
  }

  static getDerivedStateFromProps(
    { children, updateSelectors, dispatch, form, ...nextProps },
    prevState
  ) {
    const childMap = Form.DOMMap(children)
    const shouldUpdate = !_.isEqual(prevState.childMap, childMap)
    const shouldUpdateFlags = !_.isEqual(nextProps.flags, prevState.flags)

    const shouldUpdateFieldSelectors = !_.isEqual(
      nextProps.fields,
      prevState.fields
    )

    if (shouldUpdate || shouldUpdateFlags || shouldUpdateFieldSelectors) {
      const fCont = shouldUpdateFlags
        ? { flags: nextProps.flags }
        : { flags: prevState.flags }

      const fsCont = shouldUpdateFieldSelectors
        ? { fields: nextProps.fields }
        : { fields: prevState.fields }

      if (shouldUpdateFlags || shouldUpdateFieldSelectors) {
        updateSelectors({ ...fCont, ...fsCont })
      }

      if (shouldUpdate) {
        const pack = { dispatch, form }

        return {
          children: Form.transform(children, pack),
          childMap,
          ...fCont,
          ...fsCont
        }
      }

      return {
        ...prevState,
        ...fCont,
        ...fsCont
      }
    }
    return null
  }

  render() {
    const { handleSubmit, className, decorated, fullPage, withFeaturedImage } =
      this.props
    const { children } = this.state

    const formClasses = classNames(className, 'form', {
      'form--decorated-normal': decorated,
      'form--decorated-full-page': fullPage,
      'form--decorated-with-featured-img': withFeaturedImage
    })
    return (
      <form onSubmit={handleSubmit} className={formClasses}>
        {children}
      </form>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    /** */
    className: ownProps.className,
    /** */
    form: ownProps.form,
    /** */
    onSubmit: ownProps.onSubmit,
    /** */
    onSubmitFail: ownProps.onSubmitFail,
    /** */
    onSubmitSuccess: ownProps.onSubmitSuccess,
    /** */
    validate: ownProps.validate,
    /** */
    warn: ownProps.warn
  }
}

export default compose(connect(mapStateToProps), reduxForm())(Form)
