import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M6.03 12.6L0.305999 0L13.518 0L7.812 12.6L6.03 12.6ZM6.93 10.782L10.98 1.566L2.898 1.566L6.93 10.782Z"
      fill="currentColor"
    />
  </Fragment>,
  'TriangleArrow',
  '0 0 14 13'
)
