'use strict';

var MapMarker = function MapMarker(_ref) {
  var url = _ref.url,
    _ref$size = _ref.size,
    _ref$size2 = _ref$size === void 0 ? {} : _ref$size,
    _ref$size2$height = _ref$size2.height,
    height = _ref$size2$height === void 0 ? 30 : _ref$size2$height,
    _ref$size2$width = _ref$size2.width,
    width = _ref$size2$width === void 0 ? 50 : _ref$size2$width,
    position = _ref.position,
    title = _ref.title,
    draggable = _ref.draggable,
    onDragEnd = _ref.onDragEnd;
  var icon = {
    url: url,
    scaledSize: new google.maps.Size(height, width),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(width / 2, height)
  };
  var marker = new google.maps.Marker({
    position: position,
    title: title,
    icon: icon,
    draggable: draggable
  });
  if (marker) google.maps.event.addListener(marker, 'dragend', onDragEnd);
  return marker;
};

module.exports = MapMarker;
