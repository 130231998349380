import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { ShowFlashNotification } from 'reduxActions/services'

import { FormContainer, TextField, SubmitButton, Typography, Spinner } from 'rc'
import { ArrowThinLeft, Mail, Checkmark } from 'rc/Icons'

import { ForgotPassword } from 'reduxActions/auth'

const ForgotPasswordForm = ({ goBackFunc, tracker, AmplitudeEvents }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [formEmail, setFormEmail] = useState('')
  const dispatch = useDispatch()

  const isRequired = newValue => {
    return (
      (!newValue || (newValue && newValue.trim().length === 0)) &&
      'Campo requerido.'
    )
  }

  const handleSubmit = values => {
    const { email } = values
    setIsLoading(true)
    tracker(
      AmplitudeEvents.LOGIN_FORGOT_PASSWORD_ACTION,
      'User forgot password send email'
    )
    const fields_with_errors =
      !email || (typeof email === 'string' && email.trim().length === 0)
    if (fields_with_errors) {
      const error = new Error({ message: 'Required fields' })
      error.code = 'REQUIRED'
      error.metadata = ['email']
      throw error
    }
    setFormEmail(values.email)
    return dispatch(ForgotPassword({ email: values.email.trim() }))
  }
  const handleSubmitSuccess = response => {
    setIsLoading(false)
    setStep(1)
  }
  const handleSubmitFail = (formErrors, fc, submitErrors) => {
    setIsLoading(false)
    if (formErrors && Object.keys(formErrors).length > 0) {
      for (const prop in formErrors) {
        if (formErrors[prop])
          return dispatch(
            ShowFlashNotification(
              'danger',
              'Ninguno de los campos puede estar vacío'
            )
          )
      }
    }
    if (!submitErrors) submitErrors = {}

    const {
      error: { response: { data: { error: { code } = {} } = {} } = {} } = {},
      code: customCode,
      metadata
    } = submitErrors
    let errorMessage = ''
    switch (code || customCode) {
      case 'INTERNAL_SERVER_ERROR':
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'
        break
      case 'REQUIRED':
        {
          const fieldErrors = metadata.reduce((previous, current) => {
            return {
              ...previous,
              [current]: 'Campo requerido'
            }
          }, {})

          fc.setCustomErrors({
            ...fieldErrors
          })
        }
        break
      case 'VALIDATION_ERROR':
        errorMessage = 'Formato del correo incorrecto.'
        break
      default:
        errorMessage =
          'Error desconocido, por favor intenta de nuevo más tarde.'
        break
    }
    dispatch(ShowFlashNotification('danger', errorMessage))
  }

  return (
    <>
      <FormContainerStyled
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        onSubmitSuccess={handleSubmitSuccess}
        onSubmitFail={handleSubmitFail}
      >
        {step === 0 ? (
          <>
            <Typography variant="body4" fontWeight={600} align="center">
              Recuperar contraseña
            </Typography>
            <TextFieldStyled
              fullWidth
              label="Correo electrónico"
              name="email"
              validate={isRequired}
              InputProps={{
                startAdornment: (
                  <StartIconContainer>
                    <Mail />
                  </StartIconContainer>
                )
              }}
            />
            <SubmitButtonStyled size="large">
              {isLoading ? (
                <Spinner
                  css={`
                    height: 40px;
                  `}
                />
              ) : (
                'Enviar correo'
              )}
            </SubmitButtonStyled>
          </>
        ) : (
          <>
            <CheckmarkIconContainer>
              <Checkmark />
            </CheckmarkIconContainer>
            <Typography variant="body4" fontWeight={600} align="center">
              ¡Listo!
            </Typography>
            <Typography
              variant="body2"
              align="center"
              css={`
                margin: ${({ theme }) => theme.remCalc('16 0 32')};
              `}
            >
              Hemos enviado un mensaje al correo electrónico <b>{formEmail}</b>{' '}
              para recuperar tu contraseña.
            </Typography>
          </>
        )}
      </FormContainerStyled>
      <TextlikeUrl onClick={goBackFunc}>
        <ArrowThinLeft /> Volver al inicio de sesión
      </TextlikeUrl>
    </>
  )
}

const FormContainerStyled = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
const TextFieldStyled = styled(TextField)`
  margin: ${({ theme }) => theme.remCalc('40 0 16')};
  > label {
    left: ${({ theme }) => theme.remCalc('20')};
  }
  > div > fieldset {
    padding-left: 28px;
  }
`
const StartIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.remCalc('10')};
  width: ${({ theme }) => theme.remCalc('20')};
  > svg {
    width: 100%;
    color: ${({ theme }) => theme.palette.text.grayDark};
    fill: ${({ theme }) => theme.palette.text.grayDark};
  }
`
const SubmitButtonStyled = styled(SubmitButton)`
  border-radius: 50px !important;
  margin: ${({ theme }) => theme.remCalc('30')};
  width: 100%;
`
const CheckmarkIconContainer = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.remCalc('30')};
  border: 2px solid #00ba88;
  padding: ${({ theme }) => theme.remCalc('10')};
  width: ${({ theme }) => theme.remCalc('56')};
  margin-bottom: ${({ theme }) => theme.remCalc('32')};
  > svg {
    width: 35px;
    fill: #00ba88;
    color: #00ba88;
  }
`
const TextlikeUrl = styled(Typography).attrs({
  variant: 'subtitle4',
  color: 'palette.primary.main',
  display: 'flex'
})`
  text-decoration: underline;
  cursor: pointer;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
  > svg {
    width: ${({ theme }) => theme.remCalc('24')};
    margin-right: ${({ theme }) => theme.remCalc('8')};
  }
`

ForgotPasswordForm.propTypes = {
  AmplitudeEvents: PropTypes.object,
  goBackFunc: PropTypes.func,
  tracker: PropTypes.func
}

export default ForgotPasswordForm
