import React, { useRef, useState, useEffect } from 'react'
import { func, number, string } from 'prop-types'
import { Typography } from 'rc'
import styled from 'styled-components'

function DualSlider(props) {
  let minEndRef = useRef(null)
  let maxEndRef = useRef(null)

  const totalUnits = props.max - props.min

  const [minEnd, setMinEnd] = useState(() =>
    props.minValue ? (props.minValue * 100) / totalUnits : 0
  )
  const [maxEnd, setMaxEnd] = useState(() =>
    props.maxValue ? (props.maxValue * 100) / totalUnits : 100
  )

  const [minValue, setMinValue] = useState(props.minValue ? props.minValue : '1000')
  const [maxValue, setMaxValue] = useState(props.maxValue ? props.maxValue : '20000000')

  useEffect(() => {
    const val = ((props.minValue - props.min) * 100) / totalUnits
    if (!isNaN(val)) {
      setMinEnd(val)
    }
  }, [props.minValue, totalUnits])

  useEffect(() => {
    const val = ((props.maxValue - props.min) * 100) / totalUnits

    if (!isNaN(val)) {
      setMaxEnd(100 - val)
    }
  }, [props.maxValue, totalUnits])

  function handleMinEnd(event) {
    if (!minEndRef.current || !maxEndRef.current) return

    const maxEnd = parseInt(maxEndRef.current.value)
    let minEnd = parseInt(event.target.value)

    let newVal

    if (minEnd + props.step >= maxEnd) {
      if (minEnd === props.max) {
        maxEndRef.current.value = props.max
        minEnd = minEnd - props.step
        newVal = props.max
      } else {
        newVal = minEnd + props.step
        maxEndRef.current.value = newVal >= props.max ? props.max : newVal
      }
    }

    setMinEnd(getSize(minEnd, props.max))

    props.onChange?.(event, { minEnd, maxEnd: newVal || maxEnd })
  }

  function handleMaxEnd(event) {
    if (!minEndRef.current || !maxEndRef.current) return

    let maxEnd = parseInt(event.target.value)
    const minEnd = parseInt(minEndRef.current?.value)

    let newVal

    if (maxEnd - props.step <= minEnd) {
      if (maxEnd === props.min) {
        minEndRef.current.value = props.min
        maxEnd = maxEnd + props.step
        newVal = props.min
      } else {
        newVal = maxEnd - props.step
        minEndRef.current.value = newVal >= props.min ? newVal : props.min
      }
    }

    setMaxEnd(100 - getSize(maxEnd, props.max))

    props.onChange?.(event, { minEnd: newVal || minEnd, maxEnd })
  }

  return (
    <>
      <EdgesContainer>
          <Typography
            variant="p"
            css={`color: #605D75; font-weight: 600;`}
          >
            {`$${Number(minValue).toLocaleString('en-US')} - $${Number(maxValue).toLocaleString('en-US')}`}
          </Typography>
      </EdgesContainer>
      <Container className={props.className}>
        <InputRange
          name={props.minInpName}
          ref={minEndRef}
          type="range"
          min={props.min}
          max={props.max}
          value={minValue}
          onInput={handleMinEnd}
          disabled={props.disabled}
          step={props.step}
          onChange={({ target }) => { setMinValue(target.value) }}
          data-debounced-submit
        />
        <InputRange
          name={props.maxInpName}
          ref={maxEndRef}
          type="range"
          min={props.min}
          max={props.max}
          value={props.maxValue}
          onInput={handleMaxEnd}
          step={props.step}
          disabled={props.disabled}
          onChange={({ target }) => { setMaxValue(target.value) }}
          data-debounced-submit
        />
        <BarsContainer>
          <Track
            style={{
              width: `calc(${minEnd}%)`,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5
            }}
          />
          {!isNaN(minEnd) && !isNaN(maxEnd) && (
            <Track active style={{ flex: 1, borderRadius: 5 }} />
          )}
          <Track
            style={{
              width: `calc(${maxEnd}%)`,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5
            }}
          />
        </BarsContainer>
      </Container>
    </>
  )
}

DualSlider.propTypes = {
  max: number,
  min: number,
  maxValue: number,
  minValue: number,
  step: number,
  className: string,
  onChange: func
}

function getSize(val, range) {
  return (val / range) * 100
}

const InputRange = styled.input`
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  margin: 0;
  padding: 0;
  /* Add some L/R padding to ensure box shadow of handle is shown */
  overflow: visible;
  border: 0;
  border-radius: 1px;
  outline: none;
  background: linear-gradient(transparent, transparent) no-repeat center;
  /* Use a linear gradient to generate only the 2px height background */
  background-size: 100% 2px;
  pointer-events: none;

  &:active,
  &:focus {
    outline: none;
  }

  &::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #0076FF;
    position: relative;
    margin: 5px 0;
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 4px solid #FFFFFF
  }

  &::-webkit-slider-thumb{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #0076FF;
    position: relative;
    margin: 5px 0;
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 4px solid #FFFFFF
  }
`

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${InputRange} {
    position: absolute;
    &:nth-child(2) {
      background: none;
    }
  }
`

const BarsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 100%;
`

const Track = styled.div`
  height: 10px;
  background-color: ${({ active }) => active ? '#00BA88' : '#d9dde7'};
`

const EdgesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export default DualSlider
