import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HideModal, ShowFlashNotification } from 'reduxActions/services'
import { LogOut } from 'reduxActions/auth'
import { UpdateProfile } from 'reduxActions/profile'

import styled from 'styled-components'
import { Typography, Link } from 'rc'
import { Link as RouterLink } from 'react-router-dom'
import { Button } from 'components'
import { TERMS_AND_CONDITIONS_VERSION } from 'utils/constants'

const ButtonStyled = styled(Button)`
  width: ${({ theme }) => theme.remCalc('180')};
  max-width: 45%;
`

const ContainerStyled = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: ${({ theme }) => theme.remCalc('12')};
  ${({ theme }) => theme.box(430, 320)}
`
const TermsLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const TermsAndConditionsMC = ({
  token,
  UpdateProfile,
  HideModal,
  LogOut,
  ShowFlashNotification,
  UnlockModal,
  UpdateTerms,
  callback
}) => {
  const [loading, setLoading] = useState(false)

  const handleReject = () => {
    UnlockModal()
    HideModal()
    LogOut(token)
  }

  const handleAccept = () => {
    setLoading(true)
    UpdateProfile(token, { terms_and_conditions: TERMS_AND_CONDITIONS_VERSION })
      .then(res => {
        UpdateTerms()
        UnlockModal()
        HideModal()

        if (callback) callback()
      })
      .catch(err => {
        setLoading(false)
        ShowFlashNotification('danger', 'Ocurrió un error inesperado')
      })
  }
  return (
    <ContainerStyled>
      <Typography variant="h2" textTransform="initial">
        ¡Bienvenido!
      </Typography>
      <Typography variant="body1">
        Hemos actualizado nuestros{' '}
        <TermsLink
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.REACT_APP_TERMS_URL}
        >
          Términos y condiciones.
        </TermsLink>{' '}
        y{' '}
        <Link
          underline="always"
          as={RouterLink}
          target="_blank"
          to="/legal/terms"
        >
          aviso de privacidad
        </Link>{' '}
        por lo que te pedimos revisarlos y aceptarlos para poder continuar, de
        lo contrario se cerrará tu sesión.
      </Typography>
      <div
        css={`
          width: 100%;
          display: flex;
          justify-content: space-around;
        `}
      >
        <ButtonStyled disabled={loading} type="tertiary" onClick={handleReject}>
          No acepto
        </ButtonStyled>
        <ButtonStyled disabled={loading} onClick={handleAccept}>
          Acepto
        </ButtonStyled>
      </div>
    </ContainerStyled>
  )
}

TermsAndConditionsMC.propTypes = {
  token: PropTypes.string,
  UnlockModal: PropTypes.func,
  UpdateProfile: PropTypes.func,
  HideModal: PropTypes.func,
  LogOut: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  UpdateTerms: PropTypes.func,
  callback: PropTypes.func
}

const mapDispatchToProps = {
  HideModal,
  UpdateProfile,
  LogOut,
  ShowFlashNotification,
  UpdateTerms: () => ({
    type: 'UPDATE_TERMS',
    payload: TERMS_AND_CONDITIONS_VERSION
  })
}
export default connect(null, mapDispatchToProps)(TermsAndConditionsMC)
