import { createReducer } from '../store'

const initialState = {
  platformActivationStatus: null,
  property: {},
  modal: null,
  currentIndexProperty: null,
}

export const propertiesReducer = createReducer(initialState, {
  COMMUNICATE_PLATFORM_ACTIVATION: (state, action) => {
    return { ...state, platformActivationStatus: action.status }
  },
  RESET_PLATFORM_ACTIVATION_STATUS: state => {
    return { ...state, platformActivationStatus: null }
  },
  SET_MODAL_PROPERTY(state, action) {
    return {
      ...state,
      modal: action.payload.data
    }
  },
  SET_CURRENT_INDEX_PROPERTY: (state, action) => {
    const {
      payload: { index }
    } = action
    return { ...state, currentIndexProperty: index }
  },
  RESET_CURRENT_INDEX_PROPERTY: (state) => {
    return { ...state, currentIndexProperty: null }
  },
})

export default propertiesReducer
