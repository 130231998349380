var _jsxFileName = 'src/lib/elements/Buttons/SecondaryButton.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../generics/Buttons';

/**
 * This focuses main actions and derived.
 */
var SecondaryButton = function SecondaryButton(_ref) {
  var className = _ref.className,
      text = _ref.children,
      onClick = _ref.onClick,
      full = _ref.full,
      margin = _ref.margin,
      noMargin = _ref.noMargin,
      submit = _ref.submit,
      extraPadding = _ref.padding,
      disabled = _ref.disabled,
      square = _ref.square,
      id = _ref.id,
      dripiconIcon = _ref.dripiconIcon;

  return React.createElement(
    Button,
    {
      id: id,
      className: className,
      disabled: disabled,
      primary: true,
      submit: submit,
      onClick: onClick,
      full: full,
      margin: margin,
      square: square,
      noMargin: noMargin,
      extraPadding: extraPadding,
      dripiconIcon: dripiconIcon,
      secondary: true,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 25
      },
      __self: _this
    },
    text
  );
};

SecondaryButton.defaultProps = {
  full: true,
  padding: false
};

SecondaryButton.propTypes = {
  /** Text to be displayed in button. */
  children: PropTypes.string,
  /** ClassName to add custom styles. */
  className: PropTypes.string,
  /** Disables button.*/
  disabled: PropTypes.bool,
  /** In case you want to use a dripiconIcon on button. */
  dripiconIcon: PropTypes.string,
  /** If you want to make it full width. */
  full: PropTypes.bool,
  /** Used to identify button. */
  id: PropTypes.string,
  /** Gives only margin top and bottom, but only when full=false */
  margin: PropTypes.bool,
  /** Gives no margin to any side, but only when full=false */
  noMargin: PropTypes.bool,
  /** Handler of click action on button. */
  onClick: PropTypes.func,
  /** Gives extra padding in button. */
  padding: PropTypes.bool,
  /** Makes button square. */
  square: PropTypes.bool,
  submit: PropTypes.bool
};

export default SecondaryButton;