import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ModalContainer } from 'c3-ui/elements/Modals'

import {
  HideModal,
  LockModal,
  ShowModal,
  ShowFlashNotification,
  UnlockModal,
  GoBackModalHistory
} from 'reduxActions/services'

class ModalService extends PureComponent {
  componentDidUpdate(prevProps) {
    const { isModalOpen, location, isLocked, HideModal } = this.props
    if (location === prevProps.location) {
      if (isModalOpen !== prevProps.isModalOpen) {
        this._modal.toggleModal()
      }
    } else if (isModalOpen) {
      HideModal()
    } else if (isLocked !== prevProps.isLocked) {
      isLocked ? this._modal.lockModal() : this._modal.unlockModal()
    }
  }

  onModalMounted = modal => {
    this._modal = modal
  }

  render() {
    const {
      Component,
      GoBackModalHistory,
      ShowFlashNotification,
      HideModal,
      LockModal,
      UnlockModal,
      componentProps,
      ShowModal,
      history,
      modalProps,
      withHistory
    } = this.props

    return (
      <ModalContainer
        ref={this.onModalMounted}
        handleCloseModal={HideModal}
        {...modalProps}
      >
        {Component && (
          <Component
            withHistory={withHistory}
            HideModal={HideModal}
            LockModal={LockModal}
            ShowFlashNotification={ShowFlashNotification}
            UnlockModal={UnlockModal}
            ShowModal={ShowModal}
            hasHistory={history.length > 0}
            handleGoBack={GoBackModalHistory}
            {...componentProps}
          />
        )}
      </ModalContainer>
    )
  }
}

ModalService.propTypes = {
  Component: PropTypes.any,
  GoBackModalHistory: PropTypes.func,
  HideModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  LockModal: PropTypes.func,
  UnlockModal: PropTypes.func,
  ShowModal: PropTypes.func,
  componentProps: PropTypes.object,
  history: PropTypes.array,
  isLocked: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  location: PropTypes.object,
  modalProps: PropTypes.object,
  withHistory: PropTypes.bool
}

const mapStateToProps = ({ servicesReducer: { modal } }) => ({ ...modal })

const mapDispatchToProps = {
  GoBackModalHistory,
  HideModal,
  LockModal,
  UnlockModal,
  ShowFlashNotification,
  ShowModal
}

const connectedModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalService)

export default withRouter(connectedModal)
