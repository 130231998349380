import React from 'react'
import styled from 'styled-components'
import { Typography } from 'rc'
import { Checkmark } from 'rc/Icons'
import BenefitLayoutMC from 'modules/freemium/BenefitLayoutMC'
import { benefits } from 'modules/freemium/utils/constants'

const AllBenefits = () => {
  return (
    <BenefitLayoutMC>
      <Typography 
        variant="h6" 
        textTransform="initial"
        color="palette.prime.yellow"
        css={`
          margin-top: ${({theme})=> theme.remCalc('10')};
        `}
      >
          Esta y más herramientas disponibles con Wiggot Prime
      </Typography>
      <BenefitsContainer>
        {benefits.map((benefit, index) => (
          <BenefitContainer key={index}>
            <CheckmarkStyled/>
            <Typography variant="body1" color="palette.prime.text">
              {benefit}
            </Typography>
          </BenefitContainer>
        ))}
      </BenefitsContainer>
      <PriceContainer>
        <PriceDescription>desde</PriceDescription>
        <Typography 
          variant="h2" 
          color="palette.prime.yellow" 
          css={`font-size: ${({theme})=> theme.remCalc('40')};`}
        >
          $459
        </Typography>
        <PriceDescription>/mes</PriceDescription>
      </PriceContainer>
    </BenefitLayoutMC>
  )
}

const BenefitsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: ${({theme})=> theme.remCalc('550')};
  margin-top: ${({theme})=> theme.remCalc('20')};
  margin-bottom: ${({theme})=> theme.remCalc('10')};
  max-width: 100%;
  ${({theme})=> theme.media.tabletDown`
    flex-direction: column;
  `}
`
const BenefitContainer = styled.div`
  width: 48%;
  display: flex;
  align-items: flex-start;
  ${({theme})=> theme.media.tabletDown`
    width: 100%;
  `}
`
const CheckmarkStyled = styled(Checkmark)`
  color: ${({theme})=> theme.palette.prime.yellow};
  margin: ${({ theme }) => theme.remCalc('5 5 0 0')};
  min-width: ${({ theme }) => theme.remCalc('20')};
  min-height: ${({ theme }) => theme.remCalc('17')};
  ${({ theme }) => theme.box(15, 15)}
`

const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({theme})=> theme.remCalc('10')};
`
const PriceDescription = styled(Typography).attrs({
  variant: 'body1',
  color: 'palette.prime.text'
})`
  &:first-child {
    margin-right: ${({theme})=> theme.remCalc('5')};
  }
  &:last-child {
    margin-left: ${({theme})=> theme.remCalc('5')};
  }
`

export default AllBenefits