import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

const PlanCounter = ({ isMobile, ...props }) => {
  const {
    roles,
    planType,
    primeType,
    primeAvailableUntil,
    fullPrimeUsed,
    trialUsed
  } = useSelector(
    ({
      authReducer: { roles },
      profileReducer: {
        primeType,
        planType,
        primeAvailableUntil,
        fullPrimeUsed,
        trialUsed
      }
    }) => ({
      roles,
      primeType,
      planType,
      primeAvailableUntil,
      fullPrimeUsed,
      trialUsed
    })
  )

  const daysLeft = () => {
    const until = (primeAvailableUntil && primeAvailableUntil.split(' ')) || []
    const availableUntil = new Date(until[0])
    const dateNow = new Date()

    const msDiff = availableUntil - dateNow
    let daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24))
    if (daysDiff < 0) daysDiff = 0
    return daysDiff
  }

  const customCopy = `Quedan ${daysLeft()} días de`

  const PLAN_TYPE = {
    TRIAL_ADVANCED: `${customCopy} prueba.`,
    ADVANCED: `${customCopy} Wiggot Avanzado`,
    STARTER: `${customCopy} Wiggot Básico`,
    TRIAL_USED: '¡Tu prueba ha terminado!',
    FULL_USED: `Renovar plan`
  }

  return (
    <BannerCounter
      pro={roles.PRO}
      prime={roles.PRIME}
      full={roles.PRIME && roles.PRO && roles.PASS}
      isMobile={isMobile}
      {...props}
    >
      <span>
        {(planType || primeType) && PLAN_TYPE[planType || primeType]}
        {!planType && !primeType
          ? fullPrimeUsed
            ? PLAN_TYPE['FULL_USED']
            : trialUsed
            ? PLAN_TYPE['TRIAL_USED']
            : ''
          : ''}
      </span>
      <Underline
        onClick={() =>
          window.location.replace(
            `${process.env.REACT_APP_NEW_APLICATION_URL}/shop`
          )
        }
      >
        {planType === 'TRIAL_ADVANCED' || (!planType && !primeType)
          ? 'Contrata tu plan.'
          : 'Ver planes'}
      </Underline>
    </BannerCounter>
  )
}

PlanCounter.propTypes = {
  isMobile: PropTypes.bool
}

const BannerCounter = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.remCalc('20')};
  font-size: 14px;
  color: #f1a208;
  position: absolute;
  left: 144px;
  background-color: rgba(241, 162, 8, 0.1);
  font-family: 'Red Hat Display', sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 20px;
  ${({ theme, isMobile }) =>
    !isMobile
      ? theme.media.tabletDown`
        display: none;
      `
      : `
        left: ${theme.remCalc('10')};
        bottom: ${theme.remCalc('3')};
        padding: ${theme.remCalc('2 10')};
        flex-direction: column;
        align-items: center;
        width: 90%;
      `}
`
const Underline = styled.span`
  text-decoration: underline;
  margin-left: 3px;
  cursor: pointer;
  color: #d89106;
`

export default PlanCounter
