/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from 'react'
import { func, object, string, bool } from 'prop-types'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Button } from 'rc'

import BasicList from './BasicList'
import { PrimeButton } from 'modules/prime'

import Background from 'static/prime/prime-modal.svg'
import WhiteLogo from 'static/logos/wiggot-prime-white.svg'
import OriginalLogo from 'static/img/wiggot-logo.svg'
import OutOfTime from 'static/drawings/out-of-time.svg'

import { ShowModal } from 'reduxActions/services'

const WiggotPrimeLogo = styled.img.attrs({
  src: WhiteLogo,
  alt: 'Wiggot Prime'
})``

const WiggotLogo = styled.img.attrs({
  src: OriginalLogo,
  alt: 'Wiggot'
})``

const ModalBackground = styled.img.attrs({
  src: Background,
  alt: 'Fondo'
})``

const Description = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.remCalc('16')};
  line-height: ${({ theme }) => theme.remCalc('20')};
  margin-bottom: ${({ theme }) => theme.remCalc('44')};
  ~ a {
    text-align: center;
  }
`

const mobileContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme, flowState }) =>
    theme.remCalc(flowState === 'INIT' ? '10 20' : '10 5')};
  h4 {
    text-align: center;
  }
  ${Description} {
    margin-top: ${({ theme }) => theme.remCalc('10')};
  }
`

const desktopContainerStyle = css`
  padding: ${({ theme }) => theme.remCalc('30 40')};
  width: ${({ theme }) => theme.remCalc('600')};
`

const Container = styled.div`
  position: relative;
  width: 100%;
  ${({ theme }) => theme.media.desktopDown`${mobileContainerStyle}`}
  ${({ theme }) => theme.media.desktop`${desktopContainerStyle}`}
  ${ModalBackground} {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
  }
`

const CounterContainer = styled.div`
  background: #ffffff;
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.remCalc('10')};
  font-size: ${({ theme }) => theme.remCalc('25')};
  ${({ theme }) => theme.media.desktop`
      padding: ${({ theme }) => theme.remCalc('20 16')};
  `}
  p {
    margin: 0;
  }
`

const desktopInfoContainerStyle = css`
  margin-bottom: ${({ theme }) => theme.remCalc('20')};
`

const mobileInfoContainerStyle = css`
  flex-direction: column;
  ${CounterContainer} {
    text-align: center;
    margin: ${({ theme }) => theme.remCalc('15 0')};
  }
`

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.media.desktopDown`${mobileInfoContainerStyle}`}
  ${({ theme }) => theme.media.desktop`${desktopInfoContainerStyle}`}
`

const ButtonStyled = styled(Button)`
  max-width: ${({ theme }) => theme.remCalc('250')};
  width: 100%;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  > button {
    flex: 1;
    height: ${({ theme }) => theme.remCalc('50')};
    margin-left: ${({ theme }) => theme.remCalc('10')};
  }
  > button:first-child {
    margin-left: ${({ theme }) => theme.remCalc('10')};
    margin-left: 0;
  }
`

const BasicListStyled = styled(BasicList)`
  margin-bottom: ${({ theme }) => theme.remCalc('23')};
  width: 100%;
`

const TrialEndMC = props => {
  const { history } = props
  const [flowState, setFlowState] = useState('INIT')

  const handleFlow = state => () => setFlowState(state)

  const openPaymentSection = () => {
  }

  return (
    <Container flowState={flowState} className={props.className}>
      {flowState === 'INIT' ? (
        <Fragment>
          <ModalBackground />
          <WiggotPrimeLogo />
          <InfoContainer>
            <div>
              <CounterContainer>
                <p>
                  Tu prueba prime
                  <b> terminó</b>
                </p>
              </CounterContainer>
            </div>
            <img src={OutOfTime} alt="out of time" />
          </InfoContainer>
          <Description>
            <b>
              Continua usando las{' '}
              <a onClick={handleFlow('SHOW')}> herramientas gratuitas</a> de
              wiggot o{' '}
              <a onClick={() => history.push('/account')}>
                conoce los planes de wiggot prime
              </a>{' '}
              y contrata uno.
            </b>
          </Description>
        </Fragment>
      ) : (
        <Fragment>
          <h4>
            Herramientas gratuitas <WiggotLogo />
          </h4>
          <BasicListStyled />
        </Fragment>
      )}
      <ButtonContainer>
        {flowState !== 'SKIP' ? (
          <Fragment>
            <ButtonStyled
              onClick={
                flowState === 'INIT' ? handleFlow('SKIP') : props.HideModal
              }
            >
              Continuar con Wiggot gratuito
            </ButtonStyled>
            <PrimeButton onClick={openPaymentSection}>
              Contratar Wiggot Prime
            </PrimeButton>
          </Fragment>
        ) : (
          <ButtonStyled
            variant="outlined"
            color="gray"
            onClick={props.HideModal}
          >
            Cerrar
          </ButtonStyled>
        )}
      </ButtonContainer>
    </Container>
  )
}

TrialEndMC.propTypes = {
  HideModal: func,
  ShowModal: func,
  action: func,
  history: object,
  className: string,
  isAdmin: bool,
  primeChanel: string,
  roles: object
}

const mapDispatchToProps = { ShowModal }

const mapStateToProps = ({ authReducer: { roles } }) => ({ roles })

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TrialEndMC)
)
