import { createReducer } from '../store'

import { createCookie } from 'utils/handleCookies'
import { snakeToCamelCase } from 'utils/helpers'

export const updateProfile = (state, data, isAdmin, roles) => {
  let {
    agencyId = state.agencyId,
    userId = state.userId,
    profileId = state.profileId,
    email = state.email,
    fullname = state.fullname,
    photo = state.photo,
    verified = state.verified,
    trialUsed = state.trialUsed,
    primeType = state.primeType,
    primeAvailableUntil = state.primeAvailableUntil,
    fullPrimeUsed = state.fullPrimeUsed,
    domain = state.domain,
    subdomain = state.subdomain,
    domainCertificateStatus = state.domainCertificateStatus,
    userScoresSectionConfig = state.userScoresSectionConfig,
    userPaymentStatus = state.userPaymentStatus,
    phoneNumberVerified = state.phoneNumberVerified,
    phoneNumber = state.phone_number,
    notificationsReminder = state.notifications_reminder,
    specialPermission = state.specialPermission,
    agencyUsersWithPrime = state.agencyUsersWithPrime,
    planAvailableUntil = state.planAvailableUntil,
    planType = state.planType,
    oldRoles = state.oldRoles,
    fullPlanUsed = state.fullPlanUsed,
    isOldPro = state.isOldPro
  } = data

  let termsAndConditions =
    data.termsAndConditions === null ? false : data.termsAndConditions
  const newState = {
    ...state,
    userId,
    profileId,
    email,
    fullname,
    photo,
    verified,
    isLoading: false,
    agencyId,
    trialUsed,
    primeType,
    fullPrime: primeType === 'FULL_PRIME',
    primeAvailableUntil,
    fullPrimeUsed,
    domain,
    subdomain,
    domainCertificateStatus,
    userScoresSectionConfig,
    userPaymentStatus,
    phoneNumberVerified,
    phoneNumber,
    notificationsReminder,
    termsAndConditions,
    specialPermission,
    agencyUsersWithPrime,
    planAvailableUntil,
    planType,
    oldRoles,
    fullPlanUsed,
    isOldPro
  }
  createCookie('profile_user', newState)
  return newState
}

const initialState = {
  agencyId: null,
  userId: null,
  profileId: null,
  email: null,
  fullname: null,
  photo: null,
  verified: null,
  isLoading: true,
  noShowRequirementOnBoarding: false,
  trialUsed: false,
  primeType: undefined,
  fullPrime: false,
  fullPrimeUsed: false,
  primeAvailableUntil: undefined,
  domain: undefined,
  subdomain: undefined,
  domainCertificateStatus: undefined,
  userScoresSectionConfig: 'HIDDEN',
  userPaymentStatus: undefined,
  phoneNumberVerified: null,
  phoneNumber: null,
  notificationsReminder: true,
  termsAndConditions: null,
  specialPermission: [],
  agencyUsersWithPrime: false,
  is_campaign_user: undefined,
  has_campaign_users: undefined,
  planAvailableUntil: undefined,
  planType: undefined,
  oldRoles: false,
  fullPlanUsed: false,
  isOldPro: false
}
const profileReducer = createReducer(
  { ...initialState },
  {
    GET_USER_INFO_SUCCESS: (state, action) => {
      let {
        payload: { data },
        meta: {
          previousAction: { isAdmin, roles }
        }
      } = action
      const responseFormatted = snakeToCamelCase(data)
      return updateProfile(state, responseFormatted, isAdmin, roles)
    },
    SEEN_NOTIFICATIONS_REMINDER_SUCCESS: (state, action) => {
      const newState = { ...state, notificationsReminder: true }
      createCookie('profile_user', newState)
      return newState
    },
    SUBMIT_UPDATE_PROFILE_SUCCESS: (state, action) => {
      const {
        meta: {
          previousAction: {
            payload: {
              request: { data: { user_photo, user_phone_number } = {} } = {}
            } = {}
          } = {}
        } = {}
      } = action

      if (user_photo) {
        const newState = {
          ...state,
          photo: user_photo
        }

        createCookie('profile_user', newState)
        return newState
      } else if (user_phone_number) {
        const newState = {
          ...state,
          phoneNumber: user_phone_number
        }
        createCookie('profile_user', newState)
        return newState
      }
      return state
    },
    NO_SHOW_REQUIREMENT_ON_BOARDING: state => {
      const newState = { ...state, noShowRequirementOnBoarding: true }
      createCookie('profile_user', newState)
      return newState
    },
    ACTIVE_PRIME_SUCCESS: (state, action) => {
      const { payload: { data: { available_until, prime_type } = {} } = {} } =
        action
      const newState = {
        ...state,
        primeAvailableUntil: available_until,
        primeType: prime_type,
        fullPrime: prime_type === 'FULL_PRIME'
      }

      createCookie('profile_user', newState)
      return newState
    },
    UPDATE_PROFILE_CONFIG_SUCCESS: (state, action) => {
      const {
        meta: {
          previousAction: {
            payload: {
              request: { data: { user_scores_section_config } = {} } = {}
            } = {}
          } = {}
        } = {}
      } = action
      const newState = {
        ...state,
        userScoresSectionConfig: user_scores_section_config
      }
      createCookie('profile_user', newState)
      return newState
    },
    SEND_PHONE_VERIFICATION_CODE_SUCCESS: (state, action) => {
      const {
        meta: {
          previousAction: {
            payload: { request: { data: { phone_number } = {} } = {} } = {}
          } = {}
        } = {}
      } = action
      const newState = {
        ...state,
        phoneNumber: phone_number
      }
      createCookie('profile_user', newState)
      return newState
    },
    VERIFY_PHONE_NUMBER_SUCCESS: (state, action) => {
      const newState = {
        ...state,
        phoneNumberVerified: true
      }
      createCookie('profile_user', newState)
      return newState
    },
    UPDATE_TERMS: (state, action) => {
      const newState = {
        ...state,
        termsAndConditions: action.payload
      }
      createCookie('profile_user', newState)
      return newState
    },
    PASS_MINIMUM_FIELDS_SUCCESS: (state, action) => {
      const {
        payload: { data: { is_campaign_user, has_campaign_users } = {} } = {}
      } = action

      const newState = {
        ...state,
        isCampaignUser: is_campaign_user,
        hasCampaignUsers: has_campaign_users
      }

      createCookie('profile_user', newState)
      return newState
    }
  }
)

export default profileReducer
