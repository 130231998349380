import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <svg
    width="40"
    height="50"
    viewBox="0 0 40 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
   <path
      d="M39.0627 30.4687C39.0627 41.2383 30.301 50 19.5314 50C8.76177 50 0 41.2383 0 30.4687C0 26.9354 0.948248 23.1795 2.81838 19.3075C4.2969 16.243 6.35941 13.0887 8.94732 9.93045C9.27349 9.53201 9.77447 9.32107 10.2872 9.36404C10.7999 9.40701 11.2579 9.699 11.5137 10.1463C12.4845 11.8475 14.1583 12.1023 15.3995 11.7723C16.6417 11.4432 17.9689 10.3924 17.9689 8.43337V1.56223C17.9689 0.964573 18.3097 0.419648 18.8468 0.157928C19.3839 -0.103792 20.0236 -0.0364091 20.4943 0.331757C20.6827 0.479219 25.1691 4.01147 29.714 9.44607C32.4016 12.66 34.5481 15.8729 36.093 18.9969C38.0637 22.9813 39.0627 26.8407 39.0627 30.4687Z"
      fill="#FFA24F"
    />
    <path
      d="M39.0627 30.4687C39.0627 41.2383 30.301 50 19.5314 50C19.006 50 18.4845 49.9795 17.9689 49.9385C28.0109 49.1397 35.9377 40.7129 35.9377 30.4687C35.9377 26.8407 34.9387 22.9813 32.968 18.9969C31.423 15.8729 29.2765 12.66 26.589 9.44607C23.0685 5.23608 19.5831 2.16771 18.1027 0.930394C18.2491 0.599337 18.5089 0.321991 18.8468 0.157928C19.3839 -0.103792 20.0236 -0.0364091 20.4943 0.331757C20.6828 0.479219 25.1691 4.01147 29.7141 9.44607C32.4016 12.66 34.5481 15.8729 36.093 18.9969C38.0637 22.9813 39.0627 26.8407 39.0627 30.4687Z"
      fill="#EF7E19"
    />
  </svg>,
  'Match',
  '0 0 40 50'
)
