import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M78.4631 66.0282C77.298 71.314 71.8883 76.0383 67.313 78.1347C60.005 81.4646 52.206 77.479 44.685 79.2979C40.4189 80.3306 37.5923 81.2442 32.9383 80.9725C28.4439 80.7149 24.6696 78.098 21.6424 74.7533C18.652 71.4212 18.3693 66.8677 18.5894 62.4056C18.8213 57.9681 20.5673 56.0861 19.1037 51.6178C18.2656 49.0698 16.5496 46.9169 14.8447 44.85C13.1398 42.783 11.3743 40.6541 10.4867 38.13C7.89395 30.8284 13.3296 25.0864 19.2245 21.7411C22.4287 20.501 26.9268 21.5045 31.9268 19.501C35.4585 18.0858 40.6814 13.6812 44.4268 13.001C53.0504 11.4394 59.2666 12.3788 66.6599 17.2921C72.2991 21.0456 76.6217 26.7471 78.6604 33.2209C79.7805 36.7287 80.1464 40.5717 79.3356 44.1934C78.5639 47.6437 74.7373 50.7173 74.7891 54.1644C74.8104 56.2251 76.5091 57.8382 77.5048 59.6398C78.6934 61.7133 78.9341 63.9114 78.4631 66.0282Z"
      fill="#F2F2F2"
    />
    <path
      d="M50.0853 50.1206H42.1422C41.0482 50.1206 40.1543 49.1148 40.1543 47.885C40.1543 46.6552 41.0482 45.6494 42.1422 45.6494H50.0896C51.1836 45.6494 52.0775 46.6552 52.0775 47.885C52.0775 49.1148 51.1793 50.1206 50.0853 50.1206V50.1206Z"
      fill="#3A3A3A"
    />
    <path
      d="M49.4563 40.4211H39.0503C38.7184 40.4561 38.3835 40.4151 38.067 40.3005C37.7505 40.186 37.4595 40.0006 37.2126 39.7561C36.9657 39.5116 36.7684 39.2135 36.6334 38.881C36.4984 38.5484 36.4287 38.1887 36.4287 37.825C36.4287 37.4613 36.4984 37.1016 36.6334 36.769C36.7684 36.4365 36.9657 36.1384 37.2126 35.8939C37.4595 35.6494 37.7505 35.464 38.067 35.3494C38.3835 35.2349 38.7184 35.1939 39.0503 35.2289H49.4563C49.7882 35.1939 50.1232 35.2349 50.4396 35.3494C50.7561 35.464 51.0471 35.6494 51.294 35.8939C51.5409 36.1384 51.7382 36.4365 51.8732 36.769C52.0082 37.1016 52.0779 37.4613 52.0779 37.825C52.0779 38.1887 52.0082 38.5484 51.8732 38.881C51.7382 39.2135 51.5409 39.5116 51.294 39.7561C51.0471 40.0006 50.7561 40.186 50.4396 40.3005C50.1232 40.4151 49.7882 40.4561 49.4563 40.4211V40.4211Z"
      fill="#3A3A3A"
    />
    <path
      d="M49.4563 60.5438H39.0503C38.7184 60.5738 38.3835 60.5387 38.067 60.4405C37.7505 60.3423 37.4595 60.1834 37.2126 59.9738C36.9657 59.7643 36.7684 59.5088 36.6334 59.2237C36.4984 58.9387 36.4287 58.6304 36.4287 58.3186C36.4287 58.0069 36.4984 57.6985 36.6334 57.4135C36.7684 57.1284 36.9657 56.8729 37.2126 56.6634C37.4595 56.4538 37.7505 56.2949 38.067 56.1967C38.3835 56.0986 38.7184 56.0634 39.0503 56.0934H49.4563C49.7882 56.0634 50.1232 56.0986 50.4396 56.1967C50.7561 56.2949 51.0471 56.4538 51.294 56.6634C51.5409 56.8729 51.7382 57.1284 51.8732 57.4135C52.0082 57.6985 52.0779 58.0069 52.0779 58.3186C52.0779 58.6304 52.0082 58.9387 51.8732 59.2237C51.7382 59.5088 51.5409 59.7643 51.294 59.9738C51.0471 60.1834 50.7561 60.3423 50.4396 60.4405C50.1232 60.5387 49.7882 60.5738 49.4563 60.5438V60.5438Z"
      fill="#3A3A3A"
    />
    <path
      d="M58.04 35.2168L62.3827 47.0276C62.4974 47.2991 62.5566 47.5906 62.5566 47.8852C62.5566 48.1797 62.4974 48.4713 62.3827 48.7428L58.04 60.5536L90.8288 47.8698L58.04 35.2168Z"
      fill="#6FCF97"
    />
    <path
      d="M59.8357 36.6161L63.5945 46.8129C63.6938 47.0473 63.745 47.299 63.745 47.5533C63.745 47.8076 63.6938 48.0593 63.5945 48.2937L59.8357 58.4905L88.2162 47.54L59.8357 36.6161ZM55.4705 65.0253C54.8165 65.0284 54.1833 64.797 53.687 64.3736C53.3133 64.0916 53.0409 63.6974 52.91 63.2495C52.7791 62.8016 52.7967 62.3237 52.9602 61.8865L58.3108 47.5045L52.9602 33.1225C52.7985 32.6858 52.7818 32.2091 52.9126 31.7623C53.0434 31.3155 53.3148 30.9221 53.687 30.6398C54.0747 30.3148 54.5458 30.1037 55.0475 30.03C55.5492 29.9564 56.0616 30.0233 56.5272 30.2231L95.9523 45.3765C96.4007 45.532 96.7915 45.8182 97.0738 46.1978C97.3561 46.5774 97.5169 47.0328 97.5352 47.5045C97.5103 47.9826 97.348 48.4435 97.0674 48.8325C96.7868 49.2215 96.3998 49.5223 95.9523 49.6991L56.5272 64.848C56.1873 64.9649 55.8302 65.0248 55.4705 65.0253V65.0253Z"
      fill="#3A3A3A"
    />
  </Fragment>,
  'ConfirmationPlane',
  '0 0 98 96'
)
