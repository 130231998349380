import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Spinner } from 'rc'
import { MarginContainer, Block } from 'c3-ui/elements/Layouts'
import { SubmitButton } from 'c3-ui/elements/Buttons'
import { FormGroup, TextInput } from 'c3-ui/elements/FormInputs'
import { Text } from 'c3-ui/elements/Texts'
import { FormBottomActions } from 'c3-ui/elements/Forms'
import { MainLink } from 'c3-ui/elements/Links'
import { Layout } from 'c3-ui/elements/Layouts'
import { ReduxForm as Form } from 'components'

const LoginForm = ({ onSubmit, onSubmitSuccess, onSubmitFail, loading }) => {
  return (
    <Form
      form="login"
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
      className="login-form"
      withFeaturedImage
      noResponsive
    >
      <Layout className="login-form__container">
        <div
          css={`
            display: flex;
            ${({ theme }) => theme.media.tabletDown`
              justify-content: center;
            `}
          `}
        >
          <Typography
            variant="h2"
            color="palette.primary.main"
            textTransform="initial"
          >
            Inicia sesión
          </Typography>
        </div>
        <MarginContainer>
          <FormGroup paddingX={false}>
            <TextInput
              id="login-form-input-email"
              name="email"
              label="Correo electrónico"
              leftFontAwesomeIcon="envelope"
              type="email"
              validations={[
                { validationName: 'emptiness' },
                {
                  validationName: 'validEmail'
                }
              ]}
            />
            <TextInput
              id="login-form-input-password"
              name="password"
              type="password"
              label="Contraseña"
              leftFontAwesomeIcon="lock"
              validations={[{ validationName: 'emptiness' }]}
            />
          </FormGroup>
          <Text center className="show-for-small-only">
            <MainLink route="/forgotPassword" noPadding>
              ¿Olvidaste tu contraseña?
            </MainLink>
          </Text>
        </MarginContainer>
        <div
          css={`
            button[type='submit'] {
              background: ${({ theme }) => theme.palette.primary.main};
            }
            position: relative;
          `}
        >
          <SubmitButton id="login-form-button-submit" disabled={loading} style={{
            padding: 0
          }}>
            {loading && (
              <Spinner
                className="spinner-white"
                css={`
                  ${({ theme }) => theme.box(40, 40)}
                  position: absolute;
                  left: 45px;
                `}
              />
            )}
            INICIAR SESIÓN
          </SubmitButton>
        </div>
        <FormBottomActions className="hide-for-small-only">
          <Text center>
            ¡Olvidé mi contraseña!{' '}
            <MainLink
              id="login-form-link-recoverPassword"
              route="/forgotPassword"
              noPadding
            >
              Recuperar contraseña
            </MainLink>
          </Text>
        </FormBottomActions>
        <Text center className="hide-for-small-only">
          No tengo una cuenta, quiero{' '}
          <MainLink id="login-form-link-signUp" route="/register" noPadding>
            registrarme!
          </MainLink>
        </Text>
        <Block className="login-form__register-button">
          <MainLink
            id="login-form-link-createAccount"
            route="/register"
            likeButton
            noPadding
            secondary
          >
            CREAR CUENTA
          </MainLink>
        </Block>
      </Layout>
    </Form>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.any.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  loading: PropTypes.bool
}

export default LoginForm
