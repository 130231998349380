var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _jsxFileName = 'src/lib/elements/Dates/DatePickerForMonth.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work

import { Layout, LayoutItem } from '../Layouts';
import { Select as SelectView } from '../Selects';

moment.locale('es');

var DatePickerForMonth = (_temp = _class = function (_Component) {
  _inherits(DatePickerForMonth, _Component);

  function DatePickerForMonth(props) {
    _classCallCheck(this, DatePickerForMonth);

    var _this = _possibleConstructorReturn(this, (DatePickerForMonth.__proto__ || Object.getPrototypeOf(DatePickerForMonth)).call(this, props));

    _this.componentDidMount = function () {
      var date = null;
      var currentDate = moment().format('YYYY-MM-DD');
      if (_this.props.value) {
        date = _this.props.value;
      } else {
        date = currentDate;
      }
      if (date) {
        var dateParse = date;
        if ((typeof date === 'undefined' ? 'undefined' : _typeof(date)) == 'object') {
          dateParse = !date._isValid ? date._i : moment(dateParse).format('YYYY-MM-DD');
        }
        var dateArray = dateParse.split('-');
        var currentDateArray = currentDate.split('-');
        var dateParsed = moment(new Date((dateArray[0] + '-' + dateArray[1] + '-01').replace(/-/g, '/')));
        !date._isValid && _this.props.onDateChanged(dateParsed);

        _this.setState({
          currentYear: currentDateArray[0],
          currentMonth: currentDateArray[1],
          valueYearFromServer: dateArray[0],
          valueMonthFromServer: dateArray[1],
          selected: dateParsed
        }, function () {
          _this.optionsGen(currentDateArray[0], currentDateArray[1]);
        });
      }
    };

    _this.optionsGen = function (year, month) {
      var yearLimit = Number(year) + 10;
      var years = Number(year);
      var yearsToState = [];

      while (years <= yearLimit) {
        yearsToState.push({ name: years.toString(), id: years });
        years = years + 1;
      }
      _this.setState({ optionsYears: yearsToState }, function () {
        _this.initialValues();
      });
    };

    _this.initialValues = function () {
      var _this$state = _this.state,
          currentMonth = _this$state.currentMonth,
          currentYear = _this$state.currentYear,
          optionsMonth = _this$state.optionsMonth,
          optionsYears = _this$state.optionsYears,
          valueMonthFromServer = _this$state.valueMonthFromServer,
          valueYearFromServer = _this$state.valueYearFromServer,
          optionsMonthFull = _this$state.optionsMonthFull;

      var remainingMonths = optionsMonth;

      var remaining = remainingMonths;
      remaining.splice(0, Number(currentMonth) - 1);

      if (_this.props.value) {
        return _this.setState({
          optionsMonthRemaining: remaining,
          optionsMonthToSelect: Number(valueYearFromServer) != Number(currentYear) ? optionsMonthFull : remaining,
          valueMonth: optionsMonthFull[Number(valueMonthFromServer) - 1],
          valueYear: optionsYears[Number(valueYearFromServer) - Number(currentYear)]
        });
      }

      return _this.setState({
        optionsMonthRemaining: remaining,
        optionsMonthToSelect: remaining,
        valueMonth: remaining[0],
        valueYear: optionsYears[0]
      });
    };

    _this.onOptionSelected = function () {
      var e = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var type = arguments[1];
      var cb = arguments[2];
      var _this$state2 = _this.state,
          currentYear = _this$state2.currentYear,
          optionsMonthRemaining = _this$state2.optionsMonthRemaining,
          optionsMonthFull = _this$state2.optionsMonthFull,
          valueYear = _this$state2.valueYear,
          valueMonth = _this$state2.valueMonth,
          currentMonth = _this$state2.currentMonth;

      if (type == 0) {
        _this.setState({
          valueMonth: e,
          selected: moment(new Date((valueYear.id + '-' + e.id + '-01').replace(/-/g, '/')))
        }, function () {
          _this.props.onDateChanged(_this.state.selected);
        });
        cb && cb();
      } else if (type == 1) {
        if (e && e.name == currentYear) {
          _this.setState({
            valueYear: e,
            valueMonth: optionsMonthFull[Number(currentMonth) - 1],
            optionsMonthToSelect: optionsMonthRemaining,
            selected: moment(new Date((e.id + '-' + optionsMonthFull[Number(currentMonth) - 1].id + '-01').replace(/-/g, '/')))
          }, function () {
            _this.props.onDateChanged(_this.state.selected);
          });
          return cb && cb();
        } else {
          _this.setState({
            valueYear: e,
            optionsMonthToSelect: optionsMonthFull,
            selected: moment(new Date((e.id + '-' + valueMonth.id + '-01').replace(/-/g, '/')))
          }, function () {
            _this.props.onDateChanged(_this.state.selected);
          });
          return cb && cb();
        }
      }
    };

    moment.locale('es');

    _this.state = {
      currentYear: null,
      currentMonth: null,
      valueMonthFromServer: null,
      valueYearFromServer: null,
      valueMonth: {},
      valueYear: {},
      optionsMonthFull: [{ name: 'Enero', id: 1 }, { name: 'Febrero', id: 2 }, { name: 'Marzo', id: 3 }, { name: 'Abril', id: 4 }, { name: 'Mayo', id: 5 }, { name: 'Junio', id: 6 }, { name: 'Julio', id: 7 }, { name: 'Agosto', id: 8 }, { name: 'Septiembre', id: 9 }, { name: 'Octubre', id: 10 }, { name: 'Noviembre', id: 11 }, { name: 'Diciembre', id: 12 }],
      optionsMonth: [{ name: 'Enero', id: 1 }, { name: 'Febrero', id: 2 }, { name: 'Marzo', id: 3 }, { name: 'Abril', id: 4 }, { name: 'Mayo', id: 5 }, { name: 'Junio', id: 6 }, { name: 'Julio', id: 7 }, { name: 'Agosto', id: 8 }, { name: 'Septiembre', id: 9 }, { name: 'Octubre', id: 10 }, { name: 'Noviembre', id: 11 }, { name: 'Diciembre', id: 12 }],
      optionsMonthRemaining: [],
      optionsMonthToSelect: [],
      optionsYears: [],
      selected: null,
      disabled: true
    };
    return _this;
  }

  _createClass(DatePickerForMonth, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _state = this.state,
          valueMonth = _state.valueMonth,
          valueYear = _state.valueYear,
          optionsMonthToSelect = _state.optionsMonthToSelect,
          optionsYears = _state.optionsYears;
      var disabled = this.props.disabled;


      return React.createElement(
        Layout,
        { justify: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 247
          },
          __self: this
        },
        React.createElement(
          LayoutItem,
          { className: 'date__adjust-select-options', __source: {
              fileName: _jsxFileName,
              lineNumber: 248
            },
            __self: this
          },
          React.createElement(SelectView, {
            auto: true,
            placeholder: 'Mes',
            disabled: disabled,
            options: optionsMonthToSelect,
            onOptionSelected: function onOptionSelected(e, cb) {
              return _this2.onOptionSelected(e, 0, cb);
            },
            value: valueMonth,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 249
            },
            __self: this
          })
        ),
        React.createElement(
          LayoutItem,
          { className: 'date__adjust-select-options', __source: {
              fileName: _jsxFileName,
              lineNumber: 258
            },
            __self: this
          },
          React.createElement(SelectView, {
            medium: 6,
            auto: true,
            placeholder: 'Año',
            options: optionsYears,
            onOptionSelected: function onOptionSelected(e, cb) {
              return _this2.onOptionSelected(e, 1, cb);
            },
            value: valueYear,
            disabled: disabled,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 259
            },
            __self: this
          })
        )
      );
    }
  }], [{
    key: 'getDerivedStateFromProps',
    value: function getDerivedStateFromProps(props, state) {
      if (!props.disabled) {
        if (!props.value) {
          props.onDateChanged(state.selected);
        }
      } else {
        props.onDateChanged(null);
      }
      return null;
    }
  }]);

  return DatePickerForMonth;
}(Component), _class.propTypes = {
  /** */
  adjust: PropTypes.bool,
  /** */
  disabled: PropTypes.bool,
  /** */
  inlineLabel: PropTypes.bool,
  /** */
  label: PropTypes.string,
  /** */
  onDateChanged: PropTypes.func,
  /** */
  right: PropTypes.bool,
  /** */
  sizeInput: PropTypes.number,
  /** */
  sizeLabel: PropTypes.number,
  /** */
  value: PropTypes.any
}, _temp);


export default DatePickerForMonth;