var _jsxFileName = 'src/lib/elements/Checkboxes/CheckboxError.js',
    _this = this;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

var BASE_CLASSNAME = 'checkbox-error';

/** Handles the error message in a checkbox. */
var CheckboxError = function CheckboxError(_ref) {
  var error = _ref.error,
      touched = _ref.touched,
      disabled = _ref.disabled;

  return React.createElement(
    Fragment,
    {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 9
      },
      __self: _this
    },
    touched && error && !disabled && React.createElement(
      'div',
      { className: BASE_CLASSNAME + '__container', __source: {
          fileName: _jsxFileName,
          lineNumber: 13
        },
        __self: _this
      },
      React.createElement(
        'span',
        { className: BASE_CLASSNAME + '__message', __source: {
            fileName: _jsxFileName,
            lineNumber: 14
          },
          __self: _this
        },
        error
      )
    )
  );
};

CheckboxError.defaultProps = {
  touched: false,
  error: '',
  disabled: false
};

CheckboxError.propTypes = {
  /** Gives a disable stylish. */
  disabled: PropTypes.bool,
  /** Sets error in case it has one. */
  error: PropTypes.string,
  /** Sets touch if passed. */
  touched: PropTypes.bool
};

export default CheckboxError;