/**
 * Register new user into wiggot platform as broker
 * @param {object} data
 * @param {string} data.fullname
 * @param {string} data.email
 * @param {string} data.password
 */
export const Register = data => ({
  type: 'SUBMIT_REGISTER_REQUEST',
  payload: {
    request: {
      method: 'post',
      url: '/user',
      data
    }
  }
})

/**
 * Log in of any user
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.password
 */
export const FlatLogIn = data => ({
  type: 'SUBMIT_LOGIN_REQUEST',
  payload: {
    request: {
      url: '/user/login',
      method: 'post',
      data
    }
  }
})

/**
 * validate Token
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.password
 */
export const TokenStatus = Authorization => ({
  type: 'GET_TOKEN_STATUS',
  payload: {
    request: {
      url: '/user/validate_token',
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Get authorized status by user.
 * @param {string} Authorization - current user token
 */
export const GetTokenStatus = Authorization => ({
  type: 'GET_TOKEN_STATUS',
  payload: {
    request: {
      url: `/user/validate_token`,
      headers: {
        Authorization
      }
    }
  }
})

export const LogIn = data => {
  return async dispatch => {
    let responseJSON = {}
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/catalogs/amenities`
      )
      responseJSON = await response.json()
    } catch (error) {
      /**
       *
       */
    }

    dispatch({
      type: 'GET_AMENITIES_SUCCESS',
      payload: { data: responseJSON }
    })
    return dispatch(FlatLogIn(data))
  }
}

export const PusherLogIn = () => ({
  type: 'SUBMIT_PUSHER_LOGIN',
  payload: {
    request: {
      url: '/user/channel',
      method: 'get'
    }
  }
})

/**
 * Removes current token
 * @param {string} Authorization - current user token
 */
export const LogOut = Authorization => ({
  type: 'SUBMIT_LOGOUT_REQUEST',
  payload: {
    request: {
      url: '/user/logout',
      method: 'post',
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Sends email to get a token to reset passwords afterwards.
 * @param {object} data
 * @param {string} data.email
 */
export const ForgotPassword = data => ({
  type: 'FORGOT_PASSWORD',
  payload: {
    request: {
      data,
      method: 'post',
      url: '/user/forgot-password'
    }
  }
})

/**
 * Sets new password when user enter email sent on forgot password.
 * @param {object} data
 * @param {string} data.verification_code
 * @param {string} data.password
 */
export const ResetPassword = data => ({
  type: 'RESET_PASSWORD',
  payload: {
    request: {
      method: 'post',
      url: '/user/reset-password',
      data
    }
  }
})

/**
 * Resends an email to user with a new verification code
 * @param {string} email - current user email
 */
export const ResendVerificationMail = email => ({
  type: 'RESEND_VERIFICATION_MAIL',
  payload: {
    request: {
      method: 'post',
      url: '/user/resend-verification',
      data: { email }
    }
  }
})

/**
 * Sets the account as valid due to a code recived via email
 * @param {string} verificationCode - verification code
 */
export const VerifyAccount = (Authorization, verificationCode) => {
  const headers = Authorization ? { Authorization } : {}

  return {
    type: 'VERIFY_ACCOUNT',
    payload: {
      request: {
        method: 'get',
        url: `/user/verify/${verificationCode}`,
        headers
      }
    }
  }
}

/**
 * Request to change the current user password to a new one.
 * @param {string} Authorization - current user token
 * @param {Object} data
 * @param {string} data.password
 * @param {string} data.new_password
 */
export const ChangePassword = (Authorization, data) => ({
  type: 'SUBMIT_CHANGE_PASSWORD',
  payload: {
    request: {
      method: 'post',
      url: '/user/change-password',
      headers: {
        Authorization
      },
      data
    }
  }
})

/**
 * Get authorized platforms by user.
 * @param {string} Authorization - current user token
 */
export const GetAuthorizedPlatforms = Authorization => ({
  type: 'GET_AUTHORIZED_PLATFORMS',
  payload: {
    request: {
      url: `/portals/credentials`,
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Get available I24 adds for user.
 * @param {string} Authorization - current user token
 */
export const GetI24AvailableAds = Authorization => ({
  type: 'GET_I24_AVAILABLE_ADS',
  payload: {
    request: {
      url: '/portals/i24/lists_available',
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Get available M3 adds for user.
 * @param {string} Authorization - current user token
 */
export const GetM3AvailableAds = Authorization => ({
  type: 'GET_M3_AVAILABLE_ADS',
  payload: {
    request: {
      url: '/portals/mercado-libre/lists_available',
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Get available SegundaMano adds for user.
 * @param {string} Authorization - current user token
 */
export const GetSegundamanoAvailableAds = Authorization => ({
  type: 'GET_SEGUNDAMANO_AVAILABLE_ADS',
  payload: {
    request: {
      url: '/portals/segunda-mano/lists_available',
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Get exist account from El Informador
 * @param {string} Authorization - current user token
 */
export const GetElInformadorExistAccount = Authorization => ({
  type: 'GET_EL_INFORMADOR_EXIST_ACCOUNT',
  payload: {
    request: {
      url: '/portals/el-informador/exist-account',
      headers: {
        Authorization
      }
    }
  }
})

export const SetAuthorizedPlatform = (Authorization, platformId, data) => ({
  type: 'SET_AUTHORIZED_PLATFORM',
  payload: {
    request: {
      url: `/portals/${platformId}`,
      method: 'post',
      headers: {
        Authorization
      },
      data
    }
  }
})

export const DisablePortalSync = (Authorization, platformId) => ({
  type: 'DISABLE_PORTAL_SYNC',
  payload: {
    request: {
      url: `/portals/${platformId}`,
      method: 'delete',
      headers: {
        Authorization
      }
    }
  }
})

export const RequestM3Authorization = Authorization => ({
  type: 'REQUEST_M3_AUTHORIZATION',
  payload: {
    request: {
      url: `/portals/mercado-libre/login`,
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const RequestM3AuthorizationToken = (Authorization, code) => ({
  type: 'REQUEST_M3_AUTHORIZATION_TOKEN',
  payload: {
    request: {
      url: `/portals/mercado-libre/token`,
      method: 'post',
      headers: {
        Authorization
      },
      data: { code }
    }
  }
})

export const VerifySegundaManoAccount = code => ({
  type: 'VERIFY_SEGUNDAMANO_ACCOUNT',
  payload: {
    request: {
      url: `/portals/segunda-mano/verify/${code}`,
      method: 'get'
    }
  }
})

/**
 *
 * @param {*} Authorization
 */
export const DownloadRoles = Authorization => ({
  type: 'DOWNLOAD_ROLES',
  payload: {
    request: {
      url: '/user/roles',
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Used when you want to set roles and token in a custom action.
 * @param {Object} userInfo
 * @param {string} userInfo.token
 * @param {string[]} userInfo.roles
 */
export const SetUserAuthInfo = userInfo => ({
  type: 'SET_USER_INFO',
  payload: {
    data: userInfo
  }
})

export const ResetPlatformActivationStatus = () => ({
  type: 'RESET_PLATFORM_ACTIVATION_STATUS'
})

export const CommunicatePlatformActivation = status => ({
  type: 'COMMUNICATE_PLATFORM_ACTIVATION',
  sendThroughChannel: true,
  status
})

export const RefreshPlatformList = platformId => ({
  type: 'REFRESH_PLATFORM_CREDENTIALS',
  platformId
})

export const SetPendingPortal = portal => ({
  type: 'SET_PENDING_PORTAL',
  portal
})

export const SetPlatformCredentials = portal => ({
  type: 'SET_PLATFORM_CREDENTIALS',
  portal
})

export const SetToken = token => ({
  type: 'SET_TOKEN',
  token
})

export const SetAdminToken = token => ({
  type: 'SET_ADMIN_TOKEN',
  token
})

/**
 *
 * @param {*} Authorization
 */
export const GetAssociations = Authorization => ({
  type: 'GET_ASSOCIATIONS',
  payload: {
    request: {
      url: '/user/associations',
      headers: {
        Authorization
      }
    }
  }
})

export const GetNewEmailRequest = Authorization => ({
  type: 'GET_NEW_EMAIL_REQUEST',
  payload: {
    request: {
      url: '/user/new_email_requested',
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const ChangeEmailRequest = (Authorization, newEmail) => ({
  type: 'CHANGE_EMAIL_REQUEST',
  payload: {
    request: {
      url: '/user/change_email_request',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        new_email: newEmail
      }
    }
  }
})

export const SendPhoneVerificationCode = (Authorization, phoneNumber) => ({
  type: 'SEND_PHONE_VERIFICATION_CODE',
  payload: {
    request: {
      url: '/user/verify_phone_number',
      method: 'post',
      headers: {
        Authorization
      },
      data: {
        phone_number: phoneNumber
      }
    }
  }
})

export const getUserTheme = Authorization => ({
  type: 'GET_USER_THEME',
  payload: {
    request: {
      url: '/user/validate-association-user',
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const setRoleStamp = () => ({
  type: 'SET_ROLE_STAMP'
})

export const RequestAllpropertyUrlAuthorization = Authorization => ({
  type: 'REQUEST_ALLPROPERTY_AUTHORIZATION',
  payload: {
    request: {
      url: `/portals/allproperty/login`,
      method: 'get',
      headers: {
        Authorization
      }
    }
  }
})

export const SetIsRolDevelop = isActive => ({
  type: 'SET_IS_SELECTED_DEVELOP',
  payload: isActive
})

export const SetRolSelectedDevelop = value => ({
  type: 'SET_ROLE_SELECTED',
  payload: value
})

export const SetOnBoarding = () => ({
  type: 'SET_ONBOARDING',
  payload: true
})
