import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g clipPath="url(#clip0)">
      <path
        d="M8.32715 0H14.9888C14.9888 1.76677 14.2869 3.46118 13.0376 4.71048C11.7883 5.95977 10.0939 6.66162 8.32715 6.66162V0Z"
        fill="white"
      />
      <path
        d="M8.32715 8.32617H14.9888V14.9878C13.222 14.9878 11.5276 14.2859 10.2783 13.0366C9.029 11.7874 8.32715 10.0929 8.32715 8.32617Z"
        fill="white"
      />
      <path
        d="M3.33081 6.66162C5.17037 6.66162 6.66162 5.17037 6.66162 3.33081C6.66162 1.49125 5.17037 0 3.33081 0C1.49125 0 0 1.49125 0 3.33081C0 5.17037 1.49125 6.66162 3.33081 6.66162Z"
        fill="white"
      />
      <path
        d="M3.33081 14.9878C5.17037 14.9878 6.66162 13.4965 6.66162 11.657C6.66162 9.81743 5.17037 8.32617 3.33081 8.32617C1.49125 8.32617 0 9.81743 0 11.657C0 13.4965 1.49125 14.9878 3.33081 14.9878Z"
        fill="white"
      />
      <path
        d="M20.5215 14.6318V1.71491L23.7923 0.0195312V8.26662L26.457 4.67601H30.3007L26.5536 9.09265L31.0035 14.6318H26.87L23.7923 10.3017V14.6318H20.5215Z"
        fill="white"
      />
      <path
        d="M31.8359 14.6323V5.97215L34.9303 4.27344V6.01545C35.1145 5.74436 35.3303 5.49614 35.5731 5.27601C35.7979 5.08955 36.0466 4.93386 36.3125 4.81304C36.7607 4.5913 37.2517 4.46969 37.7515 4.45664C38.1691 4.46354 38.5851 4.51038 38.9938 4.59653L38.1445 7.71084C37.8686 7.5718 37.567 7.49129 37.2585 7.47435C36.8154 7.46436 36.3804 7.59496 36.0161 7.8474C35.4099 8.28374 35.1101 9.02651 35.1101 10.0757V14.6289L31.8359 14.6323Z"
        fill="white"
      />
      <path
        d="M49.5027 8.42322L42.9544 11.774C43.2101 12.0082 43.4957 12.2075 43.8037 12.3669C44.0506 12.4834 44.3202 12.5437 44.5931 12.5434C45.3015 12.5434 45.9454 12.156 46.525 11.381L48.6933 12.7133L47.884 13.6226C47.4054 14.0592 46.8466 14.3986 46.2385 14.6219C45.6062 14.866 44.9345 14.9924 44.2567 14.9949C43.5611 15.0117 42.8693 14.8869 42.2235 14.6281C41.5776 14.3693 40.9911 13.9819 40.4996 13.4894C40.002 13.0041 39.6098 12.4215 39.3473 11.778C39.0848 11.1344 38.9578 10.4437 38.974 9.74888C38.9587 9.04475 39.0859 8.34479 39.3481 7.6911C39.6103 7.03741 40.0019 6.44351 40.4996 5.9451C41.4691 4.98089 42.7721 4.42623 44.1392 4.39585C45.5062 4.36547 46.8326 4.86169 47.844 5.78188C48.192 6.08825 48.4918 6.44534 48.7333 6.84109C49.0285 7.34873 49.2857 7.87755 49.5027 8.42322ZM41.9351 9.79552L46.2285 7.59718C46.0279 7.29532 45.7508 7.05211 45.4254 6.89236C45.1001 6.73261 44.7381 6.66204 44.3766 6.68786C44.0459 6.69007 43.7191 6.75907 43.4158 6.89071C43.1124 7.02236 42.8388 7.21394 42.6113 7.45396C42.3701 7.6721 42.1764 7.93756 42.0423 8.23383C41.9082 8.5301 41.8366 8.85081 41.8319 9.17598C41.8413 9.38589 41.876 9.5939 41.9351 9.79552Z"
        fill="white"
      />
      <path
        d="M57.8394 5.66238V1.71537L61.0736 0V14.6322H57.9826V13.5297L57.6262 13.9494C57.2786 14.2639 56.869 14.5022 56.4238 14.6489C55.9246 14.833 55.397 14.9277 54.865 14.9287C54.255 14.9434 53.6498 14.8174 53.0964 14.5603C52.543 14.3033 52.0563 13.9221 51.674 13.4465C50.8436 12.4606 50.4294 11.1904 50.4316 9.63603C50.4339 8.08165 50.8524 6.83371 51.6874 5.8922C52.082 5.42271 52.5774 5.04829 53.1368 4.79685C53.6962 4.54541 54.3052 4.42342 54.9183 4.43997C55.1801 4.43983 55.4413 4.46663 55.6977 4.51991C55.964 4.57511 56.2257 4.65083 56.4804 4.7464C56.7142 4.83218 56.9377 4.9439 57.1466 5.07949C57.393 5.25454 57.6247 5.44949 57.8394 5.66238ZM55.8675 6.96473C55.577 6.95796 55.2888 7.01884 55.0258 7.14254C54.7628 7.26624 54.5321 7.44938 54.352 7.67751C53.9362 8.21269 53.7258 8.87916 53.7591 9.5561C53.7236 10.2507 53.9335 10.9358 54.352 11.4913C54.5431 11.7279 54.7869 11.9166 55.064 12.0421C55.341 12.1676 55.6436 12.2265 55.9475 12.2141C57.211 12.2141 57.8416 11.3903 57.8394 9.74262C57.8507 9.29716 57.8251 8.85152 57.7628 8.4103C57.7162 8.15373 57.6076 7.91246 57.4463 7.7075C57.2661 7.46173 57.0272 7.26499 56.7514 7.13525C56.4757 7.00551 56.1718 6.94688 55.8675 6.96473Z"
        fill="white"
      />
      <path
        d="M62.7695 14.6316V5.99812L66.0037 4.30273V14.6282L62.7695 14.6316Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="66" height="14.9886" fill="white" />
      </clipPath>
    </defs>
  </Fragment>,
  'Kredi',
  '0 0 66 15'
)
