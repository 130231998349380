import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { UnlockModal } from 'reduxActions/services'

import { Button, Checkbox } from 'rc'
import { Cross } from 'rc/Icons'

import { SendPersistKey } from 'reduxActions/general'
import { VARIANTS } from './constants'
import {
  // OldWiggotUserMCText,
  NewWiggotUserMCText,
  AdvancedTrialEnded,
  NoActivePlan,
  UpgradeToAdvanced,
  PlanUpgrade,
  NoReportsWarning,
  Blocked
} from './ModalTexts'
import { usePlans } from 'modules/freemium/hooks/usePlans'

const PlansMC = ({ HideModal, variant, primaryAction, availableUntil }) => {
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()
  const { activateTrialPlan } = usePlans()

  const isReportsModal = variant === VARIANTS.NO_REPORTS_WARNING.name
  const handleSendToStore = async () => {
    await dispatch(UnlockModal())
    await HideModal()
    window.location.replace(`${process.env.REACT_APP_NEW_APLICATION_URL}/shop`)
  }
  const {
    roles,
    token
    // trialUsed
  } = useSelector(
    ({ authReducer: { roles, token }, profileReducer: { trialUsed } }) => ({
      roles,
      token,
      trialUsed
    })
  )

  const hasPlan =
    roles?.PRIME || roles?.PRO || roles?.STARTER || roles?.ADVANCED

  const getPlanVariant = () => {
    if (variant) return variant
    // if (!hasPlan && !trialUsed) return VARIANTS.OLD_WIGGOT_USER.name
    if (!hasPlan) return VARIANTS.NO_ACTIVE_PLAN.name
    if (roles?.STARTER) return VARIANTS.UPGRADE_TO_ADVANCED.name
    if (roles?.ADVANCED && !variant) return VARIANTS.BLOCKED.name
    return variant
  }

  const planVariant = getPlanVariant()
  const isPlanToUpgrade = planVariant === VARIANTS.PLAN_UPGRADE.name
  const sendToStore =
    VARIANTS[planVariant]?.mainActionLabel === 'Ir a la tienda'
  const oldWiggotUser = planVariant === VARIANTS.OLD_WIGGOT_USER.name

  const handleModalText = () => {
    switch (planVariant) {
      // case VARIANTS.OLD_WIGGOT_USER.name:
      //   return OldWiggotUserMCText(handleSendToStore)
      case VARIANTS.NEW_WIGGOT_USER.name:
        return NewWiggotUserMCText(handleSendToStore)
      case VARIANTS.ADVANCED_TRIAL_ENDED.name:
        return AdvancedTrialEnded()
      case VARIANTS.NO_ACTIVE_PLAN.name:
        return NoActivePlan()
      case VARIANTS.PLAN_UPGRADE.name:
        return PlanUpgrade(availableUntil, handleSendToStore)
      case VARIANTS.UPGRADE_TO_ADVANCED.name:
        return UpgradeToAdvanced(handleSendToStore)
      case VARIANTS.NO_REPORTS_WARNING.name:
        return NoReportsWarning()
      case VARIANTS.BLOCKED.name:
        return Blocked()
      default:
        return ''
    }
  }

  const closeModal = () => {
    dispatch(UnlockModal())
    HideModal()
  }

  const saveChoice = () => {
    const payload = {
      key: planVariant,
      value: isPlanToUpgrade ? checked : true
    }
    dispatch(SendPersistKey(token, payload))
    return (
      primaryAction ||
      (oldWiggotUser && activateTrialPlan) ||
      (sendToStore ? handleSendToStore : closeModal)
    )
  }

  return (
    <ContainerStyled isReportsModal={isReportsModal}>
      <HeaderContainer>
        <CustomTitleText isReportsModal={isReportsModal}>
          {VARIANTS[planVariant]?.title}
        </CustomTitleText>
        {!sendToStore && <CrossIconStyled onClick={HideModal} />}
      </HeaderContainer>
      <BodyContainer isReportsModal={isReportsModal}>
        <img src={VARIANTS[planVariant]?.ilustration} alt="modal-ilustration" />
        {handleModalText()}
        {isPlanToUpgrade && (
          <span
            css={`
              display: flex;
              align-items: center;
            `}
          >
            {' '}
            <Checkbox
              onChange={({ target: { checked } = {} }) => setChecked(checked)}
              name="upgrade-to-advanced"
              value={checked}
              css={`
                margin-right: 5px;
              `}
            />{' '}
            He leído y acepto mis privilegios Avanzado
          </span>
        )}
      </BodyContainer>
      <FooterContainer>
        {VARIANTS[planVariant]?.secondaryActionLabel && (
          <Button
            size="large"
            onClick={closeModal}
            css={`
              background-color: #222222;
            `}
          >
            {VARIANTS[planVariant]?.secondaryActionLabel}
          </Button>
        )}
        {VARIANTS[planVariant]?.mainActionLabel && (
          <MainActionButton
            disabled={isPlanToUpgrade && !checked}
            size="large"
            onClick={() => saveChoice()()}
          >
            {VARIANTS[planVariant]?.mainActionLabel}
          </MainActionButton>
        )}
      </FooterContainer>
    </ContainerStyled>
  )
}

PlansMC.propTypes = {
  HideModal: PropTypes.func,
  variant: PropTypes.string,
  primaryAction: PropTypes.func,
  availableUntil: PropTypes.string
}

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ isReportsModal }) =>
    isReportsModal ? '#FFFFFF' : '#03070A'};
  width: ${({ theme }) => theme.remCalc('532')};
  max-width: 100%;
`
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.remCalc('12 24 4')};
  width: 100%;
  border-bottom: 1px solid #363946;
`
const CustomTitleText = styled.span`
  color: ${({ isReportsModal }) => (isReportsModal ? '#726E7D' : '#FFFFFF')};
  font-size: ${({ theme }) => theme.remCalc('16')};
  font-weight: 600;
  line-height: ${({ theme }) => theme.remCalc('24')};
`
const CrossIconStyled = styled(Cross)`
  ${({ theme }) => theme.box(36, 36)}
  fill: #FFF;
  cursor: pointer;
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('24')};
  gap: ${({ theme }) => theme.remCalc('24')};
  > img {
    width: ${({ theme }) => theme.remCalc('200')};
  }
  color: ${({ isReportsModal }) => (isReportsModal ? '#726E7D' : '#FFFFFF')};
`
const FooterContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.remCalc('12 24')};
  justify-content: center;
  gap: ${({ theme }) => theme.remCalc('8')};
  width: 100%;
  border-top: 1px solid #363946;
  > button {
    width: 100%;
    border-radius: 50px !important;
  }
`
const MainActionButton = styled(Button)`
  background-color: ${({ disabled }) => (disabled ? 'gray' : '#0038ff')};
  border-color: ${({ disabled }) => (disabled ? 'gray' : '#0038ff')};
  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'gray' : '#0038ff')};
    border-color: ${({ disabled }) => (disabled ? 'gray' : '#0038ff')};
  }
`

export default PlansMC
