var _jsxFileName = 'src/lib/generics/FormInputViews/FeaturedInputView.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '../Icons';
import { Layout, LayoutItem } from '../../elements/Layouts';
import { NumberInputView } from './';

/**
 * Used to display a correct input.
 */
var BASE_CLASSNAME = 'input-container';

var FeaturedInputView = function FeaturedInputView(_ref) {
  var _classNames, _classNames4;

  var id = _ref.id,
      className = _ref.className,
      value = _ref.value,
      type = _ref.type,
      placeholder = _ref.placeholder,
      autoCompleteOn = _ref.autoCompleteOn,
      onKeyDown = _ref.onKeyDown,
      _onChange = _ref.onChange,
      onPaste = _ref.onPaste,
      onFocus = _ref.onFocus,
      onBlur = _ref.onBlur,
      disabled = _ref.disabled,
      disabledTransparent = _ref.disabledTransparent,
      onKeyPress = _ref.onKeyPress,
      leftDripiconIcon = _ref.leftDripiconIcon,
      leftFontAwesomeIcon = _ref.leftFontAwesomeIcon,
      leftImageUrl = _ref.leftImageUrl,
      rightDripiconIcon = _ref.rightDripiconIcon,
      rightFontAwesomeIcon = _ref.rightFontAwesomeIcon,
      rightImageUrl = _ref.rightImageUrl,
      rightIconOnClick = _ref.rightIconOnClick,
      touched = _ref.touched,
      error = _ref.error,
      warning = _ref.warning,
      min = _ref.min,
      reserved = _ref.reserved,
      onClick = _ref.onClick,
      onInputMounted = _ref.onInputMounted,
      decimalDigits = _ref.decimalDigits,
      insideLabel = _ref.insideLabel,
      primary = _ref.primary,
      secondary = _ref.secondary,
      max = _ref.max,
      noCommas = _ref.noCommas,
      maxLength = _ref.maxLength,
      flat = _ref.flat,
      lowerized = _ref.lowerized;

  var leftIcon = leftDripiconIcon || leftFontAwesomeIcon || leftImageUrl ? true : false;
  var rightIcon = rightDripiconIcon || rightFontAwesomeIcon || rightImageUrl ? true : false;
  var inputClasses = classNames(className, BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--normal', !primary && !secondary && !flat), _defineProperty(_classNames, BASE_CLASSNAME + '--primary', primary), _defineProperty(_classNames, BASE_CLASSNAME + '--secondary', secondary), _defineProperty(_classNames, BASE_CLASSNAME + '--disabled', disabled), _defineProperty(_classNames, BASE_CLASSNAME + '--disabled-transparent', disabledTransparent), _defineProperty(_classNames, BASE_CLASSNAME + '--with-icon--left', leftIcon), _defineProperty(_classNames, BASE_CLASSNAME + '--with-icon--right', rightIcon), _defineProperty(_classNames, BASE_CLASSNAME + '--with-inside-label', insideLabel), _defineProperty(_classNames, BASE_CLASSNAME + '--error', touched && error), _defineProperty(_classNames, BASE_CLASSNAME + '--flat', flat), _classNames));

  var inputReservedInputClasses = classNames(BASE_CLASSNAME + '__reserved-input', _defineProperty({}, BASE_CLASSNAME + '__reserved-input--disabled', disabled));

  var reservedInputClasses = classNames(_defineProperty({}, BASE_CLASSNAME + '__reserved-input--placeholder', !value));

  var inputSelfClasses = classNames(BASE_CLASSNAME + '__input', (_classNames4 = {}, _defineProperty(_classNames4, BASE_CLASSNAME + '__input--with-inside-label', insideLabel), _defineProperty(_classNames4, BASE_CLASSNAME + '--disabled-color', disabled), _classNames4));

  var valueParse = value ? type == 'text' ? value : value.toString().replace(',', '') : value;

  return React.createElement(
    Fragment,
    {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 92
      },
      __self: _this
    },
    React.createElement(
      'div',
      {
        className: inputClasses,
        onClick: reserved && !disabled ? onClick : undefined,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 93
        },
        __self: _this
      },
      leftIcon && React.createElement(Icon, {
        dripiconIcon: leftDripiconIcon,
        fontAwesomeIcon: leftFontAwesomeIcon,
        imageUrl: leftImageUrl,
        className: BASE_CLASSNAME + '__icon ' + BASE_CLASSNAME + '__icon--left',
        transparent: true,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 98
        },
        __self: _this
      }),
      insideLabel && React.createElement(
        LayoutItem,
        { className: BASE_CLASSNAME + '__inside-label', __source: {
            fileName: _jsxFileName,
            lineNumber: 107
          },
          __self: _this
        },
        insideLabel
      ),
      reserved ? React.createElement(
        Layout,
        { className: inputReservedInputClasses, middle: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 112
          },
          __self: _this
        },
        React.createElement(
          LayoutItem,
          { className: reservedInputClasses, shrink: true, __source: {
              fileName: _jsxFileName,
              lineNumber: 113
            },
            __self: _this
          },
          value ? valueParse : placeholder
        )
      ) : type === 'number' ? React.createElement(NumberInputView, {
        className: inputSelfClasses,
        id: id,
        thas: value + '.-dhas',
        value: value !== undefined && value !== null && value !== '' ? Number(valueParse) : value,
        placeholder: placeholder,
        autoComplete: autoCompleteOn ? 'on' : 'off',
        onChange: _onChange,
        onFocus: onFocus,
        onBlur: onBlur,
        disabled: disabled || disabledTransparent,
        onKeyPress: onKeyPress,
        onKeyDown: onKeyDown,
        decimalDigits: decimalDigits,
        ref: onInputMounted,
        max: max,
        noCommas: noCommas,
        maxLength: maxLength,
        min: min,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 118
        },
        __self: _this
      }) : React.createElement('input', {
        className: inputSelfClasses,
        id: id,
        value: valueParse,
        type: type,
        placeholder: placeholder,
        autoComplete: autoCompleteOn ? 'on' : 'off',
        onChange: function onChange(event) {
          var _event$target$value = event.target.value,
              value = _event$target$value === undefined ? '' : _event$target$value;

          if (maxLength && value.length > maxLength) {
            return;
          }
          if (lowerized) {
            event.target.value = event.target.value.toLowerCase();
          }
          _onChange(event);
        },
        onFocus: onFocus,
        onBlur: onBlur,
        disabled: disabled || disabledTransparent,
        onKeyPress: onKeyPress,
        onKeyDown: onKeyDown,
        onPaste: onPaste,
        min: min,
        ref: onInputMounted,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 143
        },
        __self: _this
      }),
      rightIcon && React.createElement(Icon, {
        dripiconIcon: rightDripiconIcon,
        fontAwesomeIcon: rightFontAwesomeIcon,
        imageUrl: rightImageUrl,
        className: BASE_CLASSNAME + '__icon ' + BASE_CLASSNAME + '__icon--right',
        transparent: true,
        onClick: rightIconOnClick,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 173
        },
        __self: _this
      })
    ),
    touched && !disabled && (error && React.createElement(
      'span',
      { className: BASE_CLASSNAME + '__error-message', __source: {
          fileName: _jsxFileName,
          lineNumber: 187
        },
        __self: _this
      },
      error
    ) || warning && React.createElement(
      'span',
      {
        __source: {
          fileName: _jsxFileName,
          lineNumber: 189
        },
        __self: _this
      },
      warning
    ))
  );
};

FeaturedInputView.propTypes = {
  /** Enables input to make suggestions according to input */
  autoCompleteOn: PropTypes.bool,
  /** Class to be passed */
  className: PropTypes.string,
  /** */
  decimalDigits: PropTypes.number,
  /** Disables the input */
  disabled: PropTypes.bool,
  /** */
  disabledTransparent: PropTypes.bool,
  /** */
  error: PropTypes.string,
  /** */
  flat: PropTypes.bool,
  /** Used for testing purposes */
  id: PropTypes.string,
  /** */
  insideLabel: PropTypes.string,
  /** In case it is useful to add a dripiconIcon on left*/
  leftDripiconIcon: PropTypes.string,
  /** Added fontAwesome icon on left */
  leftFontAwesomeIcon: PropTypes.string,
  /** Added image url as icon  on left*/
  leftImageUrl: PropTypes.string,
  lowerized: PropTypes.bool,
  /** */
  max: PropTypes.number,
  /** */
  maxLength: PropTypes.number,
  /** */
  min: PropTypes.number,
  /** Name of the component */
  name: PropTypes.string,
  /** */
  noCommas: PropTypes.bool,
  /** Callback function to be called on onBlur event. */
  onBlur: PropTypes.func,
  /** Callback function to retrieve the current input value. */
  onChange: PropTypes.func,
  /** */
  onClick: PropTypes.func,
  /** Callback function to be called on onFocus event. */
  onFocus: PropTypes.func,
  /** */
  onInputMounted: PropTypes.func,
  /** */
  onKeyDown: PropTypes.func,
  /** Handle key press callback */
  onKeyPress: PropTypes.func,
  /** Handles on paste. */
  onPaste: PropTypes.func,
  /** Placeholder to set when no text entered */
  placeholder: PropTypes.string,
  /** */
  primary: PropTypes.bool,
  /** */
  reserved: PropTypes.bool,
  /** In case it is useful to add a dripiconIcon on right*/
  rightDripiconIcon: PropTypes.string,
  /** Added fontAwesome icon on right */
  rightFontAwesomeIcon: PropTypes.string,
  /** Support on click event on icon. */
  rightIconOnClick: PropTypes.func,
  /** Added image url as icon  on right*/
  rightImageUrl: PropTypes.string,
  /** */
  secondary: PropTypes.bool,
  /** */
  touched: PropTypes.bool,
  /** Input type, it could be: password, phone, email, password, etc.*/
  type: PropTypes.string,
  /** Current value set in Input. */
  value: PropTypes.any,
  /** */
  warning: PropTypes.string
};

FeaturedInputView.defaultProps = {
  className: '',
  name: '',
  value: '',
  type: 'text',
  placeholder: '',
  autoCompleteOn: false,
  onChange: function onChange() {},
  reserved: false
};

export default FeaturedInputView;