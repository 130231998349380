import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { ShowModal } from 'reduxActions/services'

import {
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  SubmitButton,
  withForm,
  Spinner
} from 'rc'
import { isEmail } from 'rc/helpers/stringHelpers'

import { BrokerAccountForm, WhyMyTaxIdMC } from 'modules/login/forms/SignUpForm'
import StepThree from './_StepThree'
import StepFour from './_StepFour'

const AgencyAccountForm = ({ formStep, setFormStep, showErrorNotification, cancelFunction, fc, isLoading }) => {
  const dispatch = useDispatch()
  const renderFormStep = () => {
    switch(formStep) {
      case 3:
        return <StepThree />
      case 4:
        return <StepFour isLoading={isLoading} />
      default:
        return <BrokerAccountForm />
    }
  }
  const handleNextStep = () => {
    const { values } = fc
    let minimumFields = []
    let missingFields = []
    const phoneRegex = new RegExp( /^\+?\d{1,3}\d{6,}$/,'i')

    switch(formStep){
      case 2:
        minimumFields = ["gender", "birthdate", "phone_number", "state", "city", "postal_code"]
        missingFields = minimumFields.filter(item => !values[item])
        if(missingFields.length > 0) return showErrorNotification('Ningun campo puede estar vacío.')
        if(!phoneRegex.test(values['phone_number'])) return showErrorNotification('Formato de teléfono incorrecto.')
        break
      case 3:
        minimumFields = ["agency_name", "agency_business_name", "agency_tax_id", "agency_email","agency_phone_number"]
        missingFields = minimumFields.filter(item => !values[item])
        if(missingFields.length > 0) return showErrorNotification('Ningun campo puede estar vacío.')
        if(!phoneRegex.test(values['agency_phone_number'])) return showErrorNotification('Formato de teléfono incorrecto.')
        if(!isEmail(values["agency_email"])) return showErrorNotification('Ingresa un email válido.')
        break
      default:
        return ''
    }    

    setFormStep(formStep + 1)
  }
  return (
    <Container>
      {renderFormStep()}
      {formStep === 3 &&
        <TextlikeUrl onClick={() => dispatch(ShowModal(WhyMyTaxIdMC))} css={`margin: ${({ theme }) => theme.remCalc('20 0')};`}>
          ¿Por qué solicitamos tu RFC y razón social?
        </TextlikeUrl>
      }
      
        <FooterContainer>
          {formStep === 4 && 
            <FormControlLabel
              css={`align-items: flex-start; margin: 1rem 0;`}
              disabled={isLoading}
              control={<Checkbox name="accept_terms" />}
              label={
                <Typography variant="caption4" fontWeight={500}>
                  Acepto los{' '}
                  <TermsLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.REACT_APP_TERMS_URL}
                  >
                    Términos y condiciones.
                  </TermsLink>{' '}
                  y{' '}
                  <Link
                    underline="always"
                    as={RouterLink}
                    target="_blank"
                    to="/legal/privacy"
                  >
                    políticas de privacidad
                  </Link>
                </Typography>
              }
              labelPlacement="end"
            />
          }
          <ButtonsContainer>
            <ButtonStyled
              size="large"
              variant="outlined"
              onClick={() => setFormStep(formStep - 1)}
              disabled={isLoading}
            >
              Atrás
            </ButtonStyled>
            {formStep === 4 ?
              <SubmitButtonStyled>
                {isLoading ? <Spinner css={`height: 40px;`} /> : "Crear cuenta (4/4)"}
              </SubmitButtonStyled> :
              <ButtonStyled full size="large" onClick={handleNextStep}>
                {`Siguiente (${formStep}/4)`}
              </ButtonStyled>
            }
          </ButtonsContainer>
          <TextlikeUrl onClick={() => isLoading ? null : cancelFunction()}>
            Cancelar
          </TextlikeUrl>
        </FooterContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.remCalc('32')};
  > button { width: 48%; }
`
const ButtonStyled = styled(Button)`
  border-radius: 50px !important;
`
const SubmitButtonStyled = styled(SubmitButton)`
  border-radius: 50px !important;
`
const TextlikeUrl = styled(Typography).attrs({
  variant: "subtitle4",
  color: "palette.primary.main",
  align: "center"
})`
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  &:hover { color: ${({ theme }) => theme.palette.primary.dark} }
`
const TermsLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
AgencyAccountForm.propTypes = {
  formStep: PropTypes.number,
  setFormStep: PropTypes.func,
  showErrorNotification: PropTypes.func,
  cancelFunction: PropTypes.func,
  fc: PropTypes.object
}

export default withForm(AgencyAccountForm)
