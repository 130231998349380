/* eslint-disable array-callback-return */
import { useEffect } from 'react'
import moment from 'moment'
import {
  PROPERTY_TYPES_SEARCHER,
  LABELS_BY_KEY,
  DAYS_WEEK,
  MONTHS_YEAR
} from 'utils/constants'
import { MEXICAN_STATES } from 'utils/location'
moment.locale('es')

/**
 * Retrieves an object with nested objects into an array
 * @param {object} obj - Object with nested objects
 * @returns {ObjectWithKeyID[]}  - All nested objects into an array
 * after pushing id inside of each object.
 * @function
 */
export const objectToArray = obj => {
  const arr = []

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (!obj[key].id) {
        obj[key].id = key
      }
      arr.push(obj[key])
    }
  }

  return arr
}

//TO BE REFACTORED
export const capitalizeFirstLetter = data => {
  const string = data.toLowerCase()
  return string.charAt(0).toUpperCase() + string.slice(1)
}

//TO BE REFACTORED
export const removeEmptyKeys = obj => {
  Object.keys(obj).forEach(key => {
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      (Object.prototype.hasOwnProperty.call(obj[key], 'length') &&
        obj[key].length === 0) ||
      (obj[key].constructor === Object && Object.keys(obj[key]).length === 0)
    ) {
      delete obj[key]
    } else if (
      obj[key] &&
      obj[key].constructor === Object &&
      Object.keys(obj[key]).length > 0
    ) {
      removeEmptyKeys(obj[key])
    }
  })
}

export const getPlatformLocation =
  process.env.REACT_APP_ENV === 'PROD'
    ? window.location.origin === 'https://wiggot.com'
      ? `${window.location.origin}/portal`
      : window.location.origin
    : window.location.origin

export const getPathPortal = process.env.REACT_APP_PATH_PORTAL || '/portal/'

/**
 * Gets the distance between two elements
 * @param {object} fromElement - DOM element
 * @param {object} element - DOM element
 * @returns {Distances} Different distances between two DOM elements
 * @function
 */
export const getAllOffsetFrom = (fromElement, element) => {
  let fromElementRect = fromElement.getBoundingClientRect()
  let elementRect = element.getBoundingClientRect()
  return {
    childBottomToParentTop: elementRect.bottom - fromElementRect.top,
    childBottomToParentBottom: fromElementRect.bottom - elementRect.bottom,
    childTopToParentBottom: elementRect.top - fromElementRect.bottom,
    childTopToParentTop: elementRect.top - fromElementRect.top
  }
}

//TO BE REFACTORED
/**
 *Gets the parameter value of a url
 * @param {string} name - Name of the parameter
 * @param {string} url - url to parse (optional)
 * @returns {string}
 * @function
 */
export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  var regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const camelize = value => {
  if (!value) {
    return ''
  }
  if (!isNaN(value)) {
    return value
  }

  value = value.replace(/[-_\s]+(.)?/g, (match, ch) => {
    return ch ? ch.toUpperCase() : ''
  })
  return value.substr(0, 1).toLowerCase() + value.substr(1)
}

export const camelToSnakeCase = (data, depth) => {
  if (data && typeof data === 'object') {
    if (typeof depth === 'undefined') {
      depth = 1
    }
    return processKeys(data, snakelize, depth)
  }
  return snakelize(data)
}

export const snakelize = value => {
  const capitalLetterRegex = /(?=[A-Z])/
  let separator = '_'
  return value.split(capitalLetterRegex).join(separator).toLowerCase()
}

export const snakeToCamelCase = (data, depth) => {
  if (data && typeof data === 'object') {
    if (typeof depth === 'undefined') {
      depth = 1
    }
    return processKeys(data, camelize, depth)
  }
  return camelize(data)
}

export const processKeys = (data, processer, depth) => {
  if (Array.isArray(data)) {
    return data.map(item => {
      return processKeys(item, processer, 1)
    })
  }
  if (depth === 0 || typeof data !== 'object') {
    return data
  }
  let result = {}
  let keys = data ? Object.keys(data) : []

  for (let index = 0; index < keys.length; index++) {
    const processedString = processer(keys[index])
    result[processedString] = processKeys(
      data[keys[index]],
      processer,
      depth - 1
    )
  }
  return result
}

export const hasValidValue = (key, value) => {
  return (
    Object.prototype.hasOwnProperty.call(value, key) &&
    value[key] !== undefined &&
    value[key] !== '' &&
    value[key] !== null &&
    (Array.isArray(value[key]) ? value[key].length > 0 : true)
  )
}

export const propertyNameGenerator = propertyProps => {
  const {
    property_type,
    neighborhood,
    sale_price,
    presale_price,
    lease_price
  } = propertyProps
  const operations = {
    lease: { name: 'renta', price: lease_price },
    presale: { name: 'preventa', price: presale_price },
    sale: { name: 'venta', price: sale_price }
  }

  const activeOperations = Object.keys(operations).filter(
    key => operations[key].price !== null
  )

  return `
  ${property_type ? PROPERTY_TYPES_SEARCHER[property_type].name : ''}
  ${
    activeOperations.length > 0
      ? ` en ${activeOperations
          .map(key => operations[key].name)
          .join(' y ')
          .toLowerCase()}`
      : ''
  }
  ${neighborhood ? ` en ${neighborhood}` : ''}
  `
}

/**
 * Replace Object keys with a value given
 * @param {object} obj
 * @param {string[]} keys
 * @param {any} newValue
 */
export const replaceKeys = (obj, keys, newValue) => {
  keys.forEach(key => {
    obj[key] = newValue
  })
}

export const generateLabelsWithKeys = (values, customLabelsByKey = {}) => {
  let labels = []

  const allLabelsByKey = { ...LABELS_BY_KEY, ...customLabelsByKey }

  Object.keys(values)?.forEach(valueKey => {
    let value = values[valueKey]
    let otherInfo
    if (value == null) {
      return
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      const { value: realValue, ...other } = value
      otherInfo = other
      value = realValue
    }

    if (!value) {
      return
    }
    let newLabel = ''
    switch (valueKey) {
      case 'bathrooms':
      case 'half_bathrooms':
      case 'floors':
      case 'parking_lots':
      case 'bedrooms':
        newLabel = `${value} ${
          value === 1
            ? allLabelsByKey[valueKey].singular
            : allLabelsByKey[valueKey].plural
        }`.toLowerCase()

        break
      case 'ground_shape':
      case 'ground_relief':
      case 'operation_type':
        newLabel = `${allLabelsByKey[value]}`
        break
      case 'width':
        newLabel = `${value} frente`
        break
      case 'height':
        newLabel = `${value} fondo`
        break
      case 'has_service_room':
        newLabel = value ? `Cuarto de servicio` : ''
        break
      case 'inside_condo':
        newLabel = value ? `En condominio` : ''
        break
      case 'state':
      case 'q':
        newLabel = value
        break
      default:
        break
    }

    if (newLabel) {
      labels.push({
        label: newLabel,
        key: valueKey,
        currentValue: value,
        ...otherInfo
      })
      return
    }

    switch (valueKey) {
      case 'antiquity_type':
      case 'use_type':
      case 'property_type':
      case 'types':
      case 'operations':
        value?.forEach((item, subkey) => {
          if (item.value) {
            const { value: realValue, ...other } = item
            item = realValue
            otherInfo = other
          }

          newLabel = `${allLabelsByKey[item] || ''}`

          if (newLabel) {
            labels.push({
              label: newLabel,
              key: valueKey,
              subkey,
              currentValue: [item],
              ...otherInfo
            })
          }
        })
        break
      case 'locations':
        value?.forEach((location, subkey) => {
          if (location.value) {
            const { value: locationValue, ...other } = location
            location = locationValue
            otherInfo = other
          }

          let locationName = location.name || ''
          locationName = locationName ? locationName.split(',')[0] : ''
          newLabel = locationName || ''
          labels.push({
            label: newLabel,
            key: valueKey,
            subkey,
            currentValue: location,
            ...otherInfo
          })
        })

        break
      case 'amenities':
        value?.forEach((amenity, subkey) => {
          let label = ''

          if (typeof amenity === 'object' && amenity.value) {
            const { value: realAmenity, ...other } = amenity
            label = allLabelsByKey[realAmenity]
            amenity = realAmenity
            otherInfo = other
          } else {
            label = allLabelsByKey[amenity]
          }
          labels.push({
            label,
            key: valueKey,
            subkey,
            currentValue: [amenity],
            ...otherInfo
          })
        })
        break
      default:
        break
    }
  })

  Array.from(
    [
      ['min_price', 'max_price'],
      ['min_construction_size', 'max_construction_size'],
      ['min_ground_size', 'max_ground_size'],
      ['min_maintenance', 'max_maintenance']
    ],
    similarArray => {
      const key1 = similarArray[0]
      const key2 = similarArray[1]
      let value1 = ''
      let value2 = ''
      let customLabel = ''
      value1 =
        typeof values[key1] === 'object' && values[key1] != null
          ? values[key1].value
          : values[key1]
          ? values[key1]
          : ''
      value2 =
        typeof values[key2] === 'object' && values[key2] != null
          ? values[key2].value
          : values[key2]
          ? values[key2]
          : ''
      let otherInfo = {}
      if (typeof values[key1] === 'object' && values[key1] != null) {
        const { value, ...other } = values[key1]
        otherInfo = { ...other }
      }

      if (typeof values[key2] === 'object' && values[key2] != null) {
        const { value, ...other } = values[key2]
        otherInfo = { ...otherInfo, ...other }
      }

      switch (key1) {
        case 'min_construction_size':
          customLabel = 'm² construcción'
          break
        case 'min_ground_size':
          customLabel = 'm² terreno'
          break
        case 'min_maintenance':
        case 'min_price':
          value1 =
            value1 &&
            Number(value1).currencyFormat(
              0,
              undefined,
              undefined,
              undefined,
              false
            )
          value2 =
            value2 &&
            Number(value2).currencyFormat(
              0,
              undefined,
              undefined,
              undefined,
              false
            )
          customLabel = `MXN${
            key1 === 'min_maintenance' ? ' mantenimiento' : ' presupuesto'
          }`
          break

        default:
          break
      }
      if (!value1 && !value2) {
        return
      }
      if (value1 && value2) {
        labels.push({
          label: `${value1} - ${value2} ${customLabel}`,
          key: [key1, key2],
          currentValue: [value1, value2],
          ...otherInfo
        })
      } else {
        labels.push({
          label: `${value1 ? 'Desde' : 'Hasta'} ${
            value1 || value2
          } ${customLabel}`,
          key: `${value1 ? key1 : key2}`,
          currentValue: value1 || value2,
          ...otherInfo
        })
      }
    }
  )

  return labels
}

export const HandleRecordDetailRequest = response => {
  const {
    payload: { data }
  } = response

  let validate_time = null,
    create_at = null

  let validate_time_date = 0,
    create_at_date = 0

  if (data.validate_time) {
    validate_time_date = new Date(data.validate_time)

    const toDay = validate_time_date.getDate()
    const toMonth = validate_time_date.getMonth()
    const toHours = validate_time_date.getHours()
    const toMinutes = validate_time_date.getMinutes()

    validate_time = {
      day: toDay < 10 ? `0${toDay}` : toDay,
      month: toMonth < 10 ? `0${toMonth}` : toMonth,
      year: validate_time_date.getFullYear(),
      hours: toHours < 10 ? `0${toHours}` : toHours,
      minutes: toMinutes < 10 ? `0${toMinutes}` : toMinutes,
      monthName: validate_time_date.toLocaleString('default', {
        month: 'long'
      })
    }
  }

  if (data.create_at) {
    create_at_date = new Date(data.create_at)
    const fromDay = create_at_date.getDate()
    const fromMonth = create_at_date.getMonth()
    const fromHours = create_at_date.getHours()
    const fromMinutes = create_at_date.getMinutes()

    create_at = {
      day: fromDay < 10 ? `0${fromDay}` : fromDay,
      month: fromMonth < 10 ? `0${fromMonth}` : fromMonth,
      year: create_at_date.getFullYear(),
      hours: fromHours < 10 ? `0${fromHours}` : fromHours,
      minutes: fromMinutes < 10 ? `0${fromMinutes}` : fromMinutes,
      monthName: create_at_date.toLocaleString('default', {
        month: 'long'
      })
    }
  }

  const Today = new Date()

  return {
    ...data,
    create_at,
    validate_time,
    remaining_time:
      validate_time_date > 0 && create_at_date > 0
        ? Math.floor(Math.abs(Today - validate_time_date) / 8.64e7)
        : 0
  }
}

export const formatPrice = (price = null, region = 'en-US', digits = 0) => {
  return Number(price).toLocaleString(region, {
    minimumFractionDigits: digits
  })
}

export const splitPrice = price => {
  const getPriceData = { price: '0', decimal: '00' }
  const arrayPrice = price.split('.')

  getPriceData.price = formatPrice(arrayPrice[0])
  getPriceData.decimal = arrayPrice[1]

  return getPriceData
}

//TO BE REFACTORED
export const shortenToInitials = name => {
  if (name)
    return name
      .split(' ')
      .map((item, index) => {
        if (index >= 2) {
          return ''
        }
        return item.charAt(0)
      })
      .join('')
  return ''
}

export const buildTitle = ({
  propertyType,
  neighborhood,
  saleActive,
  preSaleActive,
  leaseActive
}) => {
  const operations = []
  if (preSaleActive) operations.push('Preventa')
  if (saleActive) operations.push('Venta')
  if (leaseActive) operations.push('Renta')

  return `${PROPERTY_TYPES_SEARCHER[propertyType].name} en ${operations.join(
    ' y '
  )} en ${neighborhood}`
}

export const generateUrlPublicDatasheet = ({ ...props }) => {
  return `${process.env.REACT_APP_WIGGOT_SSR}/property/${buildTitle({
    ...props
  })
    .replace(/[\s/]/g, '-')
    .replace(/[^\w\d\s-]/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLocaleLowerCase()}/${props.propertyId}`
}

/*  
 Return format score value
 @param { int } score
*/
export const getScore = (score = 0) => {
  if (typeof score !== 'number') return `0%`
  return `${score}%`
}

export const roundToDecimal = n =>
  n + parseInt((parseInt(n.toString().substr(-1)) - 10).toString().substr(-1))

export const evaluateIsEqual = (special_value, value, min = '0', max = '1') => {
  if (
    !Array.isArray(special_value) &&
    !Array.isArray(value) &&
    !isObject(special_value) &&
    !isObject(value)
  ) {
    /*
     * special_value & value are not an array, the validation is recommendable
     * for comparations with numeric values
     */
    return special_value === value
  }

  // special_value is here only an array, with 2 params(min, max), and It's a range validation
  return special_value[min] <= value && value <= special_value[max]
}

export const isLessOrEqual = (special_value, value, withEqual = true) => {
  if (withEqual) return special_value <= value

  return special_value < value
}

export const isGreaterOrEqual = (special_value, value, withEqual) => {
  if (withEqual) return special_value >= value

  return special_value > value
}

export const getAbsoluteN = (special_value, value, prop) => {
  /*
   * special_value & value are not an array, the validation is recommendable
   * for subtract 2 numeric values such as bathrooms, bedrooms, etc
   */
  if (!Array.isArray(special_value) && !Array.isArray(value)) {
    return Math.abs(special_value - value)
  }

  /* Only value is an array, then value is the principal value, and It's
   * substract with special_value
   */
  if (Array.isArray(value)) {
    return Math.abs(value[prop] - special_value)
  }

  /* Only special_value is an array, then special_value is the principal value, and It's
   * substract with value
   */
  return Math.abs(special_value[prop] - value)
}

export const setCurrencyPrice = (currency, price) => {
  if (currency !== 'USD') return price

  if (Array.isArray(price)) {
    return price.map(p => p * 20)
  }

  return price * 20
}

export const isObject = obj => {
  return obj === Object(obj)
}

/**
 *
 * @param {string} fileName
 * @returns imagen-ampliada-del-techo-de-...jpg from imagen-ampliada-del-techo-de-todas-las-casas.jpg
 */
export const getShortFileName = fileName => {
  const lengthToShow = 35
  let shortName = fileName || ''

  if (shortName.length > lengthToShow) {
    shortName = `${shortName.substring(0, 29)}..${shortName.substring(
      shortName.length - 4
    )}`
  }

  return shortName
}

export const removeEmptyArrayValuesFromObject = data => {
  const newData = {}

  for (const key in data) {
    const value = data[key]

    if (Array.isArray(value)) {
      const arrayValues = value.filter(item => item)

      if (arrayValues.length > 0) {
        newData[key] = arrayValues
      }
    } else {
      newData[key] = value
    }
  }

  return newData
}

export const useOutsideClick = (ref, cb) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, cb])
}

export const FormatPriceRange = (minpr, maxpr) => {
  const minPrice = Number(minpr).toLocaleString('en-US', {
    minimumFractionDigits: 0
  })

  const maxPrice = Number(maxpr).toLocaleString('en-US', {
    minimumFractionDigits: 0
  })

  return minPrice && maxPrice
    ? `$${minPrice} - $${maxPrice}`
    : minPrice
    ? minPrice
    : maxPrice
    ? maxPrice
    : '-'
}

export const FormatM2Range = (minpr, maxpr) => {
  const minPrice = Number(minpr).toLocaleString('en-US', {
    minimumFractionDigits: 0
  })

  const maxPrice = Number(maxpr).toLocaleString('en-US', {
    minimumFractionDigits: 0
  })

  const min = minPrice && minpr !== null
  const max = maxPrice && maxpr !== null

  return min && max
    ? `Min. ${minPrice} m2 - Max. ${maxPrice} m2`
    : min
    ? `Min. ${minPrice} m2`
    : max
    ? `Max. ${maxPrice} m2`
    : '-'
}

export const getJSONSpecialPermission = permission => {
  let s_result = {}
  for (let index = 0; index < permission.length; index++) {
    const element = permission[index]
    s_result = Object.assign(s_result, element)
  }
  return s_result
}

export const checkIfKeyExist = (object, keyName) => {
  let keyExist = Object.keys(object).some(key => key === keyName)
  return keyExist
}

export const pathnameToSnake = pathname => {
  const name = pathname.replace('/', '')
  const snakeName = camelToSnakeCase(name)

  return snakeName.toUpperCase()
}

const getPromotionPlanByType = (typePromotion, planData) => {
  let promotionPlan = null

  if (planData && planData.discount_by_validations) {
    promotionPlan = planData.discount_by_validations.find(
      item => item.validation === typePromotion
    )
  }

  return promotionPlan
}

export const getPromotionPlanByCreditCard = (
  paymentMethod,
  planData,
  isUpgrade
) => {
  return paymentMethod === 'card' && !isUpgrade
    ? getPromotionPlanByType('DIRECT_CREDIT_PAYMENT', planData)
    : null
}

/**
 *
 * @param {string} portal I24
 * @param {string} actualAdType SIMPLES
 * @param {array} plans { id: SIMPLE }
 * @returns the alternative ad type: SIMPLES -> SIMPLE or DESTACADOS -> DESTACADO
 */
export const getAlternativeAdType = (portal, actualAdType, plans) => {
  let alternative = actualAdType

  const match = plans && plans.find(item => item.id === actualAdType)

  if (!match && actualAdType && portal === 'I24') {
    const alternativeMatch =
      plans &&
      plans.find(
        item =>
          (item.id.includes(actualAdType) || actualAdType.includes(item.id)) &&
          Math.abs(item.id.length - actualAdType.length) === 1
      )

    if (alternativeMatch) {
      alternative = alternativeMatch.id
    }
  }

  return alternative
}

export const truncateLabel = (str, length = 25) => {
  if (str && str.length > 25) {
    const splitStr = str.split('', length)
    const truncStr = splitStr.join('')
    return `${truncStr}...`
  }
  return str
}

export const createPagination = (pages, page) => {
  let list = [{ label: 'chevron-left', value: page - 1, active: false }]
  let active
  let pageCutLow = page - 1
  let pageCutHigh = page + 1

  if (pages < 6) {
    for (let p = 1; p <= pages; p++) {
      active = page === p ? true : false
      list.push({ label: p, value: p - 1, active: active })
    }
  } else {
    if (page > 2) {
      list.push({ label: '1', value: 0, active: false })
      if (page > 3) list.push({ label: '...', value: page - 3, active: false })
    }

    if (page === 1) pageCutHigh += 2
    else if (page === 2) pageCutHigh += 1

    if (page === pages) pageCutLow -= 2
    else if (page === pages - 1) pageCutLow -= 1

    for (let p = pageCutLow; p <= pageCutHigh; p++) {
      if (p === 0) p += 1
      if (p > pages) continue

      active = page === p ? true : false
      list.push({ label: p, value: p - 1, active: active })
    }

    if (page < pages - 1) {
      if (page < pages - 2)
        list.push({ label: '...', value: page + 2, active: false })
      list.push({ label: pages, value: pages - 1, active: false })
    }
  }

  list.push({ label: 'chevron-right', value: page + 1, active: false })

  return list
}

export const generateLocation = (street, neighborhood, city, state) => {
  if (street && neighborhood && city && state) {
    return `${street}, ${neighborhood}, ${city}, ${state}.`
  }
  if (street && neighborhood && city) {
    return `${street}, ${neighborhood}, ${city}.`
  }
  if (street && neighborhood && city) {
    return `${street}, ${neighborhood}, ${city}.`
  }
  if (street && neighborhood) {
    return `${street}, ${neighborhood}.`
  }

  if (street) {
    return `${street}.`
  }

  return null
}

export const fromDateAgo = created_at => {
  const createdDate = moment(created_at, 'YYYY-MM-DD HH:mm:ss ZZ')
  let created =
    created_at && createdDate !== 'Invalid Date'
      ? `Hace ${createdDate.fromNow(true)}`
      : ''
  return created
}

/**
 *
 * @param {string} messageDt : 8/12/1994
 * @returns jueves 8, jueves 8 de diciembre
 */
export const getDayForMessagesChat = messageDt => {
  const dtMessage = new Date(messageDt)
  const dtNow = new Date()

  let validate = `${DAYS_WEEK[dtMessage.getDay()]} ${dtMessage.getDate()}`

  if (dtMessage.getMonth() !== dtNow.getMonth()) {
    validate = `${validate} de ${MONTHS_YEAR[dtMessage.getMonth()]}`
  }

  if (dtMessage.getFullYear() !== dtNow.getFullYear()) {
    validate = `${validate} de ${dtMessage.getFullYear()}`
  }

  return validate
}

export const timeFormater = new Intl.DateTimeFormat('es-MX', {
  hour: 'numeric',
  minute: 'numeric'
})

export const dateFormater = new Intl.DateTimeFormat('es-MX', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
})

export const formatDate = date => {
  moment.locale('es')
  return moment(date, 'YYYY-MM-DD HH:mm:ss ZZ').format('DD MMMM YYYY')
}

export const getStates = (search = '', resolve) => {
  const normalizeStr = str =>
    str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

  const normalizedSearch = search && normalizeStr(search)

  const results = MEXICAN_STATES.filter(state => {
    return normalizeStr(state.name).includes(normalizedSearch)
  })
  return resolve(results)
}

export const normalizeString = value => {
  return value?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')
}

export const slugify = value => {
  return normalizeString(value)
    ?.toLowerCase()
    ?.replace(/ /g, '-')
    ?.replace(/[^\w-]+/g, '')
}
