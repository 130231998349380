var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp2,
    _jsxFileName = 'src/lib/elements/FormInputs/thirdParty/StaticSelect.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import InputLabelContainer from '../InputLabelContainer';

import { StaticSelect as SelectComponent } from '../../Selects/thirdParty';
import { inputProp, inputMetaProp } from '../_types';

var StaticSelect = (_temp2 = _class = function (_PureComponent) {
  _inherits(StaticSelect, _PureComponent);

  function StaticSelect() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, StaticSelect);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = StaticSelect.__proto__ || Object.getPrototypeOf(StaticSelect)).call.apply(_ref, [this].concat(args))), _this), _this.getOptionLabel = function (item) {
      var getOptionLabel = _this.props.getOptionLabel;

      return getOptionLabel ? getOptionLabel(item) : item['name'];
    }, _this.getOptionValue = function (item) {
      var getOptionValue = _this.props.getOptionValue;

      return getOptionValue ? getOptionValue(item) : item['id'];
    }, _this.onChangeRF = function (item) {
      _this.props.input.onChange(item ? item['id'] : null);
      _this.props.onChangeCb(item);
    }, _this.valueResolver = function (value) {
      var options = _this.props.options;

      var valueResolved = value !== '' && value !== undefined && value !== null ? options.find(function (item) {
        return item['id'] === value;
      }) : null;

      return valueResolved;
    }, _this.inputProp = function () {
      var _this$props = _this.props,
          componentType = _this$props.componentType,
          input = _this$props.input,
          meta = _this$props.meta;

      switch (componentType) {
        case 'RF':
          return {
            error: meta.error,
            onBlur: function onBlur() {
              return input.onBlur();
            },
            onChange: _this.onChangeRF,
            onFocus: function onFocus() {
              return input.onFocus();
            },
            touched: meta.touched,
            value: _this.valueResolver(input.value)
          };
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(StaticSelect, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          disabled = _props.disabled,
          initialValue = _props.initialValue,
          isRequired = _props.isRequired,
          isSearchable = _props.isSearchable,
          label = _props.label,
          labelProps = _props.labelProps,
          options = _props.options,
          placeholder = _props.placeholder,
          styles = _props.styles;

      return React.createElement(
        InputLabelContainer,
        Object.assign({
          isRequired: isRequired,
          label: label
        }, labelProps, {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 103
          },
          __self: this
        }),
        React.createElement(SelectComponent, Object.assign({
          disabled: disabled,
          getOptionLabel: this.getOptionLabel,
          getOptionValue: this.getOptionValue,
          initialValue: initialValue,
          isSearchable: isSearchable,
          options: options,
          placeholder: placeholder,
          styles: styles
        }, this.inputProp(), {
          __source: {
            fileName: _jsxFileName,
            lineNumber: 108
          },
          __self: this
        }))
      );
    }
  }]);

  return StaticSelect;
}(PureComponent), _class.propTypes = {
  /** */
  options: PropTypes.array.isRequired,
  /** */
  componentType: PropTypes.string,
  /** */
  disabled: PropTypes.bool,
  /** */
  getOptionLabel: PropTypes.func,
  /** */
  getOptionValue: PropTypes.func,
  /** */
  initialValue: PropTypes.any,
  /** */
  input: inputProp,
  /** */
  isRequired: PropTypes.bool,
  /** */
  isSearchable: PropTypes.bool,
  /** */
  label: PropTypes.string,
  /** */
  labelProps: InputLabelContainer.propTypes,
  /** */
  meta: inputMetaProp,
  /** */
  onChangeCb: PropTypes.func,
  /** */
  placeholder: PropTypes.string,
  styles: PropTypes.object
}, _class.defaultProps = {
  placeholder: 'Seleccionar',
  isSearchable: false,
  disabled: false,
  label: '',
  onChangeCb: function onChangeCb() {
    return null;
  }
}, _temp2);


export default StaticSelect;