import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Badge = ({ color, children, ...props }) => {
  return <BadgeStyled {...props} color={color}>{children}</BadgeStyled>
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string
}

const BadgeStyled = styled.div`
  background-color: ${({ theme, color = 'blue' }) =>
    theme.palette.colors[color]};
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 20px;
  width: fit-content;
`

export default Badge
