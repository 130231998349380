import React, { useState, useContext, useRef, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css, ThemeContext } from 'styled-components'
import { useHistory } from 'react-router-dom'
import RouterNavLink from 'rc/RouterNavLink'
import { Thunderbolt } from 'rc/Icons'
import Grid from 'rc/Grid'
import Typography from 'rc/Typography'
import MenuButton from './MenuButton'
import { EventRx, EVENTS } from 'utils/event'

const NavigationSideBar = ({ options, menuHeaderCustomized }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const { palette } = useContext(ThemeContext)

  const menuContainer = useRef()
  const [isMenuOpened, setToggleMenu] = useState(false)
  const [itemHovered, setItemHovered] = useState({
    category: '',
    id: '',
    top: 0
  })

  const history = useHistory()
  const leftMenuWidth = 72
  const categories = Object.keys(options) || []

  useEffect(() => {
    EventRx.subscribe(this, EVENTS.SIDE_MENU_OPENED, ({ data } = {}) =>
      setToggleMenu(data)
    )
    return () => {
      EventRx.unsubscribe(this, EVENTS.SIDE_MENU_OPENED)
    }
  }, [])

  const toggleMenu = value => {
    if (value !== undefined) {
      if (isMenuOpened) {
        setSelectedOption(null)
      }
      setToggleMenu(current => !current)
    } else {
      if (!value) {
        setSelectedOption(null)
      }
      setToggleMenu(value)
    }
  }

  const colors = {
    subMenuForeground: palette.primary.main,
    optionColors: {
      foreground: palette.primary.main,
      background: palette.secondary.main,
      hoverForeground: palette.secondary.main,
      hoverBackground: palette.primary.main,
      activeBackground: palette.secondary.main,
      hoverPrimeForeground: palette.prime.yellow,
      hoverProForeground: '#40CBA6'
    },
    subOptionColor: {
      foreground: palette.primary.main,
      background: palette.secondary.main,
      hoverForeground: palette.secondary.main,
      hoverBackground: palette.primary.main,
      activeForeground: palette.secondary.main,
      activeBackground: palette.primary.main
    }
  }

  const changeHoveredItem = (e, keyOption, category, elementId) => {
    const offsetTop = elementId
      ? document.getElementById(elementId).getBoundingClientRect().top
      : null

    if (offsetTop)
      setItemHovered({
        category,
        id: keyOption,
        top: offsetTop
      })
    else setItemHovered({ category: '', id: '', top: 0 })
  }

  useEffect(() => {
    const _ref = menuContainer

    _ref.current?.addEventListener('scroll', changeHoveredItem)

    return () => {
      _ref.current?.removeEventListener('scroll', changeHoveredItem)
    }
  }, [menuContainer])

  const renderOptions = (keyOption, index, category) => {
    const {
      name,
      icon: Icon,
      id,
      show,
      subOptions = {},
      to,
      onClick,
      notification,
      newFeatures,
      extraAction,
      freemium,
      freemiumPro
    } = options[category][keyOption]
    const hasOptions =
      Object.keys(subOptions).length > 0 &&
      Object.keys(subOptions).filter(key => {
        const { show } = subOptions[key]

        const isSubOptionShown =
          show === null
            ? false
            : show && typeof show === 'function'
            ? show({ isMenuOpened })
            : show
        return isSubOptionShown
      }).length > 0
    const isShown =
      show === null
        ? false
        : show && typeof show === 'function'
        ? show({ isMenuOpened })
        : show

    const optionId = `navigation-side-bar__option-${keyOption}`
    const optionProps = {
      colors: colors['optionColors'],
      key: id || index,
      id: optionId,
      onMouseEnter: e => changeHoveredItem(e, keyOption, category, optionId),
      onMouseLeave: () => changeHoveredItem(),
      isSelected: keyOption === selectedOption,
      isMenuOpened,
      isFreemiumLocked: freemium,
      isFreemiumProLocked: freemiumPro,
      onClick: () => {
        if (isMenuOpened && hasOptions) {
          setSelectedOption(keyOption)
        } else if (to) history.push(to)
        else if (onClick) onClick()

        if (isMenuOpened && !hasOptions) {
          toggleMenu(false)
        }
      }
    }
    const Option = (
      <>
        <IconContainerStyled
          onClick={extraAction}
          item
          flat
          xsShrink
          container
          center
          inline
          leftMenuWidth={leftMenuWidth}
          isFreemiumLocked={freemium}
          isFreemiumProLocked={freemiumPro}
          colors={colors['optionColors']}
          isMenuOpened={isMenuOpened}
        >
          <Icon></Icon>
          {freemium && !isMenuOpened && <ThunderboltStyled />}
          {notification && !isMenuOpened && !newFeatures && (
            <NotificationStyled>!</NotificationStyled>
          )}
        </IconContainerStyled>
        <OptionTextStyled
          leftMenuWidth={leftMenuWidth}
          variant="h4"
          color={colors['optionColors'].foreground}
        >
          {name}
          {notification && isMenuOpened && (
            <NotificationStyled isOpen>!</NotificationStyled>
          )}
          {(freemium || freemiumPro) && isMenuOpened && (
            <PlanTypeBadge
              pro={freemiumPro}
              css={`
                position: absolute;
                margin: 0 !important;
                right: ${({ theme }) => theme.remCalc('50')};
                color: ${({ theme, pro }) =>
                  pro ? '#40CBA6' : theme.palette.prime.yellow};
              `}
            >
              {freemiumPro ? 'PRO' : 'PRIME'}
            </PlanTypeBadge>
          )}

          <div></div>
        </OptionTextStyled>
      </>
    )
    return isShown ? (
      to ? (
        <RouterNavLink
          to={to}
          {...optionProps}
          component={OptionContainerStyled}
        >
          {Option}
        </RouterNavLink>
      ) : (
        <OptionContainerStyled {...optionProps}>{Option}</OptionContainerStyled>
      )
    ) : null
  }

  return (
    <Fragment>
      <div
        leftMenuWidth={leftMenuWidth}
        css={`
          ${({ theme }) =>
            theme.media.desktop`width: ${({ theme, leftMenuWidth }) =>
              theme.remCalc(`${leftMenuWidth}`)};`}
        `}
      />
      <div
        onClick={() => toggleMenu(false)}
        $isMenuOpened={isMenuOpened}
        css={`
          ${({ theme }) => theme.position('fixed', 0, 0, 0, 0)}
          background-color: rgba(0,0,0,.3);
          backdrop-filter: blur(2px);
          transform: ${({ $isMenuOpened }) =>
            !$isMenuOpened ? 'translateX(100%)' : 'translateX(0)'};
          transition: transform 200ms ease-in 100ms;
          z-index: 10;
          ${({ theme }) => theme.media.tablet`display: none;`}
        `}
      />
      <MainContainerStyled
        container
        direction="column"
        $isMenuOpened={isMenuOpened}
        leftMenuWidth={leftMenuWidth}
      >
        <MenuButton
          menuHeaderCustomized={menuHeaderCustomized}
          onClick={toggleMenu}
          $isMenuOpened={isMenuOpened}
        />
        <Grid
          item
          flat
          xsAuto
          container
          css={`
            position: relative;
            margin-top: -3px;
          `}
        >
          <ScrollableOptionsContainerStyled
            refId={menuContainer}
            id="navigation-side-bar__scrollable-container"
          >
            {categories.map(
              category =>
                options[category] && (
                  <div
                    key={category}
                    css="&:last-child { hr { display: none; } }"
                  >
                    {Object.keys(options[category]).map((section, index) =>
                      renderOptions(section, index, category)
                    )}
                    <hr
                      css={`
                        margin-top: 3px;
                        border-width: ${({ theme }) => theme.remCalc('1')};
                        border-color: #ebedf0;
                      `}
                    />
                  </div>
                )
            )}
          </ScrollableOptionsContainerStyled>
        </Grid>

        {(!isMenuOpened ||
          (isMenuOpened &&
            selectedOption &&
            selectedOption !== itemHovered['id'])) &&
        itemHovered['top'] ? (
          <OptionHoveredStyled
            top={itemHovered['top']}
            colors={colors['optionColors']}
            leftMenuWidth={leftMenuWidth}
            variant="caption4"
            color={colors['optionColors'].foreground}
            isFreemiumLocked={
              options[itemHovered['category']][itemHovered['id']]['freemium']
            }
            isFreemiumProLocked={
              options[itemHovered['category']][itemHovered['id']]['freemiumPro']
            }
          >
            {itemHovered['id']
              ? options[itemHovered['category']][itemHovered['id']]['name']
              : ''}
            {options[itemHovered['category']][itemHovered['id']][
              'freemium'
            ] && <PlanTypeBadge>PRIME</PlanTypeBadge>}
            {options[itemHovered['category']][itemHovered['id']][
              'freemiumPro'
            ] && <PlanTypeBadge>PRO</PlanTypeBadge>}
          </OptionHoveredStyled>
        ) : (
          ''
        )}
      </MainContainerStyled>
    </Fragment>
  )
}

NavigationSideBar.propTypes = {
  mainText: PropTypes.string,
  menuHeaderCustomized: PropTypes.any,
  options: PropTypes.object
}

const MainContainerStyled = styled(Grid)`
  height: 100vh;
  width: ${({ theme, $isMenuOpened, leftMenuWidth }) =>
    $isMenuOpened ? '330px' : theme.remCalc(`${leftMenuWidth}`)};
  transition: width 300ms ease, transform 300ms ease;
  border-right: 1px solid #ebedf0;
  ${({ theme }) => theme.position('fixed', 0, 'unset', 'unset', 0)}
  z-index: 2;
  ${({ theme }) => theme.media.desktopDown`
    ${({ theme }) => theme.position('fixed', 'unset', 0, 0, 'unset')}
    z-index: 10;
    transform: ${({ $isMenuOpened }) =>
      !$isMenuOpened ? 'translateX(100%)' : 'translateX(0)'};
  `}
  ${({ theme }) => theme.media.tabletDown`
  width: ${({ theme, $isMenuOpened, leftMenuWidth }) =>
    $isMenuOpened ? '80vw' : theme.remCalc(`${leftMenuWidth}`)};
  `}
`

const ScrollableOptionsContainerStyled = styled(Grid)`
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  background-color: ${({ theme }) => theme.palette.secondary.main};
  ${({ theme }) => theme.position('absolute', 0, 0, 0, 0)}
  ${({ theme }) => theme.media.tabletDown`
    padding-bottom: ${({ theme }) => theme.remCalc('90')};
  `}
`

const OptionStyle = css`
  background-color: ${({ colors }) => colors.background};
  fill: ${({ colors }) => colors.foreground};
  color: ${({ colors }) => colors.foreground};
`

const OptionHoveredStyle = css`
  background-color: ${({ colors, isFreemiumLocked, isFreemiumProLocked }) =>
    isFreemiumProLocked
      ? colors.hoverProForeground
      : isFreemiumLocked
      ? colors.hoverPrimeForeground
      : '#F4F7FA'};
  fill: ${({ colors, isFreemiumLocked, isFreemiumProLocked }) =>
    isFreemiumProLocked
      ? colors.foreground
      : isFreemiumLocked
      ? '#000'
      : '#222222'};
  color: ${({ colors, isFreemiumLocked, isFreemiumProLocked }) =>
    isFreemiumProLocked
      ? colors.foreground
      : isFreemiumLocked
      ? '#000'
      : '#222222'};
  span {
    color: ${({ colors, isFreemiumProLocked }) =>
      isFreemiumProLocked ? colors.foreground : '#000'};
  }
`

const OptionActiveStyle = css`
  background-color: ${({ theme }) => theme.palette.primary.main};
  fill: ${({ colors }) => colors.hoverForeground};
  color: ${({ colors }) => colors.hoverForeground};
`

const OptionSelectedStyle = css`
  ${OptionHoveredStyle}
  ${OptionHoveredStyle}
  ${({ theme, colors }) => theme.bordered(2, colors.foreground, 'top')}
  ${({ theme, colors }) => theme.bordered(2, colors.foreground, 'bottom')}
  ${OptionHoveredStyle}  
  ${({ theme, colors }) => theme.bordered(2, colors.foreground, 'top')}
  ${({ theme, colors }) => theme.bordered(2, colors.foreground, 'bottom')}
`

const OptionContainerStyled = styled(Grid)`
  display: flex;
  user-select: none;
  padding: 0 0 1px 12px;
  ${({ isMenuOpened }) => (isMenuOpened ? '0 4px' : '0 4px 0 10px')};
  > * {
    ${({ isSelected, match }) =>
      match
        ? OptionActiveStyle
        : isSelected
        ? OptionSelectedStyle
        : OptionStyle}
  }
  &:hover {
    > * {
      ${OptionHoveredStyle}
    }
  }
  cursor: pointer;
  position: relative;
`

const IconContainerStyled = styled(Grid)`
  width: ${({ theme, leftMenuWidth }) =>
    theme.remCalc(`${leftMenuWidth - 20}`)};
  border-radius: ${({ isMenuOpened }) =>
    isMenuOpened ? '9999px 0 0 9999px' : '9999px'};
  svg {
    height: ${({ theme }) => theme.remCalc('24')};
    width: ${({ theme }) => theme.remCalc('24')};
    ${({ isFreemiumLocked, theme }) => isFreemiumLocked && `opacity: .6;`}
    @media screen and (max-height: 650px) {
      height: ${({ theme }) => theme.remCalc('24')};
      width: ${({ theme }) => theme.remCalc('24')};
    }
  }
  ${({ theme }) => theme.media.desktop`
    width: ${({ theme, isMenuOpened, leftMenuWidth }) =>
      isMenuOpened ? theme.remCalc(`${leftMenuWidth}`) : theme.remCalc('48')};
  `}
  height: 47px;
  @media screen and (max-height: 650px) {
    height: ${({ theme }) => theme.remCalc('30')};
  }
  &:hover {
    ${({ isFreemiumLocked, isFreemiumProLocked, colors }) =>
      (isFreemiumLocked || isFreemiumProLocked) &&
      `background-color: ${
        isFreemiumProLocked
          ? colors.hoverProForeground
          : colors.hoverPrimeForeground
      };
        svg {
          color: ${isFreemiumProLocked ? colors.foreground : '#000'} ;
          opacity: 1;
        }     
        span { color: #000; }
    `}
  }
`

const OptionStyleMixin = css`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.remCalc('40')};
  line-height: ${({ theme }) => theme.remCalc('40')};
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  @media screen and (max-height: 650px) {
    height: ${({ theme }) => theme.remCalc('30')};
    line-height: ${({ theme }) => theme.remCalc('30')};
  }
`
const OptionTextStyled = styled(Typography)`
  font-size: ${({ theme }) => theme.remCalc('12')};
  ${OptionStyleMixin}
  ${({ theme, leftMenuWidth }) =>
    theme.position('absolute', 0, 15, 0, leftMenuWidth - 10)}
    ${({ theme }) => theme.media.desktop`
      ${({ theme, leftMenuWidth }) =>
        theme.position('absolute', 0, 15, 0, leftMenuWidth)}
    `};
  border-radius: 0 9999px 9999px 0;
  height: 48px;
  ${({ theme }) => theme.media.tabletDown`
    font-size: 16px !important;
    font-family: 'Red Hat Display', sans-serif !important;
    font-weight: 400 !important;
  `}
`

const OptionHoveredStyled = styled(Typography)`
  ${OptionStyleMixin}
  background-color: #222;
  color: #fff;
  ${({ theme, top, leftMenuWidth }) =>
    theme.position('absolute', top, null, null, leftMenuWidth + 2)}
  box-shadow: 6px 3px 9px -2px rgba(125, 133, 154, 0.5);
  z-index: 11;
  padding: ${({ theme }) => theme.remCalc('4')};
  border-radius: ${({ theme }) => theme.remCalc('4')};
  height: 27px !important;
  margin-top: 10px;
  font-family: 'Red Hat Display', sans-serif !important;
  font-weight: 500;
  ${({ theme }) => theme.media.desktopDown`
    display: none
  `};
`

const NotificationStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.remCalc('12')};
  background-color: #fd7171;
  margin-left: ${({ theme }) => theme.remCalc('5')};
  color: #fff;
  font-weight: bold;
  position: ${({ isOpen }) => (!isOpen ? 'absolute' : '')};
  right: ${({ theme }) => theme.remCalc('5')};
  ${({ theme }) => theme.box(15, 15, true)}
`

const ThunderboltStyled = styled(Thunderbolt)`
  color: ${({ primary, theme }) =>
    primary ? '#ffffff' : theme.palette.prime.yellow} !important;
  max-height: ${({ theme }) => theme.remCalc('10')};
  right: ${({ theme }) => theme.remCalc('5')};
  position: absolute;
  opacity: 1 !important;
`

const PlanTypeBadge = styled.span`
  font-size: ${({ theme }) => theme.remCalc('9')};
  margin: ${({ theme }) => theme.remCalc('4 0 0 10')};
`

export default NavigationSideBar
