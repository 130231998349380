import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import styled from 'styled-components'

import { Grid, Typography } from 'rc'
import { Bed, Bathtub, Car } from 'rc/Icons'
import { Image, Button } from 'components'
import { TruncateText } from 'c3-ui/elements/Texts'
import { formatPrice } from 'utils/helpers'
import { PROPERTY_TYPES } from 'utils/constants'

const IconBoxStyled = styled(Grid)`
    ${({ theme }) => theme.bordered(1, theme.palette.base.disabled, 'right')}
    padding: ${({ theme }) => theme.remCalc('0 5')};
    &:first-child{
        padding-left: 0;
    }
    &:last-child{
        border: none;
    }
`

const PropertyCard = ({ data = {}, ...props }) => {
  const {
    bathrooms = '',
    bedrooms = '',
    city = '',
    cover_image = '',
    lease_currency = '',
    lease_price = null,
    lease_active,
    lease_shared_commission,
    lease_share_commission,
    neighborhood = '',
    parking_lots = '',
    presale_currency = '',
    presale_price = null,
    presale_active,
    presale_shared_commission,
    presale_share_commission,
    property_id = null,
    property_type = '',
    sale_currency = '',
    sale_price = null,
    sale_active,
    sale_shared_commission,
    sale_share_commission,
    state = '',
    title = ''
  } = data

  const operations = {
    SALE: {
      isActive: sale_active ? true : false,
      name: 'Venta'
    },
    LEASE: {
      isActive: lease_active ? true : false,
      name: 'Renta'
    },
    PRESALE: {
      isActive: presale_active ? true : false,
      name: 'Preventa'
    }
  }

  const activeOperations = Object.keys(operations).filter(
    key => operations[key].isActive
  )

  return (
    <Grid item md={12} lg={8} {...props} target="_blank">
      <RouterLink to={`/property/${property_id}?view=new`} target="_blank">
        <Grid
          container
          direction="column"
          css={`
            box-shadow: 0px 2px 4px #d1d6e4;
            overflow: hidden;
            cursor: pointer;
            ${({ theme }) => theme.radius(5)}
          `}
        >
          <Image
            id={cover_image}
            objectFit="cover"
            css={`
              height: 150px;
            `}
          />
          <Grid
            container
            direction="column"
            css={`
              flex: 1;
              padding: ${({ theme }) => theme.remCalc('5 10 10')};
            `}
          >
            <TruncateText
              css={`
            ${({ theme }) => theme.typography.subtitle3}
            height: ${({ theme }) => theme.remCalc('43')};
          `}
              text={
                title
                  ? title
                  : `${PROPERTY_TYPES[property_type].name} ${
                      activeOperations.length > 0
                        ? ` en ${activeOperations
                            .map(key => operations[key].name)
                            .join(' y ')
                            .toLowerCase()}`
                        : ''
                    } en  ${neighborhood}`
              }
              line={2}
            />
            <Typography
              variant="body2"
              color="gray"
              css={`
                height: ${({ theme }) => theme.remCalc('22')};
                overflow: hidden;
              `}
            >
              {city}, {state}.
            </Typography>
            <Grid
              container
              center
              css={`
                height: ${({ theme }) => theme.remCalc('84')};
                margin-bottom: ${({ theme }) => theme.remCalc('10')};
              `}
            >
              {(presale_price || sale_price) && (
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  css={`
                    margin-top: ${({ theme }) => theme.remCalc('5')};
                  `}
                >
                  <Grid container item flat direction="column">
                    <Typography variant="subtitle4" color="palette.primary.main">
                      {sale_price ? `Venta:` : `Preventa:`}
                    </Typography>
                    <Typography variant="subtitle4" color="palette.primary.main">
                      {sale_price
                        ? `$${formatPrice(sale_price)} ${sale_currency}`
                        : `$${formatPrice(presale_price)} ${presale_currency}`}
                    </Typography>
                  </Grid>
                  {(sale_share_commission || presale_share_commission) && (
                    <Grid container item flat direction="column">
                      <Typography variant="subtitle4" color="palette.primary.main">
                        Comisión{' '}
                        {sale_shared_commission ? `venta:` : `preventa:`}
                      </Typography>
                      <Typography variant="subtitle4" color="palette.primary.main">
                        {sale_shared_commission
                          ? sale_shared_commission
                          : presale_shared_commission}
                        %
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
              {lease_price && (
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  css={`
                    margin-top: ${({ theme }) => theme.remCalc('5')};
                  `}
                >
                  <Grid container item flat direction="column">
                    <Typography variant="subtitle4" color="warning">
                      Renta:
                    </Typography>
                    <Typography variant="subtitle4" color="warning">
                      {lease_price &&
                        `$${formatPrice(lease_price)} ${lease_currency}`}
                    </Typography>
                  </Grid>
                  {lease_share_commission && (
                    <Grid container item flat direction="column">
                      <Typography variant="subtitle4" color="warning">
                        Comisión renta:
                      </Typography>
                      <Typography variant="subtitle4" color="warning">
                        {lease_shared_commission
                          ? `${lease_shared_commission} ${
                              lease_shared_commission === 1 ? 'mes' : 'meses'
                            }`
                          : ''}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid container justify="space-between" alignItems="center">
              <Grid container xsAuto item flat>
                {bedrooms ? (
                  <IconBoxStyled>
                    <Bed
                      css={`
                  ${({ theme }) => theme.box(15, 15)}
                    color: ${({ theme }) => theme.palette.text.grayMedium};
                    margin-right: ${({ theme }) => theme.remCalc('5')};
                  `}
                    />
                    <Typography variant="body2" color="grayMedium">
                      {bedrooms}
                    </Typography>
                  </IconBoxStyled>
                ) : null}
                {bathrooms ? (
                  <IconBoxStyled>
                    <Bathtub
                      css={`
                  ${({ theme }) => theme.box(15, 15)}
                    color: ${({ theme }) => theme.palette.text.grayMedium};
                    margin-right: ${({ theme }) => theme.remCalc('5')};
                  `}
                    />
                    <Typography variant="body2" color="grayMedium">
                      {bathrooms}
                    </Typography>
                  </IconBoxStyled>
                ) : null}
                {parking_lots ? (
                  <IconBoxStyled>
                    <Car
                      css={`
                  ${({ theme }) => theme.box(15, 15)}
                    fill: ${({ theme }) => theme.palette.text.grayMedium};
                    color: ${({ theme }) => theme.palette.text.grayMedium};
                    margin-right: ${({ theme }) => theme.remCalc('5')};
                  `}
                    />
                    <Typography variant="body2" color="grayMedium">
                      {parking_lots}
                    </Typography>
                  </IconBoxStyled>
                ) : null}
              </Grid>
              <div>
                <Button
                  id={`profile-profileDetail-button-viewDetailPropertyCard-${props.index}`}
                  type="primary"
                  css={`
                    min-height: ${({ theme }) => theme.remCalc('34')};
                    min-width: ${({ theme }) => theme.remCalc('120')};
                  `}
                >
                  Ver detalle
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </RouterLink>
    </Grid>
  )
}

PropertyCard.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number
}

export default withRouter(PropertyCard)
