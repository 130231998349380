import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Image as LibImage } from 'rc'

const LibImageStyled = styled(LibImage)`
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
`

export const imageURLByID = (id, size = 'm', extension = 'jpg') => {
  return id && id.length > 8
    ? id
    : `${process.env.REACT_APP_IMAGE_BASE_URL}/${id}-${size}.${extension}`
}

const Image = ({
  className,
  id,
  size,
  extension,
  alt,
  objectFit,
  onClick,
  highSrc,
  lowSrc,

  ...props
}) => {
  return (
    <LibImageStyled
      {...props}
      className={className}
      highSrc={imageURLByID(id, size, extension) || highSrc}
      lowSrc={imageURLByID(id, 't', extension) || lowSrc}
      alt={alt}
      objectFit={objectFit ? objectFit : 'contain'}
      onClick={onClick}
    />
  )
}

Image.defaultProps = {
  extension: 'jpg',
  size: 'm',
  objectFit: 'contain',
  alt: 'Image'
}
Image.propTypes = {
  alt: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  extension: PropTypes.string,
  objectFit: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['t', 's', 'm', 'l']),
  highSrc: PropTypes.string,
  lowSrc: PropTypes.string
}

export default Image
