import React from 'react'
import PropTypes from 'prop-types'
import { LinkButton as LibLinkButton } from 'rc'
import { getDefaultProps } from './Button'

const LinkButton = ({ type, ...props }) => {
  const withDefaultProps = {
    ...getDefaultProps(type),
    ...props
  }

  return (
    <LibLinkButton
      css={`
        color: white;
        :hover{
          color: white;
        }
      `}
      {...withDefaultProps}
    />
  )
}

LinkButton.defaultProps = {
  type: 'primary'
} 

LinkButton.propTypes = {
  href: PropTypes.string,
  route: PropTypes.bool,
  to: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.oneOf(['large'])
}

export default LinkButton
