import TagManager from 'react-gtm-module'

const event = (name, data = {}) => {
  if (process.env.REACT_APP_ENV !== 'PROD') {
    return true
  }

  try {
    TagManager.dataLayer({
      dataLayer: {
        event: name,
        data
      }
    })
  } catch (e) {
    //Not handled
  }
}

const propertyDetails = [
  { event: 'downloadPDFSuccess', eventName: 'property_download_pdf_success' },
  { event: 'sharePropertySuccess', eventName: 'share_property_success' },
  { event: 'viewPhoneSuccess', eventName: 'property_detail_view_phone_success' },
  { event: 'viewEmailSuccess', eventName: 'property_detail_view_email_success' },
  { event: 'viewWhatsappSuccess', eventName: 'property_detail_view_whatsapp_success' },
  { event: 'initChat', eventName: 'property_detail_init_chat' }
]

const login = [
  { event: 'register', eventName: 'user_creates_his_account' }
]

const profile = [{ event: 'getProfile', eventName: 'get-profile' }]

const events = {
  eventsPropertyDetails: {},
  eventsLogin: {},
  eventsProfile: {}
}

const eventGroups = [
  { event: 'eventsPropertyDetails', arrEvent: propertyDetails },
  { event: 'eventsLogin', arrEvent: login },
  { event: 'eventsProfile', arrEvent: profile }
]

for (let a = 0; a < eventGroups.length; a++) {
  for (let i = 0; i < eventGroups[a].arrEvent.length; i++) {
    events[eventGroups[a].event][eventGroups[a].arrEvent[i].event] = (
      data = {}
    ) => {
      event(eventGroups[a].arrEvent[i].eventName, data)
    }
  }
}

export default events