import React from 'react'
import PropTypes from 'prop-types'
import { PrimeButton } from 'modules/prime/PrimeButton'
import { useFreemium } from 'modules/freemium/hooks/useFreemium'

const FreemiumWrapper = ({ children, label, section, custom, isLocked, launchOnLoad, goBackOnClose, withPermissionHide, modalProps, ...props }) => {
  const { validatePermission } = useFreemium({section, launchOnLoad, isLocked, goBackOnClose, withPermissionHide})
  const hasPermission = validatePermission({ section, isLocked })
  
  const customChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, { ...props, onClick: () => hasPermission?.modal(modalProps) });
  });

  return hasPermission.value 
    ? withPermissionHide ? null : children 
    : custom ? customChildren 
    : (
    <PrimeButton 
      {...props} 
      onClick={() => hasPermission?.modal()}  
    >
      {label}
    </PrimeButton>)
}

FreemiumWrapper.propTypes = {
  section: PropTypes.object,
  children: PropTypes.any,
  custom: PropTypes.bool,
  goBackOnClose: PropTypes.bool,
  isLocked: PropTypes.bool,
  label: PropTypes.string,
  launchOnLoad: PropTypes.bool,
  modalProps: PropTypes.object
}

export default FreemiumWrapper