import HouseImage from 'static/img/new-house.svg'
import Store from 'static/img/shopping-cart.svg'
import CheckedHouse from 'static/img/checked-house.svg'
import HighFive from 'static/img/high-five-threesome.svg'
import Warning from 'static/img/warning-hands.svg'

export const VARIANTS = {
  OLD_WIGGOT_USER: {
    title: 'Wiggot® se renueva',
    ilustration: HouseImage,
    mainActionLabel: 'Activar',
    secondaryActionLabel: 'Cancelar',
    name: 'OLD_WIGGOT_USER'
  },
  NEW_WIGGOT_USER: {
    title: 'Bienvenido',
    ilustration: HouseImage,
    mainActionLabel: 'Aceptar',
    secondaryActionLabel: 'Cancelar',
    name: 'NEW_WIGGOT_USER'
  },
  ADVANCED_TRIAL_ENDED: {
    title: 'Tu prueba del Plan Avanzado ha terminado',
    ilustration: Store,
    mainActionLabel: 'Ir a la tienda',
    name: 'ADVANCED_TRIAL_ENDED'
  },
  NO_ACTIVE_PLAN: {
    title: 'Adquiere un plan para continuar',
    ilustration: Store,
    mainActionLabel: 'Ir a la tienda',
    secondaryActionLabel: 'Salir',
    name: 'NO_ACTIVE_PLAN'
  },
  PLAN_UPGRADE: {
    title: 'Wiggot® se renueva',
    ilustration: CheckedHouse,
    mainActionLabel: 'Continuar',
    secondaryActionLabel: 'Cancelar',
    name: 'PLAN_UPGRADE'
  },
  UPGRADE_TO_ADVANCED: {
    title: 'Cámbiate al Plan Avanzado',
    ilustration: HighFive,
    mainActionLabel: 'Mejorar a Avanzado',
    secondaryActionLabel: 'Cancelar',
    name: 'UPGRADE_TO_ADVANCED'
  },
  NO_REPORTS_WARNING: {
    title: 'Ya no tienes reportes disponibles',
    ilustration: Warning,
    secondaryActionLabel: 'Cerrar',
    name: 'NO_REPORTS_WARNING'
  },
  BLOCKED: {
    title: 'No tienes permiso',
    ilustration: Warning,
    secondaryActionLabel: 'Aceptar',
    name: 'BLOCKED'
  }
}
