import React from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <>
    <path
      d="M2.06641 4.95829C2.06641 2.91663 3.23307 2.04163 4.98307 2.04163H10.8164C12.5664 2.04163 13.7331 2.91663 13.7331 4.95829V9.04163C13.7331 11.0833 12.5664 11.9583 10.8164 11.9583H4.98307"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8167 5.25L8.9909 6.70833C8.39006 7.18667 7.40423 7.18667 6.8034 6.70833L4.9834 5.25"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.06641 9.625H5.56641"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.06641 7.29163H3.81641"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  'HotMail',
  '0 0 15 14'
)
