import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { AgencyBrokerProfile } from 'components/Icons'
import { Typography, Grid } from 'rc'
import { Button } from 'components'
import { ShowModal } from 'reduxActions/services'
import LoginContentMC from './LoginContentMC'
import RegisterContentMC from './RegisterContentMC'
const wide = css`
  width: ${({ theme }) => theme.remCalc('520')};
  max-width: 100%;
`

const LoginContentMCStyled = styled(LoginContentMC)`
  ${wide}
`
const RegisterContentMCStyled = styled(RegisterContentMC)`
  ${wide}
`
const NotLoggedMC = ({
  coverImage: CoverImage,
  contentTitle,
  ShowModal,
  ...props
}) => {
  const baseId = 'profile-profileNotLogged-mc'

  return (
    <div
      css={`
        width: ${({ theme }) => theme.remCalc('520')};
        max-width: 100%;
      `}
    >
      <CoverImage></CoverImage>

      <div
        css={`
          padding: ${({ theme }) => theme.remCalc('25 60')};
        `}
      >
        <Typography
          variant="h3"
          color="palette.primary.main"
          css={`
            width: ${({ theme }) => theme.remCalc('340')};
            max-width: 100%;
            margin: 0 auto ${({ theme }) => theme.remCalc('20')};
          `}
          display="block"
          align="center"
        >
          Para ver {contentTitle} crea tu cuenta de wiggot o inicia sesión
        </Typography>
        <Grid container>
          <Grid item md={12}>
            <Button
              id={`${baseId}-button-createAccount`}
              full
              onClick={() => {
                ShowModal(RegisterContentMCStyled, {}, {}, true)
              }}
            >
              Crear cuenta
            </Button>
          </Grid>
          <Grid item md={12}>
            <Button
              id={`${baseId}-button-createAccount`}
              full
              variant="outlined"
              onClick={() => {
                ShowModal(LoginContentMCStyled, {}, {}, true)
              }}
            >
              Inicia sesión
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

NotLoggedMC.defaultProps = {
  coverImage: AgencyBrokerProfile,
  contentTitle: 'el perfil este asesor'
}
NotLoggedMC.propTypes = {
  ShowModal: PropTypes.func.isRequired,
  coverImage: PropTypes.any,
  contentTitle: PropTypes.string
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  ShowModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotLoggedMC)
