import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Typography, Button } from 'rc'
import { Checkmark } from 'rc/Icons'
import { PLAN_BENEFITS } from 'modules/store/constants'
import { Cross } from 'rc/Icons'

import WiggotPrimeLogo from 'static/logos/wiggot-prime.svg'
import WiggotProLogo from 'static/logos/wiggot-pro.svg'
import WiggotPassLogo from 'static/logos/wiggot-pass.svg'
import WiggotFullLogo from 'static/logos/wiggot-full.svg'
import WiggotLogo from 'static/logos/wiggot-r.svg'

import { PORTALS_NAME } from 'modules/dashboard/Leads/utils/constants'

const PlanIdBenefits = ({ action, planData, darkMode, HideModal }) => {
  const history = useHistory()

  const renderLogo = () => {
    switch (planData.plan_id) {
      case 'PRIME':
        return WiggotPrimeLogo
      case 'PRO':
        return WiggotProLogo
      case 'PASS':
        return WiggotPassLogo
      case 'FULL':
        return WiggotFullLogo
      default:
        return WiggotLogo
    }
  }

  const getBenefitsList = () => {
    const list = [...PLAN_BENEFITS[planData.plan_id].list]
    const portalItems = (planData && planData.credits_for_portals && planData.credits_for_portals.items) || []

    if (portalItems.length > 0) {
      portalItems.forEach(item => {
        if (item.credits) {
          item.credits.forEach(credit => {
            list.push({
              title: `${credit.credits} anuncios ${credit.type_pretty} en ${PORTALS_NAME[credit.portal]}`
            })
          })
        }
      })
    }

    return list
  }

  return (
    <Container darkMode={darkMode}>
      <CustomCloseButton onClick={HideModal}>
        <CrossIconStyled />
      </CustomCloseButton>
      <img
        src={renderLogo()}
        alt="logo"
        css={`
          margin: ${({ theme }) => theme.remCalc('12 0')};
          height: ${({ theme }) => theme.remCalc('50')};
        `}
      />
      <Typography
        variant="subtitle2"
        css={`
          margin-top: ${({ theme }) => theme.remCalc('12')};
          font-size: ${({ theme }) => theme.remCalc('14')};
          height: ${({ theme }) => theme.remCalc('40')};
        `}
      >
        {PLAN_BENEFITS[planData.plan_id].title}
      </Typography>
      <Benefits>
        {getBenefitsList().map((benefit, index) => (
          <div
            key={index}
            // css={`display: flex;`}
          >
            <div
              css={`
                display: flex;
              `}
            >
              <CheckmarkStyled
                css={`
                  height: ${({ theme }) => theme.remCalc('15')};
                  margin-right: ${({ theme }) => theme.remCalc('10')};
                  fill: #0076ff;
                  color: #0076ff;
                `}
              />
              <Typography
                variant="body2"
                css={`
                  width: calc(100% - 25px);
                  color: #605d75;
                `}
              >
                {benefit.title}
              </Typography>
            </div>
            {benefit.description ? (
              <div
                css={`
                  padding-left: 20px;
                `}
              >
                <Typography
                  variant="body2"
                  css={`
                    width: calc(100% - 25px);
                    color: #605d75;
                    font-size: 12px;
                  `}
                >
                  {benefit.description}
                </Typography>
              </div>
            ) : null}
          </div>
        ))}
      </Benefits>
      <Buttons multipleButtons={planData && planData.payment && planData.payment.allowed}>
        <ButtonStyled
          variant="outlined"
          current
          onClick={() => history.push(`/all-plans`)}
        >
          Ver todos los planes
        </ButtonStyled>
        {planData && planData.payment && planData.payment.allowed
          ? <ButtonStyled onClick={action}>Contratar</ButtonStyled>
          : null}
      </Buttons>
    </Container>
  )
}

PlanIdBenefits.propTypes = {
  HideModal: PropTypes.func,
  action: PropTypes.func,
  darkMode: PropTypes.bool,
  planData: PropTypes.object
}

const Container = styled.div`
  width: ${({ theme }) => theme.remCalc('550')};
  padding: ${({ theme }) => theme.remCalc('20 30')};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, darkMode }) =>
    darkMode && theme.palette.prime.main};
  ${({ theme }) => theme.media.tabletDown`
    width:100%;
  `}
`
const CustomCloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.remCalc('12')};
  right: ${({ theme }) => theme.remCalc('12')};
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
`
const CrossIconStyled = styled(Cross)`
  ${({ theme }) => theme.box(24, 24)}
  fill: #A0A3BD;
`
const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ multipleButtons }) => multipleButtons ? 'space-between' : 'center'};
  margin-top: ${({ theme }) => theme.remCalc('10')};
`
const ButtonStyled = styled(Button)`
  width: 45%;
  border-radius: ${({ theme }) => theme.remCalc('30')} !important;
  height: ${({ theme }) => theme.remCalc('40')};
`
const Benefits = styled.div`
  margin-bottom: 20px;
`
const CheckmarkStyled = styled(Checkmark)`
  height: ${({ theme }) => theme.remCalc('15')};
  margin-right: ${({ theme }) => theme.remCalc('10')};
  fill: #0076ff;
  color: #0076ff;
`

export default PlanIdBenefits
