import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from 'rc'
import { Button } from 'components'
import FreemiumWrapper from 'modules/freemium/FreemiumWrapper'

const StatusZero = ({
  buttonProps,
  description,
  iconProps: { className: iconClassName, icon: Icon },
  ...props
}) => {
  return (
    <Grid container direction="column" justify="center">
      <Grid
        item
        flat
        xsShrink
        css={`
          ${({ theme }) => theme.box(90, 90, true)}

          ${iconClassName}
        `}
        container
        center
      >
        <div
          css={`
            svg {
              color: ${({ theme }) => theme.palette.base.white};
              ${({ theme }) => theme.box(50, 50)}
            }
          `}
        >
          <Icon />
        </div>
      </Grid>
      <Typography
        variant="subtitle2"
        align="center"
        css={`
          margin: ${({ theme }) => theme.remCalc('20 0')};
        `}
      >
        {description}
      </Typography>
      <FreemiumWrapper
          section={buttonProps.rule}
          label={buttonProps.text}
        >
        <Button
          type={buttonProps.type || 'secondary'}
          id={buttonProps.id}
          onClick={buttonProps.onClick}
        >
          {buttonProps.text}
        </Button>
      </FreemiumWrapper>
    </Grid>
  )
}

StatusZero.propTypes = {
  iconProps: PropTypes.object,
  buttonProps: PropTypes.object,
  description: PropTypes.string
}

export default StatusZero
