import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  FormControlLabel,
  CheckboxControl,
  TextField,
  Grid,
  Typography
} from 'rc'
import { CheckmarkSquare as CheckBoxIcon } from 'rc/Icons'
import Button from './Button'

export const FormControlLabelStyled = styled(FormControlLabel)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: ${({ theme }) => theme.remCalc('15 20 15 15')};
  background: ${({ theme }) => theme.palette.base.white};
  margin-bottom: 1px;
`

export const CheckboxControlStyled = props => {
  return <CheckboxControl checkedIcon={CheckBoxIcon} {...props} />
}

export const ButtonStyled = styled(Button).attrs(props => ({
  type: props.type
}))`
  border-radius: ${({ theme }) => theme.remCalc('50')} !important;
  min-width: ${({ theme }) => theme.remCalc('120')};
`
export const PrimaryTitle = styled(Typography).attrs(props => ({
  variant: props.variant ? props.variant : 'h2',
  color: 'palette.primary.main',
  textTransform: 'initial'
}))``

export const TextFieldStyled = styled(TextField)`
  > div {
    background-color: ${({ theme }) => theme.palette.base.white};
  }
`

const ListItemStyled = styled(Grid).attrs(props => ({
  ...props,
  container: true,
  direction: 'column'
}))`
  background-color: white;
  position: relative;
  &:after {
    content: ' ';
    ${({ theme }) => theme.position('absolute', null, 10, 0, 10)}
    height: 2px;
    background-color: ${({ theme }) => theme.palette.gray.light};
  }

  &:last-child:after {
    content: '';
  }
  cursor: pointer;
  padding: ${({ theme }) => theme.remCalc('5 12')};
  &:hover {
    background-color: ${({ theme }) => theme.palette.gray.light};
  }
`

export const LocationHighlightedItem = ({
  label,
  subLabel,
  searchHighlighter,
  onClick,
  item: { city, state, name },
  item,
  secondLine,
  ...props
}) => {
  const secondLabel = [city, state].filter(item => item).join(', ')

  return (
    <ListItemStyled {...props} onClick={onClick}>
      <Typography
        variant="div"
        dangerouslySetInnerHTML={{
          __html: searchHighlighter
            ? searchHighlighter(label || city || state || name)
            : label
        }}
        lineHeight="28px"
        css={`
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          width: 100%;
        `}
      />
      {secondLine && secondLabel && (
        <Typography
          variant="div"
          color="gray"
          css={`
            width: 100%;
          `}
        >
          {secondLabel}
        </Typography>
      )}
    </ListItemStyled>
  )
}

LocationHighlightedItem.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  searchHighlighter: PropTypes.func,
  subLabel: PropTypes.string,
  item: PropTypes.object,
  secondLine: PropTypes.bool
}

export const LocationHighlightedMultiItem = ({ children, sectionTitle }) => {
  return (
    <Grid
      container
      direction="column"
      css="width: 100%; background-color: #f6f6f7;"
    >
      <Typography
        variant="subtitle2"
        align="right"
        css={`
          width: 100%;
          padding: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        {sectionTitle}
      </Typography>
      {children}
    </Grid>
  )
}

LocationHighlightedMultiItem.propTypes = {
  children: PropTypes.any,
  sectionTitle: PropTypes.string
}

export const ListBrokerHighlightedItem = ({ item: { fullname }, onClick }) => {
  return (
    <Grid
      container
      direction="column"
      onClick={onClick}
      css={`
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: #f2f2f2;
        }
      `}
    >
      <Typography
        variant="subtitle2"
        css={`
          width: 100%;
          padding: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        {fullname}
      </Typography>
    </Grid>
  )
}

ListBrokerHighlightedItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
}

export const ListHighlightedItem = ({ item, item: { name }, onClick }) => {
  return (
    <Grid
      container
      direction="column"
      onClick={onClick}
      css={`
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: #f2f2f2;
        }
      `}
    >
      <Typography
        variant="subtitle2"
        css={`
          width: 100%;
          padding: ${({ theme }) => theme.remCalc('10')};
        `}
      >
        {name}
      </Typography>
    </Grid>
  )
}

ListHighlightedItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
}
