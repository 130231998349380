import { createReducer } from '../store'
import { snakeToCamelCase } from 'utils/helpers'

export const updateAgency = (state, action) => {
  let { payload: { data = {} } = {} } = action
  const responseFormatted = snakeToCamelCase(data)

  let {
    agencyName = state.agencyName,
    agencyPhone = state.agencyPhone,
    agencyPhone2 = state.agencyPhone2,
    agencyAddress = state.agencyAddress,
    agencyDescription = state.agencyDescription,
    agencyLogo = state.agencyLogo,
    agencyBusinessName = state.agencyBusinessName,
    agencyState = state.agencyState,
    agencyCity = state.agencyCity,
    agencyEmail = state.agencyEmail
  } = responseFormatted

  return {
    ...state,
    agencyName,
    agencyPhone,
    agencyPhone2,
    agencyAddress,
    agencyDescription,
    agencyLogo,
    agencyBusinessName,
    agencyState,
    agencyCity,
    agencyEmail,
    hasLoaded: true
  }
}

const initialState = {
  agencyName: null,
  agencyPhone: null,
  agencyPhone2: null,
  agencyAddress: null,
  agencyDescription: null,
  agencyLogo: null,
  agencyBusinessName: null,
  agencyCity: null,
  agencyEmail: null,
  agencyState: null,
  hasLoaded: false
}

const agencyReducer = createReducer(
  {
    ...initialState
  },
  {
    GET_AGENCY_INFO_SUCCESS: updateAgency
  }
)

export default agencyReducer
