var _jsxFileName = 'src/lib/elements/Texts/Title.js',
    _this = this;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

var Title = function Title(_ref) {
  var className = _ref.className,
      text = _ref.children,
      smallSize = _ref.smallSize,
      mediumSize = _ref.mediumSize,
      largeSize = _ref.largeSize,
      gray = _ref.gray,
      darkGray = _ref.darkGray,
      primary = _ref.primary,
      primaryLight = _ref.primaryLight,
      center = _ref.center,
      uppercase = _ref.uppercase,
      thin = _ref.thin,
      extraSmallSize = _ref.extraSmallSize,
      props = _objectWithoutProperties(_ref, ['className', 'children', 'smallSize', 'mediumSize', 'largeSize', 'gray', 'darkGray', 'primary', 'primaryLight', 'center', 'uppercase', 'thin', 'extraSmallSize']);

  var titleClassNames = classNames(className, 'title', {
    'title--bold': !thin,
    'title--thin': thin,
    'title--extra-small-size': extraSmallSize,
    'title--small-size': smallSize,
    'title--medium-size': !smallSize && !largeSize && !extraSmallSize || mediumSize,
    'title--large-size': largeSize,
    'title--dark-gray': darkGray,
    'title--gray': gray,
    'title--primary': primary,
    'title--primary-light': primaryLight,
    'title--center': center,
    'title--uppercase': uppercase
  });
  return React.createElement(
    'div',
    Object.assign({ className: titleClassNames }, props, {
      __source: {
        fileName: _jsxFileName,
        lineNumber: 37
      },
      __self: _this
    }),
    text
  );
};

Title.propTypes = {
  /** */
  children: PropTypes.any.isRequired,
  /** */
  center: PropTypes.bool,
  /** */
  className: PropTypes.string,
  /** */
  darkGray: PropTypes.bool,
  /** */
  extraSmallSize: PropTypes.bool,
  /** */
  gray: PropTypes.bool,
  /** */
  largeSize: PropTypes.bool,
  /** */
  mediumSize: PropTypes.bool,
  /** */
  primary: PropTypes.bool,
  /** */
  primaryLight: PropTypes.bool,
  /** */
  smallSize: PropTypes.bool,
  /** */
  thin: PropTypes.bool,
  /** */
  uppercase: PropTypes.bool
};

export default Title;