import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  ProfileAgencyInfo,
  ProfilePersonalInfo,
  ProfileWorkExperience,
  ProfileCertifications,
  ProfileProperties,
  ProfileHeader,
  ProfilePropertiesShownInProfile,
  ProfileScores
} from 'modules/profile/ProfileDetail'
import { ProfileContext } from 'modules/profile'
import { ProfileProgressBar } from 'components'

const ProfileDetail = ({ token }) => {
  const profile = useContext(ProfileContext)

  return (
    <Fragment>
      <ProfileHeader />
      <ProfileProgressBar data={profile} profileSection={true} />
      <ProfilePersonalInfo />
      {profile.isMyProfile && <ProfileScores></ProfileScores>}
      <ProfileAgencyInfo />
      <ProfileWorkExperience />
      <ProfileCertifications />
      {token && <ProfileProperties />}
      {token && <ProfilePropertiesShownInProfile />}
      {!profile.isMyProfile && <ProfileScores></ProfileScores>}
    </Fragment>
  )
}

ProfileDetail.propTypes = {
  token: PropTypes.string
}

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetail)
