import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <path
      d="M18.0617 3.84911C17.8857 3.67311 17.6217 3.62031 17.3577 3.70831L5.28409 8.54831C5.03769 8.63631 4.86169 8.90031 4.86169 9.16431C4.86169 9.44591 5.05529 9.69231 5.30169 9.76271L10.4057 11.5227L12.1657 16.6267C12.2537 16.8731 12.4649 17.0315 12.7289 17.0667H12.7641C13.0281 17.0667 13.2921 16.8907 13.3801 16.6443L18.2201 4.55311C18.3257 4.30671 18.2553 4.04271 18.0617 3.84911ZM12.9577 9.88591C13.0809 9.76271 13.1513 9.60431 13.1513 9.42831C13.1513 9.25231 13.0809 9.09391 12.9577 8.97071C12.7113 8.72431 12.2889 8.72431 12.0425 8.97071L10.7577 10.2555L7.41369 9.11151L16.4601 5.48591L12.8169 14.5147L11.6729 11.1531L12.9577 9.88591Z"
      fill="currentColor"
    />
    <path
      d="M6.25214 12.1565L4.77374 13.5469C4.50974 13.7933 4.08734 13.7757 3.84094 13.5117C3.59454 13.2477 3.61214 12.8253 3.87614 12.5789L5.35454 11.1885C5.61854 10.9421 6.04094 10.9597 6.28734 11.2237C6.51614 11.4877 6.51614 11.9101 6.25214 12.1565Z"
      fill="currentColor"
    />
    <path
      d="M7.94176 14.7434L5.63616 16.9082C5.37216 17.1546 4.94976 17.137 4.70336 16.873C4.45696 16.609 4.47456 16.1866 4.73856 15.9402L7.04416 13.7754C7.30816 13.529 7.73056 13.5466 7.97696 13.8106C8.22336 14.0746 8.20576 14.497 7.94176 14.7434Z"
      fill="currentColor"
    />
    <path
      d="M10.6169 16.3625L9.13848 17.7529C8.87448 17.9993 8.45208 17.9817 8.20568 17.7177C7.95928 17.4537 7.97688 17.0313 8.24088 16.7849L9.71928 15.3945C9.98328 15.1481 10.4057 15.1657 10.6521 15.4297C10.8985 15.6937 10.8809 16.1161 10.6169 16.3625Z"
      fill="currentColor"
    />
  </Fragment>,
  'SendIcon',
  '0 0 22 22'
)
