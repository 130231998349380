import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { useMedia } from 'rc/Hooks'

import I24SmallLogo from 'static/logos/i24.svg'
import I24Logo from 'static/logos/inmuebles24.svg'
import VivanunciosSmallLogo from 'static/logos/VIVAANUNCIOS.svg'
import VivanunciosLogo from 'static/logos/vivanuncios-transparent.svg'
import M3Logo from 'static/logos/m3-transparent.svg'
import LamudiLogo from 'static/logos/lifull-transparent.png'

const ImagePortalCredits = ({
  portal,
  theme: {
    devices: { tabletDown }
  },
  useSmallLogos
}) => {
  const isTabletDown = useMedia(tabletDown)

  const render = (paramPortal) => {
    let tab = null
    const i24ImageProps = { width: 120, src: I24Logo }
    const VivanunciosImageProps = { width: 120, src: VivanunciosLogo }

    if (isTabletDown || useSmallLogos) {
      delete i24ImageProps.width
      delete VivanunciosImageProps.width

      i24ImageProps.src = I24SmallLogo
      VivanunciosImageProps.src = VivanunciosSmallLogo
    }

    switch(paramPortal) {
      case 'I24':
        tab = (
          <div>
            <img {...i24ImageProps} />
            <Plus>+</Plus>
            <img {...VivanunciosImageProps} />
          </div>
        )
        break;
      case 'M3':
        tab = <img src={M3Logo} />
        break;
      case 'LAMUDI':
        tab = <img src={LamudiLogo} />
        break;
    }

    return tab
  }

  return render(portal)
}

const Plus = styled.span`
  color: #0076FF;
  font-weight: 800;
  font-siz: ${({ theme }) => theme.remCalc('20')};
  margin: ${({ theme }) => theme.remCalc('10')};
`

ImagePortalCredits.propTypes = {
  portal: PropTypes.string,
  useSmallLogos: PropTypes.bool,
}

export default withTheme(ImagePortalCredits)