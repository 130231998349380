var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _class,
    _temp,
    _jsxFileName = 'src/lib/elements/Forms/FormSection.js';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { FormSectionTitle } from '../Texts';
import PropTypes from 'prop-types';

export var FormSection = (_temp = _class = function (_PureComponent) {
  _inherits(FormSection, _PureComponent);

  function FormSection() {
    _classCallCheck(this, FormSection);

    return _possibleConstructorReturn(this, (FormSection.__proto__ || Object.getPrototypeOf(FormSection)).apply(this, arguments));
  }

  _createClass(FormSection, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          title = _props.title,
          instructions = _props.instructions,
          children = _props.children,
          noLateralPadding = _props.noLateralPadding,
          className = _props.className;


      var formSectionClasses = classNames(className, 'form-section', {
        'form-section--no-lateral-padding': noLateralPadding
      });

      return React.createElement(
        'div',
        { className: formSectionClasses, __source: {
            fileName: _jsxFileName,
            lineNumber: 34
          },
          __self: this
        },
        React.createElement(
          FormSectionTitle,
          {
            className: 'form-section__title',
            instructions: instructions,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 35
            },
            __self: this
          },
          title
        ),
        children
      );
    }
  }]);

  return FormSection;
}(PureComponent), _class.propTypes = {
  /** */
  children: PropTypes.any.isRequired,
  /** */
  title: PropTypes.any.isRequired,
  /** */
  className: PropTypes.string,
  /** */
  instructions: PropTypes.any,
  /** */
  noLateralPadding: PropTypes.bool
}, _temp);

export default FormSection;