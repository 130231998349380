import { createReducer } from '../store'

const initialState = {
  tokens: []
}

const tokensReducer = createReducer(
  {
    ...initialState
  },
  {
    GET_TOKEN_LIST_SUCCESS: (state, action) => {
      return {
        ...state,
        tokens: action.payload.data
      }
    }
  }
)

export default tokensReducer
