import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Typography, Button, Spinner } from 'rc'
import { Warning } from 'rc/Icons'
import NotificationMC from 'modules/app/modalContent/NotificationMC'
import { DeleteDevelopment } from 'reduxActions/development'
import {
  HideModal,
  ShowModal,
  ShowFlashNotification
} from 'reduxActions/services'

const DeleteDevelopmentMC = ({
  token,
  developmentId,
  HideModal,
  DeleteDevelopment,
  onDelete,
  ShowFlashNotification,
  ShowModal
}) => {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <ModalContainer>
      {!isLoading ? 
        <WarningIconStyled /> :
        <SpinnerStyled />
      }
      <Typography
        variant="h2"
        align="center"
        textTransform="initial"
        color="palette.primary.main"
        css={`margin-bottom: ${({ theme }) => theme.remCalc('20')};`}
      >
        ¿Estás seguro de eliminar este desarrollo?
      </Typography>
      <Typography
        variant="subtitle4"
        align="center"
        color="palette.new.text"
        fontWeight={400}
        css={`margin-bottom: ${({ theme }) => theme.remCalc('20')};`}
      >
        La información de este desarrollo y sus modelos se borrará para tí y
        todos los asesores de tu inmobiliaria. También desaparecerá del buscador.
      </Typography>
      <ButtonsContainer>
        <ButtonStyled
          size="large"
          variant="outlined"
          color="gray"
          onClick={HideModal}
          disabled={isLoading}
        >
          Cancelar
        </ButtonStyled>
        <ButtonStyled
        size="large"
        color="alert"
        onClick={async () => {
            try {
              setIsLoading(true)
              await DeleteDevelopment(token, developmentId)
              if (onDelete) return setTimeout(() => { onDelete() }, 2000)
              
              ShowModal(NotificationMC, {
                title: '¡Se borro el desarrollo correctamente!',
                message: 'Ahora ya no se mostrará'
              })
            } catch (error) {
              setIsLoading(false)
              ShowFlashNotification(
                'danger',
                'Error al intentar eliminar el desarrollo'
              )
            }
          }}
        disabled={isLoading}
        >
          Eliminar desarrollo
        </ButtonStyled>
      </ButtonsContainer>
    </ModalContainer>
  )
}

DeleteDevelopmentMC.propTypes = {
  token: PropTypes.string,
  developmentId: PropTypes.string,
  DeleteDevelopment: PropTypes.func,
  HideModal: PropTypes.func,
  onDelete: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  ShowModal: PropTypes.func
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.remCalc('520')};
  padding: ${({ theme }) => theme.remCalc('32')};
  align-items: center;
`
const WarningIconStyled = styled(Warning)`
  fill: #FFBC08;
  width: ${({ theme }) => theme.remCalc('55')};
  margin-bottom: ${({ theme }) => theme.remCalc('24')};
`
const SpinnerStyled = styled(Spinner)`
  width: ${({ theme }) => theme.remCalc('55')};
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const ButtonStyled = styled(Button)`
  border-radius: 50px !important;
  width: 49%;
`

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  DeleteDevelopment,
  HideModal,
  ShowFlashNotification,
  ShowModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteDevelopmentMC)
