import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${({ theme }) => theme.remCalc('38')};
  justify-content: center;
  width: ${({ theme }) => theme.remCalc('38')};
  border-radius: ${({ theme }) => theme.remCalc('5')};
  position: relative;
  > button {
    svg {
      fill: ${({ theme }) => theme.palette.new.text};
    }
    outline: none;
    cursor: pointer;
    :focus,
    :hover {
      ${({ disabled, theme }) =>
        !disabled &&
        `
        svg {
        fill: white;
        background-color: ${theme.palette.primary.main};
      }
      `}
    }
  }
`

const MenuContainer = styled.div`
  display: ${({ hide }) => (hide ? `none` : `block`)};
  ${({ isMenuOpen }) =>
    isMenuOpen
      ? `animation: scale-up-tr 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;`
      : `animation: scale-up-tr-rev 0.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) reverse both;`}
  background-color: #fff;
  min-width: ${({ theme }) => theme.remCalc('130')};
  position: absolute;
  top: ${({ theme }) => theme.remCalc('38')};
  right: 0;
  z-index: 5;
  border-radius: ${({ theme }) => theme.remCalc('5')};
  overflow: hidden;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
`

const Option = ({ ...props }) => <button {...props} />

const OptionButton = styled(Option)`
  color: #7d859a;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  border-radius: 0;
  font-size: ${({ theme }) => theme.remCalc('14')};
  height: ${({ theme }) => theme.remCalc('42')};
  line-height: ${({ theme }) => theme.remCalc('42')};
  padding: ${({ theme }) => theme.remCalc('0 10')};
  position: relative;
  text-align: left;
  white-space: nowrap;
  align-items: center;
  width: 100%;
  outline: none;
  > svg {
    ${({ theme }) => theme.box(20, 20)}
    margin-right: ${({ theme }) => theme.remCalc('17')};
    fill: #7d859a;
  }
  :hover,
  :focus {
    background-color: #f6f7f9;
    color: #0077e2;
    > svg {
      fill: #0077e2;
    }
  }
`

class MenuButton extends Component {
  static propTypes = {
    buttonChildren: PropTypes.any,
    className: PropTypes.string,
    classNameButton: PropTypes.string,
    disabled: PropTypes.bool,
    classNameMenu: PropTypes.string,
    onUpdate: PropTypes.func,
    options: PropTypes.array,
    objectData: PropTypes.any,
    theme: PropTypes.string,
    idBase: PropTypes.string
  }

  static defaultProps = {
    theme: 'default'
  }

  state = { isMenuOpen: false, hide: true }

  handleOptionClick = event => {
    const { options, objectData, onUpdate } = this.props

    event.stopPropagation()

    const node = event.nativeEvent.target
    const index = parseInt(node.getAttribute('index'))
    const option = options[index]

    if (option && option.disabled) return

    if (option && option.onClick) option.onClick(objectData, onUpdate)
  }

  renderOption = ({ name, disabled, id, icon }, index) => {
    const { idBase } = this.props
    return (
      <OptionButton
        id={`${idBase ? `${idBase}-` : ''}${id ? `${id}-` : ''}`}
        disabled={disabled}
        key={`menu-button--${name}`}
        index={index}
        onClick={this.handleOptionClick}
      >
        {icon}
        {name}
      </OptionButton>
    )
  }

  handleMenuOpen = event => {
    event.stopPropagation()
    this.setState(
      prevState => {
        if (!prevState.isMenuOpen) {
          document.addEventListener('click', this.handleMenuOpen)
          document.addEventListener('scroll', this.handleMenuOpen)
        } else {
          document.removeEventListener('click', this.handleMenuOpen)
          document.removeEventListener('scroll', this.handleMenuOpen)
        }
        return { isMenuOpen: !prevState.isMenuOpen }
      },
      () => {
        if (!this.setState.isMenuOpen) {
          setTimeout(() => {
            this.setState({ hide: !this.state.hide })
          }, 100)
        }
      }
    )
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleMenuOpen)
  }

  render() {
    const { options, buttonChildren, disabled, className, idBase } = this.props
    const { isMenuOpen, hide } = this.state

    return (
      <Fragment>
        <ButtonContainer className={className}>
          <button id={idBase} disabled={disabled} onClick={this.handleMenuOpen}>
            {buttonChildren()}
          </button>
          <MenuContainer isMenuOpen={isMenuOpen} hide={hide}>
            {options && options.map(this.renderOption)}
          </MenuContainer>
        </ButtonContainer>
      </Fragment>
    )
  }
}

export default MenuButton
