var _jsxFileName = 'src/lib/elements/Buttons/SubmitButton.js',
    _this = this;

import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../generics/Buttons';

/** Mainly used at submitting any form inside the Form Component. */
var SubmitButton = function SubmitButton(_ref) {
  var id = _ref.id,
      text = _ref.children,
      full = _ref.full,
      noMargin = _ref.noMargin,
      disabled = _ref.disabled;

  return React.createElement(
    'div',
    { className: 'submit-button', __source: {
        fileName: _jsxFileName,
        lineNumber: 9
      },
      __self: _this
    },
    React.createElement(
      Button,
      {
        primary: true,
        submit: true,
        id: id,
        full: full,
        noMargin: noMargin,
        disabled: disabled,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 10
        },
        __self: _this
      },
      text
    )
  );
};

SubmitButton.defaultProps = {
  full: true
};

SubmitButton.propTypes = {
  /** Text to be displayed in button. */
  children: PropTypes.any,
  /** Disables button.*/
  disabled: PropTypes.bool,
  /** If you want to make it full width. */
  full: PropTypes.bool,
  /** Used to identify button. */
  id: PropTypes.string,
  /** Gives no margin to any side, but only when full=false */
  noMargin: PropTypes.bool
};

export default SubmitButton;