import React from 'react'
import { func, string } from 'prop-types'
import styled from 'styled-components'

function LocationElement(props) {
  return (
    <Container onClick={props.onClick}>
      <span
        dangerouslySetInnerHTML={{
          __html: props.label
        }}
      />
      <span>{props.subLabel}</span>
    </Container>
  )
}

LocationElement.propTypes = {
  label: string,
  subLabel: string,
  onClick: func
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  &:after {
    content: ' ';
    ${({ theme }) => theme.position('absolute', null, 10, 0, 10)}
    height: 2px;
    background-color: ${({ theme }) => theme.palette.gray.light};
  }

  &:last-child:after {
    content: '';
  }
  cursor: pointer;
  padding: ${({ theme }) => theme.remCalc('5 12')};
  &:hover {
    background-color: ${({ theme }) => theme.palette.gray.light};
  }
`

export default LocationElement
