import React, { Fragment } from 'react'
import { createSvgIcon } from 'rc'
export default createSvgIcon(
  <Fragment>
    <g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 21)">
      <circle cx="30" cy="30" r="30" fill="#fb5757" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M40.933 32.162a2.152 2.152 0 0 0-1.6-3.584h-4.169c.413-1.255.59-2.92.59-3.633v-.717a2.87 2.87 0 0 0-2.865-2.867h-.716a.716.716 0 0 0-.695.542l-.39 1.563c-.546 2.188-2.282 4.602-4.077 5.04a2.151 2.151 0 0 0-1.999-1.362h-4.296a.717.717 0 0 0-.716.716v12.905c0 .395.32.716.716.716h4.296a2.15 2.15 0 0 0 1.94-1.225l2.46.82a7.85 7.85 0 0 0 2.49.405h6a2.152 2.152 0 0 0 1.997-2.943 2.154 2.154 0 0 0 1.582-2.075c0-.55-.207-1.053-.548-1.434a2.144 2.144 0 0 0 0-2.867zM25.728 39.33a.717.717 0 0 1-.716.717h-3.58v-11.47h3.58c.395 0 .716.321.716.716v10.037zm12.173-6.452h1.432a.717.717 0 0 1 0 1.434h-1.432a.717.717 0 0 0 0 1.433h1.432a.717.717 0 0 1 0 1.434h-1.432a.717.717 0 0 0 0 1.434.717.717 0 0 1 0 1.434h-5.998c-.694 0-1.38-.112-2.039-.332l-2.704-.902v-8.87c1.124-.214 2.223-.927 3.216-2.092.969-1.139 1.754-2.648 2.101-4.038l.255-1.02h.157c.79 0 1.432.644 1.432 1.434v.717c0 .962-.3 2.808-.686 3.632h-1.462a.717.717 0 0 0 0 1.434h7.16a.717.717 0 0 1 0 1.434h-1.432a.717.717 0 0 0 0 1.434z"
      />
      <ellipse
        cx="23.608"
        cy="37.906"
        fill="#FFF"
        fillRule="nonzero"
        rx="1"
        ry="1"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M33.678 17.037c-.417 0-.756.311-.756.695v1.437c0 .384.339.695.756.695s.755-.311.755-.695v-1.437c0-.384-.338-.695-.755-.695zM30.54 20.197l-.974-.965a.693.693 0 0 0-.973 0 .677.677 0 0 0 0 .965l.973.964a.693.693 0 0 0 .973 0 .677.677 0 0 0 0-.964zM38.595 19.232a.693.693 0 0 0-.973 0l-.974.964a.677.677 0 0 0 0 .965.693.693 0 0 0 .974 0l.973-.964a.677.677 0 0 0 0-.965z"
      />
    </g>
  </Fragment>,
  'DisikedProperty',
  '18 -35 25 50'
)
