import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Typography } from 'rc'
import { Warning } from 'rc/Icons'
import { Button } from 'components'

const NewSessionStartedModal = ({ HideModal, UnlockModal }) => {
  return (
    <Container
      container
      item
      direction="column"
      alignItems="space-between"
      justify="center"
    >
      <CheckmarkSquareStyled />
      <Title>Se inicio otra sesión con tu cuenta</Title>
      <Typography variant="p" align="center">
        Detectamos que alguien está intentando ingresar a tu cuenta desde otro dispositivo, por lo que procederemos a cerrar la sesión actual. Si no has sido tú, te recomendamos cambiar tu contraseña por seguridad.
      </Typography>
      <Button
        onClick={() => {
          UnlockModal()
          HideModal()
          window.location.replace(`${process.env.REACT_APP_WIGGOT_SSR}/plans`)
        }}
        css={`
          border-radius: 50px !important;
          width: 100%;
        `}
      >
        Aceptar
      </Button>
    </Container>
  )
}

NewSessionStartedModal.propTypes = {
  HideModal: PropTypes.func,
  UnlockModal: PropTypes.func
}

const Title = styled.h2`
    margin: 0;
    ${({ theme }) =>
    theme.typography.buildTextVariant({
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: 36,
        color: theme.palette.primary.main
    })}
`

const Container = styled(Grid)`
  padding: 2rem 2.5rem 2rem 2.5rem;
  ${({ theme }) => theme.box(500, 345)}
`
const CheckmarkSquareStyled = styled(Warning)`
  ${({ theme }) => theme.box(50, 50)}
  fill: ${({ theme }) => theme.palette.warning.main};
`

export default NewSessionStartedModal
