import React, { useState, useContext, useRef, useEffect, Fragment } from 'react'
import { string, any, object } from 'prop-types'
import styled, { css, ThemeContext } from 'styled-components'
import { useHistory } from 'react-router-dom'

import RouterNavLink from 'rc/RouterNavLink'
import ChevronLeft from 'rc/Icons/ChevronLeft'
import ChevronRight from 'rc/Icons/ChevronRight'
import Grid from 'rc/Grid'
import Typography from 'rc/Typography'
import MenuButton from './MenuButton'

const NavigationSideBar = ({ options, menuHeaderCustomized }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const { palette } = useContext(ThemeContext)

  const menuContainer = useRef(null)
  const [isMenuOpened, setToggleMenu] = useState(false)
  const [itemHovered, setItemHovered] = useState({
    id: '',
    top: 0
  })

  const history = useHistory()
  const leftMenuWidth = 70

  const toggleMenu = value => {
    if (value !== undefined) {
      if (isMenuOpened) {
        setSelectedOption(null)
      }
      setToggleMenu(current => !current)
    } else {
      if (!value) {
        setSelectedOption(null)
      }
      setToggleMenu(value)
    }
  }

  const colors = {
    subMenuForeground: 'red',
    optionColors: {
      foreground: palette.primary.main,
      background: palette.secondary.main,
      hoverForeground: palette.primary.main,
      hoverBackground: palette.secondary.main,
      activeBackground: palette.primary.main
    },
    subOptionColor: {
      foreground: 'red',
      background: palette.base.white,
      hoverForeground: palette.base.white,
      hoverBackground: 'green',
      activeForeground: 'blue',
      activeBackground: 'orange'
    }
  }

  const changeHoveredItem = (e, keyOption, elementId) => {
    const offsetTop = elementId
      ? document.getElementById(elementId).getBoundingClientRect().top
      : null

    if (offsetTop)
      setItemHovered({
        id: keyOption,
        top: offsetTop
      })
    else setItemHovered({ id: '', top: 0 })
  }

  useEffect(() => {
    const _ref = menuContainer

    _ref && _ref.current?.addEventListener('scroll', changeHoveredItem)

    return () => {
      _ref && _ref.current?.removeEventListener('scroll', changeHoveredItem)
    }
  }, [])

  const renderOptions = (keyOption, index) => {
    const {
      name,
      icon: Icon,
      id,
      show,
      subOptions = {},
      to,
      onClick,
      notification
    } = options[keyOption]
    const hasOptions =
      Object.keys(subOptions).length > 0 &&
      Object.keys(subOptions).filter(key => {
        const { show } = subOptions[key]

        const isSubOptionShown =
          show === null
            ? false
            : show && typeof show === 'function'
            ? show({ isMenuOpened })
            : show
        return isSubOptionShown
      }).length > 0
    const isShown =
      show === null
        ? false
        : show && typeof show === 'function'
        ? show({ isMenuOpened })
        : show

    const optionId = `navigation-side-bar__option-${keyOption}`
    const optionProps = {
      colors: colors['optionColors'],
      key: id || index,
      id: optionId,
      onMouseEnter: e => changeHoveredItem(e, keyOption, optionId),
      onMouseLeave: () => changeHoveredItem(),
      isSelected: keyOption === selectedOption,
      onClick: () => {
        if (isMenuOpened && hasOptions) setSelectedOption(keyOption)
        else if (to) history.push(to)
        else if (onClick) onClick()

        if (isMenuOpened && !hasOptions) {
          toggleMenu(false)
        }
      }
    }

    const Option = (
      <>
        <IconContainerStyled
          item
          flat
          xsShrink
          container
          center
          inline
          leftMenuWidth={leftMenuWidth}
          css={`
            position: relative;
          `}
        >
          <Icon></Icon>
          {notification && !isMenuOpened && (
            <NotificationStyled>!</NotificationStyled>
          )}
        </IconContainerStyled>

        <OptionTextStyled
          leftMenuWidth={leftMenuWidth}
          variant="h4"
          color={colors['optionColors'].foreground}
        >
          {name}
          {notification && isMenuOpened && (
            <NotificationStyled isOpen>!</NotificationStyled>
          )}
          {hasOptions ? (
            <ChevronRight
              color={colors['optionColors'].foreground}
              css={`
                height: ${({ theme }) => theme.remCalc('35')};
                margin-top: ${({ theme }) => theme.remCalc('-5')};
                color: inherit;
                fill: currentColor;
              `}
            ></ChevronRight>
          ) : (
            <div></div>
          )}
        </OptionTextStyled>
      </>
    )

    return isShown ? (
      to ? (
        <RouterNavLink
          to={to}
          {...optionProps}
          component={OptionContainerStyled}
        >
          {Option}
        </RouterNavLink>
      ) : (
        <OptionContainerStyled {...optionProps}>{Option}</OptionContainerStyled>
      )
    ) : null
  }

  const renderSubOptions = (keyItem, index) => {
    const { id, name, to, onClick, show } =
      options[selectedOption]['subOptions'][keyItem]

    const isShown =
      show === null
        ? false
        : show && typeof show === 'function'
        ? show({ isMenuOpened })
        : show
    const optionId = `navigation-side-bar__option-${selectedOption}--${keyItem}`
    const optionProps = {
      key: id || index,
      id: optionId,
      colors: colors['subOptionColor'],
      onClick: () => {
        toggleMenu(false)
        if (to) {
          history.push(to)
        } else if (onClick) {
          onClick()
        }
      }
    }

    const Option = (
      <Typography
        variant="custom"
        component="p"
        css={`
          ${({ theme }) =>
            theme.typography.buildTextVariant({
              ...theme.typography.subtitle3,
              color: 'inherit'
            })}
          ${({ theme }) => theme.media.desktop`
            ${({ theme }) =>
              theme.typography.buildTextVariant({
                ...theme.typography.subtitle1,
                color: 'inherit'
              })}
          `}
        `}
      >
        {name}
      </Typography>
    )

    return isShown ? (
      <SubOptionStyled {...optionProps}>{Option}</SubOptionStyled>
    ) : null
  }

  return (
    <Fragment>
      <div
        leftMenuWidth={leftMenuWidth}
        css={`
          ${({ theme }) => theme.media.desktop`
          height: 100vh;
          width: ${({ theme, leftMenuWidth }) =>
            theme.remCalc(`${leftMenuWidth}`)};
          `}
        `}
      />
      <div
        onClick={() => toggleMenu(false)}
        $ismenuopened={isMenuOpened}
        css={`
          ${({ theme }) => theme.position('fixed', 0, 0, 0, 0)}
          background-color: rgba(0,0,0,.5);
          transform: ${({ $ismenuopened }) =>
            !$ismenuopened ? 'translateX(-100%)' : 'translateX(0)'};
          transition: transform 200ms ease-in 100ms;
          z-index: 2;
          ${({ theme }) => theme.media.tablet`
            display: none;
          `}
        `}
      />
      <MainContainerStyled
        container
        direction="column"
        $ismenuopened={isMenuOpened}
        leftMenuWidth={leftMenuWidth}
      >
        <MenuButton
          menuHeaderCustomized={menuHeaderCustomized}
          onClick={toggleMenu}
          isMenuOpened={isMenuOpened}
        />
        <Grid
          item
          flat
          xsAuto
          container
          css={`
            position: relative;
          `}
        >
          <ScrollableOptionsContainerStyled
            refId={menuContainer}
            id="navigation-side-bar__scrollable-container"
          >
            {Object.keys(options).map(renderOptions)}
          </ScrollableOptionsContainerStyled>

          <ScrollableSubOptionsContainerStyled
            opened={isMenuOpened && selectedOption}
            container
            direction="column"
            inline
            leftMenuWidth={leftMenuWidth}
          >
            <Grid item flat xsAuto container direction="column">
              <Grid
                container
                alignItems="center"
                onClick={() => setSelectedOption(null)}
                css={`
                  cursor: pointer;
                  height: ${({ theme }) => theme.remCalc('40')};
                  padding-left: ${({ theme }) => theme.remCalc('10')};
                `}
              >
                {selectedOption ? (
                  <ChevronLeft
                    targetColor={colors['subMenuForeground']}
                    css={`
                      height: ${({ theme }) => theme.remCalc('30')};
                      fill: ${({ targetColor }) => targetColor};
                      color: ${({ targetColor }) => targetColor};
                    `}
                  ></ChevronLeft>
                ) : (
                  ''
                )}
                <Typography
                  variant="h4"
                  targetColor={colors['subMenuForeground']}
                  css={`
                    color: ${({ targetColor }) => targetColor};
                    font-family: 'Red Hat Display', sans-serif !important;
                  `}
                >
                  {selectedOption ? options[selectedOption]['name'] : ''}
                </Typography>
              </Grid>
              <div
                css={`
                  height: ${({ theme }) => theme.remCalc('1')};
                  background: ${({ theme }) => theme.palette.base.grayMedium};
                `}
              ></div>
              {selectedOption
                ? Object.keys(options[selectedOption]['subOptions']).map(
                    renderSubOptions
                  )
                : null}
            </Grid>
          </ScrollableSubOptionsContainerStyled>
        </Grid>

        {(!isMenuOpened ||
          (isMenuOpened &&
            selectedOption &&
            selectedOption !== itemHovered['id'])) &&
        itemHovered['top'] ? (
          <OptionHoveredStyled
            top={itemHovered['top']}
            colors={colors['optionColors']}
            leftMenuWidth={leftMenuWidth}
            variant="h4"
            color={colors['optionColors'].foreground}
            custom={true}
          >
            {itemHovered['id'] ? options[itemHovered['id']]['name'] : ''}
          </OptionHoveredStyled>
        ) : (
          ''
        )}
      </MainContainerStyled>
    </Fragment>
  )
}

NavigationSideBar.propTypes = {
  mainText: string,
  menuHeaderCustomized: any,
  options: object
}

const MainContainerStyled = styled(Grid)`
  height: 100vh;
  width: ${({ theme, $ismenuopened, leftMenuWidth }) =>
    $ismenuopened ? '330px' : theme.remCalc(`${leftMenuWidth}`)};
  transition: width 300ms ease, transform 300ms ease;
  transition-delay: ${({ $ismenuopened }) => (!$ismenuopened ? '350ms' : '0')};
  ${({ theme }) => theme.position('fixed', 0, null, null, 0)}
  z-index: 2;
  ${({ theme }) => theme.media.desktopDown`
    transform: ${({ $ismenuopened }) =>
      !$ismenuopened ? 'translateX(-100%)' : 'translateX(0)'};
  `}
`

const ScrollableOptionsContainerStyled = styled(Grid)`
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  background-color: ${({ theme }) => theme.palette.primary.main};
  ${({ theme }) => theme.position('absolute', 0, 0, 0, 0)}
  ${({ theme }) => theme.media.tabletDown`
    padding-bottom: ${({ theme }) => theme.remCalc('90')};
  `}
`

const OptionStyle = css`
  background-color: ${({ colors }) => colors.background};
  fill: ${({ colors }) => colors.foreground};
  color: ${({ colors }) => colors.foreground};
`

const OptionHoveredStyle = css`
  background-color: ${({ colors }) => colors.hoverBackground};
  fill: ${({ colors }) => colors.hoverForeground};
  color: ${({ colors }) => colors.hoverForeground};
`

const OptionActiveStyle = css`
  background-color: ${({ colors }) => colors.activeBackground};
  fill: ${({ colors }) => colors.foreground};
  color: ${({ colors }) => colors.foreground};
`

const OptionSelectedStyle = css`
  ${OptionHoveredStyle}
  ${({ theme, colors }) => theme.bordered(2, colors.foreground, 'top')}
  ${({ theme, colors }) => theme.bordered(2, colors.foreground, 'bottom')}
`

const OptionContainerStyled = styled(Grid)`
  display: flex;
  user-select: none;
  height: ${({ theme }) => theme.remCalc('40')};
  > * {
    ${({ isSelected, match }) =>
      match
        ? OptionActiveStyle
        : isSelected
        ? OptionSelectedStyle
        : OptionStyle}
  }
  &:hover {
    > * {
      ${OptionHoveredStyle}
    }
    box-shadow: 0px 2px 5px rgba(125, 133, 154, 0.5);
  }
  cursor: pointer;
  position: relative;
`

const IconContainerStyled = styled(Grid)`
  width: ${({ theme, leftMenuWidth }) =>
    theme.remCalc(`${leftMenuWidth - 20}`)};
  height: ${({ theme }) => theme.remCalc('40')};
  svg {
    height: ${({ theme }) => theme.remCalc('20')};
    width: ${({ theme }) => theme.remCalc('20')};
  }
  ${({ theme }) => theme.media.desktop`
    width: ${({ theme, leftMenuWidth }) => theme.remCalc(`${leftMenuWidth}`)};
  `}
`

const OptionStyleMixin = css`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.remCalc('40')};
  line-height: ${({ theme }) => theme.remCalc('40')};
  border-bottom-right-radius: ${({ theme }) => theme.remCalc('24')};
  border-top-right-radius: ${({ theme }) => theme.remCalc('24')};
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
`
const OptionTextStyled = styled(Typography)`
  ${OptionStyleMixin}
  ${({ theme, leftMenuWidth }) =>
    theme.position('absolute', 0, 15, 0, leftMenuWidth - 20)}
    ${({ theme }) => theme.media.desktop`
      ${({ theme, leftMenuWidth }) =>
        theme.position('absolute', 0, 15, 0, leftMenuWidth)}
    `}
`

const OptionHoveredStyled = styled(Typography)`
  ${OptionStyleMixin}
  ${OptionHoveredStyle}
  ${({ theme, top, leftMenuWidth }) =>
    theme.position('absolute', top, null, null, leftMenuWidth)}
  box-shadow: 6px 3px 9px -2px rgba(125, 133, 154, 0.5);
  z-index: 1;
  padding-right: ${({ theme, custom }) => (custom ? theme.remCalc('15') : '')};
  ${({ theme }) => theme.media.desktopDown`
    display: none
  `};
`

const ScrollableSubOptionsContainerStyled = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  ${({ theme, opened, leftMenuWidth }) =>
    theme.position(
      'absolute',
      0,
      0,
      opened ? 0 : '100%',
      leftMenuWidth - 5 - 20
    )}
  overflow: auto;
  transition: bottom 300ms ease-in;
  ${({ theme }) => theme.media.desktop`
  ${({ theme, opened, leftMenuWidth }) =>
    theme.position('absolute', 0, 0, opened ? 0 : '100%', leftMenuWidth - 5)}
  `}
`

const SubOptionStyle = css`
  background-color: ${({ colors }) => colors.background};
  fill: ${({ colors }) => colors.foreground};
  color: ${({ colors }) => colors.foreground};
`

const SubOptionHoveredStyle = css`
  background-color: ${({ colors }) => colors.hoverBackground};
  fill: ${({ colors }) => colors.hoverForeground};
  color: ${({ colors }) => colors.hoverForeground};
`

const SubOptionActiveStyle = css`
  background-color: ${({ colors }) => colors.activeBackground};
  fill: ${({ colors }) => colors.foreground};
  color: ${({ colors }) => colors.foreground};
`

const SubOptionStyled = styled(Grid)`
  ${({ match }) => (match ? SubOptionActiveStyle : SubOptionStyle)}
  user-select: none;
  height: ${({ theme }) => theme.remCalc('45')};
  line-height: ${({ theme }) => theme.remCalc('45')};
  padding: ${({ theme }) => theme.remCalc('0 5 0 10')};
  white-space: nowrap;
  margin: ${({ theme }) => theme.remCalc('1 5 1 0')};
  display: inline-block;
  border-bottom-right-radius: ${({ theme }) => theme.remCalc('24')};
  border-top-right-radius: ${({ theme }) => theme.remCalc('24')};
  cursor: pointer;
  &:hover {
    ${({ match }) => (match ? SubOptionActiveStyle : SubOptionHoveredStyle)}
  }
`

const NotificationStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.remCalc('12')};
  background-color: #fd7171;
  margin-left: ${({ theme }) => theme.remCalc('5')};
  color: #fff;
  font-weight: bold;
  position: ${({ isOpen }) => (!isOpen ? 'absolute' : '')};
  right: ${({ theme }) => theme.remCalc('5')};
  ${({ theme }) => theme.box(15, 15, true)}
`

export default NavigationSideBar
