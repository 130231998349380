/**
 * Get user profile details
 * @param {string} Authorization
 */
export const GetUserInfo = (Authorization, isAdmin, roles) => ({
  type: 'GET_USER_INFO',
  payload: {
    request: {
      method: 'get',
      url: `/user`,
      headers: {
        Authorization
      }
    }
  },
  isAdmin,
  roles
})

/**
 * Get user profile details
 * @param {string} Authorization
 * @param {Object} data
 * @param {string} data.fullname
 * @param {string} data.phone_number
 * @param {string} data.agency_name
 * @param {string} data.agency_phone
 * @param {string} data.agency_address
 */
export const UpdateProfile = (Authorization, data) => ({
  type: 'SUBMIT_UPDATE_PROFILE',
  payload: {
    request: {
      method: 'patch',
      url: '/user/profile',
      headers: { Authorization },
      data
    }
  }
})

/**
 * Get profile detail of external brokers
 * @param {string} Authorization
 * @param {string} profile_id
 * @param {Object} data
 */
export const GetProfileDetail = (Authorization, profile_id) => ({
  type: 'GET_PROFILE_DETAIL',
  payload: {
    request: {
      method: 'get',
      headers: { Authorization },
      url: `/profile/${profile_id}`
    }
  }
})

export const GetProfileProperties = (
  Authorization,
  profile_id,
  p = 0,
  l = 9
) => ({
  type: 'GET_PROFILE_PROPERTIES',
  payload: {
    request: {
      method: 'get',
      headers: { Authorization },
      url: `/profile/${profile_id}/properties`,
      params: {
        p,
        l
      }
    }
  }
})

/**
 * Adds a new work experience
 * @param {string} Authorization
 * @param {Object} data
 * @param {string} data.position
 * @param {string} data.company_name
 * @param {string} data.start_month
 * @param {string} data.start_year
 * @param {string} data.end_month
 * @param {string} data.end_year
 */
export const AddWorkExperience = (Authorization, data) => ({
  type: 'ADD_WORK_EXPERIENCE',
  payload: {
    request: {
      method: 'post',
      url: `/user/work_experience`,
      headers: {
        Authorization
      },
      data
    }
  }
})

/**
 * Deletes a new work experience
 * @param {string} Authorization
 * @param {string} workExperienceId - Id of the work experience you want to delete.
 */
export const DeleteWorkExperience = (Authorization, workExperienceId) => ({
  type: 'DELETE_WORK_EXPERIENCE',
  payload: {
    request: {
      method: 'delete',
      url: `/user/work_experience/${workExperienceId}`,
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Edits a new work experience
 * @param {string} Authorization
 * @param {string} workExperienceId - Id of the work experience you want to update.
 * @param {Object} data
 * @param {string} data.position
 * @param {string} data.company_name
 * @param {string} data.start_month
 * @param {string} data.start_year
 * @param {string} data.end_month
 * @param {string} data.end_year
 */
export const EditWorkExperience = (Authorization, certificationId, data) => ({
  type: 'EDIT_WORK_EXPERIENCE',
  payload: {
    request: {
      method: 'put',
      url: `/user/work_experience/${certificationId}`,
      headers: {
        Authorization
      },
      data
    }
  }
})

/**
 * Adds a new work experience
 * @param {string} Authorization
 * @param {Object} data
 * @param {string} data.position
 * @param {string} data.company_name
 * @param {string} data.start_month
 * @param {string} data.start_year
 * @param {string} data.end_month
 * @param {string} data.end_year
 */
export const AddCertification = (Authorization, data) => ({
  type: 'ADD_CERTIFICATION',
  payload: {
    request: {
      method: 'post',
      url: `/user/certifications`,
      headers: {
        Authorization
      },
      data
    }
  }
})

/**
 * Deletes a new work experience
 * @param {string} Authorization
 * @param {string} certificationId - Id of the work experience you want to delete.
 */
export const DeleteCertification = (Authorization, certificationId) => ({
  type: 'DELETE_CERTIFICATION',
  payload: {
    request: {
      method: 'delete',
      url: `/user/certification/${certificationId}`,
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Edits a new work experience
 * @param {string} Authorization
 * @param {string} certificationId - Id of the work experience you want to update.
 * @param {Object} data
 * @param {string} data.position
 * @param {string} data.company_name
 * @param {string} data.start_month
 * @param {string} data.start_year
 * @param {string} data.end_month
 * @param {string} data.end_year
 */
export const EditCertification = (Authorization, certificationId, data) => ({
  type: 'EDIT_CERTIFICATION',
  payload: {
    request: {
      method: 'put',
      url: `/user/certification/${certificationId}`,
      headers: {
        Authorization
      },
      data
    }
  }
})

/**
 * Updates Agency info on profile
 * @param {*} Authorization
 * @param {*} data
 * @param {string} data.agency_name
 * @param {string} data.agency_business_name
 * @param {string} data.agency_email
 * @param {string} data.agency_phone_number
 * @param {string} [data.agency_phone_number_2]
 * @param {string} data.agency_state
 * @param {string} data.agency_city
 * @param {string} data.agency_neighborhood
 * @param {string} [data.agency_street]
 * @param {string} [data.agency_external_number]
 * @param {string} [data.agency_internal_number]
 * @param {string} [data.agency_postal_code]
 * @param {string} [data.agency_tax_id]
 * @param {string} [data.agency_description]
 * @param {string} [data.agency_logo]
 */
export const UpdateAgency = (Authorization, data) => ({
  type: 'SUBMIT_UPDATE_AGENCY',
  payload: {
    request: {
      method: 'patch',
      url: '/user/agency',
      headers: { Authorization },
      data
    }
  }
})

export const NoShowRequirementOnBoarding = () => ({
  type: 'NO_SHOW_REQUIREMENT_ON_BOARDING'
})

/**
 * Updates Agency info on profile
 * @param {*} Authorization
 * @param {*} data
 * @param {string[]} data.email_list
 * @param {string} data.profile_type - can be public or private
 */
export const ShareProfileViaEmails = (Authorization, data) => ({
  type: 'SHARE_PROFILE_VIA_EMAILS',
  payload: {
    request: {
      method: 'post',
      url: '/profile/share',
      headers: { Authorization },
      data
    }
  }
})

/**
 * Adds a new work experience
 * @param {string} Authorization
 * @param {Object} data
 * @param {string} data.type - Could be ASSOCIATION_ID or AGENCY
 */
export const ToggleProfileProperties = (Authorization, data) => ({
  type: 'TOGGLE_PROFILE_PROPERTIES',
  payload: {
    request: {
      method: 'patch',
      url: `/user/profile_properties`,
      headers: {
        Authorization
      },
      data
    }
  }
})

/**
 * Get properties count
 * @param {string} Authorization
 * @param {string} profileId
 */
export const GetProfilePropertiesCount = (Authorization, profileId) => ({
  type: 'GET_PROFILE_PROPERTIES_COUNT',
  payload: {
    request: {
      method: 'get',
      url: `/profile/${profileId}/properties_count`,
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Grade profile
 * @param {string} Authorization
 * @param {string} profileId
 * @param {Object} data
 * @param {Object} data.score - Up to number 5
 * @param {Object} data.comment - Up to 500 characters
 */
export const GradeProfile = (Authorization, profileId, data) => ({
  type: 'GRADE_PROFILE',
  payload: {
    request: {
      method: 'post',
      url: `/profile/${profileId}/grade`,
      headers: {
        Authorization
      },
      data
    }
  }
})

export const GetUserAgency = Authorization => ({
  type: 'GET_USER_AGENCY',
  payload: {
    request: {
      method: 'get',
      url: `/user/agency`,
      headers: {
        Authorization
      }
    }
  }
})

/**
 * Grade profile
 * @param {string} Authorization
 * @param {Object} data
 * @param {Object} data.user_scores_section_config - "HIDDEN", "SCORE_WITHOUT_COMMENT", "SHOWN"
 */

export const UpdateProfileConfig = (Authorization, data) => ({
  type: 'UPDATE_PROFILE_CONFIG',
  payload: {
    request: {
      method: 'patch',
      url: `/profile/config`,
      headers: {
        Authorization
      },
      data
    }
  }
})

export const SeenNotificationsReminder = Authorization => ({
  type: 'SEEN_NOTIFICATIONS_REMINDER',
  payload: {
    request: {
      method: 'post',
      url: `/user/seen_notifications_reminder`,
      headers: {
        Authorization
      }
    }
  }
})

export const VerifyPhoneNumber = (Authorization, code) => ({
  type: 'VERIFY_PHONE_NUMBER',
  payload: {
    request: {
      url: `/user/verify_phone_number/${code}`,
      method: 'post',
      headers: {
        Authorization
      }
    }
  }
})

export const GetUserPaymentInfo = Authorization => ({
  type: 'GET_USER_PAYMENT_INFO',
  payload: {
    request: {
      method: 'get',
      url: `/user/payment_info`,
      headers: {
        Authorization
      }
    }
  }
})

export const GetDeveloperProfile = Authorization => ({
  type: 'GET_DEVELOPER_PROFILE',
  payload: {
    request: {
      method: 'get',
      url: `/developers`,
      headers: {
        Authorization
      }
    }
  }
})

export const UpdateDeveloperProfile = (Authorization, data) => ({
  type: 'UPDATE_DEVELOPER_PROFILE',
  payload: {
    request: {
      method: 'patch',
      url: `/developers`,
      headers: {
        Authorization
      },
      data
    }
  }
})

export const GetDeveloperAdvisorProfile = Authorization => ({
  type: 'GET_DEVELOPER_ADVISOR_PROFILE',
  payload: {
    request: {
      method: 'get',
      url: `/developers/advisors/profile`,
      headers: {
        Authorization
      }
    }
  }
})

export const UpdateDeveloperAdvisorProfile = (Authorization, data) => ({
  type: 'UPDATE_DEVELOPER_ADVISOR_PROFILE',
  payload: {
    request: {
      method: 'put',
      url: `/developers/advisors/profile`,
      headers: {
        Authorization,
      },
      data
    }
  }
})
