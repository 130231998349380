import { createReducer } from '../store'

const initialState = {
  initialLoadOfActives: false,
  actives: []
}

const addonsReducer = createReducer(
  {
    ...initialState
  },
  {
    GET_ALL_ACTIVE_ADDONS_SUCCESS: (state, action) => {
      return {
        ...state,
        actives: action.payload.data,
        initialLoadOfActives: true
      }
    }
  }
)

export default addonsReducer
