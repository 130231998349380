export const GetAllActiveAddons = Authorization => ({
  type: 'GET_ALL_ACTIVE_ADDONS',
  payload: {
    request: {
      method: 'get',
      headers: { Authorization },
      url: `/addons/all_actives`
    }
  }
})

export const ActiveAddon = (Authorization, addonId) => ({
  type: 'ACTIVE_ADDON',
  payload: {
    request: {
      method: 'post',
      headers: { Authorization },
      url: `/addons/${addonId}/activate`
    }
  }
})

export const SaveModelSectionAddonPrime = (Authorization, from, section) => ({
  type: 'SAVE_SECTION_ADDON_PRIME',
  payload: {
    request: {
      url: `/addons/save_click_section_prime`,
      headers: { Authorization },
      method: 'post',
      data: {
        from,
        section
      }
    }
  }
})
