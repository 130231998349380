var _jsxFileName = 'src/lib/generics/Notifications/FlashNotification.js',
    _this = this;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Layout, LayoutItem } from '../../elements/Layouts';
import { IconButton } from '../Buttons';
import { Image } from '../Images';
import DangerIcon from '../../styles/static/img/notification-danger.svg';
import SuccessIcon from '../../styles/static/img/notification-success.svg';
import WarningIcon from '../../styles/static/img/notification-warning.svg';

var BASE_CLASSNAME = 'flash-notification';
var FlashNotification = function FlashNotification(_ref) {
  var _classNames;

  var className = _ref.className,
      danger = _ref.danger,
      warning = _ref.warning,
      success = _ref.success,
      text = _ref.children,
      customIconSrc = _ref.customIconSrc,
      onClick = _ref.onClick;

  var flashNotificationClasses = classNames(className, BASE_CLASSNAME, (_classNames = {}, _defineProperty(_classNames, BASE_CLASSNAME + '--danger', danger), _defineProperty(_classNames, BASE_CLASSNAME + '--warning', warning), _defineProperty(_classNames, BASE_CLASSNAME + '--success', success), _classNames));

  var flashNotificationImage = customIconSrc ? customIconSrc : success ? SuccessIcon : danger ? DangerIcon : warning ? WarningIcon : '';
  return React.createElement(
    Layout,
    { className: BASE_CLASSNAME + '__container', __source: {
        fileName: _jsxFileName,
        lineNumber: 37
      },
      __self: _this
    },
    React.createElement(
      Layout,
      { className: flashNotificationClasses, middle: true, __source: {
          fileName: _jsxFileName,
          lineNumber: 38
        },
        __self: _this
      },
      React.createElement(
        LayoutItem,
        { small: 2, __source: {
            fileName: _jsxFileName,
            lineNumber: 39
          },
          __self: _this
        },
        React.createElement(
          Layout,
          { center: true, middle: true, __source: {
              fileName: _jsxFileName,
              lineNumber: 40
            },
            __self: _this
          },
          React.createElement(Image, {
            shrink: true,
            src: flashNotificationImage,
            className: BASE_CLASSNAME + '__icon',
            __source: {
              fileName: _jsxFileName,
              lineNumber: 41
            },
            __self: _this
          })
        )
      ),
      React.createElement(
        'div',
        { small: 9, className: BASE_CLASSNAME + '__text', __source: {
            fileName: _jsxFileName,
            lineNumber: 48
          },
          __self: _this
        },
        text
      ),
      React.createElement(
        LayoutItem,
        { auto: true, __source: {
            fileName: _jsxFileName,
            lineNumber: 51
          },
          __self: _this
        },
        React.createElement(
          Layout,
          { center: true, middle: true, __source: {
              fileName: _jsxFileName,
              lineNumber: 52
            },
            __self: _this
          },
          React.createElement(IconButton, {
            noResponsive: true,
            className: BASE_CLASSNAME + '__close-button',
            dripiconIcon: 'cross',
            circle: true,
            danger: danger,
            warning: warning,
            success: success,
            onClick: onClick,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 53
            },
            __self: _this
          })
        )
      )
    )
  );
};

FlashNotification.propTypes = {
  /** */
  children: PropTypes.any,
  /** */
  className: PropTypes.string,
  /** */
  customIconSrc: PropTypes.string,
  /** */
  danger: PropTypes.bool,
  /** */
  onClick: PropTypes.func,
  /** */
  success: PropTypes.bool,
  /** */
  warning: PropTypes.bool
};

export default FlashNotification;