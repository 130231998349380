'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var Icons_AddUser = require('./AddUser.js');
var Icons_Agenda = require('./Agenda.js');
var Icons_Alarm = require('./Alarm.js');
var Icons_AlignCenter = require('./AlignCenter.js');
var Icons_AlignJustify = require('./AlignJustify.js');
var Icons_AlignLeft = require('./AlignLeft.js');
var Icons_AlignRight = require('./AlignRight.js');
var Icons_Anchor = require('./Anchor.js');
var Icons_Archive = require('./Archive.js');
var Icons_Area = require('./Area.js');
var Icons_ArrowDown = require('./ArrowDown.js');
var Icons_ArrowLeft = require('./ArrowLeft.js');
var Icons_ArrowRight = require('./ArrowRight.js');
var Icons_ArrowThinDown = require('./ArrowThinDown.js');
var Icons_ArrowThinLeft = require('./ArrowThinLeft.js');
var Icons_ArrowThinRight = require('./ArrowThinRight.js');
var Icons_ArrowThinUp = require('./ArrowThinUp.js');
var Icons_ArrowUp = require('./ArrowUp.js');
var Icons_Article = require('./Article.js');
var Icons_Backspace = require('./Backspace.js');
var Icons_BarCode = require('./BarCode.js');
var Icons_Basket = require('./Basket.js');
var Icons_Basketball = require('./Basketball.js');
var Icons_Bathtub = require('./Bathtub.js');
var Icons_BatteryEmpty = require('./BatteryEmpty.js');
var Icons_BatteryFull = require('./BatteryFull.js');
var Icons_BatteryLow = require('./BatteryLow.js');
var Icons_BatteryMedium = require('./BatteryMedium.js');
var Icons_Bed = require('./Bed.js');
var Icons_Bell = require('./Bell.js');
var Icons_BellCrossed = require('./BellCrossed.js');
var Icons_Blog = require('./Blog.js');
var Icons_Bluetooth = require('./Bluetooth.js');
var Icons_Bold = require('./Bold.js');
var Icons_Bookmark = require('./Bookmark.js');
var Icons_Bookmarks = require('./Bookmarks.js');
var Icons_Box = require('./Box.js');
var Icons_Bricks = require('./Bricks.js');
var Icons_Briefcase = require('./Briefcase.js');
var Icons_BrightnessLow = require('./BrightnessLow.js');
var Icons_BrightnessMax = require('./BrightnessMax.js');
var Icons_BrightnessMedium = require('./BrightnessMedium.js');
var Icons_Broadcast = require('./Broadcast.js');
var Icons_Browser = require('./Browser.js');
var Icons_BrowserDownload = require('./BrowserDownload.js');
var Icons_BrowserUpload = require('./BrowserUpload.js');
var Icons_Brush = require('./Brush.js');
var Icons_Building = require('./Building.js');
var Icons_Building2 = require('./Building2.js');
var Icons_Calendar = require('./Calendar.js');
var Icons_CalendarDays = require('./CalendarDays.js');
var Icons_Camcorder = require('./Camcorder.js');
var Icons_Camera = require('./Camera.js');
var Icons_Car = require('./Car.js');
var Icons_Card = require('./Card.js');
var Icons_CaretLeft = require('./CaretLeft.js');
var Icons_CaretRight = require('./CaretRight.js');
var Icons_Cart = require('./Cart.js');
var Icons_CertifiedProperty = require('./CertifiedProperty.js');
var Icons_Checklist = require('./Checklist.js');
var Icons_ChecklistPaper = require('./ChecklistPaper.js');
var Icons_Checkmark = require('./Checkmark.js');
var Icons_CheckmarkSquare = require('./CheckmarkSquare.js');
var Icons_ChevronDown = require('./ChevronDown.js');
var Icons_ChevronLeft = require('./ChevronLeft.js');
var Icons_ChevronRight = require('./ChevronRight.js');
var Icons_ChevronUp = require('./ChevronUp.js');
var Icons_Clipboard = require('./Clipboard.js');
var Icons_Clock = require('./Clock.js');
var Icons_Clockwise = require('./Clockwise.js');
var Icons_Cloud = require('./Cloud.js');
var Icons_CloudDownload = require('./CloudDownload.js');
var Icons_CloudUpload = require('./CloudUpload.js');
var Icons_Code = require('./Code.js');
var Icons_Contract = require('./Contract.js');
var Icons_Contract2 = require('./Contract2.js');
var Icons_Conversation = require('./Conversation.js');
var Icons_Copy = require('./Copy.js');
var Icons_Crop = require('./Crop.js');
var Icons_Cross = require('./Cross.js');
var Icons_Crosshair = require('./Crosshair.js');
var Icons_Cutlery = require('./Cutlery.js');
var Icons_DeviceDesktop = require('./DeviceDesktop.js');
var Icons_DeviceMobile = require('./DeviceMobile.js');
var Icons_DeviceTablet = require('./DeviceTablet.js');
var Icons_Diploma = require('./Diploma.js');
var Icons_Direction = require('./Direction.js');
var Icons_Disc = require('./Disc.js');
var Icons_Document = require('./Document.js');
var Icons_DocumentDelete = require('./DocumentDelete.js');
var Icons_DocumentEdit = require('./DocumentEdit.js');
var Icons_DocumentNew = require('./DocumentNew.js');
var Icons_DocumentRemove = require('./DocumentRemove.js');
var Icons_Dot = require('./Dot.js');
var Icons_Dots2 = require('./Dots2.js');
var Icons_Dots3 = require('./Dots3.js');
var Icons_Download = require('./Download.js');
var Icons_Duplicate = require('./Duplicate.js');
var Icons_Engine = require('./Engine.js');
var Icons_Enter = require('./Enter.js');
var Icons_Exit = require('./Exit.js');
var Icons_Expand = require('./Expand.js');
var Icons_Expand2 = require('./Expand2.js');
var Icons_Experiment = require('./Experiment.js');
var Icons_Export = require('./Export.js');
var Icons_Eye = require('./Eye.js');
var Icons_EyeSlash = require('./EyeSlash.js');
var Icons_EyeSlash2 = require('./EyeSlash2.js');
var Icons_Facebook = require('./Facebook.js');
var Icons_Feed = require('./Feed.js');
var Icons_Filter = require('./Filter.js');
var Icons_Flag = require('./Flag.js');
var Icons_FlagMarker = require('./FlagMarker.js');
var Icons_Flashlight = require('./Flashlight.js');
var Icons_Folder = require('./Folder.js');
var Icons_FolderOpen = require('./FolderOpen.js');
var Icons_Forward = require('./Forward.js');
var Icons_Gaming = require('./Gaming.js');
var Icons_Gear = require('./Gear.js');
var Icons_Graduation = require('./Graduation.js');
var Icons_GraphBar = require('./GraphBar.js');
var Icons_GraphLine = require('./GraphLine.js');
var Icons_GraphPie = require('./GraphPie.js');
var Icons_Headset = require('./Headset.js');
var Icons_Heart = require('./Heart.js');
var Icons_Height = require('./Height.js');
var Icons_Help = require('./Help.js');
var Icons_Home = require('./Home.js');
var Icons_HomeSearch = require('./HomeSearch.js');
var Icons_Hourglass = require('./Hourglass.js');
var Icons_HouseOnSale = require('./HouseOnSale.js');
var Icons_Inbox = require('./Inbox.js');
var Icons_Information = require('./Information.js');
var Icons_Italic = require('./Italic.js');
var Icons_Jewel = require('./Jewel.js');
var Icons_Lifting = require('./Lifting.js');
var Icons_Lightbulb = require('./Lightbulb.js');
var Icons_Link = require('./Link.js');
var Icons_LinkBroken = require('./LinkBroken.js');
var Icons_List = require('./List.js');
var Icons_Loading = require('./Loading.js');
var Icons_Location = require('./Location.js');
var Icons_Lock = require('./Lock.js');
var Icons_LockOpen = require('./LockOpen.js');
var Icons_Loupe = require('./Loupe.js');
var Icons_Mail = require('./Mail.js');
var Icons_Mail2 = require('./Mail2.js');
var Icons_Map = require('./Map.js');
var Icons_MapMarker = require('./MapMarker.js');
var Icons_Medal = require('./Medal.js');
var Icons_MediaLoop = require('./MediaLoop.js');
var Icons_MediaNext = require('./MediaNext.js');
var Icons_MediaPause = require('./MediaPause.js');
var Icons_MediaPlay = require('./MediaPlay.js');
var Icons_MediaPrevious = require('./MediaPrevious.js');
var Icons_MediaRecord = require('./MediaRecord.js');
var Icons_MediaShuffle = require('./MediaShuffle.js');
var Icons_MediaStop = require('./MediaStop.js');
var Icons_Medical = require('./Medical.js');
var Icons_Menu = require('./Menu.js');
var Icons_Message = require('./Message.js');
var Icons_Messenger = require('./Messenger.js');
var Icons_Meter = require('./Meter.js');
var Icons_Microphone = require('./Microphone.js');
var Icons_Minus = require('./Minus.js');
var Icons_Monitor = require('./Monitor.js');
var Icons_Move = require('./Move.js');
var Icons_Music = require('./Music.js');
var Icons_Network1 = require('./Network1.js');
var Icons_Network2 = require('./Network2.js');
var Icons_Network3 = require('./Network3.js');
var Icons_Network4 = require('./Network4.js');
var Icons_Network5 = require('./Network5.js');
var Icons_Pamphlet = require('./Pamphlet.js');
var Icons_Paperclip = require('./Paperclip.js');
var Icons_PDF = require('./PDF.js');
var Icons_Pencil = require('./Pencil.js');
var Icons_PencilFilled = require('./PencilFilled.js');
var Icons_PencilRounded = require('./PencilRounded.js');
var Icons_Percentage = require('./Percentage.js');
var Icons_Phone = require('./Phone.js');
var Icons_Photo = require('./Photo.js');
var Icons_PhotoGroup = require('./PhotoGroup.js');
var Icons_Pill = require('./Pill.js');
var Icons_Pin = require('./Pin.js');
var Icons_Plus = require('./Plus.js');
var Icons_Power = require('./Power.js');
var Icons_Preview = require('./Preview.js');
var Icons_Price = require('./Price.js');
var Icons_Print = require('./Print.js');
var Icons_PropertyNotFound = require('./PropertyNotFound.js');
var Icons_Pulse = require('./Pulse.js');
var Icons_QRCode = require('./QRCode.js');
var Icons_Question = require('./Question.js');
var Icons_Reply = require('./Reply.js');
var Icons_ReplyAll = require('./ReplyAll.js');
var Icons_Return = require('./Return.js');
var Icons_Retweet = require('./Retweet.js');
var Icons_Rocket = require('./Rocket.js');
var Icons_Scale = require('./Scale.js');
var Icons_Search = require('./Search.js');
var Icons_Share = require('./Share.js');
var Icons_ShareRight = require('./ShareRight.js');
var Icons_Social = require('./Social.js');
var Icons_ShoppingBag = require('./ShoppingBag.js');
var Icons_Skip = require('./Skip.js');
var Icons_Stack = require('./Stack.js');
var Icons_Stair = require('./Stair.js');
var Icons_Star = require('./Star.js');
var Icons_StarChecked = require('./StarChecked.js');
var Icons_StarRounded = require('./StarRounded.js');
var Icons_Stopwatch = require('./Stopwatch.js');
var Icons_Store = require('./Store.js');
var Icons_Suitcase = require('./Suitcase.js');
var Icons_SuitcaseRounded = require('./SuitcaseRounded.js');
var Icons_Swap = require('./Swap.js');
var Icons_Tag = require('./Tag.js');
var Icons_TagDelete = require('./TagDelete.js');
var Icons_Tags = require('./Tags.js');
var Icons_Terrace = require('./Terrace.js');
var Icons_ThumbsDown = require('./ThumbsDown.js');
var Icons_ThumbsUp = require('./ThumbsUp.js');
var Icons_Thunderbolt = require('./Thunderbolt.js');
var Icons_Ticket = require('./Ticket.js');
var Icons_TimeReverse = require('./TimeReverse.js');
var Icons_ToDo = require('./ToDo.js');
var Icons_Toggles = require('./Toggles.js');
var Icons_Toilet = require('./Toilet.js');
var Icons_Trash = require('./Trash.js');
var Icons_Trophy = require('./Trophy.js');
var Icons_Twitter = require('./Twitter.js');
var Icons_Upload = require('./Upload.js');
var Icons_UploadVariant = require('./UploadVariant.js');
var Icons_User = require('./User.js');
var Icons_UserGroup = require('./UserGroup.js');
var Icons_UserId = require('./UserId.js');
var Icons_UserProfile = require('./UserProfile.js');
var Icons_Vibrate = require('./Vibrate.js');
var Icons_ViewApps = require('./ViewApps.js');
var Icons_ViewList = require('./ViewList.js');
var Icons_ViewListLarge = require('./ViewListLarge.js');
var Icons_ViewThumb = require('./ViewThumb.js');
var Icons_VolumeFull = require('./VolumeFull.js');
var Icons_VolumeLow = require('./VolumeLow.js');
var Icons_VolumeMedium = require('./VolumeMedium.js');
var Icons_VolumeOff = require('./VolumeOff.js');
var Icons_Wallet = require('./Wallet.js');
var Icons_Warning = require('./Warning.js');
var Icons_Web = require('./Web.js');
var Icons_Weight = require('./Weight.js');
var Icons_Whatsapp = require('./Whatsapp.js');
var Icons_WhatsappFlat = require('./WhatsappFlat.js');
var Icons_Width = require('./Width.js');
var Icons_Wifi = require('./Wifi.js');
var Icons_Wood = require('./Wood.js');
var Icons_Wrong = require('./Wrong.js');
var Icons_ZoomIn = require('./ZoomIn.js');
var Icons_ZoomOut = require('./ZoomOut.js');
require('react');
require('./utils/createSvgIcon.js');
require('@babel/runtime/helpers/extends');
require('@babel/runtime/helpers/objectWithoutProperties');
require('prop-types');
require('../SvgIcon/SvgIcon.js');
require('styled-components');



exports.AddUser = Icons_AddUser;
exports.Agenda = Icons_Agenda;
exports.Alarm = Icons_Alarm;
exports.AlignCenter = Icons_AlignCenter;
exports.AlignJustify = Icons_AlignJustify;
exports.AlignLeft = Icons_AlignLeft;
exports.AlignRight = Icons_AlignRight;
exports.Anchor = Icons_Anchor;
exports.Archive = Icons_Archive;
exports.Area = Icons_Area;
exports.ArrowDown = Icons_ArrowDown;
exports.ArrowLeft = Icons_ArrowLeft;
exports.ArrowRight = Icons_ArrowRight;
exports.ArrowThinDown = Icons_ArrowThinDown;
exports.ArrowThinLeft = Icons_ArrowThinLeft;
exports.ArrowThinRight = Icons_ArrowThinRight;
exports.ArrowThinUp = Icons_ArrowThinUp;
exports.ArrowUp = Icons_ArrowUp;
exports.Article = Icons_Article;
exports.Backspace = Icons_Backspace;
exports.BarCode = Icons_BarCode;
exports.Basket = Icons_Basket;
exports.Basketball = Icons_Basketball;
exports.Bathtub = Icons_Bathtub;
exports.BatteryEmpty = Icons_BatteryEmpty;
exports.BatteryFull = Icons_BatteryFull;
exports.BatteryLow = Icons_BatteryLow;
exports.BatteryMedium = Icons_BatteryMedium;
exports.Bed = Icons_Bed;
exports.Bell = Icons_Bell;
exports.BellCrossed = Icons_BellCrossed;
exports.Blog = Icons_Blog;
exports.Bluetooth = Icons_Bluetooth;
exports.Bold = Icons_Bold;
exports.Bookmark = Icons_Bookmark;
exports.Bookmarks = Icons_Bookmarks;
exports.Box = Icons_Box;
exports.Bricks = Icons_Bricks;
exports.Briefcase = Icons_Briefcase;
exports.BrightnessLow = Icons_BrightnessLow;
exports.BrightnessMax = Icons_BrightnessMax;
exports.BrightnessMedium = Icons_BrightnessMedium;
exports.Broadcast = Icons_Broadcast;
exports.Browser = Icons_Browser;
exports.BrowserDownload = Icons_BrowserDownload;
exports.BrowserUpload = Icons_BrowserUpload;
exports.Brush = Icons_Brush;
exports.Building = Icons_Building;
exports.Building2 = Icons_Building2;
exports.Calendar = Icons_Calendar;
exports.CalendarDays = Icons_CalendarDays;
exports.Camcorder = Icons_Camcorder;
exports.Camera = Icons_Camera;
exports.Car = Icons_Car;
exports.Card = Icons_Card;
exports.CaretLeft = Icons_CaretLeft;
exports.CaretRight = Icons_CaretRight;
exports.Cart = Icons_Cart;
exports.CertifiedProperty = Icons_CertifiedProperty;
exports.Checklist = Icons_Checklist;
exports.ChecklistPaper = Icons_ChecklistPaper;
exports.Checkmark = Icons_Checkmark;
exports.CheckmarkSquare = Icons_CheckmarkSquare;
exports.ChevronDown = Icons_ChevronDown;
exports.ChevronLeft = Icons_ChevronLeft;
exports.ChevronRight = Icons_ChevronRight;
exports.ChevronUp = Icons_ChevronUp;
exports.Clipboard = Icons_Clipboard;
exports.Clock = Icons_Clock;
exports.Clockwise = Icons_Clockwise;
exports.Cloud = Icons_Cloud;
exports.CloudDownload = Icons_CloudDownload;
exports.CloudUpload = Icons_CloudUpload;
exports.Code = Icons_Code;
exports.Contract = Icons_Contract;
exports.Contract2 = Icons_Contract2;
exports.Conversation = Icons_Conversation;
exports.Copy = Icons_Copy;
exports.Crop = Icons_Crop;
exports.Cross = Icons_Cross;
exports.Crosshair = Icons_Crosshair;
exports.Cutlery = Icons_Cutlery;
exports.DeviceDesktop = Icons_DeviceDesktop;
exports.DeviceMobile = Icons_DeviceMobile;
exports.DeviceTablet = Icons_DeviceTablet;
exports.Diploma = Icons_Diploma;
exports.Direction = Icons_Direction;
exports.Disc = Icons_Disc;
exports.Document = Icons_Document;
exports.DocumentDelete = Icons_DocumentDelete;
exports.DocumentEdit = Icons_DocumentEdit;
exports.DocumentNew = Icons_DocumentNew;
exports.DocumentRemove = Icons_DocumentRemove;
exports.Dot = Icons_Dot;
exports.Dots2 = Icons_Dots2;
exports.Dots3 = Icons_Dots3;
exports.Download = Icons_Download;
exports.Duplicate = Icons_Duplicate;
exports.Engine = Icons_Engine;
exports.Enter = Icons_Enter;
exports.Exit = Icons_Exit;
exports.Expand = Icons_Expand;
exports.Expand2 = Icons_Expand2;
exports.Experiment = Icons_Experiment;
exports.Export = Icons_Export;
exports.Eye = Icons_Eye;
exports.EyeSlash = Icons_EyeSlash;
exports.EyeSlash2 = Icons_EyeSlash2;
exports.Facebook = Icons_Facebook;
exports.Feed = Icons_Feed;
exports.Filter = Icons_Filter;
exports.Flag = Icons_Flag;
exports.FlagMarker = Icons_FlagMarker;
exports.Flashlight = Icons_Flashlight;
exports.Folder = Icons_Folder;
exports.FolderOpen = Icons_FolderOpen;
exports.Forward = Icons_Forward;
exports.Gaming = Icons_Gaming;
exports.Gear = Icons_Gear;
exports.Graduation = Icons_Graduation;
exports.GraphBar = Icons_GraphBar;
exports.GraphLine = Icons_GraphLine;
exports.GraphPie = Icons_GraphPie;
exports.Headset = Icons_Headset;
exports.Heart = Icons_Heart;
exports.Height = Icons_Height;
exports.Help = Icons_Help;
exports.Home = Icons_Home;
exports.HomeSearch = Icons_HomeSearch;
exports.Hourglass = Icons_Hourglass;
exports.HouseOnSale = Icons_HouseOnSale;
exports.Inbox = Icons_Inbox;
exports.Information = Icons_Information;
exports.Italic = Icons_Italic;
exports.Jewel = Icons_Jewel;
exports.Lifting = Icons_Lifting;
exports.Lightbulb = Icons_Lightbulb;
exports.Link = Icons_Link;
exports.LinkBroken = Icons_LinkBroken;
exports.List = Icons_List;
exports.Loading = Icons_Loading;
exports.Location = Icons_Location;
exports.Lock = Icons_Lock;
exports.LockOpen = Icons_LockOpen;
exports.Loupe = Icons_Loupe;
exports.Mail = Icons_Mail;
exports.Mail2 = Icons_Mail2;
exports.Map = Icons_Map;
exports.MapMarker = Icons_MapMarker;
exports.Medal = Icons_Medal;
exports.MediaLoop = Icons_MediaLoop;
exports.MediaNext = Icons_MediaNext;
exports.MediaPause = Icons_MediaPause;
exports.MediaPlay = Icons_MediaPlay;
exports.MediaPrevious = Icons_MediaPrevious;
exports.MediaRecord = Icons_MediaRecord;
exports.MediaShuffle = Icons_MediaShuffle;
exports.MediaStop = Icons_MediaStop;
exports.Medical = Icons_Medical;
exports.Menu = Icons_Menu;
exports.Message = Icons_Message;
exports.Messenger = Icons_Messenger;
exports.Meter = Icons_Meter;
exports.Microphone = Icons_Microphone;
exports.Minus = Icons_Minus;
exports.Monitor = Icons_Monitor;
exports.Move = Icons_Move;
exports.Music = Icons_Music;
exports.Network1 = Icons_Network1;
exports.Network2 = Icons_Network2;
exports.Network3 = Icons_Network3;
exports.Network4 = Icons_Network4;
exports.Network5 = Icons_Network5;
exports.Pamphlet = Icons_Pamphlet;
exports.Paperclip = Icons_Paperclip;
exports.PDF = Icons_PDF;
exports.Pencil = Icons_Pencil;
exports.PencilFilled = Icons_PencilFilled;
exports.PencilRounded = Icons_PencilRounded;
exports.Percentage = Icons_Percentage;
exports.Phone = Icons_Phone;
exports.Photo = Icons_Photo;
exports.PhotoGroup = Icons_PhotoGroup;
exports.Pill = Icons_Pill;
exports.Pin = Icons_Pin;
exports.Plus = Icons_Plus;
exports.Power = Icons_Power;
exports.Preview = Icons_Preview;
exports.Price = Icons_Price;
exports.Print = Icons_Print;
exports.PropertyNotFound = Icons_PropertyNotFound;
exports.Pulse = Icons_Pulse;
exports.QRCode = Icons_QRCode;
exports.Question = Icons_Question;
exports.Reply = Icons_Reply;
exports.ReplyAll = Icons_ReplyAll;
exports.Return = Icons_Return;
exports.Retweet = Icons_Retweet;
exports.Rocket = Icons_Rocket;
exports.Scale = Icons_Scale;
exports.Search = Icons_Search;
exports.Share = Icons_Share;
exports.ShareRight = Icons_ShareRight;
exports.Social = Icons_Social;
exports.ShoppingBag = Icons_ShoppingBag;
exports.Skip = Icons_Skip;
exports.Stack = Icons_Stack;
exports.Stair = Icons_Stair;
exports.Star = Icons_Star;
exports.StarChecked = Icons_StarChecked;
exports.StarRounded = Icons_StarRounded;
exports.Stopwatch = Icons_Stopwatch;
exports.Store = Icons_Store;
exports.Suitcase = Icons_Suitcase;
exports.SuitcaseRounded = Icons_SuitcaseRounded;
exports.Swap = Icons_Swap;
exports.Tag = Icons_Tag;
exports.TagDelete = Icons_TagDelete;
exports.Tags = Icons_Tags;
exports.Terrace = Icons_Terrace;
exports.ThumbsDown = Icons_ThumbsDown;
exports.ThumbsUp = Icons_ThumbsUp;
exports.Thunderbolt = Icons_Thunderbolt;
exports.Ticket = Icons_Ticket;
exports.TimeReverse = Icons_TimeReverse;
exports.ToDo = Icons_ToDo;
exports.Toggles = Icons_Toggles;
exports.Toilet = Icons_Toilet;
exports.Trash = Icons_Trash;
exports.Trophy = Icons_Trophy;
exports.Twitter = Icons_Twitter;
exports.Upload = Icons_Upload;
exports.UploadVariant = Icons_UploadVariant;
exports.User = Icons_User;
exports.UserGroup = Icons_UserGroup;
exports.UserId = Icons_UserId;
exports.UserProfile = Icons_UserProfile;
exports.Vibrate = Icons_Vibrate;
exports.ViewApps = Icons_ViewApps;
exports.ViewList = Icons_ViewList;
exports.ViewListLarge = Icons_ViewListLarge;
exports.ViewThumb = Icons_ViewThumb;
exports.VolumeFull = Icons_VolumeFull;
exports.VolumeLow = Icons_VolumeLow;
exports.VolumeMedium = Icons_VolumeMedium;
exports.VolumeOff = Icons_VolumeOff;
exports.Wallet = Icons_Wallet;
exports.Warning = Icons_Warning;
exports.Web = Icons_Web;
exports.Weight = Icons_Weight;
exports.Whatsapp = Icons_Whatsapp;
exports.WhatsappFlat = Icons_WhatsappFlat;
exports.Width = Icons_Width;
exports.Wifi = Icons_Wifi;
exports.Wood = Icons_Wood;
exports.Wrong = Icons_Wrong;
exports.ZoomIn = Icons_ZoomIn;
exports.ZoomOut = Icons_ZoomOut;
