import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography, Spinner } from 'rc'
import { Button } from 'src/components'

const ConfirmCancelMC = ({ title, description, onCancel, onConfirm, confirmText, cancelText, HideModal, disabled }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Container>
      <Typography variant="h2" color="palette.primary.main" align="center">
        {title}
      </Typography>
      {
        typeof description === 'string' ?
          <Typography
            variant="body1"
            color="palette.new.text"
            align="center"
            textTransform="initial"
          >
            {description}
          </Typography>
          :
          description
      }
      <ButtonsContainer>
        <ButtonStyled type="tertiary" onClick={(e) => {
          HideModal(e)
          onCancel && onCancel()
        }}>
          {cancelText || 'Cancelar'}
        </ButtonStyled>
        <ButtonStyled
          type="primary"
          onClick={() => {
            setIsLoading(true)
            onConfirm(HideModal)
          }}
          disabled={disabled || false}
        >
          {confirmText || 'Confirmar'}
        </ButtonStyled>
      </ButtonsContainer>
      {isLoading && (
        <div
          css={`
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
          `}
        >
          <Spinner />
        </div>
      )}
    </Container>
  )
}

ConfirmCancelMC.propTypes = {
  title: PropTypes.string,
  description: PropTypes.any,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  HideModal: PropTypes.func,
  disabled: PropTypes.bool,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('30')};
  ${({ theme }) => theme.box(450, 350)}
`
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const ButtonStyled = styled(Button).attrs({ rounded: true })`
  width: 48%;
`

export default ConfirmCancelMC;