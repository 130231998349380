export const UserSectionLog = ( Authorization, section) => ({
  type: 'USER_SECTION_LOG',
  payload: {
    request: {
      method: 'post',
      headers: { Authorization },
      url: `/user/logs/visited_section/${section}`
    }
  }
})

export const UserLocationState = (Authorization, helpers = {}) => ({
  type: 'GET_USER_LOCATION_STATE',
  payload: {
    request: {
      method: 'get',
      url: '/user/locations/state',
      headers: { Authorization },
      params: helpers
    }
  }
})
