import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Typography, Button, Select } from 'rc'
import WiggotWebSite from 'static/drawings/wiggot-web-site.svg'

const CreateDevelopmentWebSiteMC = ({ HideModal, developmentList }) => {
  const [development, setDevelopment] = useState(null)
  const history = useHistory()
  return (
    <ModalContainer>
      <Typography
        variant="h2"
        align="center"
        textTransform="initial"
        color="palette.primary.main"
      >
        Selecciona un desarrollo para tu nuevo sitio web
      </Typography>
      <img
        src={WiggotWebSite}
        alt="WiggotWebSite"
        css={`margin: ${({ theme }) => theme.remCalc('20 0 30')};`}
      />
      <div
        css={`
          width: 60%;
          margin-bottom: ${({ theme }) => theme.remCalc('30')};
        `}
      >
        <Select
          placeholder="Selecciona un desarrollo"
          name="development"
          optionLabelKey="development_name"
          optionValueKey="development_id"
          customHeight={'300'}
          options={developmentList}
          value={development}
          onChange={({ target: { value } }) => setDevelopment(value)}
        />
       </div>
      <ButtonsContainer>
        <ButtonStyled
          size="large"
          variant="outlined"
          color="gray"
          onClick={HideModal}          
        >
          Cancelar
        </ButtonStyled>
        <ButtonStyled
          size="large"
          onClick={() => history.push(`/developmentsPromotions/site/${development}?isNewSite=true`)}
          disabled={!development}
        >
          Ir a crear sitio
        </ButtonStyled>
      </ButtonsContainer>
    </ModalContainer>
  )
}

CreateDevelopmentWebSiteMC.propTypes = {
  HideModal: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  developmentList: PropTypes.array
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.remCalc('560')};
  padding: ${({ theme }) => theme.remCalc('32')};
  align-items: center;
`
// const SpinnerStyled = styled(Spinner)`
//   width: ${({ theme }) => theme.remCalc('55')};
// `
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const ButtonStyled = styled(Button)`
  border-radius: 50px !important;
  width: 49%;
`

export default CreateDevelopmentWebSiteMC
