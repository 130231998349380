import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Typography, Button, Spinner } from 'rc'
import { Warning } from 'rc/Icons'
import NotificationMC from 'modules/app/modalContent/NotificationMC'
import { DevelopmentChangeStatusToDraft } from 'reduxActions/development'
import {
  HideModal,
  ShowModal,
  ShowFlashNotification
} from 'reduxActions/services'

const UnpublishDevelopmentMC = ({
  token,
  developmentId,
  HideModal,
  DevelopmentChangeStatusToDraft,
  onUnpublish,
  ShowFlashNotification,
  ShowModal
}) => {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <ModalContainer>
      {!isLoading ? <WarningIconStyled /> : <SpinnerStyled />}
      <Typography
        variant="h2"
        align="center"
        textTransform="initial"
        color="palette.primary.main"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('20')};
        `}
      >
        ¿Estás seguro de despublicar este desarrollo?
      </Typography>
      <Typography
        variant="subtitle4"
        align="center"
        color="palette.new.text"
        fontWeight={400}
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('20')};
        `}
      >
        La información de este desarrollo y sus modelos ya no estara disponible
        en el buscador. Podras editarlo y publicarlo nuevamente cuando lo
        desees.
      </Typography>
      <ButtonsContainer>
        <ButtonStyled
          size="large"
          variant="outlined"
          color="gray"
          onClick={HideModal}
          disabled={isLoading}
        >
          Cancelar
        </ButtonStyled>
        <ButtonStyled
          size="large"
          color="alert"
          onClick={async () => {
            try {
              setIsLoading(true)
              await DevelopmentChangeStatusToDraft(token, developmentId)
              if (onUnpublish)
                return setTimeout(() => {
                  onUnpublish()
                }, 2000)

              ShowModal(NotificationMC, {
                title: '¡Se despublico el desarrollo correctamente!',
                message: 'Ahora ya no se mostrará en el buscador'
              })
            } catch (error) {
              setIsLoading(false)
              ShowFlashNotification(
                'danger',
                'Error al intentar despublicar el desarrollo'
              )
            }
          }}
          disabled={isLoading}
        >
          Despublicar desarrollo
        </ButtonStyled>
      </ButtonsContainer>
    </ModalContainer>
  )
}

UnpublishDevelopmentMC.propTypes = {
  token: PropTypes.string,
  developmentId: PropTypes.string,
  DeleteDevelopment: PropTypes.func,
  HideModal: PropTypes.func,
  onUnpublish: PropTypes.func,
  ShowFlashNotification: PropTypes.func,
  ShowModal: PropTypes.func
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.remCalc('520')};
  padding: ${({ theme }) => theme.remCalc('32')};
  align-items: center;
`
const WarningIconStyled = styled(Warning)`
  fill: #ffbc08;
  width: ${({ theme }) => theme.remCalc('55')};
  margin-bottom: ${({ theme }) => theme.remCalc('24')};
`
const SpinnerStyled = styled(Spinner)`
  width: ${({ theme }) => theme.remCalc('55')};
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const ButtonStyled = styled(Button)`
  border-radius: 50px !important;
  width: 49%;
`

const mapStateToProps = ({ authReducer: { token } }) => ({
  token
})

const mapDispatchToProps = {
  DevelopmentChangeStatusToDraft,
  HideModal,
  ShowFlashNotification,
  ShowModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnpublishDevelopmentMC)
