import React, { useState, useEffect } from 'react'
import { string, object } from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormContainer } from 'rc'
import { UploadModel, UpdateModel } from 'reduxActions/development'
import { ShowFlashNotification, ShowModal } from 'reduxActions/services'
import { TabBarWithButtons } from 'src/components/TabBarSection'
import {
  GeneralInfo,
  Operation,
  TerrainDetail,
  Photos,
  Multimedia,
  Equipment,
  OtherAmenities
} from 'modules/development/forms/model/sections'
import { ModelFormStepContainer } from 'modules/development/static-components'
import { EmptyFieldsMC } from 'modules/development/modalContent'
import ModelFormContext from './ModelFormContext'
import { useAmplitude } from 'src/hooks/useAmplitude'

function ModelForm(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    model,
    token,
    development_id,
    model_id,
    modelBasicInfo: { equipments, development_sections }
  } = props
  const [formErrors, setFormErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const { trackEvent, AmplitudeEvents } = useAmplitude()
  const tracker = (name, description, extra = {}) =>
    trackEvent({
      name,
      eventProperties: {
        description,
        ...extra
      },
      configs: {
        path: true
      }
    })

  useEffect(() => {
    if (model_id) {
      tracker(
        AmplitudeEvents.DEVELOPMENT_EDIT_MODEL_VIEW,
        'User enters the edit model page'
      )
    } else {
      tracker(
        AmplitudeEvents.DEVELOPMENTS_NEW_MODEL_VIEW,
        'User enters the form to create a new model'
      )
    }
  }, [])

  const cleanFormErrors = field => {
    let newFormErrors = formErrors
    delete newFormErrors[field]
    setFormErrors(newFormErrors)
  }

  const handleSubmit = values => {
    setLoading(true)
    const formValues = formatFields(values)
    validateFormFields(formValues)

    const submitFunction = model_id ? UpdateModel : UploadModel
    dispatch(
      submitFunction(token, {
        ...formValues,
        units_models: formValues.units_available,
        cover_image: formValues.images && formValues.images[0],
        model_development_id: model_id ? model_id : null,
        development_id: development_id,
        presale_share_commission: formValues.presale_active,
        sale_share_commission: formValues.sale_active,
        lease_share_commission: formValues.lease_active
      })
    )
      .then(_res => {
        setLoading(false)
        setTimeout(() => {
          dispatch(
            ShowFlashNotification(
              'success',
              `El modelo ha sido ${
                model_id ? 'actualizado' : 'cargado'
              } con éxito.`
            )
          )
        }, 200)
        history.push(`/development/${development_id}`)
      })
      .catch(_err => {
        setLoading(false)
        dispatch(
          ShowFlashNotification(
            'danger',
            `Hubo un error al ${model_id ? 'actualizar' : 'cargar'} el modelo.`
          )
        )
      })
  }

  const checkMinAndMaxFields = values => {
    const {
      details_range,
      ground_active,
      max_ground_size,
      min_ground_size,
      construction_active,
      max_construction_size,
      min_construction_size,
      terrace_active,
      min_terrace_size,
      max_terrace_size,
      garden_active,
      min_garden_size,
      max_garden_size,
      price_range,
      presale_active,
      min_presale_price,
      max_presale_price,
      sale_active,
      min_sale_price,
      max_sale_price,
      lease_active,
      min_lease_price,
      max_lease_price,
      property_type,
      min_fronthead_size,
      max_fronthead_size,
      min_background_size,
      max_background_size
    } = values
    let errors = {}
    if (details_range) {
      if (
        ground_active &&
        parseFloat(min_ground_size) > parseFloat(max_ground_size)
      ) {
        errors['min_ground_size'] = 'El valor debe ser menor al máximo'
      }
      if (
        construction_active &&
        parseFloat(min_construction_size) > parseFloat(max_construction_size)
      ) {
        errors['min_construction_size'] = 'El valor debe ser menor al máximo'
      }
      if (
        terrace_active &&
        parseFloat(min_terrace_size) > parseFloat(max_terrace_size)
      ) {
        errors['min_terrace_size'] = 'El valor debe ser menor al máximo'
      }
      if (
        garden_active &&
        parseFloat(min_garden_size) > parseFloat(max_garden_size)
      ) {
        errors['min_garden_size'] = 'El valor debe ser menor al máximo'
      }
      if (
        property_type === 'TERRAIN' &&
        parseFloat(min_fronthead_size) > parseFloat(max_fronthead_size)
      ) {
        errors['min_fronthead_size'] = 'El valor debe ser menor al máximo'
      }
      if (
        property_type === 'TERRAIN' &&
        parseFloat(min_background_size) > parseFloat(max_background_size)
      ) {
        errors['min_background_size'] = 'El valor debe ser menor al máximo'
      }
    }
    if (price_range) {
      if (presale_active && min_presale_price > max_presale_price)
        errors['min_presale_price'] = 'El valor debe ser menor al máximo'
      if (sale_active && min_sale_price > max_sale_price)
        errors['min_sale_price'] = 'El valor debe ser menor al máximo'
      if (lease_active && min_lease_price > max_lease_price)
        errors['min_lease_price'] = 'El valor debe ser menor al máximo'
    }

    return errors
  }

  const checkMinimumFields = values => {
    const {
      property_type,
      images,
      price_range,
      max_presale_price,
      min_presale_price,
      max_sale_price,
      min_sale_price,
      max_lease_price,
      min_lease_price,
      ground_active,
      max_ground_size,
      terrace_active,
      max_terrace_size,
      construction_active,
      max_construction_size,
      garden_active,
      max_garden_size,
      lease_active,
      sale_active,
      presale_active
    } = values

    const missing_fields = {}

    if (property_type === 'TERRAIN') {
      const required_fields = [
        'model_name',
        'units_available',
        'model_description',
        'floors',
        'max_construction_height',
        'max_ground_size',
        'max_fronthead_size',
        'max_background_size'
      ]
      required_fields
        .filter(field => {
          return (
            !values[field] ||
            values[field] === 0 ||
            (typeof values[field] === 'string' &&
              values[field].trim().length === 0)
          )
        })
        .forEach(item => {
          missing_fields[`${item}`] = 'Ingresa un valor'
        })
    } else {
      const allFields = [
        'property_type',
        'model_name',
        'units_available',
        'model_description',
        'floors',
        'bedrooms',
        'bathrooms'
      ]
      const required_fields =
        model.development_type === 'VERTICAL_MIXTO'
          ? allFields.filter(
              item => item !== 'bathrooms' && item !== 'bedrooms'
            )
          : allFields

      required_fields
        .filter(field => {
          return (
            !values[field] ||
            (typeof values[field] === 'string' &&
              values[field].trim().length === 0)
          )
        })
        .forEach(item => {
          missing_fields[`${item}`] = 'Ingresa un valor'
        })

      if (ground_active && max_ground_size === 0)
        missing_fields['max_ground_size'] = 'Ingresa un valor'
      if (terrace_active && max_terrace_size === 0)
        missing_fields['max_terrace_size'] = 'Ingresa un valor'
      if (construction_active && max_construction_size === 0)
        missing_fields['max_construction_size'] = 'Ingresa un valor'
      if (garden_active && max_garden_size === 0)
        missing_fields['max_garden_size'] = 'Ingresa un valor'
    }

    if (!images || images.length === 0)
      missing_fields['images'] = 'Se requieren mínimo 1'
    if (presale_active) {
      if (price_range && max_presale_price === 0)
        missing_fields['max_presale_price'] = 'Ingresa un valor'
      else if (min_presale_price === 0)
        missing_fields['min_presale_price'] = 'Ingresa un valor'
    }
    if (sale_active) {
      if (price_range && max_sale_price === 0)
        missing_fields['max_sale_price'] = 'Ingresa un valor'
      else if (min_sale_price === 0)
        missing_fields['min_sale_price'] = 'Ingresa un valor'
    }
    if (lease_active) {
      if (price_range && max_lease_price === 0)
        missing_fields['max_lease_price'] = 'Ingresa un valor'
      else if (min_lease_price === 0)
        missing_fields['min_lease_price'] = 'Ingresa un valor'
    }

    return missing_fields
  }

  const validateFormFields = values => {
    const missing_minimum_fields = checkMinimumFields(values)
    const min_and_max_errors = checkMinAndMaxFields(values)

    const errors = {
      ...min_and_max_errors,
      ...missing_minimum_fields
    }
    setFormErrors(errors)

    if (Object.keys(missing_minimum_fields).length > 0)
      dispatch(
        ShowModal(EmptyFieldsMC, {
          fields_with_errors: Object.keys(missing_minimum_fields),
          isModel: true
        })
      )
    if (Object.keys(errors).length > 0)
      throw new Error({ message: 'Required fields' })
  }

  const formatFields = values => {
    const {
      floors,
      bedrooms,
      bathrooms,
      half_bathrooms,
      parking_lots,
      cellar,
      details_range,
      price_range,
      other_equipments,
      towers,
      property_type,
      ground_active
    } = values
    return {
      ...values,
      floors: parseInt(floors),
      bedrooms: parseInt(bedrooms),
      bathrooms: parseInt(bathrooms),
      half_bathrooms: half_bathrooms ? parseInt(half_bathrooms) : 0,
      parking_lots: parseInt(parking_lots),
      cellar: cellar ? parseInt(cellar) : 0,
      details_range: details_range === 'true',
      price_range: price_range === 'true',
      other_equipments:
        other_equipments && other_equipments.length > 0
          ? other_equipments.toString()
          : '',
      towers: towers && towers.length > 0 ? towers.toString() : '',
      ground_active: property_type === 'TERRAIN' ? true : ground_active
    }
  }

  const tabs = [
    {
      label: '1. Datos generales',
      section: (
        <ModelFormStepContainer>
          <GeneralInfo developmentSections={development_sections} />
          <TerrainDetail />
          <Operation />
          <Photos />
          <Multimedia />
          <Equipment modelEquipment={equipments} />
          <OtherAmenities otherEquipmentList={model.other_equipments} />
        </ModelFormStepContainer>
      ),
      show: true
    }
  ]

  return (
    <ModelFormContext.Provider
      value={{
        formErrors,
        model,
        cleanFormErrors: (...args) => cleanFormErrors(...args)
      }}
    >
      <FormContainer
        initialValues={model}
        onSubmit={handleSubmit}
        onSubmitSuccess={() => {}}
        onSubmitFail={() => {}}
      >
        <TabBarWithButtons
          tabs={tabs}
          sectionTitle={`${model ? 'Editar' : 'Cargar'} Modelo / Prototipo`}
          isForm
          withButtons
          disabled={loading}
        />
      </FormContainer>
    </ModelFormContext.Provider>
  )
}

ModelForm.propTypes = {
  model: object,
  modelBasicInfo: object,
  token: string,
  model_id: string,
  development_id: string
}

const mapStateToProps = ({ authReducer: { token } }) => ({ token })

export default connect(mapStateToProps, null)(ModelForm)
