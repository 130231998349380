import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography, Button, TextField } from 'rc'
import { ShowModal } from 'reduxActions/services'
import { useDispatch, useSelector } from 'react-redux'
import { Link as LinkIcon } from 'rc/Icons'
import { WiggotPrimeLogoBar } from 'components/Icons'
import AllBenefits from 'modules/freemium/sections/AllBenefits'
import { generateUrlPublicDatasheet } from 'utils/helpers'

const PropertyPublicUrlMC = ({ HideModal, propertyId, extraData }) => {
  const inputLink = useRef()
  const dispatch = useDispatch()
  const { profileId } = useSelector(({ profileReducer: { profileId } }) => ({
    profileId
  }))
  const [publicLink, setPublicLink] = useState('')
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    let _ref = inputLink
    setPublicLink(
      extraData && extraData.propertyType
        ? `${generateUrlPublicDatasheet({
            ...extraData,
            propertyId: propertyId
          })}?profileId=${profileId}`
        : ''
    )
    _ref.current?.addEventListener(
      'select',
      function () {
        this.selectionStart = this.selectionEnd
      },
      false
    )
    return () => {
      _ref.current?.removeEventListener('select', function () {
        this.selectionStart = this.selectionEnd
      })
    }
  }, [extraData, inputLink, profileId, propertyId])

  const copyToClipboard = () => {
    if (inputLink.current) {
      inputLink.current.select()
      inputLink.current.setSelectionRange(0, 99999)
      document.execCommand('copy')
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 1200)
    }
  }

  const ShowAllBenefitsModal = () => {
    dispatch(ShowModal(AllBenefits, {}))
  }
  return (
    <ModalContainer>
      <Typography
        variant="h2"
        fontWeight={700}
        color="palette.primary.main"
        css={`
          margin-bottom: ${({ theme }) => theme.remCalc('20')};
        `}
      >
        Enlace público
      </Typography>
      <Typography variant="body2" color="palette.text.new">
        Da click en el siguiente botón para obtener la liga de la ficha y que
        puedas enviarla por el medio de tu preferencia.
        <br />
        <br />
        Al enviar esta ficha aceptas los
        <LinkStyled href={process.env.REACT_APP_TERMS_URL}>
          términos y condiciones.
        </LinkStyled>
      </Typography>
      <ButtonStyled variant="outlined" onClick={copyToClipboard}>
        <LinkIcon /> Copiar enlace público
      </ButtonStyled>
      <Separator>
        <span />
        <Typography variant="h2" color="palette.text.new">
          O
        </Typography>
        <span />
      </Separator>
      <TextWithIcon variant="body2" color="palette.text.new">
        Contrata <WiggotPrimeLogoBar /> y envía una ficha personalizada.
      </TextWithIcon>
      <Typography variant="body2" color="palette.text.new">
        Podrás editar la información y recibir feedback de tu cliente sobre la
        propiedad compartida.
        <LinkLikeText onClick={ShowAllBenefitsModal}>
          Conoce todos los beneficios de Prime.
        </LinkLikeText>
      </Typography>
      <ButtonStyled variant="outlined" color="gray" onClick={HideModal}>
        Cerrar
      </ButtonStyled>
      <GhostInput
        inputRef={inputLink}
        value={publicLink}
        inputProps={{ readOnly: true }}
      />
      <SuccessNotification isCopied={isCopied}>
        Enlace copiado
      </SuccessNotification>
    </ModalContainer>
  )
}

PropertyPublicUrlMC.propTypes = {
  HideModal: PropTypes.func,
  propertyId: PropTypes.string,
  extraData: PropTypes.object
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.remCalc('30')};
  width: ${({ theme }) => theme.remCalc('500')};
  position: relative;
  > button {
    margin-top: ${({ theme }) => theme.remCalc('20')};
  }
`
const LinkStyled = styled.a.attrs(props => ({
  ...props,
  target: '_blank'
}))`
  text-decoration: underline;
`
const Separator = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: ${({ theme }) => theme.remCalc('20 0')};
  > span {
    background-image: linear-gradient(
      transparent 45%,
      #e8ecf9 45% 50%,
      transparent 50%
    );
    width: 42%;
  }
`
const TextWithIcon = styled(Typography)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0 !important;
  > svg {
    width: ${({ theme }) => theme.remCalc('120')};
    margin: ${({ theme }) => theme.remCalc('0 12')};
  }
`
const ButtonStyled = styled(Button)`
  width: 50%;
  border-radius: 50px !important;
  > svg {
    margin-right: ${({ theme }) => theme.remCalc('8')};
  }
`
const LinkLikeText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: ${({ theme }) => theme.remCalc('4')};
  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`
const GhostInput = styled(TextField)`
  position: absolute;
  left: -10000px;
  input {
    user-select: none;
    &[readonly] {
      background-color: #fff;
    }
  }
`
const SuccessNotification = styled.span`
  opacity: ${({ isCopied }) => (isCopied ? 1 : 0)};
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 5px;
  padding: 2px 15px;
  transition: all ease 500ms;
  position: absolute;
  left: 37%;
  top: 28%;
`

export default PropertyPublicUrlMC
